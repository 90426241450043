export interface RatingGrade {
  label: string
  color: string
  value: number
}

export const RATING_GRADE_COLORS: string[] = [
  "#6FDBF5",
  "#0089AA",
  "#004769",
  "#93E0CB",
  "#1E7D7D",
  "#045251",
  "#F69297",
  "#AA3237",
  "#771519",
  "#E7C65F",
  "#AF8209",
  "#805F05",
  "#BFC2CC",
  "#69738C",
  "#464B5F",
  "#D498C2",
  "#AA3285",
  "#711D57",
]

export function hexToRgb(hex: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}
