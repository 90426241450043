import Grid from "../../../../shared/components/restyle-grid/grid"
import { css } from "emotion"
import { getThemeColor } from "../../../../shared/helper/color"
import { formatNumber } from "../../../../shared/helper/number-format"
import React from "react"
import LoadingSpinner from "../../../../shared/components/loadingspinner"
import { translations } from "../../../../shared/i18n"
import { translations as assessmentTranslations } from "../../../i18n"
import { DataSource } from "../../../models/comparables"
import { OfferData, OfferObjectType } from "./historical-offers-box"
import Icon from "../../../../shared/components/icon"
import { Tooltip } from "../../../../shared/components/ui/tooltip"

const styles = {
  cardHeader: css({
    textOverflow: "ellipsis",
    fontSize: "16px",
    color: "black",
    padding: "16px 12px 0 14px",
  }),
  cardBody: css({
    fontSize: "14px",
    color: "black",
    padding: "12px 14px",
    borderBottom: `1px solid ${getThemeColor("primary", "light")}`,
  }),
  offerRightColumn: css({
    justifySelf: "start",
  }),
  tooltipBody: css({
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    width: "350px",
    padding: "16px 30px",
    fontSize: "14px",
    gap: "16px",
  }),
  tooltipRightColumn: css({
    textAlign: "left",
  }),
}

interface Props {
  objectData?: OfferData
  objectDataLoading: boolean
  dataSource: DataSource
  selected?: boolean
  onClick?: () => void
}

function getObjectWithProperties(obj: Record<string, any> | undefined, properties: string[]): Record<string, any> {
  const result: Record<string, any> = {}
  if (obj) {
    properties.forEach((prop) => {
      if (obj.hasOwnProperty(prop) && obj[prop] !== undefined) {
        result[prop] = obj[prop]
      }
    })
  }
  return result
}

const t = { ...translations(), assessmentTranslations: assessmentTranslations() }

export const ComparablesOfferCard = ({ objectData, objectDataLoading, dataSource, selected, onClick }: Props) => {
  const objectTypeTranslations: { [s in OfferObjectType]: string } = {
    office: t.header.usageType.office,
    retail: t.header.usageType.retail,
    hall: t.header.usageType.hall,
    flat: t.assessmentTranslations.assessmentEntryDetails.priceCategories.apartment,
    house: t.assessmentTranslations.assessmentEntryDetails.priceCategories.house,
    commercial: t.header.usageType.commercial,
    apartment: t.assessmentTranslations.assessmentEntryDetails.priceCategories.apartment,
    plot: t.header.usageType.plot,
    other: t.assessmentTranslations.assessmentEntryDetails.houseType.other,
  }
  const isTransactionDatasource = dataSource === "historical-21st-transaction"
  const isSeniorLivingDatasource = dataSource === "historical-21st-senior-living"
  const isOnlineImmoScoutDatasource = dataSource === "online-immoScout"

  const spinnerIfLoading = (elem?: string | React.ReactElement) =>
    objectDataLoading ? <LoadingSpinner size={20} alignLeft={true} /> : elem ?? "n/a"

  const extraDescriptionFields = objectData?.description
    ?.split("\\n")
    .map((item) =>
      item
        .trim()
        .split(":", 2)
        .map((i) => i.trim())
        .filter((i) => i !== "")
    )
    .filter((items) => items.length > 0)

  const extraDescriptionLabeledFields = extraDescriptionFields?.filter((p) => p.length > 1)
  const extraUnstructuredFields = extraDescriptionFields?.filter((parts) => parts.length === 1)

  const additionalParamsSeniorLiving = () => {
    const owner = isSeniorLivingDatasource ? extraDescriptionLabeledFields?.find((i) => i[0] === "Träger") : undefined
    const additionalParams = extraUnstructuredFields ? { additionalInfo: extraUnstructuredFields.join(", ") } : {}
    return { ...additionalParams, owner: owner ?? owner }
  }

  const additionalParamsTransaction = () => {
    const buyer = isTransactionDatasource ? extraDescriptionLabeledFields?.find((i) => i[0] === "Käufer") : undefined
    const seller = isTransactionDatasource
      ? extraDescriptionLabeledFields?.find((i) => i[0] === "Verkäufer")
      : undefined
    const additionalParams = extraUnstructuredFields ? { additionalInfo: extraUnstructuredFields.join(", ") } : {}
    return { ...additionalParams, buyer: buyer, seller: seller }
  }

  const tooltipData = (() => {
    switch (dataSource) {
      case "historical-21st":
        return getObjectWithProperties(objectData, ["constructionYear"])
      case "online-immoScout":
        return getObjectWithProperties(objectData, ["additionalInfo", "title"])
      case "historical-21st-transaction":
        return additionalParamsTransaction()
      case "historical-21st-senior-living":
        return additionalParamsSeniorLiving()
      default:
        return {}
    }
  })()

  const renderTitle = () => {
    let streetAndHouseNumber = objectData?.street
      ? objectData?.houseNumber
        ? `${objectData.street} ${objectData.houseNumber}`
        : objectData?.street
      : undefined

    const city =
      isOnlineImmoScoutDatasource && objectData?.city && !objectData?.houseNumber ? objectData?.city : undefined
    let title = [streetAndHouseNumber, objectData?.zip, city].filter((e) => e && !!e.trim()).join(", ")
    let subtitle = ""

    if (isTransactionDatasource) {
      if (extraDescriptionFields && extraDescriptionFields.length > 0 && extraDescriptionFields[0].length > 0) {
        subtitle = [streetAndHouseNumber, objectData?.zip, objectData?.city].filter((e) => e && !!e.trim()).join(", ")
        title = extraDescriptionFields[0][0]
        extraDescriptionFields.splice(0, 1)
      }
    }

    return (
      <div className={styles.cardHeader}>
        {spinnerIfLoading(
          <>
            <div style={{ display: "grid", columnGap: "4px", gridTemplateColumns: "1fr min-content" }}>
              {!isOnlineImmoScoutDatasource && <div>{title}</div>}
              {isOnlineImmoScoutDatasource && (
                <b>
                  <a href={`https://www.immobilienscout24.de/expose/${objectData?.int_id}`} target="_blank">
                    {title}
                  </a>
                </b>
              )}
              {Object.keys(tooltipData).length > 0 && (
                <Tooltip
                  placement={"right"}
                  newDesign
                  tooltip={
                    <div className={styles.tooltipBody}>
                      {isOnlineImmoScoutDatasource && tooltipData.title && (
                        <div style={{ gridColumn: "1 / 3", fontWeight: "bold" }}>
                          {spinnerIfLoading(tooltipData.title)}
                        </div>
                      )}
                      {tooltipData?.constructionYear && (
                        <>
                          <div>{t.assessmentTranslations.assessmentEntryDetails.constructionYear}</div>
                          <div className={styles.tooltipRightColumn}>
                            {spinnerIfLoading(tooltipData.constructionYear?.toFixed(0))}
                          </div>
                        </>
                      )}
                      {tooltipData?.owner && (
                        <>
                          <div>{tooltipData?.owner[0]}</div>
                          <div className={styles.tooltipRightColumn}>{spinnerIfLoading(tooltipData?.owner[1])}</div>
                        </>
                      )}
                      {tooltipData?.buyer && (
                        <>
                          <div>{tooltipData?.buyer[0]}</div>
                          <div className={styles.tooltipRightColumn}>{spinnerIfLoading(tooltipData?.buyer[1])}</div>
                        </>
                      )}

                      {tooltipData?.seller && (
                        <>
                          <div>{tooltipData?.seller[0]}</div>
                          <div className={styles.tooltipRightColumn}>{spinnerIfLoading(tooltipData?.seller[1])}</div>
                        </>
                      )}

                      {tooltipData?.additionalInfo && (
                        <>
                          <div>{t.assessmentTranslations.assessmentEntryDetails.additionalInfo}</div>
                          <div className={styles.tooltipRightColumn}>
                            {spinnerIfLoading(tooltipData?.additionalInfo)}
                          </div>
                        </>
                      )}
                    </div>
                  }
                >
                  <Icon name={"info"} color={"primary"} colorType={"default"} />
                </Tooltip>
              )}
            </div>
            {subtitle && <small style={{ fontWeight: "normal" }}>{subtitle}</small>}
          </>
        )}
      </div>
    )
  }

  return (
    <div style={selected ? { backgroundColor: "rgb(220, 220, 245, 0.2)" } : {}} onClick={onClick}>
      {renderTitle()}
      <Grid
        columns={2}
        rowGap={4}
        columnSpec={isSeniorLivingDatasource ? "3fr 1fr" : "2fr 1fr"}
        className={styles.cardBody}
      >
        {!isTransactionDatasource && !isSeniorLivingDatasource && (
          <>
            <div>{t.price}</div>
            <div className={styles.offerRightColumn}>
              {spinnerIfLoading(
                objectData?.sqmPriceCents
                  ? `${objectData.sqmPriceCents / 100} ${t.assessmentTranslations.assessmentEntryDetails.euroPerSqm}`
                  : undefined
              )}
            </div>
          </>
        )}

        {isTransactionDatasource && (
          <>
            <div>{t.price}</div>
            <div className={styles.offerRightColumn}>
              {spinnerIfLoading(
                objectData?.sqmPriceCents && objectData?.area
                  ? formatNumber(
                      (objectData.sqmPriceCents * objectData.area) / 100,
                      0,
                      t.assessmentTranslations.assessmentEntryDetails.euro
                    )
                  : undefined
              )}
            </div>
          </>
        )}
        {!isSeniorLivingDatasource && (
          <>
            <div>{t.assessmentTranslations.assessmentEntryDetails.livingArea}</div>
            <div className={styles.offerRightColumn}>
              {spinnerIfLoading(objectData?.area ? `${objectData.area} m²` : undefined)}
            </div>
          </>
        )}

        {((objectData?.type && objectData?.type === "house") ||
          objectData?.type === "flat" ||
          objectData?.type === "apartment") && (
          <>
            <div>{t.assessmentTranslations.assessmentEntryDetails.houseOrApartment}</div>
            <div className={styles.offerRightColumn}>
              {spinnerIfLoading(
                objectData?.type && objectTypeTranslations[objectData?.type]
                  ? objectTypeTranslations[objectData?.type]
                  : objectData?.type
              )}
            </div>
          </>
        )}
        {!isTransactionDatasource && !isSeniorLivingDatasource && (
          <>
            <div>{t.assessmentTranslations.assessmentEntryDetails.rooms}</div>
            <div className={styles.offerRightColumn}>{spinnerIfLoading(objectData?.rooms?.toFixed(0))}</div>
          </>
        )}
        {objectData?.offerDateYear && (
          <>
            <div>{t.assessmentTranslations.assessmentEntryDetails.publicationTime}</div>
            <div className={styles.offerRightColumn}>
              {spinnerIfLoading(
                isOnlineImmoScoutDatasource
                  ? objectData.offerDateYear && new Date(objectData.offerDateYear).toLocaleDateString()
                  : `Q${objectData.offerDateQuarter} ${objectData.offerDateYear}`
              )}
            </div>
          </>
        )}
        {objectData?.furnished && (
          <>
            <div>{t.assessmentTranslations.assessmentEntryDetails.furnished}</div>
            <div className={styles.offerRightColumn}>
              {spinnerIfLoading(
                objectData.furnished
                  ? t.assessmentTranslations.assessmentEntryDetails.yes
                  : t.assessmentTranslations.assessmentEntryDetails.no
              )}
            </div>
          </>
        )}
        {objectData?.description && (
          <>
            {extraDescriptionLabeledFields
              ?.filter((i) => i[0] !== "Träger" && i[0] !== "Käufer" && i[0] !== "Verkäufer")
              .map((parts) => (
                <>
                  <div>{parts[0]}</div>
                  <div className={styles.offerRightColumn}>{spinnerIfLoading(parts[1])}</div>
                </>
              ))}
          </>
        )}
      </Grid>
    </div>
  )
}
