/* tslint:disable */
/* eslint-disable */
/**
 * Comparables Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';
// @ts-ignore
import { BaseQueryParam } from '../../comparables/models';
// @ts-ignore
import { ComparablesItem } from '../../comparables/models';
// @ts-ignore
import { ComparablesResponse } from '../../comparables/models';
// @ts-ignore
import { ComparablesShortCollection } from '../../comparables/models';
// @ts-ignore
import { ComparablesStatsResponse } from '../../comparables/models';
// @ts-ignore
import { DistributionGraphResult } from '../../comparables/models';
// @ts-ignore
import { MultipleRefIdSearchQuery } from '../../comparables/models';
// @ts-ignore
import { PriceTrend } from '../../comparables/models';
// @ts-ignore
import { ResponseError } from '../../comparables/models';
// @ts-ignore
import { TwentyOneComparablesQueryParam } from '../../comparables/models';
/**
 * ComparablesApi - axios parameter creator
 * @export
 */
export const ComparablesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fetch details of historical comparables item using token from comparable/short endpoint
         * @param {string} refId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1ComparableDetailsRefid: async (refId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'refId' is not null or undefined
            assertParamExists('getApiV1ComparableDetailsRefid', 'refId', refId)
            const localVarPath = `/api/v1/comparable/details/{refId}`
                .replace(`{${"refId"}}`, encodeURIComponent(String(refId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query comparables within radius from point
         * @param {BaseQueryParam} baseQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1Comparable: async (baseQueryParam: BaseQueryParam, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseQueryParam' is not null or undefined
            assertParamExists('postApiV1Comparable', 'baseQueryParam', baseQueryParam)
            const localVarPath = `/api/v1/comparable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseQueryParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch details of historical comparables items using token from comparable/short endpoint
         * @param {MultipleRefIdSearchQuery} multipleRefIdSearchQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1ComparableDetails: async (multipleRefIdSearchQuery: MultipleRefIdSearchQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'multipleRefIdSearchQuery' is not null or undefined
            assertParamExists('postApiV1ComparableDetails', 'multipleRefIdSearchQuery', multipleRefIdSearchQuery)
            const localVarPath = `/api/v1/comparable/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(multipleRefIdSearchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get data for area distribution graph
         * @param {BaseQueryParam} baseQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1ComparableGraphsArea: async (baseQueryParam: BaseQueryParam, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseQueryParam' is not null or undefined
            assertParamExists('postApiV1ComparableGraphsArea', 'baseQueryParam', baseQueryParam)
            const localVarPath = `/api/v1/comparable/graphs/area`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseQueryParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get data for construction year distribution graph
         * @param {BaseQueryParam} baseQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1ComparableGraphsConstructionyear: async (baseQueryParam: BaseQueryParam, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseQueryParam' is not null or undefined
            assertParamExists('postApiV1ComparableGraphsConstructionyear', 'baseQueryParam', baseQueryParam)
            const localVarPath = `/api/v1/comparable/graphs/constructionYear`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseQueryParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get data for offer year distribution graph
         * @param {BaseQueryParam} baseQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1ComparableGraphsOfferyear: async (baseQueryParam: BaseQueryParam, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseQueryParam' is not null or undefined
            assertParamExists('postApiV1ComparableGraphsOfferyear', 'baseQueryParam', baseQueryParam)
            const localVarPath = `/api/v1/comparable/graphs/offerYear`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseQueryParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get data for price distribution graph
         * @param {BaseQueryParam} baseQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1ComparableGraphsPrice: async (baseQueryParam: BaseQueryParam, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseQueryParam' is not null or undefined
            assertParamExists('postApiV1ComparableGraphsPrice', 'baseQueryParam', baseQueryParam)
            const localVarPath = `/api/v1/comparable/graphs/price`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseQueryParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get data for price trend graph
         * @param {BaseQueryParam} baseQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1ComparableGraphsPricetrend: async (baseQueryParam: BaseQueryParam, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseQueryParam' is not null or undefined
            assertParamExists('postApiV1ComparableGraphsPricetrend', 'baseQueryParam', baseQueryParam)
            const localVarPath = `/api/v1/comparable/graphs/priceTrend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseQueryParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search for comparables items around the point
         * @param {BaseQueryParam} baseQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1ComparableShort: async (baseQueryParam: BaseQueryParam, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseQueryParam' is not null or undefined
            assertParamExists('postApiV1ComparableShort', 'baseQueryParam', baseQueryParam)
            const localVarPath = `/api/v1/comparable/short`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseQueryParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get database stats
         * @param {TwentyOneComparablesQueryParam} twentyOneComparablesQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1ComparableStats: async (twentyOneComparablesQueryParam: TwentyOneComparablesQueryParam, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'twentyOneComparablesQueryParam' is not null or undefined
            assertParamExists('postApiV1ComparableStats', 'twentyOneComparablesQueryParam', twentyOneComparablesQueryParam)
            const localVarPath = `/api/v1/comparable/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(twentyOneComparablesQueryParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComparablesApi - functional programming interface
 * @export
 */
export const ComparablesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ComparablesApiAxiosParamCreator(configuration)
    return {
        /**
         * Fetch details of historical comparables item using token from comparable/short endpoint
         * @param {string} refId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV1ComparableDetailsRefid(refId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparablesItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV1ComparableDetailsRefid(refId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Query comparables within radius from point
         * @param {BaseQueryParam} baseQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV1Comparable(baseQueryParam: BaseQueryParam, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparablesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV1Comparable(baseQueryParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch details of historical comparables items using token from comparable/short endpoint
         * @param {MultipleRefIdSearchQuery} multipleRefIdSearchQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV1ComparableDetails(multipleRefIdSearchQuery: MultipleRefIdSearchQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ComparablesItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV1ComparableDetails(multipleRefIdSearchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get data for area distribution graph
         * @param {BaseQueryParam} baseQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV1ComparableGraphsArea(baseQueryParam: BaseQueryParam, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DistributionGraphResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV1ComparableGraphsArea(baseQueryParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get data for construction year distribution graph
         * @param {BaseQueryParam} baseQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV1ComparableGraphsConstructionyear(baseQueryParam: BaseQueryParam, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DistributionGraphResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV1ComparableGraphsConstructionyear(baseQueryParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get data for offer year distribution graph
         * @param {BaseQueryParam} baseQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV1ComparableGraphsOfferyear(baseQueryParam: BaseQueryParam, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DistributionGraphResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV1ComparableGraphsOfferyear(baseQueryParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get data for price distribution graph
         * @param {BaseQueryParam} baseQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV1ComparableGraphsPrice(baseQueryParam: BaseQueryParam, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DistributionGraphResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV1ComparableGraphsPrice(baseQueryParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get data for price trend graph
         * @param {BaseQueryParam} baseQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV1ComparableGraphsPricetrend(baseQueryParam: BaseQueryParam, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PriceTrend>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV1ComparableGraphsPricetrend(baseQueryParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Search for comparables items around the point
         * @param {BaseQueryParam} baseQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV1ComparableShort(baseQueryParam: BaseQueryParam, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparablesShortCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV1ComparableShort(baseQueryParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get database stats
         * @param {TwentyOneComparablesQueryParam} twentyOneComparablesQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV1ComparableStats(twentyOneComparablesQueryParam: TwentyOneComparablesQueryParam, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComparablesStatsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV1ComparableStats(twentyOneComparablesQueryParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ComparablesApi - factory interface
 * @export
 */
export const ComparablesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ComparablesApiFp(configuration)
    return {
        /**
         * Fetch details of historical comparables item using token from comparable/short endpoint
         * @param {string} refId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1ComparableDetailsRefid(refId: string, options?: any): AxiosPromise<ComparablesItem> {
            return localVarFp.getApiV1ComparableDetailsRefid(refId, options).then((request) => request(axios, basePath));
        },
        /**
         * Query comparables within radius from point
         * @param {BaseQueryParam} baseQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1Comparable(baseQueryParam: BaseQueryParam, options?: any): AxiosPromise<ComparablesResponse> {
            return localVarFp.postApiV1Comparable(baseQueryParam, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch details of historical comparables items using token from comparable/short endpoint
         * @param {MultipleRefIdSearchQuery} multipleRefIdSearchQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1ComparableDetails(multipleRefIdSearchQuery: MultipleRefIdSearchQuery, options?: any): AxiosPromise<Array<ComparablesItem>> {
            return localVarFp.postApiV1ComparableDetails(multipleRefIdSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * Get data for area distribution graph
         * @param {BaseQueryParam} baseQueryParam
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1ComparableGraphsArea(baseQueryParam: BaseQueryParam, options?: any): AxiosPromise<DistributionGraphResult> {
            return localVarFp.postApiV1ComparableGraphsArea(baseQueryParam, options).then((request) => request(axios, basePath));
        },
        /**
         * Get data for construction year distribution graph
         * @param {BaseQueryParam} baseQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1ComparableGraphsConstructionyear(baseQueryParam: BaseQueryParam, options?: any): AxiosPromise<DistributionGraphResult> {
            return localVarFp.postApiV1ComparableGraphsConstructionyear(baseQueryParam, options).then((request) => request(axios, basePath));
        },
        /**
         * Get data for offer year distribution graph
         * @param {BaseQueryParam} baseQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1ComparableGraphsOfferyear(baseQueryParam: BaseQueryParam, options?: any): AxiosPromise<DistributionGraphResult> {
            return localVarFp.postApiV1ComparableGraphsOfferyear(baseQueryParam, options).then((request) => request(axios, basePath));
        },
        /**
         * Get data for price distribution graph
         * @param {BaseQueryParam} baseQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1ComparableGraphsPrice(baseQueryParam: BaseQueryParam, options?: any): AxiosPromise<DistributionGraphResult> {
            return localVarFp.postApiV1ComparableGraphsPrice(baseQueryParam, options).then((request) => request(axios, basePath));
        },
        /**
         * Get data for price trend graph
         * @param {BaseQueryParam} baseQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1ComparableGraphsPricetrend(baseQueryParam: BaseQueryParam, options?: any): AxiosPromise<Array<PriceTrend>> {
            return localVarFp.postApiV1ComparableGraphsPricetrend(baseQueryParam, options).then((request) => request(axios, basePath));
        },
        /**
         * Search for comparables items around the point
         * @param {BaseQueryParam} baseQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1ComparableShort(baseQueryParam: BaseQueryParam, options?: any): AxiosPromise<ComparablesShortCollection> {
            return localVarFp.postApiV1ComparableShort(baseQueryParam, options).then((request) => request(axios, basePath));
        },
        /**
         * Get database stats
         * @param {TwentyOneComparablesQueryParam} twentyOneComparablesQueryParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1ComparableStats(twentyOneComparablesQueryParam: TwentyOneComparablesQueryParam, options?: any): AxiosPromise<ComparablesStatsResponse> {
            return localVarFp.postApiV1ComparableStats(twentyOneComparablesQueryParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ComparablesApi - object-oriented interface
 * @export
 * @class ComparablesApi
 * @extends {BaseAPI}
 */
export class ComparablesApi extends BaseAPI {
    /**
     * Fetch details of historical comparables item using token from comparable/short endpoint
     * @param {string} refId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparablesApi
     */
    public getApiV1ComparableDetailsRefid(refId: string, options?: any) {
        return ComparablesApiFp(this.configuration).getApiV1ComparableDetailsRefid(refId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query comparables within radius from point
     * @param {BaseQueryParam} baseQueryParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparablesApi
     */
    public postApiV1Comparable(baseQueryParam: BaseQueryParam, options?: any) {
        return ComparablesApiFp(this.configuration).postApiV1Comparable(baseQueryParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch details of historical comparables items using token from comparable/short endpoint
     * @param {MultipleRefIdSearchQuery} multipleRefIdSearchQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparablesApi
     */
    public postApiV1ComparableDetails(multipleRefIdSearchQuery: MultipleRefIdSearchQuery, options?: any) {
        return ComparablesApiFp(this.configuration).postApiV1ComparableDetails(multipleRefIdSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get data for area distribution graph
     * @param {BaseQueryParam} baseQueryParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparablesApi
     */
    public postApiV1ComparableGraphsArea(baseQueryParam: BaseQueryParam, options?: any) {
        return ComparablesApiFp(this.configuration).postApiV1ComparableGraphsArea(baseQueryParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get data for construction year distribution graph
     * @param {BaseQueryParam} baseQueryParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparablesApi
     */
    public postApiV1ComparableGraphsConstructionyear(baseQueryParam: BaseQueryParam, options?: any) {
        return ComparablesApiFp(this.configuration).postApiV1ComparableGraphsConstructionyear(baseQueryParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get data for offer year distribution graph
     * @param {BaseQueryParam} baseQueryParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparablesApi
     */
    public postApiV1ComparableGraphsOfferyear(baseQueryParam: BaseQueryParam, options?: any) {
        return ComparablesApiFp(this.configuration).postApiV1ComparableGraphsOfferyear(baseQueryParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get data for price distribution graph
     * @param {BaseQueryParam} baseQueryParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparablesApi
     */
    public postApiV1ComparableGraphsPrice(baseQueryParam: BaseQueryParam, options?: any) {
        return ComparablesApiFp(this.configuration).postApiV1ComparableGraphsPrice(baseQueryParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get data for price trend graph
     * @param {BaseQueryParam} baseQueryParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparablesApi
     */
    public postApiV1ComparableGraphsPricetrend(baseQueryParam: BaseQueryParam, options?: any) {
        return ComparablesApiFp(this.configuration).postApiV1ComparableGraphsPricetrend(baseQueryParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search for comparables items around the point
     * @param {BaseQueryParam} baseQueryParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparablesApi
     */
    public postApiV1ComparableShort(baseQueryParam: BaseQueryParam, options?: any) {
        return ComparablesApiFp(this.configuration).postApiV1ComparableShort(baseQueryParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get database stats
     * @param {TwentyOneComparablesQueryParam} twentyOneComparablesQueryParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComparablesApi
     */
    public postApiV1ComparableStats(twentyOneComparablesQueryParam: TwentyOneComparablesQueryParam, options?: any) {
        return ComparablesApiFp(this.configuration).postApiV1ComparableStats(twentyOneComparablesQueryParam, options).then((request) => request(this.axios, this.basePath));
    }
}
