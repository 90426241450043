import * as React from "react"
import {
  MapStyle,
  MapStyleControl,
  persistMapStyle,
  setMapUrlBasedOnMapStyle,
} from "../../../shared/components/map-style-control"
import { SimpleOverlay } from "../../../shared/components/simple-overlay"
import * as layer from "ol/layer"
import { bind } from "decko"
import { css, cx } from "emotion"
import * as ol from "ol"
import { Pixel } from "ol/pixel"
import { FeatureLike } from "ol/Feature"
import { MapPopup, PopupFact } from "../../../shared/components/ui/map-popup"
import { FullScreen, Zoom } from "ol/control"
import { AttributeControl } from "../../../shared/components/attribute-control"
import { translations } from "../../i18n"
import memoizeOne from "memoize-one"
import { mapStyles } from "./rating-manager-map-styles"
import {
  AllowedModulesEnum,
  PrivateDataModuleSettings,
  PrivatePOICategoriesList,
  PrivatePOIList,
} from "../../../private-data/models/private-data"
import { PrivatePoiControl } from "../../../shared/components/map-private-pois-control"
import { JSXContent, PrivatePoiOverlay } from "../../../shared/components/PrivatePoiOverlay"
import Layer from "ol/layer/Layer"
import Source from "ol/source/Source"
import { areMultiplePrivatePoisListsDifferent, arePoisToShowDifferent, updateClusters } from "../../../utils/utils"
import { PoiMarkerDialog } from "../../../shared/components/privatedata-poi-marker-dialog"
import { OpenLayersAbstractProps, OpenLayersMap } from "../../../shared/components/openlayers-map"
import { Location } from "../../../assessment/models/address"
import { fromLonLat } from "ol/proj"
import * as source from "ol/source"
import { Geometry, Point } from "ol/geom"
import * as style from "ol/style"
import { reportAssetsUrl } from "../../../reports/report-config"
import RenderFeature from "ol/render/Feature"
import { formatNumber } from "../../../shared/helper/number-format"
import { getThemeColor } from "../../../shared/helper/color"
import LoadingSpinner from "../../../shared/components/loadingspinner"

export interface MapViewProps extends OpenLayersAbstractProps {
  loading: boolean
  agsRefResLoc?: string
  onSelectAgsRefResLoc?: (agsRefResLoc: string) => void
  initialMapStyle?: MapStyle
  onPreviewToggle: () => void
  preview: boolean
  hasPreview: boolean
  isPrivateDataAccessible: boolean
  privatePoiCategories: PrivatePOICategoriesList
  privateDataSettings: PrivateDataModuleSettings
  getPrivateDataCategories: () => void
  updateSelectedPrivateDataCategories: (categories: PrivatePOICategoriesList, module: AllowedModulesEnum) => void
  getPrivatePoisFromCategories: (categoryId: string, module?: AllowedModulesEnum) => void
  updatePrivatePoisToShow: (poiList: PrivatePOIList, module?: AllowedModulesEnum) => void
  items: Map<string, RatingManagerMapItem>
  locationToPresent?: Location
  mapErrorMessage?: "recalculation" | "shape_outdated"
  markerId?: number
  module: AllowedModulesEnum.RATING_MANAGER | AllowedModulesEnum.RATINGS
}

const controlClass = (top: string) =>
  css({
    right: ".5em",
    top: top,
  })

export interface MapViewState {
  selectedMapStyle: MapStyle
  showPrivatePoisPopover: boolean
  poiPopUpPosition: [number, number] | undefined
}

export abstract class RatingManagerMapView<P extends MapViewProps, S extends MapViewState> extends OpenLayersMap<P, S> {
  protected t = memoizeOne(translations)()
  protected overlay: SimpleOverlay | undefined
  protected privatePoiControl: PrivatePoiControl | undefined = undefined
  protected clusterLayerArray: layer.Vector<any>[] = []
  protected poiPopUp: PrivatePoiOverlay | undefined = undefined
  protected markerLayer: layer.Vector<any> | undefined
  protected contentLayer: layer.VectorTile | undefined
  protected locationPresentZoomLevel = 12

  @bind
  onChangeShowPrivatePoisPopover(value: boolean) {
    this.setState({
      ...this.state,
      showPrivatePoisPopover: value,
    })
  }

  @bind
  onChangeSelectedMapStyle(style: MapStyle) {
    this.setState({ selectedMapStyle: style })
    setMapUrlBasedOnMapStyle(this.getMap(), style)
    persistMapStyle(style)
  }

  componentDidMount() {
    super.componentDidMount()
    this.props.getPrivateDataCategories()
    this.overlay = new SimpleOverlay(this.getMap(), this.getOverlayAt, this.onSelection)

    this.getMap().on("singleclick", this.onMapClick)

    this.getMap().getControls().clear()

    this.getMap().addControl(new MapStyleControl(this.state.selectedMapStyle, this.onChangeSelectedMapStyle))
    this.getMap().addControl(new FullScreen({ className: `${controlClass("3.5em")}` }))
    this.getMap().addControl(new Zoom({ className: `${controlClass("6em")}` }))
    this.getMap().addControl(new AttributeControl(this.props.isPrivateDataAccessible ? "12.5em" : "10em"))
    this.addOrRefreshPrivatePoi()
    this.poiPopUp = new PrivatePoiOverlay(this.getMap(), this.poiPopUpContentCreator)
    updateClusters(
      this.getMap(),
      this.clusterLayerArray,
      this.setClusterLayerArray,
      this.props.privateDataSettings.multipleCategoryPOIList,
      this.props.privatePoiCategories,
      this.props.isPrivateDataAccessible
    )

    setMapUrlBasedOnMapStyle(this.getMap(), this.state.selectedMapStyle)
    void this.resetMap()

    document.addEventListener("keydown", this.handleKeyDownEvent)
  }

  componentDidUpdate(prevProps: P, prevState: S) {
    this.addOrRefreshPrivatePoi()

    if (
      areMultiplePrivatePoisListsDifferent(
        prevProps.privateDataSettings.multipleCategoryPOIList,
        this.props.privateDataSettings.multipleCategoryPOIList
      )
    ) {
      updateClusters(
        this.getMap(),
        this.clusterLayerArray,
        this.setClusterLayerArray,
        this.props.privateDataSettings.multipleCategoryPOIList,
        this.props.privatePoiCategories,
        this.props.isPrivateDataAccessible
      )
    }
    if (arePoisToShowDifferent(prevProps.privateDataSettings.poisToShow, this.props.privateDataSettings.poisToShow)) {
      this.updatePoiPopUp()
    }

    if (
      this.props.locationToPresent &&
      (prevProps.locationToPresent !== this.props.locationToPresent || !this.markerLayer)
    ) {
      const { lng, lat } = this.props.locationToPresent

      void this.resetMap()

      const markerSource = new source.Vector({
        features: [
          new ol.Feature({
            geometry: new Point(fromLonLat([lng, lat])),
            coordinates: fromLonLat([lng, lat]),
          }),
        ],
      })
      this.markerLayer = new layer.Vector({
        source: markerSource,
        style: new style.Style({
          image: new style.Icon({
            src: `${reportAssetsUrl ?? ""}/assets/marker.svg`,
            size: [42, 60],
            anchorOrigin: "top-left",
            anchor: [0.5, 1],
          }),
        }),
      })
      this.getMap().addLayer(this.markerLayer)
    }
  }

  protected addOrRefreshPrivatePoi() {
    if (!this.privatePoiControl) {
      this.privatePoiControl = new PrivatePoiControl(
        this.props.module,
        this.props.privatePoiCategories,
        this.props.privateDataSettings.selectedCategories,
        this.props.updateSelectedPrivateDataCategories,
        this.props.getPrivatePoisFromCategories,
        this.state.showPrivatePoisPopover,
        this.onChangeShowPrivatePoisPopover,
        this.props.isPrivateDataAccessible
      )
      this.getMap().addControl(this.privatePoiControl)
    } else {
      this.privatePoiControl?.refreshPopover(
        this.props.privatePoiCategories,
        this.props.privateDataSettings.selectedCategories,
        this.state.showPrivatePoisPopover
      )
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    document.removeEventListener("keydown", this.handleKeyDownEvent)
  }

  protected containerClass(): string {
    return mapStyles.mapClass
  }

  @bind
  protected onMoveEnd() {}

  render() {
    return super.render()
  }

  @bind
  protected handleKeyDownEvent(e: KeyboardEvent) {
    if (e.key === "Escape") {
      this.overlay?.closeCurrentOverlay(true)
    }
  }

  @bind
  protected isClickableLayers(layer: Layer<Source>): boolean {
    return layer.getClassName().includes("cluster-layer")
  }

  // why does this make new stuff show on map?
  protected abstract onMapClick(event: ol.MapBrowserEvent<any>): void

  @bind
  private getContentFeatureIdAtPixel(pixel: Pixel): string | undefined {
    let featuresAtPixel = this.getMap().getFeaturesAtPixel(pixel, {
      layerFilter: (layer) => layer == this.contentLayer,
    })

    return featuresAtPixel && featuresAtPixel[0] ? featuresAtPixel[0].getId()?.toString() : undefined
  }

  @bind
  protected getOverlayAt(pixel: Pixel) {
    const markerFeature: FeatureLike | undefined = this.getMap().getFeaturesAtPixel(pixel, {
      layerFilter: (layer) => layer === this.markerLayer,
    })?.[0]

    const locationId =
      markerFeature && this.props.markerId ? this.props.markerId.toString() : this.getContentFeatureIdAtPixel(pixel)

    return this.overlayFromLocationId(locationId)
  }

  protected abstract fillAdditionalItemsForOverlay(item: RatingManagerMapItem, additionalItems: PopupFact[]): void

  @bind
  protected overlayFromLocationId(id?: string): { id: string; content: JSX.Element } | null {
    if (id === undefined) return null

    const item = this.props.items.get(id)

    if (!item) return null

    const additionalItems: PopupFact[] = []

    if (typeof item.grade === "string") {
      // TODO: does this rating grade label need to be here?
      additionalItems.push({ label: this.t.ratingGradeLabel, value: item.grade })
    }

    if (typeof item.score === "number") {
      additionalItems.push({ label: this.t.profileScoreTitle, value: formatNumber(item.score, 0) })
    }

    // if (additionalItems.length === 0) {
    //   additionalItems.push({ label: this.t.profileScoreTitle, value: "-" })
    // }
    this.fillAdditionalItemsForOverlay(item, additionalItems)

    const [mainFact, ...secondaryFacts] = additionalItems

    return {
      id,
      content: (
        <MapPopup
          header={item.title}
          mainFact={mainFact}
          secondaryFacts={secondaryFacts}
          closeCallback={() => this.overlay?.closeCurrentOverlay(true)}
        />
      ),
    }
  }

  protected abstract onSelection(id: string | null): void

  protected abstract resetMap(): Promise<void>

  @bind
  protected setClusterLayerArray(newClusterArrayLayer: layer.Vector<any>[]) {
    newClusterArrayLayer.forEach((layer, idx) => layer.setZIndex(1 + idx))
    this.clusterLayerArray = [...newClusterArrayLayer]
  }

  @bind
  protected updatePoiPopUp() {
    if (this.props.isPrivateDataAccessible) {
      if (this.props.privateDataSettings.poisToShow.length > 0 && this.state.poiPopUpPosition) {
        this.poiPopUp?.openOverlayAt(this.state.poiPopUpPosition, this.props.privateDataSettings.poisToShow)
      } else if (this.poiPopUp) {
        this.poiPopUp.closeCurrentOverlay()
      }
    } else if (this.poiPopUp) {
      this.poiPopUp.closeCurrentOverlay()
    }
  }

  @bind onPoiPopUpClose() {
    this.poiPopUp?.closeCurrentOverlay()
    this.props.updatePrivatePoisToShow([], this.props.module)
    this.setState({ ...this.state, poiPopUpPosition: undefined })
  }

  @bind
  protected poiPopUpContentCreator(location: number[], poiList: PrivatePOIList): JSXContent {
    const props = {
      lat: location[1],
      lng: location[0],
      poisToShow: poiList,
      onClose: this.onPoiPopUpClose,
    }
    return {
      content: <PoiMarkerDialog {...props} entryPinLocation={this.props.locationToPresent} />,
    }
  }

  @bind
  protected regionStyle(feature: ol.Feature<Geometry> | RenderFeature): style.Style {
    if (feature.getId() === this.props.agsRefResLoc) {
      return new style.Style({
        stroke: new style.Stroke({
          color: getThemeColor("secondary3", "default").toString(),
          width: 2,
        }),
        fill: new style.Fill({
          color: this.props.locationToPresent ? "rgba(1,1,1,0.3)" : "rgba(1,0,0,0)", // transparent, but essential for layer.getFeatures to work
        }),
      })
    }
    return new style.Style({
      fill: new style.Fill({
        color: "rgba(1,0,0,0)", // transparent, but essential for layer.getFeatures to work
      }),
    })
  }

  @bind
  protected mapRecalculationMessage() {
    return (
      <div className={cx(mapStyles.mapNotifierStyle, css({ maxHeight: this.props.mapErrorMessage ? "48px" : "0px" }))}>
        {this.props.mapErrorMessage === "recalculation" && (
          <span style={{ padding: "8px" }}>{this.t.mapRecalculationMessage}</span>
        )}
        {this.props.mapErrorMessage === "shape_outdated" && (
          <span style={{ padding: "8px" }}>{this.t.map.mapShapeOutdatedMessage}</span>
        )}
      </div>
    )
  }

  @bind
  protected mapLoadingMessage() {
    return (
      <div className={cx(mapStyles.mapNotifierStyle, css({ maxHeight: this.props.loading ? "48px" : "0px" }))}>
        {this.props.loading && <LoadingSpinner size={32} />}
        <span style={{ padding: "8px" }}>{this.t.loadingResultData}</span>
      </div>
    )
  }
}
