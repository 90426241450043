import { axiosCallWithLocalhostFallback } from "../shared/helper/axios"
import { getCurrentDomain } from "../shared/helper/domain"

export const fetchAndHandleUser: () => Promise<User> = async () =>
  axiosCallWithLocalhostFallback<User>(
    {
      domain: getCurrentDomain(window.location),
      serviceName: "dashboard",
      pathWithoutLeadingSlash: "api/profile",
    },
    { withCredentials: true },
    {
      name: "user",
      surname: "user",
      language: "de",
      email: "",
      id: "ZA032",
      trackingAllowed: false,
      companyId: "ff11a998-ae7a-11e7-acbd-9907049fdb2a",
    }
  ).then(
    (data) => {
      return data.data
    },
    (_) => {}
  )
