import * as React from "react"
import {
  isRuleCategoryWithField,
  LoadMacroData,
  LoadMicroData,
  OneOfFilter,
  RangeFilter,
  RankByProfile,
  RankByScores,
  ruleCategories,
  RatingSelectionRule,
  SelectRanks,
} from "../../shared/models/selection"
import { Profile } from "../../profile/models/profile"
import { DataSetType } from "../../shared/models/smartdata"
import { translations } from "../i18n"
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd"
import { BorderBottom } from "../../shared/components/ui/border-bottom"
import { COLORS } from "../../shared/components/ui/colors"
import { Grid } from "../../shared/components/ui/grid"
import { GridItem } from "../../shared/components/ui/grid-item"
import { PopupMenu } from "../../shared/components/ui/popup-menu"
import { FieldOption, getFieldOptions } from "../../shared/actions/field-option-actions"
import { ruleTitle } from "../../shared/i18n/rule"
import { LANA_AGS_NODES, LANA_CELL_NODES } from "../../shared/smartdata-products/smartdata"
import { AlertBox } from "../../shared/components/alertbox"
import Text from "../../shared/components/text"
import Icon from "../../shared/components/icon"
import { formatNumber } from "../../shared/helper/number-format"

type Props = {
  availableProfiles: Profile[]
  dataSetType: DataSetType
  rules: RatingSelectionRule[]
  selectedRuleIdx: number
  onRuleSelect: (ruleIdx: number) => void
  onRuleEdit: (ruleIdx: number) => void
  onRuleDelete: (ruleIdx: number) => Promise<any>
  onRuleReorder: (sourceIdx: number, destIdx: number) => void
}

type FieldOptionsByIndex = { [idx: number]: FieldOption[] }

export const SelectionRuleList: React.FC<Props> = (props) => {
  const t = React.useMemo(() => translations(), [])

  const [toDelete, setToDelete] = React.useState<RatingSelectionRule | null>(null)
  const [fieldOptions, setFieldOptions] = React.useState<FieldOptionsByIndex>({})

  const onRuleReorder = (result: DropResult) => {
    if (!result.destination || result.source.index === result.destination.index) return

    props.onRuleReorder(result.source.index, result.destination.index)
  }

  const renderRule = (rule: RatingSelectionRule, idx: number) => {
    const selected = idx === props.selectedRuleIdx

    return (
      <Draggable draggableId={`ratings-${idx}`} index={idx} key={idx}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{
              ...provided.draggableProps.style,
            }}
            onClick={() => !selected && props.onRuleSelect(idx)}
          >
            <BorderBottom
              padding={8}
              backgroundColor={selected ? COLORS.background.lighter : COLORS.background.default}
            >
              <Grid columnSpec="min-content 1fr" gap={4} alignItems="center">
                <div {...provided.dragHandleProps}>
                  <Icon name="reorder_scrubbler" />
                </div>
                {renderRuleInner(rule, idx)}
              </Grid>
            </BorderBottom>
          </div>
        )}
      </Draggable>
    )
  }

  const renderRuleInner = (rule: RatingSelectionRule, idx: number) => {
    switch (rule.type) {
      case "loadmacrodata":
        return renderLoadMacroData(rule)
      case "loadmicrodata":
        return renderLoadMicroData(rule, idx)
      case "rangefilter":
        return renderRangeFilter(rule, idx)
      case "oneoffilter":
        return renderOneOfFilter(rule, idx)
      case "rankbyprofile":
        return renderRankByProfile(rule, idx)
      case "rankbyscores":
        return renderRankByScores(rule, idx)
      case "selectranks":
        return renderSelectRanks(rule, idx)
      default:
        return null
    }
  }

  const renderLoadMacroData = (rule: LoadMacroData) => (
    <Grid columns={1} gap={4}>
      <h3>{t.loadMacroDataTitle}</h3>
      <div>{t.country[rule.country]}</div>
    </Grid>
  )

  const renderLoadMicroData = (rule: LoadMicroData, idx: number) => {
    const agsRefResLocOptions = fieldOptions[idx]
    let label = ""

    if (!agsRefResLocOptions) {
      getFieldOptions("micro", "ags_ref_res_loc")
        .then((options) =>
          setFieldOptions({
            ...fieldOptions,
            [idx]: options,
          })
        )
        .catch(() =>
          setFieldOptions({
            ...fieldOptions,
            [idx]: [], // Just to prevent endless loop on error, there is probably a better way
          })
        )
    } else {
      label = agsRefResLocOptions.find((option) => option.key === rule.exampleAgsRefResLoc)?.label || ""
    }

    return (
      <Grid columnSpec="1fr min-content" gap={4}>
        <h3>{t.loadMicroDataTitle}</h3>
        <GridItem rowSpan={2}>
          <PopupMenu
            actions={[
              {
                title: t.selectionRuleEdit.editRuleMenuItem,
                onClick: () => props.onRuleEdit(idx),
              },
            ]}
          />
        </GridItem>
        <div>
          {t.selectionRuleEdit.loadMicroDataExample}: {label}
        </div>
      </Grid>
    )
  }

  const renderRangeFilter = (rule: RangeFilter, idx: number) => {
    const title = ruleTitle(t, rule)

    return (
      <Grid columnSpec="1fr min-content" gap={4}>
        <h3>{title}</h3>
        {renderPopupMenu(rule, idx)}
        <div>
          {rule.exclusive ? t.selectionRuleEdit.exclusiveLabel : t.selectionRuleEdit.inclusiveLabel}: &nbsp;
          {typeof rule.from === "number" && <span>&gt;= {formatNumber(rule.from)}</span>}
          &nbsp;
          {typeof rule.to === "number" && <span>&lt; {formatNumber(rule.to)}</span>}
        </div>
      </Grid>
    )
  }

  const renderOneOfFilter = (rule: OneOfFilter, idx: number) => {
    const oneOfOptions = fieldOptions[idx]
    const ruleCategory = ruleCategories.find((c) => isRuleCategoryWithField(c) && c.field === rule.field)
    let values = ""

    if (!oneOfOptions) {
      getFieldOptions(props.dataSetType, rule.field).then(
        (options) =>
          setFieldOptions({
            ...fieldOptions,
            [idx]: options,
          }),
        () =>
          setFieldOptions({
            ...fieldOptions,
            [idx]: [], // Just to prevent endless loop on error, there is probably a better way
          })
      )
    } else {
      values = rule.selections.map((key) => oneOfOptions.find((s) => s.key === key)?.label).join(", ")
    }

    return (
      <Grid columnSpec="1fr min-content" gap={4}>
        <h3>{ruleCategory && t.pickTranslation(ruleCategory.label)}</h3>
        {renderPopupMenu(rule, idx)}
        <div>
          {ruleCategory?.id === "oneoffilter::macro::nuts1"
            ? ""
            : rule.exclusive
            ? t.selectionRuleEdit.exclusiveLabel + ": "
            : t.selectionRuleEdit.inclusiveLabel + ": "}
          {values}
        </div>
      </Grid>
    )
  }

  const renderRankByProfile = (rule: RankByProfile, idx: number) => {
    const ruleCategory = ruleCategories.find((c) => c.type === "rankbyprofile")

    return (
      <Grid columnSpec="1fr min-content" gap={4}>
        <h3>{ruleCategory && t.pickTranslation(ruleCategory.label)}</h3>
        {renderPopupMenu(rule, idx)}
        <div>
          {props.availableProfiles?.find((p) => p.id === rule.id)?.name} | {formatNumber(rule.from, 0)} -{" "}
          {formatNumber(rule.to, 0)}
        </div>
      </Grid>
    )
  }

  const renderRankByScores = (rule: RankByScores, idx: number) => {
    const nodes = [
      ...LANA_CELL_NODES.filter((score) => score.scoreName in rule.scores),
      ...LANA_AGS_NODES.filter((score) => score.scoreName in rule.scores),
    ]
    const ruleCategory = ruleCategories.find((c) => c.type === "rankbyscores")

    return (
      <Grid columnSpec="1fr min-content" gap={4}>
        <h3>{ruleCategory && t.pickTranslation(ruleCategory.label)}</h3>
        {renderPopupMenu(rule, idx)}
        <div>
          {nodes.map((node) => t.pickTranslation(node.title)).join(", ")} | {formatNumber(rule.from, 0)} -{" "}
          {formatNumber(rule.to, 0)}
        </div>
      </Grid>
    )
  }

  const renderSelectRanks = (rule: SelectRanks, idx: number) => {
    const title = ruleTitle(t, rule)

    return (
      <Grid columnSpec="1fr min-content" gap={4}>
        <h3>{title}</h3>
        {renderPopupMenu(rule, idx)}
        <div>
          {rule.fromTop ? t.selectionRuleEdit.selectRankTop : t.selectionRuleEdit.selectRankBottom} |{" "}
          {formatNumber(rule.value, 0, rule.relative ? "%" : "")}
        </div>
      </Grid>
    )
  }

  const renderPopupMenu = (rule: RatingSelectionRule, idx: number) => (
    <GridItem rowSpan={2}>
      <PopupMenu
        actions={[
          {
            title: t.selectionRuleEdit.editRuleMenuItem,
            onClick: () => props.onRuleEdit(idx),
          },
          "divider",
          {
            title: t.selectionRuleEdit.deleteRuleMenuItem,
            onClick: () => setToDelete(rule),
          },
        ]}
      />
    </GridItem>
  )

  const renderDeleteConfirm = (rule: RatingSelectionRule) => {
    const ruleIdx = props.rules.indexOf(rule)

    if (ruleIdx < 0) return null

    return (
      <AlertBox
        header={t.selectionRuleEdit.deleteRuleTitle}
        onClose={() => setToDelete(null)}
        actions={[
          {
            label: t.selectionRuleEdit.deleteRuleMenuItem,
            icon: "delete",
            action: () =>
              props.onRuleDelete(ruleIdx).then(
                () => setToDelete(null),
                () => {}
              ),
          },
          { label: t.cancel, action: () => setToDelete(null) },
        ]}
      >
        <Text>{t.selectionRuleEdit.deleteRuleConfirm(ruleTitle(t, rule))}</Text>
      </AlertBox>
    )
  }

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          overflow: "auto",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        {toDelete && renderDeleteConfirm(toDelete)}
        <div onClick={() => props.onRuleSelect(0)}>
          <BorderBottom
            padding={8}
            backgroundColor={props.selectedRuleIdx === 0 ? COLORS.background.lighter : COLORS.background.default}
          >
            <Grid columnSpec="24px 1fr" gap={4} alignItems="center">
              <div />
              {renderRuleInner(props.rules[0], 0)}
            </Grid>
          </BorderBottom>
        </div>
        <DragDropContext onDragEnd={onRuleReorder}>
          <Droppable droppableId="selection">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {props.rules.slice(1).map((rule, idx) => renderRule(rule, idx + 1))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  )
}
