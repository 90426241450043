import * as React from "react"
import { bind } from "decko"
import { sequence } from "@typed/sequence"
import { Button, ButtonProps } from "./button"

export type Props = {
  onUpload?: (file: File) => Promise<void>

  multiple?: boolean
} & ButtonProps

export class Fileupload extends React.Component<Props, {}> {
  private inputElRef: HTMLInputElement | null

  constructor(props: Props) {
    super(props)
  }

  @bind
  applyChromiumFix(): void {
    if (this.inputElRef) {
      // For chromium you must reset the value so that the user can upload the same file multiple times
      // Otherwise the change event and thus our onUpload event won't fire
      // https://stackoverflow.com/a/6623379
      this.inputElRef.value = ""
    }
  }

  @bind
  handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    event.preventDefault()
    event.stopPropagation()

    const fileList = event.currentTarget.files

    if (this.props.onUpload && fileList) {
      if (this.props.multiple) {
        const files: File[] = []

        for (let index = 0; index < fileList.length; index++) {
          files.push(fileList[index])
        }

        void sequence(files, this.props.onUpload).catch()
      } else {
        void this.props.onUpload(fileList[0]).catch()
      }

      this.applyChromiumFix()
    }
  }

  @bind
  handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    this.inputElRef && this.inputElRef.click()

    this.props.onClick && this.props.onClick(event)
  }

  render() {
    const { multiple, children, ...buttonProps } = this.props

    return (
      <Button {...buttonProps} onClick={this.handleClick}>
        {children}
        <input
          ref={(el) => (this.inputElRef = el)}
          type="file"
          style={{ display: "none" }}
          onChange={this.handleChange}
          multiple={multiple}
        />
      </Button>
    )
  }
}

export default Fileupload
