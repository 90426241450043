import React, { useEffect, useState } from "react"
import DashboardWidget from "./dashboard-widget"
import { translations } from "../../i18n"
import { css } from "emotion"
import { Classes } from "@blueprintjs/core"
import { Location } from "../../models/address"
import Axios, { AxiosResponse } from "axios"
import { lanaApiUrl } from "../../../app_config"
import { DashboardEditMode } from "./dashboard-widget-grid"
import Grid from "../../../shared/components/restyle-grid/grid"
import { language } from "../../../shared/language"

const styles = {
  contentStyle: css({
    padding: "4px 8px",
  }),
}

export interface NearestAccessibilityDistanceEntry {
  title: string
  distance: number
}

export interface NearestAccessibilityDistances {
  nearestAirport?: NearestAccessibilityDistanceEntry
  nearestSubwayStation?: NearestAccessibilityDistanceEntry
  nearestBusStop?: NearestAccessibilityDistanceEntry
}

export interface Props {
  onHeaderClick: () => void
  onRemoveClick?: () => void
  printMode: boolean
  editMode: DashboardEditMode
  markerLocation?: Location
  onRenderingDone: () => void
}

export const formatDistance = (distance?: number, max?: number): string => {
  if (distance === undefined) return "-"
  if (max !== undefined && distance > max) return "-"
  if (distance < 1000) return `${distance.toFixed(0)} m`
  return `${(distance / 1000.0).toFixed(1)} km`
}

const NearestAccessibilityWidget = ({
  onHeaderClick,
  onRemoveClick,
  printMode,
  editMode,
  markerLocation,
  onRenderingDone,
}: Props) => {
  const t = React.useMemo(translations, [translations])
  const langCode = language()

  const [nearestAccessibility, setNearestAccessibility] = useState<NearestAccessibilityDistances>()

  const [loadingNearestAccessibility, setLoadingNearestAccessibility] = useState(true)

  useEffect(() => {
    if (markerLocation) {
      Axios.get(`${lanaApiUrl}/api/v2/here/distances`, {
        params: {
          lat: markerLocation.lat.toString(),
          lng: markerLocation.lng.toString(),
          language: langCode,
        },
      })
        .then(
          (success: AxiosResponse) => {
            setNearestAccessibility(success.data)
            setLoadingNearestAccessibility(false)
          },
          () => {
            setLoadingNearestAccessibility(false)
          }
        )
        .finally(onRenderingDone)
    }
  }, [markerLocation])

  const renderDistanceLine = (label: string, distance: number | undefined, max?: number) => {
    return (
      <>
        <div>{label}</div>
        {loadingNearestAccessibility && <div className={Classes.SKELETON} style={{ width: "4em" }} />}
        {!loadingNearestAccessibility && (
          <div style={{ whiteSpace: "nowrap" }}>{distance ? formatDistance(distance, max) : ""}</div>
        )}
      </>
    )
  }

  return (
    <DashboardWidget
      header={t.dashboard.nearestAccessibility.header}
      onHeaderClick={onHeaderClick}
      onRemoveClick={onRemoveClick}
      printMode={printMode}
      editMode={editMode}
    >
      <div className={styles.contentStyle}>
        <Grid columns={2} columnSpec={"auto min-content"} colGap={16} rowGap={4} padding={4}>
          {renderDistanceLine(
            t.poiExplorer.distances.nearestBusStop,
            nearestAccessibility?.nearestBusStop?.distance,
            5000
          )}
          {renderDistanceLine(
            t.poiExplorer.distances.nearestSubwayStation,
            nearestAccessibility?.nearestSubwayStation?.distance,
            5000
          )}
          {renderDistanceLine(t.poiExplorer.distances.nearestAirport, nearestAccessibility?.nearestAirport?.distance)}
        </Grid>
      </div>
    </DashboardWidget>
  )
}

export default NearestAccessibilityWidget
