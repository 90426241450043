import * as ol from "ol"
import { bind } from "decko"
import { createRoot, Root } from "react-dom/client"
import { PrivatePOIList } from "../../private-data/models/private-data"

export interface JSXContent {
  content: JSX.Element
}
export type Content = (location: number[], poiList: PrivatePOIList) => JSXContent | null
export class PrivatePoiOverlay {
  private map: ol.Map
  private overlayContent: HTMLElement = document.createElement("div")
  private root: Root = createRoot(this.overlayContent)
  private overlay: ol.Overlay
  private pos: number[] | undefined = undefined
  private contentCreator: Content

  constructor(map: ol.Map, contentCreator: Content) {
    this.map = map
    this.contentCreator = contentCreator
    this.map.on("pointermove", this.onPointerMove)
    this.overlay = new ol.Overlay({
      id: "private-poi-overlay",
      element: this.overlayContent,
      positioning: "bottom-right",
      stopEvent: true,
      insertFirst: true,
      autoPan: true,
    })
  }

  @bind isLocationDifferent(location: number[]): boolean {
    if (!this.pos) return true
    return this.pos[0] !== location[0] || this.pos[1] !== location[1]
  }

  @bind
  private onPointerMove(event: ol.MapBrowserEvent<any>) {
    event.map.getInteractions().set("doubleClickZoom", false)
  }

  @bind
  openOverlayAt(location: number[], poisToShow: PrivatePOIList): void {
    if (this.pos && this.isLocationDifferent(location)) {
      this.closeCurrentOverlay()
    }
    this.map.addOverlay(this.overlay)
    this.pos = location
    const content = this.contentCreator(this.pos, poisToShow)
    if (!content) {
      this.closeCurrentOverlay()
      return
    }
    this.root.render(content.content)
    this.overlay.setPosition(this.pos)
  }

  @bind
  closeCurrentOverlay(): void {
    this.pos = undefined
    this.overlay.setPosition(this.pos)
    this.map.getInteractions().set("doubleClickZoom", true)
    this.map.removeOverlay(this.overlay)
  }
}
