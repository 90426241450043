import * as cookie from "cookie"

let lastCookieStr = ""
let lastCookies: { [name: string]: string } = {}

const LANGUAGE_COOKIE = "SETTINGS_LANGUAGE"

export function getCookieValue(cookieName: string): string | null {
  const cookieStr = document.cookie

  if (cookieStr !== lastCookieStr) {
    lastCookieStr = cookieStr
    lastCookies = cookie.parse(cookieStr)
  }

  return cookieName in lastCookies ? lastCookies[cookieName] : null
}

/**
 * @returns {string} returns user language, default is 'en'
 */
export function language(): string {
  let lang =
    getCookieValue(LANGUAGE_COOKIE) ||
    (typeof sessionStorage !== "undefined" && sessionStorage.getItem(LANGUAGE_COOKIE))
  if (lang) {
    return lang
  }

  lang = navigator.language.substring(0, 2)

  if (["en", "de"].indexOf(lang.toLowerCase()) >= 0) {
    return lang
  }

  return "en"
}
