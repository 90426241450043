import { AllNavigationLabels, NavigationPage, NavigationPageData } from "../shared/actions/navigation"
import { store } from "./store"
import { WidgetsType } from "../assessment/models/assessment"
import { LanaScopesType } from "./user-state"
import { doLoadRatingList } from "../ratings/rating-manager-actions"
import { fetchTwoFactorEnabled } from "../user-account/user-profile-slice"
import { App, AppLocation, AppLocationForApp, Apps, ModulesForSubmenu } from "../menu/util/app-location-types"
import { fetchMembersList } from "../company-admin/reducers/memberslist-slice"
import { fetchCompany } from "../company-admin/reducers/settings-slice"
import { fetchAllBillables, fetchCompanyBudget, fetchReadonlyBookings } from "../company-admin/reducers/invoice-slice"
import { listApiTokens } from "../company-admin/reducers/api-slice"
import { selectList } from "../locationselector/location-selector-slice"
import {
  fetchAssessmentList,
  loadAssessment,
  loadAssessmentEntries,
  loadAssessmentEntry,
  navigateToAssessment,
  openAssessmentModule,
} from "../assessment/reducers/assessment-slice-functions"
import { fetchGroups, fetchProfile, fetchProfiles } from "../profile/profile-slice"
import { hideMenu, menuSetActiveAppLocation, navigateTo } from "../shared/reducers/navigation-slice"
import { RouteParams, Router, Routes } from "./routing"
import { registrationSetUserId, registrationStoreEmailToken } from "../registration/reducers/registration-slice"
import { authCookieExists } from "../menu/util/scorefromcookies"
import { loadAndHandleUser, loadMyDashboards, updateUserIsAuthorised } from "./user-slice"
import { assessmentDispatchActions } from "../assessment/reducers/assessment-slice"
import Axios, { AxiosError } from "axios"
import { loginServiceUrl } from "../app_config"
import { fetchAndHandleUser } from "../utils/local-storage"

function allowedToNavigateWithScopes(lanaScopes: LanaScopesType) {
  const navigationDefaultsByScope = [
    lanaScopes.assessment ||
    lanaScopes.comparables ||
    lanaScopes.marketData ||
    lanaScopes.poiExplorer ||
    lanaScopes.specialMaps
      ? NavigationPage.quickstart
      : undefined,
    lanaScopes.assessment ? NavigationPage.dashboardList : undefined,
    lanaScopes.assessment ? NavigationPage.assessmentList : undefined,
    lanaScopes.comparables ? NavigationPage.comparablesList : undefined,
    lanaScopes.marketData ? NavigationPage.marketdataList : undefined,
    lanaScopes.poiExplorer ? NavigationPage.poiExplorerList : undefined,
    lanaScopes.specialMaps ? NavigationPage.specialMapsList : undefined,
    lanaScopes.profile ? NavigationPage.profiles : undefined,
    lanaScopes.privateData ? NavigationPage.privateData : undefined,
    lanaScopes.ratingManager ? NavigationPage.ratingsList : undefined,
    lanaScopes.locationSelector ? NavigationPage.locationSelector : undefined,
  ] as const

  type PossibleItemTypes = Exclude<(typeof navigationDefaultsByScope)[number], undefined>

  return navigationDefaultsByScope.filter((p): p is PossibleItemTypes => !!p)
}

const navigateAction = (page: NavigationPageData) => {
  return () => {
    navigateTo(page)
    return Promise.resolve()
  }
}

const requiresLocationAnalyticsScope =
  (scopeCheck: (s: LanaScopesType) => boolean) =>
  (action: (params: RouteParams) => Promise<void>) =>
  (params: RouteParams) => {
    const lanaScopes = store.getState().user.scopes
    if (scopeCheck(lanaScopes)) {
      return action(params)
    } else {
      console.error("This Page is not available with current scopes")
      return Promise.reject()
    }
  }

export type RouteTag = AppLocation | "hideMenu" | "printMode"

export const navigateToDefaultPage = () => {
  const lanaScopes = store.getState().user.scopes
  // Here we decide where to navigate by default based on scopes we have
  const allowedToNavigate = allowedToNavigateWithScopes(lanaScopes)
  if (allowedToNavigate.length) {
    router.navigateTo(allowedToNavigate[0], {})
  } else {
    if (store.getState().navigation.currentPage?.name !== NavigationPage.userProfile) {
      router.navigateTo(NavigationPage.userProfile, {})
    }
  }

  return Promise.reject()
}

const getQueryParams = (): Record<string, string> => {
  const urlParams = new URLSearchParams(window.location.search)
  const params: Record<string, string> = {}
  for (const [key, value] of urlParams.entries()) {
    params[key] = value
  }
  return params
}

async function loginIS24(queryParams: RouteParams): Promise<true | string> {
  try {
    await Axios.get(`${loginServiceUrl}/oauth/login`, {
      params: {
        oauth_token: queryParams.oauth_token,
        oauth_verifier: queryParams.oauth_verifier,
        state: queryParams.state,
      },
    })
    return true
  } catch (e) {
    if (e instanceof AxiosError) {
      return e.message
    } else {
      return "Error"
    }
  }
}

const routes: Routes<RouteTag, AllNavigationLabels> = {
  "/": {
    name: "",
    action: navigateToDefaultPage,
  },
  "/assessments": {
    name: NavigationPage.assessmentList,
    tag: { app: "locationAnalytics", section: "locationAssessment", module: "assessments" },
    action: requiresLocationAnalyticsScope((_) => _.assessment)((routeParams) => {
      void fetchAssessmentList("module" in routeParams ? "assessment" : undefined)
      return Promise.resolve()
    }),
  },
  "/ratings": {
    name: NavigationPage.ratingsList,
    tag: { app: "locationAnalytics", section: "locationAssessment", module: "ratings" },
    action: requiresLocationAnalyticsScope((scopes) => scopes.ratingManager)(() => {
      void fetchAssessmentList("ratings")
      return Promise.resolve()
    }),
  },
  "/ratings/:assessmentId/entries/:entryId": {
    name: NavigationPage.ratings,
    tag: { app: "locationAnalytics", section: "locationAssessment", module: "ratings" },
    action: requiresLocationAnalyticsScope((_) => _.ratingManager)((params) =>
      openAssessmentModule(params.assessmentId, params.entryId, "ratings").then(() => void doLoadRatingList())
    ),
  },
  "/ratings/:assessmentId": {
    name: NavigationPage.ratingsDetails,
    tag: {
      app: "locationAnalytics",
      section: "locationAssessment",
      module: "ratings",
      modulePage: true,
    },
    action: requiresLocationAnalyticsScope((_) => _.ratingManager)((params) => {
      return loadAssessment(params.assessmentId).then(
        (assessment) => void loadAssessmentEntries(assessment, "ratings", store.getState().user.scopes.explorer)
      )
    }),
  },

  "/prices": {
    name: NavigationPage.pricesList,
    tag: { app: "locationAnalytics", section: "locationAssessment", module: "prices" },
    action: requiresLocationAnalyticsScope((scopes) => scopes.assessment)(() => {
      void fetchAssessmentList("prices")
      return Promise.resolve()
    }),
  },
  "/prices/:assessmentId/entries/:entryId": {
    name: NavigationPage.prices,
    tag: { app: "locationAnalytics", section: "locationAssessment", module: "prices" },
    action: requiresLocationAnalyticsScope((_) => _.assessment)((params) =>
      openAssessmentModule(params.assessmentId, params.entryId, "prices")
    ),
  },
  "/prices/:assessmentId": {
    name: NavigationPage.pricesDetails,
    tag: {
      app: "locationAnalytics",
      section: "locationAssessment",
      module: "prices",
      modulePage: true,
    },
    action: requiresLocationAnalyticsScope((_) => _.assessment)((params) => {
      void loadAssessment(params.assessmentId).then(
        (assessment) => void loadAssessmentEntries(assessment, "prices", store.getState().user.scopes.explorer)
      )
      return Promise.resolve()
    }),
  },

  "/comparables": {
    name: NavigationPage.comparablesList,
    tag: { app: "locationAnalytics", section: "locationAssessment", module: "comparables" },
    action: requiresLocationAnalyticsScope((scopes) => scopes.comparables)(() => {
      void fetchAssessmentList("comparables")
      return Promise.resolve()
    }),
  },
  "/comparables/:assessmentId/entries/:entryId": {
    name: NavigationPage.comparables,
    tag: { app: "locationAnalytics", section: "locationAssessment", module: "comparables" },
    action: requiresLocationAnalyticsScope((_) => _.comparables)((params) => {
      return openAssessmentModule(params.assessmentId, params.entryId, "comparables")
    }),
  },
  "/comparables/:assessmentId": {
    name: NavigationPage.comparablesDetails,
    tag: {
      app: "locationAnalytics",
      section: "locationAssessment",
      module: "comparables",
      modulePage: true,
    },
    action: requiresLocationAnalyticsScope((_) => _.comparables)((params) => {
      return loadAssessment(params.assessmentId).then(
        (assessment) => void loadAssessmentEntries(assessment, "comparables", store.getState().user.scopes.explorer)
      )
    }),
  },
  "/assessments/:assessmentId": {
    name: NavigationPage.assessmentDetails,
    tag: { app: "locationAnalytics", section: "locationAssessment", module: "assessments" },
    action: requiresLocationAnalyticsScope((_) => _.assessment)((params) => {
      return loadAssessment(params.assessmentId).then(
        (assessment) => void loadAssessmentEntries(assessment, "assessment", store.getState().user.scopes.explorer)
      )
    }),
  },
  "/assessments/:assessmentId/entries/:entryId": {
    name: NavigationPage.assessment,
    tag: {
      app: "locationAnalytics",
      section: "locationAssessment",
      module: "assessments",
      modulePage: true,
    },
    action: requiresLocationAnalyticsScope((_) => _.assessment)((params) => {
      return loadAssessment(params.assessmentId).then((assessment) => {
        store.dispatch(assessmentDispatchActions.assessmentLoadDoneModule({ assessment: assessment }))
        void loadAssessmentEntry(assessment, params.entryId)
        void navigateToAssessment(
          assessment.id,
          params.entryId,
          params.widget ? (params.widget as WidgetsType) : undefined
        )
      })
    }),
  },
  "/districtdata/:assessmentId/entries/:entryId": {
    name: NavigationPage.marketdata,
    tag: {
      app: "locationAnalytics",
      section: "locationAssessment",
      module: "marketdata",
      modulePage: true,
    },
    action: requiresLocationAnalyticsScope((_) => _.marketData)((params) => {
      return openAssessmentModule(params.assessmentId, params.entryId, "marketdata")
    }),
  },
  "/districtdata/:assessmentId": {
    name: NavigationPage.marketdataDetails,
    tag: { app: "locationAnalytics", section: "locationAssessment", module: "marketdata" },
    action: requiresLocationAnalyticsScope((_) => _.marketData)((params) => {
      return loadAssessment(params.assessmentId).then(
        (assessment) => void loadAssessmentEntries(assessment, "marketdata", store.getState().user.scopes.explorer)
      )
    }),
  },
  "/districtdata": {
    name: NavigationPage.marketdataList,
    tag: { app: "locationAnalytics", section: "locationAssessment", module: "marketdata" },
    action: requiresLocationAnalyticsScope((_) => _.marketData)(() => {
      void fetchAssessmentList("marketdata")
      return Promise.resolve()
    }),
  },
  "/dashboard/:assessmentId/entries/:entryId/pdf/:dashboardId": {
    name: NavigationPage.dashboardPdf,
    tag: "printMode",
    action: async (params) => {
      await loadAndHandleUser()
      await loadMyDashboards(params.dashboardId)
      return openAssessmentModule(
        params.assessmentId,
        params.entryId,
        "dashboard",
        true,
        undefined,
        undefined,
        params.dashboardId
      )
    },
  },
  "/dashboard/:assessmentId/entries/:entryId/pdf": {
    name: NavigationPage.dashboardPdf,
    tag: "printMode",
    action: async (params) => {
      await loadAndHandleUser()
      await loadMyDashboards("default")
      return openAssessmentModule(params.assessmentId, params.entryId, "dashboard", true)
    },
  },
  "/dashboard/:assessmentId/entries/:entryId": {
    name: NavigationPage.dashboard,
    tag: {
      app: "locationAnalytics",
      section: "locationAssessment",
      module: "dashboard",
      modulePage: true,
    },
    action: async (params) => {
      await loadMyDashboards("last_selected")
      return openAssessmentModule(params.assessmentId, params.entryId, "dashboard")
    },
  },
  "/dashboard/:assessmentId": {
    name: NavigationPage.dashboardDetails,
    tag: { app: "locationAnalytics", section: "locationAssessment", module: "dashboard" },
    action: (params) => {
      void loadAssessment(params.assessmentId).then(
        (assessment) => void loadAssessmentEntries(assessment, "dashboard", store.getState().user.scopes.explorer)
      )
      return Promise.resolve()
    },
  },
  "/dashboard": {
    name: NavigationPage.dashboardList,
    tag: { app: "locationAnalytics", section: "locationAssessment", module: "dashboard" },
    action: () => {
      void fetchAssessmentList("dashboard")
      return Promise.resolve()
    },
  },
  "/fundamentaldata/:assessmentId/entries/:entryId": {
    name: NavigationPage.fundamentalData,
    tag: {
      app: "locationAnalytics",
      section: "locationAssessment",
      module: "fundamentalData",
      modulePage: true,
    },
    action: async (params) => {
      return openAssessmentModule(params.assessmentId, params.entryId, "fundamentalData")
    },
  },
  "/fundamentaldata/:assessmentId": {
    name: NavigationPage.fundamentalDataDetails,
    tag: { app: "locationAnalytics", section: "locationAssessment", module: "fundamentalData" },
    action: (params) => {
      void loadAssessment(params.assessmentId).then(
        (assessment) => void loadAssessmentEntries(assessment, "fundamentalData", store.getState().user.scopes.explorer)
      )
      return Promise.resolve()
    },
  },
  "/fundamentaldata": {
    name: NavigationPage.fundamentalDataList,
    tag: { app: "locationAnalytics", section: "locationAssessment", module: "fundamentalData" },
    action: () => {
      void fetchAssessmentList("fundamentalData")
      return Promise.resolve()
    },
  },
  "/poiexplorer/:assessmentId/entries/:entryId": {
    name: NavigationPage.poiExplorer,
    tag: {
      app: "locationAnalytics",
      section: "locationAssessment",
      module: "poiExplorer",
      modulePage: true,
    },
    action: requiresLocationAnalyticsScope((_) => _.poiExplorer)((params) => {
      void openAssessmentModule(params.assessmentId, params.entryId, "poiExplorer")
      return Promise.resolve()
    }),
  },
  "/poiexplorer/:assessmentId": {
    name: NavigationPage.poiExplorerDetails,
    tag: { app: "locationAnalytics", section: "locationAssessment", module: "poiExplorer" },
    action: requiresLocationAnalyticsScope((_) => _.poiExplorer)((params) => {
      void loadAssessment(params.assessmentId).then(
        (assessment) => void loadAssessmentEntries(assessment, "poiExplorer", false)
      )
      return Promise.resolve()
    }),
  },
  "/poiexplorer": {
    name: NavigationPage.poiExplorerList,
    tag: { app: "locationAnalytics", section: "locationAssessment", module: "poiExplorer" },
    action: requiresLocationAnalyticsScope((_) => _.poiExplorer)(() => {
      void fetchAssessmentList("poiExplorer")
      return Promise.resolve()
    }),
  },
  "/specialmaps/:assessmentId/entries/:entryId": {
    name: NavigationPage.specialMaps,
    tag: {
      app: "locationAnalytics",
      section: "locationAssessment",
      module: "specialMaps",
      modulePage: true,
    },
    action: requiresLocationAnalyticsScope((_) => _.specialMaps)((params) => {
      void openAssessmentModule(params.assessmentId, params.entryId, "specialMaps")
      return Promise.resolve()
    }),
  },
  "/specialmaps/:assessmentId": {
    name: NavigationPage.specialMapsDetails,
    tag: { app: "locationAnalytics", section: "locationAssessment", module: "specialMaps" },
    action: requiresLocationAnalyticsScope((_) => _.specialMaps)((params) => {
      void loadAssessment(params.assessmentId).then(
        (assessment) => void loadAssessmentEntries(assessment, "specialMaps", false)
      )
      return Promise.resolve()
    }),
  },
  "/specialmaps": {
    name: NavigationPage.specialMapsList,
    tag: { app: "locationAnalytics", section: "locationAssessment", module: "specialMaps" },
    action: requiresLocationAnalyticsScope((_) => _.specialMaps)(() => {
      void fetchAssessmentList("specialMaps")
      return Promise.resolve()
    }),
  },
  "/profiles": {
    name: NavigationPage.profiles,
    tag: { app: "locationAnalytics", section: "profileManager" },
    action: requiresLocationAnalyticsScope((_) => _.profile)(() => {
      return fetchProfiles().then(() => fetchGroups())
    }),
  },
  "/profiles/profile/:profileId": {
    name: NavigationPage.profileEditor,
    tag: { app: "locationAnalytics", section: "profileManager" },
    action: requiresLocationAnalyticsScope((_) => _.profile)((params) => {
      return fetchProfiles().then(() => fetchProfile(params.profileId, "11000000"))
    }),
  },
  "/locationselector": {
    name: NavigationPage.locationSelector,
    tag: { app: "locationAnalytics", section: "locationSelector" },
    action: requiresLocationAnalyticsScope((_) => _.locationSelector)(() => {
      return selectList().then(() => {})
    }),
  },
  "/rating-manager": {
    name: NavigationPage.ratingManager,
    tag: { app: "locationAnalytics", section: "ratingManager" },
    action: requiresLocationAnalyticsScope((_) => _.ratingManager)(() => {
      store.dispatch(assessmentDispatchActions.clearAssessmentState())
      navigateTo({ name: NavigationPage.ratingManager })
      return doLoadRatingList().then(() => {})
    }),
  },
  "/features": {
    name: NavigationPage.features,
    action: navigateAction({ name: NavigationPage.features }),
  },
  "/quickstart": {
    name: NavigationPage.quickstart,
    tag: { app: "locationAnalytics", section: "quickstart" },
    action: navigateAction({ name: NavigationPage.quickstart }),
  },
  "/rentindex": {
    name: NavigationPage.rentindexList,
    tag: { app: "locationAnalytics", section: "locationAssessment", module: "rentindex" },
    action: requiresLocationAnalyticsScope((_) => _.assessment)(() => {
      void fetchAssessmentList("rentindex")
      return Promise.resolve()
    }),
  },
  "/rentindex/info": {
    name: NavigationPage.rentIndexLocationList,
    tag: { app: "locationAnalytics", section: "locationAssessment", module: "rentIndexLocationList" },
    action: navigateAction({ name: NavigationPage.rentIndexLocationList }),
  },
  "/rentindex/:assessmentId": {
    name: NavigationPage.rentindexDetails,
    tag: { app: "locationAnalytics", section: "locationAssessment", module: "rentindex" },
    action: requiresLocationAnalyticsScope((_) => _.assessment)((params) => {
      return loadAssessment(params.assessmentId).then(
        (assessment) => void loadAssessmentEntries(assessment, "rentindex", store.getState().user.scopes.explorer)
      )
    }),
  },
  "/rentindex/:assessmentId/entries/:entryId": {
    name: NavigationPage.rentindex,
    tag: {
      app: "locationAnalytics",
      section: "locationAssessment",
      module: "rentindex",
      modulePage: true,
    },
    action: requiresLocationAnalyticsScope((_) => _.assessment)((params) => {
      return openAssessmentModule(params.assessmentId, params.entryId, "rentindex")
    }),
  },
  "/privatedata": {
    name: NavigationPage.privateData,
    tag: { app: "locationAnalytics", section: "privateData" },
    action: navigateAction({ name: NavigationPage.privateData }),
  },
  "/user/profile": {
    name: NavigationPage.userProfile,
    tag: { app: "account", section: "profile" },
    action: navigateAction({ name: NavigationPage.userProfile }),
  },
  "/user/security": {
    name: NavigationPage.userSecurity,
    tag: { app: "account", section: "security" },
    action: () => {
      navigateTo({
        name: NavigationPage.userSecurity,
        params: { securityPage: "password" },
      })
      return fetchTwoFactorEnabled()
    },
  },
  "/user/2fa": {
    name: NavigationPage.userSecurity,
    tag: { app: "account", section: "security" },
    action: () => {
      navigateTo({
        name: NavigationPage.userSecurity,
        params: { securityPage: "2fa" },
      })
      return fetchTwoFactorEnabled()
    },
  },
  "/company/members": {
    name: NavigationPage.companyAdminMembers,
    tag: { app: "admin", section: "companyUsers" },
    action: () => {
      navigateTo({ name: NavigationPage.companyAdminMembers })
      return fetchMembersList()
    },
  },
  "/company/settings": {
    name: NavigationPage.companyAdminSettings,
    tag: { app: "admin", section: "companySettings" },
    action: () => fetchCompany().then(() => navigateTo({ name: NavigationPage.companyAdminSettings })),
  },
  "/company/products-statistics": {
    name: NavigationPage.companyProductStatistics,
    tag: { app: "admin", section: "products" },
    action: () => {
      navigateTo({ name: NavigationPage.companyProductStatistics })
      void fetchCompanyBudget()
      return fetchAllBillables()
    },
  },
  "/company/products-bookings": {
    name: NavigationPage.companyProductBookings,
    tag: { app: "admin", section: "products" },
    action: () => {
      navigateTo({ name: NavigationPage.companyProductBookings })
      return fetchReadonlyBookings()
    },
  },
  "/company/api-admin": {
    name: NavigationPage.companyApiTokenList,
    tag: { app: "admin", section: "apiAdmin" },
    action: () => {
      navigateTo({ name: NavigationPage.companyApiTokenList })
      return listApiTokens()
    },
  },
  "/login": {
    name: NavigationPage.login,
    tag: "hideMenu",
    action: async () => {
      const queryParams = getQueryParams()
      if ("oauth_token" in queryParams && "oauth_verifier" in queryParams && "state" in queryParams) {
        const result = await loginIS24(queryParams)
        if (result === true) {
          updateUserIsAuthorised(true)
          await fetchAndHandleUser()
          return navigateToDefaultPage()
        } else {
          console.error(result)
          return navigateAction({ name: NavigationPage.is24LoginFailed, params: { error: result } })()
        }
      } else {
        return navigateAction({ name: NavigationPage.login })()
      }
    },
  },
  "/login/invite/:token": {
    name: NavigationPage.loginInviteSetPassword,
    tag: "hideMenu",
    action: (params) => {
      navigateTo({
        name: NavigationPage.loginInviteSetPassword,
        params: { token: params.token },
      })

      return Promise.resolve()
    },
  },
  "/login/forgot-password": {
    name: NavigationPage.loginForgotPasswordRequest,
    tag: "hideMenu",
    action: navigateAction({ name: NavigationPage.loginForgotPasswordRequest }),
  },
  "/login/:token": {
    name: NavigationPage.loginForgotPassword,
    tag: "hideMenu",
    action: (params) => {
      navigateTo({
        name: NavigationPage.loginForgotPassword,
        params: { token: params.token },
      })

      return Promise.resolve()
    },
  },
  "/registration": {
    name: NavigationPage.registrationStepOne,
    tag: "hideMenu",
    action: navigateAction({ name: NavigationPage.registrationStepOne }),
  },
  "/registration/stepTwo/:userId": {
    name: NavigationPage.registrationStepTwo,
    tag: "hideMenu",
    action: (params) => {
      registrationSetUserId(params.userId)

      navigateTo({
        name: NavigationPage.registrationStepTwo,
        params: { userId: params.userId },
      })

      return Promise.resolve()
    },
  },
  "/registration/stepThree/:userId": {
    name: NavigationPage.registrationStepThree,
    tag: "hideMenu",
    action: (params) => {
      registrationSetUserId(params.userId)

      navigateTo({
        name: NavigationPage.registrationStepThree,
        params: { userId: params.userId },
      })

      return Promise.resolve()
    },
  },
  "/registration/verify/email/:token": {
    name: NavigationPage.registrationVerifyEmail,
    tag: "hideMenu",
    action: (params) => {
      registrationStoreEmailToken(params.token)
      navigateTo({
        name: NavigationPage.registrationVerifyEmail,
        params: { token: params.token },
      })

      return Promise.resolve()
    },
  },
  "/registration/complete": {
    name: NavigationPage.registrationComplete,
    tag: "hideMenu",
    action: navigateAction({ name: NavigationPage.registrationComplete }),
  },
  "/help/:section": {
    name: NavigationPage.help,
    tag: { app: "locationAnalytics", section: "help" },
    action: (params) => {
      navigateTo({ name: NavigationPage.help, params: { section: params.section } })
      return Promise.resolve()
    },
  },
}

export const isUnloggedPage = (pageName: AllNavigationLabels) => {
  const unloggedPages = [
    ...Object.entries(routes)
      .filter(([_, { tag }]) => tag === "hideMenu")
      .map(([_, { name }]) => name),
    NavigationPage.is24LoginFailed,
  ]
  return unloggedPages.includes(pageName)
}

export const router = new Router(routes, (routeName, params, tag) => {
  if (tag) {
    // Login redirect
    if (tag !== "hideMenu" && !authCookieExists()) {
      hideMenu(true)
      navigateTo({ name: NavigationPage.login })
      return false
    }

    menuSetActiveAppLocation(tag)
  }
  return true
})

function menuLocationToNavigationPageName<A extends App>(
  location: AppLocationForApp<A, Apps[A]>,
  params: object
): NavigationPageData["name"] | undefined {
  if (location.app == "locationAnalytics") {
    switch (location.section) {
      case "profileManager":
        return "profiles"

      case "locationSelector":
        return "locationSelector"

      case "privateData":
        return "privateData"

      case "quickstart":
        return "quickstart"

      case "ratingManager":
        return "ratingManager"

      case "locationAssessment":
        if (location.module) {
          // Cross-module navigation will keep params changing the module only
          if ("assessmentId" in params && "entryId" in params) {
            return `${location.module}` as ModulesForSubmenu<"locationAnalytics", "locationAssessment">
          } else if ("assessmentId" in params) {
            return `${location.module}Details` as ModulesForSubmenu<"locationAnalytics", "locationAssessment">
          } else if (location.module === "rentIndexLocationList") {
            return "rentIndexLocationList"
          } else {
            return `${location.module}List` as ModulesForSubmenu<"locationAnalytics", "locationAssessment">
          }
        } else {
          // first default available module
          const lanaScopes = store.getState().user.scopes
          // Quickstart is excluded as from proper Section default pages
          const navigateTo = allowedToNavigateWithScopes(lanaScopes).filter((x) => x != NavigationPage.quickstart)
          if (navigateTo.length) {
            return navigateTo[0]
          } else {
            console.error("moduleToPageName - unknown module in locationAnalytics: ", location, params)
            return undefined
          }
        }

      default:
        console.error("moduleToPageName - unknown section in locationAnalytics: ", location, params)
        return undefined
    }
  } else if (location.app === "admin") {
    switch (location.section) {
      case "products":
        return "companyProductStatistics"
      case "companyUsers":
        return "companyAdminMembers"
      case "companySettings":
        return "companyAdminSettings"
      case "apiAdmin":
        return "companyApiTokenList"
      default:
        console.error("moduleToPageName - unknown section in admin: ", location, params)
        return undefined
    }
  } else if (location.app === "account") {
    switch (location.section) {
      case "profile":
        return "userProfile"
      case "security":
        return "userSecurity"
      case "login":
        return "login"
      default:
        console.error("moduleToPageName - unknown section in account: ", location, params)
        return undefined
    }
  } else {
    console.error("moduleToPageName - unknown app: ", location, params)
    return undefined
  }
}

export function doChangeModule(module: AppLocation, currentPage?: NavigationPageData) {
  const currentPageParams = currentPage && "params" in currentPage ? { ...currentPage.params } : {}
  const pageName = menuLocationToNavigationPageName(module, currentPageParams)
  if (pageName) {
    let paramsObj: { [x: string]: string } = currentPageParams || {}
    if ("module" in module && module.module) {
      paramsObj.module = module.module
    }

    router.navigateTo(pageName, paramsObj)
  }
}
