import { WidgetsType } from "../../assessment/models/assessment"
import { SecurityPage } from "../../user-account/components/userAccountSecurity"
import { AppModules } from "../../menu/util/app-location-types"

export const NavigationPageLabelValues = [
  // LANA UI
  "landing",
  "profiles",
  "profileEditor",
  "assessmentList",
  "assessmentDetails",
  "assessment",
  "comparables",
  "comparablesList",
  "comparablesDetails",
  "marketdata",
  "marketdataList",
  "marketdataDetails",
  "fundamentalData",
  "fundamentalDataList",
  "fundamentalDataDetails",
  "features",
  "quickstart",
  "dashboard",
  "dashboardPdf",
  "dashboardList",
  "dashboardDetails",
  "poiExplorer",
  "poiExplorerList",
  "poiExplorerDetails",
  "specialMaps",
  "specialMapsList",
  "specialMapsDetails",
  "rentindex",
  "rentindexList",
  "rentindexDetails",
  "privateData",
  "locationSelector",
  "ratingManager",
  "ratingManagerEdit",
  "ratings",
  "ratingsDetails",
  "ratingsList",
  "prices",
  "pricesDetails",
  "pricesList",
  "rentIndexLocationList",

  // User profile management
  "userProfile",
  "userSecurity",
  // Company admin pages
  "companyAdminSettings",
  "companyAdminMembers",
  // Company admin - product invoice service
  "companyProductStatistics",
  "companyProductBookings",
  "companyApiTokenList",

  "login",
  "is24LoginFailed",
  "loginInviteSetPassword",
  "loginForgotPassword",

  "loginForgotPasswordRequest",

  // Registration pages
  "registrationStepOne",
  "registrationStepTwo",
  "registrationStepThree",
  "registrationVerifyEmail",
  "registrationComplete",
  "help",
] as const

export type AllNavigationLabels = (typeof NavigationPageLabelValues)[number] | ""

type AllNavigationLabelKeyValues = {
  [P in AllNavigationLabels]: P
}

export const NavigationPage: AllNavigationLabelKeyValues = NavigationPageLabelValues.reduce(
  (acc, curr) => ({ ...acc, [curr]: curr }),
  {} as AllNavigationLabelKeyValues
)

type LocationAnalyticsAssessmentDetailPageNames =
  | AppModules["locationAssessment"]
  | "dashboard"
  | "poiExplorer"
  | "rentindex"

export type NavigationAssessmentDetailsPageData<
  X extends LocationAnalyticsAssessmentDetailPageNames = LocationAnalyticsAssessmentDetailPageNames
> = {
  name: `${X}Details`
  params: {
    assessmentId: string
  }
}

type NavigationAssessmentListPageData = {
  name: `${LocationAnalyticsAssessmentDetailPageNames}List`
  module?: string
}

export type NavigationPageData =
  | {
      name: typeof NavigationPage.comparables
      params: {
        assessmentId: string
        entryId: string | null
      }
    }
  | {
      name: typeof NavigationPage.assessment
      params: {
        assessmentId: string
        entryId: string | null
        widget?: WidgetsType
      }
    }
  | NavigationAssessmentListPageData
  | NavigationAssessmentDetailsPageData
  | {
      name: typeof NavigationPage.profileEditor
      params: {
        profileId: string
      }
    }
  | {
      name: typeof NavigationPage.profiles
    }
  | {
      name: typeof NavigationPage.marketdata
      params: {
        assessmentId: string
        entryId: string | null
      }
    }
  | {
      name: typeof NavigationPage.fundamentalData
      params: {
        assessmentId: string
        entryId: string | null
      }
    }
  | {
      name: typeof NavigationPage.dashboard
      params: {
        assessmentId: string
        entryId: string | null
      }
    }
  | {
      name: typeof NavigationPage.dashboardPdf
      params: {
        assessmentId: string
        entryId: string | null
        pdf: true
        dashboardId?: string
      }
    }
  | {
      name: typeof NavigationPage.poiExplorer
      params: {
        assessmentId: string
        entryId: string | null
      }
    }
  | {
      name: typeof NavigationPage.features
    }
  | {
      name: typeof NavigationPage.quickstart
    }
  | {
      name: typeof NavigationPage.rentindex
      params: {
        assessmentId: string
        entryId: string | null
      }
    }
  | {
      name: typeof NavigationPage.specialMaps
      params: {
        assessmentId: string
        entryId: string | null
      }
    }
  | {
      name: typeof NavigationPage.privateData
    }
  | {
      name: typeof NavigationPage.locationSelector
    }
  | {
      name: typeof NavigationPage.ratingManager
    }
  | {
      name: typeof NavigationPage.userProfile
    }
  | {
      name: typeof NavigationPage.userSecurity
      params: {
        securityPage: SecurityPage
      }
    }
  | {
      name: typeof NavigationPage.companyAdminSettings
    }
  | {
      name: typeof NavigationPage.companyAdminMembers
    }
  | {
      name: typeof NavigationPage.companyProductStatistics
    }
  | {
      name: typeof NavigationPage.companyProductBookings
    }
  | {
      name: typeof NavigationPage.companyApiTokenList
    }
  | {
      name: typeof NavigationPage.login
    }
  | {
      name: typeof NavigationPage.is24LoginFailed
      params: {
        error: string
      }
    }
  | {
      name: typeof NavigationPage.registrationStepOne
    }
  | {
      name: typeof NavigationPage.registrationStepTwo
      params: {
        userId: string
      }
    }
  | {
      name: typeof NavigationPage.registrationStepThree
      params: {
        userId: string
      }
    }
  | {
      name: typeof NavigationPage.registrationVerifyEmail
      params: {
        token: string
      }
    }
  | {
      name: typeof NavigationPage.registrationComplete
    }
  | {
      name: typeof NavigationPage.ratings
      params: {
        assessmentId: string
        entryId: string | null
      }
    }
  | {
      name: typeof NavigationPage.prices
      params: {
        assessmentId: string
        entryId: string | null
        widget?: WidgetsType
      }
    }
  | {
      name: typeof NavigationPage.loginInviteSetPassword
      params: {
        token: string
      }
    }
  | {
      name: typeof NavigationPage.loginForgotPassword
      params: {
        token: string
      }
    }
  | {
      name: typeof NavigationPage.loginForgotPasswordRequest
    }
  | {
      name: typeof NavigationPage.rentIndexLocationList
    }
  | {
      name: typeof NavigationPage.help
      params: {
        section: string
      }
    }
