import * as React from "react"
import { css, cx } from "emotion"
import { PropsWithChildren } from "react"
import { BackgroundColor, BrandingColor, ColorType, UIState } from "../util/colorconstants"
import { ComponentSize } from "../util/layoutconstants"
import { getThemeColorVar } from "../helper/color"
import { EmotionCSS } from "../helper/responsive"

type ColorCombinations =
  | {
      color: UIState
      colorModifier?: ColorType<UIState>
    }
  | {
      color: BackgroundColor
      colorModifier?: ColorType<BackgroundColor>
    }
  | {
      color: BrandingColor
      colorModifier?: ColorType<BrandingColor>
    }
  | {
      color: "transparent"
      colorModifier?: undefined
    }
  | {
      color?: undefined
      colorModifier?: undefined
    }

export type BadgeProps = {
  size?: ComponentSize
} & ColorCombinations

const badgeClass = css({
  color: getThemeColorVar("white", undefined),
  backgroundColor: getThemeColorVar("black", undefined),
  display: "inline-block",
  height: "20px",
  lineHeight: "20px",
  borderRadius: "10px",
  padding: "0 10px",
  border: "none",
  cursor: "default",
  boxSizing: "border-box",
  textAlign: "center",
  verticalAlign: "baseline",
  whiteSpace: "nowrap",
  userSelect: "none",
  fontSize: "13px",
  fontFamily: "inherit",
  fontWeight: "bold",
})

const badgeLargeClass = css({
  height: "24px",
  lineHeight: "24px",
  borderRadius: "12px",
  padding: "0 12px",
  fontSize: "16px",
  verticalAlign: "baseline",
  fontWeight: "normal",
})

export const Badge = (props: PropsWithChildren<BadgeProps>) => {
  const classes: string[] = [badgeClass]

  const emotionStyles: EmotionCSS[] = []
  if (props.color) {
    if (props.color == "transparent") {
      emotionStyles.push({ backgroundColor: "rgba(200, 200, 200, .4)" })
    } else {
      emotionStyles.push({
        backgroundColor: `var(--revo-color-${props.color}-${props.colorModifier || "default"})`,
      })
    }
  }

  classes.push(css(emotionStyles))

  // only handle "large". "small" is kept for backwards compatibility
  if (props.size && props.size === "large") {
    classes.push(badgeLargeClass)
  }

  return <span className={cx(classes)}>{props.children}</span>
}

export default Badge
