import { PrivatePOI } from "../../private-data/models/private-data"
import { COLORS } from "./ui/colors"
import { Grid } from "./ui/grid"
import React from "react"
import { css } from "emotion"
import { AssessmentEntry } from "../../assessment/models/assessment"
import { translations } from "../i18n"

const correctedHighlightStyle = css({
  color: COLORS.positive.default,
  fontWeight: "bold",
})

type ExpectedEntryTypes = AssessmentEntry | PrivatePOI
type AddressFields = "postalCode" | "locality" | "route" | "streetNumber"

interface Props {
  entry: ExpectedEntryTypes
  typeToUse: "poi" | "assessment"
}
export const CorrectedAddressTooltip = ({ entry, typeToUse }: Props) => {
  const t = React.useMemo(translations, [translations])
  let localEntry = entry

  if (typeToUse === "poi") {
    localEntry = entry as PrivatePOI
  } else {
    localEntry = entry as AssessmentEntry
  }
  const fieldOfAddressRender = (field: AddressFields) => {
    if (!localEntry.originalAddress) {
      return <></>
    }
    const correctedField = localEntry.address[field]
    const originalField = localEntry.originalAddress[field]

    if (correctedField?.toLocaleLowerCase() === originalField?.toLocaleLowerCase()) {
      return (
        <>
          <div>{originalField ?? ""}</div>
          <div>{correctedField ?? ""}</div>
        </>
      )
    }

    return (
      <>
        <div>{originalField ?? ""}</div>
        <div className={correctedHighlightStyle}>{correctedField ?? `[${t.addressChangedTooltip.removed}]`}</div>
      </>
    )
  }

  return (
    <div style={{ maxWidth: "350px" }}>
      <div style={{ padding: "4px 4px 8px 4px" }}>{t.addressChangedTooltip.notificationTitle}</div>
      <hr style={{ marginLeft: "-8px", marginRight: "-8px" }} />
      <Grid columns={3} columnSpec={"1fr 2fr 2fr"} gap={12} padding={8}>
        <div></div>
        <div>
          <b>{t.addressChangedTooltip.originalAddress}</b>
        </div>
        <div>
          <b>{t.addressChangedTooltip.correctedAddress}</b>
        </div>
        <div>
          <b>{t.addressColumn["postalCode"]}</b>
        </div>
        {fieldOfAddressRender("postalCode")}
        <div>
          <b>{t.addressColumn["locality"]}</b>
        </div>
        {fieldOfAddressRender("locality")}
        <div>
          <b>{t.addressColumn["route"]}</b>
        </div>
        {fieldOfAddressRender("route")}
        <div>
          <b>{t.addressColumn["streetNumber"]}</b>
        </div>
        {fieldOfAddressRender("streetNumber")}
      </Grid>
    </div>
  )
}
