import * as React from "react"
import { IconName } from "@21re/revoicons/dist/icon-type"
import { bind } from "decko"
import { css } from "emotion"
import { PropsWithChildren } from "react"
import Checkbox from "./checkbox"
import ContentPortal from "./layout/contentportal"
import Grid from "./restyle-grid/grid"
import GridItem from "./restyle-grid/griditem"
import FlexContainer from "./restyle-grid/flexcontainer"
import Button from "./button"
import { getThemeColorVar } from "../helper/color"

const alertBoxOuterClass = css({
  position: "fixed",
  top: "50%",
  left: "50%",
  right: 0,
  zIndex: 10000,
  width: "660px",
  transform: "translate(-50%, -50%)",
  backgroundColor: getThemeColorVar("background", "default"),
  borderRadius: "0px",
  boxShadow: "0px 6px 32px 0px rgba(0, 0, 0, 0.21)",
})

const alertBoxContentClass = css({
  padding: "54px 68px",
})

const alertBoxOverlayClass = css({
  zIndex: 1,
  position: "fixed",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  overflow: "hidden",
  backgroundColor: getThemeColorVar("background", "darker"),
  opacity: 0.2,
})

function alertBoxHeaderClass(danger?: boolean): string {
  return css({
    fontSize: "20px",
    fontWeight: "bold",
    height: "26px",
    lineHeight: "26px",
    color: danger ? getThemeColorVar("negative", "default") : getThemeColorVar("typo", "default"),
    marginBottom: "24px",
  })
}

export interface AlertBoxAction {
  label: string
  icon?: IconName
  action: (checked?: boolean) => void
  type?: "primary" | "secondary" | "tertiary"
}

export interface CheckboxAction {
  label: string
  action?: (checked: boolean) => void
}

export interface AlertBoxProps {
  onClose: () => void
  header: string
  actions: AlertBoxAction[]
  danger?: boolean
  checkbox?: CheckboxAction
}

interface State {
  checked: boolean
}

export class AlertBox extends React.Component<PropsWithChildren<AlertBoxProps>, State> {
  constructor(props: AlertBoxProps) {
    super(props)
    this.state = {
      checked: false,
    }
  }

  render() {
    const { danger, header, children, actions, checkbox } = this.props
    return (
      <ContentPortal>
        <div className={alertBoxOuterClass}>
          <Grid columns={1}>
            <div className={alertBoxContentClass}>
              <div className={alertBoxHeaderClass(danger)}>{header}</div>
              <div>{children}</div>
            </div>
            <Grid columns={2} columnSpec={"auto 1fr"}>
              {checkbox ? (
                <GridItem
                  padding={16}
                  backgroundColor={{
                    color: "background",
                    colorType: "lighter",
                  }}
                  alignSelf={"start"}
                >
                  <Checkbox label={checkbox.label} onChange={this.onCheckboxChange} />
                </GridItem>
              ) : (
                <div />
              )}
              <FlexContainer
                direction="row-reverse"
                padding={[16, 24]}
                gap={16}
                backgroundColor={{ color: "background", colorType: "lighter" }}
              >
                {actions.map((action, idx) => (
                  <Button
                    key={idx}
                    type={action.type || (idx == 0 ? "primary" : "secondary")}
                    icon={action.icon}
                    onClick={() => action.action(checkbox ? this.state.checked : undefined)}
                    danger={danger}
                  >
                    {action.label}
                  </Button>
                ))}
              </FlexContainer>
            </Grid>
          </Grid>
        </div>
        <div className={alertBoxOverlayClass} onClick={this.handleClose} />
      </ContentPortal>
    )
  }

  @bind
  private onCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const checked = event.target.checked
    this.setState({ checked: checked })

    this.props.checkbox && this.props.checkbox.action && this.props.checkbox.action(checked)
  }

  @bind
  private handleClose(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    event.preventDefault()

    this.props.onClose && this.props.onClose()
  }
}
