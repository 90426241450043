import * as React from "react"
import { Manager, Popper, Reference } from "react-popper"
import * as PopperJS from "popper.js"
import { cx, css } from "emotion"
import { bind } from "decko"
import { createPortal } from "react-dom"
import { PropsWithChildren } from "react"
import { getThemeColorVar } from "../../helper/color"

export interface TooltipProps {
  placement: PopperJS.Placement
  tooltip: string | JSX.Element
  onOpen?: () => void
  inverted?: boolean
  newDesign?: boolean
  withPointer?: boolean
  hideArrow?: boolean
}

const referenceClassWithPointer = css({
  cursor: "pointer",
})

const tooltipClass = css({
  background: getThemeColorVar("background", "dark"),
  color: getThemeColorVar("white", undefined),
  fontSize: "12px",
  lineHeight: "16px",
  margin: "6px",
  padding: "8px",
  borderRadius: "3px",
  zIndex: 11000,
})

const tooltipInvertedClass = css({
  background: getThemeColorVar("background", "default"),
  color: getThemeColorVar("black", undefined),
  fontSize: "12px",
  lineHeight: "16px",
  margin: "6px",
  padding: "8px",
  border: "1px solid",
  borderColor: getThemeColorVar("border", "default"),
  borderRadius: "3px",
  zIndex: 11000,
  boxShadow: "0px 3px 16px 0px rgba(0, 0, 0, 0.5)",
})

const tooltipNewDesignClass = css({
  background: getThemeColorVar("background", "default"),
  color: getThemeColorVar("black", undefined),
  fontSize: "12px",
  lineHeight: "16px",
  margin: "6px",
  padding: "8px",
  border: "1px solid",
  borderColor: getThemeColorVar("primary", "default"),
  borderLeft: `4px solid ${getThemeColorVar("primary", "default")}`,
  zIndex: 11000,
  boxShadow: "none",
})

const arrowClass = css({
  position: "absolute",
  height: "5px",
  width: "5px",
  borderWidth: "5px",
  borderStyle: "solid",
})

const arrowRightClass = css({
  left: 0,
  marginLeft: "-10px",
  borderColor: `transparent ${getThemeColorVar("background", "dark")} transparent transparent`,
})

const redesignedArrowRightClass = css({
  left: 0,
  marginLeft: "-14px",
  borderColor: `transparent ${getThemeColorVar("primary", "default")} transparent transparent`,
})

const arrowTopClass = css({
  bottom: 0,
  marginBottom: "-10px",
  borderColor: `${getThemeColorVar("background", "dark")} transparent transparent transparent`,
})

const arrowLeftClass = css({
  right: 0,
  marginRight: "-10px",
  borderColor: `transparent transparent transparent ${getThemeColorVar("background", "dark")}`,
})

const arrowBottomClass = css({
  top: 0,
  marginTop: "-10px",
  borderColor: `transparent transparent ${getThemeColorVar("background", "dark")} transparent`,
})

function arrowClassForPlacement(placement: PopperJS.Placement, newDesign: boolean | undefined): string {
  switch (placement) {
    case "right":
    case "right-start":
    case "right-end":
      return cx(arrowClass, newDesign ? redesignedArrowRightClass : arrowRightClass)
    case "top":
    case "top-start":
    case "top-end":
      return cx(arrowClass, arrowTopClass)
    case "left":
    case "left-start":
    case "left-end":
      return cx(arrowClass, arrowLeftClass)
    case "bottom":
    case "bottom-start":
    case "bottom-end":
      return cx(arrowClass, arrowBottomClass)
    default:
      return arrowClass
  }
}

function offsetForPlacement(placement: PopperJS.Placement): string {
  switch (placement) {
    case "bottom-start":
    case "top-start":
    case "right-start":
    case "left-start":
    case "auto-start":
      return "-9px"
    case "bottom-end":
    case "top-end":
    case "right-end":
    case "left-end":
    case "auto-end":
      return "9px"
    default:
      return "0px"
  }
}

const SHOW_DELAY = 400
const HIDE_DELAY = 500

interface ComponentState {
  show: boolean
}

class RootPortal extends React.Component<PropsWithChildren<{}>, {}> {
  private portalNode: Element | null = null
  private rootNode = document.body

  componentWillUnmount() {
    if (this.portalNode) {
      this.rootNode && this.rootNode.removeChild(this.portalNode)
    }
    this.portalNode = null
  }

  render(): React.ReactPortal {
    if (!this.portalNode) {
      this.portalNode = document.createElement("div")
      this.rootNode && this.rootNode.appendChild(this.portalNode)
    }
    return createPortal(this.props.children, this.portalNode)
  }
}

export class Tooltip extends React.Component<PropsWithChildren<TooltipProps>, ComponentState> {
  private timerHandle: number | null = null

  constructor(props: TooltipProps) {
    super(props)

    this.state = {
      show: false,
    }
  }

  render() {
    const { children, placement, tooltip } = this.props
    const { show } = this.state

    const getClassname = () => {
      if (this.props.newDesign) return tooltipNewDesignClass
      return tooltipInvertedClass ? tooltipInvertedClass : tooltipClass
    }

    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <span
              ref={ref}
              className={this.props.withPointer ? referenceClassWithPointer : ""}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
            >
              {children}
            </span>
          )}
        </Reference>
        {show && (
          <RootPortal>
            <Popper
              placement={placement}
              modifiers={{
                flip: {
                  boundariesElement: "window",
                },
                preventOverflow: {
                  boundariesElement: "window",
                },
                offset: {
                  offset: offsetForPlacement(placement),
                },
                computeStyle: {
                  gpuAcceleration: true,
                },
              }}
            >
              {({ placement, ref, style, arrowProps }) => (
                <div
                  className={getClassname()}
                  ref={ref}
                  style={style}
                  onMouseEnter={this.onMouseEnter}
                  onMouseLeave={this.onMouseLeave}
                  onMouseDown={(ev) => {
                    ev.stopPropagation()
                  }}
                >
                  {tooltip}
                  {!this.props.hideArrow && (
                    <div
                      className={arrowClassForPlacement(placement, this.props.newDesign)}
                      ref={arrowProps.ref}
                      style={arrowProps.style}
                    />
                  )}
                </div>
              )}
            </Popper>
          </RootPortal>
        )}
      </Manager>
    )
  }

  componentWillUnmount() {
    this.timerHandle && window.clearTimeout(this.timerHandle)
  }

  @bind
  private onMouseEnter() {
    this.timerHandle && window.clearTimeout(this.timerHandle)
    this.timerHandle = window.setTimeout(() => {
      this.setState({ show: true })
      this.props.onOpen && this.props.onOpen()
      this.timerHandle = null
    }, SHOW_DELAY)
  }

  @bind
  private onMouseLeave() {
    this.timerHandle && window.clearTimeout(this.timerHandle)
    this.timerHandle = window.setTimeout(() => {
      this.setState({ show: false })
      this.timerHandle = null
    }, HIDE_DELAY)
  }
}
