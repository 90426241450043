import React from "react"
import { Grid } from "../../shared/components/ui/grid"
import { css } from "emotion"
import { Icon } from "@blueprintjs/core"
import { Tooltip } from "./ui/tooltip"
import { getThemeColorVar } from "../helper/color"

const styles = {
  shrinkableText: (hasOverFLowingElements: boolean) =>
    css({
      textOverflow: hasOverFLowingElements ? "ellipsis" : undefined,
      overflow: "hidden",
      whiteSpace: "nowrap",
    }),
  formattedTextContainer: (hasOverFLowingElements: boolean) =>
    css({
      fontFamily: "monospace",
      backgroundColor: "white",
      color: "black",
      maxWidth: "350px",
      maxHeight: "240px",
      minWidth: "160px",
      overflow: hasOverFLowingElements ? "scroll" : undefined,
      padding: hasOverFLowingElements ? "8px 20px 8px 8px" : "8px 8px",
    }),
  formattedText: css({
    whiteSpace: "pre-line",
  }),
}

interface Props {
  data: string
}
export const TruncatedTextCell = (props: Props) => {
  const [isIconHovered, setIsIconHovered] = React.useState(false)
  const [cell, setCell] = React.useState<HTMLSpanElement | null>(null)
  const [tooltip, setTooltip] = React.useState<HTMLDivElement | null>(null)

  React.useEffect(
    () => () => {
      setIsIconHovered(false)
      setCell(null)
      setTooltip(null)
    },
    []
  )

  const getIconColor = (): string => {
    if (isIconHovered) return getThemeColorVar("black", undefined)
    else return getThemeColorVar("disabled", "default")
  }
  const mouseOver = () => setIsIconHovered(true)
  const mouseOut = () => setIsIconHovered(false)

  const getIsContentOverflowing = (ref: "cell" | "tooltip"): boolean => {
    const refElement = ref === "cell" ? cell : tooltip
    if (refElement) {
      const { offsetWidth, offsetHeight, scrollHeight, scrollWidth } = refElement
      return offsetHeight < scrollHeight || offsetWidth < scrollWidth
    }
    return false
  }

  const renderFormattedTooltip = (data: string): JSX.Element => {
    const contentIsOverflowing = getIsContentOverflowing("tooltip")
    return (
      <div className={styles.formattedTextContainer(contentIsOverflowing)} ref={(e) => setTooltip(e)}>
        <p className={styles.formattedText}>{data}</p>
      </div>
    )
  }
  return (
    <Grid columns={2} columnSpec={"1fr min-content"}>
      <span className={styles.shrinkableText(getIsContentOverflowing("cell"))} ref={(e) => setCell(e)}>
        {props.data}
      </span>
      {props.data && getIsContentOverflowing("cell") && (
        <Tooltip placement={"bottom"} tooltip={renderFormattedTooltip(props.data)} inverted={true}>
          <Icon icon={"more"} color={getIconColor()} onMouseOver={mouseOver} onMouseOut={mouseOut} />
        </Tooltip>
      )}
    </Grid>
  )
}
