import React, { useEffect, useState } from "react"
import { translations } from "../../i18n"
import { Bar } from "react-chartjs-2"
import { ChartData, ChartOptions } from "chart.js"
import { focusAreaColor, referenceAreaColor } from "../district-data/district-data-analysis"
import { cx } from "emotion"
import { widgetStyles } from "./dashboard-widget"
import { AssessmentEntryFull } from "../../models/assessment"
import Axios, { AxiosResponse } from "axios"
import { lanaApiUrl } from "../../../app_config"
import { Tooltip } from "../../../shared/components/ui/tooltip"
import { DashboardEditMode } from "./dashboard-widget-grid"
import Grid from "../../../shared/components/restyle-grid/grid"
import Icon from "../../../shared/components/icon"
import { FundamentalDataQuery, WithDataLevel } from "../../reducers/fundamental-data-slice"

interface Props {
  onHeaderClick: () => void
  onRemoveClick: () => void
  htmlId?: string
  printMode: boolean
  editMode: DashboardEditMode
  assessmentEntry: AssessmentEntryFull
  onRenderingDone: () => void
}

const PopulationWidget = ({
  onHeaderClick,
  onRemoveClick,
  htmlId,
  printMode,
  editMode,
  assessmentEntry,
  onRenderingDone,
}: Props) => {
  const t = React.useMemo(translations, [translations])

  const [population, setPopulation] = useState<WithDataLevel<{ [key in string]: number }>>()

  const labels = ["0-9", "10-19", "20-29", "30-39", "40-49", "50-59", "60-74", "≥ 75"]
  const zipBuckets = [0, 0, 0, 0, 0, 0, 0, 0]
  const municipalityBuckets = [0, 0, 0, 0, 0, 0, 0, 0]

  const addToBucket = (bucketNumber: number, zipValue?: number, municipalityValue?: number) => {
    zipBuckets[bucketNumber] = zipBuckets[bucketNumber] + (zipValue ? (zipValue * 100) / zipPopulation : 0)
    municipalityBuckets[bucketNumber] =
      municipalityBuckets[bucketNumber] + (municipalityValue ? (municipalityValue * 100) / municipalityPopulation : 0)
  }

  const zipPopulation = population?.zip["ew"] ?? 0
  const municipalityPopulation = population?.municipality["ew"] ?? 0

  const populationWidgetDataSet = new Set([
    "ew_alter_75m",
    "ew_alter_10_15",
    "ew_alter_10_15",
    "ew_alter_18_20",
    "ew_alter_20_25",
    "ew_alter_25_30",
    "ew_alter_03_06",
    "ew_alter_30_35",
    "ew_alter_35_40",
    "ew_alter_40_45",
    "ew_alter_45_50",
    "ew_alter_50_55",
    "ew_alter_55_60",
    "ew_alter_06_10",
    "ew_alter_60_65",
    "ew_alter_65_75",
    "ew_alter_00_03",
    "ew",
  ])

  const fetchData = async () => {
    if (assessmentEntry.address.postalCode && assessmentEntry.agsId) {
      const postCode = assessmentEntry.postcodeId
        ? assessmentEntry.postcodeId.toString()
        : assessmentEntry.address.postalCode

      const zipData: FundamentalDataQuery = {
        zip: {
          selectedTiles: [postCode],
          selectedDataNames: Array.from(populationWidgetDataSet),
        },
        municipality: {
          selectedTiles: [assessmentEntry.agsId.toString()],
          selectedDataNames: Array.from(populationWidgetDataSet),
        },
        country: {
          selectedTiles: [],
          selectedDataNames: [],
        },
      }

      void Axios.post(`${lanaApiUrl}/api/fundamental_data/scores`, zipData)
        .then(
          (
            success: AxiosResponse<{
              scores: WithDataLevel<{ [key in string]: number }>
            }>
          ) => {
            setPopulation(success.data.scores)
          }
        )
        .catch((e) => {
          console.error(e)
        })
    }
  }

  useEffect(() => {
    fetchData().finally(onRenderingDone)
  }, [assessmentEntry, assessmentEntry.address])

  populationWidgetDataSet.forEach((scoreName) => {
    switch (scoreName) {
      case "ew_alter_00_03":
      case "ew_alter_03_06":
      case "ew_alter_06_10": {
        addToBucket(0, population?.zip[scoreName], population?.municipality[scoreName])
        break
      }
      case "ew_alter_10_15":
      case "ew_alter_15_18":
      case "ew_alter_18_20": {
        addToBucket(1, population?.zip[scoreName], population?.municipality[scoreName])
      }
      case "ew_alter_20_25":
      case "ew_alter_25_30": {
        addToBucket(2, population?.zip[scoreName], population?.municipality[scoreName])
        break
      }
      case "ew_alter_30_35":
      case "ew_alter_35_40": {
        addToBucket(3, population?.zip[scoreName], population?.municipality[scoreName])
        break
      }
      case "ew_alter_40_45":
      case "ew_alter_45_50": {
        addToBucket(4, population?.zip[scoreName], population?.municipality[scoreName])
        break
      }
      case "ew_alter_50_55":
      case "ew_alter_55_60": {
        addToBucket(5, population?.zip[scoreName], population?.municipality[scoreName])
        break
      }
      case "ew_alter_60_65":
      case "ew_alter_65_75": {
        addToBucket(6, population?.zip[scoreName], population?.municipality[scoreName])
        break
      }
      case "ew_alter_75m": {
        addToBucket(7, population?.zip[scoreName], population?.municipality[scoreName])
        break
      }
    }
  })

  const chartData: ChartData<"bar"> = {
    labels: labels,
    datasets: [
      {
        label: t.dashboard.districtData.zip,
        data: zipBuckets,
        backgroundColor: focusAreaColor,
        barPercentage: 1,
        categoryPercentage: 0.5,
      },
      {
        label: t.dashboard.districtData.municipality,
        data: municipalityBuckets,
        backgroundColor: referenceAreaColor,
        barPercentage: 1,
        categoryPercentage: 0.5,
      },
    ],
  }
  const chartOptions: ChartOptions<"bar"> = {
    animation: false,
    scales: {
      y: {
        grid: { display: false },
        ticks: {
          display: true,
          callback: (value) => {
            const numValue = typeof value === "number" ? value : parseFloat(value)
            if (isNaN(numValue)) return value
            return `${Math.round(numValue)} %`
          },
        },
      },
      x: {
        type: "category",
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: (item) => `${Math.round((item.raw as number) * 100) / 100} %`,
        },
      },
    },
  }

  const onRemove = (e: React.MouseEvent) => {
    if (editMode) {
      e.stopPropagation()
      onRemoveClick && onRemoveClick()
    }
  }

  return (
    <div
      className={cx(
        "dashboard-widget",
        editMode && "edit-mode",
        editMode && "edit-mode-" + editMode,
        printMode && widgetStyles.widgetPrintMode
      )}
      id={htmlId}
    >
      <Grid columns={1} rowSpec={"min-content 1fr"} height={[100, "%"]}>
        <div
          style={{ display: "flex" }}
          className={cx("dashboard-widget-header", editMode && "edit-mode")}
          onClick={(e) => {
            if (!editMode) {
              e.stopPropagation()
              onHeaderClick && onHeaderClick()
            }
          }}
        >
          <b>{t.dashboard.population.header}</b>
          {editMode && !printMode && (
            <div className={`notDraggable widget-edit-button remove-button`}>
              <Tooltip tooltip={t.dashboard.remove} placement={"bottom"} hideArrow={true}>
                <span onClick={onRemove}>
                  <Icon name={"remove"} fontSize={16} />
                </span>
              </Tooltip>
            </div>
          )}
        </div>
        <div className="canvas-container">
          <Bar data={chartData} options={chartOptions} />
        </div>
      </Grid>
    </div>
  )
}

export default PopulationWidget
