import { PerSqmOrTotal, PriceRange } from "../models/assessment"
import { GraphScale } from "../models/graph-scale"
import { ResizeSensor } from "@blueprintjs/core"
import * as React from "react"
import { useState } from "react"
import { translations } from "../i18n"
import { css } from "emotion"
import { COLORS } from "../../shared/components/ui/colors"
import { Flex } from "../../shared/components/ui/flex"
import { ReactCanvas } from "../../shared/components/ui/react-canvas"
import { ResizeObserverEntry } from "@juggle/resize-observer"
import GridItem from "../../shared/components/restyle-grid/griditem"
import Grid from "../../shared/components/restyle-grid/grid"
import LoadingSpinner from "../../shared/components/loadingspinner"
import { formatNumber } from "../../shared/helper/number-format"

const barGraphLabelTextClass = css({
  whiteSpace: "nowrap",
})

const svgWrapperClass = css({ overflow: "hidden" })

interface Props {
  label: string
  labelWidth?: string
  color: string
  colorLabel?: boolean
  data: PriceRange | undefined
  scaleForWidth: (width: number) => GraphScale
  perSqmOrTotal?: PerSqmOrTotal
  area?: number
  showSpinners?: boolean
  background?: string
  yieldGraph?: boolean
  showMultiplier?: boolean
}

export const BarGraph = ({
  label: labelName,
  color,
  data,
  scaleForWidth,
  perSqmOrTotal = "eurPerSqm",
  area = 1,
  showSpinners,
  labelWidth = "90px",
  background = COLORS.background.light,
  yieldGraph,
  colorLabel,
  showMultiplier,
}: Props) => {
  const translate = translations().assessmentEntryDetails

  const [graphWidth, setGraphWidth] = useState(200)

  const scale = scaleForWidth(graphWidth)

  const onResize = (entries: ResizeObserverEntry[]): void => {
    if (entries.length > 0) setGraphWidth(entries[0].contentRect.width)
  }

  const formatPrice = (price: number | undefined): string => {
    if (price === undefined) {
      return ""
    }
    if (yieldGraph) {
      return `${(Math.round(price * 100) / 100).toString()}\u00A0%`
    } else {
      const suffix = perSqmOrTotal === "eurPerSqm" ? translate.euroPerSqm : translate.euro
      const displayPrice = perSqmOrTotal === "eurTotal" ? price * area : price
      const decimalPlace = Math.floor(displayPrice).toString().length > 2 ? 0 : 2 // no decimal place for numbers greater than 99

      return formatNumber(displayPrice, decimalPlace, ` ${suffix}`).replace(/\s/g, "\u00A0")
    }
  }

  const totalBarHeight = 24
  const priceText = (priceRange?: PriceRange, showMultiplier?: boolean) => {
    const multiplier = showMultiplier ? 1 / (Math.round((priceRange?.avg ?? 1) * 100) / 10000) : undefined
    return (
      <Flex flexDirection={"column"} alignItems={"center"}>
        <div>{`Ø\u00A0${formatPrice(data?.avg)}`}</div>
        {showMultiplier && multiplier && <div>{`(${formatNumber(multiplier, 1)} ${translate.years})`}</div>}
      </Flex>
    )
  }

  return (
    <GridItem>
      <Grid
        columns={4}
        columnSpec={yieldGraph ? `${labelWidth} 60px 1fr 50px` : `${labelWidth} 120px 1fr 120px`}
        colGap={yieldGraph ? 16 : 8}
      >
        <GridItem colSpan={2} />
        <GridItem colSpan={2}>
          <Grid columnSpec={`${scale.getPositionOnScale(data?.avg ?? 0) - 24}px 100px 1fr`}>
            <GridItem />
            {showSpinners ? <LoadingSpinner size={20} /> : priceText(data, showMultiplier)}
            <GridItem />
          </Grid>
        </GridItem>

        <GridItem>
          <Grid
            columnSpec={
              colorLabel != false
                ? [
                    [12, "px"],
                    [0.5, "fr"],
                  ]
                : undefined
            }
            colGap={8}
          >
            <GridItem alignSelf="center">
              {colorLabel !== false && (
                <ReactCanvas
                  width={12}
                  height={12}
                  draw={(ctx) => {
                    ctx.beginPath()
                    ctx.rect(0, 0, 12, 12)
                    ctx.fillStyle = color
                    ctx.fill()
                  }}
                />
              )}
            </GridItem>
            <div className={barGraphLabelTextClass}>{labelName}</div>
          </Grid>
        </GridItem>
        <GridItem justifySelf={yieldGraph ? "end" : "start"}>
          {showSpinners ? <LoadingSpinner size={20} /> : formatPrice(data?.min)}
        </GridItem>
        <ResizeSensor onResize={onResize}>
          <div className={css(svgWrapperClass)}>
            <ReactCanvas
              width={graphWidth}
              height={totalBarHeight}
              draw={(ctx) => {
                ctx.beginPath()
                ctx.rect(0, 6, graphWidth, totalBarHeight / 2)
                ctx.fillStyle = background || "white"
                ctx.fill()

                if (!data) return

                ctx.beginPath()
                ctx.rect(
                  scale.getPositionOnScale(data.min),
                  6,
                  scale.getPositionOnScale(data.max) - scale.getPositionOnScale(data.min),
                  totalBarHeight / 2
                )
                ctx.fillStyle = color
                ctx.fill()

                ctx.beginPath()
                ctx.rect(scale.getPositionOnScale(data.avg), 0, 4, totalBarHeight)
                ctx.fillStyle = "black"
                ctx.strokeStyle = "white"
                ctx.lineWidth = 2
                ctx.fill()
                ctx.stroke()
              }}
            />
          </div>
        </ResizeSensor>
        <GridItem justifySelf={yieldGraph ? "start" : "end"}>
          {showSpinners ? <LoadingSpinner size={20} /> : formatPrice(data?.max)}
        </GridItem>
      </Grid>
    </GridItem>
  )
}
