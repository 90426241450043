import React from "react"
import DashboardWidget from "./dashboard-widget"
import { translations } from "../../i18n"
import { css } from "emotion"
import { MetaRating, Rating, RatingLike } from "../../../shared/models/ratings"
import { ExplorerRatingGradeLabel } from "../../../shared/components/explorer-rating-grade-label"
import { DataSetType } from "../../../shared/models/smartdata"
import { AssessmentRatingResults, MetaRatingCalculatedResult } from "../../models/assessment"
import { SelectionModes } from "./ratings-selection"
import { DashboardEditMode } from "./dashboard-widget-grid"
import { formatNumber } from "../../../shared/helper/number-format"

interface Props {
  allRatings: Rating[]
  ratingResults: { [ratingId: string]: AssessmentRatingResults }
  allMetaRatings: MetaRating[]
  metaRatingsResults: { [metaRatingId: string]: MetaRatingCalculatedResult }
  onConfigClick: () => void
  onRemoveClick: () => void
  onHeaderClick: () => void
  configurable: boolean
  printMode: boolean
  editMode: DashboardEditMode
  onRenderingDone: () => void
  selectionMode: SelectionModes
  selectedMetaRatings: string[]
  selectedRatings: string[]
}

const styles = {
  noRatings: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  }),
  nameColumn: css({ verticalAlign: "top", overflow: "hidden", maxWidth: "80%", textOverflow: "ellipsis" }),
  gradeColumn: css({ maxWidth: "max-content", verticalAlign: "top" }),
  contentStyle: css({
    padding: "8px 12px",
    display: "grid",
    gridTemplateColumns: "minmax(100px, 1fr) min-content",
    columnGap: "4px",
    rowGap: "4px",
  }),
}

const RatingsWidget = ({
  allRatings,
  ratingResults,
  selectedRatings,
  allMetaRatings,
  metaRatingsResults,
  selectedMetaRatings,
  onHeaderClick,
  onConfigClick,
  onRemoveClick,
  configurable,
  printMode,
  editMode,
  onRenderingDone,
  selectionMode,
}: Props) => {
  const t = React.useMemo(translations, [translations])

  const ratingsToDisplay = new Set(selectedRatings)
  const ratings =
    selectionMode === "partial" ? allRatings.filter((rating) => ratingsToDisplay.has(rating.id)) : allRatings

  const metaRatingsToDisplay = new Set(selectedMetaRatings)

  const metaRatings =
    selectionMode === "partial"
      ? allMetaRatings.filter((rating) => metaRatingsToDisplay.has(rating.id))
      : allMetaRatings

  const renderRatings = (dataSet: DataSetType) => {
    const ratingsByDataSet = ratings
      .filter((rating) => rating.dataSetType === dataSet)
      .sort((a, b) => a.name.localeCompare(b.name))
    return ratingsByDataSet.map((rating) => {
      const gradeIdx = ratingResults[rating.id]?.gradeId
      const grade =
        typeof gradeIdx === "number" && gradeIdx < rating.grades.length ? rating.grades[gradeIdx] : undefined
      return grade ? renderRating(rating, grade) : null
    })
  }

  const renderMetaRatings = () => {
    return metaRatings.map((metaRating) => {
      const result = metaRatingsResults[metaRating.id]
      const grade =
        result?.result !== undefined
          ? {
              label: formatNumber(result.result, 2),
              color: result?.range?.color,
            }
          : undefined

      return renderRating(metaRating, grade)
    })
  }

  function renderRating(rating: RatingLike, grade?: { label: string; color?: string }): JSX.Element | null {
    if (!grade) return null
    return (
      <React.Fragment key={"dashboard-rating-" + rating.id}>
        <div className={styles.nameColumn}>{rating.name}</div>
        <div className={styles.gradeColumn}>
          <ExplorerRatingGradeLabel grade={grade} responsiveWidth={true} />
        </div>
      </React.Fragment>
    )
  }

  const hasRatings = ratings.length + metaRatings.length > 0

  onRenderingDone()

  return (
    <DashboardWidget
      header={t.dashboard.ratings.header}
      onHeaderClick={onHeaderClick}
      onRemoveClick={onRemoveClick}
      configurable={configurable}
      onConfigClick={onConfigClick}
      printMode={printMode}
      editMode={editMode}
    >
      {!hasRatings && <div className={styles.noRatings}>{t.dashboard.ratings.noRatings}</div>}
      {hasRatings && (
        <div className={styles.contentStyle}>
          {renderRatings("macro")}
          {renderRatings("micro")}
          {renderMetaRatings()}
        </div>
      )}
    </DashboardWidget>
  )
}

export default RatingsWidget
