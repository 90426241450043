import * as React from "react"
import { Control } from "ol/control"
import * as ol from "ol"
import * as layer from "ol/layer"
import * as source from "ol/source"
import * as proj from "ol/proj"
import * as extent from "ol/extent"
import { createForProjection } from "ol/tilegrid"
import { PopoverContent } from "./PopoverContent"
import { BorderBottom } from "./ui/border-bottom"
import { Grid } from "./ui/grid"
import { HTMLSelect } from "@blueprintjs/core"
import { createRoot } from "react-dom/client"
import { CLASS_CONTROL } from "ol/css"
import { bind } from "decko"
import { translations } from "../i18n"
import { Pixel } from "ol/pixel"
import { mapProxyUrl } from "../../app_config"
import { WmsLayer } from "../models/wms-layer"
import Axios, { AxiosResponse } from "axios"
import { WMSDetailsPopup } from "./ui/wms-details-popup"
import { featureEnabled, WMS_DEVELOPMENT } from "../../utils/features-toggle"
import { WMSDetailsEmbedded } from "./ui/wms-details-embedded"
import { WidgetsType } from "../../assessment/models/assessment"
import { Address } from "../../assessment/models/address"
import { trackUsageEvent } from "../../utils/usage-tracking"
import Icon from "./icon"

type WmsLayerWithEnabled = WmsLayer & { enabled: boolean }

class MapWmsControl extends Control {
  private parent: HTMLElement | undefined
  private widget: WidgetsType | undefined

  constructor(
    input: string,
    layers: WmsLayerWithEnabled[],
    onChange: (input: string) => void,
    parent?: HTMLElement,
    widget?: WidgetsType
  ) {
    super({ element: document.createElement("div") })

    this.element.className = `${CLASS_CONTROL}`
    this.element.style.right = "0.5em"
    this.element.style.top = "12.5em"
    this.parent = parent
    this.widget = widget

    this.updateInput(input, layers, onChange)
  }

  updateInput(input: string, layers: WmsLayerWithEnabled[], onChange: (input: string) => void) {
    createRoot(this.element).render(
      <MapWmsButton input={input} layers={layers} onChange={onChange} parent={this.parent} widget={this.widget} />
    )
  }
}

interface MapWmsButtonProps {
  input: string
  layers: WmsLayerWithEnabled[]
  onChange: (input: string) => void
  parent?: HTMLElement
  widget?: WidgetsType
}

const MapWmsButton = (props: MapWmsButtonProps) => {
  const t = translations()

  const renderLegend = (selectedLayer: WmsLayerWithEnabled) => {
    if (selectedLayer.legend) {
      return (
        <div style={{ maxHeight: "400px", overflowY: "auto", paddingRight: "16px" }}>
          {selectedLayer.legend.map((group, idx) => (
            <BorderBottom key={idx}>
              <Grid columnSpec="20px 1fr 20px 1fr" gap={8} alignItems="center" padding={[0, 0, 8, 0]}>
                {group.map((legend, idx) => (
                  <React.Fragment key={idx}>
                    <img src={`${mapProxyUrl}${legend.icon}`} />
                    <div>{t.pickTranslation(legend.label)}</div>
                  </React.Fragment>
                ))}
              </Grid>
            </BorderBottom>
          ))}
        </div>
      )
    }
    if (selectedLayer.legend_simple) {
      return (
        <div style={{ maxHeight: "400px", overflowY: "auto", paddingRight: "16px" }}>
          <img style={{ maxWidth: "500px" }} src={`${mapProxyUrl}${selectedLayer.legend_simple}`} />
        </div>
      )
    }
    return null
  }

  const { input, layers, onChange } = props
  const selectedLayer = layers.find((layer) => layer.id === input)

  return (
    <PopoverContent
      title={t.map.landvalues.title}
      iconname="map"
      parent={props.parent}
      defaultIsOpen={props.widget === "specialMaps"}
    >
      <Grid columns={1} gap={8} padding={8}>
        <BorderBottom>
          <HTMLSelect value={input} onChange={(event) => onChange(event.currentTarget.value)}>
            <option value="">{t.map.landvalues.noSelection}</option>
            {layers.findIndex((layer) => layer.enabled) >= 0 && (
              <optgroup label={t.map.landvalues.availableLayers}>
                {layers
                  .filter((layer) => layer.enabled)
                  .map((layer, idx) => (
                    <option key={idx} value={layer.id}>
                      {t.pickTranslation(layer.name)}
                    </option>
                  ))}
              </optgroup>
            )}
            {layers.findIndex((layer) => !layer.enabled) >= 0 && (
              <optgroup label={t.map.landvalues.unavailableLayers}>
                {layers
                  .filter((layer) => !layer.enabled)
                  .map((layer, idx) => (
                    <option key={idx} disabled={true} value={layer.id}>
                      {t.pickTranslation(layer.name)}
                    </option>
                  ))}
              </optgroup>
            )}
          </HTMLSelect>
        </BorderBottom>
        {selectedLayer && renderLegend(selectedLayer)}
        <span>
          <Icon name="warning" color="primary" colorType="default" />
          {"\u00A0"}
          {t.map.landvalues.infoText}
        </span>
      </Grid>
    </PopoverContent>
  )
}

export class MapWmsLayer {
  private map: ol.Map
  private wmsSource: source.TileWMS | undefined
  private wmsLayer: layer.Tile<any> | undefined
  private wmsControl: MapWmsControl
  private currentLayer: string
  private availableLayers: WmsLayer[] | undefined
  private currentAssessmentEntryAddress?: Address

  constructor(map: ol.Map, parent?: HTMLElement, widget?: WidgetsType, currentAssessmentEntryAddress?: Address) {
    this.map = map
    this.wmsSource = undefined
    this.wmsLayer = undefined
    this.currentLayer = ""
    this.wmsControl = new MapWmsControl("", [], this.onChange, parent, widget)
    this.map.addControl(this.wmsControl)
    this.currentAssessmentEntryAddress = currentAssessmentEntryAddress

    this.updateWmsLayers()
  }

  public isActive() {
    return this.wmsSource !== undefined
  }

  public getOverlayAt(pixel: Pixel, closeCallback?: () => void): { id: string; content: JSX.Element } | null {
    const selectedLayer = this.availableLayers?.find((layer) => layer.id === this.currentLayer)

    if (!this.wmsSource || !selectedLayer || !selectedLayer.popup) return null

    const coordinate = this.map.getCoordinateFromPixel(pixel)

    let infoFormat: string | null = null

    switch (selectedLayer.popup.format) {
      case "xml":
      case "boris":
        infoFormat = "text/xml"
        break
      case "gml":
        infoFormat = "application/vnd.ogc.gml"
        break
      case "json":
        infoFormat = "application/json"
        break
      case "html":
        infoFormat = "text/html"
        break
    }

    if (infoFormat) {
      const url = this.wmsSource.getFeatureInfoUrl(
        coordinate,
        this.map.getView().getResolution() ?? 0,
        this.map.getView().getProjection(),
        {
          INFO_FORMAT: infoFormat,
        }
      )

      return url !== undefined
        ? {
            id: "",
            content:
              selectedLayer.popup.format === "html" ? (
                <WMSDetailsEmbedded selectedLayer={selectedLayer} url={url} closeCallback={closeCallback} />
              ) : (
                <WMSDetailsPopup selectedLayer={selectedLayer} url={url} closeCallback={closeCallback} />
              ),
          }
        : null
    }

    return null
  }

  @bind
  public onViewChange() {
    const layers = this.availableLayers?.map((layer) => ({
      ...layer,
      enabled:
        extent.getIntersectionArea(
          this.map.getView().calculateExtent(),
          proj.transformExtent(layer.extent, "EPSG:4326", "EPSG:3857")
        ) > 0,
    }))
    this.wmsControl.updateInput(this.currentLayer, layers || [], this.onChange)
  }

  @bind
  private onChange(input: string) {
    const nameOfLayer = this.availableLayers?.find((layer) => layer.id === input)?.name.en
    nameOfLayer &&
      trackUsageEvent("DETAIL_VIEW_SELECT_SPECIAL_MAP", this.currentAssessmentEntryAddress ?? null, nameOfLayer)

    const layers = this.availableLayers?.map((layer) => ({
      ...layer,
      enabled:
        extent.getIntersectionArea(
          this.map.getView().calculateExtent(),
          proj.transformExtent(layer.extent, "EPSG:4326", "EPSG:3857")
        ) > 0,
    }))
    this.wmsControl.updateInput(input, layers || [], this.onChange)
    this.wmsLayer && this.map.removeLayer(this.wmsLayer)
    this.wmsSource = undefined
    this.wmsLayer = undefined
    this.currentLayer = input

    if (input === "") return

    this.wmsSource = new source.TileWMS({
      url: `${mapProxyUrl}/wms`,
      params: {
        LAYERS: input,
        TILED: true,
      },
      hidpi: false,
      tileGrid: createForProjection("EPSG:3857", 22, [256, 256]),
      /* NOTE: This should be no longer necessary with hidpi=false
            tileLoadFunction: (imageTile: ImageTile, src: string) => {
              let img = imageTile.getImage();
              if (img instanceof HTMLImageElement) {
                // parsing the src, map proxy supports 256x256 tiles only
                let url = new URL(src)
                let search = new URLSearchParams(url.search)
                search.set("WIDTH", "256")
                search.set("HEIGHT", "256")
                url.search = search.toString()
                img.src = url.toString()
              }
            },*/
    })
    this.wmsLayer = new layer.Tile({
      source: this.wmsSource,
    })
    this.map.addLayer(this.wmsLayer)
  }

  private updateWmsLayers() {
    const includeDevelopment = featureEnabled(WMS_DEVELOPMENT)
    Axios.get(`${mapProxyUrl}/api/maps`, {
      withCredentials: false,
    }).then(
      (success: AxiosResponse) => {
        this.availableLayers = (success.data as WmsLayer[]).filter((layer) => layer.active || includeDevelopment)
        this.onViewChange()
      },
      () => {}
    )
  }
}
