import { SharedTranslations } from "./translations"
import { SHARED_DE } from "./de"
import { SHARED_EN } from "./en"
import { language } from "../language"

export function translations(): SharedTranslations {
  switch (language()) {
    case "de":
      return SHARED_DE
    default:
      return SHARED_EN
  }
}
