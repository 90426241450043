import * as React from "react"
import { Control } from "ol/control"
import { createRoot } from "react-dom/client"
import { Grid } from "./ui/grid"
import { PopoverContent } from "./PopoverContent"
import { CLASS_CONTROL } from "ol/css"

export class AttributeControl extends Control {
  private button: React.RefObject<AttributeButtonPopover>

  constructor(top: string, parent?: HTMLElement) {
    super({ element: document.createElement("div") })

    this.element.className = `${CLASS_CONTROL}`
    this.element.style.right = "0.5em"
    this.element.style.top = top

    this.button = React.createRef()
    createRoot(this.element).render(<AttributeButtonPopover ref={this.button} parent={parent} />)
  }
}

interface MapAttributeButtonProps {
  parent?: HTMLElement
}

class AttributeButtonPopover extends React.Component<MapAttributeButtonProps> {
  render() {
    return (
      <PopoverContent iconname={"info"} parent={this.props.parent}>
        <Grid columns={1} padding={[0, 8, 0, 16]}>
          <span style={{ fontSize: "12px" }}>
            <a href="https://www.maptiler.com/license/maps/" target="_blank">
              © MapTiler
            </a>
            <a href="https://www.openstreetmap.org/copyright" target="_blank">
              © OpenStreetMap contributors
            </a>
          </span>
        </Grid>
      </PopoverContent>
    )
  }
}
