import { BorderBottom } from "./ui/border-bottom"
import { Flex } from "./ui/flex"
import { FlexItem } from "./ui/flex-item"
import { ScrollBox } from "./ui/scroll-box"
import { language } from "../i18n/language"
import { Tooltip } from "./ui/tooltip"
import { Checkbox, Collapse, InputGroup, Intent, Menu, MenuItem, Popover } from "@blueprintjs/core"
import { Button as ButtonBP } from "@blueprintjs/core/lib/esm/components/button/buttons"
import * as React from "react"
import { translations } from "../i18n"
import { useDebounce } from "../../utils/use-debounce"
import { categorize, CategorizedScores, DataSetType, LanaNodeWithVariation } from "../models/smartdata"
import { CHILD_PROFILE_PREFIX, Profile } from "../../profile/models/profile"
import { Scores } from "../models/scores"
import { findNonApplicableNodes } from "../../utils/node-graph-helper"
import Expandable from "./expandable"
import { css, cx } from "emotion"
import { ProfileDependencies } from "../../profile/components/profile-dependencies"
import { useEffect } from "react"
import { LANA_AGS_NODES, LANA_CELL_NODES, LanaNode } from "../smartdata-products/smartdata"
import Grid from "./restyle-grid/grid"
import { getThemeColor, getThemeColorVar } from "../helper/color"
import Icon from "./icon"

const groupsClass = css({
  "> div": {
    borderLeft: "none",
    borderRight: "none",
    borderTop: "none",
  },
  "> div:last-child": {
    borderBottom: "none",
  },
})

const scoreInGroupClass = css({
  "> div": {
    borderLeft: "none",
    borderRight: "none",
    borderTop: `1px solid ${getThemeColorVar("border", "default")}`,
  },
  "> div:first-child": {
    borderTop: "none",
  },
})

const arrowClass = css({
  lineHeight: "25px",
  transition: "transform 0.4s",
})
const arrowExpandedClass = css({
  transform: "rotateZ(180deg)",
})

type Props = {
  smartdataSource: DataSetType
  profiles?: Profile[] | null
  currentProfile?: Profile | null
  scores?: { [p: string]: number }
  settingsSelectedScores?: Set<string>
  selected: Set<string>
  setSelected: (selected: Set<string>) => void
}
const t = translations()
const categorizedMacroScores = categorize(t, LANA_AGS_NODES)
const categorizedMicroScores = categorize(t, LANA_CELL_NODES)
const profileCategoryName = "profile_categories"

export const ScoresSelection = (props: Props) => {
  const categorisedScores = props.smartdataSource === "micro" ? categorizedMicroScores : categorizedMacroScores
  const allScoresAndProfiles = new Set(categorisedScores.flatMap((c) => c.scores.map((s) => s.rawName)))
  getFilteredProfiles().forEach((p) => allScoresAndProfiles.add(p.id))
  const allCategoriesByName = new Set(categorisedScores.map((v) => v.category.name))
  allCategoriesByName.add(profileCategoryName)

  const [debouncedScoreFilterName, scoreFilterName, setScoreFilterName] = useDebounce<string>("", 800)
  const [expandedScores, setExpandedScores] = React.useState<Set<string>>(new Set(currentSelected()))
  const [expandedCategories, setExpandedCategories] = React.useState<Set<string>>(allCategoriesByName)
  const [filteredScoresAndProfiles, setFilteredScoresAndProfiles] = React.useState<Set<string>>(allScoresAndProfiles)

  const langKey = language() === "de" ? "de" : "en"

  useEffect(() => {
    if (debouncedScoreFilterName === "") {
      setFilteredScoresAndProfiles(allScoresAndProfiles)
    } else {
      const lowercasedSearchTerm = debouncedScoreFilterName.toLowerCase()

      const filteredScores = categorisedScores.reduce<string[]>((acc, categorisedScore) => {
        const scores = categorisedScore.scores
        const filteredBySearchTerm = scores.filter(
          (s) =>
            s.title[langKey].toLowerCase().includes(lowercasedSearchTerm) ||
            s.description[langKey].toLowerCase().includes(lowercasedSearchTerm)
        )
        filteredBySearchTerm.forEach((score) => acc.push(score.rawName))
        return acc
      }, [])

      const profilesFilteredByName = getFilteredProfiles().reduce<string[]>((acc, profile) => {
        if (profile.name.toLowerCase().includes(lowercasedSearchTerm)) {
          acc.push(profile.id)
        }
        return acc
      }, [])

      const filteredSet = new Set<string>()
      filteredScores.forEach((s) => filteredSet.add(s))
      profilesFilteredByName.forEach((p) => filteredSet.add(p))

      setFilteredScoresAndProfiles(filteredSet)
    }
  }, [debouncedScoreFilterName])

  const scoreFiltering = () => (
    <Grid columnSpec={"1fr  min-content"} gap={16}>
      <InputGroup
        placeholder={t.scoreSearch.filter}
        style={{ borderRadius: "4px" }}
        value={scoreFilterName}
        leftElement={
          <div style={{ padding: "5px 4px" }}>
            <Icon name={"search"} color={"typo"} colorType={"lighter"} fontSize={16} />
          </div>
        }
        rightElement={
          <Tooltip placement="auto" tooltip={t.scoreSearch.info}>
            <div style={{ padding: "5px" }}>
              <Icon name={"info"} color={"typo"} colorType={"lighter"} fontSize={16} />
            </div>
          </Tooltip>
        }
        onChange={(event) => setScoreFilterName(event.currentTarget.value)}
      />
      <Popover
        placement="auto"
        content={
          <Menu>
            <MenuItem text={t.scoreSearch.expandActive} onClick={() => setExpandedCategories(getCurrentlySelected())} />
            <MenuItem text={t.scoreSearch.expandAll} onClick={() => setExpandedCategories(allCategoriesByName)} />
            <MenuItem text={t.scoreSearch.collapseAll} onClick={() => setExpandedCategories(new Set())} />
          </Menu>
        }
      >
        <ButtonBP
          style={{ borderRadius: "4px" }}
          intent={Intent.PRIMARY}
          rightIcon={"caret-down"}
          text={t.scoreSearch.groups}
        />
      </Popover>
    </Grid>
  )

  const renderMicroScores = () => (
    <Grid columns={1}>
      <div className={groupsClass}>
        {categorizedMicroScores.map(renderCategorizedScores)}
        {props.profiles !== undefined && renderOtherProfiles()}
      </div>
    </Grid>
  )

  const renderMacroScores = () => (
    <Grid columns={1}>
      <div className={groupsClass}>
        {categorizedMacroScores.map(renderCategorizedScores)}
        {props.profiles !== undefined && <div className={scoreInGroupClass}>{renderOtherProfiles()}</div>}
      </div>
    </Grid>
  )

  const renderCategorizedScores = (categorizedScores: CategorizedScores) => (
    <Expandable
      onClick={() => {
        const categoryName = categorizedScores.category.name
        const isSelected = expandedCategories.has(categorizedScores.category.name)
        const newExpanded = new Set(expandedCategories)
        isSelected ? newExpanded.delete(categoryName) : newExpanded.add(categoryName)
        setExpandedCategories(newExpanded)
      }}
      key={`category-${categorizedScores.category.name}`}
      header={t.pickTranslation(categorizedScores.category.title)}
      isOpen={expandedCategories.has(categorizedScores.category.name)}
    >
      <Grid columns={1} gap={4} padding={[4, 0]}>
        <div className={scoreInGroupClass}>
          {categorizedScores.scores.filter((s) => filteredScoresAndProfiles.has(s.rawName)).map(renderScore)}
        </div>
      </Grid>
    </Expandable>
  )

  const renderButtonScoreVariation = (variationName: LanaNode) => {
    const isSelected = props.selected.has(variationName.scoreName)
    return (
      <React.Fragment key={`${variationName.scoreVariation}-${variationName.scoreVariationName?.en}`}>
        {variationName.scoreVariationName && (
          <ButtonBP
            style={{
              fontSize: 12,
              borderRadius: 4,
              minWidth: "72px",
              borderLeft: isSelected ? "1px solid" : "",
              borderRight: isSelected ? "1px solid" : "",
            }}
            outlined={!isSelected}
            minimal={!isSelected}
            intent={isSelected ? "primary" : undefined}
            small
            onClick={onToggleScoreVariation(variationName.scoreName)}
          >
            {t.pickTranslation(variationName.scoreVariationName)}
          </ButtonBP>
        )}
      </React.Fragment>
    )
  }

  const renderScore = (score: LanaNodeWithVariation) => {
    let scoreSelected: boolean
    let onToggleScoreHandler: (event: React.FormEvent<HTMLInputElement>) => void

    if (score.scoreVariationNames) {
      const selectedVariations =
        score.scoreVariationNames?.filter((s) => props.selected.has(s.scoreName)).map((s) => s.scoreName) || []
      scoreSelected = selectedVariations.length > 0
      onToggleScoreHandler = onToggleScoreWithVariations(score.scoreName, selectedVariations)
    } else {
      scoreSelected = props.selected.has(score.scoreName)
      onToggleScoreHandler = onToggleScore(score.scoreName)
    }

    const scoreVariationExpanded =
      !!score.scoreVariationNames &&
      score.scoreVariationNames.some((variation) => expandedScores.has(variation.scoreName))
    return (
      <div key={score.scoreName}>
        <Flex flexDirection="row" gap={4} padding={[16, 8, 8, 16]}>
          <Checkbox
            id={`fragment-${score.scoreName}`}
            key={`fragment-${score.scoreName}`}
            checked={scoreSelected}
            onChange={onToggleScoreHandler}
          />
          <div>
            <label htmlFor={`fragment-${score.scoreName}`}>
              {t.pickTranslation(score.title)}
              &nbsp;
              <Tooltip
                placement="right"
                tooltip={
                  <div style={{ maxWidth: "400px" }}>
                    <b>{t.scoreDescription}</b>
                    <span>: {t.pickTranslation(score.description)}</span>
                    <br />
                    <b>{t.scoreSource}</b>
                    <span>: {t.pickTranslation(score.source)}</span>
                  </div>
                }
              >
                <Icon name="info" fontSize={14} color="primary" colorType="default" />
              </Tooltip>
            </label>
          </div>
          <FlexItem flexGrow={1} />

          {score.scoreVariationNames && score.scoreVariationNames.length > 1 && (
            <div className={scoreVariationExpanded ? arrowClass : cx(arrowClass, arrowExpandedClass)}>
              <a onClick={onVariationListExpand(score)}>
                <Icon fontSize={20} name={"arrow_drop_up"} />
              </a>
            </div>
          )}
        </Flex>
        {score.scoreVariationNames && score.scoreVariationNames.length > 1 && (
          <Collapse isOpen={scoreVariationExpanded} transitionDuration={0}>
            <Flex flexDirection="row" gap={16} key={score.scoreVariationName?.en} padding={[0, 0, 16, 16]}>
              {score.scoreVariationNames.map((s) => renderButtonScoreVariation(s))}
            </Flex>
          </Collapse>
        )}
      </div>
    )
  }

  const renderOtherProfiles = () => (
    <Expandable
      header={t.scoreSearch.otherProfilesCategory}
      onClick={() => {
        const isSelected = expandedCategories.has(profileCategoryName)
        const newExpanded = new Set(expandedCategories)
        isSelected ? newExpanded.delete(profileCategoryName) : newExpanded.add(profileCategoryName)
        setExpandedCategories(newExpanded)
      }}
      isOpen={expandedCategories.has(profileCategoryName)}
    >
      <Grid columns={1} gap={4} padding={[4, 0]}>
        <div className={scoreInGroupClass}>
          {getFilteredProfiles()
            .filter((p) => p.id !== props.currentProfile?.id && filteredScoresAndProfiles.has(p.id))
            .map((profile) => {
              const scoreName = CHILD_PROFILE_PREFIX + profile.id

              return (
                <Flex flexDirection="row" gap={4} key={"flex-" + profile.id} padding={[16, 8, 8, 8]}>
                  <Checkbox
                    id={`profile-${profile.id}`}
                    checked={props.selected.has(scoreName)}
                    onChange={onToggleScore(scoreName)}
                  />
                  <div>
                    <label htmlFor={`profile-${profile.id}`}>{profile.name}</label>
                  </div>
                  <ProfileDependencies profileId={scoreName.substring(8)} />
                </Flex>
              )
            })}
        </div>
      </Grid>
    </Expandable>
  )

  const updateSelectedScores = (updatedSelectedScores: Set<string>) => {
    props.setSelected(updatedSelectedScores)
  }

  const onToggleScoreWithVariations =
    (mainScoreName: string, selectedScoreNames: string[]) => (event: React.FormEvent<HTMLInputElement>) => {
      const updatedSelectedScores = new Set(props.selected)
      if (event.currentTarget.checked) {
        selectedScoreNames.forEach((s) => updatedSelectedScores.add(s))
        if (selectedScoreNames.length === 0) {
          // when we toggle on the score with variants we select the parent score by default
          updatedSelectedScores.add(mainScoreName)
        }

        setExpandedScores(new Set(expandedScores).add(mainScoreName))
      } else {
        selectedScoreNames.forEach((s) => updatedSelectedScores.delete(s))
      }
      updateSelectedScores(updatedSelectedScores)
    }

  const onToggleScore = (mainScoreName: string) => (event: React.FormEvent<HTMLInputElement>) => {
    const updatedSelectedScores = new Set(props.selected)
    if (event.currentTarget.checked) updatedSelectedScores.add(mainScoreName)
    else updatedSelectedScores.delete(mainScoreName)

    updateSelectedScores(updatedSelectedScores)
  }

  const onToggleScoreVariation = (scoreName: string) => (event: React.MouseEvent) => {
    const updatedSelectedSet = new Set(props.selected)
    if (props.selected.has(scoreName)) {
      updatedSelectedSet.delete(scoreName)
    } else {
      updatedSelectedSet.add(scoreName)
    }

    props.setSelected(updatedSelectedSet)
  }

  const onVariationListExpand = (score: LanaNodeWithVariation) => (event: React.MouseEvent) => {
    let selectedVariations = new Set(expandedScores)
    score.scoreVariationNames?.some((scoreVariation) => expandedScores.has(scoreVariation.scoreName))
      ? score.scoreVariationNames?.forEach((scoreVariation) => selectedVariations.delete(scoreVariation.scoreName))
      : selectedVariations.add(score.scoreName)
    setExpandedScores(selectedVariations)
  }

  function currentSelected(): Set<string> {
    return props.selected
  }

  function extractScoresFrom(scores: Scores): string[] {
    switch (props.smartdataSource) {
      case "micro":
        return Object.getOwnPropertyNames(scores.micro)
      case "macro":
        return Object.getOwnPropertyNames(scores.macro)
      default:
        return []
    }
  }

  function getFilteredProfiles() {
    const { profiles } = props

    const scoreNodes: [string, string[]][] =
      profiles?.map((value) => {
        const onlyProfilesId = extractScoresFrom(value.scores)
          .filter((v) => v.startsWith(CHILD_PROFILE_PREFIX))
          .map((v) => v.replace(CHILD_PROFILE_PREFIX, ""))
        return [value.id, onlyProfilesId]
      }) ?? []

    const nodesToFilter = [
      ...findNonApplicableNodes(scoreNodes, props.currentProfile?.id ?? ""),
      props.currentProfile?.id ?? "",
    ]

    return props.profiles?.filter((p) => nodesToFilter.indexOf(p.id) < 0) ?? []
  }

  const getCurrentlySelected = () => {
    const expandedCategories = categorisedScores.reduce<string[]>((acc, categorisedScore) => {
      const isActive = categorisedScore.scores.some((score) =>
        score.scoreVariationNames
          ? score.scoreVariationNames.some((s) => props.selected.has(s.scoreName))
          : props.selected.has(score.scoreName)
      )
      if (isActive) acc.push(categorisedScore.category.name)

      return acc
    }, [])

    const expandedCategoriesSet = new Set(expandedCategories)

    const profileIsExpanded = getFilteredProfiles().some((profile) => {
      const scoreName = CHILD_PROFILE_PREFIX + profile.id
      return props.selected.has(scoreName)
    })

    if (profileIsExpanded) {
      expandedCategoriesSet.add(profileCategoryName)
    }

    return expandedCategoriesSet
  }

  return (
    <>
      <BorderBottom padding={16}>{scoreFiltering()}</BorderBottom>
      <div style={{ borderBottom: `1px solid ${getThemeColor("border", "default")}`, height: "100%" }}>
        <ScrollBox>{props.smartdataSource === "micro" ? renderMicroScores() : renderMacroScores()}</ScrollBox>
      </div>
    </>
  )
}
