import * as React from "react"
import { css, cx } from "emotion"
import { getThemeColorVar } from "../../helper/color"

const tabsBarClass = css({
  listStyle: "none",
  paddingLeft: 0,
  paddingBottom: 0,
  marginBottom: 0,
  backgroundColor: "transparent",
  whiteSpace: "nowrap",
  maxWidth: "100%",
  overflowX: "auto",
  overflowY: "hidden",
  borderBottom: `1px solid ${getThemeColorVar("border", "default")}`,

  "> li": {
    position: "relative",
    display: "inline-block",

    "> a": {
      display: "block",
      whiteSpace: "nowrap",
      textTransform: "uppercase",
      height: "30px",
      lineHeight: "30px",
      paddingLeft: "15px",
      paddingRight: "15px",
      userSelect: "none",

      "&:hover": {
        textDecoration: "none",
      },
    },

    "> a.inactive": {
      color: getThemeColorVar("primary", "default"),
    },

    "> a.active": {
      color: getThemeColorVar("primary", "default"),
    },
  },
})

const tabsBarSmallClass = css({
  "> li": {
    "> a": {
      textTransform: "none",
      fontSize: "14px",
      height: "48px",
      lineHeight: "48px",
    },

    "> a.inactive": {
      color: getThemeColorVar("typo", "default"),
    },

    "> a.active": {
      color: getThemeColorVar("primary", "default"),
    },
  },
})

const justifiedClass = css({
  display: "table",
  width: "100%",
  tableLayout: "fixed",

  "> li": {
    display: "table-cell",

    "> a:": {
      textAlign: "center",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
})

const isActiveClass = css({
  borderBottom: `2px solid ${getThemeColorVar("primary", "default")}`,

  "> a": {
    color: getThemeColorVar("primary", "default"),
  },
})

const badgeClass = css({
  background: getThemeColorVar("background", "light"),
  marginLeft: "4px",
  padding: "2px 4px",
  fontSize: "12px",
  borderRadius: "2px",
})

export interface TabItem {
  label: string
  badge?: string
  onClick: React.MouseEventHandler<any>
}

export interface TabBarProps {
  selectedIndex: number
  small?: boolean
  justified?: boolean
  tabItems: TabItem[]
}

export const ExtendedTabBar: React.FunctionComponent<TabBarProps> = (props) => {
  const { tabItems, selectedIndex, justified, small } = props
  const classes = [tabsBarClass]

  justified && classes.push(justifiedClass)
  small && classes.push(tabsBarSmallClass)

  return (
    <ul className={cx(classes)}>
      {tabItems.map((tabItem, idx) => (
        <li key={idx} className={cx(idx === selectedIndex ? [isActiveClass] : [])}>
          <a onClick={tabItem.onClick} className={idx === selectedIndex ? "active" : "inactive"}>
            {tabItem.label}
            {tabItem.badge && <span className={badgeClass}>{tabItem.badge}</span>}
          </a>
        </li>
      ))}
    </ul>
  )
}
