import * as React from "react"
import { CompanyApiTranslations } from "../i18n/translations"
import { translations } from "../i18n"
import { FormEvent, useState } from "react"
import { NavigationPage } from "../../shared/actions/navigation"
import { createApiToken } from "../reducers/api-slice"
import { navigateTo } from "../../shared/reducers/navigation-slice"
import Dialog from "../../shared/components/dialog"
import DialogFooter from "../../shared/components/dialogfooter"
import Grid from "../../shared/components/restyle-grid/grid"
import TextField from "../../shared/components/textfield"
import Button from "../../shared/components/button"
import Form from "../../shared/components/form"

export const ApiTokenCreate = () => {
  const [tokenLabel, setTokenLabel] = useState("")
  const [showDialog, setShowDialog] = useState(false)
  const translate: CompanyApiTranslations = translations().api

  const openDialog = () => {
    setTokenLabel("")
    setShowDialog(true)
  }

  const closeDialog = () => {
    setShowDialog(false)
  }

  const updateLabel = (tokenLabel: string) => {
    setTokenLabel(tokenLabel)
  }
  const isValid = () => {
    return tokenLabel.length > 0
  }

  const createToken = (event: FormEvent) => {
    event.stopPropagation()
    event.preventDefault()
    setShowDialog(false)
    void createApiToken({ label: tokenLabel }).then((_) => navigateTo({ name: NavigationPage.companyApiTokenList }))
  }
  return (
    <>
      {showDialog && (
        <Dialog
          onClose={closeDialog}
          title={translate.apiToken.createToken}
          closeButton={true}
          closeOnClickOutside={true}
        >
          <Form onSubmit={createToken}>
            <Grid columns={1} paddingX="lg" paddingY="xxl">
              <TextField
                maxLength={1000}
                minLength={1}
                required={true}
                javaScriptAutoFocus={true}
                label={translate.apiToken.label}
                value={tokenLabel}
                onValueChange={updateLabel}
                hint={translate.apiToken.labelHint}
              />
            </Grid>
            <DialogFooter spaceBetween="md" paddingX="md" md-justify="spaceBetween" paddingY="lg" direction="row">
              <Button formType="button" type="secondary" onClick={closeDialog}>
                {translate.cancel}
              </Button>
              <Button type="primary" formType="submit" disabled={!isValid()} onClick={createToken}>
                {translate.apiToken.create}
              </Button>
            </DialogFooter>
          </Form>
        </Dialog>
      )}
      <Button type="primary" onClick={openDialog}>
        {translate.apiToken.createToken}
      </Button>
    </>
  )
}
