import { css, cx, ObjectInterpolation } from "emotion"
import { OffsetSpec, offsetSpecToPadding } from "./contraints"
import React, { PropsWithChildren } from "react"
import { COLORS } from "./colors"

const borderBottomClass = css({
  borderBottom: `1px solid ${COLORS.border.default}`,
  display: "grid",
  gridColumnTemplate: "1fr",
  gridRowTemplate: "1fr",
})

export interface BorderBottomProps {
  padding?: OffsetSpec
  backgroundColor?: string
}

export const BorderBottom: React.FunctionComponent<PropsWithChildren<BorderBottomProps>> = (props) => {
  const { padding, backgroundColor } = props
  const emotionStyles: ObjectInterpolation<undefined>[] = []

  padding !== undefined && emotionStyles.push(offsetSpecToPadding(padding))
  backgroundColor !== undefined && emotionStyles.push({ backgroundColor })

  return <div className={cx(css(emotionStyles), borderBottomClass)}>{props.children}</div>
}
