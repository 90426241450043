import * as React from "react"
import * as ReactDOM from "react-dom"
import { bind } from "decko"
import { css, cx } from "emotion"
import { PropsWithChildren } from "react"
import RevoTooltip from "./revo-tooltip"
import { Button, ButtonBaseProps, ButtonOrLinkProps, ButtonProps, ButtonType } from "./button"
import { getThemeColorVar } from "../helper/color"
import Icon from "./icon"

export type DropdownItemProps = Omit<ButtonBaseProps, "className" | "type"> & ButtonOrLinkProps & { title: string }

export type Props = Pick<ButtonBaseProps, "icon"> & {
  actions: (DropdownItemProps | "divider")[]
  alignment?: "left" | "right"
  autoClose?: boolean
  tooltip?: string
} & ButtonType & { disabled?: boolean }

export interface State {
  open: boolean
}

const dropdownButtonWrapperClass = css({
  display: "inline-block",
  position: "relative",
})

const dropdownButtonListClass = css({
  margin: 0,
  position: "absolute",

  zIndex: 10000,

  backgroundColor: getThemeColorVar("white", undefined),
  boxShadow: "0px 6px 32px 0px rgba(0, 0, 0, 0.21)",
  borderRadius: "5px",

  marginTop: "4px",
  padding: "12px 0",

  hr: {
    marginTop: "4px",
    marginBottom: "4px",
  },
})

const alignmentClasses: { [key in "left" | "right"]: string } = {
  left: css({
    left: 0,
  }),
  right: css({
    right: 0,
  }),
}

const dropdownButtonClass = css({
  paddingRight: 0,

  "> button, > a": {
    paddingRight: 0,
  },
})

const listItemClass = css({
  listStyle: "none",

  ":hover": {
    backgroundColor: getThemeColorVar("background", "lighter"),
  },
  padding: 4,

  "button, a": {
    padding: "0 24px",
    border: "0px",
    display: "block",
    textAlign: "left",
  },

  "~ li": {
    paddingTop: "8px",
  },
})

export class DropdownButton extends React.PureComponent<PropsWithChildren<Props>, State> {
  static defaultProps: Pick<Props, "alignment"> = {
    alignment: "right",
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  componentDidUpdate() {
    if (this.props.autoClose)
      this.setState({
        open: false,
      })
  }

  componentWillMount() {
    document.addEventListener("click", this.handleOutsideClick.bind(this))
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick.bind(this))
  }

  @bind
  renderItem(item: DropdownItemProps | "divider", index: number) {
    if (item === "divider") {
      return <hr key={index} />
    } else {
      const { title, ...props } = item
      const enriched: ButtonProps = {
        ...props,
        type: "secondary",
      }

      return (
        <li key={index} className={cx(listItemClass)}>
          <Button
            {...enriched}
            onClick={(e) => {
              enriched.onClick && enriched.onClick(e)
              this.setState({ open: false })
            }}
          >
            {title}
          </Button>
        </li>
      )
    }
  }

  @bind
  handleOutsideClick(event: React.MouseEvent<HTMLElement>): void {
    try {
      const domNode: Element | Text | null = ReactDOM.findDOMNode(this)

      if (domNode && domNode.contains && !domNode.contains(event.target as Node)) {
        this.setState({
          open: false,
        })
      }
    } catch (e) {
      // if we are brutally unmounted the above code will explode
    }
  }

  @bind renderButton() {
    return (
      <div className={cx(dropdownButtonClass)}>
        <Button {...this.props} onClick={() => this.setState({ open: !this.state.open })}>
          {this.props.children}
          <Icon name={this.state.open ? "arrow_drop_up" : "arrow_drop_down"} />
        </Button>
      </div>
    )
  }

  render() {
    const { actions, ...props } = this.props

    const listClasses = [dropdownButtonListClass]
    this.props.alignment && listClasses.push(alignmentClasses[this.props.alignment])

    return (
      <div className={cx(dropdownButtonWrapperClass)}>
        {props.tooltip && (
          <RevoTooltip text={props.tooltip} position="down">
            {this.renderButton()}
          </RevoTooltip>
        )}
        {!props.tooltip && this.renderButton()}
        {this.state.open && <ul className={cx(listClasses)}>{actions.map(this.renderItem)}</ul>}
      </div>
    )
  }
}

export default DropdownButton
