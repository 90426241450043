import { Flex } from "../../../shared/components/ui/flex"
import { FlexItem } from "../../../shared/components/ui/flex-item"
import Grid from "../../../shared/components/restyle-grid/grid"
import { ScrollBox } from "../../../shared/components/ui/scroll-box"
import React, { useCallback, useEffect, useState } from "react"
import { css, cx } from "emotion"
import { translations } from "../../i18n"
import { DownloadReport } from "../../../shared/components/ui/download-report"
import Axios, { AxiosResponse } from "axios"
import { lanaApiUrl, mapProxyUrl } from "../../../app_config"
import { WmsLayer } from "../../../shared/models/wms-layer"
import { useDrop, XYCoord } from "react-dnd"
import { MapLegend } from "./map-legend"
import { IsochroneType } from "../isochrone-type"
import { trackUsageEvent } from "../../../utils/usage-tracking"
import update from "immutability-helper"
import { SpecialMapsPopup } from "./special-maps-popup"
import useInterval from "../../../utils/use-interval"
import SpecialMapsMap from "./special-maps-map"
import { AppModules } from "../../../menu/util/app-location-types"
import { loadAssessmentForAssessmentModule } from "../../reducers/comparables-slice"
import { useAppSelector } from "../../../relas/store"
import {
  updateSpecialMapsInputIsochronePolygon,
  updateSpecialMapsInputIsochroneSettings,
  updateSpecialMapsInputSelectedLayer,
} from "../../reducers/special-maps-slice"
import { DocumentationAndSupport } from "../../../shared/components/documentation-and-support"
import { InvalidAddressMessage } from "../../../shared/components/ui/invalid-address-message"
import { getThemeColor, getThemeColorVar } from "../../../shared/helper/color"
import { language } from "../../../shared/language"
import LanaSubheader from "../../../shared/components/lana-subheader"

const styles = {
  stickyHeader: css({
    position: "sticky",
    top: 0,
    background: "white",
    zIndex: 100,
  }),
  borderGrey: css({
    borderRight: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
  }),
  notifierStyle: css({
    display: "flex",
    flexDirection: "row",
    gap: "32px",
    backgroundColor: getThemeColorVar("background", "dark"),
    color: "white",
    width: "inherit",
    position: "sticky",
    marginTop: "-64px",
    zIndex: 1005,
    bottom: 0,
    transition: "max-height 1s",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.9,
    overflow: "hidden",
    height: "64px",
  }),
  loadingAssessment: css({
    minHeight: "100%",
    padding: "1em",
    backgroundColor: "rgb(229, 227, 223)",
  }),
  contentGrid: css({
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 300px",
  }),
  listGrid: css({
    height: "100%",
    display: "grid",
    gridTemplateRows: "min-content min-content min-content 1fr",
    overflow: "auto",
  }),
  listTitle: css({
    padding: "20px 16px",
    borderBottom: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
    backgroundColor: `${getThemeColor("background", "light")}`,
    fontWeight: "bold",
  }),
  mapNameLine: css({
    padding: "12px 16px",
    borderBottom: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
    cursor: "default",
  }),
  container: css({
    height: "100%",
    position: "relative",
    overflow: "hidden",
  }),
  layerStyles: css({
    position: "fixed",
    pointerEvents: "none",
    zIndex: 100,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
  }),
}
export interface WMSLayerWithRegion extends WmsLayer {
  region: string
  mapName: string
}

const lang = language()

interface Props {
  assessmentId: string
  assessmentEntryId: string | null
  module: AppModules["locationAssessment"]
}

export type DraggableBoxesType = "legend" | "mapPopup"

export interface DragItem {
  id: DraggableBoxesType
  left: number
  top: number
}

export interface BoxMap {
  legend: DragItem
  mapPopup: DragItem
}

export const ItemTypes = {
  BOX: "box",
}

export const initialBoxes: BoxMap = {
  legend: {
    id: "legend",
    left: 10,
    top: 150,
  },
  mapPopup: {
    id: "mapPopup",
    left: 0,
    top: 0,
  },
}

const documentationLink = "https://docs.google.com/document/d/1Y-fiii9F1VDV4GahtkUVsp2AsSACuEhEvo6IqILu6AA"

export const SpecialMaps = ({ module }: Props) => {
  const t = React.useMemo(translations, [translations])

  const assessment = useAppSelector((state) => state.assessment.currentAssessment)
  const assessmentEntry = useAppSelector((state) => state.assessment.currentAssessmentEntry)

  const assessmentEntryLoadError = useAppSelector((state) => state.assessment.assessmentEntryLoadError)
  const assessmentLoadError = useAppSelector((state) => state.assessment.assessmentLoadError)

  const specialMapsInput = useAppSelector((state) => state.specialMapsApp.specialMapsInput)

  const [availableWMSLayers, setAvailableWMSLayers] = useState<WMSLayerWithRegion[]>([])
  const [unavailableWMSLayers, setUnavailableWMSLayers] = useState<WMSLayerWithRegion[]>([])

  const [selectedWMSLayer, setSelectedWMSLayer] = useState<WmsLayer | null>(specialMapsInput.selectedLayer)
  const [popupCoords, setPopupCoords] = useState<{ lat: number; lng: number } | null>(null)
  const [popupZoom, setPopupZoom] = useState<number>()
  const [boxes, setBoxes] = useState<BoxMap>(initialBoxes)

  // assessment creation from Special maps module
  useInterval(
    () => {
      if (assessment && assessmentEntry) {
        Axios.get(
          `${lanaApiUrl}/api/assessments/${encodeURIComponent(assessment.id)}/entries/${encodeURIComponent(
            assessmentEntry.id
          )}`
        ).then(
          (success: AxiosResponse) => {
            void loadAssessmentForAssessmentModule(assessment.id, assessmentEntry.id, "specialMaps")
          },
          () => {}
        )
      }
    },
    assessment &&
      (assessment.state === "scored" || assessment.state === "scored-ambiguous" || assessment.state === "failure")
      ? null
      : 2000
  )

  const assessmentLoaded = assessment && assessmentEntry
  const dataLoadingError = assessmentEntryLoadError || assessmentLoadError
  const markerLocation = assessmentEntry?.address.location

  useEffect(() => {
    if (assessmentEntry?.state !== "failure" && markerLocation) {
      Axios.get(`${mapProxyUrl}/api/maps`, {
        withCredentials: false,
      }).then(
        (success: AxiosResponse) => {
          let WMSLayers: WMSLayerWithRegion[] = []
          if (success.data.length > 0) {
            success.data
              .filter((layer: WmsLayer) => layer.active)
              .forEach((layer: WmsLayer) => {
                const lines = t.pickTranslation(layer.name).split(" ")
                switch (layer.id) {
                  case "nrw_ruhrBBP":
                  case "nrw_ruhrFNP":
                  case "rlpBRW":
                  case "rlpPV":
                  case "ndsBRW":
                  case "nds_AL":
                    lang == "en"
                      ? WMSLayers.push({
                          ...layer,
                          region: lines.slice(0, 2).join(" "),
                          mapName: lines.slice(2).join(" "),
                        })
                      : WMSLayers.push({ ...layer, region: lines[0], mapName: lines.slice(1).join(" ") })
                    break
                  case "bayern_regionregensburgBBP":
                  case "bawueFNP":
                    WMSLayers.push({ ...layer, region: lines.slice(0, 2).join(" "), mapName: lines.slice(2).join(" ") })
                    break
                  case "mvBBP":
                  case "mvBRW":
                  case "mvFNP":
                  case "mvALKIS":
                    lang === "en"
                      ? WMSLayers.push({
                          ...layer,
                          region: lines.slice(0, 3).join(" "),
                          mapName: lines.slice(3).join(" "),
                        })
                      : WMSLayers.push({
                          ...layer,
                          region: lines.slice(0, 2).join(" "),
                          mapName: lines.slice(2).join(" "),
                        })
                    break
                  default:
                    WMSLayers.push({ ...layer, region: lines[0], mapName: lines.slice(1).join(" ") })
                    break
                }
              })
            WMSLayers.sort((a, b) => a.mapName.localeCompare(b.mapName))
          }
          Axios.get(`${mapProxyUrl}/api/search/maps_by_location`, {
            params: { lat: markerLocation.lat, lng: markerLocation.lng },
            withCredentials: false,
          }).then(
            (success: AxiosResponse) => {
              const mapsInLocation = new Set(success.data.maps)
              let availableLayers: WMSLayerWithRegion[] = []
              let unavailableLayers: WMSLayerWithRegion[] = []
              WMSLayers.forEach((map) => {
                mapsInLocation.has(map.id) ? availableLayers.push(map) : unavailableLayers.push(map)
              })
              setAvailableWMSLayers(availableLayers)
              setUnavailableWMSLayers(unavailableLayers)
            },
            () => {}
          )
        },
        () => {}
      )
    }
  }, [markerLocation])

  useEffect(() => {
    if (specialMapsInput.selectedLayer === null && selectedWMSLayer !== null) setSelectedWMSLayer(null)
  }, [specialMapsInput.selectedLayer])

  const moveBox = useCallback(
    (id: DraggableBoxesType, left: number, top: number) => {
      setBoxes(
        update(boxes, {
          [id]: {
            $merge: { left, top },
          },
        })
      )
    },
    [boxes, setBoxes]
  )

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop(item: DragItem, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord
        let left = Math.round(item.left + delta.x)
        let top = Math.round(item.top + delta.y)
        moveBox(item.id, left, top)
        return undefined
      },
    }),
    [moveBox]
  )

  const renderMapName = (layer: WMSLayerWithRegion) => {
    return (
      <div>
        <div style={{ fontWeight: "bold" }}>{layer.mapName}</div>
        <div>{layer.region}</div>
      </div>
    )
  }

  const onSpecialMapSelect = (layer: WmsLayer) => {
    if (selectedWMSLayer?.id === layer.id) {
      setSelectedWMSLayer(null)
      updateSpecialMapsInputSelectedLayer(null)
    } else {
      setSelectedWMSLayer(layer)
      updateSpecialMapsInputSelectedLayer(layer)
      assessmentEntry &&
        trackUsageEvent("SPECIAL_MAPS_SHOW_MAP", assessmentEntry?.address, t.pickTranslation(layer.name))
      setPopupCoords(null)
    }
  }

  const renderUnavailableMapsList = () => {
    let unavailableWMSGroupedByRegion: { [key: string]: WMSLayerWithRegion[] } = {}
    unavailableWMSLayers.forEach((layer) => {
      layer.region in unavailableWMSGroupedByRegion
        ? unavailableWMSGroupedByRegion[layer.region].push(layer)
        : (unavailableWMSGroupedByRegion = { ...unavailableWMSGroupedByRegion, [layer.region]: [layer] })
    })

    return (
      <>
        <div className={styles.listTitle}>{t.specialMaps.furtherSpecialMaps}</div>
        <ScrollBox minHeight={"60px"}>
          {Object.keys(unavailableWMSGroupedByRegion)
            .sort((a, b) => a.localeCompare(b))
            .map((key, id) => (
              <Flex key={`${key}-${id}`} flexDirection={"column"} padding={[12, 16, 0, 16]} gap={4}>
                <FlexItem>
                  <div style={{ fontWeight: "bold", color: `${getThemeColor("disabled", "dark")}` }}>{key}</div>
                  {unavailableWMSGroupedByRegion[key].map((layer, id) => (
                    <div
                      key={`unavailableRegionName-${id}`}
                      style={{ paddingLeft: "8px", color: `${getThemeColor("disabled", "dark")}` }}
                    >
                      {layer.mapName}
                    </div>
                  ))}
                </FlexItem>
              </Flex>
            ))}
        </ScrollBox>
      </>
    )
  }

  const renderContent = () => {
    return (
      <div ref={drop} className={styles.container}>
        {selectedWMSLayer && (
          <MapLegend id={"legend"} left={boxes.legend.left} top={boxes.legend.top} layer={selectedWMSLayer} />
        )}

        {popupCoords && (
          <SpecialMapsPopup
            left={boxes.mapPopup.left}
            top={boxes.mapPopup.top}
            id={"mapPopup"}
            selectedLayer={selectedWMSLayer}
            zoom={popupZoom}
            popupCoords={popupCoords}
            onClose={() => setPopupCoords(null)}
          />
        )}

        <div className={styles.contentGrid}>
          <div className={styles.borderGrey}>
            {!assessmentLoaded && <div className={styles.loadingAssessment} />}

            {assessmentLoaded && (
              <SpecialMapsMap
                assessmentEntry={assessmentEntry}
                showControls={true}
                selectedLayer={selectedWMSLayer}
                fitToBounds={"none"}
                isochroneSettings={specialMapsInput.isochroneSettings}
                setIsochroneSettings={(isochroneSettings: IsochroneType) =>
                  updateSpecialMapsInputIsochroneSettings(isochroneSettings)
                }
                isochronePolygon={specialMapsInput.isochronePolygon}
                setIsochronePolygon={(isochronePolygon: google.maps.Polygon) =>
                  updateSpecialMapsInputIsochronePolygon(isochronePolygon)
                }
                popupCoords={popupCoords}
                setPopupCoords={setPopupCoords}
                popupZoom={popupZoom}
                setPopupZoom={setPopupZoom}
                boxes={boxes}
                setBoxes={setBoxes}
              />
            )}

            {dataLoadingError && (
              <div className={styles.notifierStyle}>
                <span style={{ padding: "8px" }}>{t.specialMaps.error}</span>
              </div>
            )}
          </div>

          <div className={styles.listGrid}>
            <div className={styles.listTitle}>{t.specialMaps.availableSpecialMaps}</div>
            <div>
              {availableWMSLayers.map((layer, id) => (
                <div
                  key={`mapLayerDiv-${id}`}
                  className={
                    layer.id === selectedWMSLayer?.id
                      ? cx(styles.mapNameLine, css({ backgroundColor: `${getThemeColor("secondary2", "light")}` }))
                      : styles.mapNameLine
                  }
                  onClick={() => onSpecialMapSelect(layer)}
                >
                  {renderMapName(layer)}
                </div>
              ))}
            </div>
            {renderUnavailableMapsList()}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div style={{ overflow: "overlay" }}>
      <Grid columns={1} rowSpec="fit-content(100%) minmax(0, 1fr)" className={css({ height: "calc(100vh - 50px)" })}>
        <div className={styles.stickyHeader}>
          <LanaSubheader
            menuSection={"locationAssessment"}
            assessment={assessment}
            assessmentEntry={assessmentEntry}
            module={module}
          >
            <DocumentationAndSupport
              documentationURL={documentationLink}
              addDocumentationLink
              tooltip={t.helpAndSupport}
              callLocation="Special Maps"
              onClick={() => {}}
            />
            <DownloadReport />
          </LanaSubheader>
        </div>
        <InvalidAddressMessage assessmentEntry={assessmentEntry} />
        {assessmentEntry && assessmentEntry.state !== "failure" && renderContent()}
      </Grid>
    </div>
  )
}

export default SpecialMaps
