import * as React from "react"
import { FC, useMemo, useState } from "react"
import { Grid } from "../../../shared/components/ui/grid"
import { GridItem } from "../../../shared/components/ui/grid-item"
import { translations } from "../../i18n"
import { useAppDispatch, useAppSelector } from "../../../relas/store"
import { ratingManagerActions } from "../../rating-manager-slice"
import { FieldPath, useFieldArray, useForm, useFormState } from "react-hook-form"
import { GradeColorSelect } from "../ui/grade-color-select"
import { cx } from "emotion"
import { doUpdateRating } from "../../rating-manager-actions"
import { ratingStyles } from "../rating-styles"
import { nonEmptyTrimmed } from "../../../shared/models/form-validators"
import { RATING_GRADE_COLORS, RatingGrade } from "../../../shared/models/rating-grade"
import { isMetaRating, MetaRating, MetaRatingRange } from "../../../shared/models/ratings"
import { RatingDetail } from "../../../explorer/models/rating"
import { NumberInput } from "../../../shared/components/ui/number-input"
import Panel from "../../../shared/components/panel"
import Button from "../../../shared/components/button"
import TextField from "../../../shared/components/textfield"
import { GenericError } from "../../../shared/helper/axios"

type FormInputs =
  | {
      name: string
      grades: RatingGrade[]
    }
  | {
      name: string
      ranges: MetaRatingRange[]
    }

export const RatingManagerEditRatingForm: FC = () => {
  const t = useMemo(() => translations(), [])

  const rating = useAppSelector((state) => state.ratingManager.currentRating)

  const { handleSubmit, watch, register, clearErrors, setValue, control, setError } = useForm<FormInputs>({
    defaultValues: {
      ...rating,
    },
  })
  const onValueChangeUseForm = (field: FieldPath<FormInputs>) => (num: number, _: string, valid: boolean) => {
    if (valid) {
      clearErrors(field)
      setValue(field, num)
    } else {
      setError(field, { type: "manual" })
    }
  }

  const { isSubmitting, isValid } = useFormState({ control })

  const gradeFields = useFieldArray({ control, name: "grades" })

  const rangesFields = useFieldArray({ control, name: "ranges" })

  const [createError, setCreateError] = useState<GenericError | undefined>(undefined)

  const dispatch = useAppDispatch()

  async function onSubmit(data: FormInputs) {
    if (!rating) {
      return
    }

    let updatedRating: MetaRating | RatingDetail | undefined
    if (isMetaRating(rating) && "ranges" in data) {
      updatedRating = {
        ...rating,
        name: data.name,
        ranges: data.ranges,
      }
    } else if ("grades" in data) {
      data.grades.forEach((g) => (g.label = g.label.trim()))

      updatedRating = {
        ...rating,
        name: data.name,
        grades: data.grades,
      }
    }

    if (updatedRating) {
      const result = await doUpdateRating(dispatch)(updatedRating)
      if ("id" in result) {
        dispatch(ratingManagerActions.setCurrentRating(result))
        dispatch(ratingManagerActions.setMenuPane("edit"))
      } else {
        setCreateError(result)
      }
    }
  }

  if (!rating) {
    dispatch(ratingManagerActions.setMenuPane("list"))
    return null
  }

  return (
    <form
      className={ratingStyles.container}
      style={{ height: "100%" }}
      onSubmit={handleSubmit(onSubmit, (e, ev) => ev?.preventDefault())}
      onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
    >
      <div>
        <Button
          type="tertiary"
          icon="back"
          onClick={(e) => {
            e.preventDefault()
            dispatch(ratingManagerActions.setMenuPane("edit"))
          }}
        >
          {rating.name}
        </Button>
      </div>
      <h2>{t.edit}</h2>
      <hr />
      <div className={cx(ratingStyles.fieldName, ratingStyles.noTopMargin)}>{t.createForm.titleField}</div>
      <TextField
        {...register("name", { required: true, validate: nonEmptyTrimmed })}
        onValueChange={(v, isValid) => setValue("name", v, { shouldValidate: true })}
        value={watch("name")}
      />
      {isMetaRating(rating) && (
        <>
          <fieldset className={ratingStyles.fieldset}>
            <legend className={ratingStyles.fieldName}>{t.createForm.metaGrades.label}</legend>
            <Grid columnSpec="min-content 1fr 1fr min-content min-content" gap={8} alignItems="center">
              <div></div>
              <div>{t.createForm.metaGrades.from}</div>
              <div>{t.createForm.metaGrades.to}</div>
              <div>{t.color}</div>
              <div></div>
              {rangesFields.fields.map((grade, index) => (
                <React.Fragment key={grade.id}>
                  <div style={{ whiteSpace: "nowrap" }}>{index + 1}</div>
                  <NumberInput
                    defaultValue={watch(`ranges.${index}.from` as const)}
                    onValueChange={onValueChangeUseForm(`ranges.${index}.from` as const)}
                  />
                  <NumberInput
                    defaultValue={watch(`ranges.${index}.to` as const)}
                    onValueChange={onValueChangeUseForm(`ranges.${index}.to` as const)}
                  />

                  <GradeColorSelect
                    value={watch(`ranges.${index}.color` as const)}
                    {...register(`ranges.${index}.color` as const)}
                    onValueChange={(color) => {
                      setValue(`ranges.${index}.color` as const, color)
                    }}
                  />
                  {index === rangesFields.fields.length - 1 ? (
                    <Button
                      type="tertiary"
                      formType={"button"}
                      icon="delete"
                      size="small"
                      disabled={rangesFields.fields.length === 1}
                      onClick={() => {
                        rangesFields.remove(index)
                      }}
                    />
                  ) : (
                    <div />
                  )}
                </React.Fragment>
              ))}
              <GridItem colSpan={4}>
                <Button
                  type="tertiary"
                  formType={"button"}
                  icon="add"
                  disabled={rangesFields.fields.length >= 10}
                  onClick={() => {
                    rangesFields.append({
                      color: RATING_GRADE_COLORS[rangesFields.fields.length],
                    })
                  }}
                >
                  {t.createForm.metaGrades.addRange}
                </Button>
              </GridItem>
            </Grid>
          </fieldset>
        </>
      )}
      {!isMetaRating(rating) && (
        <fieldset className={ratingStyles.fieldset}>
          <legend className={ratingStyles.fieldName}>{t.createForm.gradesField}</legend>
          <Grid columnSpec="min-content 1fr 55px min-content" gap={8} alignItems="center">
            <div></div>
            <div>{t.label}</div>
            <div>{t.createForm.valueField}</div>
            <div>{t.color}</div>
            {gradeFields.fields.map((grade, index) => (
              <React.Fragment key={grade.id}>
                <div style={{ whiteSpace: "nowrap" }}>{index + 1}</div>
                <TextField
                  value={watch(`grades.${index}.label` as const)}
                  size="small"
                  {...register(`grades.${index}.label` as const, { validate: nonEmptyTrimmed })}
                  onValueChange={(v) => setValue(`grades.${index}.label` as const, v, { shouldValidate: true })}
                />

                <NumberInput
                  defaultValue={watch(`grades.${index}.value` as const)}
                  onValueChange={onValueChangeUseForm(`grades.${index}.value` as const)}
                />

                <GradeColorSelect
                  value={watch(`grades.${index}.color` as const)}
                  {...register(`grades.${index}.color` as const)}
                  onValueChange={(color) => {
                    setValue(`grades.${index}.color` as const, color)
                  }}
                />
              </React.Fragment>
            ))}
          </Grid>
        </fieldset>
      )}
      {createError && <Panel color="negative">{t.ratingAlreadyExists}</Panel>}
      <div className={ratingStyles.spacer}></div>
      <div className={ratingStyles.submitRow}>
        <Button type="primary" disabled={!isValid} loading={isSubmitting}>
          {t.ok}
        </Button>
      </div>
    </form>
  )
}
