import React, { useEffect, useState } from "react"
import { translations } from "../i18n"
import { TextArea } from "@blueprintjs/core"
import Axios, { AxiosResponse } from "axios"
import { lanaApiUrl } from "../../app_config"
import { css } from "emotion"
import { trackUsageEvent } from "../../utils/usage-tracking"
import { AssessmentEntryFull } from "../../assessment/models/assessment"
import { trimText } from "../../utils/utils"
import Dialog from "./dialog"
import DialogFooter from "./dialogfooter"
import Panel from "./panel"
import Button from "./button"

const styles = {
  footer: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    gap: "8px",
  }),
}

interface Props {
  assessmentId: string
  assessmentEntry: AssessmentEntryFull
  onClose: () => void
}

interface NotesResponse {
  version: number
  content: string
}

export const AssessmentEntryNotes = (props: Props) => {
  const t = translations()
  const [notes, setNotes] = useState("")
  const [notesVersion, setNotesVersion] = useState<number>(0)
  const [notesSavingError, setNotesSavingError] = useState(false)

  useEffect(() => {
    Axios.get(`${lanaApiUrl}/api/assessments/${props.assessmentId}/entries/${props.assessmentEntry.id}/note`).then(
      (success: AxiosResponse<NotesResponse>) => {
        setNotes(success.data.content)
        setNotesVersion(success.data.version)
      },
      () => {}
    )
  }, [])

  const onSave = () => {
    Axios.post(`${lanaApiUrl}/api/assessments/${props.assessmentId}/entries/${props.assessmentEntry.id}/note`, {
      version: notesVersion + 1,
      content: notes.trim(),
    }).then(
      () => {
        props.onClose()
        trackUsageEvent("ASSESSMENT_ENTRY_NOTES", props.assessmentEntry.address, "Save")
      },
      () => setNotesSavingError(true)
    )
  }

  const onClose = () => {
    props.onClose()
    trackUsageEvent("ASSESSMENT_ENTRY_NOTES", props.assessmentEntry.address, "Cancel")
  }

  return (
    <Dialog id={"assessmentEntryNotes"} onClose={props.onClose} closeOnClickOutside={true} withoutPortal={true}>
      <div style={{ padding: "16px", height: "250px" }}>
        <TextArea
          style={{ padding: "8px", height: "100%", resize: "none" }}
          fill={true}
          placeholder={t.notesPlaceholder}
          value={notes}
          onChange={(v) => setNotes(trimText(v.target.value, 65536))}
        ></TextArea>
      </div>

      <DialogFooter paddingX="lg" spaceBetween="lg" md-justify="end" paddingY="lg" direction="column">
        <div className={styles.footer}>
          <Button type="secondary" onClick={() => onClose()}>
            {t.cancel}
          </Button>
          <Button type="primary" onClick={onSave} disabled={notesSavingError}>
            {t.save}
          </Button>
        </div>
        {notesSavingError && (
          <div style={{ width: "530px" }}>
            <Panel color="negative">{t.notesSavingError}</Panel>
          </div>
        )}
      </DialogFooter>
    </Dialog>
  )
}
