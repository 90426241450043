/* tslint:disable */
/* eslint-disable */
/**
 * Comparables Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum RealEstateType {
    ApartmentRent = 'APARTMENT_RENT',
    ApartmentBuy = 'APARTMENT_BUY',
    HouseRent = 'HOUSE_RENT',
    HouseBuy = 'HOUSE_BUY',
    GarageRent = 'GARAGE_RENT',
    GarageBuy = 'GARAGE_BUY',
    LivingRentSite = 'LIVING_RENT_SITE',
    LivingBuySite = 'LIVING_BUY_SITE',
    TradeSite = 'TRADE_SITE',
    HouseType = 'HOUSE_TYPE',
    FlatShareRoom = 'FLAT_SHARE_ROOM',
    SeniorCare = 'SENIOR_CARE',
    AssistedLiving = 'ASSISTED_LIVING',
    Office = 'OFFICE',
    Industry = 'INDUSTRY',
    Store = 'STORE',
    Gastronomy = 'GASTRONOMY',
    SpecialPurpose = 'SPECIAL_PURPOSE',
    Investment = 'INVESTMENT',
    CompulsoryAuction = 'COMPULSORY_AUCTION',
    ShortTermAccommodation = 'SHORT_TERM_ACCOMMODATION'
}



