import { HTMLSelect } from "@blueprintjs/core"
import { PopupMenu } from "../../../shared/components/ui/popup-menu"
import PoiCategoriesList, { localPublicTransportCategory } from "./poi-categories-list"
import React, { useEffect, useMemo, useState } from "react"
import { translations } from "../../i18n"
import { css } from "emotion"
import { UserDefinedPOIGroup } from "./poi-explorer"
import { trackUsageEvent } from "../../../utils/usage-tracking"
import { TextField } from "../../../shared/components/text-field"
import { GridItem } from "../../../shared/components/ui/grid-item"
import {
  stringFromTextSearchObject,
  textSearchObjectFromString,
  EmptyTextSearchIds,
} from "../../../shared/models/poi-explorer"
import { updatePoiTextSearch } from "../../reducers/poi-explorer-slice"
import { useAppSelector } from "../../../relas/store"
import Grid from "../../../shared/components/restyle-grid/grid"
import { getThemeColor } from "../../../shared/helper/color"
import Button from "../../../shared/components/button"

const styles = {
  dropdown: css({
    marginBottom: 8,
    borderRadius: 4,
    "select:enabled": {
      backgroundColor: "white",
    },
  }),
  poiCategoriesList: css({
    borderTop: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
  }),
}

interface Props {
  setSelectedPOIGroup: (selectedGroup: string) => void
  setSelectedPOICategories: (selectedCategories: Set<string>) => void
  onNewPOIGroupCreate: () => void
  onPOIGroupEdit: (groupToEdit: string, selectedCategories: Set<string>) => void
  setPoiGroupToDelete: (groupId: string) => void
}

const POIInitialSettings = ({
  setSelectedPOIGroup,
  setSelectedPOICategories,
  onNewPOIGroupCreate,
  onPOIGroupEdit,
  setPoiGroupToDelete,
}: Props) => {
  const t = useMemo(translations, [translations])

  const assessmentEntry = useAppSelector((state) => state.assessment.currentAssessmentEntry)
  const userDefinedPOIGroups = useAppSelector((state) => state.POIExplorerApp.userDefinedPOIGroups)
  const poiGroupIdToRedirect = useAppSelector((state) => state.POIExplorerApp.poiGroupIdToRedirect)
  const selectedPOICategories = useAppSelector((state) => state.POIExplorerApp.poiExplorerInput.categories)
  const selectedPOIGroup = useAppSelector((state) => state.POIExplorerApp.poiExplorerInput.group)
  const poiTextSearch = useAppSelector((state) => state.POIExplorerApp.poiExplorerInput.poiTextSearch)

  const [textSearch, setTextSearch] = useState(stringFromTextSearchObject(poiTextSearch))

  const userDefinedPOIGroupsDropdownOptions = userDefinedPOIGroups
    ? [...userDefinedPOIGroups]
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((item) => {
          return { value: item.id, label: item.name }
        })
    : []

  const openPOIGroup = (poiGroupId: string) => {
    setSelectedPOIGroup(poiGroupId)
    setSelectedPOICategories(new Set())

    if (poiGroupId !== "manual-selection") {
      const selectedPOICategoriesGroup: UserDefinedPOIGroup | undefined = userDefinedPOIGroups?.find(
        (item) => item.id === poiGroupId
      )
      if (selectedPOICategoriesGroup) setSelectedPOICategories(new Set(selectedPOICategoriesGroup.categories))
    }
  }

  useEffect(() => {
    if (poiGroupIdToRedirect) openPOIGroup(poiGroupIdToRedirect)
  }, [poiGroupIdToRedirect])

  const onPOICategoriesGroupChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPOIGroup = userDefinedPOIGroups?.find((group) => group.id === e.currentTarget.value)
    const categoriesInGroup = selectedPOIGroup?.categories.includes("bus_station")
      ? selectedPOIGroup.categories
          .filter((cat) => !localPublicTransportCategory.has(cat))
          .concat(["local_public_transport"])
      : selectedPOIGroup?.categories
    assessmentEntry &&
      selectedPOIGroup &&
      categoriesInGroup &&
      trackUsageEvent(
        "POI_EXPLORER_SELECT_CATEGORY_GROUP",
        assessmentEntry.address,
        `${selectedPOIGroup.name} - ${categoriesInGroup.join(", ")}`
      )
    openPOIGroup(e.currentTarget.value)
  }

  const trackPOITextSearch = () =>
    assessmentEntry?.address && trackUsageEvent("POI_EXPLORER_SEARCH_TEXT", assessmentEntry.address, textSearch)

  return (
    <div style={{ display: "grid", gridTemplateRows: "min-content min-content 1fr", height: "100%" }}>
      <Grid padding={[16, 16, 0, 16]} columns={2} columnSpec={"215px 1fr"}>
        <TextField
          value={textSearch}
          placeholder={t.poiExplorer.poiQuickSearch}
          onValueChange={(text) => {
            text === "" && updatePoiTextSearch(EmptyTextSearchIds())
            setTextSearch(text)
          }}
          onKeyDown={(key) => {
            if (key.key === "Enter") {
              updatePoiTextSearch(textSearchObjectFromString(textSearch))
              trackPOITextSearch()
            }
          }}
        />
        <GridItem padding={[0, 0, 0, 12]}>
          <Button
            icon="search"
            type="primary"
            inverted
            onClick={() => {
              updatePoiTextSearch(textSearchObjectFromString(textSearch))
              trackPOITextSearch()
            }}
            size={"small"}
          ></Button>
        </GridItem>
      </Grid>

      <Grid padding={16} gap={8} columns={2} columnSpec={"215px 1fr"}>
        <HTMLSelect
          fill
          className={styles.dropdown}
          style={{ borderRadius: 4 }}
          iconProps={{
            icon: "chevron-down",
            iconSize: 16,
            color: getThemeColor("primary", "default").toString(),
          }}
          options={[
            { value: "manual-selection", label: t.poiExplorer.manualSelection },
            ...userDefinedPOIGroupsDropdownOptions,
          ]}
          value={selectedPOIGroup}
          onChange={onPOICategoriesGroupChange}
        />
        <PopupMenu
          actions={[
            {
              title: t.poiExplorer.createGroup,
              onClick: onNewPOIGroupCreate,
            },
            {
              title: t.poiExplorer.editGroup,
              onClick: () => onPOIGroupEdit(selectedPOIGroup, new Set(selectedPOICategories)),
              disabled: selectedPOIGroup === "manual-selection",
            },
            {
              title: t.poiExplorer.deleteGroup,
              onClick: () => setPoiGroupToDelete(selectedPOIGroup),
              disabled: selectedPOIGroup === "manual-selection",
            },
          ]}
        />
      </Grid>
      <div className={styles.poiCategoriesList}>
        <PoiCategoriesList
          selectedPOICategories={new Set(selectedPOICategories)}
          setSelectedPOICategories={setSelectedPOICategories}
          selectedPOIGroup={selectedPOIGroup !== "manual-selection" ? selectedPOIGroup : undefined}
          textSearch={null}
          trackPOICategorySelection={true}
        />
      </div>
    </div>
  )
}

export default POIInitialSettings
