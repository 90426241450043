import * as cookie from "cookie"

let lastCookieStr = ""
let lastCookies: { [name: string]: string } = {}

export function getCookieValue(cookieName: string): string | null {
  const cookieStr = document && document.cookie

  if (cookieStr !== lastCookieStr) {
    lastCookieStr = cookieStr
    lastCookies = cookie.parse(cookieStr)
  }

  return cookieName in lastCookies ? lastCookies[cookieName] : null
}

export function setCookie(cookieName: string, cookieValue: string, expires: Date, domain: string) {
  document.cookie = cookie.serialize(cookieName, cookieValue, {
    path: "/",
    secure: false,
    domain: domain,
    expires: expires,
  })
}
