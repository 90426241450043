import * as React from "react"
import { css } from "emotion"
import { PropsWithChildren } from "react"
import { getThemeColorVar } from "../../helper/color"

export interface Props {}

const reCardClass = css({
  backgroundColor: getThemeColorVar("white", undefined),
  display: "grid",
  borderRadius: "5px",
  border: `1px solid ${getThemeColorVar("border", "default")}`,
})

export const BareCard: React.FunctionComponent<PropsWithChildren<Props>> = (props) => {
  return <div className={reCardClass}>{props.children}</div>
}
