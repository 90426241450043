import * as React from "react"
import { FC } from "react"
import { css } from "emotion"
import { getThemeColor } from "../../shared/helper/color"

const emptyContentClass = css({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  span: {
    margin: 0,
    fontSize: "12px",
    color: getThemeColor("typo", "light").toString(),
  },
})

interface Props {
  description?: string
  fontSize?: number
}

export const NoContentDisclaimer: FC<React.PropsWithChildren<Props>> = ({ description, fontSize, children }) => {
  return (
    <div className={emptyContentClass}>
      {description ? <span style={{ fontSize: fontSize ? fontSize + "px" : "inherit" }}>{description}</span> : children}
    </div>
  )
}
