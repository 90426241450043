import React, { useEffect } from "react"
import { FilterProps } from "../comparablesV2"
import { fetchComparables } from "../../../reducers/comparables-slice"
import { HistoricalSeniorLivingInputForm } from "./historical-senior-living-filters-model"

export const HistoricalSeniorLivingFilters = ({ assessmentEntry }: FilterProps) => {
  const seniorLivingDatasourceFormData: HistoricalSeniorLivingInputForm = {
    dataSource: "historical-21st-senior-living",
  }
  const onSubmit = (data: HistoricalSeniorLivingInputForm) => {
    if (assessmentEntry && assessmentEntry.address.location) {
      void fetchComparables(data, assessmentEntry.address.location)
    }
  }

  useEffect(() => {
    onSubmit(seniorLivingDatasourceFormData)
  }, [])

  useEffect(() => {
    onSubmit(seniorLivingDatasourceFormData)
  }, [assessmentEntry, assessmentEntry?.address.location])

  return <>{/*Historical Offers - No Filters so far*/}</>
}
