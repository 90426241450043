import * as React from "react"
import { Classes, Intent } from "@blueprintjs/core"
import { cx } from "emotion"

export interface TextFieldProps {
  value: string
  onValueChange: (value: string) => void
  placeholder?: string
  onFocus?: React.FocusEventHandler
  onBlur?: React.FocusEventHandler
  small?: boolean
  large?: boolean
  intent?: Intent
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

export const TextField: React.FunctionComponent<TextFieldProps> = (props) => {
  const className = cx(Classes.INPUT, Classes.FILL, Classes.intentClass(props.intent), {
    [Classes.LARGE]: props.large,
    [Classes.SMALL]: props.small,
  })

  return (
    <input
      className={className}
      type="text"
      dir="auto"
      value={props.value}
      placeholder={props.placeholder}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onKeyDown={(key) => props.onKeyDown && props.onKeyDown(key)}
      onChange={(event) => props.onValueChange(event.currentTarget.value)}
    />
  )
}
