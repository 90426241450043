import * as React from "react"
import { bind } from "decko"
import { translations } from "../i18n"
import { translations as assessmentTranslations } from "../../assessment/i18n"
import { css, cx } from "emotion"
import * as ol from "ol"
import { Feature } from "ol"
import * as layer from "ol/layer"
import * as source from "ol/source"
import { Cluster } from "ol/source"
import * as style from "ol/style"
import { Fill, Style } from "ol/style"
import * as extend from "ol/extent"
import { SimpleOverlay } from "./simple-overlay"
import { Pixel } from "ol/pixel"
import { ScoreResults, Scores } from "../models/scores"
import { StyleFunction } from "ol/style/Style"
import { Geometry, Point } from "ol/geom"
import { circular } from "ol/geom/Polygon"
import RenderFeature from "ol/render/Feature"
import { Group, Profile } from "../../profile/models/profile"
import Axios, { AxiosError, AxiosResponse, CancelTokenSource } from "axios"
import { Address, Location } from "../../assessment/models/address"
import { FeatureLike } from "ol/Feature"
import { MapPopupProfilePopup, ProfilePopupFact } from "./ui/map-popup-profile-popup"
import {
  getInitialMapStyle,
  MapStyle,
  MapStyleControl,
  persistMapStyle,
  setMapUrlBasedOnMapStyle,
} from "./map-style-control"
import { Control, FullScreen, Zoom } from "ol/control"
import { AttributeControl } from "./attribute-control"
import { formatFractionDigitsOptimized } from "../../utils/fractions"
import { DataSetType } from "../models/smartdata"
import { MapWmsLayer } from "./map-wms-control"
import VectorLayer from "ol/layer/Vector"
import VectorSource from "ol/source/Vector"
import { fromLonLat } from "ol/proj"
import { ComparablesItem } from "../../generated-apis/comparables-service"
import { ObservedOffersMapPopup } from "../../assessment/components/assessment-observed-offers-map-popup"
import { lanaApiUrl } from "../../app_config"
import { POICategoryTranslations } from "../models/poi-category-translations"
import { Rating, RatingResult, RatingResults } from "../models/ratings"
import { hexToRgb } from "../models/rating-grade"
import { MapPopup } from "./ui/map-popup"
import { WidgetsType } from "../../assessment/models/assessment"
import { CLASS_CONTROL } from "ol/css"
import { createRoot } from "react-dom/client"
import { MapNavigateToModuleButton } from "./map-navigate-to-module-button"
import { reportAssetsUrl } from "../../reports/report-config"
import { HasTranslations, LANA_AGS_NODES_BY_NAME, LANA_CELL_NODES_BY_NAME } from "../smartdata-products/smartdata"
import { AppModules } from "../../menu/util/app-location-types"
import { PrivatePoiControl } from "./map-private-pois-control"
import { JSXContent, PrivatePoiOverlay } from "./PrivatePoiOverlay"
import {
  AllowedModulesEnum,
  PrivateDataModuleSettings,
  PrivatePOICategoriesList,
  PrivatePOIList,
} from "../../private-data/models/private-data"
import { areMultiplePrivatePoisListsDifferent, arePoisToShowDifferent, updateClusters } from "../../utils/utils"
import { PoiMarkerDialog } from "./privatedata-poi-marker-dialog"
import Layer from "ol/layer/Layer"
import Source from "ol/source/Source"
import {
  fetchCategories,
  fetchCategoryPois,
  updatePOIsToShow,
  updateSelectedCategories,
} from "../../private-data/reducers/private-data-slice"
import { OpenLayersAbstractProps, OpenLayersMap } from "./openlayers-map"
import { UserState } from "../../relas/user-slice"
import {
  cellVectorTileOptions,
  municipalityVectorTileOptions,
  refRegionVectorTileOptions,
} from "../../utils/openlayers"
import { getThemeColor, getThemeColorVar } from "../helper/color"
import { GenericError, toGenericError } from "../helper/axios"
import { formatNumber } from "../helper/number-format"
import LoadingSpinner from "./loadingspinner"

interface CommonMapViewProps extends OpenLayersAbstractProps {
  user?: UserState
  colorForValue(value: number, opacity: number): string
  shapeFillOpacity: number
  smartdataSource: DataSetType
  scores?: Scores
  rating?: Rating
  macroContext?: string | null
  overlayMainFactScoreType: "assessment-profile" | "assessment-score" | "profile"
  agsRefResLoc: string
  onSelectAgsRefResLoc?: (agsRefResLoc: string) => void
  markerLocation?: Location
  profileList?: Profile[]
  groupList?: Group[]
  initialMapStyle?: MapStyle
  circleRadius?: number
  comparablesItems: ComparablesItem[]
  mapRedrawToken?: number
  displayWmsLayer: boolean
  cellId?: number
  agsId?: number
  agsLocalityTitle?: string
  poiCategoryTranslations?: POICategoryTranslations | null
  selectedProfileScore?: string
  widget?: WidgetsType
  currentAssessmentEntryAddress?: Address
  isPrivateDataAccessible: boolean
  allowedModule?: AllowedModulesEnum
  privatePoiCategories: PrivatePOICategoriesList
  privateDataSettings: PrivateDataModuleSettings
  highlightAssessmentLocationRegion?: boolean
  mapErrorMessage?: "shape_outdated"
}

const MARKER_LAYER = "MARKER_LAYER"

const containerClass = css({
  minHeight: 0,
  position: "relative",
  overflow: "hidden",
})

const mapClass = css({
  minHeight: 0,
  height: "100%",
})

const controlClass = (top: string) =>
  css({
    right: ".5em",
    top: top,
  })

const mapNotifierStyle = css({
  backgroundColor: getThemeColorVar("background", "lighter"),
  width: "100%",
  position: "absolute",
  zIndex: 1000,
  bottom: 0,
  transition: "max-height 1s",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  opacity: 0.9,
  overflow: "hidden",
})

interface OwnState {
  scoreResults: ScoreResults
  focusTileIndex: number
  ratingResults: { [refId: string]: RatingResult }
  queryScoresNoScores: boolean
  queryScoresInProgress: boolean
  queryScoresError: GenericError | null
  hoverRegion: string | null
  hoverRegionName: string | null
  selectedMapStyle: MapStyle
  ratingsLoading: boolean
  showPrivatePoisPopover: boolean
  poiPopUpPosition: [number, number] | undefined
}

interface ScoreLabel {
  unit: string
  rawValue: number
  weight: number
  label: string
  value: number
}

type Props = CommonMapViewProps

// TODO - the calculation logic should happen in the backend.
function calcWeights(
  weight: number,
  scores: Scores,
  dataSetType: DataSetType,
  profileList: Profile[]
): { name: string; weight: number }[] {
  const result: { name: string; weight: number }[] = []
  const weightedScores = scores[dataSetType]

  const sumOfWeights = Object.entries(weightedScores).reduce<number>((a, [, e]) => a + Math.abs(e), 0)

  Object.entries(weightedScores).forEach(([name, rawWeight]) => {
    const adjustedWeight = (1 / sumOfWeights) * rawWeight
    if (name.startsWith("profile.")) {
      const profileId = name.split(".")[1]
      const profile: Profile | undefined = profileList?.find((p) => p.id === profileId)
      if (!profile) throw Error("unknown profile id " + profileId)
      result.push(...calcWeights(weight * adjustedWeight, profile.scores, dataSetType, profileList))
    } else {
      result.push({ name, weight: weight * adjustedWeight })
    }
  })
  return result
}

const calculateWeightPercent = (sumOfWeights: number) => (sv: ScoreLabel) => {
  const value = sv.unit === "%" ? sv.rawValue * 100 : sv.rawValue
  const calculatedRawValue = formatNumber(value, value < 1000 ? 2 : 0, sv.unit)
  return {
    label: sv.label,
    value: `${sv.value}`,
    rawValue: calculatedRawValue,
    weight: `${formatFractionDigitsOptimized((100 / sumOfWeights) * Math.abs(sv.weight))} %`,
    inverted: sv.weight < 0,
  }
}

function getSortedScoresWithValues(
  groupedScores: Record<string, number>,
  scoreResults: ScoreResults,
  scoreResultData: number[],
  pickTranslation: (i18n: HasTranslations) => string
): ScoreLabel[] {
  return Object.entries(groupedScores)
    .map(([name, weight]) => {
      const node = LANA_AGS_NODES_BY_NAME.get(name) || LANA_CELL_NODES_BY_NAME.get(name)

      if (!node) throw Error("unknown scoreName  " + name)

      const fields = scoreResults.fields
      const value = scoreResultData[fields.indexOf("smartdata." + node.scoreName)]
      const rawValue = scoreResultData[fields.indexOf("smartdata." + node.rawName)]

      return {
        label: pickTranslation(node.title),
        value: value,
        rawValue: rawValue,
        unit: `${node.unit ? pickTranslation(node.unit) : ""}`,
        weight: Math.round(weight * 1000) / 1000,
      }
    })
    .sort((a, b) => {
      if (Math.abs(a.weight) > Math.abs(b.weight)) return -1
      if (Math.abs(a.weight) < Math.abs(b.weight)) return 1
      return a.label.localeCompare(b.label)
    })
}

export function extractProfileFacts(
  scores: Scores,
  dataSetType: DataSetType,
  profileList: Profile[],
  refId: string,
  mainFactLabel: string,
  scoreResults: ScoreResults,
  pickTranslation: (i18n: HasTranslations) => string
): { mainFact?: ProfilePopupFact; secondaryFacts?: ProfilePopupFact[] } {
  const scoreResultData = scoreResults ? scoreResults.data[refId] : null

  if (!scoreResults || !scoreResultData) return {}

  const groupedScores: Record<string, number> = {}
  // group scores with the same name and sum their weights

  calcWeights(1, scores, dataSetType, profileList || []).forEach(({ name, weight }) => {
    const w = groupedScores[name]
    groupedScores[name] = w ? w + weight : weight
  })

  const sortedScoresWithValues = getSortedScoresWithValues(
    groupedScores,
    scoreResults,
    scoreResultData,
    pickTranslation
  )

  const sumOfWeights = sortedScoresWithValues.reduce((a, e) => a + Math.abs(e.weight), 0)
  const secondaryFacts = sortedScoresWithValues.map(calculateWeightPercent(sumOfWeights))

  let [profileScore] = scoreResultData

  const mainFact = {
    label: mainFactLabel,
    value: formatNumber(profileScore, 0),
  }

  return {
    mainFact,
    secondaryFacts,
  }
}

export const ObservedOffersClusterLayerIdPrefix = "observedOffersClusterLayerId_"

class CommonMapView extends OpenLayersMap<Props, OwnState> {
  private overlay: SimpleOverlay | undefined
  private macroLayer: layer.VectorTile | undefined = undefined
  private microLayer: layer.VectorTile | undefined = undefined
  private regionLayer: layer.VectorTile | undefined = undefined
  private markerLayer: layer.Vector<any> | undefined = undefined
  private privatePoiControl: PrivatePoiControl | undefined = undefined
  private clusterLayerArray: layer.Vector<any>[] = []
  private poiPopUp: PrivatePoiOverlay | undefined = undefined
  private observerOffersCircleLayer: layer.Vector<any> | undefined = undefined
  private observedOffersClusterLayer: layer.Vector<any> | undefined = undefined
  private observedOffersClusterStyleCache: { [size: number]: Style } = {}
  private cancelTokenSource: CancelTokenSource | undefined = undefined
  private wmsLayer: MapWmsLayer | undefined = undefined
  private t = { ...translations(), assessmentTranslations: assessmentTranslations() }

  private selectedStrokeStyle = new style.Stroke({
    color: getThemeColor("primary", "default").toString(),
    width: 2,
  })
  private emptyStyle = new style.Style()

  private stylePerColorValue = new Map<string, style.Style>()

  constructor(props: Props) {
    super(props)

    this.state = {
      scoreResults: { fields: [], data: {} },
      focusTileIndex: 3,
      ratingResults: {},
      queryScoresNoScores: false,
      queryScoresInProgress: false,
      queryScoresError: null,
      ratingsLoading: false,
      hoverRegion: null,
      hoverRegionName: null,
      showPrivatePoisPopover: false,
      poiPopUpPosition: undefined,
      selectedMapStyle: getInitialMapStyle(props.initialMapStyle),
    }
  }

  @bind
  onChangeShowPrivatePoisPopover(value: boolean) {
    this.setState({
      ...this.state,
      showPrivatePoisPopover: value,
    })
  }

  @bind
  onChangeSelectedMapStyle(style: MapStyle) {
    this.setState({ selectedMapStyle: style })
    setMapUrlBasedOnMapStyle(this.getMap(), style)
    persistMapStyle(style)
  }

  @bind
  addControlButton(module: AppModules["locationAssessment"], navigateToModule: () => void) {
    const container = document.createElement("div")
    container.className = `${CLASS_CONTROL}`
    container.style.right = "0.5em"
    container.style.top = module === "poiExplorer" ? "10em" : "12.5em"

    createRoot(container).render(
      <MapNavigateToModuleButton
        module={module}
        parent={this.mapContainer.current || undefined}
        navigateToModule={navigateToModule}
      />
    )

    let control = new Control({ element: container })
    this.getMap().addControl(control)
  }

  componentDidMount() {
    super.componentDidMount()
    void fetchCategories()

    this.regionLayer = new layer.VectorTile({
      declutter: true,
      source: new source.VectorTile(refRegionVectorTileOptions()),
      style: this.regionStyle,
    })

    this.getMap().addLayer(this.regionLayer)

    this.microLayer = new layer.VectorTile({
      declutter: true,
      source: new source.VectorTile(cellVectorTileOptions()),
      style: this.featureStyle("micro"),
    })
    this.getMap().addLayer(this.microLayer)

    this.macroLayer = new layer.VectorTile({
      declutter: true,
      source: new source.VectorTile(municipalityVectorTileOptions()),
      style: this.featureStyle("macro"),
    })

    this.getMap().addLayer(this.macroLayer)

    this.getMap().on("pointermove", this.onPointerMove)

    this.getMap().on("singleclick", this.onMapClick)

    this.getMap().getControls().clear()
    this.getMap().addControl(
      new MapStyleControl(
        this.state.selectedMapStyle,
        this.onChangeSelectedMapStyle,
        this.mapContainer.current || undefined
      )
    )
    this.getMap().addControl(new FullScreen({ className: `${controlClass("3.5em")}` }))
    this.getMap().addControl(new Zoom({ className: `${controlClass("6em")}` }))

    this.addOrRefreshPrivatePoi()
    this.poiPopUp = new PrivatePoiOverlay(this.getMap(), this.poiPopUpContentCreator)

    setMapUrlBasedOnMapStyle(this.getMap(), this.state.selectedMapStyle)

    let infoTop = this.props.isPrivateDataAccessible || true ? "12.5em" : "10em"

    if (!this.props.user?.scopes.specialMaps && this.props.displayWmsLayer) {
      this.wmsLayer = new MapWmsLayer(
        this.getMap(),
        this.mapContainer.current || undefined,
        this.props.widget,
        this.props.currentAssessmentEntryAddress
      )
      this.getMap().getView().on("change", this.wmsLayer.onViewChange)
    }

    const itemStyle = (scale: number) => ({
      image: new style.Icon({
        src: `${reportAssetsUrl ?? ""}/assets/item_pin.svg`,
        scale: scale,
        size: [29, 51],
        anchorOrigin: "top-left",
        anchor: [0.5, 1],
      }),
    })

    this.observerOffersCircleLayer = new VectorLayer({
      style: new Style({
        fill: new Fill({ color: "rgba(90, 85, 235, 0.3)" }),
        ...itemStyle(0.8),
      }),
    })
    this.getMap().addLayer(this.observerOffersCircleLayer)

    this.observedOffersClusterLayer = new VectorLayer({
      style: this.clusterStyle,
      declutter: false,
    })
    this.getMap().addLayer(this.observedOffersClusterLayer)

    this.getMap().addControl(new AttributeControl(infoTop, this.mapContainer.current || undefined))

    document.addEventListener("keydown", this.handleKeyDownEvent)

    this.overlay = new SimpleOverlay(this.getMap(), this.overlayContentCreator, this.onSelection)

    this.updateMarker()
    updateClusters(
      this.getMap(),
      this.clusterLayerArray,
      this.setClusterLayerArray,
      this.props.privateDataSettings.multipleCategoryPOIList,
      this.props.privatePoiCategories,
      this.props.isPrivateDataAccessible
    )
    this.updateScoreResults()
    this.getMap().render()
    if (this.props.highlightAssessmentLocationRegion && this.regionLayer && this.props.markerLocation) {
      const coords = fromLonLat([this.props.markerLocation.lng, this.props.markerLocation.lat])
      this.getMap().once("rendercomplete", () => {
        const pixel = this.getMap().getPixelFromCoordinate(coords)
        const features = this.getMap().getFeaturesAtPixel(pixel, {
          layerFilter: (layer) => layer === this.regionLayer,
        })
        const id = features[0].getId()?.toString()
        if (id != this.state.hoverRegion && id !== undefined) {
          this.setState(
            {
              hoverRegion: id,
              hoverRegionName: `${features[0].getProperties()["BEZ"]} ${features[0].getProperties()["GEN"]}`,
            },
            () => {
              if (this.overlay?.getRendering()) this.getMap().once("rendercomplete", () => this.regionLayer?.changed())
              else this.regionLayer?.changed()
            }
          )
        }
      })
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    document.removeEventListener("keydown", this.handleKeyDownEvent)
  }

  componentDidUpdate(prevProps: Props, prevState: OwnState) {
    super.componentDidUpdate(prevProps, prevState)

    this.addOrRefreshPrivatePoi()
    if (
      areMultiplePrivatePoisListsDifferent(
        prevProps.privateDataSettings.multipleCategoryPOIList,
        this.props.privateDataSettings.multipleCategoryPOIList
      )
    ) {
      updateClusters(
        this.getMap(),
        this.clusterLayerArray,
        this.setClusterLayerArray,
        this.props.privateDataSettings.multipleCategoryPOIList,
        this.props.privatePoiCategories,
        this.props.isPrivateDataAccessible
      )
    }

    if (this.props.markerLocation !== prevProps.markerLocation) {
      this.updateMarker()
    }

    if (this.props.shapeFillOpacity !== prevProps.shapeFillOpacity) {
      this.refreshLayers()
    } else if (
      this.props.smartdataSource !== prevProps.smartdataSource ||
      this.props.agsRefResLoc !== prevProps.agsRefResLoc ||
      JSON.stringify(this.props.scores) !== JSON.stringify(prevProps.scores) ||
      this.props.macroContext !== prevProps.macroContext ||
      this.props.rating !== prevProps.rating
    ) {
      this.refreshLayers()
      this.updateScoreResults()
    }

    if (
      JSON.stringify(prevProps.comparablesItems) !== JSON.stringify(this.props.comparablesItems) ||
      (this.props.circleRadius === 0 && prevProps.circleRadius !== 0) ||
      (!this.state.queryScoresInProgress && prevState.queryScoresInProgress)
    ) {
      this.overlay?.closeCurrentOverlay(true)
    }

    if (
      this.props.markerLocation &&
      this.observerOffersCircleLayer &&
      this.props.circleRadius &&
      this.props.circleRadius > 0
    ) {
      const circle = circular(
        [this.props.markerLocation.lng, this.props.markerLocation.lat],
        this.props.circleRadius,
        120
      ).transform("EPSG:4326", "EPSG:3857")
      const circleFeature = new Feature(circle)

      const items = this.props.comparablesItems.map((i) => {
        const f = new Feature(new Point(fromLonLat([i.geom.lng, i.geom.lat])))
        f.setId(ObservedOffersClusterLayerIdPrefix + i.id)
        return f
      })

      let circleOverlayVectorSource = new VectorSource({
        useSpatialIndex: false,
        features: [circleFeature],
      })

      let clusterSource = new Cluster({
        source: new source.Vector({
          features: items,
        }),
        distance: 20,
      })

      this.observerOffersCircleLayer.setSource(circleOverlayVectorSource)
      this.observedOffersClusterLayer?.setSource(clusterSource)

      this.observerOffersCircleLayer?.setVisible(true)
      this.observedOffersClusterLayer?.setVisible(true)
    } else {
      this.observerOffersCircleLayer?.setVisible(false)
      this.observedOffersClusterLayer?.setVisible(false)
    }

    if (prevProps.mapRedrawToken !== this.props.mapRedrawToken) {
      this.getMap().updateSize()
    }

    if (arePoisToShowDifferent(prevProps.privateDataSettings.poisToShow, this.props.privateDataSettings.poisToShow)) {
      this.updatePoiPopUp()
    }
  }

  private addOrRefreshPrivatePoi() {
    if (this.props.allowedModule) {
      if (!this.privatePoiControl) {
        this.privatePoiControl = new PrivatePoiControl(
          this.props.allowedModule,
          this.props.privatePoiCategories,
          this.props.privateDataSettings.selectedCategories,
          updateSelectedCategories,
          fetchCategoryPois,
          this.state.showPrivatePoisPopover,
          this.onChangeShowPrivatePoisPopover,
          this.props.isPrivateDataAccessible
        )

        this.getMap().addControl(this.privatePoiControl)
      } else {
        this.privatePoiControl?.refreshPopover(
          this.props.privatePoiCategories,
          this.props.privateDataSettings.selectedCategories,
          this.state.showPrivatePoisPopover
        )
      }
    }
  }

  @bind
  private clusterStyle(feature: ol.Feature): style.Style {
    const size = (feature.get && feature.get("features")?.length) || 0

    if (!this.observedOffersClusterStyleCache[size]) {
      this.observedOffersClusterStyleCache[size] = new style.Style({
        image: new style.Circle({
          radius: 10 + Math.min((Math.log(size) / Math.log(10)) * 4 - 4, 10),
          fill: new style.Fill({
            color: getThemeColor("primary", "default").string(),
          }),
        }),
        text: new style.Text({
          text: size.toString(),
          offsetY: 0,
          fill: new style.Fill({
            color: "#ffffff",
          }),
        }),
      })
    }
    return this.observedOffersClusterStyleCache[size]
  }

  @bind
  private observedOffersItemOverlayContent(items: ComparablesItem[]) {
    return <ObservedOffersMapPopup items={items} onClose={() => this.overlay?.closeCurrentOverlay(true)} />
  }

  @bind
  private handleKeyDownEvent(e: KeyboardEvent) {
    if (e.key === "Escape") {
      this.overlay?.closeCurrentOverlay(true)
    }
  }

  protected containerClass(): string {
    return mapClass
  }

  @bind
  protected onMoveEnd() {}

  @bind
  private quarterYearToNumber(item: ComparablesItem): number {
    return (item.offerDateQuarter - 1) / 4 + item.offerDateYear
  }

  @bind
  private overlayContentCreator(pixel: Pixel): { id: string; content: JSX.Element } | null {
    const observedOffersFeaturesAtPixel = this.getMap().getFeaturesAtPixel(pixel, {
      layerFilter: (l) => l === this.observedOffersClusterLayer,
    })
    const clusteredFeaturesIdsAtPixel: number[] = observedOffersFeaturesAtPixel
      .map((x) => {
        const observedFeatureIds: number[] = x
          .get("features")
          ?.filter((x: FeatureLike) => x.getId()?.toString().startsWith(ObservedOffersClusterLayerIdPrefix))
          .map((x: FeatureLike) =>
            parseInt(x.getId()?.toString().substr(ObservedOffersClusterLayerIdPrefix.length) ?? "0")
          )
        return observedFeatureIds
      })
      .reduce((acc, val) => acc.concat(val), [])

    if (clusteredFeaturesIdsAtPixel.length > 0) {
      const ids = new Set<number>(clusteredFeaturesIdsAtPixel)
      const observedOffers = this.props.comparablesItems.filter((v) => ids.has(v.id))

      if (observedOffers) {
        const popupId = observedOffers
          .map((x) => x.id)
          .sort((a, b) => a - b)
          .join("_")
          .toString()
        const observedOffersSorted = observedOffers.sort(
          (a: ComparablesItem, b: ComparablesItem) => this.quarterYearToNumber(b) - this.quarterYearToNumber(a)
        )

        this.setState((state) => ({
          ...state,
          observedOffersPopup: {
            items: observedOffersSorted,
            currentIndex: 0,
          },
        }))
        return {
          id: "observed_offers_" + popupId,
          content: this.observedOffersItemOverlayContent(observedOffersSorted),
        }
      }
    }
    if (this.props.displayWmsLayer && this.wmsLayer?.isActive()) {
      return this.wmsLayer?.getOverlayAt(pixel, () => this.overlay?.closeCurrentOverlay(true))
    }

    const getFeaturesAtPixel = (isMacroLayer: boolean) =>
      this.getMap().getFeaturesAtPixel(pixel, {
        layerFilter: (layer) =>
          layer === (isMacroLayer ? this.macroLayer : this.microLayer) || layer === this.markerLayer,
      })

    switch (this.props.smartdataSource) {
      case "macro":
        if (this.macroLayer)
          return this.overlayFromFeatures(getFeaturesAtPixel(true), this.props.agsId, this.props.agsLocalityTitle)
        break
      case "micro":
        if (this.microLayer) return this.overlayFromFeatures(getFeaturesAtPixel(false), this.props.cellId)
        break
    }
    return null
  }

  @bind
  private overlayFromFeatures(
    features: FeatureLike[],
    markerCellId?: number,
    markerCellTitle?: string
  ): { id: string; content: JSX.Element } | null {
    // Do we hit the marker pin ? We need to find an actual feature at the marker pin position
    const markerPin = features.find((el) => el.getId() === MARKER_LAYER)

    let id: string | null = null
    let cellTitle: string | null = null

    // click on a Pin
    if (markerPin && markerCellId) {
      id = markerCellId.toString()

      // TB-1302 Feature Title is used where possible on marker click
      cellTitle = markerCellTitle || `#${id}`
    } else {
      let feature = features.find((f) => f.getId() !== MARKER_LAYER)
      if (feature) {
        const featureId = feature.getId()

        id = featureId ? featureId.toString() : "unknown"

        cellTitle = feature.getProperties()["GEN"] || (featureId ? `#${featureId}` : "unknown")
      }
    }

    const { smartdataSource, profileList, scores, rating, overlayMainFactScoreType, selectedProfileScore } = this.props

    if (!id || !cellTitle) return null

    if (rating) {
      const ratingResult = this.state.ratingResults[id]

      if (!ratingResult) return null

      const header =
        smartdataSource == "micro"
          ? ratingResult.focusCell
            ? cellTitle
            : this.t.map.uninhabitedCell
          : ratingResult.fields && "ags_name" in ratingResult.fields
          ? `${ratingResult.fields.ags_name}`
          : "unknown"

      return {
        id,
        content: (
          <MapPopup
            nonFocusCell={!ratingResult.focusCell}
            header={header}
            mainFact={{ label: this.t.ratingGrade, value: rating.grades[ratingResult.gradeIdx].label }}
            secondaryFacts={[]}
            closeCallback={() => this.overlay?.closeCurrentOverlay(true)}
          />
        ),
      }
    }

    if (scores) {
      const { scoreResults } = this.state

      const dataSetType: DataSetType = smartdataSource
      const assessmentProfileOneSelectedScore =
        overlayMainFactScoreType === "assessment-profile" && !!selectedProfileScore
      const mainFactLabel =
        overlayMainFactScoreType === "assessment-score" || assessmentProfileOneSelectedScore
          ? this.t.profileScoreTitle
          : this.t.profilePopupScoreTitle

      const { mainFact, secondaryFacts } = extractProfileFacts(
        scores,
        dataSetType,
        profileList || [],
        id,
        mainFactLabel,
        scoreResults,
        this.t.pickTranslation
      )

      const { queryScoresInProgress } = this.state

      if (!mainFact || !secondaryFacts) return null

      return {
        id,
        content: (
          <MapPopupProfilePopup
            loading={queryScoresInProgress}
            showRawValues={smartdataSource === "macro"}
            header={
              scoreResults.data[id][this.state.focusTileIndex] == 0 && smartdataSource === "micro"
                ? this.t.map.uninhabitedCell
                : cellTitle
            }
            mainFact={mainFact}
            secondaryFacts={secondaryFacts}
            closeCallback={() => this.overlay?.closeCurrentOverlay(true)}
          />
        ),
      }
    }
    return null
  }

  render() {
    return (
      <div
        className={containerClass}
        onMouseLeave={() => {
          if (!this.props.highlightAssessmentLocationRegion) this.cleanHoverRegion()
        }}
      >
        {super.render()}

        <div className={cx(mapNotifierStyle, css({ maxHeight: this.state.ratingsLoading ? "48px" : "0px" }))}>
          {this.state.ratingsLoading && <LoadingSpinner size={32} />}
          <span style={{ padding: "8px" }}>{this.t.loadingRatings}</span>
        </div>

        {!this.props.highlightAssessmentLocationRegion && (
          <div className={cx(mapNotifierStyle, css({ maxHeight: this.state.hoverRegionName ? "48px" : "0px" }))}>
            <span style={{ padding: "8px" }}>
              {this.t.map.switchToRegion}: {this.state.hoverRegionName}
            </span>
          </div>
        )}

        <div className={cx(mapNotifierStyle, css({ maxHeight: this.props.mapErrorMessage ? "48px" : "0px" }))}>
          {this.props.mapErrorMessage === "shape_outdated" && (
            <span style={{ padding: "8px" }}>{this.t.map.mapShapeOutdatedMessage}</span>
          )}
        </div>
      </div>
    )
  }

  @bind
  private onSelection() {
    this.macroLayer?.changed()
    this.microLayer?.changed()
  }

  @bind
  private featureStyle(source: DataSetType): StyleFunction {
    return (feature) => {
      const id = feature.getId()?.toString()
      if (this.props.smartdataSource === source && id !== undefined) {
        if (this.props.rating) {
          const ratingResult = this.state.ratingResults[id]

          if (ratingResult) {
            const rgb = hexToRgb(this.props.rating.grades[ratingResult.gradeIdx].color) || { r: 128, g: 128, b: 128 }
            const selected = this.overlay?.getSelectedId() === id
            const currZoom = this.getMap().getView().getZoom() ?? 0
            const strokeStyle =
              currZoom < 11.5 && this.props.smartdataSource === "micro"
                ? {
                    color: "rgba(255,0,0,0.8)",
                    width: 1,
                  }
                : {
                    color: "rgba(255,0,0,0.8)",
                    width: 0.5,
                  }

            return new style.Style({
              stroke: selected ? new style.Stroke(strokeStyle) : undefined,
              fill: new style.Fill({
                color: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${
                  ratingResult.focusCell === false ? 0 : this.props.shapeFillOpacity
                })`,
              }),
            })
          }
        } else {
          const scoreResult = this.state.scoreResults.data[id]

          if (scoreResult) {
            this.stylePerColorValue.set(
              "nonFocus",
              new style.Style({
                fill: new style.Fill({
                  color: this.props.colorForValue(scoreResult[0], 0),
                }),
              })
            )

            if (this.overlay?.getSelectedId() === id) {
              return new style.Style({
                stroke: this.selectedStrokeStyle,
                fill: new style.Fill({
                  color: this.props.colorForValue(
                    scoreResult[0],
                    scoreResult[this.state.focusTileIndex] == 0 && this.props.smartdataSource === "micro"
                      ? 0
                      : this.props.shapeFillOpacity
                  ),
                }),
              })
            } else {
              const colorValue = this.props.colorForValue(scoreResult[0], this.props.shapeFillOpacity)
              if (!this.stylePerColorValue.has(colorValue)) {
                this.stylePerColorValue.set(
                  colorValue,
                  new style.Style({
                    fill: new style.Fill({
                      color: this.props.colorForValue(scoreResult[0], this.props.shapeFillOpacity),
                    }),
                  })
                )
              }

              return scoreResult[this.state.focusTileIndex] == 0 && this.props.smartdataSource === "micro"
                ? this.stylePerColorValue.get("nonFocus")
                : this.stylePerColorValue.get(colorValue)
            }
          }
        }
      }

      return this.emptyStyle
    }
  }

  @bind
  private regionStyle(feature: ol.Feature<Geometry> | RenderFeature): style.Style {
    if (
      !this.state.queryScoresNoScores &&
      this.props.smartdataSource === "micro" &&
      feature.getId() === this.props.agsRefResLoc
    ) {
      return new style.Style({
        stroke: new style.Stroke({
          color: getThemeColor("secondary3", "default").toString(),
          width: 2,
        }),
        fill: new style.Fill({
          color: "rgba(1,0,0,0)", // transparent, but essential for layer.getFeatures to work
        }),
      })
    } else if (
      this.props.smartdataSource === "micro" &&
      this.state.hoverRegion &&
      feature.getId() === this.state.hoverRegion
    ) {
      return new style.Style({
        fill: new style.Fill({
          color: "rgba(1,1,1,0.4)",
        }),
      })
    }
    return new style.Style({
      fill: new style.Fill({
        color: "rgba(1,0,0,0)", // transparent, but essential for layer.getFeatures to work
      }),
    })
  }

  @bind
  private onPointerMove(event: ol.MapBrowserEvent<any>) {
    const features = this.getMap().getFeaturesAtPixel(event.pixel, {
      layerFilter: (layer) => layer === this.regionLayer,
    })
    if (!this.props.highlightAssessmentLocationRegion) {
      const coordinate = this.getMap().getCoordinateFromPixel(event.pixel)
      if (features.length < 1 || !extend.containsCoordinate(features[0].getGeometry()?.getExtent() ?? [], coordinate)) {
        this.cleanHoverRegion()
        return
      }

      const id = features[0]?.getId()?.toString()

      if (this.props.onSelectAgsRefResLoc && id !== undefined && id !== this.props.agsRefResLoc) {
        if (id != this.state.hoverRegion) {
          this.setState(
            {
              hoverRegion: id,
              hoverRegionName: `${features[0].getProperties()["BEZ"]} ${features[0].getProperties()["GEN"]}`,
            },
            () => {
              if (this.overlay?.getRendering()) this.getMap().once("rendercomplete", () => this.regionLayer?.changed())
              else this.regionLayer?.changed()
            }
          )
        }
      } else {
        this.cleanHoverRegion()
      }
    }
  }

  @bind
  private isClickableLayers(layer: Layer<Source>): boolean {
    if (layer.getClassName().includes("cluster-layer")) {
      return true
    } else {
      return layer === this.regionLayer
    }
  }

  @bind onMapClick(event: ol.MapBrowserEvent<any>) {
    const features = this.getMap().getFeaturesAtPixel(event.pixel, {
      layerFilter: (layer) => this.isClickableLayers(layer),
    })
    if (features[0].getProperties().features && features[0].getProperties().geometry) {
      this.overlay?.closeCurrentOverlay(true)
      const featuresArray: ol.Feature[] = features[0].getProperties().features
      const geometry = features[0].getProperties().geometry

      const categoryId = featuresArray[0].getId()?.toString().split(":")[1] ?? ""
      const poiIdsToShow: string[] = featuresArray.map((feature) => feature.getId()?.toString().split(":")[2] ?? "")
      const poisToShow: PrivatePOIList = (
        this.props.privateDataSettings.multipleCategoryPOIList[categoryId] ?? []
      ).filter((poi) => poiIdsToShow.includes(poi.id))
      const position = geometry.getCoordinates()
      this.setState({ ...this.state, poiPopUpPosition: position })
      this.props.allowedModule && updatePOIsToShow(poisToShow, this.props.allowedModule)
    }
    if (this.state.poiPopUpPosition) this.onPoiPopUpClose()
    if (this.props.smartdataSource === "micro" && !this.props.highlightAssessmentLocationRegion) {
      this.onMicroDatasetMapClick(event, features)
    }
  }
  @bind
  private onMicroDatasetMapClick(event: ol.MapBrowserEvent<any>, features: FeatureLike[]) {
    const coordinate = this.getMap().getCoordinateFromPixel(event.pixel)
    if (features.length < 1 || !extend.containsCoordinate(features[0].getGeometry()?.getExtent() ?? [], coordinate)) {
      return
    }

    const id = features[0].getId()?.toString()

    if (id !== undefined && id.toString() !== this.props.agsRefResLoc) {
      this.overlay?.closeCurrentOverlay(true)
      this.props.onSelectAgsRefResLoc?.call(null, id)
      this.cleanHoverRegion()
    }
  }

  @bind
  private cleanHoverRegion() {
    if (this.state.hoverRegion) {
      this.setState(
        {
          hoverRegion: null,
          hoverRegionName: null,
        },
        () => {
          if (this.overlay?.getRendering()) this.getMap().once("rendercomplete", () => this.regionLayer?.changed())
          else this.regionLayer?.changed()
        }
      )
    }
  }

  private updateScoreResults() {
    const { scores, smartdataSource, agsRefResLoc, macroContext, rating } = this.props

    this.cancelTokenSource?.cancel()
    this.cancelTokenSource = undefined

    if (rating) {
      this.cancelTokenSource = Axios.CancelToken.source()

      this.setState({
        queryScoresNoScores: false,
        queryScoresInProgress: true,
        queryScoresError: null,
        ratingsLoading: true,
      })

      Axios.get(`${lanaApiUrl}/api/ratings/${encodeURIComponent(rating.id)}/results`, {
        params: {
          agsRefResLoc,
        },
        cancelToken: this.cancelTokenSource.token,
      }).then(
        (success: AxiosResponse) => {
          const ratingResultResponse: RatingResults = success.data
          const ratingResults: { [refId: string]: RatingResult } = {}

          ratingResultResponse.results.map((result) => (ratingResults[result.refId] = result))

          this.cancelTokenSource = undefined
          this.setState(
            {
              queryScoresInProgress: false,
              queryScoresNoScores: false,
              scoreResults: { fields: [], data: {} },
              ratingResults,
              ratingsLoading: false,
            },
            this.refreshLayers
          )
        },
        (error: AxiosError) => {
          if (!Axios.isCancel(error))
            this.setState({
              queryScoresInProgress: false,
              queryScoresError: toGenericError(error),
              ratingsLoading: false,
            })
        }
      )
    }

    if (
      !scores ||
      (smartdataSource == "macro" && Object.keys(scores.macro).length === 0) ||
      (smartdataSource == "micro" && Object.keys(scores.micro).length === 0)
    ) {
      this.setState(
        {
          scoreResults: { fields: [], data: {} },
          ratingResults: {},
          queryScoresNoScores: true,
          queryScoresInProgress: false,
          queryScoresError: null,
        },
        this.refreshLayers
      )
      return
    }

    this.cancelTokenSource = Axios.CancelToken.source()

    this.setState({ queryScoresNoScores: false, queryScoresInProgress: true, queryScoresError: null })

    Axios.post(
      `${lanaApiUrl}/api/map/scores`,
      smartdataSource === "macro"
        ? {
            dataSetType: "macro",
            weightedScores: scores.macro,
            macroContext,
          }
        : {
            dataSetType: "micro",
            weightedScores: scores.micro,
            filter1: agsRefResLoc,
          },
      { cancelToken: this.cancelTokenSource.token }
    ).then(
      (success: AxiosResponse) => {
        this.cancelTokenSource = undefined
        this.setState(
          {
            queryScoresInProgress: false,
            queryScoresNoScores: false,
            scoreResults: success.data,
            focusTileIndex: success.data.fields.indexOf("smartdata.focus_tile"),
            ratingResults: {},
          },
          this.refreshLayers
        )
      },
      (error: AxiosError) => {
        if (!Axios.isCancel(error)) {
          this.setState({
            queryScoresInProgress: false,
            queryScoresError: toGenericError(error),
          })
        }
      }
    )
  }

  @bind
  private refreshLayers() {
    this.overlay?.closeCurrentOverlay(true)
    this.regionLayer?.changed()
    switch (this.props.smartdataSource) {
      case "macro":
        this.microLayer?.setVisible(false)
        this.macroLayer?.setVisible(true)
        this.macroLayer?.changed()
        break
      case "micro":
        this.microLayer?.setVisible(true)
        this.macroLayer?.setVisible(false)
        this.microLayer?.changed()
        break
    }
  }

  private updateMarker() {
    if (this.props.markerLocation) {
      if (!this.markerLayer) {
        const feature = new ol.Feature({
          geometry: new Point(fromLonLat([this.props.markerLocation.lng, this.props.markerLocation.lat])),
        })
        feature.setId(MARKER_LAYER)
        this.markerLayer = new layer.Vector<any>({
          source: new source.Vector({
            features: [feature],
          }),
          zIndex: 99,
          style: new style.Style({
            image: new style.Icon({
              src: "/assets/address_pin.svg",
              size: [36, 60],
              anchorOrigin: "top-left",
              anchor: [0.5, 1],
            }),
          }),
        })

        this.getMap().addLayer(this.markerLayer)
      } else {
        const feature = new ol.Feature({
          geometry: new Point(fromLonLat([this.props.markerLocation.lng, this.props.markerLocation.lat])),
        })
        feature.setId(MARKER_LAYER)

        this.markerLayer.setSource(new source.Vector({ features: [feature] }))
      }
      this.getMap()
        .getView()
        .animate({
          center: fromLonLat([this.props.markerLocation.lng, this.props.markerLocation.lat]),
          zoom: 10,
        })
    } else {
      if (this.markerLayer) {
        this.getMap().removeLayer(this.markerLayer)
        this.markerLayer = undefined
      }
    }
  }

  @bind private setClusterLayerArray(newClusterArrayLayer: layer.Vector<any>[]) {
    this.clusterLayerArray = [...newClusterArrayLayer]
  }

  @bind
  private updatePoiPopUp() {
    if (this.props.isPrivateDataAccessible) {
      if (this.props.privateDataSettings.poisToShow.length > 0 && this.state.poiPopUpPosition) {
        this.poiPopUp?.openOverlayAt(this.state.poiPopUpPosition, this.props.privateDataSettings.poisToShow)
      } else if (this.poiPopUp) {
        this.poiPopUp.closeCurrentOverlay()
      }
    } else if (this.poiPopUp) {
      this.poiPopUp.closeCurrentOverlay()
    }
  }

  @bind onPoiPopUpClose() {
    this.poiPopUp?.closeCurrentOverlay()
    this.props.allowedModule && updatePOIsToShow([], this.props.allowedModule)
    this.setState({ ...this.state, poiPopUpPosition: undefined })
  }

  @bind
  private poiPopUpContentCreator(location: number[], poiList: PrivatePOIList): JSXContent {
    const props = {
      lat: location[1],
      lng: location[0],
      poisToShow: poiList,
      onClose: this.onPoiPopUpClose,
    }
    return {
      content: <PoiMarkerDialog {...props} entryPinLocation={this.props.currentAssessmentEntryAddress?.location} />,
    }
  }
}

export default CommonMapView
