import { AxiosError, AxiosHeaders, AxiosPromise, AxiosRequestConfig, default as Axios } from "axios"

export interface ServiceCall {
  domain: string
  pathWithoutLeadingSlash: string
  serviceName: string
}

/**
 * Use this wrapper to perform axios GETs. If the `sample` object is given and the domain is localhost:6060
 * the axios promise will be resolved immediately with the value from `sample`.
 * In all other cases standard 21re routing is applied (<servicename>.<domain>/<path>)
 */
export function axiosCallWithLocalhostFallback<T>(
  call: ServiceCall,
  config?: AxiosRequestConfig,
  localhostReturnData?: T,
  localhostReturnStatus: number = 200
): AxiosPromise {
  if (localhostReturnData && call.domain.indexOf("localhost") >= 0) {
    if (localhostReturnStatus < 400) {
      return Promise.resolve({
        data: localhostReturnData,
        status: localhostReturnStatus,
        statusText: "OK",
        headers: {},
        config: {
          headers: new AxiosHeaders(),
        },
      })
    } else {
      const error = {
        config: {},
        response: {
          data: localhostReturnData,
          status: localhostReturnStatus,
          statusText: "NOT OK",
          headers: [],
          config: {},
        },
      }
      return Promise.reject(error)
    }
  } else {
    return Axios.get(`https://${call.serviceName}.${call.domain}/${call.pathWithoutLeadingSlash}`, config)
  }
}

/**
 * Use this wrapper to perform axios POSTs. If the `sample` object is given and the domain is localhost:6060
 * the axios promise will be resolved immediately with the value from `sample`.
 *
 * The call url and the `data` will be logged via console.log
 *
 * In all other cases standard 21re routing is applied (<servicename>.<domain>/<path>)
 */
export function axiosPost<T>(
  call: ServiceCall,
  config?: AxiosRequestConfig,
  data?: T,
  sample?: any,
  status: number = 200
): AxiosPromise {
  if (sample && call.domain.indexOf("localhost") >= 0) {
    if (status < 400) {
      return Promise.resolve({
        data: sample,
        status: status,
        statusText: "OK",
        headers: {},
        config: {
          headers: new AxiosHeaders(),
        },
      })
    } else {
      // There is no easy way to type this shit: https://github.com/Microsoft/TypeScript/issues/14669
      const error = {
        config: {},
        response: {
          data: sample,
          status: status,
          statusText: "NOT OK",
          headers: [],
          config: {},
        },
      }
      return Promise.reject(error)
    }
  } else {
    return Axios.post(`https://${call.serviceName}.${call.domain}/${call.pathWithoutLeadingSlash}`, data, config)
  }
}

export interface GenericError<T = any> {
  flowId?: string
  status?: number
  data?: T
}

type Input<T> = T extends string ? T : AxiosError<T>

/**
 * @param error an Axios error response
 */
export function toGenericError<T>(error: Input<T>): GenericError<T> {
  if (typeof error === "string") {
    return {
      data: error as T,
    }
  }

  return {
    flowId: error.response?.headers["x-flow-id"],
    status: error.response?.status,
    data: error.response?.data,
  }
}
