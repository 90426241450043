import { SHARED_EN } from "../../shared/i18n/en"
import { LocationSelectorTranslations } from "./translations"
import * as React from "react"

export const LOCATION_SELECTOR_EN: LocationSelectorTranslations = {
  ...SHARED_EN,
  createNew: "Create Selection",
  selectionListMenu: {
    delete: "Delete",
    duplicate: "Duplicate",
    edit: "Edit",
    rename: "Rename",
  },
  selection: "Selection",
  municipalitiesHeader: "Municipalities",
  municipalityPopulation: "Residents",
  municipalityDownload: "Download",
  loadingResultData: "Loading selection results...",
  ratingGradeLabel: "Grade",
  showListToggle: "Show List",
  selectionAlreadyExists: "A selection with this name already exists",
  allSelections: "All Selections",
  newSelection: "New Selection",
  createSelection: "Create Selection",
  title: "Title",
  context: "Context",
  micro: "Micro (Cell / Neighbourhood)",
  macro: "Macro (Municipality)",
  addNewRule: "Add New Rule",
  selectionRuleEdit: {
    duplicateSuffix: "Copy",
    loadMicroDataExample: "Example",
    editRuleMenuItem: "Edit",
    deleteRuleMenuItem: "Delete",
    deleteRuleTitle: "Delete Rule",
    deleteRuleConfirm: (name: string) => `Do you really want to delete rule '${name}'?`,
    inclusiveLabel: "Inclusive",
    exclusiveLabel: "Exclusive",
    selectRankTop: "Top",
    selectRankBottom: "Bottom",
    selectRankPercentage: "Percentage",
    selectRankAbsolute: "Absolute",
    rescaleRankNo: "Absolute values",
    rescaleRankYes: "Relative values",
    duplicate: "Duplicate",
    rename: "Rename",
    deleteSelectionConfirm: (name: string) => `Do you really want to delete the selection '${name}'?`,
    helpText: (
      <>
        <p>Depending on the rule, one or more of the following options can be set:</p>
        <p>
          <strong>Inclusive / Exclusive</strong>
          <br />
          Determines if matching municipalities should be included in the selection or excluded from the selection.
        </p>

        <p>
          <strong>Raw data values from/to</strong>
          <br />
          These values can be just counts (e.g. from 1 to 5) or have a unit (e.g. "%" or "Inhabitants per km²"). If you
          don't set a value, "min to max" is assumed.
        </p>

        <p>
          <strong>Absolute / Relative </strong>
          <br />
          Scores always go from 0 to 100 and cover a certain reference area. The absolute macro scores are based on
          whole Germany. Relative macro scores are recalculated based on the current selection (e.g. only municipalities
          in Bavaria instead of all municipalities in whole Germany). Micro scores are handled analogously.
        </p>
        <p>
          <strong>Score/profile values from/to</strong>
          <br />
          The scores always go from 0-100 and can be filtered accordingly (e.g. only scores from 80-100).
        </p>
      </>
    ),
    createTitle: "New Rule",
    editTitle: "Edit Rule",
  },
  options: "Options",
  lockSelection: "Lock Selection",
  unlockSelection: "Unlock Selection",
  lockConfirmation:
    "You can lock a selection to protect it. Your colleagues can still use it, but they can not edit or delete it.",
  unlockConfirmation: "If you unlock your selection, your colleagues will be able to edit and delete it.",
}
