import * as React from "react"
import { useMemo, useState } from "react"
import { PriceTrend } from "../../../../generated-apis/comparables-service"
import { Flex } from "../../../../shared/components/ui/flex"
import { Grid } from "../../../../shared/components/ui/grid"
import { HTMLSelect, Popover } from "@blueprintjs/core"
import { css } from "emotion"
import { ComparablesTrendGraph } from "./comparables-trend-graph"
import { ComparablesTrendTable } from "./comparables-trend-table"
import { translations } from "../../../i18n"
import {
  analysisItemClass,
  chartTableSwitchStyle,
  chartTitleStyle,
  comparablesAnalysisContentStyle,
  dropdownIconProps,
  settingsItemLabelStyle,
} from "./comparables-analysis-common"
import { TrendTabs } from "../comparablesV2"
import ButtonTabs from "../../../../shared/components/buttontabs"
import Icon from "../../../../shared/components/icon"

interface Props {
  trendData: PriceTrend[]
  title: string
  notEnoughOffers: boolean
}

export const ComparablesTrend = ({ trendData, title, notEnoughOffers }: Props) => {
  const [selectedTab, setSelectedTab] = React.useState<TrendTabs>(notEnoughOffers ? "table" : "chart")

  const translate = useMemo(() => {
    return translations().comparablesGraphs
  }, [])

  const [middleParameter, setMiddleParameter] = useState<"median" | "average">("median")
  const [topParameter, setTopParameter] = useState<"seventyFifth" | "ninetieth">("ninetieth")
  const [bottomParameter, setBottomParameter] = useState<"tenth" | "twentyFifth">("tenth")

  React.useEffect(() => {
    if (notEnoughOffers) setSelectedTab("table")
  }, [notEnoughOffers])

  function doChangeOuterParameters(p: string) {
    if (p === "ninetieth") {
      setTopParameter("ninetieth")
      setBottomParameter("tenth")
    } else {
      setTopParameter("seventyFifth")
      setBottomParameter("twentyFifth")
    }
  }

  function doChangeMiddleParameter(p: string) {
    if (p === "median") {
      setMiddleParameter("median")
    } else {
      setMiddleParameter("average")
    }
  }

  return (
    <div className={analysisItemClass}>
      <Flex flexDirection={"row"} alignItems={"center"} gap={20} padding={[0, 0, 8, 0]}>
        <span className={chartTitleStyle}>{title}</span>
        <div className={chartTableSwitchStyle}>
          <ButtonTabs
            values={["chart", "table"]}
            selected={selectedTab}
            onSelect={(t) => setSelectedTab(t)}
            translate={(t) => translate[t]}
          />
        </div>
        <Popover usePortal={false} className={css({ display: "flex" })}>
          <div style={{ cursor: "pointer", display: "flex" }}>
            <Icon name={"settings_options"} colorType={"default"} color={"primary"} fontSize={20} />
          </div>

          <Grid columns={1} padding={16} rowGap={8} width={[200, "px"]}>
            <span className={settingsItemLabelStyle}>{translate.meanValues}</span>
            <HTMLSelect
              fill
              iconProps={dropdownIconProps}
              style={{ backgroundColor: "transparent" }}
              value={middleParameter}
              options={[
                { value: "median", label: translate.median },
                { value: "average", label: translate.average },
              ]}
              onChange={(e) => doChangeMiddleParameter(e.currentTarget.value)}
            />
            <span className={settingsItemLabelStyle}>{translate.range}</span>
            <HTMLSelect
              fill
              iconProps={dropdownIconProps}
              value={topParameter}
              options={[
                { value: "ninetieth", label: "10-90%" },
                { value: "seventyFifth", label: "25-75%" },
              ]}
              onChange={(e) => doChangeOuterParameters(e.currentTarget.value)}
            />
          </Grid>
        </Popover>
      </Flex>
      {trendData.length > 0 && selectedTab === "table" && (
        <div className={comparablesAnalysisContentStyle}>
          <ComparablesTrendTable
            trendData={trendData}
            middleParameter={middleParameter}
            topParameter={topParameter}
            bottomParameter={bottomParameter}
          />
        </div>
      )}
      {!notEnoughOffers && trendData.length > 0 && selectedTab === "chart" && (
        <div className={comparablesAnalysisContentStyle}>
          <ComparablesTrendGraph
            trendData={trendData}
            middleParameter={middleParameter}
            topParameter={topParameter}
            bottomParameter={bottomParameter}
          />
        </div>
      )}
      {(trendData.length === 0 || (notEnoughOffers && selectedTab === "chart")) && (
        <div className={comparablesAnalysisContentStyle}>
          <svg width="100%" height="250">
            <rect width="100%" height="250" rx="5" ry="5" fill="#eee" />
          </svg>
        </div>
      )}
    </div>
  )
}
