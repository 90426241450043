import * as React from "react"
import { translations } from "../i18n"
import { LoginData } from "../models/login-data"
import { useAppSelector } from "../../relas/store"
import { loginStart } from "../reducers/login-slice"
import { onLoginDone } from "./login-is24-form"
import LoadingDimmer from "../../shared/components/loadingdimmer"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"
import TextField from "../../shared/components/textfield"
import Button from "../../shared/components/button"
import Form from "../../shared/components/form"

export const OtpForm = () => {
  const loginData = useAppSelector((state) => state.login.form)
  const inProgress = useAppSelector((state) => state.login.loginInProgress)
  const lastAuthorisedUser = useAppSelector((state) => state.user.user)

  const translate = React.useMemo(() => translations(), [])

  let inputField: HTMLInputElement | null = null
  let [code, setCode] = React.useState<string | null>(null)

  React.useEffect(() => {
    inputField && inputField.focus()
  })

  const handleOtp = () => {
    const data: LoginData = { ...loginData, otpCode: parseInt(code ? code : "") }
    loginStart(data)
      .then((newUser) => onLoginDone(newUser, lastAuthorisedUser, true))
      .catch(() => {})
    setCode(null)
  }

  const strip = (rawOtpCode: string | null): string | null => {
    if (rawOtpCode === null || rawOtpCode === undefined) return null

    const onlyDigits = rawOtpCode.replace(/[^0-9]/g, "")
    return onlyDigits.slice(0, 6)
  }

  const onChangeOtp = (value: string) => {
    const actualValue = strip(value)
    setCode(actualValue)
  }

  const canSubmit = code && code.length === 6

  return (
    <LoadingDimmer loading={inProgress}>
      <FlexContainer padding={[0, 24]}>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            handleOtp()
          }}
        >
          <FlexContainer direction="column" spaceBetween="xxl">
            <p style={{ whiteSpace: "pre-wrap" }}>{translate.login.otpExplanation}</p>
            <TextField
              id="21re-otp"
              ref={(ref) => {
                if (ref && ref.inputElRef) inputField = ref.inputElRef
              }}
              hint={translate.login.otpHint}
              onValueChange={onChangeOtp}
              value={code ?? ""}
            />

            <FlexContainer direction="column" gap={4}>
              <Button id="21re-login-button" disabled={!canSubmit} type="primary" icon="login">
                {translate.login.loginButton}
              </Button>
            </FlexContainer>
          </FlexContainer>
        </Form>
      </FlexContainer>
    </LoadingDimmer>
  )
}
