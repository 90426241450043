import { css } from "emotion"
import { InputGroup, Radio, RadioGroup } from "@blueprintjs/core"
import { AssessmentPriceType, ObjectPrices, PerSqmOrTotal, PriceRange } from "../models/assessment"
import * as React from "react"
import { SetStateAction, useMemo, useState } from "react"
import { translations } from "../i18n"
import { MlPricesFormData } from "./assessment-entry-prices"
import { useEffect } from "react"
import { BarGraph } from "./assessment-entry-bar-graph"
import { COLORS } from "../../shared/components/ui/colors"
import { Flex } from "../../shared/components/ui/flex"
import { FlexItem } from "../../shared/components/ui/flex-item"
import { useSelector } from "react-redux"
import { State } from "../reducers/state"
import { GraphScale } from "../models/graph-scale"
import {
  maxValidator,
  minValidator,
  moreThanValidator,
  numberValidator,
  requiredValidator,
  sequentialValidator,
  valuesOfPriceRange,
} from "../../utils/utils"
import { NumberInput } from "../../shared/components/ui/number-input"
import { yearValidator } from "../../utils/form-validators"
import Checkbox from "../../shared/components/checkbox"
import Select from "../../shared/components/select"
import NumberField from "../../shared/components/numberfield"
import GridItem from "../../shared/components/restyle-grid/griditem"
import Grid from "../../shared/components/restyle-grid/grid"
import { getThemeColorVar } from "../../shared/helper/color"

const inputLabelClass = css({
  fontSize: "12px",
  fontWeight: "bold",
})

const radioClass = css({
  marginBottom: "0px", //override bp default
  paddingTop: "4px",
})

const triangleClass = (bottomOffset: number) =>
  css({
    width: 0,
    height: 0,
    borderLeft: "8px solid transparent",
    borderRight: "8px solid transparent",
    borderTop: `12px solid ${getThemeColorVar("background", "lighter")}`,
    marginBottom: `-${bottomOffset}px`,
  })

const radioGroupClass = css({
  marginTop: "-12px", // align with input fields
  paddingLeft: "16",
})
const wrap = css({
  margin: "0 -24px",
  borderTop: `1px solid ${getThemeColorVar("border", "default")}`,
})

interface Props {
  isResidential: boolean
  mlPricesState: MlPricesFormData
  setMlPricesState: (newValue: SetStateAction<MlPricesFormData>) => void
  area: number
  perSqmOrTotal: PerSqmOrTotal
  priceType: AssessmentPriceType
  assessmentEntryId: string
}

export const selectedObjectPrices = (
  priceType: AssessmentPriceType,
  objectPrices: ObjectPrices | null
): PriceRange | undefined => {
  switch (priceType) {
    case "office":
      return objectPrices?.office
    case "retail":
      return objectPrices?.retail
    case "residentialRent":
      return objectPrices?.residentialRent
    case "residentialSale":
      return objectPrices?.residentialSale
    case "plotSale":
      return objectPrices?.plotSale
    case "hall":
    case "logistics":
      return objectPrices?.logistics
  }
}
export const minConstructionYear = 1700

export const maxYear = new Date().getFullYear()

export const ObjectPriceInputs = ({
  isResidential,
  mlPricesState,
  setMlPricesState,
  area = 1,
  perSqmOrTotal,
  priceType,
  assessmentEntryId,
}: Props) => {
  const translate = useMemo(translations, [translations])
  const [internalMlPricesState, setInternalMlPricesState] = useState(mlPricesState)
  const objectPricesLoading = useSelector((state: State) => state.assessment.objectPricesLoadInProgress)

  const isPlot = useMemo(() => priceType === "plotSale", [priceType])

  const handleTyping =
    <K extends keyof MlPricesFormData>(inputName: K) =>
    (newValue: MlPricesFormData[K], isValid: boolean) => {
      let newState = { ...internalMlPricesState, [inputName]: newValue }
      setInternalMlPricesState(newState)
      if (isValid) {
        setMlPricesState(newState)
      }
    }

  const onNumberInputChange =
    <K extends keyof MlPricesFormData>(inputName: K) =>
    (newValue: number, _: string, valid: boolean) => {
      if (valid) {
        setMlPricesState({ ...mlPricesState, [inputName]: newValue })
      }
    }

  useEffect(() => {
    if (mlPricesState != internalMlPricesState) {
      setInternalMlPricesState(mlPricesState)
    }
  }, [mlPricesState])

  const toggleRadioSelection = () => {
    const newValue = mlPricesState.perSqmOrTotal === "eurPerSqm" ? "eurTotal" : "eurPerSqm"
    setMlPricesState({ ...mlPricesState, perSqmOrTotal: newValue })
  }

  const rowGap = 8
  const disableConstructionYear: boolean = mlPricesState.newBuilding

  const agsPrices = useSelector((state: State) => state.assessment.currentAssessmentEntry?.agsPrices[priceType])
  const cellPrices = useSelector((state: State) => state.assessment.currentAssessmentEntry?.cellPrices[priceType])
  const objectPrices = useSelector((state: State) => state.assessment.currentObjectPrices)

  const allPriceValues: number[] = [
    ...valuesOfPriceRange(agsPrices),
    ...valuesOfPriceRange(cellPrices),
    ...valuesOfPriceRange(selectedObjectPrices(priceType, objectPrices)),
  ]

  const scaleForWidth = (newGraphWidth: number): GraphScale => {
    const graphOffset = 24
    return new GraphScale(allPriceValues, graphOffset, newGraphWidth - 2 * graphOffset)
  }

  return (
    <div className={wrap} id={"assessment-entry-object-price-inputs-" + assessmentEntryId}>
      <div style={{ padding: "24px" }}>
        <GridItem backgroundColor={{ color: "background", colorType: "lighter" }}>
          {!isPlot && (
            <Grid columns={6} padding={16} colGap={16} rowGap={rowGap}>
              <div className={inputLabelClass}>{translate.assessmentEntryDetails.constructionOrRenovationYear}</div>
              <div className={inputLabelClass}></div>
              <div className={inputLabelClass}>{translate.assessmentEntryDetails.livingArea}</div>
              <div className={inputLabelClass}>{translate.assessmentEntryDetails.quality}</div>
              <div className={inputLabelClass}>
                {isResidential ? translate.assessmentEntryDetails.houseOrApartment : ""}
              </div>
              <GridItem />
              {disableConstructionYear ? (
                <InputGroup disabled={true} value="-" />
              ) : (
                <NumberInput
                  defaultValue={parseInt(internalMlPricesState.constructionYear)}
                  validator={sequentialValidator([
                    requiredValidator(translate.validationTranslations.required),
                    minValidator(minConstructionYear, translate.validationTranslations.min(minConstructionYear)),
                    maxValidator(maxYear, translate.validationTranslations.max(maxYear)),
                    numberValidator(translate.validationTranslations.notANumber),
                    yearValidator(translate.validationTranslations.constructionYearValidation),
                  ])}
                  onValueChange={onNumberInputChange("constructionYear")}
                  disabled={disableConstructionYear}
                />
              )}
              <Checkbox
                id={"new_building-checkbox-" + assessmentEntryId + "-" + mlPricesState.newBuilding}
                label={translate.assessmentEntryDetails.newBuilding}
                checked={mlPricesState.newBuilding}
                onChange={() => handleTyping("newBuilding")(!internalMlPricesState.newBuilding, true)}
              />
              <NumberInput
                defaultValue={parseFloat(internalMlPricesState.area)}
                validator={sequentialValidator([
                  requiredValidator(translate.validationTranslations.required),
                  moreThanValidator(0, translate.validationTranslations.moreThan(0)),
                  maxValidator(999999, translate.validationTranslations.max(999999)),
                  numberValidator(translate.validationTranslations.notANumber),
                ])}
                onValueChange={onNumberInputChange("area")}
                suffix={"m²"}
              />
              <Select
                size="small"
                value={mlPricesState.quality}
                onValueChange={handleTyping("quality")}
                options={["★", "★★", "★★★"].map((label, idx) => ({ value: (idx + 1).toString(), label }))}
              />
              <GridItem>
                {isResidential && (
                  <Select
                    size="small"
                    value={mlPricesState.houseOrApartment}
                    onValueChange={handleTyping("houseOrApartment")}
                    options={[
                      { value: "apartment", label: translate.assessmentEntryDetails.priceCategories.apartment },
                      { value: "house", label: translate.assessmentEntryDetails.priceCategories.house },
                    ]}
                  />
                )}
              </GridItem>
              <div className={radioGroupClass}>
                <RadioGroup selectedValue={mlPricesState.perSqmOrTotal} onChange={() => toggleRadioSelection()}>
                  <Radio
                    key={"eurPerSqm"}
                    className={radioClass}
                    label={translate.assessmentEntryDetails.euroPerSqm}
                    value={"eurPerSqm"}
                  />
                  <Radio
                    key={"eurTotal"}
                    className={radioClass}
                    label={translate.assessmentEntryDetails.euro}
                    value={"eurTotal"}
                  />
                </RadioGroup>
              </div>
            </Grid>
          )}
          {isPlot && (
            <Flex flexDirection={"row"} padding={16} gap={8}>
              <FlexItem alignSelf={"center"}>{translate.assessmentEntryDetails.livingArea}</FlexItem>
              <FlexItem alignSelf={"center"} flexGrow={0}>
                <NumberField
                  size="small"
                  value={internalMlPricesState.area}
                  suffix="m²"
                  required
                  min={1}
                  max={99999}
                  onValueChange={handleTyping("area")}
                />
              </FlexItem>
              <RadioGroup
                selectedValue={mlPricesState.perSqmOrTotal}
                onChange={() => toggleRadioSelection()}
                inline={true}
              >
                <Radio
                  key={"eurPerSqm"}
                  className={radioClass}
                  label={translate.assessmentEntryDetails.euroPerSqm}
                  value={"eurPerSqm"}
                />
                <Radio
                  key={"eurTotal"}
                  className={radioClass}
                  label={translate.assessmentEntryDetails.euro}
                  value={"eurTotal"}
                />
              </RadioGroup>
              <div></div>
            </Flex>
          )}
        </GridItem>
        <div style={{ display: "flex", justifyContent: "center", marginBottom: "16px" }}>
          <div className={triangleClass(rowGap)} />
        </div>
        <BarGraph
          label={
            isPlot ? translate.assessmentEntryDetails.usageType.plot : translate.assessmentEntryDetails.objectPriceLabel
          }
          color={COLORS.secondary1.default}
          data={selectedObjectPrices(priceType, objectPrices)}
          labelWidth={"120px"}
          scaleForWidth={scaleForWidth}
          perSqmOrTotal={perSqmOrTotal}
          area={area}
          showSpinners={objectPricesLoading}
        />
      </div>
    </div>
  )
}
