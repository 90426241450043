import * as React from "react"
import { useEffect, useState } from "react"
import { AddressSuggestionAutoComplete } from "./address-suggestion-auto-complete"
import {
  AddressSuggestion,
  AddressSuggestionResponse,
  GeoInformation,
  HereAddress,
  LocationResponse,
} from "../models/poi-explorer"
import { useDebounce } from "../../utils/use-debounce"
import Axios, { CancelTokenSource } from "axios"
import { geoServiceUrl } from "../../app_config"
import * as proj from "ol/proj"
import { Address } from "../../assessment/models/address"
import { isoCountry } from "iso-country"
import { language } from "../language"

type Props = {
  label: string
  onValueChange: (value?: Address) => void
  cleanUpTrigger?: number
  forcedAddress?: Address
}

interface AddressDialogState {
  addressSelection: Address | null
  center: number[]
  zoom: number
  searchTermSuggestion: AddressSuggestion | undefined
}

const INITIAL_ADDRESS_STATE: AddressDialogState = {
  center: proj.fromLonLat([10.5, 51]),
  zoom: 6,
  searchTermSuggestion: undefined,
  addressSelection: null,
}

let cancel: CancelTokenSource | undefined = undefined

const SUGGESTION_SUFFIX = ", Deutschland"

export const AddressSuggestionAutoCompleteField = (props: Props) => {
  const [suggestions, setSuggestions] = useState<AddressSuggestion[]>([])
  const [debouncedSearchTerm, searchTerm, setSearchTerm] = useDebounce<string>("", 200)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [firstLoading, setFirstLoading] = useState(true)
  const [manualCoords, setManualCoords] = useState(false)
  const [addressDialogState, setAddressDialogState] = useState(INITIAL_ADDRESS_STATE)

  useEffect(() => {
    if (firstLoading) setFirstLoading(false)
    else if (!manualCoords && !addressDialogState.searchTermSuggestion) {
      setAddressDialogState({ ...addressDialogState, searchTermSuggestion: undefined })
      setAddressDialogState({
        ...addressDialogState,
        searchTermSuggestion: undefined,
      })
      setSuggestions([])
      getSuggestions(debouncedSearchTerm + SUGGESTION_SUFFIX, (suggestions) => {
        setSuggestions(suggestions)
        setShowSuggestions(suggestions.length > 0)
      })
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    setSearchTerm("")
    setSuggestions([])
    setShowSuggestions(false)
  }, [props.cleanUpTrigger])

  useEffect(() => {
    if (props.forcedAddress) {
      setManualCoords(true)
      setAddressDialogState({
        ...addressDialogState,
        addressSelection: props.forcedAddress,
      })
      const formattedAddress =
        props.forcedAddress.formatted ??
        `${getOrElse(props.forcedAddress.country)}${getOrElse(props.forcedAddress.postalCode)}${getOrElse(
          props.forcedAddress.locality
        )}${getOrElse(props.forcedAddress.route)}${getOrElse(props.forcedAddress.streetNumber)}`

      setSearchTerm(formattedAddress)
    }
  }, [props.forcedAddress])

  const fetchSuggestions = (address: string): Promise<void> => {
    setManualCoords(false)
    setAddressDialogState({ ...addressDialogState, searchTermSuggestion: undefined })
    setShowSuggestions(false)
    setSuggestions([])
    setSearchTerm(address)
    return Promise.resolve()
  }

  const locationToGeoInformation = (locationResponse: LocationResponse): GeoInformation => {
    const location = locationResponse.response.view[0].result[0].location

    return {
      coordinates: {
        latitude: location.displayPosition.latitude,
        longitude: location.displayPosition.longitude,
      },
    }
  }

  const getSuggestions = (address: string, callback: (suggestions: AddressSuggestion[]) => void): void => {
    cancel?.cancel()
    cancel = Axios.CancelToken.source()

    if (address.trim().length !== 0) {
      const lang = language()
      Axios.get(
        `${geoServiceUrl}/api/geocode/by/here/autocomplete?query=${encodeURIComponent(
          address
        )}&maxresults=10&language=${lang}`,
        { withCredentials: true, cancelToken: cancel.token }
      ).then(
        (success) => {
          cancel = undefined
          const response: AddressSuggestionResponse = success.data
          const suggestions = response.suggestions

          return Promise.all(
            suggestions.map((suggestion) =>
              Axios.get(
                `${geoServiceUrl}/api/here/location/${encodeURIComponent(suggestion.locationId)}?language=${lang}`,
                { withCredentials: true }
              ).then((success) => {
                const locationResponse: LocationResponse = success.data
                const result: AddressSuggestion = {
                  ...suggestion,
                  address: resolveAddress(suggestion, locationResponse),
                  geoInformation: locationToGeoInformation(locationResponse),
                }

                return result
              })
            )
          ).then(
            (success) => callback(success),
            (error) => {
              if (!Axios.isCancel(error)) {
                callback([])
              }
            }
          )
        },
        (error) => {
          if (!Axios.isCancel(error)) {
            callback([])
          }
        }
      )
    }
  }

  const resolveAddress = (suggestion: AddressSuggestion, locationResponse: LocationResponse): HereAddress => {
    const location = locationResponse.response.view[0].result[0].location

    if (location.address) {
      const address = location.address
      const maybeCountry = address.additionalData && address.additionalData["CountryName"]

      return {
        ...address,
        country: maybeCountry || suggestion.address.country,
      }
    }

    return suggestion.address
  }

  const getOrElse = (maybe: string | undefined) => `${maybe ? `${maybe} ` : ""}`

  const selectAddress = (suggestion: AddressSuggestion) => {
    const formattedAddress = `${getOrElse(suggestion.address.country)}${getOrElse(
      suggestion.address.postalCode
    )}${getOrElse(suggestion.address.city)}${getOrElse(suggestion.address.street)}${getOrElse(
      suggestion.address.houseNumber
    )}`

    cancel?.cancel()

    const suggestedAddress = suggestionToAddress(suggestion)

    setAddressDialogState({
      ...addressDialogState,
      addressSelection: suggestedAddress,
      searchTermSuggestion: suggestion,
      center: proj.fromLonLat([
        suggestion.geoInformation.coordinates.longitude,
        suggestion.geoInformation.coordinates.latitude,
      ]),
      zoom: 16,
    })
    setManualCoords(false)
    setSearchTerm(formattedAddress)
    setShowSuggestions(false)
    props.onValueChange(suggestedAddress)
  }

  const suggestionToAddress = (s: AddressSuggestion): Address => {
    const lookupResult = isoCountry(s.countryCode)

    return {
      location: {
        lat: s.geoInformation.coordinates.latitude,
        lng: s.geoInformation.coordinates.longitude,
      },
      route: s.address.street,
      administrativeAreaLevel1: s.address.state,
      country: lookupResult ? lookupResult.code : "",
      locality: s.address.city,
      postalCode: s.address.postalCode,
      streetNumber: s.address.houseNumber,
    }
  }

  const onForceSelection = () => {
    getSuggestions(searchTerm + SUGGESTION_SUFFIX, (suggestions) => {
      if (suggestions.length == 1) {
        selectAddress(suggestions[0])
      } else {
        setSuggestions(suggestions)
        setShowSuggestions(suggestions.length > 0)
      }
    })
  }

  return (
    <AddressSuggestionAutoComplete
      displaySuggestion={(s: AddressSuggestion) => s.address.label || s.label}
      label={props.label}
      showSuggestions={showSuggestions}
      suggestions={suggestions}
      value={searchTerm}
      onValueChange={fetchSuggestions}
      selectSuggestion={(s) => s && selectAddress(s)}
      onFocus={() => suggestions && suggestions.length > 0 && setShowSuggestions(true)}
      toDoOnBlur={() => setShowSuggestions(false)}
      onForceSuggestion={onForceSelection}
    />
  )
}
