import * as React from "react"
import { FC } from "react"
import { css } from "emotion"
import { getThemeColorVar } from "../../shared/helper/color"

const backgroundClass = css({
  background: getThemeColorVar("background", "lighter"),
  position: "relative",
})

const foregroundClass = (score: number, inverted?: boolean) =>
  css({
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    width: `${score}%`,
    background: inverted ? getThemeColorVar("neutral", "default") : getThemeColorVar("secondary1", "dark"),
  })

interface Props {
  score: number
  inverted?: boolean
}

export const ScoreBar: FC<Props> = ({ score, inverted }) => {
  return (
    <div className={backgroundClass}>
      <div className={foregroundClass(score, inverted)} />
    </div>
  )
}
