import { Radio, RadioGroup } from "@blueprintjs/core"
import React from "react"
import { translations } from "../../i18n"
import { css } from "emotion"
import PoiCategoriesList from "../POI-explorer/poi-categories-list"
import { POISelectionMode } from "../../models/AssessmentDashboard"
import { aggregatedPOIsCategories } from "./pois-widget"
import { getThemeColorVar } from "../../../shared/helper/color"

const styles = {
  radioGroup: css({
    padding: "16px",
  }),
  poisList: css({
    "> div": {
      borderLeft: "none",
      borderRight: "none",
      borderTop: "none",
    },
    "> div:first-child": {
      borderTop: `1px solid ${getThemeColorVar("border", "default")}`,
    },
    height: "100%",
  }),
}

type Props = {
  selectedPOIsInDrawer: Array<string>
  setSelectedPOIsInDrawer: (selected: Array<string>) => void
  selectionModeInDrawer: POISelectionMode
  setSelectionModeInDrawer: (mode: POISelectionMode) => void
}

export const POIsSelection = (props: Props) => {
  const t = translations()

  return (
    <>
      <RadioGroup
        className={styles.radioGroup}
        selectedValue={props.selectionModeInDrawer}
        onChange={(event) =>
          props.setSelectionModeInDrawer((event.target as HTMLTextAreaElement).value as POISelectionMode)
        }
      >
        <Radio
          label={t.dashboard.POIs.allPOIs}
          value={"defaults"}
          onClick={() => props.setSelectedPOIsInDrawer(aggregatedPOIsCategories)}
        />
        <Radio
          label={t.dashboard.POIs.selectedPOIs}
          value={"partial"}
          onClick={() => props.setSelectedPOIsInDrawer([])}
        />
      </RadioGroup>
      {props.selectionModeInDrawer === "partial" && (
        <div className={styles.poisList}>
          <PoiCategoriesList
            textSearch={null}
            selectedPOICategories={new Set(props.selectedPOIsInDrawer)}
            setSelectedPOICategories={(selectedPOIs) => props.setSelectedPOIsInDrawer([...selectedPOIs])}
            thematicGroupsCollapsed={false}
            selectionLimit={10}
          />
        </div>
      )}
      {props.selectionModeInDrawer === "defaults" && <div />}
    </>
  )
}
