import * as React from "react"
import { IconName } from "@21re/revoicons/dist/icon-type"
import { Fragment } from "react"
import { Tooltip } from "./ui/tooltip"
import { css } from "emotion"
import Icon from "./icon"

const styles = {
  controlButton: css({
    height: "40px",
    width: "40px",
    margin: "10px",
    backgroundColor: "white",
    border: "none",
    borderRadius: "2px",
    cursor: "pointer",
  }),
}
interface Props {
  tooltip: string | JSX.Element
  buttonName: IconName
  onClick: () => void
  fontSize?: number
  isDark?: boolean
}
export const TooltipButtonContainer = (props: Props) => {
  const [tooltipIsHovered, setTooltipIsHovered] = React.useState(false)

  const MouseOver = () => {
    setTooltipIsHovered(true)
  }

  const MouseOut = () => {
    setTooltipIsHovered(false)
  }

  const getButton = (): JSX.Element => {
    return props.isDark ? (
      <button onClick={props.onClick} onMouseOver={MouseOver} onMouseOut={MouseOut}>
        <Icon name={props.buttonName} fontSize={props.fontSize} />
      </button>
    ) : (
      <button onClick={props.onClick} onMouseOver={MouseOver} onMouseOut={MouseOut} className={styles.controlButton}>
        <Icon name={props.buttonName} color={"background"} colorType={"darker"} fontSize={props.fontSize} />
      </button>
    )
  }

  return (
    <Fragment>
      {tooltipIsHovered ? (
        <Tooltip placement={"auto"} tooltip={props.tooltip}>
          {getButton()}
        </Tooltip>
      ) : (
        getButton()
      )}
    </Fragment>
  )
}
