import { DistributionGraphResult, DistributionPercentiles } from "../../../../generated-apis/comparables-service"
import { COLORS } from "../../../../shared/components/ui/colors"
import { Bar } from "react-chartjs-2"
import * as React from "react"
import { useMemo } from "react"
import { ChartData, ChartOptions } from "chart.js"
import { translations } from "../../../i18n"
import { translations as sharedTranslations } from "../../../../shared/i18n"
import { numericYearWithQuarterToLabel } from "../../../../utils/numeric-year-with-quarter-to-label"
import { AnnotationOptions, LabelOptions } from "chartjs-plugin-annotation"
import { calculateStep, ShowAnnotationOption } from "./comparables-analysis-common"
import { DeepPartial } from "chart.js/types/utils"
import { formatNumber } from "../../../../shared/helper/number-format"

interface Props {
  distributionData: DistributionGraphResult
  bucketToLabel: (bucketValue: number) => string
  bucketSuffix: string
  middleParameter: keyof DistributionPercentiles
  showAnnotations: ShowAnnotationOption
  isRangeBuckets: boolean
}

const annotationBase: AnnotationOptions = {
  type: "line",
  xScaleID: "x",
  borderWidth: 1,
  borderColor: "#e1e1eb",
}

const annotationLabelBase: Partial<LabelOptions> = {
  enabled: true,
  position: "start",
  yAdjust: -7,
  height: 30,
  font: {
    family: "Lato, Roboto, Arial, Helvetica, sans-serif",
    size: 12,
    style: "normal",
    weight: null,
    lineHeight: 16,
  },
  color: "#828796",
  backgroundColor: "#e1e1eb",
}

export const yearQuarterBucketToLabel = (bucketValue: number) => {
  return numericYearWithQuarterToLabel(bucketValue)
}

export const yearBucketToLabel = (bucketValue: number) => {
  return Math.round(bucketValue).toString()
}

export const priceToBucketLabel = (bucketValueEur: number) => {
  const valueInEur = bucketValueEur > 1000 ? Math.round(bucketValueEur) : bucketValueEur
  return formatNumber(valueInEur, 2).toString()
}

export const areaToBucketLabel = (valueInM2: number) => {
  const valueInEur = valueInM2 > 10 ? Math.round(valueInM2) : valueInM2
  return formatNumber(valueInEur, 2).toString()
}

export const ComparablesDistributionGraph = ({
  distributionData,
  bucketToLabel,
  bucketSuffix,
  middleParameter,
  showAnnotations,
  isRangeBuckets,
}: Props) => {
  const t = useMemo(() => {
    return {
      ...translations().assessmentEntryDetails,
      ...translations().comparablesGraphs,
      shared: sharedTranslations(),
    }
  }, [translations])

  const { step, chartData, annotations } = useMemo(() => {
    const step = calculateStep(distributionData)

    const tenth = Math.round((distributionData.percentiles.tenth - distributionData.min) / step)
    const twentyFifth = Math.round((distributionData.percentiles.twentyFifth - distributionData.min) / step)
    const middle = Math.round((distributionData.percentiles[middleParameter] - distributionData.min) / step)
    const seventyFifth = Math.round((distributionData.percentiles.seventyFifth - distributionData.min) / step)
    const ninetieth = Math.round((distributionData.percentiles.ninetieth - distributionData.min) / step)

    const bucketData: number[] = (() => {
      const arr = Array(distributionData.count).fill(0)
      distributionData.buckets?.forEach((b) => {
        arr[b.bucket - 1] = b.offers
      })
      return arr
    })()

    const labelWithoutDuplicates = bucketData.reduce((acc, cur, index) => {
      const number = distributionData.min + step * index
      const newLabel = `${bucketToLabel(number)} ${bucketSuffix}`
      acc.push(acc.indexOf(newLabel) < 0 ? newLabel : "")
      return acc
    }, [] as string[])

    const chartData: ChartData<"bar"> = {
      labels: labelWithoutDuplicates,
      datasets: [
        {
          label: "distribution",
          data: bucketData,
          backgroundColor: "rgba(0, 185, 225, 0.3)",
          barPercentage: 0.95,
          categoryPercentage: 1,
          borderWidth: 0,
        },
      ],
    }

    let annotations: DeepPartial<Record<string, AnnotationOptions>> = {}

    if (showAnnotations === "all" || showAnnotations === "no_median") {
      annotations = {
        ...annotations,
        tenth: {
          ...annotationBase,
          xMin: tenth,
          xMax: tenth,
          label: {
            ...annotationLabelBase,
            yAdjust: 93,
            content: "10%",
          },
        },
        twentyFifth: {
          ...annotationBase,
          xMin: twentyFifth,
          xMax: twentyFifth,
          label: {
            ...annotationLabelBase,
            yAdjust: 68,
            content: "25%",
          },
        },
        seventyFifth: {
          ...annotationBase,
          xMin: seventyFifth,
          xMax: seventyFifth,
          label: {
            ...annotationLabelBase,
            yAdjust: 18,
            content: "75%",
          },
        },
        ninetieth: {
          ...annotationBase,
          xMin: ninetieth,
          xMax: ninetieth,
          label: {
            ...annotationLabelBase,
            content: "90%",
          },
        },
      }
    }

    if (showAnnotations === "all") {
      annotations["median"] = {
        ...annotationBase,
        xMin: middle,
        xMax: middle,
        label: {
          ...annotationLabelBase,
          yAdjust: 43,
          content: middleParameter === "median" ? t.median : t.average,
        },
      }
    }

    return {
      step,
      chartData,
      annotations,
    }
  }, [distributionData, middleParameter])

  const chartOptions: ChartOptions<"bar"> = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          display: true,
          stepSize: 1,
          maxTicksLimit: 5,
        },
        grid: {
          borderDash: [5, 5],
        },
      },
      x: {
        type: "category",
        position: "bottom",
        ticks: {
          font: {
            family: "Lato, Roboto, Arial, Helvetica, sans-serif",
            size: 12,
          },
          autoSkip: true,
          minRotation: 0,
          maxRotation: 0,
          sampleSize: 100,
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,

    plugins: {
      annotation: {
        annotations: annotations,
      },
      legend: {
        display: false,
      },
      tooltip: {
        titleFont: {
          size: 16,
          family: "Lato",
        },
        bodyFont: {
          size: 14,
          family: "Lato",
        },
        titleMarginBottom: 8,
        padding: {
          top: 8,
          left: 8,
          bottom: 8,
          right: 8,
        },
        titleSpacing: 16,
        caretPadding: 10,
        displayColors: false,
        backgroundColor: COLORS.background.dark,
        position: "nearest",
        callbacks: {
          label: (item) => {
            const min = bucketToLabel(
              Math.max(distributionData.min, distributionData.min + step * item.dataIndex - step / 2)
            )
            const max = bucketToLabel(
              Math.min(distributionData.max, distributionData.min + step * item.dataIndex + step / 2)
            )
            if (!isRangeBuckets || min === max) {
              const nonRangeLabel = bucketToLabel(distributionData.min + step * item.dataIndex)

              return [`${nonRangeLabel} ${bucketSuffix}`, "", t.observedOffers + ": " + `${item.parsed.y}`]
            }

            return [
              t.range + ": " + `${min} - ${max} ${bucketSuffix}`,
              "",
              t.observedOffers + ": " + `${item.parsed.y}`,
            ]
          },
          title: () => "",
        },
      },
    },
  }

  return <Bar data={chartData} options={chartOptions} height={250} />
}
