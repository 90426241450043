import * as React from "react"
import { IsochronePopup } from "../../assessment/components/isochrone-popup"
import { IsochroneType } from "../../assessment/components/isochrone-type"
import { translations } from "../i18n"
import { Control } from "ol/control"
import { CLASS_CONTROL } from "ol/css"
import { createRoot } from "react-dom/client"
import { AppModules } from "../../menu/util/app-location-types"
import { PopoverContent } from "./PopoverContent"

export class IsochroneControl extends Control {
  private mapIsochroneButton: React.RefObject<MapIsochroneButtonPopover>

  constructor(
    module: AppModules["locationAssessment"],
    initialIsoSettings: IsochroneType,
    setIsochroneSettings: (settings: IsochroneType) => void,
    showPopover: boolean,
    onChangeShowPopover: (value: boolean) => void,
    parent?: HTMLElement,
    assesmentEntry?: any
  ) {
    super({ element: document.createElement("div") })
    this.element.className = `${CLASS_CONTROL}`
    this.element.style.right = "0.5em"
    this.element.style.top = "13.5em"
    this.mapIsochroneButton = React.createRef()

    createRoot(this.element).render(
      <MapIsochroneButtonPopover
        module={module}
        isochroneSettings={initialIsoSettings}
        setIsochroneSettings={setIsochroneSettings}
        showPopover={showPopover}
        onChangeShowPopover={onChangeShowPopover}
        ref={this.mapIsochroneButton}
        parent={parent}
        assessmentEntry={assesmentEntry}
      />
    )
  }
}

interface MapIsochroneButtonPopoverProps {
  module: AppModules["locationAssessment"]
  isochroneSettings: IsochroneType
  setIsochroneSettings: (settings: IsochroneType) => void
  showPopover: boolean
  onChangeShowPopover: (show: boolean) => void
  parent?: HTMLElement
  assessmentEntry?: any
}

interface MapIsochroneButtonPopoverState {
  settings: IsochroneType
  isOpen: boolean
}

class MapIsochroneButtonPopover extends React.Component<
  MapIsochroneButtonPopoverProps,
  MapIsochroneButtonPopoverState
> {
  private t = translations()

  constructor(props: MapIsochroneButtonPopoverProps) {
    super(props)

    this.state = {
      settings: this.props.isochroneSettings,
      isOpen: this.props.showPopover,
    }
  }

  render() {
    return (
      <div title={this.t.isochrone}>
        <PopoverContent
          iconname="marker"
          tooltip={this.t.isochrone}
          parent={this.props.parent}
          title={this.t.isochrone}
          justX
        >
          <IsochronePopup
            hideX={true}
            assessmentEntry={this.props.assessmentEntry}
            isochrone={this.state.settings}
            onChange={(field, value) => {
              this.setState({
                ...this.state,
                settings: { ...this.state.settings, ...{ [field]: value } },
              })
              this.props.setIsochroneSettings({ ...this.state.settings, ...{ [field]: value } })
            }}
            onClose={() => {
              this.setState({ ...this.state, isOpen: false })
              this.props.onChangeShowPopover(false)
            }}
            moduleName={"MARKET_DATA"}
            isContained
            hideRightMargin={true}
          />
        </PopoverContent>
      </div>
    )
  }
}
