import * as React from "react"
import { RevoMainMenuItem } from "./revo-main-menu-item"
import {
  App,
  AppLocationForApp,
  Apps,
  ModulesForSubmenu,
  ModulesForSubmenuWithScopeRequirement,
  SubmenuEntry,
} from "./util/app-location-types"
import { appLocationConfig } from "./util/app-location-config"
import { filterAuthorizedMenuItems, userApps } from "./util/app-authorization"
import { css, cx } from "emotion"
import RevoMenuItemGroup from "./revo-menu-item-group"
import { CookieScopeRequirement } from "./models/scoperequirements"
import { Services } from "./models/services"
import { scopeRequirementIsFulfilled } from "./util/scorefromcookies"
import { menuSetActiveAppLocation } from "../shared/reducers/navigation-slice"
import { NavigationPageData } from "../shared/actions/navigation"
import { featureEnabled, UI_FEATURE, UI_FEATURES_SET } from "../utils/features-toggle"
import { doChangeModule } from "../relas/router"
import { useAppSelector } from "../relas/store"
import { getThemeColorVar } from "../shared/helper/color"
import { getCookieValue } from "../shared/helper/cookies"
import { Address } from "../assessment/models/address"

const redesignMenu = css({
  padding: "0 10px 10px 10px",
  borderTop: `1px solid ${getThemeColorVar("primary", "light")}`,
})

const backgroundWrapperRedesign = css({
  height: "100%",
  boxShadow: "none",
  borderRight: `1px solid ${getThemeColorVar("primary", "light")}`,
  borderTop: `1px solid ${getThemeColorVar("primary", "light")}`,
  width: "81px",
})

const menuEmptyElementOverlay = css({
  position: "absolute",
  padding: "0 10px",
  height: "60px",
  width: "60px",
  backgroundColor: getThemeColorVar("primary", "dark"),
  alignSelf: "start",
  zIndex: -1,
})

const addressMenuLine = css({
  display: "flex",
  flexDirection: "column",
  rowGap: "4px",
  padding: "8px 12px",
  color: "white",
  fontWeight: "bold",
  alignSelf: "start",
  position: "relative",
  zIndex: 1,
})

interface Props {
  user?: User
  open: boolean
  overlay: boolean
  location?: AppLocationForApp<App, any>
  toggleMainMenu: () => void
  toggleAppDrawer: () => void
  onMouseEnter: () => void
  onMouseLeave: () => void
  menuIsHovered: boolean
  scopes: Array<CookieScopeRequirement>
  address?: Address
}

export type FooterMenuLinks = {
  key: string
  url: string
  navigationPage?: NavigationPageData | ((e: React.MouseEvent) => void)
  className?: string
}

export type AppDrawerMenuGroup = {
  [key in App]?: AppItem
}

type ValidateAppScopes = (existingScopes: CookieScopeRequirement[]) => boolean

export interface AppItem {
  service: [Services, string] | NavigationPageData
  scopeRequirements: ValidateAppScopes
  icon: string
}

const ImpersonatingCookieName = "TS_AUTH_IMPERSONATE"
export const isUserImpersonating = () => getCookieValue(ImpersonatingCookieName) !== null

export interface TwentyOneWindow {
  twentyone?: {
    allowedProducts?: string[]
    language?: string
  }
}

export function allowedProducts(): string[] | undefined {
  const twentyOneWindow = (window as unknown as TwentyOneWindow).twentyone

  return twentyOneWindow && twentyOneWindow.allowedProducts
}

export const isProductAvailable = (productRequirement: string) => {
  const products = allowedProducts()

  return products ? products.some((_) => _ === productRequirement) : true
}

export const filterMenuEntries = <T extends App>(entries: SubmenuEntry<T>[], scopes: CookieScopeRequirement[]) => {
  return entries
    .filter((_) => (_.productRequirement ? isProductAvailable(_.productRequirement) : true))
    .filter((_) => (_.scopeRequirement ? scopeRequirementIsFulfilled(_.scopeRequirement, scopes) : true))
}

export const RevoApplicationMenu = (props: Props) => {
  const isImpersonating = isUserImpersonating()

  const currentPage = useAppSelector((state) => state.navigation.currentPage)

  const isProductAvailable = (productRequirement: string) => {
    const products = allowedProducts()

    return products ? products.some((_) => _ === productRequirement) : true
  }

  const filterMenuEntries = <T extends App>(entries: SubmenuEntry<T>[], scopes: CookieScopeRequirement[]) => {
    return entries
      .filter((_) => (_.productRequirement ? isProductAvailable(_.productRequirement) : true))
      .filter((_) => (_.scopeRequirement ? scopeRequirementIsFulfilled(_.scopeRequirement, scopes) : true))
  }

  const renderGroup = <T extends App>(
    entries: SubmenuEntry<T>[],
    index: number,
    onMouseEnter: () => void,
    onMouseLeave: () => void,
    activeModule: ModulesForSubmenu<any, any>,
    modulePage: boolean | undefined,
    scopes: Array<CookieScopeRequirement>,
    currentLocation?: AppLocationForApp<T, Apps[T]>
  ) => {
    const menuEntries = filterMenuEntries(entries, scopes).filter((_) => !_.toolsPopup)

    const isImpersonating = isUserImpersonating()
    // single application works on a service scope only

    const renderModule =
      (entry: SubmenuEntry<T, Apps[T]>) => (entryModule: ModulesForSubmenuWithScopeRequirement<T, Apps[T]>) => {
        // We have a mismatch between the page name for "assessment" only
        const isActiveModule =
          entryModule.module === "assessment" ? activeModule === "assessments" : activeModule === entryModule.module

        const appModuleLocation = {
          app: entry.app,
          section: entry.entry,
          module: entryModule.module,
        } as AppLocationForApp<typeof entry.app, typeof entry.entry>

        return (
          <RevoMainMenuItem
            key={entryModule.module}
            service={entry.service}
            path={entryModule.path}
            icon={entryModule.icon}
            text={entryModule.text}
            active={isActiveModule}
            module={entryModule.module}
            onClick={() => {
              menuSetActiveAppLocation(appModuleLocation)
              doChangeModule(appModuleLocation, currentPage)
            }}
            scopes={scopes}
            isImpersonating={isImpersonating}
          />
        )
      }

    if (menuEntries.length === 0) {
      return null
    } else {
      return (
        <RevoMenuItemGroup key={index} onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseLeave()}>
          <>
            <div
              className={cx("revoMainMenu__menuItemModule", "revoMainMenu__menuItemModule--active")}
              onMouseEnter={props.onMouseEnter}
            >
              <img
                src="/assets/address.svg"
                alt="Address"
                height={"50px"}
                width={"60px"}
                style={{
                  padding: "15px 16px 0 12px",
                  backgroundColor: getThemeColorVar("primary", "dark"),
                  alignSelf: "start",
                  border: "none",
                }}
              />
              <div className={"revoMenuItem__text"} style={{ border: "0px" }}>
                <div
                  style={{
                    padding: "0 0 0 20px",
                  }}
                ></div>
              </div>
            </div>

            <div
              className={cx("revoMainMenu__menuItemModule", "revoMainMenu__menuItemModule--active")}
              onMouseEnter={props.onMouseEnter}
            >
              <div className={menuEmptyElementOverlay} />
              <div className={cx("revoMenuItem__text", "menuAddress")}>
                <div className={addressMenuLine}>
                  <span>
                    {props.address &&
                      props.address.route &&
                      `${props.address.route} ${props.address.streetNumber ? props.address.streetNumber : ""},`}
                  </span>
                  <span>{props.address && `${props.address.postalCode} ${props.address.locality}`}</span>
                </div>
              </div>
            </div>
          </>

          {menuEntries.map((entry, entryIndex) => {
            const currentMenuSectionEntry: undefined | SubmenuEntry<T> =
              currentLocation &&
              menuEntries.find(
                (me) =>
                  me.app === currentLocation.app &&
                  me.entry === currentLocation.section &&
                  (!me.module || me.module === currentLocation.module)
              )

            const onSectionClick = () => {
              const appModuleLocation = entry.module
                ? {
                    app: entry.app,
                    section: entry.entry,
                    module: entry.module,
                  }
                : { app: entry.app, section: entry.entry }

              menuSetActiveAppLocation(appModuleLocation)

              doChangeModule(appModuleLocation, currentPage)
            }

            const isActiveSection =
              entry === currentMenuSectionEntry && (!modulePage || !!entry.module) && !activeModule

            const renderEntryModule = renderModule(entry)

            const scopeRequirement = (entryModule: ModulesForSubmenuWithScopeRequirement<T, Apps[T]>) =>
              scopeRequirementIsFulfilled(entryModule.scopeRequirement, scopes)

            const featureFlagRequirement = (entryModule: ModulesForSubmenuWithScopeRequirement<T, Apps[T]>) => {
              const getTrimmedLowercaseModule = (module: string) => module.trim().toLowerCase()
              if (Object.keys(UI_FEATURES_SET).includes(getTrimmedLowercaseModule(entryModule.module))) {
                return featureEnabled(getTrimmedLowercaseModule(entryModule.module) as UI_FEATURE)
              } else return true
            }
            const showMenuGroupHeader = entry.app !== "locationAnalytics"
            return (
              <React.Fragment key={"menu-item-group-" + index + "entry-" + entryIndex}>
                {showMenuGroupHeader && (
                  <RevoMainMenuItem
                    key={"entry-" + index + "-" + entry.entry}
                    service={entry.service}
                    path={entry.path}
                    icon={entry.icon}
                    text={entry.text}
                    active={isActiveSection}
                    onClick={onSectionClick}
                    scopes={scopes}
                    isImpersonating={isImpersonating}
                  />
                )}
                {entry.modules &&
                  entry.modules.filter(scopeRequirement).filter(featureFlagRequirement).map(renderEntryModule)}
              </React.Fragment>
            )
          })}
        </RevoMenuItemGroup>
      )
    }
  }

  const renderAppMenu = (config: SubmenuEntry<App>[][], appLocation: AppLocationForApp<App, any>) => {
    return config.map((submenuEntry, index) =>
      renderGroup(
        submenuEntry,
        index,
        () => {
          props.onMouseEnter()
        },
        () => undefined,
        appLocation.module,
        appLocation.modulePage,
        props.scopes,
        props.location
      )
    )
  }

  const mainMenu = () => {
    const { location } = props
    if (!location) return
    const appConfig = appLocationConfig[location.app as App]
    return renderAppMenu(appConfig, location)
  }

  const classes = {
    revoMainMenu__wrapper: true,
    impersonate: isImpersonating,
    "revoMainMenu__wrapper--expanded": props.open,
    "revoMainMenu__wrapper--overlay": props.overlay,
    revoMainMenuRedesign: true,
  }

  const renderUserAppInDropDown = filterAuthorizedMenuItems(props.scopes)(userApps)

  // 100vh Chrome does not consider if the applications navbar is open or not, so
  // if it is open 100vh is actually higher than the available viewport which leads to elements being
  // cropped off. So on mobile when we render the menu we set the height of the menu to the innerHeight
  // of the window.

  const mobileFix = {
    "--windowInnerHeight": `${window.innerHeight}px`,
  } as React.CSSProperties

  const topDropdownApps = new Set(Object.keys(renderUserAppInDropDown))
  if (props.location?.app) {
    topDropdownApps.add(props.location.app)
  }

  const menuBackground = () => {
    if (isImpersonating) return "red"
    return "white"
  }

  return (
    <div className={cx(classes)} onMouseLeave={props.onMouseLeave} style={{ width: "80px" }}>
      <div style={mobileFix} className={cx("revoMainMenu__menu", redesignMenu)}>
        <div className="revoMainMenu__menuWrap" id={"redesignedSidebarMenu"} style={{ width: "60px" }}>
          <div className="revoMainMenu__menuToggleWrapper">
            <div className="revoMainMenu__menuItem revoMainMenu__wrapAppsList">
              <div
                className={cx({
                  impersonate: isImpersonating,
                })}
              />
            </div>
          </div>
          <div className="revoMainMenu__itemsWrapper">{mainMenu()}</div>
        </div>
      </div>
      <div className={cx("revoMainMenu__backgroundWrapper", backgroundWrapperRedesign)}>
        <div
          style={{
            backgroundColor: menuBackground(),
            pointerEvents: "auto",
            height: "100%",
          }}
        />
        <div style={{ backgroundColor: "white" }} />
      </div>
    </div>
  )
}
