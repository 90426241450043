import { RatingManagerTranslations } from "./translations"
import { RATING_MANAGER_DE } from "./de"
import { RATING_MANAGER_EN } from "./en"
import { language } from "../../shared/language"

export function translations(): RatingManagerTranslations {
  switch (language()) {
    case "de":
      return RATING_MANAGER_DE
    default:
      return RATING_MANAGER_EN
  }
}
