import { BorderBottom } from "../../shared/components/ui/border-bottom"
import { COLORS } from "../../shared/components/ui/colors"
import { Grid } from "../../shared/components/ui/grid"
import { Flex } from "../../shared/components/ui/flex"
import { FlexItem } from "../../shared/components/ui/flex-item"
import { Collapse } from "@blueprintjs/core"
import { css } from "emotion"
import * as React from "react"
import { Selection } from "../../shared/models/selection"
import { PopupMenu } from "../../shared/components/ui/popup-menu"
import { translations } from "../i18n"
import { deleteSelection, updateSelectionLocked } from "../location-selector-slice"
import { AlertBox } from "../../shared/components/alertbox"
import Text from "../../shared/components/text"
import LoadingSpinner from "../../shared/components/loadingspinner"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"
import { getThemeColor, getThemeColorVar } from "../../shared/helper/color"
import Icon from "../../shared/components/icon"

type Props = {
  title: string
  selections: Selection[] | null
  selectionsLoading: boolean
  collapsed: boolean
  setCollapsed: (b: boolean) => void
  setSelectedSelection: (s: Selection) => void
  selectedSelectionId: string | null
  toEditSelection: (s: Selection) => void
  toRenameSelection: (selection: Selection) => void
  toDuplicateSelection: (selection: Selection) => void
  userId: string | undefined
  isCompanyAdmin: boolean
}

const styles = {
  listItem: css({
    borderBottom: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
    cursor: "pointer",
  }),
}

const sortAlphabetically = (a: Selection, b: Selection) => a.name.localeCompare(b.name)

export const Selections = (props: Props) => {
  const t = React.useMemo(() => translations(), [])
  const [toDelete, setToDelete] = React.useState<Selection | null>(null)
  const [toLock, setToLock] = React.useState<Selection | null>(null)

  const backgroundColor = (selection: Selection) =>
    props.selectedSelectionId === selection.id
      ? getThemeColorVar("secondary2", "light")
      : getThemeColorVar("background", "default")

  const renderSelection = (selection: Selection, idx: number) => {
    const userCanUnlock = (props.userId && selection.lockedBy === props.userId) || props.isCompanyAdmin
    const isLocked = !!selection.lockedBy

    return (
      <div
        onClick={() => props.setSelectedSelection(selection)}
        className={styles.listItem}
        key={`${selection.name}-${idx}`}
      >
        <Flex
          backgroundColor={backgroundColor(selection)}
          flexDirection="row"
          gap={4}
          key={idx}
          padding={[8, 0, 8, 16]}
        >
          <FlexContainer md-align="center">
            <Text size="base">{selection.name}</Text>&nbsp;{selection.lockedBy && <Icon name="locked" fontSize={14} />}
          </FlexContainer>
          <FlexContainer md-justify="end">
            <PopupMenu
              actions={[
                {
                  title: t.selectionListMenu.edit,
                  onClick: () => props.toEditSelection(selection),
                  disabled: isLocked,
                },
                {
                  title: t.selectionListMenu.duplicate,
                  onClick: () => props.toDuplicateSelection(selection),
                },
                {
                  title: t.selectionListMenu.rename,
                  onClick: () => props.toRenameSelection(selection),
                  disabled: isLocked,
                },
                "divider",
                {
                  title: isLocked ? t.unlock : t.lock,
                  onClick: () => setToLock(selection),
                  disabled: selection.lockedBy !== undefined && !userCanUnlock,
                },
                "divider",
                {
                  title: t.selectionListMenu.delete,
                  onClick: () => setToDelete(selection),
                  disabled: isLocked,
                },
              ]}
            />
          </FlexContainer>
        </Flex>
      </div>
    )
  }

  const renderToLockConfirmation = (selection: Selection) => (
    <AlertBox
      header={selection.lockedBy ? t.unlockSelection : t.lockSelection}
      onClose={() => setToLock(null)}
      actions={[
        {
          label: selection.lockedBy ? t.unlock : t.lock,
          icon: selection.lockedBy ? "lock_unlock" : "lock",
          action: () => {
            void updateSelectionLocked(selection.id, !selection.lockedBy).then(() => setToLock(null))
          },
        },
        { label: t.cancel, action: () => setToLock(null) },
      ]}
    >
      <Text>{selection.lockedBy ? t.unlockConfirmation : t.lockConfirmation}</Text>
    </AlertBox>
  )

  const renderToDeleteConfirmation = () => (
    <AlertBox
      header={`${t.selectionListMenu.delete} ${t.selection}`}
      onClose={() => setToDelete(null)}
      actions={[
        {
          label: t.selectionListMenu.delete,
          icon: "delete",
          action: () => {
            toDelete && void deleteSelection(toDelete.id).then(() => setToDelete(null))
          },
        },
        { label: t.cancel, action: () => setToDelete(null) },
      ]}
    >
      <Text>{t.selectionRuleEdit.deleteSelectionConfirm(toDelete?.name || "")}</Text>
    </AlertBox>
  )

  return (
    <Grid columns={1} rowSpec={props.collapsed ? "min-content" : "min-content 1fr"}>
      {toDelete && renderToDeleteConfirmation()}
      {toLock && renderToLockConfirmation(toLock)}
      <BorderBottom backgroundColor={COLORS.background.light} padding={16}>
        <Flex flexDirection="row">
          <FlexContainer canGrow>
            <Text fontWeight="bold">{props.title}</Text>
          </FlexContainer>
          <FlexItem>
            <div style={{ cursor: "pointer" }} onClick={() => props.setCollapsed(!props.collapsed)}>
              <Icon name={props.collapsed ? "dropdown_up" : "dropdown_down"} />
            </div>
          </FlexItem>
        </Flex>
      </BorderBottom>

      <Collapse isOpen={!props.collapsed}>
        <Flex flexDirection="column">
          {props.selectionsLoading && (
            <div>
              <LoadingSpinner color="primary" />
            </div>
          )}

          {!props.selectionsLoading &&
            props.selections &&
            props.selections.sort(sortAlphabetically).map(renderSelection)}
        </Flex>
      </Collapse>
    </Grid>
  )
}
