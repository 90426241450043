import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  EmptyTextSearchIds,
  IsolineVehicleType,
  POIExplorerInput,
  TextSearchIDsToTitle,
} from "../../shared/models/poi-explorer"
import { UserDefinedPOIGroup } from "../components/POI-explorer/poi-explorer"
import {
  ASSESSMENT_ENTRY_CHANGED_MODULE,
  AssessmentEntryChangedPayload,
} from "../actions/assessment-module-action-creators"
import Axios, { AxiosError, AxiosResponse } from "axios"
import { lanaApiUrl } from "../../app_config"
import { store } from "../../relas/store"
import { IsochroneType } from "../components/isochrone-type"
import { PlaceResultEncodable } from "../../shared/models/place-result-encodable"
import { GenericError, toGenericError } from "../../shared/helper/axios"

export interface POIExplorerAppState {
  userDefinedPOIGroups: UserDefinedPOIGroup[] | null
  userDefinedPOIGroupsError: GenericError | null
  poiGroupIdToRedirect: string

  pois: { [id: string]: PlaceResultEncodable[] } | null
  poiExplorerInput: POIExplorerInput
}

export const initialState: POIExplorerAppState = {
  userDefinedPOIGroups: null,
  userDefinedPOIGroupsError: null,
  poiGroupIdToRedirect: "",
  pois: null,
  poiExplorerInput: {
    categories: [],
    group: "manual-selection",
    isochronePolygon: undefined,
    isochroneSettings: { mode: "none" as IsolineVehicleType, time: 15 },
    onlyPrimaryCategories: false,
    onlyWithingIsochrone: false,
    poiTextSearch: EmptyTextSearchIds(),
  },
}

const poiExplorerSlice = createSlice({
  name: "poiExplorer",
  initialState,
  reducers: {
    loadUserDefinedPOIGroupsDone(
      state,
      action: PayloadAction<{
        categories: UserDefinedPOIGroup[] | null
        poiGroupIdToRedirect?: string
      }>
    ) {
      state.userDefinedPOIGroups = action.payload.categories
      if (action.payload.poiGroupIdToRedirect) {
        state.poiGroupIdToRedirect = action.payload.poiGroupIdToRedirect
      }
      state.userDefinedPOIGroupsError = null
    },
    loadUserDefinedPOIGroupsError(state, action: PayloadAction<GenericError>) {
      state.userDefinedPOIGroups = []
      state.userDefinedPOIGroupsError = action.payload
    },
    addPOIsDone(state, action: PayloadAction<{ category: string; pois: PlaceResultEncodable[] }>) {
      if (state.pois) {
        state.pois[action.payload.category] = action.payload.pois
      } else {
        state.pois = { [action.payload.category]: action.payload.pois }
      }
    },
    updatePOIGroupDone(state, action: PayloadAction<{ group: string; categories: string[] }>) {
      state.poiExplorerInput.categories = action.payload.categories
      state.poiExplorerInput.group = action.payload.group
    },
    updatePOICategoriesDone(state, action: PayloadAction<string[]>) {
      state.poiExplorerInput.categories = action.payload
    },
    updateShowOnlyPrimaryCategoriesDone(state, action: PayloadAction<boolean>) {
      state.poiExplorerInput.onlyPrimaryCategories = action.payload
    },
    updateShowOnlyWithingIsochroneDone(state, action: PayloadAction<boolean>) {
      state.poiExplorerInput.onlyWithingIsochrone = action.payload
    },
    updateIsochroneSettingsDone(state, action: PayloadAction<IsochroneType>) {
      state.poiExplorerInput.isochroneSettings = action.payload
    },
    updateIochronePolygonDone(state, action: PayloadAction<google.maps.Polygon | undefined>) {
      state.poiExplorerInput.isochronePolygon = action.payload
    },
    updatePOITextSearchDone(state, action: PayloadAction<TextSearchIDsToTitle>) {
      state.poiExplorerInput.poiTextSearch = action.payload
    },
  },
  extraReducers: {
    [ASSESSMENT_ENTRY_CHANGED_MODULE]: (state, action: PayloadAction<AssessmentEntryChangedPayload>) => {
      state.pois = null
      state.poiExplorerInput = initialState.poiExplorerInput
    },
  },
})

const {
  loadUserDefinedPOIGroupsDone,
  loadUserDefinedPOIGroupsError,
  addPOIsDone,
  updatePOICategoriesDone,
  updatePOIGroupDone,
  updateShowOnlyPrimaryCategoriesDone,
  updateShowOnlyWithingIsochroneDone,
  updateIsochroneSettingsDone,
  updateIochronePolygonDone,
  updatePOITextSearchDone,
} = poiExplorerSlice.actions

export function fetchUserDefinedPOIGroups(poiGroupIdToRedirect?: string): void {
  Axios.get(`${lanaApiUrl}/api/v3/poi/categories_groups`).then(
    (success: AxiosResponse) => {
      store.dispatch(
        loadUserDefinedPOIGroupsDone({
          categories: success.data.groups,
          poiGroupIdToRedirect: poiGroupIdToRedirect,
        })
      )
    },
    (error: AxiosError) => {
      store.dispatch(loadUserDefinedPOIGroupsError(toGenericError(error)))
    }
  )
}

export function addPois(category: string, pois: PlaceResultEncodable[]): void {
  store.dispatch(addPOIsDone({ category, pois }))
}
export function updatePOIGroup(data: { group: string; categories: string[] }): void {
  store.dispatch(updatePOIGroupDone(data))
}

export function updatePOICategories(categories: string[]) {
  store.dispatch(updatePOICategoriesDone(categories))
}

export function updateShowOnlyPrimaryCategories(onlyPrimaryCategories: boolean) {
  store.dispatch(updateShowOnlyPrimaryCategoriesDone(onlyPrimaryCategories))
}

export function updateShowOnlyWithingIsochrone(onlyWithingIsochrone: boolean) {
  store.dispatch(updateShowOnlyWithingIsochroneDone(onlyWithingIsochrone))
}

export function updateIsochroneSettings(isochroneSettings: IsochroneType) {
  store.dispatch(updateIsochroneSettingsDone(isochroneSettings))
}

export function updateIsochronePolygon(isochronePolygon: google.maps.Polygon | undefined) {
  store.dispatch(updateIochronePolygonDone(isochronePolygon))
}

export function updatePoiTextSearch(poiTextSearch: TextSearchIDsToTitle) {
  store.dispatch(updatePOITextSearchDone(poiTextSearch))
}

export default poiExplorerSlice.reducer
