import * as React from "react"
import { css, cx } from "emotion"
import { bind } from "decko"
import * as layer from "ol/layer"
import * as source from "ol/source"
import { StyleFunction } from "ol/style/Style"
import * as extend from "ol/extent"
import * as style from "ol/style"
import { translations } from "../i18n"
import { SimpleOverlay } from "../../shared/components/simple-overlay"
import { Pixel } from "ol/pixel"
import { FeatureLike } from "ol/Feature"
import { fromLonLat, transformExtent } from "ol/proj"
import { Flex } from "../../shared/components/ui/flex"
import { MapPopup, PopupFact } from "../../shared/components/ui/map-popup"
import {
  getInitialMapStyle,
  MapStyle,
  MapStyleControl,
  persistMapStyle,
  setMapUrlBasedOnMapStyle,
} from "../../shared/components/map-style-control"
import { FullScreen, Zoom } from "ol/control"
import { AttributeControl } from "../../shared/components/attribute-control"
import { locationSelectorMapStyles } from "./location-selector-map-micro-view"
import { fetchMunicipalityViewport } from "../map-helpers"
import {
  AllowedModulesEnum,
  PrivateDataModuleSettings,
  PrivatePOICategoriesList,
  PrivatePOIList,
} from "../../private-data/models/private-data"
import { PrivatePoiControl } from "../../shared/components/map-private-pois-control"
import { JSXContent, PrivatePoiOverlay } from "../../shared/components/PrivatePoiOverlay"
import Layer from "ol/layer/Layer"
import Source from "ol/source/Source"
import * as ol from "ol"
import { PoiMarkerDialog } from "../../shared/components/privatedata-poi-marker-dialog"
import { areMultiplePrivatePoisListsDifferent, arePoisToShowDifferent, updateClusters } from "../../utils/utils"
import { MunicipalityAndFocus } from "../location-selector-slice"
import { OpenLayersAbstractProps, OpenLayersMap } from "../../shared/components/openlayers-map"
import { municipalityVectorTileOptions } from "../../utils/openlayers"
import Toggle from "../../shared/components/toggle"
import LoadingSpinner from "../../shared/components/loadingspinner"
import { formatNumber } from "../../shared/helper/number-format"

type MacroItems = Map<
  string,
  { color: string; title: string; data: { label: string; value: string }[]; score?: number; grade?: string }
>

interface LocationSelectorMapMacroProps extends OpenLayersAbstractProps {
  showMunicipalities: boolean
  onShowMunicipalities: (show: boolean) => void
  macroItems: MacroItems
  loading: boolean
  onSetSelectedMunicipalityId: (id: string | null) => void
  initialMapStyle?: MapStyle
  selectedMunicipalityAndFocus: MunicipalityAndFocus | null
  selectedSelectionId: string | null
  mapNeedsReset: boolean
  dispatchMapReset: () => void
  isPrivateDataAccessible: boolean
  privatePoiCategories: PrivatePOICategoriesList
  privateDataSettings: PrivateDataModuleSettings
  getPrivateDataCategories: () => void
  updateSelectedPrivateDataCategories: (categories: PrivatePOICategoriesList, module: AllowedModulesEnum) => void
  getPrivatePoisFromCategories: (categoryId: string, module?: AllowedModulesEnum) => void
  updatePrivatePoisToShow: (poiList: PrivatePOIList, module?: AllowedModulesEnum) => void
}

interface OwnState {
  selectedMapStyle: MapStyle
  showPrivatePoisPopover: boolean
  poiPopUpPosition: [number, number] | undefined
}

const controlClass = (top: string) =>
  css({
    right: ".5em",
    top: top,
  })

const GERMANY: extend.Extent = [5.87, 47.27, 15.03, 55.06]

export class LocationSelectorMapMacroView extends OpenLayersMap<LocationSelectorMapMacroProps, OwnState> {
  private t = translations()
  private overlay: SimpleOverlay | undefined
  private macroLayer: layer.VectorTile | undefined = undefined
  private privatePoiControl: PrivatePoiControl | undefined = undefined
  private clusterLayerArray: layer.Vector<any>[] = []
  private poiPopUp: PrivatePoiOverlay | undefined = undefined

  constructor(props: LocationSelectorMapMacroProps) {
    super(props)

    this.state = {
      selectedMapStyle: getInitialMapStyle(props.initialMapStyle),
      showPrivatePoisPopover: false,
      poiPopUpPosition: undefined,
    }
  }

  @bind
  onChangeShowPrivatePoisPopover(value: boolean) {
    this.setState({
      ...this.state,
      showPrivatePoisPopover: value,
    })
  }
  @bind
  onChangeSelectedMapStyle(style: MapStyle) {
    this.setState({ selectedMapStyle: style })
    setMapUrlBasedOnMapStyle(this.getMap(), style)
    persistMapStyle(style)
  }

  componentDidMount() {
    super.componentDidMount()
    this.props.getPrivateDataCategories()
    void this.resetMap()

    this.overlay = new SimpleOverlay(this.getMap(), this.getOverlayAt, this.onSelection)

    this.macroLayer = new layer.VectorTile({
      declutter: true,
      source: new source.VectorTile(municipalityVectorTileOptions()),
      style: this.macroStyle(),
    })
    this.getMap().addLayer(this.macroLayer)
    this.getMap()
      .getView()
      .setCenter(fromLonLat([9.75, 51.47]))
    this.getMap().getView().setZoom(7)

    this.getMap().on("singleclick", this.onMapClick)
    // remove default controls
    this.getMap().getControls().clear()

    this.getMap().addControl(new MapStyleControl(this.state.selectedMapStyle, this.onChangeSelectedMapStyle))
    this.getMap().addControl(new FullScreen({ className: `${controlClass("3.5em")}` }))
    this.getMap().addControl(new Zoom({ className: `${controlClass("6em")}` }))
    this.getMap().addControl(new Zoom({ className: `${controlClass("6em")}` }))
    this.getMap().addControl(new AttributeControl("12.5em"))
    this.addOrRefreshPrivatePoi()
    this.poiPopUp = new PrivatePoiOverlay(this.getMap(), this.poiPopUpContentCreator)
    updateClusters(
      this.getMap(),
      this.clusterLayerArray,
      this.setClusterLayerArray,
      this.props.privateDataSettings.multipleCategoryPOIList,
      this.props.privatePoiCategories,
      this.props.isPrivateDataAccessible
    )
    setMapUrlBasedOnMapStyle(this.getMap(), this.state.selectedMapStyle)

    document.addEventListener("keydown", this.handleKeyDownEvent)

    this.resetMap().catch(() => {})
  }

  componentDidUpdate(prevProps: LocationSelectorMapMacroProps): void {
    this.macroLayer?.changed()

    if (
      this.props.selectedMunicipalityAndFocus?.focusInMap &&
      this.props.selectedMunicipalityAndFocus.selectedMunicipalityId !==
        prevProps.selectedMunicipalityAndFocus?.selectedMunicipalityId
    ) {
      void this.resetMap()
    }

    if (prevProps.selectedSelectionId !== this.props.selectedSelectionId) {
      this.overlay?.closeCurrentOverlay(true)
      void this.resetMap()
    }

    if (this.props.mapNeedsReset) {
      void this.resetMap()
      this.props.dispatchMapReset()
    }

    this.addOrRefreshPrivatePoi()

    if (
      areMultiplePrivatePoisListsDifferent(
        prevProps.privateDataSettings.multipleCategoryPOIList,
        this.props.privateDataSettings.multipleCategoryPOIList
      )
    ) {
      updateClusters(
        this.getMap(),
        this.clusterLayerArray,
        this.setClusterLayerArray,
        this.props.privateDataSettings.multipleCategoryPOIList,
        this.props.privatePoiCategories,
        this.props.isPrivateDataAccessible
      )
    }
    if (arePoisToShowDifferent(prevProps.privateDataSettings.poisToShow, this.props.privateDataSettings.poisToShow)) {
      this.updatePoiPopUp()
    }
  }

  private addOrRefreshPrivatePoi() {
    if (!this.privatePoiControl) {
      this.privatePoiControl = new PrivatePoiControl(
        AllowedModulesEnum.LOCATION_SELECTOR,
        this.props.privatePoiCategories,
        this.props.privateDataSettings.selectedCategories,
        this.props.updateSelectedPrivateDataCategories,
        this.props.getPrivatePoisFromCategories,
        this.state.showPrivatePoisPopover,
        this.onChangeShowPrivatePoisPopover,
        this.props.isPrivateDataAccessible
      )
      this.getMap().addControl(this.privatePoiControl)
    } else {
      this.privatePoiControl?.refreshPopover(
        this.props.privatePoiCategories,
        this.props.privateDataSettings.selectedCategories,
        this.state.showPrivatePoisPopover
      )
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    document.removeEventListener("keydown", this.handleKeyDownEvent)
  }

  protected containerClass(): string {
    return locationSelectorMapStyles.mapClass
  }

  @bind
  protected onMoveEnd() {}

  render() {
    const { showMunicipalities, onShowMunicipalities } = this.props

    return (
      <div className={locationSelectorMapStyles.containerClass}>
        {super.render()}

        <div className={locationSelectorMapStyles.showListContainerClass}>
          <Flex flexDirection="row" alignItems="center" gap={16}>
            <Toggle label={this.t.showListToggle} checked={showMunicipalities} onChange={onShowMunicipalities} />
          </Flex>
        </div>

        <div
          className={cx(
            locationSelectorMapStyles.mapNotifierStyle,
            css({ maxHeight: this.props.loading ? "48px" : "0px" })
          )}
        >
          {this.props.loading && <LoadingSpinner size={32} />}
          <span style={{ padding: "8px" }}>{this.t.loadingResultData}</span>
        </div>
      </div>
    )
  }

  @bind
  private handleKeyDownEvent(e: KeyboardEvent) {
    if (e.key === "Escape") {
      this.overlay?.closeCurrentOverlay(true)
    }
  }

  @bind
  private isClickableLayers(layer: Layer<Source>): boolean {
    return layer.getClassName().includes("cluster-layer")
  }

  @bind
  private onMapClick(event: ol.MapBrowserEvent<any>) {
    const features = this.getMap().getFeaturesAtPixel(event.pixel, {
      layerFilter: (layer) => this.isClickableLayers(layer),
    })
    if (features.length > 0 && features[0].getProperties().features?.[0]?.id_.includes("category:")) {
      this.overlay?.closeCurrentOverlay(true)
      const featuresArray: ol.Feature[] = features[0].getProperties().features
      const geometry = features[0].getProperties().geometry

      const categoryId = featuresArray[0].getId()?.toString().split(":")[1] ?? ""
      const poiIdsToShow: string[] = featuresArray.map((feature) => feature.getId()?.toString().split(":")[2] ?? "")
      const poisToShow: PrivatePOIList = (
        this.props.privateDataSettings.multipleCategoryPOIList[categoryId] ?? []
      ).filter((poi) => poiIdsToShow.includes(poi.id))
      const position = geometry.getCoordinates()
      this.setState({ ...this.state, poiPopUpPosition: position })
      this.props.updatePrivatePoisToShow(poisToShow, AllowedModulesEnum.LOCATION_SELECTOR)
    } else {
      this.onPoiPopUpClose()
    }
  }

  @bind
  private getOverlayAt(pixel: Pixel) {
    return this.overlayFromFeatures(
      this.getMap().getFeaturesAtPixel(pixel, { layerFilter: (layer) => layer == this.macroLayer })
    )
  }

  @bind
  private overlayFromFeatures(features: FeatureLike[]): { id: string; content: JSX.Element } | null {
    if (features.length < 1) return null

    const id = features[0].getId()?.toString()
    const item = this.props.macroItems.get(features[0].getId()?.toString() ?? "")

    if (!item || id === undefined) return null

    const additionalItems: PopupFact[] = []

    if (typeof item.grade === "string") {
      additionalItems.push({ label: this.t.ratingGradeLabel, value: item.grade })
    }

    if (typeof item.score === "number") {
      additionalItems.push({ label: this.t.profileScoreTitle, value: formatNumber(item.score, 0) })
    }

    item.data.forEach((d) => additionalItems.push(d))

    const [mainFact, ...secondaryFacts] = additionalItems

    return {
      id,
      content: (
        <MapPopup
          header={item.title}
          mainFact={mainFact}
          secondaryFacts={secondaryFacts}
          closeCallback={() => this.overlay?.closeCurrentOverlay(true)}
        />
      ),
    }
  }

  @bind
  private onSelection(id: string | null) {
    this.props.onSetSelectedMunicipalityId(id)
    this.macroLayer?.changed()
  }

  @bind
  private macroStyle(): StyleFunction {
    return (feature) => {
      const macroItem = this.props.macroItems.get(feature.getId()?.toString() ?? "")
      if (!macroItem) return new style.Style()
      const selected =
        this.props.selectedMunicipalityAndFocus &&
        this.props.selectedMunicipalityAndFocus.selectedMunicipalityId === feature.getId()?.toString()

      return new style.Style({
        stroke: new style.Stroke({
          color: selected ? "rgba(255,0,0,0.8)" : "rgba(0,0,255,0.8)",
          width: selected ? 2.0 : 0.5,
        }),
        fill: new style.Fill({
          color: macroItem.color,
        }),
      })
    }
  }

  @bind
  private async resetMap() {
    const viewport =
      this.props.selectedMunicipalityAndFocus && this.props.selectedMunicipalityAndFocus.focusInMap
        ? await fetchMunicipalityViewport(this.props.selectedMunicipalityAndFocus.selectedMunicipalityId)
        : GERMANY

    this.getMap() && this.getMap().getView().fit(transformExtent(viewport, "EPSG:4326", "EPSG:3857"))
    this.overlay?.closeCurrentOverlay(true)
  }

  @bind private setClusterLayerArray(newClusterArrayLayer: layer.Vector<any>[]) {
    this.clusterLayerArray = [...newClusterArrayLayer]
  }

  @bind
  private updatePoiPopUp() {
    if (this.props.isPrivateDataAccessible) {
      if (this.props.privateDataSettings.poisToShow.length > 0 && this.state.poiPopUpPosition) {
        this.poiPopUp?.openOverlayAt(this.state.poiPopUpPosition, this.props.privateDataSettings.poisToShow)
      } else if (this.poiPopUp) {
        this.poiPopUp.closeCurrentOverlay()
      }
    } else if (this.poiPopUp) {
      this.poiPopUp.closeCurrentOverlay()
    }
  }

  @bind onPoiPopUpClose() {
    this.poiPopUp?.closeCurrentOverlay()
    this.props.updatePrivatePoisToShow([], AllowedModulesEnum.LOCATION_SELECTOR)
    this.setState({ ...this.state, poiPopUpPosition: undefined })
  }

  @bind
  private poiPopUpContentCreator(location: number[], poiList: PrivatePOIList): JSXContent {
    const props = {
      lat: location[1],
      lng: location[0],
      poisToShow: poiList,
      onClose: this.onPoiPopUpClose,
    }
    return {
      content: <PoiMarkerDialog {...props} entryPinLocation={undefined} />,
    }
  }
}
