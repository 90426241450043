import * as React from "react"
import { useEffect, useState } from "react"
import { useAppSelector } from "../relas/store"
import { hideMenu as doHideMenu } from "../shared/reducers/navigation-slice"
import { RevoLoggedInMenu } from "./revo-loggedin-menu"
import { LanaHeader, LanaHeaderTabs } from "../shared/components/header/lana-header"
import { AllNavigationLabels } from "../shared/actions/navigation"
import { isUnloggedPage } from "../relas/router"

export const pagesWithHiddenMenu = new Set<AllNavigationLabels>([
  "quickstart",
  "companyAdminSettings",
  "companyAdminMembers",
  "companyProductStatistics",
  "companyApiTokenList",
  "userProfile",
  "userSecurity",
  "features",
])

export const RevoMenu = () => {
  const user = useAppSelector((state) => state.user.user)
  const companyAdmin = useAppSelector((state) => state.user.scopes.companyAdmin)
  const hideMenu = useAppSelector((state) => state.navigation.hideMenu)
  const printMode = useAppSelector((state) => state.navigation.printMode)
  const currentAppPage = useAppSelector((state) => state.navigation.currentPage)
  const [selectedTab, setSelectedTab] = useState<LanaHeaderTabs>(undefined)

  const toolsPages = ["profiles", "locationSelector", "ratingManager", "privateData"]
  const isAssessmentsListPage =
    currentAppPage?.name.slice(-4) === "List" && currentAppPage?.name !== "companyApiTokenList"

  useEffect(() => {
    if (currentAppPage) {
      if (toolsPages.includes(currentAppPage?.name)) {
        setSelectedTab("tools")
      } else if (isAssessmentsListPage) {
        setSelectedTab("locationAssessment")
      } else if (currentAppPage?.name === "quickstart") {
        setSelectedTab("quickstart")
      } else {
        setSelectedTab(undefined)
      }
    }
  }, [])

  useEffect(() => {
    if (currentAppPage) {
      if (pagesWithHiddenMenu.has(currentAppPage?.name) || isAssessmentsListPage) {
        doHideMenu(true)
      } else {
        if (selectedTab !== "tools") setSelectedTab(undefined)
        doHideMenu(false)
      }
    }
  }, [currentAppPage, selectedTab])

  if (printMode) {
    document.body.classList.add("print-mode")
  } else {
    document.body.classList.remove("print-mode")
  }

  document.body.classList.toggle("logged-out-background", hideMenu)

  return (
    <>
      <div style={{ gridColumn: "1 / span 3", zIndex: 101 }}>
        {currentAppPage?.name && !isUnloggedPage(currentAppPage?.name) && (
          <LanaHeader companyAdmin={companyAdmin} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        )}
      </div>
      {hideMenu || (currentAppPage?.name && isUnloggedPage(currentAppPage?.name)) ? (
        <div></div>
      ) : (
        <RevoLoggedInMenu user={user} />
      )}
    </>
  )
}
