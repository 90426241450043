import * as React from "react"
import { bind } from "decko"
import * as ReactDOM from "react-dom"
import { css, cx } from "emotion"
import LoadingSpinner from "./loadingspinner"
import FlexContainer, { Props as FlexProps } from "./restyle-grid/flexcontainer"
import Button from "./button"
import { ComponentSize } from "../util/layoutconstants"
import { getThemeColorVar } from "../helper/color"
import Icon from "./icon"

export interface XlWidgetProps<T extends number | string> {
  value?: T
  saveInProgress: boolean
  onSave: (value?: T) => void
  required?: boolean
  viewMode?: boolean
  viewModeValue?: string
  label?: string
  disabled?: boolean
  size?: ComponentSize
}

export interface State<T extends number | string> {
  editMode: boolean
  editValue?: T
  validationError: boolean
}

export interface XlInputWrapper {
  inputElRef: HTMLElement | null
}

const xlWidgetClass = css({
  label: {
    color: getThemeColorVar("typo", "default"),
    display: "block",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "25px",
    marginBottom: "5px",
  },

  "input:focus-within": {
    zIndex: 10000,
  },
})

const xlWidgetViewClass = css({
  label: {
    color: getThemeColorVar("typo", "default"),
    display: "block",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "25px",
    whiteSpace: "pre-wrap",
  },
})

const buttonContainerClass = css({
  maxWidth: "0px",
  transition: "max-width .5s",
  marginLeft: "10px",

  "> div": {
    overflow: "hidden",
  },
})

const requiredMarkerClass = css({
  color: getThemeColorVar("negative", "default"),
  fontWeight: 600,
})

const editModeClass = css({
  maxWidth: "100%",
})

export class XlWidget<P, T extends string | number = string> extends React.Component<P & XlWidgetProps<T>, State<T>> {
  protected inputWrapper: XlInputWrapper | null
  protected renderInner(): JSX.Element {
    throw new Error("abstractMethod not implemented")
  }

  protected additionalWrapperClasses: string[]
  protected additionalButtonContainerClasses: string[]
  protected additionalEditModeClass: string
  protected additionalButtonContainerEditModeClass: string

  protected wrapperFlexProps: FlexProps = {
    direction: "row",
    flexWrap: "nowrap",
  }
  protected buttonWrapperFlexProps: FlexProps = {
    direction: "row",
    spaceBetween: "base",
  }

  constructor(props: P & XlWidgetProps<T>, private submitRequireControlModifier?: boolean) {
    super(props)
    this.state = {
      editMode: false,
      editValue: props.value,
      validationError: false,
    }
  }

  @bind
  handleKeyPress<T>(event: React.KeyboardEvent<T>) {
    switch (event.key) {
      case "Enter":
        if (this.submitRequireControlModifier && !event.ctrlKey) {
          return
        }

        if (!this.state.validationError && this.isDirty()) {
          this.save()
        }

        return
      case "Escape":
        this.discard()
        return
    }
  }

  @bind
  handleOutsideClick(event: React.MouseEvent<HTMLElement>): void {
    try {
      const domNode: Element | Text | null = ReactDOM.findDOMNode(this)
      if (!this.state.editMode || (event.type === "focus" && event.target === document)) return

      if (domNode && domNode.contains && !domNode.contains(event.target as Node)) {
        if (this.isDirty() && !this.state.validationError) {
          this.save()
        } else {
          this.discard()
        }
      }
    } catch (e) {
      // if we are brutally unmounted the above code will explode
    }
  }

  componentWillMount() {
    document.addEventListener("click", this.handleOutsideClick.bind(this))
    document.addEventListener("focus", this.handleOutsideClick.bind(this), true)
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick.bind(this))
    document.removeEventListener("focus", this.handleOutsideClick.bind(this), true)
  }

  componentWillReceiveProps(nextProps: XlWidgetProps<T>) {
    if (nextProps.value != this.props.value || (this.props.saveInProgress && !nextProps.saveInProgress)) {
      this.setState({ editValue: nextProps.value })
    }
  }

  @bind
  edit() {
    if (!this.state.editMode) {
      this.setState({ editMode: true })
    }
  }

  @bind
  discard() {
    this.inputWrapper && this.inputWrapper.inputElRef && this.inputWrapper.inputElRef.blur()
    this.setState({
      editMode: false,
      editValue: this.props.value,
      validationError: false,
    })
  }

  @bind()
  editValueUpdate(newValue: T, valid: boolean) {
    this.setState({ editValue: newValue, validationError: !valid })
  }

  renderSaveIndicator() {
    if (this.isDirty() && this.props.saveInProgress) {
      return (
        <FlexContainer direction="row" paddingX="base" canGrow={false} md-align="center">
          <LoadingSpinner size={32} />
        </FlexContainer>
      )
    } else {
      return null
    }
  }

  @bind
  save() {
    if (!this.state.validationError) {
      this.props.onSave && this.props.onSave(this.state.editValue)
      this.inputWrapper && this.inputWrapper.inputElRef && this.inputWrapper.inputElRef.blur()
      this.setState({ editMode: false })
    }
  }

  @bind
  currentValue(): any {
    return this.state.editValue
  }

  @bind
  isDirty(): boolean {
    return this.state.editValue != this.props.value
  }

  render() {
    const wrapperClasses: string[] = [xlWidgetClass].concat(this.additionalWrapperClasses)
    const buttonContainerClasses = [buttonContainerClass].concat(this.additionalButtonContainerClasses)

    if (this.state.editMode) {
      this.additionalEditModeClass && wrapperClasses.push(this.additionalEditModeClass)

      buttonContainerClasses.push(editModeClass)

      this.additionalButtonContainerEditModeClass &&
        buttonContainerClasses.push(this.additionalButtonContainerEditModeClass)
    }

    if (this.props.viewMode) {
      return (
        <div className={cx(xlWidgetViewClass)}>
          {this.props.label && <label>{this.props.label}</label>}
          {this.props.viewModeValue ? this.props.viewModeValue : this.props.value}
        </div>
      )
    }

    return (
      <div className={cx(wrapperClasses)}>
        {this.props.label && (
          <label>
            {this.props.label}
            {this.props.required && <span className={cx(requiredMarkerClass)}> *</span>}
          </label>
        )}
        <FlexContainer {...this.wrapperFlexProps}>
          <FlexContainer canShrink={true} spaceBetween="base" md-align="center">
            {this.isDirty() && !this.state.editMode && !this.props.saveInProgress && (
              <Icon color="negative" colorType="default" name="error" />
            )}
            {this.renderInner()}
          </FlexContainer>

          <div className={cx(buttonContainerClasses)}>
            <FlexContainer {...this.buttonWrapperFlexProps}>
              <Button
                type="secondary"
                icon="check"
                tabIndex={-1}
                size={this.props.size === "small" ? "small" : "normal"}
                disabled={!this.state.editMode || !this.isDirty() || this.state.validationError}
                title="&#9166;"
                onClick={(e) => {
                  this.save()
                  e.stopPropagation()
                  e.preventDefault()
                  e.currentTarget.blur()
                }}
              />
              <Button
                type="secondary"
                icon="clear"
                tabIndex={-1}
                size={this.props.size === "small" ? "small" : "normal"}
                disabled={!this.state.editMode}
                title="ESC"
                onClick={(e) => {
                  this.discard()
                  e.stopPropagation()
                  e.preventDefault()
                  e.currentTarget.blur()
                }}
              />
            </FlexContainer>
          </div>
          {this.renderSaveIndicator()}
        </FlexContainer>
      </div>
    )
  }
}
