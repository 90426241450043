import * as React from "react"
import { useMemo } from "react"
import { trackUsageEvent } from "../../utils/usage-tracking"
import { css } from "emotion"
import { translations } from "../i18n"
import DropdownButton from "./dropdown-button"
import Icon from "./icon"
import { featureEnabled, HELP_DEVELOPMENT } from "../../utils/features-toggle"

type Props = {
  tooltip: string
  callLocation?: string
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  addDocumentationLink?: boolean
  documentationURL?: string
  buttonOnly?: boolean
}

const containerStyles = css({
  "div div button i:first-child": {
    fontSize: "24px",
    marginRight: "0px",
    display: "initial",
  },
  "div div button i": {
    display: "none",
  },
  "div div button span": {
    display: "block",
  },
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  alignItems: "center",
})
const buttonStyle = css({
  span: {
    display: "block",
  },
})
export const DocumentationAndSupport: React.FC<Props> = (props) => {
  const t = useMemo(translations, [translations])

  const isTools =
    props.callLocation === "Profile Manager" ||
    props.callLocation === "Location Selector" ||
    props.callLocation === "Rating Manager" ||
    props.callLocation === "Private Data"

  const documentationAction = {
    title: t.documentationAndSupport.openDocumentation,
    href: props.documentationURL ?? "",
    target: "_blank",
    onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      props.onClick(e)
      trackUsageEvent("OPEN_DOCUMENTATION", null, props.callLocation ?? "")
    },
  }
  const supportAction = {
    title: t.documentationAndSupport.contactSupport,
    href: "mailto:support@21re.de",
  }

  const getActions = () => {
    if ((props.addDocumentationLink && props.documentationURL) || featureEnabled(HELP_DEVELOPMENT)) {
      return [documentationAction, supportAction]
    } else {
      return [supportAction]
    }
  }
  return (
    <>
      <div className={containerStyles}>
        {props.buttonOnly ? (
          <div className={buttonStyle} onClick={(e: React.MouseEvent<HTMLElement>) => props.onClick(e)}>
            <Icon name="help" size={"regular"} color={"primary"} colorType={"default"} />
          </div>
        ) : (
          <DropdownButton type={"tertiary"} actions={getActions()}>
            <Icon name="help" size={"regular"} />
          </DropdownButton>
        )}
        {!isTools && <div style={{ fontSize: "14px" }}>{t.helpTitle}</div>}
      </div>
    </>
  )
}
