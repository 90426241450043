import { scopesFromCookie } from "../menu/util/scorefromcookies"
import { CookieScopeRequirement } from "../menu/models/scoperequirements"
import { Scopes } from "../menu/models/scopes"

export type LanaScopesType = {
  companyAdmin: boolean
  assessment: boolean
  profile: boolean
  explorer: boolean
  comparables: boolean
  marketData: boolean
  poiExplorer: boolean
  specialMaps: boolean
  privateData: boolean
  ratingManager: boolean
  locationSelector: boolean
  aiTextGeneration: boolean
  fundamentalData: boolean
}

export const emptyLanaScopes: LanaScopesType = {
  companyAdmin: false,
  assessment: false,
  profile: false,
  explorer: false,
  comparables: false,
  marketData: false,
  poiExplorer: false,
  specialMaps: false,
  privateData: false,
  ratingManager: false,
  locationSelector: false,
  aiTextGeneration: false,
  fundamentalData: false,
}

export function getLanaScopesFromCookie(): LanaScopesType {
  return getLanaScopes(scopesFromCookie())
}

export function getRelasAuthScopes(): Array<Scopes> {
  const allScopes = scopesFromCookie()

  const lanaScopes = allScopes.find((v) => v.service === "lana")

  if (!lanaScopes) return []

  return lanaScopes.scopes
}

export function getLanaScopes(
  cookieScopeRequirements: (CookieScopeRequirement | { service: "lana"; scopes: Scopes[] })[]
): LanaScopesType {
  const lanaScopesFromCookie = cookieScopeRequirements.find((v) => v.service === "lana")
  const authScopes = lanaScopesFromCookie ? lanaScopesFromCookie.scopes : []
  const companyAdminScope = cookieScopeRequirements.find((v) => v.service === "companyadmin")?.scopes ?? []

  return {
    companyAdmin: companyAdminScope.includes("CA") || authScopes.includes("CA"),
    assessment: authScopes.includes("A"),
    profile: authScopes.includes("P"),
    explorer: authScopes.includes("E"),
    comparables: authScopes.includes("C"),
    marketData: authScopes.includes("MD"),
    poiExplorer: authScopes.includes("POI"),
    specialMaps: authScopes.includes("SM"),
    privateData: authScopes.includes("PD"),
    locationSelector: authScopes.includes("LS"),
    ratingManager: authScopes.includes("RM"),
    aiTextGeneration: authScopes.includes("AITEXTGEN"),
    fundamentalData: authScopes.includes("FD"),
  }
}
