import { Component, default as React } from "react"
import { bind } from "decko"
import FlexContainer from "./restyle-grid/flexcontainer"
import { SortDirection } from "../models/sorting"
import Icon from "./icon"

export type HeaderSorting<T> = [SortDirection, T]

export interface Props<T> {
  sorting: HeaderSorting<T>
  label: string
  sorter: (sorting: HeaderSorting<T>) => void
  sortingActive: (sortKey: T) => boolean
}

export interface State<T> {
  sorting: HeaderSorting<T>
}

export class SortableTableHeader<T> extends Component<Props<T>, State<T>> {
  constructor(props: Props<T>) {
    super(props)

    this.state = {
      sorting: props.sorting,
    }
  }

  @bind
  handleTableHeaderClick() {
    const sortDirection: SortDirection =
      this.state.sorting[0] === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC

    const sorting: HeaderSorting<T> = [sortDirection, this.state.sorting[1]]
    this.setState({
      sorting: sorting,
    })

    this.props.sorter(sorting)
  }

  @bind
  renderSortDirection() {
    if (this.props.sortingActive(this.props.sorting[1])) {
      return (
        <FlexContainer md-align="center">
          &nbsp;
          {this.state.sorting[0] === SortDirection.DESC ? (
            <Icon size="small" name="arrow_downward" />
          ) : (
            <Icon size="small" name="arrow_upward" />
          )}
        </FlexContainer>
      )
    }

    return null
  }

  render() {
    return (
      <th style={{ cursor: "pointer" }} onClick={this.handleTableHeaderClick}>
        <FlexContainer direction="row">
          {this.props.label} {this.renderSortDirection()}
        </FlexContainer>
      </th>
    )
  }
}
