import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { store } from "../../relas/store"
import Axios, { AxiosResponse } from "axios"
import { lanaApiUrl } from "../../app_config"
import { AssessmentEntryFull } from "../models/assessment"
import { Coordinate } from "ol/coordinate"
import { fromLonLat, transformExtent } from "ol/proj"
import { Extent } from "ol/extent"
import { GeoJSON } from "ol/format"
import { IsochroneType } from "../components/isochrone-type"

export type DataLevel = "zip" | "municipality" | "country"

export type ViewportSettings = {
  [key in DataLevel]: {
    zoomLevel: number
    viewportCentre: Coordinate | undefined
    movedByUser: boolean
    pinnedTileExtent: any | undefined
  }
}

export type ByAssessmentEntryId<T> = {
  [assessmentEntryId in string]: T
}

export type WithDataLevel<T> = {
  [dataLevel in DataLevel]: T
}

export type FundamentalDataQuery = WithDataLevel<{
  selectedTiles: string[]
  selectedDataNames: string[]
}>

export const makeFundamentalDataQuery = (fundamentalDataForEntry: FundamentalDataForEntry): FundamentalDataQuery => ({
  zip: {
    selectedTiles: fundamentalDataForEntry.selectedTileIds.zip,
    selectedDataNames: fundamentalDataForEntry.selectedData.zip,
  },
  municipality: {
    selectedTiles: fundamentalDataForEntry.selectedTileIds.municipality,
    selectedDataNames: fundamentalDataForEntry.selectedData.municipality,
  },
  country: {
    selectedTiles: fundamentalDataForEntry.selectedTileIds.country,
    selectedDataNames: fundamentalDataForEntry.selectedData.country,
  },
})

type DataFetchStatus = "pre-fetch" | "need-initial" | "need-fetching" | "fetched"

export type FundamentalDataForEntry = {
  dataLevel: DataLevel
  viewportSettings: ViewportSettings
  selectedTileIds: WithDataLevel<string[]>
  selectedData: WithDataLevel<string[]>
  collapsedCategories: WithDataLevel<string[]>
  collapsedTopics: WithDataLevel<string[]>
  dataViewScores: WithDataLevel<{ [key in string]: number }>
  dataFetchStatus: DataFetchStatus
  isochroneSettings: IsochroneType
}

export interface FundamentalDataState {
  dataForEntries: ByAssessmentEntryId<FundamentalDataForEntry>
}

const centreBerlinFundamentalData = {
  lng: 13.404954,
  lat: 52.520008,
}

export const centreBerlinCoordinate: Coordinate = fromLonLat([
  centreBerlinFundamentalData.lng,
  centreBerlinFundamentalData.lat,
])

const GERMANY: Extent = [5.87, 47.27, 15.03, 55.06]

export const defaultViewportSettings: () => ViewportSettings = () => ({
  zip: {
    zoomLevel: 13,
    viewportCentre: undefined,
    movedByUser: false,
    pinnedTileExtent: undefined,
  },
  municipality: {
    zoomLevel: 11,
    viewportCentre: undefined,
    movedByUser: false,
    pinnedTileExtent: undefined,
  },
  country: {
    zoomLevel: 6,
    viewportCentre: undefined,
    movedByUser: false,
    pinnedTileExtent: transformExtent(GERMANY, "EPSG:4326", "EPSG:3857"),
  },
})

type ShapeResponse = {
  partitionName: string
  refId: string
  shape: any
}

const searchShape = (shape: "macroShape" | "postcode", lat: number, lng: number): Promise<ShapeResponse> =>
  Axios.get(`${lanaApiUrl}/api/map/search/${shape}?lat=${lat}&lng=${lng}`, {}).then((response) => response.data)

const municipalityShapePromise = (lat: number, lng: number): Promise<ShapeResponse> =>
  searchShape("macroShape", lat, lng)

const getExtentFromGeoJson = (geoJson: any): Extent => {
  return transformExtent(new GeoJSON().readGeometry(geoJson).getExtent(), "EPSG:4326", "EPSG:3857")
}

const postcodeShapePromise = (lat: number, lng: number): Promise<ShapeResponse> => searchShape("postcode", lat, lng)

export async function initialiseFundamentalDataState(
  assessmentEntry: AssessmentEntryFull,
  assessmentEntryHasBeenInitialised: boolean
): Promise<void> {
  try {
    if (!assessmentEntryHasBeenInitialised) {
      let postcodeShapeResponse: ShapeResponse | undefined = undefined
      let postcodeTileId: string | undefined = undefined

      if (assessmentEntry.postcodeId) {
        postcodeTileId = assessmentEntry.postcodeId.toString()
      }

      if (assessmentEntry.address?.location) {
        postcodeShapeResponse = await postcodeShapePromise(
          assessmentEntry.address.location.lat,
          assessmentEntry.address.location.lng
        )
        if (!postcodeTileId) {
          postcodeTileId = postcodeShapeResponse.refId
        }
      }

      if (postcodeTileId) {
        updateSelectedTileIds(assessmentEntry.id, "zip", postcodeTileId, false)
      }

      let municipalityShapeResponse: ShapeResponse | undefined = undefined
      let municipalityTileId: string | undefined = undefined

      if (assessmentEntry.agsId) {
        municipalityTileId = assessmentEntry.agsId.toString()
      }

      if (assessmentEntry.address.location) {
        municipalityShapeResponse = await municipalityShapePromise(
          assessmentEntry.address.location.lat,
          assessmentEntry.address.location.lng
        )
        if (!municipalityTileId) {
          municipalityTileId = municipalityShapeResponse.refId
        }
      }

      if (municipalityTileId) {
        updateSelectedTileIds(assessmentEntry.id, "municipality", municipalityTileId, false)
      }

      if (assessmentEntry.address.location) {
        const viewportSettings = defaultViewportSettings()

        updateViewportSettings(assessmentEntry.id, {
          ...viewportSettings,
          zip: {
            ...viewportSettings.zip,
            viewportCentre: fromLonLat([assessmentEntry.address.location.lng, assessmentEntry.address.location.lat]),
            pinnedTileExtent: postcodeShapeResponse?.shape && getExtentFromGeoJson(postcodeShapeResponse.shape),
          },
          municipality: {
            ...viewportSettings.municipality,
            viewportCentre: fromLonLat([assessmentEntry.address.location.lng, assessmentEntry.address.location.lat]),
            pinnedTileExtent: municipalityShapeResponse?.shape && getExtentFromGeoJson(municipalityShapeResponse.shape),
          },
        })
      }

      updateFetchedStatus(assessmentEntry.id, "need-initial")
    }
  } catch (_) {}
}

export const initialState: FundamentalDataState = {
  dataForEntries: {},
}

function makeDefaultWithDataLevel<T>(t: T): WithDataLevel<T> {
  return {
    zip: t,
    municipality: t,
    country: t,
  }
}
const defaultSelectedDataList = () => ["lc_total", "ew", "ew_prog_30", "alo_qu"]

const makeFundamentalDataForEntry = (): FundamentalDataForEntry => ({
  dataLevel: "zip",
  viewportSettings: defaultViewportSettings(),
  selectedTileIds: makeDefaultWithDataLevel([]),
  collapsedCategories: makeDefaultWithDataLevel([]),
  collapsedTopics: makeDefaultWithDataLevel([]),
  selectedData: {
    zip: defaultSelectedDataList(),
    municipality: defaultSelectedDataList(),
    country: defaultSelectedDataList(),
  },
  dataViewScores: makeDefaultWithDataLevel({}),
  dataFetchStatus: "pre-fetch",
  isochroneSettings: { mode: "none", time: 15 },
})

type WithAssessmentEntryId<T> = {
  assessmentEntryId: string
  data: T
}

const fundamentalDataSlice = createSlice({
  name: "fundamentalData",
  initialState,
  reducers: {
    setDataLevel: (state, action: PayloadAction<WithAssessmentEntryId<DataLevel>>) => {
      if (state.dataForEntries[action.payload.assessmentEntryId] === undefined) {
        state.dataForEntries = {
          ...state.dataForEntries,
          [action.payload.assessmentEntryId]: {
            ...makeFundamentalDataForEntry(),
            dataLevel: action.payload.data,
          },
        }
      } else {
        state.dataForEntries[action.payload.assessmentEntryId].dataLevel = action.payload.data
      }
    },
    setViewportSettings: (state, action: PayloadAction<WithAssessmentEntryId<ViewportSettings>>) => {
      if (state.dataForEntries[action.payload.assessmentEntryId] === undefined) {
        state.dataForEntries = {
          ...state.dataForEntries,
          [action.payload.assessmentEntryId]: {
            ...makeFundamentalDataForEntry(),
            viewportSettings: action.payload.data,
          },
        }
      } else {
        state.dataForEntries[action.payload.assessmentEntryId].viewportSettings = action.payload.data
      }
    },
    setSelectedTileIds: (
      state,
      action: PayloadAction<WithAssessmentEntryId<{ dataLevel: DataLevel; selectedTileId: string }>>
    ) => {
      if (state.dataForEntries[action.payload.assessmentEntryId] === undefined) {
        state.dataForEntries = {
          ...state.dataForEntries,
          [action.payload.assessmentEntryId]: {
            ...makeFundamentalDataForEntry(),
            selectedTileIds: {
              ...makeDefaultWithDataLevel([]),
              [action.payload.data.dataLevel]: [action.payload.data.selectedTileId],
            },
          },
        }
      } else {
        const currentlySelectedTileIds = [
          ...state.dataForEntries[action.payload.assessmentEntryId].selectedTileIds[action.payload.data.dataLevel],
        ]

        const newSelectedTileIds = currentlySelectedTileIds.includes(action.payload.data.selectedTileId)
          ? currentlySelectedTileIds.filter((id) => id !== action.payload.data.selectedTileId)
          : [...currentlySelectedTileIds, action.payload.data.selectedTileId]

        state.dataForEntries[action.payload.assessmentEntryId].selectedTileIds[action.payload.data.dataLevel] =
          newSelectedTileIds
      }
    },
    setCollapsedCategories: (
      state,
      action: PayloadAction<WithAssessmentEntryId<{ dataLevel: DataLevel; categories: string[] }>>
    ) => {
      if (state.dataForEntries[action.payload.assessmentEntryId] === undefined) {
        state.dataForEntries = {
          ...state.dataForEntries,
          [action.payload.assessmentEntryId]: {
            ...makeFundamentalDataForEntry(),
            collapsedCategories: {
              ...state.dataForEntries[action.payload.assessmentEntryId].collapsedCategories,
              [action.payload.data.dataLevel]: action.payload.data.categories,
            },
          },
        }
      } else {
        state.dataForEntries[action.payload.assessmentEntryId].collapsedCategories[action.payload.data.dataLevel] =
          action.payload.data.categories
      }
    },
    setCollapsedTopics: (
      state,
      action: PayloadAction<WithAssessmentEntryId<{ dataLevel: DataLevel; topics: string[] }>>
    ) => {
      if (state.dataForEntries[action.payload.assessmentEntryId] === undefined) {
        state.dataForEntries = {
          ...state.dataForEntries,
          [action.payload.assessmentEntryId]: {
            ...makeFundamentalDataForEntry(),
            collapsedTopics: {
              ...state.dataForEntries[action.payload.assessmentEntryId].collapsedTopics,
              [action.payload.data.dataLevel]: action.payload.data.topics,
            },
          },
        }
      } else {
        state.dataForEntries[action.payload.assessmentEntryId].collapsedTopics[action.payload.data.dataLevel] =
          action.payload.data.topics
      }
    },
    setSelectedData: (
      state,
      action: PayloadAction<WithAssessmentEntryId<{ dataLevel: DataLevel; selectedData: string }>>
    ) => {
      if (state.dataForEntries[action.payload.assessmentEntryId] === undefined) {
        state.dataForEntries = {
          ...state.dataForEntries,
          [action.payload.assessmentEntryId]: {
            ...makeFundamentalDataForEntry(),
            selectedData: {
              ...state.dataForEntries[action.payload.assessmentEntryId].selectedData,
              [action.payload.data.dataLevel]: [action.payload.data.selectedData],
            },
          },
        }
      } else {
        if (action.payload.data.selectedData !== "reset") {
          const selectedDataList =
            state.dataForEntries[action.payload.assessmentEntryId].selectedData[action.payload.data.dataLevel]
          const currentlySelectedData = selectedDataList.includes(action.payload.data.selectedData)
            ? selectedDataList.filter((id) => id !== action.payload.data.selectedData)
            : [...selectedDataList, action.payload.data.selectedData]

          state.dataForEntries[action.payload.assessmentEntryId].selectedData[action.payload.data.dataLevel] =
            currentlySelectedData
        } else {
          state.dataForEntries[action.payload.assessmentEntryId].selectedData[action.payload.data.dataLevel] =
            defaultSelectedDataList()
        }
      }
    },
    setClearSelectedData: (state, action: PayloadAction<WithAssessmentEntryId<{ dataLevel: DataLevel }>>) => {
      if (state.dataForEntries[action.payload.assessmentEntryId] === undefined) {
        state.dataForEntries = {
          ...state.dataForEntries,
          [action.payload.assessmentEntryId]: makeFundamentalDataForEntry(),
        }
      } else {
        state.dataForEntries[action.payload.assessmentEntryId].selectedData[action.payload.data.dataLevel] = []
      }
    },
    setDataForScores: (
      state,
      action: PayloadAction<{ assessmentEntryId: string; data: WithDataLevel<{ [key in string]: number }> }>
    ) => {
      if (state.dataForEntries[action.payload.assessmentEntryId] === undefined) {
        state.dataForEntries = {
          ...state.dataForEntries,
          [action.payload.assessmentEntryId]: {
            ...makeFundamentalDataForEntry(),
            dataViewScores: {
              ...makeDefaultWithDataLevel({}),
              ...action.payload,
            },
          },
        }
      } else {
        state.dataForEntries[action.payload.assessmentEntryId].dataViewScores = action.payload.data
      }
    },
    setFetchedStatus: (state, action: PayloadAction<WithAssessmentEntryId<DataFetchStatus>>) => {
      if (state.dataForEntries[action.payload.assessmentEntryId] === undefined) {
        state.dataForEntries = {
          ...state.dataForEntries,
          [action.payload.assessmentEntryId]: {
            ...makeFundamentalDataForEntry(),
            dataFetchStatus: action.payload.data,
          },
        }
      } else {
        state.dataForEntries[action.payload.assessmentEntryId].dataFetchStatus = action.payload.data
      }
    },
    setIsochroneType: (state, action: PayloadAction<WithAssessmentEntryId<IsochroneType>>) => {
      if (state.dataForEntries[action.payload.assessmentEntryId] === undefined) {
        state.dataForEntries = {
          ...state.dataForEntries,
          [action.payload.assessmentEntryId]: {
            ...makeFundamentalDataForEntry(),
            isochroneSettings: action.payload.data,
          },
        }
      } else {
        state.dataForEntries[action.payload.assessmentEntryId].isochroneSettings = action.payload.data
      }
    },
  },
})

const {
  setDataLevel,
  setViewportSettings,
  setSelectedTileIds,
  setCollapsedCategories,
  setCollapsedTopics,
  setSelectedData,
  setClearSelectedData,
  setDataForScores,
  setFetchedStatus,
  setIsochroneType,
} = fundamentalDataSlice.actions

function makeWithAssessmentEntryId<T>(assessmentEntryId: string, data: T): WithAssessmentEntryId<T> {
  return { assessmentEntryId, data }
}

export function updateDataLevel(assessmentEntryId: string, dataLevel: DataLevel) {
  store.dispatch(setDataLevel(makeWithAssessmentEntryId(assessmentEntryId, dataLevel)))
}

export function updateViewportSettings(assessmentEntryId: string, viewportSettings: ViewportSettings) {
  store.dispatch(setViewportSettings(makeWithAssessmentEntryId(assessmentEntryId, viewportSettings)))
}

export function updateSelectedTileIds(
  assessmentEntryId: string,
  dataLevel: DataLevel,
  tileId: string | undefined,
  updateStatus: boolean
) {
  if (tileId)
    store.dispatch(
      setSelectedTileIds(makeWithAssessmentEntryId(assessmentEntryId, { dataLevel, selectedTileId: tileId }))
    )

  if (updateStatus) {
    updateFetchedStatus(assessmentEntryId, "need-fetching")
  }
}

export function updateCollapsedCategories(assessmentEntryId: string, dataLevel: DataLevel, categories: string[]) {
  store.dispatch(setCollapsedCategories(makeWithAssessmentEntryId(assessmentEntryId, { dataLevel, categories })))
}

export function updateCollapsedTopics(assessmentEntryId: string, dataLevel: DataLevel, topics: string[]) {
  store.dispatch(setCollapsedTopics(makeWithAssessmentEntryId(assessmentEntryId, { dataLevel, topics })))
}

export function updateSelectedData(assessmentEntryId: string, dataLevel: DataLevel, selectedData: string) {
  store.dispatch(setSelectedData(makeWithAssessmentEntryId(assessmentEntryId, { dataLevel, selectedData })))
  updateFetchedStatus(assessmentEntryId, "need-fetching")
}

export function resetSelectedData(assessmentEntryId: string, dataLevel: DataLevel) {
  store.dispatch(setSelectedData(makeWithAssessmentEntryId(assessmentEntryId, { dataLevel, selectedData: "reset" })))
  updateFetchedStatus(assessmentEntryId, "need-fetching")
}

export function clearSelectedData(assessmentEntryId: string, dataLevel: DataLevel) {
  store.dispatch(setClearSelectedData(makeWithAssessmentEntryId(assessmentEntryId, { dataLevel })))
  updateFetchedStatus(assessmentEntryId, "need-fetching")
}

export function updateDataForScores(
  assessmentEntryId: string,
  data: FundamentalDataQuery,
  callback: (success: boolean) => void
) {
  void Axios.post(`${lanaApiUrl}/api/fundamental_data/scores`, data)
    .then(
      (
        success: AxiosResponse<{
          scores: WithDataLevel<{ [key in string]: number }>
        }>
      ) => {
        store.dispatch(setDataForScores({ assessmentEntryId, data: success.data.scores }))
        callback(true)
      }
    )
    .catch((e) => {
      console.error(e)
      callback(false)
    })
}

export function updateFetchedStatus(assessmentEntryId: string, data: DataFetchStatus) {
  store.dispatch(setFetchedStatus(makeWithAssessmentEntryId(assessmentEntryId, data)))
}

export function updateIsochroneType(assessmentEntryId: string, data: IsochroneType) {
  store.dispatch(setIsochroneType(makeWithAssessmentEntryId(assessmentEntryId, data)))
}

export default fundamentalDataSlice.reducer
