import * as React from "react"
import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react"
import { translations } from "../../i18n"
import { COLORS } from "../../../shared/components/ui/colors"
import { ScrollBox } from "../../../shared/components/ui/scroll-box"
import { css } from "emotion"
import {
  FieldPath,
  useForm,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form"
import { EnumQuestion, Question } from "../../models/rentindex"
import { AssessmentTranslationsGroup } from "../../i18n/translations"
import { HTMLSelect, IconProps } from "@blueprintjs/core"
import { UseFormWatch } from "react-hook-form/dist/types/form"
import { DownloadReport } from "../../../shared/components/ui/download-report"
import { readAnswers, RentIndexFormData, rentIndexFormDefaults, saveAnswers } from "./rent-index-form-utils"
import useInterval from "../../../utils/use-interval"
import Axios from "axios"
import { lanaApiUrl } from "../../../app_config"
import { loadAssessmentForAssessmentModule } from "../../reducers/comparables-slice"
import { useAppSelector } from "../../../relas/store"
import { getCityInfo, getRentIndex } from "../../reducers/rentindex-slice"
import { DocumentationAndSupport } from "../../../shared/components/documentation-and-support"
import { NumberInput } from "../../../shared/components/ui/number-input"
import {
  integerValidator,
  maxValidator,
  minValidator,
  moreThanValidator,
  numberValidator,
  requiredValidator,
  sequentialValidator,
  StringKeys,
} from "../../../utils/utils"
import { yearValidator } from "../../../utils/form-validators"
import GenericErrorPanel from "../../../shared/components/genericerrorpanel"
import HorizontalDivider from "../../../shared/components/horizontaldivider"
import Panel from "../../../shared/components/panel"
import Card from "../../../shared/components/card"
import LoadingSpinner from "../../../shared/components/loadingspinner"
import Grid from "../../../shared/components/restyle-grid/grid"
import Button from "../../../shared/components/button"
import { getThemeColor } from "../../../shared/helper/color"
import { formatNumber } from "../../../shared/helper/number-format"
import LanaSubheader from "../../../shared/components/lana-subheader"

const styles = {
  headerPanel: css({
    display: "flex",
    gap: "16px",
    alignItems: "center",
    backgroundColor: COLORS.background.lighter,
    padding: "16px",
    marginBottom: "4px",
  }),
  questItem: css({
    display: "grid",
    div: {
      alignSelf: "end",
    },
  }),
  catalogTitle: css({
    color: COLORS.primary.default,
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "8px",
  }),
  questionsGrid: css({
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    columnGap: "16px",
    rowGap: "8px",
    padding: "0 8px",
  }),
  questionSeparator: css({
    color: COLORS.primary.default,
    fontStyle: "italic",
    fontWeight: 500,
    fontSize: "18px",
    gridColumn: "1 / -1",
    alignSelf: "center",
  }),
  dropdown: css({
    marginBottom: 8,
    borderRadius: 4,
    "select:enabled": {
      backgroundColor: "white",
    },
  }),
  calculatorGrid: css({
    display: "grid",
    rowGap: "12px",
  }),
  calculatorResult: css({
    textAlign: "center",
    padding: "10px 15px",
    borderRadius: 3,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: COLORS.border.default,
  }),
  pageContainer: css({
    display: "grid",
    gridTemplateRows: "fit-content(100%) fit-content(100%) minmax(0, 1fr)",
    height: "100%",
  }),
}

const iconProps: Partial<IconProps> = {
  icon: "chevron-down",
  iconSize: 16,
  color: getThemeColor("primary", "default").toString(),
}

type Props = {
  assessmentId: string
  assessmentEntryId: string | null
}

const QuestionWrapper: FC<PropsWithChildren<{ question: Question }>> = ({ question, children }) => {
  return (
    <div className={styles.questItem}>
      <strong>{question.text}</strong>
      {question.description && <p>{question.description}</p>}
      {children}
    </div>
  )
}

function questionRenderer(
  register: UseFormRegister<RentIndexFormData>,
  watch: UseFormWatch<RentIndexFormData>,
  setValue: UseFormSetValue<RentIndexFormData>,
  setError: UseFormSetError<RentIndexFormData>,
  clearErrors: UseFormClearErrors<RentIndexFormData>,
  t: AssessmentTranslationsGroup
) {
  const setNumericInputValidValue =
    (path: FieldPath<RentIndexFormData>) => (value: number, _: string, valid: boolean) => {
      if (valid) {
        clearErrors(path)
        setValue(path, value)
      } else {
        setError(path, { type: "manual" })
      }
    }

  return (question: Question, idx: number) => {
    const questionKey = "question-" + (question.id || `sep-${idx}`)
    const fieldKind = question.kind === "single" ? question.valueType : question.kind
    switch (fieldKind) {
      case "boolean":
        return (
          <QuestionWrapper question={question} key={questionKey}>
            <HTMLSelect
              className={styles.dropdown}
              value={watch(`answers.${question.id}`) as any}
              {...register(`answers.${question.id}`)}
              fill
              iconProps={iconProps}
              onChange={(e) => setValue(`answers.${question.id}`, e.target.value)}
              options={[
                { value: "", label: "" },
                { value: "true", label: t.assessmentEntryDetails.yes },
                { value: "false", label: t.assessmentEntryDetails.no },
              ]}
            />
          </QuestionWrapper>
        )
      case "enum":
        return (
          <QuestionWrapper question={question} key={questionKey}>
            <HTMLSelect
              className={styles.dropdown}
              value={watch(`answers.${question.id}`) as any}
              {...register(`answers.${question.id}`)}
              fill
              iconProps={iconProps}
              onChange={(e) => setValue(`answers.${question.id}`, e.target.value)}
              options={[
                { value: "", label: "" },
                ...(question as EnumQuestion).value.map((o) => ({ value: o.answer, label: o.answer })),
              ]}
            />
          </QuestionWrapper>
        )
      case "separator":
        return (
          <div className={styles.questionSeparator} key={questionKey}>
            {question.text}
          </div>
        )
      case "int":
        return (
          <QuestionWrapper question={question} key={questionKey}>
            <NumberInput
              defaultValue={
                typeof watch(`answers.${question.id}`) === "string"
                  ? parseInt(watch(`answers.${question.id}`) as string)
                  : undefined
              }
              onValueChange={setNumericInputValidValue(`answers.${question.id}`)}
              validator={integerValidator()}
            />
          </QuestionWrapper>
        )
      case "double":
        return (
          <QuestionWrapper question={question} key={questionKey}>
            <NumberInput
              defaultValue={
                typeof watch(`answers.${question.id}`) === "string"
                  ? parseFloat(watch(`answers.${question.id}`) as string)
                  : undefined
              }
              onValueChange={setNumericInputValidValue(`answers.${question.id}`)}
            />
          </QuestionWrapper>
        )
      case "percent":
        return (
          <QuestionWrapper question={question} key={questionKey}>
            <NumberInput
              defaultValue={
                typeof watch(`answers.${question.id}`) === "string"
                  ? parseFloat(watch(`answers.${question.id}`) as string)
                  : undefined
              }
              onValueChange={setNumericInputValidValue(`answers.${question.id}`)}
              suffix="%"
              validator={integerValidator()}
            />
          </QuestionWrapper>
        )
      default:
        if (question.kind === "single" && typeof question.valueType === "object") {
          return (
            <QuestionWrapper question={question} key={questionKey}>
              <HTMLSelect
                className={styles.dropdown}
                value={watch(`answers.${question.id}`) as any}
                {...register(`answers.${question.id}`)}
                fill
                iconProps={iconProps}
                onChange={(e) => setValue(`answers.${question.id}`, e.target.value)}
                options={[{ value: "", label: "" }, ...question.valueType.enum.map((o) => ({ value: o, label: o }))]}
              />
            </QuestionWrapper>
          )
        }
        return null
    }
  }
}

export const RentIndexMain: FC<Props> = ({}) => {
  const assessment = useAppSelector((state) => state.assessment.currentAssessment)
  const assessmentEntry = useAppSelector((state) => state.assessment.currentAssessmentEntry)
  const cityInfo = useAppSelector((state) => state.rentindex.cityInfo)
  const cityInfoLoadInProgress = useAppSelector((state) => state.rentindex.cityInfoLoadInProgress)
  const cityInfoLoadError = useAppSelector((state) => state.rentindex.cityInfoLoadError)
  const rentindexResult = useAppSelector((state) => state.rentindex.rentindexResult?.[0])
  const rentindexResultLoadError = useAppSelector((state) => state.rentindex.rentindexResultLoadError)
  const rentindexResultLoadInProgress = useAppSelector((state) => state.rentindex.rentindexResultLoadInProgress)

  const t = useMemo(translations, [translations])

  const [submitTimeout, setSubmitTimeout] = useState<NodeJS.Timeout | undefined>(undefined)

  function getDefaultValues(): RentIndexFormData {
    return {
      area: assessmentEntry?.area || rentIndexFormDefaults.area,
      yearOfConstruction:
        (assessmentEntry?.newBuilding ? new Date().getFullYear() : assessmentEntry?.year) ||
        rentIndexFormDefaults.yearOfConstruction,
      answers: {},
    }
  }

  const defaultValues = getDefaultValues()

  const { register, handleSubmit, watch, setValue, reset, clearErrors, setError } = useForm<RentIndexFormData>({
    defaultValues: defaultValues,
  })

  const renderQuestion = questionRenderer(register, watch, setValue, setError, clearErrors, t)

  useEffect(() => {
    if (assessmentEntry) {
      reset(getDefaultValues())
      void getCityInfo(assessmentEntry.address)
    }
  }, [assessmentEntry])

  useEffect(() => {
    if (assessmentEntry && cityInfo) {
      const answers = readAnswers(assessmentEntry.id, cityInfo)
      if (answers) {
        setValue("area", answers.area)
        setValue("yearOfConstruction", answers.yearOfConstruction)
        setValue("answers", answers.answers, { shouldDirty: true, shouldValidate: true })
      }
      void handleSubmit(onSubmit)()
    }
  }, [cityInfo?.city])

  // assessment creation from Rent index module
  useInterval(
    () => {
      if (assessment && assessmentEntry) {
        Axios.get(
          `${lanaApiUrl}/api/assessments/${encodeURIComponent(assessment.id)}/entries/${encodeURIComponent(
            assessmentEntry.id
          )}`
        ).then(
          () => {
            void loadAssessmentForAssessmentModule(assessment.id, assessmentEntry.id, "rentindex")
          },
          () => {}
        )
      }
    },
    assessment &&
      (assessment.state === "scored" || assessment.state === "scored-ambiguous" || assessment.state === "failure")
      ? null
      : 2000
  )

  function onSubmit(data: RentIndexFormData) {
    if (assessmentEntry) {
      getRentIndex({
        address: assessmentEntry!.address,
        yearOfConstruction: data.yearOfConstruction,
        answers: { ...data.answers },
        areas: [data.area],
      })
      saveAnswers(assessmentEntry.id, data)
    }
  }

  function scheduleSubmit() {
    if (submitTimeout) {
      clearTimeout(submitTimeout)
    }

    setSubmitTimeout(
      setTimeout(() => {
        return handleSubmit(onSubmit)()
      }, 1000)
    )
  }

  const onResetAllAnswersClick = () => {
    if (assessmentEntry && cityInfo) {
      const answers = readAnswers(assessmentEntry.id, cityInfo)
      if (answers?.answers) {
        Object.keys(answers.answers).forEach((i) => (answers.answers[i] = ""))
        setValue("answers", answers.answers)
      }
      setValue("area", assessmentEntry?.area || rentIndexFormDefaults.area)
      setValue("yearOfConstruction", assessmentEntry?.year || rentIndexFormDefaults.yearOfConstruction)
      window.localStorage.removeItem(`RENTINDEX_DATA_${assessmentEntry.id}`)
    }
    void handleSubmit(onSubmit)()
  }

  const onNumberInputChange =
    (inputName: keyof StringKeys<RentIndexFormData>) => (newValue: number, strValue: string, valid: boolean) => {
      if (valid) {
        setValue(inputName, newValue)
      }
    }

  function renderContent() {
    if (cityInfoLoadInProgress) {
      return <LoadingSpinner />
    }

    if (cityInfoLoadError?.status === 404) {
      return (
        <div style={{ padding: "8px" }}>
          <Panel title={t.rentindex.noCityInfoHeader} color="neutral">
            <p>{t.rentindex.noCityInfoDescription}</p>
            <p>{t.rentindex.listOfAllCitiesInformationNavigateTo}</p>
          </Panel>
        </div>
      )
    }

    const errorDetailsOpt = rentindexResultLoadError?.data?.details

    const hasAddressError =
      Array.isArray(errorDetailsOpt) &&
      (errorDetailsOpt?.reduce((a, b) => [...a, ...b], []).findIndex((i) => i.path?.includes("address")) ?? -1) !== -1

    if (hasAddressError) {
      return (
        <Panel color="negative" title={t.assessmentEntryDetails.stateStatus.failure}>
          {t.rentindex.unknownAddress}
        </Panel>
      )
    }

    if (cityInfoLoadError) {
      return <GenericErrorPanel error={cityInfoLoadError} />
    }

    if (cityInfo) {
      return (
        <>
          <div className={styles.headerPanel}>
            <h1>
              {t.rentindex.rentIndexHeader} {cityInfo.city} ({cityInfo.rentIndexYear})
            </h1>
            <div style={{ marginLeft: "auto" }}>
              <Button type={"primary"} onClick={onResetAllAnswersClick}>
                {t.rentindex.resetAllAnswers}
              </Button>
            </div>
          </div>
          <ScrollBox>
            <form onChange={() => scheduleSubmit()} onSubmit={() => handleSubmit(onSubmit)}>
              <Grid columns={2} columnSpec={"1fr 300px"} gap={8} padding={8}>
                <Grid columns={1} rowGap={8}>
                  {cityInfo.generalInformation && (
                    <Card header={t.rentindex.generalInformation}>
                      <div dangerouslySetInnerHTML={{ __html: cityInfo.generalInformation }} />
                    </Card>
                  )}
                  {cityInfo?.questionsCatalogs.map((i) => i.questions.length).reduce((a, b) => a + b, 0) > 0 && (
                    <Card header={t.rentindex.quesitonCatalog}>
                      <div className={styles.questionsGrid}>
                        {...(
                          cityInfo?.questionsCatalogs.map((c) => c.questions.filter((q) => q.kind === "single")) ?? []
                        )
                          .reduce((acc, cur) => acc.concat(cur), [])
                          .map(renderQuestion)}
                      </div>
                      {cityInfo.questionsCatalogs
                        .map((c) => ({ ...c, questions: c.questions.filter((q) => q.kind !== "single") }))
                        .filter((c) => c.questions.length > 0)
                        .map((catalog) => {
                          return (
                            <div style={{ padding: "8px 0 4px" }} key={"catalog-" + catalog.name}>
                              <div className={styles.catalogTitle}>{catalog.name}</div>
                              <div className={styles.questionsGrid}>{catalog.questions.map(renderQuestion)}</div>
                            </div>
                          )
                        })}
                    </Card>
                  )}
                </Grid>
                <div>
                  <div style={{ position: "sticky", top: 8 }}>
                    <Card header={t.rentindex.calculationHeader}>
                      <div className={styles.calculatorGrid}>
                        <div>
                          <strong>{t.assessmentEntryDetails.livingArea}</strong>
                          <NumberInput
                            defaultValue={defaultValues.area}
                            validator={sequentialValidator([
                              requiredValidator(t.validationTranslations.required),
                              moreThanValidator(0, t.validationTranslations.moreThan(0)),
                              maxValidator(99999, t.validationTranslations.max(99999)),
                              numberValidator(t.validationTranslations.notANumber),
                            ])}
                            onValueChange={onNumberInputChange("area")}
                            suffix={"m²"}
                          />
                        </div>
                        <div>
                          <strong>{t.assessmentEntryDetails.constructionYear}</strong>
                          <NumberInput
                            defaultValue={defaultValues.yearOfConstruction}
                            validator={sequentialValidator([
                              requiredValidator(t.validationTranslations.required),
                              minValidator(1261, t.validationTranslations.min(1261)),
                              maxValidator(2100, t.validationTranslations.max(2100)),
                              numberValidator(t.validationTranslations.notANumber),
                              yearValidator(t.validationTranslations.constructionYearValidation),
                            ])}
                            onValueChange={onNumberInputChange("yearOfConstruction")}
                          />
                        </div>
                        {!rentindexResult?.success && !rentindexResultLoadInProgress && (
                          <Panel color={"negative"}>{t.rentindex.cantBeCalculated}</Panel>
                        )}
                        <div>
                          <strong>{t.rentindex.rentindexMinMax}</strong>

                          <div
                            className={styles.calculatorResult}
                            style={{
                              backgroundColor: rentindexResult?.success ? COLORS.positive.light : "transparent",
                            }}
                          >
                            <Grid columnSpec={"1fr 1fr 1fr"} columns={3} gap={"sm"}>
                              <div>
                                {rentindexResult?.success
                                  ? formatNumber(rentindexResult.rentIndex.min, 2, undefined, true)
                                  : "-"}
                              </div>
                              <div>
                                <strong>
                                  {rentindexResult?.success
                                    ? formatNumber(rentindexResult.rentIndex.avg, 2, undefined, true)
                                    : "-"}
                                </strong>
                              </div>
                              <div>
                                {rentindexResult?.success
                                  ? formatNumber(rentindexResult.rentIndex.max, 2, undefined, true)
                                  : "-"}
                              </div>
                            </Grid>
                            <div>{t.rentindex.allValues}</div>
                          </div>
                        </div>
                      </div>
                    </Card>
                    <br />
                    <Card header={t.rentindex.listOfAllCitiesInformationHeader}>
                      {t.rentindex.listOfAllCitiesInformationNavigateTo}
                    </Card>
                  </div>
                </div>
              </Grid>
            </form>
          </ScrollBox>
        </>
      )
    }

    return null
  }

  return (
    <div className={styles.pageContainer}>
      <LanaSubheader
        menuSection={"locationAssessment"}
        assessment={assessment}
        assessmentEntry={assessmentEntry}
        module={"rentindex"}
      >
        <DocumentationAndSupport tooltip={t.helpAndSupport} onClick={() => {}} />
        <HorizontalDivider height={32} />
        <DownloadReport />
      </LanaSubheader>
      {renderContent()}
    </div>
  )
}
