import PoiCategoriesList from "./poi-categories-list"
import React, { useMemo, useState } from "react"
import { translations } from "../../i18n"
import { css } from "emotion"
import { Flex } from "../../../shared/components/ui/flex"
import Axios, { AxiosError } from "axios"
import { lanaApiUrl } from "../../../app_config"
import { trackUsageEvent } from "../../../utils/usage-tracking"
import { fetchUserDefinedPOIGroups } from "../../reducers/poi-explorer-slice"
import { useAppSelector } from "../../../relas/store"
import Panel from "../../../shared/components/panel"
import Grid from "../../../shared/components/restyle-grid/grid"
import TextField from "../../../shared/components/textfield"
import Button from "../../../shared/components/button"
import { getThemeColor } from "../../../shared/helper/color"
import { toGenericError } from "../../../shared/helper/axios"
import Form from "../../../shared/components/form"

const styles = {
  paneHeader: css({
    fontWeight: "bold",
    borderBottom: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
  }),
  poiCategoriesList: css({
    borderTop: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
  }),
}

interface Props {
  selectedPOICategories: Set<string>
  setSelectedPOICategories: (selectedCategories: Set<string>) => void
  setSelectedPOIGroup: (selectedPOIGroup: string) => void
  onClosePanel: () => void
}

const POICategoryCreation = ({
  setSelectedPOIGroup,
  selectedPOICategories,
  setSelectedPOICategories,
  onClosePanel,
}: Props) => {
  const t = useMemo(translations, [translations])
  const assessmentEntry = useAppSelector((state) => state.assessment.currentAssessmentEntry)
  const userDefinedPOIGroups = useAppSelector((state) => state.POIExplorerApp.userDefinedPOIGroups)

  const [newPOICategoryGroupName, setNewPOICategoryGroupName] = useState<string>("")
  const [groupCreationError, setGroupCreationError] = useState(false)

  const onSubmit = (event: React.FormEvent) => {
    const trackingAdditionalInfo = `${newPOICategoryGroupName} - ${[...selectedPOICategories].join(", ")}`
    assessmentEntry && trackUsageEvent("POI_EXPLORER_CREATE_GROUP", assessmentEntry.address, trackingAdditionalInfo)

    event.preventDefault()
    event.stopPropagation()

    if (
      userDefinedPOIGroups &&
      userDefinedPOIGroups.find(
        (poiGroup) => poiGroup.name.toLowerCase() === newPOICategoryGroupName.toLowerCase().trim()
      )
    ) {
      setGroupCreationError(true)
    } else {
      const dataJson = {
        name: newPOICategoryGroupName.trim(),
        categories: [...selectedPOICategories],
      }

      Axios.post(`${lanaApiUrl}/api/v3/poi/categories_groups`, dataJson).then(
        (success) => {
          fetchUserDefinedPOIGroups(success.data.id)
          onClosePanel()
        },
        (error: AxiosError) => {
          const mapped = toGenericError(error)
          return Promise.reject(mapped)
        }
      )
    }
  }

  const onCancel = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    onClosePanel()
  }
  return (
    <div style={{ display: "grid", gridTemplateRows: "min-content 1fr", height: "100%", fontSize: "14px" }}>
      <div className={styles.paneHeader}>
        <div style={{ padding: "16px" }}>{t.poiExplorer.createGroup}</div>
      </div>
      <Form onSubmit={onSubmit}>
        <Grid columns={1} gap={4} rowSpec={"min-content 1fr min-content"} padding={[16, 0]} height={[100, "%"]}>
          <Flex flexDirection={"column"} gap={4} padding={[0, 16]}>
            <div style={{ fontWeight: "bold" }}>{t.poiExplorer.name}</div>
            <TextField
              javaScriptAutoFocus={true}
              value={newPOICategoryGroupName}
              onValueChange={(value) => {
                setNewPOICategoryGroupName(value)
                setGroupCreationError(false)
              }}
            />
            <div>{t.poiExplorer.newPOIGroupNameLabel}</div>
            {groupCreationError && <Panel color="negative">{t.groupAlreadyExists}</Panel>}
            <div style={{ paddingTop: "8px", fontWeight: "bold" }}>{t.poiExplorer.categories}</div>
          </Flex>
          <div className={styles.poiCategoriesList}>
            <PoiCategoriesList
              selectedPOICategories={selectedPOICategories}
              setSelectedPOICategories={setSelectedPOICategories}
              textSearch={null}
            />
          </div>
          <Flex flexDirection="row-reverse" gap={16} padding={16}>
            <Button
              disabled={groupCreationError || newPOICategoryGroupName.trim() === ""}
              formType="submit"
              type="primary"
            >
              {t.ok}
            </Button>
            <Button type="secondary" onClick={(e) => onCancel(e)}>
              {t.cancel}
            </Button>
          </Flex>
        </Grid>
      </Form>
    </div>
  )
}

export default POICategoryCreation
