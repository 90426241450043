import * as React from "react"
import { css } from "emotion"
import { Location } from "../../../shared/models/geo-partition"
import { fromLonLat } from "ol/proj"
import * as ol from "ol"
import * as geom from "ol/geom"
import { Point } from "ol/geom"
import * as layer from "ol/layer"
import * as source from "ol/source"
import * as style from "ol/style"
import DashboardWidget from "./dashboard-widget"
import { translations as assessmentTranslations } from "../../i18n"
import { HereCombinedResponse, IsoLine } from "../../../shared/models/poi-explorer"
import { reportAssetsUrl } from "../../../reports/report-config"
import { OpenLayersAbstractProps, OpenLayersMap } from "../../../shared/components/openlayers-map"
import Axios from "axios"
import { lanaApiUrl } from "../../../app_config"
import { bind } from "decko"
import { DashboardEditMode } from "./dashboard-widget-grid"
import { IsochroneType } from "../isochrone-type"
import {
  MultipleCategoryPOIs,
  PrivatePOICategoriesList,
  PrivatePOIList,
} from "../../../private-data/models/private-data"
import { updateClusters } from "../../../utils/utils"
import { translations as sharedTranslations } from "../../../shared/i18n"
import Grid from "../../../shared/components/restyle-grid/grid"
import { getThemeColor } from "../../../shared/helper/color"

interface DashboardProps extends OpenLayersAbstractProps {
  markerLocation?: Location
  onHeaderClick: () => void
  onRemoveClick: () => void
  onConfigClick?: () => void
  htmlId?: string
  isochrone: IsochroneType
  isPrivateDataAccessible: boolean
  privatePoiCategoriesInConfig: string[]
  privatePoiCategories: PrivatePOICategoriesList
  printMode: boolean
  editMode: DashboardEditMode
  configurable: boolean
  onRenderingDone: () => void
}

const mapClass = css({
  minHeight: "200px",
  height: "100%",
  position: "relative",
  backgroundColor: "#ffffff",
  borderRadius: "0px",
})

export const defaultIsochroneConfig: IsochroneType = { mode: "pedestrian", time: 15 }
export const defaultPrivatePoiCategories: string[] = []

type State = { isochrone?: IsoLine }

const MARKER_LAYER = "MARKER_LAYER"

export class MicroMap extends OpenLayersMap<DashboardProps, State> {
  private t = assessmentTranslations()
  private sharedT = sharedTranslations()
  private readonly isochroneLayer: layer.Vector<any>
  private markerLayer: layer.Vector<any> | undefined = undefined
  protected clusterLayerArray: layer.Vector<any>[] = []
  private privatePoiCategoryMap: MultipleCategoryPOIs = {}
  private onRenderDoneTimeout: number | undefined = undefined

  constructor(props: DashboardProps) {
    super(props)
    this.state = {
      isochrone: undefined,
    }
    this.isochroneLayer = new layer.Vector({
      source: new source.Vector({
        features: [],
      }),
      style: new style.Style({
        fill: new style.Fill({
          color: "rgba(255, 255, 255, 0.4)",
        }),
        stroke: new style.Stroke({
          color: getThemeColor("primary", "default").toString(),
          width: 4,
        }),
      }),
      zIndex: 1,
    })
    if (this.props.isPrivateDataAccessible)
      this.props.privatePoiCategoriesInConfig.forEach((category) => {
        this.privatePoiCategoryMap[category] = []
      })
  }

  @bind()
  onWidgetRenderingDone() {
    if (this.onRenderDoneTimeout) {
      window.clearTimeout(this.onRenderDoneTimeout)
    }

    this.onRenderDoneTimeout = window.setTimeout(() => {
      this.props.onRenderingDone()
    }, 2000)
  }

  @bind
  protected setClusterLayerArray(newClusterArrayLayer: layer.Vector<any>[]) {
    newClusterArrayLayer.forEach((layer, idx) => layer.setZIndex(1 + idx))
    this.clusterLayerArray = [...newClusterArrayLayer]
  }

  async componentDidMount() {
    super.componentDidMount()
    this.getMap().getControls().clear()
    this.getMap()
      .getInteractions()
      .forEach((interaction) => {
        interaction.setActive(false)
      })
    if (this.props.markerLocation) {
      this.getMap()
        .getView()
        .setCenter(fromLonLat([this.props.markerLocation.lng, this.props.markerLocation.lat]))
      // this.getMap().getView().setZoom(12)
      this.createMarkerLayer(false)
      this.getMap().addLayer(this.isochroneLayer)
      await this.setIsochrone()
      if (this.props.isPrivateDataAccessible) await this.fetchAndSetPrivatePoiMap()
      updateClusters(
        this.getMap(),
        this.clusterLayerArray,
        this.setClusterLayerArray,
        this.privatePoiCategoryMap,
        this.props.privatePoiCategories,
        this.props.isPrivateDataAccessible
      )
    }
  }

  async componentDidUpdate(prevProps: DashboardProps, prevState: State) {
    this.getMap().un("rendercomplete", this.onWidgetRenderingDone)

    super.componentDidUpdate(prevProps, prevState)

    if (
      this.props.isochrone.mode !== prevProps.isochrone.mode ||
      this.props.isochrone.time !== prevProps.isochrone.time
    ) {
      await this.setIsochrone()
    }
    this.displayIsoline(this.state.isochrone)

    if (this.props.markerLocation && this.props.markerLocation !== prevProps.markerLocation)
      this.createMarkerLayer(true)

    if (
      this.props.privatePoiCategoriesInConfig !== prevProps.privatePoiCategoriesInConfig ||
      this.props.privatePoiCategories !== prevProps.privatePoiCategories
    ) {
      this.clusterLayerArray.forEach((layer) => this.getMap().removeLayer(layer))
      this.clusterLayerArray = []
      await this.fetchAndSetPrivatePoiMap()
      updateClusters(
        this.getMap(),
        this.clusterLayerArray,
        this.setClusterLayerArray,
        this.privatePoiCategoryMap,
        this.props.privatePoiCategories,
        this.props.isPrivateDataAccessible
      )
    }

    this.getMap().on("rendercomplete", this.onWidgetRenderingDone)
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    this.getMap().un("rendercomplete", this.onWidgetRenderingDone)
  }

  createMarkerLayer(deleteExisting: boolean) {
    if (deleteExisting && this.markerLayer) this.getMap().removeLayer(this.markerLayer)

    if (this.props.markerLocation) {
      const feature = new ol.Feature({
        geometry: new Point(fromLonLat([this.props.markerLocation.lng, this.props.markerLocation.lat])),
      })
      feature.setId(MARKER_LAYER)
      this.markerLayer = new layer.Vector({
        source: new source.Vector({ features: [feature] }),
        style: new style.Style({
          image: new style.Icon({
            src: `${reportAssetsUrl ?? ""}/assets/marker.svg`,
            size: [42, 60],
            anchorOrigin: "top-left",
            anchor: [0.5, 1],
          }),
        }),
        zIndex: 999,
      })
      this.getMap().addLayer(this.markerLayer)
    }
  }

  protected containerClass(): string {
    return mapClass
  }

  protected onMoveEnd() {
    // do nothing!
  }

  private async getPrivatePOIs(categoryId: string): Promise<PrivatePOIList> {
    const response = await Axios.get<PrivatePOIList>(
      `${lanaApiUrl}/api/v1/user_pois/categories/${encodeURIComponent(categoryId)}/pois`
    )
    return response.data
  }

  private async fetchAndSetPrivatePoiMap() {
    this.privatePoiCategoryMap = {}
    const promiseArray = this.props.privatePoiCategoriesInConfig.map((key) => ({
      key,
      value: this.getPrivatePOIs(key),
    }))
    await Promise.all(promiseArray).then(async (response) => {
      for (const res of response) {
        this.privatePoiCategoryMap[res.key] = await res.value
      }
    })
  }

  private async setIsochrone() {
    if (this.props.markerLocation && this.props.isochrone.mode !== "none") {
      await Axios.get<HereCombinedResponse>(`${lanaApiUrl}/api/v2/here/combined`, {
        params: {
          lat: this.props.markerLocation.lat.toString(),
          lng: this.props.markerLocation.lng.toString(),
          range: this.props.isochrone.time * 60,
          rangeType: "time",
          transportMode: this.props.isochrone.mode,
        },
      }).then((success) => {
        this.setState({
          isochrone: success.data.isolines[0],
        })
      })
    } else {
      this.setState({
        isochrone: undefined,
      })
    }
  }

  private displayIsoline(isochrone?: IsoLine) {
    const features: ol.Feature[] = []
    if (isochrone) {
      const coords = isochrone.polygons.map((polygon) => polygon.map((c) => fromLonLat(c)))
      const polygon = new geom.Polygon(coords)
      this.getMap()
        .getView()
        .fit(polygon, {
          duration: 0,
          size: this.getMap().getSize(),
          padding: [30, 10, 10, 10],
        })

      features.push(
        new ol.Feature({
          type: "Feature",
          geometry: polygon,
        })
      )
    } else this.getMap().getView().setZoom(14.62)
    this.isochroneLayer.setSource(new source.Vector({ features }))
  }

  render() {
    return (
      <DashboardWidget
        header={this.t.dashboard.microMap.header(
          this.props.isochrone.mode,
          this.sharedT.map.vehicle[this.props.isochrone.mode],
          this.props.isochrone.time
        )}
        headerFloating={true}
        onHeaderClick={this.props.onHeaderClick}
        onRemoveClick={this.props.onRemoveClick}
        onConfigClick={this.props.onConfigClick}
        configurable={this.props.configurable}
        htmlId={this.props.htmlId}
        printMode={this.props.printMode}
        editMode={this.props.editMode}
      >
        <Grid columns={1} rowSpec="1fr" height={[100, "%"]}>
          <div className={mapClass}>{super.render()}</div>
        </Grid>
      </DashboardWidget>
    )
  }
}
