import * as React from "react"
import { useEffect, useState } from "react"
import {
  AddressSuggestion,
  AddressSuggestionResponse,
  GeoInformation,
  HereAddress,
  LocationResponse,
} from "../../../shared/models/poi-explorer"
import { Address, formatAddress, isAddressComplete, Location } from "../../models/address"
import * as proj from "ol/proj"

import { AddressGeoView } from "../address-geo-view"
import Axios, { CancelTokenSource } from "axios"
import { geoServiceUrl } from "../../../app_config"
import { useDebounce } from "../../../utils/use-debounce"
import { Exclusiveness, reconstructUsageType } from "../../models/assessment"
import { LanaScopesType } from "../../../relas/user-state"
import {
  AllowedModulesEnum,
  PrivateDataModuleSettings,
  PrivatePOICategoriesList,
  PrivatePOIList,
} from "../../../private-data/models/private-data"
import { language } from "../../../shared/language"
import Grid from "../../../shared/components/restyle-grid/grid"
import FlexContainer from "../../../shared/components/restyle-grid/flexcontainer"
import { AddressSuggestionAutoComplete } from "../../../shared/components/address-suggestion-auto-complete"
import Panel from "../../../shared/components/panel"
import { Flex } from "../../../shared/components/ui/flex"
import { AppModules } from "../../../menu/util/app-location-types"
import { translations } from "../../i18n"
import { isoCountry } from "iso-country"
import { createSingleAssessment } from "../../reducers/assessment-slice-functions"
import { createAssessmentTitle } from "../assessment-create-single"
import Button from "../../../shared/components/button"
import { Tooltip } from "../../../shared/components/ui/tooltip"
import AssessmentEntryDetailEdit, { AssessmentEntryFormData } from "../assessment-entry-detail-edit"
import HorizontalDivider from "../../../shared/components/horizontaldivider"
import { getThemeColorVar } from "../../../shared/helper/color"
import Icon from "../../../shared/components/icon"
import { css, cx } from "emotion"
import { headerTextColor } from "../../../shared/components/header/lana-header"

const styles = {
  inputLabels: css({
    alignSelf: "auto",
    fontSize: "18px",
    whiteSpace: "nowrap",
    color: headerTextColor,
  }),
  details: css({
    alignSelf: "center",
    fontSize: "14px",
    whiteSpace: "nowrap",
  }),
  dropdownMenuItem: css({
    flexDirection: "column",
  }),
  buttonSelectBlueprint: css({
    background: "white !important",
    borderRadius: "4px",
    width: "100%",
    justifyContent: "space-between",
  }),
  addressInputError: css({
    " > div > div > div > div": {
      border: `1px solid ${getThemeColorVar("negative", "default")}`,
      color: getThemeColorVar("typo", "default"),
      borderRadius: "4px",
      "&:focus-within": {
        border: `1px solid ${getThemeColorVar("negative", "default")}`,
      },
    },
  }),
  addressInput: css({
    " > div > div > div > div": {
      border: `1px solid ${getThemeColorVar("border", "default")}`,
      borderRadius: "4px",
      "&:focus-within": {
        border: `1px solid ${getThemeColorVar("border", "default")}`,
      },
    },
  }),
}

interface AddressDialogState {
  addressSelection: Address | null
  center: number[]
  zoom: number
  searchTermSuggestion: AddressSuggestion | undefined
}

const INITIAL_ADDRESS_STATE: AddressDialogState = {
  center: proj.fromLonLat([10.5, 51]),
  zoom: 6,
  searchTermSuggestion: undefined,
  addressSelection: null,
}

export const INITIAL_CONFIGURATION_STATE: AssessmentEntryFormData = {
  usageType: "residentialRent",
  newBuilding: false,
  buildingYear: 2000,
  area: 100,
  exclusiveness: Exclusiveness.Standard,
  houseOrApartment: "apartment",
}

let cancel: CancelTokenSource | undefined = undefined

/**
 * The order of elements affects the priority of modules. The first available module is considered as a default one.
 * @param ls LanaScopes available
 */

function assessmentModulesAvailable(ls: LanaScopesType): Array<AppModules["locationAssessment"]> {
  const availableModules = [
    ls.assessment ? "dashboard" : undefined,
    ls.assessment ? "assessment" : undefined,
    ls.comparables ? "comparables" : undefined,
    ls.marketData ? "marketdata" : undefined,
    ls.poiExplorer ? "poiExplorer" : undefined,
    ls.specialMaps ? "specialMaps" : undefined,
  ] as const

  type PossibleModuleTypes = Exclude<(typeof availableModules)[number], undefined>

  return availableModules.filter((p): p is PossibleModuleTypes => !!p)
}

type Props = {
  lanaScopes: LanaScopesType
  isPrivateDataAccessible: boolean
  privatePoiCategories: PrivatePOICategoriesList
  privateDataSettings: PrivateDataModuleSettings
  getPrivateDataCategories: () => void
  updateSelectedPrivateDataCategories: (categories: PrivatePOICategoriesList, module: AllowedModulesEnum) => void
  getPrivatePoisFromCategories: (categoryId: string, module?: AllowedModulesEnum) => void
  updatePrivatePoisToShow: (poiList: PrivatePOIList, module?: AllowedModulesEnum) => void
}

const NewAssessmentConfiguration = (props: Props) => {
  const SUGGESTION_SUFFIX = ", Deutschland"
  const t = React.useMemo(translations, [translations])

  const [addressDialogState, setAddressDialogState] = useState(INITIAL_ADDRESS_STATE)
  const [suggestions, setSuggestions] = useState<AddressSuggestion[]>([])
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [droppedLocation, setDroppedLocation] = useState<Location | null>(null)
  const [manualCoords, setManualCoords] = useState(false)
  const [debouncedSearchTerm, searchTerm, setSearchTerm] = useDebounce<string>("", 200)
  const [firstLoading, setFirstLoading] = useState(true)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [quickAssessmentConfiguration, setQuickAssessmentConfiguration] = useState<AssessmentEntryFormData>()

  const showSetPinHint =
    !manualCoords &&
    addressDialogState.addressSelection !== null &&
    !isAddressComplete(addressDialogState.addressSelection)
  const addressOutsideOfGermany =
    !showSetPinHint &&
    addressDialogState.addressSelection !== null &&
    addressDialogState.addressSelection.country !== undefined &&
    addressDialogState.addressSelection.country !== "DE"

  useEffect(() => {
    if (firstLoading) setFirstLoading(false)
    else if (!manualCoords && !addressDialogState.searchTermSuggestion) {
      setAddressDialogState({ ...addressDialogState, searchTermSuggestion: undefined })
      setAddressDialogState({
        ...addressDialogState,
        searchTermSuggestion: undefined,
      })
      setSuggestions([])
      getSuggestions(debouncedSearchTerm + SUGGESTION_SUFFIX, (suggestions) => {
        setSuggestions(suggestions)
        setShowSuggestions(suggestions.length > 0)
      })
    }
  }, [debouncedSearchTerm])

  const fetchSuggestions = (address: string): Promise<void> => {
    setManualCoords(false)
    setAddressDialogState({ ...addressDialogState, searchTermSuggestion: undefined })
    setShowSuggestions(false)
    setSuggestions([])
    setSearchTerm(address)
    return Promise.resolve()
  }

  const locationToGeoInformation = (locationResponse: LocationResponse): GeoInformation => {
    const location = locationResponse.response.view[0].result[0].location

    return {
      coordinates: {
        latitude: location.displayPosition.latitude,
        longitude: location.displayPosition.longitude,
      },
    }
  }

  const resolveAddress = (suggestion: AddressSuggestion, locationResponse: LocationResponse): HereAddress => {
    const location = locationResponse.response.view[0].result[0].location

    if (location.address) {
      const address = location.address
      const maybeCountry = address.additionalData && address.additionalData["CountryName"]

      return {
        ...address,
        country: maybeCountry || suggestion.address.country,
      }
    }

    return suggestion.address
  }

  const suggestionToAddress = (s: AddressSuggestion): Address => {
    const lookupResult = isoCountry(s.countryCode)

    return {
      location: {
        lat: s.geoInformation.coordinates.latitude,
        lng: s.geoInformation.coordinates.longitude,
      },
      route: s.address.street,
      administrativeAreaLevel1: s.address.state,
      country: lookupResult ? lookupResult.code : "",
      locality: s.address.city,
      postalCode: s.address.postalCode,
      streetNumber: s.address.houseNumber,
    }
  }

  const selectAddress = (suggestion: AddressSuggestion) => {
    const formattedAddress = [
      suggestion.address.country,
      suggestion.address.postalCode,
      suggestion.address.city,
      suggestion.address.street,
      suggestion.address.houseNumber,
    ].join(" ")

    cancel?.cancel()

    const suggestedAddress = suggestionToAddress(suggestion)

    setAddressDialogState({
      ...addressDialogState,
      addressSelection: suggestedAddress,
      searchTermSuggestion: suggestion,
      center: proj.fromLonLat([
        suggestion.geoInformation.coordinates.longitude,
        suggestion.geoInformation.coordinates.latitude,
      ]),
      zoom: 16,
    })
    setManualCoords(false)
    setDroppedLocation(null)
    setSearchTerm(formattedAddress)
    setShowSuggestions(false)
  }

  const onForceSelection = () => {
    getSuggestions(searchTerm + SUGGESTION_SUFFIX, (suggestions) => {
      if (suggestions.length == 1) {
        selectAddress(suggestions[0])
      } else {
        setSuggestions(suggestions)
        setShowSuggestions(suggestions.length > 0)
      }
    })
  }

  const getSuggestions = (address: string, callback: (suggestions: AddressSuggestion[]) => void): void => {
    cancel?.cancel()
    cancel = Axios.CancelToken.source()

    if (address.trim().length !== 0) {
      const lang = language()
      Axios.get(
        `${geoServiceUrl}/api/geocode/by/here/autocomplete?query=${encodeURIComponent(
          address
        )}&maxresults=10&language=${lang}`,
        { withCredentials: true, cancelToken: cancel.token }
      ).then(
        (success) => {
          cancel = undefined
          const response: AddressSuggestionResponse = success.data
          const suggestions = response.suggestions

          return Promise.all(
            suggestions.map((suggestion) =>
              Axios.get(
                `${geoServiceUrl}/api/here/location/${encodeURIComponent(suggestion.locationId)}?language=${lang}`,
                { withCredentials: true }
              ).then((success) => {
                const locationResponse: LocationResponse = success.data
                const result: AddressSuggestion = {
                  ...suggestion,
                  address: resolveAddress(suggestion, locationResponse),
                  geoInformation: locationToGeoInformation(locationResponse),
                }

                return result
              })
            )
          ).then(
            (success) => callback(success),
            (error) => {
              if (!Axios.isCancel(error)) {
                callback([])
              }
            }
          )
        },
        (error) => {
          if (!Axios.isCancel(error)) {
            callback([])
          }
        }
      )
    }
  }

  const onSetPin = (location: Location) => {
    cancel?.cancel()
    setDroppedLocation(location)
    const lang = language()

    Axios.get(`${geoServiceUrl}/api/here/reverse?lng=${location.lng}&lat=${location.lat}&language=${lang}`, {
      withCredentials: true,
    }).then(
      (success) => updateSelectedAddressFromLocation(location, success.data),
      () => {}
    )
  }

  const updateSelectedAddressFromLocation = (location: Location, reverseAddresses: Address[]) => {
    if (reverseAddresses.length > 0) {
      setAddressDialogState({
        ...addressDialogState,
        addressSelection: {
          ...reverseAddresses[0],
          location,
        },
      })
      setManualCoords(true)
      setSearchTerm(formatAddress(reverseAddresses[0]))
    } else if (addressDialogState.addressSelection != null) {
      setAddressDialogState({
        ...addressDialogState,
        addressSelection: {
          ...addressDialogState.addressSelection,
          location,
        },
      })
      setManualCoords(true)
    }
  }

  const renderAssessmentQuality = () => {
    if (!quickAssessmentConfiguration)
      return (
        <>
          <div>{t.assessmentEntryDetails.quality}</div>
          <div>{"\u00A0★\u00A0"}</div>
        </>
      )
    const qualityStars = {
      [Exclusiveness.Standard]: "\u00A0★\u00A0",
      [Exclusiveness.High]: "\u00A0★★\u00A0",
      [Exclusiveness.Highest]: "\u00A0★★★\u00A0",
    }

    return <div>{qualityStars[quickAssessmentConfiguration.exclusiveness] || ""}</div>
  }

  const renderHouseOrApartment = () => {
    if (!quickAssessmentConfiguration) return <div>{t.assessmentEntryDetails.columns.usageType}</div>
    if (
      quickAssessmentConfiguration.usageType === "residentialRent" ||
      quickAssessmentConfiguration.usageType == "residentialSale"
    ) {
      const houseOrApartmentMapping = {
        house: t.header.priceCategories.house,
        apartment: t.header.priceCategories.apartment,
      }

      return houseOrApartmentMapping[quickAssessmentConfiguration.houseOrApartment] || ""
    }
    return <div></div>
  }

  const onStartAssessmentClick = () => {
    const availableModules = assessmentModulesAvailable(props.lanaScopes)
    const firstAvailableRelasModule = availableModules.length ? availableModules[0] : null

    if (addressDialogState.addressSelection && firstAvailableRelasModule) {
      const entryLabel = formatAddress(addressDialogState.addressSelection)

      const {
        houseOrApartment = INITIAL_CONFIGURATION_STATE.houseOrApartment,
        usageType = INITIAL_CONFIGURATION_STATE.usageType,
        exclusiveness = INITIAL_CONFIGURATION_STATE.exclusiveness,
        buildingYear = INITIAL_CONFIGURATION_STATE.buildingYear,
        area = INITIAL_CONFIGURATION_STATE.area,
        newBuilding = INITIAL_CONFIGURATION_STATE.newBuilding,
      } = quickAssessmentConfiguration || {}

      const usageTypeReconstructed = reconstructUsageType(houseOrApartment, usageType)

      void createSingleAssessment(
        {
          entryLabel,
          usageType: usageTypeReconstructed,
          droppedLocation,
          exclusiveness,
          address: addressDialogState.addressSelection,
          title: createAssessmentTitle(addressDialogState.addressSelection),
          year: buildingYear,
          area,
          newBuilding,
        },
        firstAvailableRelasModule,
        "quickstart"
      )
    }
  }

  return (
    <>
      <AssessmentEntryDetailEdit
        isOpen={isEditDialogOpen}
        toggle={() => {
          setIsEditDialogOpen(false)
        }}
        assessmentCreation={true}
        newAssessmentConfiguration={quickAssessmentConfiguration}
        setNewAssessmentConfiguration={setQuickAssessmentConfiguration}
      />
      <Grid
        columnSpec={"1fr min-content min-content 1fr"}
        colGap={40}
        backgroundColor={{ colorType: "lighter", color: "background" }}
        bordersColor={{ colorType: "light", color: "primary" }}
        padding={[16, 20, 12, 20]}
      >
        <FlexContainer direction={"column"} gap={8} padding={4}>
          <div className={styles.inputLabels}>{t.quickStart.newAddress}</div>
          <div className={showSetPinHint || addressOutsideOfGermany ? styles.addressInputError : styles.addressInput}>
            <Flex flexDirection={"column"} gap={12}>
              <AddressSuggestionAutoComplete
                displaySuggestion={(s: AddressSuggestion) => s.address.label || s.label}
                showSuggestions={showSuggestions}
                suggestions={suggestions}
                value={searchTerm}
                onValueChange={fetchSuggestions}
                selectSuggestion={(s) => s && selectAddress(s)}
                onFocus={() => suggestions && suggestions.length > 0 && setShowSuggestions(true)}
                toDoOnBlur={() => setShowSuggestions(false)}
                onForceSuggestion={onForceSelection}
                hint={"Type in Address"}
              />
            </Flex>
          </div>
        </FlexContainer>
        <Grid columns={1} padding={4} gap={4}>
          <div className={styles.inputLabels}>{t.quickStart.propertyDetails}</div>
          <Grid columnSpec={"min-content auto"} colGap={12}>
            <div
              className={
                isEditDialogOpen || searchTerm === ""
                  ? cx(styles.details, css({ color: getThemeColorVar("disabled", "dark") }))
                  : cx(styles.details, css({ color: headerTextColor }))
              }
            >
              <FlexContainer direction={"row"} md-align={"center"} gap={4}>
                <div>
                  {quickAssessmentConfiguration?.usageType
                    ? t.quickStart.usageType[quickAssessmentConfiguration?.usageType]
                    : t.assessmentEntryDetails.usageTypesTitle}
                </div>
                <HorizontalDivider height={12} />
                <div>
                  {quickAssessmentConfiguration?.buildingYear
                    ? `${t.quickStart.yearOfConstructionAbbreviation}: ${quickAssessmentConfiguration?.buildingYear}`
                    : t.quickStart.yearOfConstruction}
                </div>
                <HorizontalDivider height={12} />
                <div>{`${quickAssessmentConfiguration?.area || ""} \u00A0m²`}</div>
              </FlexContainer>
              <FlexContainer direction={"row"} md-align={"center"} gap={4}>
                {renderAssessmentQuality()}
                <HorizontalDivider height={12} />
                {renderHouseOrApartment()}
              </FlexContainer>
            </div>
            <div style={{ alignContent: "end" }}>
              <Tooltip placement="bottom" tooltip={t.header.changeAssessmentEntryDetails}>
                <a onClick={() => setIsEditDialogOpen(true)}>
                  <Icon name={"edit"} fontSize={24} />
                </a>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        <div style={{ padding: "4px", alignContent: "end" }}>
          <Button
            type={"primary"}
            id={"quickStartAssessmentButton"}
            onClick={onStartAssessmentClick}
            disabled={
              isEditDialogOpen || showSetPinHint || addressOutsideOfGermany || !addressDialogState.addressSelection
            }
          >
            {t.quickStart.startAssessment}
          </Button>
        </div>
        <div style={{ alignContent: "end", padding: "4px" }}>
          {showSetPinHint && <Panel color="negative">{t.quickStart.ambiguousAddressMessage}</Panel>}
          {addressOutsideOfGermany && <Panel color="negative">{t.quickStart.outsideOfGermanyAddress}</Panel>}
        </div>
      </Grid>
      <AddressGeoView
        address={addressDialogState.addressSelection?.location}
        onSetPin={onSetPin}
        isPrivateDataAccessible={props.isPrivateDataAccessible}
        privatePoiCategories={props.privatePoiCategories}
        privateDataSettings={props.privateDataSettings}
        getPrivateDataCategories={props.getPrivateDataCategories}
        updateSelectedPrivateDataCategories={props.updateSelectedPrivateDataCategories}
        getPrivatePoisFromCategories={props.getPrivatePoisFromCategories}
        updatePrivatePoisToShow={props.updatePrivatePoisToShow}
      />
    </>
  )
}

export default NewAssessmentConfiguration
