import { AppLocationConfigItem, AppLocationConfig } from "./app-location-types"
import { menuTranslations } from "../i18n"

const submenuTranslation = menuTranslations().apps

const adminMenuConf: AppLocationConfigItem<"admin"> = [
  [
    {
      app: "admin",
      entry: "companySettings",
      icon: "settings_config",
      text: submenuTranslation.admin.submenus.companySettings,
      service: "lana",
      path: "company/settings",
      scopeRequirement: { service: "companyadmin", scope: "CA" },
    },
    {
      app: "admin",
      entry: "companyUsers",
      icon: "user_group",
      text: submenuTranslation.admin.submenus.companyUsers,
      service: "lana",
      path: "company/members",
      scopeRequirement: { service: "companyadmin", scope: "CA" },
    },
    {
      app: "admin",
      entry: "products",
      icon: "usage",
      text: submenuTranslation.admin.submenus.products,
      service: "lana",
      path: "company/products-statistics",
      scopeRequirement: { service: "companyadmin", scope: "CA" },
    },
  ],
  [
    {
      app: "admin",
      entry: "apiAdmin",
      icon: "key",
      text: submenuTranslation.admin.submenus.apiAdmin,
      service: "lana",
      path: "company/api-admin",
      scopeRequirement: { service: "api", scope: "CA" },
    },
  ],
]

const accountMenuConf: AppLocationConfigItem<"account"> = [
  [
    {
      app: "account",
      entry: "profile",
      icon: "profile_male",
      text: submenuTranslation.account.submenus.profile,
      service: "lana",
      path: "user/profile",
    },
    {
      app: "account",
      entry: "security",
      icon: "security",
      text: submenuTranslation.account.submenus.security,
      service: "lana",
      path: "user/security",
    },
  ],
]

const valuationMenuConf: AppLocationConfigItem<"valuation"> = [
  [
    {
      app: "valuation",
      entry: "home",
      icon: "home",
      text: submenuTranslation.valuation.submenus["home"],
      service: "valuation",
      path: "",
    },
  ],
]

const locationAnalyticsMenuConf: AppLocationConfigItem<"locationAnalytics"> = [
  [
    {
      app: "locationAnalytics",
      entry: "locationAssessment",
      module: "ratings",
      icon: "rating_empty",
      text: submenuTranslation.locationAnalytics.submenus["locationAssessment"].modules["ratings"],
      service: "lana",
      path: "ratings",
      scopeRequirement: {
        service: "lana",
        scope: {
          scopeConditions: "AND_NOT",
          requiredScope: "RM",
          absentScopes: ["A"],
        },
      },
      singleAppNavigation: true,
    },
    {
      app: "locationAnalytics",
      entry: "locationAssessment",
      module: "comparables",
      icon: "price_trend",
      text: submenuTranslation.locationAnalytics.submenus["locationAssessment"].modules["comparables"],
      service: "lana",
      path: "comparables",
      scopeRequirement: {
        service: "lana",
        scope: {
          scopeConditions: "AND_NOT",
          requiredScope: "C",
          absentScopes: ["A"],
        },
      },
      singleAppNavigation: true,
    },
    {
      app: "locationAnalytics",
      entry: "locationAssessment",
      module: "poiExplorer",
      icon: "marker",
      text: submenuTranslation.locationAnalytics.submenus["locationAssessment"].modules["poiExplorer"],
      service: "lana",
      path: "poiexplorer",
      scopeRequirement: {
        service: "lana",
        scope: {
          scopeConditions: "AND_NOT",
          requiredScope: "POI",
          absentScopes: ["A"],
        },
      },
      singleAppNavigation: true,
    },
    {
      app: "locationAnalytics",
      entry: "locationAssessment",
      module: "fundamentalData",
      icon: "monitor",
      text: submenuTranslation.locationAnalytics.submenus["locationAssessment"].modules["fundamentalData"],
      service: "lana",
      path: "fundamentalData",
      scopeRequirement: {
        service: "lana",
        scope: {
          scopeConditions: "AND_NOT",
          requiredScope: "FD",
          absentScopes: ["A"],
        },
      },
      singleAppNavigation: true,
    },
    {
      app: "locationAnalytics",
      entry: "locationAssessment",
      module: "specialMaps",
      icon: "map",
      text: submenuTranslation.locationAnalytics.submenus["locationAssessment"].modules["specialMaps"],
      service: "lana",
      path: "specialmaps",
      scopeRequirement: {
        service: "lana",
        scope: {
          scopeConditions: "AND_NOT",
          requiredScope: "SM",
          absentScopes: ["A"],
        },
      },
      singleAppNavigation: true,
    },
    {
      app: "locationAnalytics",
      entry: "locationAssessment",
      icon: "indication_location",
      text: submenuTranslation.locationAnalytics.submenus["locationAssessment"].name,
      service: "lana",
      path: "assessments",
      scopeRequirement: { scope: "A", service: "lana" },
      modules: [
        {
          module: "dashboard",
          scopeRequirement: { scope: "A", service: "lana" },
          icon: "dashboard",
          text: submenuTranslation.locationAnalytics.submenus["locationAssessment"].modules["dashboard"],
          path: "dashboard",
        },
        {
          module: "prices",
          scopeRequirement: { scope: "A", service: "lana" },
          icon: "prices_default",
          text: submenuTranslation.locationAnalytics.submenus["locationAssessment"].modules["prices"],
          path: "prices",
        },
        {
          module: "comparables",
          scopeRequirement: { scope: "C", service: "lana" },
          icon: "price_trend",
          text: submenuTranslation.locationAnalytics.submenus["locationAssessment"].modules["comparables"],
          path: "comparables",
        },
        {
          module: "poiExplorer",
          scopeRequirement: { scope: "POI", service: "lana" },
          icon: "marker",
          text: submenuTranslation.locationAnalytics.submenus["locationAssessment"].modules["poiExplorer"],
          path: "poiexplorer",
        },
        {
          module: "fundamentalData",
          scopeRequirement: { scope: "FD", service: "lana" },
          icon: "monitor",
          text: submenuTranslation.locationAnalytics.submenus["locationAssessment"].modules["fundamentalData"],
          path: "fundamentalData",
        },
        {
          module: "rentindex",
          scopeRequirement: { scope: "A", service: "lana" },
          icon: "prices_rent_index",
          text: submenuTranslation.locationAnalytics.submenus["locationAssessment"].modules["rentindex"],
          path: "rentindex",
        },
        {
          module: "assessment",
          scopeRequirement: { scope: "A", service: "lana" },
          icon: "statistics",
          text: submenuTranslation.locationAnalytics.submenus["locationAssessment"].modules["assessment"],
          path: "assessments",
        },
        {
          module: "ratings",
          scopeRequirement: { scope: "RM", service: "lana" },
          icon: "rating_empty",
          text: submenuTranslation.locationAnalytics.submenus["locationAssessment"].modules["ratings"],
          path: "ratings",
        },
        {
          module: "specialMaps",
          scopeRequirement: { scope: "SM", service: "lana" },
          icon: "map",
          text: submenuTranslation.locationAnalytics.submenus["locationAssessment"].modules["specialMaps"],
          path: "specialmaps",
        },
      ],
      singleAppNavigation: true,
    },
    {
      app: "locationAnalytics",
      entry: "profileManager",
      icon: "scoremanager",
      text: submenuTranslation.locationAnalytics.submenus["profileManager"],
      service: "lana",
      path: "profiles",
      scopeRequirement: { scope: "P", service: "lana" },
      singleAppNavigation: true,
      toolsPopup: true,
    },
    {
      app: "locationAnalytics",
      entry: "locationExplorer",
      icon: "my_location",
      text: submenuTranslation.locationAnalytics.submenus["locationExplorer"],
      service: "lana",
      path: "explorer",
      scopeRequirement: {
        service: "lana",
        scope: {
          scopeConditions: "AND_NOT",
          requiredScope: "E",
          absentScopes: ["LS", "RM"],
        },
      },
      singleAppNavigation: true,
      toolsPopup: true,
    },
    {
      app: "locationAnalytics",
      entry: "locationSelector",
      icon: "my_location",
      text: submenuTranslation.locationAnalytics.submenus["locationSelector"],
      service: "lana",
      path: "locationselector",
      scopeRequirement: { scope: "LS", service: "lana" },
      singleAppNavigation: true,
      toolsPopup: true,
    },
    {
      app: "locationAnalytics",
      entry: "ratingManager",
      icon: "my_location",
      text: submenuTranslation.locationAnalytics.submenus["ratingManager"],
      service: "lana",
      path: "rating-manager",
      scopeRequirement: { scope: "RM", service: "lana" },
      singleAppNavigation: true,
      toolsPopup: true,
    },
    {
      app: "locationAnalytics",
      entry: "privateData",
      icon: "private_poi",
      text: submenuTranslation.locationAnalytics.submenus["privateData"],
      service: "lana",
      path: "privatedata",
      scopeRequirement: { scope: "PD", service: "lana" },
      singleAppNavigation: true,
      toolsPopup: true,
    },
  ],
]

export const appLocationConfig: AppLocationConfig = {
  admin: adminMenuConf,
  account: accountMenuConf,
  valuation: valuationMenuConf,
  locationAnalytics: locationAnalyticsMenuConf,
}
