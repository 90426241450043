import { Services } from "../models/services"
import { getCurrentDomain, isLocalhost } from "../../shared/helper/domain"

export function betterServiceUrl(service?: Services, path?: string): string {
  const currentDomain = getCurrentDomain(window.location)

  let basePath = service ? `//${service}.${currentDomain}` : `//${currentDomain}`

  if (isLocalhost(window.location)) {
    basePath = `//${currentDomain}`
  }

  if (path) {
    return `${basePath}/${path}`
  }

  return basePath
}
