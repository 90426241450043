declare namespace window {
  const twentyone: {
    language: string
    reportAssetsUrl: string
    authToken: string
  }
}

export const language = window.twentyone.language
export const reportAssetsUrl = window.twentyone.reportAssetsUrl
export const authToken = window.twentyone.authToken
