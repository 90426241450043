import * as React from "react"
import { useEffect } from "react"
import { translations } from "../i18n"
import { LoginData } from "../models/login-data"
import { LoginError } from "./login-error"
import { resetGlobalStore, useAppSelector } from "../../relas/store"
import { loginStart } from "../reducers/login-slice"
import { RevoLastPathCookie } from "../../menu/revo-loggedin-menu"
import { domainName, loginServiceUrl } from "../../app_config"
import { navigateToDefaultPage } from "../../relas/router"
import { updateAppScopes, updateUserData } from "../../relas/user-slice"
import { scopesFromCookie } from "../../menu/util/scorefromcookies"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"
import TextField from "../../shared/components/textfield"
import Button from "../../shared/components/button"
import Form from "../../shared/components/form"
import { LoginErrors } from "../models/login-errors"
import { toCanvas } from "qrcode"
import { TwoFactorMandatorySetupPayload } from "../../user-account/models/two-factor"
import { setupMandatoryTwoFactor } from "../../user-account/user-profile-slice"
import Panel from "../../shared/components/panel"
import { getCookieValue } from "../../shared/helper/cookies"
import { navigateTo } from "../../shared/reducers/navigation-slice"
import { NavigationPage } from "../../shared/actions/navigation"
import { Grid } from "../../shared/components/restyle-grid/grid"
import { css } from "emotion"
import { BorderBottom } from "@21re/bp-style"
import { OtpForm } from "./otp-form"

type Props = {
  resetStateOnUserChange?: boolean
  is24AuthError?: string
}

const styles = {
  buttonIS24: css({
    fontWeight: "bold",
    display: "inline-block",
    borderRadius: "4px 4px 4px 4px",
    cursor: "pointer",
    textAlign: "center",
    whiteSpace: "nowrap",
    outline: "none",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "7px 16px",
    borderWidth: "1px",
    borderStyle: "solid",
    backgroundColor: "#00ffd0",
    borderColor: "#00ffd0",
    paddingLeft: "7px",
  }),
}

export function onLoginDone(
  user: User | null,
  lastAuthorisedUser: User | undefined,
  resetStateOnUserChange: boolean | undefined
) {
  if (user) {
    if (user.backofficeAdmin) {
      window.location.href = `https://backoffice.${domainName}/`
      return
    }

    if (lastAuthorisedUser) {
      if (resetStateOnUserChange && lastAuthorisedUser.id !== user.id) {
        // we are logging in as a different user, so we need to clear the scopes
        resetGlobalStore()
        updateUserData(user)
      }
    }

    const scopesFromCookies = scopesFromCookie()

    updateAppScopes(scopesFromCookies)

    const cookiePath = getCookieValue(RevoLastPathCookie)

    const redirectLinks = cookiePath ? JSON.parse(cookiePath) : {}
    if (redirectLinks["customer/" + user.id]) {
      const redirectLink = redirectLinks["customer/" + user.id]
      if ("service" in redirectLink && "path" in redirectLink) {
        // old format with absolute path, service is irrelevant as it must be always the same domain
        window.location.href = "/" + redirectLink["path"]
        return
      }
    }

    void navigateToDefaultPage().catch(() => {})
  }
}

export const LoginIS24Form = ({ resetStateOnUserChange, is24AuthError }: Props) => {
  const loginData = useAppSelector((state) => state.login.form)
  const lastAuthorisedUser = useAppSelector((state) => state.user.user)
  const loginError = useAppSelector((state) => state.login.loginError)
  const loginOtpRequired = useAppSelector((state) => state.login.loginOtpRequired)
  const twoFactorSetup = useAppSelector((state) => state.login.login2FASetup)
  const [qrState, setQrState] = React.useState<"rendered" | "error" | undefined>()
  const [form, setForm] = React.useState<LoginData>(loginData)
  const translate = React.useMemo(() => translations(), [])

  const setupTwoFactorInProgress = useAppSelector((state) => state.userProfile.setupTwoFactorInProgress)
  const setupTwoFactorError = useAppSelector((state) => state.userProfile.setupTwoFactorError)

  const [otpCode, setOtpCode] = React.useState("")

  useEffect(() => {
    if (twoFactorSetup) {
      populateQrCode()
    }
  }, [twoFactorSetup])

  const handleLogin = () => {
    // need to do this in case browser autofilled the fields (with chrome we don't get a change event)
    const loginForm: LoginData = {
      username: (document.getElementById("21re-email") as HTMLInputElement).value,
      password: (document.getElementById("21re-password") as HTMLInputElement).value,
      trackingAllowed: form.trackingAllowed,
      otpCode: form.otpCode,
    }

    loginStart(loginForm)
      .then((newUser) => onLoginDone(newUser, lastAuthorisedUser, resetStateOnUserChange))
      .catch(() => {})
  }

  const onChangeUsername = (username: string) => {
    setForm({ ...form, username })
  }

  const onChangePassword = (password: string) => {
    setForm({ ...form, password })
  }

  const populateQrCode = () => {
    if (twoFactorSetup) {
      toCanvas(document.getElementById("qr-canvas"), twoFactorSetup.sharedSecretCandidateChartURL, (error: any) => {
        if (error) {
          setQrState("error")
        } else {
          setQrState("rendered")
        }
      })
    }
  }

  const renderQRCode = () => {
    return (
      <div>
        <canvas id="qr-canvas"></canvas>
        {qrState === "error" ? <Panel color="negative">{translate.twoFactorSetup.qrRenderError}</Panel> : null}
      </div>
    )
  }

  const finishOTPSetup = () => {
    const formData: TwoFactorMandatorySetupPayload = {
      login: form.username ?? "",
      password: form.password ?? "",
      twoFactorSetup: {
        sharedSecret: twoFactorSetup?.sharedSecretCandidate ?? "",
        generatedCode: parseInt(otpCode),
      },
    }

    void setupMandatoryTwoFactor(formData).then((success) => {
      if (success) {
        loginStart(form)
          .then((newUser) => onLoginDone(newUser, lastAuthorisedUser, resetStateOnUserChange))
          .catch(() => {})
      }
    })
  }

  const handleIS24Login = () => {
    window.location.href = `${loginServiceUrl}/oauth/login`
  }

  return (
    <FlexContainer spaceBetween="xxl" direction="column" padding={[0, 0, 8, 0]} gap={4}>
      {loginError !== LoginErrors.mandatory2FASetup && !loginOtpRequired && (
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            handleLogin()
          }}
        >
          <FlexContainer spaceBetween="xxl" direction="column">
            <div style={{ padding: "0 24px" }}>
              <TextField
                id="21re-email"
                autoFocus={true}
                label={translate.login.usernameLabel}
                onValueChange={onChangeUsername}
                value={form.username || ""}
                required={true}
              />
            </div>
            <FlexContainer padding={[0, 24]} spaceBetween="sm" direction="column">
              <TextField
                id="21re-password"
                label={translate.login.passwordLabel}
                type="password"
                onValueChange={onChangePassword}
                value={form.password || ""}
                required={true}
              />
              <a
                id="21re-password-forgotten-link"
                href="/login/forgot-password"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  navigateTo({ name: NavigationPage.loginForgotPasswordRequest })
                }}
              >
                {translate.login.passwordForgotten}
              </a>
            </FlexContainer>
            <FlexContainer padding={[0, 24]} direction="column" gap={4}>
              <Button id="21re-login-button" type="primary" icon="login">
                {translate.login.loginButton}
              </Button>
              <LoginError />
            </FlexContainer>

            <div style={{ textAlign: "center", padding: "0 24px" }}>
              <a id="21re-registration-link" href={`https://21re.de/${translate.links.relativeContactFormLink}`}>
                {translate.login.registration}
              </a>
            </div>
          </FlexContainer>
        </Form>
      )}

      {loginError === LoginErrors.mandatory2FASetup && (
        <FlexContainer direction="column" spaceBetween="md" padding={[0, 24]}>
          <Panel color="negative">{translate.twoFactorSetup.mandatorySetup}</Panel>
          <div style={{ textAlign: "center" }}>{renderQRCode()}</div>
          <div>
            {translate.twoFactorSetup.secretLabel}: {twoFactorSetup?.sharedSecretCandidate}
          </div>
          <TextField
            value={otpCode}
            onValueChange={(t) => setOtpCode(t.replace(new RegExp("\\D"), "").substring(0, 6))}
            label={translate.twoFactorSetup.generatedCodeLabel}
            required={true}
          />
          {setupTwoFactorError && <Panel color="negative">{translate.twoFactorSetup.submittedErrorText}</Panel>}
          <div>
            <Button
              type="primary"
              icon="login"
              disabled={otpCode.length < 6 || setupTwoFactorInProgress}
              onClick={finishOTPSetup}
            >
              {translate.twoFactorSetup.submitLabel}
            </Button>
          </div>
        </FlexContainer>
      )}

      {loginOtpRequired && <OtpForm />}

      <Grid columns={3} columnSpec={"1fr min-content 1fr"} padding={[24, 0]}>
        <Grid rowSpec={"1fr 1fr"} columns={1}>
          <BorderBottom />
        </Grid>

        <div style={{ padding: "8px", fontWeight: "bold", textTransform: "uppercase" }}>{translate.login.or}</div>
        <Grid rowSpec={"1fr 1fr"} columns={1}>
          <BorderBottom />
        </Grid>
      </Grid>
      <FlexContainer spaceBetween="md" direction="column" padding={[0, 24]} gap={8}>
        <button className={styles.buttonIS24} onClick={() => handleIS24Login()}>
          {translate.login.registerWithIS24}
        </button>
        {is24AuthError && (
          <Panel color="negative" title={translate.login.IS24LoginErrorTitle}>
            {translate.login.IS24LoginError}
          </Panel>
        )}
      </FlexContainer>
    </FlexContainer>
  )
}
