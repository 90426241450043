import { Chart, registerables } from "chart.js"
import Annotation from "chartjs-plugin-annotation"

export interface AppConfig {
  DOMAIN_NAME: string
  COMPARABLES_SERVICE_URL: string
  GEO_SERVICE_URL: string
  RENTINDEX_SERVICE_URL: string
  LANA_API_URL: string
  tileServerUrl?: string
  GMAPS_API_KEY: string
  GMAPS_LIBRARIES?: string[]
  DASHBOARD_SERVICE_URL: string
  DATA_DICTIONARY_URL: string
  COMPANYADMIN_SERVICE_URL: string
  LOGIN_SERVICE_URL: string
  INVOICE_SERVICE_URL: string
  TEAS_API_SERVICE_URL: string
  REGISTRATION_SERVICE_URL: string
}

export declare namespace window {
  const twentyone: AppConfig
}

Chart.register(...registerables)
Chart.register(Annotation)

export const domainName = window.twentyone.DOMAIN_NAME

export const tileServerUrl = window.twentyone.tileServerUrl || `https://assets.${window.twentyone.DOMAIN_NAME}`
export const geoServiceUrl = window.twentyone.GEO_SERVICE_URL
export const rentIndexUrl = window.twentyone.RENTINDEX_SERVICE_URL
export const mapProxyUrl = `https://mapproxy.${window.twentyone.DOMAIN_NAME}`
export const comparablesServiceUrl = window.twentyone.COMPARABLES_SERVICE_URL

export const dashboardServiceUrl = window.twentyone.DASHBOARD_SERVICE_URL

export const companyadminServiceUrl = window.twentyone.COMPANYADMIN_SERVICE_URL

export const invoiceServiceUrl = window.twentyone.INVOICE_SERVICE_URL

export const apiServiceUrl = window.twentyone.TEAS_API_SERVICE_URL

export const lanaApiUrl = window.twentyone.LANA_API_URL

export const registrationServiceUrl = window.twentyone.REGISTRATION_SERVICE_URL
export const googleMapsApiKey = window.twentyone.GMAPS_API_KEY
export const googleMapsApiLibraries = window.twentyone.GMAPS_LIBRARIES || []

export const loginServiceUrl = window.twentyone.LOGIN_SERVICE_URL
