import * as React from "react"
import { NewPasswordInput } from "./new-password-input"
import { translations } from "../i18n"
import Card from "../../shared/components/card"
import Grid from "../../shared/components/restyle-grid/grid"

export const LoginPasswordInvite = (props: { inviteToken: string }) => {
  const translate = React.useMemo(() => translations(), [])

  return (
    <div style={{ marginTop: "200px" }}>
      <Grid columnSpec="1fr 50% 1fr">
        <div />
        <Card header={translate.setPassword.setPasswordHeader}>
          <p>{translate.setPassword.setPasswordInfo}</p>
          <NewPasswordInput
            passwordLabel={translate.setPassword.passwordLabel}
            confirmPasswordLabel={translate.setPassword.confirmPasswordLabel}
            submitPasswordLabel={translate.setPassword.submitPassword}
            submitSuccessMessage={translate.setPassword.submitSuccess}
            resetToken={props.inviteToken}
          />
        </Card>
        <div />
      </Grid>
    </div>
  )
}
