import { FC, default as React, SetStateAction, useMemo, useEffect } from "react"
import { HTMLSelect } from "@blueprintjs/core"
import { AssessmentPriceType, RentPriceTypes, SalePriceTypes } from "../../models/assessment"
import { translations } from "../../i18n"
import { DataSource } from "../../models/comparables"
import { getThemeColor } from "../../../shared/helper/color"

interface Props {
  priceType: AssessmentPriceType
  dataSource: DataSource
  onPriceTypeChange: (newValue: SetStateAction<AssessmentPriceType>) => void
}

export const PriceTypeDropdown: FC<Props> = ({ priceType, onPriceTypeChange, dataSource }) => {
  const translate = useMemo(translations, [translations]).assessmentEntryDetails

  const options = useMemo(() => {
    if (dataSource === "online-immoScout") {
      return [
        {
          value: RentPriceTypes.residential,
          label: translate.priceType.rent + " / " + translate.usageType.residential,
        },
        { value: RentPriceTypes.office, label: translate.priceType.rent + " / " + translate.usageType.office },
        { value: RentPriceTypes.retail, label: translate.priceType.rent + " / " + translate.usageType.retail },
        {
          value: SalePriceTypes.residential,
          label: translate.priceType.sale + " / " + translate.usageType.residential,
        },
      ]
    } else {
      return [
        {
          value: RentPriceTypes.residential,
          label: translate.priceType.rent + " / " + translate.usageType.residential,
        },
        { value: RentPriceTypes.office, label: translate.priceType.rent + " / " + translate.usageType.office },
        { value: RentPriceTypes.retail, label: translate.priceType.rent + " / " + translate.usageType.retail },
        { value: RentPriceTypes.hall, label: translate.priceType.rent + " / " + translate.usageType.hall },
        {
          value: SalePriceTypes.residential,
          label: translate.priceType.sale + " / " + translate.usageType.residential,
        },
      ]
    }
  }, [dataSource])

  useEffect(() => {
    if (dataSource === "online-immoScout" && priceType === RentPriceTypes.hall) {
      onPriceTypeChange(RentPriceTypes.residential)
    }
  }, [dataSource])

  return (
    <HTMLSelect
      fill
      style={{ background: "white", marginBottom: 8, borderRadius: 4 }}
      value={priceType}
      iconProps={{
        icon: "chevron-down",
        iconSize: 16,
        color: getThemeColor("primary", "default").toString(),
      }}
      options={options}
      onChange={(e) => onPriceTypeChange(e.target.value as AssessmentPriceType)}
    />
  )
}
