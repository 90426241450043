import { Checkbox, Collapse } from "@blueprintjs/core"
import { ScrollBox } from "../../../shared/components/ui/scroll-box"
import React, { useEffect, useMemo, useState } from "react"
import { translations } from "../../i18n"
import { css } from "emotion"
import { GoogleMapsPOICategory, GoogleMapsThematicGroup } from "../../models/assessment"
import { trackUsageEvent } from "../../../utils/usage-tracking"
import { NUMBERED_TEXT_SEARCH_IDS, TextSearchIDsToTitle } from "../../../shared/models/poi-explorer"
import { fetchGoogleMapsCategories } from "../../reducers/google-maps-slice"
import { useAppSelector } from "../../../relas/store"
import { getThemeColor, getThemeColorVar } from "../../../shared/helper/color"
import Icon from "../../../shared/components/icon"

const styles = {
  thematicGroup: css({
    display: "flex",
    padding: "8px 16px",
    borderBottom: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
    cursor: "pointer",
    backgroundColor: `${getThemeColorVar("background", "lighter")}`,
  }),
  categoriesList: css({
    borderBottom: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
  }),
  category: css({
    display: "flex",
    gap: "4px",
    padding: "4px 8px 4px 16px",
  }),
}

interface Props {
  selectedPOICategories: Set<string>
  setSelectedPOICategories: (selectedCategories: Set<string>) => void
  thematicGroupsCollapsed?: boolean
  selectedPOIGroup?: string
  readonly?: boolean
  textSearch: TextSearchIDsToTitle | null
  selectionLimit?: number
  trackPOICategorySelection?: boolean
}

export const localPublicTransportCategory = new Set([
  "bus_station",
  "subway_station",
  "transit_station",
  "light_rail_station",
])

const PoiCategoriesList = ({
  selectedPOICategories,
  setSelectedPOICategories,
  thematicGroupsCollapsed,
  selectedPOIGroup,
  readonly,
  textSearch,
  selectionLimit,
  trackPOICategorySelection,
}: Props) => {
  const t = useMemo(translations, [translations])
  const assessmentEntry = useAppSelector((state) => state.assessment.currentAssessmentEntry)
  const googleMapsData = useAppSelector((state) => state.googleMapsData)
  const userDefinedPOIGroups = useAppSelector((state) => state.POIExplorerApp.userDefinedPOIGroups)
  const [collapsedThematicGroups, setCollapsedThematicGroups] = useState<Set<string>>(new Set())
  const [categoriesToShowArr, setCategoriesToShowArr] = useState<GoogleMapsPOICategory[]>(
    googleMapsData.poiCategories && selectedPOIGroup
      ? googleMapsData.poiCategories
          .map(([, v]) => v)
          .reduce((flatten, arr) => [...flatten, ...arr])
          .filter((cat) => selectedPOICategories.has(cat.id))
          .sort((a, b) => t.pickTranslation(a.title).localeCompare(t.pickTranslation(b.title)))
      : []
  )

  let hasTextSearchRendered = false

  useEffect(() => {
    if (!googleMapsData.poiCategoriesLoadError && !googleMapsData.poiCategories && !googleMapsData.poiThematicGroups)
      void fetchGoogleMapsCategories()
  }, [])

  useEffect(() => {
    setCategoriesToShowArr(
      googleMapsData.poiCategories && selectedPOIGroup
        ? googleMapsData.poiCategories
            .map(([, v]) => v)
            .reduce((flatten, arr) => [...flatten, ...arr])
            .filter((cat) =>
              cat.id === "local_public_transport"
                ? selectedPOICategories.has("bus_station")
                : selectedPOICategories.has(cat.id) && !localPublicTransportCategory.has(cat.id)
            )
            .sort((a, b) => t.pickTranslation(a.title).localeCompare(t.pickTranslation(b.title)))
        : []
    )
  }, [selectedPOIGroup, userDefinedPOIGroups])

  useEffect(() => {
    const collapsedGroups: Set<string> = new Set()

    if (thematicGroupsCollapsed) {
      googleMapsData.poiCategories?.forEach(([theme, categoriesByTheme]) => {
        collapsedGroups.add(theme)
        selectedPOICategories.forEach((selectedCategory) => {
          if (categoriesByTheme.find((category) => category.id === selectedCategory)) collapsedGroups.delete(theme)
        })
      })
      setCollapsedThematicGroups(collapsedGroups)
    }
  }, [])

  const onArrowClick = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>, name: string) => {
    ev.stopPropagation()
    ev.preventDefault()
    const c = new Set(collapsedThematicGroups)
    collapsedThematicGroups.has(name) ? c.delete(name) : c.add(name)
    setCollapsedThematicGroups(c)
  }

  const onCategoryCheck = (_: React.BaseSyntheticEvent, id: string) => {
    const categoriesSet = new Set(selectedPOICategories)
    assessmentEntry &&
      trackPOICategorySelection &&
      (id === "local_public_transport" ? !selectedPOICategories.has("bus_station") : !selectedPOICategories.has(id)) &&
      trackUsageEvent("POI_EXPLORER_SELECT_CATEGORY", assessmentEntry.address, id)

    if (id === "local_public_transport") {
      if (categoriesSet.has("bus_station")) {
        for (const item of localPublicTransportCategory) {
          categoriesSet.delete(item)
        }
      } else {
        for (const item of localPublicTransportCategory) {
          categoriesSet.add(item)
        }
      }
    } else categoriesSet.has(id) ? categoriesSet.delete(id) : categoriesSet.add(id)
    setSelectedPOICategories(categoriesSet)
  }

  const renderPoiCategory = (category: GoogleMapsPOICategory) => {
    const categorySelected =
      category.id === "local_public_transport"
        ? selectedPOICategories.has("bus_station")
        : selectedPOICategories.has(category.id)
    return readonly === true ? (
      categorySelected ? (
        <div key={category.id} className={styles.category}>
          {t.pickTranslation(category.title)}
        </div>
      ) : null
    ) : (
      <div className={styles.category} key={`checkbox-selection-div-${category.id}`}>
        <Checkbox
          style={{ whiteSpace: "nowrap", marginBottom: 0 }}
          checked={categorySelected}
          disabled={selectionLimit ? !categorySelected && selectedPOICategories.size > selectionLimit : false}
          id={"category-checkbox-" + category.id}
          onChange={(ev) => onCategoryCheck(ev, category.id)}
          key={"checkbox-selection-" + category.id}
        >
          {t.pickTranslation(category.title)}
        </Checkbox>
      </div>
    )
  }

  const renderTextSearch = () => {
    if (textSearch !== null && !hasTextSearchRendered) {
      const textSearches = NUMBERED_TEXT_SEARCH_IDS.reduce<string[]>((acc, id) => {
        if (selectedPOICategories.has(id)) {
          const searchedTerm = textSearch[id]
          searchedTerm && acc.push(searchedTerm)
        }
        return acc
      }, []).sort((a, b) => a.localeCompare(b))

      hasTextSearchRendered = true

      return (
        <>
          {textSearches.map((textSearch) => (
            <div key="textSearch" className={styles.category}>
              {textSearch}
            </div>
          ))}
        </>
      )
    }
    return null
  }

  const renderedCategories = (group: GoogleMapsThematicGroup) => {
    const cats = googleMapsData.poiCategories ? new Map(googleMapsData.poiCategories) : undefined

    return (
      cats &&
      cats.has(group.groupName) &&
      cats
        .get(group.groupName)!
        .filter((category: GoogleMapsPOICategory) => !localPublicTransportCategory.has(category.id))
        .map((cat) => renderPoiCategory(cat))
    )
  }

  return (
    <ScrollBox>
      {!selectedPOIGroup &&
        googleMapsData.poiThematicGroups &&
        googleMapsData.poiThematicGroups.map(([, group]) => (
          <div key={`thematic-group-div-${group.groupName}`}>
            {readonly !== true && (
              <div
                className={styles.thematicGroup}
                onClick={(ev) => {
                  onArrowClick(ev, group.groupName)
                }}
              >
                <b>{t.pickTranslation(group.title)}</b>
                <a style={{ marginLeft: "auto" }}>
                  <Icon
                    fontSize={20}
                    name={collapsedThematicGroups.has(group.groupName) ? "arrow_drop_up" : "arrow_drop_down"}
                  />
                </a>
              </div>
            )}

            {readonly === true ? (
              <>
                {renderTextSearch()}
                {renderedCategories(group)}
              </>
            ) : (
              <div className={styles.categoriesList}>
                <Collapse isOpen={!collapsedThematicGroups.has(group.groupName)}>{renderedCategories(group)}</Collapse>
              </div>
            )}
          </div>
        ))}
      {selectedPOIGroup && categoriesToShowArr?.map((cat) => renderPoiCategory(cat))}
    </ScrollBox>
  )
}

export default PoiCategoriesList
