import * as React from "react"
import { CHILD_PROFILE_PREFIX, Profile } from "../models/profile"
import { translations } from "../i18n"
import { css } from "emotion"
import { PropsWithChildren, useMemo, useState } from "react"
import { BorderBottom } from "../../shared/components/ui/border-bottom"
import { Flex } from "../../shared/components/ui/flex"
import { FlexItem } from "../../shared/components/ui/flex-item"
import { Grid } from "../../shared/components/ui/grid"
import { GridItem } from "../../shared/components/ui/grid-item"
import { Popup } from "../../shared/components/ui/popup"
import { LANA_AGS_NODES, LANA_CELL_NODES } from "../../shared/smartdata-products/smartdata"
import { useAppSelector } from "../../relas/store"
import Button from "../../shared/components/button"
import { getThemeColorVar } from "../../shared/helper/color"
import Icon from "../../shared/components/icon"

interface OwnProps {
  profileId: string
}

const treeContainer = css({
  overflowY: "auto",
  maxHeight: "350px",
})

const treeLeafClass = css({
  padding: "8px 16px 8px 16px",
  margin: 0,
  fontSize: "16px",
  color: getThemeColorVar("typo", "default"),
  cursor: "text",
})

const TreeLeaf: React.FunctionComponent<{ text: string }> = (props) => {
  return <div className={treeLeafClass}>{props.text}</div>
}

const treeNodeClass = css({
  padding: "4px 0px 4px 16px",
  margin: 0,
  fontSize: "16px",
  color: getThemeColorVar("primary", "default"),
  cursor: "pointer",
})

const TreeNode: React.FunctionComponent<PropsWithChildren<{ text: string }>> = (props) => {
  const [opened, setOpened] = useState(true)

  return (
    <div className={treeNodeClass}>
      <div onClick={() => setOpened(!opened)}>
        <Flex flexDirection="row">
          <div>{props.text}</div>
          <FlexItem flexGrow={1} />
          <Icon name={opened ? "arrow_drop_down" : "arrow_drop_up"} />
        </Flex>
      </div>
      {opened && props.children}
    </div>
  )
}

export const ProfileDependencies: React.FunctionComponent<OwnProps> = (props) => {
  const t = useMemo(translations, [translations])
  const [opened, setOpened] = useState(false)
  const { profileList, smartdataSource } = useAppSelector((state) => state.profile)

  function renderMacroScores(profile: Profile) {
    return (
      <>
        {LANA_AGS_NODES.filter((score) => typeof profile.scores.macro[score.scoreName] !== "undefined").map(
          (score, idx) => (
            <TreeLeaf key={idx} text={t.pickTranslation(score.title)} />
          )
        )}
        {(profileList || [])
          .filter((child) => typeof profile.scores.macro[CHILD_PROFILE_PREFIX + child.id] !== "undefined")
          .map((child, idx) => (
            <TreeNode key={idx} text={child.name}>
              {renderMacroScores(child)}
            </TreeNode>
          ))}
      </>
    )
  }

  function renderMicroScores(profile: Profile): JSX.Element {
    return (
      <>
        {LANA_CELL_NODES.filter((score) => typeof profile.scores.micro[score.scoreName] !== "undefined").map(
          (score, idx) => (
            <TreeLeaf key={idx} text={t.pickTranslation(score.title)} />
          )
        )}
        {(profileList || [])
          .filter((child) => typeof profile.scores.micro[CHILD_PROFILE_PREFIX + child.id] !== "undefined")
          .map((child, idx) => (
            <TreeNode key={idx} text={child.name}>
              {renderMicroScores(child)}
            </TreeNode>
          ))}
      </>
    )
  }

  function renderContent() {
    const profile = profileList?.find((profile) => profile.id === props.profileId)

    if (!profile) return ""

    return (
      <Grid columns={1}>
        <BorderBottom padding={[8, 0, 8, 16]}>
          <Grid columns={2}>
            <h3>{profile.name}</h3>
            <GridItem justifySelf={"end"}>
              <div className="bp4-popover-dismiss ">
                <Button type="tertiary" icon="close" size="small" onClick={() => setOpened(false)} />
              </div>
            </GridItem>
          </Grid>
        </BorderBottom>
        <div className={treeContainer}>
          {smartdataSource === "macro" && renderMacroScores(profile)}
          {smartdataSource === "micro" && renderMicroScores(profile)}
        </div>
      </Grid>
    )
  }

  return (
    <Popup
      placement="right"
      show={opened}
      tooltip={renderContent()}
      background={getThemeColorVar("white", undefined)}
      color={getThemeColorVar("typo", "default")}
      onClickOutside={() => setOpened(false)}
    >
      <Button icon="restructure" size="small" type="tertiary" onClick={() => setOpened(!opened)} />
    </Popup>
  )
}
