import * as React from "react"
import { bind } from "decko"
import { css, keyframes } from "emotion"
import { PropsWithChildren } from "react"

import { DialogHeader, DialogHeaderProps } from "./dialogheader"
import { getThemeColorVar } from "../helper/color"
import Portal from "./portal"

export interface HeaderlessDialogProps {
  id?: string
  modifier?: "danger"
  width?: string
  height?: string
  closeOnClickOutside?: boolean
  onClose: () => void
  withoutPortal?: boolean
}

export type DialogWithHeaderProps = HeaderlessDialogProps & DialogHeaderProps

function dialogHasHeader(
  props: any
): props is Readonly<{ children?: React.ReactNode }> & Readonly<DialogWithHeaderProps> {
  return props.title !== undefined
}

const animation = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100;
  }
`

const dialogClass = css({
  backgroundColor: getThemeColorVar("background", "lighter"),
  borderRadius: "5px",
  minWidth: "30%",
  maxWidth: "85%",
  zIndex: 9999999,
  display: "flex",
  flex: "0 1 auto",
  flexDirection: "column",
  animation: `${animation} .5s`,
  boxShadow: "0 1px 3px 0 rgb(0 0 0 / 30%), 0 0 0 1px rgb(0 0 0 / 4%)",
  overflow: "hidden",
})

export class Dialog extends React.Component<PropsWithChildren<HeaderlessDialogProps | DialogWithHeaderProps>, {}> {
  @bind
  handleCloseButton<T>(event: React.MouseEvent<T>) {
    event.preventDefault()
    event.stopPropagation()
    this.props.onClose && this.props.onClose()
  }

  @bind
  handleClickOutside(event: React.MouseEvent<HTMLDivElement>) {
    event.preventDefault()
    event.stopPropagation()
    if (this.props.closeOnClickOutside) this.props.onClose && this.props.onClose()
  }

  render() {
    const { id } = this.props

    const window = (
      <div id={id} className={dialogClass} style={{ width: this.props.width, height: this.props.height }}>
        {dialogHasHeader(this.props) && (
          <DialogHeader
            modifier={this.props.modifier}
            icon={this.props.icon}
            title={this.props.title}
            closeButton={this.props.closeButton}
            onClose={this.handleCloseButton}
          />
        )}
        {this.props.children}
      </div>
    )

    if (this.props.withoutPortal) {
      return window
    } else {
      return <Portal onClickOutside={this.handleClickOutside}>{window}</Portal>
    }
  }
}

export default Dialog
