import React, { ChangeEvent, useEffect, useMemo, useState } from "react"
import ComparablesMap from "./comparables-map"
import { useSelector } from "react-redux"
import { State } from "../../reducers/state"
import { css, cx } from "emotion"
import { AssessmentEntryFull, AssessmentPriceType } from "../../models/assessment"
import { DataSource } from "../../models/comparables"
import { Button, HTMLSelect, IconProps } from "@blueprintjs/core"
import { translations } from "../../i18n"
import { ComparablesApi, HouseType } from "../../../generated-apis/comparables-service"
import { ComparablesAnalysis } from "./comparables-analysis/comparables-analysis"
import { trackUsageEvent } from "../../../utils/usage-tracking"
import { comparablesServiceUrl, lanaApiUrl } from "../../../app_config"
import { DownloadReport } from "../../../shared/components/ui/download-report"
import useInterval from "../../../utils/use-interval"
import Axios from "axios"
import { assertUnreachable } from "../../../utils/utils"
import { AssessmentTranslationsGroup } from "../../i18n/translations"
import { HistoricalOffersFilters } from "./comparables-map/historical-offers-filters"
import { OnlineImmoscoutFilters } from "./comparables-map/online-immoscout-filters"
import { HistoricalTransactionFilters } from "./comparables-map/historical-transaction-filters"
import { HistoricalOffersInputForm } from "./comparables-map/historical-offers-filters-model"
import { HistoricalTransactionInputForm } from "./comparables-map/historical-transaction-filters-model"
import { OnlineImmoScoutInputForm } from "./comparables-map/online-immoscout-filters-model"
import { AppModules } from "../../../menu/util/app-location-types"
import {
  loadAssessmentForAssessmentModule,
  setComparablesInputDefaultDataSource,
  updateComparablesMapSettingsZoom,
} from "../../reducers/comparables-slice"
import { DocumentationAndSupport } from "../../../shared/components/documentation-and-support"
import { InvalidAddressMessage } from "../../../shared/components/ui/invalid-address-message"
import { HistoricalSeniorLivingInputForm } from "./comparables-map/historical-senior-living-filters-model"
import { HistoricalSeniorLivingFilters } from "./comparables-map/historical-senior-living-filters"
import Grid from "../../../shared/components/restyle-grid/grid"
import { getThemeColor, getThemeColorVar } from "../../../shared/helper/color"
import { featureEnabled, HELP_DEVELOPMENT } from "../../../utils/features-toggle"
import { navigateTo } from "../../../shared/reducers/navigation-slice"
import { NavigationPage } from "../../../shared/actions/navigation"
import LanaSubheader from "../../../shared/components/lana-subheader"
import Icon from "../../../shared/components/icon"
import { ViewModeSelectionDropdown } from "../../../shared/components/ui/view-selection-dropdown"
import { ScrollBox } from "../../../shared/components/ui/scroll-box"

export const styles = {
  stickyHeader: css({
    position: "sticky",
    top: 0,
    background: "white",
    zIndex: 100,
  }),
  borderGrey: css({
    borderRight: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
  }),
  filtersButton: css({
    height: "100%",
    justifySelf: "end",
    justifyContent: "space-between",
    fontSize: "18px",
    padding: "0 16px",
  }),
  filtersFormLine: css({
    padding: "0 16px 24px 16px",
    borderBottom: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
  }),
  headerButtons: css({
    justifySelf: "center",
    padding: "30px 0 20px 0",
    width: "300px",
  }),
  inputLabel: css({
    fontWeight: "bold",
    paddingBottom: "6px",
    paddingTop: "24px",
    fontSize: "14px",
  }),
  inputRangeDivider: css({
    color: "#828796",
    paddingRight: "16px",
    paddingLeft: "16px",
  }),
  dropdown: css({
    marginBottom: 8,
    borderRadius: 4,
    "select:enabled": {
      backgroundColor: "white",
    },
  }),
  formStyle: css({
    fontSize: "14px",
  }),
  notifierStyle: css({
    backgroundColor: getThemeColorVar("background", "dark"),
    color: "white",
    width: "inherit",
    position: "sticky",
    marginTop: "-64px",
    zIndex: 1005,
    bottom: 0,
    transition: "max-height 1s",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.9,
    overflow: "hidden",
    height: "64px",
  }),
  loadingAssessment: css({
    minHeight: "100%",
    padding: "1em",
    backgroundColor: "rgb(229, 227, 223)",
  }),
}

export type TrendTabs = "chart" | "table"
export type ComparablesAppPageType = "map" | "analysis"

interface Props {
  assessmentId: string
  assessmentEntryId: string | null
  module: AppModules["locationAssessment"]
}

export type LocationSourceType = "radius" | "zip"

export type ComparablesInputFormType =
  | HistoricalOffersInputForm
  | OnlineImmoScoutInputForm
  | HistoricalTransactionInputForm
  | HistoricalSeniorLivingInputForm

export function trackPriceTypeChange(assessmentEntry: AssessmentEntryFull | null, v: AssessmentPriceType) {
  assessmentEntry?.address && trackUsageEvent("COMPARABLES_CHANGE_USAGE_TYPE", assessmentEntry.address, v)
}

export type FilterProps = {
  t: AssessmentTranslationsGroup
  assessmentEntry: AssessmentEntryFull | null

  comparablesAppPage: ComparablesAppPageType

  comparablesApi: ComparablesApi

  houseTypes: ComparablesHouseTypes
}

export type ComparablesHouseTypes = { value: HouseType; label: string }[]

export const iconProps: Partial<IconProps> = {
  icon: "chevron-down",
  iconSize: 16,
  color: getThemeColor("primary", "default").toString(),
}

const documentationLink = "https://docs.google.com/document/d/1MZ9r4ZVx4lpLz186C2nOTUDqAyMc9cERaqbPahbbWOU"

export const ComparablesV2App = ({ module }: Props) => {
  const t = useMemo(translations, [translations])

  const assessment = useSelector((state: State) => state.assessment.currentAssessment)
  const assessmentEntryLoadError = useSelector((state: State) => state.assessment.assessmentEntryLoadError)
  const assessmentLoadError = useSelector((state: State) => state.assessment.assessmentLoadError)
  const assessmentLoadInProgress = useSelector((state: State) => state.assessment.assessmentLoadInProgress)
  const assessmentEntry = useSelector((state: State) => state.assessment.currentAssessmentEntry)
  const assessmentEntryLoadInProgress = useSelector((state: State) => state.assessment.assessmentEntryLoadInProgress)
  const comparables = useSelector((state: State) => state.comparablesApp)
  const dataSource = useSelector((state: State) => state.comparablesApp.comparablesInput.dataSource)
  const priceTrends = useSelector((state: State) => state.comparablesApp.priceTrends)
  const priceDistribution = useSelector((state: State) => state.comparablesApp.priceDistribution)
  const areaDistribution = useSelector((state: State) => state.comparablesApp.areaDistribution)
  const offerYearDistribution = useSelector((state: State) => state.comparablesApp.offerYearDistribution)
  const constructionYearDistribution = useSelector((state: State) => state.comparablesApp.constructionYearDistribution)
  const graphsLoadingInProgress = useSelector((state: State) => state.comparablesApp.graphsLoadingInProgress)
  const comparablesItemsLoadError = useSelector((state: State) => state.comparablesApp.comparablesItemsLoadError)
  const graphsLoadingError = useSelector((state: State) => state.comparablesApp.graphsLoadingError)

  const [filtersCollapsed, setFiltersCollapsed] = useState(false)
  const toggleRightPanelWidth = "320px"

  const houseTypes: ComparablesHouseTypes = useMemo(
    () => [
      { value: HouseType.Other, label: t.assessmentEntryDetails.houseType.other },
      { value: HouseType.SingleDetached, label: t.assessmentEntryDetails.houseType.singleDetached },
      { value: HouseType.Multiple, label: t.assessmentEntryDetails.houseType.multiple },
      { value: HouseType.SemiDetached, label: t.assessmentEntryDetails.houseType.semiDetached },
      { value: HouseType.Middle, label: t.assessmentEntryDetails.houseType.middle },
      { value: HouseType.Corner, label: t.assessmentEntryDetails.houseType.corner },
    ],
    [t]
  )

  React.useEffect(() => {
    updateComparablesMapSettingsZoom(12)
  }, [assessmentEntry?.id])

  const [comparablesAppPage, setComparablesAppPage] = useState<ComparablesAppPageType>("analysis")

  const comparablesApi = new ComparablesApi(undefined, comparablesServiceUrl)

  const notEnoughOffers = 0 < comparables.comparablesItems.length && comparables.comparablesItems.length < 5

  useEffect(() => {
    if (comparablesAppPage === "map") {
      setFiltersCollapsed(true)
    }
  }, [comparablesAppPage])

  // assessment creation from Comparables module
  useInterval(
    () => {
      if (assessment && assessmentEntry) {
        Axios.get(
          `${lanaApiUrl}/api/assessments/${encodeURIComponent(assessment.id)}/entries/${encodeURIComponent(
            assessmentEntry.id
          )}`
        ).then(
          () => {
            void loadAssessmentForAssessmentModule(assessment.id, assessmentEntry.id, "comparables")
          },
          () => {}
        )
      }
    },
    assessment &&
      (assessment.state === "scored" || assessment.state === "scored-ambiguous" || assessment.state === "failure")
      ? null
      : 2000
  )

  const onSetComparablesAppPage = (page: ComparablesAppPageType) => {
    if (dataSource === "historical-21st") {
      trackUsageEvent("COMPARABLES_SWITCH_TAB", assessmentEntry?.address ?? null, page)
      setComparablesAppPage(page)
    }
  }

  const isMapTab = comparablesAppPage === "map"
  const assessmentLoaded = assessment && assessmentEntry

  const isHistoricalDatasource = dataSource === "historical-21st"

  const graphDataIsMissing =
    priceTrends.length === 0 &&
    !priceDistribution?.count &&
    !areaDistribution?.count &&
    !offerYearDistribution?.count &&
    !constructionYearDistribution?.count
  const dataLoadingError =
    graphsLoadingError || assessmentEntryLoadError || assessmentLoadError || comparablesItemsLoadError

  const noOffersError =
    !dataLoadingError &&
    assessment &&
    assessmentEntry &&
    ((!isMapTab && !graphsLoadingInProgress && graphDataIsMissing) ||
      (isMapTab && !comparables.comparablesItemsLoadInProgress && comparables.comparablesItems.length === 0))

  const filterConfigProps: FilterProps = {
    t,
    assessmentEntry,
    comparablesAppPage,
    houseTypes,
    comparablesApi,
  }

  const filtersConfig: Record<DataSource, JSX.Element> = {
    "historical-21st": <HistoricalOffersFilters {...filterConfigProps} />,
    "historical-21st-transaction": <HistoricalTransactionFilters {...filterConfigProps} />,
    "historical-21st-senior-living": <HistoricalSeniorLivingFilters {...filterConfigProps} />,
    "online-immoScout": <OnlineImmoscoutFilters {...filterConfigProps} />,
  }

  function dataSourceToTrackingExtraInfo(source: DataSource) {
    switch (source) {
      case "historical-21st":
        return "historical"
      case "online-immoScout":
        return "live"
      case "historical-21st-transaction":
        return "transaction"
      case "historical-21st-senior-living":
        return "senior-living"
    }
    return assertUnreachable(source)
  }

  const onDatasourceChange = (e: ChangeEvent<HTMLSelectElement>) => {
    assessmentEntry?.address &&
      trackUsageEvent(
        "COMPARABLES_CHANGE_DATA_SOURCE",
        assessmentEntry.address,
        dataSourceToTrackingExtraInfo(e.currentTarget.value as DataSource)
      )
    setComparablesInputDefaultDataSource(e.currentTarget.value as DataSource)
  }

  const filtersColumnStyles = (isCollapsed: boolean) =>
    css({
      display: isCollapsed ? "none" : "block",
      width: toggleRightPanelWidth,
      borderLeft: `1px solid ${getThemeColorVar("primary", "light")}`,
    })

  const renderContent = () => {
    const sourceOptions: { value: DataSource; label: string }[] = [
      { value: "historical-21st", label: t.assessmentEntryDetails.dataSource.historical },
      { value: "online-immoScout", label: t.assessmentEntryDetails.dataSource.onlineImmo },
      { value: "historical-21st-transaction", label: t.assessmentEntryDetails.dataSource.transaction },
      { value: "historical-21st-senior-living", label: t.assessmentEntryDetails.dataSource.seniorLiving },
    ]

    const filterPanel = (
      <Grid columns={isMapTab ? 1 : 2} columnSpec={isMapTab ? "1fr" : "1fr min-content"}>
        {!isMapTab && (
          <div>
            {
              <ComparablesAnalysis
                notEnoughOffers={notEnoughOffers}
                areaDistribution={areaDistribution}
                priceTrends={priceTrends}
                constructionYearDistribution={constructionYearDistribution}
                offerYearDistribution={offerYearDistribution}
                priceDistribution={priceDistribution}
              />
            }

            {noOffersError && (
              <div className={styles.notifierStyle}>
                <span style={{ padding: "8px" }}>{t.assessmentComparables.analysisErrors.noOffers}</span>
              </div>
            )}

            {dataLoadingError && (
              <div className={styles.notifierStyle}>
                <span style={{ padding: "8px" }}>{t.assessmentComparables.analysisErrors.error}</span>
              </div>
            )}

            {notEnoughOffers && (
              <div className={styles.notifierStyle}>
                <span style={{ padding: "8px" }}>{t.assessmentComparables.analysisErrors.notEnoughOffers}</span>
              </div>
            )}
          </div>
        )}

        <Grid
          columns={1}
          rowSpec="min-content min-content min-content"
          className={!isMapTab ? filtersColumnStyles(filtersCollapsed) : ""}
        >
          <form className={styles.formStyle}>
            <div className={styles.filtersFormLine}>
              <div className={styles.inputLabel}>{t.assessmentEntryDetails.dataSource.header}</div>
              <HTMLSelect
                fill
                className={styles.dropdown}
                style={{ borderRadius: 4 }}
                iconProps={iconProps}
                options={sourceOptions}
                disabled={comparablesAppPage === "analysis"}
                value={dataSource}
                onChange={onDatasourceChange}
              />

              <div>{t.assessmentComparables.offersLimit[comparablesAppPage][dataSource]}</div>

              {dataSource === "historical-21st-transaction" && (
                <div className="comparables-transaction-source-info">
                  {t.assessmentComparables.transactionSourceInfo}
                </div>
              )}
            </div>
          </form>

          {filtersConfig[dataSource]}
        </Grid>
      </Grid>
    )

    return (
      <Grid columns={isMapTab ? 2 : 1} columnSpec={isMapTab ? "1fr min-content" : "1fr"}>
        {isMapTab && (
          <div>
            {!assessmentLoaded && <div className={styles.loadingAssessment} />}

            {assessmentLoaded && <ComparablesMap dataSource={dataSource} />}

            {noOffersError && (
              <div className={styles.notifierStyle}>
                <span style={{ padding: "8px" }}>{t.assessmentComparables.analysisErrors.noOffers}</span>
              </div>
            )}

            {dataLoadingError && (
              <div className={styles.notifierStyle}>
                <span style={{ padding: "8px" }}>{t.assessmentComparables.analysisErrors.error}</span>
              </div>
            )}
          </div>
        )}

        <div className={isMapTab ? filtersColumnStyles(filtersCollapsed) : ""}>
          {isMapTab ? <ScrollBox>{filterPanel}</ScrollBox> : filterPanel}
        </div>
      </Grid>
    )
  }

  const helpDevelopment = featureEnabled(HELP_DEVELOPMENT)

  const viewSelectionActions = [
    {
      title: `${t.assessmentEntryDetails.view}: ${t.assessmentEntryDetails.comparablesAppPageTranslation("analysis")}`,
      onClick: () => onSetComparablesAppPage("analysis"),
      disabled: !isHistoricalDatasource,
    },
    {
      title: `${t.assessmentEntryDetails.view}: ${t.assessmentEntryDetails.comparablesAppPageTranslation("map")}`,
      onClick: () => onSetComparablesAppPage("map"),
    },
  ]

  return (
    <Grid columns={1} height={[100, "%"]} rowSpec="fit-content(100%) minmax(0, 1fr)">
      <div className={styles.stickyHeader}>
        <LanaSubheader
          menuSection={"locationAssessment"}
          assessment={assessment}
          assessmentEntry={assessmentEntry}
          module={module}
          isLoading={assessmentLoadInProgress || assessmentEntryLoadInProgress}
        >
          <DocumentationAndSupport
            documentationURL={helpDevelopment ? undefined : documentationLink}
            addDocumentationLink
            tooltip={t.helpAndSupport}
            callLocation="Comparables"
            onClick={
              helpDevelopment
                ? () => navigateTo({ name: NavigationPage.help, params: { section: "comparables" } })
                : () => {}
            }
          />
          <DownloadReport />
        </LanaSubheader>

        {assessmentEntry?.state && assessmentEntry?.state !== "failure" && (
          <Grid
            columns={3}
            columnSpec={`1fr 4fr ${toggleRightPanelWidth}`}
            gap={8}
            width={[100, "%"]}
            padding={[20, 0, 0, 0]}
            className={css({ alignItems: "center" })}
          >
            <h2 style={{ whiteSpace: "nowrap", paddingLeft: "32px", paddingBottom: "20px" }}>
              {t.assessmentEntryDetails.comparables}
            </h2>

            <div style={{ width: "300px", justifySelf: "center", paddingBottom: "20px" }}>
              <ViewModeSelectionDropdown
                options={viewSelectionActions}
                selected={`${t.assessmentEntryDetails.view}: ${t.assessmentEntryDetails.comparablesAppPageTranslation(
                  comparablesAppPage
                )}`}
              />
            </div>

            <Grid columns={1} rowSpec={"1fr 20px"} height={[100, "%"]}>
              <Button
                icon={<Icon name={"filter"} />}
                intent={"primary"}
                rightIcon={<Icon name={filtersCollapsed ? "chevron_left_small" : "chevron_right_small"} />}
                className={cx(styles.filtersButton, css({ width: filtersCollapsed ? "180px" : toggleRightPanelWidth }))}
                onClick={() => setFiltersCollapsed(!filtersCollapsed)}
              >
                Filter
              </Button>
              <div
                style={
                  filtersCollapsed
                    ? {}
                    : {
                        borderLeft: `1px solid ${getThemeColorVar("primary", "light")}`,
                        width: toggleRightPanelWidth,
                      }
                }
              ></div>
            </Grid>
          </Grid>
        )}
      </div>

      <InvalidAddressMessage assessmentEntry={assessmentEntry} />
      {assessmentEntry?.state && assessmentEntry?.state !== "failure" && renderContent()}
    </Grid>
  )
}
