import { Translations } from "./translations"
import { DE } from "./de"
import { EN } from "./en"
import { language } from "../../shared/language"

export function translations(): Translations {
  switch (language()) {
    case "de":
      return DE
    default:
      return EN
  }
}
