import DropdownButton from "../dropdown-button"
import React from "react"

export interface ViewOption {
  title: string
  onClick: () => void
  disabled?: boolean
}
interface Props {
  options: ViewOption[]
  selected: string
}

export const ViewModeSelectionDropdown = ({ options, selected }: Props) => {
  return (
    <div id={"view-mode-selection-dropdown"}>
      <DropdownButton
        icon={"password_show"}
        type="secondary"
        alignment={"left"}
        actions={options.map((option) => {
          return {
            title: option.title,
            onClick: option.onClick,
            disabled: option.disabled,
          }
        })}
      >
        <span style={{ paddingLeft: "9px", fontSize: "18px" }}>{selected}</span>
      </DropdownButton>
    </div>
  )
}
