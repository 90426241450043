import React, { ReactNode } from "react"
import { css, cx } from "emotion"
import { Tooltip } from "../../../shared/components/ui/tooltip"
import { translations } from "../../i18n"
import { DashboardEditMode } from "./dashboard-widget-grid"
import Grid from "../../../shared/components/restyle-grid/grid"
import Icon from "../../../shared/components/icon"

export const widgetStyles = {
  widgetPrintMode: css({
    boxShadow: "none",
    border: "1px solid #ddd",
  }),
  headerUnit: css({
    justifySelf: "start",
  }),
  headerColumns: css({
    justifySelf: "end",
    paddingRight: "4px",
  }),
}

interface Props {
  header?: string
  headerAddition?: string[]
  headerFloating?: boolean
  onHeaderClick?: () => void
  children: ReactNode
  htmlId?: string
  configurable?: boolean
  editMode: DashboardEditMode
  onConfigClick?: () => void
  onRemoveClick?: () => void
  printMode: boolean
}

const DashboardWidget = (props: Props) => {
  const t = translations()

  const headerColumns = props.headerAddition ? props.headerAddition.length + 2 : 2
  const onHeaderClick = (e: React.MouseEvent) => {
    if (!props.editMode) {
      e.stopPropagation()
      props.onHeaderClick && props.onHeaderClick()
    }
  }

  const onConfigClick = (e: React.MouseEvent) => {
    if (props.editMode) {
      e.stopPropagation()
      props.onConfigClick && props.onConfigClick()
    }
  }
  const onRemoveClick = (e: React.MouseEvent) => {
    if (props.editMode) {
      e.stopPropagation()
      props.onRemoveClick && props.onRemoveClick()
    }
  }

  const renderGridContent = (content: React.ReactElement) =>
    props.headerFloating ? (
      content
    ) : (
      <Grid columns={1} rowSpec={"min-content 1fr"} height={[100, "%"]}>
        {content}
      </Grid>
    )

  return (
    <div
      className={cx(
        "dashboard-widget",
        props.htmlId && "dashboard-widget-" + props.htmlId,
        props.editMode && "edit-mode",

        props.editMode && "edit-mode-" + props.editMode,
        props.printMode && widgetStyles.widgetPrintMode,
        props.headerFloating && "dashboard-widget-floating"
      )}
    >
      {renderGridContent(
        <>
          {props.header && (
            <div
              className={cx(
                "dashboard-widget-header",
                "dashboard-widget-header-" + props.htmlId,
                props.editMode && "edit-mode",
                props.editMode && "edit-mode-" + props.editMode,
                props.headerFloating && "dashboard-widget-header-floating"
              )}
              onClick={onHeaderClick}
            >
              <Grid
                columns={headerColumns}
                colGap={8}
                columnSpec={
                  headerColumns === 2 ? "2fr min-content" : `3fr repeat(${headerColumns - 2},1fr) min-content`
                }
              >
                <div>
                  <span className="single-line-with-ellipsis">{props.header}</span>
                </div>
                {props.headerAddition && (
                  <div
                    className={props.headerAddition.length > 1 ? widgetStyles.headerColumns : widgetStyles.headerUnit}
                  >
                    {props.headerAddition[0]}
                  </div>
                )}
                {props.headerAddition && <div className={widgetStyles.headerColumns}>{props.headerAddition[1]}</div>}
                {!props.printMode && (
                  <>
                    {props.editMode && props.editMode === "configure" && (
                      <div
                        className={`notDraggable widget-edit-button ${
                          props.configurable ? "remove-button shifted" : "remove-button"
                        }`}
                      >
                        <Tooltip tooltip={t.dashboard.remove} placement={"bottom"} hideArrow={true}>
                          <span onClick={onRemoveClick}>
                            <Icon name={"remove"} fontSize={16} />
                          </span>
                        </Tooltip>
                      </div>
                    )}
                    {props.configurable && props.editMode === "configure" && (
                      <div className={"notDraggable widget-edit-button config-button"}>
                        <Tooltip tooltip={t.dashboard.configuration} placement={"bottom"} hideArrow={true}>
                          <span onClick={onConfigClick}>
                            <Icon name={"settings"} fontSize={16} />
                          </span>
                        </Tooltip>
                      </div>
                    )}
                  </>
                )}
              </Grid>
            </div>
          )}
          <div
            className={cx(
              "dashboard-widget-content",
              props.htmlId && "dashboard-widget-content-" + props.htmlId,
              props.headerFloating && "dashboard-widget-content-floating"
            )}
          >
            {props.children}
          </div>
        </>
      )}
    </div>
  )
}

export default DashboardWidget
