import React from "react"
import { css } from "emotion"
import { Grid } from "../../../shared/components/ui/grid"
import { ExtendedPlaceResult } from "./poi-explorer-map"
import { translations } from "../../../shared/i18n"
import { useAppSelector } from "../../../relas/store"
import Card from "../../../shared/components/card"
import { formatNumber } from "../../../shared/helper/number-format"

const styles = {
  closeIcon: css({
    i: { fontSize: "16px" },
    alignSelf: "center",
    justifySelf: "end",
    cursor: "pointer",
  }),
  poiPropertiesText: css({
    fontWeight: "bold",
  }),
}

export interface PoiMapPopupProps {
  poiData: ExtendedPlaceResult
  distance?: number
}

const PoiMapPopup = ({ poiData, distance }: PoiMapPopupProps) => {
  const t = React.useMemo(translations, [translations])
  const googleMapsData = useAppSelector((state) => state.googleMapsData)
  const poiCategories = googleMapsData.poiCategories
    ? googleMapsData.poiCategories.map(([, v]) => v).reduce((acc, val) => acc.concat(val), [])
    : []

  const getTitleFromId = (id: string) => {
    const title = poiCategories.find((category) => category.id === id)?.title
    return title ? t.pickTranslation(title) : ""
  }

  const poiTitlesArr = poiData.types?.map((type) => getTitleFromId(type)).filter((type) => type !== "")

  return (
    <Card header={poiData.name}>
      <Grid columnSpec={"116px auto"} rowGap={8} padding={[4, 0, 4, 0]}>
        <div>{t.map.poiMapPopup.category}</div>
        <div>{poiTitlesArr && <div>{poiTitlesArr.join(", ")}</div>}</div>
        <div>{t.map.poiMapPopup.address}</div>
        <div className={styles.poiPropertiesText}>{poiData.vicinity ?? poiData.formatted_address}</div>
        <div>{t.map.poiMapPopup.distance}</div>
        <div>{distance && <div>{`${formatNumber(distance, 0)} m`}</div>}</div>
      </Grid>
    </Card>
  )
}

export default PoiMapPopup
