import { SharedTranslations } from "./translations"
import { DataSetType } from "../models/smartdata"
import { HasTranslations } from "../smartdata-products/smartdata"

export const SHARED_EN: SharedTranslations = {
  pickTranslation: (t: HasTranslations) => t["en"],
  apply: "Apply",
  dateFormat: "MM/DD/YYYY",
  of100: "/100",
  edit: "Edit",
  editing: "Editing",
  duplicate: "Duplicate",
  delete: "Delete",
  ok: "OK",
  yes: "Yes",
  no: "No",
  cancel: "Cancel",
  default: "Default",
  done: "Done",
  save: "Save",
  saveAs: "Save as",
  download: "Download",
  lock: "Lock",
  unlock: "Unlock",
  loadingScores: "Loading scores...",
  loadingChart: "Content is loading",
  scores: "Scores",
  scoreDescription: "Description",
  scoreSource: "Source",
  price: "Price",
  pricePerSqm: "Price per m²",

  privatePOICategories: "Private POIs",
  map: {
    distanceTitle: "Time of travel",
    distanceDataHint: "The isochrone data and calculation is provided by third party supplier HERE.",
    poiTitle: "Points of interest",
    mapStyleTitle: "Map style",
    mapStyleName: {
      default: "Default",
      pastel: "Pastel",
      hybrid: "Hybrid",
      positron: "Positron",
      topo: "Topo",
    },
    considerTraffic: "Consider traffic",
    transitTimeLabel: "Transit time",
    vehicleLabel: "Vehicle",
    vehicle: {
      none: "None",
      car: "Car",
      truck: "Truck",
      pedestrian: "Pedestrian",
    },
    transitTimes: {
      "300": "5 minutes",
      "900": "15 minutes",
      "1800": "30 minutes",
      "3600": "1 hour",
      "7200": "2 hours",
    },
    poiCategory: {
      "atm-bank-exchange": "ATM/Bank",
      "petrol-station": "Petrol station",
      "public-transport": "Public transport",
      "taxi-stand": "Taxi stand",
      shopping: "Shopping",
      restaurant: "Restaurant",
      hotel: "Hotel",
    },
    poiZoomHint:
      "Up to 100 of the most important POIs are shown, depending on the zoom level,for every selected category in the near surroundings.",
    poiDataHint: "This data is provided by third party supplier HERE.\nAll distances are measured in beeline.",
    poiMapPopup: {
      category: "Category",
      address: "Address",
      openingHours: "Opening hours",
      distance: "Distance (beeline)",
    },
    switchToRegion: "Switch to",
    landvalues: {
      title: "Available Special Maps",
      infoText: "The special maps are only visible from a certain zoom level.",
      detailsUnavailable: "No additional values available at this position.",
      availableLayers: "Available at location",
      unavailableLayers: "Unavailable at location",
      noSelection: "<none>",
      specialMapsTitle: "Special Maps",
      newSpecialMapsHint:
        "Our new modul for Special Maps offers a huge map, more clarity, movable windows for legend and additional infos and more map material.",
      toNewSpecialMaps: "Open new Special Maps",
    },
    uninhabitedCell: "Uninhabited cell ~ Approximate values",
    mapShapeOutdatedMessage: 'Some map data is outdated. Please click "Recalculate" to get the latest results.',
  },
  poiExplorer: {
    title: "POI Explorer",
    tabs: {
      poi: "Points of interest",
      isochrone: "Isochrone",
      summary: "Summary",
    },
    poiManualSelection: "Manual selection",
    isolineShowOnMap: "Show on map and use in summary",
    poiOnlyNearest: "Only nearest POIs",
    poiStrictFilter: "Filter primary categories",
    vehicleLabel: "Type",
    transitTimeLabel: "Transit time",
    transitTimeUnit: "min",
    accessibility: "Nearest Accessibility",
    distances: {
      nearestAirport: "Int. Airport",
      nearestBusStop: "Bus Stop",
      nearestSubwayStation: "U-Bahn Stop",
    },
    summaryCount: "Count",
    summaryNearest: "Nearest",
    countTotalLabel: "Points of interest",
    countInsideLabel: "Points of interest inside isochrone",
    manualSelection: "Manual selection",
    createGroup: "Create group",
    newPOIGroupNameLabel: "Please enter a unique name",
    editGroup: "Edit group",
    deleteGroup: "Delete group",
    name: "Name",
    categories: "Categories",
    deleteConfirmHeader: "Delete Category Group",
    deleteConfirmText: (name: string) => `Do you really want to delete group '${name}'?`,
    onlyWithinIsochrone: "Only within isochrone",
    onlyPrimaryCategories: "Only primary categories",
    infoText:
      "Up to 60 POIs are shown for every category in the near surroundings. This data is provided by third party supplier Google. All distances are measured in beeline.",
    busAndTransit: "Bus & Transit",
    error: "Oops, an error occurred. Please try again later.",
    newPOIExplorerHint:
      "The new POI Explorer offers a huge map, more clarity and the possibility to manage your own category groups.",
    toNewPOIExplorer: "Open new POI Explorer",
    poiQuickSearch: "POI Quicksearch",
  },
  smartdataSourceTranslation: (source: DataSetType) => {
    switch (source) {
      case "micro":
        return "Micro"
      case "macro":
        return "Macro"
    }
  },
  scoreKey: {
    macro: "Macro",
    micro: "Micro",
  },
  scoreKeyLong: {
    macro: "Macro (Municipality)",
    micro: "Micro (Cell / Neighborhood)",
  },
  metaScoreKey: "Meta",
  microScores: "Micro scores",
  macroScores: "Macro scores",
  macroRating: "Macro rating",
  microRating: "Micro rating",
  loadMacroDataTitle: "Starting point",
  loadMicroDataTitle: "Starting point",
  selected: "Selected",
  ratingGrades: "Possible Grades",
  ratingGrade: "Grade",
  firstProfile: "To create custom scores create a new profile.",
  sliderDescription: "Opacity",
  activeProfile: "Active",
  inverted: "Inverted score",
  profileAlreadyExists: "A profile with that name already exists.",
  groupAlreadyExists: "A group with that name already exists.",
  profileScoreTitle: "Score",
  profilePopupScoreTitle: "Profile Score",
  rawValue: "VALUE",
  scoreValue: "SCORE",
  weight: "WEIGHT",
  description: "DESCRIPTION",
  saveChangesAlertBoxTitle: "Save changes?",
  saveChangesErrorBoxTitle: "Save changes failed",
  saveChangesErrorCyclic: "Cyclic dependency between profiles detected",
  saveChanges: "Save changes",
  withdrawChanges: "Withdraw changes",
  alertBoxContent: "The profile has been changed. Do you want to save the changes?",
  scoreComposedOf: "Composed of:",
  queryViewportTooLarge: "Zoomed out too far to display scores",
  loadingPOIs: "Loading POIs...",
  loadingRatings: "Loading ratings...",

  country: {
    de: "Germany",
  },
  prefixWithProfile: (profileName: string) => `Profile: ${profileName}`,
  helpTitle: "Help",
  graphAverage: "Median",
  newLabel: "New",
  feedback: "Feedback",
  helpAndSupport: "Help & Support",
  releaseIndicator: {
    title: "New Features",
    mainText: "We've released new features for you.",
    button: "Read more",
  },
  header: {
    section: {
      locationAssessment: "Assessments",
      profileManager: "Profile Manager",
      quickstart: "Quickstart",
      locationExplorer: "Location Explorer",
      privateData: "Private data",
      locationSelector: "Location Selector",
      ratingManager: "Rating Manager",
    },
    module: {
      dashboard: "Assessment Overview",
      assessment: "Scores",
      ratings: "Ratings",
      prices: "Prices",
      comparables: "Comparables",
      marketdata: "District Data",
      poiExplorer: "POI - Explorer",
      specialMaps: "Special Maps",
      rentindex: "Rent Index",
      fundamentalData: "Fundamental Data",
    },
    profile: {
      logout: "Logout",
      myProfile: "My profile",
      profileSettings: "Profile Settings",
      securitySettings: "Security Settings",
    },
    admin: {
      title: "Administration",
      submenus: {
        companySettings: "Company settings",
        apiAdmin: "Manage API Tokens",
        companyUsers: "User management",
        products: "Product usage",
      },
    },
    usageType: {
      office: "Office",
      retail: "Retail",
      residential: "Residential",
      hall: "Logistics",
      commercial: "Commercial",
      plot: "Plot",
    },
    priceType: {
      rent: "Rent",
      sale: "Purchase",
    },
    houseType: {
      other: "Other",
      singleDetached: "One-family house (detached)",
      multiple: "Multifamily house",
      semiDetached: "Semi-detached house",
      corner: "End-terrace house",
      middle: "Mid-terrace house",
    },
    priceCategories: {
      apartment: "Apartment",
      house: "House",
    },
    recalculate: "Recalculate",
    recalculateHint: "The displayed values might be outdated and should be recalculated.",
    stateStatus: {
      created: "Assessment created",
      geocoded: "Geocoding",
      "geocoded-ambiguous": "Geocoding error",
      smartdata: "Data processing",
      "smartdata-ambiguous": "Data processing error",
      rated: "Ratings processing",
      "rated-ambiguous": "Ratings error",
      "object-prices": "Prices processing",
      "object-prices-ambiguous": "Prices error",
      scored: "Scores processing",
      "scored-ambiguous": "Scores error",
      failure: "Failure",
    },
    downloadDataTooltip: "Download data",
    downloadPDFTooltip: "Download Dashboard (PDF)",
    notesTooltip: "Notes",
    multiAddressRenameTooltip: "Rename portfolio",
    changeAddressOrTitleTooltip: "Change address or title",
    newBldg: "New Bldg.",
    pois: "POIs",
    changeAssessmentEntryDetails: "Change unit specifications",
    aiTextGeneration: "AI Text Generation",
    aiTextGenerationLabel: "AI Text",
    backToAssessmentTooltip: "Back to the assessment list",
    editDashboardConfigurationTooltip: "Manage dashboards & widgets",
    allAsessments: "All Assessments",
    newAssessment: "New Assessment",
  },
  legal: {
    title: "21st Legal Information",
    imprint: "Imprint",
    privacy: "Data Privacy",
    tac: "Terms & Conditions",
  },
  scoreSearch: {
    filter: "Filter",
    groups: "Groups",
    expandActive: "Expand active groups",
    expandAll: "Expand all groups",
    collapseAll: "Collapse all groups",
    unselectAll: "Unselect everything",
    info: "Here you can filter matching scores. Names and descriptions are included in the search.",
    otherProfilesCategory: "Profiles",
    to: "To",
    from: "From",
  },
  districtDataSearchInfo: "Here you can filter matching items. Names and descriptions are included in the search.",
  isochrone: "Isochrone",
  notesPlaceholder: "Type your notes here",
  notesSavingError:
    "Your changes can’t be saved because this would overwrite changes from another user. Please close this dialog and edit the notes again.",
  color: "Color",
  label: "Label",
  privatePois: "Private POIs",
  noPrivatePoisFound: "No 'Private POIs' found",
  noTitle: "No Title",
  addressChangedTooltip: {
    notificationTitle: "The original address was ambiguous and got corrected by our geocoding service.",
    originalAddress: "Original",
    correctedAddress: "Corrected",
    removed: "Removed",
  },
  addressColumn: {
    postalCode: "ZIP",
    locality: "City",
    route: "Street",
    streetNumber: "Nr",
  },
  textGeneration: {
    header: "AI Text Generation: City Description",
    descriptionTitle: "AI Generated City Description",
    generateText: "Generate text",
    clear: "Clear",
    copy: "Copy",
    short: "Short",
    medium: "Medium",
    long: "Long",
    newFeatureDescription: `Let ChatGPT write a text about the city of your assessment that you can use in reports, memorandums, etc!
    Please note that this is an experimental feature. You should definitely check the generated text before use.
    Please feel free to give us feedback on how this feature can be improved.`,
    english: "English",
    german: "German",
    language: "Language",
    length: "Length",
  },
  documentationAndSupport: {
    openDocumentation: "Open documentation",
    contactSupport: "Contact our support",
  },
  validationTranslations: {
    constructionYearValidation: "Specify reasonable year",
    textTooLong: (maxAllowed?: number) => `Please enter only up to ${maxAllowed} characters.`,
    textTooShort: (minRequired?: number) => `Please enter at least ${minRequired} characters.`,
    date: {
      formatMismatch: (format: string) => `The given date does not match the expected format (${format}).`,
    },
    notANumber: "Please enter a number.",
    required: "Please enter a value.",
    min: (min: number) => `Value too small (expected ≥ ${min}).`,
    max: (max: number) => `Value too big (expected ≤ ${max}).`,
    moreThan: (moreThan: number) => `Value too small (expected > ${moreThan}).`,
    integer: "Please enter an integer.",
  },
  primitive: {
    thousandSeparator: ",",
    decimalSeparator: ".",
  },
  numberSuffix: {
    billion: "bn",
    million: "m",
  },
  error: {
    confirmButton: "Close",
    defaultTitle: "Error",
    defaultHint: "An unexpected error occurred. Your request could not be processed at this time. ",
    serverError: "Please try again later.",
    networkError: "Couldn't connect to the 21st platform. Please check your network connection and try again later.",
    messageWithFlowId: (flowId: string) => `We have assigned this error the ID ${flowId} and will investigate.`,
  },
  breadcrumbTitle: "You are here",
  revoHeader: {
    backButton: "Back",
  },
  yearOfConstructionAbbreviation: "BY",
}
