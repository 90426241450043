import * as React from "react"
import { Flex } from "../../shared/components/ui/flex"
import { css } from "emotion"
import { Assessment, AssessmentEntryListViewModes, isInProgressState } from "../models/assessment"
import { translations } from "../i18n"
import { AssessmentEntryList } from "./assessment-entry-list"
import { AssessmentEntryMap } from "./assessment-entry-map"
import { AssessmentRename } from "./assessment-rename"
import { TagWithCount } from "../../shared/components/ui/tag-filter-select"
import { Tooltip } from "../../shared/components/ui/tooltip"
import { AssessmentDownloadScore } from "./assessment-download-scores"
import { useAppSelector } from "../../relas/store"
import AppHeader from "../../shared/components/app-header"
import { AppModules } from "../../menu/util/app-location-types"
import {
  refreshAssessmentEntries,
  setAssessmentDetailsSearchTerm,
  setAssessmentEntryListViewMode,
  setAssessmentTagFilter,
  setMacroContext,
} from "../reducers/assessment-slice-functions"
import { DocumentationAndSupport } from "../../shared/components/documentation-and-support"
import { TagFilterSelect } from "../../shared/components/ui/tag-filter-select"
import useInterval from "../../utils/use-interval"
import ButtonTabs from "../../shared/components/buttontabs"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import HorizontalDivider from "../../shared/components/horizontaldivider"
import Select from "../../shared/components/select"
import CenteredElement from "../../shared/components/layout/centeredelement"
import Grid from "../../shared/components/restyle-grid/grid"
import GridItem from "../../shared/components/restyle-grid/griditem"
import TextField from "../../shared/components/textfield"
import LoadingSpinner from "../../shared/components/loadingspinner"
import Icon from "../../shared/components/icon"

type Props = {
  module: AppModules["locationAssessment"]
}

const listOrMapButton = css({
  backgroundColor: "white",
  borderRadius: "4px",
})

const gridClassName = css({
  alignItems: "center",
})

let refreshLock: boolean = false

export const AssessmentDetails = ({ module }: Props) => {
  const t = translations()

  const [toRename, setToRename] = React.useState<Assessment>()

  const currentAssessment = useAppSelector((state) => state.assessment.currentAssessment)
  const assessmentEntries = useAppSelector((state) => state.assessment.assessmentEntries)
  const assessmentLoadError = useAppSelector((state) => state.assessment.assessmentLoadError)
  const assessmentLoadInProgress = useAppSelector((state) => state.assessment.assessmentLoadInProgress)
  const assessmentUpdateInProgress = useAppSelector((state) => state.assessment.assessmentUpdateInProgress)
  const tagFilter = useAppSelector((state) => state.assessment.tagFilter)
  const entryListViewMode = useAppSelector((state) => state.assessment.entryListViewMode)
  const assessmentDetailsSearchTerm = useAppSelector((state) => state.assessment.assessmentDetailsSearchTerm)
  const availableMacroContexts = useAppSelector((state) => state.assessment.availableMacroContexts)
  const lanaScopes = useAppSelector((state) => state.user.scopes)

  async function refresh() {
    if (!currentAssessment || refreshLock) return
    refreshLock = true

    try {
      await refreshAssessmentEntries(currentAssessment.id)
    } finally {
      refreshLock = false
    }
  }

  useInterval(refresh, currentAssessment && isInProgressState(currentAssessment.state) ? 2000 : null)

  const renderHeader = () => {
    return (
      <AppHeader
        menuSection={"locationAssessment"}
        assessment={currentAssessment}
        assessmentEntry={null}
        module={module}
        isLoading={assessmentLoadInProgress}
        recalculationButton={true}
      >
        <AssessmentRename toRename={toRename || null} onClose={() => setToRename(undefined)} />
        <Flex flexDirection="row-reverse" gap={16}>
          <DocumentationAndSupport tooltip={t.helpAndSupport} onClick={() => {}} />
          <HorizontalDivider height={32} />
          <AssessmentDownloadScore />
          <HorizontalDivider height={32} />
          <Tooltip placement="bottom" tooltip={t.header.multiAddressRenameTooltip}>
            <a
              href={"#"}
              onClick={(e) => {
                e.preventDefault()
                setToRename(currentAssessment || undefined)
              }}
            >
              <Icon name="edit" size={"large"} />
            </a>
          </Tooltip>
        </Flex>
      </AppHeader>
    )
  }

  const renderSubHeader = () => {
    return (
      <Grid
        className={gridClassName}
        columns={4}
        backgroundColor={{ color: "background", colorType: "lighter" }}
        padding={16}
      >
        <GridItem justifySelf="left" padding={[0, 0, 0, 8]}>
          <Flex flexDirection="row" gap={8} alignItems={"center"}>
            {entryListViewMode == "list" && (
              <>
                <div>{t.assessment.search}</div>
                <div style={{ paddingRight: "8px" }}>
                  <TextField
                    value={assessmentDetailsSearchTerm}
                    onValueChange={setAssessmentDetailsSearchTerm}
                    size="small"
                  />
                </div>
              </>
            )}
            <div>{t.assessment.tags}</div>
            <TagFilterSelect
              totalCount={assessmentEntries?.entries.length || 0}
              tags={countTags()}
              selectedTag={tagFilter}
              onSelectTag={setAssessmentTagFilter}
            />
          </Flex>
        </GridItem>
        <GridItem justifySelf="center" minWidth={[300, "px"]}>
          <div className={listOrMapButton}>
            <ButtonTabs
              values={AssessmentEntryListViewModes}
              selected={entryListViewMode}
              onSelect={setAssessmentEntryListViewMode}
              translate={(viewMode) => t.assessmentDetails.viewMode[viewMode]}
            />
          </div>
        </GridItem>
        {lanaScopes.assessment && currentAssessment && (
          <GridItem justifySelf="center">
            <Flex flexDirection="row" alignItems="center" gap={8}>
              <div style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{t.assessment.macroScoreContext}:</div>
              <Select
                size="small"
                value={currentAssessment?.macroContext || ""}
                options={[
                  { value: "", label: t.assessment.macroScoreDefaultContext },
                  ...(availableMacroContexts || []).map((selection) => ({
                    value: selection.id,
                    label: selection.name,
                  })),
                ]}
                onValueChange={(value) => setMacroContext(currentAssessment.id, value)}
              />
            </Flex>
          </GridItem>
        )}
      </Grid>
    )
  }

  const renderContent = () => {
    return (
      <Grid columns={1} height={[100, "%"]} rowSpec="fit-content(100%) minmax(0, 1fr)" gap={16} padding={[0, 0, 16, 0]}>
        {renderSubHeader()}
        {assessmentLoadInProgress && <LoadingSpinner />}
        {assessmentLoadError && (
          <CenteredElement>
            <GenericErrorPanel error={assessmentLoadError} />
          </CenteredElement>
        )}
        {!assessmentLoadInProgress && !assessmentLoadError && currentAssessment && entryListViewMode === "list" && (
          <AssessmentEntryList
            searchTerm={assessmentDetailsSearchTerm}
            tagFilter={tagFilter}
            assessmentStateLoading={assessmentUpdateInProgress || isInProgressState(currentAssessment.state)}
            module={module}
          />
        )}
        {!assessmentLoadInProgress && !assessmentLoadError && entryListViewMode === "map" && (
          <AssessmentEntryMap tagFilter={tagFilter} module={module} />
        )}
      </Grid>
    )
  }

  const countTags: () => TagWithCount[] = () => {
    if (!assessmentEntries) return []

    const map = new Map<string, TagWithCount>()

    for (const entry of assessmentEntries.entries) {
      for (const tag of entry.tags) {
        const tagWithCount = map.get(tag)

        if (tagWithCount) tagWithCount.count += 1
        else map.set(tag, { name: tag, count: 1 })
      }
    }

    const tagWithCounts = Array.from(map.values())

    tagWithCounts.sort((a, b) => a.name.localeCompare(b.name))

    return tagWithCounts
  }

  return (
    <Grid columns={1} height={[100, "vh"]} rowSpec="fit-content(100%) minmax(0, 1fr)" rowGap={4}>
      {renderHeader()}
      {renderContent()}
    </Grid>
  )
}
