import * as React from "react"
import { useSelector } from "react-redux"
import { ProfileData } from "../models/profile"
import { translations } from "../i18n"
import { Translations } from "../i18n/translations"
import * as cookie from "cookie"
import { UserAccountState } from "../user-profile-slice"
import { saveLanguage } from "../user-profile-slice"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import SelectXlWidget from "../../shared/components/selectxlwidget"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"
import { getCurrentDomain } from "../../shared/helper/domain"

export const LanguagePicker = () => {
  const t = React.useMemo(translations, [translations])

  const profileData = useSelector((state: UserAccountState) => state.userProfile.profileData)
  const inFlight = useSelector((state: UserAccountState) => state.userProfile.changeLanguageInProgress)
  const changeLanguageError = useSelector((state: UserAccountState) => state.userProfile.changeLanguageError)

  const onSave = (newLanguage: string) => {
    const domain = () => {
      const domainAndPort = getCurrentDomain(window.location)
      return "." + domainAndPort.split(":")[0]
    }
    const secure = () => {
      return window.location.hostname !== "localhost"
    }

    saveLanguage(newLanguage).then(
      (ok) => {
        const expires = new Date()
        expires.setFullYear(expires.getFullYear() + 2)
        const languageCookie = cookie.serialize("SETTINGS_LANGUAGE", newLanguage, {
          path: "/",
          secure: secure(),
          domain: domain(),
          expires: expires,
        })
        document.cookie = languageCookie
        window.location.reload()
      },
      (error) => console.log("Could not set language")
    )
  }

  const renderLanguageSelection = (profileData: ProfileData, t: Translations) => {
    const options = [
      { value: "de", label: t.settings.language.de },
      { value: "en", label: t.settings.language.en },
    ]
    return (
      <div>
        {changeLanguageError && (
          <GenericErrorPanel hint={t.genericError.languageChangeErrored} error={changeLanguageError} />
        )}
        <SelectXlWidget
          value={profileData.profile.language.toLowerCase()}
          options={options}
          saveInProgress={inFlight}
          onSave={onSave}
        />
      </div>
    )
  }

  return (
    <FlexContainer direction="column">{profileData ? renderLanguageSelection(profileData, t) : null}</FlexContainer>
  )
}
