import React, { useEffect, useMemo } from "react"
import { AppModules } from "../../menu/util/app-location-types"
import { css, cx } from "emotion"
import { useSelector } from "react-redux"
import { State } from "../../assessment/reducers/state"
import { useAppDispatch, useAppSelector } from "../../relas/store"
import { Panel, PanelStack2 } from "@blueprintjs/core"
import { RatingList } from "./rating-list"
import { COLORS } from "../../shared/components/ui/colors"
import { RatingManagerMap } from "./map/rating-manager-map"
import { RatingDetailsExplanation } from "./assessment-section/ratings-details-explanation"
import { DocumentationAndSupport } from "../../shared/components/documentation-and-support"
import { translations } from "../i18n"
import { DownloadReport } from "../../shared/components/ui/download-report"
import { ratingManagerActions } from "../rating-manager-slice"
import { AllowedModulesEnum } from "../../private-data/models/private-data"
import Grid from "../../shared/components/restyle-grid/grid"
import GridItem from "../../shared/components/restyle-grid/griditem"
import { featureEnabled, HELP_DEVELOPMENT } from "../../utils/features-toggle"
import { navigateTo } from "../../shared/reducers/navigation-slice"
import { NavigationPage } from "../../shared/actions/navigation"
import LanaSubheader from "../../shared/components/lana-subheader"

const styles = {
  stickyHeader: css({
    position: "sticky",
    top: 0,
    background: "white",
    zIndex: 100,
  }),
  borderPortal: css({
    outline: "1px solid",
    outlineColor: COLORS.border.default,
  }),
  list: css({
    height: "100%",
  }),
}

interface Props {
  module: AppModules["locationAssessment"]
  assessmentId: string
  assessmentEntryId: string | null
}

export const ratingAppDocumentationLink =
  "https://docs.google.com/document/d/103IXyISowpVPt4Rg9sJ8J6ddAoMKp_yXpMgUDXgYlWc"

export const RatingsApp = ({ module }: Props) => {
  const t = useMemo(() => translations(), [])
  const assessment = useSelector((state: State) => state.assessment.currentAssessment)
  const currentAssessmentEntry = useAppSelector((state) => state.assessment.currentAssessmentEntry)
  const assessmentLoadInProgress = useSelector((state: State) => state.assessment.assessmentLoadInProgress)
  const assessmentEntryLoadInProgress = useSelector((state: State) => state.assessment.assessmentEntryLoadInProgress)

  const dispatch = useAppDispatch()

  const ratingsStack: Panel<any>[] = useMemo(
    () => [
      {
        renderPanel: () =>
          RatingList({
            assessmentEntry: currentAssessmentEntry,
          }),
      },
    ],
    [currentAssessmentEntry]
  )

  useEffect(() => {
    dispatch(ratingManagerActions.setCurrentRating(undefined))
  }, [])

  const helpDevelopment = featureEnabled(HELP_DEVELOPMENT)

  return (
    <Grid columns={1} height={[100, "%"]} rowSpec="fit-content(100%) minmax(0, 1fr)">
      <div className={styles.stickyHeader}>
        <LanaSubheader
          menuSection={"locationAssessment"}
          assessment={assessment}
          assessmentEntry={currentAssessmentEntry}
          module={module}
          isLoading={assessmentLoadInProgress || assessmentEntryLoadInProgress}
          recalculationButton={true}
          recalculationDetails={true}
        >
          <DocumentationAndSupport
            documentationURL={helpDevelopment ? undefined : ratingAppDocumentationLink}
            tooltip={t.helpAndSupport}
            callLocation="Price Widget"
            addDocumentationLink
            onClick={
              helpDevelopment
                ? () => navigateTo({ name: NavigationPage.help, params: { section: "ratings" } })
                : () => {}
            }
          />
          <DownloadReport />
        </LanaSubheader>
      </div>
      <Grid columnSpec="3fr 4fr 5fr" rowSpec="1fr min-content">
        <GridItem rowSpan={1}>
          <PanelStack2
            className={cx(styles.list, styles.borderPortal)}
            stack={ratingsStack}
            showPanelHeader={false}
            renderActivePanelOnly={true}
          />
        </GridItem>
        <RatingDetailsExplanation />
        <GridItem rowSpan={2}>
          <Grid columns={1} rowSpec="1fr fit-content(100%)" height={[100, "%"]}>
            <RatingManagerMap
              hideMunicipalityListToggle={true}
              hideAddressInput={true}
              locationToPresent={
                currentAssessmentEntry?.address?.location ?? currentAssessmentEntry?.droppedLocation ?? undefined
              }
              module={AllowedModulesEnum.RATINGS}
            />
          </Grid>
        </GridItem>
      </Grid>
    </Grid>
  )
}
