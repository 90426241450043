import { BorderBottom } from "../../../shared/components/ui/border-bottom"
import { Grid } from "../../../shared/components/ui/grid"
import * as React from "react"
import { useAppSelector } from "../../../relas/store"
import { FundamentalDataMap } from "./fundamental-data-map"
import { translations } from "../../i18n"
import { css, cx } from "emotion"
import {
  clearSelectedData,
  DataLevel,
  FundamentalDataForEntry,
  initialiseFundamentalDataState,
  makeFundamentalDataQuery,
  resetSelectedData,
  updateCollapsedCategories,
  updateCollapsedTopics,
  updateDataForScores,
  updateDataLevel,
  updateFetchedStatus,
  updateIsochroneType,
  updateSelectedData,
  updateSelectedTileIds,
} from "../../reducers/fundamental-data-slice"
import DataListSelector from "./data-list-selector"
import { DataListViewer } from "./data-list-viewer"
import { DownloadReport } from "../../../shared/components/ui/download-report"
import { DocumentationAndSupport } from "../../../shared/components/documentation-and-support"
import { trackUsageEvent } from "../../../utils/usage-tracking"
import { IsochroneType } from "../isochrone-type"
import { styles as comparablesComponentStyles } from "../comparables/comparablesV2"
import ButtonTabs from "../../../shared/components/buttontabs"
import DropdownButton, { DropdownItemProps } from "../../../shared/components/dropdown-button"
import { getThemeColor } from "../../../shared/helper/color"
import { language } from "../../../shared/language"
import LoadingSpinner from "../../../shared/components/loadingspinner"
import Icon from "../../../shared/components/icon"
import LanaSubheader from "../../../shared/components/lana-subheader"

const dataLevels: DataLevel[] = ["zip", "municipality", "country"]

type ViewMode = "data" | "select"

const styles = {
  borderGrey: css({
    border: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
  }),
  borderGreyWithoutTop: css({
    borderBottom: "1px solid",
    borderLeft: "1px solid",
    borderRight: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
  }),
  dataList: css({
    fontSize: 14,
    border: "none",
  }),
}

const containerStyles = css({
  display: "flex",
  alignItems: "center",
  "div div button": {
    display: "flex",
  },
  "div div button i:first-child": {
    fontSize: "18px",
    marginRight: "0px",
    display: "initial",
  },
  "div div button i": {
    display: "none",
  },
  "div div button span": {
    display: "block",
  },
})

export const FundamentalDataApp = () => {
  const [t, sharedT] = React.useMemo(() => {
    const t = translations()
    return [t.fundamentalData, t]
  }, [language()])

  const assessment = useAppSelector((state) => state.assessment.currentAssessment)
  const assessmentEntry = useAppSelector((state) => state.assessment.currentAssessmentEntry)
  const assessmentLoadInProgress = useAppSelector((state) => state.assessment.assessmentLoadInProgress)
  const fundamentalData = useAppSelector((state) => state.fundamentalData.dataForEntries)

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [hasError, setHasError] = React.useState<boolean>(false)
  const [viewMode, setViewMode] = React.useState<ViewMode>("data")

  const fundamentalDataForEntry: FundamentalDataForEntry | null = assessmentEntry && fundamentalData[assessmentEntry.id]

  React.useEffect(() => {
    if (assessmentEntry) {
      void initialiseFundamentalDataState(assessmentEntry, !!fundamentalData[assessmentEntry.id])
    }
  }, [assessmentEntry?.id])

  React.useEffect(() => {
    if (assessmentEntry && fundamentalDataForEntry && fundamentalDataForEntry.dataFetchStatus === "need-fetching") {
      setIsLoading(true)

      updateDataForScores(assessmentEntry.id, makeFundamentalDataQuery(fundamentalDataForEntry), (success: boolean) => {
        setIsLoading(false)
        updateFetchedStatus(assessmentEntry.id, "fetched")
        setHasError(!success)
      })
    }
  }, [fundamentalDataForEntry?.dataFetchStatus])

  React.useEffect(() => {
    if (assessmentEntry && fundamentalDataForEntry && fundamentalDataForEntry.dataFetchStatus === "need-initial") {
      setIsLoading(true)
      const query = makeFundamentalDataQuery(fundamentalDataForEntry)

      if (query.zip.selectedTiles.length > 0 && query.municipality.selectedTiles.length > 0) {
        updateDataForScores(assessmentEntry.id, query, (success: boolean) => {
          setIsLoading(false)
          updateFetchedStatus(assessmentEntry.id, "fetched")
          setHasError(!success)
        })
      }
    }
  }, [
    fundamentalDataForEntry?.selectedTileIds.zip,
    fundamentalDataForEntry?.selectedTileIds.municipality,
    fundamentalDataForEntry?.dataFetchStatus,
  ])

  const resetActions = (): DropdownItemProps[] => {
    const resetArea = {
      title: t.resetArea,
      onClick: () => {
        if (assessmentEntry && fundamentalDataForEntry) {
          const selectedTilesObj = fundamentalDataForEntry.selectedTileIds
          if (fundamentalDataForEntry.dataLevel === "zip") {
            selectedTilesObj.zip.forEach((zip) => updateSelectedTileIds(assessmentEntry.id, "zip", zip, false))
            updateSelectedTileIds(assessmentEntry.id, "zip", undefined, true)
            updateSelectedTileIds(assessmentEntry.id, "zip", assessmentEntry.address.postalCode, true)
          } else if (fundamentalDataForEntry.dataLevel === "municipality") {
            selectedTilesObj.municipality.forEach((municipality) =>
              updateSelectedTileIds(assessmentEntry.id, "municipality", municipality, false)
            )
            updateSelectedTileIds(assessmentEntry.id, "municipality", assessmentEntry.agsRefResloc, true)
          }
        }
      },
    }
    const resetData = {
      title: t.resetData,
      onClick: () => {
        assessmentEntry &&
          fundamentalDataForEntry &&
          resetSelectedData(assessmentEntry.id, fundamentalDataForEntry.dataLevel)
      },
    }
    return [resetArea, resetData]
  }

  return (
    <Grid columns={1} height={[100, "%"]} rowSpec="fit-content(100%) 1fr">
      <LanaSubheader
        assessment={assessment}
        assessmentEntry={assessmentEntry}
        menuSection="locationAssessment"
        module="fundamentalData"
        isLoading={assessmentLoadInProgress}
      >
        <div style={{ display: "flex" }}>
          <DocumentationAndSupport
            tooltip={sharedT.helpAndSupport}
            callLocation="Fundamental Data"
            onClick={() => {}}
          />
        </div>
        <DownloadReport />
      </LanaSubheader>

      {!fundamentalDataForEntry || !assessmentEntry ? (
        <LoadingSpinner />
      ) : (
        <Grid columns={2} columnSpec="2fr 1fr" height={[100, "%"]}>
          <div className={styles.borderGreyWithoutTop}>
            <FundamentalDataMap
              selectedTileIds={fundamentalDataForEntry.selectedTileIds[fundamentalDataForEntry.dataLevel]}
              dataLevel={fundamentalDataForEntry.dataLevel}
              markerLocation={assessmentEntry.address.location}
              viewportSettings={fundamentalDataForEntry.viewportSettings}
              assessmentEntryId={assessmentEntry.id}
              isochroneSettings={fundamentalDataForEntry.isochroneSettings}
              setIsochroneSettings={(settings: IsochroneType) => updateIsochroneType(assessmentEntry.id, settings)}
            />
            {hasError && (
              <div className={comparablesComponentStyles.notifierStyle}>
                <span style={{ padding: "8px" }}>{t.loadingError}</span>
              </div>
            )}
          </div>
          <div className={cx(styles.borderGrey, css({ borderLeft: "none", borderTop: "none" }))}>
            <Grid columns={1} padding={12} gap={12} height={[100, "%"]} rowSpec="min-content 1fr">
              <ButtonTabs<DataLevel>
                values={dataLevels}
                selected={fundamentalDataForEntry.dataLevel}
                onSelect={(dataLevel) => {
                  if (assessmentEntry) {
                    updateDataLevel(assessmentEntry?.id, dataLevel)
                    trackUsageEvent("FUNDAMENTAL_DATA_SWITCH_LEVEL", assessmentEntry.address, dataLevel)
                  }
                }}
                translate={t.dataView}
              />
              <div className={styles.borderGrey}>
                <Grid columns={1} width={[100, "%"]} height={[100, "%"]} rowSpec="min-content 1fr">
                  <BorderBottom padding={12}>
                    <Grid columnSpec={"min-content 1fr"} gap={8}>
                      <ButtonTabs<ViewMode>
                        values={["data", "select"]}
                        selected={viewMode}
                        onSelect={(viewMode) => {
                          setViewMode(viewMode)
                          trackUsageEvent("FUNDAMENTAL_DATA_SWITCH_VIEW", assessmentEntry.address, viewMode)
                        }}
                        translate={(x) => t[x]}
                      />
                      <div className={containerStyles}>
                        <DropdownButton type={"tertiary"} actions={resetActions()}>
                          <Icon name="restore" size={"small"} />
                        </DropdownButton>
                      </div>
                    </Grid>
                  </BorderBottom>
                  <div className={styles.dataList}>
                    {viewMode === "select" ? (
                      <DataListSelector
                        dataLevel={fundamentalDataForEntry.dataLevel}
                        collapsedCategories={
                          fundamentalDataForEntry.collapsedCategories[fundamentalDataForEntry.dataLevel]
                        }
                        collapsedTopics={fundamentalDataForEntry.collapsedTopics[fundamentalDataForEntry.dataLevel]}
                        setCollapsedCategories={(categories) =>
                          updateCollapsedCategories(assessmentEntry.id, fundamentalDataForEntry.dataLevel, categories)
                        }
                        setCollapsedTopics={(topics) =>
                          updateCollapsedTopics(assessmentEntry.id, fundamentalDataForEntry.dataLevel, topics)
                        }
                        selected={fundamentalDataForEntry.selectedData[fundamentalDataForEntry.dataLevel]}
                        setSelectedData={(selected) =>
                          updateSelectedData(assessmentEntry.id, fundamentalDataForEntry.dataLevel, selected)
                        }
                        clearSelectedData={() =>
                          clearSelectedData(assessmentEntry.id, fundamentalDataForEntry.dataLevel)
                        }
                      />
                    ) : (
                      <DataListViewer
                        selectedData={fundamentalDataForEntry.selectedData[fundamentalDataForEntry.dataLevel]}
                        scores={fundamentalDataForEntry.dataViewScores[fundamentalDataForEntry.dataLevel]}
                        isLoading={isLoading}
                      />
                    )}
                  </div>
                </Grid>
              </div>
            </Grid>
          </div>
        </Grid>
      )}
    </Grid>
  )
}
