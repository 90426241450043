import * as React from "react"
import { IconName } from "@21re/revoicons/dist/icon-type"
import { css, cx } from "emotion"
import LoadingSpinner from "./loadingspinner"
import { EmotionCSS } from "../helper/responsive"
import { getThemeColorVar } from "../helper/color"
import Icon from "./icon"

export type ButtonType = {
  type: "primary" | "secondary" | "tertiary"
  inverted?: boolean
  shadow?: boolean
}

export type ButtonOrLinkProps =
  | {
      href: string
      target?: "_self" | "_blank" | "_parent" | "_top"
      formType?: undefined
      download?: string
    }
  | {
      href?: undefined
      target?: undefined
      formType?: "button" | "submit" | "reset"
      download?: undefined
    }

export type ButtonIconProps = {
  icon?: IconName
  loading?: boolean
}

export type ButtonBaseProps = {
  id?: string
  children?: any
  danger?: boolean
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLElement>
  tabIndex?: number
  title?: string
  size?: "normal" | "small" | "tiny"
  icon?: IconName
  href?: string
}

const reButtonClass = css({
  display: "inline-block",
  borderRadius: "4px 4px 4px 4px",
  backgroundRepeat: "no-repeat",
  cursor: "pointer",
  textAlign: "center",
  whiteSpace: "nowrap",
  outline: "none",

  fontSize: "16px",
  lineHeight: "24px",
  padding: "7px 16px",

  borderWidth: "1px",
  borderStyle: "solid",
  i: {
    fontSize: "24px",
    marginRight: "7px",
  },
  ".revo-loading-spinner": {
    marginRight: "7px",
  },
})

const reButtonSmallClass = css({
  fontSize: "14px",
  padding: "3px 10px",
  i: {
    marginRight: "5px",
    fontSize: "20px",
  },
  ".revo-loading-spinner": {
    marginRight: "5px",
  },
})

const reButtonTinyClass = css({
  fontSize: "12px",
  lineHeight: "20px",
  i: {
    marginRight: "5px",
    fontSize: "20px",
    lineHeight: "20px",
  },
  ".revo-loading-spinner": {
    marginRight: "5px",
  },
})

const reButtonTinyPaddingClass = css({
  padding: "1px 4px",
})

const reButtonShadowClass = css({
  boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.2)",
})

export type ButtonProps = ButtonBaseProps & ButtonType & ButtonOrLinkProps & ButtonIconProps

const primaryCssColorProps: EmotionCSS = {
  backgroundColor: getThemeColorVar("primary", "default"),
  color: getThemeColorVar("white", undefined),
  borderColor: getThemeColorVar("primary", "default"),
}

const primaryDisabledCssColorProps: EmotionCSS = {
  "&:disabled": {
    backgroundColor: getThemeColorVar("disabled", "default"),
    borderColor: getThemeColorVar("disabled", "default"),
  },
}

const disabledCursorCssProps: EmotionCSS = {
  "&:disabled": {
    cursor: "default",
  },
}

const primaryClass = css(primaryCssColorProps, primaryDisabledCssColorProps, disabledCursorCssProps)

const primaryDangerClass = css({
  backgroundColor: getThemeColorVar("negative", "default"),
  borderColor: getThemeColorVar("negative", "default"),
})

const primaryInvertedCssColorProps: EmotionCSS = {
  color: getThemeColorVar("primary", "default"),
  backgroundColor: getThemeColorVar("white", undefined),
  borderColor: getThemeColorVar("white", undefined),
}

const primaryInvertedDisabledCssColorProps: EmotionCSS = {
  "&:disabled": {
    color: getThemeColorVar("disabled", "default"),
    backgroundColor: getThemeColorVar("white", undefined),
    borderColor: getThemeColorVar("white", undefined),
  },
}

const primaryInvertedClass = css(
  primaryInvertedCssColorProps,
  primaryInvertedDisabledCssColorProps,
  disabledCursorCssProps
)

const primaryInvertedDangerClass = css({
  backgroundColor: getThemeColorVar("white", undefined),
  color: getThemeColorVar("negative", "default"),
  borderColor: getThemeColorVar("white", undefined),
})

const secondaryCssColorProps: EmotionCSS = {
  backgroundColor: "Transparent",
  color: getThemeColorVar("primary", "default"),
  borderColor: getThemeColorVar("primary", "default"),
}

const secondaryDisabledCssColorProps: EmotionCSS = {
  "&:disabled": {
    color: getThemeColorVar("disabled", "default"),
    borderColor: getThemeColorVar("disabled", "default"),
  },
}

const secondaryClass = css(secondaryCssColorProps, secondaryDisabledCssColorProps, disabledCursorCssProps)

const secondaryDangerClass = css({
  color: getThemeColorVar("negative", "default"),
  borderColor: getThemeColorVar("negative", "default"),
})

const tertiaryCssColorProps: EmotionCSS = {
  backgroundColor: "Transparent",
  color: getThemeColorVar("primary", "default"),
}

const tertiaryCssOtherProps: EmotionCSS = {
  fontSize: "14px",
  border: 0,
  padding: 0,
}

const tertiaryDisabledCssColorProps: EmotionCSS = {
  "&:disabled": {
    color: getThemeColorVar("disabled", "default"),
  },
}

const tertiaryClass = css(
  tertiaryCssColorProps,
  tertiaryCssOtherProps,
  tertiaryDisabledCssColorProps,
  disabledCursorCssProps
)

const tertiaryInvertedCssColorProps: EmotionCSS = {
  color: getThemeColorVar("primary", "inverted"),
  backgroundColor: "Transparent",
  fontSize: "14px",
}

const tertiaryInvertedCssOtherProps = css({
  border: 0,
  padding: 0,
})

const tertiaryInvertedClass = css(tertiaryInvertedCssColorProps, tertiaryInvertedCssOtherProps)

const tertiaryDangerClass = css({
  color: getThemeColorVar("negative", "default"),
})

const linkClass = css({
  "&:hover, &:focus, &:active": {
    outline: 0,
    textDecoration: "none",
  },
})

const smallIconClass = css({
  paddingLeft: "5px",
})

const smallOnlyIconClass = css({
  paddingLeft: "5px",
  paddingRight: "5px",
  i: {
    marginRight: 0,
  },
  ".revo-loading-spinner": {
    marginRight: 0,
  },
})

const iconClass = css({
  paddingLeft: "7px",
})

const onlyIconClass = css({
  paddingLeft: "7px",
  paddingRight: "7px",
  i: {
    marginRight: 0,
  },
  ".revo-loading-spinner": {
    marginRight: 0,
  },
})

const loadingClass = css({
  opacity: 0.7,
  cursor: "not-allowed",
})

function buildTypeClasses({ type, inverted, danger }: ButtonProps): string[] {
  const typeProps: string[] = []
  switch (type) {
    case "primary":
      if (inverted) {
        typeProps.push(primaryInvertedClass)
        if (danger) {
          typeProps.push(primaryInvertedDangerClass)
        }
      } else {
        typeProps.push(primaryClass)
        if (danger) {
          typeProps.push(primaryDangerClass)
        }
      }
      break
    case "secondary":
      typeProps.push(secondaryClass)
      if (danger) {
        typeProps.push(secondaryDangerClass)
      }
      break
    case "tertiary":
      if (inverted) {
        typeProps.push(tertiaryInvertedClass)
      } else {
        typeProps.push(tertiaryClass)
      }
      if (danger) {
        typeProps.push(tertiaryDangerClass)
      }
      break
  }

  return typeProps
}

function buildSizeClasses({ size, type }: ButtonProps): string[] {
  const classes: string[] = []
  if (size == "small") {
    classes.push(reButtonSmallClass)
  } else if (size == "tiny") {
    classes.push(reButtonTinyClass)
    if (type === "primary" || type === "secondary") classes.push(reButtonTinyPaddingClass)
  }
  return classes
}

function buildShadowClasses({ shadow }: ButtonProps): string[] {
  if (shadow) {
    return [reButtonShadowClass]
  }
  return []
}

function buildIconClasses({ type, icon, size, children }: ButtonProps): string[] {
  const classes: string[] = []
  if (type !== "tertiary") {
    if (icon && React.Children.count(children) === 0) {
      if (size == "small" || size == "tiny") {
        classes.push(smallOnlyIconClass)
      } else {
        classes.push(onlyIconClass)
      }
    } else if (icon) {
      if (size == "small" || size == "tiny") {
        classes.push(smallIconClass)
      } else {
        classes.push(iconClass)
      }
    }
  }
  return classes
}

function buildLoadingClasses({ type, inverted, danger, loading }: ButtonProps): string[] {
  if (!loading) {
    return []
  }

  if (type === "primary" && inverted && danger) {
    const overridingClass = css({ "&:disabled": primaryInvertedDangerClass })
    return [loadingClass, overridingClass]
  }
  if (type === "primary" && danger) {
    const overridingClass = css({ "&:disabled": primaryDangerClass })
    return [loadingClass, overridingClass]
  }
  if (type === "primary" && inverted) {
    const overridingClass = css({ "&:disabled": primaryInvertedClass })
    return [loadingClass, overridingClass]
  }
  if (type === "primary") {
    const overridingClass = css({ "&:disabled": primaryCssColorProps })
    return [loadingClass, overridingClass]
  }
  if (type === "secondary" && danger) {
    const overridingClass = css({ "&:disabled": secondaryDangerClass })
    return [loadingClass, overridingClass]
  }
  if (type === "secondary") {
    const overridingClass = css({ "&:disabled": secondaryClass })
    return [loadingClass, overridingClass]
  }
  if (type === "tertiary" && danger) {
    const overridingClass = css({ "&:disabled": tertiaryDangerClass })
    return [loadingClass, overridingClass]
  }
  if (type === "tertiary") {
    const overridingClass = css({ "&:disabled": tertiaryCssColorProps })
    return [loadingClass, overridingClass]
  }
  return [loadingClass]
}

function renderIcon(props: ButtonProps): JSX.Element | undefined {
  if (props.loading) {
    return (
      <LoadingSpinner
        alignLeft={true}
        size={props.size == "small" || props.size == "tiny" ? 20 : 24}
        color={props.danger ? "negative" : "primary"}
        inverted={props.type === "primary" && !props.inverted}
      />
    )
  } else if (props.icon) {
    return <Icon name={props.icon} />
  } else {
    return undefined
  }
}

export const Button: React.FunctionComponent<ButtonProps> = (props) => {
  const classes = [reButtonClass].concat(
    buildTypeClasses(props),
    buildSizeClasses(props),
    buildShadowClasses(props),
    buildIconClasses(props),
    buildLoadingClasses(props)
  )

  const p = {
    id: props.id,
    onClick: (e: React.MouseEvent<HTMLElement>) => {
      !props.disabled && !props.loading && props.onClick && props.onClick(e)
    },
    disabled: props.disabled || props.loading,
    tabIndex: props.tabIndex,
    title: props.title,
  }

  if (props.href && !props.disabled) {
    return props.download === undefined ? (
      <a {...p} className={cx(classes, linkClass)} href={props.href} target={props.target}>
        {renderIcon(props)}
        {props.children}
      </a>
    ) : (
      <a {...p} className={cx(classes, linkClass)} href={props.href} target={props.target} download={props.download}>
        {renderIcon(props)}
        {props.children}
      </a>
    )
  } else {
    return (
      <button {...p} className={cx(classes)} type={props.formType}>
        {renderIcon(props)}
        {props.children}
      </button>
    )
  }
}

Button.defaultProps = {
  formType: "submit",
}

export default Button
