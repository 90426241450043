import { getCookieValue } from "./helper/cookies"
import { getCurrentDomain, isLocalhost } from "./helper/domain"
import * as cookie from "cookie"

const LANGUAGE_COOKIE = "SETTINGS_LANGUAGE"

/**
 * @returns {string} returns user language, default is 'en'
 */
export function language(): string {
  let lang =
    getCookieValue(LANGUAGE_COOKIE) ||
    (typeof sessionStorage !== "undefined" && sessionStorage.getItem(LANGUAGE_COOKIE))
  if (lang) {
    return lang
  }

  const twentyOneWindow = (window as any).twentyone

  if (twentyOneWindow && twentyOneWindow.language && typeof twentyOneWindow.language === "string") {
    lang = twentyOneWindow.language as string
  } else {
    lang = navigator.language.substring(0, 2)
  }

  if (["en", "de"].indexOf(lang.toLowerCase()) >= 0) {
    return lang
  }

  return "en"
}

function domain(): string {
  const domainAndPort = getCurrentDomain(window.location)

  return isLocalhost(window.location) ? "localhost" : "." + domainAndPort.split(":")[0]
}

export function switchLanguage(language: string): void {
  const expires = new Date()
  expires.setFullYear(expires.getFullYear() + 2)
  const languageCookie = cookie.serialize(LANGUAGE_COOKIE, language, {
    path: "/",
    secure: false,
    domain: domain(),
    expires: expires,
  })
  document.cookie = languageCookie

  window.location.reload()
}
