import { css, cx } from "emotion"
import * as React from "react"
import {
  isRuleCategoryWithField,
  RuleCategories,
  RuleCategory,
  RatingSelectionRule,
} from "../../shared/models/selection"
import { GlobalState, useAppSelector } from "../../relas/store"
import { CategorizedScores, DataSetType, LanaNodeWithVariation } from "../../shared/models/smartdata"
import { translations } from "../i18n"
import { categorize } from "../../shared/models/smartdata"
import { BorderBottom } from "../../shared/components/ui/border-bottom"
import { Flex } from "../../shared/components/ui/flex"
import { FlexItem } from "../../shared/components/ui/flex-item"
import { Grid } from "../../shared/components/ui/grid"
import { GridItem } from "../../shared/components/ui/grid-item"
import { ScrollBox } from "../../shared/components/ui/scroll-box"
import { Profile } from "../../profile/models/profile"
import { HelpPopover } from "../../shared/components/help-popover"
import { Tooltip } from "../../shared/components/ui/tooltip"
import { Collapse } from "@blueprintjs/core"
import { Button as ButtonBP } from "@blueprintjs/core/lib/esm/components/button/buttons"
import { FieldOption, getFieldOptions } from "../../shared/actions/field-option-actions"
import { FieldOptionSuggest } from "../../shared/components/field-option-suggest"
import { FieldOptionMultiSelect } from "../../shared/components/field-option-multi-select"
import { LANA_AGS_NODES, LANA_CELL_NODES, LanaNode } from "../../shared/smartdata-products/smartdata"
import { clearUpdateSelectionError, setAgsRefResLoc } from "../location-selector-slice"
import { RadioButton } from "../../shared/components/ui/radio-button"
import { NumberInput } from "../../shared/components/ui/number-input"
import { maxValidator, minValidator, sequentialValidator } from "../../utils/utils"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import Checkbox from "../../shared/components/checkbox"
import Select from "../../shared/components/select"
import LoadingSpinner from "../../shared/components/loadingspinner"
import Expandable from "../../shared/components/expandable"
import Button from "../../shared/components/button"
import { getThemeColorVar } from "../../shared/helper/color"
import Icon from "../../shared/components/icon"

export const scoreGroupsClass = css({
  "> div": {
    borderLeft: "none",
    borderRight: "none",
    borderTop: "none",
  },
  "> div:first-child": {
    borderLeft: "none",
    borderRight: "none",
    borderTop: `1px solid ${getThemeColorVar("border", "default")}`,
  },
})

export const scoreInGroupClass = css({
  "> div": {
    borderLeft: "none",
    borderRight: "none",
    borderTop: `1px solid ${getThemeColorVar("border", "default")}`,
  },
  "> div:first-child": {
    borderTop: "none",
  },
})

const profileClass = css({
  borderBottom: `1px solid ${getThemeColorVar("border", "default")}`,
  padding: "8px 16px ",
})

const arrowClass = css({
  transition: "transform 0.4s",
})
const arrowExpandedClass = css({
  transform: "rotateZ(180deg)",
})

const buttonScoreVariationClass = css({
  ":focus": { outline: "none" },
})

const styles = {
  groupHeader: (isActive: boolean, isGroupEmpty?: boolean) =>
    css({
      borderBottom: `1px solid ${getThemeColorVar("border", "default")}`,
      padding: "8px 16px ",
      display: "flex",
      justifyContent: "space-between",
      cursor: isGroupEmpty ? "default" : "pointer",
      backgroundColor: isActive && !isGroupEmpty ? getThemeColorVar("background", "lighter") : undefined,
      height: "42px",
      alignItems: "center",
    }),
  groupName: css({
    fontSize: "14px",
    fontWeight: "bold",
  }),
  chevronWrap: css({
    cursor: "pointer",
  }),
}

type Props = {
  rule: RatingSelectionRule | null
  dataSetType: DataSetType
  onClose: () => void
  onEdited: (rule: RatingSelectionRule) => void
}

type RuleItem = {
  body: React.ReactNode | React.ReactNode[] | null
  options: React.ReactNode | React.ReactNode[] | null
}

const selectedRuleCategory = (rule: RatingSelectionRule | null, ruleCategories: RuleCategory[]): RuleCategory => {
  if (rule === null) return ruleCategories[0]

  const result =
    rule.type === "rangefilter" || rule.type === "prefixfilter" || rule.type === "oneoffilter"
      ? ruleCategories.find((rc) => isRuleCategoryWithField(rc) && rc.field === rule.field)
      : ruleCategories.find((rc) => rc.type === rule.type)

  return result ?? ruleCategories[0]
}

const rangeFromForFule = (rule: RatingSelectionRule | null): number | undefined => {
  return rule && rule.type === "rangefilter" ? rule.from : undefined
}

const rangeToForFule = (rule: RatingSelectionRule | null): number | undefined => {
  return rule && rule.type === "rangefilter" ? rule.to : undefined
}

const profileFromForRule = (rule: RatingSelectionRule | null): number => {
  return rule && (rule.type === "rankbyprofile" || rule.type === "rankbyscores") ? rule.from : 0
}

const profileToForRule = (rule: RatingSelectionRule | null): number => {
  return rule && (rule.type === "rankbyprofile" || rule.type === "rankbyscores") ? rule.to : 100
}

const rankValueForRule = (rule: RatingSelectionRule | null): number | undefined => {
  return rule && rule.type == "selectranks" ? rule.value : undefined
}

export const SelectionRuleEdit: React.FC<Props> = (props: Props) => {
  const t = React.useMemo(() => translations(), [])

  const updateInProgress = useAppSelector((state: GlobalState) => state.locationSelector.updateSelectionLoading)
  const updateError = useAppSelector((state: GlobalState) => state.locationSelector.updateSelectionError)
  const availableProfiles = useAppSelector((state: GlobalState) => state.locationSelector.availableProfiles)
  const availableProfileInProgress = useAppSelector(
    (state: GlobalState) => state.locationSelector.availableProfilesLoading
  )
  const profileGroups = useAppSelector((state: GlobalState) => state.locationSelector.profileGroups)

  const ruleCategories = React.useMemo(
    () => RuleCategories(props.dataSetType).filter((r) => r.id !== "districtdata"),
    [props.dataSetType]
  )

  const [ruleCategory, setRuleCategory] = React.useState<RuleCategory>(selectedRuleCategory(props.rule, ruleCategories))
  const [exclusive, setExclusive] = React.useState<boolean>(false)
  const [rangeFrom, setRangeFrom] = React.useState<number | undefined>(rangeFromForFule(props.rule))
  const [rangeTo, setRangeTo] = React.useState<number | undefined>(rangeToForFule(props.rule))
  const [oneOfSelections, setOneOfSelections] = React.useState<string[]>([])
  const [oneOfOptions, setOneOfOptions] = React.useState<FieldOption[]>([])
  const [selectedProfileId, setSelectedProfileId] = React.useState<string | undefined>(undefined)
  const [profileFrom, setProfileFrom] = React.useState<number>(profileFromForRule(props.rule))
  const [profileTo, setProfileTo] = React.useState<number>(profileToForRule(props.rule))
  const [rankRelative, setRankRelative] = React.useState<boolean>(true)
  const [rankFromTop, setRankFromTop] = React.useState<boolean>(true)
  const [rankValue, setRankValue] = React.useState<number | undefined>(rankValueForRule(props.rule))
  const [rankRescale, setRankRescale] = React.useState<boolean>(false)
  const [exampleAgsRefResLoc, setExampleAgsRefResLoc] = React.useState<string>("")
  const [agsRefResLocOptions, setAgsRefResLocOptions] = React.useState<FieldOption[]>([])
  const [showScoresWithVariations, setShowScoresWithVariations] = React.useState<Set<string>>(new Set())
  const [collapsedGroup, setCollapsedGroup] = React.useState<string[]>([])
  const [isInitCollapsedGroupSeated, setIsInitCollapsedGroupSeated] = React.useState<boolean>(false)

  const getAllScores: () => LanaNodeWithVariation[] | undefined = () => {
    return findScoreList()?.reduce((acc: LanaNode[], c) => [...acc, ...c.scores], [])
  }

  const findScoreList = () => {
    switch (props.dataSetType) {
      case "macro":
        return categorize(t, LANA_AGS_NODES)
      case "micro":
        return categorize(t, LANA_CELL_NODES)
      default:
        return null
    }
  }

  const getSelectedScore: (scoreName: string) => {
    scoreOpt: LanaNode | undefined
    scoreOptWithVariation: LanaNodeWithVariation | undefined
  } = (scoreName: string) => {
    const scoreNameMatches = (node: LanaNodeWithVariation) => node.scoreName === scoreName || node.rawName === scoreName
    const scoreNameMatchesWithVariants = (node: LanaNodeWithVariation) =>
      node.scoreVariation ? !!node.scoreVariationNames?.some(scoreNameMatches) : scoreNameMatches(node)

    const scoreOpt = getAllScores()?.find(scoreNameMatchesWithVariants)
    const scoreOptWithVariation =
      scoreOpt && scoreOpt.scoreVariationNames && scoreOpt.scoreVariationNames.find(scoreNameMatches)

    return { scoreOptWithVariation, scoreOpt }
  }

  let initialSedlectedScore: LanaNodeWithVariation | undefined
  if (props.rule && props.rule.type === "rankbyscores") {
    let selectedScoreName = Object.getOwnPropertyNames(props.rule.scores).pop()

    if (selectedScoreName) {
      const scores = getSelectedScore(selectedScoreName)
      initialSedlectedScore = scores.scoreOptWithVariation || scores.scoreOpt
    }
  }

  const [selectedScore, setSelectedScore] = React.useState<LanaNodeWithVariation | undefined>(initialSedlectedScore)

  React.useEffect(() => {
    fetchOptions()
    clearUpdateSelectionError()
  }, [])

  React.useEffect(() => {
    setRuleCategory(ruleCategories[0])
  }, [props.dataSetType])

  React.useEffect(() => {
    fetchOptions()
  }, [ruleCategory])

  React.useEffect(() => {
    updateStateFromRule(props)
  }, [props.rule])

  const fetchOptions = () => {
    if (!isRuleCategoryWithField(ruleCategory)) return

    if (ruleCategory.type !== "oneoffilter") {
      setOneOfOptions([])
    }

    if (ruleCategory.field) {
      getFieldOptions(props.dataSetType, ruleCategory.field)
        .then(setOneOfOptions)
        .catch(() => {})
    }
  }

  const findRuleCategory: (rule: RatingSelectionRule) => RuleCategory = (rule: RatingSelectionRule) => {
    const ruleCategory =
      rule.type === "prefixfilter" || rule.type === "rangefilter" || rule.type === "oneoffilter"
        ? ruleCategories.find(
            (category) =>
              isRuleCategoryWithField(category) && category.type === rule.type && rule.field.match(category.field)
          )
        : ruleCategories.find((category) => category.type === rule.type)

    return ruleCategory || ruleCategories[0]
  }

  const updateStateFromRule = (props: Props) => {
    const { rule, dataSetType } = props

    if (!rule) return

    switch (rule.type) {
      case "loadmicrodata":
        getFieldOptions("micro", "ags_ref_res_loc")
          .then(setAgsRefResLocOptions)
          .catch(() => {})
        setRuleCategory(findRuleCategory(rule))
        setExampleAgsRefResLoc(rule.exampleAgsRefResLoc)
        setAgsRefResLoc(rule.exampleAgsRefResLoc)

        break
      case "rangefilter":
        let maybeScoreWithVariation: LanaNode | undefined
        const maybeScore = getAllScores()?.find((s) =>
          s.scoreVariation
            ? (maybeScoreWithVariation = s.scoreVariationNames?.find((s) => s.rawName === rule.field))
            : s.rawName === rule.field
        )

        let rangeFrom: number | undefined
        let rangeTo: number | undefined

        if (maybeScore?.unit?.en === "%") {
          rangeFrom = rule.from !== undefined ? rule.from * 100 : undefined
          rangeTo = rule.to !== undefined ? rule.to * 100 : undefined
        } else {
          rangeFrom = rule.from !== undefined ? rule.from : undefined
          rangeTo = rule.to !== undefined ? rule.to : undefined
        }

        setRuleCategory(findRuleCategory(rule))
        setExclusive(rule.exclusive)
        setRangeFrom(rangeFrom)
        setRangeTo(rangeTo)
        setSelectedScore(maybeScoreWithVariation ?? maybeScore)
        setShowScoresWithVariations(
          maybeScoreWithVariation
            ? new Set<string>().add(maybeScoreWithVariation?.rawName)
            : maybeScore
            ? new Set<string>().add(maybeScore?.rawName)
            : new Set<string>()
        )

        break
      case "oneoffilter":
        getFieldOptions(dataSetType, rule.field)
          .then(setOneOfOptions)
          .catch(() => {})

        setRuleCategory(findRuleCategory(rule))
        setExclusive(rule.exclusive)
        setOneOfSelections(rule.selections)

        break
      case "rankbyprofile":
        setRuleCategory(findRuleCategory(rule))
        setSelectedProfileId(rule.id)
        setRankRescale(rule.rescale)
        setProfileTo(rule.to)
        setProfileFrom(rule.from)

        break
      case "rankbyscores":
        const maybeScoreName = Object.getOwnPropertyNames(rule.scores).pop()

        let scoreOptWithVariation = undefined
        let scoreOpt = undefined

        if (maybeScoreName) {
          const scores = getSelectedScore(maybeScoreName)
          scoreOpt = scores.scoreOpt
          scoreOptWithVariation = scores.scoreOptWithVariation
        }

        setRuleCategory(findRuleCategory(rule))
        setSelectedScore(scoreOptWithVariation ?? scoreOpt)
        setRankRescale(rule.rescale)
        setProfileTo(rule.to)
        setProfileFrom(rule.from)
        setShowScoresWithVariations(scoreOpt ? new Set<string>().add(scoreOpt?.scoreName) : new Set<string>())

        break
      case "selectranks":
        setRuleCategory(findRuleCategory(rule))
        setRankRelative(rule.relative)
        setRankFromTop(rule.fromTop)
        setRankValue(rule.value)

        break
      default:
        break
    }
  }

  const renderRuleFields: () => RuleItem = () => {
    if (isRuleCategoryWithField(ruleCategory)) {
      switch (ruleCategory.type) {
        case "rangefilter":
          return renderRangeFilterFields()
        case "oneoffilter":
          return renderOneOfFilterFields()
        case "selectranks":
          return renderSelectRanks()
      }
    }

    switch (ruleCategory.type) {
      case "rankbyprofile":
        return renderRankByProfile()
      case "rankbyscores":
        return renderRankByScores()
      case "loadmicrodata":
        return renderLoadMicroData()
    }

    return { body: null, options: null }
  }

  const renderLoadMicroData = () => {
    if (agsRefResLocOptions.length === 0)
      return {
        body: <LoadingSpinner />,
        options: null,
      }

    return {
      body: (
        <FieldOptionSuggest
          options={agsRefResLocOptions}
          selected={exampleAgsRefResLoc}
          onSelectionChanged={(exampleAgsRefResLoc) => setExampleAgsRefResLoc(exampleAgsRefResLoc)}
        />
      ),
      options: null,
    }
  }

  const renderRangeFilterFields: () => RuleItem = () => {
    const scoreUnit = selectedScore?.unit ? t.pickTranslation(selectedScore?.unit) : undefined

    if (!isRuleCategoryWithField(ruleCategory)) {
      return { body: null, options: null }
    }

    return {
      body: ruleCategory.field.startsWith("raw_") && (
        <Flex flexDirection="column">
          <div className={scoreGroupsClass}>{renderScoreList(true)}</div>
        </Flex>
      ),
      options: (
        <Grid columns={1}>
          <GridItem padding={[0, 0, 12, 0]}>
            <Select
              value={exclusive.toString()}
              options={[
                { label: t.selectionRuleEdit.inclusiveLabel, value: "false" },
                { label: t.selectionRuleEdit.exclusiveLabel, value: "true" },
              ]}
              onValueChange={(value) => setExclusive(value === "true")}
            />
          </GridItem>
          <Grid columnSpec="1fr min-content 1fr" gap={8}>
            <div>{t.scoreSearch.from} (&gt;=)</div>
            <div></div>
            <div>{t.scoreSearch.to} (&lt;)</div>
            <NumberInput defaultValue={rangeFrom} onValidValueChange={setRangeFrom} suffix={scoreUnit} />
            <GridItem padding={8}>
              <span>—</span>
            </GridItem>
            <NumberInput defaultValue={rangeTo} onValidValueChange={setRangeTo} suffix={scoreUnit} />
          </Grid>
        </Grid>
      ),
    }
  }

  const renderOneOfFilterFields: () => RuleItem = () => {
    if (oneOfOptions.length === 0) {
      return { body: <LoadingSpinner />, options: null }
    }

    if (oneOfOptions.length > 20) {
      return {
        body: (
          <FieldOptionMultiSelect
            options={oneOfOptions}
            selected={oneOfSelections}
            onSelectionChanged={setOneOfSelections}
          />
        ),
        options: ruleCategory.id === "oneoffilter::macro::ref_id" && (
          <Grid columns={1}>
            <Select
              value={exclusive.toString()}
              options={[
                { label: t.selectionRuleEdit.inclusiveLabel, value: "false" },
                { label: t.selectionRuleEdit.exclusiveLabel, value: "true" },
              ]}
              onValueChange={(value) => setExclusive(value === "true")}
            />
          </Grid>
        ),
      }
    }

    return {
      body: oneOfOptions.map((option, idx) => (
        <BorderBottom key={idx} padding={[4, 16, 4, 16]}>
          <Checkbox
            key={idx}
            label={option.label}
            checked={oneOfSelections.indexOf(option.key) >= 0}
            onChange={onToggleOneOfOption(option.key)}
          />
        </BorderBottom>
      )),
      options: ruleCategory.id === "oneoffilter::macro::ref_id" && (
        <Grid columns={1}>
          <Select
            value={exclusive.toString()}
            options={[
              { label: t.selectionRuleEdit.inclusiveLabel, value: "false" },
              { label: t.selectionRuleEdit.exclusiveLabel, value: "true" },
            ]}
            onValueChange={(value) => setExclusive(value === "true")}
          />
        </Grid>
      ),
    }
  }

  const onToggleOneOfOption = (key: string) => {
    return () => {
      const idx = oneOfSelections.indexOf(key)

      idx < 0
        ? setOneOfSelections([...oneOfSelections, key])
        : setOneOfSelections([...oneOfSelections.slice(0, idx), ...oneOfSelections.slice(idx + 1)])
    }
  }

  const renderSelectRanks: () => RuleItem = () => {
    return {
      body: (
        <Grid padding={16} columns={1} gap={8}>
          <GridItem padding={[0, 0, 8, 0]}>
            <Select
              value={rankRelative ? "true" : "false"}
              options={[
                { label: t.selectionRuleEdit.selectRankPercentage, value: "true" },
                { label: t.selectionRuleEdit.selectRankAbsolute, value: "false" },
              ]}
              onValueChange={(value) => setRankRelative(value === "true")}
            />
          </GridItem>
          <GridItem padding={[0, 0, 8, 0]}>
            <Select
              value={rankFromTop ? "true" : "false"}
              options={[
                { label: t.selectionRuleEdit.selectRankTop, value: "true" },
                { label: t.selectionRuleEdit.selectRankBottom, value: "false" },
              ]}
              onValueChange={(value) => setRankFromTop(value === "true")}
            />
          </GridItem>
          <GridItem padding={[0, 0, 8, 0]}>
            <NumberInput defaultValue={rankValue} onValidValueChange={setRankValue} />
          </GridItem>
        </Grid>
      ),
      options: null,
    }
  }

  const renderRankByProfile: () => RuleItem = () => {
    const expandedPanel = (isActive: boolean) =>
      css({
        maxHeight: isActive ? "auto" : 0,
        overflow: "hidden",
        transition: "max-height .5s ease",

        "> div": {
          paddingLeft: "32px",
        },
      })

    const expandGroup = availableProfiles?.find((profile) => profile.id === selectedProfileId)
    if (
      expandGroup &&
      expandGroup.groupId &&
      !isInitCollapsedGroupSeated &&
      !collapsedGroup.includes(expandGroup.groupId)
    ) {
      setCollapsedGroup([...collapsedGroup, expandGroup.groupId])
      setIsInitCollapsedGroupSeated(true)
    }

    const profileItem = (profile: Profile) => (
      <div className={profileClass} key={`${profile.id}-${profile.name}`}>
        <RadioButton
          id={profile.id}
          key={profile.id}
          value={profile.id}
          label={profile.name}
          name="selectedProfile"
          checked={profile.id === selectedProfileId}
          onChange={setSelectedProfileId}
        />
      </div>
    )

    const setCollapsedGroups = (groupId: string) =>
      collapsedGroup.includes(groupId)
        ? setCollapsedGroup(collapsedGroup.filter((id) => id !== groupId))
        : setCollapsedGroup([...collapsedGroup, groupId])

    return {
      body: (
        <ScrollBox>
          <Flex flexDirection="column">
            {availableProfileInProgress && <LoadingSpinner />}
            {availableProfiles?.map((profile) => !profile.groupId && profileItem(profile))}
            {profileGroups?.map((group) => {
              const isGroupEmpty = availableProfiles?.some((profile) => profile.groupId === group.id)

              return (
                <div key={`${group.id}-${group.name}`}>
                  <div
                    className={styles.groupHeader(collapsedGroup.includes(group.id), !isGroupEmpty)}
                    onClick={() => setCollapsedGroups(group.id)}
                  >
                    <span className={styles.groupName}>{group.name}</span>
                    <span className={styles.chevronWrap}>
                      <Icon
                        name={collapsedGroup.includes(group.id) && isGroupEmpty ? "dropdown_up" : "dropdown_down"}
                      />
                    </span>
                  </div>
                  <div className={expandedPanel(collapsedGroup.includes(group.id))}>
                    {availableProfiles?.map((profile) => profile.groupId === group.id && profileItem(profile))}
                  </div>
                </div>
              )
            })}
          </Flex>
        </ScrollBox>
      ),
      options: (
        <Grid columns={1}>
          <GridItem padding={[0, 0, 16, 0]}>
            <Select
              id="rescaleRank"
              name="rescaleRank"
              value={rankRescale.toString()}
              options={[
                { value: "false", label: t.selectionRuleEdit.rescaleRankNo },
                { value: "true", label: t.selectionRuleEdit.rescaleRankYes },
              ]}
              onValueChange={(v) => setRankRescale(v === "true")}
            />
          </GridItem>
          <Grid columnSpec="1fr min-content 1fr" gap={8}>
            <div>{t.scoreSearch.from} (&gt;=)</div>
            <div></div>
            <div>{t.scoreSearch.to} (&lt;=)</div>
            <NumberInput defaultValue={profileFrom} onValidValueChange={setProfileFrom} validator={minValidator(0)} />
            <GridItem padding={8}>
              <span>—</span>
            </GridItem>
            <NumberInput defaultValue={profileTo} onValidValueChange={setProfileTo} validator={maxValidator(100)} />
          </Grid>
        </Grid>
      ),
    }
  }

  const renderRankByScores: () => RuleItem = () => ({
    body: (
      <Flex flexDirection="column">
        <div className={scoreGroupsClass}>{renderScoreList(false)}</div>
      </Flex>
    ),
    options: (
      <Grid columns={1}>
        <GridItem padding={[0, 0, 16, 0]}>
          <Select
            id="rescaleRank"
            name="rescaleRank"
            value={rankRescale.toString()}
            options={[
              { value: "false", label: t.selectionRuleEdit.rescaleRankNo },
              { value: "true", label: t.selectionRuleEdit.rescaleRankYes },
            ]}
            onValueChange={(v) => setRankRescale(v === "true")}
          />
        </GridItem>

        <Grid columnSpec="1fr min-content 1fr" gap={8}>
          <div>{t.scoreSearch.from} (&gt;=)</div>
          <div></div>
          <div>{t.scoreSearch.to} (&lt;=)</div>
          <NumberInput
            defaultValue={profileFrom}
            validator={sequentialValidator([minValidator(0), maxValidator(100)])}
            onValidValueChange={setProfileFrom}
          />
          <GridItem padding={8}>
            <span>—</span>
          </GridItem>
          <NumberInput
            defaultValue={profileTo}
            validator={sequentialValidator([minValidator(0), maxValidator(100)])}
            onValidValueChange={setProfileTo}
          />
        </Grid>
      </Grid>
    ),
  })

  const renderScoreList = (useRaw: boolean) => {
    return categorize(t, props.dataSetType === "macro" ? LANA_AGS_NODES : LANA_CELL_NODES).map((c) =>
      renderCategorizedScoresWithVariations(c, useRaw)
    )
  }

  const renderCategorizedScoresWithVariations = (categorizedScores: CategorizedScores, useRaw: boolean) => {
    const expanded = categorizedScores.scores.some((s) => s.category === selectedScore?.category)

    return (
      <Expandable
        key={`category-${categorizedScores.category.name}`}
        header={t.pickTranslation(categorizedScores.category.title)}
        isOpen={expanded}
      >
        <Grid columns={1}>
          <div className={scoreInGroupClass}>
            {categorizedScores.scores.map((s) => renderScoreWithVariation(s, useRaw))}
          </div>
        </Grid>
      </Expandable>
    )
  }

  const onOk = () => {
    const newRule = createRule()

    if (!newRule) {
      props.onClose()
      return
    }

    props.onEdited(newRule)
  }

  const onArrowClick = (scoreName: string) => {
    let newShownVariationsSet = new Set(showScoresWithVariations)
    showScoresWithVariations.has(scoreName)
      ? newShownVariationsSet.delete(scoreName)
      : newShownVariationsSet.add(scoreName)
    setShowScoresWithVariations(newShownVariationsSet)
  }

  const renderScoreWithVariation = (score: LanaNodeWithVariation, useRaw: boolean) => {
    const scoreName = useRaw ? score.rawName : score.scoreName
    const selectedScoreName = useRaw ? selectedScore?.rawName : selectedScore?.scoreName
    const selected = score.scoreVariation
      ? score.scoreVariationNames?.some((s) => (useRaw ? s.rawName : s.scoreName) === selectedScoreName)
      : scoreName === selectedScoreName
    const scoreVariationExpanded =
      !!score.scoreVariationName &&
      score.scoreVariationNames?.some((s) => showScoresWithVariations.has(useRaw ? s.rawName : s.scoreName))

    return (
      <div key={scoreName}>
        <Flex flexDirection="row" gap={4} key={scoreName} padding={[16, 16, 12, 16]}>
          <FlexItem>
            <RadioButton
              id={scoreName}
              value={scoreName}
              checked={selected}
              label={t.pickTranslation(score.title)}
              name={scoreName}
              onChange={() => {
                if (score.scoreVariationNames) {
                  setSelectedScore(score.scoreVariationNames[0])
                  setShowScoresWithVariations(new Set<string>().add(scoreName))
                } else {
                  setSelectedScore(score)
                }
              }}
            />
          </FlexItem>
          <Tooltip
            placement="left"
            tooltip={
              <div style={{ maxWidth: "400px" }}>
                <b>{t.scoreDescription}</b>
                <span>: {t.pickTranslation(score.description)}</span>
                <br />
                <b>{t.scoreSource}</b>
                <span>: {t.pickTranslation(score.source)}</span>
              </div>
            }
          >
            <FlexItem alignSelf="start">
              <Icon name="info" fontSize={14} color="primary" colorType="default" />
            </FlexItem>
          </Tooltip>
          <FlexItem flexGrow={1} />

          {score.scoreVariationNames && score.scoreVariationNames?.length > 1 && (
            <div className={scoreVariationExpanded ? arrowClass : cx(arrowClass, arrowExpandedClass)}>
              <a onClick={() => onArrowClick(scoreName)}>
                <Icon fontSize={20} name={"arrow_drop_up"} />
              </a>
            </div>
          )}
        </Flex>
        {score.scoreVariationNames && score.scoreVariationNames.length > 1 && (
          <Collapse isOpen={scoreVariationExpanded} transitionDuration={0}>
            <Flex flexDirection="row" gap={16} key={score.scoreVariationName?.en} padding={[0, 8, 16, 16]}>
              {score.scoreVariationNames.map((s) => renderButtonScoreVariation(s, useRaw))}
            </Flex>
          </Collapse>
        )}
      </div>
    )
  }

  const renderButtonScoreVariation = (variationName: LanaNode, useRaw: boolean) => {
    const selected = useRaw
      ? selectedScore?.rawName === variationName.rawName
      : selectedScore?.scoreName === variationName.scoreName

    return (
      <React.Fragment key={`${variationName.rawName}-${variationName.scoreName}`}>
        {variationName.scoreVariationName && (
          <ButtonBP
            className={buttonScoreVariationClass}
            style={{
              fontSize: 12,
              borderRadius: 4,
              minWidth: "72px",
              borderRight: selected ? "1px solid" : "",
              borderLeft: selected ? "1px solid" : "",
            }}
            outlined={!selected}
            minimal={!selected}
            intent={selected ? "primary" : undefined}
            small
            key={`${variationName.scoreVariation}-${variationName.scoreVariationName?.en}`}
            onClick={() => setSelectedScore(variationName)}
          >
            {t.pickTranslation(variationName.scoreVariationName)}
          </ButtonBP>
        )}
      </React.Fragment>
    )
  }

  const createRule: () => RatingSelectionRule | null = () => {
    const currentRuleType = ruleCategory.type
    let currentField: string | null = null
    if (isRuleCategoryWithField(ruleCategory)) {
      currentField = ruleCategory.field
    }

    switch (currentRuleType) {
      case "loadmicrodata":
        if (exampleAgsRefResLoc.length === 0) return null
        return {
          type: "loadmicrodata",
          exampleAgsRefResLoc,
        }
      case "rangefilter":
        if (!currentField || (currentField?.startsWith("raw_") && typeof selectedScore !== "object")) return null

        if (selectedScore?.unit?.en === "%")
          return {
            type: "rangefilter",
            field:
              currentField.startsWith("raw_") && typeof selectedScore === "object"
                ? selectedScore.rawName
                : currentField,
            exclusive,
            to: rangeTo ? rangeTo / 100 : undefined,
            from: rangeFrom ? rangeFrom / 100 : undefined,
          }
        else
          return {
            type: "rangefilter",
            field:
              currentField.startsWith("raw_") && typeof selectedScore === "object"
                ? selectedScore.rawName
                : currentField,
            exclusive,
            to: rangeTo,
            from: rangeFrom,
          }

      case "oneoffilter":
        if (oneOfSelections.length === 0 || !currentField) return null
        return {
          type: "oneoffilter",
          field: currentField,
          exclusive,
          selections: oneOfSelections,
        }
      case "selectranks":
        if (rankValue === undefined) return null
        return {
          type: "selectranks",
          relative: rankRelative,
          fromTop: rankFromTop,
          value: rankValue,
        }
      case "rankbyprofile":
        if (selectedProfileId === undefined) return null
        return {
          type: "rankbyprofile",
          id: selectedProfileId,
          to: profileTo,
          from: profileFrom,
          rescale: rankRescale,
        }
      case "rankbyscores":
        if (typeof selectedScore !== "object") return null
        return {
          type: "rankbyscores",
          scores: {
            [selectedScore.scoreName]: 1.0,
          },
          to: profileTo,
          from: profileFrom,
          rescale: rankRescale,
        }
      default:
        return null
    }
  }

  const { body, options } = renderRuleFields()
  const rowSpec = options ? "158px 1fr 156px min-content" : "158px 1fr min-content"

  return (
    <Grid columns={1} rowSpec={rowSpec} height={[100, "%"]}>
      <BorderBottom backgroundColor={"rgb(245, 245, 250)"}>
        <FlexItem padding={16}>
          <Flex flexDirection="row">
            <FlexItem flexGrow={1} />
            <Button type="tertiary" icon="close" size="small" onClick={props.onClose} />
          </Flex>
          <FlexItem padding={[16, 0, 16, 0]}>
            <h1>{props.rule ? t.selectionRuleEdit.editTitle : t.selectionRuleEdit.createTitle}</h1>
          </FlexItem>

          {!props.rule && (
            <Select
              disabled={!!props.rule}
              value={ruleCategory.id}
              onValueChange={(id) => {
                clearUpdateSelectionError()
                setRuleCategory(ruleCategories.find((c) => c.id === id) || ruleCategories[0])
              }}
              options={ruleCategories
                .filter((c) => c.type !== "loadmicrodata")
                .map((c) => ({ value: c.id, label: t.pickTranslation(c.label) }))}
            />
          )}

          {props.rule && <h3>{t.pickTranslation(ruleCategory.label)}</h3>}
        </FlexItem>
      </BorderBottom>

      <GridItem overflow={"auto"}>{body}</GridItem>

      {options && (
        <GridItem>
          <Grid columns={2} padding={16}>
            <GridItem>
              <b>{t.options}</b>
            </GridItem>
            <GridItem alignSelf={"end"} justifySelf={"end"}>
              <HelpPopover position={"right-bottom"} text={t.selectionRuleEdit.helpText} />
            </GridItem>

            <GridItem colSpan={2} padding={[16, 0, 0, 0]}>
              {options}
            </GridItem>
          </Grid>
        </GridItem>
      )}

      <GridItem padding={16} alignSelf={"end"}>
        {updateError && <GenericErrorPanel error={updateError} />}

        <Grid columns={1}>
          <GridItem justifySelf={"end"}>
            <Button type="primary" loading={updateInProgress} icon="add" disabled={!createRule()} onClick={onOk}>
              {t.ok}
            </Button>
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  )
}
