import * as React from "react"
import { css, cx } from "emotion"
import { ProfileMenu } from "./profile-menu"
import { translations } from "../i18n"
import { Flex } from "../../shared/components/ui/flex"
import { DataSetType } from "../../shared/models/smartdata"
import { lanaApiUrl } from "../../app_config"
import { Tooltip } from "../../shared/components/ui/tooltip"
import { DocumentationAndSupport } from "../../shared/components/documentation-and-support"
import AppHeader from "../../shared/components/app-header"
import CommonMapView from "../../shared/components/common-map-view"
import { useState } from "react"
import { useAppSelector } from "../../relas/store"
import { AllowedModulesEnum } from "../../private-data/models/private-data"
import { updateView } from "../profile-slice"
import ButtonTabs from "../../shared/components/buttontabs"
import HorizontalDivider from "../../shared/components/horizontaldivider"
import Dialog from "../../shared/components/dialog"
import DialogFooter from "../../shared/components/dialogfooter"
import Slider from "../../shared/components/slider"
import Text from "../../shared/components/text"
import NumberField from "../../shared/components/numberfield"
import Grid from "../../shared/components/restyle-grid/grid"
import GridItem from "../../shared/components/restyle-grid/griditem"
import Button from "../../shared/components/button"
import { getThemeColorVar } from "../../shared/helper/color"
import { language } from "../../shared/language"
import Icon from "../../shared/components/icon"

const borderWrapperClass = css({
  margin: "15px",
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: "5px",
  borderColor: getThemeColorVar("border", "default"),
})

const disabledLink = css({
  pointerEvents: "none",
  textDecoration: "none",
  color: getThemeColorVar("disabled", "light"),
})

export const profilesHelpLink = "https://docs.google.com/document/d/1m9gePVoHbhyE4_uE8lVJb0qdNtiNVnebVCvz_797ssk"

export const Profiles = () => {
  const t = translations()

  const [downloadMacroScoresDialogOpen, setDownloadMacroScoresDialogOpen] = useState(false)
  const [downloadFromScore, setDownloadFromScore] = useState("90")
  const [downloadFromScoreValid, setDownloadFromScoreValid] = useState(true)
  const [downloadToScore, setDownloadToScore] = useState("100")
  const [downloadToScoreValid, setDownloadToScoreValid] = useState(true)
  const [shapeFillOpacity, setShapeFillOpacity] = useState(0.6)

  const smartdataSource = useAppSelector((state) => state.profile.smartdataSource)
  const agsRefResLoc = useAppSelector((state) => state.profile.agsRefResLoc)
  const currentProfile = useAppSelector((state) => state.profile.profile)
  const profileList = useAppSelector((state) => state.profile.profileList)
  const groupList = useAppSelector((state) => state.profile.groupList)
  const scores = useAppSelector((state) => state.profile.scores)
  const isPrivateDataAccessible = useAppSelector((state) => state.user.scopes.privateData)
  const privatePoiCategories = useAppSelector((state) => state.privateData.privatePOICategories)
  const privateDataSettings = useAppSelector((state) => state.privateData.modulesWithPrivateData.profileManager)

  const onSelectAgsRefResLoc = (agsRefResLocId: string) => {
    if (smartdataSource === "micro") {
      updateView(scores, smartdataSource, agsRefResLocId)
    }
  }

  const renderDownloadDialog = () => {
    return (
      <Dialog
        title={t.profileExplorer.downloadMacroScores}
        onClose={() => setDownloadMacroScoresDialogOpen(false)}
        closeOnClickOutside
        closeButton
      >
        <Flex flexDirection="column" padding={24}>
          <p>{t.profileExplorer.downloadOptions}</p>
          <div>
            <Flex flexDirection="row" gap={8} alignItems="center">
              <div style={{ whiteSpace: "nowrap" }}>{t.profileExplorer.downloadFromScore}</div>
              <NumberField
                value={downloadFromScore}
                min={80}
                onValueChange={(downloadFromScoreNew, downloadFromScoreValidNew) => {
                  setDownloadFromScore(downloadFromScoreNew)
                  setDownloadFromScoreValid(downloadFromScoreValidNew)
                }}
              />
              <div style={{ whiteSpace: "nowrap" }}>{t.profileExplorer.downloadToScore}</div>
              <NumberField
                value={downloadToScore}
                min={80}
                onValueChange={(downloadToScoreNew, downloadToScoreValidNew) => {
                  setDownloadToScore(downloadToScoreNew)
                  setDownloadToScoreValid(downloadToScoreValidNew)
                }}
              />
            </Flex>
          </div>
        </Flex>
        <DialogFooter paddingX="lg" spaceBetween="md" md-justify="spaceBetween" paddingY="lg" direction="row">
          <Button type="secondary" onClick={() => setDownloadMacroScoresDialogOpen(false)}>
            {t.cancel}
          </Button>
          <Button
            type="primary"
            onClick={onDownloadMacroScores}
            disabled={
              !downloadFromScoreValid ||
              !downloadToScoreValid ||
              downloadFromScore.length == 0 ||
              downloadToScore.length == 0
            }
          >
            {t.download}
          </Button>
        </DialogFooter>
      </Dialog>
    )
  }

  const colorForValue = (value: number, opacity: number) => {
    return `hsla(${225 - (100 - (value * 0.5 + 50))}, 100%, 50%, ${opacity})`
  }

  const canDownloadMacroScores = () => {
    return smartdataSource === "macro" && Object.keys(scores.macro).length > 0
  }

  const onDownloadMacroScores = () => {
    if (!currentProfile) return

    const lang = language()
    const from = downloadFromScore
    const to = downloadToScore

    window.open(
      `${lanaApiUrl}/api/profiles/${currentProfile.id}/downloadMacro?from=${from}&to=${to}&lang=${lang}`,
      "_blank"
    )
    setDownloadMacroScoresDialogOpen(false)
  }

  return (
    <Grid columnSpec="500px 1fr" height={[100, "%"]} rowSpec="fit-content(100%) minmax(0, 1fr)">
      {downloadMacroScoresDialogOpen && renderDownloadDialog()}
      <GridItem colSpan={2}>
        <AppHeader menuSection={"profileManager"} assessment={null} assessmentEntry={null} module={null}>
          <Flex flexDirection="row-reverse" gap={16} alignItems={"center"}>
            <DocumentationAndSupport
              tooltip={t.helpAndSupport}
              documentationURL={profilesHelpLink}
              callLocation="Profile Manager"
              addDocumentationLink
              onClick={() => {}}
            />
            <HorizontalDivider height={32} />
            <Tooltip placement="bottom" tooltip={t.profileExplorer.downloadMacroScores}>
              <div style={canDownloadMacroScores() ? {} : { cursor: "default" }}>
                <a
                  href={"#"}
                  className={canDownloadMacroScores() ? "" : disabledLink}
                  onClick={(e) => {
                    e.preventDefault()
                    setDownloadMacroScoresDialogOpen(true)
                  }}
                >
                  <Icon name="download" size={"regular"} />
                </a>
              </div>
            </Tooltip>
          </Flex>
        </AppHeader>
      </GridItem>

      <ProfileMenu />

      <div className={cx(borderWrapperClass, css({ marginLeft: 0 }))}>
        <Grid columns={1} rowSpec="1fr fit-content(100%)" height={[100, "%"]}>
          <CommonMapView
            profileList={profileList ?? undefined}
            groupList={groupList ?? undefined}
            shapeFillOpacity={shapeFillOpacity}
            colorForValue={colorForValue}
            smartdataSource={smartdataSource}
            overlayMainFactScoreType={"profile"}
            scores={scores}
            agsRefResLoc={agsRefResLoc}
            onSelectAgsRefResLoc={smartdataSource === "micro" ? onSelectAgsRefResLoc : undefined}
            comparablesItems={[]}
            displayWmsLayer={false}
            currentAssessmentEntryAddress={undefined}
            isPrivateDataAccessible={isPrivateDataAccessible}
            allowedModule={AllowedModulesEnum.PROFILE_MANAGER}
            privatePoiCategories={privatePoiCategories}
            privateDataSettings={privateDataSettings}
          />

          <Grid columnSpec="fit-content(100%) 1fr fit-content(100%) 1fr fit-content(100%)" gap={8} padding={8}>
            <Flex flexDirection="row" alignItems="center" gap={8}>
              <ButtonTabs<DataSetType>
                values={["micro", "macro"]}
                selected={smartdataSource}
                onSelect={(source) => {
                  updateView(scores, source, agsRefResLoc)
                }}
                translate={t.smartdataSourceTranslation}
              />
            </Flex>

            <div />

            <Flex flexDirection="row" alignItems="center" gap={8}>
              <div>0</div>
              <div
                style={{
                  height: "20px",
                  width: "400px",
                  backgroundImage: `linear-gradient(to right, ${colorForValue(0, 1)}, ${colorForValue(100, 1)})`,
                }}
              />
              <div>100</div>
            </Flex>

            <div />

            <Flex flexDirection="row" alignItems="center" gap={8}>
              <Text>{t.sliderDescription}</Text>
              <Slider min={0} max={1} step={0.05} value={shapeFillOpacity} onChange={setShapeFillOpacity} />
            </Flex>
          </Grid>
        </Grid>
      </div>
    </Grid>
  )
}
