import { Translations } from "./translations"
import { SecurityPage } from "../components/userAccountSecurity"

export const DE: Translations = {
  security: {
    header: "Sicherheitseinstellungen",
    localNav: (page: SecurityPage) => {
      switch (page) {
        case "2fa":
          return "2-Faktor-Authentifizierung"
        case "password":
          return "Passwort"
      }
    },
  },
  settings: {
    header: "Allgemeine Profil-Einstellungen",
    changePassword: {
      doIt: "Passwort ändern",
      currentMissingError: "Bitte geben Sie das aktuelle Passwort ein.",
      notMatchingError: "Die Passwörter stimmen nicht überein oder sind zu kurz (min. 9 Zeichen)",
      tooShortError: "Das neue Passwort muss mindestens 9 Zeichen lang sein.",
      wrongCurrentPasswordError: "Ihr aktuelles Passwort stimmt nicht.",
      noNewError: "Bitte geben Sie das neue Passwort ein",
      title: "Passwort ändern",
      currentLabel: "Aktuelles Passwort",
      currentHint: "Ihr aktuelles Passwort",
      newLabel: "Neues Passwort",
      newHint: "Geben Sie Ihr neues Passwort ein",
      confirmLabel: "Neues Passwort",
      confirmHint: "Bestätigen Sie ihr neues Passwort",
      cancel: "Abbrechen",
      success: "Wechsel erfolgreich",
      doneTitle: "Ihr Passwort wurde geändert",
      doneBody: "Beim nächsten Einloggen können Sie das neue Passwort verwenden.",
      ok: "Ok",
      fakePasswordLabel: "Passwort",
    },
    settingsHeader: "Einstellungen",
    profileImageHeader: "Profilbild",
    companyImageHeader: "Firmenlogo",
    personalDataHeader: "Persönliche Daten",
    personalDataName: "Vorname",
    personalDataSurname: "Nachname",
    personalDataEmail: "Email",
    personalDataPhone: "Telefon",
    personalDataPassword: "Passwort",
    personalDataCompanyName: "Firma",
    language: {
      de: "Deutsch",
      en: "Englisch",
      chooseLanguage: "Wählen Sie Ihre Sprache aus",
    },
    image: {
      upload: "Hochladen",
    },
    product: "Produkt",
  },
  genericError: {
    profileErrored: "Wir haben gerade Schwierigkeiten Ihr Profil zu laden.",
    imageErrored: "Ihre Aktion konnte aufgrund eines technischen Fehlers nicht durchgeführt werden.",
    languageChangeErrored: "Die Spracheinstellungen konnten nicht gespeichert werden.",
    passwordChangeErrored: "Ihr Passwort konnte nicht geändert werden.",
    noProduct:
      "Derzeit sind keine Produkte für Sie gebucht. Falls Sie weiter mit unserer Plattform arbeiten wollen, wenden Sie sich bitte an Ihren Admin oder nehmen Sie Kontakt mit unserem Sales Team (sales@21re.de) auf.",
  },
  twoFactorSetup: {
    setupHeader: "Einrichtung",
    explanationHeader: "Was muss ich tun?",
    generatedCodeLabel: "Geben Sie bitte einen generierten Code ein.",
    submitLabel: "Einrichtung abschliessen",
    submittedOkText: "Die Einrichtung wurde erfolgreich abgeschlossen.",
    submittedErrorText:
      "Die Einrichtung konnte nicht abgeschlossen werden. Vermutlich ist der generierte Code ungültig.",
    secretLabel: "Der geheime Schlüssel ist",
    qrRenderError:
      "Leider ist uns beim erzeugen des QR-Codes ein Fehler passiert. Bitte versuchen Sie es mit einem anderen Browser oder probieren Sie ggf. den Browser zu aktualisieren. Alternativ können bestimmte Zwei-Faktor Apps mithilfe des geheimen Schlüssels eingerichtet werden.",
    explanation: `Installieren Sie eine der unten aufgelisteten Apps. Mit der App kann der QR Code unter 'Einrichtung' gescannt werden. Die App kann dann Codes erstellen, die immer 30 Sekunden lang gültig sind. Wenn Sie auf 21st-Market nach einem generierten Code gefragt werden, starten Sie die App und geben den gerade angezeigten Code ein. Damit Sie Erfahrung mit diesem Prozess sammeln muss ein solcher generierter Code im Feld weiter unten eingegeben werden, bevor Ihre Zwei-Faktor Authentifizierung aktiv wird.`,
    teaser:
      "Traditionell nutzen Sie zum Einloggen nur ein Passwort. Wenn ein Angreifer Ihr Passwort errät, ist Ihr Konto kompromittiert. Mit der sogenannten Zwei-Faktor Authentifizierung geben Sie beim Einloggen nicht nur Ihr Passwort sondern auch ein Code an, der nur begrenzt gültig ist. Diese Codes können nur von einem Gerät erzeugt werden, welches in Ihrem Besitz ist. Ein Angreifer müsste damit sowohl Ihr Passwort als auch das Gerät stehlen, um Ihr Konto zu kompromittieren.",
    infographic: "/assets/mfa_de.png",
    otpLengthMismatch: "Der Code muss aus genau 6 Ziffern bestehen.",

    infographicHeader: "So geht's",

    companyAdminHeader: "Bitte richten Sie Zwei-Faktor Authentifizierung ein",
    companyAdminExplanation:
      "Sie sind in unserem System innerhalb Ihrer Firma als Administrator gekennzeichnet. Damit können Sie wichtige Einstellungen der Firma ändern, z.B. Berechnungsparameter, sowie andere Mitglieder der Firma administrieren. Aus diesem Grund empfehlen wir besondere Vorsichtsmaßnahmen, damit Ihr Konto vor Hackerangriffen sicher ist.",

    nukeTwoFactorHeader: "Ihr Konto ist mit der Zwei-Faktor Authentifizierung geschützt.",
    nukeTwoFactorError: "Beim Deaktivieren der Zwei-Faktor Authentifizierung ist ein Fehler passiert.",
    nukeTwoFactor: "Deaktivieren",
  },
}
