import { SHARED_DE } from "../../shared/i18n/de"
import { LocationSelectorTranslations } from "./translations"
import * as React from "react"

export const LOCATION_SELECTOR_DE: LocationSelectorTranslations = {
  ...SHARED_DE,
  createNew: "Neue Selektion erstellen",
  selectionListMenu: {
    delete: "Löschen",
    duplicate: "Duplizieren",
    edit: "Bearbeiten",
    rename: "Umbenennen",
  },
  selection: "Selektion",
  municipalityPopulation: "Einwohner",
  municipalitiesHeader: "Gemeinden",
  municipalityDownload: "Download",
  loadingResultData: "Lade Selektion ...",
  ratingGradeLabel: "Note",
  showListToggle: "Liste zeigen",
  selectionAlreadyExists: "Eine Selektion mit diesem Namen existiert bereits.",
  allSelections: "Alle Selektionen",
  newSelection: "Neue Selektion",
  createSelection: "Selektion anlegen",
  title: "Titel",
  context: "Kontext",
  micro: "Mikro (Kachel / Nachbarschaft)",
  macro: "Makro (Gemeinde)",
  addNewRule: "Neue Regel hinzufügen",
  selectionRuleEdit: {
    duplicateSuffix: "Kopie",
    loadMicroDataExample: "Beispiel",
    editRuleMenuItem: "Bearbeiten",
    deleteRuleMenuItem: "Löschen",
    deleteRuleTitle: "Regel löschen",
    deleteRuleConfirm: (name: string) => `Wollen Sie die Regel '${name}' wirklich löschen?`,
    inclusiveLabel: "Inklusive",
    exclusiveLabel: "Exklusive",
    selectRankTop: "Oberste",
    selectRankBottom: "Unterste",
    selectRankPercentage: "Prozent",
    selectRankAbsolute: "Absolute",
    rescaleRankNo: "Absolute Werte",
    rescaleRankYes: "Relative Werte",
    createTitle: "Neue Regel",
    editTitle: "Regel bearbeiten",
    duplicate: "Duplizieren",
    rename: "Umbenennen",
    deleteSelectionConfirm: (name: string) => `Wollen Sie die Selektion '${name}' wirklich löschen?`,
    helpText: (
      <>
        <p>Je nach Regel lassen sich eine oder mehrere der folgenden Optionen einstellen:</p>

        <p>
          <strong>Inklusive / Exklusive</strong>
          <br />
          Legt fest, ob die zum Filter passenden Gemeinden zur Auswahl hinzugefügt oder von der Auswahl ausgeschlossen
          werden sollen.
        </p>

        <p>
          <strong>Score Rohdaten von/bis</strong>
          <br />
          Bei diesen Werten kann es sich um eine Anzahl handeln (zB. von 1 bis 5) oder um eine bestimmte Einheit (zB.
          "%" oder "Einwohner pro km²"). Wenn Sie keinen Wert angeben, wird "von minimal bis maximal" angenommen.
        </p>

        <p>
          <strong>Absolute / Relative Werte</strong>
          <br />
          Scores gehen immer von 0 bis 100 und beziehen sich auf einen bestimmten Referenzrahmen. So beziehen sich
          absolute Makro-Scores auf ganz Deutschland. Relative Makro-Scores hingegen werden bezogen auf die aktuelle
          Auswahl neu berechnet (zB. nur innerhalb hessischer Gemeinden statt aller Gemeinden in ganz Deutschland).
          Micro-Scores werden analog behandelt.
        </p>

        <p>
          <strong>Score/Profil von/bis</strong>
          <br />
          Scores gehen immer von 0 bis 100 und lassen sich entsprechend filtern (zB. nur Scores von 80-100).
        </p>
      </>
    ),
  },
  options: "Optionen",
  lockSelection: "Selektion sperren",
  unlockSelection: "Selektion entsperren",
  lockConfirmation:
    "Durch das Sperren lässt sich eine Selektion schützen. Ihre Kollegen können sie dann immer noch nutzen, aber nicht mehr bearbeiten oder löschen.",
  unlockConfirmation:
    "Wenn Sie diese Selektion entsperren, kann sie von Ihren Kollegen bearbeitet und gelöscht werden.",
}
