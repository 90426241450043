import { Group, Profile, ProfileDetails } from "../models/profile"
import { translations } from "../i18n"
import { useDrop } from "react-dnd"
import { css } from "emotion"

import { PopupMenu } from "../../shared/components/ui/popup-menu"
import React from "react"
import { ProfileDnD } from "./profile-dnd"
import { getThemeColorVar } from "../../shared/helper/color"
import Table from "../../shared/components/table"
import Icon from "../../shared/components/icon"

const styles = {
  groupRow: (isActive: boolean | null, isGroupEmpty: boolean) =>
    css({
      width: "100%",
      display: "flex",
      alignItems: "center",
      backgroundColor: isActive ? getThemeColorVar("background", "lighter") : getThemeColorVar("white", undefined),
      cursor: isGroupEmpty ? "pointer" : "default",
      td: {
        padding: "10px 0",
      },
      ":hover": {
        backgroundColor: isGroupEmpty
          ? getThemeColorVar("background", "lighter")
          : getThemeColorVar("white", undefined),
      },
    }),
  groupTd: css({
    display: "flex",
    flexDirection: "column",
  }),
  iconWrapper: css({
    width: "37px",
    display: "flex",
    height: "100%",
    alignItems: "center",
    paddingRight: "15px !important",
  }),
  groupItem: css({
    tr: {
      paddingLeft: "10px",
      display: "block",
    },
    "tr:last-child": {
      borderBottom: 0,
    },
  }),
}

export const ProfileDnDGroup = (props: {
  group: Group
  profiles: Profile[]
  doGroupRenameStart: (group: Group) => void
  renderGroupConfirm: (group: Group) => void
  setGroupToDelete: (group: Group) => void
  onClickProfile: (profile: Profile) => void
  onDelete: (profile: Profile) => void
  setProfileToDelete: (profile: Profile) => void
  setProfileToLock: (profile: Profile) => void
  doFetchProfile: (id: string, agsRefResLoc: string) => void
  doProfileDuplicateStart: (profile: Profile, agsRefResLoc: string) => void
  doProfileRenameStart: (profile: Profile, agsRefResLoc: string) => void
  agsRefResLoc: string
  setUnableToDelete: (p: ProfileDetails) => void
  dndGroup: (profileId: string, groupId: string) => void
  currentProfile: null | Profile
  setCollapsedGroup: (groupId: string) => void
  collapsedGroup: Array<string>
  doUpdateProfileLocked: (id: string, locked: boolean) => void
  currentUserId: string
  isCompanyAdmin: boolean
}) => {
  const translation = translations()

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "Type",
    drop: () => ({ groupId: props.group.id }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: () => true,
  })

  const expandedPanel = (isActive: boolean) =>
    css({
      maxHeight: isActive ? "100%" : 0,
      overflow: "hidden",

      tr: {
        paddingLeft: "16px",

        td: {
          cursor: "pointer",
        },
      },
      "tr:last-child": {
        borderBottom: 0,
      },
    })

  let isCollapsed = props.collapsedGroup.some((group) => group === props.group.id)
  const isGroupEmpty = props.profiles.some((profile) => profile.groupId === props.group.id)

  return (
    <tr ref={drop}>
      <td width="100%" style={{ padding: 0 }} className={styles.groupTd}>
        <Table>
          <tbody>
            <tr
              className={styles.groupRow(isCollapsed, isGroupEmpty)}
              onClick={() => (isGroupEmpty ? props.setCollapsedGroup(props.group.id) : {})}
              style={{
                borderBottom: isCollapsed ? `1px solid ${getThemeColorVar("border", "default")}` : 0,
                backgroundColor: isOver
                  ? canDrop
                    ? getThemeColorVar("background", "lighter")
                    : getThemeColorVar("background", "lighter")
                  : "",
              }}
            >
              <td style={{ width: "100%", border: 0, fontWeight: "bold", padding: "10px 0 10px 10px" }}>
                {props.group.name}
              </td>
              <td>
                <PopupMenu
                  actions={[
                    {
                      title: translation.profileRename.menuItem,
                      onClick: () => {
                        props.doGroupRenameStart(props.group)
                      },
                    },
                    {
                      title: translation.profileDelete.menuItem,
                      onClick: () => {
                        props.setGroupToDelete(props.group)
                        props.renderGroupConfirm(props.group)
                      },
                    },
                  ]}
                />
              </td>
              <td className={styles.iconWrapper}>
                <Icon name={isCollapsed && isGroupEmpty ? "dropdown_up" : "dropdown_down"} />
              </td>
            </tr>
          </tbody>
        </Table>

        <div className={expandedPanel(isCollapsed)}>
          <Table>
            <tbody className={styles.groupItem}>
              {props.profiles
                .filter((profile) => profile.groupId === props.group.id)
                .map((profile, index) => (
                  <ProfileDnD
                    key={profile.id}
                    profile={profile}
                    index={index}
                    onClickProfile={props.onClickProfile}
                    doFetchProfile={props.doFetchProfile}
                    agsRefResLoc={props.agsRefResLoc}
                    onDelete={props.onDelete}
                    doUpdateProfileLocked={props.doUpdateProfileLocked}
                    doProfileDuplicateStart={props.doProfileDuplicateStart}
                    doProfileRenameStart={props.doProfileRenameStart}
                    setProfileToDelete={props.setProfileToDelete}
                    setProfileToLock={props.setProfileToLock}
                    setUnableToDelete={props.setUnableToDelete}
                    dndGroup={props.dndGroup}
                    currentProfile={props.currentProfile}
                    currentUserId={props.currentUserId}
                    isCompanyAdmin={props.isCompanyAdmin}
                  />
                ))}
            </tbody>
          </Table>
        </div>
      </td>
    </tr>
  )
}
