import * as React from "react"
import { useSelector } from "react-redux"
import { State } from "../reducers/state"
import { isInProgressState } from "../models/assessment"
import { translations } from "../i18n"
import { lanaApiUrl } from "../../app_config"
import { BorderBottom } from "../../shared/components/ui/border-bottom"
import { Grid } from "../../shared/components/ui/grid"
import { GridItem } from "../../shared/components/ui/grid-item"
import { ScrollBox } from "../../shared/components/ui/scroll-box"
import { Flex } from "../../shared/components/ui/flex"
import { Checkbox } from "@blueprintjs/core"
import { Tooltip } from "../../shared/components/ui/tooltip"
import { css } from "emotion"
import Dialog from "../../shared/components/dialog"
import Panel from "../../shared/components/panel"
import Button from "../../shared/components/button"
import { getThemeColorVar } from "../../shared/helper/color"
import { language } from "../../shared/language"
import Icon from "../../shared/components/icon"

export const SETTINGS_KEY = "AssessmentDownloadScoreSettings"

const disabledLink = css({
  pointerEvents: "none",
  textDecoration: "none",
  color: getThemeColorVar("disabled", "light"),
})

interface AssessmentDownloadScoreSettings {
  selectedProfiles: string[]
}

export function getAssessmentDownloadScoreSettings(): AssessmentDownloadScoreSettings {
  if (typeof Storage !== "undefined") {
    const settings = window.localStorage.getItem(SETTINGS_KEY)

    return settings !== null ? JSON.parse(settings) : { selectedProfiles: [] }
  }
  return { selectedProfiles: [] }
}

function saveAssessmentDownloadScoreSettings(settings: AssessmentDownloadScoreSettings) {
  if (typeof Storage !== "undefined") {
    window.localStorage.setItem(SETTINGS_KEY, JSON.stringify(settings))
  }
}

function clearAssessmentDownloadScoreSettings() {
  if (typeof Storage !== "undefined") {
    window.localStorage.removeItem(SETTINGS_KEY)
  }
}
type Props = { onClose: () => void }

const AssessmentDownloadScoreDialog = ({ onClose }: Props) => {
  const t = React.useMemo(translations, [translations])
  const [saveSettings, setSaveSettings] = React.useState(true)
  const { currentAssessment, assessmentEntries } = useSelector((state: State) => ({
    currentAssessment: state.assessment.currentAssessment,
    assessmentEntries: state.assessment.assessmentEntries,
  }))

  const initialSettings = () => {
    const settings = getAssessmentDownloadScoreSettings().selectedProfiles
    const selectedProfiles = settings.filter((value) =>
      assessmentEntries?.profiles.find((el) => el.id === value.toString())
    )
    return {
      selectedProfiles: selectedProfiles,
    }
  }
  const [settings, setSettings] = React.useState(initialSettings())

  function onDownloadScores() {
    if (!currentAssessment || isInProgressState(currentAssessment.state)) return

    if (saveSettings) {
      saveAssessmentDownloadScoreSettings(settings)
    } else {
      clearAssessmentDownloadScoreSettings()
    }
    onClose()

    const lang = language()

    window.open(
      `${lanaApiUrl}/api/assessments/${
        currentAssessment.id
      }/downloadScores?lang=${lang}&profiles=${settings.selectedProfiles.join(",")}`,
      "_blank"
    )
  }

  function toggleProfile(profileId: string) {
    const idx = settings.selectedProfiles.indexOf(profileId)

    if (idx < 0) {
      setSettings({
        ...settings,
        selectedProfiles: [...settings.selectedProfiles, profileId],
      })
    } else {
      setSettings({
        ...settings,
        selectedProfiles: [...settings.selectedProfiles.slice(0, idx), ...settings.selectedProfiles.slice(idx + 1)],
      })
    }
  }

  return (
    <Dialog
      onClose={onClose}
      title={t.assessmentDetails.download.title + " - " + t.assessmentDetails.download.selectProfiles}
      height="75vh"
    >
      <Grid columns={1} rowSpec="1fr min-content min-content" padding={[0, 0, 16, 0]} height={[100, "%"]}>
        <BorderBottom padding={[0, 16, 16, 16]}>
          <ScrollBox bordered>
            <Flex flexDirection="column" padding={[8, 8, 8, 8]} gap={8}>
              {assessmentEntries?.profiles.map((profile, idx) => (
                <Checkbox
                  key={idx}
                  checked={settings.selectedProfiles.indexOf(profile.id) >= 0}
                  disabled={settings.selectedProfiles.length >= 5 && settings.selectedProfiles.indexOf(profile.id) < 0}
                  onChange={() => toggleProfile(profile.id)}
                >
                  {profile.name}
                </Checkbox>
              ))}
            </Flex>
          </ScrollBox>
        </BorderBottom>
        {settings.selectedProfiles.length >= 5 && (
          <GridItem padding={[16, 24, 0, 24]}>
            <Panel color="neutral">
              <div style={{ maxWidth: "600px" }}>{t.assessmentDetails.download.tooManyProfiles}</div>
            </Panel>
          </GridItem>
        )}
        <Grid padding={[16, 24, 0, 24]} columnSpec="min-content 1fr min-content" rowGap={16} colGap={16}>
          <GridItem alignSelf="center">
            <Checkbox
              checked={saveSettings}
              onChange={() => setSaveSettings(!saveSettings)}
              style={{ whiteSpace: "nowrap", marginBottom: 0 }}
            >
              {t.assessmentDetails.download.saveSettings}
            </Checkbox>
          </GridItem>
          <GridItem alignSelf="center" justifySelf="end">
            <Button type="secondary" size="small" onClick={onClose}>
              {t.cancel}
            </Button>
          </GridItem>
          <GridItem alignSelf="center">
            <Button type="primary" size="small" onClick={onDownloadScores}>
              {t.assessmentDetails.download.downloadButton}
            </Button>
          </GridItem>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export const AssessmentDownloadScore = () => {
  const t = React.useMemo(translations, [translations])

  const [open, setOpen] = React.useState(false)
  const { currentAssessment, assessmentEntries } = useSelector((state: State) => ({
    currentAssessment: state.assessment.currentAssessment,
    assessmentEntries: state.assessment.assessmentEntries,
  }))

  return (
    <>
      <Tooltip placement="bottom" tooltip={t.header.downloadDataTooltip}>
        <a
          href={"#"}
          className={
            !currentAssessment || !assessmentEntries || isInProgressState(currentAssessment.state) ? disabledLink : ""
          }
          onClick={(e) => {
            e.preventDefault()
            setOpen(true)
          }}
        >
          <Icon name="download" size={"large"} />
        </a>
      </Tooltip>
      {open && <AssessmentDownloadScoreDialog onClose={() => setOpen(false)} />}
    </>
  )
}
