import * as React from "react"
import { bind } from "decko"
import { css } from "emotion"
import * as ol from "ol"
import * as layer from "ol/layer"
import * as style from "ol/style"
import { Geometry } from "ol/geom"
import RenderFeature from "ol/render/Feature"
import { SelectionAreaType } from "../../models/market-data"
import { DataSetType, districtSource, municipalitySource, RegionExtentType } from "../district-data"
import { focusAreaColor, referenceAreaColor } from "./district-data-analysis"
import * as extend from "ol/extent"
import {
  CountryAreaSelection,
  DistrictsAreaSelection,
  MunicipalitiesAreaSelection,
} from "../../reducers/market-data-slice"
import { OpenLayersAbstractProps, OpenLayersMap } from "../../../shared/components/openlayers-map"
import Grid from "../../../shared/components/restyle-grid/grid"
import { getThemeColor } from "../../../shared/helper/color"

interface MarketDataMapViewProps extends OpenLayersAbstractProps {
  dataSet: DataSetType
  selectionArea: SelectionAreaType
  selectedLocationsFromStore: DistrictsAreaSelection | MunicipalitiesAreaSelection | CountryAreaSelection
  selectedAreaExtents: RegionExtentType
}

const mapClass = css({
  minHeight: "200px",
  height: "240px",
  position: "relative",
  backgroundColor: "rgb(229, 227, 223)",
})

interface OwnState {}

const adjustViewPort = (extents: RegionExtentType, map: ol.Map) => {
  let allSelectedRegionsExtent = extend.createEmpty()
  extents.forEach((regExtent) => extend.extend(allSelectedRegionsExtent, regExtent))
  if (!extend.isEmpty(allSelectedRegionsExtent)) {
    map.getView().fit(allSelectedRegionsExtent, {
      size: map.getSize(),
      padding: [10, 10, 10, 10],
    })
  }
}

export class ConfiguredAreaMap extends OpenLayersMap<MarketDataMapViewProps, OwnState> {
  private selectedAreaLayer: layer.VectorTile | undefined = undefined

  constructor(props: MarketDataMapViewProps) {
    super(props)
  }

  componentDidMount() {
    super.componentDidMount()
    const selectedAreaSource =
      this.props.selectedLocationsFromStore.kind === "District" ? districtSource : municipalitySource
    this.selectedAreaLayer = new layer.VectorTile({
      declutter: true,
      source: selectedAreaSource,
      style: this.selectedAreaStyle,
    })
    this.getMap().addLayer(this.selectedAreaLayer)
    this.getMap().getControls().clear()
    this.getMap()
      .getInteractions()
      .forEach((interaction) => {
        interaction.setActive(false)
      })
    adjustViewPort(this.props.selectedAreaExtents, this.getMap())
  }

  componentDidUpdate(prevProps: MarketDataMapViewProps, prevState: OwnState) {
    super.componentDidUpdate(prevProps, prevState)
    if (this.props.dataSet !== prevProps.dataSet) {
      adjustViewPort(this.props.selectedAreaExtents, this.getMap())
      this.selectedAreaLayer?.set(
        "source",
        this.props.selectedLocationsFromStore.kind === "District" ? districtSource : municipalitySource
      )
      this.selectedAreaLayer?.changed()
    }
  }

  protected containerClass(): string {
    return mapClass
  }
  @bind
  protected onMoveEnd() {}

  render() {
    return (
      <Grid columns={1} rowSpec="1fr" height={[100, "%"]}>
        <div className={mapClass}>{super.render()}</div>
      </Grid>
    )
  }

  rgbToRgba = (color: string, opacity: number): string => {
    return color.replace(/rgb/i, "rgba").replace(/\)/i, `,${opacity})`)
  }

  @bind
  private selectedAreaStyle(feature: ol.Feature<Geometry> | RenderFeature): style.Style {
    const selectedAreaColor = this.rgbToRgba(
      this.props.selectionArea === "focusArea" ? focusAreaColor : referenceAreaColor,
      0.75
    )
    const selectedAreaBorderColor = this.rgbToRgba(
      this.props.selectionArea === "focusArea"
        ? `${getThemeColor("primary", "dark")}`
        : `${getThemeColor("secondary1", "dark")}`,
      1
    )
    const featureId = feature.getId()?.toString()

    if (this.props.selectedLocationsFromStore.kind === "Country") {
      return new style.Style({
        fill: new style.Fill({ color: selectedAreaColor }),
      })
    }
    if (this.props.selectedLocationsFromStore.list.find((id) => id === featureId)) {
      return new style.Style({
        fill: new style.Fill({ color: selectedAreaColor }),
        stroke: new style.Stroke({
          width: 3,
          color: selectedAreaBorderColor,
        }),
      })
    }

    return new style.Style({
      fill: new style.Fill({ color: "rgba(1,0,0,0)" }),
    })
  }
}
