export const LocalhostDomain = "localhost"
export const WorksDomain = "21re.works"
export const LiveDomain = "21re.de"
export const ServicesDomain = "21re.services"
export const PromoDomain = "21re.promo"

export function getCurrentServiceRoot(location: Location): string {
  if (!location.protocol) {
    return `https://${location.host}`
  }

  return `${location.protocol}//${location.host}`
}

export function getCurrentDomain(location: Location): string {
  const domainParts = location.host.split(".")

  if (domainParts.length < 2) {
    return location.host
  }

  return `${domainParts[domainParts.length - 2]}.${domainParts[domainParts.length - 1]}`
}

export function getSanitizedDomain(location: Location): string {
  return isLocalhost(location) ? "localhost" : getCurrentDomain(location).split(":")[0]
}

export function isLocalhost(location: Location): boolean {
  return location.host.indexOf(LocalhostDomain) >= 0
}

export function isLocalhostOrOneOfTheGivenDomains(location: Location, domains: string[]): boolean {
  const currentDomain = getCurrentDomain(location)

  return domains.some((domain) => domain === currentDomain) || isLocalhost(location)
}

export function getServiceName(location: Location): string | null {
  const domainParts = location.host.split(".")

  if (domainParts.length < 3) {
    return null
  }

  return domainParts[0]
}

export function getStage(location: Location): string | null {
  if (isLocalhost(location)) return null

  const domainParts = location.host.split(".")

  if (domainParts.length < 3) {
    return null
  }

  return domainParts[2] as string
}
