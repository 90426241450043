import * as React from "react"
import { bind } from "decko"
import { css, cx } from "emotion"
import { GenericInputElementProps, GenericInputElementState } from "../models/genericinputelement"
import GenericInputElement from "./genericinputfield"
import { getThemeColorVar } from "../helper/color"
import { ComponentSize } from "../util/layoutconstants"
import { isNullOrUndefined } from "../helper/utils"

export type Option<T extends string | number | undefined = string> = {
  value: T
  label: string
  disabled?: boolean
}

export type OptionGroup<T extends string | number | undefined> = {
  label: string
  options: Option<T>[]
  disabled?: boolean
}

export interface Props<T extends string | number | undefined> extends GenericInputElementProps<HTMLSelectElement, T> {
  readOnly?: boolean
  useDefault?: boolean
  defaultValue?: string | null
  options: (Option<T> | OptionGroup<T>)[]
}

const selectClass = css({
  position: "relative",

  select: {
    cursor: "pointer",
    appearance: "none",
    height: "38px",
    padding: "8px",
    paddingRight: "25px",
    lineHeight: "22px",

    "&:-moz-focusring": {
      color: "transparent",
      textShadow: "0 0 0 #000",
    },
  },

  "&:after": {
    content: '"▼"',
    display: "block",
    padding: "9px 10px",
    position: "absolute",
    pointerEvents: "none",
    right: 0,
    top: 0,
    fontSize: "10.8px",
  },
})

const disabledClass = css({
  "&:after": {
    color: getThemeColorVar("typo", "default"),
  },
})

const sizeClasses: { [key in ComponentSize]: string } = {
  small: css({
    select: {
      height: "30px",
      padding: "4px 31px 4px 4px",
    },
    "&:after": {
      padding: "5px 10px",
    },
  }),

  large: css({}),
}

export class Select<T extends string | number | undefined = string> extends GenericInputElement<
  Props<T>,
  GenericInputElementState,
  HTMLSelectElement,
  T
> {
  public inputElRef: HTMLSelectElement | null
  enableLiveValidation = true

  public static defaultProps: Partial<Props<any>> = {
    readOnly: false,
    useDefault: false,
    defaultValue: null,
    options: [],
  }

  constructor(props: Props<T>) {
    super(props)

    this.state = this.defaultState
  }

  @bind
  handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({ isPristine: false })

    const flatOptions: Option<T>[] = this.props.options.reduce((acc, option) => {
      if ("options" in option) {
        return [...acc, ...option.options]
      } else {
        return [...acc, option]
      }
    }, [] as Option<T>[])

    const maybeOption = flatOptions[event.target.selectedIndex]
    let selectedValue: T
    if (maybeOption && "value" in maybeOption) {
      selectedValue = maybeOption.value
    } else {
      selectedValue = undefined as any
    }

    this.props.onValueChange && this.props.onValueChange(selectedValue, !maybeOption.disabled)
  }

  renderInner() {
    const classes = [selectClass]

    this.props.disabled && classes.push(disabledClass)
    this.props.size && classes.push(sizeClasses[this.props.size])

    function isOptionGroup(value: any): value is OptionGroup<T> {
      return !isNullOrUndefined(value.options)
    }

    const options = this.props.options.map((option, idx) => {
      if (isOptionGroup(option)) {
        return (
          <optgroup key={`group--${option.label}`} label={option.label} disabled={option.disabled}>
            {option.options.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </optgroup>
        )
      } else {
        return (
          <option key={option.value?.toString() ?? `option-${idx}`} value={option.value} disabled={option.disabled}>
            {option.label}
          </option>
        )
      }
    })

    return (
      <div className={cx(classes)}>
        <select
          id={this.props.id}
          ref={(el) => (this.inputElRef = el)}
          disabled={this.props.disabled}
          value={this.props.value}
          defaultValue={this.props.defaultValue || undefined}
          name={this.props.name}
          required={this.props.required}
          onChange={this.handleChange}
          onFocus={this.props.onFocus}
          autoFocus={this.props.autoFocus}
          onKeyDown={this.props.onKeyDown}
          tabIndex={this.props.tabIndex}
        >
          {options}
        </select>
      </div>
    )
  }
}

export default Select
