import { ApiToken, CreateApiToken } from "../models/api-token"
import { ApiStats } from "../models/api-stats"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { store } from "../../relas/store"
import { AxiosError, AxiosResponse, default as Axios } from "axios"
import { apiServiceUrl } from "../../app_config"
import { GenericError, toGenericError } from "../../shared/helper/axios"

interface ApiState {
  error: GenericError | null
  apiTokens: ApiToken[] | null
  statToken: string | null
  currentStats: ApiStats | null
  fetchStatsInProgress: boolean
  fetchStatsError: GenericError | null
}

export type CompanyApiState = { apiState: ApiState }

export const initialState: ApiState = {
  error: null,
  apiTokens: null,
  statToken: null,
  currentStats: null,
  fetchStatsError: null,
  fetchStatsInProgress: false,
}

const apiSlice = createSlice({
  name: "companyApi",
  initialState,
  reducers: {
    apiSetError(state, action: PayloadAction<GenericError>) {
      state.error = action.payload
    },
    apiListTokensStart(state) {
      state.error = null
      state.apiTokens = null
    },
    apiListTokensDone(state, action: PayloadAction<ApiToken[]>) {
      state.error = null
      state.apiTokens = action.payload
    },
    apiSetStatToken(state, action: PayloadAction<string>) {
      state.statToken = action.payload
    },
    apiDismissStatToken(state) {
      state.statToken = null
    },
    apiStartFetchStats(state) {
      state.fetchStatsInProgress = true
      state.currentStats = null
    },
    apiDoneFetchStats(state, action: PayloadAction<ApiStats>) {
      state.fetchStatsInProgress = false
      state.currentStats = action.payload
    },
    apiErrorFetchStats(state, action: PayloadAction<GenericError>) {
      state.fetchStatsInProgress = false
      state.currentStats = null
      state.fetchStatsError = action.payload
    },
  },
})

const {
  apiDismissStatToken,
  apiSetStatToken,
  apiErrorFetchStats,
  apiDoneFetchStats,
  apiStartFetchStats,
  apiListTokensDone,
  apiListTokensStart,
  apiSetError,
} = apiSlice.actions

export function listApiTokens(): Promise<void> {
  store.dispatch(apiListTokensStart())
  return Axios.get(`${apiServiceUrl}/api/admin/tokens`).then(
    (success: AxiosResponse) => {
      store.dispatch(apiListTokensDone(success.data))
    },
    (error: AxiosError) => {
      store.dispatch(apiSetError(toGenericError(error)))
    }
  )
}

export function showTokenStats(token: string): Promise<void> {
  store.dispatch(apiSetStatToken(token))
  return Promise.resolve()
}

export function dismissTokenStats(): Promise<void> {
  store.dispatch(apiDismissStatToken())
  return Promise.resolve()
}

export function createApiToken(createToken: CreateApiToken): Promise<void> {
  return Axios.post(`${apiServiceUrl}/api/admin/tokens`, createToken).then(
    () => listApiTokens(),
    (error: AxiosError) => {
      store.dispatch(apiSetError(toGenericError(error)))
    }
  )
}

export function fetchTokenStats(token: string, year: number, month: number): Promise<void> {
  store.dispatch(apiStartFetchStats())
  return Axios.get(`${apiServiceUrl}/api/admin/stats/tokens/${token}/year/${year}/month/${month}`).then(
    (success: AxiosResponse) => {
      store.dispatch(apiDoneFetchStats(success.data))
    },
    (error: AxiosError) => {
      store.dispatch(apiErrorFetchStats(toGenericError(error)))
    }
  )
}

export function revokeToken(token: string): Promise<void> {
  return Axios.delete(`${apiServiceUrl}/api/admin/tokens/${encodeURIComponent(token)}`).then(
    () => listApiTokens(),
    (error: AxiosError) => {
      store.dispatch(apiSetError(toGenericError(error)))
    }
  )
}
export default apiSlice.reducer
