import { css, cx, ObjectInterpolation } from "emotion"
import { AlignSpec, OffsetSpec, offsetSpecToPadding } from "./contraints"
import React, { PropsWithChildren } from "react"

export interface FlexItemProps {
  flexGrow?: number
  flexShrink?: number
  alignSelf?: AlignSpec
  overflow?: "visible" | "hidden" | "scroll" | "auto" | "inherit" | "initial" | "unset"
  padding?: OffsetSpec
}

export const FlexItem: React.FunctionComponent<PropsWithChildren<FlexItemProps>> = (props) => {
  const classes: string[] = []
  const { padding, overflow, alignSelf, flexGrow, flexShrink } = props
  const emotionStyles: ObjectInterpolation<undefined>[] = []

  flexGrow !== undefined && emotionStyles.push({ flexGrow })
  flexShrink !== undefined && emotionStyles.push({ flexShrink })
  overflow !== undefined && emotionStyles.push({ overflow })
  alignSelf !== undefined && emotionStyles.push({ alignSelf })

  padding !== undefined && emotionStyles.push(offsetSpecToPadding(padding))

  classes.push(css(emotionStyles))

  return <div className={cx(classes)}>{props.children}</div>
}
