import { BorderBottom } from "../../shared/components/ui/border-bottom"
import { Flex } from "../../shared/components/ui/flex"
import { FlexItem } from "../../shared/components/ui/flex-item"
import { Drawer } from "@blueprintjs/core"
import * as React from "react"
import { RatingSelectionRule } from "../../shared/models/selection"
import { GlobalState, useAppSelector } from "../../relas/store"
import { translations } from "../i18n"
import { SelectionRuleEdit } from "./selection-rule-edit"
import { SelectionRuleList } from "./selection-rule-list"
import {
  fetchSelectionResults,
  returnToSelectionList,
  SelectionPane,
  selectRuleForEditing,
  setAgsRefResLoc,
  setViewPane,
  updateSelection,
} from "../location-selector-slice"
import Grid from "../../shared/components/restyle-grid/grid"
import Button from "../../shared/components/button"

export const EditSelection = () => {
  const t = React.useMemo(() => translations(), [])

  const currentSelection = useAppSelector((state: GlobalState) => state.locationSelector.currentSelection)
  const selectedSelectionRule = useAppSelector(
    (state: GlobalState) => state.locationSelector.selectedSelectionRuleIndex
  )
  const availableProfiles = useAppSelector((state: GlobalState) => state.locationSelector.availableProfiles)
  const agsRefResLoc = useAppSelector((state: GlobalState) => state.locationSelector.agsRefResLoc)

  const [showRuleEdit, setShowRuleEdit] = React.useState(false)
  const [indexOfRuleBeingEdited, setIndexOfRuleBeingEdited] = React.useState(-1)

  const closeRuleEdit = () => {
    setShowRuleEdit(false)
    setIndexOfRuleBeingEdited(-1)
  }

  const onRuleDelete = async (ruleIdx: number) => {
    if (!currentSelection) return Promise.resolve()

    await updateSelection({
      ...currentSelection,
      rules: [...currentSelection.rules.slice(0, ruleIdx), ...currentSelection.rules.slice(ruleIdx + 1)],
    })
    return selectRuleForEditing(-1)
  }

  const onRuleReorder = async (sourceIdx: number, destIdx: number) => {
    if (!currentSelection) return

    const newSelectionRules = [...currentSelection.rules]
    ;[newSelectionRules[destIdx], newSelectionRules[sourceIdx]] = [
      newSelectionRules[sourceIdx],
      newSelectionRules[destIdx],
    ]

    await updateSelection({
      ...currentSelection,
      rules: newSelectionRules,
    })

    return closeRuleEdit()
  }

  const onRuleChanged = async (newRule: RatingSelectionRule) => {
    if (!currentSelection) return

    const rules =
      indexOfRuleBeingEdited >= 0
        ? [
            ...currentSelection.rules.slice(0, indexOfRuleBeingEdited),
            newRule,
            ...currentSelection.rules.slice(indexOfRuleBeingEdited + 1),
          ]
        : [...currentSelection.rules, newRule]

    await updateSelection({
      ...currentSelection,
      rules,
    })

    return closeRuleEdit()
  }

  return (
    <Grid height={[100, "%"]} columns={1}>
      <Drawer
        isOpen={showRuleEdit}
        size="400px"
        onClose={() => {
          setShowRuleEdit(false)
          setIndexOfRuleBeingEdited(-1)
        }}
      >
        {currentSelection && (
          <SelectionRuleEdit
            rule={indexOfRuleBeingEdited >= 0 ? currentSelection.rules[indexOfRuleBeingEdited] : null}
            dataSetType={currentSelection.dataSetType}
            onEdited={onRuleChanged}
            onClose={closeRuleEdit}
          />
        )}
      </Drawer>
      <Grid columns={1} height={[100, "%"]} rowSpec="min-content 1fr">
        <BorderBottom>
          <Flex flexDirection="column" padding={[8, 0]}>
            <Flex flexDirection="row" padding={[8, 16]}>
              <Button type="tertiary" icon="back" onClick={returnToSelectionList}>
                {t.allSelections}
              </Button>
              <FlexItem flexGrow={1} />
              <Button type="tertiary" icon="edit" onClick={() => setViewPane(SelectionPane.EditTitle)} />
            </Flex>
            <FlexItem alignSelf="start" padding={[0, 16]}>
              <h2>{currentSelection?.name}</h2>
            </FlexItem>
          </Flex>
        </BorderBottom>

        {currentSelection && (
          <SelectionRuleList
            availableProfiles={availableProfiles || []}
            dataSetType={currentSelection.dataSetType}
            rules={currentSelection.rules}
            selectedRuleIdx={selectedSelectionRule}
            onRuleSelect={(i) => {
              selectRuleForEditing(i)
              currentSelection && void fetchSelectionResults(currentSelection, i + 1, agsRefResLoc ?? undefined)

              const rule = currentSelection?.rules[i]
              if (rule && rule.type === "loadmicrodata") {
                setAgsRefResLoc(rule.exampleAgsRefResLoc)
              }
            }}
            onRuleEdit={(editIdx) => {
              setIndexOfRuleBeingEdited(editIdx)
              setShowRuleEdit(true)
            }}
            onRuleDelete={onRuleDelete}
            onRuleReorder={onRuleReorder}
          />
        )}

        <Flex flexDirection="column-reverse">
          <Flex flexDirection="row" padding={16}>
            <FlexItem flexGrow={1} />
            <Button type="primary" onClick={() => setShowRuleEdit(true)}>
              {t.addNewRule}
            </Button>
          </Flex>
        </Flex>
      </Grid>
    </Grid>
  )
}
