import * as React from "react"
import { useMemo } from "react"
import { css } from "emotion"
import { useAppDispatch, useAppSelector } from "../../../relas/store"
import { extractLabel } from "../../../shared/models/selection"
import { translations } from "../../i18n"
import { hexToRgb } from "../../../shared/models/rating-grade"
import { RatingManagerMapMicroView } from "./rating-manager-map-micro-view"
import { RatingManagerMapMacroView } from "./rating-manager-map-macro-view"
import { ratingManagerActions } from "../../rating-manager-slice"
import { fetchRatingResults } from "../../rating-manager-actions"
import { isRatingsResults } from "../../rating-manager-common"
import { isMetaRating } from "../../../shared/models/ratings"
import {
  updateSelectedCategories,
  fetchCategories,
  fetchCategoryPois,
  updatePOIsToShow,
} from "../../../private-data/reducers/private-data-slice"
import { RatingManagerMapMetaView } from "./rating-manager-map-meta-view"
import { Location } from "../../../assessment/models/address"
import { isInProgressState } from "../../../assessment/models/assessment"
import { isAssessmentPartitionOutdated } from "../../../shared/models/geo-partition"
import { DataSetType } from "../../../shared/models/smartdata"
import { AllowedModulesEnum } from "../../../private-data/models/private-data"
import { formatNumber, formatValue } from "../../../shared/helper/number-format"

const borderWrapperClass = css({
  height: "100%",
})

type Props = {
  hideAddressInput?: boolean
  hideMunicipalityListToggle?: boolean
  locationToPresent?: Location
  module: AllowedModulesEnum.RATING_MANAGER | AllowedModulesEnum.RATINGS
}

export const RatingManagerMap = (props: Props) => {
  const t = React.useMemo(() => translations(), [])

  const dispatch = useAppDispatch()

  const getPrivateDataCategories = fetchCategories
  const updateSelectedPrivateDataCategories = updateSelectedCategories
  const getPrivatePoisFromCategories = fetchCategoryPois
  const updatePrivatePoisToShow = updatePOIsToShow

  const ratingResults = useAppSelector((state) => state.ratingManager.ratingResults)
  const ratingResultsInProgress = useAppSelector((state) => state.ratingManager.ratingResultsInProgress)
  const showMunicipalityList = useAppSelector((state) => state.ratingManager.showMunicipalityList)
  const agsRefResLoc = useAppSelector(
    (state) => state.assessment.currentAssessmentEntry?.agsRefResloc ?? state.ratingManager.agsRefResLoc
  )
  const selectedMunicipalityAndFocus = useAppSelector((state) => state.ratingManager.selectedMunicipalityAndFocus)
  const showPreview = useAppSelector((state) => state.ratingManager.showPreview)
  const hasPreview = useAppSelector(
    (state) => state.ratingManager.selectedRatingSelectionIdx !== undefined && state.ratingManager.menuPane === "edit"
  )
  const isPrivateDataAccessible = useAppSelector((state) => state.user.scopes.privateData)
  const privatePoiCategories = useAppSelector((state) => state.privateData.privatePOICategories)
  const privateDataSettings = useAppSelector((state) => state.privateData.modulesWithPrivateData[props.module])

  const assessmentEntry = useAppSelector((state) => state.assessment.currentAssessmentEntry ?? undefined)

  const showRecalculateMessage = useAppSelector((state) => {
    return (
      (state.assessment.currentAssessment &&
        state.navigation.currentPage?.name === "ratings" &&
        (state.assessment.currentAssessment?.outdated ||
          state.assessment.assessmentUpdateInProgress ||
          isInProgressState(
            state.assessment.currentAssessmentEntry?.state ?? state.assessment.currentAssessment.state
          ))) ??
      false
    )
  })

  const { currentRating, markerId, mapView } = useAppSelector((state) => {
    let currentRating = state.ratingManager.currentRating
    let mapView: "meta" | DataSetType =
      (currentRating && (isMetaRating(currentRating) ? "meta" : currentRating.dataSetType)) ?? "micro"

    const markerId: number | undefined =
      mapView === "micro"
        ? state.assessment.currentAssessmentEntry?.cellId
        : state.assessment.currentAssessmentEntry?.agsId

    return {
      currentRating: currentRating,
      mapView: mapView,
      showRecalculateMessage: showRecalculateMessage,
      markerId: markerId,
    }
  })

  React.useEffect(() => {
    dispatch(ratingManagerActions.setSelectedMunicipalityAndFocus(undefined))
  }, [currentRating])

  const { items } = useMemo(() => {
    const items = new Map<
      string,
      { color: string; title: string; data: { label: string; value: string }[]; score?: number; grade?: string }
    >()

    if (ratingResults && currentRating && !isMetaRating(currentRating)) {
      const isRatings = isRatingsResults(ratingResults)

      if (isRatings) {
        for (const result of ratingResults.results) {
          const rgb = (currentRating.grades[result.gradeIdx]
            ? hexToRgb(currentRating.grades[result.gradeIdx].color)
            : undefined) ?? { r: 128, g: 128, b: 128 }
          const data: { label: string; value: string }[] = []

          if (currentRating.dataSetType === "macro") {
            const label = extractLabel(result, result.selectionLabel)

            if (label) {
              data.push({
                label: t.pickTranslation(label.label),
                value: label.value
                  ? formatValue(label.value, 2, label.unit ? t.pickTranslation(label.unit) : undefined)
                  : "",
              })
            }
            data.push({
              label: t.municipalitiesTab.population,
              value: formatNumber(result.fields.population as number, 0),
            })
          }

          items.set(result.refId, {
            title: ratingResults.dataSetType === "macro" ? (result.fields.ags_name as string) : `# ${result.refId}`,
            data,
            grade: currentRating.grades[result.gradeIdx]?.label ?? "",
            color: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.8)`,
          })
        }
      } else {
        let minScore = 0
        let maxScore = 100

        if (ratingResults.results.some((r) => typeof r.score === "number")) {
          ;[minScore, maxScore] = ratingResults.results.reduce(
            ([curMin, curMax], curr) => {
              if (typeof curr.score === "number") {
                return [Math.min(curMin, curr.score), Math.max(curMax, curr.score)]
              } else {
                return [curMin, curMax]
              }
            },
            [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER]
          )

          if (minScore === maxScore) {
            minScore = 0
            maxScore = 100
          }
        }

        for (const result of ratingResults.results) {
          const data: { label: string; value: string }[] = []

          if (currentRating.dataSetType === "macro") {
            const label = extractLabel(result, ratingResults.selectionLabel)

            if (label) {
              const isPercentage = label.unit?.en === "%"
              const valueToDisplay = isPercentage
                ? formatNumber(
                    label.value ? label.value * 100 : undefined,
                    2,
                    label.unit ? t.pickTranslation(label.unit) : undefined
                  )
                : formatNumber(
                    label.value,
                    label.value && label.value < 1000 ? 2 : 0,
                    label.unit ? t.pickTranslation(label.unit) : undefined
                  )

              data.push({
                label: t.pickTranslation(label.label),
                value: valueToDisplay,
              })
            }
            data.push({
              label: t.municipalitiesTab.population,
              value: formatNumber(result.fields.population as number, 0),
            })
          }

          items.set(result.refId, {
            title: currentRating.dataSetType === "macro" ? (result.fields.ags_name as string) : `# ${result.refId}`,
            data,
            score: typeof result.score === "number" ? result.score : undefined,
            color: colorForValue(((result.score || 0) - minScore) / (maxScore - minScore)),
          })
        }
      }
    }
    return { items }
  }, [currentRating, ratingResults])

  function colorForValue(value: number): string {
    return `hsla(${225 - (100 - (value * 50 + 50))}, 100%, 50%, 0.7)`
  }

  const errorMessageType = useMemo(() => {
    const view = mapView === "meta" ? "macro" : mapView
    if (isAssessmentPartitionOutdated(view, assessmentEntry)) {
      return "shape_outdated"
    }

    if (showRecalculateMessage) {
      return "recalculation"
    }

    return undefined
  }, [currentRating, mapView, assessmentEntry])

  return (
    <div className={borderWrapperClass}>
      {mapView === "micro" && (
        <RatingManagerMapMicroView
          loading={ratingResultsInProgress}
          agsRefResLoc={agsRefResLoc}
          items={items}
          onSelectAgsRefResLoc={(newAgsLocation) => {
            dispatch(ratingManagerActions.setAgsRefResLoc(newAgsLocation))
            currentRating && void fetchRatingResults(dispatch)(currentRating, newAgsLocation)
          }}
          showStroke={true}
          hasPreview={hasPreview}
          preview={showPreview}
          onPreviewToggle={() => dispatch(ratingManagerActions.setShowPreview(!showPreview))}
          isPrivateDataAccessible={isPrivateDataAccessible}
          privatePoiCategories={privatePoiCategories}
          privateDataSettings={privateDataSettings}
          getPrivateDataCategories={getPrivateDataCategories}
          updateSelectedPrivateDataCategories={updateSelectedPrivateDataCategories}
          getPrivatePoisFromCategories={getPrivatePoisFromCategories}
          updatePrivatePoisToShow={updatePrivatePoisToShow}
          locationToPresent={props.locationToPresent}
          mapErrorMessage={errorMessageType}
          markerId={markerId}
          module={props.module}
        />
      )}

      {mapView === "macro" && (
        <RatingManagerMapMacroView
          showMunicipalities={showMunicipalityList}
          onShowMunicipalities={(b) => dispatch(ratingManagerActions.setShowMunicipalityList(b))}
          items={items}
          loading={ratingResultsInProgress}
          selectedMunicipalityAndFocus={selectedMunicipalityAndFocus ?? null}
          onSetSelectedMunicipalityId={(selectedMunicipalityId) =>
            selectedMunicipalityId &&
            dispatch(
              ratingManagerActions.setSelectedMunicipalityAndFocus({ selectedMunicipalityId, focusInMap: false })
            )
          }
          selectedRatingId={currentRating?.id ?? null}
          hasPreview={hasPreview}
          preview={showPreview}
          onPreviewToggle={() => dispatch(ratingManagerActions.setShowPreview(!showPreview))}
          isPrivateDataAccessible={isPrivateDataAccessible}
          privatePoiCategories={privatePoiCategories}
          privateDataSettings={privateDataSettings}
          getPrivateDataCategories={getPrivateDataCategories}
          updateSelectedPrivateDataCategories={updateSelectedPrivateDataCategories}
          getPrivatePoisFromCategories={getPrivatePoisFromCategories}
          updatePrivatePoisToShow={updatePrivatePoisToShow}
          hideMunicipalityListToggle={props.hideMunicipalityListToggle}
          locationToPresent={props.locationToPresent}
          mapErrorMessage={errorMessageType}
          markerId={markerId}
          module={props.module}
        />
      )}
      {mapView === "meta" && (
        <RatingManagerMapMetaView
          items={new Map()}
          loading={ratingResultsInProgress}
          hasPreview={hasPreview}
          preview={showPreview}
          onPreviewToggle={() => dispatch(ratingManagerActions.setShowPreview(!showPreview))}
          isPrivateDataAccessible={isPrivateDataAccessible}
          privatePoiCategories={privatePoiCategories}
          privateDataSettings={privateDataSettings}
          getPrivateDataCategories={getPrivateDataCategories}
          updateSelectedPrivateDataCategories={updateSelectedPrivateDataCategories}
          getPrivatePoisFromCategories={getPrivatePoisFromCategories}
          updatePrivatePoisToShow={updatePrivatePoisToShow}
          currentRating={currentRating && isMetaRating(currentRating) ? currentRating : undefined}
          hideAddressInput={props.hideAddressInput}
          locationToPresent={props.locationToPresent}
          agsRefResLoc={agsRefResLoc}
          mapErrorMessage={errorMessageType}
          module={props.module}
        />
      )}
    </div>
  )
}
