import { Translations } from "./translations"

export const DE: Translations = {
  membersList: {
    header: "Mitglieder Ihres Unternehmens",
    headerFieldId: "ID",
    headerFieldName: "Name",
    headerFieldSurname: "Nachname",
    headerFieldEMail: "E-Mail",
    headerFieldActions: "Aktionen",
    headerFieldRole: "Rolle",
    admin: "Administrator",
    user: "Benutzer",
    promote: "Adminrechte vergeben",
    revoke: "Adminrechte entziehen",
    addNewMember: "Neuen Mitarbeiter einladen",
    language: {
      de: "Deutsch",
      en: "Englisch",
    },
    nameLabel: "Vorname",
    surnameLabel: "Name",
    languageLabel: "Sprache",
    emailLabel: "E-Mail",
    inviteMemberLabel: "Einladung versenden",
    invalidEmail: "E-Mailadresse ist nicht gültig",
    duplicateEmailErrorMsg: "E-Mailadresse bereits vergeben",
    headerFieldMemberStatus: "Status",
    memberInvitationPending: "Eingeladen",
    blocked: "Gesperrt",
    memberDelete: "Löschen",
    deleteUserText: "Wollen Sie diesen Benutzer wirklich unwiderruflich löschen?",
  },
  settings: {
    header: "Einstellungen für Ihr Unternehmen",
    logoUploadHeadline: "Firmenlogo",
    logoUrlUploadButtonLabel: "Hochladen",
    logoUrlDeleteButtonLabel: "Löschen",
    addressHeadline: "Adresse",
    streetLabel: "Straße",
    houseNumberLabel: "Hausnummer",
    countryLabel: "Land",
    zipLabel: "Postleitzahl",
    cityLabel: "Stadt",
    additionalInfoLabel: "Zusätzliche Adressinformationen",
    openEditAddressDialogLabel: "Bearbeiten",
    saveAddressButton: "Speichern",
    editAddressDialogTitle: "Adresse ändern",
    editAddressWarningText:
      "Das Ändern Ihrer Adresse hat zur Folge, dass Sie Ihren Address-Verified-Badge verlieren. Um diesen wieder zu erhalten, müssen Sie Ihre neue Adresse verifzieren. Dazu wird Ihnen nach Eingabe Ihrer Adresse erneut ein Brief mit einem Verifizierungscode zugesendet.",
    editAddressWarningCancelLabel: "Abbrechen",
    editAddressWarningConfirmLabel: "Adresse trotzdem ändern",
    editAddressSuccessText:
      "Wir haben Ihre neue Adresse gespeichert und werden Ihnen einen Bestätigungscode per Post zukommen lassen. Diesen können Sie nach Erhalt an dieser Stelle eingeben, um den Address-Verified-Badge wieder zu erhalten.",
    okButtonLabel: "OK",
    cancelButtonLabel: "Abbrechen",
    addressVerifiedLabel: "verifiziert",
    addressNotVerifiedLabel: "nicht verifiziert",
    addressVerificationSuccessText:
      "Ihre Adresse wurde erfolgreich verifiziert. Bitte melden Sie sich erneut an, damit die Einstellungen wirksam werden.",
    logoutButtonLabel: "Abmelden",
    verifyAddressDialogTitle: "Verifizierung Ihrer Adresse",
    verifyAddressHint: "Ihr Verifizierungscode (wurde per Post verschickt)",
    verifyAddressConfirmButtonLabel: "Verifizieren",
    openValidateAddressDialogLabel: "Adresse verifizieren",
    wrongVerificationCodeError:
      "Der von Ihnen eingegebene Adressverifizierungscode ist leider nicht korrekt. Bitte versuchen Sie es erneut.",
  },
  shortCode: "de",
  overview: {
    cardHeader: "Übersicht der Firmen mit Produktnutzung",
    companyId: "ID",
    companyName: "Firmenname",
  },
  booking: {
    currentlyActive: "Aktuell aktiv",
    currentlyInactive: "Aktuell inaktiv",
    currentlyActiveStandard: "Aktuell aktive Standard-Produktpakete",
    currentlyInactiveStandard: "Aktuell inaktive Standard-Produktpakete",
    createBooking: "Buchung anlegen",
    endDate: "Enddatum",
    startDate: "Startdatum",
    softLimit: "Kostenalarm ab Aufrufe/Monat",
    bundle: "Bundle",
    cancel: "Abbrechen",
    create: "Anlegen",
    bookings: "Buchungen",
    expireNow: "Jetzt beenden",
    actions: "Aktionen",
    endBeforeStart: "Das Enddatum darf nicht vor dem Startdatum liegen.",
    processedDone: "Erfolgreich bearbeitet",
    processedPending: "Bearbeitung ausstehend",
    createdBy: "Angelegt von",
    expiredBy: "Beendet durch",
    description: {
      W1: "Aktuelle Marktpreise",
      G1: "Aktuelle Marktpreise",
      W2: "Preisentwicklung + Aktuelle Marktpreise",
      G2: "Preisentwicklung + Aktuelle Marktpreise",
      W3: "Lageinformation + Preisentwicklung + Aktuelle Marktpreise",
      G3: "Lageinformation + Preisentwicklung + Aktuelle Marktpreise",
      W4: "Investmentbewertung + Lageinformation + Preisentwicklung + Aktuelle Marktpreise",
      G4: "Investmentbewertung + Lageinformation + Preisentwicklung + Aktuelle Marktpreise",
      W5: "Smart Data Report",
      W6: "Mietspiegel",
      W7: "Objektbewertung",
      BHYP: "Bhyp excel + bhyp ratings",
      XLF: "Excel functions",
      C7: "Wertermittlung",
      Valuation: "Valuation Service",

      FullShaping: "Shaping Komplettpaket",
      FullApiExplorer: "Api Explorer Komplettpaket",
      Everything: "Einmal alles (nur interne bitte)",

      LanaProfile: "LANA - Profile Manager",
      LanaAssessment: "LANA - Location Assessment",
      LanaSpecialMaps: "LANA - Sonderkarten",
      LanaExplorer: "LANA - Location Explorer",
      LanaComparables: "LANA - Location Comparables",
      LanaMarketData: "LANA - Quartiersdaten",
      LanaPointsOfInterest: "LANA - Points of Interest",
      LanaPrivateData: "LANA - Private Data",
      LanaLocationSelector: "LANA - Location Selector",
      LanaRatingManager: "LANA - Rating Manager",
      DAPIFull: "Data-API Voller Zugriff",
    },
    gotoBookings: "Buchungen verwalten",
    gotoLimits: "Limits verwalten",
  },
  dateFormat: "DD.MM.YYYY",
  invoiceItemsTranslations: {
    from: "von",
    to: "bis",
    fetch: "abrufen",
    items: "Einträge",
    download: "Herunterladen",
  },
  productUsage: {
    productUsage: "Produktnutzung",
    billableAssessments: "Zahlungsrelevante Assessments",
    usageCount: "Anzahl Nutzung",
    deduplicationNotice: "Die Nutzung an einer bestimmten Addresse wird innerhalb von 6 Monaten nur einmalig gezählt.",

    quota: {
      title: "Verfügbares Kontingent",
      amount: "Gesamtkontingent",
      availableNow: "Noch verfügbar",
      startDate: "Start Abrechnungszeitraum",
      usedForPeriod: "Bereits verbraucht",
    },
    translateMonth: (m: number) => {
      switch (m) {
        case 1:
          return "Januar"
        case 2:
          return "Februar"
        case 3:
          return "März"
        case 4:
          return "April"
        case 5:
          return "Mai"
        case 6:
          return "Juni"
        case 7:
          return "Juli"
        case 8:
          return "August"
        case 9:
          return "September"
        case 10:
          return "Oktober"
        case 11:
          return "November"
        case 12:
          return "Dezember"
        default:
          return "???"
      }
    },
    nextMonth: "Nächster Monat",
    prevMonth: "Vorheriger Monat",
  },
  readonlyBooking: {
    cardTitle: "Aktive Buchungen",
    numberOfBillableAssessments: "Anzahl der in diesem Monat zahlungsrelevanten Assessments",
  },
  api: {
    apiToken: {
      listHeader: "API Tokens",
      label: "Bezeichnung",
      token: "Token",
      createdAt: "Erzeugt am",
      lastUsed: "Zuletzt verwendet",
      active: "Activ",
      revokeToken: "API Token Invalidieren",
      revoke: "Invalidieren",
      createToken: "Neues API Token erzeugen",
      create: "Anlegen",
      copyToClipboard: "Token in die Zwischenablage kopiere",
      labelHint: "Hier können Sie einen eigenen Namen für das Token vergeben.",
      revokeExplanation: "Wollen Sie das Token wirklich invalidieren? Es kann dann nicht mehr verwendet werden.",
    },
    cancel: "Abbrechen",
    close: "Schließen",
    demoInfo:
      "Wenn sie diesem Link folgen kommen sie auf eine Seite die mit Ihrem API Token die Verwendung unserer API demonstriert.",
    cardHeader: "Karten Kopfball",
    panelHeader: "Gremium Kopfball",
    buttonText: "Knopf Text",
    dateFormat: "DD.MM.YYYY",
    dateTimeFormat: "DD.MM.YYYY HH:mm",
    shortLanguageName: "de",
    stats: {
      title: "API Token Nutzungsübersicht",
      month: "Monat",
      year: "Jahr",
      doFetch: "Aktualisieren",
      apiNameHeader: "API Name",
      callsHeader: "Anzahl Aufrufe",
      translateMonth: (m: number) => {
        switch (m) {
          case 1:
            return "Januar"
          case 2:
            return "Februar"
          case 3:
            return "März"
          case 4:
            return "April"
          case 5:
            return "Mai"
          case 6:
            return "Juni"
          case 7:
            return "Juli"
          case 8:
            return "August"
          case 9:
            return "September"
          case 10:
            return "Oktober"
          case 11:
            return "November"
          case 12:
            return "Dezember"
          default:
            return "???"
        }
      },
      nextMonth: "Nächster Monat",
      prevMonth: "Vorheriger Monat",
      statsLink: "Nutzungsübersicht",
      noCallsFor: (label: string) => `Das Token ${label} wurde in diesem Zeitraum nicht benutzt`,
      explanation: (label: string, month: string, year: number) =>
        `Hier finden Sie eine Nutzungsübersicht für das Token ${label} für den Monat ${month} ${year}. Anfragen im Test-Rechteck um Berlin 10117 und fehlerhafte API-Antworten werden nicht gezählt`,
      invoiceLinkText: "hier",
      invoiceLinkPrefix: "Um zu sehen wieviel tatsächliche Produktnutzung stattgefunden hat, klicken Sie bitte",
    },
    apiDocExplanationBeforeLink: "Für die API-Dokumentation klicken Sie bitte",
    here: "hier",
  },
}
