import { State } from "../reducers/state"
import { useSelector } from "react-redux"
import { YieldType } from "../models/assessment"
import React, { FC } from "react"
import { YieldTrendsGraph } from "./assessment-entry-yield-trends-graphs"

interface Props {
  yieldType: YieldType
  dashboardView?: boolean
}

export const YieldTrendsContent: FC<Props> = (props) => {
  const { currentObjectYields } = useSelector((state: State) => state.assessment)

  return (
    <YieldTrendsGraph yieldType={props.yieldType} dashboardView={props.dashboardView} yieldData={currentObjectYields} />
  )
}
