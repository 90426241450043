import * as React from "react"
import { BorderBottom } from "../../shared/components/ui/border-bottom"
import { Flex } from "../../shared/components/ui/flex"
import { FlexItem } from "../../shared/components/ui/flex-item"
import { Grid } from "../../shared/components/ui/grid"
import { BareCard } from "../../shared/components/ui/bare-card"
import { translations } from "../i18n"
import { GlobalState, useAppSelector } from "../../relas/store"
import { clearUpdateSelectionError, SelectionPane, setViewPane, updateSelection } from "../location-selector-slice"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import Panel from "../../shared/components/panel"
import Button from "../../shared/components/button"
import TextField from "../../shared/components/textfield"
import { GenericError } from "../../shared/helper/axios"

export const EditSelectionTitle = () => {
  const t = React.useMemo(() => translations(), [])
  const currentSelection = useAppSelector((state: GlobalState) => state.locationSelector.currentSelection)
  const updateError = useAppSelector((state: GlobalState) => state.locationSelector.updateSelectionError)

  const [title, setTitle] = React.useState(currentSelection?.name ?? "")

  React.useEffect(() => {
    setTitle(currentSelection?.name ?? "")
  }, [currentSelection])

  React.useEffect(() => {
    updateError && clearUpdateSelectionError()
  }, [title])

  const renderUpdateError = (error: GenericError) => {
    if (error.status && error.status === 409) {
      return <Panel color="negative">{t.selectionAlreadyExists}</Panel>
    }
    return <GenericErrorPanel error={error} />
  }

  const isValid = () => title.length > 0

  const onOk = () => {
    if (!currentSelection) return

    const newSelection = {
      ...currentSelection,
      name: title,
    }

    updateSelection(newSelection)
      .then(() => setViewPane(SelectionPane.Edit))
      .catch(() => {})
  }

  return (
    <Grid height={[100, "%"]} columns={1}>
      <BareCard>
        <Grid columns={1} height={[100, "%"]} rowSpec="min-content 1fr">
          <BorderBottom>
            <Flex flexDirection="column" padding={[8, 0]}>
              <FlexItem alignSelf="start" padding={[8, 16]}>
                <Button type="tertiary" icon="back" onClick={() => currentSelection && setViewPane(SelectionPane.Edit)}>
                  {currentSelection?.name}
                </Button>
              </FlexItem>
              <FlexItem alignSelf="start" padding={[0, 16]}>
                <h2>
                  {t.selectionListMenu.edit} {t.selection}
                </h2>
              </FlexItem>
            </Flex>
          </BorderBottom>
          <Flex flexDirection="column" padding={16} gap={8}>
            <TextField value={title} onValueChange={(title) => setTitle(title)} label={t.title} />
            {updateError && renderUpdateError(updateError)}
          </Flex>
          <Flex flexDirection="row" padding={16}>
            <FlexItem flexGrow={1} />
            <Button type="primary" onClick={onOk} disabled={!isValid()}>
              {t.ok}
            </Button>
          </Flex>
        </Grid>
      </BareCard>
    </Grid>
  )
}
