import { Button, MenuItem } from "@blueprintjs/core"
import { ItemRendererProps, MultiSelect2 } from "@blueprintjs/select"
import React from "react"

export interface TagEditFieldProps {
  availableTags: string[]
  selectedTags: string[]
  onSelectionChanged: (selectedTags: string[]) => void
}

function itemPredicate(query: string, item: string, _index: number, exactMatch: boolean) {
  if (exactMatch) return query.toLowerCase() === item.toLowerCase()
  else return item.toLowerCase().startsWith(query.toLowerCase())
}

function renderTag(item: string) {
  return <span style={{ paddingLeft: "2px" }}>{item}</span>
}

export const TagEditField: React.FC<TagEditFieldProps> = (props) => {
  function onItemSelect(item: string) {
    const { selectedTags } = props
    const idx = selectedTags.indexOf(item)

    if (idx < 0) {
      props.onSelectionChanged([...selectedTags, item])
    }
  }

  function onItemRemove(_item: string, index: number) {
    const { selectedTags } = props
    props.onSelectionChanged([...selectedTags.slice(0, index), ...selectedTags.slice(index + 1)])
  }

  function onClear() {
    props.onSelectionChanged([])
  }

  function renderItem(item: string, itemProps: ItemRendererProps) {
    if (!itemProps.modifiers.matchesPredicate || props.selectedTags.indexOf(item) >= 0) return null
    return <MenuItem text={item} key={item} active={itemProps.modifiers.active} onClick={itemProps.handleClick} />
  }

  function renderCreateTag(query: string, active: boolean, handleClick: React.MouseEventHandler<HTMLElement>) {
    return (
      <MenuItem
        icon="add"
        text={`Create "${query}"`}
        active={active}
        onClick={handleClick}
        shouldDismissPopover={false}
      />
    )
  }

  const { availableTags, selectedTags } = props

  const clearButton = selectedTags.length > 0 ? <Button icon="cross" minimal={true} onClick={onClear} /> : undefined

  return (
    <MultiSelect2
      items={availableTags}
      selectedItems={selectedTags}
      itemRenderer={renderItem}
      tagRenderer={renderTag}
      onItemSelect={onItemSelect}
      itemsEqual={(a, b) => a.toLowerCase() === b.toLocaleLowerCase()}
      itemPredicate={itemPredicate}
      createNewItemFromQuery={(item) => item}
      createNewItemRenderer={renderCreateTag}
      popoverProps={{ minimal: true, openOnTargetFocus: true }}
      tagInputProps={{ tagProps: { minimal: true }, onRemove: onItemRemove, rightElement: clearButton }}
      placeholder="Tags..."
      resetOnSelect
      fill
    />
  )
}
