import React, { useMemo, useState } from "react"
import { MenuItem, PanelProps } from "@blueprintjs/core"
import {
  privatePOIsIcons,
  PrivatePOICategory,
  CreateOrEditCategoryPanelInfo,
  CategoryFormData,
} from "../../models/private-data"
import { css } from "emotion"
import { translations } from "../../i18n"
import { useForm } from "react-hook-form"
import { IItemModifiers } from "@blueprintjs/select/src/common/itemRenderer"
import { Flex } from "../../../shared/components/ui/flex"
import { FlexItem } from "../../../shared/components/ui/flex-item"
import { trimText } from "../../../utils/utils"
import { Select } from "@blueprintjs/select"
import { Button as ButtonBP } from "@blueprintjs/core/lib/esm/components/button/buttons"
import { createOrEditCategory, fetchCategories } from "../../reducers/private-data-slice"
import { useAppSelector } from "../../../relas/store"
import { trackUsageEvent } from "../../../utils/usage-tracking"
import Panel from "../../../shared/components/panel"
import TextArea from "../../../shared/components/textarea"
import Grid from "../../../shared/components/restyle-grid/grid"
import TextField from "../../../shared/components/textfield"
import Button from "../../../shared/components/button"
import { getThemeColor } from "../../../shared/helper/color"
import Icon from "../../../shared/components/icon"

const styles = {
  paneHeader: css({
    padding: "8px 16px",
    display: "flex",
    gap: "8px",
    flexDirection: "column",
    borderBottom: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
  }),
}

const getEmptyCategoryFormData = (categoryToEdit?: PrivatePOICategory): CategoryFormData =>
  categoryToEdit
    ? {
        title: categoryToEdit.title,
        notes: categoryToEdit.notes ?? "",
        icon: categoryToEdit.icon ?? "100",
      }
    : {
        title: "",
        notes: "",
        icon: "100",
      }

interface categoryFormErrors {
  empty: boolean
  duplicatedName: boolean
}
const getCategoryFormErrorsInitialState = (categoryToEdit?: PrivatePOICategory) => ({
  empty: !categoryToEdit,
  duplicatedName: false,
})

interface Props {
  categoryToEdit: PrivatePOICategory | undefined
  closePanel: () => void
}

const CreateOrEditImpl = (props: Props) => {
  const t = useMemo(translations, [translations])
  const privatePOICategories = useAppSelector((state) => state.privateData.privatePOICategories)
  const [categoryCreationError, setCategoryCreationError] = useState<categoryFormErrors>(
    getCategoryFormErrorsInitialState(props.categoryToEdit)
  )
  const { register, handleSubmit, watch, setValue } = useForm<CategoryFormData>({
    defaultValues: {
      ...getEmptyCategoryFormData(props.categoryToEdit),
    },
  })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onDone = () => {
    void fetchCategories()
    backToCategoriesList()
    setIsSubmitting(false)
  }

  const submit = async (categoryData: CategoryFormData, editedCategory?: PrivatePOICategory) => {
    setIsSubmitting(true)
    await createOrEditCategory({ editedCategory, categoryData })
    onDone()
  }

  async function onSubmit(data: CategoryFormData): Promise<void> {
    if (!isSubmitting) {
      const categoryDataToSend: CategoryFormData = {
        title: data.title.trim(),
        icon: data.icon,
        notes: data.notes.trim(),
      }
      if (props.categoryToEdit) {
        if (
          privatePOICategories.find(
            (poiCategory) =>
              poiCategory.title.toLowerCase() === data.title.toLowerCase().trim() &&
              poiCategory.id !== props.categoryToEdit?.id
          )
        ) {
          setCategoryCreationError({ ...categoryCreationError, duplicatedName: true })
        } else await submit(categoryDataToSend, props.categoryToEdit)
      } else {
        if (
          privatePOICategories.find(
            (poiCategory) => poiCategory.title.toLowerCase() === data.title.toLowerCase().trim()
          )
        ) {
          setCategoryCreationError({ ...categoryCreationError, duplicatedName: true })
        } else {
          trackUsageEvent("PRIVATE_DATA_POIS_CREATE_CATEGORY", null, data.title)
          await submit(categoryDataToSend, props.categoryToEdit)
        }
      }
    }
  }

  const itemRenderer = (item: string, handleClick: any, modifiers: IItemModifiers) => {
    return (
      <MenuItem
        active={modifiers.active}
        className={"private-icon-popover"}
        key={`icon-${item}`}
        text={
          <div style={{ width: "50px", height: "24px" }}>
            <img src={`/assets/private-data/${item}.svg`} width="24" height="24" alt="icon" />
          </div>
        }
        onClick={handleClick}
      />
    )
  }

  const backToCategoriesList = () => props.closePanel()
  return (
    <div style={{ display: "grid", gridTemplateRows: "min-content 1fr", height: "100%", fontSize: "14px" }}>
      <div className={styles.paneHeader}>
        <Flex flexDirection="column" gap={8}>
          <FlexItem alignSelf="start">
            <Button type="tertiary" icon="back" onClick={backToCategoriesList}>
              {t.privateData.allCategories}
            </Button>
          </FlexItem>
        </Flex>
        <h2>{props.categoryToEdit ? t.privateData.editCategory : t.privateData.createCategory}</h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid columns={1} gap={8} rowSpec={"min-content min-content 1fr min-content"} padding={16} height={[100, "%"]}>
          <Flex flexDirection={"column"} gap={4}>
            <div>{t.privateData.title}</div>
            <TextField
              {...register("title")}
              value={watch("title")}
              onValueChange={(valueAsString) => {
                setValue("title", valueAsString)
                if (valueAsString.trim() === "" && !categoryCreationError.empty) {
                  setCategoryCreationError({ ...categoryCreationError, empty: true })
                } else if (valueAsString.trim() !== "" && categoryCreationError)
                  setCategoryCreationError({
                    ...getCategoryFormErrorsInitialState(props.categoryToEdit),
                    empty: false,
                  })
              }}
              javaScriptAutoFocus={true}
            />
          </Flex>
          <Flex flexDirection={"column"} gap={4}>
            <div>{t.privateData.notes}</div>
            <TextArea
              {...register("notes")}
              value={watch("notes")}
              onValueChange={(valueAsString) => setValue("notes", trimText(valueAsString, 5120))}
              resize={false}
              rows={5}
            ></TextArea>
          </Flex>
          <Flex flexDirection={"column"} gap={4}>
            <div>{t.privateData.icon}</div>
            <Select<string>
              {...register("icon")}
              filterable={false}
              items={privatePOIsIcons}
              itemRenderer={(item, { handleClick, modifiers }) => itemRenderer(item, handleClick, modifiers)}
              onItemSelect={(item) => setValue("icon", item)}
              activeItem={watch("icon")}
            >
              <ButtonBP
                text={<img src={`/assets/private-data/${watch("icon")}.svg`} width="24" height="24" />}
                rightIcon={<Icon name={"dropdown_down"} color={"primary"} colorType={"default"} />}
              />
            </Select>
          </Flex>
          {categoryCreationError.duplicatedName && (
            <Panel color="negative">
              <p>{t.privateData.categoryNameAlreadyExists}</p>
            </Panel>
          )}

          <Flex flexDirection="row-reverse" gap={16}>
            <Button
              formType="submit"
              type="primary"
              loading={isSubmitting}
              disabled={categoryCreationError.duplicatedName || categoryCreationError.empty || isSubmitting}
            >
              {props.categoryToEdit ? t.privateData.save : t.privateData.create}
            </Button>
            <Button
              type="secondary"
              onClick={(e) => {
                e.preventDefault()
                backToCategoriesList()
              }}
            >
              {t.cancel}
            </Button>
          </Flex>
        </Grid>
      </form>
    </div>
  )
}
const CreateOrEditForm = CreateOrEditImpl

export const CreateOrEditCategoryPanel: React.FC<PanelProps<CreateOrEditCategoryPanelInfo>> = (props) => {
  return <CreateOrEditForm categoryToEdit={props.category} closePanel={props.closePanel} />
}
