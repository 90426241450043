import { default as React, useEffect } from "react"
import { menuTranslations } from "./i18n"
import Portal from "../shared/components/portal"
import Panel from "../shared/components/panel"
import Grid from "../shared/components/restyle-grid/grid"
import FlexContainer from "../shared/components/restyle-grid/flexcontainer"
import { LoginIS24Form } from "../login/components/login-is24-form"

export interface ReloginPortalProps {
  onWindowHidden?: () => void
  onWindowVisible?: () => void
}

export const ReloginPortal = (props: ReloginPortalProps) => {
  const translate = React.useMemo(() => menuTranslations(), [])

  const handleVisibilityChange = () => {
    if (!document.hidden) {
      props.onWindowVisible && props.onWindowVisible()
    } else {
      props.onWindowHidden && props.onWindowHidden()
    }
  }

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange)

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  })

  return (
    <Portal coverAllTheThings>
      <Panel>
        <Grid columnSpec={[[1, "fr"]]}>
          <FlexContainer direction="column" paddingX="md" paddingY="lg" spaceBetween="xl">
            <h1>{translate.reloginPortal.title}</h1>
            <div />
            <LoginIS24Form resetStateOnUserChange={true} />
          </FlexContainer>
        </Grid>
      </Panel>
    </Portal>
  )
}
