import React, { useEffect, useState } from "react"
import DashboardWidget from "./dashboard-widget"
import { translations } from "../../i18n"
import { PriceRange } from "../../models/assessment"
import { css, cx } from "emotion"
import Axios from "axios"
import { lanaApiUrl } from "../../../app_config"
import { RentIndexQuerySuccess } from "../../models/rentindex"
import { Address } from "../../models/address"
import { DashboardEditMode } from "./dashboard-widget-grid"
import Grid from "../../../shared/components/restyle-grid/grid"
import GridItem from "../../../shared/components/restyle-grid/griditem"
import { getThemeColorVar } from "../../../shared/helper/color"
import { formatNumber } from "../../../shared/helper/number-format"

export interface Props {
  rentIndexData?: PriceRange
  onHeaderClick: () => void
  onRemoveClick: () => void
  printMode: boolean
  editMode: DashboardEditMode
  onRenderingDone: () => void
  assessmentEntryAddress?: Address
  assessmentEntryYear?: number
  assessmentEntryArea?: number
}
const styles = {
  widgetContent: css({
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }),
  minMaxLabels: css({
    alignSelf: "end",
    justifySelf: "center",
  }),
  avgLabel: css({
    fontSize: "18px",
    fontWeight: "bold",
    paddingBottom: "12px",
    justifySelf: "center",
  }),
  bar: css({
    backgroundColor: getThemeColorVar("primary", "default"),
    height: "24px",
    margin: "0 8px",
  }),
  line: css({
    height: "40px",
    borderLeft: "1px solid",
    borderRight: "1px solid",
    borderColor: "white",
    backgroundColor: "black",
    position: "relative",
    top: "-36px",
    width: "4px",
  }),
}

export const RentIndexWidget = ({
  onHeaderClick,
  onRemoveClick,
  printMode,
  editMode,
  assessmentEntryAddress,
  assessmentEntryYear,
  assessmentEntryArea,
  onRenderingDone,
}: Props) => {
  const t = React.useMemo(translations, [translations])

  const [rentIndexData, setRentIndexData] = useState<PriceRange>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(true)
    Axios.post<RentIndexQuerySuccess[]>(`${lanaApiUrl}/api/v1/rentindex/calculate`, {
      address: assessmentEntryAddress,
      yearOfConstruction: assessmentEntryYear || 2000,
      areas: [assessmentEntryArea ?? 100],
      answers: {},
    })
      .then(
        (success) => {
          setRentIndexData(success.data[0].rentIndex)
        },
        () => {
          setRentIndexData(undefined)
        }
      )
      .finally(() => {
        setLoading(false)
        onRenderingDone()
      })
  }, [assessmentEntryAddress, assessmentEntryYear, assessmentEntryArea])

  const graphLeftShift =
    rentIndexData &&
    rentIndexData.min !== rentIndexData.max! &&
    rentIndexData.min !== rentIndexData.avg &&
    rentIndexData.max !== rentIndexData.avg
      ? ((rentIndexData.avg - rentIndexData.min) / (rentIndexData.max - rentIndexData.min)) * 100
      : undefined

  return (
    <DashboardWidget
      header={t.dashboard.rentIndex.header}
      headerAddition={[t.assessmentEntryDetails.euroPerSqm]}
      onHeaderClick={onHeaderClick}
      onRemoveClick={onRemoveClick}
      printMode={printMode}
      editMode={editMode}
    >
      <div className={styles.widgetContent}>
        {!rentIndexData && !loading && t.dashboard.rentIndex.noRentIndex}

        {rentIndexData && graphLeftShift && !loading && (
          <Grid columns={3} columnSpec={"1fr 6fr 1fr"} colGap={4} rowGap={4} rowSpec={"min-content 24px"} padding={4}>
            <div className={styles.minMaxLabels}>{formatNumber(rentIndexData.min, rentIndexData.min > 99 ? 0 : 2)}</div>
            <div className={styles.avgLabel}>{`Ø\u00A0 ${formatNumber(
              rentIndexData.avg,
              rentIndexData.avg > 99 ? 0 : 2
            )}`}</div>
            <div className={styles.minMaxLabels}>
              {" "}
              {formatNumber(rentIndexData.max, rentIndexData.max > 99 ? 0 : 2)}
            </div>
            <GridItem colSpan={3} padding={4}>
              <div className={styles.bar} />
              <div className={cx(styles.line, css({ left: `${graphLeftShift}%` }))} />
            </GridItem>
          </Grid>
        )}

        {rentIndexData && !graphLeftShift && (
          <div className={styles.avgLabel}>{`Ø\u00A0 ${formatNumber(
            rentIndexData.avg,
            rentIndexData.avg > 99 ? 0 : 2
          )}`}</div>
        )}
      </div>
    </DashboardWidget>
  )
}
