import React from "react"
import { translations } from "../../i18n"
import { Flex } from "../../../shared/components/ui/flex"
import { deleteDashboard } from "../../../relas/user-slice"
import { AssessmentDashboard } from "../../models/AssessmentDashboard"
import { DashboardSelectItem } from "../../../shared/components/multi-dashboard-select"
import Dialog from "../../../shared/components/dialog"
import Button from "../../../shared/components/button"
import { language } from "../../../shared/language"
import { getThemeColorVar } from "../../../shared/helper/color"

type Props = {
  dashboardToDelete: AssessmentDashboard
  setDashboardToDelete: (item?: AssessmentDashboard) => void
  dashboards: AssessmentDashboard[] | undefined
  selectedDashboard: DashboardSelectItem
  setHighLighted: (item?: DashboardSelectItem) => void
}

export const DeleteDashboardDialog = ({
  dashboardToDelete,
  selectedDashboard,
  setDashboardToDelete,
  setHighLighted,
  dashboards,
}: Props) => {
  const t = React.useMemo(() => translations().dashboard.editDashboardConfigurationDialog, [language()])
  const [processingAction, setProcessingAction] = React.useState<boolean>(false)

  const deleteConfiguration = async () => {
    if (dashboardToDelete) {
      await deleteDashboard(dashboardToDelete.id)
      if (dashboards) {
        if (dashboards.length > 2 && dashboardToDelete.id !== selectedDashboard?.id) {
          const nextHighlighted = dashboards[dashboards.findIndex((d) => d.id === dashboardToDelete.id) - 1]
          if (nextHighlighted) setHighLighted(nextHighlighted)
        }
        setHighLighted(dashboards?.find((d) => d.isDefault))
      }
      setProcessingAction(false)
      onClose()
    }
  }

  const onClose = () => {
    setDashboardToDelete()
  }

  const buttonsDisabled = !dashboardToDelete || processingAction

  return (
    <Dialog onClose={onClose} closeOnClickOutside={true}>
      <Flex flexDirection="column" gap={16} padding={0}>
        <Flex flexDirection="column" flexGrow={0} padding={56} gap={24}>
          <h2>{t.deleteDashboard}</h2>
          <p>{t.deleteDashboardConfirmation(dashboardToDelete.name)}</p>
        </Flex>
        <Flex
          flexDirection="row-reverse"
          flexGrow={0}
          gap={8}
          padding={[12, 24]}
          alignItems="start"
          backgroundColor={`${getThemeColorVar("background", "lighter")}`}
        >
          <Button
            icon="delete"
            type="primary"
            disabled={buttonsDisabled || (dashboardToDelete && dashboardToDelete.name.toLowerCase() === "default")}
            onClick={() => {
              setProcessingAction(true)
              void deleteConfiguration()
            }}
          >
            {t.delete}
          </Button>
          <Button
            type="secondary"
            onClick={() => {
              setProcessingAction(false)
              setDashboardToDelete()
            }}
          >
            {t.cancel}
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  )
}
