import React from "react"
import { RadioButton as RadioButtonComponent } from "../radiobutton"
import { Props } from "../radiobutton"
import { css } from "emotion"
import { getThemeColor } from "../../helper/color"

const radioButtonClass = css({
  accentColor: getThemeColor("primary", "default").string(),
})
export const RadioButton = React.forwardRef<Props, Props>((props: Props, ref) => {
  return (
    <div className={radioButtonClass}>
      <RadioButtonComponent {...props} ref={ref as React.LegacyRef<RadioButtonComponent>} />
    </div>
  )
})
