import Axios, { AxiosResponse, AxiosError } from "axios"
import { lanaApiUrl } from "../app_config"
import { Address } from "../assessment/models/address"

const usageTrackingEndpoint = `${lanaApiUrl}/api/reporting/usage`

type UsageEvent = {
  event: string
  address: Address | null
  additionalInfo: string | null
}

function makeUsageEvent(eventName: string, address: Address | null, additionalInfo: string | null): UsageEvent {
  return {
    event: eventName,
    address,
    additionalInfo,
  }
}

export function trackUsageEvent(eventName: string, address: Address | null, additionalInfo: string | null) {
  const usageEvent = makeUsageEvent(eventName, address, additionalInfo)
  Axios.post(usageTrackingEndpoint, usageEvent).then(
    (_: AxiosResponse) => {},
    (_: AxiosError) => {}
  )
}
