import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { emptyLanaScopes, getLanaScopes, getLanaScopesFromCookie, LanaScopesType } from "./user-state"
import {
  AssessmentDashboard,
  AssessmentDashboardId,
  AssessmentDashboardUpdate,
  AssessmentDashboardVersion,
  AssessmentDashboardWidgetId,
  AssessmentDynamicDashboardConfig,
  POISelectionMode,
  WidgetConfig,
  WidgetConfigData,
  WidgetConfigDimensions,
} from "../assessment/models/AssessmentDashboard"
import { CookieScopeRequirement } from "../menu/models/scoperequirements"
import { resetGlobalStore, store } from "./store"
import { authCookieExists } from "../menu/util/scorefromcookies"
import { hideMenu } from "../shared/reducers/navigation-slice"
import Axios from "axios"
import { lanaApiUrl, loginServiceUrl } from "../app_config"
import { fetchAndHandleUser } from "../utils/local-storage"
import { defaultDistrictWidgetData, defaultWidgetLayouts } from "../assessment/components/dashboard/dashboard-export"
import { v4 } from "uuid"
import { SelectionModes } from "../assessment/components/dashboard/ratings-selection"
import { defaultPOIsSettings } from "../assessment/components/dashboard/pois-widget"
import { defaultMacroScores, defaultMicroScores } from "../assessment/components/dashboard/scores-widget"
import { WidgetsType } from "../assessment/models/assessment"
import { trackUsageEvent } from "../utils/usage-tracking"
import { Address } from "../assessment/models/address"
import { DashboardEditMode } from "../assessment/components/dashboard/dashboard-widget-grid"
import {
  defaultIsochroneConfig,
  defaultPrivatePoiCategories,
} from "../assessment/components/dashboard/micro-map-widget"
import { GenericError } from "../shared/helper/axios"

export type UserState = {
  scopes: LanaScopesType
  lana: {
    assessmentDashboards: AssessmentDashboard[] | undefined // undefined means loading
    selectedDashboardId: AssessmentDashboardId | undefined // undefined means loading
    assessmentDashboardLoading: boolean
    assessmentDashboardLoadingError: GenericError | undefined
    editingUserDashboards: DashboardEditMode
  }
  user?: User
  isAuthorised: boolean
}

export const getInitialUserState = (scopeFromCookies: boolean = true, authCookieRead: boolean = true): UserState => ({
  scopes: scopeFromCookies ? getLanaScopesFromCookie() : emptyLanaScopes,
  lana: {
    assessmentDashboardLoading: true,
    assessmentDashboards: undefined,
    assessmentDashboardLoadingError: undefined,
    selectedDashboardId: undefined,
    editingUserDashboards: false,
  },

  isAuthorised: authCookieRead ? authCookieExists() : false,
})

export const initialState: UserState = getInitialUserState()

/** Deprecated */
type AssessmentStaticDashboardConfig = {
  microScores?: Array<string>
  macroScores?: Array<string>
  ratings?: [Array<string>, SelectionModes, string[]]
  POIs?: [Array<string>, POISelectionMode]
  districtData?: Array<string>
}

/** Deprecated */
// This type is used to interoperate with the old format in the Database. The old dashboard will be converted to the new format on the fly.
interface StaticAssessmentDashboard {
  id: AssessmentDashboardId
  name: string
  config: AssessmentStaticDashboardConfig
  isDefault: boolean
}

type LoadUserAssessmentDashboardsDonePayload = {
  dashboards: AssessmentDashboard[]
  selectedDashboardId?: AssessmentDashboardId | "default" | "last_selected" // true means select the first/default dashboard, used when we create a default one
}

export const LAST_SET_DASHBOARD_CONFIG_KEY = (userId: string) => `LAST_SET_DASHBOARD_CONFIG_${userId}`

function migrateDashboardToNewConfig(dashboard: AssessmentDashboard | StaticAssessmentDashboard): AssessmentDashboard {
  const config: AssessmentDynamicDashboardConfig | AssessmentStaticDashboardConfig = dashboard.config

  if ("widgets" in config) {
    // some new format dashboards with old widget configs

    let patchedWidgets: WidgetConfig[] = config.widgets.map((widget) => {
      if (!widget.config) {
        return widget
      }
      switch (widget.config.type) {
        case "ratings":
          const oldFormatRatings = (widget.config as any)["ratings"]
          if (oldFormatRatings && Array.isArray(oldFormatRatings)) {
            return {
              ...widget,
              config: {
                type: "ratings",
                selectionMode: oldFormatRatings[1],
                selectedRatings: oldFormatRatings[0],
                selectedMetaRatings: oldFormatRatings[2] ? oldFormatRatings[2] : [],
              },
            }
          }
          return widget

        case "POIs":
          const oldFormatPOIs = (widget.config as any)["POIs"]
          if (oldFormatPOIs && Array.isArray(oldFormatPOIs)) {
            return {
              ...widget,
              config: {
                type: "POIs",
                selectedPOICategories: oldFormatPOIs[0],
                selectionMode: oldFormatPOIs[1],
              },
            }
          }
          return widget
        default:
          return widget
      }
    })

    // migrate pre-fixed widget size default layouts. We reset existing default layout if the default widget size/position doesn't match our "new" default.

    if (config.version === undefined && dashboard.isDefault) {
      const defaultLayoutsPerType = new Map(
        defaultWidgetLayouts.map((layout) => {
          return [layout.t, layout]
        })
      )

      patchedWidgets = patchedWidgets.map((widget) => {
        const defaultWidgetDimensions = defaultLayoutsPerType.get(widget.config.type)
        return {
          ...widget,
          dimensions: defaultWidgetDimensions
            ? {
                w: defaultWidgetDimensions.w,
                h: defaultWidgetDimensions.h,
                x: defaultWidgetDimensions.x,
                y: defaultWidgetDimensions.y,
              }
            : widget.dimensions,
        }
      })
    }

    const resultingDashboard: AssessmentDashboard = {
      ...dashboard,
      config: {
        version: 1,
        widgets: patchedWidgets,
      },
    }

    return resultingDashboard
  } else {
    const updatedWidgets: WidgetConfig[] = []
    defaultWidgetLayouts.forEach((layout) => {
      const id = v4()
      const dimensions = layout
      let updatedConfig: WidgetConfigData

      switch (layout.t) {
        case "microMap":
          // default config
          updatedConfig = {
            type: "microMap",
            isochrone: defaultIsochroneConfig,
            privatePOICategories: defaultPrivatePoiCategories,
          }
          break
        case "microScores":
          // default config
          updatedConfig = { type: "microScores", microScores: [...defaultMicroScores] }
          break
        case "macroScores":
          // default config
          updatedConfig = { type: "macroScores", macroScores: [...defaultMacroScores] }
          break
        case "districtData":
          // default config
          updatedConfig = { type: "districtData", districtData: defaultDistrictWidgetData }
          break
        case "ratings":
          // default config
          if ("ratings" in config && config.ratings !== undefined) {
            updatedConfig = {
              type: "ratings",
              selectionMode: config.ratings[1],
              selectedRatings: config.ratings[0],
              selectedMetaRatings: config.ratings[2] ? config.ratings[2] : [],
            }
          } else {
            updatedConfig = {
              type: "ratings",
              selectionMode: "all",
              selectedRatings: [],
              selectedMetaRatings: [],
            }
          }

          break
        case "POIs":
          // pre-2023 config format

          if ("POIs" in config && config.POIs !== undefined) {
            updatedConfig = {
              type: "POIs",
              selectedPOICategories: config.POIs[0],
              selectionMode: config.POIs[1],
            }
          } else {
            updatedConfig = {
              type: "POIs",
              selectedPOICategories: defaultPOIsSettings.selectedPOICategories,
              selectionMode: defaultPOIsSettings.selectionMode,
            }
          }
          break

        default:
          updatedConfig = { type: layout.t }
          break
      }

      updatedWidgets.push({
        id,
        dimensions,
        config: updatedConfig,
      })
    })

    return {
      id: dashboard.id,
      name: dashboard.name,
      config: {
        version: 1,
        widgets: updatedWidgets,
      },
      isDefault: dashboard.isDefault,
    }
  }
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserScopesDone(state, action: PayloadAction<CookieScopeRequirement[]>) {
      state.scopes = getLanaScopes(action.payload)
    },
    loadUserAssessmentDashboardsDone(state, action: PayloadAction<LoadUserAssessmentDashboardsDonePayload>) {
      if (!action.payload) return

      state.lana.assessmentDashboardLoading = false

      state.lana.assessmentDashboards = action.payload.dashboards

      // Select the default or first dashboard
      if (action.payload.selectedDashboardId === "default") {
        const defaultDashboardIndex = action.payload.dashboards.findIndex((dashboard) => dashboard.isDefault)
        if (defaultDashboardIndex !== -1) {
          state.lana.selectedDashboardId = action.payload.dashboards[defaultDashboardIndex].id
        } else if (action.payload.dashboards.length > 0) {
          // just select the first one
          state.lana.selectedDashboardId = action.payload.dashboards[0].id
        }
      } else if (action.payload.selectedDashboardId === "last_selected" && state.user?.id) {
        const lastSelectedDashboardId = localStorage.getItem(LAST_SET_DASHBOARD_CONFIG_KEY(state.user.id))
        if (lastSelectedDashboardId) {
          const lastSelectedDashboard = action.payload.dashboards.find(
            (dashboard) => dashboard.id === lastSelectedDashboardId
          )
          if (lastSelectedDashboard) {
            state.lana.selectedDashboardId = lastSelectedDashboard.id
          }
        }
      } else {
        const selectedIndex = action.payload.selectedDashboardId
          ? action.payload.dashboards.findIndex((dashboard) => dashboard.id === action.payload.selectedDashboardId)
          : undefined
        if (selectedIndex && selectedIndex !== -1) {
          state.lana.selectedDashboardId = action.payload.selectedDashboardId
        }
      }
    },
    updateUserAssessmentDashboardsLoading(state, action: PayloadAction<boolean>) {
      state.lana.assessmentDashboardLoading = action.payload
    },
    selectUserDashboard(state, action: PayloadAction<AssessmentDashboardId | undefined>) {
      state.lana.selectedDashboardId = action.payload
    },
    setAssessmentDashboardError(state, action: PayloadAction<GenericError | undefined>) {
      state.lana.assessmentDashboardLoadingError = action.payload
    },
    setEditingUserDashboard(state, action: PayloadAction<DashboardEditMode>) {
      state.lana.editingUserDashboards = action.payload
    },
    updateUserDataDone(state, action: PayloadAction<User | undefined>) {
      state.user = action.payload
    },
    updateUserIsAuthorised(state, action: PayloadAction<boolean>) {
      state.isAuthorised = action.payload
    },
    updateDashboardWidgetConfigAction(
      state,
      action: PayloadAction<
        [AssessmentDashboardId, AssessmentDashboardWidgetId, WidgetConfigData, AssessmentDashboardVersion]
      >
    ) {
      if (!state.lana.assessmentDashboards) return

      const [dashboardId, widgetId, widgetConfig, version] = action.payload
      const dashboardIndex = state.lana.assessmentDashboards.findIndex((dashboard) => dashboard.id === dashboardId)
      if (dashboardIndex === -1) return

      const dashboard = state.lana.assessmentDashboards[dashboardIndex]

      // new widget config

      if ("widgets" in dashboard.config) {
        const widgetIndex = dashboard.config.widgets.findIndex((widget) => widget.id === widgetId)
        if (widgetIndex === -1) return

        state.lana.assessmentDashboards[dashboardIndex].config.widgets[widgetIndex].config = widgetConfig
        state.lana.assessmentDashboards[dashboardIndex].config.version = version
      } else {
        console.error("Old widget config found for: ", dashboard.config)
      }
    },

    updateDashboardWidgetLayoutAction(
      state,
      action: PayloadAction<
        [
          AssessmentDashboardId,
          { id: AssessmentDashboardWidgetId; dimensions: WidgetConfigDimensions }[],
          AssessmentDashboardVersion
        ]
      >
    ) {
      if (!state.lana.assessmentDashboards) return

      const [dashboardId, widgetLayouts, newVersion] = action.payload
      const dashboardIndex = state.lana.assessmentDashboards.findIndex((dashboard) => dashboard.id === dashboardId)
      if (dashboardIndex === -1) return

      const widgetIdsToKeep = new Set(widgetLayouts.map((w) => w.id))

      state.lana.assessmentDashboards[dashboardIndex].config.version = newVersion
      state.lana.assessmentDashboards[dashboardIndex].config.widgets = state.lana.assessmentDashboards[
        dashboardIndex
      ].config.widgets
        .filter((w) => widgetIdsToKeep.has(w.id))
        .map((w) => {
          const newLayout = widgetLayouts.find((l) => l.id === w.id)
          if (newLayout) {
            return {
              ...w,
              dimensions: newLayout.dimensions,
            }
          } else {
            return w
          }
        })
    },
    logout() {
      return getInitialUserState(false, false)
    },
  },
})

const {
  updateUserScopesDone,
  updateUserDataDone,
  loadUserAssessmentDashboardsDone,
  updateUserAssessmentDashboardsLoading,
  selectUserDashboard,
  setAssessmentDashboardError,
  updateDashboardWidgetConfigAction,
  updateDashboardWidgetLayoutAction,
} = userSlice.actions

export function updateAppScopes(newScopes: CookieScopeRequirement[]): void {
  store.dispatch(updateUserScopesDone(newScopes))
}

export function updateDashboardWidgetConfig(
  dashboardId: AssessmentDashboardId,
  widgetId: AssessmentDashboardWidgetId,
  widgetConfig: WidgetConfigData,
  version: AssessmentDashboardVersion
) {
  store.dispatch(updateDashboardWidgetConfigAction([dashboardId, widgetId, widgetConfig, version]))
}

export async function userLogout() {
  resetGlobalStore()
  store.dispatch(userSlice.actions.logout())
  updateUserIsAuthorised(false)
  hideMenu(true)
  await Axios.get(loginServiceUrl + "/logout?redirect=false")
  return true
}

export function updateUserAssessmentDashboardsLoadingStatus(loading: boolean): void {
  store.dispatch(updateUserAssessmentDashboardsLoading(loading))
}

export function updateUserData(user: User | undefined): void {
  store.dispatch(updateUserDataDone(user))
}

export function selectedUserAssessmentDashboard(dashboardId: AssessmentDashboardId | undefined): void {
  store.dispatch(selectUserDashboard(dashboardId))
}

export function setUserAssessmentDashboardError(error: GenericError | undefined): void {
  store.dispatch(setAssessmentDashboardError(error))
}

export async function loadAndHandleUser(): Promise<User | undefined> {
  return fetchAndHandleUser().then(
    (usr) => {
      updateUserData(usr)
      return usr
    },
    () => {
      console.error("Error fetching user data")
      return undefined
    }
  )
}

export function updateUserIsAuthorised(isAuthorised: boolean): void {
  store.dispatch(userSlice.actions.updateUserIsAuthorised(isAuthorised))
}

/**
 * Loads the user's assessment dashboards from the backend. Should be called when the module is load.
 * @param selectedDashboardId
 * @param preventRefresh
 */
export async function loadMyDashboards(
  selectedDashboardId: AssessmentDashboardId | undefined | "default" | "last_selected",
  preventRefresh?: boolean
): Promise<AssessmentDashboard[] | null> {
  !preventRefresh && updateUserAssessmentDashboardsLoadingStatus(true)

  try {
    const success = await Axios.get<(AssessmentDashboard | StaticAssessmentDashboard)[]>(
      `${lanaApiUrl}/api/v1/dashboards/my`
    )

    const migratedDashboards = success.data.map(migrateDashboardToNewConfig)

    store.dispatch(loadUserAssessmentDashboardsDone({ dashboards: migratedDashboards, selectedDashboardId }))

    return migratedDashboards
  } catch (e) {
    console.error("Error loading user dashboards", e)
  }

  !preventRefresh && updateUserAssessmentDashboardsLoadingStatus(false)
  return null
}

export function startDashboardResize(): void {
  store.dispatch(userSlice.actions.setEditingUserDashboard("resize"))
}

export function startDashboardConfigure(): void {
  store.dispatch(userSlice.actions.setEditingUserDashboard("configure"))
}

export function stopDashboardEdit(): void {
  store.dispatch(userSlice.actions.setEditingUserDashboard(false))
}

export async function createDefaultDashboard(
  dataJson: AssessmentDashboardUpdate
): Promise<AssessmentDashboard[] | null> {
  await Axios.post(`${lanaApiUrl}/api/v1/dashboards/default/my`, dataJson)
  return loadMyDashboards("default")
}

export function updateWidgetConfiguration(
  dashboard: AssessmentDashboard,
  widgetId: AssessmentDashboardWidgetId,
  widgetConfig: WidgetConfigData,
  address: Address | undefined
): void {
  if (widgetId && dashboard) {
    const updatedWidgets = [...dashboard.config.widgets]
    const widgetExistsAt = updatedWidgets.findIndex((w) => w.id === widgetId)
    if (widgetExistsAt > -1) {
      updatedWidgets[widgetExistsAt] = {
        ...updatedWidgets[widgetExistsAt],
        config: widgetConfig,
      }
    } else {
      console.error("Widget not found in dashboard config", widgetId)
      return
    }

    const newVersion = (dashboard.config.version || 0) + 1

    const dataJson: AssessmentDashboardUpdate = {
      name: dashboard.name,
      config: {
        version: newVersion,
        widgets: updatedWidgets,
      },
    }

    const updateResult = Axios.put(`${lanaApiUrl}/api/v1/dashboards/${dashboard.id}/my`, dataJson)

    // we immediately update the state to avoid waiting for the API call to finish
    updateDashboardWidgetConfig(dashboard.id, widgetId, widgetConfig, newVersion)

    void updateResult.then(() => {
      address && trackWidgetConfigChange(address, widgetConfig.type)
    })
  }
}

function trackWidgetConfigChange(address: Address, widget: WidgetsType): void {
  if (address) {
    let widgetNameForTracking: string
    switch (widget) {
      case "macroScores":
        widgetNameForTracking = "Macro Scores"
        break
      case "microScores":
        widgetNameForTracking = "Micro Scores"
        break
      case "ratings":
        widgetNameForTracking = "Ratings"
        break
      case "POIs":
        widgetNameForTracking = "POIs"
        break
      case "districtData":
        widgetNameForTracking = "District Data"
        break
      default:
        widgetNameForTracking = ""
        break
    }
    trackUsageEvent("DASHBOARD_CHANGE_WIDGET_CONFIG", address, widgetNameForTracking)
  }
}

export function updateDashboardWidgetLayout(
  dashboard: AssessmentDashboard,
  updatedLayout: { id: AssessmentDashboardWidgetId; dimensions: WidgetConfigDimensions; config: WidgetConfigData }[]
): Promise<void> {
  const widgetConfigDataById: Map<AssessmentDashboardWidgetId, WidgetConfigData> = new Map(
    dashboard.config.widgets.map((w) => [w.id, w.config])
  )

  const newWidgetLayout: WidgetConfig[] = updatedLayout.map((layout) => ({
    id: layout.id,
    dimensions: layout.dimensions,
    config: widgetConfigDataById.has(layout.id)
      ? (widgetConfigDataById.get(layout.id) as WidgetConfigData)
      : layout.config,
  }))

  const newVersion = (dashboard.config.version || 0) + 1
  const updatedDashboard: AssessmentDashboardUpdate = {
    name: dashboard.name,
    config: {
      version: newVersion,
      widgets: newWidgetLayout,
    },
  }

  store.dispatch(updateDashboardWidgetLayoutAction([dashboard.id, newWidgetLayout, newVersion]))

  return Axios.put(`${lanaApiUrl}/api/v1/dashboards/${dashboard.id}/my`, updatedDashboard).then(
    () => void loadMyDashboards(dashboard.id, true)
  )
}

export async function deleteDashboard(
  dashboardToDeleteId: AssessmentDashboardId
): Promise<AssessmentDashboardId | null> {
  try {
    await Axios.delete(`${lanaApiUrl}/api/v1/dashboards/${dashboardToDeleteId}/`)
    const response = await loadMyDashboards("default", true)

    return response ? response[0].id : null
  } catch (e) {
    setUserAssessmentDashboardError(e)
    return null
  }
}

export default userSlice.reducer
