import { isMetaRating, MetaRating, Rating } from "../../shared/models/ratings"
import { LoadMicroData, RatingSelectionRule } from "../../shared/models/selection"

export function microRatingFirstAgsRefLoc(rating: RatingDetail | MetaRating): string | undefined {
  if (isMetaRating(rating) || rating.dataSetType === "macro") {
    return undefined
  }
  const firstMicroDataRule = rating.rules.flatMap((r) => r.rules).find((r) => r.type === "loadmicrodata") as
    | LoadMicroData
    | undefined
  return firstMicroDataRule?.exampleAgsRefResLoc
}
export interface RatingDetail extends Rating {
  rules: RatingSelection[]
}

export interface RatingSelection {
  title: string
  gradeIdx: number
  rules: RatingSelectionRule[]
}
