import React, { useEffect, useMemo } from "react"
import { AssessmentEntryTrends } from "../../assessment/components/assessment-entry-trends"
import { useSelector } from "react-redux"
import { State } from "../../assessment/reducers/state"
import { translations } from "../../assessment/i18n"
import { loadAssessmentTrends } from "../../assessment/reducers/assessment-slice-functions"
import { getThemeColor } from "../../shared/helper/color"
import { PriceWidget } from "./price-widget"

export const LocationPriceTrendsV2 = () => {
  const t = useMemo(translations, [translations])
  const priceType = useSelector((state: State) => state.assessment.selectedPriceType)

  const currentAssessmentEntry = useSelector((state: State) => state.assessment.currentAssessmentEntry)

  useEffect(() => {
    if (currentAssessmentEntry !== null) void loadAssessmentTrends(currentAssessmentEntry)
  }, [currentAssessmentEntry?.address])

  return (
    <PriceWidget title={t.prices.pricesTrends.header} info={t.prices.pricesTrends.info}>
      <AssessmentEntryTrends
        priceType={priceType}
        noDataMessage={t.prices.pricesTrends.noData}
        pointWidth={0.7}
        colors={{
          primary: getThemeColor("primary", "default").toString(),
          secondary: getThemeColor("secondary1", "default").toString(),
        }}
      />
    </PriceWidget>
  )
}
