import * as React from "react"
import { Button, Icon } from "@blueprintjs/core"
import { css } from "emotion"
interface OwnProps {
  onClick: () => void
  disabled: boolean
}

const numericInputClass = css({
  position: "relative",
  margin: "0 0 0 -10px",
  //color: "#5a55eb",
})

export const NumericInputIcon: React.FunctionComponent<OwnProps> = (props) => {
  return (
    <Button
      disabled={props.disabled}
      className={numericInputClass}
      icon={<Icon icon="text-highlight" iconSize={20} />}
      minimal={true}
      onClick={props.onClick}
    />
  )
}
