import { App } from "./app-location-types"
import { AppDrawerMenuGroup, AppItem } from "../revo-application-menu"
import { CookieScopeRequirement } from "../models/scoperequirements"
import { NavigationPage } from "../../shared/actions/navigation"

export function filterAuthorizedMenuItems(
  cookieRequirements: CookieScopeRequirement[]
): (a: AppDrawerMenuGroup) => AppDrawerMenuGroup {
  return (a: AppDrawerMenuGroup) => {
    const newGroup: AppDrawerMenuGroup = {}

    Object.keys(a)
      .filter((key: App) => {
        const item = a[key] as AppItem

        return item.scopeRequirements(cookieRequirements)
      })
      .forEach((newKey: App) => {
        newGroup[newKey] = a[newKey]
      })

    return newGroup
  }
}

export const userApps: AppDrawerMenuGroup = {
  locationAnalytics: {
    service: ["lana", ""], // Default page will redirect further into one of active modules/locations
    scopeRequirements: (scopes) =>
      scopes.some((c) => c.service === "lana" && c.scopes.filter((s) => s != "CA").length > 0),
    icon: "/assets/apps/app_location_analytics.svg",
  },
  valuation: {
    service: ["valuation", ""],
    scopeRequirements: (scopes) => scopes.some((c) => c.service === "valuation" && c.scopes.includes("S")),
    icon: "/assets/apps/app_icons/app_valuation.svg",
  },
  admin: {
    service: { name: NavigationPage.companyAdminSettings },
    scopeRequirements: (scopes) => scopes.some((c) => c.scopes.includes("CA")),
    icon: "/assets/apps/app_icons/app_management.svg",
  },
}
