import * as React from "react"
import { PopoverContent } from "./PopoverContent"
import { Grid } from "./ui/grid"
import { translations } from "../i18n"
import { AppModules } from "../../menu/util/app-location-types"
import Button from "./button"

interface MapNavigateToModuleButtonProps {
  module: AppModules["locationAssessment"]
  parent?: HTMLElement
  navigateToModule: () => void
}

export const MapNavigateToModuleButton: React.FC<MapNavigateToModuleButtonProps> = (
  props: MapNavigateToModuleButtonProps
) => {
  const t = translations()

  return (
    <PopoverContent
      iconname={props.module === "poiExplorer" ? "marker" : "map"}
      title={props.module === "poiExplorer" ? t.poiExplorer.title : t.map.landvalues.specialMapsTitle}
      parent={props.parent}
    >
      <Grid columns={1} width={[520, "px"]} padding={16} gap={16}>
        <div>
          {props.module === "poiExplorer" ? t.poiExplorer.newPOIExplorerHint : t.map.landvalues.newSpecialMapsHint}
        </div>
        <div style={{ justifySelf: "center" }}>
          <Button type="primary" onClick={props.navigateToModule}>
            {props.module === "poiExplorer" ? t.poiExplorer.toNewPOIExplorer : t.map.landvalues.toNewSpecialMaps}
          </Button>
        </div>
      </Grid>
    </PopoverContent>
  )
}
