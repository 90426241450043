import PoiCategoriesList from "./poi-categories-list"
import React, { useEffect, useMemo, useState } from "react"
import { translations } from "../../i18n"
import { css } from "emotion"
import { Flex } from "../../../shared/components/ui/flex"
import Axios from "axios"
import { lanaApiUrl } from "../../../app_config"
import { fetchUserDefinedPOIGroups } from "../../reducers/poi-explorer-slice"
import { useAppSelector } from "../../../relas/store"
import Panel from "../../../shared/components/panel"
import Grid from "../../../shared/components/restyle-grid/grid"
import TextField from "../../../shared/components/textfield"
import Button from "../../../shared/components/button"
import { getThemeColor } from "../../../shared/helper/color"
import Form from "../../../shared/components/form"

const styles = {
  paneHeader: css({
    fontWeight: "bold",
    borderBottom: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
  }),
  poiCategoriesList: css({
    borderTop: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
  }),
}

interface Props {
  selectedPOICategories: Set<string>
  setSelectedPOICategories: (selectedCategories: Set<string>) => void
  selectedPOIGroupId: string
  setSelectedPOIGroup: (selectedPOIGroup: string) => void
  onClosePanel: () => void
}

const isArrayEqualsToSet = (a: Array<string>, b: Set<string>) => a.length === b.size && a.every((arrEl) => b.has(arrEl))

const POIGroupEdit = ({ selectedPOIGroupId, selectedPOICategories, setSelectedPOICategories, onClosePanel }: Props) => {
  const t = useMemo(translations, [translations])
  const userDefinedPOIGroups = useAppSelector((state) => state.POIExplorerApp.userDefinedPOIGroups)

  const initialPOIGroup = userDefinedPOIGroups?.find((group) => group.id === selectedPOIGroupId)

  const [newPOICategoryGroupName, setNewPOICategoryGroupName] = useState<string>(initialPOIGroup?.name || "")
  const [groupCreationError, setGroupCreationError] = useState(false)

  useEffect(() => {
    setGroupCreationError(false)
  }, [selectedPOICategories])

  const buttonDisabled =
    initialPOIGroup &&
    isArrayEqualsToSet(initialPOIGroup.categories, selectedPOICategories) &&
    !!userDefinedPOIGroups?.find(
      (poiGroup) =>
        poiGroup.name.toLowerCase() === newPOICategoryGroupName.toLowerCase().trim() &&
        poiGroup.id === selectedPOIGroupId
    )

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    event.stopPropagation()

    let editGroupError = false
    if (
      userDefinedPOIGroups &&
      userDefinedPOIGroups.find(
        (poiGroup) =>
          poiGroup.name.toLowerCase() === newPOICategoryGroupName.toLowerCase().trim() &&
          poiGroup.id !== selectedPOIGroupId
      )
    ) {
      editGroupError = true
    }

    if (
      userDefinedPOIGroups &&
      userDefinedPOIGroups.find(
        (poiGroup) =>
          poiGroup.name.toLowerCase() === newPOICategoryGroupName.toLowerCase().trim() &&
          poiGroup.id === selectedPOIGroupId
      ) &&
      initialPOIGroup &&
      isArrayEqualsToSet(initialPOIGroup.categories, selectedPOICategories)
    ) {
      editGroupError = true
    }

    if (editGroupError) setGroupCreationError(true)
    else {
      const dataJson = {
        id: selectedPOIGroupId,
        name: newPOICategoryGroupName.trim(),
        categories: [...selectedPOICategories],
      }

      Axios.put(`${lanaApiUrl}/api/v3/poi/categories_groups/${selectedPOIGroupId}`, dataJson).then(
        (success) => {
          fetchUserDefinedPOIGroups(success.data.id)
          onClosePanel()
        },
        () => {}
      )
    }
  }

  const onCancel = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    if (initialPOIGroup) setSelectedPOICategories(new Set(initialPOIGroup.categories))
    onClosePanel()
  }
  return (
    <div style={{ display: "grid", gridTemplateRows: "min-content 1fr", height: "100%", fontSize: "14px" }}>
      <div className={styles.paneHeader}>
        <div style={{ padding: "16px" }}>{t.poiExplorer.editGroup}</div>
      </div>
      <Form onSubmit={onSubmit}>
        <Grid columns={1} gap={4} rowSpec={"min-content 1fr min-content"} padding={[16, 0]} height={[100, "%"]}>
          <Flex flexDirection={"column"} gap={4} padding={[0, 16]}>
            <div style={{ fontWeight: "bold" }}>{t.poiExplorer.name}</div>
            <TextField
              javaScriptAutoFocus={true}
              value={newPOICategoryGroupName}
              onValueChange={(value) => {
                setNewPOICategoryGroupName(value)
                setGroupCreationError(false)
              }}
            />
            <div>{t.poiExplorer.newPOIGroupNameLabel}</div>
            {groupCreationError && <Panel color="negative">{t.groupAlreadyExists}</Panel>}
            <div style={{ paddingTop: "8px", fontWeight: "bold" }}>{t.poiExplorer.categories}</div>
          </Flex>
          <div className={styles.poiCategoriesList}>
            <PoiCategoriesList
              selectedPOICategories={selectedPOICategories}
              setSelectedPOICategories={setSelectedPOICategories}
              thematicGroupsCollapsed={true}
              textSearch={null}
              trackPOICategorySelection={true}
            />
          </div>
          <Flex flexDirection="row-reverse" gap={16} padding={16}>
            <Button
              disabled={groupCreationError || buttonDisabled || newPOICategoryGroupName.trim() === ""}
              formType="submit"
              type="primary"
            >
              {t.ok}
            </Button>
            <Button type="secondary" onClick={(e) => onCancel(e)}>
              {t.cancel}
            </Button>
          </Flex>
        </Grid>
      </Form>
    </div>
  )
}

export default POIGroupEdit
