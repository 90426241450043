import * as React from "react"
import { doUploadImage, doDeleteImage } from "../services/image-service"
import { translations } from "../i18n"
import Fileupload from "../../shared/components/fileupload"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import Card from "../../shared/components/card"
import LoadingDimmer from "../../shared/components/loadingdimmer"
import Grid from "../../shared/components/restyle-grid/grid"
import GridItem from "../../shared/components/restyle-grid/griditem"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"
import Button from "../../shared/components/button"
import { GenericError, toGenericError } from "../../shared/helper/axios"
import { isNullOrUndefined } from "../../shared/helper/utils"

interface AdditionalProps {
  imagePath: string
  hasImage: boolean
}

enum FinalUploadState {
  done,
  notStarted,
}

enum DeleteState {
  pristine,
  inProgress,
}

export const Image: React.FunctionComponent<AdditionalProps> = (props) => {
  const t = React.useMemo(translations, [translations])
  const [currentImageUrl, setCurrentImageUrl] = React.useState(props.imagePath)
  const [error, setError] = React.useState<GenericError | undefined>(undefined)
  const [progress, setProgress] = React.useState<number | FinalUploadState>(FinalUploadState.notStarted)
  const [deleteState, setDeleteState] = React.useState<DeleteState>(DeleteState.pristine)
  const [hasImage, setHasImage] = React.useState<boolean>(props.hasImage)

  const cacheBustImage = (url: string): string => {
    return url + "#" + new Date().getTime()
  }

  const handleUploadProgress = (currentProgress: number) => {
    setProgress(currentProgress)
  }

  const handleUpload = (file: File): Promise<void> => {
    return doUploadImage(file, props.imagePath, handleUploadProgress).then(
      () => {
        setProgress(FinalUploadState.done)
        setError(undefined)
        setCurrentImageUrl(cacheBustImage(props.imagePath))
        setHasImage(true)
      },
      (error) => {
        setProgress(FinalUploadState.notStarted)
        setError(toGenericError(error))
      }
    ) as Promise<void>
  }

  const currentProgress = (): number | undefined => {
    return progress !== FinalUploadState.done && progress !== FinalUploadState.notStarted ? progress : undefined
  }

  const canUpload = () => {
    return progress == FinalUploadState.notStarted || progress === FinalUploadState.done
  }

  const deleteImage = () => {
    setDeleteState(DeleteState.inProgress)
    doDeleteImage(props.imagePath).then(
      (ok) => {
        setDeleteState(DeleteState.pristine)
        setCurrentImageUrl(cacheBustImage(props.imagePath))
        setHasImage(false)
      },
      (err: any) => {
        const error: GenericError = toGenericError(err)
        setError(error)
        setDeleteState(DeleteState.pristine)
      }
    )
  }

  return (
    <Card header={t.settings.profileImageHeader}>
      <Grid columns={1} height={[100, "%"]}>
        <LoadingDimmer loading={!isNullOrUndefined(currentProgress())}>
          <Grid columns={1} height={[100, "%"]}>
            <GridItem alignSelf="center" justifySelf="center">
              {hasImage ? <img src={currentImageUrl} className="image" /> : null}
            </GridItem>

            {error && <GenericErrorPanel hint={t.genericError.imageErrored} error={error} />}

            <GridItem alignSelf="end">
              <FlexContainer spaceBetween="md">
                <Fileupload type="primary" icon="insert_drive_file" disabled={!canUpload()} onUpload={handleUpload}>
                  {t.settings.image.upload}
                </Fileupload>
                {hasImage && (
                  <Button
                    disabled={deleteState === DeleteState.inProgress}
                    onClick={deleteImage}
                    type="secondary"
                    icon="delete"
                  />
                )}
              </FlexContainer>
            </GridItem>
          </Grid>
        </LoadingDimmer>
      </Grid>
    </Card>
  )
}
