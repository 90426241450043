import * as React from "react"
import { POIMarkerType } from "./poi-explorer-map"
import { iconsForCategories } from "./poi-utils"
import { cx, css } from "emotion"
import { reportAssetsUrl } from "../../../reports/report-config"
import { NumberedTextSearchIds } from "../../../shared/models/poi-explorer"

const styles = {
  poiMarker: css({
    position: "absolute",
    transform: "translate(-50%, -100%)",
    img: { width: "25px", height: "37px" },
    cursor: "pointer",
  }),
}

type PropsWithoutTextSearch = POIMarkerType & { onClick: (data: POIMarkerType) => void; inside: boolean }

const getIcon = (categories: string[] | undefined): string => {
  if (!categories || categories.length === 0) return "000"

  const icon = categories.find((category) => iconsForCategories[category])
  return icon ? iconsForCategories[icon] : "000"
}

const POIMarkerImpl = (
  props: POIMarkerType & {
    onClick: (data: POIMarkerType) => void
    inside: boolean
    textSearchId: NumberedTextSearchIds | undefined
  }
) => {
  const iconId = props.textSearchId ? iconsForCategories[props.textSearchId] : getIcon(props.poiData.types)

  return (
    <div
      className={!props.inside ? cx(styles.poiMarker, css({ opacity: "50%" })) : styles.poiMarker}
      onClick={() => props.onClick(props)}
    >
      <img src={`${reportAssetsUrl ?? ""}/assets/poi-explorer/${iconId}.svg`} alt="POI" />
    </div>
  )
}

export const TextSearchPOIMarker = (props: PropsWithoutTextSearch & { textSearchId: NumberedTextSearchIds }) => (
  <POIMarkerImpl {...props} textSearchId={props.textSearchId} />
)

export const POIMarker = (props: PropsWithoutTextSearch) => <POIMarkerImpl {...props} textSearchId={undefined} />
