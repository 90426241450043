import React, { useEffect, useMemo, useState } from "react"
import AppHeader from "../../../shared/components/app-header"
import { css } from "emotion"
import { PrivatePoisMap } from "./private-pois-map"
import { DeleteModes, PrivateDataTabType, PrivatePOI, PrivatePOICategory } from "../../models/private-data"
import { translations } from "../../i18n"
import PoiCategoriesMenu from "./poi-categories-menu"
import Axios from "axios"
import { lanaApiUrl } from "../../../app_config"
import { useAppSelector } from "../../../relas/store"
import { PrivatePoisTable, SelectedRow } from "./private-pois-table"
import { UploadDialog } from "./upload-dialog"
import { DeleteDialog } from "./delete-dialog"
import CreateEditSinglePOIDialog, { PrivatePOICreateOrEditModes } from "./private-poi-add-edit-dialog"
import { DocumentationAndSupport } from "../../../shared/components/documentation-and-support"
import {
  updateSelectedCategory,
  fetchCategories,
  fetchCategoryPois,
  uploadPrivateDataPOIs,
  cancelUploadAddressList,
  openDelete,
  closeDelete,
  deletePrivateData,
  updatePOIsToShow,
} from "../../reducers/private-data-slice"
import { AlertBox } from "../../../shared/components/alertbox"
import ButtonTabs from "../../../shared/components/buttontabs"
import HorizontalDivider from "../../../shared/components/horizontaldivider"
import Text from "../../../shared/components/text"
import Grid from "../../../shared/components/restyle-grid/grid"
import Button from "../../../shared/components/button"
import { getThemeColorVar } from "../../../shared/helper/color"

const styles = {
  mainTabGrid: css({
    display: "grid",
    columns: 1,
    gridTemplateRows: "62px 1fr",
    height: "100%",
  }),
  viewSettingsButtonsClass: css({
    backgroundColor: "white",
    width: "300px",
  }),
}

const PrivateData = () => {
  const t = useMemo(translations, [translations])

  const userScopes = useAppSelector((state) => state.user.scopes)
  const privateCategories = useAppSelector((state) => state.privateData.privatePOICategories)
  const selectedCategory = useAppSelector((state) => state.privateData.selectedCategory)
  const uploadError = useAppSelector((state) => state.privateData.privatePOIAddressListUploadError)
  const poiUploadLoading = useAppSelector((state) => state.privateData.privatePOIAddressListUploadLoading)
  const privatePOIListLoading = useAppSelector((state) => state.privateData.privatePOIListLoading)
  const poiList = useAppSelector((state) => state.privateData.privatePOIList)
  const deleteMode = useAppSelector((state) => state.privateData.deleteMode)
  const deleteSelection = useAppSelector((state) => state.privateData.deleteSelection)
  const poisDeleted = useAppSelector((state) => state.privateData.poisDeleted)
  const poisToShow = useAppSelector((state) => state.privateData.poisToShow)
  const userId = useAppSelector((state) => state.user.user?.id)

  const [mainTab, setMainTab] = useState<PrivateDataTabType>("data")
  const [categoryToLock, setCategoryToLock] = useState<PrivatePOICategory>()
  const [checkedPOIRows, setCheckedPOIRows] = useState<Set<string>>(new Set())
  const [selectedRow, setSelectedRow] = useState<SelectedRow[]>()
  const [selectedPoi, setSelectedPoi] = useState<PrivatePOI | undefined>(undefined)
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false)
  const [editCreatePOIDialogMode, setEditCreatePOIDialogMode] = useState<PrivatePOICreateOrEditModes>("create")

  const [showPoiCreateEditDialog, setShowPoiCreateEditDialog] = useState(false)

  useEffect(() => {
    void fetchCategories()
  }, [])

  useEffect(() => {
    if (selectedCategory) {
      const updatedCategory = privateCategories.find((category) => category.id === selectedCategory.id)
      updateSelectedCategory(updatedCategory)
    }
  }, [privateCategories])

  useEffect(() => {
    if (selectedCategory) {
      resetSelectedAndCheckedRows()
      void fetchCategoryPois(selectedCategory.id)
    }
  }, [selectedCategory])

  useEffect(() => {
    if (poisDeleted && selectedCategory) {
      void fetchCategoryPois(selectedCategory.id)
      resetSelectedAndCheckedRows()
    }
  }, [poisDeleted])

  const resetSelectedAndCheckedRows = () => {
    setCheckedPOIRows(new Set())
    setSelectedRow(undefined)
    setSelectedPoi(undefined)
  }

  useEffect(() => {
    if (poiList.length === 0 && (selectedRow || (checkedPOIRows.size >= 1 && selectedPoi))) {
      resetSelectedAndCheckedRows()
    }
  }, [poiList])

  useEffect(() => {
    if (poiList.length > 0) {
      let foundPoi: undefined | PrivatePOI
      if (selectedRow) foundPoi = poiList.find((poi) => poi.id === selectedRow[0].poiId)
      else {
        if (checkedPOIRows.size === 1) {
          foundPoi = poiList.find((poi) => poi.id === [...checkedPOIRows][0])
        } else foundPoi = undefined
      }
      setSelectedPoi(foundPoi)
    }
  }, [selectedRow, checkedPOIRows])

  async function updateCategoryLocked(category: PrivatePOICategory): Promise<void> {
    await Axios.put(`${lanaApiUrl}/api/v1/user_pois/categories/${category.id}`, {
      title: category.title,
      icon: category.icon,
      notes: category.notes,
      lockedBy: category.lockedBy ? undefined : userId,
    })
    void fetchCategories()
  }

  function renderLockConfirm(category: PrivatePOICategory) {
    const isLocked = category.lockedBy !== undefined && category.lockedBy !== null

    return (
      <AlertBox
        header={isLocked ? t.privateData.unlockCategory : t.privateData.lockCategory}
        onClose={() => setCategoryToLock(undefined)}
        actions={[
          {
            label: isLocked ? t.privateData.unlock : t.privateData.lock,
            icon: isLocked ? "unlocked" : "locked",
            action: () => {
              void updateCategoryLocked(category)
              setCategoryToLock(undefined)
            },
          },
          { label: t.cancel, action: () => setCategoryToLock(undefined) },
        ]}
      >
        <Text>{isLocked ? t.privateData.unlockMessage : t.privateData.lockMessage}</Text>
      </AlertBox>
    )
  }

  function onCancelUpload() {
    cancelUploadAddressList()
    setShowUploadDialog(false)
  }

  const getArrayToDelete = (): Array<string> => {
    const copySet = new Set(checkedPOIRows)
    if (selectedRow) {
      copySet.add(selectedRow[0].poiId)
    }
    return Array.from(copySet)
  }

  const closeEditCreateDialog = () => setShowPoiCreateEditDialog(false)

  const openEditCreateDialog = (mode: PrivatePOICreateOrEditModes) => {
    setEditCreatePOIDialogMode(mode)
    setShowPoiCreateEditDialog(true)
  }

  const categoryNotSelectedOrIsLocked = () => !selectedCategory ?? !!selectedCategory?.lockedBy

  return (
    <>
      {categoryToLock && renderLockConfirm(categoryToLock)}
      <Grid
        columns={1}
        height={undefined}
        rowSpec="fit-content(100%) min-content 1fr"
        className={css({ height: "calc(100vh - 50px)" })}
      >
        <AppHeader menuSection={"privateData"} assessment={null} assessmentEntry={null} module={null}>
          <DocumentationAndSupport
            tooltip={t.helpAndSupport}
            documentationURL="https://docs.google.com/document/d/1LDviPxMe5NGCdxHOYFeNp4kH2z9Km2nqqeUVZT2eOfs"
            callLocation="Private Data"
            addDocumentationLink
            onClick={() => {}}
          />
        </AppHeader>
        <HorizontalDivider height={4} borderless={true} />
        {showUploadDialog && selectedCategory && (
          <UploadDialog
            categoryId={selectedCategory.id}
            isLoading={poiUploadLoading}
            uploadError={uploadError}
            uploadData={uploadPrivateDataPOIs}
            onCancel={onCancelUpload}
          />
        )}
        {deleteMode && deleteSelection && (
          <DeleteDialog
            onCancel={closeDelete}
            onDelete={deletePrivateData}
            mode={deleteMode}
            selection={deleteSelection}
          />
        )}
        <Grid height={[100, "%"]} columns={2} columnSpec={"300px 1fr"}>
          {userId && (
            <PoiCategoriesMenu
              setCategoryToLock={setCategoryToLock}
              userId={userId}
              isAdmin={userScopes.companyAdmin}
            />
          )}
          <div className={styles.mainTabGrid}>
            <div
              style={{
                padding: "16px 32px",
                backgroundColor: getThemeColorVar("background", "lighter"),
                borderBottom: "1px solid",
                borderColor: getThemeColorVar("border", "default"),
                display: "flex",
                gap: "76px",
              }}
            >
              <div className={styles.viewSettingsButtonsClass}>
                <ButtonTabs
                  values={["data", "map"]}
                  selected={mainTab}
                  onSelect={(page) => setMainTab(page)}
                  translate={t.privateData.mainTabs}
                />
              </div>
              {mainTab === "data" && (
                <div
                  style={{
                    display: "grid",
                    columns: "4",
                    gridTemplateColumns: "repeat(4, 100px)",
                    gap: "8px",
                    gridTemplateRows: "30px",
                  }}
                >
                  <Button
                    type={"primary"}
                    size={"small"}
                    onClick={() => setShowUploadDialog(true)}
                    disabled={categoryNotSelectedOrIsLocked()}
                  >
                    {t.privateData.upload}
                  </Button>
                  <Button
                    type={"primary"}
                    size={"small"}
                    onClick={() => openEditCreateDialog("create")}
                    disabled={categoryNotSelectedOrIsLocked()}
                  >
                    {t.privateData.add}
                  </Button>
                  <Button
                    type={"primary"}
                    size={"small"}
                    onClick={() => openEditCreateDialog("edit")}
                    disabled={categoryNotSelectedOrIsLocked() || !selectedPoi}
                  >
                    {t.privateData.edit}
                  </Button>
                  <Button
                    type={"primary"}
                    size={"small"}
                    onClick={() => openDelete(DeleteModes.POI, getArrayToDelete())}
                    disabled={categoryNotSelectedOrIsLocked() || (!selectedRow && checkedPOIRows.size === 0)}
                  >
                    {t.privateData.delete}
                  </Button>
                </div>
              )}
            </div>
            {showPoiCreateEditDialog && selectedCategory && (
              <CreateEditSinglePOIDialog
                poiToEdit={selectedPoi}
                selectedCategory={selectedCategory}
                onClose={closeEditCreateDialog}
                mode={editCreatePOIDialogMode}
              />
            )}
            {mainTab === "data" && (
              <PrivatePoisTable
                poiList={poiList}
                poiListLoading={privatePOIListLoading}
                checkedPOIRows={checkedPOIRows}
                setCheckedPOIRows={setCheckedPOIRows}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                selectedCategory={selectedCategory}
              />
            )}
            {mainTab === "map" && (
              <PrivatePoisMap
                showControls={true}
                poiList={poiList}
                selectedCategory={selectedCategory}
                poisToDisplay={poisToShow}
                updatePoiToDisplay={updatePOIsToShow}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default PrivateData
