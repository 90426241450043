import { Translations } from "./translations"
import { SecurityPage } from "../components/userAccountSecurity"

export const EN: Translations = {
  security: {
    header: "Security settings",
    localNav: (page: SecurityPage) => {
      switch (page) {
        case "2fa":
          return "2 factor authentication"
        case "password":
          return "Password"
      }
    },
  },
  settings: {
    header: "Profile settings",
    changePassword: {
      doIt: "Change password",
      currentMissingError: "Please enter your current password.",
      notMatchingError: "Passwords do not match or are too short (must be at least 9 characters).",
      tooShortError: "The new password must be at least 9 characters long.",
      wrongCurrentPasswordError: "The old password is invalid.",
      noNewError: "Please enter your new password.",
      title: "Change password",
      currentLabel: "Current password",
      currentHint: "Your current password",
      newLabel: "New password",
      newHint: "Please enter your new password",
      confirmLabel: "New password",
      confirmHint: "Please confirm your new password",
      cancel: "Cancel",
      success: "Password change successful",
      doneTitle: "Your password was updated",
      doneBody: "Please use your new password during the next login.",
      ok: "Ok",
      fakePasswordLabel: "Password",
    },
    settingsHeader: "Settings",
    profileImageHeader: "Profile image",
    companyImageHeader: "Company logo",
    personalDataHeader: "Personal data",
    personalDataName: "Name",
    personalDataSurname: "Surname",
    personalDataEmail: "Email",
    personalDataPhone: "Phone",
    personalDataPassword: "Password",
    personalDataCompanyName: "Company",
    language: {
      de: "German",
      en: "English",
      chooseLanguage: "Choose your language",
    },
    image: {
      upload: "Upload",
    },
    product: "Product",
  },
  genericError: {
    profileErrored: "We're having trouble loading your profile right now.",
    imageErrored: "Your action could not be performed due to a technical issue.",
    languageChangeErrored: "Couldn't save your language settings.",
    passwordChangeErrored: "Couldn't change your password.",
    noProduct:
      "Currently, there are no products booked for you. If you want to continue working with our platform, please talk to your admin or get in contact with our sales team (sales@21re.de).",
  },
  twoFactorSetup: {
    setupHeader: "Setup",
    explanationHeader: "Instructions",
    generatedCodeLabel: "Please enter the generated code.",
    submitLabel: "Complete setup",
    submittedOkText: "Your two factor authentication is now active.",
    submittedErrorText:
      "There was an error setting up your second factor authentication. The generated code is likely invalid.",
    secretLabel: "The secret key is",
    qrRenderError:
      "An error occurred trying rendering your QR code. Please try using another browser or upgrade the one you are using. Alternatively, you could try setting up your virtual MFA device by using the secret key below.",
    explanation: `Install one of the virtual MFA apps listed below, then scan the QR code using that app. The app will now be able to generate a code that changes every 30 seconds. When you are prompted for a code on 21st-Market, start the app and type in the code shown. To familiarize you with this process we request that you enter one such code below before finishing the setup of your MFA device.`,
    teaser:
      "Traditionally you log in using just a password, but if someone cracks or steals it, your account is compromised. With two factor authentication (2FA) you have to provide a single use code when you log in. A device that you own (typically a smartphone) generates the code, so an intruder would have to steal both your password and your device, increasing both your and our security.",
    infographic: "/assets/mfa_en.png",
    otpLengthMismatch: "The code consists of 6 digits.",

    infographicHeader: "How it works",

    companyAdminHeader: "Please set up two-factor authentication",
    companyAdminExplanation:
      "You are flagged as a company administrator in our system. This allows you to alter important company-wide settings, such as calculation defaults, and confers you the ability to administer other company members as well. As a consequence, we recommend going the extra mile to protect your account from hackers who might be able to guess your password.",

    nukeTwoFactorHeader: "Your account is protected by the two-factor authentication.",
    nukeTwoFactorError: "Something went wrong deactivating the two-factor authentication.",
    nukeTwoFactor: "Deactivate",
  },
}
