import React, { CSSProperties, useState } from "react"
import { WmsLayer } from "../../../shared/models/wms-layer"
import { mapProxyUrl } from "../../../app_config"
import { DragSourceMonitor, useDrag } from "react-dnd"
import { css } from "emotion"
import { translations } from "../../i18n"
import { DraggableBoxesType, ItemTypes } from "./special-maps"
import { Grid } from "../../../shared/components/ui/grid"
import { getThemeColor } from "../../../shared/helper/color"
import Icon from "../../../shared/components/icon"

const styles = {
  legendHeader: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
    borderRadius: "4px 4px 0 0",
    cursor: "pointer",
  }),
  legendBody: css({
    maxHeight: "500px",
    overflowY: "auto",
    padding: "12px 8px",
    borderBottom: "1px solid",
    borderLeft: "1px solid",
    borderRight: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
    borderRadius: "0 0 4px 4px",
  }),
  legendBodyCollapsed: css({
    visibility: "hidden",
    height: "0px",
    borderLeft: "1px solid",
    borderRight: "1px solid",
    maxHeight: "500px",
    overflowY: "auto",
  }),
}

interface Props {
  layer: WmsLayer
  id: DraggableBoxesType
  left: number
  top: number
}

const getStyles = (left: number, top: number): CSSProperties => {
  const transform = `translate3d(${left}px, ${top}px, 0)`
  return {
    position: "absolute",
    transform,
    WebkitTransform: transform,
    zIndex: 1001,
    backgroundColor: "white",
    borderRadius: "4px",
  }
}

export const MapLegend = (props: Props) => {
  const t = React.useMemo(translations, [translations])

  const [isCollapsed, setIsCollapsed] = useState(false)
  const { id, left, top, layer } = props
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.BOX,
      item: { id, left, top },
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top]
  )

  if (isDragging) {
    return <div ref={drag} />
  }

  const isOverflown = (element: any): boolean => {
    if (!element) return false
    return element.scrollHeight > element.clientHeight
  }
  const legendImg = document.getElementById("legendBody")
  const legendImgStyle = isCollapsed ? (isOverflown(legendImg) ? { margin: "8px" } : { marginLeft: "1px" }) : {}

  const renderLegendBody = () => {
    if (layer.legend) {
      return (
        <>
          {layer.legend.map((group, idx) => (
            <Grid columnSpec="20px 1fr 20px 1fr" gap={8} alignItems="center" padding={[0, 0, 8, 0]}>
              {group.map((legend, idx) => (
                <React.Fragment key={idx}>
                  <img src={`${mapProxyUrl}${legend.icon}`} />
                  <div>{t.pickTranslation(legend.label)}</div>
                </React.Fragment>
              ))}
            </Grid>
          ))}
        </>
      )
    }
    if (layer.legend_simple || layer.legend) {
      return <img style={legendImgStyle} src={`${mapProxyUrl}${layer.legend_simple}`} />
    }
    return <div style={isCollapsed ? { margin: "0 8px 0 9px" } : { margin: "8px" }}>{t.specialMaps.noLegend}</div>
  }

  return (
    <div ref={drag} style={getStyles(left, top)}>
      <div className={styles.legendHeader}>
        <div style={{ fontWeight: "bold", padding: "12px" }}>{t.specialMaps.legendHeader}</div>
        <div onClick={() => setIsCollapsed(!isCollapsed)}>
          <a style={{ padding: "12px" }}>
            <Icon fontSize={24} name={isCollapsed ? "arrow_drop_down" : "arrow_drop_up"} />
          </a>
        </div>
      </div>
      <div>
        <div id="legendBody" className={isCollapsed ? styles.legendBodyCollapsed : styles.legendBody}>
          {renderLegendBody()}
        </div>
      </div>
    </div>
  )
}
