import React from "react"
import { QuickstartHelp } from "./quickstart-help"
import { DashboardHelp } from "./dashboard-help"
import { RatingsHelp } from "./ratings-help"
import { ComparablesHelp } from "./comparables-help"

//export type HelpSection = "quickstart" | "assessment" | "poi" | "private-data"

type Props = {
  section: string
}
export const Help = (props: Props) => {
  switch (props.section) {
    case "quickstart":
      return <QuickstartHelp />
    case "dashboard":
      return <DashboardHelp />
    case "ratings":
      return <RatingsHelp />
    case "comparables":
      return <ComparablesHelp />
    default:
      return null
  }
}
