export function isNullOrUndefined(value: any): value is null | undefined {
  return value === null || value === undefined
}

export function isString(value: any): value is string {
  return typeof value === "string"
}

export function isNumber(value: any): value is number {
  return typeof value === "number"
}
