import { Options } from "ol/source/VectorTile"
import { lanaApiUrl } from "../app_config"
import * as format from "ol/format"

/** We are serving pbf files from the lib/packed-assets.jar from the lana-api microservices.
 * The data is generated using: https://github.com/21re/geo-hierarchy-de/blob/master/tilebuilder/build-tiles.sh */
export function refRegionVectorTileOptions(apiUrl?: string, token?: string): Options {
  return {
    maxZoom: 9,
    minZoom: 5,
    url:
      `${apiUrl || lanaApiUrl}/api/map/region/{z}/{x}/{y}.pbf` + (token ? `?token=${encodeURIComponent(token)}` : ""),
    format: new format.MVT({
      idProperty: "ags_ref_resloc",
    }),
  }
}

export function cellVectorTileOptions(apiUrl?: string, token?: string): Options {
  return {
    maxZoom: 12,
    minZoom: 5,
    url: `${apiUrl || lanaApiUrl}/api/map/cells/{z}/{x}/{y}.pbf` + (token ? `?token=${encodeURIComponent(token)}` : ""),
    format: new format.MVT({
      idProperty: "cid",
    }),
  }
}

export function municipalityVectorTileOptions(apiUrl?: string, token?: string): Options {
  return {
    maxZoom: 9,
    minZoom: 5,
    url: `${apiUrl || lanaApiUrl}/api/map/gem/{z}/{x}/{y}.pbf` + (token ? `?token=${encodeURIComponent(token)}` : ""),
    format: new format.MVT({
      idProperty: "AGS_num",
    }),
  }
}

export function germanStatesVectorTileOptions(apiUrl?: string, token?: string): Options {
  return {
    maxZoom: 9,
    minZoom: 5,
    url:
      `${apiUrl || lanaApiUrl}/api/map/states/{z}/{x}/{y}.pbf` + (token ? `?token=${encodeURIComponent(token)}` : ""),
    format: new format.MVT({
      idProperty: "AGS_num",
    }),
    tileSize: 128,
  }
}

export function districtDataVectorTileOptions(apiUrl?: string, token?: string): Options {
  return {
    maxZoom: 12,
    minZoom: 9,
    url:
      `${apiUrl || lanaApiUrl}/api/map/districts/{z}/{x}/{y}.pbf` +
      (token ? `?token=${encodeURIComponent(token)}` : ""),
    format: new format.MVT({
      idProperty: "KGS22",
    }),
  }
}

export function postcodeVectorTileOptions(apiUrl?: string, token?: string): Options {
  return {
    maxZoom: 12,
    minZoom: 5,
    url: `${apiUrl || lanaApiUrl}/api/map/plz/{z}/{x}/{y}.pbf` + (token ? `?token=${encodeURIComponent(token)}` : ""),
    format: new format.MVT({
      idProperty: "PLZ",
    }),
  }
}
