import * as React from "react"
import { useEffect, useMemo } from "react"
import {
  isRuleCategoryWithField,
  RatingSelectionRule,
  RuleCategories,
  RuleCategory,
} from "../../../shared/models/selection"
import { categorize, CategorizedScores, DataSetType, LanaNodeWithVariation } from "../../../shared/models/smartdata"
import { translations } from "../../i18n"
import { BorderBottom } from "../../../shared/components/ui/border-bottom"
import { Flex } from "../../../shared/components/ui/flex"
import { FlexItem } from "../../../shared/components/ui/flex-item"
import { Grid } from "../../../shared/components/ui/grid"
import { GridItem } from "../../../shared/components/ui/grid-item"
import { ScrollBox } from "../../../shared/components/ui/scroll-box"
import { HelpPopover } from "../../../shared/components/help-popover"
import { css, cx } from "emotion"
import { FieldOptionMultiSelect } from "../../../shared/components/ui/field-option-multi-select"
import { FieldOption, getFieldOptions } from "../../../shared/models/field-option"
import { Group, Profile } from "../../../profile/models/profile"
import { Tooltip } from "../../../shared/components/ui/tooltip"
import { Collapse, MenuItem } from "@blueprintjs/core"
import { Button as ButtonBP } from "@blueprintjs/core/lib/esm/components/button/buttons"
import { useAppSelector } from "../../../relas/store"
import { FieldPath, useForm, useFormState } from "react-hook-form"
import { IItemRendererProps, Suggest } from "@blueprintjs/select"
import {
  LANA_AGS_NODES,
  LANA_CELL_NODES,
  LANA_DISTRICT_PROPS,
  LanaNode,
} from "../../../shared/smartdata-products/smartdata"
import { RatingManagerDistrictdataList } from "./rating-manager-districtdata-list"
import { RadioButton } from "../../../shared/components/ui/radio-button"
import { NumberInput } from "../../../shared/components/ui/number-input"
import { integerValidator, maxValidator, minValidator } from "../../../utils/utils"
import GenericErrorPanel from "../../../shared/components/genericerrorpanel"
import Checkbox from "../../../shared/components/checkbox"
import Select from "../../../shared/components/select"
import LoadingSpinner from "../../../shared/components/loadingspinner"
import Button from "../../../shared/components/button"
import Expandable from "../../../shared/components/expandable"
import Icon from "../../../shared/components/icon"
import { getThemeColorVar } from "../../../shared/helper/color"

type Props = {
  rule?: RatingSelectionRule
  dataSetType: DataSetType
  onClose: () => void
  onEdited: (rule: RatingSelectionRule) => Promise<void>
  availableProfiles: Profile[]
  profileGroups: Group[]
  availableProfileInProgress: boolean
}

const styles = {
  scoreGroupsClass: css({
    "> div": {
      borderLeft: "none",
      borderRight: "none",
      borderTop: "none",
    },
    "> div:first-child": {
      borderLeft: "none",
      borderRight: "none",
      borderTop: `1px solid ${getThemeColorVar("border", "default")}`,
    },
  }),
  scoreInGroupClass: css({
    "> div": {
      borderLeft: "none",
      borderRight: "none",
      borderTop: `1px solid ${getThemeColorVar("border", "default")}`,
    },
    "> div:first-child": {
      borderTop: "none",
    },
  }),
  profileClass: css({
    borderBottom: `1px solid ${getThemeColorVar("border", "default")}`,
    padding: "8px 16px ",
  }),
  arrowClass: css({
    transition: "transform 0.4s",
  }),
  arrowExpandedClass: css({
    transform: "rotateZ(180deg)",
  }),
  buttonScoreVariationClass: css({
    ":focus": { outline: "none" },
  }),
  groupHeader: (isActive: boolean, isGroupEmpty?: boolean) =>
    css({
      borderBottom: `1px solid ${getThemeColorVar("border", "default")}`,
      padding: "8px 16px ",
      display: "flex",
      justifyContent: "space-between",
      cursor: isGroupEmpty ? "default" : "pointer",
      backgroundColor: isActive && !isGroupEmpty ? getThemeColorVar("background", "lighter") : undefined,
      height: "42px",
      alignItems: "center",
    }),
  groupName: css({
    fontSize: "14px",
    fontWeight: "bold",
  }),
  chevronWrap: css({
    cursor: "pointer",
  }),
}

type FormState = {
  exclusive: boolean
  ruleCategory: RuleCategory
  rangeFrom?: number
  rangeTo?: number
  selectedScore: LanaNodeWithVariation | undefined
  oneOfSelections: string[]
  oneOfOptions: FieldOption[]
  rankRelative: boolean
  rankFromTop: boolean
  rankValue?: number
  exampleAgsRefResLoc: string
  agsRefResLocOptions: FieldOption[]
  selectedProfileId: string | undefined
  profileTo: number
  profileFrom: number
  rankRescale: boolean
  collapsedGroup: string[]
  isInitCollapsedGroupSeated: boolean
  showScoresWithVariations: Set<string>
  districtData: {
    from?: number
    to?: number
    exclusive: boolean
    score?: string
  }
}

type FormStateKeys = Array<keyof FormState>

export const RatingManagerSelectionRuleEdit: React.FC<Props> = (props) => {
  const t = useMemo(translations, [translations])

  const { updateError, updateInProgress } = useAppSelector((state) => ({
    updateError: state.ratingManager.updateError,
    updateInProgress: state.ratingManager.updateInProgress,
  }))

  const ruleCategories = useMemo(() => RuleCategories(props.dataSetType), [props.dataSetType])

  const { watch, setValue, control, clearErrors, setError, handleSubmit } = useForm<FormState>({
    defaultValues: formDefaults(),
  })

  const { errors } = useFormState({ control })

  const isValid = Object.keys(errors).length === 0

  const onValueChangeUseForm = (field: FieldPath<FormState>) => (num: number, _: string, valid: boolean) => {
    if (valid) {
      clearErrors(field)
      setValue(field, num)
    } else {
      setError(field, { type: "manual" })
    }
  }

  const watchedFieldNames = Object.keys(watch()) as FormStateKeys

  const renderedParts = useMemo(() => {
    return renderRuleFields()
  }, [props.rule, props.dataSetType, ...watchedFieldNames.map((fieldName) => watch(fieldName))])

  const rowSpec = useMemo(() => {
    if (renderedParts?.options) {
      return "158px 1fr 156px min-content"
    } else {
      return "158px 1fr min-content"
    }
  }, [renderedParts])

  useEffect(() => {
    loadAdditionalFormData()
  }, [watch("ruleCategory")])

  function formDefaults(): FormState {
    let selectedRule: RuleCategory | undefined = undefined
    const rule = props.rule
    if (rule && "field" in rule) {
      selectedRule = ruleCategories.find((rc) => isRuleCategoryWithField(rc) && rc.field === rule.field)
    } else if (rule) {
      selectedRule = ruleCategories.find((rc) => rc.type === rule.type)
    }

    return {
      exclusive: false,
      ruleCategory: selectedRule ?? ruleCategories[0],
      oneOfOptions: [],
      oneOfSelections: [],
      rankRelative: true,
      rankFromTop: true,
      rankValue: undefined,
      exampleAgsRefResLoc: "",
      agsRefResLocOptions: [],
      selectedProfileId: undefined,
      profileFrom: 0,
      profileTo: 100,
      rankRescale: false,
      collapsedGroup: [],
      isInitCollapsedGroupSeated: false,
      showScoresWithVariations: new Set(),
      selectedScore: undefined,
      districtData: {
        exclusive: false,
      },
      ...defaultFromCurrentRule(),
    }
  }

  function loadAdditionalFormData() {
    const ruleCategory = watch("ruleCategory")
    let currentField: string | null = null
    if (isRuleCategoryWithField(ruleCategory)) {
      currentField = ruleCategory.field
    }
    switch (ruleCategory.type) {
      case "loadmicrodata":
        getFieldOptions("micro", "ags_ref_res_loc").then(
          (agsRefResLocOptions) => {
            setTimeout(() => setValue("agsRefResLocOptions", agsRefResLocOptions), 0)
          },
          () => {}
        )
        break
      case "oneoffilter":
        currentField &&
          getFieldOptions(props.dataSetType, currentField).then(
            (oneOfOptions) => setValue("oneOfOptions", oneOfOptions),
            () => {}
          )
        break
    }
  }

  function defaultFromCurrentRule(): Partial<FormState> {
    const { rule } = props
    if (!rule) {
      return {}
    }
    switch (rule.type) {
      case "loadmicrodata":
        return {
          ruleCategory: findRuleCategory(rule),
          exampleAgsRefResLoc: rule.exampleAgsRefResLoc,
        }
      case "rangefilter":
        let maybeScoreWithVariation: LanaNode | undefined
        const maybeScore = getAllScores()?.find((s) =>
          s.scoreVariation
            ? (maybeScoreWithVariation = s.scoreVariationNames?.find((s) => s.rawName === rule.field))
            : s.rawName === rule.field
        )
        let rangeFrom: number | undefined
        let rangeTo: number | undefined

        if (maybeScore?.unit?.en === "%") {
          rangeFrom = rule.from ? rule.from * 100 : undefined
          rangeTo = rule.to ? rule.to * 100 : undefined
        } else {
          rangeFrom = rule.from
          rangeTo = rule.to
        }

        return {
          ruleCategory: findRuleCategory(rule),
          exclusive: rule.exclusive,
          rangeFrom: rangeFrom,
          rangeTo: rangeTo,
          selectedScore: maybeScoreWithVariation ? maybeScoreWithVariation : maybeScore,
          showScoresWithVariations: maybeScoreWithVariation
            ? new Set<string>().add(maybeScoreWithVariation?.rawName)
            : maybeScore
            ? new Set<string>().add(maybeScore?.rawName)
            : new Set<string>(),
        }
      case "oneoffilter":
        return {
          ruleCategory: findRuleCategory(rule),
          exclusive: rule.exclusive,
          oneOfSelections: rule.selections,
        }
      case "rankbyprofile":
        return {
          ruleCategory: findRuleCategory(rule),
          selectedProfileId: rule.id,
          rankRescale: rule.rescale,
          profileTo: rule.to,
          profileFrom: rule.from,
        }
      case "rankbyscores":
        const maybeScoreName = Object.getOwnPropertyNames(rule.scores)[0]
        const scoreNameMatches = (node: LanaNodeWithVariation) =>
          node.scoreName === maybeScoreName || node.rawName === maybeScoreName
        const scoreNameMatchesWithVariants = (node: LanaNodeWithVariation) =>
          node.scoreVariation ? !!node.scoreVariationNames?.some(scoreNameMatches) : scoreNameMatches(node)

        const scoreOpt = getAllScores()?.find(scoreNameMatchesWithVariants)
        const scoreOptWithVariation =
          scoreOpt && scoreOpt.scoreVariationNames && scoreOpt.scoreVariationNames.find(scoreNameMatches)
        return {
          ruleCategory: findRuleCategory(rule),
          selectedScore: scoreOptWithVariation ? scoreOptWithVariation : scoreOpt,
          rankRescale: rule.rescale,
          profileTo: rule.to,
          profileFrom: rule.from,
          showScoresWithVariations: scoreOpt ? new Set<string>().add(scoreOpt?.scoreName) : new Set<string>(),
        }
      case "selectranks":
        return {
          ruleCategory: findRuleCategory(rule),
          rankRelative: rule.relative,
          rankFromTop: rule.fromTop,
          rankValue: rule.value,
        }
      case "districtdatarule": {
        const prop = LANA_DISTRICT_PROPS[rule.score]

        const to = rule.to && prop.unit?.en === "%" ? rule.to * 100 : rule.to
        const from = rule.from && prop.unit?.en === "%" ? rule.from * 100 : rule.from

        return {
          districtData: {
            from,
            to,
            exclusive: rule.exclusive,
            score: rule.score,
          },
        }
      }
      default:
        return {}
    }
  }

  function getAllScores(): LanaNodeWithVariation[] {
    const nodes = props.dataSetType === "micro" ? LANA_CELL_NODES : LANA_AGS_NODES
    return categorize(t, nodes)
      .map((c) => c.scores)
      .flat()
  }

  function findRuleCategory(rule: RatingSelectionRule): RuleCategory {
    let ruleCategory: RuleCategory | undefined
    if ("field" in rule) {
      ruleCategory = ruleCategories.find(
        (category) =>
          isRuleCategoryWithField(category) && category.type === rule.type && rule.field.match(category.field)
      )
    } else {
      ruleCategory = ruleCategories.find((category) => category.type === rule.type)
    }

    return ruleCategory || ruleCategories[0]
  }

  function renderRuleFields() {
    const ruleCategory = watch("ruleCategory")
    if ("type" in ruleCategory) {
      switch (ruleCategory.type) {
        case "rangefilter":
          return renderRangeFilterFields()
          break
        case "oneoffilter":
          return renderOneOfFilterFields()
          break
        case "selectranks":
          return renderSelectRanks()
          break
        case "loadmicrodata":
          return renderLoadMicroData()
          break
        case "rankbyprofile":
          return renderRankByProfile()
          break
        case "rankbyscores":
          return renderRankByScores()
          break
        case "districtdatarule":
          return renderDistrictData()
      }
    }

    return undefined
  }

  function renderScoreList(useRaw: boolean) {
    switch (props.dataSetType) {
      case "macro":
        return categorize(t, LANA_AGS_NODES).map((c) => renderCategorizedScoresWithVariations(c, useRaw))
      case "micro":
        return categorize(t, LANA_CELL_NODES).map((c) => renderCategorizedScoresWithVariations(c, useRaw))
    }
  }

  function renderCategorizedScoresWithVariations(categorizedScores: CategorizedScores, useRaw: boolean) {
    const selectedScore = watch("selectedScore")
    const expanded = categorizedScores.scores.some((s) => s.category === selectedScore?.category)
    return (
      <Expandable
        key={`category-${categorizedScores.category.name}`}
        header={t.pickTranslation(categorizedScores.category.title)}
        isOpen={expanded}
      >
        <Grid columns={1}>
          <div className={styles.scoreInGroupClass}>
            {categorizedScores.scores.map((s) => renderScoreWithVariation(s, useRaw))}
          </div>
        </Grid>
      </Expandable>
    )
  }

  function renderScoreWithVariation(score: LanaNodeWithVariation, useRaw: boolean) {
    const selectedScore = watch("selectedScore")
    const scoreName = useRaw ? score.rawName : score.scoreName
    const selectedScoreName = useRaw ? selectedScore?.rawName : selectedScore?.scoreName
    const selected = score.scoreVariation
      ? score.scoreVariationNames?.some((s) => (useRaw ? s.rawName : s.scoreName) === selectedScoreName)
      : scoreName === selectedScoreName
    const scoreVariationExpanded =
      !!score.scoreVariationName &&
      score.scoreVariationNames?.some((s) => watch("showScoresWithVariations").has(useRaw ? s.rawName : s.scoreName))

    const radioOnChangeHandler = () => {
      if (score.scoreVariationNames) {
        setValue("selectedScore", score.scoreVariationNames[0])
        setValue("showScoresWithVariations", new Set<string>().add(scoreName))
      } else {
        setValue("selectedScore", score)
      }
    }

    return (
      <div key={`scorevariation-${score.scoreName}`}>
        <Flex flexDirection="row" gap={4} key={scoreName} padding={[16, 16, 12, 16]}>
          <FlexItem>
            <RadioButton
              id={scoreName}
              value={scoreName}
              checked={selected}
              label={t.pickTranslation(score.title)}
              name={scoreName}
              onChange={radioOnChangeHandler}
            />
          </FlexItem>
          <Tooltip
            placement="left"
            tooltip={
              <div style={{ maxWidth: "400px" }}>
                <b>{t.scoreDescription}</b>
                <span>: {t.pickTranslation(score.description)}</span>
                <br />
                <b>{t.scoreSource}</b>
                <span>: {t.pickTranslation(score.source)}</span>
              </div>
            }
          >
            <FlexItem alignSelf="start">
              <Icon name="info" fontSize={14} color="primary" colorType="default" />
            </FlexItem>
          </Tooltip>
          <FlexItem flexGrow={1} />

          {score.scoreVariationNames && score.scoreVariationNames?.length > 1 && (
            <div
              className={scoreVariationExpanded ? styles.arrowClass : cx(styles.arrowClass, styles.arrowExpandedClass)}
            >
              <a
                onClick={() => {
                  const showScoresWithVariations = watch("showScoresWithVariations")
                  showScoresWithVariations.has(scoreName)
                    ? showScoresWithVariations.delete(scoreName)
                    : showScoresWithVariations.add(scoreName)
                  setValue("showScoresWithVariations", showScoresWithVariations)
                }}
              >
                <Icon fontSize={20} name={"arrow_drop_up"} />
              </a>
            </div>
          )}
        </Flex>
        {score.scoreVariationNames && score.scoreVariationNames.length > 1 && (
          <Collapse isOpen={scoreVariationExpanded} transitionDuration={0}>
            <Flex flexDirection="row" gap={16} key={score.scoreVariationName?.en} padding={[0, 8, 16, 16]}>
              {score.scoreVariationNames.map((s) => renderButtonScoreVariation(s, useRaw))}
            </Flex>
          </Collapse>
        )}
      </div>
    )
  }

  function renderButtonScoreVariation(variationName: LanaNode, useRaw: boolean) {
    const selected = useRaw
      ? watch("selectedScore")?.rawName === variationName.rawName
      : watch("selectedScore")?.scoreName === variationName.scoreName
    return (
      <React.Fragment key={`${variationName.scoreName}-${variationName.rawName}`}>
        {variationName.scoreVariationName && (
          <ButtonBP
            className={styles.buttonScoreVariationClass}
            style={{
              fontSize: 12,
              borderRadius: 4,
              minWidth: "72px",
              borderRight: selected ? "1px solid" : "",
              borderLeft: selected ? "1px solid" : "",
            }}
            outlined={!selected}
            minimal={!selected}
            intent={selected ? "primary" : undefined}
            small
            key={`${variationName.scoreVariation}-${variationName.scoreVariationName?.en}`}
            onClick={() => setValue("selectedScore", variationName)}
          >
            {t.pickTranslation(variationName.scoreVariationName)}
          </ButtonBP>
        )}
      </React.Fragment>
    )
  }

  function renderRangeFilterFields() {
    const ruleCategory = watch("ruleCategory")
    let selectedScore = watch("selectedScore")
    const scoreUnit = selectedScore?.unit ? t.pickTranslation(selectedScore?.unit) : undefined

    if (!isRuleCategoryWithField(ruleCategory)) {
      return { body: null, options: null }
    }

    return {
      body: ruleCategory.field.startsWith("raw_") && (
        <Flex flexDirection="column">
          <div className={styles.scoreGroupsClass}>{renderScoreList(true)}</div>
        </Flex>
      ),
      options: (
        <Grid columns={1}>
          <GridItem padding={[0, 0, 12, 0]}>
            <Select
              value={watch("exclusive").toString()}
              options={[
                { label: t.selectionRuleEdit.inclusiveLabel, value: "false" },
                { label: t.selectionRuleEdit.exclusiveLabel, value: "true" },
              ]}
              onValueChange={(value) => setValue("exclusive", value === "true")}
            />
          </GridItem>
          <Grid columnSpec="1fr min-content 1fr" gap={8}>
            <div>{t.scoreSearch.from} (&gt;=)</div>
            <div></div>
            <div>{t.scoreSearch.to} (&lt;)</div>
            <NumberInput
              defaultValue={watch("rangeFrom")}
              onValueChange={onValueChangeUseForm("rangeFrom")}
              suffix={scoreUnit}
            />
            <GridItem padding={8}>
              <span>—</span>
            </GridItem>
            <NumberInput
              defaultValue={watch("rangeTo")}
              onValueChange={onValueChangeUseForm("rangeTo")}
              suffix={scoreUnit}
            />
          </Grid>
        </Grid>
      ),
    }
  }

  function renderOneOfFilterFields() {
    const { oneOfOptions, ruleCategory, exclusive } = watch()

    if (oneOfOptions.length === 0) {
      return { body: <LoadingSpinner />, options: null }
    }

    if (oneOfOptions.length > 20) {
      return {
        body: (
          <FieldOptionMultiSelect
            options={oneOfOptions}
            selected={watch("oneOfSelections")}
            onSelectionChanged={(oneOfSelections) => setValue("oneOfSelections", oneOfSelections)}
          />
        ),
        options: ruleCategory.id === "oneoffilter::macro::ref_id" && (
          <Grid columns={1}>
            <Select
              value={watch("exclusive")?.toString()}
              options={[
                { label: t.selectionRuleEdit.inclusiveLabel, value: "false" },
                { label: t.selectionRuleEdit.exclusiveLabel, value: "true" },
              ]}
              onValueChange={(value) => setValue("exclusive", value === "true")}
            />
          </Grid>
        ),
      }
    }

    return {
      body: oneOfOptions.map((option, idx) => (
        <BorderBottom key={idx} padding={[4, 16, 4, 16]}>
          <Checkbox
            key={idx}
            label={option.label}
            checked={watch("oneOfSelections").indexOf(option.key) >= 0}
            onChange={() => {
              const idx = watch("oneOfSelections").indexOf(option.key)
              if (idx >= 0) {
                setValue(
                  "oneOfSelections",
                  watch("oneOfSelections").filter((v) => v !== option.key)
                )
              } else {
                setValue("oneOfSelections", [...watch("oneOfSelections"), option.key])
              }
            }}
          />
        </BorderBottom>
      )),
      options: ruleCategory.id === "oneoffilter::macro::ref_id" && (
        <Grid columns={1}>
          <Select
            value={exclusive.toString()}
            options={[
              { label: t.selectionRuleEdit.inclusiveLabel, value: "false" },
              { label: t.selectionRuleEdit.exclusiveLabel, value: "true" },
            ]}
            onValueChange={(value) => setValue("exclusive", value === "true")}
          />
        </Grid>
      ),
    }
  }

  function renderSelectRanks() {
    return {
      body: (
        <Grid padding={16} columns={1} gap={8}>
          <GridItem padding={[0, 0, 8, 0]}>
            <Select
              value={watch("rankRelative") ? "true" : "false"}
              options={[
                { label: t.selectionRuleEdit.selectRankPercentage, value: "true" },
                { label: t.selectionRuleEdit.selectRankAbsolute, value: "false" },
              ]}
              onValueChange={(value) => setValue("rankRelative", value === "true")}
            />
          </GridItem>
          <GridItem padding={[0, 0, 8, 0]}>
            <Select
              value={watch("rankFromTop") ? "true" : "false"}
              options={[
                { label: t.selectionRuleEdit.selectRankTop, value: "true" },
                { label: t.selectionRuleEdit.selectRankBottom, value: "false" },
              ]}
              onValueChange={(value) => setValue("rankFromTop", value === "true")}
            />
          </GridItem>
          <GridItem padding={[0, 0, 8, 0]}>
            <NumberInput defaultValue={watch("rankValue")} onValueChange={onValueChangeUseForm("rankValue")} />
          </GridItem>
        </Grid>
      ),
      options: null,
    }
  }

  function renderLoadMicroData() {
    if (watch("agsRefResLocOptions").length === 0) {
      return {
        body: <LoadingSpinner />,
        options: null,
      }
    }

    const options = watch("agsRefResLocOptions")
    const selected = watch("exampleAgsRefResLoc")

    const renderItem = (item: FieldOption, itemProps: IItemRendererProps) => {
      if (!itemProps.modifiers.matchesPredicate) return null
      return (
        <MenuItem
          text={item.label}
          key={item.key}
          active={itemProps.modifiers.active}
          onClick={itemProps.handleClick}
        />
      )
    }

    const itemListPredicate = (query: string, items: FieldOption[]): FieldOption[] => {
      if (query.length < 2) return items.slice(0, 100)
      const filtered = items.filter((item) => item.label.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0)

      return filtered.slice(0, 100)
    }

    return {
      body: (
        <Suggest
          items={options}
          itemsEqual={(a, b) => a.key === b.key}
          selectedItem={options.find((o) => o.key === selected)}
          inputValueRenderer={(item) => item.label}
          onItemSelect={(exampleAgsRefResLoc) => setValue("exampleAgsRefResLoc", exampleAgsRefResLoc.key)}
          itemRenderer={renderItem}
          itemListPredicate={itemListPredicate}
          popoverProps={{ minimal: true, fill: true }}
        />
      ),
      options: null,
    }
  }

  function renderRankByProfile() {
    const expandedPanel = (isActive: boolean) =>
      css({
        maxHeight: isActive ? "auto" : 0,
        overflow: "hidden",
        transition: "max-height .5s ease",

        "> div": {
          paddingLeft: "32px",
        },
      })

    const expandGroup = props.availableProfiles?.find((profile) => profile.id === watch("selectedProfileId"))
    if (
      expandGroup &&
      expandGroup.groupId &&
      !watch("isInitCollapsedGroupSeated") &&
      !watch("collapsedGroup").includes(expandGroup.groupId)
    ) {
      setValue("collapsedGroup", [...watch("collapsedGroup"), expandGroup.groupId])
      setValue("isInitCollapsedGroupSeated", true)
    }

    const profileItem = (profile: Profile) => (
      <div className={styles.profileClass} key={`profileItem-${profile.id}`}>
        <RadioButton
          id={profile.id}
          value={profile.id}
          label={profile.name}
          name="selectedProfile"
          checked={profile.id === watch("selectedProfileId")}
          onChange={(selectedProfileId) => setValue("selectedProfileId", selectedProfileId)}
        />
      </div>
    )

    const setCollapsedGroups = (groupId: string) => {
      if (watch("collapsedGroup").includes(groupId)) {
        setValue(
          "collapsedGroup",
          watch("collapsedGroup").filter((id) => id !== groupId)
        )
      } else {
        setValue("collapsedGroup", [...watch("collapsedGroup"), groupId])
      }
    }

    return {
      body: (
        <ScrollBox>
          <Flex flexDirection="column">
            {props.availableProfileInProgress && <LoadingSpinner />}
            {props.availableProfiles?.map((profile) => !profile.groupId && profileItem(profile))}
            {props.profileGroups?.map((group) => {
              const isGroupEmpty = props.availableProfiles?.some((profile) => profile.groupId === group.id)

              let isInCollapsedGroup = watch("collapsedGroup").includes(group.id)
              return (
                <div key={group.id}>
                  <div
                    className={styles.groupHeader(isInCollapsedGroup, !isGroupEmpty)}
                    onClick={() => setCollapsedGroups(group.id)}
                  >
                    <span className={styles.groupName}>{group.name}</span>
                    <span className={styles.chevronWrap}>
                      <Icon name={isInCollapsedGroup && isGroupEmpty ? "dropdown_up" : "dropdown_down"} />
                    </span>
                  </div>
                  <div className={expandedPanel(isInCollapsedGroup)}>
                    {props.availableProfiles?.map((profile) => profile.groupId === group.id && profileItem(profile))}
                  </div>
                </div>
              )
            })}
          </Flex>
        </ScrollBox>
      ),
      options: (
        <Grid columns={1}>
          <GridItem padding={[0, 0, 16, 0]}>
            <Select
              id="rescaleRank"
              name="rescaleRank"
              value={watch("rankRescale").toString()}
              options={[
                { value: "false", label: t.selectionRuleEdit.rescaleRankNo },
                { value: "true", label: t.selectionRuleEdit.rescaleRankYes },
              ]}
              onValueChange={(v) => setValue("rankRescale", v === "true")}
            />
          </GridItem>
          <Grid columnSpec="1fr min-content 1fr" gap={8}>
            <div>{t.scoreSearch.from} (&gt;=)</div>
            <div></div>
            <div>{t.scoreSearch.to} (&lt;=)</div>
            <NumberInput
              defaultValue={watch("profileFrom")}
              validator={[minValidator(0), maxValidator(100), integerValidator()]}
              onValueChange={onValueChangeUseForm("profileFrom")}
            />
            <GridItem padding={8}>
              <span>—</span>
            </GridItem>
            <NumberInput
              defaultValue={watch("profileTo")}
              validator={[minValidator(0), maxValidator(100), integerValidator()]}
              onValueChange={onValueChangeUseForm("profileTo")}
            />
          </Grid>
        </Grid>
      ),
    }
  }

  function renderRankByScores() {
    return {
      body: (
        <Flex flexDirection="column">
          <div className={styles.scoreGroupsClass}>{renderScoreList(false)}</div>
        </Flex>
      ),
      options: (
        <Grid columns={1}>
          <GridItem padding={[0, 0, 16, 0]}>
            <Select
              id="rescaleRank"
              name="rescaleRank"
              value={watch("rankRescale").toString()}
              options={[
                { value: "false", label: t.selectionRuleEdit.rescaleRankNo },
                { value: "true", label: t.selectionRuleEdit.rescaleRankYes },
              ]}
              onValueChange={(v) => setValue("rankRescale", v === "true")}
            />
          </GridItem>

          <Grid columnSpec="1fr min-content 1fr" gap={8}>
            <div>{t.scoreSearch.from} (&gt;=)</div>
            <div></div>
            <div>{t.scoreSearch.to} (&lt;=)</div>
            <NumberInput
              defaultValue={watch("profileFrom")}
              validator={[minValidator(0), maxValidator(100)]}
              onValueChange={onValueChangeUseForm("profileFrom")}
            />
            <GridItem padding={8}>
              <span>—</span>
            </GridItem>
            <NumberInput
              validator={[minValidator(0), maxValidator(100)]}
              defaultValue={watch("profileTo")}
              onValueChange={onValueChangeUseForm("profileTo")}
            />
          </Grid>
        </Grid>
      ),
    }
  }

  function renderDistrictData() {
    const scoreName = watch("districtData.score")
    const score = scoreName ? LANA_DISTRICT_PROPS[scoreName] : undefined
    return {
      body: (
        <RatingManagerDistrictdataList
          datasetType={props.dataSetType}
          setSelectedScore={(s) => setValue("districtData.score", s)}
          selectedScore={scoreName}
        />
      ),
      options: (
        <Grid columns={1}>
          <GridItem padding={[0, 0, 12, 0]}>
            <Select
              value={watch("districtData.exclusive").toString()}
              options={[
                { label: t.selectionRuleEdit.inclusiveLabel, value: "false" },
                { label: t.selectionRuleEdit.exclusiveLabel, value: "true" },
              ]}
              onValueChange={(value) => setValue("districtData.exclusive", value === "true")}
            />
          </GridItem>
          <Grid columnSpec="1fr min-content 1fr" gap={8}>
            <NumberInput
              defaultValue={watch("districtData.from")}
              onValueChange={onValueChangeUseForm("districtData.from")}
              suffix={score?.unit ? t.pickTranslation(score.unit) : undefined}
            />
            <GridItem padding={8}>
              <span>—</span>
            </GridItem>
            <NumberInput
              defaultValue={watch("districtData.to")}
              onValueChange={onValueChangeUseForm("districtData.to")}
              suffix={score?.unit ? t.pickTranslation(score.unit) : undefined}
            />
          </Grid>
        </Grid>
      ),
    }
  }

  function createRule(): RatingSelectionRule | null {
    const {
      selectedProfileId,
      exampleAgsRefResLoc,
      rangeFrom,
      rangeTo,
      profileTo,
      profileFrom,
      oneOfSelections,
      selectedScore,
      rankRelative,
      rankFromTop,
      rankValue,
      rankRescale,
      exclusive,
      ruleCategory,
      districtData,
    } = watch()

    const currentRuleType = ruleCategory.type
    let currentField: string | null = null
    if (isRuleCategoryWithField(ruleCategory)) {
      currentField = ruleCategory.field
    }

    switch (currentRuleType) {
      case "loadmicrodata":
        if (exampleAgsRefResLoc.length === 0) return null
        return {
          type: "loadmicrodata",
          exampleAgsRefResLoc,
        }
      case "rangefilter":
        if (!currentField || (currentField?.startsWith("raw_") && typeof selectedScore !== "object")) return null

        if (selectedScore?.unit?.en === "%")
          return {
            type: "rangefilter",
            field:
              currentField.startsWith("raw_") && typeof selectedScore === "object"
                ? selectedScore.rawName
                : currentField,
            exclusive,
            to: rangeTo ? rangeTo / 100 : undefined,
            from: rangeFrom ? rangeFrom / 100 : undefined,
          }
        else
          return {
            type: "rangefilter",
            field:
              currentField.startsWith("raw_") && typeof selectedScore === "object"
                ? selectedScore.rawName
                : currentField,
            exclusive,
            to: rangeTo,
            from: rangeFrom,
          }

      case "oneoffilter":
        if (oneOfSelections.length === 0 || !currentField) return null
        return {
          type: "oneoffilter",
          field: currentField,
          exclusive,
          selections: oneOfSelections,
        }
      case "selectranks":
        if (rankValue === undefined) return null
        return {
          type: "selectranks",
          relative: rankRelative,
          fromTop: rankFromTop,
          value: rankValue,
        }
      case "rankbyprofile":
        if (selectedProfileId === undefined) return null
        return {
          type: "rankbyprofile",
          id: selectedProfileId,
          to: profileTo,
          from: profileFrom,
          rescale: rankRescale,
        }
      case "rankbyscores":
        if (typeof selectedScore !== "object") return null
        return {
          type: "rankbyscores",
          scores: {
            [selectedScore.scoreName]: 1.0,
          },
          to: profileTo,
          from: profileFrom,
          rescale: rankRescale,
        }
      case "districtdatarule":
        if (districtData.score === undefined) return null

        const prop = LANA_DISTRICT_PROPS[districtData.score]

        const to = districtData.to && prop.unit?.en === "%" ? districtData.to / 100 : districtData.to
        const from = districtData.from && prop.unit?.en === "%" ? districtData.from / 100 : districtData.from

        return {
          type: "districtdatarule",
          score: districtData.score,
          exclusive: districtData.exclusive,
          to: to,
          from: from,
        }
      default:
        return null
    }
  }

  async function onOk() {
    const newRule = createRule()

    if (!newRule) {
      props.onClose()
      return
    }

    await props.onEdited(newRule)
  }

  return (
    <form
      style={{ height: "100%" }}
      onSubmit={handleSubmit(onOk, (e, ev) => ev?.preventDefault())}
      onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
    >
      <Grid columns={1} rowSpec={rowSpec} height={[100, "%"]}>
        <BorderBottom backgroundColor={"rgb(245, 245, 250)"}>
          <FlexItem padding={16}>
            <Flex flexDirection="row">
              <FlexItem flexGrow={1} />
              <Button type="tertiary" icon="close" size="small" onClick={props.onClose} />
            </Flex>
            <FlexItem padding={[16, 0, 16, 0]}>
              <h1>{props.rule ? t.selectionRuleEdit.editTitle : t.selectionRuleEdit.createTitle}</h1>
            </FlexItem>

            {!props.rule && (
              <Select
                disabled={!!props.rule}
                value={watch("ruleCategory.id")}
                onValueChange={(id) => {
                  setValue("ruleCategory", ruleCategories.find((c) => c.id === id) || ruleCategories[0])
                }}
                options={ruleCategories
                  .filter((c) => c.type !== "loadmicrodata" && c.type !== "districtdatarule") // TB-2068
                  .map((c) => ({
                    value: c.id,
                    label: t.pickTranslation(c.label),
                  }))}
              />
            )}

            {props.rule && <h3>{t.pickTranslation(watch("ruleCategory.label"))}</h3>}
          </FlexItem>
        </BorderBottom>

        <GridItem overflow={"auto"}>{renderedParts?.body}</GridItem>

        {renderedParts?.options && (
          <GridItem>
            <Grid columns={2} padding={16}>
              <GridItem>
                <b>{t.selectionRuleEdit.options}</b>
              </GridItem>
              <GridItem alignSelf={"end"} justifySelf={"end"}>
                <HelpPopover position={"right-bottom"} text={t.selectionRuleEdit.helpText} />
              </GridItem>

              <GridItem colSpan={2} padding={[16, 0, 0, 0]}>
                {renderedParts?.options}
              </GridItem>
            </Grid>
          </GridItem>
        )}
        <GridItem padding={16} alignSelf={"end"}>
          {updateError && <GenericErrorPanel error={updateError} />}

          <Grid columns={1}>
            <GridItem justifySelf={"end"}>
              <Button type="primary" loading={updateInProgress} disabled={!createRule() || !isValid}>
                {t.ok}
              </Button>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </form>
  )
}
