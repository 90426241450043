import React, { useEffect, useMemo, useState } from "react"
import { Layout, Layouts, Responsive, WidthProvider } from "react-grid-layout"
import { AssessmentEntryFull, PriceRange, WidgetsType } from "../../assessment/models/assessment"
import { Grid } from "../../shared/components/ui/grid"
import { ViewModeSelectionDropdown } from "../../shared/components/ui/view-selection-dropdown"
import { trackUsageEvent } from "../../utils/usage-tracking"
import { ContentSelector } from "./content-selector"
import { translations } from "../../assessment/i18n"
import Icon from "../../shared/components/icon"
import { Button } from "@blueprintjs/core"
import { getThemeColorVar } from "../../shared/helper/color"
import { LocationPricesV2 } from "./location-prices-v2"
import { LocationPriceTrendsV2 } from "./location-price-trends-v2"
import { RentIndexWidgetV2 } from "./rent-index-v2"
import { UnitPricesV2 } from "./unit-prices-v2"
import { UnitPriceVariationsV2 } from "./unit-price-variations-v2"
import { MarketGrossYieldsV2 } from "./market-gross-yields-V2"
import { MarketYieldTrendsV2 } from "./market-yield-trends-v2"
import { css, cx } from "emotion"
import { UnitSettingsV2 } from "./unit-settings-v2"

const styles = {
  filtersButton: css({
    height: "100%",
    justifySelf: "end",
    justifyContent: "space-between",
    fontSize: "18px",
    padding: "0 16px",
  }),
  filtersPanel: css({
    display: "grid",
    gridTemplateColumns: "1fr",
    width: "320px",
    borderLeft: `1px solid ${getThemeColorVar("primary", "light")}`,
    padding: "0 22px",
  }),
}

const ResponsiveGridLayout = WidthProvider(Responsive)

type PricesWidgetIds =
  | "location-prices"
  | "unit-prices"
  | "rent-index"
  | "price-trends"
  | "price-variations"
  | "location-prices-title"
  | "unit-prices-title"

type YieldsWidgetIds = "market-gross-yields" | "market-yields-trends" | "yields-title"

type WidgetIds = PricesWidgetIds | YieldsWidgetIds

interface PricesWidgetLayout extends Layout {
  i: WidgetIds
}

interface PricesWidgetLayouts extends Layouts {
  lg: PricesWidgetLayout[]
}
interface Props {
  widget?: WidgetsType
  currentAssessmentEntry?: AssessmentEntryFull | null
}

const PricesWidgetLayout: PricesWidgetLayouts = {
  lg: [
    { i: "location-prices-title", x: 0, y: 0, w: 12, h: 0.5, static: true },
    { i: "location-prices", x: 0, y: 0.5, w: 6, h: 4, static: true },
    { i: "price-trends", x: 6, y: 0.5, w: 6, h: 4, static: true },

    { i: "unit-prices-title", x: 0, y: 4.5, w: 12, h: 0.5, static: true },
    { i: "unit-prices", x: 0, y: 5, w: 6, h: 4, static: true },
    { i: "price-variations", x: 6, y: 5, w: 6, h: 8, static: true },
    { i: "rent-index", x: 0, y: 9, w: 6, h: 4, static: true },
  ],
  sm: [
    { i: "location-prices-title", x: 0, y: 0, w: 1, h: 0.5, static: true },
    { i: "location-prices", x: 0, y: 0.5, w: 1, h: 4, static: true },
    { i: "price-trends", x: 0, y: 4.5, w: 1, h: 4, static: true },

    { i: "unit-prices-title", x: 0, y: 8.5, w: 1, h: 0.5, static: true },
    { i: "unit-prices", x: 0, y: 9, w: 1, h: 4, static: true },
    { i: "price-variations", x: 0, y: 13, w: 1, h: 8, static: true },
    { i: "rent-index", x: 0, y: 21, w: 1, h: 4, static: true },
  ],
}

const YieldsWidgetLayout: PricesWidgetLayouts = {
  lg: [
    { i: "market-gross-yields", x: 0, y: 0, w: 5, h: 6, static: true },
    { i: "market-yields-trends", x: 5, y: 0, w: 5, h: 6, static: true },
    { i: "rent-index", x: 10, y: 0, w: 2, h: 6, static: true },
  ],
  sm: [
    { i: "market-gross-yields", x: 0, y: 0, w: 1, h: 6, static: true },
    { i: "market-yields-trends", x: 0, y: 6, w: 1, h: 6, static: true },
    { i: "rent-index", x: 10, y: 12, w: 1, h: 5, static: true },
  ],
}

const ShowAllWidgetLayout: PricesWidgetLayouts = {
  lg: [
    ...PricesWidgetLayout.lg,
    { i: "yields-title", x: 0, y: 13, w: 12, h: 0.5, static: true },
    { i: "market-gross-yields", x: 0, y: 13.5, w: 6, h: 6, static: true },
    { i: "market-yields-trends", x: 6, y: 13.5, w: 6, h: 6, static: true },
  ],
  sm: [
    ...PricesWidgetLayout.sm,
    { i: "yields-title", x: 0, y: 25, w: 1, h: 0.5, static: true },
    { i: "market-gross-yields", x: 0, y: 25.5, w: 1, h: 6, static: true },
    { i: "market-yields-trends", x: 0, y: 31.5, w: 1, h: 6, static: true },
  ],
}

const getLayoutsByType = (type: PricesPageViewType): PricesWidgetLayouts => {
  switch (type) {
    case "prices":
      return PricesWidgetLayout
    case "yields":
      return YieldsWidgetLayout
    case "all":
      return ShowAllWidgetLayout
  }
}

export type PricesPageViewType = "prices" | "yields" | "all"

export const PricesModuleGridV2 = ({ widget, currentAssessmentEntry }: Props) => {
  const [unitMlPrices, setUnitMlPrices] = useState<PriceRange | undefined>()
  const [pricesPageView, setPricesPageView] = React.useState<PricesPageViewType>("all")
  const [filtersCollapsed, setFiltersCollapsed] = useState(false)

  const t = useMemo(translations, [translations])

  useEffect(() => {
    if (widget === "yields") setPricesPageView("yields")
  }, [])

  useEffect(() => {
    window.dispatchEvent(new Event("resize"))
  }, [filtersCollapsed])

  const onSetPricesAppPage = (pageView: PricesPageViewType) => {
    setPricesPageView(pageView)
    if (currentAssessmentEntry)
      trackUsageEvent("PRICES_CHANGE_TAB", currentAssessmentEntry?.address, pageView.toUpperCase())
  }

  const pricesViewActions = [
    {
      title: `${t.assessmentEntryDetails.view}: ${t.prices.selector.switchButtonLabels.prices}`,
      onClick: () => onSetPricesAppPage("prices"),
    },
    {
      title: `${t.assessmentEntryDetails.view}: ${t.prices.selector.switchButtonLabels.yields}`,
      onClick: () => onSetPricesAppPage("yields"),
    },
    {
      title: `${t.assessmentEntryDetails.view}: ${t.prices.selector.switchButtonLabels.all}`,
      onClick: () => onSetPricesAppPage("all"),
    },
  ]

  const renderWidget = (widgetId: WidgetIds): JSX.Element | undefined => {
    switch (widgetId) {
      case "location-prices":
        return <LocationPricesV2 />
      case "unit-prices":
        return <UnitPricesV2 unitMlPrices={unitMlPrices} setUnitMlPrices={setUnitMlPrices} />
      case "rent-index":
        return <RentIndexWidgetV2 />
      case "price-trends":
        return <LocationPriceTrendsV2 />
      case "price-variations":
        return <UnitPriceVariationsV2 unitMlPrices={unitMlPrices} />
      case "market-gross-yields":
        return <MarketGrossYieldsV2 />
      case "market-yields-trends":
        return <MarketYieldTrendsV2 />
      case "yields-title":
        return <h3>{t.prices.selector.switchButtonLabels.yields}</h3>
      case "location-prices-title":
        return <h3>{t.assessmentEntryDetails.locationPrices}</h3>
      case "unit-prices-title":
        return <h3>{t.assessmentEntryDetails.objectPriceLabel}</h3>
      default:
        console.error("Unknown widget id: ", widgetId)
        return undefined
    }
  }
  const getWidgets = (): Array<JSX.Element> => {
    const layouts = getLayoutsByType(pricesPageView)
    return layouts.lg.map((layout) => {
      return (
        <div key={layout.i} className={"grid-item"}>
          {renderWidget(layout.i)}
        </div>
      )
    })
  }
  return (
    <Grid columns={1} rowSpec={"min-content 1fr"}>
      <Grid
        columns={3}
        columnSpec={`1fr 4fr 1fr`}
        gap={8}
        width={[100, "%"]}
        padding={[32, 0, 0, 0]}
        alignItems={"center"}
      >
        <h2 style={{ whiteSpace: "nowrap", paddingLeft: "32px" }}>
          {t.prices.selector.switchButtonLabels[pricesPageView]}
        </h2>
        <div style={{ width: "300px", justifySelf: "center" }}>
          <ViewModeSelectionDropdown
            options={pricesViewActions}
            selected={`${t.assessmentEntryDetails.view}: ${t.prices.selector.switchButtonLabels[pricesPageView]}`}
          />
        </div>
        <Button
          icon={<Icon name={"filter"} />}
          intent={"primary"}
          rightIcon={<Icon name={filtersCollapsed ? "chevron_left_small" : "chevron_right_small"} />}
          className={cx(styles.filtersButton, css({ width: filtersCollapsed ? "180px" : "320px" }))}
          onClick={() => setFiltersCollapsed(!filtersCollapsed)}
        >
          <span style={{ whiteSpace: "nowrap", padding: "0 4px" }}>
            {pricesPageView !== "yields" ? t.prices.filterPrices : t.prices.filter}
          </span>
        </Button>
      </Grid>
      <Grid columns={2} columnSpec={"1fr min-content"}>
        <div className={"grid-container"}>
          <ResponsiveGridLayout
            className={"layout"}
            breakpoints={{ lg: 1200, sm: 768 }}
            cols={{ lg: 12, sm: 1 }}
            margin={[30, 30]}
            compactType={"horizontal"}
            rowHeight={56}
            layouts={getLayoutsByType(pricesPageView)}
            useCSSTransforms={false}
          >
            {getWidgets()}
          </ResponsiveGridLayout>
        </div>
        <div className={cx(styles.filtersPanel, css({ display: filtersCollapsed ? "none" : "block" }))}>
          <ContentSelector pageView={pricesPageView} />
          {pricesPageView !== "yields" && <UnitSettingsV2 />}
        </div>
      </Grid>
    </Grid>
  )
}
