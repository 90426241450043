import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { CreateProfileGroupPayload, CreateProfilePayload, Group, Profile, ProfileDetails } from "./models/profile"
import { ProfileDependencies } from "./models/profile-dependencies"
import { DataSetType } from "../shared/models/smartdata"
import { Scores } from "../shared/models/scores"
import { store } from "../relas/store"
import { NavigationPage } from "../shared/actions/navigation"
import Axios, { AxiosError, AxiosResponse } from "axios"
import { lanaApiUrl } from "../app_config"
import { navigateTo, NavigationState } from "../shared/reducers/navigation-slice"
import { GenericError, toGenericError } from "../shared/helper/axios"

export type ProfileId = string
export type ProfileGroupId = string
export enum ProfilePane {
  List = 0,
  Add,
  Edit,
  Rename,
  Duplicate,
  AddScores,
  groupList,
  AddGroup,
  RenameGroup,
}

export interface State {
  navigation: NavigationState
  profile: ProfileState
}

export interface ProfileState {
  agsRefResLoc: string

  profileSaveInProgress: boolean
  profileSaveError: GenericError | null

  profileList: null | Profile[]
  profileListInProgress: boolean
  profileListError: GenericError | null

  profileDependencies: ProfileDependencies | null
  profileDependenciesInProgress: boolean
  profileDependenciesError: GenericError | null

  profile: null | Profile
  profileGetInProgress: boolean
  profileGetError: GenericError | null

  profileDeleteInProgress: boolean
  profileDeleteError: GenericError | null

  profilePane: ProfilePane

  smartdataSource: DataSetType
  scores: Scores

  groupPane: ProfilePane
  group: null | Group
  isGroupError: boolean

  groupList: null | Group[]
}
export const initialState: ProfileState = {
  agsRefResLoc: "11000000",

  profileSaveError: null,
  profileSaveInProgress: false,

  profileList: null,
  profileListError: null,
  profileListInProgress: false,

  profileDependencies: null,
  profileDependenciesError: null,
  profileDependenciesInProgress: false,

  profileGetError: null,
  profileGetInProgress: false,
  profile: null,
  profilePane: ProfilePane.List,

  profileDeleteError: null,
  profileDeleteInProgress: false,

  smartdataSource: "micro",
  scores: { micro: {}, macro: {} },

  groupPane: ProfilePane.List,
  group: null,
  isGroupError: false,

  groupList: null,
}
const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    deleteProfileStart(state, action: PayloadAction<ProfileId>) {
      state.profileDeleteInProgress = true
      state.profileDeleteError = null
    },
    deleteProfileDone(state, action: PayloadAction<ProfileId>) {
      state.profileDeleteInProgress = false
      state.profileDeleteError = null
    },
    deleteProfileError(state, action: PayloadAction<GenericError>) {
      state.profileDeleteInProgress = false
      state.profileDeleteError = action.payload
    },
    switchProfilePaneDone(state, action: PayloadAction<ProfilePane>) {
      state.profilePane = action.payload
      state.profileSaveError = null
    },
    getProfileStart(state) {
      state.profileGetInProgress = true
      state.profileGetError = null
      state.profile = null
    },
    getProfileDone(state, action: PayloadAction<Profile>) {
      state.profileGetInProgress = false
      state.profileGetError = null
      state.profile = action.payload
      state.scores = {
        micro: action.payload.scores.micro,
        macro: action.payload.scores.macro,
      }
    },
    getProfileError(state, action: PayloadAction<GenericError>) {
      state.profileGetInProgress = false
      state.profile = null
      state.profileGetError = action.payload
    },
    getProfileListStart(state) {
      state.profileListInProgress = true
      state.profileListError = null
      state.profileList = null
    },
    getProfileListDone(state, action: PayloadAction<Profile[]>) {
      state.profileListInProgress = false
      state.profileListError = null
      state.profileList = action.payload
    },
    getProfileListError(state, action: PayloadAction<GenericError>) {
      state.profileListInProgress = false
      state.profileListError = action.payload
      state.profileList = null
    },
    getProfileDependenciesStart(state) {
      state.profileDependenciesInProgress = true
      state.profileDependenciesError = null
      state.profileDependencies = null
    },
    getProfileDependenciesDone(state, action: PayloadAction<ProfileDependencies>) {
      state.profileDependenciesInProgress = false
      state.profileDependenciesError = null
      state.profileDependencies = action.payload
    },
    getProfileDependenciesError(state, action: PayloadAction<GenericError>) {
      state.profileDependenciesInProgress = false
      state.profileDependenciesError = action.payload
      state.profileDependencies = null
    },
    saveProfileStart(state) {
      state.profileSaveInProgress = true
      state.profileSaveError = null
    },
    saveProfileDone(state, action: PayloadAction<Profile>) {
      state.profileSaveInProgress = false
      state.profileSaveError = null
      state.profile = action.payload
      state.scores = {
        micro: action.payload.scores.micro,
        macro: action.payload.scores.macro,
      }
    },
    saveProfileError(state, action: PayloadAction<GenericError>) {
      state.profileSaveInProgress = false
      state.profileSaveError = action.payload
    },
    updateViewDone(state, action: PayloadAction<{ agsRefResLoc: string; source: DataSetType }>) {
      state.smartdataSource = action.payload.source
      state.agsRefResLoc = action.payload.agsRefResLoc
    },
    updateScoresDone(state, action: PayloadAction<Scores>) {
      state.scores = action.payload
    },
    setCurrentProfileDone(state, action: PayloadAction<Profile>) {
      state.profile = action.payload
      state.scores = {
        micro: action.payload.scores.micro,
        macro: action.payload.scores.macro,
      }
    },
    switchGroupPaneDone(state, action: PayloadAction<ProfilePane>) {
      state.groupPane = action.payload
      state.profileSaveError = null
      state.profileSaveInProgress = false
    },
    saveGroupStart(state) {
      state.profileSaveInProgress = true
      state.profileSaveError = null
      state.isGroupError = false
    },
    saveGroupDone(state, action: PayloadAction<Group>) {
      state.profileSaveInProgress = false
      state.profileSaveError = null
      state.isGroupError = false
      if (!state.groupList) {
        state.groupList = []
      }
      state.groupList.push(action.payload)
    },
    saveGroupError(state, action: PayloadAction<GenericError>) {
      state.profileSaveInProgress = false
      state.profileSaveError = action.payload
      state.isGroupError = true
    },
    updateGroupDone(state, action: PayloadAction<Group>) {
      state.profileSaveInProgress = false
      state.profileSaveError = null
      state.isGroupError = false
      if (state.groupList) {
        state.groupList.forEach((group) => {
          if (group.id === action.payload.id) {
            group.name = action.payload.name
          }
        })
      }
    },
    changeProfileGroupDone(state, action: PayloadAction<ProfileDetails>) {
      state.profileSaveInProgress = false
      state.profileSaveError = null
      state.isGroupError = false
      if (state.profileList) {
        state.profileList.forEach((profile) => {
          if (profile.id === action.payload.id) {
            profile.groupId = action.payload.groupId
          }
        })
      }
    },
    getGroupListStart(state) {
      state.profileListInProgress = true
      state.profileListError = null
      state.groupList = null
    },
    getGroupListDone(state, action: PayloadAction<Group[]>) {
      state.profileListInProgress = false
      state.profileListError = null
      state.groupList = action.payload
    },
    deleteGroupDone(state, action: PayloadAction<ProfileGroupId>) {
      const deletedProfileGroupId = action.payload

      if (state.groupList) {
        const index = state.groupList.findIndex((group) => group.id === deletedProfileGroupId)
        if (index !== -1) state.groupList.splice(index, 1)
      }

      if (state.profileList) {
        state.profileList.forEach((profile) => {
          if (profile.groupId === deletedProfileGroupId) {
            profile.groupId = undefined
          }
        })
      }

      state.profileSaveInProgress = false
      state.profileSaveError = null
    },
    setCurrentGroupDone(state, action: PayloadAction<Group>) {
      state.group = action.payload
      state.profileSaveError = null
    },
  },
})

const {
  deleteProfileStart,
  deleteProfileDone,
  deleteProfileError,
  deleteGroupDone,
  setCurrentProfileDone,
  setCurrentGroupDone,
  updateGroupDone,
  saveProfileDone,
  getProfileDependenciesDone,
  getProfileListStart,
  saveProfileStart,
  saveProfileError,
  getProfileDependenciesStart,
  getProfileDependenciesError,
  getProfileListError,
  getProfileListDone,
  saveGroupStart,
  saveGroupError,
  saveGroupDone,
  getProfileStart,
  getProfileError,
  getGroupListStart,
  getGroupListDone,
  changeProfileGroupDone,
  updateScoresDone,
  getProfileDone,
  updateViewDone,
  switchProfilePaneDone,
  switchGroupPaneDone,
} = profileSlice.actions

export function cancelAddProfile(): void {
  store.dispatch(switchProfilePaneDone(ProfilePane.List))
}

export function startAddProfile(): void {
  store.dispatch(switchProfilePaneDone(ProfilePane.Add))
}

export function startAddProfileScores(): void {
  store.dispatch(switchProfilePaneDone(ProfilePane.AddScores))
}

export function doProfileAddScoresDone(): void {
  store.dispatch(switchProfilePaneDone(ProfilePane.Edit))
}

export function startDuplicateProfile(profile: Profile, agsRefResLoc: string): void {
  store.dispatch(setCurrentProfileDone(profile))
  updateView(profile.scores, "micro", agsRefResLoc)
  store.dispatch(switchProfilePaneDone(ProfilePane.Duplicate))
}

export function startRenameProfile(profile: Profile, agsRefResLoc: string): void {
  store.dispatch(setCurrentProfileDone(profile))
  updateView(profile.scores, "micro", agsRefResLoc)
  store.dispatch(switchProfilePaneDone(ProfilePane.Rename))
}

export function fetchProfiles(): Promise<void> {
  store.dispatch(switchProfilePaneDone(ProfilePane.List))
  navigateTo({ name: NavigationPage.profiles })
  store.dispatch(getProfileListStart())

  return Axios.get<Profile[]>(`${lanaApiUrl}/api/profiles`).then(
    (success) => {
      store.dispatch(getProfileListDone(success.data))
      return fetchProfileDependencies()
    },
    (error: AxiosError) => {
      store.dispatch(getProfileListError(toGenericError(error)))
    }
  )
}

function fetchProfileDependencies(): Promise<void> {
  store.dispatch(getProfileDependenciesStart())
  return Axios.get<ProfileDependencies>(`${lanaApiUrl}/api/profileDependencies`).then(
    (success) => {
      store.dispatch(getProfileDependenciesDone(success.data))
    },
    (error: any) => {
      store.dispatch(getProfileDependenciesError(toGenericError(error)))
    }
  )
}

export function deleteProfile(id: string): Promise<void> {
  store.dispatch(deleteProfileStart(id))

  return Axios.delete(`${lanaApiUrl}/api/profiles/${id}`).then(
    () => {
      store.dispatch(deleteProfileDone(id))
      return fetchProfiles()
    },
    (error: any) => {
      store.dispatch(deleteProfileError(toGenericError(error)))
    }
  )
}

export function fetchProfile(id: string, agsRefResLoc: string): Promise<void> {
  store.dispatch(switchProfilePaneDone(ProfilePane.Edit))
  store.dispatch(getProfileStart())
  navigateTo({ name: NavigationPage.profileEditor, params: { profileId: id } })

  return Axios.get<Profile>(`${lanaApiUrl}/api/profiles/${id}`).then(
    (success) => {
      store.dispatch(getProfileDone(success.data))
      updateView(success.data.scores, "micro", agsRefResLoc)
    },
    (error: any) => {
      store.dispatch(getProfileError(toGenericError(error)))
    }
  )
}

export function createProfile(createProfilePayload: CreateProfilePayload): Promise<void> {
  store.dispatch(saveProfileStart())
  return Axios.post(`${lanaApiUrl}/api/profiles`, createProfilePayload).then(
    (success: AxiosResponse) => {
      const profile: ProfileDetails = success.data
      store.dispatch(saveProfileDone(profile))
      store.dispatch(switchProfilePaneDone(ProfilePane.Edit))
      navigateTo({
        name: NavigationPage.profileEditor,
        params: { profileId: profile.id },
      })
    },
    (error: AxiosError) => {
      store.dispatch(saveProfileError(toGenericError(error)))
    }
  )
}

export function updateProfileName(profileId: string, name: string): Promise<void> {
  store.dispatch(saveProfileStart())
  return Axios.put(`${lanaApiUrl}/api/profiles/${profileId}/name`, JSON.stringify(name), {
    headers: { "Content-Type": "application/json" },
  }).then(
    (success: AxiosResponse) => {
      store.dispatch(saveProfileDone(success.data))
      void fetchProfiles()
    },
    (error: AxiosError) => {
      store.dispatch(saveProfileError(toGenericError(error)))
    }
  )
}

export function updateProfileLocked(profileId: string, locked: boolean): Promise<void> {
  store.dispatch(saveProfileStart())
  return Axios.put(`${lanaApiUrl}/api/profiles/${profileId}/locked`, JSON.stringify(locked), {
    headers: { "Content-Type": "application/json" },
  }).then(
    (success: AxiosResponse) => {
      store.dispatch(saveProfileDone(success.data))
      void fetchProfiles()
    },
    (error: AxiosError) => {
      store.dispatch(saveProfileError(toGenericError(error)))
    }
  )
}

export function updateProfileScores(profileId: string, scores: Scores): Promise<void> {
  store.dispatch(saveProfileStart())
  store.dispatch(updateScoresDone(scores))
  return Axios.put(`${lanaApiUrl}/api/profiles/${profileId}/scores`, scores).then(
    (success: AxiosResponse) => {
      store.dispatch(saveProfileDone(success.data))
    },
    (error: AxiosError) => {
      store.dispatch(saveProfileError(toGenericError(error)))
      return Promise.reject(toGenericError(error))
    }
  )
}

export function updateView(scores: Scores, source: DataSetType, agsRefResLoc: string): void {
  store.dispatch(updateScoresDone(scores))
  store.dispatch(updateViewDone({ agsRefResLoc, source }))
}

export function setCurrentProfile(
  profile: Profile,
  oldSelectedSmartdataSource: DataSetType,
  agsRefResLoc: string
): void {
  store.dispatch(setCurrentProfileDone(profile))
  let source: DataSetType
  if (Object.keys(profile.scores.macro).length > 0 && Object.keys(profile.scores.micro).length == 0) {
    source = "macro"
  } else if (Object.keys(profile.scores.macro).length == 0 && Object.keys(profile.scores.micro).length > 0) {
    source = "micro"
  } else {
    source = oldSelectedSmartdataSource
  }
  updateView(profile.scores, source, agsRefResLoc)
}

export function addGroupStart(): void {
  store.dispatch(switchProfilePaneDone(ProfilePane.AddGroup))
}

export function renameGroupStart(group: Group): void {
  store.dispatch(setCurrentGroupDone(group))
  store.dispatch(switchProfilePaneDone(ProfilePane.RenameGroup))
}

export function fetchGroups(): void {
  store.dispatch(switchGroupPaneDone(ProfilePane.groupList))
  store.dispatch(getGroupListStart())

  Axios.get(`${lanaApiUrl}/api/profilegroups`).then(
    (success: AxiosResponse) => {
      store.dispatch(getGroupListDone(success.data))
    },
    (error: any) => {
      store.dispatch(saveGroupError(toGenericError(error)))
    }
  )
}

export function createGroup(createProfileGroupPayload: CreateProfileGroupPayload): Promise<void> {
  store.dispatch(saveGroupStart())
  return Axios.post(`${lanaApiUrl}/api/profilegroups`, createProfileGroupPayload).then(
    (success: AxiosResponse) => {
      store.dispatch(saveGroupDone(success.data))
      store.dispatch(switchProfilePaneDone(ProfilePane.List))
      navigateTo({ name: NavigationPage.profiles })
    },
    (error: AxiosError) => {
      store.dispatch(saveGroupError(toGenericError(error)))
    }
  )
}

export function deleteGroup(id: string): Promise<void> {
  return Axios.delete(`${lanaApiUrl}/api/profilegroups/${id}`).then(
    () => {
      store.dispatch(deleteGroupDone(id))
    },
    (_) => {}
  )
}

export function updateGroupName(profileId: string, name: string): Promise<void> {
  store.dispatch(saveGroupStart())
  return Axios.put(`${lanaApiUrl}/api/profilegroups/${profileId}/name`, JSON.stringify(name), {
    headers: { "Content-Type": "application/json" },
  }).then(
    (success: AxiosResponse) => {
      store.dispatch(updateGroupDone(success.data))
      store.dispatch(switchProfilePaneDone(ProfilePane.List))
      navigateTo({ name: NavigationPage.profiles })
    },
    (error: AxiosError) => {
      store.dispatch(saveGroupError(toGenericError(error)))
    }
  )
}

export function doDNDGroup(profileId: string, groupID: string | null): Promise<void> {
  return Axios.put(`${lanaApiUrl}/api/profiles/${profileId}/group`, JSON.stringify(groupID), {
    headers: { "Content-Type": "application/json" },
  }).then(
    (success: AxiosResponse) => {
      store.dispatch(changeProfileGroupDone(success.data))
    },
    (_) => {}
  )
}

export default profileSlice.reducer
