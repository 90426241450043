import { RELAS_DE } from "./de"
import { RELAS_EN } from "./en"
import { RelasTranslations } from "./translations"
import { language } from "../../shared/language"

export function translations(): RelasTranslations {
  switch (language()) {
    case "de":
      return RELAS_DE
    default:
      return RELAS_EN
  }
}
