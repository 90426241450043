import React, { useEffect, useState } from "react"
import GoogleMapReact from "google-map-react"
import { css } from "emotion"
import { generatePoiMarkers, PrivatePOICategory, PrivatePOIList } from "../../models/private-data"
import { googleMapsApiKey, googleMapsApiLibraries } from "../../../app_config"
import useSupercluster from "use-supercluster"
import { BBox } from "geojson"
import LatLngBounds = google.maps.LatLngBounds
import { PoiMarkerType, POIClusterMarker } from "../../../shared/components/poi-cluster-marker"
import { POIObjectMarkerType } from "../../../shared/components/private-poi-clusters"
import { PoiMarkerDialog } from "../../../shared/components/privatedata-poi-marker-dialog"
import Grid from "../../../shared/components/restyle-grid/grid"

const styles = {
  mapContainer: css({
    height: "100%",
    width: "100%",
    position: "relative",
    backgroundColor: "rgb(229, 227, 223)",
  }),
}

interface Props {
  showControls: boolean
  poiList: PrivatePOIList
  selectedCategory: PrivatePOICategory | undefined
  poisToDisplay: PrivatePOIList
  updatePoiToDisplay: (pois: PrivatePOIList) => void
}

const centerBerlin = { lat: 52.520008, lng: 13.404954 }

export const PrivatePoisMap = ({
  showControls,
  poiList,
  selectedCategory,
  poisToDisplay,
  updatePoiToDisplay,
}: Props) => {
  const [gmap, setGmap] = useState<google.maps.Map>()
  const [gmaps, setGmaps] = useState<typeof google.maps>()
  const [bounds, setBounds] = useState<LatLngBounds>()
  const [center, setCenter] = useState(centerBerlin)
  const [zoom, setZoom] = useState(13)
  const [nWSeBounds, setNwSeBounds] = useState<BBox | undefined>(undefined)
  const [locationToShowPopup, setLocationToShowPopup] = useState<PoiMarkerType | undefined>(undefined)
  const [isPopUpHovered, setIsPopUpHovered] = useState<boolean>(false)

  useEffect(() => {
    if (gmap && gmaps) {
      const newBounds = new google.maps.LatLngBounds()
      if (poiList.length >= 0) {
        poiList.forEach((poi) => {
          const pos = poi.droppedLocation ? poi.droppedLocation : poi.address.location
          if (pos) newBounds.extend(pos)
        })
      }
      if (!newBounds.isEmpty()) {
        setBounds(newBounds)
        setCenter({
          lng: newBounds.getCenter().lng(),
          lat: newBounds.getCenter().lat(),
        })
        gmap.fitBounds(newBounds, 100)
      }
    }
  }, [poiList, gmap, gmaps])

  useEffect(() => {
    if (bounds) {
      setNwSeBounds(getNorthWestAndSouthEastBounds(bounds))
    } else setZoom(13)
  }, [bounds])

  useEffect(() => {
    setBounds(undefined)
  }, [selectedCategory])

  const privatePOIMarkers: POIObjectMarkerType[] = generatePoiMarkers(poiList)

  const getNorthWestAndSouthEastBounds = (bounds: LatLngBounds): BBox => {
    let SW = { lng: bounds.getSouthWest().lng(), lat: bounds.getSouthWest().lat() }
    let NE = { lng: bounds.getNorthEast().lng(), lat: bounds.getNorthEast().lat() }
    return [SW.lng, SW.lat, NE.lng, NE.lat]
  }
  const { clusters, supercluster } = useSupercluster({
    points: privatePOIMarkers,
    bounds: nWSeBounds,
    zoom,
    options: { radius: 75, maxZoom: 23 },
  })
  const handleOnLoad = (map: google.maps.Map, maps: typeof google.maps) => {
    map.setOptions({ isFractionalZoomEnabled: true })
    setGmap(map)
    setGmaps(maps)
  }

  const openPoiMarkerDialog = (location: PoiMarkerType, poisToDisplay: PrivatePOIList) => {
    setLocationToShowPopup(location)
    updatePoiToDisplay(poisToDisplay)
  }

  const closePoiMarkerDialog = () => {
    setLocationToShowPopup(undefined)
    updatePoiToDisplay([])
  }

  return (
    <Grid columns={1} columnSpec={"1fr"} height={[100, "%"]}>
      <div id={"private-poi-map"} className={styles.mapContainer}>
        <GoogleMapReact
          onGoogleApiLoaded={({ map, maps }) => handleOnLoad(map, maps)}
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{
            key: googleMapsApiKey,
            libraries: googleMapsApiLibraries,
            id: "defaultGoogleMapScript",
          }}
          zoom={zoom}
          center={poiList.length > 0 ? center : centerBerlin}
          defaultZoom={zoom}
          onChange={({ zoom, bounds }) => {
            setZoom(zoom)
            setBounds(gmap?.getBounds())
          }}
          options={{
            mapTypeControl: showControls,
            rotateControl: showControls,
            streetViewControl: showControls,
            zoomControl: showControls,
            zoomControlOptions: { position: 3 },
            fullscreenControl: showControls,
            draggableCursor: "default",
            disableDoubleClickZoom: isPopUpHovered,
          }}
        >
          {privatePOIMarkers.length > 0 &&
            clusters.map((cluster) => {
              const [longitude, latitude] = cluster.geometry.coordinates
              const { cluster: isCluster, point_count: pointCount, poiId, cluster_id } = cluster.properties
              const markerId = isCluster
                ? `poi:cluster:${cluster_id}:${selectedCategory?.id}`
                : `poi:marker:${poiId}:${selectedCategory?.id}`
              return (
                <POIClusterMarker
                  markerId={markerId}
                  key={markerId}
                  lat={latitude}
                  lng={longitude}
                  pointCount={isCluster ? pointCount : 1}
                  markerPoints={
                    isCluster ? (supercluster ? supercluster.getLeaves(cluster.id as number, Infinity) : []) : [cluster]
                  }
                  category={selectedCategory}
                  openDialog={openPoiMarkerDialog}
                  poiList={poiList}
                />
              )
            })}
          {locationToShowPopup && poisToDisplay.length > 0 && (
            <PoiMarkerDialog
              lat={locationToShowPopup.lat}
              lng={locationToShowPopup.lng}
              poisToShow={poisToDisplay}
              onClose={closePoiMarkerDialog}
              onHover={setIsPopUpHovered}
              entryPinLocation={undefined}
            />
          )}
        </GoogleMapReact>
      </div>
    </Grid>
  )
}
