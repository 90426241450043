import { StepOneData } from "../models/step-one-data"
import Axios, { AxiosError, AxiosResponse } from "axios"
import { StepOneError, StepThreeError } from "../models/errors"
import { StepThreeData } from "../models/step-three-data"
import { store } from "../../relas/store"
import { navigateTo } from "../../shared/reducers/navigation-slice"
import { NavigationPage } from "../../shared/actions/navigation"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { registrationServiceUrl } from "../../app_config"
import { GenericError, toGenericError } from "../../shared/helper/axios"

export interface RegistrationState {
  stepOneError: GenericError | undefined | StepOneError
  stepOneInProgress: boolean
  emailToken?: string
  emailTokenVerificationError?: GenericError | "expired" | "user-already-verified"
  userId?: string
  stepThreeError: GenericError | undefined | StepThreeError
  stepThreeInProgress: boolean
}

export const initialState: RegistrationState = {
  stepOneError: undefined,
  stepOneInProgress: false,
  emailToken: undefined,
  emailTokenVerificationError: undefined,
  userId: undefined,
  stepThreeError: undefined,
  stepThreeInProgress: false,
}

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    stepOneError(state, action: PayloadAction<GenericError | StepOneError>) {
      state.stepOneError = action.payload
      state.stepOneInProgress = false
    },

    stepOneSubmitDone(state) {
      state.stepOneInProgress = false
      state.stepOneError = undefined
    },
    stepOneSubmitInProgress(state) {
      state.stepOneInProgress = true
      state.stepOneError = undefined
    },
    setEmailToken(state, action: PayloadAction<string>) {
      state.emailToken = action.payload
    },
    verifyEmailTokenError(state, action: PayloadAction<GenericError>) {
      state.emailTokenVerificationError = action.payload
    },
    verifyEmailTokenExpired(state) {
      state.emailTokenVerificationError = "expired"
    },
    verifyEmailUserAlreadyVerified(state) {
      state.emailTokenVerificationError = "user-already-verified"
    },
    setUserId(state, action: PayloadAction<string>) {
      state.userId = action.payload
    },
    stepThreeSubmitInProgress(state) {
      state.stepThreeInProgress = true
      state.stepThreeError = undefined
    },

    stepThreeSubmitDone(state) {
      state.stepThreeInProgress = false
      state.stepThreeError = undefined
    },

    stepThreeError(state, action: PayloadAction<GenericError | StepThreeError>) {
      state.stepThreeInProgress = false
      state.stepThreeError = action.payload
    },
  },
})

const {
  stepOneError,
  stepOneSubmitDone,
  stepOneSubmitInProgress,
  setEmailToken,
  verifyEmailTokenError,
  verifyEmailTokenExpired,
  verifyEmailUserAlreadyVerified,
  setUserId,
  stepThreeSubmitInProgress,
  stepThreeSubmitDone,
  stepThreeError,
} = registrationSlice.actions

export function registrationStoreEmailToken(token: string) {
  store.dispatch(setEmailToken(token))
}

export function registrationDoStepOne(stepOneData: StepOneData) {
  store.dispatch(stepOneSubmitInProgress())

  return Axios.post(`${registrationServiceUrl}/api/verify/step1`, stepOneData).then(
    (success: AxiosResponse) => {
      store.dispatch(stepOneSubmitDone())
      navigateTo({ name: NavigationPage.registrationStepTwo, params: { userId: success.data } })
    },
    (error: AxiosError<any>) => {
      if (error.response && error.response.data && error.response.data.details) {
        let detailedError

        if (error.response.data.details.emailTaken === true) {
          detailedError = StepOneError.EmailTaken
        } else {
          detailedError = toGenericError(error)
        }

        store.dispatch(stepOneError(detailedError))
      } else {
        store.dispatch(stepOneError(toGenericError(error)))
      }
    }
  )
}

export function registrationDoResendCodes(token: string) {
  return Axios.post(`${registrationServiceUrl}/api/resetForToken/${encodeURIComponent(token)}`).then(
    (success: AxiosResponse) => {
      navigateTo({ name: NavigationPage.registrationStepTwo, params: { userId: success.data } })
    },
    (error: AxiosError) => {
      store.dispatch(verifyEmailTokenError(toGenericError(error)))
    }
  )
}

export function registrationSetUserId(userId: string) {
  store.dispatch(setUserId(userId))
}

export function registrationDoVerifyEmailToken(token: string) {
  return Axios.get(`${registrationServiceUrl}/api/verify/email/${encodeURIComponent(token)}`).then(
    (success: AxiosResponse) => {
      store.dispatch(setUserId(success.data.userId))
      navigateTo({
        name: NavigationPage.registrationStepThree,
        params: { userId: success.data.userId },
      })
    },
    (error: AxiosError) => {
      if (error.response && error.response.status === 424) {
        store.dispatch(verifyEmailTokenExpired())
      } else if (error.response && error.response.status === 410) {
        store.dispatch(verifyEmailUserAlreadyVerified())
      } else {
        store.dispatch(verifyEmailTokenError(toGenericError(error)))
      }
    }
  )
}

export function registrationDoStepThree(userId: string, stepThreeData: StepThreeData) {
  store.dispatch(stepThreeSubmitInProgress())

  const dataWithZipAsString = {
    ...stepThreeData,
    address: {
      ...stepThreeData.address,
      zip: stepThreeData.address.zip && stepThreeData.address.zip.toString(),
    },
  }

  return Axios.post(
    `${registrationServiceUrl}/api/verify/step3/${encodeURIComponent(userId)}`,
    dataWithZipAsString
  ).then(
    (success: AxiosResponse) => {
      store.dispatch(stepThreeSubmitDone())
      navigateTo({ name: NavigationPage.registrationComplete })
    },
    (error: AxiosError<any>) => {
      if (error.response && error.response.data && error.response.data.details) {
        let detailedError

        if (error.response.data.details === "error.field.password.tooshort") {
          detailedError = StepThreeError.PasswordTooShort
        } else if (error.response.data.details === "error.field.password.toolong") {
          detailedError = StepThreeError.PasswordTooLong
        } else {
          detailedError = toGenericError(error)
        }

        store.dispatch(stepThreeError(detailedError))
      } else {
        store.dispatch(stepThreeError(toGenericError(error)))
      }
    }
  )
}

export default registrationSlice.reducer
