import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { store } from "../../relas/store"
import Axios, { AxiosError } from "axios"
import { companyadminServiceUrl } from "../../app_config"
import { Customer } from "../models/customer"
import { NewMember } from "../models/new-member"
import { GenericError, toGenericError } from "../../shared/helper/axios"

export type Role = "admin" | "user"
interface CompanyMembersListState {
  members: Customer[] | undefined
  fetchMembersListInProgress: boolean
  fetchMembersListError: GenericError | undefined

  inviteMemberInProgress: boolean
  inviteMemberError: GenericError | undefined

  updateCompanyAdminInProgress: boolean
  updateCompanyAdminError: GenericError | undefined

  deleteMemberError: GenericError | undefined
  deleteMemberInProgress: boolean
}
export type CompanyAdminMembersState = {
  companyMembersList: CompanyMembersListState
}

export const initialState: CompanyMembersListState = {
  members: undefined,
  fetchMembersListError: undefined,
  fetchMembersListInProgress: false,
  inviteMemberInProgress: false,
  inviteMemberError: undefined,
  updateCompanyAdminInProgress: false,
  updateCompanyAdminError: undefined,
  deleteMemberError: undefined,
  deleteMemberInProgress: false,
}

const companyMembersListSlice = createSlice({
  name: "companyMembersList",
  initialState,
  reducers: {
    deleteCustomerStart(state) {
      state.deleteMemberInProgress = true
      state.deleteMemberError = undefined
    },
    deleteCustomerDone(state, action: PayloadAction<string>) {
      state.deleteMemberInProgress = false
      state.deleteMemberError = undefined
      state.members = state.members ? state.members.filter((member) => member.id !== action.payload) : state.members
    },
    deleteCustomerError(state, action: PayloadAction<GenericError>) {
      state.deleteMemberInProgress = false
      state.deleteMemberError = action.payload
    },
    fetchMembersListStart(state) {
      state.members = undefined
      state.fetchMembersListError = undefined
      state.fetchMembersListInProgress = true
    },
    fetchMembersListDone(state, action: PayloadAction<Customer[]>) {
      state.members = action.payload
      state.fetchMembersListError = undefined
      state.fetchMembersListInProgress = false
    },
    fetchMembersListError(state, action: PayloadAction<GenericError>) {
      state.members = undefined
      state.fetchMembersListError = action.payload
      state.fetchMembersListInProgress = false
    },
    inviteMemberStart(state) {
      state.inviteMemberError = undefined
      state.inviteMemberInProgress = true
    },
    inviteMemberDone(state, action: PayloadAction<Customer[]>) {
      state.inviteMemberError = undefined
      state.inviteMemberInProgress = false
      state.members = action.payload
    },
    inviteMemberError(state, action: PayloadAction<GenericError>) {
      state.inviteMemberError = action.payload
      state.inviteMemberInProgress = false
    },
    inviteMemberReset(state) {
      state.inviteMemberError = undefined
      state.inviteMemberInProgress = false
    },
    updateCompanyAdminStart(state) {
      state.updateCompanyAdminError = undefined
      state.updateCompanyAdminInProgress = true
    },
    updateCompanyAdminDone(state, action: PayloadAction<Customer[]>) {
      state.updateCompanyAdminError = undefined
      state.updateCompanyAdminInProgress = false
      state.members = action.payload
    },
    updateCompanyAdminError(state, action: PayloadAction<GenericError>) {
      state.updateCompanyAdminError = action.payload
      state.updateCompanyAdminInProgress = false
    },
  },
})

const {
  deleteCustomerStart,
  deleteCustomerDone,
  deleteCustomerError,
  fetchMembersListError,
  fetchMembersListStart,
  fetchMembersListDone,
  inviteMemberStart,
  inviteMemberDone,
  inviteMemberError,
  inviteMemberReset,
  updateCompanyAdminStart,
  updateCompanyAdminDone,
  updateCompanyAdminError,
} = companyMembersListSlice.actions

export function fetchMembersList(): Promise<void> {
  store.dispatch(fetchMembersListStart())
  return Axios.get(`${companyadminServiceUrl}/api/v1/company/members`, {
    params: {},
  }).then(
    (response) => {
      const members = response.data as Customer[]
      store.dispatch(fetchMembersListDone(members))
      return Promise.resolve()
    },
    (error: AxiosError) => {
      const genericError = toGenericError(error)
      store.dispatch(fetchMembersListError(genericError))
      return Promise.reject(genericError)
    }
  )
}
export function deleteCustomer(customerId: string): Promise<void> {
  store.dispatch(deleteCustomerStart())
  return Axios.delete(`${companyadminServiceUrl}/api/v1/company/member/${customerId}`).then(
    () => {
      store.dispatch(deleteCustomerDone(customerId))
      return Promise.resolve()
    },
    (error: AxiosError) => {
      const genericError = toGenericError(error)
      store.dispatch(deleteCustomerError(genericError))
      return Promise.reject(genericError)
    }
  )
}

export function inviteMember(newMember: NewMember) {
  store.dispatch(inviteMemberStart())
  return Axios.post(`${companyadminServiceUrl}/api/v1/company/member/invite`, newMember).then(
    (response) => {
      const members = response.data as Customer[]
      store.dispatch(inviteMemberDone(members))
      return Promise.resolve()
    },
    (error: AxiosError) => {
      const genericError = toGenericError(error)
      store.dispatch(inviteMemberError(genericError))
      return Promise.reject(genericError)
    }
  )
}

export function updateCompanyAdmin(member: Customer, patchToRole: Role): Promise<void> {
  store.dispatch(updateCompanyAdminStart())
  return Axios.post(
    `${companyadminServiceUrl}/api/v1/company/member/${member.id}/${patchToRole === "user" ? "revoke" : "promote"}`,
    {},
    {
      params: {},
    }
  ).then(
    (response) => {
      const members = response.data as Customer[]
      store.dispatch(updateCompanyAdminDone(members))
      return Promise.resolve()
    },
    (error: AxiosError) => {
      const genericError = toGenericError(error)
      store.dispatch(updateCompanyAdminError(genericError))
      return Promise.reject(genericError)
    }
  )
}

export function resetMemberInvite(): void {
  store.dispatch(inviteMemberReset())
}

export default companyMembersListSlice.reducer
