import * as React from "react"
import {
  getInitialMapStyle,
  MapStyle,
  MapStyleControl,
  persistMapStyle,
  setMapUrlBasedOnMapStyle,
} from "../../shared/components/map-style-control"
import { SimpleOverlay } from "../../shared/components/simple-overlay"
import * as layer from "ol/layer"
import { bind } from "decko"
import { css, cx } from "emotion"
import * as source from "ol/source"
import * as ol from "ol"
import * as extend from "ol/extent"
import { Geometry } from "ol/geom"
import RenderFeature from "ol/render/Feature"
import * as style from "ol/style"
import { StyleFunction } from "ol/style/Style"
import { transformExtent } from "ol/proj"
import { Pixel } from "ol/pixel"
import { FeatureLike } from "ol/Feature"
import { MapPopup, PopupFact } from "../../shared/components/ui/map-popup"
import { FullScreen, Zoom } from "ol/control"
import { AttributeControl } from "../../shared/components/attribute-control"
import { translations } from "../i18n"
import { LocationSelectorTranslations } from "../i18n/translations"
import memoizeOne from "memoize-one"
import { fetchAgsResLocViewport } from "../map-helpers"
import {
  AllowedModulesEnum,
  PrivateDataModuleSettings,
  PrivatePOICategoriesList,
  PrivatePOIList,
} from "../../private-data/models/private-data"
import { PrivatePoiControl } from "../../shared/components/map-private-pois-control"
import { JSXContent, PrivatePoiOverlay } from "../../shared/components/PrivatePoiOverlay"
import Layer from "ol/layer/Layer"
import Source from "ol/source/Source"
import { areMultiplePrivatePoisListsDifferent, arePoisToShowDifferent, updateClusters } from "../../utils/utils"
import { PoiMarkerDialog } from "../../shared/components/privatedata-poi-marker-dialog"
import { OpenLayersAbstractProps, OpenLayersMap } from "../../shared/components/openlayers-map"
import { cellVectorTileOptions, refRegionVectorTileOptions } from "../../utils/openlayers"
import { getThemeColor, getThemeColorVar } from "../../shared/helper/color"
import LoadingSpinner from "../../shared/components/loadingspinner"
import { formatNumber } from "../../shared/helper/number-format"

interface Props extends OpenLayersAbstractProps {
  loading: boolean
  agsRefResLoc: string
  onSelectAgsRefResLoc?: (agsRefResLoc: string) => void
  microItems: Map<
    string,
    { color: string; title: string; data: { label: string; value: string }[]; score?: number; grade?: string }
  >
  showStroke: boolean
  initialMapStyle?: MapStyle
  mapNeedsReset: boolean
  dispatchMapReset: () => void
  isPrivateDataAccessible: boolean
  privatePoiCategories: PrivatePOICategoriesList
  privateDataSettings: PrivateDataModuleSettings
  getPrivateDataCategories: () => void
  updateSelectedPrivateDataCategories: (categories: PrivatePOICategoriesList, module: AllowedModulesEnum) => void
  getPrivatePoisFromCategories: (categoryId: string, module?: AllowedModulesEnum) => void
  updatePrivatePoisToShow: (poiList: PrivatePOIList, module?: AllowedModulesEnum) => void
}

const controlClass = (top: string) =>
  css({
    right: ".5em",
    top: top,
  })

interface OwnState {
  hoverRegion: string | null
  hoverRegionName: string | null
  selectMicroId: string | null
  selectedMapStyle: MapStyle
  showPrivatePoisPopover: boolean
  poiPopUpPosition: [number, number] | undefined
}

export const locationSelectorMapStyles = {
  mapClass: css({
    minHeight: 0,
    height: "100%",
  }),
  containerClass: css({
    minHeight: 0,
    position: "relative",
    overflow: "hidden",
  }),
  mapNotifierStyle: css({
    backgroundColor: getThemeColorVar("background", "lighter"),
    width: "100%",
    position: "absolute",
    zIndex: 1000,
    bottom: 0,
    transition: "max-height 1s",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.9,
    overflow: "hidden",
  }),
  showListContainerClass: css({
    position: "absolute",
    top: "16px",
    left: "16px",
    border: `1px solid ${getThemeColorVar("border", "default")}`,
    background: getThemeColorVar("background", "default"),
    color: getThemeColorVar("typo", "default"),
    padding: "8px",
  }),
}

export class LocationSelectorMapMicroView extends OpenLayersMap<Props, OwnState> {
  private t: LocationSelectorTranslations = memoizeOne(translations)()
  private overlay: SimpleOverlay | undefined
  private microLayer: layer.VectorTile | undefined = undefined
  private regionLayer: layer.VectorTile | undefined = undefined
  private privatePoiControl: PrivatePoiControl | undefined = undefined
  private clusterLayerArray: layer.Vector<any>[] = []
  private poiPopUp: PrivatePoiOverlay | undefined = undefined

  constructor(props: Props) {
    super(props)

    this.state = {
      hoverRegion: null,
      hoverRegionName: null,
      selectMicroId: null,
      selectedMapStyle: getInitialMapStyle(props.initialMapStyle),
      showPrivatePoisPopover: false,
      poiPopUpPosition: undefined,
    }
  }

  @bind
  onChangeShowPrivatePoisPopover(value: boolean) {
    this.setState({
      ...this.state,
      showPrivatePoisPopover: value,
    })
  }

  @bind
  onChangeSelectedMapStyle(style: MapStyle) {
    this.setState({ selectedMapStyle: style })
    setMapUrlBasedOnMapStyle(this.getMap(), style)
    persistMapStyle(style)
  }

  componentDidMount() {
    super.componentDidMount()
    this.props.getPrivateDataCategories()

    this.overlay = new SimpleOverlay(this.getMap(), this.getOverlayAt, this.onSelection)

    this.regionLayer = new layer.VectorTile({
      declutter: true,
      source: new source.VectorTile(refRegionVectorTileOptions()),
      style: this.regionStyle,
    })

    this.microLayer = new layer.VectorTile({
      declutter: true,
      source: new source.VectorTile(cellVectorTileOptions()),
      style: this.microStyle(),
    })
    this.getMap().addLayer(this.microLayer)
    this.getMap().addLayer(this.regionLayer)
    this.getMap().on("pointermove", this.onPointerMove)
    this.getMap().on("singleclick", this.onMapClick)

    this.getMap().getControls().clear()

    this.getMap().addControl(new MapStyleControl(this.state.selectedMapStyle, this.onChangeSelectedMapStyle))
    this.getMap().addControl(new FullScreen({ className: `${controlClass("3.5em")}` }))
    this.getMap().addControl(new Zoom({ className: `${controlClass("6em")}` }))
    this.getMap().addControl(new AttributeControl("12.5em"))
    this.addOrRefreshPrivatePoi()
    this.poiPopUp = new PrivatePoiOverlay(this.getMap(), this.poiPopUpContentCreator)
    updateClusters(
      this.getMap(),
      this.clusterLayerArray,
      this.setClusterLayerArray,
      this.props.privateDataSettings.multipleCategoryPOIList,
      this.props.privatePoiCategories,
      this.props.isPrivateDataAccessible
    )

    setMapUrlBasedOnMapStyle(this.getMap(), this.state.selectedMapStyle)
    void this.resetMap()

    document.addEventListener("keydown", this.handleKeyDownEvent)
  }
  componentDidUpdate(prevProps: Props) {
    if (prevProps.microItems !== this.props.microItems) {
      this.overlay?.closeCurrentOverlay(true)
      this.microLayer?.changed()
    }

    if (prevProps.agsRefResLoc !== this.props.agsRefResLoc) {
      void this.resetMap()
    }

    if (this.props.mapNeedsReset) {
      void this.resetMap()
      this.props.dispatchMapReset()
    }
    this.addOrRefreshPrivatePoi()
    if (
      areMultiplePrivatePoisListsDifferent(
        prevProps.privateDataSettings.multipleCategoryPOIList,
        this.props.privateDataSettings.multipleCategoryPOIList
      )
    ) {
      updateClusters(
        this.getMap(),
        this.clusterLayerArray,
        this.setClusterLayerArray,
        this.props.privateDataSettings.multipleCategoryPOIList,
        this.props.privatePoiCategories,
        this.props.isPrivateDataAccessible
      )
    }

    if (arePoisToShowDifferent(prevProps.privateDataSettings.poisToShow, this.props.privateDataSettings.poisToShow)) {
      this.updatePoiPopUp()
    }
  }

  private addOrRefreshPrivatePoi() {
    if (!this.privatePoiControl) {
      this.privatePoiControl = new PrivatePoiControl(
        AllowedModulesEnum.LOCATION_SELECTOR,
        this.props.privatePoiCategories,
        this.props.privateDataSettings.selectedCategories,
        this.props.updateSelectedPrivateDataCategories,
        this.props.getPrivatePoisFromCategories,
        this.state.showPrivatePoisPopover,
        this.onChangeShowPrivatePoisPopover,
        this.props.isPrivateDataAccessible
      )
      this.getMap().addControl(this.privatePoiControl)
    } else {
      this.privatePoiControl?.refreshPopover(
        this.props.privatePoiCategories,
        this.props.privateDataSettings.selectedCategories,
        this.state.showPrivatePoisPopover
      )
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    document.removeEventListener("keydown", this.handleKeyDownEvent)
  }

  protected containerClass(): string {
    return locationSelectorMapStyles.mapClass
  }

  @bind
  protected onMoveEnd() {}

  render() {
    return (
      <div className={locationSelectorMapStyles.containerClass} onMouseLeave={this.cleanHoverRegion}>
        {super.render()}

        <div
          className={cx(
            locationSelectorMapStyles.mapNotifierStyle,
            css({ maxHeight: this.props.loading ? "48px" : "0px" })
          )}
        >
          {this.props.loading && <LoadingSpinner size={32} />}
          <span style={{ padding: "8px" }}>{this.t.loadingResultData}</span>
        </div>

        <div
          className={cx(
            locationSelectorMapStyles.mapNotifierStyle,
            css({ maxHeight: this.state.hoverRegionName ? "48px" : "0px" })
          )}
        >
          <span style={{ padding: "8px" }}>
            {this.t?.map.switchToRegion}: {this.state.hoverRegionName}
          </span>
        </div>
      </div>
    )
  }

  @bind
  private handleKeyDownEvent(e: KeyboardEvent) {
    if (e.key === "Escape") {
      this.overlay?.closeCurrentOverlay(true)
    }
  }

  @bind
  private onPointerMove(event: ol.MapBrowserEvent<any>) {
    const features = this.getMap().getFeaturesAtPixel(event.pixel, {
      layerFilter: (layer) => layer === this.regionLayer,
    })

    const coordinate = this.getMap().getCoordinateFromPixel(event.pixel)
    if (features.length < 1 || !extend.containsCoordinate(features[0].getGeometry()?.getExtent() ?? [], coordinate)) {
      this.cleanHoverRegion()
      return
    }

    const id = features[0].getId()?.toString()

    if (this.props.onSelectAgsRefResLoc && id !== this.props.agsRefResLoc) {
      if (id !== undefined && id != this.state.hoverRegion) {
        this.setState(
          {
            hoverRegion: id,
            hoverRegionName: `${features[0].getProperties()["BEZ"]} ${features[0].getProperties()["GEN"]}`,
          },
          () => {
            if (this.overlay?.getRendering()) this.getMap().once("rendercomplete", () => this.regionLayer?.changed())
            else this.regionLayer?.changed()
          }
        )
      }
    } else {
      this.cleanHoverRegion()
    }
  }

  @bind
  private isClickableLayers(layer: Layer<Source>): boolean {
    if (layer.getClassName().includes("cluster-layer")) {
      return true
    } else {
      return layer === this.regionLayer
    }
  }

  // why does this make new stuff show on map?
  @bind
  private onMapClick(event: ol.MapBrowserEvent<any>) {
    const features = this.getMap().getFeaturesAtPixel(event.pixel, {
      layerFilter: (layer) => this.isClickableLayers(layer),
    })

    if (features.length > 0 && features[0].getProperties().features?.[0]?.id_.includes("category:")) {
      this.overlay?.closeCurrentOverlay(true)
      const featuresArray: ol.Feature[] = features[0].getProperties().features
      const geometry = features[0].getProperties().geometry

      const categoryId = featuresArray[0].getId()?.toString().split(":")[1] ?? ""
      const poiIdsToShow: string[] = featuresArray.map((feature) => feature.getId()?.toString().split(":")[2] ?? "")
      const poisToShow: PrivatePOIList = (
        this.props.privateDataSettings.multipleCategoryPOIList[categoryId] ?? []
      ).filter((poi) => poiIdsToShow.includes(poi.id))
      const position = geometry.getCoordinates()
      this.setState({ ...this.state, poiPopUpPosition: position })
      this.props.updatePrivatePoisToShow(poisToShow, AllowedModulesEnum.LOCATION_SELECTOR)
    } else {
      if (this.state.poiPopUpPosition) this.onPoiPopUpClose()

      const coordinate = this.getMap().getCoordinateFromPixel(event.pixel)

      if (features.length < 1 || !extend.containsCoordinate(features[0].getGeometry()?.getExtent() ?? [], coordinate)) {
        return
      }

      const id = features[0].getId()?.toString()

      if (id !== undefined && id !== this.props.agsRefResLoc) {
        this.overlay?.closeCurrentOverlay(true)
        this.props.onSelectAgsRefResLoc?.call(null, id)
        this.cleanHoverRegion()
      }
    }
  }

  @bind
  private cleanHoverRegion() {
    if (this.state.hoverRegion) {
      this.setState(
        {
          hoverRegion: null,
          hoverRegionName: null,
        },
        () => {
          if (this.overlay?.getRendering()) this.getMap().once("rendercomplete", () => this.regionLayer?.changed())
          else this.regionLayer?.changed()
        }
      )
    }
  }

  @bind
  private regionStyle(feature: ol.Feature<Geometry> | RenderFeature): style.Style {
    if (feature.getId() === this.props.agsRefResLoc) {
      return new style.Style({
        stroke: new style.Stroke({
          color: getThemeColor("secondary3", "default").toString(),
          width: 2,
        }),
        fill: new style.Fill({
          color: "rgba(1,0,0,0)", // transparent, but essential for layer.getFeatures to work
        }),
      })
    } else if (this.state.hoverRegion && feature.getId() === this.state.hoverRegion) {
      return new style.Style({
        fill: new style.Fill({
          color: "rgba(1,1,1,0.4)",
        }),
      })
    }
    return new style.Style({
      fill: new style.Fill({
        color: "rgba(1,0,0,0)", // transparent, but essential for layer.getFeatures to work
      }),
    })
  }

  @bind
  private microStyle(): StyleFunction {
    return (feature) => {
      const macroItem = this.props.microItems.get(feature.getId()?.toString() ?? "")
      if (!macroItem) return new style.Style()
      const selected = this.state.selectMicroId === feature.getId()?.toString()
      const currZoom = this.getMap().getView().getZoom() ?? 0

      return new style.Style({
        stroke:
          (this.props.showStroke && currZoom > 11.5) || selected
            ? new style.Stroke({
                color: selected ? "rgba(255,0,0,0.8)" : "rgba(0,0,255,0.8)",
                width: selected ? 2.0 : 0.5,
              })
            : undefined,
        fill: new style.Fill({
          color: macroItem.color,
        }),
      })
    }
  }

  @bind
  private getOverlayAt(pixel: Pixel) {
    return this.overlayFromFeatures(
      this.getMap().getFeaturesAtPixel(pixel, { layerFilter: (layer) => layer == this.microLayer })
    )
  }

  @bind
  private overlayFromFeatures(features: FeatureLike[]): { id: string; content: JSX.Element } | null {
    if (features.length < 1) return null

    const id = features[0].getId()?.toString()
    const item = this.props.microItems.get(features[0].getId()?.toString() ?? "")

    if (!item || id === undefined) return null

    const additionalItems: PopupFact[] = []

    if (typeof item.grade === "string") {
      // TODO: does this rating grade label need to be here?
      additionalItems.push({ label: this.t.ratingGradeLabel, value: item.grade })
    }

    if (typeof item.score === "number") {
      additionalItems.push({ label: this.t.profileScoreTitle, value: formatNumber(item.score, 0) })
    }

    if (additionalItems.length === 0) {
      additionalItems.push({ label: this.t.profileScoreTitle, value: "-" })
    }

    const [mainFact, ...secondaryFacts] = additionalItems

    return {
      id,
      content: (
        <MapPopup
          header={item.title}
          mainFact={mainFact}
          secondaryFacts={secondaryFacts}
          closeCallback={() => this.overlay?.closeCurrentOverlay(true)}
        />
      ),
    }
  }

  @bind
  private onSelection(id: string | null) {
    this.setState({ selectMicroId: id }, () => this.microLayer?.changed())
  }

  @bind
  private async resetMap() {
    try {
      const viewport = await fetchAgsResLocViewport(this.props.agsRefResLoc)
      this.getMap() && this.getMap().getView().fit(transformExtent(viewport, "EPSG:4326", "EPSG:3857"))
      this.overlay?.closeCurrentOverlay(true)
    } catch (e) {
      console.log("Error fetching the AgsResLocViewport", e)
    }
  }

  @bind private setClusterLayerArray(newClusterArrayLayer: layer.Vector<any>[]) {
    this.clusterLayerArray = [...newClusterArrayLayer]
  }

  @bind
  private updatePoiPopUp() {
    if (this.props.isPrivateDataAccessible) {
      if (this.props.privateDataSettings.poisToShow.length > 0 && this.state.poiPopUpPosition) {
        this.poiPopUp?.openOverlayAt(this.state.poiPopUpPosition, this.props.privateDataSettings.poisToShow)
      } else if (this.poiPopUp) {
        this.poiPopUp.closeCurrentOverlay()
      }
    } else if (this.poiPopUp) {
      this.poiPopUp.closeCurrentOverlay()
    }
  }

  @bind onPoiPopUpClose() {
    this.poiPopUp?.closeCurrentOverlay()
    this.props.updatePrivatePoisToShow([], AllowedModulesEnum.LOCATION_SELECTOR)
    this.setState({ ...this.state, poiPopUpPosition: undefined })
  }

  @bind
  private poiPopUpContentCreator(location: number[], poiList: PrivatePOIList): JSXContent {
    const props = {
      lat: location[1],
      lng: location[0],
      poisToShow: poiList,
      onClose: this.onPoiPopUpClose,
    }
    return {
      content: <PoiMarkerDialog {...props} entryPinLocation={undefined} />,
    }
  }
}
