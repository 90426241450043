import { AssessmentEntryFull } from "../../../assessment/models/assessment"
import React from "react"
import { translations } from "../../../assessment/i18n"
import Panel from "../panel"

export const InvalidAddressMessage = ({
  assessmentEntry,
}: {
  assessmentEntry: AssessmentEntryFull | null
}): JSX.Element | null => {
  const t = React.useMemo(translations, [translations])

  if (assessmentEntry?.state === "failure")
    return (
      <div style={{ height: "min-content", padding: "8px" }}>
        <Panel color="neutral" title={t.assessment.invalidAddressInfo.title}>
          {t.assessment.invalidAddressInfo.message}
        </Panel>
      </div>
    )

  return null
}
