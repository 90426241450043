import * as React from "react"
import { css } from "emotion"
import { PropsWithChildren } from "react"

const centeredElementOuterClass = css({
  backgroundImage: "url(https://assets.21real.estate/background.png)",
  backgroundSize: "cover",
  flexGrow: 1,
  position: "relative",
})

function centeredElementInnerClass(x: [number, "%"], y: [number, "%"]) {
  return css({
    position: "absolute",
    left: x.join(""),
    top: y.join(""),
    transform: "translate(-50%, -50%)",
  })
}

export interface CenteredElementProps {
  x?: [number, "%"]
  y?: [number, "%"]
}

export const CenteredElement: React.FunctionComponent<PropsWithChildren<CenteredElementProps>> = (props) => (
  <div className={centeredElementOuterClass}>
    <div className={centeredElementInnerClass(props.x ? props.x : [50, "%"], props.y ? props.y : [50, "%"])}>
      {props.children}
    </div>
  </div>
)

export default CenteredElement
