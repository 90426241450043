import { SharedTranslations } from "./translations"
import { DataSetType } from "../models/smartdata"
import { HasTranslations } from "../smartdata-products/smartdata"

export const SHARED_DE: SharedTranslations = {
  pickTranslation: (t: HasTranslations) => t["de"],
  apply: "Anwenden",
  dateFormat: "DD.MM.YYYY",
  of100: "/100",
  ok: "OK",
  yes: "Ja",
  no: "Nein",
  edit: "Bearbeiten",
  editing: "In Bearbeitung",
  duplicate: "Duplizieren",
  cancel: "Abbrechen",
  default: "Standard",
  delete: "Löschen",
  done: "Fertig",
  save: "Speichern",
  saveAs: "Speichern als",
  download: "Download",
  lock: "Lock",
  unlock: "Unlock",
  loadingScores: "Lade Scores...",
  loadingChart: "Inhalt wird geladen",
  scores: "Scores",
  scoreDescription: "Beschreibung",
  scoreSource: "Quelle",
  price: "Preis",
  pricePerSqm: "Preis pro m²",

  privatePOICategories: "Private POIs",
  map: {
    distanceTitle: "Fahrzeit",
    distanceDataHint: "Die Isochrone und ihre Berechnung stammen vom Drittanbieter HERE.",
    poiTitle: "Points of interest",
    mapStyleTitle: "Mapstyle",
    mapStyleName: {
      default: "Standard",
      pastel: "Pastel",
      hybrid: "Hybrid",
      positron: "Positron",
      topo: "Topo",
    },
    considerTraffic: "Verkehr berücksichtigen",
    transitTimeLabel: "Fahrdauer",
    vehicleLabel: "Fahrzeug",
    vehicle: {
      none: "Keine",
      car: "PKW",
      truck: "LKW",
      pedestrian: "Fußgänger",
    },
    transitTimes: {
      "300": "5 Minuten",
      "900": "15 Minuten",
      "1800": "30 Minuten",
      "3600": "1 Stunde",
      "7200": "2 Stunden",
    },
    poiCategory: {
      "atm-bank-exchange": "Geldautomat/Bank",
      "petrol-station": "Tankstelle",
      "public-transport": "ÖPNV",
      "taxi-stand": "Taxi Stellplatz",
      shopping: "Shopping",
      restaurant: "Restaurant",
      hotel: "Hotel",
    },
    poiZoomHint:
      "Angezeigt werden je nach Zoomlevel für jede ausgewählte Kategorie die bis zu 100 wichtigsten POIs aus der direkten Umgebung.",
    poiDataHint: "Diese Daten stammen vom Drittanbieter HERE.\nAlle Entfernungsangaben in Luftlinie.",
    poiMapPopup: {
      category: "Kategorie",
      address: "Adresse",
      openingHours: "Öffnungszeiten",
      distance: "Entfernung (Luftlinie)",
    },
    switchToRegion: "Wechseln zu",
    landvalues: {
      title: "Verfügbare Sonderkarten",
      infoText: "Die Sonderkarten erscheinen erst ab einem bestimmten Zoomlevel.",
      detailsUnavailable: "An dieser Position sind keine zusätzlichen Werte verfügbar.",
      availableLayers: "An Position verfügbar",
      unavailableLayers: "An Position nicht verfügbar",
      noSelection: "<keine>",
      specialMapsTitle: "Sonderkarten",
      newSpecialMapsHint:
        "Unser neues Modul für Sonderkarten bietet eine größere Karte, mehr Übersichtlichkeit, verschiebbare Fenster für Legende und Zusatzinfos sowie weiteres Kartenmaterial.",
      toNewSpecialMaps: "Neue Sonderkarten öffnen",
    },
    uninhabitedCell: "Unbewohnte Kachel ~ Näherungswerte",
    mapShapeOutdatedMessage:
      'Es gibt neue Daten für die Karte. Klicken Sie auf "Neu berechnen", um die neuesten Ergebnisse zu sehen.',
  },
  poiExplorer: {
    title: "POI Explorer",
    tabs: {
      poi: "Points of interest",
      isochrone: "Isochrone",
      summary: "Summary",
    },
    poiManualSelection: "Manuelle Auswahl",
    isolineShowOnMap: "Auf Karte anzeigen und in Summary verwenden",
    poiOnlyNearest: "Nur nächste POIs",
    poiStrictFilter: "Primärkategorien filtern",
    vehicleLabel: "Typ",
    transitTimeLabel: "Fahrdauer",
    transitTimeUnit: "min",
    accessibility: "Nächste Erreichbarkeit",
    distances: {
      nearestAirport: "Int. Flughafen",
      nearestBusStop: "Bushaltestelle",
      nearestSubwayStation: "U-Bahn Station",
    },
    summaryCount: "Anzahl",
    summaryNearest: "Nächste",
    countTotalLabel: "Points of interest",
    countInsideLabel: "Points of interest innerhalb Isochrone",
    manualSelection: "Manuelle Auswahl",
    createGroup: "Gruppe erstellen",
    newPOIGroupNameLabel: "Bitte vergeben Sie einen eindeutigen Namen",
    editGroup: "Gruppe bearbeiten",
    deleteGroup: "Gruppe löschen",
    name: "Name",
    categories: "Kategorien",
    deleteConfirmHeader: "Kategorien-Gruppe löschen",
    deleteConfirmText: (name: string) => `Wollen Sie die Gruppe '${name}' wirklich löschen?`,
    onlyWithinIsochrone: "Nur innerhalb der Isochrone",
    onlyPrimaryCategories: "Nur Primärkategorien",
    infoText:
      "Angezeigt werden für jede Kategorie bis zu 60 POIs aus der direkten Umgebung. Diese Daten stammen vom Drittanbieter Google. Alle Entfernungsangaben in Luftlinie.",
    busAndTransit: "Bus & Transit",
    error: "Oops, ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch mal.",
    newPOIExplorerHint:
      "Der neue POI Explorer bietet eine größere Karte, mehr Übersichtlichkeit und die Möglichkeit, Kategorien in Gruppen zu verwalten.",
    toNewPOIExplorer: "Neuen POI Explorer öffnen",
    poiQuickSearch: "POI Schnellsuche",
  },
  smartdataSourceTranslation: (source: DataSetType) => {
    switch (source) {
      case "micro":
        return "Mikro"
      case "macro":
        return "Makro"
    }
  },
  scoreKey: {
    macro: "Makro",
    micro: "Mikro",
  },
  scoreKeyLong: {
    macro: "Makro (Gemeinde)",
    micro: "Mikro (Kachel / Nachbarschaft)",
  },
  metaScoreKey: "Meta",
  microScores: "Mikro Scores",
  macroScores: "Makro Scores",
  macroRating: "Makro Rating",
  microRating: "Mikro Rating",
  loadMacroDataTitle: "Startpunkt",
  loadMicroDataTitle: "Startpunkt",
  selected: "Ausgewählt",
  ratingGrades: "Mögliche Noten",
  ratingGrade: "Note",
  firstProfile: "Legen Sie ein Profil an um eigene Scores zu erstellen.",
  sliderDescription: "Deckkraft",
  activeProfile: "Aktiv",
  inverted: "Invertierter Score",
  profileAlreadyExists: "Ein Profil mit diesem Namen existiert bereits.",
  groupAlreadyExists: "Eine Gruppe mit diesem Namen existiert bereits",
  profileScoreTitle: "Score",
  profilePopupScoreTitle: "Profil Score",
  rawValue: "WERT",
  scoreValue: "SCORE",
  weight: "GEWICHT",
  description: "BESCHREIBUNG",
  saveChangesAlertBoxTitle: "Änderungen speichern?",
  saveChangesErrorBoxTitle: "Speichern fehlgeschlagen",
  saveChangesErrorCyclic: "Zyklische Abhängigkeit zwischen den Profilen gefunden",
  saveChanges: "Änderungen speichern",
  withdrawChanges: "Änderungen verwerfen",
  alertBoxContent: "Das Profil wurde verändert. Wollen Sie die Änderungen speichern?",
  scoreComposedOf: "Zusammengesetzt aus:",
  queryViewportTooLarge: "Zu weit herausgezoomt um Scores darzustellen.",
  loadingPOIs: "Lade POIs...",
  loadingRatings: "Lade Ratings...",

  country: {
    de: "Deutschland",
  },
  prefixWithProfile: (profileName: string) => `Profil: ${profileName}`,
  helpTitle: "Hilfe",
  graphAverage: "Median",
  newLabel: "Neu",
  feedback: "Feedback",
  helpAndSupport: "Hilfe & Support",
  releaseIndicator: {
    title: "Neue Funktionen",
    mainText: "Wir haben neue Funktionen für Sie veröffentlicht.",
    button: "Mehr lesen",
  },
  header: {
    section: {
      locationAssessment: "Assessments",
      profileManager: "Profile Manager",
      quickstart: "Quickstart",
      locationExplorer: "Location Explorer",
      privateData: "Private data",
      locationSelector: "Location Selector",
      ratingManager: "Rating Manager",
    },
    module: {
      dashboard: "Bewertungsübersicht",
      assessment: "Scores",
      ratings: "Ratings",
      prices: "Preise",
      comparables: "Comparables",
      marketdata: "Quartiersdaten",
      poiExplorer: "POI - Explorer",
      specialMaps: "Sonderkarten",
      rentindex: "Mietspiegel",
      fundamentalData: "Basisdaten",
    },
    profile: {
      logout: "Logout",
      myProfile: "Meine Profile",
      profileSettings: "Profil-Einstellungen",
      securitySettings: "Sicherheitseinstellungen",
    },
    admin: {
      title: "Verwaltung",
      submenus: {
        companySettings: "Firmeneinstellungen",
        apiAdmin: "API Tokens verwalten",
        companyUsers: "Benutzerverwaltung",
        products: "Produktnutzung",
      },
    },
    usageType: {
      office: "Büro",
      retail: "Einzelhandel",
      residential: "Wohnen",
      hall: "Logistik",
      commercial: "Private/Gewerblich",
      plot: "Grundstück",
    },
    priceType: {
      rent: "Miete",
      sale: "Kauf",
    },
    houseType: {
      other: "Andere",
      singleDetached: "Einfamilienhaus (freistehend)",
      multiple: "Mehrfamilienhaus",
      semiDetached: "Doppelhaushälfte",
      corner: "Reiheneckhaus",
      middle: "Reihenmittelhaus",
    },
    priceCategories: {
      apartment: "Wohnung",
      house: "Haus",
    },
    recalculate: "Neu berechnen",
    recalculateHint: "Die angezeigten Werte sind nicht mehr aktuell und sollten neu berechnet werden.",
    stateStatus: {
      created: "Bewertung erstellen",
      geocoded: "Geocoding",
      "geocoded-ambiguous": "Geocoding fehler",
      smartdata: "Datenverarbeitung",
      "smartdata-ambiguous": "Datenverarbeitung fehler",
      rated: "Verarbeitung von Bewertungen",
      "rated-ambiguous": "Fehler bei der Rating-Verarbeitung",
      "object-prices": "Preise Verarbeitung",
      "object-prices-ambiguous": "Fehler bei der Preisverarbeitung",
      scored: "Scores Verarbeitung",
      "scored-ambiguous": "Fehler bei der Scores Verarbeitung",
      failure: "Fehler",
    },
    downloadDataTooltip: "Daten herunterladen",
    downloadPDFTooltip: "Download Dashboard (PDF)",
    notesTooltip: "Notizen",
    multiAddressRenameTooltip: "Portfolio umbenennen",
    changeAddressOrTitleTooltip: "Adresse oder Titel ändern",
    newBldg: "Neubau",
    pois: "POIs",
    changeAssessmentEntryDetails: "Angaben zur Einheit ändern",
    aiTextGeneration: "KI Text Generierung",
    aiTextGenerationLabel: "KI Text",
    backToAssessmentTooltip: "Zurück zur Assessment Liste",
    editDashboardConfigurationTooltip: "Dashboards & Widgets verwalten",
    allAsessments: "Adressübersicht",
    newAssessment: "Neue Adresse",
  },
  legal: {
    title: "Rechtliche Hinweise",
    imprint: "Impressum",
    privacy: "Datenschutz",
    tac: "AGB",
  },
  scoreSearch: {
    filter: "Filter",
    groups: "Gruppen",
    expandActive: "Aktive Gruppen ausklappen",
    expandAll: "Alle Gruppen ausklappen",
    collapseAll: "Alle Gruppen einklappen",
    unselectAll: "Alles abwählen",
    info: "Über diese Eingabe lassen sich passende Scores filtern. Dabei werden Namen und Beschreibung durchsucht.",
    otherProfilesCategory: "Profile",
    to: "Bis",
    from: "Von",
  },
  districtDataSearchInfo:
    "Über diese Eingabe lassen sich passende Einträge filtern. Durchsucht werden dabei Namen und Beschreibung.",
  isochrone: "Isochrone",
  notesPlaceholder: "Geben Sie Ihre Notizen ein",
  notesSavingError:
    "Ihre Änderungen können nicht gespeichert werden, weil dadurch die Änderungen eines anderen Anwenders überschrieben würden. Bitte schließen Sie diesen Dialog und nehmen Sie die Änderungen erneut vor.",
  label: "Bezeichnung",
  color: "Farbe",
  privatePois: "Private POIs",
  noPrivatePoisFound: "Keine 'Private POIs' gefunden",
  noTitle: "Ohne Titel",
  addressChangedTooltip: {
    notificationTitle:
      "Die ursprüngliche Adresse war nicht eindeutig und wurde von unserem Geocoding Service korrigiert.",
    correctedAddress: "Korrigiert",
    originalAddress: "Original",
    removed: "Entfernt",
  },
  addressColumn: {
    postalCode: "PLZ",
    locality: "Stadt",
    route: "Straße",
    streetNumber: "Nr",
  },
  textGeneration: {
    header: "KI Text Generierung: Stadtbeschreibung",
    descriptionTitle: "KI generierte Stadtbeschreibung",
    generateText: "Text generieren",
    clear: "Löschen",
    copy: "Kopieren",
    short: "Kurz",
    medium: "Mittel",
    long: "Lang",
    newFeatureDescription: `Lassen Sie sich von ChatGPT einen Text über die Stadt Ihres Assessments schreiben, den Sie in Reports, Memoranden etc. verwenden können!
    Beachten Sie bitte, dass es sich hierbei um ein experimentelles Feature handelt. Sie sollten den erzeugten Text unbedingt vor der Verwendung prüfen.
    Geben Sie uns gerne Feedback, wie sich dieses Feature verbessern lässt.`,
    english: "Englisch",
    german: "Deutsch",
    language: "Sprache",
    length: "Länge",
  },
  documentationAndSupport: {
    openDocumentation: "Hilfe öffnen",
    contactSupport: "Support kontaktieren",
  },
  validationTranslations: {
    constructionYearValidation: "Gültiges Jahr erforderlich",
    textTooLong: (maxAllowed?: number) => `Bitte geben Sie maximal ${maxAllowed} Zeichen ein.`,
    textTooShort: (minRequired?: number) => `Bitte geben Sie mindestens ${minRequired} Zeichen ein.`,
    date: {
      formatMismatch: (format: string) => `Das angegebene Datum entspricht nicht dem vorgegebenem Format (${format}).`,
    },
    notANumber: "Bitte geben Sie eine Zahl ein.",
    required: "Bitte geben Sie einen Wert ein.",
    min: (min: number) => `Ihre Zahl ist zu klein (erwartet ≥ ${min}).`,
    max: (max: number) => `Ihre Zahl ist zu groß (erwartet ≤ ${max}).`,
    moreThan: (moreThan: number) => `Ihre Zahl ist zu klein (erwartet > ${moreThan}).`,
    integer: "Bitte geben Sie eine Ganzzahl ein.",
  },
  primitive: {
    thousandSeparator: ".",
    decimalSeparator: ",",
  },
  numberSuffix: {
    billion: "Mrd",
    million: "Mio",
  },
  error: {
    confirmButton: "Schließen",
    defaultTitle: "Fehler",
    defaultHint: "Aufgrund eines unerwarteten Fehlers konnte Ihre Anfrage leider nicht verarbeitet werden. ",
    serverError: "Bitte versuchen Sie es später erneut.",
    networkError:
      "Es bestehen Verbindungsprobleme, bitte überprüfen Sie die Netzwerkverbindung und versuchen es später erneut.",
    messageWithFlowId: (flowId: string) =>
      `Wir haben diesen Fehler prokolliert und kümmern uns um eine Lösung. Für eventuelle Rückfragen geben Sie bitte die Fehlerreferenz ${flowId} mit an.`,
  },
  breadcrumbTitle: "Sie befinden sich hier",
  revoHeader: {
    backButton: "Zurück",
  },
  yearOfConstructionAbbreviation: "BJ",
}
