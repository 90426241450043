import React, { useEffect } from "react"
import { FilterProps } from "../comparablesV2"
import { HistoricalTransactionInputForm } from "./historical-transaction-filters-model"
import { fetchComparables } from "../../../reducers/comparables-slice"

export const HistoricalTransactionFilters = ({ assessmentEntry }: FilterProps) => {
  const transactionalDatasourceFormData: HistoricalTransactionInputForm = {
    dataSource: "historical-21st-transaction",
  }
  const onSubmit = (data: HistoricalTransactionInputForm) => {
    if (assessmentEntry && assessmentEntry.address.location) {
      void fetchComparables(data, assessmentEntry.address.location)
    }
  }

  useEffect(() => {
    onSubmit(transactionalDatasourceFormData)
  }, [])

  useEffect(() => {
    onSubmit(transactionalDatasourceFormData)
  }, [assessmentEntry, assessmentEntry?.address.location])

  return <>{/*Historical Offers - No Filters so far*/}</>
}
