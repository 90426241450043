import React from "react"
import { connect } from "react-redux"
import { GlobalState } from "../../../relas/store"
import {
  fetchCategories,
  fetchCategoryPois,
  updatePOIsToShow,
  updateSelectedCategories,
} from "../../../private-data/reducers/private-data-slice"
import NewAssessmentConfiguration from "./new-assessment-configuration"
import Grid from "../../../shared/components/restyle-grid/grid"

const mapStateToProps = (state: GlobalState) => ({
  lanaScopes: state.user.scopes,
  isPrivateDataAccessible: state.user.scopes.privateData,
  privatePoiCategories: state.privateData.privatePOICategories,
  privateDataSettings: state.privateData.modulesWithPrivateData.quickStart,
})

type Props = ReturnType<typeof mapStateToProps>

export const quickstartDocumentationURL =
  "https://docs.google.com/document/d/1SRUEbNmntn55x7olD1AmpS6tC4FwQnNCnTjo8OZjw64"

const QuickStartImpl = (props: Props) => {
  const getPrivateDataCategories = fetchCategories
  const updateSelectedPrivateDataCategories = updateSelectedCategories
  const getPrivatePoisFromCategories = fetchCategoryPois
  const updatePrivatePoisToShow = updatePOIsToShow

  return (
    <Grid
      columns={1}
      height={[100, "%"]}
      rowSpec={"fit-content(100%) minmax(0, 1fr)"}
      backgroundColor={{ color: "background", colorType: "default" }}
    >
      <NewAssessmentConfiguration
        lanaScopes={props.lanaScopes}
        isPrivateDataAccessible={props.isPrivateDataAccessible}
        privatePoiCategories={props.privatePoiCategories}
        privateDataSettings={props.privateDataSettings}
        getPrivateDataCategories={getPrivateDataCategories}
        updateSelectedPrivateDataCategories={updateSelectedPrivateDataCategories}
        getPrivatePoisFromCategories={getPrivatePoisFromCategories}
        updatePrivatePoisToShow={updatePrivatePoisToShow}
      />
    </Grid>
  )
}

export const QuickStart = connect(mapStateToProps)(QuickStartImpl)
