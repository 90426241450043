import * as React from "react"
import { FC, useMemo } from "react"
import { css } from "emotion"
import { Tooltip } from "../../shared/components/ui/tooltip"
import { ScoreBar } from "./assessment-entry-score-bar"
import { Flex } from "../../shared/components/ui/flex"
import { assertUnreachable } from "../../utils/utils"
import { translations } from "../../shared/i18n"
import Grid from "../../shared/components/restyle-grid/grid"
import GridItem from "../../shared/components/restyle-grid/griditem"
import Toggle from "../../shared/components/toggle"
import Icon from "../../shared/components/icon"

const titleClass = css({
  fontSize: "12px",
})

export interface ScoreTooltip {
  tag: "score"
  description: string
  source: string
  scoreDescription: string
  scoreSource: string
}

export interface ProfileTooltip {
  tag: "profile"
}

export const profileTooltip: ProfileTooltip = { tag: "profile" }

export type ScoreProfileTooltip = ScoreTooltip | ProfileTooltip

interface Props {
  name: string
  title: string
  scoreProfileTooltip: ScoreProfileTooltip
  value?: number
  isSelected: boolean
  onToggleChange: (isChecked: boolean, name: string) => void
  inverted?: boolean
}

export const ScoreView: FC<Props> = ({
  name,
  title,
  scoreProfileTooltip,
  value,
  onToggleChange,
  isSelected,
  inverted,
}) => {
  const t = useMemo(translations, [translations])

  const columns = value !== undefined ? 1 : 2
  const topColSpec = value !== undefined ? "1fr" : "1fr min-content"

  const tooltip = () => {
    switch (scoreProfileTooltip.tag) {
      case "score":
        return (
          <Tooltip
            placement="right"
            tooltip={
              <div style={{ maxWidth: "400px" }}>
                <b>{scoreProfileTooltip.scoreDescription}</b>
                <span>: {scoreProfileTooltip.description}</span>
                <br />
                <b>{scoreProfileTooltip.scoreSource}</b>
                <span>: {scoreProfileTooltip.source}</span>
              </div>
            }
          >
            <Icon name="info" fontSize={14} />
          </Tooltip>
        )
      case "profile":
        return <Icon name="restructure" fontSize={14} />
      default:
        return assertUnreachable(scoreProfileTooltip)
    }
  }

  return (
    <Grid columnSpec={topColSpec} columns={columns} key={name}>
      <Flex key={name} flexDirection="row" gap={4} alignItems={"center"}>
        {inverted && (
          <Tooltip placement="top" tooltip={t.inverted}>
            <img
              style={{ paddingRight: "8px", width: "22px", height: "20px", alignSelf: "baseline" }}
              alt="inverted"
              src={"/assets/icon_inverted.svg"}
            />
          </Tooltip>
        )}
        <div className={titleClass}>{title}</div>
        {tooltip()}
      </Flex>
      <Grid columnSpec="1fr 1.5em min-content" padding={[4, 0]} gap={8}>
        {value !== undefined && (
          <>
            <ScoreBar score={Math.round(value)} inverted={inverted} />
            <GridItem alignSelf="center">
              <div>{Math.round(value)}</div>
            </GridItem>
          </>
        )}
        <GridItem alignSelf="center">
          <Toggle small checked={isSelected} onChange={(isChecked) => onToggleChange(isChecked, name)} />
        </GridItem>
      </Grid>
    </Grid>
  )
}
