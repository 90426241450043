import React, { ReactNode, useEffect, useMemo, useState } from "react"
import {
  Assessment,
  AssessmentEntryFull,
  AssessmentState,
  isInProgressState,
  UsageType,
} from "../../assessment/models/assessment"
import { translations } from "../i18n"
import { useAppSelector } from "../../relas/store"
import { css, cx } from "emotion"
import { Text } from "@blueprintjs/core"
import { Tooltip } from "./ui/tooltip"
import useInterval from "../../utils/use-interval"
import Axios, { AxiosResponse } from "axios"
import { lanaApiUrl } from "../../app_config"
import { AssessmentEntryUpdate } from "../../assessment/components/assessment-entry-update"
import { AssessmentEntryNotes } from "./assessment-entry-notes"
import { formatAddressWithoutCountry } from "../../assessment/models/address"

import AssessmentEntryDetailEdit from "../../assessment/components/assessment-entry-detail-edit"
import { AppModules, Apps } from "../../menu/util/app-location-types"
import { loadAssessmentForAssessmentModule } from "../../assessment/reducers/comparables-slice"
import {
  fetchAssessmentList,
  loadAssessmentEntries,
  loadAssessmentEntry,
  loadAssessmentFirstEntry,
  refreshAssessmentEntries,
  resetAssessment,
  setAssessmentStatus,
  setNotesIcon,
} from "../../assessment/reducers/assessment-slice-functions"
import {
  LAST_SET_DASHBOARD_CONFIG_KEY,
  selectedUserAssessmentDashboard,
  stopDashboardEdit,
} from "../../relas/user-slice"
import { AITextGenerationOverlay } from "./ai-text-generation-overlay"
import {
  AssessmentDashboardId,
  AssessmentDynamicDashboardConfig,
  DashboardEvents,
} from "../../assessment/models/AssessmentDashboard"
import { DashboardSelectItem, MultiDashboardSelect } from "./multi-dashboard-select"
import { DeleteDashboardDialog } from "../../assessment/components/dashboard/delete-dashboard-dialog"
import { trackUsageEvent } from "../../utils/usage-tracking"
import { DashboardEditMode } from "../../assessment/components/dashboard/dashboard-widget-grid"
import Badge from "./badge"
import HorizontalDivider from "./horizontaldivider"
import Grid from "./restyle-grid/grid"
import Button from "./button"
import { getThemeColorVar } from "../helper/color"
import Icon from "./icon"

const styles = {
  headerItem: css({
    borderRight: `1px solid ${getThemeColorVar("border", "default")}`,
  }),
  headerItemGrid: css({
    display: "grid",
    gridTemplateColumns: "45px auto",
    gridTemplateRows: "40px auto",
    padding: "6px 0px 7px 2px",
    columnGap: "1px",
  }),
  headerItemNoBorder: css({
    borderRight: "none",
  }),
  title: css({
    fontWeight: "bold",
    fontSize: "18px",
    paddingTop: "6px",
  }),
  subTitle: css({
    fontSize: "14px",
  }),
  dashboardSubtitle: css({
    fontSize: "14px",
    paddingTop: "6px",
  }),
  link: css({
    cursor: "pointer",
  }),
  rotateButtonClass: css({
    "button i": {
      animation: "spin 4s linear infinite",
      animationDirection: "reverse",
    },
  }),
  headerActionsButtons: css({
    alignSelf: "center",
    alignItems: "center",
    justifySelf: "end",
    display: "flex",
    flexDirection: "row",
    flexFlow: "row-reverse",
    gap: "16px",
  }),
  arrowBackIcon: css({
    transform: "rotate(180deg)",
    alignSelf: "center",
    margin: "0 auto",
    fontSize: "16px",
    position: "relative",
    top: "2px",
  }),
  arrowForwardIcon: css({
    fontSize: "16px",
  }),
  selectContainer: css({
    "span > span > div > button": {
      width: "70% !important",
    },
  }),
}

type Props = {
  menuSection: Apps["locationAnalytics"]
  assessment: Assessment | null
  assessmentEntry: AssessmentEntryFull | null
  module: AppModules["locationAssessment"] | null
  recalculationButton?: boolean
  recalculationDetails?: boolean
  isLoading?: boolean
  children?: ReactNode
  printMode?: boolean
  editMode?: DashboardEditMode
  defaultDashboardWidgets?: () => AssessmentDynamicDashboardConfig
  displayDashboardId?: AssessmentDashboardId
}

const AppHeader = ({
  menuSection,
  assessment,
  assessmentEntry,
  module,
  recalculationButton,
  recalculationDetails,
  isLoading,
  children,
  printMode,
  defaultDashboardWidgets,
  displayDashboardId,
}: Props) => {
  const assessmentUpdateInProgress = useAppSelector((state) => state.assessment.assessmentUpdateInProgress)
  const scopes = useAppSelector((state) => state.user.scopes)
  const notesIcon = useAppSelector((state) => state.assessment.notesIcon)

  const [currentAssessmentEntryStatus, setCurrentAssessmentEntryStatus] = useState<AssessmentState | null>(null)
  const [recalculationButtonPressed, setRecalculationButtonPressed] = useState<boolean>(false)
  const [showChangeAddress, setShowChangeAddress] = useState<boolean>(false)
  const [showNotes, setShowNotes] = useState<boolean>(false)
  const [showAiTextGeneration, setShowAiTextGeneration] = useState<boolean>(false)
  const aiTextGenerationScopePresent = useAppSelector((state) => state.user.scopes.aiTextGeneration)

  const dashboardConfigurations = useAppSelector((state) => state.user.lana.assessmentDashboards)

  const selectedFromGlobalStateDashboardId = useAppSelector((state) => state.user.lana.selectedDashboardId)
  const selectedDashboardId = displayDashboardId || selectedFromGlobalStateDashboardId

  const selectedDashboardConfiguration = dashboardConfigurations?.find((d) => d.id === selectedDashboardId)
  const editDashboardConfiguration = useAppSelector((state) => state.user.lana.editingUserDashboards)
  const userId = useAppSelector((state) => state.user.user?.id)
  const [highlightedItem, setHighlightedItem] = useState<DashboardSelectItem>(undefined)
  const [dashboardAddMode, setDashboardAddMode] = useState<"add" | "duplicate" | undefined>(undefined)
  const currentPage = useAppSelector((state) => state.navigation.currentPage)
  const [dashboardToDelete, setDashboardToDelete] = useState<DashboardSelectItem>(undefined)

  const getNotes = async () => {
    if (assessment && assessmentEntry) {
      let success = await Axios.get(`${lanaApiUrl}/api/assessments/${assessment.id}/entries/${assessmentEntry.id}/note`)
      success.data.content.length > 0 ? setNotesIcon("note") : setNotesIcon("note_add")
    } else {
      setNotesIcon(null)
    }
  }

  const trackDashboardSelectAction = (action: DashboardEvents, dashboardName: string) => {
    if (assessmentEntry?.address) {
      trackUsageEvent(action, assessmentEntry.address, dashboardName)
    }
  }

  useEffect(() => {
    if (currentPage?.name !== "dashboard") {
      stopDashboardEdit()
    }
  }, [currentPage])

  useEffect(() => {
    getNotes().catch(() => {})
  }, [assessment && assessmentEntry])

  useEffect(() => {
    if (!showNotes) {
      getNotes().catch(() => {})
    }
  }, [showNotes])

  const t = useMemo(translations, [translations])

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)

  // null timeout will disable the callback

  useInterval(
    () => {
      if (
        assessment &&
        assessmentEntry &&
        assessmentEntry.state !== "scored" &&
        assessmentEntry.state !== "scored-ambiguous"
      ) {
        Axios.get<AssessmentState>(
          `${lanaApiUrl}/api/assessments/${assessment.id}/entries/${assessmentEntry.id}/state`
        ).then(
          async (success: AxiosResponse<AssessmentState>) => {
            setCurrentAssessmentEntryStatus(success.data.replace("-ambiguous", "") as AssessmentState)
            setAssessmentStatus(success.data)
            if (
              assessmentEntry?.state !== success.data &&
              (success.data === "scored" || success.data === "scored-ambiguous") &&
              assessment &&
              assessmentEntry?.id
            ) {
              // state was updated - refresh the page
              await loadAssessmentEntry({ ...assessment, state: success.data }, assessmentEntry.id)
            }
          },
          () => {}
        )
      }
    },
    !printMode &&
      assessment &&
      assessmentEntry &&
      assessmentEntry.state !== "scored" &&
      assessmentEntry.state !== "scored-ambiguous"
      ? 2000
      : null
  )

  useInterval(() => {
    if (currentAssessmentEntryStatus === "scored") setCurrentAssessmentEntryStatus(null)
  }, 4000)

  const onBackClick = () => {
    if (module)
      assessment && assessmentEntry && assessment.numberOfAddresses > 1
        ? void loadAssessmentEntries(assessment, module, scopes.explorer)
        : void fetchAssessmentList(module)
  }

  const backToAssessmentList = () => {
    if (module) void fetchAssessmentList(module)
  }

  const usageTypeLabel = (usageType: UsageType | undefined): string => {
    switch (usageType) {
      case UsageType.Office:
        return `${t.header.priceType.rent} - ${t.header.usageType.office}`
      case UsageType.Retail:
        return `${t.header.priceType.rent} - ${t.header.usageType.retail}`
      case UsageType.Hall:
        return `${t.header.priceType.rent} - ${t.header.usageType.hall}`
      case UsageType.ResidentialApartmentRent:
        return `${t.header.priceType.rent} - ${t.header.usageType.residential}`
      case UsageType.ResidentialApartmentSale:
        return `${t.header.priceType.sale} - ${t.header.usageType.residential}`
      case UsageType.ResidentialHouseRent:
        return `${t.header.priceType.rent} - ${t.header.usageType.residential}`
      case UsageType.ResidentialHouseSale:
        return `${t.header.priceType.sale} - ${t.header.usageType.residential}`
      default:
        return `${t.header.priceType.rent} - ${t.header.usageType.residential}`
    }
  }

  const houseOrApartment = (type: UsageType): string => {
    switch (type) {
      case UsageType.ResidentialHouseSale:
      case UsageType.ResidentialHouseRent:
        return `- ${t.header.priceCategories.house}`
      case UsageType.ResidentialApartmentSale:
      case UsageType.ResidentialApartmentRent:
        return `- ${t.header.priceCategories.apartment}`
      default:
        return ""
    }
  }

  const onRecalculate = () => {
    const currentAssessment = assessment

    if (!currentAssessment) return
    setRecalculationButtonPressed(true)
    resetAssessment(currentAssessment.id).then(
      (assessment) => {
        if (currentAssessment.numberOfAddresses == 1) {
          void (module === "dashboard"
            ? loadAssessmentForAssessmentModule(currentAssessment.id, assessmentEntry?.id ?? null, module)
            : loadAssessmentFirstEntry(assessment, false))
        } else {
          void refreshAssessmentEntries(currentAssessment.id, assessmentEntry?.id, module)
        }
      },
      () => {}
    )
  }

  const getColoursForBadge = (state: AssessmentState) => {
    switch (state) {
      case "failure":
        return "secondary3"
      case "scored":
        return "positive"
      default:
        return "neutral"
    }
  }

  const constructionYear = assessmentEntry?.newBuilding ? t.header.newBldg : `${assessmentEntry?.year || 2000}`

  const isAssessmentList = () => menuSection === "locationAssessment" && assessment === null

  const selectDashboard = (id: string) => {
    const selectedDashboard = dashboardConfigurations?.find((config) => config.id === id)

    if (selectedDashboard) {
      selectedUserAssessmentDashboard(selectedDashboard.id)
    }
  }

  const handleItemClick = (item: DashboardSelectItem) => {
    if (item && item.id !== selectedDashboardId) {
      userId && window.localStorage.setItem(LAST_SET_DASHBOARD_CONFIG_KEY(userId), item.id)
      selectDashboard(item.id)
      trackDashboardSelectAction("DASHBOARD_CHANGE_CURRENT", item.name)
    }
  }

  return (
    <Grid
      columns={4}
      columnSpec={`240px ${menuSection === "privateData" ? "240px" : "360px"} ${
        menuSection === "locationSelector" || menuSection === "ratingManager" || isAssessmentList() ? "" : "250px"
      } 1fr`}
      backgroundColor={{ color: "background", colorType: "lighter" }}
      padding={[0, 16, 0, 0]}
      height={[76, "px"]}
    >
      <div className={cx(styles.headerItem, styles.headerItemGrid, "app-header-container")}>
        <img src="/assets/app-header/app_location.svg" alt="Location" style={{ placeSelf: "center" }} />
        {!isLoading && (
          <>
            {assessmentEntry && (
              <>
                {module && <div className={styles.title}>{t.header.module[module]}</div>}
                {!printMode && module !== "dashboard" && (
                  <Tooltip placement={"bottom"} tooltip={t.header.backToAssessmentTooltip}>
                    <Grid padding={[0, 0, 0, 0]} columnSpec="45px auto">
                      <div
                        className={cx(styles.subTitle, styles.link, css({ display: "flex", gap: "8px" }))}
                        onClick={backToAssessmentList}
                      >
                        <div className={styles.arrowBackIcon}>&#10142;</div>
                      </div>
                      <div onClick={backToAssessmentList} className={styles.subTitle}>
                        {t.header.section[menuSection]}
                      </div>
                    </Grid>
                  </Tooltip>
                )}
                {module === "dashboard" && dashboardConfigurations && (
                  <>
                    <div style={{ display: "flex", justifyContent: "center", alignSelf: "center" }}>
                      {!printMode && (
                        <Tooltip placement={"bottom"} tooltip={t.header.backToAssessmentTooltip}>
                          <div
                            className={cx(styles.subTitle, styles.link, css({ display: "flex", gap: "8px" }))}
                            onClick={backToAssessmentList}
                          >
                            <div className={cx(styles.arrowBackIcon, css({ top: "1px" }))}>&#10142;</div>
                          </div>
                        </Tooltip>
                      )}
                    </div>
                    <Grid rowSpec={"1fr"} columnSpec={"3fr 1fr"} className="appHeaderMultiDashboardSelect">
                      {!printMode && (
                        <MultiDashboardSelect
                          dashboardConfigurations={dashboardConfigurations}
                          editDashboardConfiguration={editDashboardConfiguration}
                          selectedDashboardConfiguration={selectedDashboardConfiguration}
                          highlightedItem={highlightedItem}
                          setHighlightedItem={setHighlightedItem}
                          handleItemClick={handleItemClick}
                          stopEdit={stopDashboardEdit}
                          dashboardAddMode={dashboardAddMode}
                          setDashboardAddMode={setDashboardAddMode}
                          setDashboardToDelete={setDashboardToDelete}
                          defaultConfig={defaultDashboardWidgets}
                          trackDashboardSelectAction={trackDashboardSelectAction}
                        />
                      )}
                      {printMode && selectedDashboardConfiguration && (
                        <div style={{ marginTop: "6px" }}>{selectedDashboardConfiguration.name}</div>
                      )}
                      {editDashboardConfiguration && (
                        <div
                          style={{
                            display: "flex",
                            color: getThemeColorVar("primary", "default"),
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              borderRadius: "50%",
                              cursor: "pointer",
                              overflow: "hidden",
                              height: "21px",
                              width: "21px",
                            }}
                            onClick={stopDashboardEdit}
                          >
                            <Icon name={"checkbox_checked"} size={"large"} fontSize={22} />
                          </div>
                        </div>
                      )}
                    </Grid>
                  </>
                )}
              </>
            )}
            {!assessmentEntry && (
              <>
                <div
                  className={cx({ [styles.title]: true, [styles.link]: !!assessment })}
                  onClick={assessment ? backToAssessmentList : () => {}}
                >
                  {t.header.section[menuSection]}
                </div>
                {module && !printMode && (
                  <>
                    <div />
                    <div className={cx(styles.subTitle, css({ display: "flex", gap: "8px" }))}>
                      <div className={styles.arrowForwardIcon}>&#10142;</div>
                      <div>{t.header.module[module]}</div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>

      {assessment && (
        <div className={cx(styles.headerItem, styles.headerItemGrid)}>
          {showChangeAddress && module && (
            <AssessmentEntryUpdate
              assessment={assessment}
              assessmentEntry={assessmentEntry}
              onClose={() => setShowChangeAddress(false)}
              module={module}
            />
          )}
          {!isLoading && (
            <>
              <div style={{ justifySelf: "center", paddingTop: "2px" }}>
                <img
                  src={assessmentEntry ? "/assets/app-header/location.svg" : "/assets/app-header/header-list.svg"}
                  alt="Assessment"
                />
              </div>
              <Text
                ellipsize={true}
                className={
                  assessmentEntry && assessment.numberOfAddresses > 1 ? cx(styles.title, styles.link) : styles.title
                }
                onClick={assessmentEntry && assessment.numberOfAddresses > 1 ? onBackClick : () => {}}
              >
                {assessment?.title}
              </Text>
            </>
          )}
          {assessmentEntry && !isLoading && (
            <>
              <div style={{ justifySelf: "center" }}>
                {!printMode && (
                  <Tooltip placement="bottom" tooltip={t.header.changeAddressOrTitleTooltip}>
                    <a onClick={() => setShowChangeAddress(true)}>
                      <img
                        style={{ height: "15px", marginTop: "4px" }}
                        src="/assets/app-header/Edit_data.svg"
                        alt="edit-assessment-entry"
                      />
                    </a>
                  </Tooltip>
                )}
              </div>
              <Text className={styles.subTitle} ellipsize={true}>
                {formatAddressWithoutCountry(assessmentEntry.address)}
              </Text>
            </>
          )}
        </div>
      )}

      {menuSection === "privateData" && (
        <div className={cx(styles.headerItem, styles.headerItemGrid)}>
          <div style={{ justifySelf: "center", paddingTop: "2px" }}>
            {!isLoading && <img src={"/assets/app-header/location.svg"} alt="location" />}
          </div>
          {!isLoading && (
            <Text ellipsize={true} className={styles.title}>
              {t.header.pois}
            </Text>
          )}
        </div>
      )}

      {assessment && assessmentEntry && (
        <>
          <AssessmentEntryDetailEdit
            isOpen={isEditDialogOpen}
            toggle={() => {
              setIsEditDialogOpen(false)
            }}
          />
          <div className={cx(styles.headerItem, styles.headerItemGrid, printMode ? styles.headerItemNoBorder : null)}>
            {!isLoading && (
              <>
                <div style={{ justifySelf: "center", paddingTop: "2px" }}>
                  <img src="/assets/app-header/unit_information.svg" alt="Unit information" />
                </div>
                <Text className={styles.title} ellipsize={true}>
                  {usageTypeLabel(assessmentEntry.usageType)}
                </Text>
                <div style={{ justifySelf: "center" }}>
                  {!printMode && (
                    <Tooltip placement="bottom" tooltip={t.header.changeAssessmentEntryDetails}>
                      <a onClick={() => setIsEditDialogOpen(true)}>
                        <img
                          style={{ height: "15px", marginTop: "4px" }}
                          src="/assets/app-header/Edit_data.svg"
                          alt="edit-assessment-entry"
                        />
                      </a>
                    </Tooltip>
                  )}
                </div>

                <Text className={styles.subTitle} ellipsize={true}>
                  {`${constructionYear} - ${assessmentEntry.area ?? 100} m² ${houseOrApartment(
                    assessmentEntry.usageType ?? UsageType.ResidentialApartmentRent
                  )}`}
                </Text>
              </>
            )}
          </div>
        </>
      )}
      {(!assessment || !assessmentEntry) && <div></div>}
      {menuSection === "profileManager" && <div></div>}
      {menuSection === "quickstart" && <div></div>}
      {!printMode && (
        <div className={styles.headerActionsButtons}>
          {children}
          {notesIcon && (
            <>
              <HorizontalDivider height={32} />
              <Tooltip placement="bottom" tooltip={t.header.notesTooltip}>
                <div>
                  <a onClick={() => setShowNotes(true)}>
                    <Icon name={notesIcon} size={"large"} />
                  </a>
                </div>
              </Tooltip>
            </>
          )}
          {assessmentEntry && aiTextGenerationScopePresent && (
            <>
              <HorizontalDivider height={32} />
              <Tooltip placement="bottom" tooltip={t.header.aiTextGeneration}>
                <div>
                  <a onClick={() => setShowAiTextGeneration(true)}>
                    <Icon name="feedback" size={"large"} />
                  </a>
                </div>
              </Tooltip>
              <div onClick={() => setShowAiTextGeneration(true)} />
            </>
          )}
          <div style={{ alignSelf: "center" }}>
            {!isLoading && recalculationButton && (
              <div style={{ display: "flex", flexDirection: "row", paddingLeft: "12px" }}>
                {assessment &&
                  (assessment.outdated ||
                    assessmentUpdateInProgress ||
                    (assessmentEntry
                      ? isInProgressState(assessmentEntry.state)
                      : isInProgressState(assessment.state))) && (
                    <Tooltip placement="bottom" tooltip={t.header.recalculateHint}>
                      <div
                        className={
                          assessmentUpdateInProgress || isInProgressState(assessment.state)
                            ? styles.rotateButtonClass
                            : ""
                        }
                      >
                        <Button
                          type="primary"
                          size="normal"
                          icon="refresh"
                          danger
                          disabled={
                            assessmentUpdateInProgress ||
                            (assessmentEntry
                              ? isInProgressState(assessmentEntry.state)
                              : isInProgressState(assessment.state)) ||
                            recalculationButtonPressed
                          }
                          onClick={onRecalculate}
                        >
                          {t.header.recalculate}
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                {recalculationDetails && (
                  <div style={{ alignSelf: "center" }}>
                    {currentAssessmentEntryStatus && currentAssessmentEntryStatus !== "failure" && (
                      <Badge color={getColoursForBadge(currentAssessmentEntryStatus)}>
                        {t.header.stateStatus[currentAssessmentEntryStatus]}
                      </Badge>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {showNotes && assessment && assessmentEntry && (
        <AssessmentEntryNotes
          assessmentId={assessment?.id}
          assessmentEntry={assessmentEntry}
          onClose={() => setShowNotes(false)}
        />
      )}
      {showAiTextGeneration && assessment && assessmentEntry && (
        <AITextGenerationOverlay
          assessmentId={assessment.id}
          assessmentEntryId={assessmentEntry.id}
          assessmentEntryAddress={assessmentEntry.address}
          onClose={() => setShowAiTextGeneration(false)}
        />
      )}
      {dashboardConfigurations && dashboardToDelete && assessment && assessmentEntry && (
        <DeleteDashboardDialog
          dashboardToDelete={dashboardToDelete}
          dashboards={dashboardConfigurations}
          setDashboardToDelete={setDashboardToDelete}
          selectedDashboard={selectedDashboardConfiguration}
          setHighLighted={setHighlightedItem}
        />
      )}
    </Grid>
  )
}

export default AppHeader
