import * as React from "react"
import { ProfileEdit } from "./profile-edit"
import { ProfileList } from "./profile-list"
import { css } from "emotion"
import { ProfileNameEdit } from "./profile-name-edit"
import { IPanel, PanelStack } from "@blueprintjs/core"
import { ProfileAddGroupedScores } from "./profile-add-grouped-scores"
import { ProfilePane } from "../profile-slice"
import { useAppSelector } from "../../relas/store"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"

const plainWrapperClass = css({
  margin: "15px",
  "> div": {
    height: "100%",
  },
  "#profileList > div:nth-child(2)": {
    minHeight: 0,
  },
  "#settingsPanel > div:nth-child(2)": {
    minHeight: 0,
  },
})

export const ProfileMenu = () => {
  const profilePane = useAppSelector((state) => state.profile.profilePane)
  const currentProfileError = useAppSelector((state) => state.profile.profileGetError)

  const render = () => {
    if (currentProfileError) {
      return <GenericErrorPanel error={currentProfileError} />
    }

    const stack: IPanel<any>[] = [{ component: ProfileList }]

    switch (profilePane) {
      case ProfilePane.Add:
        stack.push({ component: ProfileNameEdit, props: { mode: "create" } })
        break
      case ProfilePane.Rename:
        stack.push({ component: ProfileNameEdit, props: { mode: "rename" } })
        break
      case ProfilePane.Duplicate:
        stack.push({ component: ProfileNameEdit, props: { mode: "duplicate" } })
        break
      case ProfilePane.AddGroup:
        stack.push({ component: ProfileNameEdit, props: { mode: "createGroup" } })
        break
      case ProfilePane.RenameGroup:
        stack.push({ component: ProfileNameEdit, props: { mode: "renameGroup" } })
        break
      case ProfilePane.Edit:
        stack.push({ component: ProfileEdit })
        break
      case ProfilePane.AddScores:
        stack.push({ component: ProfileEdit })
        stack.push({ component: ProfileAddGroupedScores })
        break
    }
    return (
      <div className={plainWrapperClass}>
        <PanelStack stack={stack} showPanelHeader={false} />
      </div>
    )
  }
  return render()
}
