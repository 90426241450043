import * as React from "react"
import { translations } from "../i18n"
import { CHILD_PROFILE_PREFIX } from "../models/profile"
import { BareCard } from "../../shared/components/ui/bare-card"
import { ExtendedTabBar } from "../../shared/components/ui/extended-tab-bar"
import { BorderBottom } from "../../shared/components/ui/border-bottom"
import { Flex } from "../../shared/components/ui/flex"
import { FlexItem } from "../../shared/components/ui/flex-item"
import { ScrollBox } from "../../shared/components/ui/scroll-box"
import { Switch } from "@blueprintjs/core"
import { formatFractionDigitsOptimized } from "../../utils/fractions"
import { ProfileDependencies } from "./profile-dependencies"
import { DataSetType } from "../../shared/models/smartdata"
import { NumericInputIcon } from "./numeric-input-icon"
import { NumericInputDrawer } from "./numeric-input-edit"
import { Scores } from "../../shared/models/scores"
import { LANA_AGS_NODES, LANA_CELL_NODES } from "../../shared/smartdata-products/smartdata"
import { usePrevious } from "../../utils/use-previous"
import { useDebounce } from "../../utils/use-debounce"
import { updateProfileScores, fetchProfiles, startAddProfileScores, updateView } from "../profile-slice"
import { useAppSelector } from "../../relas/store"
import { AlertBox } from "../../shared/components/alertbox"
import Slider from "../../shared/components/slider"
import Grid from "../../shared/components/restyle-grid/grid"
import GridItem from "../../shared/components/restyle-grid/griditem"
import LoadingSpinner from "../../shared/components/loadingspinner"
import Button from "../../shared/components/button"

export interface ScoreListItem {
  scoreName: string
  title: string
  weight: number
}

type ScoreWeightSource = { weights: Scores; source: DataSetType } | undefined

export const ProfileEdit = () => {
  const t = React.useMemo(translations, [translations])

  const profileList = useAppSelector((state) => state.profile.profileList)
  const currentProfileLoading = useAppSelector((state) => state.profile.profileGetInProgress)
  const currentProfile = useAppSelector((state) => state.profile.profile)
  const scores = useAppSelector((state) => state.profile.scores)
  const smartdataSource = useAppSelector((state) => state.profile.smartdataSource)
  const agsRefResLoc = useAppSelector((state) => state.profile.agsRefResLoc)

  const [intermediateScores, setIntermediateScores] = React.useState(scores)
  const [showNumericInputPane, setShowNumericInputPane] = React.useState(false)
  const [scoreToDelete, setScoreToDelete] = React.useState<string | undefined>(undefined)
  const [debouncedScoreWeights, scoreWeights, setScoreWeights] = useDebounce<ScoreWeightSource>(undefined, 500)
  const previousScores = usePrevious(scores)

  React.useEffect(() => {
    if (previousScores !== scores) {
      setIntermediateScores(scores)
    }
  }, [scores])

  React.useEffect(() => {
    if (debouncedScoreWeights && scoreWeights) {
      const { weights, source } = debouncedScoreWeights
      currentProfile && void updateProfileScores(currentProfile.id, weights)
      updateView(weights, source, agsRefResLoc)
    }
  }, [debouncedScoreWeights])

  const renderScoreDeleteDialog = (
    scoreName: string,
    smartdataSource: DataSetType,
    scoreListLength: number
  ): JSX.Element | null => {
    if (scoreListLength > 2) {
      return (
        <AlertBox
          onClose={() => setScoreToDelete(undefined)}
          header={t.profileEdit.removingScoreAlertTitle}
          actions={[
            {
              label: t.profileEdit.distributeEvenly,
              action: () => removeScoreWeight(scoreName, smartdataSource),
              type: "secondary",
            },
            {
              label: t.profileEdit.numericInput,
              action: () => {
                setScoreToDelete(scoreName)
                setShowNumericInputPane(true)
              },
              type: "secondary",
            },
          ]}
        >
          {t.profileEdit.removingScoreAlertText}
        </AlertBox>
      )
    } else {
      removeScoreWeight(scoreName, smartdataSource)
      return null
    }
  }

  const getListForNumericInput = (originalScoreList: ScoreListItem[], scoreToDelete?: string): ScoreListItem[] => {
    const sumBeforeDelete = originalScoreList.reduce((acc, score) => Math.abs(score.weight) + acc, 0)

    return originalScoreList.reduce<ScoreListItem[]>((acc, score) => {
      if (score.scoreName !== scoreToDelete) {
        const toAdd = { ...score, weight: (score.weight * 100) / sumBeforeDelete }
        acc.push(toAdd)
      }

      return acc
    }, [])
  }

  const removeScoreWeight = (name: string, source: DataSetType): void => {
    const key = source
    const newWeights: Scores = {
      micro: { ...intermediateScores.micro },
      macro: { ...intermediateScores.macro },
    }

    delete newWeights[key][name]

    currentProfile && void updateProfileScores(currentProfile.id, newWeights)
    updateView(newWeights, source, agsRefResLoc)
    setScoreToDelete(undefined)
  }

  const getSelectedMicroScores = (): ScoreListItem[] => {
    const result: { scoreName: string; title: string; weight: number }[] = []
    for (const score of LANA_CELL_NODES) {
      const weight = intermediateScores.micro[score.scoreName]
      if (weight !== undefined)
        result.push({ scoreName: score.scoreName, title: t.pickTranslation(score.title), weight })
    }
    for (const profile of profileList || []) {
      const scoreName = CHILD_PROFILE_PREFIX + profile.id
      const weight = intermediateScores.micro[scoreName]
      if (weight !== undefined) result.push({ scoreName, title: t.prefixWithProfile(profile.name), weight })
    }
    return result
  }

  const getSelectedMacroScores = (): ScoreListItem[] => {
    const result: { scoreName: string; title: string; weight: number }[] = []
    for (const score of LANA_AGS_NODES) {
      const weight = intermediateScores.macro[score.scoreName]
      if (weight !== undefined)
        result.push({ scoreName: score.scoreName, title: t.pickTranslation(score.title), weight })
    }
    for (const profile of profileList || []) {
      const scoreName = CHILD_PROFILE_PREFIX + profile.id
      const weight = intermediateScores.macro[scoreName]
      if (weight !== undefined) result.push({ scoreName, title: t.prefixWithProfile(profile.name), weight })
    }
    return result
  }

  const renderWeightedScore = (
    scoreName: string,
    title: string,
    weight: number,
    weightsSum: number,
    numericButtonDisabled: boolean
  ) => {
    const relativeWeight = weightsSum > 0 ? (100 * Math.abs(weight)) / weightsSum : 0
    const hasChildProfiles = scoreName.startsWith(CHILD_PROFILE_PREFIX)
    return (
      <BorderBottom key={`fragment-${scoreName}`}>
        <Grid padding={16} colGap={32} columnSpec="1fr min-content min-content">
          <Flex flexDirection="row" gap={8} alignItems="center">
            <b>{title}</b>
            {hasChildProfiles && <ProfileDependencies profileId={scoreName.substring(8)} />}
          </Flex>
          <div />
          <Button type="tertiary" size="small" icon="remove" danger onClick={() => setScoreToDelete(scoreName)} />

          <Slider
            min={1}
            max={100}
            value={Math.abs(weight)}
            onChange={(selection) => {
              const value = weight < 0 ? -selection : selection
              saveScoreWeight(scoreName, value, smartdataSource)
            }}
          />
          <NumericInputIcon disabled={numericButtonDisabled} onClick={() => setShowNumericInputPane(true)} />
          <GridItem rowSpan={2} alignSelf="end" justifySelf="end">
            <b style={{ textAlign: "right" }}>{formatFractionDigitsOptimized(relativeWeight)}%</b>
            <div style={{ textAlign: "right", fontSize: "14px" }}>{t.profileEdit.weight}</div>
          </GridItem>
          <Switch
            label={t.profileEdit.inverting}
            checked={weight < 0}
            large
            onChange={() => {
              const value = -weight
              saveScoreWeight(scoreName, value, smartdataSource)
            }}
          />
          <div />
        </Grid>
      </BorderBottom>
    )
  }

  const saveScoreWeight = (name: string, value: number, dataSetType: DataSetType): void => {
    const newWeights: Scores = {
      ...intermediateScores,
      [dataSetType]: { ...intermediateScores[dataSetType], [name]: value },
    }

    setScoreWeights({ weights: newWeights, source: dataSetType })
    setIntermediateScores(newWeights)
  }

  const saveScoreWeightNumericInput = (newScoresNumericInput: ScoreListItem[], dataSetType: DataSetType): void => {
    let updatedScores
    if (scoreToDelete) {
      const weightsWithoutDeletedItem: Scores = {
        micro: { ...intermediateScores.micro },
        macro: { ...intermediateScores.macro },
      }
      delete weightsWithoutDeletedItem[dataSetType][scoreToDelete]
      updatedScores = newScoresNumericInput.reduce(
        (scores, item) => ({ ...scores, [item.scoreName]: item.weight }),
        weightsWithoutDeletedItem[dataSetType]
      )
    } else {
      updatedScores = newScoresNumericInput.reduce(
        (scores, item) => ({ ...scores, [item.scoreName]: item.weight }),
        intermediateScores[dataSetType]
      )
    }
    const newWeights: Scores = { ...intermediateScores, [dataSetType]: updatedScores }
    setScoreWeights({ weights: newWeights, source: dataSetType })
    setIntermediateScores(newWeights)
    setScoreToDelete(undefined)
    setShowNumericInputPane(false)
  }

  const backToProfiles = () => {
    fetchProfiles().then(
      () => {},
      () => {}
    )
  }

  const render = () => {
    if (currentProfileLoading) {
      return (
        <Flex flexDirection="row" md-justify="center">
          <LoadingSpinner color="primary" />
        </Flex>
      )
    }

    const scoreKey = smartdataSource
    const selectedMicroScores = getSelectedMicroScores()
    const selectedMacroScores = getSelectedMacroScores()
    const scoreList = scoreKey == "micro" ? selectedMicroScores : selectedMacroScores
    const weightsSum = scoreList.reduce((total, { weight }) => total + Math.abs(weight), 0)
    const numericInputButtonDisabled = scoreList.length < 2

    return (
      <Grid height={[100, "%"]} columns={1}>
        {scoreToDelete &&
          !showNumericInputPane &&
          renderScoreDeleteDialog(scoreToDelete, smartdataSource, scoreList.length)}
        <NumericInputDrawer
          isOpen={showNumericInputPane}
          scoreList={getListForNumericInput(scoreList, scoreToDelete)}
          saveScoreWeightNumericInput={(newScores: ScoreListItem[]) => saveScoreWeightNumericInput(newScores, scoreKey)}
          onClose={() => {
            setScoreToDelete(undefined)
            setShowNumericInputPane(false)
          }}
        />
        <BareCard>
          <Grid columns={1} height={[100, "%"]} rowSpec="min-content 1fr">
            <Flex flexDirection="column">
              <FlexItem alignSelf="start" padding={[8, 16]}>
                <Button type="tertiary" icon="back" onClick={backToProfiles}>
                  {t.profileList.backTo}
                </Button>
              </FlexItem>
              <FlexItem alignSelf="start" padding={[0, 16]}>
                <h2>{currentProfile ? currentProfile.name : ""}</h2>
              </FlexItem>
              <ExtendedTabBar
                selectedIndex={scoreKey == "micro" ? 0 : 1}
                tabItems={[
                  {
                    label: t.microScores,
                    badge: selectedMicroScores.length.toString(),
                    onClick: () => {
                      updateView(scores, "micro", agsRefResLoc)
                    },
                  },
                  {
                    label: t.macroScores,
                    badge: selectedMacroScores.length.toString(),
                    onClick: () => {
                      updateView(scores, "macro", agsRefResLoc)
                    },
                  },
                ]}
                small
              />
            </Flex>
            <GridItem>
              <ScrollBox>
                {scoreList.map(({ scoreName, title, weight }) =>
                  renderWeightedScore(scoreName, title, weight, weightsSum, numericInputButtonDisabled)
                )}
              </ScrollBox>
            </GridItem>
            <GridItem padding={16} justifySelf="end">
              <Button type="primary" icon="add" onClick={startAddProfileScores}>
                {scoreKey == "micro" ? t.profileEdit.addMicroScore : t.profileEdit.addMacroScore}
              </Button>
            </GridItem>
          </Grid>
        </BareCard>
      </Grid>
    )
  }

  return render()
}
