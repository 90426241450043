import * as React from "react"
import { useMemo } from "react"
import { isMetaRating, MetaRating, Rating } from "../../shared/models/ratings"
import { Collapse } from "@blueprintjs/core"
import { css, cx } from "emotion"
import { PopupMenu } from "../../shared/components/ui/popup-menu"
import { translations } from "../i18n"
import { COLORS } from "../../shared/components/ui/colors"
import { Flex } from "../../shared/components/ui/flex"
import { FlexItem } from "../../shared/components/ui/flex-item"
import { useAppDispatch, useAppSelector } from "../../relas/store"
import {
  doDeleteRating,
  doExplorerDisplayRating,
  doFetchRatingDetail,
  updateRatingLockStatus,
} from "../rating-manager-actions"
import { ratingManagerActions } from "../rating-manager-slice"
import { RatingDetail } from "../../explorer/models/rating"
import { getRelasAuthScopes } from "../../relas/user-state"
import { AssessmentEntryFull } from "../../assessment/models/assessment"
import {
  ExplorerRatingGradeLabel,
  Props as ExplorerRatingGradeLabelProps,
} from "../../shared/components/explorer-rating-grade-label"
import { trackUsageEvent } from "../../utils/usage-tracking"
import { AlertBox } from "../../shared/components/alertbox"
import Text from "../../shared/components/text"
import { formatNumber } from "../../shared/helper/number-format"
import Icon from "../../shared/components/icon"

type Props = {
  ratings: Rating[] | MetaRating[]
  title: string
  userId: string | undefined
  assessmentEntry?: AssessmentEntryFull | null
  conflictList?: { [k: string]: string[] }
}

const styles = {
  listItem: css({
    borderBottom: "1px solid",
    borderColor: COLORS.border.default,
    backgroundColor: COLORS.background.default,
    cursor: "pointer",
    display: "flex",
    padding: "8px 0 8px 16px",
    alignItems: "center",
    height: "58px",
  }),
  selectedListItem: css({
    backgroundColor: COLORS.secondary2.light,
  }),
  listItemTitle: css({
    flex: "1 1 auto",
    placeSelf: "center",
  }),
  listItemIcon: css({
    flex: "0 0 auto",
  }),
  collapseButton: css({
    backgroundColor: COLORS.background.light,
    padding: "16px",
  }),
}

export const Ratings = (props: Props) => {
  const t = React.useMemo(() => translations(), [])

  const [collapsed, setCollapsed] = React.useState(false)

  const dispatch = useAppDispatch()

  const selectedRating = useAppSelector((state) => state.ratingManager.currentRating)

  const currentAssessmentEntry = useAppSelector((state) => state.assessment.currentAssessmentEntry ?? undefined)

  const module = useAppSelector((state) => state.navigation.currentPage)

  const [ratingToManipulate, setRatingToManipulate] = React.useState<
    { rating: RatingDetail | Rating | MetaRating; action: "delete" | "lock" } | undefined
  >(undefined)

  const isCompanyAdmin = useMemo(() => getRelasAuthScopes().includes("CA"), [])

  const [ratingDeleteConfictList, setRatingDeleteConfictList] = React.useState<string[]>([])

  async function manipulateRating(rating: Rating | MetaRating, action: "edit" | "rename" | "duplicate") {
    const type = isMetaRating(rating) ? "meta" : "standard"
    const ratingDetails = await doFetchRatingDetail(rating.id, type)
    if ("id" in ratingDetails) {
      dispatch(ratingManagerActions.setCurrentRating(ratingDetails))
      dispatch(ratingManagerActions.setMenuPane(action))
    }
  }

  function renderLockConfirm() {
    const rating = ratingToManipulate?.rating
    if (!rating) return null
    const isLocked = rating.lockedBy !== undefined && rating.lockedBy !== null

    return (
      <AlertBox
        header={isLocked ? t.lockAlert.unlockTitle : t.lockAlert.title}
        onClose={() => setRatingToManipulate(undefined)}
        actions={[
          {
            label: isLocked ? t.unlock : t.lock,
            icon: isLocked ? "unlocked" : "locked",
            action: () => {
              void updateRatingLockStatus(rating)
              setRatingToManipulate(undefined)
            },
          },
          { label: t.cancel, action: () => setRatingToManipulate(undefined) },
        ]}
      >
        <Text>{isLocked ? t.lockAlert.unlockConfirm : t.lockAlert.lockConfirm}</Text>
      </AlertBox>
    )
  }

  function renderSingleRating(rating: Rating | MetaRating) {
    if (!props.assessmentEntry) return null

    let grade: ExplorerRatingGradeLabelProps["grade"]
    if (isMetaRating(rating) && props.assessmentEntry.metaRatingResults[rating.id]) {
      const result = props.assessmentEntry.metaRatingResults[rating.id]
      const range = result.range
      grade = {
        label: formatNumber(result.result, 2),
        color: range?.color,
      }
    } else if (props.assessmentEntry.ratingResults[rating.id]) {
      const result = props.assessmentEntry.ratingResults[rating.id]
      grade = result.grade
    } else {
      return null
    }

    return (
      <Flex flexDirection="row" key={rating.id} padding={[0, 8]}>
        <FlexItem flexGrow={1} />
        {grade && <ExplorerRatingGradeLabel grade={grade} />}
      </Flex>
    )
  }
  function renderRating(rating: Rating | MetaRating, idx: number) {
    const userCanUnlock = isCompanyAdmin || rating.lockedBy === props.userId

    return (
      <div
        className={cx({ [styles.listItem]: true, [styles.selectedListItem]: selectedRating?.id === rating.id })}
        key={idx}
        onClick={() => onRatingClick(rating)}
      >
        <div className={styles.listItemTitle}>
          {rating.name}
          {rating.lockedBy && (
            <>
              &nbsp;
              <Icon name="locked" fontSize={14} />
            </>
          )}
        </div>
        <div className={styles.listItemIcon}>
          {props.assessmentEntry ? (
            renderSingleRating(rating)
          ) : (
            <PopupMenu
              actions={[
                {
                  title: t.listMenu.edit,
                  onClick: (e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    return manipulateRating(rating, "edit")
                  },
                  disabled: rating.lockedBy !== undefined,
                },
                {
                  title: t.listMenu.duplicate,
                  onClick: (e) => {
                    e.stopPropagation()
                    return manipulateRating(rating, "duplicate")
                  },
                },
                {
                  title: t.listMenu.rename,
                  onClick: (e) => {
                    e.stopPropagation()
                    return manipulateRating(rating, "rename")
                  },
                  disabled: rating.lockedBy !== undefined,
                },
                "divider",
                {
                  title: rating.lockedBy ? t.unlock : t.lock,
                  onClick: (e) => {
                    e.stopPropagation()
                    setRatingToManipulate({ rating, action: "lock" })
                  },
                  disabled: rating.lockedBy !== undefined && !userCanUnlock,
                },
                "divider",
                {
                  title: t.listMenu.delete,
                  onClick: () => attemptToDeleteRating(rating),
                  disabled: rating.lockedBy !== undefined,
                },
              ]}
            />
          )}
        </div>
      </div>
    )
  }

  function onRatingClick(rating: Rating | MetaRating) {
    if (selectedRating?.id === rating.id) {
      dispatch(ratingManagerActions.setCurrentRating(undefined))
    } else {
      doExplorerDisplayRating(dispatch)(rating, currentAssessmentEntry?.agsRefResloc, currentAssessmentEntry)
      if (module?.name === "ratings") {
        const ratingType = isMetaRating(rating) ? "meta" : rating.dataSetType
        trackUsageEvent(
          "RATINGS_SELECT_RATING",
          currentAssessmentEntry?.address ?? null,
          `${ratingType}: ${rating.name}`
        )
      }
    }
  }

  async function onDeleteRatingClick() {
    setRatingToManipulate(undefined)
    if (ratingToManipulate?.action == "delete") {
      const result = await doDeleteRating(dispatch)(ratingToManipulate.rating)
      if (result?.data?.details && Array.isArray(result.data?.details)) {
        setRatingDeleteConfictList(result.data.details)
      }
    }
  }

  function attemptToDeleteRating(rating: Rating | MetaRating) {
    if (!isMetaRating(rating)) {
      const foundInRatings = props.conflictList?.[rating.id] ?? []
      if (foundInRatings.length > 0) {
        setRatingDeleteConfictList(foundInRatings)
      }
    }
    setRatingToManipulate({ rating, action: "delete" })
  }

  return (
    <>
      {ratingDeleteConfictList.length === 0 && ratingToManipulate?.action == "delete" && (
        <AlertBox
          header={t.deleteDialog.titleRating}
          onClose={() => setRatingToManipulate(undefined)}
          actions={[
            {
              label: t.deleteDialog.menuItem,
              icon: "delete",
              action: () => onDeleteRatingClick(),
            },
            { label: t.cancel, action: () => setRatingToManipulate(undefined) },
          ]}
        >
          <Text>{t.deleteDialog.confirmRating(ratingToManipulate?.rating.name ?? "")}</Text>
        </AlertBox>
      )}
      {ratingDeleteConfictList.length > 0 && (
        <AlertBox
          header={t.deleteDialog.ratingCannotBeDeleted}
          onClose={() => setRatingDeleteConfictList([])}
          actions={[
            {
              label: t.ok,
              action: () => {
                setRatingDeleteConfictList([])
                setRatingToManipulate(undefined)
              },
            },
          ]}
        >
          <>
            <div>{t.deleteDialog.ratingCannotBeDeletedText(ratingToManipulate?.rating.name ?? "")}</div>
            <ul>
              {ratingDeleteConfictList.map((name) => {
                return <li>{name}</li>
              })}
            </ul>
          </>
        </AlertBox>
      )}
      {ratingToManipulate?.action == "lock" && renderLockConfirm()}
      <div className={cx(styles.listItem, styles.collapseButton)}>
        <div className={styles.listItemTitle}>
          <Text fontWeight="bold">{props.title}</Text>
        </div>
        <div className={styles.listItemIcon} onClick={() => setCollapsed(!collapsed)}>
          <Icon name={collapsed ? "dropdown_up" : "dropdown_down"} />
        </div>
      </div>
      <Collapse isOpen={!collapsed}>{props.ratings.map(renderRating)}</Collapse>
    </>
  )
}
