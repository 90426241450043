import * as React from "react"
import { useEffect, useState } from "react"
import {
  Assessment,
  AssessmentEntry,
  AssessmentEntryWithScore,
  AssessmentEntryWithScoreResult,
  Exclusiveness,
  isInProgressState,
  PriceRange,
} from "../models/assessment"
import { Checkbox, Icon } from "@blueprintjs/core"
import { Icon as IconReStyle } from "../../shared/components/icon"
import { IconNames } from "@blueprintjs/icons"
import {
  Cell,
  Column,
  ColumnHeaderCell2,
  IColumnProps,
  Region,
  RegionCardinality,
  RenderMode,
  Table2,
} from "@blueprintjs/table"
import { translations } from "../i18n"
import { Profile } from "../../profile/models/profile"
import { COLORS } from "../../shared/components/ui/colors"
import { Flex } from "../../shared/components/ui/flex"
import { Grid } from "../../shared/components/ui/grid"
import { GridItem } from "../../shared/components/ui/grid-item"
import { css, cx } from "emotion"
import { ListRatingGradeLabel, Props as GradeLabelProps } from "./list-rating-grade-label"
import { isMetaRating, MetaRating, Rating, RatingLike } from "../../shared/models/ratings"
import { assertUnreachable } from "../../utils/utils"
import { trackUsageEvent } from "../../utils/usage-tracking"
import { Address } from "../models/address"
import { AppModules } from "../../menu/util/app-location-types"
import { Tooltip } from "../../shared/components/ui/tooltip"
import { CorrectedAddressTooltip } from "../../shared/components/corrected-address-tooltip"
import { useAppSelector } from "../../relas/store"
import {
  deleteAssessment,
  deleteAssessmentEntry,
  loadAssessmentEntry,
  navigateToAssessment,
  openAssessmentModule,
  setAssessmentEntriesSorting,
} from "../reducers/assessment-slice-functions"
import { AlertBox } from "../../shared/components/alertbox"
import DropdownButton from "../../shared/components/dropdown-button"
import HorizontalDivider from "../../shared/components/horizontaldivider"
import { HeaderSorting } from "../../shared/components/sortable-table-header"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"
import { SortDirection } from "../../shared/models/sorting"
import { getThemeColorVar } from "../../shared/helper/color"
import { formatNumber } from "../../shared/helper/number-format"

const header = css({
  justifySelf: "center",
  maxWidth: "100%",
  fontSize: "16px",
  fontWeight: "bold",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
})

const emptyHeader = css({
  minHeight: "24px",
})

const smallHeader = css({
  fontSize: "12px",
  fontWeight: "normal",
  textAlign: "center",
  flexGrow: 1,
})

const pointer = css({
  cursor: "pointer",
})

const cell = css({
  fontSize: "14px",
  fontWeight: "normal",
  lineHeight: "24px",
  ":hover": {
    background: COLORS.background.lighter,
  },
})

const shrinkableText = css({
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
})

const checkboxDeletingObject = css({
  whiteSpace: "nowrap",
  marginBottom: 0,
})

interface OwnProps {
  assessmentStateLoading: boolean
  searchTerm: string
  tagFilter: string | null
  module: AppModules["locationAssessment"]
}

export type ObjectColumnHeader = "externalID" | "year" | "area" | "usageType" | "exclusiveness"

export type ExclusivenessDropdownItems = "standard" | "high" | "highest"

const isMacro = (m: string) => m === "macro"

function filterEntries(
  entries: AssessmentEntryWithScore[],
  searchTerm: string,
  tagFilter: string | null,
  sorting: HeaderSorting<string>
): AssessmentEntryWithScore[] {
  let filtered

  if (searchTerm.length > 0) {
    filtered = entries.filter(
      (entry) =>
        (entry.address.locality?.toLocaleLowerCase().indexOf(searchTerm) || 0) >= 0 ||
        (entry.address.route?.toLocaleLowerCase().indexOf(searchTerm) || 0) >= 0 ||
        (entry.address.postalCode?.toLocaleLowerCase().indexOf(searchTerm) || 0) >= 0
    )
  } else {
    filtered = [...entries]
  }

  if (tagFilter !== null) {
    filtered = filtered.filter((entry) => entry.tags.indexOf(tagFilter) >= 0)
  }

  let sorter: (a: AssessmentEntryWithScore, b: AssessmentEntryWithScore) => number
  switch (sorting[1]) {
    case "locality":
      sorter = (a, b) => a.address.locality?.localeCompare(b.address.locality || "") || 0
      break
    case "postalCode":
      sorter = (a, b) => a.address.postalCode?.localeCompare(b.address.postalCode || "") || 0
      break
    case "route":
      sorter = (a, b) => {
        const aStreetWithNumber = a.address.route
          ? a.address.route + (a.address.streetNumber ? " " + a.address.streetNumber : "")
          : ""
        const bStreetNumber = b.address.route
          ? b.address.route + (b.address.streetNumber ? " " + b.address.streetNumber : "")
          : ""
        return aStreetWithNumber.localeCompare(bStreetNumber) || 0
      }
      break
    case "externalID":
      sorter = (a, b) => a.externalID?.localeCompare(b.externalID || "") || 0
      break
    case "year":
      sorter = (a, b) => (a.year ?? 0) - (b.year ?? 0)
      break
    case "area":
      sorter = (a, b) => (a.area ?? 0) - (b.area ?? 0)
      break
    case "usageType":
      sorter = (a, b) => {
        const aUsageType = a.usageType?.toString() ?? ""
        const bUsageType = b.usageType?.toString() ?? ""
        return aUsageType.localeCompare(bUsageType)
      }
      break
    case "exclusiveness":
      sorter = (a, b) => {
        const order = {
          [Exclusiveness.Standard]: 1,
          [Exclusiveness.High]: 2,
          [Exclusiveness.Highest]: 3,
        }

        if (a.exclusiveness && b.exclusiveness) return order[a.exclusiveness] - order[b.exclusiveness]
        if (a.exclusiveness) return 1
        if (b.exclusiveness) return -1
        return 0
      }
      break
    default:
      // Either sorted by Prices or Profiles
      const [a, b] = sorting[1].split("::")

      if (new Set(["min", "max", "avg"]).has(a)) {
        const [minAvgMax, key] = [a, b]
        let getPriceRange: (entry: AssessmentEntry) => PriceRange | undefined

        switch (key) {
          case "locationMacroPrices":
            getPriceRange = (e) => e.macroPrice
            break
          case "locationMicroPrices":
            getPriceRange = (e) => e.microPrice
            break
          case "locationObjectPrices":
            getPriceRange = (e) => e.objectPrice
            break
          default:
            getPriceRange = (_) => undefined
        }

        sorter = (a, b) => sortByPrices(getPriceRange, minAvgMax as keyof PriceRange)(a, b)
      } else {
        const [macroMicro, profileId] = sorting[1].split("::")
        sorter = (a, b) => sortByProfile(profileId, isMacro(macroMicro))(a, b)
      }
  }

  sorter = compareAscendingOrDescending(sorting[0], sorter)

  return filtered.sort((a, b) => sorter(a, b))
}

function compareAscendingOrDescending(
  sortDirection: SortDirection,
  sortFn: (a: AssessmentEntryWithScore, b: AssessmentEntryWithScore) => number
): (a: AssessmentEntryWithScore, b: AssessmentEntryWithScore) => number {
  return (a: AssessmentEntryWithScore, b: AssessmentEntryWithScore) =>
    sortDirection === SortDirection.ASC ? sortFn(a, b) : sortFn(b, a)
}

function sortByPrices(
  getPriceRange: (entry: AssessmentEntry) => PriceRange | undefined,
  priceType: keyof PriceRange
): (a: AssessmentEntryWithScore, b: AssessmentEntryWithScore) => number {
  return (a, b) => {
    const aPrice = getPriceRange(a)
    const bPrice = getPriceRange(b)

    if (aPrice && bPrice) return aPrice[priceType] - bPrice[priceType]
    if (aPrice) return 1
    if (bPrice) return -1
    return 0
  }
}

function sortByProfile(profileId: string, sortByMacro: boolean) {
  return (a: AssessmentEntryWithScore, b: AssessmentEntryWithScore) => {
    let getScore

    if (sortByMacro) {
      getScore = (score: { macro: number }) => (score ? score.macro : 0)
    } else {
      getScore = (score: { micro: number }) => (score ? score.micro : 0)
    }

    return getScore(a.scores[profileId]) - getScore(b.scores[profileId])
  }
}

function objectColumnCell(columnValue: string | undefined): JSX.Element {
  return <Cell className={cell}>{columnValue === undefined ? "-" : columnValue}</Cell>
}

function exclusivenessStars(exclusiveness: Exclusiveness): string {
  const star = "★"
  let amountOfStars = 0
  switch (exclusiveness) {
    case Exclusiveness.Standard:
      amountOfStars = 1
      break
    case Exclusiveness.High:
      amountOfStars = 2
      break
    case Exclusiveness.Highest:
      amountOfStars = 3
      break
  }
  return new Array(amountOfStars).fill(star).join(" ")
}

function defaultColumnWidth(
  assessmentEntries: AssessmentEntryWithScoreResult | null,
  containsObjectData: boolean
): number[] {
  if (!assessmentEntries) return []

  const lengths = [30, 60, 150, 200, 250, 250, 250]
    .concat(assessmentEntries.ratings.map((rating) => Math.max(120, 12 * rating.name.length)))
    .concat(assessmentEntries.metaRatings.map((rating) => Math.max(120, 12 * rating.name.length)))
    .concat(assessmentEntries.profiles.map((profile) => Math.max(120, 12 * profile.name.length)))

  if (containsObjectData) {
    lengths.splice(4, 0, 110, 110, 110, 230, 110)
  }

  return lengths
}

const arrowIcon = (size: number, direction: SortDirection) => (
  <Icon
    icon={direction === SortDirection.ASC ? IconNames.ARROW_UP : IconNames.ARROW_DOWN}
    color={getThemeColorVar("primary", "default")}
    iconSize={size}
  />
)

export const AssessmentEntryList = ({ assessmentStateLoading, searchTerm, tagFilter, module }: OwnProps) => {
  const t = translations()

  const currentAssessment = useAppSelector((state) => state.assessment.currentAssessment)
  const currentAssessmentEntry = useAppSelector((state) => state.assessment.currentAssessmentEntry)
  const assessmentEntries = useAppSelector((state) => state.assessment.assessmentEntries)
  const assessmentEntriesSorting = useAppSelector((state) => state.assessment.assessmentEntriesSorting)

  const [filtered, setFiltered] = useState<AssessmentEntryWithScore[]>(assessmentEntries?.entries || [])
  const [columnWidths, setColumnWidths] = useState<number[]>(
    defaultColumnWidth(assessmentEntries, currentAssessment ? currentAssessment.containsObjectData : false)
  )
  const [checkedEntries, setCheckedEntries] = useState<Set<string>>(new Set())

  const [showAlertDeleteAssessment, setShowAlertDeleteAssessment] = useState(false)

  useEffect(() => {
    setFiltered(
      filterEntries(
        assessmentEntries?.entries || [],
        searchTerm.toLocaleLowerCase(),
        tagFilter,
        assessmentEntriesSorting
      )
    )

    setColumnWidths(
      defaultColumnWidth(assessmentEntries, currentAssessment ? currentAssessment.containsObjectData : false)
    )
  }, [assessmentEntries, searchTerm, tagFilter])

  const getEntriesById: () => Record<string, Address> = () => {
    return !assessmentEntries
      ? {}
      : assessmentEntries.entries.reduce<Record<string, Address>>((acc, entry) => {
          acc[entry.id] = entry.address
          return acc
        }, {})
  }

  const trackSelectedObjects = (assessmentTitle: string, entriesForDeletion: Set<string>) => {
    const entriesById = getEntriesById()

    entriesForDeletion.forEach((id) => {
      const address = entriesById[id]
      if (address) {
        trackUsageEvent("DELETE_ASSESSMENT_FROM_ADDRESS_LIST", address, assessmentTitle)
      }
    })
  }

  const deleteSelectedObjects = () => {
    if (currentAssessment) {
      trackSelectedObjects(currentAssessment.title, checkedEntries)
      void deleteAssessmentEntry(currentAssessment.id, Array.from(checkedEntries))
      setCheckedEntries(new Set())
    }
  }

  const renderAssessmentDeleteDialog = () => {
    return (
      <AlertBox
        onClose={() => {}}
        header={t.assessmentDetails.emptyAssessmentAlert.title}
        actions={[
          {
            label: t.delete,
            icon: "delete",
            action: () => {
              deleteSelectedObjects()
              if (currentAssessment?.id) void deleteAssessment(currentAssessment.id, module)
            },
          },
          {
            label: t.cancel,
            action: () => {
              setShowAlertDeleteAssessment(false)
            },
            type: "secondary",
          },
        ]}
      >
        {" "}
        {t.assessmentDetails.emptyAssessmentAlert.text}
      </AlertBox>
    )
  }

  const onDeleteClickHandle = () => {
    checkedEntries.size === assessmentEntries?.entries.length
      ? setShowAlertDeleteAssessment(true)
      : deleteSelectedObjects()
  }

  const emptyColumnHeader = () => {
    return (
      <ColumnHeaderCell2>
        <Grid columns={1}>
          <div className={header}>&nbsp;</div>
          <div className={emptyHeader} />
        </Grid>
      </ColumnHeaderCell2>
    )
  }

  const sortableColumnHeader = <T extends string>(columnHeader: T, getLabel: (s: T) => string) => {
    return () => (
      <ColumnHeaderCell2>
        <Grid columns={1}>
          <div className={cx(pointer, header)} onClick={onChangeSorting(columnHeader)}>
            {getLabel(columnHeader)}
            {assessmentEntriesSorting[1] === columnHeader && arrowIcon(16, assessmentEntriesSorting[0])}
          </div>
          <div className={emptyHeader} />
        </Grid>
      </ColumnHeaderCell2>
    )
  }

  const addressColumnHeader = (sortField: string) => {
    return sortableColumnHeader(sortField, () => t.address[sortField])
  }

  const objectColumnHeader = (columnHeader: ObjectColumnHeader) => {
    return sortableColumnHeader(columnHeader, (s) => t.assessmentEntryDetails.columns[s])
  }

  const pricesColumnHeader = (title: string, key: string) => {
    return () => {
      const [minMaxAvg, sortedByKey] = assessmentEntriesSorting[1].split("::")

      return (
        <ColumnHeaderCell2>
          <Grid columns={1}>
            <div className={header}>{title}</div>
            <Grid columnSpec="1fr 10px 1fr 10px 1fr" alignItems="center" justifyItems="center" padding={[0, 8]}>
              <div className={cx(pointer, smallHeader)} onClick={onChangeSorting(`min::${key}`)}>
                {t.assessmentDetails.prices.min}
                {minMaxAvg === "min" && sortedByKey === key && arrowIcon(16, assessmentEntriesSorting[0])}
              </div>
              <HorizontalDivider height={12} />
              <div className={cx(pointer, smallHeader)} onClick={onChangeSorting(`avg::${key}`)}>
                {t.assessmentDetails.prices.avg}
                {minMaxAvg === "avg" && sortedByKey === key && arrowIcon(16, assessmentEntriesSorting[0])}
              </div>
              <HorizontalDivider height={12} />
              <div className={cx(pointer, smallHeader)} onClick={onChangeSorting(`max::${key}`)}>
                {t.assessmentDetails.prices.max}
                {minMaxAvg === "max" && sortedByKey === key && arrowIcon(16, assessmentEntriesSorting[0])}
              </div>
            </Grid>
          </Grid>
        </ColumnHeaderCell2>
      )
    }
  }

  const pricesCellRenderer = (
    entries: AssessmentEntryWithScore[],
    priceGetter: (entry: AssessmentEntry) => PriceRange | undefined
  ) => {
    return (rowIdx: number, columnIndex: number) => {
      const entry = entries[rowIdx]
      const prices = priceGetter(entry)

      return (
        <Cell className={cell}>
          <Grid columnSpec="1fr 10px 1fr 10px 1fr" alignItems="center" justifyItems="center" padding={[0, 8]}>
            <div>{typeof prices === "undefined" ? "-" : formatNumber(prices.min, 2)}</div>
            <HorizontalDivider height={12} />
            <div>{typeof prices === "undefined" ? "-" : formatNumber(prices.avg, 2)}</div>
            <HorizontalDivider height={12} />
            <div>{typeof prices === "undefined" ? "-" : formatNumber(prices.max, 2)}</div>
          </Grid>
        </Cell>
      )
    }
  }

  const profileColumnHeaderRenderer = (profile: Profile) => {
    const [direction, sorting] = assessmentEntriesSorting
    const [macroMicro, profileId] = sorting.split("::")
    const sortedByMacro = isMacro(macroMicro)
    const icon = profile.id === profileId ? arrowIcon(12, direction) : <></>

    return () => (
      <ColumnHeaderCell2>
        <Grid columns={1}>
          <div className={header}>{profile.name}</div>
          <Grid columnSpec="1fr 10px 1fr" alignItems="center" justifyItems="center" padding={[0, 8]}>
            <div className={cx(smallHeader, pointer)} onClick={onChangeSorting(`macro::${profile.id}`)}>
              {t.scoreKey.macro}
              {sortedByMacro && icon}
            </div>

            <HorizontalDivider height={12} />

            <div className={cx(smallHeader, pointer)} onClick={onChangeSorting(`micro::${profile.id}`)}>
              {t.scoreKey.micro}
              {!sortedByMacro && icon}
            </div>
          </Grid>
        </Grid>
      </ColumnHeaderCell2>
    )
  }

  const profileCellRenderer = (profile: Profile, entries: AssessmentEntryWithScore[]) => {
    return (rowIdx: number, columnIndex: number) => {
      const entry = entries[rowIdx]

      switch (entry.state) {
        case "scored":
        case "scored-ambiguous": {
          const scores = entry.scores[profile.id] || { macro: -1, micro: -1 }
          return (
            <Cell className={cell}>
              <Grid columnSpec="1fr 10px 1fr" alignItems="center" justifyItems="center" padding={[0, 8]}>
                <div>{Object.keys(profile.scores.macro).length == 0 || scores.macro < 0 ? "-" : scores.macro}</div>
                <HorizontalDivider height={12} />
                <div>{Object.keys(profile.scores.micro).length == 0 || scores.micro < 0 ? "-" : scores.micro}</div>
              </Grid>
            </Cell>
          )
        }
        case "failure":
          return (
            <Cell className={cell}>
              <Grid columns={1}>
                <GridItem justifySelf="center">
                  <Tooltip tooltip={t.assessmentDetails.entryFailure} placement={"auto"}>
                    <Flex flexDirection="row" alignItems="center" gap={4} padding={[0, 8]}>
                      <Icon icon="error" iconSize={14} />
                    </Flex>
                  </Tooltip>
                </GridItem>
              </Grid>
            </Cell>
          )
        default:
          return <Cell loading />
      }
    }
  }

  const ratingColumnHeaderRenderer = (rating: RatingLike) => {
    return () => {
      return (
        <ColumnHeaderCell2>
          <Grid columns={1}>
            <div className={header}>{rating.name}</div>
          </Grid>
        </ColumnHeaderCell2>
      )
    }
  }

  const ratingCellRenderer = (rating: Rating | MetaRating, entries: AssessmentEntryWithScore[]) => {
    const isMeta = isMetaRating(rating)

    return (rowIdx: number) => {
      const entry = entries[rowIdx]
      let grade: GradeLabelProps["grade"] | undefined

      if (isMeta) {
        const result = entry.metaRatingResults[rating.id]
        const range = result?.range
        grade = result ? { label: formatNumber(result.result, 2), color: range?.color } : undefined
      } else if (!isMeta) {
        grade = entry.ratingResults[rating.id]?.grade
      }
      if (!grade) {
        return (
          <Cell className={cell}>
            <Grid columns={1} alignItems="center" justifyItems="center" padding={[0, 8]}>
              <div>-</div>
            </Grid>
          </Cell>
        )
      }

      return (
        <Cell className={cell}>
          <Grid columns={1} alignItems="center" justifyItems="center" padding={[0, 8]}>
            <div>
              <ListRatingGradeLabel grade={grade} />
            </div>
          </Grid>
        </Cell>
      )
    }
  }

  const onSelectEntry = (regions: Region[]) => {
    if (regions.length === 0 || !regions[0].rows) return

    const entry = filtered[regions[0].rows[0]]

    if (!currentAssessment || isInProgressState(entry.state)) return

    if (regions[0].cols && regions[0].cols[0] === 0 && regions[0].cols[1] === 0) {
      const checkedEntriesFromState = new Set(checkedEntries)
      checkedEntriesFromState.has(entry.id)
        ? checkedEntriesFromState.delete(entry.id)
        : checkedEntriesFromState.add(entry.id)
      setCheckedEntries(checkedEntriesFromState)
      return
    }

    void doLoadAssessmentEntryModule(currentAssessment, entry.id)
  }

  const doLoadAssessmentEntryModule = (currentAssessment: Assessment, entryId: string) => {
    switch (module) {
      case "assessment":
        void loadAssessmentEntry(currentAssessment, entryId)
        return navigateToAssessment(currentAssessment.id, entryId)
      case "comparables":
      case "marketdata":
      case "dashboard":
      case "poiExplorer":
      case "rentindex":
      case "specialMaps":
      case "fundamentalData":
      case "ratings":
      case "prices":
        return openAssessmentModule(
          currentAssessment.id,
          entryId,
          module,
          undefined,
          currentAssessment?.id,
          currentAssessmentEntry?.id
        )
      default:
        assertUnreachable(module)
    }
  }

  const onChangeSorting = (sortField: string) => {
    return () => {
      let assessmentEntriesSortingNew: HeaderSorting<string> = [SortDirection.DESC, sortField]

      if (assessmentEntriesSorting[1] !== sortField || assessmentEntriesSorting[0] === SortDirection.DESC) {
        assessmentEntriesSortingNew = [SortDirection.ASC, sortField]
      }
      void setAssessmentEntriesSorting(assessmentEntriesSortingNew)
      setFiltered(
        filterEntries(
          assessmentEntries?.entries || [],
          searchTerm.toLocaleLowerCase(),
          tagFilter,
          assessmentEntriesSortingNew
        )
      )
    }
  }

  if (!assessmentEntries || !currentAssessment) return null

  const columns: Array<React.ReactElement<IColumnProps>> = [
    <Column
      key="checked"
      columnHeaderCellRenderer={emptyColumnHeader}
      cellRenderer={(rowIdx) => (
        <Cell className={cell}>
          <Checkbox
            disabled={assessmentStateLoading}
            className={checkboxDeletingObject}
            checked={checkedEntries.has(filtered[rowIdx].id)}
          />
        </Cell>
      )}
    />,
    <Column
      key="postalCode"
      columnHeaderCellRenderer={addressColumnHeader("postalCode")}
      cellRenderer={(rowIdx) => <Cell className={cell}>{filtered[rowIdx].address.postalCode}</Cell>}
    />,
    <Column
      key="locality"
      columnHeaderCellRenderer={addressColumnHeader("locality")}
      cellRenderer={(rowIdx) => <Cell className={cell}>{filtered[rowIdx].address.locality}</Cell>}
    />,
    <Column
      key="route"
      columnHeaderCellRenderer={addressColumnHeader("route")}
      cellRenderer={(rowIdx) => (
        <Cell className={cell}>
          <Grid columns={2} columnSpec={"1fr min-content"}>
            <span className={shrinkableText}>
              {filtered[rowIdx].address.route} {filtered[rowIdx].address.streetNumber}
            </span>
            {filtered[rowIdx].originalAddress && (
              <Tooltip
                placement="auto"
                tooltip={<CorrectedAddressTooltip entry={filtered[rowIdx]} typeToUse={"assessment"} />}
                inverted={true}
              >
                <IconReStyle name="warning" fontSize={14} />
              </Tooltip>
            )}
          </Grid>
        </Cell>
      )}
    />,
  ]

  if (currentAssessment.containsObjectData) {
    columns.splice(
      4,
      0,
      <Column
        key="externalID"
        columnHeaderCellRenderer={objectColumnHeader("externalID")}
        cellRenderer={(rowIdx) => objectColumnCell(filtered[rowIdx].externalID?.toString())}
      />,
      <Column
        key="year"
        columnHeaderCellRenderer={objectColumnHeader("year")}
        cellRenderer={(rowIdx) => objectColumnCell(filtered[rowIdx].year?.toString())}
      />,
      <Column
        key="area"
        columnHeaderCellRenderer={objectColumnHeader("area")}
        cellRenderer={(rowIdx) => {
          const area = filtered[rowIdx].area
          const toDisplay = area ? formatNumber(area, 2) + " m²" : undefined
          return objectColumnCell(toDisplay)
        }}
      />,
      <Column
        key="usageType"
        columnHeaderCellRenderer={objectColumnHeader("usageType")}
        cellRenderer={(rowIdx) => objectColumnCell(filtered[rowIdx].usageType)}
      />,
      <Column
        key="exclusiveness"
        columnHeaderCellRenderer={objectColumnHeader("exclusiveness")}
        cellRenderer={(rowIdx) => {
          const exclusiveness = filtered[rowIdx].exclusiveness
          const toDisplay = exclusiveness ? exclusivenessStars(exclusiveness) : undefined
          return objectColumnCell(toDisplay)
        }}
      />
    )
  }

  columns.push(
    <Column
      key="locationMacroPrices"
      columnHeaderCellRenderer={pricesColumnHeader(t.assessmentDetails.locationMacroPrices, "locationMacroPrices")}
      cellRenderer={pricesCellRenderer(filtered, (entry) => entry.macroPrice)}
    />,
    <Column
      key="locationMicroPrices"
      columnHeaderCellRenderer={pricesColumnHeader(t.assessmentDetails.locationMicroPrices, "locationMicroPrices")}
      cellRenderer={pricesCellRenderer(filtered, (entry) => entry.microPrice)}
    />,
    <Column
      key="locationObjectPrices"
      columnHeaderCellRenderer={pricesColumnHeader(t.assessmentDetails.objectPrices, "locationObjectPrices")}
      cellRenderer={pricesCellRenderer(filtered, (entry) => entry.objectPrice)}
    />
  )

  const { microRatings, macroRatings } = assessmentEntries.ratings.reduce(
    (acc, rating) => {
      if (rating.dataSetType === "macro") {
        acc.macroRatings.push(rating)
      } else {
        acc.microRatings.push(rating)
      }
      return acc
    },
    { microRatings: [] as Rating[], macroRatings: [] as Rating[] }
  )

  for (const rating of [...macroRatings, ...microRatings]) {
    columns.push(
      <Column
        key={rating.id}
        name={rating.name}
        columnHeaderCellRenderer={ratingColumnHeaderRenderer(rating)}
        cellRenderer={ratingCellRenderer(rating, filtered)}
      />
    )
  }

  for (const rating of assessmentEntries.metaRatings) {
    columns.push(
      <Column
        key={rating.id}
        name={rating.name}
        columnHeaderCellRenderer={ratingColumnHeaderRenderer(rating)}
        cellRenderer={ratingCellRenderer(rating, filtered)}
      />
    )
  }

  for (const profile of assessmentEntries.profiles) {
    columns.push(
      <Column
        key={profile.id}
        name={profile.name}
        columnHeaderCellRenderer={profileColumnHeaderRenderer(profile)}
        cellRenderer={profileCellRenderer(profile, filtered)}
      />
    )
  }

  return (
    <>
      {showAlertDeleteAssessment && renderAssessmentDeleteDialog()}
      <Grid columns={1} rowGap={8} rowSpec="min-content 1fr" padding={[0, 16]}>
        <div id="deleteAssessmentObjectDropdownButton" style={{ marginLeft: "8px" }}>
          <FlexContainer>
            <Checkbox
              disabled={assessmentStateLoading}
              className={checkboxDeletingObject}
              checked={false}
              indeterminate={!!checkedEntries.size}
              onClick={() => setCheckedEntries(new Set())}
            />
            <DropdownButton
              type="tertiary"
              alignment="left"
              disabled={assessmentStateLoading}
              actions={[
                {
                  title: t.delete,
                  onClick: onDeleteClickHandle,
                },
              ]}
            ></DropdownButton>
          </FlexContainer>
        </div>
        <Table2
          numRows={filtered.length}
          minRowHeight={24}
          defaultRowHeight={24}
          enableRowHeader={false}
          numFrozenColumns={4}
          renderMode={RenderMode.BATCH}
          selectionModes={[RegionCardinality.CELLS]}
          onSelection={onSelectEntry}
          columnWidths={columnWidths}
          onColumnWidthChanged={(index: number, size: number) => {
            setColumnWidths([...columnWidths.slice(0, index), size, ...columnWidths.slice(index + 1)])
          }}
          cellRendererDependencies={[checkedEntries, assessmentEntriesSorting, filtered, columnWidths]}
        >
          {columns}
        </Table2>
      </Grid>
    </>
  )
}
