import React, { useEffect, useMemo, useRef, useState } from "react"
import { translations } from "../../i18n"
import { css } from "emotion"
import Moveable from "react-moveable"
import { getThemeColorVar } from "../../../shared/helper/color"
import Icon from "../../../shared/components/icon"

interface DialogProps {
  showOverlay: boolean
  setShowOverlay: (show: boolean) => void
}

const centerOverlayClass = css({
  top: "10vh",
  right: "25vw",
  pointerEvents: "auto",
  background: "white",
  boxShadow: "0px 3px 16px 0px rgb(75, 75, 90)",
  borderRadius: "4px",
  color: "white",
  display: "block",
  position: "absolute",
  zIndex: 10001,
})

const districtdataDocumentationURL = "https://docs.google.com/document/d/17IS2c1c3GtW_9up83wCTE5eRsb5uZfA8IjRzRtM0tFM"

const MoveableHelp = ({ showOverlay, setShowOverlay }: DialogProps) => {
  const t = useMemo(translations, [translations])

  const selfElem = useRef<HTMLDivElement>(null)

  const [, setDummy] = useState<boolean>(false)

  const [frame, setFrame] = React.useState({
    translate: [0, 0],
  })

  useEffect(() => {
    setDummy(true)
  }, [])

  return (
    <div className={"districtDataHelp"}>
      {selfElem.current && (
        <Moveable
          edge={false}
          props={{ customLine: true }}
          target={selfElem.current}
          draggable={true}
          onDragStart={(e) => {
            e.set(frame.translate)
          }}
          onDrag={(e) => {
            setFrame({ translate: e.beforeTranslate })
          }}
          onRender={(e) => {
            const { translate } = frame
            e.target.style.transform = `translate(${translate[0]}px, ${translate[1]}px)`
          }}
        />
      )}

      <div ref={selfElem} className={centerOverlayClass}>
        <div
          style={{
            backgroundColor: getThemeColorVar("typo", "light"),
            height: "36x",
            width: "100%",
            textAlign: "right",
            borderRadius: "4px 4px 0 0",
          }}
        >
          <div
            className={css({
              i: { fontSize: "24px" },
              cursor: "pointer",
              padding: "10px",
              width: "min-content",
              marginLeft: "auto",
            })}
            onClick={(e) => setShowOverlay(false)}
          >
            <Icon name={"close"} color={"background"} colorType={"default"} />
          </div>
        </div>

        <div>
          <img
            src="/assets/cheatsheet.png"
            alt="Cheat sheet"
            style={{ padding: "12px", width: "920px", height: "660px", maxWidth: "inherit" }}
          />
        </div>
        <div style={{ textAlign: "right", padding: "0 12px 12px 12px" }}>
          <a href={districtdataDocumentationURL} onClick={() => setShowOverlay(false)} target="_blank">
            {t.districtData.readMore}
          </a>
        </div>
      </div>
    </div>
  )
}

export default MoveableHelp
