import Axios from "axios"
import { setWithCredentials } from "ol/featureloader"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./relas/store"
import { default as React } from "react"
import { RelasMainFrame } from "./relas/main-frame"
import { RevoMenu } from "./menu/revo-menu"
import { router } from "./relas/router"

function initApp() {
  Axios.defaults.withCredentials = true
  setWithCredentials(true)

  const appContentDiv = document.getElementsByClassName("revoMenu__menuWrapper")

  if (appContentDiv && appContentDiv.length > 0) {
    const firstDiv = appContentDiv.item(0) as HTMLElement
    firstDiv.style.height = "100%"
    firstDiv.style.gridTemplateRows = "fit-content(100%) 1fr"

    const root = createRoot(firstDiv)
    root.render(
      <Provider store={store}>
        <RevoMenu />
        <div id="revo-content">
          <RelasMainFrame />
        </div>
      </Provider>
    )
    void router.handleUri(window.location.pathname + window.location.search)
  }
}
initApp()
