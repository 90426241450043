import React, { useEffect } from "react"
import { Flex } from "../../../shared/components/ui/flex"
import { FlexItem } from "../../../shared/components/ui/flex-item"
import { language } from "../../../shared/i18n/language"
import { ScrollBox } from "../../../shared/components/ui/scroll-box"
import { translations } from "../../i18n"
import { Tooltip } from "../../../shared/components/ui/tooltip"
import { Checkbox, Collapse, InputGroup, Intent, Menu, MenuDivider, MenuItem, Popover } from "@blueprintjs/core"
import { css } from "emotion"
import { ReferenceAreaComparisonType } from "../../models/market-data"
import { categorizedDataListForDistricts, categorizedDataListForMunicipalities } from "../district-data"
import { Button as ButtonBP } from "@blueprintjs/core/lib/esm/components/button/buttons"
import { useDebounce } from "../../../utils/use-debounce"
import { Category, DistrictProp, LANA_DISTRICT_PROPS, Topic } from "../../../shared/smartdata-products/smartdata"
import { clearSelectedData, toggleSelectedData } from "../../reducers/market-data-slice"
import { useAppSelector } from "../../../relas/store"
import Grid from "../../../shared/components/restyle-grid/grid"
import { getThemeColor } from "../../../shared/helper/color"
import Icon from "../../../shared/components/icon"

const styles = {
  listItem: css({
    borderBottom: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
    cursor: "pointer",
  }),
}

export interface CollapsedItemType {
  micro: Set<string>
  macro: Set<string>
}

export interface DataByTopic {
  topic: Topic
  data: DistrictProp[]
}
export interface DataByCategory {
  category: Category
  data: DataByTopic[]
}

interface Props {
  areaComparisonScope: ReferenceAreaComparisonType
  collapsedCategories: CollapsedItemType
  collapsedTopics: CollapsedItemType
  setCollapsedCategories: (category: CollapsedItemType) => void
  setCollapsedTopics: (topic: CollapsedItemType) => void
  inDashboardWidget?: boolean
  selectedInWidget?: Set<string>
  setSelectedDistrictDataInDrawer?: (selected: Set<string>) => void
}

const DataList = ({
  areaComparisonScope,
  collapsedCategories,
  collapsedTopics,
  setCollapsedCategories,
  setCollapsedTopics,
  inDashboardWidget,
  selectedInWidget,
  setSelectedDistrictDataInDrawer,
}: Props) => {
  const t = React.useMemo(translations, [translations])

  const dataSet = useAppSelector((state) => state.marketDataApp.dataSet)
  const macroSelection = useAppSelector((state) => state.marketDataApp.macroSelection)
  const microSelection = useAppSelector((state) => state.marketDataApp.microSelection)

  const langKey = language() === "de" ? "de" : "en"
  const {
    dataList: categorizedDataList,
    dataNames: allDistrictData,
    topics: allTopics,
    categories: allCategories,
  } = areaComparisonScope === "District" ? categorizedDataListForDistricts : categorizedDataListForMunicipalities

  const selectedScopeDataSet =
    inDashboardWidget && selectedInWidget
      ? selectedInWidget
      : areaComparisonScope === "District"
      ? new Set(microSelection.data)
      : new Set(macroSelection.data)

  const [debouncedScoreFilterName, scoreFilterName, setScoreFilterName] = useDebounce<string>("", 800)
  const [filteredDistrictData, setFilteredDistrictData] = React.useState<Set<string>>(allDistrictData)

  useEffect(() => {
    if (debouncedScoreFilterName === "") {
      setFilteredDistrictData(allDistrictData)
    } else {
      const lowercasedSearchTerm = debouncedScoreFilterName.toLowerCase()

      const filteredScores = categorizedDataList.reduce<string[]>((acc, dataByCategory) => {
        const data = dataByCategory.data.flatMap((dataByTopic) => dataByTopic.data)
        const filteredBySearchTerm = data.filter(
          (s) =>
            s.title[langKey].toLowerCase().includes(lowercasedSearchTerm) ||
            s.description[langKey].toLowerCase().includes(lowercasedSearchTerm)
        )
        filteredBySearchTerm.forEach((data) => acc.push(data.name))
        return acc
      }, [])

      setFilteredDistrictData(new Set(filteredScores))
    }
  }, [debouncedScoreFilterName, dataSet])

  const renderDataListItem = (dataItem: DistrictProp) => {
    const onCheckboxChange = (item: string) => {
      if (inDashboardWidget && setSelectedDistrictDataInDrawer) {
        const newSelected = new Set(selectedInWidget)
        newSelected?.has(item) ? newSelected.delete(item) : newSelected.add(item)
        setSelectedDistrictDataInDrawer(newSelected)
      } else toggleSelectedData(dataSet, [item])
    }

    return (
      <div className={styles.listItem} key={"renderDataListItem-" + dataItem.name}>
        <Flex flexDirection="row" gap={4} key={dataItem.name} padding={[8, 0, 8, 32]}>
          <Checkbox
            checked={selectedScopeDataSet.has(dataItem.name)}
            style={{ marginBottom: 0 }}
            id={dataItem.name}
            onChange={() => onCheckboxChange(dataItem.name)}
          >
            {t.pickTranslation(dataItem.title)}&nbsp;
            <Tooltip
              placement="right"
              tooltip={
                <div style={{ maxWidth: "400px" }}>
                  <div>{t.pickTranslation(dataItem.description)}</div>
                  <div>
                    <b>{t.scoreSource}</b>: {t.pickTranslation(dataItem.source)}
                  </div>
                </div>
              }
            >
              <Icon name="info" fontSize={14} color="primary" colorType="default" />
            </Tooltip>
          </Checkbox>
        </Flex>
      </div>
    )
  }

  const onArrowClick = (
    ev: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    name: string,
    collapsedItems: CollapsedItemType,
    setCollapsedItems: (c: CollapsedItemType) => void
  ) => {
    ev.stopPropagation()
    ev.preventDefault()
    const c = new Set(collapsedItems[dataSet])
    if (collapsedItems[dataSet].has(name)) {
      c.delete(name)
      setCollapsedItems({ ...collapsedItems, [dataSet]: c })
    } else setCollapsedItems({ ...collapsedItems, [dataSet]: c.add(name) })
  }

  const getSetsDifference = (a: Set<string>, b: Set<string>): Set<string> => new Set([...a].filter((x) => !b.has(x)))

  const collapseNotActiveCategoriesAndTopics = () => {
    const expandedCategories = new Set<string>()
    const expandedTopics = new Set<string>()

    for (const [, value] of Object.entries(LANA_DISTRICT_PROPS)) {
      if (selectedScopeDataSet.has(value.name)) {
        expandedCategories.add(value.category)
        expandedTopics.add(value.topic)
      }
    }

    setCollapsedCategories({ ...collapsedCategories, [dataSet]: getSetsDifference(allCategories, expandedCategories) })
    setCollapsedTopics({ ...collapsedTopics, [dataSet]: getSetsDifference(allTopics, expandedTopics) })
  }

  const scoreFiltering = () => (
    <div style={{ borderBottom: "1px solid", borderColor: `${getThemeColor("border", "default")}` }}>
      <Grid columnSpec={"1fr  min-content"} gap={16} padding={16}>
        <InputGroup
          placeholder={t.scoreSearch.filter}
          style={{ borderRadius: "4px" }}
          value={scoreFilterName}
          leftElement={
            <div style={{ padding: "5px 4px" }}>
              <Icon name={"search"} color={"typo"} colorType={"lighter"} fontSize={16} />
            </div>
          }
          rightElement={
            <Tooltip placement="auto" tooltip={t.districtDataSearchInfo}>
              <div style={{ padding: "5px" }}>
                <Icon name={"info"} color={"typo"} colorType={"lighter"} fontSize={16} />
              </div>
            </Tooltip>
          }
          onChange={(event) => setScoreFilterName(event.currentTarget.value)}
        />
        <Popover
          placement="auto"
          content={
            <Menu>
              <MenuItem text={t.scoreSearch.expandActive} onClick={collapseNotActiveCategoriesAndTopics} />
              <MenuItem
                text={t.scoreSearch.expandAll}
                onClick={() => {
                  setCollapsedCategories({ micro: new Set(), macro: new Set() })
                  setCollapsedTopics({ micro: new Set(), macro: new Set() })
                }}
              />
              <MenuItem
                text={t.scoreSearch.collapseAll}
                onClick={() => {
                  setCollapsedCategories({ ...collapsedCategories, [dataSet]: allCategories })
                  setCollapsedTopics({ ...collapsedTopics, [dataSet]: allTopics })
                }}
              />
              <MenuDivider />
              <MenuItem text={t.scoreSearch.unselectAll} onClick={() => clearSelectedData(dataSet)} />
            </Menu>
          }
        >
          <ButtonBP
            style={{ borderRadius: "4px" }}
            intent={Intent.PRIMARY}
            rightIcon={"caret-down"}
            text={t.scoreSearch.groups}
          />
        </Popover>
      </Grid>
    </div>
  )

  const renderDataListInTopic = (dataListInTopic: DataByTopic) => {
    const topicCollapsed = collapsedTopics[dataSet].has(dataListInTopic.topic.name)
    return (
      <React.Fragment key={"renderDataListInTopic-" + dataListInTopic.topic.name}>
        <div
          className={styles.listItem}
          onClick={(ev) => {
            onArrowClick(ev, dataListInTopic.topic.name, collapsedTopics, setCollapsedTopics)
          }}
        >
          <Flex
            flexDirection={"row"}
            backgroundColor={`${getThemeColor("background", "lighter")}`}
            padding={[8, 0, 8, 32]}
          >
            <FlexItem flexGrow={1}>
              <b>{t.pickTranslation(dataListInTopic.topic.title)}</b>
            </FlexItem>
            <a
              onClick={(ev) => {
                onArrowClick(ev, dataListInTopic.topic.name, collapsedTopics, setCollapsedTopics)
              }}
            >
              <Icon fontSize={20} name={topicCollapsed ? "arrow_drop_down" : "arrow_drop_up"} />
            </a>
          </Flex>
        </div>
        <Collapse isOpen={!topicCollapsed}>
          {dataListInTopic.data.filter((data) => filteredDistrictData.has(data.name)).map(renderDataListItem)}
        </Collapse>
      </React.Fragment>
    )
  }

  const renderCategorizedDataList = (categorizedDataList: DataByCategory, index: number) => {
    const categoryCollapsed = collapsedCategories[dataSet].has(categorizedDataList.category.name)
    const ungroupedData = categorizedDataList.data.find((item) => item.topic.name === "ungrouped")
    return (
      <React.Fragment key={index}>
        <div
          className={styles.listItem}
          onClick={(ev) =>
            onArrowClick(ev, categorizedDataList.category.name, collapsedCategories, setCollapsedCategories)
          }
        >
          <Flex
            flexDirection={"row"}
            backgroundColor={`${getThemeColor("background", "lighter")}`}
            padding={[8, 0, 8, 16]}
          >
            <FlexItem flexGrow={1}>
              <b>{t.pickTranslation(categorizedDataList.category.title)}</b>
            </FlexItem>
            <a
              onClick={(ev) =>
                onArrowClick(ev, categorizedDataList.category.name, collapsedCategories, setCollapsedCategories)
              }
            >
              <Icon fontSize={20} name={categoryCollapsed ? "arrow_drop_down" : "arrow_drop_up"} />
            </a>
          </Flex>
        </div>
        <Collapse isOpen={!categoryCollapsed}>
          {ungroupedData && (
            <>
              {ungroupedData.data
                .filter((data) => filteredDistrictData.has(data.name))
                .map((item) => renderDataListItem(item))}
            </>
          )}
          {categorizedDataList.data
            .filter((dataByTopic) =>
              dataByTopic.topic.name !== "ungrouped"
                ? dataByTopic.data.filter((data) => filteredDistrictData.has(data.name)).length > 0
                : false
            )
            .map(renderDataListInTopic)}
        </Collapse>
      </React.Fragment>
    )
  }

  return (
    <Grid columns={1} rowSpec={"min-content min-content 1fr"} height={[100, "%"]}>
      {!selectedInWidget && (
        <div
          style={{
            fontWeight: "bold",
            padding: "16px",
            borderBottom: "1px solid",
            borderColor: `${getThemeColor("border", "default")}`,
          }}
        >
          {t.districtData.data}
        </div>
      )}
      {selectedInWidget && <div />}
      {scoreFiltering()}
      <ScrollBox>
        <Flex flexDirection="column">
          {categorizedDataList
            .filter(
              (dataByCategory) =>
                dataByCategory.data.filter(
                  (dataByTopic) => dataByTopic.data.filter((data) => filteredDistrictData.has(data.name)).length > 0
                ).length > 0
            )
            .map((c, index) => renderCategorizedDataList(c, index))}
        </Flex>
      </ScrollBox>
    </Grid>
  )
}

export default DataList
