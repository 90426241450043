import * as React from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Translations } from "../i18n/translations"
import { translations } from "../i18n"
import { LanguagePicker } from "./component-languagepicker"
import { Image } from "./component-image"
import { PersonalDataReadOnly } from "./component-personal-data-read-only"
import { UserAccountState } from "../user-profile-slice"
import { dashboardServiceUrl } from "../../app_config"
import { fetchProfile } from "../user-profile-slice"
import { getRelasAuthScopes } from "../../relas/user-state"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import RevoHeader from "../../shared/components/revo-header"
import LoadingDimmer from "../../shared/components/loadingdimmer"
import Panel from "../../shared/components/panel"
import Card from "../../shared/components/card"
import Grid from "../../shared/components/restyle-grid/grid"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"
import { GenericError } from "../../shared/helper/axios"

export const UserAccountProfile = () => {
  const translate = React.useMemo(translations, [translations])

  const profileData = useSelector((state: UserAccountState) => state.userProfile.profileData)
  const profileFetchError = useSelector((state: UserAccountState) => state.userProfile.profileFetchError)

  useEffect(() => {
    fetchProfile()
  }, [])

  const renderActualProfile = (translate: Translations) => {
    const authCookiesTS = getRelasAuthScopes().filter((scope) => scope != "CA")

    const noProduct = !authCookiesTS || authCookiesTS.length === 0

    return (
      <div className="with-body-scroll">
        <LoadingDimmer loading={!profileData && !profileFetchError}>
          <FlexContainer direction="column" spaceBetween="xxl">
            {noProduct && (
              <Panel color={"negative"}>
                <Grid columns={1} rowGap="lg">
                  <div style={{ fontWeight: "bold" }}>{translate.settings.product}</div>
                  <div>{translate.genericError.noProduct}</div>
                  <div></div>
                </Grid>
              </Panel>
            )}

            <Card header={translate.settings.settingsHeader}>
              <Grid columns={1} rowGap="lg">
                <div>
                  <p>{translate.settings.language.chooseLanguage}</p>
                  <LanguagePicker />
                </div>
              </Grid>
            </Card>

            <Grid columns={2} gap="xxl">
              <Card header={translate.settings.personalDataHeader}>
                <PersonalDataReadOnly />
              </Card>

              {profileData && (
                <Image
                  imagePath={dashboardServiceUrl + "/api/profile/picture"}
                  hasImage={profileData.personalData.hasPicture || false}
                />
              )}
            </Grid>
          </FlexContainer>
        </LoadingDimmer>
      </div>
    )
  }

  const renderErrored = (error: GenericError, translate: Translations) => {
    return <GenericErrorPanel error={error} hint={translate.genericError.profileErrored} />
  }

  return (
    <Grid columns={1}>
      <RevoHeader title={translate.settings.header} />

      <Grid columns={1} paddingY="xxl" paddingX="xxl">
        {profileFetchError ? renderErrored(profileFetchError, translate) : renderActualProfile(translate)}
      </Grid>
    </Grid>
  )
}
