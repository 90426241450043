import * as React from "react"
import { Flex } from "../../../shared/components/ui/flex"
import { FlexItem } from "../../../shared/components/ui/flex-item"
import { AddressListImportError, PrivatePOIErrorResponse } from "../../models/private-data"
import { useMemo } from "react"
import { translations } from "../../i18n"
import Fileupload from "../../../shared/components/fileupload"
import GenericErrorPanel from "../../../shared/components/genericerrorpanel"
import Panel from "../../../shared/components/panel"
import Dialog from "../../../shared/components/dialog"
import Button from "../../../shared/components/button"
import LoadingSpinner from "../../../shared/components/loadingspinner"
interface UploadDialogProps {
  uploadData: (categoryId: string, file: File) => Promise<boolean>
  onCancel: () => void
  isLoading: boolean
  uploadError: AddressListImportError | null
  categoryId: string
}

export const UploadDialog = ({ uploadData, onCancel, isLoading, uploadError, categoryId }: UploadDialogProps) => {
  const t = useMemo(translations, [translations])
  const [uploadSent, setUploadSent] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (!isLoading && !uploadError && uploadSent) onCancel()
  }, [isLoading, uploadError])

  const onUpload = (file: File): Promise<any> => {
    setUploadSent(true)
    return uploadData(categoryId, file)
  }

  const onPrivatePOIError = (errorResponseBody: PrivatePOIErrorResponse): string => {
    if (errorResponseBody.details) {
      const untranslatedError =
        typeof errorResponseBody.details === "string" ? errorResponseBody.details : errorResponseBody.details.error
      const translated = t.privateData.fileUpload.fileUploadErrorResponse[untranslatedError]
      if (typeof errorResponseBody.details !== "string" && errorResponseBody.details.error === "MissingColumns") {
        return translated.replace(
          "{}",
          errorResponseBody.details.missing
            .map((missingCol: "postalCode" | "city" | "street") => t.privateData.fileUpload.missingColumns[missingCol])
            .join(", ")
        )
      } else {
        return translated
      }
    } else {
      return errorResponseBody.message
    }
  }

  const renderError = (error: AddressListImportError) => {
    if (error.status === 400) {
      return <Panel color="negative">{onPrivatePOIError(error.data)}</Panel>
    } else return <GenericErrorPanel error={error} />
  }

  return (
    <Dialog onClose={onCancel} closeOnClickOutside>
      <Flex flexDirection="column" gap={16} padding={24}>
        <h2>{t.privateData.fileUpload.title}</h2>
        <p dangerouslySetInnerHTML={{ __html: t.privateData.fileUpload.hint }} />
        {uploadError && renderError(uploadError)}
        <Flex flexDirection="row" flexGrow={0} gap={24} alignItems="center">
          <Button type="secondary" onClick={onCancel}>
            {" "}
            {t.privateData.cancel}{" "}
          </Button>
          <FlexItem flexGrow={1} />
          {isLoading && (
            <Flex flexDirection="row" gap={8}>
              <FlexItem>{t.privateData.fileUpload.uploadingFile}</FlexItem>
              <div>
                <LoadingSpinner size={24} />
              </div>
            </Flex>
          )}
          <Fileupload type="primary" icon="upload" disabled={isLoading} onUpload={onUpload}>
            {t.privateData.fileUpload.selectFile}
          </Fileupload>
        </Flex>
      </Flex>
    </Dialog>
  )
}
