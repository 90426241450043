import * as React from "react"
import { IconName } from "@21re/revoicons/dist/icon-type"
import { cx } from "emotion"
import Button from "../shared/components/button"

interface Props {
  icon?: IconName
  onClick: () => void
  isImpersonating: boolean
}
export const RevoMenuItemButton = ({ icon, onClick, isImpersonating }: Props) => {
  return (
    <div
      className={cx({
        revoMenuItem__button: true,
        impersonate: isImpersonating,
      })}
    >
      <Button type={"tertiary"} icon={icon ?? undefined} onClick={onClick} />
    </div>
  )
}
