import { MenuTranslations } from "./translations"

export const DE: MenuTranslations = {
  appDrawerTeaser: "Weitere Anwendungen und Profil",
  menuTeaser: "Menü öffnen",
  openMenu: "Menü aufklappen",
  popupMenu: {
    logout: "Logout",
    profileSettings: "Profil-Einstellungen",
    securitySettings: "Sicherheitseinstellungen",
    support: "Support",
    imprint: "Impressum",
    privacy: "Datenschutz",
    tac: "AGB",
    releaseNotes: "Neuigkeiten",
  },
  appDrawer: {
    switchApps: "Anwendung wechseln",
    loggedInAs: "Angemeldet als",
    logout: "Logout",
    myAccount: "Mein Konto",
    links: {
      support: {
        label: "Support",
        url: "mailto:support@21re.de",
        overThisLine: "Bitte schreiben Sie ihr Feedback über dieser Linie",
      },
      imprint: {
        label: "Impressum",
        url: "https://21re.de/impressum?lang=de",
      },
      privacy: {
        label: "Datenschutz",
        url: "https://21re.de/datenschutz?lang=de",
      },
      tac: {
        label: "AGB",
        url: "https://21re.de/agb?lang=de",
      },
    },
  },
  apps: {
    admin: {
      title: "Verwaltung",
      submenus: {
        companySettings: "Firmeneinstellungen",
        apiAdmin: "API Tokens verwalten",
        companyUsers: "Benutzerverwaltung",
        products: "Produktnutzung",
      },
    },
    account: {
      title: "Mein Konto",
      submenus: {
        profile: "Profil",
        security: "Sicherheit",
        login: "Login",
      },
    },
    valuation: {
      title: "Bewertung",
      submenus: {
        home: "Übersicht",
      },
    },
    locationAnalytics: {
      title: "RELAS",
      submenus: {
        quickstart: "Quickstart",
        profileManager: "Profile Manager",
        locationAssessment: {
          name: "Location Assessment",
          modules: {
            dashboard: "Bewertungsübersicht",
            assessment: "Scores",
            ratings: "Ratings",
            prices: "Preise",
            comparables: "Comparables",
            marketdata: "Quartiersdaten",
            poiExplorer: "POI Explorer",
            specialMaps: "Sonderkarten",
            rentindex: "Mietspiegel",
            fundamentalData: "Basisdaten",
          },
        },
        locationExplorer: "Location Explorer",
        privateData: "Private Data",
        locationSelector: "Location Selector",
        ratingManager: "Rating Manager",
      },
    },
  },
  admin: {
    admin: "Verwaltung",
    companyDefaults: "Firmen Defaults",
    calculationMethods: "Berechnungsmethoden",
    apiTokens: "API token verwalten",
    productUsage: "Produktnutzung",
    companyMembers: "Benutzerverwaltung",
    companySettings: "Firmeneinstellungen",
    autoassign: "Zuweisungs-App",
  },
  sidebar: {
    settings: "Einstellungen",
    monitors: "Externe Monitore",
    notifications: "Benachrichtigungen",
    logout: "Logout",
    feedback: "Feedback",
  },
  forum: "Forum",
  login: "Login",
  registration: "Registrierung",
  profile: {
    logout: "Abmelden",
    language: "Sprache",
    templates: "Vorlagen",
    settings: "Einstellungen",
    monitor: "Externe Monitore",
    statusAndInvoice: "Status & Rechnungen",
    deleteAccount: "Account löschen",
  },
  imprint: "Impressum",
  terms: "AGB",
  privacy: "Datenschutz",
  relativeImprintLink: "impressum?lang=de",
  relativePrivacyLink: "datenschutz?lang=de",
  relativeTermsLink: "agb?lang=de",
  wrongBrowser: "Diese Seite ist optimiert für: ",
  languageCode: "de",
  legal: {
    contact: "Kontakt",
    imprint: "Impressum",
    agb: "AGB",
    dataProtection: "Datenschutz",
  },
  reloginPortal: {
    title: "Ihre Session ist abgelaufen. Bitte loggen Sie sich erneut ein.",
  },
}
