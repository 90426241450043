import * as React from "react"
import * as moment from "moment"
import { translations } from "../i18n"
import { ApiToken } from "../models/api-token"
import { revokeToken } from "../reducers/api-slice"
import Dialog from "../../shared/components/dialog"
import DialogFooter from "../../shared/components/dialogfooter"
import Button from "../../shared/components/button"
import Grid from "../../shared/components/restyle-grid/grid"

export const ApiTokenRevoke = (props: { apiToken: ApiToken }) => {
  const translate = translations().api
  const { apiToken } = props
  const [showDialog, setShowDialog] = React.useState(false)

  const maybeLastUsed = apiToken.lastUsed && moment(new Date(apiToken.lastUsed * 1000))
  const lastUsed = maybeLastUsed
    ? `${maybeLastUsed.format(translate.dateTimeFormat)} (${maybeLastUsed.fromNow()})`
    : "-"

  const openDialog = () => {
    setShowDialog(true)
  }

  const closeDialog = () => {
    setShowDialog(false)
  }

  const onRevokeToken = () => {
    void revokeToken(props.apiToken.id).then((_) => setShowDialog(false))
  }

  return (
    <>
      {showDialog && (
        <Dialog
          icon="warning"
          modifier="danger"
          onClose={closeDialog}
          title={translate.apiToken.revokeToken}
          closeButton={true}
          closeOnClickOutside={true}
        >
          <Grid columns={1} gap="md" paddingX="lg" paddingY="xxl">
            <p>{translate.apiToken.revokeExplanation}</p>
            <br />
            <Grid columnSpec="fit-content(100%) 1fr" gap="md">
              <div>{translate.apiToken.label}:</div>
              <div>{apiToken.label}</div>
              <div>{translate.apiToken.lastUsed}:</div>
              <div>{lastUsed}</div>
            </Grid>
          </Grid>
          <DialogFooter spaceBetween="md" md-justify="spaceBetween" paddingX="md" paddingY="lg" direction="row">
            <Button type="secondary" danger onClick={closeDialog}>
              {translate.cancel}
            </Button>
            <Button type="primary" danger onClick={onRevokeToken}>
              {translate.apiToken.revoke}
            </Button>
          </DialogFooter>
        </Dialog>
      )}
      <Button size="small" danger type="secondary" onClick={openDialog}>
        {translate.apiToken.revoke}
      </Button>
    </>
  )
}
