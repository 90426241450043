import * as React from "react"
import { Translations } from "./translations"

export const DE: Translations = {
  stepOne: {
    houseNumberTooLong: "Hausnummer zu lang",
    header: "Registrierung",
    nameLabel: "Vorname",
    surnameLabel: "Nachname",
    areaCodeLabel: "Ländervorwahl",
    areaCodes: {
      germany: "Deutschland: +49",
      austria: "Österreich: +43",
      switzerland: "Schweiz: +41",
    },
    phoneLabel: "Mobilnummer",
    emailLabel: "E-Mail-Adresse",
    registerButtonText: "Registrieren",
    wrongPhoneHeader: "Fehler",
    wrongPhone: (given: string) =>
      `Die angegebene Telefonnummer '${given}' ist nach Angaben unseres Providers ungültig.`,
    invalidPhone: "Die Telefonnummer darf nur Zahlen enthalten.",
    emailTakenHeader: "Email vergeben",
    emailTaken: (given: string) => `Die angegebene Email '${given}' ist leider schon vergeben.`,
    invalidEmail: "Ungültige E-Mail-Adresse",
  },
  stepTwo: {
    header: "Verifizierungs-Email",
    text: "Vielen Dank für Ihre Registrierung! Sie erhalten jetzt eine E-Mail mit einem Verifizierungslink, der 48 Stunden lang gültig ist. Bitte klicken Sie auf diesen Link in der E-Mail, um die Registrierung abzuschließen.",
  },
  verifyEmail: {
    header: "Verifizierung der Email",
    text: "Bitte haben Sie Geduld, während wir ihre Email Adresse verifizieren.",
    codeExpiredTitle: "Code abgelaufen",
    codeExpiredBody:
      "Ihr E-Mail Link ist abgelaufen. Dieser ist nur 48 Stunden gültig. Hier können Sie einen neuen Email-Link anfordern.",
    contactSupport: "kontaktieren Sie bitte support@21re.de",
    resendCodesLabel: "Neuen E-Mail Verifizierungslink",
    userAlreadyVerifiedTitle: "Sie sind schon verifiziert",
    userAlreadyVerifiedBody:
      'Ihre Verifikation ist schon erfolgt. Klicken Sie bitte auf "Login", um die Software zu benutzen.',
    loginServiceLinkLabel: "Login",
  },
  stepThree: {
    header: "Registrierung",
    password1Label: "Passwort",
    password2Label: "Passwort wiederholen",
    businessCompanyNameLabel: "Firma",
    streetLabel: "Straße",
    houseNumberLabel: "Hausnr.",
    additionalAddressInformationLabel: "Zusätzliche Adressinformationen",
    countryLabel: "Land",
    zipCodeLabel: "PLZ",
    cityLabel: "Stadt",
    agbLabel: (
      <>
        Hiermit bestätige ich die{" "}
        <a href="https://21re.de/agb?lang=de" target="_blank">
          AGB
        </a>{" "}
        und die{" "}
        <a href="https://21re.de/datenschutz?lang=de" target="_blank">
          Datenschutzrichtlinien
        </a>
        .
      </>
    ),
    countries: {
      germany: "Deutschland",
      austria: "Österreich",
      switzerland: "Schweiz",
    },
    registerButtonText: "Registrieren",
    passwordTooShort: "Ihr gewähltes Passwort ist zu kurz (mind. 9 Zeichen)!",
    passwordTooLong: "Ihr gewähltes Passwort ist zu lang (max. 60 Zeichen)!",
    passwordsAreNotEqual: "Passwörter müssen übereinstimmen",
  },
  complete: {
    header: "Registrierung abgeschlossen",
    text: `Vielen Dank für Ihre Registrierung. Wir wünschen Ihnen viel Erfolg beim Nutzen der 21st-Software.`,
  },
  links: {
    iAlreadyHaveAnAccount: "Ich habe bereits einen Zugang",
    imprint: "Impressum",
    terms: "AGB",
    privacy: "Datenschutz",
    imprintLink: "https://21re.de/impressum?lang=de",
    privacyLink: "https://21re.de/datenschutz?lang=de",
    termsLink: "https://21re.de/agb?lang=de",
  },
}
