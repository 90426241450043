import React, { useEffect, useMemo } from "react"
import { COLORS } from "../../shared/components/ui/colors"
import { translations } from "../../assessment/i18n"
import { useSelector } from "react-redux"
import { State } from "../../assessment/reducers/state"
import { BarGraph } from "../../assessment/components/assessment-entry-bar-graph"
import { selectedObjectPrices } from "../../assessment/components/assessment-entry-object-price-inputs"
import { valuesOfPriceRange } from "../../utils/utils"
import { PriceRange, reconstructUsageType } from "../../assessment/models/assessment"
import { scaleForWidth } from "../../assessment/components/assessment-entry-price-graphs"
import { fetchMachineLearningUnitPrice } from "../../assessment/reducers/assessment-slice-functions"
import { formDefaultValues, UnitSettingsForm } from "./unit-settings-v2"
import { PriceWidget } from "./price-widget"

interface Props {
  unitMlPrices: PriceRange | undefined
  setUnitMlPrices: (PriceRange: PriceRange) => void
}
export const UnitPricesV2 = (props: Props) => {
  const t = useMemo(translations, [translations])
  const priceType = useSelector((state: State) => state.assessment.selectedPriceType)

  const objectPrices = useSelector((state: State) => state.assessment.currentObjectPrices)

  const objectPricesLoading = useSelector((state: State) => state.assessment.objectPricesLoadInProgress)

  const agsPrices = useSelector((state: State) => state.assessment.currentAssessmentEntry?.agsPrices[priceType])
  const cellPrices = useSelector((state: State) => state.assessment.currentAssessmentEntry?.cellPrices[priceType])

  const assessmentEntry = useSelector((state: State) => state.assessment.currentAssessmentEntry)

  const markerLocation = assessmentEntry?.address.location
  const cellId = assessmentEntry?.cellId

  const priceParams: UnitSettingsForm | undefined = useSelector(
    (state: State) => state.assessment?.objectPricesParams || undefined
  )

  useEffect(() => {
    if (!markerLocation) return

    const requestParams = priceParams || formDefaultValues(assessmentEntry)

    if (!requestParams) return

    fetchMachineLearningUnitPrice(
      markerLocation,
      cellId,
      reconstructUsageType(requestParams.houseOrApartment, priceType),
      requestParams
    ).then(
      (success) => props.setUnitMlPrices(success),
      () => {}
    )
  }, [markerLocation, cellId, priceParams, priceType])

  const allPriceValues: number[] = [
    ...valuesOfPriceRange(agsPrices),
    ...valuesOfPriceRange(cellPrices),
    ...valuesOfPriceRange(selectedObjectPrices(priceType, objectPrices)),
  ]

  const scaleChart = scaleForWidth(allPriceValues)

  return (
    <PriceWidget title={t.prices.unitPrices.header} info={t.prices.unitPrices.info}>
      <div style={{ alignContent: "center" }}>
        <BarGraph
          label={
            priceType === "plotSale"
              ? t.assessmentEntryDetails.usageType.plot
              : t.assessmentEntryDetails.objectPriceLabel
          }
          color={COLORS.primary.default}
          data={props.unitMlPrices}
          labelWidth={"120px"}
          scaleForWidth={scaleChart}
          perSqmOrTotal={priceParams?.perSqmOrTotal}
          area={priceParams?.area}
          showSpinners={objectPricesLoading}
          colorLabel={false}
        />
      </div>
    </PriceWidget>
  )
}
