import { css, cx } from "emotion"
import React, { FormEvent, useEffect } from "react"
import { Radio, RadioGroup } from "@blueprintjs/core"
import { translations } from "../../assessment/i18n"
import { useSelector } from "react-redux"
import { State } from "../../assessment/reducers/state"
import { AssessmentPriceType, YieldType } from "../../assessment/models/assessment"
import {
  setAssessmentSelectedPriceType,
  setAssessmentSelectedYieldType,
} from "../../assessment/reducers/assessment-slice-functions"
import { trackUsageEvent } from "../../utils/usage-tracking"
import { PricesPageViewType } from "./prices-module-grid-v2"

const styles = {
  sectionTitle: css({
    marginTop: "21px",
    fontWeight: "bold",
    fontSize: "17px",
  }),
  firstSwitchButtonClass: css({
    backgroundColor: "white",
    width: "100%",
  }),
  contentSelectorRadioButtonsGroup: css({
    div: { paddingBottom: "16px" },
    label: {
      paddingBottom: "12px",
      span: { marginRight: "24px !important" },
    },
  }),
}

interface Props {
  pageView: PricesPageViewType
}

export const ContentSelector = ({ pageView }: Props) => {
  const t = React.useMemo(translations, [translations])
  const currentAssessmentEntry = useSelector((state: State) => state.assessment.currentAssessmentEntry)
  const priceType = useSelector((state: State) => state.assessment.selectedPriceType)
  const yieldType = useSelector((state: State) => state.assessment.selectedYieldType)

  const [pricesRadioGroup, setPricesRadioGroup] = React.useState<AssessmentPriceType>(priceType)
  const [yieldsRadioGroup, setYieldsRadioGroup] = React.useState<YieldType>(yieldType)

  useEffect(() => {
    setPricesRadioGroup(priceType)
  }, [priceType])

  const getAssetTypeFullName = (assetType: AssessmentPriceType | YieldType): string => {
    if (pageView === "prices") {
      switch (assetType) {
        case "residentialRent":
          return `${pageView}/rent/residential`
        case "office":
          return `${pageView}/rent/office`
        case "retail":
          return `${pageView}/rent/retail`
        case "logistics":
          return `${pageView}/rent/logistics`
        case "residentialSale":
          return `${pageView}/purchase/residential`
        case "plotSale":
          return `${pageView}/purchase/plots`
        default:
          return ""
      }
    } else {
      switch (assetType) {
        case "residential":
          return `${pageView}/residential`
        case "office":
          return `${pageView}/office`
        case "retail":
          return `${pageView}/retail`
        default:
          return ""
      }
    }
  }

  const getRadioButtonSection = (): JSX.Element => {
    const onChange = (e: FormEvent<HTMLInputElement>, pricesOrYields: "prices" | "yields") => {
      const assetType = e.currentTarget.value as AssessmentPriceType | YieldType

      if (pricesOrYields === "prices") {
        setPricesRadioGroup(assetType as AssessmentPriceType)
        setAssessmentSelectedPriceType(assetType as AssessmentPriceType)
        if (pageView === "all") {
          const yieldType = (() => {
            if (["residentialRent", "office", "retail", "residentialSale"].includes(assetType)) {
              if (assetType === "residentialRent" || assetType === "residentialSale") return "residential"
              else return assetType
            } else return undefined
          })()
          if (yieldType) {
            setYieldsRadioGroup(yieldType as YieldType)
            setAssessmentSelectedYieldType(yieldType as YieldType)
          }
        }
      } else {
        setYieldsRadioGroup(assetType as YieldType)
        setAssessmentSelectedYieldType(assetType as YieldType)
      }
      if (currentAssessmentEntry) {
        trackUsageEvent("PRICES_CHANGE_ASSET_TYPE", currentAssessmentEntry?.address, getAssetTypeFullName(assetType))
      }
    }

    if (pageView === "prices" || pageView === "all")
      return (
        <RadioGroup
          className={styles.contentSelectorRadioButtonsGroup}
          selectedValue={pricesRadioGroup}
          onChange={(e) => onChange(e, "prices")}
        >
          <div className={styles.sectionTitle}>{t.prices.selector.subtitles.rent}</div>
          <Radio label={t.prices.selector.residential} value="residentialRent" large />
          <Radio label={t.prices.selector.office} value="office" large />
          <Radio label={t.prices.selector.retail} value="retail" large />
          <Radio label={t.prices.selector.logistics} value="logistics" large />
          <div className={styles.sectionTitle}>{t.prices.selector.subtitles.purchase}</div>

          <Radio label={t.prices.selector.residential} value="residentialSale" large />
          <Radio label={t.prices.selector.plots} value="plotSale" large />
        </RadioGroup>
      )
    if (pageView === "yields" || pageView === "all")
      return (
        <RadioGroup
          selectedValue={yieldsRadioGroup}
          onChange={(e) => onChange(e, "yields")}
          className={cx(styles.contentSelectorRadioButtonsGroup, css({ marginTop: "21px" }))}
        >
          <Radio label={t.prices.selector.residential} value="residential" />
          <Radio label={t.prices.selector.office} value="office" />
          <Radio label={t.prices.selector.retail} value="retail" />
        </RadioGroup>
      )
    else return <></>
  }
  return getRadioButtonSection()
}
