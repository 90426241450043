import * as React from "react"
import { BorderBottom } from "../../shared/components/ui/border-bottom"
import { Flex } from "../../shared/components/ui/flex"
import { FlexItem } from "../../shared/components/ui/flex-item"
import { Grid } from "../../shared/components/ui/grid"
import { ScrollBox } from "../../shared/components/ui/scroll-box"
import { PopupMenu } from "../../shared/components/ui/popup-menu"
import { lanaApiUrl } from "../../app_config"
import { translations } from "../i18n"
import { SelectionLabel } from "../../shared/models/ratings"
import { css } from "emotion"
import { extractLabel, SelectionResult, SelectionResults } from "../../shared/models/selection"
import { GlobalState, useAppSelector } from "../../relas/store"
import { setSelectedMunicipalityAndFocus } from "../location-selector-slice"
import { getThemeColorVar } from "../../shared/helper/color"
import { language } from "../../shared/language"
import { formatNumber, formatValue } from "../../shared/helper/number-format"
import LoadingSpinner from "../../shared/components/loadingspinner"

const wrapInBorderBottom = (element: JSX.Element) => <BorderBottom>{element}</BorderBottom>

const styles = {
  municipality: (selected: boolean) =>
    css({
      backgroundColor: selected ? getThemeColorVar("background", "lighter") : undefined,
      padding: "16px",
      cursor: "pointer",
    }),
  headerBackgroundColour: "#f5f8fa",
}

export const MunicipalityList = () => {
  const t = React.useMemo(translations, [translations])
  const selectionResults = useAppSelector((state: GlobalState) => state.locationSelector.selectionResults)
  const selectionResultsLoading = useAppSelector((state: GlobalState) => state.locationSelector.selectionResultsLoading)
  const selectedMunicipalityId = useAppSelector(
    (state: GlobalState) => state.locationSelector.selectedMunicipalityAndFocus?.selectedMunicipalityId
  )
  const currentSelection = useAppSelector((state: GlobalState) => state.locationSelector.currentSelection)

  const onDownload = () => {
    const lang = language()

    currentSelection &&
      window.open(`${lanaApiUrl}/api/selections/${currentSelection.id}/download?lang=${lang}`, "_blank")
  }

  const renderHeader = () => {
    const selectionHeader = (selectionResults: SelectionResults) => (
      <Flex flexDirection="row" padding={16} backgroundColor={styles.headerBackgroundColour}>
        <h2>
          {selectionResults && formatNumber(selectionResults.results.length)} {t.municipalitiesHeader}
        </h2>
        <FlexItem flexGrow={1} />
        <PopupMenu
          actions={[
            {
              title: t.municipalityDownload,
              onClick: onDownload,
            },
          ]}
        />
      </Flex>
    )

    const neitherHeader = () => (
      <Flex flexDirection="row" backgroundColor={styles.headerBackgroundColour} padding={16}>
        <h2>{t.municipalitiesHeader}</h2>
        <FlexItem flexGrow={1} />
        <PopupMenu
          actions={[
            {
              title: t.municipalityDownload,
              onClick: onDownload,
            },
          ]}
        />
      </Flex>
    )

    if (selectionResults && selectionResults.dataSetType === "macro") {
      return wrapInBorderBottom(selectionHeader(selectionResults))
    }

    // TODO: is neither header needed?
    return wrapInBorderBottom(neitherHeader())
  }

  const renderMunicipalities = () => {
    const renderSelectionMacroResult = (result: SelectionResult, idx: number, selectionLabel?: SelectionLabel) => {
      const selected = result.refId === selectedMunicipalityId
      const label = extractLabel(result, selectionLabel)

      return (
        <div
          key={idx}
          className={styles.municipality(selected)}
          onClick={() => setSelectedMunicipalityAndFocus({ selectedMunicipalityId: result.refId, focusInMap: true })}
        >
          <h3>{result.fields.ags_name}</h3>
          <div>
            {t.municipalityPopulation}: {formatNumber(result.fields.population as number)}
          </div>
          {label && (
            <div>
              {t.pickTranslation(label.label)}:{" "}
              {formatValue(label.value ? label.value : 0, 2, label.unit ? t.pickTranslation(label.unit) : undefined)}
            </div>
          )}
        </div>
      )
    }

    if (!selectionResultsLoading && selectionResults && selectionResults.dataSetType === "macro") {
      const currSelectionResults = selectionResults
      return currSelectionResults.results.map((result, idx) =>
        wrapInBorderBottom(renderSelectionMacroResult(result, idx, currSelectionResults.selectionLabel))
      )
    }

    return <></>
  }

  return (
    <Grid columns={1} height={[100, "%"]} rowSpec="min-content 1fr min-content">
      {renderHeader()}
      {selectionResultsLoading ? (
        <LoadingSpinner />
      ) : (
        <div className={css({ contain: "strict" })}>
          <ScrollBox>{renderMunicipalities()}</ScrollBox>
        </div>
      )}
    </Grid>
  )
}
