export function findNonApplicableNodes(nodes: [string, string[]][], target: string): string[] {
  function internal(currentNode: string, subNodes: string[], visited: string[]): string[] {
    if (currentNode === target) {
      return visited
    } else {
      return subNodes
        .map((subNode) => {
          if (visited.indexOf(subNode) >= 0) {
            return [...visited, currentNode]
          } else {
            const nextNodes = nodes.find(([nKey]) => nKey === subNode)
            if (nextNodes !== undefined) {
              return internal(subNode, nextNodes[1], [...visited, currentNode])
            } else {
              return visited
            }
          }
        })
        .reduce((acc: string[], v: string[]) => {
          return [...acc, ...v]
        }, [] as string[])
    }
  }

  let resultWithDuplicates = nodes.reduce((acc, [key, values]) => {
    if (acc.indexOf(key) >= 0) {
      return acc
    } else {
      return [...acc, ...internal(key, values, acc)]
    }
  }, [] as string[])

  return [...new Set(resultWithDuplicates)]
}
