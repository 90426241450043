import React, { useMemo } from "react"
import { MenuItem } from "@blueprintjs/core"
import { css } from "emotion"
import { translations } from "../../i18n"
import Icon from "../icon"
import { IconName } from "@21re/revoicons/dist/icon-type"
import { App, AppLocation, Apps } from "../../../menu/util/app-location-types"
import { doChangeModule } from "../../../relas/router"
import { getThemeColorVar } from "../../helper/color"

const styles = {
  dropdownItem: css({
    padding: "13px 30px",
    "span > i": {
      color: "#333333",
    },
    "&:hover": {
      outline: "none",
      textDecoration: "none",
      background: "none",
      color: getThemeColorVar("primary", "default"),
      "span > i": {
        color: getThemeColorVar("primary", "default"),
      },
    },
    "&:focus": {
      outline: "none",
      textDecoration: "none",
      background: "none",
      color: getThemeColorVar("primary", "default"),
      "span > i": {
        color: getThemeColorVar("primary", "default"),
      },
    },
  }),
}

type ScopeRequirement = {
  service: string
  scope: string
}

type CompanySettingsConfig = {
  app: App
  entry: Apps["admin"]
  icon: string
  text: string
  service: string
  path: string
  scopeRequirement: ScopeRequirement
}

interface Props {
  appLocation: AppLocation | undefined
  resetSelectedTab: () => void
}

export const CompanySettings = ({ resetSelectedTab, appLocation }: Props) => {
  const t = useMemo(translations, [translations])

  const companySettingsConfig: CompanySettingsConfig[] = [
    {
      app: "admin",
      entry: "companySettings",
      icon: "settings_config",
      text: t.header.admin.submenus.companySettings,
      service: "lana",
      path: "company/settings",
      scopeRequirement: { service: "companyadmin", scope: "CA" },
    },
    {
      app: "admin",
      entry: "companyUsers",
      icon: "user_group",
      text: t.header.admin.submenus.companyUsers,
      service: "lana",
      path: "company/members",
      scopeRequirement: { service: "companyadmin", scope: "CA" },
    },
    {
      app: "admin",
      entry: "products",
      icon: "usage",
      text: t.header.admin.submenus.products,
      service: "lana",
      path: "company/products-statistics",
      scopeRequirement: { service: "companyadmin", scope: "CA" },
    },
    {
      app: "admin",
      entry: "apiAdmin",
      icon: "key",
      text: t.header.admin.submenus.apiAdmin,
      service: "lana",
      path: "company/api-admin",
      scopeRequirement: { service: "api", scope: "CA" },
    },
  ]

  return (
    <>
      {companySettingsConfig.map((entry) => {
        return (
          <MenuItem
            key={entry.entry}
            className={styles.dropdownItem}
            style={{ paddingLeft: "36px" }}
            text={t.header.admin.submenus[entry.entry]}
            icon={<Icon name={entry.icon as IconName} />}
            href={entry.path}
            onClick={(e) => {
              resetSelectedTab()
              e.preventDefault()
              if (entry.entry !== appLocation?.section) {
                doChangeModule({ app: entry.app, section: entry.entry })
              }
            }}
          />
        )
      })}
    </>
  )
}
