import * as React from "react"
import { Select, IItemRendererProps } from "@blueprintjs/select"
import { Button, MenuItem } from "@blueprintjs/core"
import { RATING_GRADE_COLORS } from "../../../shared/models/rating-grade"
import { FC } from "react"

export interface Props {
  value: string
  onValueChange: (color: string) => void
}

function renderColor(color: string, itemProps: IItemRendererProps) {
  return (
    <MenuItem
      key={itemProps.index}
      active={itemProps.modifiers.active}
      onClick={itemProps.handleClick}
      text={
        <div
          style={{
            width: "100px",
            height: "16px",
            backgroundColor: color,
          }}
        />
      }
    />
  )
}

export const GradeColorSelect: FC<Props> = React.forwardRef((props, ref) => {
  return (
    <Select
      items={RATING_GRADE_COLORS}
      itemRenderer={renderColor}
      onItemSelect={props.onValueChange}
      filterable={false}
    >
      <Button
        text={
          <div
            style={{
              width: "16px",
              height: "16px",
              backgroundColor: props.value,
              border: "1px solid black",
            }}
          />
        }
        rightIcon="caret-down"
      />
    </Select>
  )
})
