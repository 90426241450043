import { Chart, Plugin } from "chart.js"
import { COLORS } from "../../shared/components/ui/colors"

export interface FillFromXOptions {
  datasetIndex: number
  pointIndexInDataset: number
}

declare module "chart.js" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PluginOptionsByType<TType extends ChartType> {
    fillFromX: FillFromXOptions
  }
}

function fillFromX(chart: Chart) {
  const fillFromXOpts = chart.config.options?.plugins?.fillFromX
  if (
    chart.ctx &&
    fillFromXOpts &&
    fillFromXOpts.datasetIndex !== undefined &&
    fillFromXOpts.pointIndexInDataset !== undefined
  ) {
    const ctx = chart.ctx
    const chartArea = chart.chartArea
    const fillFromPoint = chart.getDatasetMeta(fillFromXOpts.datasetIndex).data[fillFromXOpts.pointIndexInDataset]
    const fillStartX = fillFromPoint.x
    ctx.save()
    ctx.fillStyle = COLORS.background.lighter
    ctx.fillRect(fillStartX, chartArea.top, chartArea.right - fillStartX, chartArea.bottom - chartArea.top)
    ctx.restore()
  }
}

export const fillFromXPlugin: Plugin = {
  id: "fillFromXPlugin",
  beforeDraw: (chart: Chart) => {
    fillFromX(chart)
  },
}
