/* tslint:disable */
/* eslint-disable */
/**
 * Comparables Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 *
 * @export
 * @enum {string}
 */
export enum DatasourceType {
    _21Crawler = '21Crawler',
    FplusB = 'FplusB',
    Interpolation = 'interpolation',
    Immoscout = 'immoscout',
    Is24Listings = 'IS24_listings',
    Transaction = 'transaction',
    SeniorLiving = 'senior_living'
}



