import * as React from "react"
import { css } from "emotion"
import { Location } from "../../../shared/models/geo-partition"
import { fromLonLat, transformExtent } from "ol/proj"
import * as ol from "ol"
import { Point } from "ol/geom"
import * as layer from "ol/layer"
import * as source from "ol/source"
import * as style from "ol/style"
import DashboardWidget from "./dashboard-widget"
import { translations } from "../../i18n"
import { Extent } from "ol/extent"
import { OpenLayersAbstractProps, OpenLayersMap } from "../../../shared/components/openlayers-map"
import { DashboardEditMode } from "./dashboard-widget-grid"
import { germanStatesVectorTileOptions } from "../../../utils/openlayers"
import Grid from "../../../shared/components/restyle-grid/grid"
import { getThemeColor } from "../../../shared/helper/color"

interface DashboardProps extends OpenLayersAbstractProps {
  markerLocation?: Location
  onHeaderClick: () => void
  onRemoveClick: () => void
  printMode: boolean
  editMode: DashboardEditMode
  onRenderingDone: () => void
}

const mapClass = css({
  minHeight: "150px",
  height: "100%",
  position: "relative",
  backgroundColor: "#ffffff",
  borderRadius: "5px",
  overflow: "hidden",
})

const MARKER_LAYER = "MARKER_LAYER"
const GERMANY_EXTENT: Extent = [5.87, 47.27, 15.03, 55.06]

export class MacroMapWidget extends OpenLayersMap<DashboardProps> {
  private t = translations()
  private markerLayer: layer.Vector<any> | undefined = undefined
  private countryLayer: layer.VectorTile | undefined = undefined
  constructor(props: DashboardProps) {
    super(props)
  }

  componentDidMount() {
    super.componentDidMount()

    this.countryLayer = new layer.VectorTile({
      declutter: true,
      source: new source.VectorTile(germanStatesVectorTileOptions()),
      style: new style.Style({
        stroke: new style.Stroke({
          color: `${getThemeColor("background", "lighter")}`,
          width: 0.4,
        }),
        fill: new style.Fill({
          color: `${getThemeColor("primary", "default")}`,
        }),
      }),
      zIndex: 0,
    })
    this.getMap().addLayer(this.countryLayer)

    this.getMap().getControls().clear()
    this.getMap()
      .getInteractions()
      .forEach((interaction) => {
        interaction.setActive(false)
      })
    this.createMarkerLayer(false)
    this.getMap()
      .getView()
      .fit(transformExtent(GERMANY_EXTENT, "EPSG:4326", "EPSG:3857"), { padding: [5, 5, 5, 5], duration: 0 })

    this.props.onRenderingDone()
  }

  componentDidUpdate(prevProps: DashboardProps): void {
    if (this.props.markerLocation && this.props.markerLocation !== prevProps.markerLocation) {
      this.createMarkerLayer(true)
    }
  }

  createMarkerLayer(deleteExisting: boolean) {
    if (deleteExisting && this.markerLayer) this.getMap().removeLayer(this.markerLayer)

    if (this.props.markerLocation) {
      const feature = new ol.Feature({
        geometry: new Point(fromLonLat([this.props.markerLocation.lng, this.props.markerLocation.lat])),
      })
      feature.setId(MARKER_LAYER)
      this.markerLayer = new layer.Vector({
        source: new source.Vector({ features: [feature] }),
        style: new style.Style({
          image: new style.Circle({
            radius: 6,
            stroke: new style.Stroke({
              color: "#FFF",
            }),
            fill: new style.Fill({
              color: getThemeColor("secondary3", "default").string(),
            }),
          }),
        }),
        zIndex: 999,
      })

      this.getMap().addLayer(this.markerLayer)
    }
  }

  protected containerClass(): string {
    return mapClass
  }

  protected onMoveEnd() {}

  render() {
    return (
      <DashboardWidget
        header={this.t.dashboard.macroMap.header}
        onHeaderClick={this.props.onHeaderClick}
        onRemoveClick={this.props.onRemoveClick}
        headerFloating={true}
        htmlId={"macro-map-widget"}
        printMode={this.props.printMode}
        editMode={this.props.editMode}
      >
        <Grid columns={1} rowSpec="1fr" height={[100, "%"]}>
          <div className={mapClass}>{super.render()}</div>
        </Grid>
      </DashboardWidget>
    )
  }
}
