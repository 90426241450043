import { Dispatch } from "redux"
import { microRatingFirstAgsRefLoc, RatingDetail } from "../explorer/models/rating"
import Axios, { AxiosError, AxiosResponse, CancelTokenSource } from "axios"
import { lanaApiUrl } from "../app_config"
import {
  isMetaRating,
  MetaRating,
  MetaRatingCreatePayload,
  MetaRatingResult,
  Rating,
  RatingCreatePayload,
  RatingLike,
  RatingResults,
} from "../shared/models/ratings"
import { ratingManagerActions } from "./rating-manager-slice"
import { AppDispatch, store } from "../relas/store"
import { BERLIN_AGS_REF_RES_LOC } from "../shared/actions/map-actions"
import { Group, Profile } from "../profile/models/profile"
import { Location } from "../assessment/models/address"
import { PlayServiceError } from "../shared/models/ServiceErrorResponse"
import { AssessmentEntryFull } from "../assessment/models/assessment"
import { isAssessmentPartitionOutdated } from "../shared/models/geo-partition"
import { GenericError, toGenericError } from "../shared/helper/axios"

export async function doLoadRatingList(): Promise<{
  meta: Rating[] | MetaRating[]
  simple: Rating[] | MetaRating[]
} | void> {
  store.dispatch(ratingManagerActions.listLoadStart())

  try {
    const [simpleRatings, metaRatings] = await Promise.all([loadRatingList("ratings"), loadRatingList("meta_ratings")])

    store.dispatch(
      ratingManagerActions.listDone({
        simple: simpleRatings,
        meta: metaRatings,
      })
    )

    return {
      simple: simpleRatings,
      meta: metaRatings,
    }
  } catch (error) {
    store.dispatch(ratingManagerActions.listError(toGenericError(error)))
    return
  }
}

type RatingListEndpoints = {
  ratings: Rating[]
  meta_ratings: MetaRating[]
}

async function loadRatingList<T extends keyof RatingListEndpoints>(endpoint: T): Promise<RatingListEndpoints[T]> {
  const result = await Axios.get<RatingListEndpoints[T]>(`${lanaApiUrl}/api/${endpoint}`)
  return result.data
}

export function doCreateRating(
  dispatch: Dispatch
): (payload: RatingCreatePayload | MetaRatingCreatePayload) => Promise<RatingDetail | MetaRating | GenericError> {
  return async (
    payload: RatingCreatePayload | MetaRatingCreatePayload
  ): Promise<RatingDetail | MetaRating | GenericError> => {
    try {
      let result: AxiosResponse<RatingDetail | MetaRating>

      if ("ranges" in payload) {
        result = await Axios.post<MetaRating>(`${lanaApiUrl}/api/meta_ratings`, {
          ...payload,
          name: payload.name.trim(),
          ranges: payload.ranges,
        })
      } else {
        result = await Axios.post<RatingDetail>(`${lanaApiUrl}/api/ratings`, {
          ...payload,
          name: payload.name.trim(),
          dataSetType: payload.dataSetType,
          grades: payload.grades.map((g) => ({ ...g, label: g.label.trim() })),
          rules: payload.rules,
        })
      }
      dispatch(ratingManagerActions.ratingCreateDone(result.data))
      return result.data
    } catch (error) {
      const genericError = toGenericError(error)
      return genericError
    }
  }
}

export function doDeleteRating(
  dispatch: Dispatch
): (rating: RatingLike) => Promise<void | GenericError<PlayServiceError<string[]>>> {
  return (rating: RatingLike) => {
    let url: string
    if (isMetaRating(rating)) {
      url = `${lanaApiUrl}/api/meta_ratings/${encodeURIComponent(rating.id)}`
    } else {
      url = `${lanaApiUrl}/api/ratings/${encodeURIComponent(rating.id)}`
    }
    return Axios.delete(url).then(
      (success: AxiosResponse) => {
        dispatch(ratingManagerActions.ratingDeleteDone(rating))
      },
      (error: AxiosError<PlayServiceError<string[]>>) => {
        let genericError = toGenericError<PlayServiceError<string[]>>(error)
        dispatch(ratingManagerActions.listError(genericError))
        return genericError
      }
    )
  }
}

let fetchRatingToken: CancelTokenSource | undefined
export async function doFetchRatingDetail(
  id: string,
  type: "meta" | "standard"
): Promise<RatingDetail | MetaRating | GenericError> {
  fetchRatingToken?.cancel()
  fetchRatingToken = Axios.CancelToken.source()
  try {
    let result: AxiosResponse<RatingDetail | MetaRating>
    if (type === "meta") {
      result = await Axios.get<MetaRating>(`${lanaApiUrl}/api/meta_ratings/${encodeURIComponent(id)}`, {
        cancelToken: fetchRatingToken.token,
      })
    } else {
      result = await Axios.get<RatingDetail>(`${lanaApiUrl}/api/ratings/${encodeURIComponent(id)}`, {
        cancelToken: fetchRatingToken.token,
      })
    }

    return result.data
  } catch (error) {
    return toGenericError(error)
  }
}

export function doUpdateRating(
  dispatch: Dispatch
): (rating: RatingDetail | MetaRating) => Promise<RatingDetail | MetaRating | GenericError> {
  return (rating: RatingDetail) => {
    dispatch(ratingManagerActions.ratingUpdateStart())
    rating.name = rating.name.trim()

    let url: string
    if (isMetaRating(rating)) {
      url = `${lanaApiUrl}/api/meta_ratings/${encodeURIComponent(rating.id)}`
    } else {
      url = `${lanaApiUrl}/api/ratings/${encodeURIComponent(rating.id)}`
    }

    return Axios.put(url, rating).then(
      (success: AxiosResponse<RatingDetail | MetaRating>) => {
        dispatch(ratingManagerActions.ratingUpdateDone(success.data))
        return success.data
      },
      (error: AxiosError) => {
        dispatch(ratingManagerActions.updateError(toGenericError(error)))
        return toGenericError(error)
      }
    )
  }
}

export async function updateRatingLockStatus(rating: RatingLike): Promise<void> {
  let url: string
  if (isMetaRating(rating)) {
    url = `${lanaApiUrl}/api/meta_ratings/${encodeURIComponent(rating.id)}/locked`
  } else {
    url = `${lanaApiUrl}/api/ratings/${encodeURIComponent(rating.id)}/locked`
  }

  return Axios.put(url, JSON.stringify(!rating.lockedBy), {
    headers: { "Content-Type": "application/json" },
  }).then(
    () => {
      doLoadRatingList().then(
        () => {},
        () => {}
      )
    },
    (error: AxiosError) => {
      store.dispatch(ratingManagerActions.updateError(toGenericError(error)))
    }
  )
}

export function doExplorerDisplayRating(
  dispatch: Dispatch
): (
  rating: Rating | MetaRating,
  forcedAgsRefLog: string | undefined,
  assessmentEntry: AssessmentEntryFull | undefined
) => void {
  return (rating: Rating | MetaRating, forcedAgsRefLog?: string, assessmentEntry?: AssessmentEntryFull) => {
    const type = isMetaRating(rating) ? "meta" : "standard"
    return doFetchRatingDetail(rating.id, type).then(
      (result) => {
        if ("id" in result) {
          dispatch(ratingManagerActions.setCurrentRating(result))
          const viewMode = isMetaRating(rating) ? "macro" : rating.dataSetType
          !isAssessmentPartitionOutdated(viewMode, assessmentEntry) &&
            void fetchRatingResults(dispatch)(result, forcedAgsRefLog ?? microRatingFirstAgsRefLoc(result))
        } else {
          dispatch(ratingManagerActions.ratingGetError(result))
        }
      },
      (error) => {
        dispatch(ratingManagerActions.ratingGetError(toGenericError(error)))
      }
    )
  }
}

let fetchRatingResultsToken: CancelTokenSource | undefined = undefined
export function fetchRatingResults(dispatch: AppDispatch) {
  return async function (
    rating: RatingDetail | MetaRating,
    agsRefResLoc?: string,
    ruleId?: number,
    selectionRuleCount?: number
  ) {
    if (isMetaRating(rating)) {
      return
    }
    dispatch(ratingManagerActions.startLoadingRatingResults())
    fetchRatingResultsToken?.cancel()
    fetchRatingResultsToken = Axios.CancelToken.source()

    try {
      const result = await Axios.get<RatingResults>(
        `${lanaApiUrl}/api/ratings/${encodeURIComponent(rating.id)}/results`,
        {
          params: {
            agsRefResLoc,
            ratingRuleIdx: ruleId,
            selectionRuleCount: typeof selectionRuleCount !== "undefined" ? selectionRuleCount + 1 : undefined,
          },
          cancelToken: fetchRatingResultsToken.token,
        }
      )
      dispatch(ratingManagerActions.ratingResultsDone(result.data))

      if (result.data.dataSetType === "micro" && !isMetaRating(rating)) {
        dispatch(
          ratingManagerActions.setAgsRefResLoc(
            agsRefResLoc || result.data.agsRefResLoc || microRatingFirstAgsRefLoc(rating) || BERLIN_AGS_REF_RES_LOC
          )
        )
        dispatch(ratingManagerActions.setShowMunicipalityList(false))
      }
    } catch (error) {
      if (Axios.isCancel(error)) {
        return
      }
      dispatch(ratingManagerActions.ratingResultsError(toGenericError(error)))
    }
  }
}

export async function fetchProfiles(): Promise<[Profile[], Group[]]> {
  const fetchProfiles = Axios.get<Profile[]>(`${lanaApiUrl}/api/profiles`)
  const fetchProfileGroups = Axios.get<Group[]>(`${lanaApiUrl}/api/profilegroups`)

  const [profiles, profileGroups] = await Promise.all([fetchProfiles, fetchProfileGroups])

  return [profiles.data, profileGroups.data]
}

let fetchMetaResultsToken: CancelTokenSource | undefined
export async function fetchMetaResults(
  id: MetaRating["id"],
  location: Location,
  weights: MetaRating["weights"] | undefined
) {
  fetchMetaResultsToken?.cancel()
  fetchMetaResultsToken = Axios.CancelToken.source()
  const result = await Axios.post<MetaRatingResult>(
    `${lanaApiUrl}/api/meta_ratings/${id}/results`,
    {
      location: location,
      weights,
    },
    {
      cancelToken: fetchMetaResultsToken.token,
    }
  )
  return result.data
}
