import * as React from "react"
import { translations } from "../i18n"
import { PasswordForgottenState } from "../models/password-forgotten-state"
import { useAppSelector } from "../../relas/store"
import { sendPasswordForgottenEmail } from "../reducers/login-slice"
import { Translations } from "../i18n/translations"
import { UnloggedFormWrapper } from "./unlogged-form-wrapper"
import { navigateTo } from "../../shared/reducers/navigation-slice"
import { NavigationPage } from "../../shared/actions/navigation"
import Panel from "../../shared/components/panel"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"
import TextField from "../../shared/components/textfield"
import LoadingSpinner from "../../shared/components/loadingspinner"
import Button from "../../shared/components/button"
import Form from "../../shared/components/form"
import { ReValidator } from "../../shared/models/genericinputelement"

export const PasswordForgottenRequestPage = () => {
  const translate = React.useMemo(() => translations(), [])
  const footerLinks = (
    <a
      href={"/login"}
      onClick={(e) => {
        e.preventDefault()
        navigateTo({ name: NavigationPage.login })
      }}
    >
      {translate.login.backToLogin}
    </a>
  )
  return (
    <UnloggedFormWrapper title={translate.login.passwordForgotten} footerLinks={footerLinks}>
      <PasswordForgottenRequest />
    </UnloggedFormWrapper>
  )
}

export const PasswordForgottenRequest = () => {
  const translate = React.useMemo(() => translations(), [])
  const [email, setEmail] = React.useState<string | null>(null)
  const passwordForgotState = useAppSelector((state) => state.login.passwordForgottenState)

  const handleSubmit = () => {
    email && sendPasswordForgottenEmail(email)
  }

  const createMailValidator = (translate: Translations): ReValidator<string> => {
    return (value: string) => {
      return {
        valid: /^[^@]+@[^@]+\.[^@]+$/.test(value),
        validationMessage: translate.login.invalidEmail,
      }
    }
  }

  const renderInnerContents = () => {
    switch (passwordForgotState) {
      case PasswordForgottenState.nothing:
        return (
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            <FlexContainer spaceBetween="lg" direction="column">
              <TextField
                id="21re-password-forgotten-email"
                label={translate.login.email}
                hint={translate.login.emailHint}
                onValueChange={setEmail}
                customValidation={[createMailValidator(translate)]}
                value={email || ""}
                required={true}
              />
              <Button id="21re-password-forgotten-submit" disabled={!email} type="primary">
                {translate.login.sendPasswordForgotten}
              </Button>
            </FlexContainer>
          </Form>
        )
      case PasswordForgottenState.in_progress:
        return (
          <div>
            <LoadingSpinner alignLeft={false} />
          </div>
        )
      case PasswordForgottenState.error:
        return (
          <Panel title={translate.login.passwordForgottenErrHeader} color="negative">
            {translate.login.passwordForgottenErrText}
          </Panel>
        )
      case PasswordForgottenState.performed:
        return (
          <Panel title={translate.login.passwordForgottenOkHeader} color="positive">
            {translate.login.passwordForgottenOkText}
          </Panel>
        )
    }
  }

  return (
    <div style={{ padding: "0 24px" }}>
      <FlexContainer direction="column" spaceBetween="xxl">
        {renderInnerContents()}
      </FlexContainer>
    </div>
  )
}
