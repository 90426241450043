import { AssessmentTranslationsGroup } from "./translations"
import { ASSESSMENTS_DE } from "./de"
import { ASSESSMENTS_EN } from "./en"
import { language } from "../../shared/language"

export function translations(): AssessmentTranslationsGroup {
  switch (language()) {
    case "de":
      return ASSESSMENTS_DE
    default:
      return ASSESSMENTS_EN
  }
}
