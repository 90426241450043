import React, { useEffect, useMemo, useState } from "react"
import { css, cx } from "emotion"
import { translations } from "../../assessment/i18n"
import { State } from "../../assessment/reducers/state"
import Axios, { AxiosError } from "axios"
import { RentIndexQuerySuccess } from "../../assessment/models/rentindex"
import { lanaApiUrl } from "../../app_config"
import { PriceRange } from "../../assessment/models/assessment"
import { useAppSelector } from "../../relas/store"
import { UnitSettingsForm } from "./unit-settings-v2"
import GridItem from "../../shared/components/restyle-grid/griditem"
import { getThemeColorVar } from "../../shared/helper/color"
import { formatNumber } from "../../shared/helper/number-format"
import { Grid } from "../../shared/components/ui/grid"
import { PriceWidget } from "./price-widget"
import { openAssessmentModule } from "../../assessment/reducers/assessment-slice-functions"

type RentIndexError = "generic" | "notAvailableForCity" | "cannotCalculate" | false

export const RentIndexWidgetV2 = () => {
  const t = useMemo(translations, [translations])
  const { currentAssessmentEntry, currentAssessment } = useAppSelector((state: State) => state.assessment)
  const [rentIndexData, setRentIndexData] = useState<PriceRange>()
  const [rentIndexError, setRentIndexError] = useState<RentIndexError>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const rentIndexCalculatorUrl = `/rentindex/${currentAssessment?.id}/entries/${currentAssessmentEntry?.id}`

  const priceParams: UnitSettingsForm | undefined = useAppSelector(
    (state) => state.assessment?.objectPricesParams || undefined
  )

  const styles = {
    widgetContent: css({
      display: "flex",
      height: "100%",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }),
    minMaxLabels: css({
      alignSelf: "end",
      justifySelf: "center",
    }),
    avgLabel: css({
      fontSize: "16px",
      fontWeight: "bold",
      paddingBottom: "2px",
      marginLeft: "-1px",
      textAlign: "center",
    }),
    bar: css({
      backgroundColor: getThemeColorVar("primary", "default"),
      height: "12px",
      margin: "0 4px",
    }),
    line: css({
      height: "22px",
      borderLeft: "1px solid",
      borderRight: "1px solid",
      borderColor: "white",
      backgroundColor: "black",
      position: "relative",
      top: "-17px",
      width: "4px",
    }),
  }

  function openRentIndex(event: React.MouseEvent) {
    event.preventDefault()
    event.stopPropagation()
    if (currentAssessment && currentAssessmentEntry) {
      void openAssessmentModule(currentAssessment.id, currentAssessmentEntry.id, "rentindex")
    }
  }
  const calculateLink = () => {
    return (
      <>
        <a href={rentIndexCalculatorUrl} onClick={openRentIndex} target="_blank">
          <b>{t.prices.rentIndex.info.calculator}</b>
        </a>
      </>
    )
  }

  useEffect(() => {
    setLoading(true)
    Axios.post<RentIndexQuerySuccess[]>(`${lanaApiUrl}/api/v1/rentindex/calculate`, {
      address: currentAssessmentEntry?.address,
      yearOfConstruction: priceParams?.constructionYear || currentAssessmentEntry?.year || 2000,
      areas: [priceParams?.area || currentAssessmentEntry?.area || 100],
      answers: {},
    })
      .then(
        (success) => {
          setRentIndexData(success.data[0].rentIndex)
          setRentIndexError(false)
        },
        (err: AxiosError) => {
          if (err.response && err.response?.status === 424) {
            setRentIndexError("cannotCalculate")
          } else if (err.response && err.response?.status % 100 === 5) {
            setRentIndexError("generic")
          } else {
            setRentIndexError("notAvailableForCity")
          }

          setRentIndexData(undefined)
        }
      )
      .finally(() => {
        setLoading(false)
      })
  }, [
    currentAssessmentEntry?.address,
    currentAssessmentEntry?.year,
    currentAssessmentEntry?.area,
    priceParams?.area,
    priceParams?.constructionYear,
  ])

  const isRentIndexAvailable = rentIndexData && !rentIndexError && !loading

  const getAvailableMessage = () => {
    return <div>{t.prices.rentIndex.info.available(calculateLink())}</div>
  }

  const rendIndexErrorMessages = rentIndexError && t.prices.rentIndex.info.errors[rentIndexError]

  const graphLeftShift =
    rentIndexData &&
    rentIndexData.min !== rentIndexData.max! &&
    rentIndexData.min !== rentIndexData.avg &&
    rentIndexData.max !== rentIndexData.avg
      ? ((rentIndexData.avg - rentIndexData.min) / (rentIndexData.max - rentIndexData.min)) * 100
      : undefined

  return (
    <PriceWidget title={t.prices.rentIndex.header} info={getAvailableMessage()}>
      {!isRentIndexAvailable && <div style={{ alignContent: "center" }}>{rendIndexErrorMessages}</div>}
      {isRentIndexAvailable && (
        <div style={{ alignContent: "center" }}>
          {rentIndexData && !loading && (
            <Grid columns={3} columnSpec={"1fr 6fr 1fr"} colGap={4} rowGap={4} rowSpec={"min-content 24px"} padding={4}>
              <div className={styles.minMaxLabels}>
                {formatNumber(rentIndexData.min, rentIndexData.min > 99 ? 0 : 2)}
              </div>
              <div className={styles.avgLabel}>{`Ø\u00A0 ${formatNumber(
                rentIndexData.avg,
                rentIndexData.avg > 99 ? 0 : 2
              )}`}</div>
              <div className={styles.minMaxLabels}>
                {" "}
                {formatNumber(rentIndexData.max, rentIndexData.max > 99 ? 0 : 2)}
              </div>
              <GridItem colSpan={3} padding={4}>
                <div className={styles.bar} />
                <div className={cx(styles.line, css({ left: `${graphLeftShift}%` }))} />
              </GridItem>
            </Grid>
          )}

          {rentIndexData && !graphLeftShift && (
            <div className={styles.avgLabel}>{`Ø\u00A0 ${formatNumber(
              rentIndexData.avg,
              rentIndexData.avg > 99 ? 0 : 2
            )}`}</div>
          )}
        </div>
      )}
    </PriceWidget>
  )
}
