import * as React from "react"
import { css, cx } from "emotion"
import { PropsWithChildren } from "react"
import FlexContainer, { Props as FlexProps } from "./restyle-grid/flexcontainer"
import { getThemeColorVar } from "../helper/color"

const dialogFooterClass = css({
  borderTop: `1px solid ${getThemeColorVar("border", "default")}`,
  backgroundColor: getThemeColorVar("background", "default"),
})

export const DialogFooter: React.FunctionComponent<PropsWithChildren<FlexProps>> = (props) => {
  return (
    <div className={cx(dialogFooterClass)}>
      <FlexContainer {...props}>{props.children}</FlexContainer>
    </div>
  )
}

export default DialogFooter
