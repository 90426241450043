import React, { ReactNode, useEffect, useMemo, useState } from "react"
import {
  Assessment,
  AssessmentEntryFull,
  AssessmentState,
  Exclusiveness,
  isInProgressState,
  UsageType,
} from "../../assessment/models/assessment"
import { translations } from "../i18n"
import { useAppSelector } from "../../relas/store"
import { css, cx } from "emotion"
import { Text } from "@blueprintjs/core"
import { Tooltip } from "./ui/tooltip"
import useInterval from "../../utils/use-interval"
import Axios, { AxiosResponse } from "axios"
import { lanaApiUrl } from "../../app_config"
import { AssessmentEntryNotes } from "./assessment-entry-notes"
import AssessmentEntryDetailEdit from "../../assessment/components/assessment-entry-detail-edit"
import { AppModules, Apps } from "../../menu/util/app-location-types"
import { loadAssessmentForAssessmentModule } from "../../assessment/reducers/comparables-slice"
import {
  loadAssessmentEntry,
  loadAssessmentFirstEntry,
  refreshAssessmentEntries,
  resetAssessment,
  setAssessmentStatus,
  setNotesIcon,
} from "../../assessment/reducers/assessment-slice-functions"
import {
  LAST_SET_DASHBOARD_CONFIG_KEY,
  selectedUserAssessmentDashboard,
  stopDashboardEdit,
} from "../../relas/user-slice"
import { AITextGenerationOverlay } from "./ai-text-generation-overlay"
import {
  AssessmentDashboardId,
  AssessmentDynamicDashboardConfig,
  DashboardEvents,
} from "../../assessment/models/AssessmentDashboard"
import { DashboardSelectItem, MultiDashboardSelect } from "./multi-dashboard-select"
import { DeleteDashboardDialog } from "../../assessment/components/dashboard/delete-dashboard-dialog"
import { trackUsageEvent } from "../../utils/usage-tracking"
import { DashboardEditMode } from "../../assessment/components/dashboard/dashboard-widget-grid"
import Badge from "./badge"
import HorizontalDivider from "./horizontaldivider"
import Grid from "./restyle-grid/grid"
import Button from "./button"
import { getThemeColorVar } from "../helper/color"
import Icon from "./icon"
import FlexContainer from "./restyle-grid/flexcontainer"
import { headerTextColor } from "./header/lana-header"

const styles = {
  headerItem: css({
    fontSize: "14px",
    color: headerTextColor,
    borderRight: `1px solid ${getThemeColorVar("primary", "light")}`,
  }),
  headerItemGrid: css({
    display: "grid",
    gridTemplateColumns: "45px auto",
    gridTemplateRows: "40px auto",
    padding: "6px 0px 7px 2px",
    columnGap: "1px",
  }),
  headerItemNoBorder: css({
    borderRight: "none",
  }),
  title: css({
    fontWeight: "bold",
    fontSize: "18px",
    paddingTop: "6px",
  }),
  subTitle: css({
    fontSize: "14px",
  }),
  dashboardSubtitle: css({
    fontSize: "14px",
    paddingTop: "6px",
  }),
  link: css({
    cursor: "pointer",
  }),
  rotateButtonClass: css({
    "button i": {
      animation: "spin 4s linear infinite",
      animationDirection: "reverse",
    },
  }),
  headerActionsButtons: css({
    alignSelf: "center",
    alignItems: "center",
    justifySelf: "start",
    display: "flex",
    flexDirection: "row",
    flexFlow: "row-reverse",
    gap: "30px",
    padding: "0px 10px",
    borderRight: `1px solid ${getThemeColorVar("primary", "light")}`,
  }),
  arrowBackIcon: css({
    transform: "rotate(180deg)",
    alignSelf: "center",
    margin: "0 auto",
    position: "relative",
    top: "2px",
  }),
  selectContainer: css({
    "span > span > div > button": {
      width: "70% !important",
    },
  }),
  actionButtonWithLabel: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    alignItems: "center",
    fontSize: "14px",
  }),
}

type Props = {
  menuSection: Apps["locationAnalytics"]
  assessment: Assessment | null
  assessmentEntry: AssessmentEntryFull | null
  module: AppModules["locationAssessment"] | null
  recalculationButton?: boolean
  recalculationDetails?: boolean
  isLoading?: boolean
  children?: ReactNode
  printMode?: boolean
  editMode?: DashboardEditMode
  defaultDashboardWidgets?: () => AssessmentDynamicDashboardConfig
  displayDashboardId?: AssessmentDashboardId
}

const LanaSubheader = ({
  menuSection,
  assessment,
  assessmentEntry,
  module,
  recalculationButton,
  recalculationDetails,
  isLoading,
  children,
  printMode,
  defaultDashboardWidgets,
  displayDashboardId,
}: Props) => {
  const assessmentUpdateInProgress = useAppSelector((state) => state.assessment.assessmentUpdateInProgress)
  const notesIcon = useAppSelector((state) => state.assessment.notesIcon)

  const [currentAssessmentEntryStatus, setCurrentAssessmentEntryStatus] = useState<AssessmentState | null>(null)
  const [recalculationButtonPressed, setRecalculationButtonPressed] = useState<boolean>(false)
  const [showNotes, setShowNotes] = useState<boolean>(false)
  const [showAiTextGeneration, setShowAiTextGeneration] = useState<boolean>(false)
  const aiTextGenerationScopePresent = useAppSelector((state) => state.user.scopes.aiTextGeneration)

  const dashboardConfigurations = useAppSelector((state) => state.user.lana.assessmentDashboards)

  const selectedFromGlobalStateDashboardId = useAppSelector((state) => state.user.lana.selectedDashboardId)
  const selectedDashboardId = displayDashboardId || selectedFromGlobalStateDashboardId

  const selectedDashboardConfiguration = dashboardConfigurations?.find((d) => d.id === selectedDashboardId)
  const editDashboardConfiguration = useAppSelector((state) => state.user.lana.editingUserDashboards)
  const userId = useAppSelector((state) => state.user.user?.id)
  const [highlightedItem, setHighlightedItem] = useState<DashboardSelectItem>(undefined)
  const [dashboardAddMode, setDashboardAddMode] = useState<"add" | "duplicate" | undefined>(undefined)
  const currentPage = useAppSelector((state) => state.navigation.currentPage)
  const [dashboardToDelete, setDashboardToDelete] = useState<DashboardSelectItem>(undefined)

  const getNotes = async () => {
    if (assessment && assessmentEntry) {
      let success = await Axios.get(`${lanaApiUrl}/api/assessments/${assessment.id}/entries/${assessmentEntry.id}/note`)
      success.data.content.length > 0 ? setNotesIcon("note") : setNotesIcon("note_add")
    } else {
      setNotesIcon(null)
    }
  }

  const trackDashboardSelectAction = (action: DashboardEvents, dashboardName: string) => {
    if (assessmentEntry?.address) {
      trackUsageEvent(action, assessmentEntry.address, dashboardName)
    }
  }

  useEffect(() => {
    if (currentPage?.name !== "dashboard") {
      stopDashboardEdit()
    }
  }, [currentPage])

  useEffect(() => {
    getNotes().catch(() => {})
  }, [assessment && assessmentEntry])

  useEffect(() => {
    if (!showNotes) {
      getNotes().catch(() => {})
    }
  }, [showNotes])

  const t = useMemo(translations, [translations])

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)

  // null timeout will disable the callback

  useInterval(
    () => {
      if (
        assessment &&
        assessmentEntry &&
        assessmentEntry.state !== "scored" &&
        assessmentEntry.state !== "scored-ambiguous"
      ) {
        Axios.get<AssessmentState>(
          `${lanaApiUrl}/api/assessments/${assessment.id}/entries/${assessmentEntry.id}/state`
        ).then(
          async (success: AxiosResponse<AssessmentState>) => {
            setCurrentAssessmentEntryStatus(success.data.replace("-ambiguous", "") as AssessmentState)
            setAssessmentStatus(success.data)
            if (
              assessmentEntry?.state !== success.data &&
              (success.data === "scored" || success.data === "scored-ambiguous") &&
              assessment &&
              assessmentEntry?.id
            ) {
              // state was updated - refresh the page
              await loadAssessmentEntry({ ...assessment, state: success.data }, assessmentEntry.id)
            }
          },
          () => {}
        )
      }
    },
    !printMode &&
      assessment &&
      assessmentEntry &&
      assessmentEntry.state !== "scored" &&
      assessmentEntry.state !== "scored-ambiguous"
      ? 2000
      : null
  )

  useInterval(() => {
    if (currentAssessmentEntryStatus === "scored") setCurrentAssessmentEntryStatus(null)
  }, 4000)

  const usageTypeLabel = (usageType: UsageType | undefined): string => {
    switch (usageType) {
      case UsageType.Office:
        return `${t.header.priceType.rent} - ${t.header.usageType.office}`
      case UsageType.Retail:
        return `${t.header.priceType.rent} - ${t.header.usageType.retail}`
      case UsageType.Hall:
        return `${t.header.priceType.rent} - ${t.header.usageType.hall}`
      case UsageType.ResidentialApartmentRent:
        return `${t.header.priceType.rent} - ${t.header.usageType.residential}`
      case UsageType.ResidentialApartmentSale:
        return `${t.header.priceType.sale} - ${t.header.usageType.residential}`
      case UsageType.ResidentialHouseRent:
        return `${t.header.priceType.rent} - ${t.header.usageType.residential}`
      case UsageType.ResidentialHouseSale:
        return `${t.header.priceType.sale} - ${t.header.usageType.residential}`
      default:
        return `${t.header.priceType.rent} - ${t.header.usageType.residential}`
    }
  }

  const houseOrApartment = (type: UsageType): string => {
    switch (type) {
      case UsageType.ResidentialHouseSale:
      case UsageType.ResidentialHouseRent:
        return `${t.header.priceCategories.house}`
      case UsageType.ResidentialApartmentSale:
      case UsageType.ResidentialApartmentRent:
        return `${t.header.priceCategories.apartment}`
      default:
        return ""
    }
  }

  const onRecalculate = () => {
    const currentAssessment = assessment

    if (!currentAssessment) return
    setRecalculationButtonPressed(true)
    resetAssessment(currentAssessment.id).then(
      (assessment) => {
        if (currentAssessment.numberOfAddresses == 1) {
          void (module === "dashboard"
            ? loadAssessmentForAssessmentModule(currentAssessment.id, assessmentEntry?.id ?? null, module)
            : loadAssessmentFirstEntry(assessment, false))
        } else {
          void refreshAssessmentEntries(currentAssessment.id, assessmentEntry?.id, module)
        }
      },
      () => {}
    )
  }

  const getColoursForBadge = (state: AssessmentState) => {
    switch (state) {
      case "failure":
        return "secondary3"
      case "scored":
        return "positive"
      default:
        return "neutral"
    }
  }
  const renderAssessmentQuality = () => {
    if (!assessmentEntry?.exclusiveness) return <div>{"\u00A0★\u00A0"}</div>
    const qualityStars = {
      [Exclusiveness.Standard]: "\u00A0★\u00A0",
      [Exclusiveness.High]: "\u00A0★★\u00A0",
      [Exclusiveness.Highest]: "\u00A0★★★\u00A0",
    }

    return <div>{qualityStars[assessmentEntry?.exclusiveness] || ""}</div>
  }

  const isAssessmentList = () => menuSection === "locationAssessment" && assessment === null

  const selectDashboard = (id: string) => {
    const selectedDashboard = dashboardConfigurations?.find((config) => config.id === id)

    if (selectedDashboard) {
      selectedUserAssessmentDashboard(selectedDashboard.id)
    }
  }

  const handleItemClick = (item: DashboardSelectItem) => {
    if (item && item.id !== selectedDashboardId) {
      userId && window.localStorage.setItem(LAST_SET_DASHBOARD_CONFIG_KEY(userId), item.id)
      selectDashboard(item.id)
      trackDashboardSelectAction("DASHBOARD_CHANGE_CURRENT", item.name)
    }
  }

  return (
    <Grid
      bordersColor={{ color: "primary", colorType: "light" }}
      backgroundColor={{ color: "background", colorType: "lighter" }}
      columns={3}
      columnSpec={`${
        menuSection === "locationSelector" || menuSection === "ratingManager" || isAssessmentList() ? "" : "270px"
      } ${module === "dashboard" ? "min-content" : ""} 1fr`}
      padding={[12, 32]}
      height={[80, "px"]}
      className={css({ alignContent: "center" })}
    >
      {menuSection === "privateData" && (
        <div className={cx(styles.headerItem, styles.headerItemGrid)}>
          <div style={{ justifySelf: "center", paddingTop: "2px" }}>
            {!isLoading && <img src={"/assets/app-header/location.svg"} alt="location" />}
          </div>
          {!isLoading && (
            <Text ellipsize={true} className={styles.title}>
              {t.header.pois}
            </Text>
          )}
        </div>
      )}

      {assessment && assessmentEntry && (
        <>
          <AssessmentEntryDetailEdit
            isOpen={isEditDialogOpen}
            toggle={() => {
              setIsEditDialogOpen(false)
            }}
          />
          <div className={cx(styles.headerItem, printMode ? styles.headerItemNoBorder : null)}>
            {!isLoading && (
              <Grid columnSpec={"auto 1fr"} gap={8} className={css({ alignItems: "end", height: "100%" })}>
                <FlexContainer direction={"column"}>
                  <FlexContainer direction={"row"} md-align={"center"} gap={4}>
                    <div>{usageTypeLabel(assessmentEntry.usageType)}</div>
                    <HorizontalDivider height={16} color={headerTextColor} />
                    <div>{`${t.yearOfConstructionAbbreviation}: ${assessmentEntry?.year ?? 2000}`}</div>
                    <HorizontalDivider height={16} color={"headerTextColor"} />
                  </FlexContainer>
                  <FlexContainer direction={"row"} md-align={"center"} gap={4}>
                    <div>{`${assessmentEntry?.area ?? 100} \u00A0m²`}</div>
                    <HorizontalDivider height={16} color={headerTextColor} />
                    {renderAssessmentQuality()}
                    <HorizontalDivider height={16} color={headerTextColor} />
                    {houseOrApartment(assessmentEntry.usageType ?? UsageType.ResidentialApartmentRent)}
                  </FlexContainer>
                </FlexContainer>
                <Tooltip placement="bottom" tooltip={t.header.changeAddressOrTitleTooltip}>
                  <Button type={"tertiary"} size={"small"} icon={"edit"} onClick={() => setIsEditDialogOpen(true)} />
                </Tooltip>
              </Grid>
            )}
          </div>
        </>
      )}

      {module === "dashboard" && dashboardConfigurations && (
        <Grid rowSpec={"1fr"} columnSpec={"3fr 1fr"} className={"appHeaderMultiDashboardSelectRedesign"}>
          {!printMode && (
            <MultiDashboardSelect
              dashboardConfigurations={dashboardConfigurations}
              editDashboardConfiguration={editDashboardConfiguration}
              selectedDashboardConfiguration={selectedDashboardConfiguration}
              highlightedItem={highlightedItem}
              setHighlightedItem={setHighlightedItem}
              handleItemClick={handleItemClick}
              stopEdit={stopDashboardEdit}
              dashboardAddMode={dashboardAddMode}
              setDashboardAddMode={setDashboardAddMode}
              setDashboardToDelete={setDashboardToDelete}
              defaultConfig={defaultDashboardWidgets}
              trackDashboardSelectAction={trackDashboardSelectAction}
            />
          )}
          {printMode && selectedDashboardConfiguration && (
            <div style={{ marginTop: "6px" }}>{selectedDashboardConfiguration.name}</div>
          )}
          {editDashboardConfiguration && (
            <div
              style={{
                display: "flex",
                color: getThemeColorVar("primary", "default"),
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  borderRadius: "50%",
                  cursor: "pointer",
                  overflow: "hidden",
                  height: "21px",
                  width: "21px",
                }}
                onClick={stopDashboardEdit}
              >
                <Icon name={"checkbox_checked"} size={"large"} fontSize={22} />
              </div>
            </div>
          )}
        </Grid>
      )}

      {(!assessment || !assessmentEntry) && <div></div>}
      {menuSection === "profileManager" && <div></div>}
      {menuSection === "quickstart" && <div></div>}
      {!printMode && (
        <div
          className={
            module === "dashboard"
              ? cx(
                  styles.headerActionsButtons,
                  css({ borderLeft: `1px solid ${getThemeColorVar("primary", "light")}` })
                )
              : styles.headerActionsButtons
          }
        >
          <div style={{ alignSelf: "center" }}>
            {!isLoading && recalculationButton && (
              <div style={{ display: "flex", flexDirection: "row", paddingLeft: "12px" }}>
                {assessment &&
                  (assessment.outdated ||
                    assessmentUpdateInProgress ||
                    (assessmentEntry
                      ? isInProgressState(assessmentEntry.state)
                      : isInProgressState(assessment.state))) && (
                    <Tooltip placement="bottom" tooltip={t.header.recalculateHint}>
                      <div
                        className={
                          assessmentUpdateInProgress || isInProgressState(assessment.state)
                            ? styles.rotateButtonClass
                            : ""
                        }
                      >
                        <Button
                          type="primary"
                          size="normal"
                          icon="refresh"
                          danger
                          disabled={
                            assessmentUpdateInProgress ||
                            (assessmentEntry
                              ? isInProgressState(assessmentEntry.state)
                              : isInProgressState(assessment.state)) ||
                            recalculationButtonPressed
                          }
                          onClick={onRecalculate}
                        >
                          {t.header.recalculate}
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                {recalculationDetails && (
                  <div style={{ alignSelf: "center" }}>
                    {currentAssessmentEntryStatus && currentAssessmentEntryStatus !== "failure" && (
                      <Badge color={getColoursForBadge(currentAssessmentEntryStatus)}>
                        {t.header.stateStatus[currentAssessmentEntryStatus]}
                      </Badge>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          {children}
          {notesIcon && (
            <>
              <div className={styles.actionButtonWithLabel}>
                <a onClick={() => setShowNotes(true)}>
                  <Icon name={notesIcon} size={"regular"} />
                </a>
                <div>{t.header.notesTooltip}</div>
              </div>
            </>
          )}
          {assessmentEntry && aiTextGenerationScopePresent && (
            <>
              <div className={styles.actionButtonWithLabel}>
                <a onClick={() => setShowAiTextGeneration(true)}>
                  <Icon name="AI" size={"regular"} />
                </a>
                <div>{t.header.aiTextGenerationLabel}</div>
              </div>
              <div onClick={() => setShowAiTextGeneration(true)} />
            </>
          )}
        </div>
      )}
      {showNotes && assessment && assessmentEntry && (
        <AssessmentEntryNotes
          assessmentId={assessment?.id}
          assessmentEntry={assessmentEntry}
          onClose={() => setShowNotes(false)}
        />
      )}
      {showAiTextGeneration && assessment && assessmentEntry && (
        <AITextGenerationOverlay
          assessmentId={assessment.id}
          assessmentEntryId={assessmentEntry.id}
          assessmentEntryAddress={assessmentEntry.address}
          onClose={() => setShowAiTextGeneration(false)}
        />
      )}
      {dashboardConfigurations && dashboardToDelete && assessment && assessmentEntry && (
        <DeleteDashboardDialog
          dashboardToDelete={dashboardToDelete}
          dashboards={dashboardConfigurations}
          setDashboardToDelete={setDashboardToDelete}
          selectedDashboard={selectedDashboardConfiguration}
          setHighLighted={setHighlightedItem}
        />
      )}
    </Grid>
  )
}

export default LanaSubheader
