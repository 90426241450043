import { BorderBottom } from "../../shared/components/ui/border-bottom"
import { Flex } from "../../shared/components/ui/flex"
import { Grid } from "../../shared/components/ui/grid"
import { GridItem } from "../../shared/components/ui/grid-item"
import { Card, Radio, RadioGroup, Slider } from "@blueprintjs/core"
import * as React from "react"
import { translations } from "../../shared/i18n"
import { css, cx } from "emotion"
import { IsolineVehicleType } from "../../shared/models/poi-explorer"
import { AssessmentEntryFull } from "../models/assessment"
import { FormEvent } from "react"
import { trackUsageEvent } from "../../utils/usage-tracking"
import { IsochroneType } from "./isochrone-type"
import Icon from "../../shared/components/icon"

export interface IsochronePopupProps {
  assessmentEntry?: AssessmentEntryFull | null
  isContained?: boolean
  isochrone: IsochroneType
  onChange: (field: string, value: IsolineVehicleType | number) => void
  onClose: () => void
  moduleName: string
  hideRightMargin?: boolean
  hideX?: boolean
}

const styles = {
  label: css({
    paddingBottom: "8px",
    fontWeight: "bold",
  }),
  card: css({
    position: "absolute",
    bottom: "50px",
    right: "100px",
    width: "450px",
    fontSize: "14px",
    cursor: "default",
    padding: 0,
  }),
  containedCard: css({
    position: "absolute",
    top: "0px",
    right: "50px",
    width: "450px",
    fontSize: "14px",
    cursor: "default",
    padding: 0,
  }),
}

export const IsochronePopup = ({
  assessmentEntry,
  isochrone,
  onChange,
  onClose,
  moduleName,
  isContained,
  hideRightMargin,
  hideX,
}: IsochronePopupProps) => {
  const t = React.useMemo(translations, [translations])

  const onVehicleTypeChange = (e: FormEvent<HTMLInputElement>) => {
    assessmentEntry &&
      e.currentTarget.value !== "none" &&
      trackUsageEvent("SHOW_ISOCHRONE", assessmentEntry.address, `${moduleName} - ${e.currentTarget.value}`)
    onChange("mode", e.currentTarget.value as IsolineVehicleType)
  }

  const cardStyle = isContained ? styles.containedCard : styles.card
  const rightMarginCardStyle = hideRightMargin ? cx(cardStyle, css({ right: "0px" })) : cardStyle

  return (
    <Card elevation={2} className={rightMarginCardStyle}>
      <BorderBottom>
        <Flex flexDirection={"row"} padding={16}>
          <h3 style={{ flexGrow: 1 }}>{t.isochrone}</h3>
          {!hideX && (
            <div onClick={onClose} style={{ cursor: "pointer" }}>
              <Icon name={"close"} color={"primary"} colorType={"default"} fontSize={18} />
            </div>
          )}
        </Flex>
      </BorderBottom>
      <Grid columns={1} padding={16} gap={16}>
        <GridItem>
          <div className={styles.label}>{t.poiExplorer.vehicleLabel}</div>
          <RadioGroup selectedValue={isochrone.mode} onChange={onVehicleTypeChange} inline>
            <Radio label={t.map.vehicle["none"]} value="none" style={{ marginRight: 24 }} />
            <Radio label={t.map.vehicle["pedestrian"]} value="pedestrian" style={{ marginRight: 24 }} />
            <Radio label={t.map.vehicle["car"]} value="car" />
          </RadioGroup>
        </GridItem>

        <GridItem>
          <div className={styles.label}>{t.poiExplorer.transitTimeLabel}</div>
          <div style={{ padding: "8px 16px 16px 16px" }}>
            <Slider
              min={5}
              max={120}
              stepSize={5}
              labelStepSize={115}
              labelRenderer={(v) => `${v}\u00a0${t.poiExplorer.transitTimeUnit}`}
              value={isochrone.time}
              onChange={(e) => onChange("time", e)}
            />
          </div>
        </GridItem>

        <p style={{ fontSize: 12 }}>{t.map.distanceDataHint}</p>
      </Grid>
    </Card>
  )
}
