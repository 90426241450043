import { Panel, PanelStack2 } from "@blueprintjs/core"
import React, { useEffect, useMemo, useState } from "react"
import { css } from "emotion"
import { CategoriesListPanelInfo, PrivatePOICategory } from "../../models/private-data"
import { translations } from "../../i18n"
import { CategoriesListPanel } from "./categories-list-panel"
import { useAppSelector } from "../../../relas/store"
import { getThemeColorVar } from "../../../shared/helper/color"

const styles = {
  categoriesListGrid: css({
    height: "100%",
    display: "grid",
    columns: 1,
    gridTemplateRows: "62px 1fr",
  }),
  rightBorder: css({
    borderRight: "1px solid",
    borderColor: getThemeColorVar("border", "default"),
  }),
}

interface Props {
  setCategoryToLock: (category: PrivatePOICategory) => void
  userId: string
  isAdmin: boolean
}

const PoiCategoriesMenu = (props: Props) => {
  const t = useMemo(translations, [translations])
  const categories = useAppSelector((state) => state.privateData.privatePOICategories)
  const categoriesLoading = useAppSelector((state) => state.privateData.privatePOICategoriesLoading)

  const [currentCategoriesLoading, setCurrentCategoriesLoading] = useState(false)
  const [categoriesWereLoaded, setCategoriesWereLoaded] = useState(false)
  const [currentPanelStack, setCurrentPanelStack] = useState<Array<Panel<CategoriesListPanelInfo | {}>>>([
    {
      props: {
        setCategoryToLock: props.setCategoryToLock,
        userId: props.userId,
        isAdmin: props.isAdmin,
        loadedCategoryListIsEmpty: false,
      },
      renderPanel: CategoriesListPanel,
    },
  ])

  React.useEffect(() => {
    if (currentCategoriesLoading && !categoriesLoading) {
      setCategoriesWereLoaded(true)
    } else setCategoriesWereLoaded(false)
    setCurrentCategoriesLoading(categoriesLoading)
  }, [categoriesLoading])

  useEffect(() => {
    if (categoriesWereLoaded) {
      let currentStackTemp = [...currentPanelStack]
      let currentPanelTemp = currentPanelStack[currentPanelStack.length - 1]
      if (categories.length === 0) {
        if (
          currentPanelTemp.props &&
          "loadedCategoryListIsEmpty" in currentPanelTemp.props &&
          !currentPanelTemp.props.loadedCategoryListIsEmpty
        ) {
          currentPanelTemp.props = { ...currentPanelTemp.props, loadedCategoryListIsEmpty: true }
          currentStackTemp[currentStackTemp.length - 1] = currentPanelTemp
          setCurrentPanelStack(currentStackTemp)
        }
      } else {
        if (
          currentPanelTemp.props &&
          "loadedCategoryListIsEmpty" in currentPanelTemp.props &&
          currentPanelTemp.props.loadedCategoryListIsEmpty
        ) {
          currentPanelTemp.props = { ...currentPanelTemp.props, loadedCategoryListIsEmpty: false }
          currentStackTemp[currentStackTemp.length - 1] = currentPanelTemp
          setCurrentPanelStack(currentStackTemp)
        }
      }
    }
  }, [categories, categoriesWereLoaded])

  const addToPanelStack = React.useCallback(
    (newPanel: Panel<CategoriesListPanelInfo>) => setCurrentPanelStack((stack) => [...stack, newPanel]),
    []
  )

  const removeFromPanelStack = React.useCallback(() => setCurrentPanelStack((stack) => stack.slice(0, -1)), [])

  return (
    <div className={styles.categoriesListGrid}>
      <div
        style={{
          backgroundColor: getThemeColorVar("background", "lighter"),
          borderBottom: "1px solid",
          borderColor: getThemeColorVar("border", "default"),
        }}
      >
        <div style={{ fontWeight: "bold", padding: "16px" }}>{t.privateData.categories}</div>
      </div>
      <div className={styles.rightBorder}>
        <PanelStack2
          className="private-pois-categories-panel-stack2"
          onOpen={addToPanelStack}
          onClose={removeFromPanelStack}
          renderActivePanelOnly={true}
          showPanelHeader={false}
          stack={currentPanelStack}
        />
      </div>
    </div>
  )
}

export default PoiCategoriesMenu
