import { Assessment, AssessmentEntryFull, WidgetsType } from "../../models/assessment"
import {
  AssessmentDashboard,
  AssessmentDashboardWidgetId,
  AssessmentDynamicDashboardConfig,
  WidgetConfig,
  WidgetConfigData,
} from "../../models/AssessmentDashboard"
import { MacroMapWidget } from "./macro-map-widget"
import NearestAccessibilityWidget from "./nearest-accessibility-widget"
import { defaultIsochroneConfig, MicroMap } from "./micro-map-widget"
import { ScoresWidget } from "./scores-widget"
import MarketDataWidget from "./market-data-widget"
import ComparablesWidget from "./comparables-widget"
import { RentIndexWidget } from "./rent-index-widget"
import POIsWidget from "./pois-widget"
import SpecialMapsWidget from "./special-maps-widget"
import PopulationWidget from "./population-widget"
import MLPricesWidget from "./ml-prices-widget"
import YieldsWidget from "./yields-widget"
import RatingsWidget from "./ratings-widget"
import React, { useEffect, useRef, useState } from "react"
import {
  loadAssessmentEntry,
  navigateToAssessment,
  navigateToPrices,
  openAssessmentModule,
} from "../../reducers/assessment-slice-functions"
import { AppModules } from "../../../menu/util/app-location-types"
import { Layout, Layouts, Responsive, WidthProvider } from "react-grid-layout"
import { v4 } from "uuid"
import { updateDashboardWidgetLayout } from "../../../relas/user-slice"
import { translations } from "../../i18n"
import { fillPlaceholdersWithAddWidgets, removeAddWidgets } from "../../../utils/virtual-dashboard-grid"
import { cx } from "emotion"
import { AddWidgetPopover } from "./add-widget-popover"
import { useAppSelector } from "../../../relas/store"
import { setComparablesInputDefaultDataSource } from "../../reducers/comparables-slice"

export type DashboardEditMode = false | "resize" | "configure"

type Props = {
  dashboard?: AssessmentDashboard
  setConfigWidget: (widgetId: AssessmentDashboardWidgetId, widgetConfig: WidgetConfigData) => void
  assessmentEntry: AssessmentEntryFull
  assessment: Assessment
  cleanDashboard: boolean
  editMode: DashboardEditMode
  onRenderingDone: (success: boolean) => void
  rowHeight: number
  defaultDashboardWidgets: () => AssessmentDynamicDashboardConfig
}

export interface WidgetLayout extends Layout {
  config: WidgetConfigData
}

export interface WidgetLayouts extends Layouts {
  lg: WidgetLayout[]
}

const getWidgetLayouts = (selectedDashboard: AssessmentDashboard, editMode: DashboardEditMode): WidgetLayouts => {
  const widgetLayoutArray = selectedDashboard.config.widgets.map((widget) => ({
    i: widget.id ?? v4(),
    x: widget.dimensions.x,
    y: widget.dimensions.y,
    w: widget.dimensions.w,
    h: widget.dimensions.h,
    static: editMode !== "resize",
    config: widget.config,
  }))
  return editMode ? fillPlaceholdersWithAddWidgets({ lg: widgetLayoutArray }) : { lg: widgetLayoutArray }
}

const ResponsiveGridLayout = WidthProvider(Responsive)

export const DashboardWidgetGrid = ({
  dashboard,
  setConfigWidget,
  assessmentEntry,
  cleanDashboard,
  editMode,
  assessment,
  onRenderingDone,
  rowHeight,
  defaultDashboardWidgets,
}: Props) => {
  const markerLocation = assessmentEntry.address.location
  const assessmentEntryId = assessmentEntry.id
  const t = React.useMemo(translations, [translations])
  const privatePoiCategories = useAppSelector((state) => state.privateData.privatePOICategories)
  const isPrivateDataAccessible = useAppSelector((state) => state.user.scopes.privateData)
  const [layouts, setLayouts] = useState<WidgetLayouts>()

  const widgetRenderingCompleted = useRef<{
    [key: AssessmentDashboardWidgetId]: boolean
  }>({})

  const onWidgetHeaderClick = (widgetName: WidgetsType) => {
    void loadAssessmentEntry(assessment, assessmentEntryId)
    if (widgetName === "yields") {
      void navigateToPrices(assessment.id, assessmentEntryId, widgetName)
    } else {
      void navigateToAssessment(assessment.id, assessmentEntryId, widgetName)
    }
  }

  const onModuleWidgetHeaderClick = (module: AppModules["locationAssessment"]) => {
    if (module === "comparables") setComparablesInputDefaultDataSource("historical-21st")
    if (assessment && assessmentEntryId) {
      void openAssessmentModule(assessment.id, assessmentEntryId, module)
    }
  }

  const widgetRenderingCompletedReferenceId = (widgetId: AssessmentDashboardWidgetId, type: WidgetsType) =>
    widgetId + "-" + type

  const updateWidgetLayouts = (newLayouts: WidgetLayouts) => {
    const filteredLayout = newLayouts.lg.filter((item) => !item.i.includes("add"))
    widgetRenderingCompleted.current = Object.fromEntries(
      filteredLayout.map((item) => [widgetRenderingCompletedReferenceId(item.i, item.config.type), false])
    )
    setLayouts(newLayouts)
  }

  const onWidgetRenderingCompleted = (widgetId: AssessmentDashboardWidgetId, configType: WidgetsType) => {
    const allAlreadyDone = Object.entries(widgetRenderingCompleted.current).every(([, value]) => value)

    if (allAlreadyDone) return

    widgetRenderingCompleted.current[widgetRenderingCompletedReferenceId(widgetId, configType)] = true

    const allCompleted = Object.entries(widgetRenderingCompleted.current).every(([, value]) => value)

    if (widgetRenderingCompleted.current && allCompleted) {
      onRenderingDone(allCompleted)
    }
  }

  const repaintWidgets = () => {
    // this event is triggered to redraw OpenLayers maps
    window.dispatchEvent(new Event("resize"))
  }

  useEffect(() => {
    setTimeout(repaintWidgets, 500)
  }, [])

  useEffect(() => {
    if (layouts) {
      let updatedLayouts = changeLayoutsStaticValues(layouts, editMode)
      if (editMode === "resize") {
        updatedLayouts = removeAddWidgets(updatedLayouts)
      } else if (editMode === "configure") {
        updatedLayouts = fillPlaceholdersWithAddWidgets(updatedLayouts)
      } else {
        updatedLayouts = removeAddWidgets(updatedLayouts)
      }
      updateWidgetLayouts(updatedLayouts)
    }
  }, [editMode])

  useEffect(() => {
    if (dashboard) {
      const updatedLayouts = getWidgetLayouts(dashboard, editMode)
      updateWidgetLayouts(updatedLayouts)
    }
  }, [dashboard, assessmentEntryId])

  const handleLayoutChange = (dashboard: AssessmentDashboard, allLayouts: Layouts) => {
    repaintWidgets()
    if (editMode === "resize") {
      const layoutConfigs = new Map(dashboard.config.widgets.map((item) => [item.id, item.config]))

      const changedLgLayouts = allLayouts.lg
        .map((item) => {
          if (layoutConfigs.has(item.i) && !item.i.includes("add-")) {
            return {
              ...item,
              config: layoutConfigs.get(item.i),
            }
          }

          return undefined
        })
        .filter((item) => item !== undefined) as WidgetLayout[]

      updateWidgetLayouts({ lg: changedLgLayouts })
    }
  }

  const saveLayoutsToConfig = (layout: WidgetLayout[]) => {
    if (dashboard) {
      repaintWidgets()

      const trimmedLayout = layout.filter((l) => !l.i.includes("add-"))
      const widgetLayout = trimmedLayout.map((l) => ({
        id: l.i,
        dimensions: {
          x: l.x,
          y: l.y,
          w: l.w,
          h: l.h,
        },
        config: l.config,
      }))

      void updateDashboardWidgetLayout(dashboard, widgetLayout)
    }
  }

  const changeLayoutsStaticValues = (oldLayouts: WidgetLayouts, editMode: DashboardEditMode): WidgetLayouts => ({
    lg: oldLayouts.lg.map((item) => ({
      ...item,
      static: editMode !== "resize",
    })),
  })

  const onRatingWidgetHeaderClick = () => onModuleWidgetHeaderClick("ratings")

  const onPoisWidgetHeaderClick = () => onModuleWidgetHeaderClick("poiExplorer")

  const renderWidget = (widgetId: AssessmentDashboardWidgetId, widgetConfig: WidgetConfigData) => {
    const onConfigClick = () => setConfigWidget(widgetId, widgetConfig)
    const onWidgetRenderingDone = () => {
      onWidgetRenderingCompleted(widgetId, widgetConfig.type)
    }
    const onRemoveClick = () => {
      const newLayout = layouts?.lg.filter((item) => item.i !== widgetId)
      if (newLayout) {
        const changedLayout = fillPlaceholdersWithAddWidgets({ lg: newLayout })
        const layoutToSave = removeAddWidgets(changedLayout)
        updateWidgetLayouts(changedLayout)
        saveLayoutsToConfig(layoutToSave.lg)
      }
    }

    const replaceAddWidget = (widgetConfigToReplace: WidgetConfig) => {
      const addWidgetIndex = layouts ? layouts.lg.findIndex((item) => item.i === widgetId) : undefined
      if (typeof addWidgetIndex === "number" && addWidgetIndex >= 0) {
        let newLayout = layouts ? [...layouts.lg] : []
        newLayout[addWidgetIndex] = {
          i: widgetConfigToReplace.id,
          x: newLayout[addWidgetIndex].x,
          y: newLayout[addWidgetIndex].y,
          w: newLayout[addWidgetIndex].w,
          h: newLayout[addWidgetIndex].h,
          config: widgetConfigToReplace.config,
        }
        const modifiedLayout = { lg: newLayout }
        const layoutToSave = removeAddWidgets(modifiedLayout)
        const newVirtualLayout = fillPlaceholdersWithAddWidgets(modifiedLayout)
        saveLayoutsToConfig(layoutToSave.lg)
        updateWidgetLayouts(newVirtualLayout)
      }
    }

    const confType = widgetConfig?.type

    switch (confType) {
      case "macroMap":
        return (
          <MacroMapWidget
            markerLocation={markerLocation}
            tileSource={false}
            onHeaderClick={() => onWidgetHeaderClick("macroMap")}
            onRemoveClick={onRemoveClick}
            printMode={cleanDashboard}
            editMode={editMode}
            onRenderingDone={onWidgetRenderingDone}
          />
        )
      case "nearestAccessibility":
        return (
          <NearestAccessibilityWidget
            markerLocation={markerLocation}
            onHeaderClick={() => onModuleWidgetHeaderClick("poiExplorer")}
            onRemoveClick={onRemoveClick}
            printMode={cleanDashboard}
            editMode={editMode}
            onRenderingDone={onWidgetRenderingDone}
          />
        )
      case "microMap":
        return (
          <MicroMap
            markerLocation={markerLocation}
            onHeaderClick={() => onWidgetHeaderClick("microMap")}
            onRemoveClick={onRemoveClick}
            isochrone={widgetConfig.isochrone ?? defaultIsochroneConfig}
            isPrivateDataAccessible={isPrivateDataAccessible}
            privatePoiCategoriesInConfig={widgetConfig.privatePOICategories ?? []}
            privatePoiCategories={privatePoiCategories}
            onConfigClick={onConfigClick}
            htmlId={"assessment-dashboard-nearest-accessibility-widget"}
            printMode={cleanDashboard}
            configurable={!!editMode}
            editMode={editMode}
            onRenderingDone={onWidgetRenderingDone}
          />
        )

      case "microScores":
        return (
          <ScoresWidget
            header={t.dashboard.scores.microHeader}
            dataSetType={"micro"}
            defaultData={assessmentEntry?.microData}
            widgetScores={widgetConfig.microScores}
            onHeaderClick={() => onWidgetHeaderClick("microScores")}
            onConfigClick={onConfigClick}
            onRemoveClick={onRemoveClick}
            printMode={cleanDashboard}
            editMode={editMode}
            configurable={!!editMode}
            onRenderingDone={onWidgetRenderingDone}
            entryId={assessmentEntryId}
            assessmentId={assessment.id}
          />
        )
      case "macroScores":
        return (
          <ScoresWidget
            header={t.dashboard.scores.macroHeader}
            dataSetType={"macro"}
            defaultData={assessmentEntry?.macroData}
            widgetScores={widgetConfig.macroScores}
            onHeaderClick={() => onWidgetHeaderClick("macroScores")}
            onConfigClick={onConfigClick}
            onRemoveClick={onRemoveClick}
            printMode={cleanDashboard}
            editMode={editMode}
            configurable={!!editMode}
            selectionContext={widgetConfig.macroContext}
            onRenderingDone={onWidgetRenderingDone}
            entryId={assessmentEntryId}
            assessmentId={assessment.id}
          />
        )
      case "districtData":
        return (
          <MarketDataWidget
            assessmentEntry={assessmentEntry}
            configurable={!!editMode}
            editMode={editMode}
            onConfigClick={onConfigClick}
            onRemoveClick={onRemoveClick}
            onHeaderClick={() => onModuleWidgetHeaderClick("fundamentalData")}
            printMode={cleanDashboard}
            selectedCategoriesInDistrictData={new Set(widgetConfig.districtData)}
            onRenderingDone={onWidgetRenderingDone}
          />
        )

      case "comparables":
        return (
          <ComparablesWidget
            onHeaderClick={() => onModuleWidgetHeaderClick("comparables")}
            onRemoveClick={onRemoveClick}
            printMode={cleanDashboard}
            editMode={editMode}
            assessmentEntry={assessmentEntry || undefined}
            markerLocation={markerLocation}
            onRenderingDone={onWidgetRenderingDone}
          />
        )

      case "rentIndex":
        return (
          <RentIndexWidget
            onHeaderClick={() => onModuleWidgetHeaderClick("rentindex")}
            onRemoveClick={onRemoveClick}
            printMode={cleanDashboard}
            editMode={editMode}
            assessmentEntryYear={assessmentEntry?.year}
            assessmentEntryAddress={assessmentEntry?.address}
            assessmentEntryArea={assessmentEntry?.area}
            onRenderingDone={onWidgetRenderingDone}
          />
        )

      case "POIs":
        return (
          <POIsWidget
            selectionMode={widgetConfig.selectionMode || "defaults"}
            selectedPOICategories={widgetConfig.selectedPOICategories}
            configurable={!!editMode}
            editMode={editMode}
            onConfigClick={onConfigClick}
            onRemoveClick={onRemoveClick}
            onHeaderClick={onPoisWidgetHeaderClick}
            printMode={cleanDashboard}
            markerLocationLat={markerLocation?.lat}
            markerLocationLng={markerLocation?.lng}
            onRenderingDone={onWidgetRenderingDone}
          />
        )

      case "specialMaps":
        return (
          <SpecialMapsWidget
            onHeaderClick={() => onModuleWidgetHeaderClick("specialMaps")}
            onRemoveClick={onRemoveClick}
            printMode={cleanDashboard}
            editMode={editMode}
            markerLocation={markerLocation}
            onRenderingDone={onWidgetRenderingDone}
          />
        )

      case "population":
        return (
          <PopulationWidget
            onHeaderClick={() => onModuleWidgetHeaderClick("fundamentalData")}
            onRemoveClick={onRemoveClick}
            htmlId={"assessment-dashboard-population-distribution-widget"}
            printMode={cleanDashboard}
            editMode={editMode}
            assessmentEntry={assessmentEntry}
            onRenderingDone={onWidgetRenderingDone}
          />
        )

      case "MLPrices":
        return (
          <MLPricesWidget
            printMode={cleanDashboard}
            exclusiveness={assessmentEntry?.exclusiveness}
            constructionYear={assessmentEntry?.year}
            area={assessmentEntry?.area}
            newBuilding={assessmentEntry?.newBuilding}
            usageType={assessmentEntry?.usageType}
            onHeaderClick={() => onModuleWidgetHeaderClick("prices")}
            onRemoveClick={onRemoveClick}
            editMode={editMode}
            cellId={assessmentEntry?.cellId}
            agsPrices={assessmentEntry?.agsPrices}
            cellPrices={assessmentEntry?.cellPrices}
            markerLocation={markerLocation}
            onRenderingDone={onWidgetRenderingDone}
          />
        )

      case "yields":
        return (
          <YieldsWidget
            usageType={assessmentEntry?.usageType}
            onHeaderClick={() => onWidgetHeaderClick("yields")}
            onRemoveClick={onRemoveClick}
            printMode={cleanDashboard}
            editMode={editMode}
            markerLocation={markerLocation}
            onRenderingDone={onWidgetRenderingDone}
          />
        )
      case "ratings":
        return (
          <RatingsWidget
            configurable={!!editMode}
            editMode={editMode}
            printMode={cleanDashboard}
            selectionMode={widgetConfig.selectionMode}
            allRatings={assessmentEntry.ratings}
            selectedRatings={widgetConfig.selectedRatings}
            ratingResults={assessmentEntry ? assessmentEntry?.ratingResults : {}}
            allMetaRatings={assessmentEntry.metaRatings}
            metaRatingsResults={assessmentEntry?.metaRatingResults ?? {}}
            selectedMetaRatings={widgetConfig.selectedMetaRatings}
            onHeaderClick={onRatingWidgetHeaderClick}
            onConfigClick={onConfigClick}
            onRemoveClick={onRemoveClick}
            onRenderingDone={onWidgetRenderingDone}
          />
        )
      case "add":
        return (
          <div className={cx("dashboard-widget", editMode && "editMode", "add-section")}>
            <AddWidgetPopover
              currentLayout={layouts as WidgetLayouts}
              defaultConfigWidgets={defaultDashboardWidgets()}
              replaceAddWidget={replaceAddWidget}
            />
          </div>
        )

      default:
        return undefined
    }
  }

  return (
    <div className={"grid-container"}>
      <ResponsiveGridLayout
        className={"layout"}
        breakpoints={{ lg: 1280, md: 996, sm: 768 }}
        cols={{ lg: 12, md: 4, sm: 2 }}
        margin={[12, 12]}
        onLayoutChange={(_, allLayouts) => dashboard && handleLayoutChange(dashboard, allLayouts)}
        onDragStop={saveLayoutsToConfig}
        onResizeStop={saveLayoutsToConfig}
        compactType={"vertical"}
        rowHeight={rowHeight}
        layouts={layouts}
        draggableCancel={".notDraggable"}
        measureBeforeMount={true}
        useCSSTransforms={true}
      >
        {dashboard && layouts?.lg.map((widget) => <div key={widget.i}>{renderWidget(widget.i, widget.config)}</div>)}
      </ResponsiveGridLayout>
    </div>
  )
}
