import React, { useEffect, useState } from "react"
import POIInitialSettings from "./poi-initial-settings"
import { Panel, PanelProps, PanelStack2 } from "@blueprintjs/core"
import POICategoryCreation from "./poi-create-category"
import POIGroupEdit from "./poi-group-edit"
import { fetchGoogleMapsCategories } from "../../reducers/google-maps-slice"
import { fetchUserDefinedPOIGroups } from "../../reducers/poi-explorer-slice"
import { useAppSelector } from "../../../relas/store"

interface Props {
  selectedPOICategories: Set<string>
  setSelectedPOICategories: (selectedCategories: Set<string>) => void
  selectedPOIGroup: string
  setSelectedPOIGroup: (poiGroup: string) => void
  setPoiGroupToDelete: (poiGroup: string) => void
}
interface StartPanelInfo {
  selectedPOIGroupId: string
  setSelectedPOICategories: (selectedCategories: Set<string>) => void
  setSelectedPOIGroup: (categoryName: string) => void
  setPoiGroupToDelete: (groupId: string) => void
}

interface CreationPOIGroupPanelInfo {
  setSelectedPOIGroup: (createdPOIGroup: string) => void
  setSelectedPOICategories: (selectedCategories: Set<string>) => void
}

interface EditionPOIGroupPanelInfo {
  selectedPOIGroupId: string
  selectedPOICategories: Set<string>
  setSelectedPOIGroup: (createdPOIGroup: string) => void
  setSelectedPOICategories: (selectedCategories: Set<string>) => void
}

const startPanel: React.FC<PanelProps<StartPanelInfo>> = (props) => {
  const openCreatePOIGroupPanel = () => {
    props.openPanel({
      props: {
        setSelectedPOICategories: props.setSelectedPOICategories,
        setSelectedPOIGroup: props.setSelectedPOIGroup,
      },
      renderPanel: creationPOICategoryPanel,
    })
  }

  const openEditPOIGroupPanel = (selectedPOIGroupId: string, selectedPOICategories: Set<string>) => {
    props.openPanel({
      props: {
        setSelectedPOICategories: props.setSelectedPOICategories,
        setSelectedPOIGroup: props.setSelectedPOIGroup,
        selectedPOIGroupId,
        selectedPOICategories,
      },
      renderPanel: editingPOICategoryPanel,
    })
  }

  return (
    <div className="start-content-panel-stack" style={{ height: "100%" }}>
      <POIInitialSettings
        setSelectedPOIGroup={props.setSelectedPOIGroup}
        setSelectedPOICategories={props.setSelectedPOICategories}
        onNewPOIGroupCreate={openCreatePOIGroupPanel}
        onPOIGroupEdit={(selectedPOIGroupId: string, selectedCategories: Set<string>) =>
          openEditPOIGroupPanel(selectedPOIGroupId, selectedCategories)
        }
        setPoiGroupToDelete={props.setPoiGroupToDelete}
      />
    </div>
  )
}

const creationPOICategoryPanel: React.FC<PanelProps<CreationPOIGroupPanelInfo>> = (props) => {
  const [selectedPOICategoriesInPanel, setSelectedPOICategoriesInPanel] = useState<Set<string>>(new Set())

  useEffect(() => {
    props.setSelectedPOICategories(selectedPOICategoriesInPanel)
  }, [selectedPOICategoriesInPanel])

  return (
    <div className="group-creation-panel-stack" style={{ height: "100%" }}>
      <POICategoryCreation
        selectedPOICategories={selectedPOICategoriesInPanel}
        setSelectedPOICategories={setSelectedPOICategoriesInPanel}
        setSelectedPOIGroup={props.setSelectedPOIGroup}
        onClosePanel={() => props.closePanel()}
      />
    </div>
  )
}

const editingPOICategoryPanel: React.FC<PanelProps<EditionPOIGroupPanelInfo>> = (props) => {
  const [selectedPOICategoriesInPanel, setSelectedPOICategoriesInPanel] = useState<Set<string>>(
    props.selectedPOICategories
  )

  useEffect(() => {
    props.setSelectedPOICategories(selectedPOICategoriesInPanel)
  }, [selectedPOICategoriesInPanel])

  return (
    <div className="group-edit-panel-stack" style={{ height: "100%" }}>
      <POIGroupEdit
        selectedPOICategories={selectedPOICategoriesInPanel}
        setSelectedPOICategories={setSelectedPOICategoriesInPanel}
        selectedPOIGroupId={props.selectedPOIGroupId}
        setSelectedPOIGroup={props.setSelectedPOIGroup}
        onClosePanel={() => props.closePanel()}
      />
    </div>
  )
}

const POIExplorerMenu = ({
  setSelectedPOIGroup,
  setSelectedPOICategories,
  selectedPOIGroup,
  setPoiGroupToDelete,
}: Props) => {
  const googleMapsData = useAppSelector((state) => state.googleMapsData)

  const [currentPanelStack, setCurrentPanelStack] = useState<
    Array<Panel<StartPanelInfo | CreationPOIGroupPanelInfo | EditionPOIGroupPanelInfo>>
  >([
    {
      props: {
        setSelectedPOICategories,
        setSelectedPOIGroup,
        selectedPOIGroupId: selectedPOIGroup,
        setPoiGroupToDelete,
      },
      renderPanel: startPanel,
    },
  ])

  useEffect(() => {
    if (!googleMapsData.poiCategoriesLoadError && !googleMapsData.poiCategories && !googleMapsData.poiThematicGroups) {
      void fetchGoogleMapsCategories()
    }
    fetchUserDefinedPOIGroups()
  }, [])

  const addToPanelStack = React.useCallback(
    (newPanel: Panel<StartPanelInfo | CreationPOIGroupPanelInfo | EditionPOIGroupPanelInfo>) =>
      setCurrentPanelStack((stack) => [...stack, newPanel]),
    []
  )

  const removeFromPanelStack = React.useCallback(() => setCurrentPanelStack((stack) => stack.slice(0, -1)), [])

  return (
    <PanelStack2
      className="poi-explorer-panel-stack"
      onOpen={addToPanelStack}
      onClose={removeFromPanelStack}
      renderActivePanelOnly={false}
      showPanelHeader={false}
      stack={currentPanelStack}
    />
  )
}

export default POIExplorerMenu
