import * as React from "react"
import { PropsWithChildren } from "react"

export interface ReactCanvasProps {
  width: number
  height: number
  draw: (context: CanvasRenderingContext2D) => void
}

export const ReactCanvas: React.FunctionComponent<PropsWithChildren<ReactCanvasProps>> = ({ width, height, draw }) => {
  // Note: ref is bound directly since a regular useRef/useEffect indirection might lead to empty graphs in pdf rendering
  return (
    <canvas
      ref={(canvas) => {
        const context = canvas?.getContext("2d")

        if (context) {
          context.clearRect(0, 0, width, height)
          draw(context)
        }
      }}
      width={width}
      height={height}
    />
  )
}
