import * as React from "react"
import { translations } from "../i18n"
import { Flex } from "../../shared/components/ui/flex"
import { FlexItem } from "../../shared/components/ui/flex-item"
import { AssessmentCreateError } from "../models/assessment"
import { useAppSelector } from "../../relas/store"
import { AppModules } from "../../menu/util/app-location-types"
import { useEffect, useState } from "react"
import { uploadAssessmentList } from "../reducers/assessment-slice-functions"
import Fileupload from "../../shared/components/fileupload"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import Dialog from "../../shared/components/dialog"
import Panel from "../../shared/components/panel"
import TextField from "../../shared/components/textfield"
import Button from "../../shared/components/button"
import LoadingSpinner from "../../shared/components/loadingspinner"

type Props = {
  onCancel: () => void
  module: AppModules["locationAssessment"]
}

export const AssessmentCreateList = ({ onCancel, module }: Props) => {
  const t = translations()

  const assessmentCreateError = useAppSelector((state) => state.assessment.assessmentCreateError)
  const scopes = useAppSelector((state) => state.user.scopes)

  const [assessmentTitle, setAssessmentTitle] = useState("")
  const [uploadingList, setUploadingList] = useState(false)

  useEffect(() => {
    if (assessmentCreateError && uploadingList) setUploadingList(false)
  }, [assessmentCreateError, uploadingList])

  const renderError = (error: AssessmentCreateError) => {
    if (error.status === 400 && typeof error.error === "string") {
      switch (error.error) {
        case "InvalidFileType":
          return (
            <Panel color="negative">{t.assessmentUploadAddresses.errorInvalidFileFormat(error.fileProperties)}</Panel>
          )
        case "NoRows":
          return <Panel color="negative">{t.assessmentUploadAddresses.errorNoRows}</Panel>
        case "MissingColumns":
          return (
            <Panel color="negative">
              {t.assessmentUploadAddresses.errorMissingColumns}:
              {error.missing && error.missing.map((col) => t.assessmentUploadAddresses.column[col]).join(", ")}
            </Panel>
          )
        case "TooManyEntries":
          return <Panel color="negative">{t.assessmentUploadAddresses.errorTooManyEntries}</Panel>
      }
    }
    return <GenericErrorPanel error={error} />
  }

  const onUpload = (file: File) => {
    setUploadingList(true)
    return uploadAssessmentList(assessmentTitle.trim(), file, scopes.explorer || scopes.locationSelector, module)
  }

  return (
    <Dialog onClose={onCancel} closeOnClickOutside>
      <Flex flexDirection="column" gap={16} padding={24}>
        <h2>{t.assessmentUploadAddresses.title}</h2>
        <p dangerouslySetInnerHTML={{ __html: t.assessmentUploadAddresses.hint }} />
        <TextField
          label={`${t.assessment.title}*`}
          value={assessmentTitle}
          onValueChange={setAssessmentTitle}
          disabled={uploadingList}
        />
        {assessmentCreateError && renderError(assessmentCreateError)}
        <Flex flexDirection="row" flexGrow={0} gap={24} alignItems={"center"}>
          <Button type="secondary" onClick={onCancel}>
            {t.cancel}
          </Button>
          <FlexItem flexGrow={1} />
          {uploadingList && (
            <Flex flexDirection="row" gap={8}>
              <FlexItem>{t.assessmentAddAddress.uploadingFile}</FlexItem>
              <div>
                <LoadingSpinner size={24} />
              </div>
            </Flex>
          )}
          <Fileupload
            type="primary"
            icon="upload"
            disabled={assessmentTitle.trim().length == 0 || uploadingList}
            onUpload={onUpload}
          >
            {t.assessmentAddAddress.loadListButton}
          </Fileupload>
        </Flex>
      </Flex>
    </Dialog>
  )
}
