import React from "react"
import {
  generatePoiMarkers,
  MultipleCategoryPOIs,
  PrivatePOICategoriesList,
  PrivatePOICategory,
  PrivatePOIList,
} from "../../private-data/models/private-data"
import { POIClusterMarker, PoiMarkerType } from "./poi-cluster-marker"
import { Bounds } from "google-map-react"
import LatLngBounds = google.maps.LatLngBounds
import { BBox, Point } from "geojson"
import Supercluster from "supercluster"
import * as GeoJSON from "geojson"

export interface CategoryData {
  categoryId: string
  poiList: PrivatePOIList
}
export type CategoryDataList = Array<CategoryData>

export interface POIObjectMarkerType extends GeoJSON.Feature {
  type: "Feature"
  properties: { [name: string]: any }
  geometry: Point
}
interface Props {
  categoryDataList: CategoryDataList
  categoryData: CategoryData
  zoom: number
  bounds: Bounds | LatLngBounds
  categories: PrivatePOICategoriesList
  dataIndex: number
  openDialog: (location: PoiMarkerType, poisToDisplay: PrivatePOIList) => void
}
export const generateNewPoiClusterData = (data: MultipleCategoryPOIs): CategoryDataList => {
  const categoryDataEntries = Object.entries(data)
  if (categoryDataEntries.length === 0) return []
  else return categoryDataEntries.map(([categoryId, poiList]) => ({ categoryId, poiList }))
}
const getBoundsFromLatLng = (bounds: LatLngBounds): BBox => {
  let SW = { lng: bounds.getSouthWest().lng(), lat: bounds.getSouthWest().lat() }
  let NE = { lng: bounds.getNorthEast().lng(), lat: bounds.getNorthEast().lat() }
  return [SW.lng, SW.lat, NE.lng, NE.lat]
}
const getBoundsArrayFromBounds = (bounds: Bounds): BBox => {
  return [bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]
}
const isBoundsLatLngBounds = (bounds: Bounds | LatLngBounds): boolean => {
  return bounds instanceof LatLngBounds
}
export const PrivatePoiClusters = ({
  categoryDataList,
  categoryData,
  zoom,
  bounds,
  categories,
  dataIndex,
  openDialog,
}: Props) => {
  const { categoryId, poiList } = categoryData
  const getCategoryFromId = (id: string): PrivatePOICategory | undefined => {
    return categories.find((category) => category.id === id) ?? undefined
  }
  const getBounds = (bounds: Bounds | LatLngBounds): BBox => {
    if (isBoundsLatLngBounds(bounds)) {
      return getBoundsFromLatLng(bounds as LatLngBounds)
    } else {
      return getBoundsArrayFromBounds(bounds as Bounds)
    }
  }

  const supercluster = new Supercluster({ radius: 75, maxZoom: 23 })
  supercluster.load(generatePoiMarkers(poiList))
  const clusters = supercluster.getClusters(getBounds(bounds), zoom)

  return clusters.map((cluster, clusterIndex: number) => {
    const [longitude, latitude] = cluster.geometry.coordinates
    const { cluster: isCluster, point_count: pointCount, poiId, cluster_id } = cluster.properties

    const markerId = isCluster
      ? `poi:cluster:${dataIndex}:${clusterIndex}:${cluster_id}:${categoryId}`
      : `poi:marker:${dataIndex}${clusterIndex}:${poiId}:${categoryId}`
    return (
      <POIClusterMarker
        key={markerId}
        markerId={markerId}
        lat={latitude}
        lng={longitude}
        pointCount={isCluster ? pointCount : 1}
        markerPoints={isCluster ? supercluster.getLeaves(cluster.id as number, Infinity) : [cluster]}
        category={getCategoryFromId(categoryId)}
        categoryDataList={categoryDataList}
        openDialog={openDialog}
      />
    )
  })
}
