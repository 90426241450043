import * as React from "react"
import { FC, useMemo, useState } from "react"
import { Grid } from "../../../shared/components/ui/grid"
import { GridItem } from "../../../shared/components/ui/grid-item"
import { translations } from "../../i18n"
import { useAppDispatch } from "../../../relas/store"
import { ratingManagerActions } from "../../rating-manager-slice"
import { DataSetType } from "../../../shared/models/smartdata"
import { FieldPath, useFieldArray, useForm, useFormState } from "react-hook-form"
import { RATING_GRADE_COLORS, RatingGrade } from "../../../shared/models/rating-grade"
import { GradeColorSelect } from "../ui/grade-color-select"
import { cx } from "emotion"
import { doCreateRating } from "../../rating-manager-actions"
import { ratingStyles } from "../rating-styles"
import { nonEmptyTrimmed } from "../../../shared/models/form-validators"
import { RatingDetail } from "../../../explorer/models/rating"
import { MetaRating, MetaRatingCreatePayload } from "../../../shared/models/ratings"
import { RadioButton } from "../../../shared/components/ui/radio-button"
import { NumberInput } from "../../../shared/components/ui/number-input"
import Panel from "../../../shared/components/panel"
import Button from "../../../shared/components/button"
import TextField from "../../../shared/components/textfield"
import { GenericError } from "../../../shared/helper/axios"

type StandardFormInputs = {
  type: "standard"
  name: string
  dataSetType: DataSetType
  grades: RatingGrade[]
}

type MetaFormInputs = MetaRatingCreatePayload & {
  type: "meta"
}

type FormInputs = StandardFormInputs | MetaFormInputs

const defaultValues: StandardFormInputs = {
  type: "standard",
  name: "",
  dataSetType: "macro",
  grades: [
    { label: "A", color: RATING_GRADE_COLORS[0], value: 0 },
    { label: "B", color: RATING_GRADE_COLORS[1], value: 0 },
    { label: "C", color: RATING_GRADE_COLORS[2], value: 0 },
  ],
}

const defaultMetaValues: MetaFormInputs = {
  type: "meta",
  name: "",
  ranges: [
    {
      color: RATING_GRADE_COLORS[0],
    },
  ],
  weights: {},
}

export const RatingManagerCreateNew: FC = ({}) => {
  const t = useMemo(() => translations(), [])

  const { handleSubmit, watch, register, clearErrors, setValue, setError, control } = useForm<FormInputs>({
    defaultValues: { ...defaultMetaValues, ...defaultValues },
  })

  const onValueChangeUseForm = (field: FieldPath<FormInputs>) => (num: number, _: string, valid: boolean) => {
    if (valid) {
      clearErrors(field)
      setValue(field, num)
    } else {
      setError(field, { type: "manual" })
    }
  }

  const { isSubmitting, isValid } = useFormState({ control })

  const gradeFields = useFieldArray({ control, name: "grades" })

  const metaGradeFields = useFieldArray({ control, name: "ranges" })

  const [createError, setCreateError] = useState<GenericError | undefined>(undefined)

  const dispatch = useAppDispatch()

  async function onSubmit(data: FormInputs) {
    let result: RatingDetail | MetaRating | GenericError<any> | undefined
    if (data.type === "standard") {
      result = await doCreateRating(dispatch)({
        name: data.name,
        dataSetType: data.dataSetType,
        grades: data.grades,
      })
    } else {
      result = await doCreateRating(dispatch)({
        name: data.name,
        ranges: data.ranges,
        weights: data.weights,
      })
    }
    if ("id" in result) {
      dispatch(ratingManagerActions.setMenuPane("list"))
      dispatch(ratingManagerActions.setCurrentRating(result))
    } else {
      setCreateError(result)
    }
  }

  return (
    <form
      className={ratingStyles.container}
      style={{ height: "100%" }}
      onSubmit={handleSubmit(onSubmit, (e, ev) => ev?.preventDefault())}
      onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
    >
      <div>
        <Button
          type="tertiary"
          formType={"button"}
          icon="back"
          onClick={() => dispatch(ratingManagerActions.setMenuPane("list"))}
        >
          {t.allRatings}
        </Button>
      </div>
      <div>
        <h2>{t.createForm.formTitle}</h2>
      </div>
      <hr />
      <div className={cx(ratingStyles.fieldName, ratingStyles.noTopMargin)}>{t.createForm.titleField}</div>
      <TextField
        {...register("name", { required: true, validate: nonEmptyTrimmed })}
        onValueChange={(v, isValid) => isValid && setValue("name", v)}
        value={watch("name")}
      />

      <fieldset className={ratingStyles.fieldset}>
        <legend className={ratingStyles.fieldName}>{t.createForm.typeField}</legend>
        <RadioButton
          id="ratingTypeStandard"
          {...register("type")}
          onChange={(v: FormInputs["type"]) => setValue("type", v)}
          checked={watch("type") === "standard"}
          label={t.createForm.standardType}
          name="ratingType"
          value="standard"
        />
        <RadioButton
          id="ratingTypeMeta"
          {...register("type")}
          onChange={(v: FormInputs["type"]) => setValue("type", v)}
          checked={watch("type") === "meta"}
          label={t.createForm.metaType}
          name="ratingType"
          value="meta"
        />
      </fieldset>
      {watch("type") === "standard" && (
        <>
          <fieldset className={ratingStyles.fieldset}>
            <legend className={ratingStyles.fieldName}>{t.context}</legend>
            <RadioButton
              id="scoreKeyMacro"
              {...register("dataSetType")}
              onChange={(v: DataSetType) => setValue("dataSetType", v)}
              checked={watch("dataSetType") === "macro"}
              label={t.scoreKeyLong.macro}
              name="scoreKey"
              value="macro"
            />
            <RadioButton
              id="scoreKeyMicro"
              {...register("dataSetType")}
              onChange={(v: DataSetType) => setValue("dataSetType", v)}
              checked={watch("dataSetType") === "micro"}
              label={t.scoreKeyLong.micro}
              name="scoreKey"
              value="micro"
            />
          </fieldset>

          <fieldset className={ratingStyles.fieldset}>
            <legend className={ratingStyles.fieldName}>{t.createForm.gradesField}</legend>
            <Grid columnSpec="min-content 1fr 55px min-content min-content" gap={8} alignItems="center">
              <div></div>
              <div>{t.label}</div>
              <div>{t.createForm.valueField}</div>
              <div>{t.color}</div>
              <div></div>
              {gradeFields.fields.map((grade, index) => (
                <React.Fragment key={grade.id}>
                  <div style={{ whiteSpace: "nowrap" }}>{index + 1}</div>
                  <TextField
                    value={watch(`grades.${index}.label` as const)}
                    size="small"
                    {...register(`grades.${index}.label` as const, { validate: nonEmptyTrimmed })}
                    onValueChange={(v) => setValue(`grades.${index}.label` as const, v)}
                  />
                  <NumberInput
                    defaultValue={watch(`grades.${index}.value` as const)}
                    onValueChange={onValueChangeUseForm(`grades.${index}.value` as const)}
                  />
                  <GradeColorSelect
                    value={watch(`grades.${index}.color` as const)}
                    {...register(`grades.${index}.color` as const)}
                    onValueChange={(color) => {
                      setValue(`grades.${index}.color` as const, color)
                    }}
                  />
                  {index === gradeFields.fields.length - 1 ? (
                    <Button
                      type="tertiary"
                      formType={"button"}
                      icon="delete"
                      size="small"
                      disabled={gradeFields.fields.length === 1}
                      onClick={() => {
                        gradeFields.remove(index)
                      }}
                    />
                  ) : (
                    <div />
                  )}
                </React.Fragment>
              ))}
              <GridItem colSpan={4}>
                <Button
                  type="tertiary"
                  formType={"button"}
                  icon="add"
                  disabled={gradeFields.fields.length >= 10}
                  onClick={() => {
                    gradeFields.append({ label: "", color: RATING_GRADE_COLORS[gradeFields.fields.length], value: 0 })
                  }}
                >
                  {t.createForm.append}
                </Button>
              </GridItem>
            </Grid>
          </fieldset>
        </>
      )}
      {watch("type") === "meta" && (
        <>
          <fieldset className={ratingStyles.fieldset}>
            <legend className={ratingStyles.fieldName}>{t.createForm.metaGrades.label}</legend>
            <Grid columnSpec="min-content 1fr 1fr min-content min-content" gap={8} alignItems="center">
              <div></div>
              <div>{t.createForm.metaGrades.from}</div>
              <div>{t.createForm.metaGrades.to}</div>
              <div>{t.color}</div>
              <div></div>
              {metaGradeFields.fields.map((grade, index) => (
                <React.Fragment key={grade.id}>
                  <div style={{ whiteSpace: "nowrap" }}>{index + 1}</div>

                  <NumberInput
                    defaultValue={watch(`ranges.${index}.from` as const)}
                    onValueChange={onValueChangeUseForm(`ranges.${index}.from` as const)}
                  />
                  <NumberInput
                    defaultValue={watch(`ranges.${index}.to` as const)}
                    onValueChange={onValueChangeUseForm(`ranges.${index}.to` as const)}
                  />

                  <GradeColorSelect
                    value={watch(`ranges.${index}.color` as const)}
                    {...register(`ranges.${index}.color` as const)}
                    onValueChange={(color) => {
                      setValue(`ranges.${index}.color` as const, color)
                    }}
                  />
                  {index === metaGradeFields.fields.length - 1 ? (
                    <Button
                      type="tertiary"
                      formType={"button"}
                      icon="delete"
                      size="small"
                      disabled={metaGradeFields.fields.length === 1}
                      onClick={() => {
                        metaGradeFields.remove(index)
                      }}
                    />
                  ) : (
                    <div />
                  )}
                </React.Fragment>
              ))}
              <GridItem colSpan={4}>
                <Button
                  type="tertiary"
                  formType={"button"}
                  icon="add"
                  disabled={metaGradeFields.fields.length >= 10}
                  onClick={() => {
                    metaGradeFields.append({
                      color: RATING_GRADE_COLORS[metaGradeFields.fields.length],
                    })
                  }}
                >
                  {t.createForm.metaGrades.addRange}
                </Button>
              </GridItem>
            </Grid>
          </fieldset>
        </>
      )}
      {createError && <Panel color="negative">{t.ratingAlreadyExists}</Panel>}
      <div className={ratingStyles.spacer}></div>
      <div className={ratingStyles.submitRow}>
        <Button type="primary" disabled={!isValid} loading={isSubmitting}>
          {t.createNew}
        </Button>
      </div>
    </form>
  )
}
