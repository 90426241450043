import * as React from "react"
import { createRoot, Root } from "react-dom/client"
import { translations } from "../i18n"
import { Control } from "ol/control"
import { CLASS_CONTROL } from "ol/css"
import { AllowedModules, AllowedModulesEnum, PrivatePOICategoriesList } from "../../private-data/models/private-data"
import { PrivateDataCategoryListPopup } from "./privatedata-category-list-popup"
import { bind } from "decko"
import { PrivateDataCategoryListPopupNotBooked } from "./private-data-category-list-popup-not-booked"
import { PopoverContent } from "./PopoverContent"
import { trackUsageEvent } from "../../utils/usage-tracking"

export class PrivatePoiControl extends Control {
  private rootElement: Root

  constructor(
    private module: AllowedModules,
    categories: PrivatePOICategoriesList,
    selectedCategories: PrivatePOICategoriesList,
    private updateCategories: (categories: PrivatePOICategoriesList, moduleName?: AllowedModules) => void,
    private getPois: (categoryId: string, moduleName?: AllowedModules) => void,
    showPopover: boolean,
    private onChangeShowPopover: (value: boolean) => void,
    private isPrivateDataAccessible: boolean
  ) {
    super({ element: document.createElement("div") })
    this.element.className = `${CLASS_CONTROL}`
    this.element.style.right = "0.5em"
    this.element.style.top = [
      AllowedModulesEnum.PROFILE_MANAGER,
      AllowedModulesEnum.RATING_MANAGER,
      AllowedModulesEnum.RATINGS,
      AllowedModulesEnum.LOCATION_SELECTOR,
      AllowedModulesEnum.RATINGS_SCORE_PRICES,
    ].includes(module)
      ? "10em"
      : "11.25em"
    if (module === AllowedModulesEnum.QUICK_START) this.element.style.top = "3em"

    this.rootElement = createRoot(this.element)
    this.updateCategories = updateCategories

    this.rootElement.render(
      <MapPrivatePoiSelectButtonPopover
        module={module}
        categories={categories}
        selectedCategories={selectedCategories}
        updateCategories={updateCategories}
        getPois={getPois}
        showPopover={showPopover}
        onChangeShowPopover={onChangeShowPopover}
        isPrivateDataAccessible={isPrivateDataAccessible}
      />
    )
  }

  @bind
  public refreshPopover(
    categories: PrivatePOICategoriesList,
    selectedCategories: PrivatePOICategoriesList,
    showPopover: boolean
  ) {
    this.rootElement.render(
      <MapPrivatePoiSelectButtonPopover
        module={this.module}
        categories={categories}
        selectedCategories={selectedCategories}
        updateCategories={this.updateCategories}
        getPois={this.getPois}
        showPopover={showPopover}
        onChangeShowPopover={this.onChangeShowPopover}
        isPrivateDataAccessible={this.isPrivateDataAccessible}
      />
    )
  }
}

interface MapPrivatePoiSelectButtonPopoverProps {
  module: AllowedModules
  categories: PrivatePOICategoriesList
  selectedCategories: PrivatePOICategoriesList
  updateCategories: (categories: PrivatePOICategoriesList, moduleName?: AllowedModules) => void
  getPois: (categoryId: string, moduleName?: AllowedModules) => void
  showPopover: boolean
  onChangeShowPopover: (value: boolean) => void
  isPrivateDataAccessible: boolean
}

const MapPrivatePoiSelectButtonPopover: React.FC<MapPrivatePoiSelectButtonPopoverProps> = ({
  module,
  categories,
  selectedCategories,
  updateCategories,
  getPois,
  onChangeShowPopover,
  isPrivateDataAccessible,
}) => {
  const t = translations()

  const onClose = () => {
    onChangeShowPopover(false)

    if (selectedCategories.length > 0) {
      const categoryString = selectedCategories.map((c) => c.title).join(",")
      trackUsageEvent("PRIVATE_DATA_POIS_SHOW_ON_MAP", null, `${module} - ${categoryString}`)
    }
  }
  return (
    <div title={t.privatePois}>
      <PopoverContent title={t.privatePois} justX iconname="private_poi" onClose={onClose}>
        {isPrivateDataAccessible ? (
          <PrivateDataCategoryListPopup
            categories={categories}
            module={module}
            selectedCategories={selectedCategories}
            updateCategories={updateCategories}
            getPois={getPois}
            isContained
            hideRightMargin={true}
            onClose={() => onClose()}
          />
        ) : (
          <PrivateDataCategoryListPopupNotBooked onClose={() => onClose()} isContained />
        )}
      </PopoverContent>
    </div>
  )
}
