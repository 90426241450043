import React, { useMemo } from "react"
import { COLORS } from "../../shared/components/ui/colors"
import { translations } from "../../assessment/i18n"
import { useSelector } from "react-redux"
import { State } from "../../assessment/reducers/state"
import { PriceGraphs } from "../../assessment/components/assessment-entry-price-graphs"
import { PriceWidget } from "./price-widget"

export const LocationPricesV2 = () => {
  const t = useMemo(translations, [translations])
  const priceType = useSelector((state: State) => state.assessment.selectedPriceType)

  return (
    <PriceWidget title={t.prices.locationPrices.header} info={t.prices.locationPrices.info}>
      <PriceGraphs priceType={priceType} color={COLORS.primary.default} colorLabel={false} />
    </PriceWidget>
  )
}
