import { IsochroneType } from "../../assessment/components/isochrone-type"

export interface AddressSuggestion {
  address: HereAddress
  label: string
  locationId: string
  matchLevel: string
  countryCode: string
  geoInformation: GeoInformation
}

export interface Coordinates {
  latitude: number
  longitude: number
}

export interface GeoInformation {
  coordinates: Coordinates
}

export interface AddressSuggestionResponse {
  suggestions: AddressSuggestion[]
}

export interface HereAddress {
  label?: string
  country?: string
  state?: string
  county?: string
  city?: string
  district?: string
  subdistrict?: string
  street?: string
  houseNumber?: string
  postalCode?: string
  building?: string
  addressLine?: string
  unit?: string
  additionalData?: { [key: string]: string }
}

export interface LocationResponse {
  response: {
    view: {
      result: {
        matchLevel: string
        location: {
          navigationPosition: {
            latitude: number
            longitude: number
          }[]
          displayPosition: {
            latitude: number
            longitude: number
          }
          mapView: {
            topLeft: {
              latitude: number
              longitude: number
            }
            bottomRight: {
              latitude: number
              longitude: number
            }
          }
          address?: HereAddress
        }
      }[]
    }[]
  }
}

export interface IsoLine {
  range: {
    type: string
    value: number
  }
  polygons: [number, number][][]
}

export type IsolineVehicleType = "none" | "car" | "truck" | "pedestrian"

export type IsoLineTransitTime = "300" | "900" | "1800" | "3600" | "7200"

export type POICategory =
  | "public-transport"
  | "taxi-stand"
  | "restaurant"
  | "atm-bank-exchange"
  | "shopping"
  | "petrol-station"
  | "hotel"

export type HerePOIResponseItem = {
  id: string
  categoryId: string
  categoryGroup: string
  location: {
    lat: number
    lng: number
  }
  inside: boolean
}

export interface POICategoryCount {
  count: number
  distances: number[]
}

export interface HereCombinedResponse {
  items: HerePOIResponseItem[]
  isolines: IsoLine[]
  countTotal: { [categoryGroup: string]: POICategoryCount }
  countInside: { [categoryGroup: string]: POICategoryCount }
}

export type HerePOIdetailsResponse = {
  id: string
  title: string
  address: string
  location: {
    lat: number
    lng: number
  }
  category: {
    id: string
    name: string
  }
}

export const POIExplorerTabs = ["poi", "isochrone", "summary"] as const

export type POIExplorerTabs = (typeof POIExplorerTabs)[number]

export interface POIExplorerInput {
  categories: string[]
  group: string
  isochronePolygon: google.maps.Polygon | undefined
  onlyPrimaryCategories: boolean
  onlyWithingIsochrone: boolean
  isochroneSettings: IsochroneType
  poiTextSearch: TextSearchIDsToTitle
}

export const NUMBERED_TEXT_SEARCH_IDS = [
  "textSearch-1",
  "textSearch-2",
  "textSearch-3",
  "textSearch-4",
  "textSearch-5",
] as const
export const NUMBERED_TEXT_SEARCH_IDS_SET: Set<NumberedTextSearchIds> = new Set(NUMBERED_TEXT_SEARCH_IDS)
export type NumberedTextSearchIds = (typeof NUMBERED_TEXT_SEARCH_IDS)[number]

export function stringIsNumberedTextSearchId(s: string): s is NumberedTextSearchIds {
  return (NUMBERED_TEXT_SEARCH_IDS as readonly string[]).includes(s)
}

export type TextSearchIDsToTitle = Record<NumberedTextSearchIds, string | undefined>

export const EmptyTextSearchIds: () => TextSearchIDsToTitle = () => ({
  [NUMBERED_TEXT_SEARCH_IDS[0]]: undefined,
  [NUMBERED_TEXT_SEARCH_IDS[1]]: undefined,
  [NUMBERED_TEXT_SEARCH_IDS[2]]: undefined,
  [NUMBERED_TEXT_SEARCH_IDS[3]]: undefined,
  [NUMBERED_TEXT_SEARCH_IDS[4]]: undefined,
})

const convertTextSearchToArray = (s: string) => s.split(",").map((s) => s.trim())

export const textSearchObjectFromString: (s: string) => TextSearchIDsToTitle = (s) => {
  const asArray = convertTextSearchToArray(s)

  return [0, 1, 2, 3, 4].reduce<TextSearchIDsToTitle>((acc, n) => {
    const textSearch = asArray[n]
    if (textSearch !== "") {
      acc[NUMBERED_TEXT_SEARCH_IDS[n]] = textSearch
    }
    return acc
  }, EmptyTextSearchIds())
}

export const stringFromTextSearchObject: (t: TextSearchIDsToTitle) => string = (t) =>
  NUMBERED_TEXT_SEARCH_IDS.map((id) => t[id])
    .filter((s) => s !== undefined)
    .join(", ")
