import * as React from "react"
import { bind } from "decko"
import { CSSTransition } from "react-transition-group"
import { css } from "emotion"
import { PropsWithChildren } from "react"
import ContentPortal from "./layout/contentportal"
import { getThemeColorVar } from "../helper/color"

const animationMillis = 200

const sidePanelWrapperClass = css({
  zIndex: 10000,
  position: "fixed",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  overflow: "hidden",
  h2: {
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: "26px",
    width: "100%",
  },
})

const sidePanelContentClass = css({
  height: "100%",
  position: "absolute",
  right: 0,
  top: 0,
  width: "400px",
  backgroundColor: getThemeColorVar("background", "default"),
  boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.3)",
})

const sidePanelOverlayClass = css({
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundColor: "#000",
  opacity: 0.3,
})

const sidePanelTransitions = {
  enter: css({
    [`.${sidePanelContentClass}`]: { right: "-400px" },
    [`.${sidePanelOverlayClass}`]: { opacity: 0 },
  }),
  enterActive: css({
    [`.${sidePanelContentClass}`]: {
      right: 0,
      transition: `right ${animationMillis}ms`,
    },
    [`.${sidePanelOverlayClass}`]: {
      opacity: 0.3,
      transition: `opacity ${animationMillis}ms`,
    },
  }),
  enterDone: css({
    [`.${sidePanelContentClass}`]: { right: 0 },
    [`.${sidePanelOverlayClass}`]: { opacity: 0.3 },
  }),
  exit: css({
    [`.${sidePanelContentClass}`]: { right: 0 },
    [`.${sidePanelOverlayClass}`]: { opacity: 0.3 },
  }),
  exitActive: css({
    [`.${sidePanelContentClass}`]: {
      right: "-400px",
      transition: `right ${animationMillis}ms`,
    },
    [`.${sidePanelOverlayClass}`]: {
      opacity: 0,
      transition: `opacity ${animationMillis}ms`,
    },
  }),
  exitDone: css({
    [`.${sidePanelContentClass}`]: { right: "-400px" },
    [`.${sidePanelOverlayClass}`]: { opacity: 0 },
  }),
}

export interface BareSidePanelProps {
  onClose: () => void
  opened: boolean
  sticky?: boolean // Prevent close on click outside
}

export class BareSidePanel extends React.Component<PropsWithChildren<BareSidePanelProps>, {}> {
  render() {
    const { children, opened, sticky } = this.props
    return (
      <ContentPortal>
        <CSSTransition
          classNames={sidePanelTransitions}
          timeout={animationMillis}
          in={opened}
          mountOnEnter
          unmountOnExit
        >
          <div className={sidePanelWrapperClass}>
            <div className={sidePanelOverlayClass} onClick={sticky ? () => {} : this.handleCloseButton} />

            <div className={sidePanelContentClass}>{children}</div>
          </div>
        </CSSTransition>
      </ContentPortal>
    )
  }

  @bind
  private handleCloseButton(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault()
    event.stopPropagation()
    this.props.onClose && this.props.onClose()
  }
}

export default BareSidePanel
