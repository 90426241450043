import * as React from "react"
import { Popup } from "./popup"
import { css } from "emotion"
import { useRef, useState } from "react"
import { useClickOutside } from "../../../utils/use-click-outside"
import { DropdownItemProps } from "../dropdown-button"
import Button, { ButtonProps } from "../button"
import { getThemeColorVar } from "../../helper/color"

interface Props {
  actions: (DropdownItemProps | "divider")[]
  permitPropagation?: boolean
}

const menuClass = css({
  margin: 0,

  padding: "4px 0",

  hr: {
    marginTop: "4px",
    marginBottom: "4px",
  },
})

const listItemClass = css({
  listStyle: "none",

  ":hover": {
    backgroundColor: getThemeColorVar("background", "lighter"),
  },
  padding: 4,

  "button, a": {
    padding: "0 16px",
    border: "0px",
    display: "block",
  },

  "~ li": {
    paddingTop: "8px",
  },
})

export const PopupMenu: React.FC<Props> = (props) => {
  const { actions } = props

  const [itemSelecting, setItemSelecting] = useState(false)

  const ref = useRef(null)
  useClickOutside(ref, () => setItemSelecting(false))

  return (
    <Popup
      placement="bottom-start"
      show={itemSelecting}
      ref={ref}
      tooltip={<ul className={menuClass}>{actions.map(renderItem)}</ul>}
      background={getThemeColorVar("background", "default")}
      color={getThemeColorVar("typo", "default")}
    >
      <Button
        type="tertiary"
        size="small"
        icon={itemSelecting ? "actionoverflow_active" : "actionoverflow_inactive"}
        formType="button"
        onClick={(event) => {
          if (!props.permitPropagation) {
            event.preventDefault()
            event.stopPropagation()
          }
          setItemSelecting((s) => !s)
        }}
      />
    </Popup>
  )

  function renderItem(item: DropdownItemProps | "divider", index: number) {
    if (item === "divider") {
      return <hr key={index} />
    } else {
      const { title, ...props } = item
      const enriched: ButtonProps = {
        ...props,
        type: "tertiary",
        size: "small",
      }

      return (
        <li key={index} className={listItemClass}>
          <Button
            {...enriched}
            onClick={(e) => {
              enriched.onClick?.(e)
              setItemSelecting((s) => !s)
            }}
          >
            {title}
          </Button>
        </li>
      )
    }
  }
}
