import { translations } from "../i18n"
import * as accounting from "accounting"

const primitiveTranslations = translations().primitive

export function isInteger(value: number | undefined) {
  return typeof value === "number" && isFinite(value) && Math.floor(value) === value
}

export function formatNumber(
  value: number | undefined,
  precision?: number,
  symbol?: string,
  forcePrecision: boolean = false
) {
  if (typeof value !== "number" || !isFinite(value)) {
    return ""
  }

  const narrowNoBreakSpace = String.fromCharCode(8239)

  const options = {
    symbol: symbol,
    format: symbol ? "%v" + narrowNoBreakSpace + "%s" : "%v",
    decimal: primitiveTranslations.decimalSeparator,
    thousand: primitiveTranslations.thousandSeparator,
    precision: isInteger(value) && !forcePrecision ? 0 : precision !== undefined ? precision : 2,
  }

  return accounting.formatMoney(value, options)
}

export function formatValue(value: number, precision?: number, unit?: string): string {
  if (!unit) {
    return formatNumber(value, precision)
  }

  const noBreakSpace = String.fromCharCode(160)
  const billion = translations().numberSuffix.billion
  const million = translations().numberSuffix.million

  switch (unit) {
    case "%":
      return formatNumber(value * 100, precision, "%")
    case "€":
      if (Math.abs(value) >= 1000000000)
        return formatNumber(value / 1000000000, precision, `${billion}${noBreakSpace}€`)
      else if (Math.abs(value) >= 1000000) return formatNumber(value / 1000000, precision, `${million}${noBreakSpace}€`)
      else return formatNumber(value, precision, "€")
    default:
      return formatNumber(value, precision, unit)
  }
}
