import * as React from "react"
import { translations } from "../i18n"
import { ApiStats } from "../models/api-stats"
import { useAppSelector } from "../../relas/store"
import { useEffect, useState } from "react"
import { dismissTokenStats, fetchTokenStats } from "../reducers/api-slice"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import Dialog from "../../shared/components/dialog"
import LoadingDimmer from "../../shared/components/loadingdimmer"
import Panel from "../../shared/components/panel"
import GridItem from "../../shared/components/restyle-grid/griditem"
import Table from "../../shared/components/table"
import Grid from "../../shared/components/restyle-grid/grid"
import Button from "../../shared/components/button"

interface OwnProps {
  tokenId: string
  label: string
}

export const ApiTokenStats = ({ tokenId, label }: OwnProps) => {
  const inFlight = useAppSelector((state) => state.apiState.fetchStatsInProgress)
  const error = useAppSelector((state) => state.apiState.fetchStatsError)
  const stats = useAppSelector((state) => state.apiState.currentStats)

  const t = translations().api.stats
  const now = new Date()
  const thisYear = now.getFullYear()
  const thisMonth = now.getMonth() + 1

  const [year, setYear] = useState(thisYear)
  const [month, setMonth] = useState(thisMonth)

  useEffect(() => {
    void fetchTokenStats(tokenId, year, month)
  }, [])

  const renderStats = (stats: ApiStats | null) => {
    if (!stats) return null
    if (Object.keys(stats.calls).length === 0) {
      return (
        <GridItem alignSelf="center" justifySelf="center">
          <Panel color="neutral">{t.noCallsFor(label)}</Panel>
        </GridItem>
      )
    }
    return (
      <div className="stats-table">
        <Table stickyHeader={true} selectable={true}>
          <thead>
            <tr>
              <th>{t.apiNameHeader}</th>
              <th>{t.callsHeader}</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(stats.calls).map((apiName: string) => {
              return (
                <tr key={apiName}>
                  <td>{apiName}</td>
                  <td>{stats.calls[apiName]}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    )
  }

  const nextMonth = () => {
    const [nextMonth, nextYear] = month === 12 ? [1, year + 1] : [month + 1, year]

    setMonth(nextMonth)
    setYear(nextYear)
    void fetchTokenStats(tokenId, nextYear, nextMonth)
  }

  const prevMonth = () => {
    const [nextMonth, nextYear] = month === 1 ? [12, year - 1] : [month - 1, year]
    setMonth(nextMonth)
    setYear(nextYear)
    void fetchTokenStats(tokenId, nextYear, nextMonth)
  }

  const renderNavigation = () => {
    return (
      <Grid columns={3}>
        <GridItem alignSelf="start">
          <Button onClick={prevMonth} type="secondary" icon="arrow_left">
            {t.prevMonth}
          </Button>
        </GridItem>
        <GridItem alignSelf="center" justifySelf="center">
          <Button
            type="secondary"
            onClick={() => fetchTokenStats(tokenId, year, month)}
            title="Refresh"
            icon="refresh"
          ></Button>
        </GridItem>
        <GridItem alignSelf="end" justifySelf="end">
          <Button
            type="secondary"
            disabled={month === thisMonth && year === thisYear}
            onClick={nextMonth}
            icon="arrow_right"
          >
            {t.nextMonth}
          </Button>
        </GridItem>
      </Grid>
    )
  }

  const invoiceLink = `/company/products-statistics`
  return (
    <Dialog
      onClose={dismissTokenStats}
      closeOnClickOutside={true}
      title={`${label} - ${t.title} - ${t.translateMonth(month)} ${year}`}
      icon="trending_up"
      width="80%"
      closeButton={true}
    >
      <Grid columns={1}>
        <LoadingDimmer loading={inFlight}>
          <Grid columns={1} gap="lg" paddingX="md" paddingY="md">
            {renderNavigation()}
            <p>
              {t.explanation(label, t.translateMonth(month), year)}. {t.invoiceLinkPrefix}{" "}
              <a target="_blank" href={invoiceLink}>
                {t.invoiceLinkText}
              </a>
              .
            </p>
            {renderStats(stats)}
            {error && <GenericErrorPanel error={error} />}
            {renderNavigation()}
          </Grid>
        </LoadingDimmer>
      </Grid>
    </Dialog>
  )
}
