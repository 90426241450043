import { Company } from "../models/company"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { store } from "../../relas/store"
import { AxiosError, AxiosResponse, default as Axios } from "axios"
import { companyadminServiceUrl } from "../../app_config"
import { AddressVerificationCode } from "../models/address-verification-code"
import { Address } from "../models/address"
import { GenericError, toGenericError } from "../../shared/helper/axios"

interface CompanySettingsState {
  company: Company | undefined
  fetchCompanyInProgress: boolean
  fetchCompanyError: GenericError | undefined
  updateLogoUrlInProgress: boolean
  updateLogoUrlError: GenericError | undefined
  updateAddressInProgress: boolean
  updateAddressError: GenericError | undefined
  updateAddressDone: boolean
  validateAddressInProgress: boolean
  validateAddressError: GenericError | undefined
}
export type CompanyAdminSettingsState = {
  companySettings: CompanySettingsState
}
export const initialState: CompanySettingsState = {
  company: undefined,
  fetchCompanyInProgress: false,
  fetchCompanyError: undefined,
  updateLogoUrlInProgress: false,
  updateLogoUrlError: undefined,
  updateAddressInProgress: false,
  updateAddressError: undefined,
  updateAddressDone: false,
  validateAddressError: undefined,
  validateAddressInProgress: false,
}

const companySettings = createSlice({
  name: "companySettings",
  initialState,
  reducers: {
    fetchCompanyStart(state) {
      state.company = undefined
      state.fetchCompanyError = undefined
      state.fetchCompanyInProgress = true
    },
    fetchCompanyDone(state, action: PayloadAction<Company>) {
      state.company = action.payload
      state.fetchCompanyError = undefined
      state.fetchCompanyInProgress = false
    },
    fetchCompanyError(state, action: PayloadAction<GenericError>) {
      state.company = undefined
      state.fetchCompanyError = action.payload
      state.fetchCompanyInProgress = false
    },
    updateLogoUrlStart(state) {
      state.updateLogoUrlError = undefined
      state.updateLogoUrlInProgress = true
    },
    updateLogoUrlDone(state, action: PayloadAction<Company>) {
      state.company = action.payload
      state.updateLogoUrlError = undefined
      state.updateLogoUrlInProgress = false
    },
    updateLogoUrlError(state, action: PayloadAction<GenericError>) {
      state.updateLogoUrlError = action.payload
      state.updateLogoUrlInProgress = false
    },
    updateAddressStart(state) {
      state.updateAddressError = undefined
      state.updateAddressInProgress = true
    },
    updateAddressDone(state, action: PayloadAction<Company>) {
      state.company = action.payload
      state.updateAddressError = undefined
      state.updateAddressInProgress = false
      state.updateAddressDone = true
    },
    updateAddressError(state, action: PayloadAction<GenericError>) {
      state.updateAddressError = action.payload
      state.updateAddressInProgress = false
    },
    validateAddressStart(state) {
      state.validateAddressError = undefined
      state.validateAddressInProgress = true
    },
    validateAddressDone(state, action: PayloadAction<Company>) {
      state.company = action.payload
      state.validateAddressError = undefined
      state.validateAddressInProgress = false
    },
    validateAddressError(state, action: PayloadAction<GenericError>) {
      state.validateAddressError = action.payload
      state.validateAddressInProgress = false
    },
  },
})

const {
  fetchCompanyStart,
  fetchCompanyDone,
  fetchCompanyError,
  updateLogoUrlStart,
  updateLogoUrlDone,
  updateLogoUrlError,
  updateAddressStart,
  updateAddressDone,
  updateAddressError,
  validateAddressStart,
  validateAddressDone,
  validateAddressError,
} = companySettings.actions

function readFile(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result === null ? undefined : reader.result)
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}

export function fetchCompany(): Promise<void> {
  store.dispatch(fetchCompanyStart())
  return Axios.get(`${companyadminServiceUrl}/api/v1/company`, {
    params: {},
  }).then(
    (response: AxiosResponse) => {
      const company = response.data as Company
      store.dispatch(fetchCompanyDone(company))
    },
    (error: AxiosError) => {
      store.dispatch(fetchCompanyError(toGenericError(error)))
    }
  )
}

export function uploadImage(file: File, path: string): Promise<void> {
  store.dispatch(updateLogoUrlStart())
  return readFile(file).then((fileContent) => {
    Axios.put(path, fileContent, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
    }).then(
      (response: AxiosResponse) => {
        const company = response.data as Company
        store.dispatch(updateLogoUrlDone(company))
      },
      (error: AxiosError) => {
        store.dispatch(updateLogoUrlError(toGenericError(error)))
      }
    )
  })
}

export function deleteImage(path: string): Promise<void> {
  store.dispatch(updateLogoUrlStart())
  return Axios.delete(path, {
    headers: {
      "Content-Type": "application/octet-stream",
    },
  }).then(
    (response: AxiosResponse) => {
      const company = response.data as Company
      store.dispatch(updateLogoUrlDone(company))
    },
    (error: AxiosError) => {
      store.dispatch(updateLogoUrlError(toGenericError(error)))
    }
  )
}

export function updateAddress(address: Address): Promise<void> {
  store.dispatch(updateAddressStart())
  return Axios.put(`${companyadminServiceUrl}/api/v1/company/address`, address, {
    params: {},
  }).then(
    (response: AxiosResponse) => {
      const company = response.data as Company
      store.dispatch(updateAddressDone(company))
    },
    (error: AxiosError) => {
      store.dispatch(updateAddressError(toGenericError(error)))
    }
  )
}
export function validateAddress(code: AddressVerificationCode): Promise<void> {
  store.dispatch(validateAddressStart())
  return Axios.post(`${companyadminServiceUrl}/api/v1/company/address/verify`, code, { params: {} }).then(
    (response: AxiosResponse) => {
      const company = response.data as Company
      store.dispatch(validateAddressDone(company))
    },
    (error: AxiosError) => {
      store.dispatch(validateAddressError(toGenericError(error)))
    }
  )
}
export default companySettings.reducer
