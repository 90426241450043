import { Translations } from "./translations"

export const EN: Translations = {
  login: {
    invalidEmail: "Invalid email",
    header: "Login",
    usernameLabel: "Email address",
    passwordLabel: "Password",
    cookiesSettings: {
      header: "Cookie Settings",
      usageWarn: "Our Website and Services are using cookies. For detailed information, please use our ",
      generalProtection: "general data protection rights page.",
      radioHeader: "Please choose the type of cookies we are allowed to use:",
      all: {
        label: "Enable all cookies",
        details:
          "You’ll help us to improve our page and optimize your workflow. We’ll use technically needed as well as tracking and analytics cookies.",
      },
      necessary: {
        label: "Enable only necessary cookies",
        details:
          "You’ll be able to use this website and only technically necessary cookies will be set (e.g. to manage your log-in).",
      },
      moreInfo: "more information",
      lessInfo: "less information",
      changeInfo: "You can change your cookie settings at any time in your account settings.",
    },
    loginButton: "Log in",
    loginFailed: "Login failed.",
    loginFailedNoProductsBooked: "You have no booked products.",
    otpLabel: "Authentication code",
    otpHint: "Please enter code",
    otpExplanation:
      "Please enter a code from your authenticator app (e.g. Google Authenticator) here.\nYour device will be marked as trusted for 30 days, so you won't have to enter a two-factor code to log in during that time.",
    passwordForgotten: "Password forgotten",
    registration: "Interested in getting an account?",
    backToLogin: "Back to login view",
    sendPasswordForgotten: "Send email",
    email: "Email",
    emailHint: `Your registered Email`,
    passwordForgottenOkHeader: "E-mail sent",
    passwordForgottenOkText: `If the address is registered on 21st-Market then an email has been sent with further instructions.`,
    passwordForgottenErrHeader: "Could not send email",
    passwordForgottenErrText: "An error occurred trying to send your email. Please try again later.",
    userBlocked:
      "Your account is blocked and you do not have the authorization to use the plattform. Please contact us via support@21re.de",
    or: "Or",
    registerWithIS24: "Register with ImmoScout24",
    IS24LoginErrorTitle: "Login Error",
    IS24LoginError:
      "We are unable to log you in at this time. Please contact your ImmoScout24 account manager for assistance.",
  },
  setPassword: {
    setNewPasswordHeader: "Set new password",
    setPasswordHeader: "Set new password",
    setPasswordInfo:
      "Here you can set your personal password. This password, together with your email address, is needed for login.",
    newPasswordLabel: "Your new password",
    passwordLabel: "Your password",
    confirmPasswordLabel: "Retype your new password",
    valid: "Looks good, ready to submit!",
    tooLong: (charCount: number) => `Your new password is too long. It should be at most ${charCount} characters long.`,
    tooShort: (charCount: number) =>
      `Your new password is too short. It should be at least  ${charCount} characters long.`,
    notMatching: "Your passwords do not match.",
    submitNewPassword: "Change my password",
    submitPassword: "Set my password",
    submitError: "Error changing password. Please try again later.",
    submitNewSuccess: "Your password has been set. Please use it to log in.",
    submitSuccess: "Your password has been changed. Please use it to log in.",
    loginNow: "Log in now",
  },
  twoFactorSetup: {
    setupHeader: "Setup",
    explanationHeader: "Instructions",
    generatedCodeLabel: "Please enter the generated code.",
    submitLabel: "Complete setup",
    submittedOkText: "Your two factor authentication is now active.",
    submittedErrorText:
      "There was an error setting up your second factor authentication. The generated code is likely invalid.",
    secretLabel: "The secret key is",
    qrRenderError:
      "An error occurred trying rendering your QR code. Please try using another browser or upgrade the one you are using. Alternatively, you could try setting up your virtual MFA device by using the secret key below.",
    explanation: `Install one of the virtual MFA apps listed below, then scan the QR code using that app. The app will now be able to generate a code that changes every 30 seconds. When you are prompted for a code on 21st-Market, start the app and type in the code shown. To familiarize you with this process we request that you enter one such code below before finishing the setup of your MFA device.`,
    teaser:
      "Traditionally you log in using just a password, but if someone cracks or steals it, your account is compromised. With two factor authentication (2FA) you have to provide a single use code when you log in. A device that you own (typically a smartphone) generates the code, so an intruder would have to steal both your password and your device, increasing both your and our security.",
    infographic: "/assets/mfa_en.png",
    otpLengthMismatch: "The code consists of 6 digits.",

    infographicHeader: "How it works",

    companyAdminHeader: "Please set up two-factor authentication",
    companyAdminExplanation:
      "You are flagged as a company administrator in our system. This allows you to alter important company-wide settings, such as calculation defaults, and confers you the ability to administer other company members as well. As a consequence, we recommend going the extra mile to protect your account from hackers who might be able to guess your password.",
    mandatorySetup: "You are required to set up two-factor authentication before you can continue.",
  },
  links: {
    imprint: "Legal data",
    terms: "Terms and conditions",
    privacy: "Privacy protection",
    relativeImprintLink: "impressum?lang=en",
    relativePrivacyLink: "datenschutz?lang=en",
    relativeTermsLink: "agb?lang=en",
    relativeContactFormLink: "kontakt?lang=en",
  },
  autoCloseLogin: {
    title: "Your session has been extended!",
    paragraph: "Hang on and we'll take you back in just a few seconds.",
  },
}
