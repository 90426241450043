import * as React from "react"
import { BorderBottom } from "../../shared/components/ui/border-bottom"
import { Flex } from "../../shared/components/ui/flex"
import { FlexItem } from "../../shared/components/ui/flex-item"
import { Grid } from "../../shared/components/ui/grid"
import { translations } from "../i18n"
import { DataSetType } from "../../shared/models/smartdata"
import { useForm } from "react-hook-form"
import { GlobalState, useAppSelector } from "../../relas/store"
import { clearCreationError, createSelection, returnToSelectionList } from "../location-selector-slice"
import { RadioButton } from "../../shared/components/ui/radio-button"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import Panel from "../../shared/components/panel"
import TextField from "../../shared/components/textfield"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"
import Button from "../../shared/components/button"
import { GenericError } from "../../shared/helper/axios"

type FormData = {
  title: string
  dataSetType: DataSetType
}

const defaultInputs: FormData = {
  title: "",
  dataSetType: "macro",
}

export const CreateSelection = () => {
  const t = React.useMemo(() => translations(), [])
  const creationError = useAppSelector((state: GlobalState) => state.locationSelector.selectionCreationError)

  const { register, handleSubmit, setValue, watch } = useForm<FormData>({ defaultValues: defaultInputs })

  const onSubmit = (data: FormData) => createSelection(data.title.trim(), data.dataSetType)

  const renderCreateError = (error: GenericError) =>
    error.status && error.status === 409 ? (
      <Panel color="negative">{t.selectionAlreadyExists}</Panel>
    ) : (
      <GenericErrorPanel error={error} />
    )

  React.useEffect(() => {
    if (creationError) {
      clearCreationError()
    }
  }, [watch("title")])

  return (
    <Grid height={[100, "%"]} columns={1}>
      <Grid columns={1} height={[100, "%"]} rowSpec="min-content 1fr">
        <BorderBottom>
          <Flex flexDirection="column" padding={[8, 0]}>
            <FlexItem alignSelf="start" padding={[8, 16]}>
              <Button type="tertiary" icon="back" onClick={returnToSelectionList}>
                {t.allSelections}
              </Button>
            </FlexItem>
            <FlexItem alignSelf="start" padding={[0, 16]}>
              <h2>{t.newSelection}</h2>
            </FlexItem>
          </Flex>
        </BorderBottom>

        <Flex flexDirection="column" padding={16} gap={8}>
          <TextField
            {...register("title")}
            value={watch("title")}
            onValueChange={(v) => setValue("title", v)}
            label={t.title}
          />
          <fieldset>
            <legend>{t.context}</legend>
            <RadioButton
              {...register("dataSetType")}
              id="scoreKeyMacro"
              value="macro"
              label={t.macro}
              name="scoreKey"
              checked={watch("dataSetType") === "macro"}
              onChange={(_) => setValue("dataSetType", "macro")}
            />
            <RadioButton
              {...register("dataSetType")}
              id="scoreKeyMicro"
              value="micro"
              label={t.micro}
              name="scoreKey"
              checked={watch("dataSetType") === "micro"}
              onChange={(_) => setValue("dataSetType", "micro")}
            />
          </fieldset>

          {creationError && renderCreateError(creationError)}
        </Flex>

        <FlexContainer canGrow xs-align="end">
          <FlexContainer flexWrap="wrap" direction="row" padding={16}>
            <FlexContainer />
            <Button type="primary" onClick={handleSubmit(onSubmit)} disabled={!(watch("title").trim().length > 0)}>
              {t.createSelection}
            </Button>
          </FlexContainer>
        </FlexContainer>
      </Grid>
    </Grid>
  )
}
