import Axios, { AxiosResponse, CancelTokenSource } from "axios"
import { lanaApiUrl } from "../app_config"
import { IsochroneType } from "../assessment/components/isochrone-type"
import { Location } from "../assessment/models/address"
import { HereCombinedResponse } from "../shared/models/poi-explorer"
import { Feature } from "ol"
import { Polygon } from "ol/geom"
import { getThemeColor } from "../shared/helper/color"
import { language } from "../shared/language"

export function toGooglePolygonCoordinates(poiResponse: HereCombinedResponse): { lng: number; lat: number }[] {
  const coords = poiResponse.isolines[0].polygons[0].map((polygon) => ({ lat: polygon[1], lng: polygon[0] }))
  return coords
}

export function makePolygon(paths: { lng: number; lat: number }[]): google.maps.Polygon {
  return new google.maps.Polygon({
    paths: paths,
    strokeColor: getThemeColor("primary", "default").toString(),
    strokeWeight: 4,
    fillColor: "rgba(255, 255, 255, 0.6)",
    clickable: false,
  })
}

let token: CancelTokenSource | undefined

export const getIsochronePolygon = (
  location: Location,
  isochroneSettings: IsochroneType
): Promise<google.maps.Polygon> => {
  token?.cancel()
  token = Axios.CancelToken.source()
  return Axios.get(`${lanaApiUrl}/api/v2/here/combined`, {
    params: {
      lat: location.lat.toString(),
      lng: location.lng.toString(),
      strictFilter: false,
      range: isochroneSettings.time * 60,
      rangeType: "time",
      transportMode: isochroneSettings.mode,
      language: language(),
    },
    cancelToken: token.token,
  }).then((success: AxiosResponse) => {
    let paths = toGooglePolygonCoordinates(success.data)
    const polygon = makePolygon(paths)
    return polygon
  })
}

export const toOlFeatureFromGooglePolygon = (polygon: google.maps.Polygon): Feature => {
  let path = polygon.getPath().getArray()
  let coordArray = path.map((latLng) => [latLng.lng(), latLng.lat()])

  return new Feature({
    geometry: new Polygon([coordArray]).transform("EPSG:4326", "EPSG:3857"),
  })
}
