import * as React from "react"
import {
  GeoInformation,
  HereAddress,
  LocationResponse,
  AddressSuggestion,
  AddressSuggestionResponse,
} from "../../shared/models/poi-explorer"
import { Address, formatAddress, isAddressComplete, Location } from "../models/address"
import { AddressGeoView } from "./address-geo-view"
import { AddressSuggestionAutoComplete } from "../../shared/components/address-suggestion-auto-complete"
import { bind, debounce } from "decko"
import Axios, { CancelTokenSource } from "axios"
import { translations } from "../i18n"
import * as proj from "ol/proj"
import { isoCountry } from "iso-country"
import { Flex } from "../../shared/components/ui/flex"
import { FlexItem } from "../../shared/components/ui/flex-item"
import { geoServiceUrl } from "../../app_config"
import { AppModules } from "../../menu/util/app-location-types"
import Dialog from "../../shared/components/dialog"
import Panel from "../../shared/components/panel"
import Grid from "../../shared/components/restyle-grid/grid"
import Button from "../../shared/components/button"
import { language } from "../../shared/language"

export interface AbstractProps {
  onClose: () => void
  module: AppModules["locationAssessment"]
}

export interface AbstractState {
  suggestions: AddressSuggestion[]
  addressSelection: Address | null
  center: number[]
  zoom: number
  searchTerm: string
  searchTermSuggestion: AddressSuggestion | undefined
  showSuggestions: boolean
  tryToAddDuplicate: boolean
  manualCoords: boolean
  droppedLocation: Location | null
  droppedLocationGeocodingLoading: boolean
}

export const INITIAL_STATE: AbstractState = {
  suggestions: [],
  center: proj.fromLonLat([10.5, 51]),
  zoom: 6,
  searchTerm: "",
  searchTermSuggestion: undefined,
  showSuggestions: false,
  addressSelection: null,
  tryToAddDuplicate: false,
  manualCoords: false,
  droppedLocation: null,
  droppedLocationGeocodingLoading: false,
}

function getOrElse(maybe: string | undefined): string {
  return `${maybe ? `${maybe} ` : ""}`
}

let cancel: CancelTokenSource | undefined = undefined
// At the moment is does not make sense to add addresses outside germany
const SUGGESTION_SUFFIX = ", Deutschland"

export abstract class AddressDialog<P extends AbstractProps, S extends AbstractState> extends React.Component<P, S> {
  protected t = translations()

  render() {
    const { showSuggestions, suggestions, searchTerm, addressSelection, manualCoords } = this.state
    const { onClose } = this.props

    // We keep the old address and new location while dropped pin reverse geocoding is loading
    const currentlyDisplayedAddressLocation: Location | undefined =
      this.state.droppedLocationGeocodingLoading && !!this.state.droppedLocation
        ? this.state.droppedLocation
        : addressSelection
        ? addressSelection.location
        : undefined

    const showSetPinHint = !manualCoords && addressSelection !== null && !isAddressComplete(addressSelection)
    const addressOutsideOfGermany =
      !showSetPinHint &&
      addressSelection !== null &&
      addressSelection.country !== undefined &&
      addressSelection.country !== "DE"

    return (
      <Dialog width="60vw" height="60vh" onClose={onClose} closeOnClickOutside>
        <Grid columns={2} padding={24} gap={16} height={[100, "%"]}>
          <Flex flexDirection="column" gap={8}>
            {this.renderAbove()}
            <AddressSuggestionAutoComplete
              displaySuggestion={(s: AddressSuggestion) => s.address.label || s.label}
              label={this.t.assessmentAddAddress.addressLabel}
              showSuggestions={showSuggestions}
              suggestions={suggestions}
              value={searchTerm}
              onValueChange={this.fetchSuggestions}
              selectSuggestion={(s) => s && this.selectAddress(s)}
              onFocus={() => {
                suggestions && suggestions.length > 0 && this.setState({ showSuggestions: true })
              }}
              toDoOnBlur={() => this.setState({ showSuggestions: false })}
              onForceSuggestion={this.onForceSelection}
              hint={this.t.assessmentAddAddress.addressInputPlaceholder}
              hasWarning={showSetPinHint || addressOutsideOfGermany}
            />

            {showSetPinHint && <Panel color="neutral">{this.t.assessmentAddAddress.setPinHint}</Panel>}
            {addressOutsideOfGermany && <Panel color="neutral">{this.t.assessmentAddAddress.outsideOfGermany}</Panel>}

            {this.renderBelow()}
            <FlexItem flexGrow={1} />
            <Flex flexDirection="row" flexGrow={0}>
              <Button type="secondary" onClick={onClose}>
                {this.t.cancel}
              </Button>
              <FlexItem flexGrow={1} />
              <Button
                type="primary"
                icon="save"
                loading={this.isLoading()}
                disabled={!addressSelection || showSetPinHint || addressOutsideOfGermany}
                onClick={() => this.onOk(addressSelection)}
              >
                {this.okButtonLabel()}
              </Button>
            </Flex>
          </Flex>
          <AddressGeoView
            address={currentlyDisplayedAddressLocation}
            onSetPin={this.state.droppedLocationGeocodingLoading ? undefined : this.onSetPin}
          />
        </Grid>
      </Dialog>
    )
  }

  protected abstract isLoading(): boolean

  protected abstract renderAbove(): JSX.Element | null

  protected abstract renderBelow(): JSX.Element | null

  protected abstract onOk(address: Address | null): void

  protected abstract okButtonLabel(): string

  @bind
  private fetchSuggestions(address: string): Promise<void> {
    this.setState({
      searchTerm: address,
      searchTermSuggestion: undefined,
      showSuggestions: false,
      suggestions: [],
      tryToAddDuplicate: false,
    })
    this.getSuggestionsDebounced(address + SUGGESTION_SUFFIX)

    return Promise.resolve()
  }

  @bind
  private onForceSelection() {
    this.getSuggestions(this.state.searchTerm + SUGGESTION_SUFFIX, (suggestions) => {
      if (suggestions.length == 1) {
        this.selectAddress(suggestions[0])
      } else {
        this.setState({ suggestions, showSuggestions: suggestions.length > 0 })
      }
    })
  }

  @debounce(700)
  private getSuggestionsDebounced(address: string): void {
    this.getSuggestions(address, (suggestions) =>
      this.setState({ suggestions, showSuggestions: suggestions.length > 0 })
    )
  }

  private getSuggestions(address: string, callback: (suggesstions: AddressSuggestion[]) => void): void {
    cancel?.cancel()
    cancel = Axios.CancelToken.source()

    if (address.trim().length !== 0) {
      const lang = language()
      Axios.get(
        `${geoServiceUrl}/api/geocode/by/here/autocomplete?query=${encodeURIComponent(
          address
        )}&maxresults=10&language=${lang}`,
        { withCredentials: true, cancelToken: cancel.token }
      ).then(
        (success) => {
          cancel = undefined
          const response: AddressSuggestionResponse = success.data
          const suggestions = response.suggestions

          return Promise.all(
            suggestions.map((suggestion) =>
              Axios.get<LocationResponse>(
                `${geoServiceUrl}/api/here/location/${encodeURIComponent(suggestion.locationId)}?language=${lang}`,
                { withCredentials: true }
              ).then((success) => {
                const locationResponse: LocationResponse = success.data
                const result: AddressSuggestion = {
                  ...suggestion,
                  address: this.resolveAddress(suggestion, locationResponse),
                  geoInformation: this.locationToGeoInformation(locationResponse),
                }

                return result
              })
            )
          ).then(
            (success) => callback(success),
            (error) => {
              if (!Axios.isCancel(error)) {
                callback([])
              }
            }
          )
        },
        (error) => {
          if (!Axios.isCancel(error)) {
            callback([])
          }
        }
      )
    }
  }

  private locationToGeoInformation(locationResponse: LocationResponse): GeoInformation {
    const location = locationResponse.response.view[0].result[0].location

    return {
      coordinates: {
        latitude: location.displayPosition.latitude,
        longitude: location.displayPosition.longitude,
      },
    }
  }

  private resolveAddress(suggestion: AddressSuggestion, locationResponse: LocationResponse): HereAddress {
    const location = locationResponse.response.view[0].result[0].location

    if (location.address) {
      const address = location.address
      const maybeCountry = address.additionalData && address.additionalData["CountryName"]

      return {
        ...address,
        country: maybeCountry || suggestion.address.country,
      }
    }

    return suggestion.address
  }

  private suggestionToAddress(s: AddressSuggestion): Address {
    const lookupResult = isoCountry(s.countryCode)

    return {
      location: {
        lat: s.geoInformation.coordinates.latitude,
        lng: s.geoInformation.coordinates.longitude,
      },
      route: s.address.street,
      administrativeAreaLevel1: s.address.state,
      country: lookupResult ? lookupResult.code : "",
      locality: s.address.city,
      postalCode: s.address.postalCode,
      streetNumber: s.address.houseNumber,
    }
  }

  private selectAddress(suggestion: AddressSuggestion) {
    const formattedAddress = `${getOrElse(suggestion.address.country)}${getOrElse(
      suggestion.address.postalCode
    )}${getOrElse(suggestion.address.city)}${getOrElse(suggestion.address.street)}${getOrElse(
      suggestion.address.houseNumber
    )}`

    cancel?.cancel()
    this.setState({
      addressSelection: this.suggestionToAddress(suggestion),
      searchTerm: formattedAddress,
      searchTermSuggestion: suggestion,
      showSuggestions: false,
      center: proj.fromLonLat([
        suggestion.geoInformation.coordinates.longitude,
        suggestion.geoInformation.coordinates.latitude,
      ]),
      zoom: 16,
      manualCoords: false,
      droppedLocation: null,
    })
  }

  @bind
  private onSetPin(location: Location) {
    cancel?.cancel()
    this.setState({ droppedLocation: location, droppedLocationGeocodingLoading: true })
    const lang = language()

    Axios.get(`${geoServiceUrl}/api/here/reverse?lng=${location.lng}&lat=${location.lat}&language=${lang}`, {
      withCredentials: true,
    }).then(
      (success) => this.updateSelectedAddressFromLocation(location, success.data),
      () => {}
    )
  }

  @bind
  private updateSelectedAddressFromLocation(location: Location, reverseAddresses: Address[]) {
    const { addressSelection } = this.state

    if (reverseAddresses.length > 0) {
      this.setState({
        addressSelection: {
          ...reverseAddresses[0],
          location,
        },
        searchTerm: formatAddress(reverseAddresses[0]),
        manualCoords: true,
        droppedLocationGeocodingLoading: false,
      })
    } else if (addressSelection != null) {
      this.setState({
        addressSelection: {
          ...addressSelection,
          location,
        },
        manualCoords: true,
        droppedLocationGeocodingLoading: false,
      })
    }
  }
}
