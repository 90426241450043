import React, { useEffect, useState } from "react"
import Axios, { AxiosResponse } from "axios"
import { lanaApiUrl } from "../../../app_config"
import { NonConfigurableWidgetsType, WidgetsType } from "../../models/assessment"
import { translations } from "../../i18n"
import { defaultPOIsSettings } from "./pois-widget"
import useInterval from "../../../utils/use-interval"
import { DownloadReport } from "../../../shared/components/ui/download-report"
import DashboardConfigDrawer from "./dashboard-config-drawer"
import { useAppSelector } from "../../../relas/store"
import {
  AssessmentDashboard,
  AssessmentDashboardId,
  AssessmentDashboardUpdate,
  AssessmentDashboardWidgetId,
  WidgetConfigData,
} from "../../models/AssessmentDashboard"
import { AppModules } from "../../../menu/util/app-location-types"
import { loadAssessmentForAssessmentModule } from "../../reducers/comparables-slice"
import { DocumentationAndSupport } from "../../../shared/components/documentation-and-support"
import { defaultMacroScores, defaultMicroScores } from "./scores-widget"
import {
  createDefaultDashboard,
  LAST_SET_DASHBOARD_CONFIG_KEY,
  selectedUserAssessmentDashboard,
} from "../../../relas/user-slice"
import { v4 } from "uuid"
import { DashboardWidgetGrid } from "./dashboard-widget-grid"
import { loadSelectionEntries } from "../../reducers/assessment-slice-functions"
import { defaultIsochroneConfig, defaultPrivatePoiCategories } from "./micro-map-widget"
import { fetchCategories } from "../../../private-data/reducers/private-data-slice"
import {
  defaultDistrictWidgetData,
  defaultFundamentalWidgetData,
  defaultWidgetLayouts,
  RatingsSelectionSettings,
} from "./dashboard-export"
import { InvalidAddressMessage } from "../../../shared/components/ui/invalid-address-message"
import Panel from "../../../shared/components/panel"
import Grid from "../../../shared/components/restyle-grid/grid"
import { SizeSpec } from "../../../shared/util/layoutconstants"
import { featureEnabled, HELP_DEVELOPMENT } from "../../../utils/features-toggle"
import { navigateTo } from "../../../shared/reducers/navigation-slice"
import { NavigationPage } from "../../../shared/actions/navigation"
import LanaSubheader from "../../../shared/components/lana-subheader"

interface Props {
  assessmentId: string
  assessmentEntryId: string | null
  module: AppModules["locationAssessment"]
  cleanDashboard: boolean
  showDashboardId?: AssessmentDashboardId
}

declare const window: Window & {
  // It is used by the headless chrome (backend print service) to wait for the page to finish rendering
  getReadyToRenderPromise: () => Promise<void>
  readyToPrint: {
    [k in AssessmentDashboardId]: boolean
  }
}

function getReadyToRenderDashboardPromise(): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    let checkerTimeout: number

    function checker(count: number): number {
      return window.setTimeout(
        () => {
          if (window.readyToPrint && Object.values(window.readyToPrint).every((v) => v)) {
            console.log("getReadyToRenderPromise - ready to print")
            if (cancellationTimeout) {
              clearTimeout(cancellationTimeout)
            }
            resolve()
          } else {
            console.log(
              `getReadyToRenderPromise - not ready to render after ${count} retries: ` +
                Object.entries(window.readyToPrint)
                  .map(([k, v]) => `${k}: ${v}`)
                  .join(", ")
            )

            checkerTimeout = checker(count + 1)
          }
        },
        5000,
        1
      )
    }

    const cancellationTimeout = window.setTimeout(() => {
      if (checkerTimeout) {
        clearTimeout(checkerTimeout)
      }
      reject("getReadyToRenderPromise - timed is out")
    }, 120000)

    checkerTimeout = checker(0)
  })
}

const documentationLink = "https://docs.google.com/document/d/1QdeFh2m8fomq2Z8LBvXx3OV-2uA0Y3oy6w9ZTKhgT6A"

export const DashboardApp = ({ cleanDashboard, module, showDashboardId }: Props) => {
  const t = React.useMemo(translations, [translations])

  const assessment = useAppSelector((state) => state.assessment.currentAssessment)
  const assessmentEntry = useAppSelector((state) => state.assessment.currentAssessmentEntry)
  const dashboardConfigurations = useAppSelector((state) => state.user.lana.assessmentDashboards)
  const assessmentDashboardLoading = useAppSelector((state) => state.user.lana.assessmentDashboardLoading)
  const selectedDashboardIdFromGlobalState = useAppSelector((state) => state.user.lana.selectedDashboardId)
  const privatePoiCategories = useAppSelector((state) => state.privateData.privatePOICategories)

  const selectedDashboardId = showDashboardId || selectedDashboardIdFromGlobalState
  const editMode = useAppSelector((state) => state.user.lana.editingUserDashboards)

  const selectedDashboard = dashboardConfigurations?.find((d) => d.id === selectedDashboardId)

  const userId = useAppSelector((state) => state.user.user?.id)

  const [configWidget, setConfigWidget] = useState<[AssessmentDashboardWidgetId, WidgetConfigData]>()

  const defaultRatings: RatingsSelectionSettings = assessmentEntry
    ? {
        selectedRatings: assessmentEntry?.ratings.map((rating) => rating.id),
        selectedMetaRatings: assessmentEntry?.metaRatings?.map((r) => r.id),
        selectionMode: "all",
      }
    : { selectedRatings: [], selectedMetaRatings: [], selectionMode: "all" }

  const onRenderingDone = () => {
    if (selectedDashboardId) {
      window.readyToPrint = {
        [selectedDashboardId]: true,
      }
    }
  }

  useEffect(() => {
    void loadSelectionEntries()
    void fetchCategories()

    window.getReadyToRenderPromise = getReadyToRenderDashboardPromise

    return () => {
      window.getReadyToRenderPromise = () => Promise.resolve()
    }
  }, [])

  // This grid show fit the FullHD screen with 12 rows without scroll
  // we have large rows on print
  const gridRowHeight = cleanDashboard ? 70 : 56

  const getPrintCanvasSize = () => {
    const printWidth = 1600
    // TODO it should be computed, default layout was optimized to take 12 rows and should fit in 1024 px. It should be computed based on the layout height. It should be a fraction of width - A4 210:297 aspect ratio

    const printHeight = gridRowHeight * 12
    return [printWidth, printHeight]
  }

  const getPrintStyles = () => {
    const [w] = getPrintCanvasSize()
    const printWidth = `${w}px`

    return (
      <style>{`
          @page {
              margin: 1.6cm 0.2cm 1.6cm 0.2cm;
              size: A4 landscape;
              bleed: 0cm;
          }
          @page:first {
              margin: 0.6cm 0.2cm 0.6cm 0.2cm !important;
          }
          body.print-mode .assessment-dashboard-component { width: ${printWidth}; }
          body.print-mode { width: ${printWidth}; }
          body.print-mode #revo-menu {width: ${printWidth}; }
          body.print-mode #revo-menu .revoAppDrawer .revoMenu__menuWrapper {width:${printWidth}; }
          body.print-mode #revo-menu .revoAppDrawer .revoAppDrawer__pushedArea {width:${printWidth}; }
          body.print-mode #revo-menu #revo-content {width: ${printWidth}; }
          body.print-mode #revo-menu .revoMenu__contentWrapper { width: ${printWidth}; }
        `}</style>
    )
  }

  const getDefaultDashboard = () => {
    const dataJson: AssessmentDashboardUpdate = {
      name: "default",
      config: {
        version: 1,
        widgets: defaultWidgetLayouts.map((item) => {
          let config: WidgetConfigData
          switch (item.t) {
            case "microMap":
              config = {
                type: "microMap",
                privatePOICategories: defaultPrivatePoiCategories,
                isochrone: defaultIsochroneConfig,
              }
              break
            case "districtData":
              config = { type: "districtData", districtData: defaultDistrictWidgetData }

              break
            case "POIs":
              config = {
                type: "POIs",
                ...defaultPOIsSettings,
              }

              break
            case "ratings":
              config = {
                type: "ratings",
                selectedRatings: defaultRatings.selectedRatings,
                selectionMode: defaultRatings.selectionMode,
                selectedMetaRatings: defaultRatings.selectedMetaRatings,
              }

              break
            case "microScores":
              config = { type: "microScores", microScores: Array.from(defaultMicroScores) }

              break
            case "macroScores":
              config = { type: "macroScores", macroScores: Array.from(defaultMacroScores) }

              break
            default:
              config = { type: item.t as NonConfigurableWidgetsType }
          }
          return {
            id: v4(),
            type: item.t as WidgetsType,
            dimensions: {
              x: item.x,
              y: item.y,
              w: item.w,
              h: item.h,
            },
            config,
          }
        }),
      },
    }
    return dataJson
  }

  useEffect(() => {
    if (!assessmentDashboardLoading && selectedDashboard === undefined) {
      if (!dashboardConfigurations || dashboardConfigurations.length === 0) {
        // Create a default dashboard
        void createDefaultDashboard(getDefaultDashboard())
      } else {
        let dashboardToSelect: AssessmentDashboard | undefined

        if (userId) {
          const lastSetDashboardIdFromLocalStorage = window.localStorage.getItem(LAST_SET_DASHBOARD_CONFIG_KEY(userId))
          if (lastSetDashboardIdFromLocalStorage) {
            dashboardToSelect = dashboardConfigurations.find((item) => item.id === lastSetDashboardIdFromLocalStorage)
          }
        }

        if (!dashboardToSelect) {
          dashboardToSelect = dashboardConfigurations.find((item) => item.isDefault)
        }

        if (!dashboardToSelect) {
          dashboardToSelect = dashboardConfigurations[0]
        }

        if (selectedDashboardId !== dashboardToSelect.id) {
          selectedUserAssessmentDashboard(dashboardToSelect.id)
        }
      }
    }
  }, [assessmentDashboardLoading])

  // assessment creation from Dashboard module
  useInterval(
    () => {
      if (assessment && assessmentEntry) {
        Axios.get(
          `${lanaApiUrl}/api/assessments/${encodeURIComponent(assessment.id)}/entries/${encodeURIComponent(
            assessmentEntry.id
          )}`
        ).then(
          (success: AxiosResponse) => {
            if (assessmentEntry.agsId !== success.data.agsId) {
              void loadAssessmentForAssessmentModule(assessment.id, assessmentEntry.id, "dashboard")
            }
            if (assessmentEntry.state !== success.data.agsId) {
              void loadAssessmentForAssessmentModule(assessment.id, assessmentEntry.id, "dashboard")
            }
          },
          () => {}
        )
      }
    },
    assessment &&
      assessmentEntry &&
      assessmentEntry.agsId &&
      (assessmentEntry.state === "scored" ||
        assessmentEntry.state === "scored-ambiguous" ||
        assessmentEntry.state === "failure")
      ? null
      : 1000
  )

  if (showDashboardId && !selectedDashboard) {
    return <Panel color="negative">{"Dashboard Not Found"}</Panel>
  }

  const gridHeight: SizeSpec = [100, "%"]
  const widgetGridRowHeight = gridRowHeight

  const gridRowSpec = "fit-content(100%) min-content"

  const helpDevelopment = featureEnabled(HELP_DEVELOPMENT)
  return (
    <div className={"assessment-dashboard-component"}>
      {cleanDashboard && getPrintStyles()}
      {!cleanDashboard && configWidget && (
        <DashboardConfigDrawer
          scores={configWidget[1].type === "microScores" ? assessmentEntry?.microData : assessmentEntry?.macroData}
          selectedScores={
            configWidget[1].type === "microScores"
              ? configWidget[1].microScores
              : configWidget[1].type === "macroScores"
              ? configWidget[1].macroScores
              : undefined
          }
          assessmentEntry={assessmentEntry}
          selectedRatings={configWidget[1].type === "ratings" ? configWidget[1] : defaultRatings}
          availablePrivatePoiCategories={privatePoiCategories}
          selectedPrivatePoiCategories={
            configWidget[1].type === "microMap"
              ? configWidget[1].privatePOICategories ?? defaultPrivatePoiCategories
              : defaultPrivatePoiCategories
          }
          isochrone={
            configWidget[1].type === "microMap"
              ? configWidget[1].isochrone ?? defaultIsochroneConfig
              : defaultIsochroneConfig
          }
          selectedPOIs={
            configWidget[1].type === "POIs"
              ? {
                  selectedPOICategories: configWidget[1].selectedPOICategories || [],
                  selectionMode: configWidget[1].selectionMode || "defaults",
                }
              : defaultPOIsSettings
          }
          widgetConfig={configWidget[1]}
          widgetId={configWidget[0]}
          selectedInDistrictData={
            configWidget[1].type === "districtData"
              ? configWidget[1].districtData.map((item) => item.toLowerCase())
              : defaultFundamentalWidgetData
          }
          onClose={() => setConfigWidget(undefined)}
        />
      )}
      <Grid columns={1} height={gridHeight} rowSpec={gridRowSpec}>
        <LanaSubheader
          menuSection={"locationAssessment"}
          assessment={assessment}
          assessmentEntry={assessmentEntry}
          module={module}
          recalculationButton={true}
          printMode={cleanDashboard}
          editMode={editMode}
          defaultDashboardWidgets={() => getDefaultDashboard().config}
          displayDashboardId={selectedDashboardId}
        >
          {!cleanDashboard && (
            <DocumentationAndSupport
              documentationURL={helpDevelopment ? undefined : documentationLink}
              addDocumentationLink
              tooltip={t.helpAndSupport}
              onClick={
                helpDevelopment
                  ? () => navigateTo({ name: NavigationPage.help, params: { section: "dashboard" } })
                  : () => {}
              }
              callLocation="Dashboard"
            />
          )}
          {!cleanDashboard && <DownloadReport showDashboardDownload={selectedDashboardId} />}
        </LanaSubheader>

        <InvalidAddressMessage assessmentEntry={assessmentEntry} />

        {assessmentEntry && assessment && assessmentEntry?.state !== "failure" && (
          <DashboardWidgetGrid
            dashboard={selectedDashboard}
            setConfigWidget={(widgetId, conf) => setConfigWidget([widgetId, conf])}
            editMode={editMode}
            cleanDashboard={cleanDashboard}
            assessmentEntry={assessmentEntry}
            assessment={assessment}
            onRenderingDone={onRenderingDone}
            rowHeight={widgetGridRowHeight}
            defaultDashboardWidgets={() => getDefaultDashboard().config}
          />
        )}
      </Grid>
    </div>
  )
}
