import React from "react"
import { css } from "emotion"
import { Button, ButtonGroup, Intent, Tag } from "@blueprintjs/core"
import { getThemeColor } from "../../helper/color"

const tagSelectClass = css({
  "div .bp4-tag.bp4-intent-success": {
    backgroundColor: getThemeColor("secondary1", "default").string(),
    color: "white",
  },
})

export interface TagWithCount {
  name: string
  count: number
}

export interface TagSelectGroupProps {
  totalCount: number
  tags: TagWithCount[]
  selectedTag: string | null
  onSelectTag: (tag: string | null) => void
}
const TagFilterSelectComponent: React.FunctionComponent<TagSelectGroupProps> = (props) => {
  const { totalCount, tags, selectedTag, onSelectTag } = props

  return (
    <ButtonGroup>
      <Button
        intent={selectedTag === null ? Intent.PRIMARY : Intent.NONE}
        rightIcon={<Tag intent={Intent.SUCCESS}>{totalCount}</Tag>}
        text="All"
        onClick={() => onSelectTag(null)}
      />
      {tags.map((tag, idx) => (
        <Button
          key={idx}
          intent={selectedTag === tag.name ? Intent.PRIMARY : Intent.NONE}
          rightIcon={<Tag intent={Intent.SUCCESS}>{tag.count}</Tag>}
          text={tag.name}
          onClick={() => onSelectTag(tag.name)}
        />
      ))}
    </ButtonGroup>
  )
}
export const TagFilterSelect = (props: TagSelectGroupProps) => {
  return (
    <div className={tagSelectClass}>
      <TagFilterSelectComponent {...props} />
    </div>
  )
}
