import * as React from "react"
import { FC, useMemo, useState } from "react"
import { translations } from "../i18n"
import { AssessmentState, DetailSelectionTab } from "../models/assessment"
import { useSelector } from "react-redux"
import { State } from "../reducers/state"
import { ScrollBox } from "../../shared/components/ui/scroll-box"
import { Radio, RadioGroup, Tab, Tabs } from "@blueprintjs/core"
import { Profile } from "../../profile/models/profile"
import { getEmptyScores, Scores } from "../../shared/models/scores"
import { css, cx } from "emotion"
import { NoContentDisclaimer } from "./assessment-no-content-disclaimer"
import { DataSetType, getScoreSetByDataSetType } from "../../shared/models/smartdata"
import { Rating } from "../../shared/models/ratings"
import { trackUsageEvent } from "../../utils/usage-tracking"
import {
  Category,
  CategoryName,
  LANA_AGS_NODES_BY_NAME,
  LANA_CATEGORIES,
  LANA_CELL_NODES_BY_NAME,
} from "../../shared/smartdata-products/smartdata"
import Card from "../../shared/components/card"
import Panel from "../../shared/components/panel"
import GridItem from "../../shared/components/restyle-grid/griditem"
import Grid from "../../shared/components/restyle-grid/grid"
import LoadingSpinner from "../../shared/components/loadingspinner"
import { getThemeColorVar } from "../../shared/helper/color"
import Icon from "../../shared/components/icon"

const radioClass = css({
  marginBottom: "0px", //override bp default
  paddingBottom: "16px",
})

const radioLabelClass = css({
  //circumvent misalignment of block elements inside BlueprintJS <Radio/>
  marginTop: "-21px",
})

const cardHeaderClass = css({
  fontWeight: "normal",
  '.bp4-tab[aria-selected="true"], .bp4-tab:not([aria-disabled="true"]):hover': {
    color: getThemeColorVar("primary", "default"),
  },

  ".bp4-tab-indicator-wrapper": {
    ".bp4-tab-indicator": {
      backgroundColor: getThemeColorVar("primary", "default"),
    },
  },
})

const styles = {
  scrollerWrap: css({
    margin: "-15px",
    height: "calc(100% + 30px)",
    minWidth: "100%",
    minHeight: "100%",

    ".bp4-control:hover input:checked ~ .bp4-control-indicator": {
      backgroundColor: getThemeColorVar("primary", "default"),
    },

    label: {
      padding: "12px !important",
      borderBottom: `1px solid ${getThemeColorVar("border", "default")}`,

      "> span": {
        marginLeft: "0 !important",
      },
      "> div": {
        marginLeft: "22px !important",
        marginTop: "-20px !important",
      },
    },
    "label:last-child": {
      borderBottom: 0,
    },
  }),
  profileListItem: css({
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    "label:first-child": {
      borderTop: `1px solid ${getThemeColorVar("border", "default")}`,
    },
    "> .revo-grid__item:first-child": {
      fontWeight: "bold",
    },
  }),
  ratingListItem: css({
    borderBottom: `1px solid ${getThemeColorVar("border", "default")}`,
    alignItems: "center",
    padding: "10px !important",
    height: "45px",
    display: "flex",
    label: {
      borderBottom: 0,
      padding: "0px !important",
    },
  }),
  profileGroup: css({
    "div:first-child": {
      "> div:first-child": {
        borderTop: `1px solid ${getThemeColorVar("border", "default")}`,
      },
    },
    label: {
      borderTop: "0 !important",
      paddingLeft: "20px !important",
    },
    "label:last-child": {
      borderBottom: `1px solid ${getThemeColorVar("border", "default")}`,
    },
    ".revo-grid__item": {
      borderTop: "0 !important",
    },
  }),
  groupHeader: (isActive: boolean, isGroupEmpty: boolean) =>
    css({
      borderBottom: `1px solid ${getThemeColorVar("border", "default")}`,
      padding: "10px",
      display: "flex",
      justifyContent: "space-between",
      height: "45px",
      alignItems: "center",
      backgroundColor: isActive ? getThemeColorVar("background", "lighter") : getThemeColorVar("white", undefined),
      cursor: isGroupEmpty ? "pointer" : "default",

      ":hover": {
        backgroundColor: isGroupEmpty
          ? getThemeColorVar("background", "lighter")
          : getThemeColorVar("white", undefined),
      },
    }),

  groupName: css({
    fontSize: "14px",
    fontWeight: "bold",
  }),
  chevronWrap: css({
    cursor: "pointer",
  }),
  ratingsDeprecatedContainer: css({
    padding: "32px 16px 0 16px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  }),
}

interface Props {
  viewMode: DataSetType
  selectedTab: DetailSelectionTab
  setSelectedTab: (tab: DetailSelectionTab) => void
  selectedProfile?: Profile
  setSelectedProfile: (profile?: Profile) => void
  selectedScoreCategory?: Category
  setSelectedScoreCategory: (category?: Category) => void
  selectedRating?: Rating
  setSelectedRating: (rating?: Rating) => void
  onSelectScores: (scores: Scores) => void
}

export const AssessmentDetailSelection: FC<Props> = ({
  viewMode,
  selectedTab,
  setSelectedTab,
  selectedProfile,
  setSelectedProfile,
  selectedScoreCategory,
  setSelectedScoreCategory,
  selectedRating,
  setSelectedRating,
  onSelectScores,
}: Props) => {
  const t = useMemo(translations, [translations])

  const currentAssessmentEntry = useSelector((state: State) => state.assessment.currentAssessmentEntry)
  const assessmentEntryProfileScoresInProgress = useSelector(
    (state: State) => state.assessment.assessmentEntryProfileScoresInProgress
  )
  const assessmentEntryProfileScoresWithSources = useSelector(
    (state: State) => state.assessment.assessmentEntryProfileScoresWithSources
  )
  const assessmentStatus = useSelector((state: State) => state.assessment.currentAssessment?.state)

  const [collapsedGroup, setCollapsedGroup] = useState<string[]>([])

  function onTabChange(newTabId: DetailSelectionTab) {
    setSelectedTab(newTabId)
    currentAssessmentEntry && trackUsageEvent("DETAIL_VIEW_CHANGE_SECTION", currentAssessmentEntry.address, newTabId)
    setSelectedProfile(undefined)
    setSelectedScoreCategory(undefined)
    onSelectScores(getEmptyScores())
  }

  function onProfileChange(profileId: string): void {
    const profile = currentAssessmentEntry?.profiles.find((profile) => profile.id === profileId)
    setSelectedProfile(profile)
    onSelectScores(profile?.scores ?? getEmptyScores())
    currentAssessmentEntry &&
      profile &&
      trackUsageEvent("DETAIL_VIEW_SELECT_PROFILE", currentAssessmentEntry.address, `${viewMode},${profile.name}`)
  }

  function renderProfileList() {
    const setCollapsedGroups = (groupId: string) =>
      collapsedGroup.includes(groupId)
        ? setCollapsedGroup(collapsedGroup.filter((id) => id !== groupId))
        : setCollapsedGroup([...collapsedGroup, groupId])

    const expandedPanel = (isActive: boolean) =>
      css({
        maxHeight: isActive ? "auto" : 0,
        overflow: "hidden",
        transition: "max-height .5s ease",
      })

    if (currentAssessmentEntry && currentAssessmentEntry.profiles.length !== 0) {
      return (
        <>
          {viewMode === "micro" && currentAssessmentEntry?.focusCell === false && (
            <div style={{ padding: "12px" }}>
              <Panel color={"neutral"}>{t.assessmentEntryDetails.nonFocusCellProfileMessage}</Panel>
            </div>
          )}

          <div className={styles.profileListItem}>
            <GridItem justifySelf={"end"} padding={[12, 12]}>
              ({t.scoreKey.micro}&nbsp;|&nbsp;{t.scoreKey.macro})
            </GridItem>

            <GridItem>
              {currentAssessmentEntry.profileGroups?.length !== 0 && (
                <>
                  <RadioGroup
                    onChange={(event) => onProfileChange((event.target as HTMLTextAreaElement).value)}
                    selectedValue={selectedProfile?.id}
                  >
                    {currentAssessmentEntry.profiles
                      .filter((profile) => profile.groupId === undefined)
                      .map(renderSingleProfile)}
                  </RadioGroup>
                  <div className={styles.profileGroup}>
                    {currentAssessmentEntry.profileGroups?.map((group, index) => {
                      const isCollapsed = collapsedGroup.includes(group.id)
                      const isGroupEmpty = currentAssessmentEntry.profiles.some(
                        (profile) => profile.groupId === group.id
                      )

                      return (
                        <div key={index}>
                          <div
                            className={styles.groupHeader(isCollapsed, isGroupEmpty)}
                            onClick={() => (isGroupEmpty ? setCollapsedGroups(group.id) : {})}
                          >
                            <span className={styles.groupName}>{group.name}</span>
                            <span className={styles.chevronWrap}>
                              <Icon name={isCollapsed ? "dropdown_up" : "dropdown_down"} />
                            </span>
                          </div>
                          <div className={expandedPanel(isCollapsed)}>
                            <RadioGroup
                              onChange={(event) => onProfileChange((event.target as HTMLTextAreaElement).value)}
                              selectedValue={selectedProfile?.id}
                            >
                              {currentAssessmentEntry.profiles
                                .filter((profile) => profile.groupId === group.id)
                                .map(renderSingleProfile)}
                            </RadioGroup>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </>
              )}
              {(!currentAssessmentEntry.profileGroups || currentAssessmentEntry.profileGroups.length === 0) && (
                <RadioGroup
                  onChange={(event) => onProfileChange((event.target as HTMLTextAreaElement).value)}
                  selectedValue={selectedProfile?.id}
                >
                  {currentAssessmentEntry.profiles.map(renderSingleProfile)}
                </RadioGroup>
              )}
            </GridItem>
          </div>
        </>
      )
    } else {
      return <NoContentDisclaimer description={t.assessmentEntryDetails.noProfilesAvailable} />
    }
  }

  function renderSingleProfile(profile: Profile) {
    let scores: { macro: number; micro: number } = assessmentEntryProfileScoresWithSources?.profileScores[profile.id] ||
      currentAssessmentEntry?.scores[profile.id] || { macro: -1, micro: -1 }
    const radioLabel = css({ label: `profile-id-${profile.id}` }) //for tracking

    if (assessmentEntryProfileScoresInProgress) {
      return (
        <Radio key={profile.id} className={cx(radioClass, radioLabel)} value={profile.id}>
          <div className={radioLabelClass}>
            <Grid columns={2}>
              <GridItem>{profile.name}</GridItem>
              <GridItem justifySelf={"end"}>
                <LoadingSpinner size={12} />
              </GridItem>
            </Grid>
          </div>
        </Radio>
      )
    }

    const macro = Object.keys(profile.scores.macro).length > 0 && scores.macro >= 0 ? scores.macro.toFixed(0) : "-"
    const micro = Object.keys(profile.scores.micro).length > 0 && scores.micro >= 0 ? scores.micro.toFixed(0) : "-"

    return (
      <Radio key={profile.id} className={cx(radioClass, radioLabel)} value={profile.id}>
        <div className={radioLabelClass}>
          <Grid columns={2}>
            <GridItem>{profile.name}</GridItem>
            <GridItem justifySelf={"end"}>{`(${micro} | ${macro})`}</GridItem>
          </Grid>
        </div>
      </Radio>
    )
  }

  function renderScoreGroupList() {
    const scores = getScoreSetByDataSetType(viewMode)
    const [categoriesNamesMap, currentAssessmentEntryScoreValues] =
      viewMode === "macro"
        ? [LANA_AGS_NODES_BY_NAME, currentAssessmentEntry?.macroData]
        : [LANA_CELL_NODES_BY_NAME, currentAssessmentEntry?.microData]

    let currentValuesCategoryNames = new Set<CategoryName>()
    if (currentAssessmentEntryScoreValues)
      Object.keys(currentAssessmentEntryScoreValues).forEach((scoreName) => {
        const categoryName = categoriesNamesMap.get(scoreName)?.category
        if (categoryName) currentValuesCategoryNames.add(categoryName)
      })

    const categories = Object.values<Category>(
      scores
        .map((v) => v.category)
        .reduce((acc, v) => {
          if (currentValuesCategoryNames.has(v)) acc[v] = LANA_CATEGORIES[v]
          return acc
        }, {} as Record<string, Category>)
    ).sort((x, y) => t.pickTranslation(x.title).localeCompare(t.pickTranslation(y.title)))

    if (categories.length > 0) {
      return (
        <>
          {viewMode === "micro" && currentAssessmentEntry?.focusCell === false && (
            <div style={{ padding: "12px" }}>
              <Panel color={"neutral"}>{t.assessmentEntryDetails.nonFocusCellMessage}</Panel>
            </div>
          )}
          <RadioGroup
            onChange={(event) => {
              const categoryName = (event.target as HTMLTextAreaElement).value
              setSelectedScoreCategory(categories.find((v) => v.name === categoryName))
              currentAssessmentEntry &&
                trackUsageEvent(
                  "DETAIL_VIEW_SELECT_SCORE_GROUP",
                  currentAssessmentEntry.address,
                  `${viewMode},${categoryName}`
                )
            }}
            selectedValue={selectedScoreCategory?.name}
          >
            {categories.map((cat) => {
              const radioLabel = css({ label: `profile-id-${cat.name}` }) //for tracking
              return (
                <Radio key={cat.name} className={cx(radioLabel, radioClass)} value={cat.name}>
                  <div className={radioLabelClass}>{t.pickTranslation(cat.title)}</div>
                </Radio>
              )
            })}
          </RadioGroup>
        </>
      )
    } else {
      let description
      if (viewMode === "micro" && currentAssessmentEntry?.microDataIsIncomplete) {
        description = ""
      } else if (assessmentStatus) {
        description = t.assessmentEntryDetails.stateStatus[assessmentStatus as AssessmentState]
      } else {
        description = t.assessmentEntryDetails.noDetailsAvailable
      }

      return <NoContentDisclaimer description={description} />
    }
  }

  if (!currentAssessmentEntry) return null

  return (
    <Card
      header={
        <div className={cardHeaderClass}>
          <Tabs selectedTabId={selectedTab} onChange={onTabChange}>
            <Tab id="scores">{t.assessmentEntryDetails.scores}</Tab>
            <Tab id="profiles">{t.assessmentEntryDetails.profiles}</Tab>
          </Tabs>
        </div>
      }
    >
      <div className={styles.scrollerWrap}>
        <ScrollBox>
          {selectedTab === "scores" && renderScoreGroupList()}
          {selectedTab === "profiles" && renderProfileList()}
        </ScrollBox>
      </div>
    </Card>
  )
}
