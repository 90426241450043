import Axios, { CancelTokenSource } from "axios"
import { Extent } from "ol/extent"
import { lanaApiUrl } from "../../app_config"
import { GeoJSON } from "ol/format"

export const BERLIN_AGS_REF_RES_LOC = "11000000"

let viewportRequestToken: CancelTokenSource | undefined = undefined

export async function fetchAgsResLocViewport(agsRefResLoc?: string): Promise<Extent | undefined> {
  if (typeof agsRefResLoc !== "string") return Promise.resolve(undefined)

  viewportRequestToken?.cancel()
  viewportRequestToken = Axios.CancelToken.source()

  try {
    const response = await Axios.get(`${lanaApiUrl}/api/map/microRegionShape/${encodeURIComponent(agsRefResLoc)}`, {
      cancelToken: viewportRequestToken.token,
    })
    return new GeoJSON().readGeometry(response.data).getExtent()
  } catch (err) {
    return Promise.reject("failed to get viewport")
  }
}

export async function fetchMunicipalityViewport(selectedMunicipalityId: string): Promise<Extent | undefined> {
  if (typeof selectedMunicipalityId !== "string") return Promise.resolve(undefined)

  viewportRequestToken?.cancel()
  viewportRequestToken = Axios.CancelToken.source()

  try {
    const response = await Axios.get(`${lanaApiUrl}/api/map/macroShape/${encodeURIComponent(selectedMunicipalityId)}`, {
      cancelToken: viewportRequestToken.token,
    })
    return new GeoJSON().readGeometry(response.data).getExtent()
  } catch (err) {
    return Promise.reject(`Couldn't get viewport for municipality ${selectedMunicipalityId}`)
  }
}
