import * as React from "react"
import { NewPasswordInput } from "./new-password-input"
import { translations } from "../i18n"
import { Grid } from "../../shared/components/ui/grid"
import Card from "../../shared/components/card"

export const LoginPasswordForgotten = (props: { resetToken: string }) => {
  const translate = React.useMemo(() => translations(), [])
  return (
    <div style={{ marginTop: "200px" }}>
      <Grid columnSpec="1fr 50% 1fr">
        <div />
        <Card header={translate.setPassword.setNewPasswordHeader}>
          <NewPasswordInput
            passwordLabel={translate.setPassword.newPasswordLabel}
            confirmPasswordLabel={translate.setPassword.confirmPasswordLabel}
            submitPasswordLabel={translate.setPassword.submitNewPassword}
            submitSuccessMessage={translate.setPassword.submitNewSuccess}
            resetToken={props.resetToken}
          />
        </Card>
      </Grid>
    </div>
  )
}
