import { AssessmentEntry, AssessmentPriceType, HouseOrApartment } from "../../../models/assessment"
import { NumericRange } from "../../../../shared/models/numeric-range"
import { historicalOffersInputFormDefaults } from "./historical-offers-filters-model"

export type OnlineImmoScoutInputForm = {
  dataSource: "online-immoScout"
  priceTypeComps: AssessmentPriceType
  radius: number
  areaRangeNeeded: boolean
  areaRange: NumericRange
  houseOrApartment: HouseOrApartment
  roomsFilterEnabled: boolean
  roomsNumberRange: NumericRange
}

export const onlineImmoScoutInputFormDefaults = (assessmentEntry?: AssessmentEntry) => {
  const historicalInputForm = historicalOffersInputFormDefaults(assessmentEntry)
  return {
    ...historicalInputForm,
    radius: 1000,
    dataSource: "online-immoScout",
  } as OnlineImmoScoutInputForm
}
