import { Property } from "./property"
import { GeoJsonObject } from "geojson"
import { Coordinate } from "ol/coordinate"
import { AssessmentEntryFull } from "../../assessment/models/assessment"
import { DataSetType } from "./smartdata"

export const DEFAULT_VIEWPORT: Viewport = {
  northeast: {
    lng: 19,
    lat: 56,
  },
  southwest: {
    lng: 0,
    lat: 46,
  },
}

export const GEO_PARTITIONS = {
  macro: "gem2021_num",
  micro: "cells2023",
} as const

export function isAssessmentPartitionOutdated(viewMode: DataSetType, entry?: AssessmentEntryFull) {
  if (entry === undefined) {
    return false
  }

  return viewMode in GEO_PARTITIONS && entry.geoPartitions
    ? entry.geoPartitions.indexOf(GEO_PARTITIONS[viewMode]) === -1
    : false
}

export interface GeoPartition {
  geo_partition_id: string
  display_name: string
  id_property: string
  name_property: string
  properties: Property[]
  filter1_property?: string
  filter2_property?: string
  filter3_property?: string
}

export interface GeoPartitionShapeHeader {
  count: number
}

export interface GeoPartitionShapeSuccess {
  index: number
  id: string
  geo_partition_id: string
}

export interface GeoPartitionShapeError {
  index: number
  geo_partition_id: string
  message: string
}

export type GeoPartitionShapeResult =
  | { header: GeoPartitionShapeHeader }
  | { success: GeoPartitionShapeSuccess }
  | { error: GeoPartitionShapeError }

export interface GeoPartitionShapeEntry {
  id: string
  geo_partition_id: string
  ref_id: string
  name: string
  shape: GeoJsonObject
  properties: { [key: string]: string | number }
  filter1?: string
  filter2?: string
  filter3?: string
}

export interface Location {
  lat: number
  lng: number
}
export interface Viewport {
  northeast: Location
  southwest: Location
}

export interface ShapeQuery {
  north: number
  east: number
  south: number
  west: number
  id?: string
  name?: string
}

export interface GeoPartitionShapeRef {
  geo_partition_id: string
  ref_id: string
  name: string
}

export interface ViewportResponseEntry {
  geometry: GeoJsonObject
  value: number
  smartdata: { [key: string]: number }
}

export type ExplorerResult = {
  result: QueryViewportResponse
  borderGeometry?: GeoJsonObject
}

export type QueryViewportResponse = ViewportResponseEntry[]

function toRadians(degrees: number): number {
  return degrees * (Math.PI / 180)
}

function toDegrees(radians: number): number {
  return radians * (180 / Math.PI)
}

export function centroid(viewport: Viewport): Coordinate {
  const lat1 = toRadians(viewport.northeast.lat)
  const lon1 = toRadians(viewport.northeast.lng)
  const lat2 = toRadians(viewport.southwest.lat)
  const lon2 = toRadians(viewport.southwest.lng)

  const bx = Math.cos(lat2) * Math.cos(lon2 - lon1)
  const by = Math.cos(lat2) * Math.sin(lon2 - lon1)

  const latMid = Math.atan2(
    Math.sin(lat1) + Math.sin(lat2),
    Math.sqrt((Math.cos(lat1) + bx) * (Math.cos(lat1) + bx) + by * by)
  )

  const lonMid = lon1 + Math.atan2(by, Math.cos(lat1) + bx)

  return [toDegrees(lonMid), toDegrees(latMid)]
}
