import { Address, Location } from "./address"
import { Group, Profile } from "../../profile/models/profile"
import { MetaRating, MetaRatingRange, Rating } from "../../shared/models/ratings"
import { HasTranslations } from "../../shared/smartdata-products/smartdata"
import { RatingGrade } from "../../shared/models/rating-grade"
import { RatingSelectionRule } from "../../shared/models/selection"
import { DataSetType } from "../../shared/models/smartdata"
import { assertUnreachable } from "../../utils/utils"
import { GenericError } from "../../shared/helper/axios"

export type AssessmentState =
  | "created"
  | "geocoded"
  | "geocoded-ambiguous"
  | "smartdata"
  | "smartdata-ambiguous"
  | "rated"
  | "rated-ambiguous"
  | "scored"
  | "scored-ambiguous"
  | "failure"
  | "object-prices"
  | "object-prices-ambiguous"

export function isInProgressState(state: AssessmentState): boolean {
  return (
    state == "created" ||
    state == "geocoded" ||
    state == "geocoded-ambiguous" ||
    state == "rated" ||
    state == "rated-ambiguous" ||
    state == "smartdata" ||
    state == "smartdata-ambiguous"
  )
}

export interface Assessment {
  id: string
  title: string
  state: AssessmentState
  tags: string[]
  companyId: string
  createdAt: string
  outdated: boolean
  numberOfAddresses: number
  containsObjectData: boolean
  macroContext?: string
}

export interface AssessmentCreateSingle {
  title: string
  entryLabel: string
  address: Address
  droppedLocation: Location | null
  usageType?: UsageType
  year?: Number
  area?: Number
  exclusiveness?: Exclusiveness
  newBuilding?: boolean
}

export type AssessmentCreateErrorType =
  | "MissingColumns"
  | "NoRows"
  | "InvalidFileType"
  | "NoSmartdata"
  | "TooManyEntries"

export type FileProperties = { mimeType: string; fileExtension: string }

export type AssessmentCreateError = GenericError & {
  error?: AssessmentCreateErrorType
  missing?: AddressListColumn[]
  fileProperties?: FileProperties
}

export interface AssessmentEntry {
  id: string
  state: AssessmentState
  label: string
  tags: string[]
  source: {
    name?: string
    rowNumber?: number
  }
  address: Address
  originalAddress: Address | undefined
  macroPrice?: PriceRange
  microPrice?: PriceRange
  objectPrice?: PriceRange
  year: number | undefined
  area: number | undefined
  exclusiveness: Exclusiveness | undefined
  usageType: UsageType | undefined
  externalID?: string
  droppedLocation: Location | null
  newBuilding?: boolean
  focusCell: boolean
}

export enum Exclusiveness {
  Standard = "standard",
  High = "high",
  Highest = "highest",
}

export function exclusivenessToNumber(exclusiveness?: Exclusiveness): number {
  switch (exclusiveness) {
    case Exclusiveness.Standard:
      return 1
    case Exclusiveness.High:
      return 2
    case Exclusiveness.Highest:
      return 3
    default:
      // Standard by default
      return 1
  }
}

export enum UsageType {
  ResidentialApartmentRent = "residential-apartment-rent",
  ResidentialApartmentSale = "residential-apartment-sale",
  ResidentialHouseRent = "residential-house-rent",
  ResidentialHouseSale = "residential-house-sale",
  Office = "office",
  Retail = "retail",
  Hall = "hall",
  PlotSale = "plot-sale",
  Logistics = "logistics",
}

export type AssessmentEntryListViewMode = "list" | "map"

export const AssessmentEntryListViewModes: AssessmentEntryListViewMode[] = ["list", "map"]

export type AssessmentEntryScores = {
  [profileId: string]: {
    macro: number
    micro: number
  }
}

export interface AssessmentEntryWithScore extends AssessmentEntry {
  scores: AssessmentEntryScores
  ratingResults: { [ratingId: string]: AssessmentRatingResults }
  metaRatingResults: { [metaRatingId: string]: MetaRatingCalculatedResult }
}

export interface AssessmentRatingResults {
  gradeId: number
  facts: SelectionFact[]
  grade: RatingGrade
}

export interface MetaRatingResultDetail {
  ratingName: string
  ratingId: string
  dataSetType?: DataSetType
  grade: RatingGrade
  weight: number
}

export interface MetaRatingCalculatedResult {
  name: string
  result: number
  range: MetaRatingRange | undefined
  details: MetaRatingResultDetail[]
}

export interface SelectionFact {
  rule: RatingSelectionRule
  additionalName: string | undefined
  value: string | number | boolean | undefined
}

export interface AssessmentEntryWithScoreResult {
  profiles: Profile[]
  profileGroups?: Group[]
  ratings: Rating[]
  entries: AssessmentEntryWithScore[]
  metaRatings: MetaRating[]
}

export type AssessmentEntryScoresWithSources = {
  macroContext?: string
  insideContext: boolean
  profileScores: {
    [profileId: string]: {
      macro: number
      micro: number
      macroData: { [key: string]: number }
      microData: { [key: string]: number }
    }
  }
}

export interface ContextScoreValues {
  insideContext: boolean
  values: { [key: string]: number }
}

export interface PriceRange {
  min: number
  max: number
  avg: number
}

export interface AssessmentEntryFull extends AssessmentEntry {
  microData: { [source: string]: number }
  macroData: { [source: string]: number }
  microDataIsIncomplete?: boolean
  agsRefResloc?: string
  profiles: Profile[]
  ratings: Rating[]
  metaRatings: MetaRating[]
  scores: AssessmentEntryScores
  profileGroups?: Group[]
  ratingResults: { [ratingId: string]: AssessmentRatingResults }
  availableTags: { name: string; usageCount: number }[]
  agsId?: number
  cellId?: number
  districtId?: number
  postcodeId?: number
  agsPrices: { [key in AssessmentPriceType]?: PriceRange }
  cellPrices: { [key in AssessmentPriceType]?: PriceRange }
  agsYields: { [key in AgsYieldType]?: PriceRange }
  cellYields: { [key in YieldType]?: PriceRange }
  metaRatingResults: { [metaRatingId: string]: MetaRatingCalculatedResult }
  geoPartitions: string[]
}

export interface ObjectPricesParams {
  constructionYear: number
  area: number
  class: number
  houseOrApartment: HouseOrApartment
  newBuilding: boolean
  perSqmOrTotal: PerSqmOrTotal
}

export type ObjectPrices = { [key in AssessmentPriceType]?: PriceRange }

export type AddressListColumn = "postalCode" | "locality" | "route" | "streetNumber"

export type HouseOrApartment = "apartment" | "house"

export type PerSqmOrTotal = "eurPerSqm" | "eurTotal"

export type AssessmentPriceType =
  | "office"
  | "retail"
  | "residentialRent"
  | "residentialSale"
  | "hall"
  | "plotSale"
  | "logistics"

export type YieldType = "office" | "retail" | "residential"

export type AgsYieldType = YieldType | "top_office" | "top_retail" | "top_residential"

export const PRICE_TYPES: AssessmentPriceType[] = ["residentialRent", "office", "retail", "residentialSale"]

export const COMPARABLES_PRICE_TYPES: AssessmentPriceType[] = [
  "residentialRent",
  "office",
  "retail",
  "hall",
  "residentialSale",
]

export const RentPriceTypes: { [key: string]: AssessmentPriceType } = {
  residential: "residentialRent",
  office: "office",
  retail: "retail",
  hall: "hall",
}

export const SalePriceTypes: { [key: string]: AssessmentPriceType } = {
  residential: "residentialSale",
  plotSale: "plotSale",
}

export const ResidentialPriceTypes: AssessmentPriceType[] = ["residentialRent", "residentialSale"]

export interface AssessmentTrendItem {
  avg_retail_rent_index: number
  avg_office_rent_index: number
  avg_residential_sale_index: number
  avg_residential_rent_index: number
  lower_retail_rent_index?: number
  lower_residential_sale_index?: number
  lower_residential_rent_index?: number
  lower_office_rent_index?: number
  upper_retail_rent_index?: number
  upper_residential_sale_index?: number
  upper_residential_rent_index?: number
  upper_office_rent_index?: number
  med_rent: number
  med_rent_office: number
  med_rent_retail: number
  med_sale: number
  year: number
  qy: number
  type: string
}

export interface AssessmentTrendData {
  cell_long: AssessmentTrendItem[]
  ags_long_quarterly: AssessmentTrendItem[]
}

export function priceTypeForUsageType(usageType: UsageType | undefined): AssessmentPriceType | undefined {
  switch (usageType) {
    case UsageType.Office:
      return "office"
    case UsageType.Retail:
      return "retail"
    case UsageType.ResidentialApartmentRent:
    case UsageType.ResidentialHouseRent:
      return "residentialRent"
    case UsageType.ResidentialApartmentSale:
    case UsageType.ResidentialHouseSale:
      return "residentialSale"
    default:
      return undefined
  }
}

export interface AssessmentCellYieldItem {
  year: number
  yield_min_residential: number
  yield_residential: number
  yield_max_residential: number
  yield_min_retail: number
  yield_retail: number
  yield_max_retail: number
  yield_min_office: number
  yield_office: number
  yield_max_office: number
}

export interface AssessmentAgsYieldItem {
  year: number
  yield_ags_min_residential: number
  yield_ags_residential: number
  yield_ags_max_residential: number
  top_yield_min_ags_residential: number
  top_yield_ags_residential: number
  top_yield_max_ags_residential: number
  yield_ags_min_retail: number
  yield_ags_retail: number
  yield_ags_max_retail: number
  top_yield_min_ags_retail: number
  top_yield_ags_retail: number
  top_yield_max_ags_retail: number
  yield_ags_min_office: number
  yield_ags_office: number
  yield_ags_max_office: number
  top_yield_min_ags_office: number
  top_yield_ags_office: number
  top_yield_max_ags_office: number
}

export interface AssessmentYieldData {
  cell_long_yearly: AssessmentCellYieldItem[]
  ags_long_yearly: AssessmentAgsYieldItem[]
}

export function yieldTypeForUsageType(usageType: UsageType | undefined): YieldType | undefined {
  switch (usageType) {
    case UsageType.Office:
      return "office"
    case UsageType.Retail:
      return "retail"
    case UsageType.ResidentialApartmentRent:
    case UsageType.ResidentialHouseRent:
    case UsageType.ResidentialApartmentSale:
    case UsageType.ResidentialHouseSale:
      return "residential"
    default:
      return undefined
  }
}

export function houseOrApartmentForUsageType(usageType: UsageType | undefined): HouseOrApartment | undefined {
  switch (usageType) {
    case UsageType.ResidentialApartmentRent:
    case UsageType.ResidentialApartmentSale:
      return "apartment"
    case UsageType.ResidentialHouseRent:
    case UsageType.ResidentialHouseSale:
      return "house"
    default:
      return undefined
  }
}

export function reconstructUsageType(houseOrApartment: HouseOrApartment, priceType: AssessmentPriceType): UsageType {
  switch (priceType) {
    case "office":
      return UsageType.Office
    case "retail":
      return UsageType.Retail
    case "residentialRent":
      switch (houseOrApartment) {
        case "apartment":
          return UsageType.ResidentialApartmentRent
        case "house":
          return UsageType.ResidentialHouseRent
        default:
          assertUnreachable(houseOrApartment)
      }
      break

    case "residentialSale":
      switch (houseOrApartment) {
        case "apartment":
          return UsageType.ResidentialApartmentSale
        case "house":
          return UsageType.ResidentialHouseSale
        default:
          assertUnreachable(houseOrApartment)
      }
      break
    case "hall":
      return UsageType.Hall
    case "plotSale":
      return UsageType.PlotSale
    case "logistics":
      return UsageType.Logistics
    default:
      assertUnreachable(priceType)
  }
}

export type ComparablesTab = "prices" | "yields"
export type PricesTab = "market" | "trends" | "rentIndex"
export type YieldsTab = "market" | "trends"
export type DetailSelectionTab = "scores" | "profiles"
export type TempWidgets = "add"
export type WidgetsType =
  | "ratings"
  | "specialMaps"
  | "nearestAccessibility"
  | "rentIndex"
  | "districtData"
  | "microMap"
  | "macroMap"
  | "MLPrices"
  | "microScores"
  | "macroScores"
  | "POIs"
  | "yields"
  | "comparables"
  | "population"
  | TempWidgets

type ConfigurableWidgetsType = Extract<
  WidgetsType,
  "microMap" | "microScores" | "macroScores" | "ratings" | "POIs" | "districtData"
>
export type NonConfigurableWidgetsType = Exclude<WidgetsType, ConfigurableWidgetsType>

export type POIThematicGroupsType = { [key: string]: HasTranslations }

export interface GoogleMapsPOICategory {
  id: string
  title: HasTranslations
  group: string
}

export const LOCAL_PUBLIC_TRANSPORT_CATEGORY: GoogleMapsPOICategory = {
  id: "local_public_transport",
  title: {
    en: "Local public transport",
    de: "Öffentlicher Nahverkehr",
  },
  group: "transport",
}

export interface GoogleMapsThematicGroup {
  title: HasTranslations
  groupName: string
}
