import { Chart, Plugin } from "chart.js"

export type BottomTextOptions = {
  text: string
}

declare module "chart.js" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PluginOptionsByType<TType extends ChartType> {
    bottomText: BottomTextOptions
  }
}

function bottomText(chart: Chart) {
  if (chart.ctx) {
    const opts = chart.config.options?.plugins?.bottomText
    if (opts?.text) {
      const ctx = chart.ctx
      const chartArea = chart.chartArea

      ctx.save()
      const textMeasurments = ctx.measureText(opts.text)
      ctx.fillText(opts.text, chartArea.right - textMeasurments.width, (chart.height ?? 0) - 16)
      ctx.restore()
    }
  }
}

export const bottomTextPlugin: Plugin = {
  id: "bottomTextPlugin",
  beforeDraw: (chart: Chart) => {
    bottomText(chart)
  },
}
