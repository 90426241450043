import * as React from "react"
import { useAppSelector } from "../../relas/store"
import { UnloggedFormWrapper } from "./unlogged-form-wrapper"
import { translations } from "../i18n"
import LoadingDimmer from "../../shared/components/loadingdimmer"
import { LoginIS24Form } from "./login-is24-form"

type Props = {
  is24AuthError?: string
}

export const Login = ({ is24AuthError }: Props) => {
  const translate = React.useMemo(() => translations(), [])
  const inProgress = useAppSelector((state) => state.login.loginInProgress)

  return (
    <UnloggedFormWrapper title={translate.login.header}>
      <LoadingDimmer loading={inProgress}>{<LoginIS24Form is24AuthError={is24AuthError} />}</LoadingDimmer>
    </UnloggedFormWrapper>
  )
}
