import { RatingSelectionRule, Selection, SelectionResults } from "../shared/models/selection"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { store } from "../relas/store"
import Axios, { AxiosError, AxiosResponse, CancelTokenSource } from "axios"
import { lanaApiUrl } from "../app_config"
import { BERLIN_AGS_REF_RES_LOC } from "../shared/actions/map-actions"
import { DataSetType } from "../shared/models/smartdata"
import { Group as ProfileGroup, Profile } from "../profile/models/profile"
import { NavigationPage } from "../shared/actions/navigation"
import { navigateTo } from "../shared/reducers/navigation-slice"
import { GenericError, toGenericError } from "../shared/helper/axios"

export enum SelectionPane {
  List = 0,
  Create,
  Edit,
  Duplicate,
  Rename,
  EditTitle,
}

export interface LocationSelectorState {
  showMunicipalityList: boolean

  selectionList: Selection[] | null
  selectionListLoading: boolean
  selectionListError: GenericError | null

  selectionResults: SelectionResults | null
  selectionResultsLoading: boolean
  selectionResultsError: GenericError | null

  currentSelection: Selection | null

  selectionCreationLoading: boolean
  selectionCreationError: GenericError | null

  updateSelectionLoading: boolean
  updateSelectionError: GenericError | null

  availableProfiles: Profile[] | null
  availableProfilesLoading: boolean
  profileGroups: ProfileGroup[] | null

  agsRefResLoc: string | null

  selectedMunicipalityAndFocus: MunicipalityAndFocus | null

  selectedSelectionRuleIndex: number
  selectedRuleIndexForPartialDisplay: number | undefined

  selectionViewPane: SelectionPane

  mapNeedsReset: boolean
}

export type MunicipalityAndFocus = {
  selectedMunicipalityId: string
  focusInMap: boolean
}

export const initialState: LocationSelectorState = {
  showMunicipalityList: false,
  selectionList: null,
  selectionListError: null,
  selectionListLoading: false,
  selectionResultsLoading: false,
  selectionResults: null,
  selectionResultsError: null,
  agsRefResLoc: null,
  selectedMunicipalityAndFocus: null,
  currentSelection: null,
  selectionCreationLoading: false,
  selectionCreationError: null,
  selectedSelectionRuleIndex: -1,
  selectionViewPane: SelectionPane.List,
  updateSelectionLoading: false,
  updateSelectionError: null,
  availableProfiles: [],
  availableProfilesLoading: false,
  profileGroups: [],
  mapNeedsReset: false,
  selectedRuleIndexForPartialDisplay: undefined,
}

const locationSelectorSlice = createSlice({
  name: "locationSelector",
  initialState,
  reducers: {
    selectionListStart(state) {
      state.selectionListLoading = true
      state.selectionListError = null
      state.selectionList = null
    },
    selectionListDone(state, action: PayloadAction<Selection[]>) {
      state.selectionListLoading = false
      state.selectionListError = null
      state.selectionList = action.payload
    },
    selectionListError(state, action: PayloadAction<GenericError>) {
      state.selectionListLoading = false
      state.selectionListError = action.payload
      state.selectionList = null
    },
    showMunicipalityList(state, action: PayloadAction<boolean>) {
      state.showMunicipalityList = action.payload
    },
    selectionResultsStart(state) {
      state.selectionResultsLoading = true
      state.selectionResultsError = null
      state.selectionResults = null
    },
    selectionResultsDone(state, action: PayloadAction<SelectionResults>) {
      state.selectionResultsLoading = false
      state.selectionResultsError = null
      state.selectionResults = action.payload
    },
    selectionResultsError(state, action: PayloadAction<GenericError>) {
      state.selectionResultsLoading = false
      state.selectionResultsError = action.payload
      state.selectionResults = null
    },
    setAgsRefResLocDone(state, action: PayloadAction<string>) {
      state.agsRefResLoc = action.payload
    },
    setMunicipalityIdAndFocus(state, action: PayloadAction<MunicipalityAndFocus | null>) {
      state.selectedMunicipalityAndFocus = action.payload
    },
    selectionCreateStart(state) {
      state.selectionCreationLoading = true
      state.selectionCreationError = null
    },
    selectionCreateDone(state, action: PayloadAction<Selection>) {
      state.selectionCreationLoading = false
      state.selectionCreationError = null
      state.currentSelection = action.payload
    },
    selectionCreateError(state, action: PayloadAction<GenericError>) {
      state.selectionCreationLoading = false
      state.selectionCreationError = action.payload
    },
    selectionUpdateStart(state) {
      state.updateSelectionLoading = true
      state.updateSelectionError = null
    },
    selectionUpdateDone(state, action: PayloadAction<Selection>) {
      state.updateSelectionLoading = false
      state.updateSelectionError = null
      state.currentSelection = action.payload
    },
    selectionUpdateError(state, action: PayloadAction<GenericError>) {
      state.updateSelectionLoading = false
      state.updateSelectionError = action.payload
    },
    setSelectedSelectionRuleIndex(state, action) {
      if (action.payload < 0) {
        state.selectedSelectionRuleIndex = action.payload
        state.selectedRuleIndexForPartialDisplay = undefined
      } else {
        state.selectedSelectionRuleIndex = action.payload
        state.selectedRuleIndexForPartialDisplay = action.payload + 1
      }
    },
    setLocationSelectorViewPane(state, action: PayloadAction<SelectionPane>) {
      state.selectionViewPane = action.payload
    },
    setCurrentSelection(state, action: PayloadAction<Selection>) {
      state.currentSelection = action.payload
      state.selectedRuleIndexForPartialDisplay = undefined
    },
    fetchAvailableProfilesStart(state) {
      state.availableProfilesLoading = true
      state.availableProfiles = []
    },
    fetchAvailableProfilesDone(state, action: PayloadAction<Profile[]>) {
      state.availableProfilesLoading = false
      state.availableProfiles = action.payload
    },
    fetchAvailableProfileGroupsDone(state, action: PayloadAction<ProfileGroup[]>) {
      state.profileGroups = action.payload
    },
    clearUpdateError(state) {
      state.updateSelectionError = null
    },
    clearCreateError(state) {
      state.selectionCreationError = null
    },
    selectionDeleteDone(state, action: PayloadAction<string>) {
      if (state.selectionList) {
        const selectionIdx = state.selectionList.findIndex((selection) => selection.id === action.payload)
        if (selectionIdx > 0) {
          state.agsRefResLoc = null
          state.mapNeedsReset = true
          state.selectionList = [
            ...state.selectionList.slice(0, selectionIdx).concat(state.selectionList.slice(selectionIdx + 1)),
          ]
        }
      }
    },
    mapHasBeenReset(state) {
      state.mapNeedsReset = false
      state.currentSelection = null
      state.selectedMunicipalityAndFocus = null
    },
  },
})

const {
  selectionListStart,
  selectionListDone,
  selectionListError,
  selectionResultsError,
  selectionCreateStart,
  selectionCreateError,
  selectionCreateDone,
  selectionResultsDone,
  selectionUpdateError,
  selectionResultsStart,
  selectionUpdateStart,
  selectionUpdateDone,
  selectionDeleteDone,
  setSelectedSelectionRuleIndex,
  clearUpdateError,
  setCurrentSelection,
  clearCreateError,
  fetchAvailableProfilesStart,
  fetchAvailableProfilesDone,
  fetchAvailableProfileGroupsDone,
  setMunicipalityIdAndFocus,
  showMunicipalityList,
  setLocationSelectorViewPane,
  setAgsRefResLocDone,
  mapHasBeenReset,
} = locationSelectorSlice.actions

export function selectList(): Promise<void> {
  navigateTo({ name: NavigationPage.locationSelector })
  store.dispatch(selectionListStart())
  store.dispatch(fetchAvailableProfilesStart())

  const fetchProfiles = Axios.get(`${lanaApiUrl}/api/profiles`)
    .then((success) => store.dispatch(fetchAvailableProfilesDone(success.data)))
    .catch((error) => store.dispatch(selectionListError(toGenericError(error))))

  const fetchProfileGroups = Axios.get(`${lanaApiUrl}/api/profilegroups`)
    .then((success) => store.dispatch(fetchAvailableProfileGroupsDone(success.data)))
    .catch((error) => store.dispatch(selectionListError(toGenericError(error))))

  const selections = Axios.get(`${lanaApiUrl}/api/selections`)
    .then((response) => store.dispatch(selectionListDone(response.data)))
    .catch((error) => store.dispatch(selectionListError(toGenericError(error))))

  return Promise.all([fetchProfiles, fetchProfileGroups, selections]).then((_) => {})
}

export function setAgsRefResLoc(agsRefResLoc: string): void {
  store.dispatch(setAgsRefResLocDone(agsRefResLoc))
}

let mapRequestCancelToken: CancelTokenSource | undefined = undefined

export function fetchSelectionResults(
  selection: Selection,
  ruleIndexForPartialDisplay: number | undefined,
  agsRefResLoc: string | undefined
): Promise<void> {
  store.dispatch(selectionResultsStart())

  mapRequestCancelToken?.cancel()
  mapRequestCancelToken = Axios.CancelToken.source()

  return Axios.get(`${lanaApiUrl}/api/selections/${encodeURIComponent(selection.id)}/results`, {
    params: {
      ruleCount: ruleIndexForPartialDisplay,
      agsRefResLoc,
    },
    cancelToken: mapRequestCancelToken.token,
  }).then(
    (response: AxiosResponse) => {
      const selectionResults = response.data as SelectionResults
      if (selectionResults.dataSetType === "micro") {
        setAgsRefResLoc(agsRefResLoc || selectionResults.agsRefResLoc || BERLIN_AGS_REF_RES_LOC)
        setShowMunicipalityList(false)
      }

      store.dispatch(selectionResultsDone(selectionResults))
    },
    (error: AxiosError) => {
      store.dispatch(selectionResultsError(toGenericError(error)))
    }
  )
}

export function setShowMunicipalityList(showList: boolean): void {
  store.dispatch(showMunicipalityList(showList))
}

export function setSelectedMunicipalityAndFocus(selectedMunicipalityAndFocus: MunicipalityAndFocus | null): void {
  store.dispatch(setMunicipalityIdAndFocus(selectedMunicipalityAndFocus))
}

export function setViewPane(pane: SelectionPane): void {
  store.dispatch(setLocationSelectorViewPane(pane))
}

export function returnToSelectionList(): void {
  void selectList()
  clearCreateError()
  clearUpdateSelectionError()
  setViewPane(SelectionPane.List)
}

export function createSelection(name: string, dataSetType: DataSetType, rules?: RatingSelectionRule[]): Promise<void> {
  store.dispatch(selectionCreateStart())

  return Axios.post(`${lanaApiUrl}/api/selections`, {
    name,
    dataSetType,
    rules,
  }).then(
    (success) => {
      store.dispatch(selectionCreateDone(success.data))
      setViewPane(SelectionPane.Edit)
    },
    (error) => {
      store.dispatch(selectionCreateError(toGenericError(error)))
    }
  )
}

export function clearCreationError(): void {
  store.dispatch(clearCreateError())
}

export function selectRuleForEditing(index: number): void {
  store.dispatch(setSelectedSelectionRuleIndex(index))
}

export function updateSelection(selection: Selection): Promise<void> {
  store.dispatch(selectionUpdateStart())

  return Axios.put(`${lanaApiUrl}/api/selections/${encodeURIComponent(selection.id)}`, selection).then(
    (success: AxiosResponse) => {
      store.dispatch(selectionUpdateDone(success.data))
      return Promise.resolve()
    },
    (error: AxiosError) => {
      store.dispatch(selectionUpdateError(toGenericError(error)))
      return Promise.reject()
    }
  )
}

export function select(selection: Selection): void {
  store.dispatch(setCurrentSelection(selection))
}

export function clearUpdateSelectionError(): void {
  store.dispatch(clearUpdateError())
}

export function deleteSelection(id: string): Promise<void> {
  return Axios.delete(`${lanaApiUrl}/api/selections/${encodeURIComponent(id)}`).then(
    (success: AxiosResponse) => {
      store.dispatch(selectionDeleteDone(id))
      return Promise.resolve()
    },
    (error: AxiosError) => {
      return Promise.reject(toGenericError(error))
    }
  )
}

export function resetMap(): void {
  store.dispatch(mapHasBeenReset())
}

export function updateSelectionLocked(selectionId: string, locked: boolean): Promise<void> {
  return Axios.put(`${lanaApiUrl}/api/selections/${encodeURIComponent(selectionId)}/locked`, JSON.stringify(locked), {
    headers: { "Content-Type": "application/json" },
  }).then(
    (_) => {
      void selectList().then((_) => Promise.resolve())
    },
    (error: AxiosError) => {
      store.dispatch(selectionUpdateError(toGenericError(error)))
      return Promise.reject()
    }
  )
}

export function setSelectionAndResetMunicipality(selection: Selection): void {
  store.dispatch(setCurrentSelection(selection))
  store.dispatch(setMunicipalityIdAndFocus(null))
}

export function toSelectionModificationPane(pane: SelectionPane, s: Selection): void {
  store.dispatch(setCurrentSelection(s))
  setViewPane(pane)
}

export default locationSelectorSlice.reducer
