import * as React from "react"
import { bind } from "decko"
import { css, cx } from "emotion"
import * as ol from "ol"
import * as layer from "ol/layer"
import * as source from "ol/source"
import * as style from "ol/style"
import { Stroke } from "ol/style"
import * as extend from "ol/extent"
import { Extent } from "ol/extent"
import { SimpleOverlay } from "../../../shared/components/simple-overlay"
import { Geometry, Point } from "ol/geom"
import RenderFeature from "ol/render/Feature"
import { Location } from "../../models/address"
import {
  getInitialMapStyle,
  MapStyle,
  MapStyleControl,
  persistMapStyle,
  setMapUrlBasedOnMapStyle,
} from "../../../shared/components/map-style-control"
import { FullScreen, Zoom } from "ol/control"
import { DataSetType } from "../../../shared/models/smartdata"
import { fromLonLat, transformExtent } from "ol/proj"
import { lanaApiUrl } from "../../../app_config"
import { SelectionAreaType } from "../../models/market-data"
import {
  AreaComparisonOptionsType,
  districtSource,
  MainTabType,
  municipalitySource,
  SelectedLocationsExtentsType,
  ViewportSettingsType,
} from "../district-data"
import { translations } from "../../i18n"
import Axios, { AxiosResponse } from "axios"
import { GeoJSON } from "ol/format"
import { centerBerlin } from "../../reducers/reducers"
import { reportAssetsUrl } from "../../../reports/report-config"
import { IsochroneControl } from "../../../shared/components/map-isochrone-control"
import { IsochroneType } from "../isochrone-type"
import { getIsochronePolygon, toOlFeatureFromGooglePolygon } from "../../../utils/here-maps-isochrone"
import { GlobalState } from "../../../relas/store"
import { connect, ConnectedProps } from "react-redux"
import { PrivatePoiControl } from "../../../shared/components/map-private-pois-control"
import { AllowedModulesEnum, PrivatePOIList } from "../../../private-data/models/private-data"
import { areMultiplePrivatePoisListsDifferent, arePoisToShowDifferent, updateClusters } from "../../../utils/utils"
import Layer from "ol/layer/Layer"
import Source from "ol/source/Source"
import { JSXContent, PrivatePoiOverlay } from "../../../shared/components/PrivatePoiOverlay"
import { PoiMarkerDialog } from "../../../shared/components/privatedata-poi-marker-dialog"
import {
  updateSelectedCategories,
  fetchCategories,
  fetchCategoryPois,
  updatePOIsToShow,
} from "../../../private-data/reducers/private-data-slice"
import {
  CountryAreaSelection,
  defaultViewportSettings,
  DistrictsAreaSelection,
  MunicipalitiesAreaSelection,
} from "../../reducers/market-data-slice"
import { OpenLayersAbstractProps, OpenLayersMap } from "../../../shared/components/openlayers-map"
import Grid from "../../../shared/components/restyle-grid/grid"
import { getThemeColor, getThemeColorVar } from "../../../shared/helper/color"

interface MarketDataMapViewProps extends OpenLayersAbstractProps {
  initialMunicipalityId?: number
  dataSet: DataSetType
  mainTab: MainTabType
  currentSelectionArea: SelectionAreaType
  selectedLocationsFromStore:
    | {
        focusArea: DistrictsAreaSelection
        referenceArea: MunicipalitiesAreaSelection | DistrictsAreaSelection
      }
    | {
        focusArea: MunicipalitiesAreaSelection
        referenceArea: MunicipalitiesAreaSelection | CountryAreaSelection
      }
  selectionAreaSettings: AreaComparisonOptionsType
  onSelectLocation?: (regId: string) => void
  onRemoveSelectedLocation?: (regId: string) => void
  markerLocation?: Location
  initialMapStyle?: MapStyle
  removeSelectionActive: boolean
  mapRedrawToken?: number
  selectedLocationsExtents: SelectedLocationsExtentsType
  onSelectedLocationsExtentsChange: (regId?: string, regExtent?: Extent) => void
  userViewportSettings: ViewportSettingsType
  setUserViewportSettings: (settings: ViewportSettingsType) => void
  showDistrictLayer: boolean
  isochroneSettings: IsochroneType
  setIsochroneSettings: (changedSettings: IsochroneType) => void
}

const mapState = (state: GlobalState) => ({
  isPrivateDataAccessible: state.user.scopes.privateData,
  privatePoiCategories: state.privateData.privatePOICategories,
  privateDataSettings: state.privateData.modulesWithPrivateData.districtData,
})

const connector = connect(mapState, {})

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & MarketDataMapViewProps

const MARKER_LAYER = "MARKER_LAYER"
const ISOCHRONE_LAYER = "ISOCHRONE_LAYER"

const mapClass = css({
  minHeight: 0,
  height: "100%",
  position: "relative",
  backgroundColor: "rgb(229, 227, 223)",
})

const controlClass = (top: string) =>
  css({
    right: ".5em",
    top: top,
  })

const mapNotifierStyle = css({
  backgroundColor: getThemeColorVar("background", "lighter"),
  width: "100%",
  position: "absolute",
  zIndex: 1000,
  bottom: 0,
  transition: "max-height 1s",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  opacity: 0.9,
  overflow: "hidden",
})

const zoomInNotifierStyle = css({
  backgroundColor: "rgba(1,1,1,0.4)",
  color: "white",
  width: "100%",
  position: "absolute",
  zIndex: 1000,
  bottom: 0,
  transition: "max-height 1s",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
})

interface OwnState {
  hoverRegion: string | null
  hoverRegionName: string | null
  selectedMapStyle: MapStyle
  districtsAreVisible: boolean
  showIsochronePopover: boolean
  showPrivatePoisPopover: boolean
  poiPopUpPosition: [number, number] | undefined
}

class DistrictDataMap extends OpenLayersMap<Props, OwnState> {
  private overlay: SimpleOverlay | undefined
  private regionLayer: layer.VectorTile | undefined = undefined
  private districtsLayer: layer.VectorTile | undefined = undefined
  private markerLayer: layer.Vector<any> | undefined = undefined
  private isochroneLayer: layer.Vector<any> | undefined = undefined
  private privatePoiControl: PrivatePoiControl | undefined = undefined
  private clusterLayerArray: layer.Vector<any>[] = []
  private poiPopUp: PrivatePoiOverlay | undefined = undefined

  private isDistrictSelectionOn: boolean =
    this.props.dataSet === "micro" && this.props.selectionAreaSettings[this.props.currentSelectionArea] === "District"
  private t = translations()

  constructor(props: Props) {
    super(props)
    this.state = {
      hoverRegion: null,
      hoverRegionName: null,
      selectedMapStyle: getInitialMapStyle(props.initialMapStyle),
      districtsAreVisible: true,
      showIsochronePopover: false,
      showPrivatePoisPopover: false,
      poiPopUpPosition: undefined,
    }
  }

  @bind
  onChangeShowIsochrone(value: boolean) {
    this.setState({
      ...this.state,
      showPrivatePoisPopover: value ? false : this.state.showPrivatePoisPopover,
      showIsochronePopover: value,
    })
  }

  @bind
  onChangeShowPrivatePoisPopover(value: boolean) {
    this.setState({
      ...this.state,
      showIsochronePopover: value ? false : this.state.showIsochronePopover,
      showPrivatePoisPopover: value,
    })
  }

  @bind
  onChangeSelectedMapStyle(style: MapStyle) {
    this.setState({ selectedMapStyle: style })
    setMapUrlBasedOnMapStyle(this.getMap(), style)
    persistMapStyle(style)
  }

  async componentDidMount() {
    super.componentDidMount()
    void fetchCategories()
    this.regionLayer = new layer.VectorTile({
      declutter: true,
      source: municipalitySource,
      style: this.districtRegionStyle,
    })

    this.getMap().addLayer(this.regionLayer)

    this.getMap().on("pointermove", this.onPointerMove)
    this.getMap().on("singleclick", this.onMapClick)

    this.getMap().getControls().clear()
    this.getMap().addControl(
      new MapStyleControl(
        this.state.selectedMapStyle,
        this.onChangeSelectedMapStyle,
        this.mapContainer.current || undefined
      )
    )
    this.getMap().addControl(new FullScreen({ className: `${controlClass("3.5em")}` }))
    this.getMap().addControl(new Zoom({ className: `${controlClass("6em")}` }))

    this.addOrRefreshPrivatePoi()
    this.getMap().addControl(
      new IsochroneControl(
        "marketdata",
        this.props.isochroneSettings,
        this.props.setIsochroneSettings,
        this.state.showIsochronePopover,
        this.onChangeShowIsochrone,
        this.mapContainer.current || undefined
      )
    )

    this.poiPopUp = new PrivatePoiOverlay(this.getMap(), this.poiPopUpContentCreator)

    this.getMap().on("rendercomplete", this.onChangeViewport)
    setMapUrlBasedOnMapStyle(this.getMap(), this.state.selectedMapStyle)

    document.addEventListener("keydown", this.handleKeyDownEvent)

    if (this.props.userViewportSettings === defaultViewportSettings) {
      if (this.props.selectedLocationsExtents.focusArea.size) {
        const allSelectedRegionsExtent = extend.createEmpty()
        this.props.selectedLocationsExtents.focusArea.forEach((regExtent) =>
          extend.extend(allSelectedRegionsExtent, regExtent)
        )

        this.getMap()
          .getView()
          .fit(allSelectedRegionsExtent, {
            size: this.getMap().getSize(),
            padding: [100, 100, 100, 100],
          })
      } else {
        if (this.props.markerLocation)
          this.getMap()
            .getView()
            .setCenter(fromLonLat([this.props.markerLocation.lng, this.props.markerLocation.lat]))
        this.getMap().getView().setZoom(13)
      }
    } else {
      const currMapViewportSettings =
        this.props.userViewportSettings[this.props.dataSet][this.props.currentSelectionArea]
      this.getMap().getView().setCenter(currMapViewportSettings.viewportCenter)
      this.getMap().getView().setZoom(currMapViewportSettings.zoom)
    }

    if (this.props.showDistrictLayer) {
      this.districtsLayer = new layer.VectorTile({
        declutter: true,
        source: districtSource,
        style: this.districtRegionStyle,
      })
      this.getMap().addLayer(this.districtsLayer)
    }

    this.updateMarker(true)
    await this.updateIsochrone()
    updateClusters(
      this.getMap(),
      this.clusterLayerArray,
      this.setClusterLayerArray,
      this.props.privateDataSettings.multipleCategoryPOIList,
      this.props.privatePoiCategories,
      this.props.isPrivateDataAccessible
    )
  }
  componentWillUnmount() {
    super.componentWillUnmount()
    document.removeEventListener("keydown", this.handleKeyDownEvent)
  }

  async componentDidUpdate(prevProps: Props, prevState: OwnState) {
    super.componentDidUpdate(prevProps, prevState)

    this.addOrRefreshPrivatePoi()
    if (
      areMultiplePrivatePoisListsDifferent(
        prevProps.privateDataSettings.multipleCategoryPOIList,
        this.props.privateDataSettings.multipleCategoryPOIList
      )
    ) {
      updateClusters(
        this.getMap(),
        this.clusterLayerArray,
        this.setClusterLayerArray,
        this.props.privateDataSettings.multipleCategoryPOIList,
        this.props.privatePoiCategories,
        this.props.isPrivateDataAccessible
      )
    }
    // Never occurs due to unmounting on marker location change
    if (this.props.markerLocation !== prevProps.markerLocation) {
      this.updateMarker(false)
    }
    if (
      this.props.markerLocation !== prevProps.markerLocation ||
      this.props.isochroneSettings !== prevProps.isochroneSettings
    ) {
      await this.updateIsochrone()
    }

    if (
      this.props.selectionAreaSettings !== prevProps.selectionAreaSettings ||
      this.props.currentSelectionArea !== prevProps.currentSelectionArea
    ) {
      this.isDistrictSelectionOn =
        this.props.dataSet === "micro" &&
        this.props.selectionAreaSettings[this.props.currentSelectionArea] === "District"
      const savedZoomLevel = this.props.userViewportSettings[this.props.dataSet][this.props.currentSelectionArea].zoom
      if (savedZoomLevel)
        this.setState({
          ...this.state,
          districtsAreVisible: this.isDistrictSelectionOn && savedZoomLevel > 11,
        })
      this.adjustViewportToUserSettings()
      this.refreshLayers()
    }

    if (this.props.selectedLocationsFromStore !== prevProps.selectedLocationsFromStore) {
      this.refreshLayers()
    }

    if (this.props.showDistrictLayer !== prevProps.showDistrictLayer) {
      if (this.props.showDistrictLayer) {
        if (!this.districtsLayer) {
          this.districtsLayer = new layer.VectorTile({
            declutter: true,
            source: districtSource,
            style: this.districtRegionStyle,
          })

          this.getMap().addLayer(this.districtsLayer)
          this.districtsLayer.setVisible(true)
        }
      } else {
        if (this.districtsLayer) {
          this.districtsLayer.setVisible(false)
        }
      }
    }
    if (this.props.mainTab === "configuration" && prevProps.mainTab === "analysis") {
      this.getMap().updateSize()
    }

    if (arePoisToShowDifferent(prevProps.privateDataSettings.poisToShow, this.props.privateDataSettings.poisToShow)) {
      this.updatePoiPopUp()
    }
  }

  private addOrRefreshPrivatePoi() {
    if (!this.privatePoiControl) {
      this.privatePoiControl = new PrivatePoiControl(
        AllowedModulesEnum.DISTRICT_DATA,
        this.props.privatePoiCategories,
        this.props.privateDataSettings.selectedCategories,
        updateSelectedCategories,
        fetchCategoryPois,
        this.state.showPrivatePoisPopover,
        this.onChangeShowPrivatePoisPopover,
        this.props.isPrivateDataAccessible
      )
      this.getMap().addControl(this.privatePoiControl)
    } else {
      this.privatePoiControl?.refreshPopover(
        this.props.privatePoiCategories,
        this.props.privateDataSettings.selectedCategories,
        this.state.showPrivatePoisPopover
      )
    }
  }

  @bind
  private handleKeyDownEvent(e: KeyboardEvent) {
    if (e.key === "Escape") {
      this.overlay?.closeCurrentOverlay(true)
    }
  }

  protected containerClass(): string {
    return mapClass
  }

  @bind
  protected onMoveEnd() {}

  render() {
    return (
      <Grid columns={1} rowSpec="1fr" height={[100, "%"]}>
        <div className={mapClass} onMouseLeave={this.cleanHoverRegion}>
          {super.render()}

          {!this.isDistrictSelectionOn && (
            <div className={cx(mapNotifierStyle, css({ maxHeight: this.state.hoverRegionName ? "48px" : 0 }))}>
              <span style={{ padding: "8px" }}>{this.state.hoverRegionName}</span>
            </div>
          )}

          <div
            className={cx(
              zoomInNotifierStyle,
              css({
                maxHeight:
                  !this.state.districtsAreVisible &&
                  this.props.selectionAreaSettings[this.props.currentSelectionArea] === "District"
                    ? "180px"
                    : 0,
              })
            )}
          >
            <span style={{ padding: "56px" }}>{this.t.districtData.districtsZoomIn}</span>
          </div>
        </div>
      </Grid>
    )
  }

  rgbToRgba = (color: string, opacity: number): string => {
    return color.replace(/rgb/i, "rgba").replace(/\)/i, `,${opacity})`)
  }

  @bind
  private districtRegionStyle(feature: ol.Feature<Geometry> | RenderFeature): style.Style {
    if (
      this.props.dataSet === "macro" &&
      this.props.currentSelectionArea === "referenceArea" &&
      this.props.selectionAreaSettings.referenceArea === "Country"
    ) {
      return new style.Style({
        fill: new style.Fill({
          color: this.rgbToRgba(`${getThemeColor("secondary1", "default")}`, 0.75),
        }),
      })
    }

    if (
      (this.isDistrictSelectionOn && this.state.districtsAreVisible) ||
      this.props.selectionAreaSettings.referenceArea === "Municipality" ||
      this.props.selectionAreaSettings.focusArea === "Municipality"
    ) {
      const featureId = feature.getId()?.toString()
      if (this.state.hoverRegion && featureId === this.state.hoverRegion) {
        return new style.Style({
          fill: new style.Fill({
            color: "rgba(1,1,1,0.4)",
          }),
        })
      }

      if (this.props.selectedLocationsFromStore[this.props.currentSelectionArea].list.find((id) => id === featureId)) {
        return new style.Style({
          fill: new style.Fill({
            color: this.rgbToRgba(
              `${getThemeColor(this.props.currentSelectionArea === "focusArea" ? "primary" : "secondary1", "default")}`,
              0.75
            ),
          }),
          stroke: new style.Stroke({
            width: 3,
            color: this.rgbToRgba(
              `${getThemeColor(this.props.currentSelectionArea === "focusArea" ? "primary" : "secondary1", "dark")}`,
              1
            ),
          }),
        })
      }
    }

    return new style.Style({
      fill: new style.Fill({
        color: "rgba(1,0,0,0)", // transparent, but essential for layer.getFeatures to work
      }),
    })
  }

  @bind
  private onPointerMove(event: ol.MapBrowserEvent<any>) {
    if (!this.districtsLayer?.getVisible() && this.isDistrictSelectionOn) return
    if (
      this.props.dataSet === "macro" &&
      this.props.selectionAreaSettings[this.props.currentSelectionArea] === "Country"
    )
      return
    if (this.props.removeSelectionActive) return

    const features = this.getMap().getFeaturesAtPixel(event.pixel, {
      layerFilter: (layer) => (this.isDistrictSelectionOn ? layer === this.districtsLayer : layer === this.regionLayer),
    })

    const coordinate = this.getMap().getCoordinateFromPixel(event.pixel)
    if (features.length < 1 || !extend.containsCoordinate(features[0].getGeometry()?.getExtent() ?? [], coordinate)) {
      this.cleanHoverRegion()
      return
    }

    const id = features[0].getId()?.toString()

    if (
      this.props.onSelectLocation &&
      !this.props.selectedLocationsFromStore[this.props.currentSelectionArea].list.find((r) => r === id) &&
      id !== undefined
    ) {
      if (id != this.state.hoverRegion) {
        this.setState(
          {
            hoverRegion: id,
            hoverRegionName: this.isDistrictSelectionOn ? "" : `${features[0].getProperties()["GEN"]}`,
          },
          () => {
            if (this.overlay?.getRendering())
              this.getMap().once("rendercomplete", () =>
                this.isDistrictSelectionOn ? this.districtsLayer?.changed() : this.regionLayer?.changed()
              )
            else this.isDistrictSelectionOn ? this.districtsLayer?.changed() : this.regionLayer?.changed()
          }
        )
      }
    } else {
      this.cleanHoverRegion()
    }
  }

  @bind
  private isClickableLayers(layer: Layer<Source>): boolean {
    if (layer.getClassName().includes("cluster-layer")) {
      return true
    } else {
      return this.isDistrictSelectionOn ? layer === this.districtsLayer : layer === this.regionLayer
    }
  }

  @bind
  private onMapClick(event: ol.MapBrowserEvent<any>) {
    if (!this.districtsLayer?.getVisible() && this.isDistrictSelectionOn && this.clusterLayerArray.length === 0) return

    const features = this.getMap().getFeaturesAtPixel(event.pixel, {
      layerFilter: (layer) => this.isClickableLayers(layer),
    })
    if (features.length > 0) {
      if (features[0].getProperties().features && features[0].getProperties().geometry) {
        const featuresArray: ol.Feature[] = features[0].getProperties().features
        const geometry = features[0].getProperties().geometry

        const categoryId = featuresArray[0].getId()?.toString().split(":")[1] ?? ""
        const poiIdsToShow: string[] = featuresArray.map((feature) => feature.getId()?.toString().split(":")[2] ?? "")
        const poisToShow: PrivatePOIList = (
          this.props.privateDataSettings.multipleCategoryPOIList[categoryId] ?? []
        ).filter((poi) => poiIdsToShow.includes(poi.id))
        const position = geometry.getCoordinates()
        this.setState({ ...this.state, poiPopUpPosition: position })
        updatePOIsToShow(poisToShow, AllowedModulesEnum.DISTRICT_DATA)
      } else {
        const id = features[0].getId()?.toString() ?? ""
        const updateSelectedLocations = (selectedRegions: Array<string>) => {
          this.overlay?.closeCurrentOverlay(true)
          if (selectedRegions.find((r) => r === id)) {
            this.props.onRemoveSelectedLocation?.call(null, id)
            this.setState(
              {
                hoverRegion: id,
                hoverRegionName: this.isDistrictSelectionOn ? "" : `${features[0].getProperties()["GEN"]}`,
              },
              () => {
                if (this.overlay?.getRendering())
                  this.getMap().once("rendercomplete", () =>
                    this.isDistrictSelectionOn ? this.districtsLayer?.changed() : this.regionLayer?.changed()
                  )
                else this.isDistrictSelectionOn ? this.districtsLayer?.changed() : this.regionLayer?.changed()
              }
            )
            this.props.onSelectedLocationsExtentsChange(id)
          } else {
            const shapeKind = this.isDistrictSelectionOn ? "districtShape" : "macroShape"

            this.props.onSelectLocation?.call(null, id)

            void Axios.get(`${lanaApiUrl}/api/map/${shapeKind}/${encodeURIComponent(id)}`, {}).then(
              (success: AxiosResponse) => {
                const selectedLocationExtent = transformExtent(
                  new GeoJSON().readGeometry(success.data).getExtent(),
                  "EPSG:4326",
                  "EPSG:3857"
                )
                this.props.onSelectedLocationsExtentsChange(id, selectedLocationExtent)
                this.cleanHoverRegion()
                this.refreshLayers()
              }
            )
          }
        }
        updateSelectedLocations(this.props.selectedLocationsFromStore[this.props.currentSelectionArea].list)
      }
    }
  }

  @bind
  private cleanHoverRegion() {
    if (this.state.hoverRegion) {
      this.setState(
        {
          hoverRegion: null,
          hoverRegionName: null,
        },
        () => {
          if (this.overlay?.getRendering())
            this.getMap().once("rendercomplete", () =>
              this.isDistrictSelectionOn ? this.districtsLayer?.changed() : this.regionLayer?.changed()
            )
          else this.isDistrictSelectionOn ? this.districtsLayer?.changed() : this.regionLayer?.changed()
        }
      )
    }
  }

  @bind
  private refreshLayers() {
    this.overlay?.closeCurrentOverlay(true)
    this.districtsLayer?.changed()
    this.regionLayer?.changed()

    let allSelectedRegionsExtent = extend.createEmpty()

    this.props.selectedLocationsExtents[this.props.currentSelectionArea].forEach((regExtent) =>
      extend.extend(allSelectedRegionsExtent, regExtent)
    )

    if (!extend.isEmpty(allSelectedRegionsExtent)) {
      const viewExtent = this.getMap().getView().calculateExtent(this.getMap().getSize())

      if (!extend.containsExtent(viewExtent, allSelectedRegionsExtent)) {
        this.getMap()
          .getView()
          .fit(allSelectedRegionsExtent, {
            duration: 250,
            size: this.getMap().getSize(),
            padding: [100, 50, 100, 50],
          })
        this.onChangeViewport()
      }
    } else {
      this.props.markerLocation
        ? this.getMap()
            .getView()
            .setCenter(fromLonLat([this.props.markerLocation.lng, this.props.markerLocation.lat]))
        : this.getMap()
            .getView()
            .setCenter(fromLonLat([centerBerlin.lng, centerBerlin.lat]))
      this.onChangeViewport()
    }
  }

  @bind
  private onChangeViewport() {
    this.districtsLayer?.setVisible((this.getMap().getView().getZoom() ?? 0) > 11)
    this.setState({ ...this.state, districtsAreVisible: (this.getMap().getView().getZoom() ?? 0) > 11 })
    const dataSet = this.props.dataSet
    const selectionArea = this.props.currentSelectionArea

    this.props.setUserViewportSettings({
      ...this.props.userViewportSettings,
      [dataSet]: {
        ...this.props.userViewportSettings[dataSet],
        [selectionArea]: {
          viewportCenter: this.getMap().getView().getCenter(),
          zoom: this.getMap().getView().getZoom(),
        },
      },
    })
  }

  @bind
  private adjustViewportToUserSettings() {
    const zoomSettings = this.props.userViewportSettings[this.props.dataSet][this.props.currentSelectionArea].zoom
    if (zoomSettings !== null) this.getMap().getView().setZoom(zoomSettings)
    const viewportCenterSettings =
      this.props.userViewportSettings[this.props.dataSet][this.props.currentSelectionArea].viewportCenter
    if (viewportCenterSettings !== null) this.getMap().getView().setCenter(viewportCenterSettings)
  }

  // N.B. this never gets called when false
  private updateMarker(initialLoad: boolean) {
    if (this.props.markerLocation) {
      if (!this.markerLayer) {
        const feature = new ol.Feature({
          geometry: new Point(fromLonLat([this.props.markerLocation.lng, this.props.markerLocation.lat])),
        })
        feature.setId(MARKER_LAYER)
        this.markerLayer = new layer.Vector({
          source: new source.Vector({
            features: [feature],
          }),
          style: new style.Style({
            image: new style.Icon({
              src: `${reportAssetsUrl ?? ""}/assets/marker.svg`,
              size: [42, 60],
              anchorOrigin: "top-left",
              anchor: [0.5, 1],
            }),
          }),
        })
        this.getMap().addLayer(this.markerLayer)
      } else {
        this.markerLayer.setSource(
          new source.Vector({
            features: [
              new ol.Feature({
                geometry: new Point(fromLonLat([this.props.markerLocation.lng, this.props.markerLocation.lat])),
              }),
            ],
          })
        )
      }

      if (!initialLoad)
        this.getMap()
          .getView()
          .animate({
            center: fromLonLat([this.props.markerLocation.lng, this.props.markerLocation.lat]),
          })
    } else {
      if (this.markerLayer) {
        this.getMap().removeLayer(this.markerLayer)
        this.markerLayer = undefined
      }
    }
  }

  @bind private setClusterLayerArray(newClusterArrayLayer: layer.Vector<any>[]) {
    this.clusterLayerArray = [...newClusterArrayLayer]
  }

  @bind
  private updatePoiPopUp() {
    if (this.props.isPrivateDataAccessible) {
      if (this.props.privateDataSettings.poisToShow.length > 0 && this.state.poiPopUpPosition) {
        this.poiPopUp?.openOverlayAt(this.state.poiPopUpPosition, this.props.privateDataSettings.poisToShow)
      } else if (this.poiPopUp) {
        this.poiPopUp.closeCurrentOverlay()
      }
    } else if (this.poiPopUp) {
      this.poiPopUp.closeCurrentOverlay()
    }
  }

  @bind onPoiPopUpClose() {
    this.poiPopUp?.closeCurrentOverlay()
    updatePOIsToShow([], AllowedModulesEnum.DISTRICT_DATA)
    this.setState({ ...this.state, poiPopUpPosition: undefined })
  }

  @bind
  private poiPopUpContentCreator(location: number[], poiList: PrivatePOIList): JSXContent {
    const props = {
      lat: location[1],
      lng: location[0],
      poisToShow: poiList,
      onClose: this.onPoiPopUpClose,
    }
    return {
      content: <PoiMarkerDialog {...props} entryPinLocation={this.props.markerLocation} />,
    }
  }

  private async updateIsochrone() {
    if (this.props.markerLocation && this.props.isochroneSettings.mode !== "none") {
      const isochronePolygon = await getIsochronePolygon(this.props.markerLocation, this.props.isochroneSettings)
      const feature = toOlFeatureFromGooglePolygon(isochronePolygon)
      if (!this.isochroneLayer) {
        feature.setId(ISOCHRONE_LAYER)
        this.isochroneLayer = new layer.Vector({
          source: new source.Vector({
            features: [feature],
          }),
          style: new style.Style({
            stroke: new Stroke({
              color: this.rgbToRgba(`${getThemeColor("primary", "default")}`, 1),
              width: 4,
            }),
          }),
        })
        this.getMap().addLayer(this.isochroneLayer)
      } else {
        this.isochroneLayer.setSource(
          new source.Vector({
            features: [feature],
          })
        )
      }
    } else {
      if (this.isochroneLayer) {
        this.getMap().removeLayer(this.isochroneLayer)
        this.isochroneLayer = undefined
      }
    }
  }
}

export default connector(DistrictDataMap)
