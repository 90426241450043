import React, { useEffect, useMemo, useRef, useState } from "react"
import {
  AssessmentEntryFull,
  Exclusiveness,
  exclusivenessToNumber,
  PriceRange,
  reconstructUsageType,
  UsageType,
} from "../../assessment/models/assessment"
import { useSelector } from "react-redux"
import { State } from "../../assessment/reducers/state"
import { useAppSelector } from "../../relas/store"
import { FlexItem } from "../../shared/components/ui/flex-item"
import { Line } from "react-chartjs-2"
import { Chart, ChartData, ChartOptions, Plugin, ScatterDataPoint, TooltipItem } from "chart.js"
import { Chart as ChartGraph } from "react-chartjs-2"
import { translations } from "../../assessment/i18n"
import Axios, { AxiosResponse } from "axios"
import { lanaApiUrl } from "../../app_config"
import { formatNumber } from "../../shared/helper/number-format"
import { getThemeColor } from "../../shared/helper/color"
import LoadingSpinner from "../../shared/components/loadingspinner"
import { PriceWidget } from "./price-widget"

interface PriceVariationRequest {
  request: {
    cid?: number
    lon: number
    lat: number
    construction_year: number
    area: number
    class: number
    new_building?: number
  }
  usageType: UsageType
}

interface PriceVariationPlotData {
  data: Array<[number, number]>
  min: number
  max: number
}

interface PriceVariationResponse {
  areaPlotData: PriceVariationPlotData
  classPlotData?: PriceVariationPlotData
  constructionYearPlotData?: PriceVariationPlotData
}
type variationType = "class" | "area" | "constructionYear"

interface Props {
  unitMlPrices: PriceRange | undefined
}
export const UnitPriceVariationsV2 = (props: Props) => {
  const t = useMemo(translations, [translations])
  const priceType = useSelector((state: State) => state.assessment.selectedPriceType)
  const currentAssessmentEntry = useSelector((state: State) => state.assessment.currentAssessmentEntry)
  const objectPricesParams = useAppSelector((state) => state.assessment.objectPricesParams)
  const [loadingData, setLoadingData] = useState(false)

  const controllerRef = useRef<AbortController>()

  const [priceVariation, setPriceVariation] = useState<PriceVariationResponse | undefined>(undefined)
  const isDataAvailable = (): boolean => {
    const entryValues = getCurrentAssessmentEntryParsedValues(currentAssessmentEntry)
    return !!(
      entryValues &&
      entryValues.address.location &&
      entryValues.year &&
      entryValues.area &&
      entryValues.exclusiveness
    )
  }

  const getCurrentAssessmentEntryParsedValues = (
    currentAssessmentEntry: AssessmentEntryFull | null
  ): AssessmentEntryFull | null => {
    return currentAssessmentEntry
      ? {
          ...currentAssessmentEntry,
          year: currentAssessmentEntry.year ? currentAssessmentEntry.year : 2000,

          area: currentAssessmentEntry.area ? currentAssessmentEntry.area : 100,
          exclusiveness: currentAssessmentEntry.exclusiveness
            ? currentAssessmentEntry.exclusiveness
            : Exclusiveness.Standard,
        }
      : null
  }

  const getEntryValuesToCompare = () => {
    const assessmentData = getCurrentAssessmentEntryParsedValues(currentAssessmentEntry)
    return {
      constructionYear: assessmentData?.year,
      area: assessmentData?.area,
      class: exclusivenessToNumber(assessmentData?.exclusiveness),
    }
  }

  useEffect(() => {
    if (controllerRef.current) controllerRef.current.abort()
    controllerRef.current = new AbortController()
    if (isDataAvailable()) {
      let signal: AbortSignal = controllerRef.current.signal
      const assessmentEntry = getCurrentAssessmentEntryParsedValues(currentAssessmentEntry)
      const request: PriceVariationRequest = {
        request: {
          lon: assessmentEntry?.address.location?.lng as number,
          lat: assessmentEntry?.address.location?.lat as number,
          construction_year: objectPricesParams?.constructionYear ?? (assessmentEntry?.year as number),
          area: objectPricesParams?.area ?? (assessmentEntry?.area as number),
          class: objectPricesParams?.class ?? exclusivenessToNumber(assessmentEntry?.exclusiveness),
          new_building: objectPricesParams?.newBuilding ? 1 : 0,
        },
        usageType: reconstructUsageType(objectPricesParams?.houseOrApartment ?? "apartment", priceType),
      }

      setLoadingData(true)
      Axios.post(`${lanaApiUrl}/api/ml_aggregate/price_variation`, request, {
        withCredentials: true,
        signal,
      })
        .then((response: AxiosResponse<PriceVariationResponse>) => response.data)
        .then((data) => {
          setPriceVariation(data)
          setLoadingData(false)
        })
        .catch(() => {
          if (signal.aborted) setLoadingData(true)
          else setLoadingData(false)
        })
    } else setPriceVariation(undefined)
    return () => {
      if (controllerRef.current) controllerRef.current.abort()
    }
  }, [currentAssessmentEntry, priceType, objectPricesParams])

  const getClosestPointToAvg = (
    plotData: PriceVariationPlotData | undefined,
    type: variationType
  ): [number, number] => {
    const assessmentData = getCurrentAssessmentEntryParsedValues(currentAssessmentEntry)
    if ((objectPricesParams || assessmentData) && plotData) {
      const assessmentEntryValues = getEntryValuesToCompare()
      const index = objectPricesParams?.[type] ?? assessmentEntryValues[type] ?? 0
      if (index >= plotData.min && index <= plotData.max) {
        const dataCopy = [...plotData.data]
        return dataCopy.sort((a, b) => Math.abs(index - a[0]) - Math.abs(index - b[0]))[0]
      } else return [0, 0]
    } else return [0, 0]
  }

  const getClosestXFromDataSet = (
    dataSet: ScatterDataPoint[],
    type: variationType,
    plotData: PriceVariationPlotData
  ): number => {
    const assessmentData = getCurrentAssessmentEntryParsedValues(currentAssessmentEntry)
    if ((objectPricesParams || assessmentData) && dataSet) {
      const assessmentEntryValues = getEntryValuesToCompare()
      const index = objectPricesParams?.[type] ?? assessmentEntryValues[type] ?? 0
      const dataCopy = [...dataSet]
      if (index >= plotData.min && index <= plotData.max) {
        let closest = dataCopy.sort((a, b) => Math.abs(index - a.x) - Math.abs(index - b.x))[0]
        return closest.x
      } else return 0
    } else return 0
  }

  const chartOptions = (type: variationType): ChartOptions<"line"> => {
    let plotData: PriceVariationPlotData | undefined
    switch (type) {
      case "class":
        plotData = priceVariation?.classPlotData
        break
      case "area":
        plotData = priceVariation?.areaPlotData
        break
      case "constructionYear":
        plotData = priceVariation?.constructionYearPlotData
        break
    }
    return {
      layout: {
        padding: {
          left: 10,
          right: 20,
          top: 10,
        },
      },
      scales: {
        y: {
          title: {
            display: true,
            text: t.prices.priceVariations.yAxis,
          },
          ticks: {
            callback: (value) => {
              const label = Number(value)
              const decimalPlace = Math.floor(label).toString().length > 2 ? 0 : 2
              return plotData?.min && plotData?.max ? formatNumber(label, decimalPlace) : ""
            },
          },
        },
        x: {
          type: "linear",
          position: "bottom",
          min: plotData?.min,
          max: plotData?.max,
          title: {
            display: true,
            text:
              type === "area"
                ? t.prices.priceVariations.xAxisArea
                : type === "class"
                ? t.prices.priceVariations.xAxisClass
                : t.prices.priceVariations.xAxisConstructionYear,
          },
          ticks: {
            callback: (value) => {
              return plotData?.min && plotData?.max ? value : ""
            },
            stepSize: type === "class" ? 1 : undefined,
          },
        },
      },
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          titleFont: {
            size: 16,
            family: "Lato",
          },
          bodyFont: {
            size: 14,
            family: "Lato",
          },
          displayColors: false,
          backgroundColor: getThemeColor("background", "dark").toString(),
          callbacks: {
            title: (items: TooltipItem<any>[]) => {
              const decimalPlace = Math.floor(items[0].parsed.x).toString().length > 2 ? 0 : 2
              return type === "constructionYear" ? items[0].parsed.x : formatNumber(items[0].parsed.x, decimalPlace)
            },
            label: (item: TooltipItem<"bar" | "line">) => {
              const closestPointXVal = getClosestPointToAvg(plotData, type)[0]
              const isClosest =
                (type === "constructionYear" ? Math.trunc(closestPointXVal) : closestPointXVal) === item.parsed.x
              const value = isClosest && props.unitMlPrices ? props.unitMlPrices.avg : item.parsed.y
              const decimalPlace = Math.floor(value).toString().length > 2 ? 0 : 2
              return formatNumber(value, decimalPlace)
            },
          },
        },
      },
    }
  }

  const chartData = (type: variationType): ChartData<"line"> | ChartData<"bar"> => {
    let plotData: PriceVariationPlotData | undefined
    switch (type) {
      case "class":
        plotData = priceVariation?.classPlotData
        break
      case "area":
        plotData = priceVariation?.areaPlotData
        break
      case "constructionYear":
        plotData = priceVariation?.constructionYearPlotData
        break
    }

    return {
      datasets: [
        {
          pointBackgroundColor: (context) => {
            if (context.dataset.data.length > 1 && plotData) {
              let index = context.dataIndex
              let value = context.dataset.data[index]
              return getClosestXFromDataSet(context.dataset.data as ScatterDataPoint[], type, plotData) ===
                (value as ScatterDataPoint).x
                ? getThemeColor("primary", "default").toString()
                : getThemeColor("primary", "light").toString()
            } else return ""
          },
          backgroundColor: getThemeColor("primary", "light").toString(),
          borderColor: getThemeColor("primary", "light").toString(),
          fill: false,
          pointHoverBorderColor: "white",
          pointHoverBorderWidth: 2,
          pointHoverRadius: 10,
          borderWidth: 2,
          pointRadius: (context) => {
            if (context.dataset.data.length > 1 && plotData) {
              let index = context.dataIndex
              let value = context.dataset.data[index]
              return getClosestXFromDataSet(context.dataset.data as ScatterDataPoint[], type, plotData) ===
                (value as ScatterDataPoint).x
                ? 8
                : 0.7
            } else return 0
          },
          data: plotData
            ? plotData.data.map((item) => {
                return {
                  x: type === "constructionYear" ? Math.trunc(item[0]) : item[0],
                  y: item[1],
                }
              })
            : [{ x: 0, y: 0 }],
        },
      ],
    }
  }
  const plugin: Plugin = {
    id: "emptyChart",
    afterDraw(chart: Chart) {
      const { datasets } = chart.data
      let hasData = false
      for (let dataset of datasets) {
        //set this condition according to your needs
        if (dataset.data.length > 1 && dataset.data.some((item: ScatterDataPoint) => item.x !== 0 && item.y !== 0)) {
          hasData = true
          break
        }
      }

      if (!hasData) {
        //type of ctx is CanvasRenderingContext2D
        //https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D
        const {
          chartArea: { left, top, right, bottom },
          ctx,
        } = chart
        const centerX = (left + right) / 2
        const centerY = (top + bottom) / 2

        //chart.clear();
        //ctx.save();
        ctx.textAlign = "center"
        ctx.textBaseline = "middle"
        ctx.fillText(t.prices.priceVariations.noValues, centerX, centerY)
        ctx.restore()
      }
    },
  }

  const multiData = {
    datasets: [
      {
        type: "scatter" as const,
        pointBackgroundColor: getThemeColor("primary", "default").toString(),
        borderColor: getThemeColor("primary", "light").toString(),
        borderWidth: 2,
        pointHoverBorderColor: "white",
        pointHoverBorderWidth: 2,
        pointHoverRadius: 14,
        pointRadius: 10,
        fill: false,
        data: [
          {
            x:
              getClosestPointToAvg(priceVariation?.classPlotData, "class")[0] >= 1 &&
              getClosestPointToAvg(priceVariation?.classPlotData, "class")[0] <= 3
                ? getClosestPointToAvg(priceVariation?.classPlotData, "class")[0]
                : undefined,
            y:
              getClosestPointToAvg(priceVariation?.classPlotData, "class")[0] >= 1 &&
              getClosestPointToAvg(priceVariation?.classPlotData, "class")[0] <= 3
                ? priceVariation?.classPlotData?.data.find(
                    (data) => data[0] === getClosestPointToAvg(priceVariation?.classPlotData, "class")[0]
                  )?.[1]
                : undefined,
          },
        ],
      },
      {
        type: "bar" as const,
        backgroundColor: getThemeColor("primary", "light").toString(),
        borderColor: getThemeColor("primary", "light").toString(),
        borderWidth: 2,
        data:
          priceVariation?.classPlotData?.data.map((item) => {
            return {
              x: item[0],
              y:
                item[1] === getClosestPointToAvg(priceVariation?.classPlotData, "class")[1]
                  ? getClosestPointToAvg(priceVariation?.classPlotData, "class")[1] || item[1]
                  : item[1],
            }
          }) ?? [],
      },
    ],
  }

  return (
    <PriceWidget title={t.prices.priceVariations.header} info={t.prices.priceVariations.info}>
      {loadingData ? (
        <div style={{ justifySelf: "center", alignSelf: "center", justifyContent: "center" }}>
          <LoadingSpinner size={64} />
        </div>
      ) : (
        <div style={{ alignSelf: "center" }}>
          <FlexItem>
            <Line
              data={chartData("area") as ChartData<"line">}
              options={chartOptions("area") as ChartOptions<"line">}
              height={140}
              plugins={[plugin]}
              style={{ paddingBottom: "10px" }}
            />
          </FlexItem>
          <FlexItem>
            <Line
              data={chartData("constructionYear") as ChartData<"line">}
              options={chartOptions("constructionYear") as ChartOptions<"line">}
              height={140}
              plugins={[plugin]}
              style={{ paddingBottom: "10px" }}
            />
          </FlexItem>
          <FlexItem>
            <ChartGraph type="bar" data={multiData} options={chartOptions("class")} height={140} plugins={[plugin]} />
          </FlexItem>
        </div>
      )}
    </PriceWidget>
  )
}
