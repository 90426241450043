import * as React from "react"
import { bind } from "decko"
import { css, cx } from "emotion"
import Grid from "./restyle-grid/grid"
import GridItem from "./restyle-grid/griditem"
import FlexContainer from "./restyle-grid/flexcontainer"
import { GenericInputElementProps, GenericInputElementState, ReValidator } from "../models/genericinputelement"
import GenericInputElement from "./genericinputfield"
import { getThemeColorVar } from "../helper/color"
import { isNullOrUndefined } from "../helper/utils"
import { formatNumber, isInteger } from "../helper/number-format"

export interface Props extends GenericInputElementProps<HTMLInputElement> {
  min?: number
  max?: number
  moreThan?: number
  integer?: boolean
  precision?: number // precision when formatting enabled
  formattingEnabled?: boolean
  suffix?: string
  javaScriptAutoFocus?: boolean
  lang?: string
  autoComplete?: "on" | "off"
}

function isNumber(value: string): boolean {
  return /^[\-]?[0-9.]+$/.test(value) && !isNaN(value as any) && parseFloat(value).toString().indexOf("e") === -1
}

export const suffixClass = css({
  backgroundColor: getThemeColorVar("background", "lighter"),
  color: getThemeColorVar("typo", "light"),
  cursor: "default",
  padding: "8px",
  verticalAlign: "middle",
  fontWeight: 400,
  minWidth: "30px",
  textAlign: "center",
  borderRadius: "0px 4px 4px 0px",
  textOverflow: "ellipsis",
  overflow: "hidden",
})

export const suffixSmallClass = css({
  padding: "5px 8px",
  backgroundColor: getThemeColorVar("border", "default"),
  color: getThemeColorVar("white", undefined),
})

const readOnlyOverlayWrapperClass = css({
  ":focus-within": {
    ".revo-input-number-overlay-wrapper": {
      display: "none",
    },
  },
  ":not(:focus-within)": {
    overflow: "hidden",
    "input, input[disabled], input::placeholder, input[disabled]:not(:focus)": {
      color: "transparent",
    },
    ".revo-input-number-overlay-wrapper": {
      pointerEvents: "none",
      gridArea: "1 / 1 / auto / auto",
      alignSelf: "center",
      fontSize: "16px",
      paddingRight: "9px",
      paddingLeft: "9px",
      minWidth: 0,

      p: {
        maxWidth: "100%",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
    },
  },
  ".revo-grid input": {
    height: "100%",
  },
})

const disabledClass = css({
  p: {
    color: getThemeColorVar("typo", "light"),
  },
})

export class NumberField extends GenericInputElement<Props, GenericInputElementState, HTMLInputElement> {
  public inputElRef: HTMLInputElement | null

  constructor(props: Props) {
    super(props)
    this.validators.push(
      this.createNumberValidator(),
      this.createMinNumberValidator(),
      this.createMaxNumberValidator(),
      this.createMoreThanNumberValidator(),
      this.createIsIntegerValidator()
    )

    this.state = {
      ...this.defaultState,
    }
  }

  createMinNumberValidator(): ReValidator<string> {
    return (value) => {
      let isValid = true

      if (!isNullOrUndefined(this.props.min) && value != "" && isNumber(value)) {
        isValid = this.props.min <= parseFloat(value)
      }

      return {
        valid: isValid,
        validationMessage: this.translate.validationTranslations.min(
          isNullOrUndefined(this.props.min) ? 0 : this.props.min
        ),
      }
    }
  }

  createMaxNumberValidator(): ReValidator<string> {
    return (value) => {
      let isValid = true

      if (!isNullOrUndefined(this.props.max) && value != "" && isNumber(value)) {
        isValid = this.props.max >= parseFloat(value)
      }

      return {
        valid: isValid,
        validationMessage: this.translate.validationTranslations.max(
          isNullOrUndefined(this.props.max) ? 0 : this.props.max
        ),
      }
    }
  }

  createMoreThanNumberValidator(): ReValidator<string> {
    return (value) => {
      let isValid = true

      if (!isNullOrUndefined(this.props.moreThan) && value != "" && isNumber(value)) {
        isValid = this.props.moreThan < parseFloat(value)
      }

      return {
        valid: isValid,
        validationMessage: this.translate.validationTranslations.moreThan(
          isNullOrUndefined(this.props.moreThan) ? 0 : this.props.moreThan
        ),
      }
    }
  }

  createNumberValidator(): ReValidator<string> {
    return (value) => {
      return {
        valid: value === "" || isNumber(value),
        validationMessage: this.translate.validationTranslations.notANumber,
      }
    }
  }

  createIsIntegerValidator(): ReValidator<string> {
    return (value) => {
      let isValid = true

      if (this.props.integer && !isNullOrUndefined(value) && value !== "" && isNumber(value)) {
        isValid = isInteger(parseFloat(value)) && value.toString().indexOf(".") === -1
      }

      return {
        valid: isValid,
        validationMessage: this.translate.validationTranslations.integer,
      }
    }
  }

  componentDidMount() {
    this.inputElRef && this.props.javaScriptAutoFocus && this.inputElRef.focus()
  }

  @bind
  normalizeValue(value: string) {
    return value.replace(this.translate.primitive.decimalSeparator, ".")
  }

  @bind
  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ isPristine: false })
    const normalizedValue = this.inputElRef ? this.normalizeValue(this.inputElRef.value) : ""
    this.props.onValueChange && this.props.onValueChange(normalizedValue, this.validate(normalizedValue).length === 0)
  }

  @bind
  handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      // handle Enter
      const normalizedValue = this.inputElRef ? this.normalizeValue(this.inputElRef.value) : ""
      this.props.onValueCommit && this.props.onValueCommit(normalizedValue, this.validate(normalizedValue).length === 0)
    }
    this.props.onKeyDown && this.props.onKeyDown(event)
  }

  @bind
  handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    const normalizedValue = this.inputElRef ? this.normalizeValue(this.inputElRef.value) : ""
    this.props.onValueCommit && this.props.onValueCommit(normalizedValue, this.validate(normalizedValue).length === 0)
    this.props.onBlur && this.props.onBlur(event)
  }

  inputField() {
    const overlayClasses = []
    this.props.disabled && overlayClasses.push(disabledClass)

    const valueString = isNullOrUndefined(this.props.value)
      ? ""
      : this.props.value.toString().replace(".", this.translate.primitive.decimalSeparator)

    return (
      <div className={cx(readOnlyOverlayWrapperClass)}>
        <Grid columns={1} height={[100, "%"]}>
          <GridItem colStart={1} rowStart={1}>
            <input
              tabIndex={this.props.disabled ? -1 : this.props.tabIndex}
              id={this.props.id}
              ref={(el) => (this.inputElRef = el)}
              type="text"
              autoFocus={this.props.autoFocus}
              value={valueString}
              placeholder={this.props.hint}
              required={this.props.required}
              disabled={this.props.disabled}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              onFocus={this.defaultHandleFocus}
              onKeyDown={this.handleKeyDown}
              lang={this.props.lang}
              autoComplete={this.props.autoComplete}
            />
          </GridItem>
          <div className={cx("revo-input-number-overlay-wrapper", cx(overlayClasses))}>
            <p>
              {this.props.formattingEnabled && isNumber(this.props.value)
                ? formatNumber(parseFloat(this.props.value), this.props.precision, undefined)
                : valueString}
            </p>
          </div>
        </Grid>
      </div>
    )
  }

  renderInner() {
    if (this.props.suffix) {
      const suffixClasses = [suffixClass]

      this.props.size === "small" && suffixClasses.push(suffixSmallClass)

      return (
        <Grid columnSpec="auto minmax(0, min-content)">
          {this.inputField()}
          {this.props.suffix && (
            <FlexContainer direction="row" canShrink={false}>
              <div className={cx(suffixClasses)}>{this.props.suffix}</div>
            </FlexContainer>
          )}
        </Grid>
      )
    } else {
      return this.inputField()
    }
  }
}

export default NumberField
