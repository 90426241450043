import * as React from "react"
import { translations } from "../i18n"
import { Translations } from "../i18n/translations"
import { RegistrationContainer } from "./registration-container"
import RevoCard from "../../shared/components/revo-card"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"

export const RegistrationStepTwo = () => {
  const translate: Translations = translations()

  return (
    <RegistrationContainer title={translate.stepTwo.header}>
      <RevoCard>
        <FlexContainer>{translate.stepTwo.text}</FlexContainer>
      </RevoCard>
    </RegistrationContainer>
  )
}
