import * as React from "react"
import { useEffect } from "react"
import { AssessmentList } from "../assessment/components/assessment-list"
import { AssessmentDetails } from "../assessment/components/assessment-details"
import { AssessmentEntry } from "../assessment/components/assessment-entry"
import { NavigationPage } from "../shared/actions/navigation"
import DistrictDataApp from "../assessment/components/district-data"
import { useAppSelector } from "./store"
import { Profiles } from "../profile/components/profiles"
import { assertUnreachable } from "../utils/utils"
import { FeaturePage } from "../features/feature-page"
import { QuickStart } from "../assessment/components/quick-start/quick-start"
import { DashboardApp } from "../assessment/components/dashboard/dashboard"
import { translations } from "./i18n"
import { SpecialMaps } from "../assessment/components/special-maps/special-maps"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd"
import PrivateData from "../private-data/components/private-pois/private-data"
import { LocationSelector } from "../locationselector/components/location-selector"
import RatingManager from "../ratings/components/rating-manager"
import { UserAccountProfile } from "../user-account/components/user-account-profile"
import { UserAccountSecurity } from "../user-account/components/userAccountSecurity"
import { CompanyMembersList } from "../company-admin/components/memberslist"
import { CompanySettings } from "../company-admin/components/companySettings"
import { CompanyProductUsage } from "../company-admin/components/company-product-usage"
import { CompanyReadonlyBookings } from "../company-admin/components/company-readonly-bookings"
import { ApiTokenList } from "../company-admin/components/api-token-list"
import { RentIndexMain } from "../assessment/components/rentindex/rent-index-main"
import { POIExplorerApp } from "../assessment/components/POI-explorer/poi-explorer"
import { loadMyDashboards } from "./user-slice"
import { RegistrationStepOne } from "../registration/components/registration-step-one"
import { RegistrationStepTwo } from "../registration/components/registration-step-two"
import { RegistrationStepThree } from "../registration/components/registration-step-three"
import { RegistrationVerifyEmail } from "../registration/components/registration-verify-email"
import { RegistrationComplete } from "../registration/components/registration-complete"
import { RatingsApp } from "../ratings/components/ratings-app"
import { Login } from "../login/components/login"
import { LoginPasswordInvite } from "../login/components/password-invite"
import { LoginPasswordForgotten } from "../login/components/password-forgotten"
import { PasswordForgottenRequestPage } from "../login/components/password-forgotten-request"
import { RentIndexAssessmentList } from "../assessment/components/rentindex/rent-index-assessment-list"
import { FundamentalDataApp } from "../assessment/components/fundamental-data/fundamental-data-app"
import { PricesApp } from "../prices/components/prices-app"
import { Help } from "../help/components/help"
import { ComparablesV2App } from "../assessment/components/comparables/comparablesV2"
import { DowntimeOverlay } from "./downtime-overlay"

const setPageTitle = (title: string) => {
  document.title = `21st - ${title}`
}

export const RelasMainFrame = () => {
  const t = React.useMemo(translations, [translations])
  const currentPage = useAppSelector((state) => state.navigation.currentPage)

  const userId = useAppSelector((state) => state.user.user?.id)
  const locationAssessmentScope = useAppSelector((state) => state.user.scopes.assessment)
  const selectedDashboardId = useAppSelector((state) => state.user.lana.selectedDashboardId)
  const [showDowntimePopup, setShowDowntimePopup] = React.useState(true)

  useEffect(() => {
    if (userId && locationAssessmentScope) {
      void loadMyDashboards(selectedDashboardId)
    }
  }, [userId, locationAssessmentScope])

  const renderInner = () => {
    if (currentPage) {
      switch (currentPage.name) {
        case NavigationPage.quickstart:
          setPageTitle(t.pageTitles.quickstart)
          return <QuickStart />
        case NavigationPage.assessmentList:
          setPageTitle(t.pageTitles.locationAssessment)
          return <AssessmentList module="assessment" />
        case NavigationPage.comparablesList:
          setPageTitle(t.pageTitles.locationAssessment)
          return <AssessmentList module="comparables" />
        case NavigationPage.marketdataList:
          setPageTitle(t.pageTitles.locationAssessment)
          return <AssessmentList module="marketdata" />
        case NavigationPage.dashboardList:
          setPageTitle(t.pageTitles.locationAssessment)
          return <AssessmentList module="dashboard" />
        case NavigationPage.fundamentalDataList:
          setPageTitle(t.pageTitles.locationAssessment)
          return <AssessmentList module="fundamentalData" />
        case NavigationPage.poiExplorerList:
          setPageTitle(t.pageTitles.locationAssessment)
          return <AssessmentList module="poiExplorer" />
        case NavigationPage.specialMapsList:
          setPageTitle(t.pageTitles.locationAssessment)
          return <AssessmentList module="specialMaps" />
        case NavigationPage.ratingsList:
          setPageTitle(t.pageTitles.locationAssessment)
          return <AssessmentList module="ratings" />
        case NavigationPage.pricesList:
          setPageTitle(t.pageTitles.locationAssessment)
          return <AssessmentList module="prices" />
        case NavigationPage.assessmentDetails:
          setPageTitle(t.pageTitles.locationAssessment)
          return <AssessmentDetails module={"assessment"} />
        case NavigationPage.comparablesDetails:
          setPageTitle(t.pageTitles.locationAssessment)
          return <AssessmentDetails module={"comparables"} />
        case NavigationPage.marketdataDetails:
          setPageTitle(t.pageTitles.locationAssessment)
          return <AssessmentDetails module={"marketdata"} />
        case NavigationPage.fundamentalDataDetails:
          setPageTitle(t.pageTitles.locationAssessment)
          return <AssessmentDetails module={"fundamentalData"} />
        case NavigationPage.dashboardDetails:
          setPageTitle(t.pageTitles.locationAssessment)
          return <AssessmentDetails module={"dashboard"} />
        case NavigationPage.poiExplorerDetails:
          setPageTitle(t.pageTitles.locationAssessment)
          return <AssessmentDetails module={"poiExplorer"} />
        case NavigationPage.specialMapsDetails:
          setPageTitle(t.pageTitles.locationAssessment)
          return <AssessmentDetails module={"specialMaps"} />
        case NavigationPage.ratingsDetails:
          setPageTitle(t.pageTitles.locationAssessment)
          return <AssessmentDetails module={"ratings"} />
        case NavigationPage.pricesDetails:
          setPageTitle(t.pageTitles.locationAssessment)
          return <AssessmentDetails module={"prices"} />
        case NavigationPage.assessment:
          setPageTitle(t.pageTitles.scoresRatingsPrices)
          return <AssessmentEntry module={"assessment"} widget={currentPage.params.widget} />
        case NavigationPage.ratings:
          setPageTitle(t.pageTitles.ratings)
          return (
            <RatingsApp
              assessmentEntryId={currentPage.params.entryId}
              assessmentId={currentPage.params.assessmentId}
              module={"ratings"}
            />
          )
        case NavigationPage.prices:
          setPageTitle(t.pageTitles.prices)
          return (
            <PricesApp
              assessmentEntryId={currentPage.params.entryId}
              assessmentId={currentPage.params.assessmentId}
              module={"prices"}
              widget={currentPage.params.widget}
            />
          )
        case NavigationPage.comparables:
          setPageTitle(t.pageTitles.comparables)
          return (
            <ComparablesV2App
              assessmentEntryId={currentPage.params.entryId}
              assessmentId={currentPage.params.assessmentId}
              module={"comparables"}
            />
          )
        case NavigationPage.marketdata:
          setPageTitle(t.pageTitles.districtData)
          return (
            <DistrictDataApp
              assessmentEntryId={currentPage.params.entryId}
              assessmentId={currentPage.params.assessmentId}
              module={"marketdata"}
            />
          )
        case NavigationPage.fundamentalData:
          setPageTitle(t.pageTitles.fundamentalData)
          return <FundamentalDataApp />
        case NavigationPage.dashboard:
          setPageTitle(t.pageTitles.assessmentOverview)
          return (
            <DashboardApp
              assessmentEntryId={currentPage.params.entryId}
              assessmentId={currentPage.params.assessmentId}
              module={"dashboard"}
              cleanDashboard={false}
            />
          )
        case NavigationPage.dashboardPdf:
          setPageTitle(t.pageTitles.assessmentOverview)
          return (
            <DashboardApp
              assessmentEntryId={currentPage.params.entryId}
              assessmentId={currentPage.params.assessmentId}
              showDashboardId={currentPage.params.dashboardId}
              module={"dashboard"}
              cleanDashboard={true}
            />
          )
        case NavigationPage.poiExplorer:
          setPageTitle(t.pageTitles.poiExplorer)
          return (
            <POIExplorerApp
              assessmentEntryId={currentPage.params.entryId}
              assessmentId={currentPage.params.assessmentId}
              module={"poiExplorer"}
            />
          )
        case NavigationPage.specialMaps:
          setPageTitle(t.pageTitles.specialMaps)
          return (
            <DndProvider backend={HTML5Backend}>
              <SpecialMaps
                assessmentEntryId={currentPage.params.entryId}
                assessmentId={currentPage.params.assessmentId}
                module={"specialMaps"}
              />
            </DndProvider>
          )
        case NavigationPage.profiles:
        case NavigationPage.profileEditor:
          setPageTitle(t.pageTitles.profileManager)
          return <Profiles />
        case NavigationPage.privateData:
          setPageTitle(t.pageTitles.privateData)
          return <PrivateData />
        case NavigationPage.features:
          return <FeaturePage />
        case NavigationPage.rentindex:
          setPageTitle(t.pageTitles.rentIndex)
          return (
            <RentIndexMain
              assessmentEntryId={currentPage.params.entryId}
              assessmentId={currentPage.params.assessmentId}
            />
          )
        case NavigationPage.rentindexList:
          setPageTitle(t.pageTitles.locationAssessment)
          return <AssessmentList module="rentindex" />
        case NavigationPage.rentindexDetails:
          setPageTitle(t.pageTitles.locationAssessment)
          return <AssessmentDetails module={"rentindex"} />
        case NavigationPage.locationSelector:
          setPageTitle(t.pageTitles.locationSelector)
          return <LocationSelector />
        case NavigationPage.ratingManager:
          setPageTitle(t.pageTitles.ratingManager)
          return <RatingManager />
        case NavigationPage.userSecurity:
          return <UserAccountSecurity />
        case NavigationPage.userProfile:
          return <UserAccountProfile />
        case NavigationPage.companyAdminSettings:
          return <CompanySettings />
        case NavigationPage.companyAdminMembers:
          return <CompanyMembersList />
        case NavigationPage.companyProductStatistics:
          return <CompanyProductUsage />
        case NavigationPage.companyProductBookings:
          return <CompanyReadonlyBookings />
        case NavigationPage.companyApiTokenList:
          return <ApiTokenList />
        case NavigationPage.login:
          setPageTitle(t.pageTitles.login)
          return <Login />
        case NavigationPage.is24LoginFailed:
          setPageTitle(t.pageTitles.login)
          return <Login is24AuthError={currentPage.params.error} />
        case NavigationPage.registrationStepOne:
          return <RegistrationStepOne />
        case NavigationPage.registrationStepTwo:
          return <RegistrationStepTwo />
        case NavigationPage.registrationStepThree:
          return <RegistrationStepThree />
        case NavigationPage.registrationVerifyEmail:
          return <RegistrationVerifyEmail />
        case NavigationPage.registrationComplete:
          return <RegistrationComplete />
        case NavigationPage.loginInviteSetPassword:
          return <LoginPasswordInvite inviteToken={currentPage.params.token} />
        case NavigationPage.loginForgotPassword:
          return <LoginPasswordForgotten resetToken={currentPage.params.token} />
        case NavigationPage.loginForgotPasswordRequest:
          return <PasswordForgottenRequestPage />
        case NavigationPage.rentIndexLocationList:
          return <RentIndexAssessmentList />
        case NavigationPage.help:
          return <Help section={currentPage.params.section} />
        default:
          assertUnreachable(currentPage)
      }
    } else {
      return ""
    }
  }

  const render = () => {
    return (
      <>
        <div
          className="bp4-focus-disabled"
          style={{
            height: "100%",
            width: "100%",
            display: "grid",
            gridTemplateRows: "1fr min-content",
            backgroundColor: "white",
          }}
        >
          <DowntimeOverlay show={showDowntimePopup} onClose={() => setShowDowntimePopup(false)} />
          <div>{renderInner()}</div>
        </div>
      </>
    )
  }

  return render()
}
