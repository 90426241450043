import * as React from "react"
import { useState } from "react"
import { Translations } from "../i18n/translations"
import { translations } from "../i18n"
import { UserAccountPasswordChange } from "./component-password"
import { UserAccountTwoFactorSetup } from "./user-account-two-factor-setup"
import { useSelector } from "react-redux"
import { NavigationPage, NavigationPageData } from "../../shared/actions/navigation"
import { navigateToSecurityPage } from "../user-profile-slice"
import { NavigationState } from "../../shared/reducers/navigation-slice"
import RevoHeader from "../../shared/components/revo-header"
import LocalNav from "../../shared/components/localnav"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"
import Grid from "../../shared/components/restyle-grid/grid"

export type SecurityPage = "password" | "2fa"

export const UserAccountSecurity = () => {
  const currentPage = useSelector((state: NavigationState) => state.currentPage)
  const toSecurityPage = (currentPage?: NavigationPageData) => {
    if (!currentPage || currentPage.name !== NavigationPage.userSecurity) return "password"

    switch (currentPage.params.securityPage) {
      case "password":
        return "password"
      case "2fa":
        return "2fa"
      default:
        return "password"
    }
  }

  const [page, setPage] = useState<SecurityPage>(toSecurityPage(currentPage))

  const switchPage = (page: SecurityPage) => {
    navigateToSecurityPage(page).then(
      () => setPage(page),
      () => {}
    )
  }

  const translate: Translations = translations()

  return (
    <div className="with-body-scroll">
      <FlexContainer direction="column">
        <RevoHeader title={translate.security.header} />
        <div style={{ height: "64px", minHeight: "64px" }}>
          <LocalNav<SecurityPage>
            values={["password", "2fa"]}
            selected={page}
            translate={translate.security.localNav}
            onSelect={switchPage}
          />
        </div>

        <Grid columns={1} paddingX="xxl" paddingY="xxl">
          {page === "password" && <UserAccountPasswordChange />}
          {page === "2fa" && <UserAccountTwoFactorSetup />}
        </Grid>
      </FlexContainer>
    </div>
  )
}
