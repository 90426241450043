import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ProfileData } from "./models/profile"
import { AxiosError, AxiosResponse, default as Axios } from "axios"
import { dashboardServiceUrl, loginServiceUrl } from "../app_config"
import { ChangePasswordRequest } from "./models/change-password"
import { TwoFactorMandatorySetupPayload, TwoFactorSetupPayload } from "./models/two-factor"
import { SecurityPage } from "./components/userAccountSecurity"
import { NavigationPage } from "../shared/actions/navigation"
import { store } from "../relas/store"
import { navigateTo } from "../shared/reducers/navigation-slice"
import { GenericError, toGenericError } from "../shared/helper/axios"

export enum TwoFactorState {
  Loading,
  Enabled,
  Disabled,
}

interface UserAccountProfileState {
  profileData?: ProfileData
  profileFetchError?: GenericError
  changeLanguageError?: GenericError
  changeLanguageInProgress: boolean
  changePasswordError?: GenericError
  wrongCurrentPasswordError?: GenericError
  changePasswordInProgress: boolean
  twoFactorEnabled: TwoFactorState
  fetchTwoFactorEnabledInProgress: boolean
  fetchTwoFactorEnabledError?: GenericError
  nukeTwoFactorInProgress: boolean
  nukeTwoFactorError?: GenericError
  setupTwoFactorInProgress: boolean
  setupTwoFactorError?: GenericError
}
export type UserAccountState = { userProfile: UserAccountProfileState }
export const initialState: UserAccountProfileState = {
  profileData: undefined,
  profileFetchError: undefined,
  changeLanguageError: undefined,
  changeLanguageInProgress: false,
  changePasswordError: undefined,
  changePasswordInProgress: false,
  twoFactorEnabled: TwoFactorState.Loading,
  fetchTwoFactorEnabledInProgress: false,
  fetchTwoFactorEnabledError: undefined,
  nukeTwoFactorInProgress: false,
  nukeTwoFactorError: undefined,
  setupTwoFactorInProgress: false,
  setupTwoFactorError: undefined,
}

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    startFetchProfile: (state) => {
      state.profileData = undefined
      state.changePasswordError = undefined
    },
    fetchProfileOk: (state, action: PayloadAction<ProfileData>) => {
      state.profileData = action.payload
      state.changePasswordError = undefined
    },
    fetchProfileError: (state, action: PayloadAction<GenericError>) => {
      state.profileData = undefined
      state.changePasswordError = action.payload
    },
    startChangeLanguage: (state) => {
      state.changeLanguageInProgress = true
      state.changeLanguageError = undefined
    },
    changeLanguageOk: (state, action: PayloadAction<ProfileData>) => {
      state.changeLanguageInProgress = false
      state.changeLanguageError = undefined
      state.profileData = action.payload
    },
    changeLanguageError: (state, action: PayloadAction<GenericError>) => {
      state.changeLanguageInProgress = false
      state.changeLanguageError = action.payload
    },
    wrongCurrentPasswordError: (state, action: PayloadAction<GenericError>) => {
      state.changePasswordInProgress = false
      state.changePasswordError = action.payload
    },
    startChangePassword: (state) => {
      state.changePasswordInProgress = true
      state.changePasswordError = undefined
      state.wrongCurrentPasswordError = undefined
    },
    changePasswordOk: (state, action: PayloadAction<ProfileData>) => {
      state.changePasswordInProgress = false
      state.changePasswordError = undefined
      state.wrongCurrentPasswordError = undefined
      state.profileData = action.payload
    },
    changePasswordError: (state, action: PayloadAction<GenericError>) => {
      state.changePasswordInProgress = false
      state.changePasswordError = action.payload
    },
    startFetchTwoFactorEnabled: (state) => {
      state.twoFactorEnabled = TwoFactorState.Loading
      state.fetchTwoFactorEnabledInProgress = true
      state.fetchTwoFactorEnabledError = undefined
    },
    fetchTwoFactorEnabledOk: (state, action: PayloadAction<TwoFactorState>) => {
      state.twoFactorEnabled = action.payload
      state.fetchTwoFactorEnabledInProgress = false
      state.fetchTwoFactorEnabledError = undefined
    },
    fetchTwoFactorEnabledError: (state, action: PayloadAction<GenericError>) => {
      state.fetchTwoFactorEnabledInProgress = false
      state.fetchTwoFactorEnabledError = action.payload
    },
    startNukeTwoFactor: (state) => {
      state.nukeTwoFactorInProgress = true
      state.nukeTwoFactorError = undefined
    },
    nukeTwoFactorOk: (state) => {
      state.nukeTwoFactorInProgress = false
      state.nukeTwoFactorError = undefined
    },
    nukeTwoFactorError: (state, action: PayloadAction<GenericError>) => {
      state.nukeTwoFactorInProgress = false
      state.nukeTwoFactorError = action.payload
    },
    startSetupTwoFactor: (state) => {
      state.setupTwoFactorInProgress = true
      state.setupTwoFactorError = undefined
    },
    setupTwoFactorOk: (state) => {
      state.setupTwoFactorInProgress = false
      state.setupTwoFactorError = undefined
    },
    setupTwoFactorError: (state, action: PayloadAction<GenericError>) => {
      state.setupTwoFactorInProgress = false
      state.setupTwoFactorError = action.payload
    },
  },
})
const {
  startFetchProfile,
  fetchProfileOk,
  fetchProfileError,
  fetchTwoFactorEnabledError,
  fetchTwoFactorEnabledOk,
  nukeTwoFactorOk,
  setupTwoFactorError,
  nukeTwoFactorError,
  startFetchTwoFactorEnabled,
  startNukeTwoFactor,
  startSetupTwoFactor,
  setupTwoFactorOk,
  startChangePassword,
  changePasswordError,
  changePasswordOk,
  startChangeLanguage,
  changeLanguageOk,
  changeLanguageError,
  wrongCurrentPasswordError,
} = userProfileSlice.actions

export function fetchProfile(): void {
  store.dispatch(startFetchProfile())
  Axios.get(`${dashboardServiceUrl}/api/v2/profile`, {
    params: {},
  }).then(
    (response: AxiosResponse) => {
      const profileData = response.data as ProfileData
      store.dispatch(fetchProfileOk(profileData))
    },
    (error: any) => {
      store.dispatch(fetchProfileError(toGenericError(error)))
    }
  )
}

export function saveLanguage(language: string): Promise<void> {
  store.dispatch(startChangeLanguage())
  const saveLanguagePromise = Axios.put(
    `${dashboardServiceUrl}/api/profile/language`,
    {
      language,
    },
    {
      params: {},
    }
  )
  return saveLanguagePromise.then(
    (response: AxiosResponse) => {
      const profileData = response.data as ProfileData
      store.dispatch(changeLanguageOk(profileData))
    },
    (error: any) => {
      store.dispatch(changeLanguageError(toGenericError(error)))
    }
  )
}
export function changePassword(request: ChangePasswordRequest): Promise<void> {
  store.dispatch(startChangePassword())
  const changePasswordPromise = Axios.put(`${dashboardServiceUrl}/api/profile/password`, request, {
    params: {},
  })
  return changePasswordPromise.then(
    (response: AxiosResponse) => {
      const profileData = response.data as ProfileData
      store.dispatch(changePasswordOk(profileData))
    },
    (error: AxiosError) => {
      if (error.response && error.response.status === 424) {
        store.dispatch(wrongCurrentPasswordError(toGenericError(error)))
      } else {
        store.dispatch(changePasswordError(toGenericError(error)))
      }
    }
  )
}

export function fetchTwoFactorEnabled(): Promise<void> {
  store.dispatch(startFetchTwoFactorEnabled())
  const fetchTwoFactorEnabledPromise = Axios.get(`${dashboardServiceUrl}/api/v2/profile/twoFactorEnabled`, {
    params: {},
  })
  return fetchTwoFactorEnabledPromise.then(
    (response: AxiosResponse) => {
      const twoFactorEnabled = response.data as boolean
      store.dispatch(fetchTwoFactorEnabledOk(twoFactorEnabled ? TwoFactorState.Enabled : TwoFactorState.Disabled))
    },
    (error: any) => {
      store.dispatch(fetchTwoFactorEnabledError(toGenericError(error)))
    }
  )
}
export function setupTwoFactor(twoFactorSetup: TwoFactorSetupPayload): Promise<boolean> {
  store.dispatch(startSetupTwoFactor())

  const setupTwoFactorPromise = Axios.post(`${dashboardServiceUrl}/api/v2/profile/setupTwoFactor`, twoFactorSetup, {
    params: {},
  })
  return setupTwoFactorPromise.then(
    () => {
      store.dispatch(setupTwoFactorOk())
      return true
    },
    (error: any) => {
      store.dispatch(setupTwoFactorError(toGenericError(error)))
      return false
    }
  )
}

export function setupMandatoryTwoFactor(payload: TwoFactorMandatorySetupPayload): Promise<boolean> {
  store.dispatch(startSetupTwoFactor())

  const setupTwoFactorPromise = Axios.post(`${loginServiceUrl}/api/2fa/setup_mandatory`, payload, {
    params: {},
  })
  return setupTwoFactorPromise.then(
    () => {
      store.dispatch(setupTwoFactorOk())
      return true
    },
    (error: any) => {
      store.dispatch(setupTwoFactorError(toGenericError(error)))
      return false
    }
  )
}

export function nukeTwoFactor(): Promise<void> {
  store.dispatch(startNukeTwoFactor())
  const nukeTwoFactorPromise = Axios.delete(`${dashboardServiceUrl}/api/v2/profile/nukeTwoFactor`, {
    params: {},
  })
  return nukeTwoFactorPromise.then(
    (response: AxiosResponse) => {
      store.dispatch(nukeTwoFactorOk())
    },
    (error: any) => {
      store.dispatch(nukeTwoFactorError(toGenericError(error)))
    }
  )
}

export function navigateToSecurityPage(page: SecurityPage): Promise<void> {
  navigateTo({ name: NavigationPage.userSecurity, params: { securityPage: page } })
  return fetchTwoFactorEnabled()
}

export default userProfileSlice.reducer
