import React from "react"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"
import { getThemeColorVar } from "../../shared/helper/color"

export const QuickstartHelp = () => {
  return (
    <div style={{ height: "100vh", overflow: "auto" }}>
      <div
        style={{
          padding: "32px 64px",
          display: "flex",
          gap: "16px",
          flexDirection: "column",
          lineHeight: "1.6",
          textAlign: "justify",
        }}
      >
        <h1 style={{ color: getThemeColorVar("primary", "default") }}>Quickstart</h1>
        <p>
          Der <i>Quickstart</i> ist die einfachste und schnellste Art, ein neues Assessment zu erstellen. Er steht im
          RELAS Menü an erster Stelle und kann von überall aus aufgerufen werden.
        </p>
        <FlexContainer direction={"column"} gap={4}>
          <img
            src="/assets/help/images-quickstart/image1.png"
            alt="Den Quickstart im Menü aufrufen"
            width={"15%"}
            height={"auto"}
          />
          <i>Abb: Den Quickstart im Menü aufrufen</i>
        </FlexContainer>
        <p>
          Nach dem Erstellen des Assessments können Sie die Lage/Einheit anhand von Scores, Ratings, Preisen, Renditen,
          Comparables, Points of Interest und vielen weiteren Zusatzinformationen auf mehreren Unterseiten begutachten
          und bewerten. Über den Menüeintrag Location Assessment können Sie auch später noch jederzeit auf das
          Assessment zugreifen.
        </p>
        <h2 style={{ color: getThemeColorVar("primary", "default") }}>Adresse</h2>
        <p>
          Alles, was für ein Assessment zunächst gebraucht wird, ist die Adresse. Schon während der Eingabe erhalten Sie
          im Dropdown passende Vorschläge, die Sie nur noch auswählen und evtl. ergänzen müssen. Alternativ können Sie
          die Adresse jederzeit durch einen Klick in die Karte festlegen. Sobald eine gültige Adresse vorliegt, lässt
          sich das Assessment starten.
        </p>
        <p>
          Falls Sie statt einer einzelnen Lage ein ganzes Portfolio bewerten wollen, haben Sie innerhalb des Location
          Assessments die Möglichkeit, eine Adressliste hochzuladen.
        </p>
        <FlexContainer direction={"column"} gap={4}>
          <img
            src="/assets/help/images-quickstart/image2.png"
            alt="Den Quickstart im Menü aufrufen"
            width={"50%"}
            height={"auto"}
          />
          <i>Abb: Der Quickstart</i>
        </FlexContainer>
        <h2 style={{ color: getThemeColorVar("primary", "default") }}>Einheit</h2>
        <p>
          Neben der Lage/Adresse können Sie bereits beim Erstellen des Assessments zusätzliche Angaben zur Einheit
          machen. Diese Angaben sind optional – wenn Sie nichts ändern, werden die Standardvorgaben übernommen (die
          später jederzeit geändert werden können).
        </p>
        <p>
          Rolle spielen diese Angaben lediglich bei der Berechnung der Einheit-Preise mittels Machine Learning und KI.
          Während z. B. für Scores und die Location-Preise nur die Lage ausschlaggebend ist, machen bei den Preisen für
          die Einheit auch deren Größe, Ausstattung und das Baujahr einen signifikanten Unterschied.
        </p>
        <ul>
          <li>
            Nutzungsart Über die vier verfügbaren Nutzungsarten legen Sie fest, wie die Einheit grundsätzlich genutzt
            wird.
          </li>
          <li>
            Bau- oder Sanierungsjahr Hier wird ein vierstelliger Wert zwischen 1700 und 2020 erwartet. Für Neubauten
            bzw. Bauvorhaben gibt es eine zusätzliche Option.
          </li>
          <li>
            Fläche Die Fläche bezieht sich auf die betrachtete Einheit, nicht auf das ganze (Mehrfamilien-)Haus.
            Zulässig sind Werte zwischen 1 und 99999, auch Kommawerte sind möglich (z.B. “63,5”).
          </li>
          <li>
            Exklusivität Hierbei geht es um die Ausstattung im Vergleich zum Standard in der Gegend. Mehr dazu im
            nächsten Abschnitt.
          </li>

          <li>
            Typ Für die Nutzungsart Wohnen lassen sich hier zwei Werte einstellen (für die anderen Nutzungsarten Büro
            und Einzelhandel gibt es diese Unterscheidung nicht):
            <ul>
              <li>Der Typ Wohnung meint die klassische Einheit innerhalb eines Mehrfamilienhauses.</li>
              <li>
                Der Typ Haus steht für Einfamilienhäuser, Doppelhaushälften und Reihenhäuser. Ein Objekt mit drei
                getrennten Etagen für drei Mietparteien wäre jedoch nicht vom Typ Haus, sondern würde sich aus drei
                Einheiten vom Typ Wohnung zusammensetzen.
              </li>
            </ul>
          </li>
        </ul>
        <h3>Exkurs: Exklusivität</h3>
        <p>
          Neben Bau-/Sanierungsjahr und Fläche ist die Exklusivität ein weiterer wichtiger Parameter zur Beschreibung
          einer Einheit und bei der Berechnung der Preise. Dabei geht es um die Ausstattung der Einheit bzw. des
          Objekts, in dem sich die Einheit befindet. Im Gegensatz zu den anderen Parametern ist die Exklusivität jedoch
          kein absolutes Merkmal, sondern immer relativ im Vergleich zur näheren Umgebung zu verstehen. Dadurch
          unterscheidet sie sich deutlich von der Sterne-Bewertung, wie man sie z.B. von Hotels gewohnt ist.
        </p>
        <p>
          Grundsätzlich wird die Ausstattung nicht anhand einzelner konkreter Merkmale (z.B. Balkon, Fußbodenheizung
          oder Aufzug) beurteilt, sondern in drei Klassen unterteilt:{" "}
        </p>
        <ol>
          <li>
            <b>Standard / 1 Stern</b>
            <br /> Die Einheit entspricht dem Standard, der innerhalb des Objekts und in der näheren Umgebung zu
            erwarten wäre. Wenn es um eine top ausgestattete Wohnung geht, alle anderen Wohnungen im Haus und in den
            Nachbarhäusern aber ebenfalls top sind, dann ist das in dieser Gegend der Standard und wird von der KI auch
            so bewertet.
          </li>
          <li>
            <b>Hoch / 2 Sterne </b>
            <br />
            In dieser Klasse setzt sich eine Einheit deutlich von der Umgebung ab. Das könnte z.B. das frisch sanierte
            Dachgeschoss in einem ansonsten zweitklassigen Bau sein. Oder der edle Neubau in einer Nachbarschaft aus
            gängigem Altbau.
          </li>
          <li>
            <b>Am höchsten / 3 Sterne</b>
            <br /> In dieser höchsten Klasse verfügt die Einheit im Vergleich zur Umgebung nicht nur über eine weit
            überdurchschnittliche Ausstattung, sondern kann auch mit Merkmalen punkten, die man so in dieser Gegend
            nicht erwarten würde. Das frisch sanierte Dachgeschoss aus dem letzten Abschnitt hätte dann noch einen
            eigenen Dachgarten, der edle Neubau einen gemeinschaftlichen Rooftop-Pool.
          </li>
        </ol>
        <div>
          Zu beachten ist bei der Exklusivität auch der Zusammenhang mit den anderen Parametern, denn die Unterteilung
          in drei Klassen ist nicht global, sondern findet jeweils für eine Kombination aus Fläche und Baujahr statt.
          Bei der Größe gelten durchaus unterschiedliche Standards, je nachdem, ob es sich z.B. um ein kleines
          Mikroapartment oder um eine große Familienwohnung handelt. Im Vergleich zu Altbau/Bestandsbauten bringen
          Neubauten von sich aus schon einen gehobenen Standard mit. Die KI rechnet alsobereits aufgrund des Baujahres
          mit höheren Preisen. Die Exklusivität kann man daher bei gewöhnlichen Neubauten bei einem Stern belassen, auch
          wenn die Ausstattung die der Bestandsbauten übertrifft. Wenn man die Umgebung nicht kennt, ist es natürlich
          schwierig, die betrachtete Einheit in Relation zu setzen. In diesem Fall ist man auf der sicheren Seite, wenn
          man die Exklusivität bei Standard belässt. Weitere Informationen zur Exklusivität und grundsätzlich zur
          Berechnung der Lage- und Einheit-Preise finden Sie in der ausführlichen Hilfe zum Price-Widget.
        </div>
      </div>
    </div>
  )
}
