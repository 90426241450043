import * as React from "react"
import { css } from "emotion"
import { IconName } from "@21re/revoicons/dist/icon-type"
import { bind } from "decko"
import { PropsWithChildren } from "react"
import { BareSidePanel, BareSidePanelProps } from "./baresidepanel"
import HorizontalForm from "./horizontalform"
import FlexContainer from "./restyle-grid/flexcontainer"
import Button from "./button"
import { getThemeColorVar } from "../helper/color"
import Icon from "./icon"

const sidePanelTitleClass = css({
  marginTop: "24px",
  marginBottom: "48px",
})

const sidePanelMainTitleClass = css({
  color: getThemeColorVar("typo", "dark"),
  fontSize: "30px",
  lineHeight: "30px",
  fontWeight: 300,
})

const sidePanelSubtitleClass = css({
  color: getThemeColorVar("typo", "light"),
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: 400,
  marginTop: "24px",
  whiteSpace: "pre-wrap",
})

const sidePanelCloseButtonClass = css({
  cursor: "pointer",
  textAlign: "right",
  minHeight: "20px",
})

export interface SidePanelAction {
  label: string
  icon?: IconName
  disabled?: boolean
  action: () => void
}

export interface Props extends BareSidePanelProps {
  title: string
  subtitle?: string
  actions?: SidePanelAction[]
}

export class SidePanel extends React.Component<PropsWithChildren<Props>, {}> {
  render() {
    const { children, actions, opened, sticky, title, subtitle, onClose } = this.props
    return (
      <BareSidePanel sticky={sticky} opened={opened} onClose={onClose}>
        <HorizontalForm
          padding={[24, 24, 56, 24]}
          header={
            <FlexContainer direction="column">
              <div className={sidePanelCloseButtonClass} onClick={this.handleCloseButton}>
                <Icon name="close" color="primary" colorType="default" />
              </div>
              <div className={sidePanelTitleClass}>
                <div className={sidePanelMainTitleClass}>{title}</div>
                {subtitle && <div className={sidePanelSubtitleClass}>{subtitle}</div>}
              </div>
            </FlexContainer>
          }
          actionBar={
            <FlexContainer direction="row-reverse" gap={16} padding={[24, 0, 0, 0]}>
              {actions &&
                actions.map((action, idx) =>
                  idx === 0 ? (
                    <Button
                      type="primary"
                      formType="submit"
                      key={idx}
                      onClick={action.action}
                      icon={action.icon}
                      disabled={action.disabled}
                    >
                      {action.label}
                    </Button>
                  ) : (
                    <Button
                      type="secondary"
                      formType="button"
                      key={idx}
                      onClick={action.action}
                      icon={action.icon}
                      disabled={action.disabled}
                    >
                      {action.label}
                    </Button>
                  )
                )}
            </FlexContainer>
          }
        >
          {children}
        </HorizontalForm>
      </BareSidePanel>
    )
  }

  @bind
  private handleCloseButton(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault()
    event.stopPropagation()
    this.props.onClose && this.props.onClose()
  }
}

export default SidePanel
