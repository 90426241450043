import * as React from "react"
import { PropsWithChildren } from "react"
import { translations } from "../i18n"
import { LanguageSwitcher } from "./language-switcher"
import { css } from "emotion"
import RevoCard from "../../shared/components/revo-card"
import Grid from "../../shared/components/restyle-grid/grid"
import GridItem from "../../shared/components/restyle-grid/griditem"

type Link = {
  link: string
  translation: string
}

const imageBackground = "https://assets.21real.estate/background.png"

const styles = {
  background: css({
    backgroundImage: `url(${imageBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "50% bottom",
    backgroundRepeat: "no-repeat",
    height: "100vh",
  }),
}

export const UnloggedFormWrapper = (props: PropsWithChildren<{ title: string; footerLinks?: JSX.Element }>) => {
  const translate = React.useMemo(() => translations(), [])
  const links: Link[] = [
    { link: translate.links.relativeImprintLink, translation: translate.links.imprint },
    { link: translate.links.relativePrivacyLink, translation: translate.links.privacy },
    { link: translate.links.relativeTermsLink, translation: translate.links.terms },
  ]

  return (
    <div className={styles.background}>
      <Grid
        columns={3}
        rowSpec={[
          [20, "px"],
          [1, "fr"],
          [20, "px"],
        ]}
        height={[100, "vh"]}
        paddingX="xxl"
        paddingY="xxl"
      >
        <GridItem alignSelf="start" justifySelf="start">
          <a href="/">
            <img alt="logo" width="90px" src="https://assets.21real.estate/21_logo_without_padding.svg" />
          </a>
        </GridItem>

        <GridItem alignSelf="start" justifySelf="end" colSpan={2}>
          <LanguageSwitcher />
        </GridItem>

        <GridItem colSpan={3} justifySelf="center" alignSelf="center">
          <div className="re-login-container">
            <RevoCard footer={props.footerLinks} padding={[24, 0]}>
              <h1 style={{ padding: "0 24px 20px 24px" }}>{props.title}</h1>
              <div id="loadingDimmerLoginPage">{props.children}</div>
            </RevoCard>
          </div>
        </GridItem>

        <GridItem colSpan={3} alignSelf="end" justifySelf="end">
          {links
            .map((link, index) => (
              <a key={index} href={`https://21re.de/${link.link}`}>
                {link.translation}
              </a>
            ))
            .map((link, index, arr) => (index === arr.length - 1 ? link : [link, " | "]))}
        </GridItem>
      </Grid>
    </div>
  )
}
