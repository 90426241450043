import * as React from "react"
import { createPortal } from "react-dom"
import { PropsWithChildren } from "react"

// Similiar to the Portal component, but attaches to the revo-content element instead of the document body
export class ContentPortal extends React.Component<PropsWithChildren<{}>, {}> {
  private portalNode: Element | null = null
  private rootNode = document.getElementById("revo-content") || document.body

  componentWillUnmount() {
    if (this.portalNode) {
      this.rootNode && this.rootNode.removeChild(this.portalNode)
    }
    this.portalNode = null
  }

  render(): React.ReactPortal {
    if (!this.portalNode) {
      this.portalNode = document.createElement("div")
      this.rootNode && this.rootNode.appendChild(this.portalNode)
    }
    return createPortal(this.props.children, this.portalNode)
  }
}

export default ContentPortal
