export class GraphScale {
  private min: number = 100000
  private max: number = 0
  private offset: number
  private width: number

  constructor(values: number[], offset: number, width: number) {
    this.offset = offset
    this.width = width
    this.min = Math.min(...values)
    this.max = Math.max(...values)
  }

  getPositionOnScale(value: number): number {
    return (this.width * (value - this.min)) / (this.max - this.min) + this.offset
  }
}
