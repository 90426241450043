import * as React from "react"
import { IconName } from "@21re/revoicons/dist/icon-type"
import { RevoMenuItemButton } from "./revo-menu-item-button"
import { Services } from "./models/services"
import { CookieScopeRequirement, MenuScopeRequirement } from "./models/scoperequirements"
import { betterServiceUrl } from "./util/serviceurl"
import { menuItemShouldBeShown } from "./util/scorefromcookies"
import { cx } from "emotion"
import RevoTooltip from "../shared/components/revo-tooltip"
import FlexContainer from "../shared/components/restyle-grid/flexcontainer"

interface Props {
  icon?: IconName
  active?: boolean
  text: string
  service?: Services
  tooltip?: string
  path?: string
  absolutePath?: boolean
  onClick?: () => void
  scopeRequirement?: MenuScopeRequirement
  logo?: boolean
  module?: string
  scopes: Array<CookieScopeRequirement>
  isImpersonating: boolean
}

export const RevoMainMenuItem = ({
  onClick,
  icon,
  text,
  active,
  service,
  path,
  absolutePath,
  scopeRequirement,
  logo,
  scopes,
  tooltip,
  isImpersonating,
  module,
}: Props) => {
  const decideClickAction = (e: React.MouseEvent) => {
    if (onClick) {
      e.preventDefault()
      e.stopPropagation()
      onClick()
    }
  }

  const classes = module ? ["revoMainMenu__menuItemModule"] : ["revoMainMenu__menuItem"]
  active && classes.push(module ? "revoMainMenu__menuItemModule--active" : "revoMainMenu__menuItem--active")

  if (menuItemShouldBeShown(scopeRequirement, scopes)) {
    return (
      <a href={absolutePath ? path : betterServiceUrl(service, path)} onClick={decideClickAction}>
        <div className={cx(classes)}>
          {(tooltip && (
            <RevoTooltip text={tooltip} position="right">
              <RevoMenuItemButton icon={icon ?? undefined} onClick={() => null} isImpersonating={isImpersonating} />
            </RevoTooltip>
          )) || <RevoMenuItemButton icon={icon ?? undefined} onClick={() => null} isImpersonating={isImpersonating} />}
          <div className={"revoMenuItem__text"}>
            <FlexContainer md-justify="spaceBetween">
              {logo ? (
                <div className="revoMenuItem__logoTop">
                  <img src="/assets/21_logo.svg" alt="21st RE LOGO" />
                </div>
              ) : (
                <span style={{ paddingLeft: "0px" }}>{text}</span>
              )}
            </FlexContainer>
          </div>
        </div>
      </a>
    )
  } else {
    return null
  }
}
