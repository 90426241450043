import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  AllowedModules,
  DeleteData,
  DeleteMode,
  DeleteModes,
  DeleteSelection,
  initialPrivateDataModuleSettings,
  MultipleCategoryPOIsResponse,
  PoiFormDataErrors,
  PoisToShowData,
  PrivateDataModuleSettings,
  PrivateDataModuleSettingsCollection,
  PrivatePOICategoriesList,
  PrivatePOICategory,
  PrivatePOICategoryToCreateOrEdit,
  PrivatePOIList,
  PrivatePOIToCreate,
  PrivatePOIToEdit,
  ToDeleteSelection,
  UpdatePrivatePOICategoriesListResponse,
} from "../models/private-data"
import { store } from "../../relas/store"
import Axios, { AxiosError, AxiosResponse } from "axios"
import { lanaApiUrl } from "../../app_config"
import {
  ASSESSMENT_ENTRY_CHANGED_MODULE,
  AssessmentEntryChangedPayload,
} from "../../assessment/actions/assessment-module-action-creators"
import { GenericError, toGenericError } from "../../shared/helper/axios"

export interface PrivateDataState {
  privatePOICategories: PrivatePOICategoriesList
  privatePOICategoriesLoading: boolean
  privatePOICategoriesError: GenericError | null
  privatePOIList: PrivatePOIList
  privatePOIListLoading: boolean
  privatePOIListError: GenericError | null
  privatePOIAddressListUploadLoading: boolean
  privatePOIAddressListUploadError: GenericError | null
  selectedCategory: PrivatePOICategory | undefined
  deleteMode: DeleteMode
  deleteSelection: DeleteSelection
  privatePoiCreateOrEditLoading: boolean
  privatePOICreateOrEditError: PoiFormDataErrors | null
  deleteInProgress: boolean
  deleteError: GenericError | null
  poisDeleted: boolean
  poisToShow: PrivatePOIList
  modulesWithPrivateData: PrivateDataModuleSettingsCollection
}

export const initialState: PrivateDataState = {
  selectedCategory: undefined,
  privatePOICategories: [],
  privatePOICategoriesLoading: false,
  privatePOICategoriesError: null,
  privatePOIAddressListUploadLoading: false,
  privatePOIAddressListUploadError: null,
  privatePOIList: [],
  poisToShow: [],
  privatePOIListLoading: false,
  privatePOIListError: null,
  deleteMode: undefined,
  deleteSelection: undefined,
  deleteInProgress: false,
  deleteError: null,
  poisDeleted: false,
  privatePoiCreateOrEditLoading: false,
  privatePOICreateOrEditError: null,

  modulesWithPrivateData: {
    comparables: initialPrivateDataModuleSettings(),
    poiExplorer: initialPrivateDataModuleSettings(),
    specialMaps: initialPrivateDataModuleSettings(),
    districtData: initialPrivateDataModuleSettings(),
    profileManager: initialPrivateDataModuleSettings(),
    ratingManager: initialPrivateDataModuleSettings(),
    ratings: initialPrivateDataModuleSettings(),
    locationSelector: initialPrivateDataModuleSettings(),
    ratingsScorePrices: initialPrivateDataModuleSettings(),
    quickStart: initialPrivateDataModuleSettings(),
    fundamentalData: initialPrivateDataModuleSettings(),
  },
}

const privateDataSlice = createSlice({
  name: "privateData",
  initialState,
  reducers: {
    fetchCategoriesStart(state) {
      state.privatePOICategories = []
      state.privatePOICategoriesError = null
      state.privatePOICategoriesLoading = true
    },
    fetchCategoriesDone(state, action: PayloadAction<PrivatePOICategoriesList>) {
      state.privatePOICategories = action.payload

      const categoriesSet = new Set(action.payload.map((category) => category.id))

      Object.values(state.modulesWithPrivateData).map((moduleSettings: PrivateDataModuleSettings) => {
        Object.keys(moduleSettings.multipleCategoryPOIList).map((categoryID) => {
          if (!categoriesSet.has(categoryID)) {
            delete moduleSettings.multipleCategoryPOIList[categoryID]
          }
        })
      })

      state.privatePOICategoriesLoading = false
    },
    fetchCategoriesError(state, action: PayloadAction<GenericError>) {
      state.privatePOICategories = []
      state.privatePOICategoriesError = action.payload
      state.privatePOICategoriesLoading = false
    },
    fetchPOIsStart(state) {
      state.privatePOIList = []
      state.privatePOIListError = null
      state.privatePOIListLoading = true
    },
    fetchPOIsDone(state, action: PayloadAction<{ pois: PrivatePOIList; categoryId: string }>) {
      state.privatePOIList = action.payload.pois
      Object.values(state.modulesWithPrivateData).map((moduleSettings: PrivateDataModuleSettings) => {
        Object.keys(moduleSettings.multipleCategoryPOIList).map((cid) => {
          if (cid === action.payload.categoryId) {
            moduleSettings.multipleCategoryPOIList[cid] = action.payload.pois
          }
        })
      })

      state.privatePOIListLoading = false
      state.privatePOIListError = null
      state.poisDeleted = false
    },
    fetchPOIsError(state, action: PayloadAction<GenericError>) {
      state.privatePOIList = []
      state.privatePOIListError = action.payload
      state.privatePOIListLoading = false
    },
    fetchModulePOIListItemDone(state, action: PayloadAction<MultipleCategoryPOIsResponse>) {
      const module: AllowedModules = action.payload.module
      const data = action.payload.data
      const oldStateOfModules = JSON.parse(JSON.stringify(state.modulesWithPrivateData))
      const oldModuleState = { ...oldStateOfModules[module] }
      const oldMultipleCategories = { ...oldModuleState.multipleCategoryPOIList }
      const newMultipleCategoryPois = Object.assign(oldMultipleCategories, data)

      const newPrivateDataSettings = {
        ...oldModuleState,
        multipleCategoryPOIList: newMultipleCategoryPois,
      }
      state.privatePOIListLoading = false
      state.privatePOIListError = null
      state.modulesWithPrivateData[module] = newPrivateDataSettings
    },
    updateSelectedCategoriesDone(state, action: PayloadAction<UpdatePrivatePOICategoriesListResponse>) {
      const module = action.payload.module
      const categories = action.payload.categories
      const oldStateOfModules = JSON.parse(JSON.stringify(state.modulesWithPrivateData))
      const oldModuleState = { ...oldStateOfModules[module] }
      const newCategoryIDList = new Set([...categories.map((category) => category.id)])
      const newMultiplePoiList = Object.fromEntries(
        Object.entries(oldModuleState.multipleCategoryPOIList).filter(([categoryID]) =>
          newCategoryIDList.has(categoryID)
        )
      )
      state.modulesWithPrivateData[module] = {
        ...oldModuleState,
        selectedCategories: categories,
        multipleCategoryPOIList: newMultiplePoiList,
      }
    },
    updateSelectedCategoryDone(state, action: PayloadAction<PrivatePOICategory | undefined>) {
      state.selectedCategory = action.payload
      state.privatePOIList = action.payload === undefined ? [] : state.privatePOIList
    },

    addressListUploadStart(state) {
      state.privatePOIAddressListUploadLoading = true
      state.privatePOIAddressListUploadError = null
    },

    addressListUploadCancel(state) {
      state.privatePOIAddressListUploadLoading = false
      state.privatePOIAddressListUploadError = null
    },
    addressListUploadDone(state) {
      state.privatePOIAddressListUploadLoading = false
      state.privatePOIAddressListUploadError = null
    },
    addressListUploadError(state, action: PayloadAction<GenericError>) {
      state.privatePOIAddressListUploadLoading = false
      state.privatePOIAddressListUploadError = action.payload
    },
    deleteOpen(state, action: PayloadAction<DeleteData>) {
      state.deleteMode = action.payload.mode
      state.deleteSelection = action.payload.selection
    },
    deleteStart(state) {
      state.deleteInProgress = true
    },
    deleteCancel(state) {
      state.deleteInProgress = false
      state.deleteSelection = undefined
      state.deleteMode = undefined
    },
    deleteError(state, action: PayloadAction<GenericError>) {
      state.deleteInProgress = false
      state.deleteMode = undefined
      state.deleteSelection = undefined
      state.deleteError = action.payload
    },
    categoryDeleteDone(state) {
      state.deleteInProgress = false
      state.deleteSelection = undefined
      state.deleteMode = undefined
      state.selectedCategory = undefined
      state.privatePOIList = []
    },
    poiDeleteDone(state) {
      state.deleteInProgress = false
      state.deleteSelection = undefined
      state.deleteMode = undefined
      state.poisDeleted = true
      state.privatePOIList = []
    },
    poiCreateOrEditStart(state) {
      state.privatePoiCreateOrEditLoading = true
      state.privatePOICreateOrEditError = null
    },
    poiCreateOrEditDone(state) {
      state.privatePoiCreateOrEditLoading = false
      state.privatePOICreateOrEditError = null
    },
    poiCreateOrEditError(state, action: PayloadAction<GenericError>) {
      state.privatePoiCreateOrEditLoading = false
      state.privatePOICreateOrEditError = action.payload
    },
    updatePOIsToShowDone(state, action: PayloadAction<PoisToShowData>) {
      const module = action.payload.module
      const poiList = action.payload.poiList
      if (module) {
        const oldStateOfModules = JSON.parse(JSON.stringify(state.modulesWithPrivateData))
        const oldModuleState = { ...oldStateOfModules[module] }
        state.modulesWithPrivateData[module] = {
          ...oldModuleState,
          poisToShow: poiList,
        }
      } else {
        state.poisToShow = poiList
      }
    },
  },
  extraReducers: {
    [ASSESSMENT_ENTRY_CHANGED_MODULE]: (state, action: PayloadAction<AssessmentEntryChangedPayload>) => {
      if (action.payload.newId !== action.payload.oldId) {
        return initialState
      } else {
        return state
      }
    },
  },
})

const {
  fetchCategoriesStart,
  fetchCategoriesError,
  fetchCategoriesDone,
  categoryDeleteDone,
  fetchPOIsStart,
  fetchPOIsError,
  fetchPOIsDone,
  poiDeleteDone,
  fetchModulePOIListItemDone,
  addressListUploadDone,
  addressListUploadError,
  addressListUploadCancel,
  addressListUploadStart,
  deleteStart,
  poiCreateOrEditDone,
  poiCreateOrEditError,
  poiCreateOrEditStart,
  updatePOIsToShowDone,
  deleteCancel,
  updateSelectedCategoryDone,
  updateSelectedCategoriesDone,
  deleteError,
  deleteOpen,
} = privateDataSlice.actions

export function fetchCategories(): Promise<PrivatePOICategory[]> {
  store.dispatch(fetchCategoriesStart())
  return Axios.get<PrivatePOICategory[]>(`${lanaApiUrl}/api/v1/user_pois/categories`).then(
    (response) => {
      const sortedPOICategoriesList = response.data.sort((a: PrivatePOICategory, b: PrivatePOICategory) =>
        a.title.localeCompare(b.title)
      )
      store.dispatch(fetchCategoriesDone(sortedPOICategoriesList))
      return sortedPOICategoriesList
    },
    (error: AxiosError) => {
      store.dispatch(fetchCategoriesError(toGenericError(error)))
      throw error
    }
  )
}

export function updateSelectedCategory(category: PrivatePOICategory | undefined) {
  store.dispatch(updateSelectedCategoryDone(category))
}

export function createOrEditCategory(data: PrivatePOICategoryToCreateOrEdit): Promise<void> {
  const getUrl = () =>
    data.editedCategory
      ? `${lanaApiUrl}/api/v1/user_pois/categories/${data.editedCategory.id}`
      : `${lanaApiUrl}/api/v1/user_pois/categories`
  if (data.editedCategory) {
    return Axios.put(getUrl(), data.categoryData)
  } else {
    return Axios.post(getUrl(), data.categoryData).then((response: AxiosResponse) =>
      updateSelectedCategory(response.data as PrivatePOICategory)
    )
  }
}

export function cleanPoiCreateOrEditErrors(): void {
  store.dispatch(poiCreateOrEditDone())
}

export function updateSelectedCategories(categories: PrivatePOICategoriesList, module: AllowedModules): void {
  store.dispatch(updateSelectedCategoriesDone({ module, categories }))
}

function fetchPOIs(categoryId: string, module?: AllowedModules): Promise<void> {
  store.dispatch(fetchPOIsStart())
  return Axios.get<PrivatePOIList>(
    `${lanaApiUrl}/api/v1/user_pois/categories/${encodeURIComponent(categoryId)}/pois`
  ).then((response) => {
    const pois: PrivatePOIList = response.data
    {
      if (module) {
        store.dispatch(
          fetchModulePOIListItemDone({
            module,
            data: { [categoryId]: pois },
          })
        )
      } else {
        store.dispatch(fetchPOIsDone({ pois, categoryId }))
      }
    }
  })
}

export async function fetchCategoryPois(categoryId: string, module?: AllowedModules): Promise<void> {
  try {
    return await fetchPOIs(categoryId, module)
  } catch (error) {
    store.dispatch(fetchPOIsError(toGenericError(error)))
  }
}

export async function createPrivatePoi(data: PrivatePOIToCreate): Promise<boolean> {
  store.dispatch(poiCreateOrEditStart())
  const { categoryId } = data

  const url = `${lanaApiUrl}/api/v1/user_pois/categories/${encodeURIComponent(categoryId)}/pois`

  try {
    await Axios.post(url, data.poiData)

    store.dispatch(poiCreateOrEditDone())
    await fetchCategoryPois(categoryId)
    return true
  } catch (error) {
    const mapped = toGenericError(error)
    store.dispatch(poiCreateOrEditError(mapped))
    return false
  }
}

export async function editPrivatePoi(data: PrivatePOIToEdit): Promise<boolean> {
  store.dispatch(poiCreateOrEditStart())
  const { categoryId, poiId } = data
  const url = `${lanaApiUrl}/api/v1/user_pois/pois/${encodeURIComponent(poiId)}`

  try {
    await Axios.put(url, data.poiData)
    store.dispatch(poiCreateOrEditDone())
    await fetchCategoryPois(categoryId)
    return true
  } catch (error) {
    const mapped = toGenericError(error)
    store.dispatch(poiCreateOrEditError(mapped))
    return false
  }
}

export async function uploadPrivateDataPOIs(categoryId: string, file: File): Promise<boolean> {
  const formData = new FormData()

  formData.append("file", file)
  store.dispatch(addressListUploadStart())

  try {
    await Axios.post(`${lanaApiUrl}/api/v1/user_pois/categories/${encodeURIComponent(categoryId)}/pois`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })

    store.dispatch(addressListUploadDone())
    await fetchCategoryPois(categoryId)
    return true
  } catch (error) {
    let mapped = toGenericError(error)
    if (typeof error.response?.data.details === "object") {
      mapped = {
        ...mapped,
        ...error.response.data.details,
      }
    }
    store.dispatch(addressListUploadError(mapped))
    if (Axios.isAxiosError(error) && error.response?.status === 400) {
      await fetchCategoryPois(categoryId)
    }
    return false
  }
}

export function cancelUploadAddressList() {
  store.dispatch(addressListUploadCancel())
}

export function openDelete(mode: DeleteMode, selection: DeleteSelection): void {
  store.dispatch(deleteOpen({ mode, selection }))
}

export function closeDelete(): void {
  store.dispatch(deleteCancel())
}

export function deletePrivateData(mode: DeleteModes, selection: ToDeleteSelection): Promise<void> {
  store.dispatch(deleteStart())
  switch (mode) {
    case DeleteModes.CATEGORY:
      return Axios.delete(`${lanaApiUrl}/api/v1/user_pois/categories/${(selection as PrivatePOICategory).id}`).then(
        (_) => {
          store.dispatch(categoryDeleteDone())
          void fetchCategories()
        },
        (error: AxiosError) => {
          store.dispatch(deleteError(toGenericError(error)))
        }
      )
    case DeleteModes.POI:
      const poisToDelete = {
        ids: selection as string[],
      }
      return Axios.delete(`${lanaApiUrl}/api/v1/user_pois/pois`, { data: poisToDelete }).then(
        async (_) => {
          store.dispatch(poiDeleteDone())
        },
        (error: AxiosError) => {
          store.dispatch(deleteError(toGenericError(error)))
        }
      )
  }
}

export function updatePOIsToShow(poiList: PrivatePOIList, module?: AllowedModules): void {
  store.dispatch(updatePOIsToShowDone({ module, poiList }))
}

export default privateDataSlice.reducer
