import * as React from "react"
import { CSSProperties, useState } from "react"
import { css, cx } from "emotion"
import { Grid } from "./ui/grid"
import { getThemeColorVar } from "../helper/color"
import Icon from "./icon"

export interface Props {
  header: string
  id?: string
  isOpen: boolean
  onClick?: () => void
}

const expandableClass = css({
  backgroundColor: "white",
  display: "block",
  paddingLeft: "15px",
  paddingRight: "15px",
  paddingTop: "15px",
  borderRadius: "3px",
  border: `1px solid ${getThemeColorVar("border", "default")}`,
})

const headerClass = css({
  cursor: "pointer",
  borderBottom: `1px solid ${getThemeColorVar("border", "default")}`,
  backgroundColor: getThemeColorVar("white", undefined),
  color: getThemeColorVar("primary", "default"),
  marginLeft: "-15px",
  marginTop: "-15px",
  marginRight: "-15px",
  padding: "10px 15px",
  fontWeight: 600,
})

const contentClass = css({
  marginLeft: "-15px",
  marginRight: "-15px",
  overflow: "hidden",
  transition: "max-height 0.4s",
})

const headlineClass = css({
  fontSize: "16px",
  lineHeight: "25px", // this should match the height of a small button
})

const arrowClass = css({
  color: getThemeColorVar("primary", "default"),
  fontSize: "27px",
  lineHeight: "25px", // this should match the height of a small button
  transition: "transform 0.4s",
})

const arrowExpandedClass = css({
  transform: "rotateZ(180deg)",
})

export const Expandable = (props: React.PropsWithChildren<Props>) => {
  const { header, id, isOpen } = props

  const [isOpenState, setIsOpenState] = useState(isOpen)

  const arrowClasses = [arrowClass]

  const isExpandableOpen = props.onClick ? isOpen : isOpenState

  isExpandableOpen && arrowClasses.push(arrowExpandedClass)

  const contentStyle: CSSProperties = isExpandableOpen
    ? {}
    : {
        maxHeight: 0,
      }

  const onClick = () => {
    if (props.onClick) {
      props.onClick()
    } else {
      setIsOpenState(!isOpenState)
    }
  }

  return (
    <div className={cx(expandableClass)} id={id}>
      <div onClick={onClick} className={cx(headerClass)}>
        <Grid columnSpec="1fr fit-content(100%)">
          <h1 className={cx(headlineClass)}>{header}</h1>
          <div className={cx(arrowClasses)}>
            <Icon name="arrow_drop_up"></Icon>
          </div>
        </Grid>
      </div>
      <div className={cx(contentClass)} style={contentStyle}>
        {props.children}
      </div>
    </div>
  )
}

export default Expandable
