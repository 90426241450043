import React from "react"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"
import { getThemeColorVar } from "../../shared/helper/color"

export const DashboardHelp = () => {
  return (
    <div style={{ height: "100vh", overflow: "auto" }}>
      <div
        style={{
          padding: "32px 64px",
          display: "flex",
          gap: "16px",
          flexDirection: "column",
          lineHeight: "1.6",
          textAlign: "justify",
        }}
      >
        <h1 style={{ color: getThemeColorVar("primary", "default"), paddingBottom: "12px" }}>Dashboard</h1>
        <h2 style={{ color: getThemeColorVar("primary", "default") }}>Übersicht</h2>
        <p>
          Das Dashboard ist unsere Einstiegsseite für jedes Assessment. Hier finden Sie die wichtigsten Informationen
          auf einen Blick. Mit relevanten Karten, Tabellen, Graphen und Zahlen wollen wir Ihnen dabei helfen, einen
          möglichst schnellen und zuverlässigen Eindruck der Lage zu gewinnen.
        </p>
        <p>
          Im Menü finden Sie das Dashboard als ersten Eintrag unter dem Location Assessment. Auch wenn Sie über den{" "}
          <i>Quickstart</i> ein neues Assessment erstellen, gelangen Sie automatisch zum Dashboard.
        </p>
        <p>
          Die Informationen sind in einzelne <b>Widgets</b> unterteilt (z. B. Mikro Scores, Mietspiegel oder Ratings).
          Meist stellen diese Widgets nur einen kleinen Ausschnitt der verfügbaren Daten dar. Durch einen Klick auf die
          Titelzeile gelangen Sie in das entsprechende Modul, wo die Daten tiefgehender visualisiert und analysiert
          werden können.
        </p>
        <p>
          Über das <b>Download</b> Icon oben rechts im Header lässt sich das aktuell angezeigte Dashboard jederzeit als
          PDF laden und extern weiterverwenden (z. B. zum Drucken oder Verteilen).
        </p>
        <p>
          Das Dashboard ist nicht statisch, sondern kann Ihren Anforderungen entsprechend angepasst werden. Es lassen
          sich mehrere Dashboards erzeugen und die Widgets beliebig konfigurieren.
        </p>
        <FlexContainer direction={"column"} gap={4}>
          <img
            src="/assets/help/images-dashboard/image1.png"
            alt="Das Dashboard mit seinen Widgets"
            width={"50%"}
            height={"auto"}
          />
          <i>Abb: Das Dashboard mit seinen Widgets</i>
        </FlexContainer>
        <h2 style={{ color: getThemeColorVar("primary", "default") }}>Multi-Dashboards</h2>
        <p>
          Für unterschiedliche Zwecke kann es sinnvoll sein, jeweils passende Dashboards zu erstellen. Zum Beispiel
          könnten Sie zwei Dashboards für <i>Wohnen</i> und <i>Office</i> erstellen mit angepassten Widgets, die die
          jeweils relevanten Daten zeigen. Alle Dashboards und ihre Widgets werden pro User gespeichert. So kann sich
          jeder Anwender für seine Zwecke die passenden Dashboards erstellen.
        </p>
        <p>
          Die Steuerung der Dashboards erfolgt innerhalb des <i>Dashboard Dropdowns</i> oben links im Header.
          Standardmäßig wird hier der Name des gerade aktuellen Dashboards angezeigt. Im geöffneten Dropdown werden alle
          verfügbaren Dashboards aufgelistet.
        </p>
        <FlexContainer direction={"column"} gap={4}>
          <img
            src="/assets/help/images-dashboard/image3.png"
            alt="Das Dashboard Dropdown"
            width={"25%"}
            height={"auto"}
          />
          <i>Abb: Das Dashboard Dropdown</i>
        </FlexContainer>

        <p>
          Hier lassen sich Dashboards erstellen, duplizieren, löschen oder einfach als neues aktuelles Dashboard
          auswählen
        </p>
        <ul>
          <li>
            Zum <b>Erstellen</b> eines neuen Dashboards klicken Sie unten im Dropdown auf das Plus-Icon und geben den
            gewünschten Namen ein.
          </li>
          <li>
            Soll ein neues Dashboard auf Basis eines bereits existierenden Dashboards erstellt werden, gelingt dies über
            das <b>Duplizieren</b> Icon.
          </li>
          <li>
            Über das <b>Löschen</b> Icon lässt sich ein Dashboard wieder aus der Liste entfernen.
          </li>
          <li>Ein Klick auf eines der Dashboards wählt dieses als aktuelles Dashboard aus.</li>
        </ul>
        <h2 style={{ color: getThemeColorVar("primary", "default") }}>Widgets konfigurieren</h2>
        <p>
          Sie können die einzelnen Widgets fast beliebig konfigurieren. Anpassen lassen sich die Inhalte der Widgets
          sowie deren Layout (Größe und Position).
        </p>
        <h3>Inhalte</h3>
        <p>
          Im Dashboard Dropdown finden Sie hinter jedem Eintrag mehrere Icons - über das erste aktivieren Sie den Modus
          für die Konfiguration der Inhalte. Daraufhin erscheinen in den Widgets kleine Icons zum <i>Löschen</i> und{" "}
          <i>Konfigurieren</i> sowie auf freien Plätzen ein Icon zum <i>Hinzufügen</i> neuer Widgets. Um den
          Bearbeiten-Modus zu verlassen, klicken Sie einfach hinter dem Dropdown auf den blauen Haken - alle Änderungen
          werden automatisch gespeichert.
        </p>
        <FlexContainer direction={"column"} gap={4}>
          <img
            src="/assets/help/images-dashboard/image2.png"
            alt="Widgets löschen, hinzufügen und konfigurieren"
            width={"35%"}
            height={"auto"}
          />
          <i>Abb: Widgets löschen, hinzufügen und konfigurieren</i>
        </FlexContainer>
        <p>
          Beim <b>Löschen</b> eines Widgets wird dieses vom Dashboard entfernt und der Platz freigegeben. Über das{" "}
          <b>Hinzufügen</b> Icon öffnet sich eine Liste mit allen verfügbaren Widgets - klicken Sie eines davon an, wird
          es an dieser Stelle dem Dashboard hinzugefügt.
        </p>
        <p>
          Das Icon zum <b>Konfigurieren</b> wird nur in solchen Widgets gezeigt, deren Inhalt angepasst werden kann.
          Nach einem Klick öffnet sich rechts eine Sidebar mit den jeweiligen Optionen. So können Sie z. B. für das POI
          Widget festlegen, welche der vielen verfügbaren POIs angezeigt werden sollen. Über den Button <b>Standard</b>{" "}
          kommen Sie immer wieder zurück zu den Standardeinstellungen.
        </p>
        <h3>Layout (Position & Größe)</h3>
        <p>
          Innerhalb des Dashboard Dropdowns lässt sich über das zweite Icon hinter jedem Eintrag der Bearbeiten-Modus
          für das Layout aktivieren. Auch diesen Modus verlassen Sie wieder, indem Sie das blaue Icon hinter dem
          Dropdown klicken.
        </p>
        <p>
          Während Sie sich im Bearbeiten-Modus befinden, können Sie innerhalb eines Widgets klicken und das Widget
          mittels Drag & Drop an die gewünschte Stelle <b>verschieben</b>. Über einen Klick auf die rechte untere Ecke
          lässt sich die <b>Größe</b> des Widgets anpassen.
        </p>
        <FlexContainer direction={"column"} gap={4}>
          <img
            src="/assets/help/images-dashboard/image4.png"
            alt="Widgets verschieben / Größe ändern"
            width={"25%"}
            height={"auto"}
          />
          <i>Abb: Widgets verschieben / Größe ändern</i>
        </FlexContainer>
      </div>
    </div>
  )
}
