import React, { PropsWithChildren } from "react"
import { css, cx, ObjectInterpolation } from "emotion"
import { OffsetSpec, offsetSpecToPadding } from "./contraints"
import { COLORS } from "./colors"

const outer = css({
  position: "relative",
  height: "100%",
  width: "100%",
})

const inner = css({
  overflow: "auto",
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
})

export interface ScrollBoxProps {
  bordered?: boolean
  padding?: OffsetSpec
  minHeight?: string
  minWidth?: string
}

export const ScrollBox: React.FunctionComponent<PropsWithChildren<ScrollBoxProps>> = (props) => {
  const emotionStyles: ObjectInterpolation<undefined>[] = []

  props.bordered &&
    emotionStyles.push({
      border: `1px solid ${COLORS.border.default}`,
      borderRadius: "2px",
    })
  props.padding !== undefined && emotionStyles.push(offsetSpecToPadding(props.padding))
  props.minWidth !== undefined && emotionStyles.push({ minWidth: props.minWidth })
  props.minHeight !== undefined && emotionStyles.push({ minHeight: props.minHeight })

  return (
    <div className={cx(outer, css(emotionStyles))}>
      <div className={cx(inner)}>{props.children}</div>
    </div>
  )
}
