import { COLORS } from "../../shared/components/ui/colors"
import * as React from "react"
import { useMemo } from "react"
import { GlobalState, useAppSelector } from "../../relas/store"
import AppHeader from "../../shared/components/app-header"
import { LocationSelectorMap } from "./location-selector-map"
import { MunicipalityList } from "./municipality-list"
import { LocationSelectorMenu } from "./location-selector-menu"
import { css, cx } from "emotion"
import { fetchSelectionResults } from "../location-selector-slice"
import { DocumentationAndSupport } from "../../shared/components/documentation-and-support"
import { translations } from "../../assessment/i18n"

const styles = {
  grid: css({
    display: "grid",
    gridTemplateRows: "fit-content(100%) minmax(0, 1fr)",
    gridTemplateColumns: "420px 420px 1fr",
    gridTemplateAreas: `"header header header" "list map map"`,
    height: "100%",
    gap: "1px",
  }),
  withMunicipalities: css({
    gridTemplateAreas: `"header header header" "list municipalities map"`,
  }),
  header: css({
    gridArea: "header",
  }),
  borderPortal: css({
    outline: "1px solid",
    outlineColor: COLORS.border.default,
  }),
  list: css({
    gridArea: "list",
    "> div": {
      height: "100%",
    },
  }),
  map: css({
    gridArea: "map",
  }),
  municipalities: css({
    gridArea: "municipalities",
  }),
}

export const LocationSelector = () => {
  const showMunicipalityList = useAppSelector((state: GlobalState) => state.locationSelector.showMunicipalityList)
  const agsRefResLoc = useAppSelector((state: GlobalState) => state.locationSelector.agsRefResLoc)
  const currentSelection = useAppSelector((state: GlobalState) => state.locationSelector.currentSelection)
  const selectionViewPane = useAppSelector((state: GlobalState) => state.locationSelector.selectionViewPane)
  const partialRuleToBeDisplayed = useAppSelector(
    (state: GlobalState) => state.locationSelector.selectedRuleIndexForPartialDisplay
  )

  const t = useMemo(translations, [translations])

  React.useEffect(() => {
    if (currentSelection) {
      let agsRefResLocForQuery = agsRefResLoc ?? undefined

      if (currentSelection.dataSetType === "micro") {
        const rule = currentSelection.rules[0]
        agsRefResLocForQuery = rule.type === "loadmicrodata" ? rule.exampleAgsRefResLoc : agsRefResLocForQuery
      }

      fetchSelectionResults(currentSelection, partialRuleToBeDisplayed, agsRefResLocForQuery).catch(() => {})
    }
  }, [currentSelection, agsRefResLoc])

  const mainGridStyles = useMemo(() => {
    return cx({
      [styles.grid]: true,
      [styles.withMunicipalities]: showMunicipalityList,
    })
  }, [showMunicipalityList])

  return (
    <div className={mainGridStyles}>
      <div className={styles.header}>
        <AppHeader menuSection={"locationSelector"} assessment={null} assessmentEntry={null} module={null}>
          <DocumentationAndSupport tooltip={t.helpAndSupport} onClick={() => {}} callLocation={"Location Selector"} />
        </AppHeader>
      </div>

      <div className={cx(styles.list, styles.borderPortal)}>
        <LocationSelectorMenu selectionViewPane={selectionViewPane} />
      </div>

      {showMunicipalityList && (
        <div className={cx(styles.municipalities, styles.borderPortal)}>
          <MunicipalityList />
        </div>
      )}

      <div className={cx(styles.map, styles.borderPortal)}>
        <LocationSelectorMap />
      </div>
    </div>
  )
}
