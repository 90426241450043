import * as React from "react"
import { IItemRendererProps, Suggest } from "@blueprintjs/select"
import { MenuItem } from "@blueprintjs/core"
import { FieldOption } from "../actions/field-option-actions"

const OptionSuggest = Suggest.ofType<FieldOption>()

export interface Props {
  options: FieldOption[]
  selected: string
  onSelectionChanged: (selected: string) => void
}

export const FieldOptionSuggest = ({ selected, options, onSelectionChanged }: Props) => {
  const onItemSelect = (item: FieldOption) => {
    onSelectionChanged(item.key)
  }

  const renderItem = (item: FieldOption, itemProps: IItemRendererProps) => {
    if (!itemProps.modifiers.matchesPredicate) return null
    return (
      <MenuItem text={item.label} key={item.key} active={itemProps.modifiers.active} onClick={itemProps.handleClick} />
    )
  }

  const itemListPredicate = (query: string, items: FieldOption[]) => {
    if (query.length < 2) return items.slice(0, 100)
    const filtered = items.filter((item) => item.label.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0)

    return filtered.slice(0, 100)
  }

  return (
    <OptionSuggest
      items={options}
      itemsEqual={(a, b) => a.key === b.key}
      selectedItem={options.find((o) => o.key === selected)}
      inputValueRenderer={(item) => item.label}
      onItemSelect={onItemSelect}
      itemRenderer={renderItem}
      itemListPredicate={itemListPredicate}
      popoverProps={{ minimal: true, fill: true }}
    />
  )
}
