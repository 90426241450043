import React, { useEffect, useState } from "react"
import DashboardWidget from "./dashboard-widget"
import { translations } from "../../i18n"
import { css } from "emotion"
import { HasTranslations, LANA_POSTCODE_PROPS } from "../../../shared/smartdata-products/smartdata"
import Axios from "axios"
import { lanaApiUrl } from "../../../app_config"
import { AssessmentEntryFull } from "../../models/assessment"
import { DashboardEditMode } from "./dashboard-widget-grid"
import { language } from "../../../shared/language"
import { FundamentalDataQuery, WithDataLevel } from "../../reducers/fundamental-data-slice"
import { formatValue } from "../../../shared/helper/number-format"

interface Props {
  configurable: boolean
  onConfigClick: () => void
  onRemoveClick: () => void
  onHeaderClick: () => void
  printMode: boolean
  editMode: DashboardEditMode
  assessmentEntry: AssessmentEntryFull
  selectedCategoriesInDistrictData: Set<string>
  onRenderingDone: () => void
}

const styles = {
  rawValue: css({
    justifySelf: "end",
  }),
  contentStyle: css({
    padding: "4px 12px",
  }),
  marketDataGrid: css({
    paddingRight: "4px",
    display: "grid",
    gap: "2px 8px",
    gridTemplateColumns: "3fr 1fr 1fr",
  }),
}

const fetchFundamentalData = async (
  assessmentEntry: AssessmentEntryFull,
  selectedCategoriesInDistrictData: Set<string>
) => {
  const selectedCategoriesInFundamentalData: Set<string> = new Set()
  selectedCategoriesInDistrictData.forEach((cat) => selectedCategoriesInFundamentalData.add(cat.toLowerCase()))

  if (assessmentEntry.address.postalCode && assessmentEntry.agsId) {
    const postCode = assessmentEntry.postcodeId
      ? assessmentEntry.postcodeId.toString()
      : assessmentEntry.address.postalCode

    const zipData: FundamentalDataQuery = {
      zip: {
        selectedTiles: [postCode],
        selectedDataNames: Array.from(selectedCategoriesInFundamentalData),
      },
      municipality: {
        selectedTiles: [assessmentEntry.agsId.toString()],
        selectedDataNames: Array.from(selectedCategoriesInFundamentalData),
      },
      country: {
        selectedTiles: [],
        selectedDataNames: [],
      },
    }

    const success = await Axios.post(`${lanaApiUrl}/api/fundamental_data/scores`, zipData)
    return success.data
  } else {
    return {}
  }
}

const MarketDataWidget = ({
  configurable,
  onConfigClick,
  onRemoveClick,
  onHeaderClick,
  printMode,
  editMode,
  assessmentEntry,
  selectedCategoriesInDistrictData,
  onRenderingDone,
}: Props) => {
  const t = React.useMemo(translations, [translations])
  const lang = language() as keyof HasTranslations
  const [fundamentalData, setFundamentalData] = useState<WithDataLevel<{ [key in string]: number }>>()

  useEffect(() => {
    if (assessmentEntry?.address.location) {
      fetchFundamentalData(assessmentEntry, selectedCategoriesInDistrictData)
        .then((data) => {
          setFundamentalData(data ? data.scores : undefined)
        })
        .finally(onRenderingDone)
    }
  }, [assessmentEntry?.address.postalCode, assessmentEntry?.agsId, selectedCategoriesInDistrictData])

  const renderDataRow = (name: string) => {
    const unit = LANA_POSTCODE_PROPS[name].unit

    const formattedFocusValue = fundamentalData?.zip[name]
      ? `${formatValue(
          unit && t.pickTranslation(unit) === "%" ? fundamentalData?.zip[name] * 100 : fundamentalData?.zip[name],
          2
        )}  ${unit ? t.pickTranslation(unit) : ""}`
      : "-"
    const formattedReferenceValue = fundamentalData?.municipality[name]
      ? `${formatValue(
          unit && t.pickTranslation(unit) === "%"
            ? fundamentalData?.municipality[name] * 100
            : fundamentalData?.municipality[name],
          2
        )}  ${unit ? t.pickTranslation(unit) : ""}`
      : "-"

    const title = t.pickTranslation(LANA_POSTCODE_PROPS[name].title)
    return (
      <React.Fragment key={name}>
        <div className="single-line-with-ellipsis" title={title}>
          {title}
        </div>
        <div className={styles.rawValue}>{formattedFocusValue}</div>
        <div className={styles.rawValue}>{formattedReferenceValue}</div>
      </React.Fragment>
    )
  }

  const rawFieldNames = new Set(
    Object.entries(fundamentalData ?? {}).flatMap(([_, a], b) => {
      return Object.keys(a)
    })
  )

  const sortedScoresNames = [...rawFieldNames].sort((a, b) =>
    LANA_POSTCODE_PROPS[a].title[lang].localeCompare(LANA_POSTCODE_PROPS[b].title[lang])
  )

  return (
    <DashboardWidget
      configurable={configurable}
      onConfigClick={onConfigClick}
      onRemoveClick={onRemoveClick}
      header={t.dashboard.districtData.header}
      headerAddition={[t.dashboard.districtData.zip, t.dashboard.districtData.municipality]}
      onHeaderClick={onHeaderClick}
      printMode={printMode}
      editMode={editMode}
    >
      <div className={styles.contentStyle}>
        <div className={styles.marketDataGrid}>
          {sortedScoresNames && sortedScoresNames.map((item) => renderDataRow(item))}
        </div>
      </div>
    </DashboardWidget>
  )
}

export default MarketDataWidget
