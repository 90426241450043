import { EmotionCSS } from "../helper/responsive"

export * from "./colorconstants"
import { ColorType, RevoColor } from "./colorconstants"

export type BreakpointLabel = "xs" | "sm" | "md" | "lg" | "xl"

export const BreakpointLabels: BreakpointLabel[] = ["xs", "sm", "md", "lg", "xl"]

export type BreakpointStep = {
  [dec in BreakpointLabel]: { min: number; max: number }
}

export const Breakpoints: BreakpointStep = {
  xs: { min: 0, max: 543.99 },
  sm: { min: 544, max: 767.99 },
  md: { min: 768, max: 991.99 },
  lg: { min: 992, max: 1199.99 },
  xl: { min: 1200, max: Infinity },
}

export type RevoBreakpointLabel =
  | "mobilePortrait"
  | "mobileLandscape"
  | "tabletPortrait"
  | "tabletLandscape"
  | "desktopSmall"
  | "desktopWide"
export type BreakpointProperties = { min: number; max: number }
export type RevoBreakpointStep = {
  [dec in RevoBreakpointLabel]: BreakpointProperties
}

export const revoBreakpoints: RevoBreakpointStep = {
  mobilePortrait: { min: 0, max: 479.99 },
  mobileLandscape: { min: 480, max: 767.99 },
  tabletPortrait: { min: 768, max: 1023.99 },
  tabletLandscape: { min: 1024, max: 1368.99 },
  desktopSmall: { min: 1369, max: 1679 },
  desktopWide: { min: 1370, max: Infinity },
}

export const sortedRevoBreakpoints: RevoBreakpointLabel[] = Object.keys(revoBreakpoints).sort(
  (keyA: RevoBreakpointLabel, keyB: RevoBreakpointLabel) => revoBreakpoints[keyA].min - revoBreakpoints[keyB].min
) as RevoBreakpointLabel[]

export type ColSpan = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export interface ContainerLayoutProps {
  /** SpacingSize ('sm' | 'base' | 'md' | 'lg' | 'xl' | 'xxl')*/
  paddingX?: SpacingSize
  /** SpacingSize ('sm' | 'base' | 'md' | 'lg' | 'xl' | 'xxl')*/
  paddingY?: SpacingSize
  /** SpacingSize ('sm' | 'base' | 'md' | 'lg' | 'xl' | 'xxl')
   *
   *  Adds space between the containers children.
   */
  spaceBetween?: SpacingSize
}

export type FlexAlignmentType = "center" | "end" | "start" | "spaceBetween"
export const flexAlignMapping: { [key in FlexAlignmentType]: string } = {
  center: "center",
  spaceBetween: "space-between",
  end: "flex-end",
  start: "flex-start",
}

export type FlexDirection = "row" | "col" | "row-reverse" | "col-reverse"
export const flexDirectionMapping: { [key in FlexDirection]: string } = {
  col: "column",
  "col-reverse": "column-reverse",
  row: "row",
  "row-reverse": "row-reverse",
}

export type SpacingSize = "sm" | "base" | "md" | "lg" | "xl" | "xxl"

export type TypoColor = "typo-lighter" | "typo-light" | "typo-default" | "typo-dark" | "black" | "white"

/** @deprecated Use `ColorType` instead. */
export type SpecialColorType<T extends RevoColor> = ColorType<T>

export type ComponentSize = "small" | "large"
export type SizeSpec = [number, "px" | "fr" | "%" | "em" | "cm" | "en" | "vw" | "vh"]

export type RevoSize = 0 | 4 | 8 | 12 | 16 | 20 | 24 | 32 | 40 | 48 | 56 | 64 | 72 | 80 | 88
export type RevoSizeSpec =
  | RevoSize
  | [RevoSize, RevoSize]
  | [RevoSize, RevoSize, RevoSize]
  | [RevoSize, RevoSize, RevoSize, RevoSize]

export type ResponsiveSpec<T> = {
  [dec in RevoBreakpointLabel]?: T
}

export type RevoResponsiveSizeSpec = ResponsiveSpec<RevoSizeSpec>

export function isResponsiveSpec<T>(spec: any): spec is ResponsiveSpec<T> {
  const hokus = spec as ResponsiveSpec<T>
  return (
    typeof spec === "object" &&
    !!(
      hokus.desktopWide ||
      hokus.desktopSmall ||
      hokus.mobilePortrait ||
      hokus.mobileLandscape ||
      hokus.tabletLandscape ||
      hokus.tabletPortrait
    )
  )
}

export function mapBreakpoint<T, U>(
  f: (item: T, breakpoint: [RevoBreakpointLabel, BreakpointProperties]) => U,
  spec: ResponsiveSpec<T>
): U[] {
  return Object.keys(spec)
    .sort(
      (keyA: RevoBreakpointLabel, keyB: RevoBreakpointLabel) => revoBreakpoints[keyA].min - revoBreakpoints[keyB].min
    )
    .map((key: RevoBreakpointLabel) => f(spec[key] as T, [key, revoBreakpoints[key]]))
}

export function revoSizeToPadding(size: RevoSizeSpec): EmotionCSS {
  if (Array.isArray(size)) {
    return {
      padding: `${size.join("px ")}px`,
    }
  } else {
    return {
      padding: `${size}px`,
    }
  }
}
