import * as React from "react"
import { css, cx } from "emotion"
import { PropsWithChildren } from "react"
import { getThemeColorVar } from "../helper/color"
import { EmotionCSS } from "../helper/responsive"

export type RevoTooltipProps = {
  text: string
  position: "up" | "left" | "right" | "down"
  mode?: "default" | "wrap"
}

const revoTooltipClass = css({
  position: "relative",
  cursor: "pointer",

  ":after": {
    opacity: 0,
    pointerEvents: "none",
    transition: "all .18s ease-out .18s",
    fontSize: "12px",
    background: getThemeColorVar("background", "dark"),
    minHeight: "32px",
    color: "#ffffff",
    content: "attr(data-tooltip-text)",
    position: "absolute",
    zIndex: 100000,
    textAlign: "center",
    boxSizing: "border-box",
    borderRadius: "3px",
  },
  ":before": {
    opacity: 0,
    pointerEvents: "none",
    transition: "all .18s ease-out .18s",

    content: '""',
    position: "absolute",
    zIndex: 100000,
  },
  ":hover": {
    ":before, :after": {
      opacity: 1,
    },
  },
} as EmotionCSS)

const positionClasses: { [key in "up" | "left" | "right" | "down"]: string } = {
  up: css({
    ":after": {
      bottom: "100%",
      left: "50%",
      padding: "8px 16px",
      marginBottom: "10px",
      transform: "translate(-50%, 10px)",
      transformOrigin: "top",
    },
    ":before": {
      width: 0,
      height: 0,
      borderLeft: "8px solid transparent",
      borderRight: "8px solid transparent",
      borderTop: `6px solid ${getThemeColorVar("background", "dark")}`,
      bottom: "100%",
      left: "50%",
      marginBottom: "5px",
      transform: "translate(-50%, 10px)",
      transformOrigin: "top",
    },
    ":hover": {
      ":after": {
        transform: "translate(-50%, 0)",
      },

      ":before": {
        transform: "translate(-50%, 0)",
      },
    },
  } as EmotionCSS),

  down: css({
    ":after": {
      left: "50%",
      marginTop: "10px",
      padding: "8px 16px",
      top: "100%",
      transform: "translate(-50%, -10px)",
    },
    ":before": {
      width: 0,
      height: 0,
      borderLeft: "8px solid transparent",
      borderRight: "8px solid transparent",
      borderBottom: `6px solid ${getThemeColorVar("background", "dark")}`,

      left: "50%",
      marginTop: "5px",
      top: "100%",
      transform: "translate(-50%, -10px)",
    },
    ":hover": {
      ":after": {
        transform: "translate(-50%, 0)",
      },

      ":before": {
        transform: "translate(-50%, 0)",
      },
    },
  } as EmotionCSS),

  left: css({
    ":after": {
      marginRight: "11px",
      padding: "8px 16px",
      right: "100%",
      top: "50%",
      transform: "translate(10px, -50%)",
    },
    ":before": {
      width: 0,
      height: 0,
      borderTop: "8px solid transparent",
      borderBottom: "8px solid transparent",
      borderLeft: `6px solid ${getThemeColorVar("background", "dark")}`,

      marginRight: "5px",
      right: "100%",
      top: "50%",
      transform: "translate(10px, -50%)",
    },
    ":hover": {
      ":after": {
        transform: "translate(0, -50%)",
      },

      ":before": {
        transform: "translate(0, -50%)",
      },
    },
  } as EmotionCSS),

  right: css({
    ":after": {
      padding: "8px 16px",
      left: "100%",
      marginLeft: "11px",
      top: "50%",
      transform: "translate(-10px, -50%)",
    },
    ":before": {
      width: 0,
      height: 0,
      borderTop: "8px solid transparent",
      borderBottom: "8px solid transparent",
      borderRight: `6px solid ${getThemeColorVar("background", "dark")}`,

      right: "-11px",
      marginLeft: "5px",
      top: "50%",
      transform: "translate(-10px, -50%)",
    },
    ":hover": {
      ":after": {
        transform: "translate(0, -50%)",
      },

      ":before": {
        transform: "translate(0, -50%)",
      },
    },
  } as EmotionCSS),
}

const modeClasses: { [key in "default" | "wrap"]: string } = {
  default: css({
    ":after": {
      whiteSpace: "nowrap",
    },
  }),

  wrap: css({
    ":after": {
      whiteSpace: "pre-line",
    },
  }),
}

export const RevoTooltip: React.FunctionComponent<PropsWithChildren<RevoTooltipProps>> = (props) => {
  const text = props.text
  const mode = props.mode ? props.mode : "default"

  return (
    <div
      className={cx([revoTooltipClass, positionClasses[props.position], modeClasses[mode]])}
      data-tooltip-text={text}
    >
      {props.children}
    </div>
  )
}

export default RevoTooltip
