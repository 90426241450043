import * as React from "react"
import { css } from "emotion"
import { PropsWithChildren } from "react"
import { ResponsiveSpec, RevoSize, RevoSizeSpec } from "../util/layoutconstants"
import Grid from "./restyle-grid/grid"

const formClass = css({
  height: "100%",
})

const formMainContent = css({
  overflow: "auto",
})

export interface HorizontalFormProps {
  header?: JSX.Element
  actionBar?: JSX.Element
  actionBarHeight?: RevoSize
  padding?: RevoSizeSpec | ResponsiveSpec<RevoSizeSpec>
}

export const HorizontalForm: React.FunctionComponent<PropsWithChildren<HorizontalFormProps>> = (props) => {
  const { children, header, actionBar, actionBarHeight, padding } = props

  return (
    <form onSubmit={(e) => e.preventDefault()} className={formClass}>
      <Grid columns={1} rowSpec={`min-content 1fr ${actionBarHeight || 64}px`} height={[100, "%"]} padding={padding}>
        <div>{header}</div>

        <div className={formMainContent}>{children}</div>

        <div>{actionBar}</div>
      </Grid>
    </form>
  )
}

export default HorizontalForm
