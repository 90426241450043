import React, { useEffect, useMemo, useState } from "react"
import { css, cx } from "emotion"
import { translations } from "../../i18n"
import { ConfiguredAreaMap } from "./configured-area-map"
import { State } from "../../reducers/state"
import { connect, ConnectedProps } from "react-redux"
import { SelectedLocationsExtentsType, lang } from "../district-data"
import { Flex } from "../../../shared/components/ui/flex"
import { FlexItem } from "../../../shared/components/ui/flex-item"
import { ScrollBox } from "../../../shared/components/ui/scroll-box"
import { CollapsedItemType, DataByCategory, DataByTopic } from "./data-list"
import { Collapse } from "@blueprintjs/core"
import Axios, { AxiosResponse } from "axios"
import { lanaApiUrl } from "../../../app_config"
import { AnalysisDataItem, AnalysisDataItemSource, SelectionAreaType } from "../../models/market-data"
import { Tooltip } from "../../../shared/components/ui/tooltip"
import MoveableHelp from "./moveable-help"
import { DocumentationAndSupport } from "../../../shared/components/documentation-and-support"
import {
  DistrictProp,
  LANA_CATEGORIES,
  LANA_DISTRICT_PROPS,
  LANA_DISTRICT_TOPICS,
} from "../../../shared/smartdata-products/smartdata"
import GridItem from "../../../shared/components/restyle-grid/griditem"
import Grid from "../../../shared/components/restyle-grid/grid"
import { getThemeColor } from "../../../shared/helper/color"
import Icon from "../../../shared/components/icon"
import { formatValue } from "../../../shared/helper/number-format"

export const focusAreaColor = `${getThemeColor("primary", "default")}`
export const referenceAreaColor = `${getThemeColor("secondary1", "default")}`

const styles = {
  borderGrey: css({
    borderRight: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
  }),
  inputLabel: css({
    fontWeight: "bold",
    paddingRight: "6px",
  }),
  borderBottom: css({
    paddingTop: "16px",
    borderBottom: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
  }),
  detailsLabel: css({
    fontSize: "14px",
    color: `${getThemeColor("typo", "light")}`,
  }),
  legendFocusArea: css({
    width: "16px",
    height: "16px",
    background: focusAreaColor,
  }),
  legendReferenceArea: css({
    width: "16px",
    height: "16px",
    background: referenceAreaColor,
  }),
  legendLabel: css({
    fontSize: "16px",
    paddingRight: "8px",
  }),
  borderRight: css({
    borderRight: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
  }),
  categoryTopicHeader: css({
    borderBottom: `1px solid ${getThemeColor("background", "default")}`,
    cursor: "pointer",
  }),
  dataItemHeader: css({
    textAlign: "center",
    padding: "8px 0",
    backgroundColor: `${getThemeColor("background", "lighter")}`,
  }),
  focusAreaValue: css({
    color: focusAreaColor,
    fontSize: "16px",
    alignSelf: "start",
  }),
  referenceAreaValue: css({
    color: referenceAreaColor,
    fontSize: "16px",
    alignSelf: "end",
  }),
  container: css({
    overflow: "auto",
  }),
}

const mapState = (state: State) => ({
  marketData: state.marketDataApp,
  dataSet: state.marketDataApp.dataSet,
  microSelection: state.marketDataApp.microSelection,
  macroSelection: state.marketDataApp.macroSelection,
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  microSelectionExtents: SelectedLocationsExtentsType
  macroSelectionExtents: SelectedLocationsExtentsType
}

const categorize = (dataList: DistrictProp[]) => {
  const result: DataByCategory[] = []

  for (const marketDataItem of dataList) {
    const existing = result.find((c) => c.category.name == marketDataItem.category)

    if (existing) {
      const existingTopic = existing.data.find((item) => item.topic.name == marketDataItem.topic)
      if (existingTopic) {
        existingTopic.data.push(marketDataItem)
      } else
        existing.data.push({
          topic: LANA_DISTRICT_TOPICS[marketDataItem.topic],
          data: [marketDataItem],
        })
    } else
      result.push({
        category: LANA_CATEGORIES[marketDataItem.category],
        data: [
          {
            topic: LANA_DISTRICT_TOPICS[marketDataItem.topic],
            data: [marketDataItem],
          },
        ],
      })
  }
  return result
}

const indexRounding = (value: number): string => {
  if (value < 0.001) return `< ${(0.001).toLocaleString(lang)}`
  if (value > 1) return `${Math.round(value)}`
  return (Math.round(value * 1000) / 1000).toLocaleString(lang, { minimumFractionDigits: 3 })
}

export const toAnalysisTabData = (dataFromServer: AnalysisDataItemSource[]): AnalysisDataItem[] =>
  dataFromServer.map((item: AnalysisDataItemSource) => {
    const newItem: AnalysisDataItem = {
      rawValueFocusArea: item.a1,
      rawValueReferenceArea: item.a2,
      rawValueGermany: item.a3,
      inPercentageOfDenominatorFocusArea: item.b1
        ? (Math.round(item.b1 * 100) / 100).toLocaleString(lang, { minimumFractionDigits: 2 })
        : "-",
      inPercentageOfDenominatorReferenceArea: item.b2
        ? (Math.round(item.b2 * 100) / 100).toLocaleString(lang, { minimumFractionDigits: 2 })
        : "-",
      inPercentageOfDenominatorGermany: item.b3
        ? (Math.round(item.b3 * 100) / 100).toLocaleString(lang, { minimumFractionDigits: 2 })
        : "-",
      asIndexOfReferenceFocusArea: item.c1 ? indexRounding(item.c1) : "-",
      asIndexOfReferenceReferenceArea: item.c2 ? indexRounding(item.c2) : "-",
      asIndexOfGermanyFocusArea: item.d1 ? indexRounding(item.d1) : "-",
      asIndexOfGermanyReferenceArea: item.d2 ? indexRounding(item.d2) : "-",
      score: item.score,
    }
    return newItem
  })

const DistrictDataAnalysis = ({
  dataSet,
  microSelection,
  macroSelection,
  microSelectionExtents,
  macroSelectionExtents,
}: Props) => {
  const t = useMemo(translations, [translations])
  const [collapsedCategories, setCollapsedCategories] = useState<CollapsedItemType>({
    micro: new Set(),
    macro: new Set(),
  })
  const [collapsedTopics, setCollapsedTopics] = useState<CollapsedItemType>({ micro: new Set(), macro: new Set() })
  const [analysisData, setAnalysisData] = useState<Array<AnalysisDataItem>>()
  const [showCheatSheet, setShowCheatSheet] = useState(false)

  const selection = dataSet === "micro" ? microSelection : macroSelection

  // It is used to show the Area and Population on the right side in the Analysis (under the selection map)

  const areaPropName = "FA_QKM_BASE"
  const populationPropName = "EW"

  const area = analysisData?.find((item) => item.score === areaPropName)
  const population = analysisData?.find((item) => item.score === populationPropName)

  useEffect(() => {
    const dataJson = {
      focusAreaIds: selection.focusArea.list,
      focusAreaType: selection.focusArea.kind,
      referenceAreaIds: selection.referenceArea.list,
      referenceAreaType: selection.referenceArea.kind,
      scores: Array.from(new Set([...selection.data, areaPropName, populationPropName])),
    }
    void Axios.post(`${lanaApiUrl}/api/market_data/scores`, dataJson).then((success: AxiosResponse) =>
      setAnalysisData(toAnalysisTabData(success.data.scores))
    )
  }, [dataSet])

  const renderDataListItem = (dataItem: DistrictProp, id: number) => {
    const dataListItemData = analysisData?.find((item) => dataItem.name === item.score)

    return (
      <Flex flexDirection="column" key={`${dataItem.name}-${id}`}>
        <div className={styles.dataItemHeader}>
          {t.pickTranslation(dataItem.title)}&nbsp;
          <Tooltip
            placement="right"
            tooltip={
              <div style={{ maxWidth: "400px" }}>
                <div>{t.pickTranslation(dataItem.description)}</div>
                <div>
                  <b>{t.scoreSource}</b>: {t.pickTranslation(dataItem.source)}
                </div>
              </div>
            }
          >
            <Icon name="info" fontSize={14} color="primary" colorType="default" />
          </Tooltip>
        </div>
        <Grid columns={4} rowSpec={"min-content min-content"}>
          <GridItem colSpan={{ mobilePortrait: 2, desktopSmall: 1 }}>
            <Flex flexDirection={"column"}>
              <div className={cx(styles.borderRight, styles.detailsLabel, css({ padding: "16px 16px 8px 16px" }))}>
                {t.districtData.rawValue}
              </div>
              <div className={styles.borderRight}>
                <Flex flexDirection={"row"} alignItems={"stretch"} padding={[0, 16, 16, 16]}>
                  <FlexItem flexGrow={1}>
                    <div className={styles.focusAreaValue}>
                      {dataListItemData?.rawValueFocusArea !== undefined
                        ? formatValue(
                            dataListItemData?.rawValueFocusArea,
                            2,
                            dataItem.unit ? t.pickTranslation(dataItem.unit) : undefined
                          )
                        : ""}
                    </div>
                  </FlexItem>
                  <div className={styles.referenceAreaValue}>
                    {dataListItemData?.rawValueReferenceArea !== undefined
                      ? formatValue(
                          dataListItemData?.rawValueReferenceArea,
                          2,
                          dataItem.unit ? t.pickTranslation(dataItem.unit) : undefined
                        )
                      : ""}
                  </div>
                </Flex>
              </div>
            </Flex>
          </GridItem>

          <GridItem colSpan={{ mobilePortrait: 2, desktopSmall: 1 }}>
            <Flex flexDirection={"column"}>
              <div className={cx(styles.borderRight, styles.detailsLabel, css({ padding: "16px 16px 8px 16px" }))}>
                {dataItem.denominatorVarName
                  ? `${t.districtData.inPercentOf} ${t.pickTranslation(
                      LANA_DISTRICT_PROPS[dataItem.denominatorVarName].title
                    )}`
                  : ""}
                &nbsp;
              </div>
              <div className={styles.borderRight}>
                <Flex flexDirection={"row"} alignItems={"stretch"} padding={[0, 16, 16, 16]}>
                  <FlexItem flexGrow={1}>
                    <div className={styles.focusAreaValue}>{dataListItemData?.inPercentageOfDenominatorFocusArea}</div>
                  </FlexItem>
                  <div className={styles.referenceAreaValue}>
                    {dataListItemData?.inPercentageOfDenominatorReferenceArea}
                  </div>
                </Flex>
              </div>
            </Flex>
          </GridItem>

          <GridItem colSpan={{ mobilePortrait: 2, desktopSmall: 1 }}>
            <Flex flexDirection={"column"}>
              <div className={cx(styles.borderRight, styles.detailsLabel, css({ padding: "16px 16px 8px 16px" }))}>
                {t.districtData.asIndexOfReference}
              </div>
              <div className={styles.borderRight}>
                <Flex flexDirection={"row"} alignItems={"stretch"} padding={[0, 16, 16, 16]}>
                  <FlexItem flexGrow={1}>
                    <div className={styles.focusAreaValue}>{dataListItemData?.asIndexOfReferenceFocusArea}</div>
                  </FlexItem>
                  <div className={styles.referenceAreaValue}>{dataListItemData?.asIndexOfReferenceReferenceArea}</div>
                </Flex>
              </div>
            </Flex>
          </GridItem>
          <GridItem colSpan={{ mobilePortrait: 2, desktopSmall: 1 }}>
            <Flex flexDirection={"column"}>
              <div className={cx(styles.detailsLabel, css({ padding: "16px 16px 8px 16px" }))}>
                {t.districtData.asIndexOfGermany}
              </div>
            </Flex>
            <Flex flexDirection={"row"} alignItems={"stretch"} padding={[0, 16, 16, 16]}>
              <FlexItem flexGrow={1}>
                <div className={styles.focusAreaValue}>{dataListItemData?.asIndexOfGermanyFocusArea}</div>
              </FlexItem>
              <div className={styles.referenceAreaValue}>{dataListItemData?.asIndexOfGermanyReferenceArea}</div>
            </Flex>
          </GridItem>
        </Grid>
      </Flex>
    )
  }

  const onArrowClick = (
    name: string,
    collapsedItems: CollapsedItemType,
    setCollapsedItems: (c: CollapsedItemType) => void
  ) => {
    const c = new Set(collapsedItems[dataSet])
    if (collapsedItems[dataSet].has(name)) {
      c.delete(name)
      setCollapsedItems({ ...collapsedItems, [dataSet]: c })
    } else setCollapsedItems({ ...collapsedItems, [dataSet]: c.add(name) })
  }

  const onHelpClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setShowCheatSheet(true)
  }

  const renderDataListInTopic = (dataListInTopic: DataByTopic, id: number) => {
    const topicCollapsed = collapsedTopics[dataSet].has(dataListInTopic.topic.name)
    return (
      <React.Fragment key={`${dataListInTopic.topic.name}-${id}`}>
        <div
          className={styles.categoryTopicHeader}
          onClick={() => {
            onArrowClick(dataListInTopic.topic.name, collapsedTopics, setCollapsedTopics)
          }}
        >
          <Flex flexDirection={"row"} backgroundColor={`${getThemeColor("background", "light")}`} padding={[8, 0]}>
            <FlexItem flexGrow={1}>
              <div style={{ textAlign: "center" }}>{t.pickTranslation(dataListInTopic.topic.title)}</div>
            </FlexItem>
            <a
              onClick={() => {
                onArrowClick(dataListInTopic.topic.name, collapsedTopics, setCollapsedTopics)
              }}
            >
              <Icon
                fontSize={20}
                name={topicCollapsed ? "arrow_drop_down" : "arrow_drop_up"}
                color={"background"}
                colorType={"default"}
              />
            </a>
          </Flex>
        </div>
        <Collapse isOpen={!topicCollapsed}>{dataListInTopic.data.map(renderDataListItem)}</Collapse>
      </React.Fragment>
    )
  }

  const renderCategorizedDataList = (categorizedDataList: DataByCategory) => {
    const categoryCollapsed = collapsedCategories[dataSet].has(categorizedDataList.category.name)
    const ungroupedData = categorizedDataList.data.find((item) => item.topic.name === "ungrouped")
    return (
      <React.Fragment key={`${categorizedDataList.category.name}`}>
        <div
          className={styles.categoryTopicHeader}
          onClick={() => onArrowClick(categorizedDataList.category.name, collapsedCategories, setCollapsedCategories)}
        >
          <Flex flexDirection={"row"} backgroundColor={`${getThemeColor("background", "dark")}`} padding={[8, 0]}>
            <FlexItem flexGrow={1}>
              <div style={{ color: "white", textAlign: "center" }}>
                {t.pickTranslation(categorizedDataList.category.title)}
              </div>
            </FlexItem>
            <a
              onClick={() =>
                onArrowClick(categorizedDataList.category.name, collapsedCategories, setCollapsedCategories)
              }
            >
              <Icon
                fontSize={20}
                name={categoryCollapsed ? "arrow_drop_down" : "arrow_drop_up"}
                color={"background"}
                colorType={"default"}
              />
            </a>
          </Flex>
        </div>
        <Collapse isOpen={!categoryCollapsed}>
          {ungroupedData && <>{ungroupedData.data.map(renderDataListItem)}</>}
          {categorizedDataList.data.filter((item) => item.topic.name !== "ungrouped").map(renderDataListInTopic)}
        </Collapse>
      </React.Fragment>
    )
  }

  const renderSelectedValues = (selectedValues: Set<string>) => {
    const selectedValuesProps: DistrictProp[] = []
    if (selectedValues.size > 0) {
      selectedValues.forEach((name) => selectedValuesProps.push(LANA_DISTRICT_PROPS[name]))
      const sortedSelectedValuesProps = Object.values(selectedValuesProps).sort((a, b) => {
        const cCmp = LANA_CATEGORIES[a.category].title[lang].localeCompare(LANA_CATEGORIES[b.category].title[lang])
        if (cCmp != 0) return cCmp
        else {
          const tCmp = LANA_DISTRICT_TOPICS[a.topic].title[lang].localeCompare(
            LANA_DISTRICT_TOPICS[b.topic].title[lang]
          )
          return tCmp != 0 ? tCmp : a.title[lang].localeCompare(b.title[lang])
        }
      })
      return (
        <Flex flexDirection={"column"}>{categorize(sortedSelectedValuesProps).map(renderCategorizedDataList)}</Flex>
      )
    } else return <div></div>
  }

  const microSelectionData = new Set(microSelection.data)
  const macroSelectionData = new Set(macroSelection.data)

  const noSelectedData =
    (dataSet === "micro" && microSelectionData.size === 0) || (dataSet === "macro" && macroSelectionData.size === 0)

  const renderAnalysisMap = (focusOrReference: SelectionAreaType): JSX.Element => {
    const entitiesCountString = (): string => {
      const selectedEntytiesCount =
        dataSet === "micro"
          ? microSelection[focusOrReference].list.length
          : macroSelection[focusOrReference].list.length

      switch (dataSet === "micro" ? microSelection[focusOrReference].kind : macroSelection[focusOrReference].kind) {
        case "Country":
          return "(Germany)"
        case "District":
          return `(${selectedEntytiesCount} ${
            selectedEntytiesCount !== 1 ? t.districtData.districts : t.districtData.district
          })`
        case "Municipality":
          return `(${selectedEntytiesCount} ${
            selectedEntytiesCount !== 1 ? t.districtData.municipalities : t.districtData.municipality
          })`
      }
    }

    const areaPopulationToString = (param: AnalysisDataItem | undefined) => {
      if (param === undefined) return ""
      return focusOrReference === "focusArea"
        ? param.rawValueFocusArea
          ? formatValue(param.rawValueFocusArea, param.rawValueFocusArea < 100 ? 2 : 0)
          : ""
        : param.rawValueReferenceArea
        ? formatValue(param.rawValueReferenceArea, param.rawValueReferenceArea < 100 ? 2 : 0)
        : ""
    }

    return (
      <>
        <GridItem colSpan={3}>
          <div>
            <span className={styles.inputLabel}>{t.districtData[focusOrReference]}</span>
            {entitiesCountString()}
          </div>
        </GridItem>
        <GridItem colSpan={3}>
          <ConfiguredAreaMap
            dataSet={dataSet}
            selectionArea={focusOrReference}
            selectedLocationsFromStore={
              dataSet === "micro" ? microSelection[focusOrReference] : macroSelection[focusOrReference]
            }
            selectedAreaExtents={
              dataSet === "micro" ? microSelectionExtents[focusOrReference] : macroSelectionExtents[focusOrReference]
            }
          />
        </GridItem>

        <Flex flexDirection={"column"} gap={8}>
          <div className={styles.detailsLabel}>{t.districtData.population}</div>
          <div>{areaPopulationToString(population)}</div>
        </Flex>
        <Flex flexDirection={"column"} gap={8}>
          <div className={styles.detailsLabel}>{t.districtData.area}</div>
          <div>{areaPopulationToString(area)} km²</div>
        </Flex>
      </>
    )
  }

  return (
    <>
      {showCheatSheet && <MoveableHelp showOverlay={showCheatSheet} setShowOverlay={() => setShowCheatSheet(false)} />}
      <Grid columns={2} columnSpec="2fr 1fr" height={[100, "%"]} className={styles.container}>
        <div className={cx(styles.borderGrey, css({ width: "100%", height: "100%" }))}>
          {noSelectedData && (
            <Grid columns={1} height={[100, "%"]}>
              <div style={{ alignSelf: "center", justifySelf: "center", color: `${getThemeColor("typo", "light")}` }}>
                {t.districtData.noDataSelected}
              </div>
            </Grid>
          )}
          {!noSelectedData && (
            <Grid columns={1} padding={[0, 0, 0, 16]} rowSpec={"min-content 1fr"} height={[100, "%"]}>
              <Flex flexDirection={"row"} gap={8} padding={[16, 0]} alignItems={"center"}>
                <div className={styles.legendFocusArea} />
                <span className={styles.legendLabel}>{t.districtData.focusArea}</span>
                <div className={styles.legendReferenceArea} />
                <span className={styles.legendLabel}>{t.districtData.referenceArea}</span>
                <div style={{ marginLeft: "auto", paddingRight: "10px" }}>
                  <DocumentationAndSupport
                    documentationURL=""
                    callLocation="District Data Table"
                    tooltip={t.documentationAndSupport.openDocumentation}
                    onClick={(e) => onHelpClick(e)}
                    buttonOnly
                  />
                </div>
              </Flex>
              <ScrollBox>
                {dataSet === "micro"
                  ? renderSelectedValues(microSelectionData)
                  : renderSelectedValues(macroSelectionData)}
              </ScrollBox>
            </Grid>
          )}
        </div>
        <Grid columns={3} padding={16} rowGap={16} rowSpec="repeat(6, min-content)">
          {renderAnalysisMap("focusArea")}
          <GridItem colSpan={3}>
            <div className={styles.borderBottom} />
          </GridItem>
          {renderAnalysisMap("referenceArea")}
        </Grid>
      </Grid>
    </>
  )
}

export default connector(DistrictDataAnalysis)
