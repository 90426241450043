/* tslint:disable */
/* eslint-disable */
/**
 * Comparables Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum PictureScaleType {
    Scale60x40 = 'SCALE_60x40',
    Scale60x60 = 'SCALE_60x60',
    Scale73x73 = 'SCALE_73x73',
    Scale118x118 = 'SCALE_118x118',
    Scale155x40 = 'SCALE_155x40',
    Scale200x200 = 'SCALE_200x200',
    Scale210x210 = 'SCALE_210x210',
    Scale300x300 = 'SCALE_300x300',
    Scale400x300 = 'SCALE_400x300',
    Scale540x540 = 'SCALE_540x540',
    Scale1000x1000 = 'SCALE_1000x1000',
    ScaleAndCrop = 'SCALE_AND_CROP',
    Scale = 'SCALE',
    WhiteFilling = 'WHITE_FILLING',
    ScaleMax = 'SCALE_MAX'
}



