import React, { useEffect, useMemo, useState } from "react"
import { BorderBottom } from "../../shared/components/ui/border-bottom"
import { Flex } from "../../shared/components/ui/flex"
import { translations } from "../i18n"
import { css, cx } from "emotion"
import { HTMLSelect } from "@blueprintjs/core"
import { AreaComparisonType, ReferenceAreaComparisonType, SelectionAreaType } from "../models/market-data"
import { Extent } from "ol/extent"
import Axios, { AxiosResponse } from "axios"
import { lanaApiUrl } from "../../app_config"
import { GeoJSON } from "ol/format"
import { transformExtent } from "ol/proj"
import { Coordinate } from "ol/coordinate"
import DataList, { CollapsedItemType, DataByCategory } from "./district-data/data-list"
import MarketDataAnalysis from "./district-data/district-data-analysis"
import * as source from "ol/source"
import useInterval from "../../utils/use-interval"
import { trackUsageEvent } from "../../utils/usage-tracking"
import AppHeader from "../../shared/components/app-header"
import { DocumentationAndSupport } from "../../shared/components/documentation-and-support"
import { DownloadReport } from "../../shared/components/ui/download-report"
import { usePrevious } from "../../utils/use-previous"
import {
  DistrictProp,
  HasTranslations,
  LANA_CATEGORIES,
  LANA_DISTRICT_PROPS,
  LANA_DISTRICT_TOPICS,
} from "../../shared/smartdata-products/smartdata"
import { IsochroneType } from "./isochrone-type"
import DistrictDataMap from "./district-data/district-data-map"
import { AppModules } from "../../menu/util/app-location-types"
import { loadAssessmentForAssessmentModule } from "../reducers/comparables-slice"
import { useAppSelector } from "../../relas/store"
import {
  addSelectedRegion,
  clearSelectedRegions,
  removeSelectedRegion,
  setSelectedData,
  updateAreaComparisonKind,
  updateDataSet,
  updateIsochroneSettings,
  updateMainTab,
  updateViewportSettings,
} from "../reducers/market-data-slice"
import { districtDataVectorTileOptions, municipalityVectorTileOptions } from "../../utils/openlayers"
import { InvalidAddressMessage } from "../../shared/components/ui/invalid-address-message"
import { AlertBox } from "../../shared/components/alertbox"
import ButtonTabs from "../../shared/components/buttontabs"
import HorizontalDivider from "../../shared/components/horizontaldivider"
import Text from "../../shared/components/text"
import Grid from "../../shared/components/restyle-grid/grid"
import GridItem from "../../shared/components/restyle-grid/griditem"
import Button from "../../shared/components/button"
import { getThemeColor, getThemeColorVar } from "../../shared/helper/color"
import { language } from "../../shared/language"

export type DataSetType = "macro" | "micro"
export type MainTabType = "configuration" | "analysis"
export type ViewportSettingsType = {
  [key in DataSetType]: {
    [key in SelectionAreaType]: {
      viewportCenter: Coordinate
      zoom: number
    }
  }
}
export type PressedButtonType = "select" | "remove"

const styles = {
  viewSettingsButtonsClass: css({
    backgroundColor: "white",
    width: "300px",
  }),
  borderGrey: css({
    borderRight: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
  }),
  filtersFormLine: css({
    paddingBottom: "16px",
    paddingLeft: "16px",
    borderBottom: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
  }),
  inputLabel: css({
    fontWeight: "bold",
    paddingBottom: "6px",
    paddingTop: "16px",
    fontSize: "14px",
  }),
  dropdown: css({
    marginBottom: 8,
    borderRadius: 4,
  }),
  dataList: css({
    fontSize: 14,
    border: "none",
  }),
}

interface Props {
  assessmentId: string
  assessmentEntryId: string | null
  module: AppModules["locationAssessment"]
}

export interface AreaComparisonOptionsType {
  focusArea: AreaComparisonType
  referenceArea: ReferenceAreaComparisonType
}

export type RegionExtentType = Map<string, Extent>

export interface SelectedLocationsExtentsType {
  focusArea: RegionExtentType
  referenceArea: RegionExtentType
}

type DataListSettings<T> = {
  collapsedCategories: string[]
  collapsedTopics: string[]
  focusAreaType: AreaComparisonType
  checked: string[]
  focusAreasShapes: RegionExtentType
  referenceAreasShapes: RegionExtentType
  focusAreaId: string[]
  referenceAreaId: string[]
  referenceAreaType: T
  districtionPartitionVersionReset: boolean
}

type MicroViewSettings = DataListSettings<AreaComparisonType>
type MacroViewSettings = DataListSettings<ReferenceAreaComparisonType>

type SelectionSettingsSourceType = {
  microView?: MicroViewSettings
  macroView?: MacroViewSettings
}

const GERMANY: Extent = [5.87, 47.27, 15.03, 55.06]

export const lang = language() as keyof HasTranslations

export const districtSource = new source.VectorTile(districtDataVectorTileOptions())

export const municipalitySource = new source.VectorTile(municipalityVectorTileOptions())

const helpLink = "https://docs.google.com/document/d/17IS2c1c3GtW_9up83wCTE5eRsb5uZfA8IjRzRtM0tFM"

const categorize = (dataList: DistrictProp[], areaComparisonScope: ReferenceAreaComparisonType) => {
  const result: DataByCategory[] = []

  for (const marketDataItem of dataList) {
    if (
      (areaComparisonScope === "District" && marketDataItem.kgs22Years.length > 0) ||
      (areaComparisonScope === "Municipality" && marketDataItem.kgs8Years.length > 0)
    ) {
      const existing = result.find((c) => c.category.name === marketDataItem.category)

      if (existing) {
        const existingTopic = existing.data.find((item) => item.topic.name === marketDataItem.topic)
        if (existingTopic) {
          existingTopic.data.push(marketDataItem)
        } else
          existing.data.push({
            topic: LANA_DISTRICT_TOPICS[marketDataItem.topic],
            data: [marketDataItem],
          })
      } else
        result.push({
          category: LANA_CATEGORIES[marketDataItem.category],
          data: [
            {
              topic: LANA_DISTRICT_TOPICS[marketDataItem.topic],
              data: [marketDataItem],
            },
          ],
        })
    }
  }

  const allCategories = new Set(result.map((category) => category.category.name))
  const allTopics = new Set(result.flatMap((category) => category.data.map((topic) => topic.topic.name)))
  const allDistrictData = new Set(
    result
      .flatMap((category) => category.data.map((topic) => topic.data))
      .flatMap((topic) => topic.map((data) => data.name))
  )

  return {
    dataList: result,
    categories: allCategories,
    topics: allTopics,
    dataNames: allDistrictData,
  }
}

const dataList = Object.values(LANA_DISTRICT_PROPS).sort((a, b) => {
  const cCmp = LANA_CATEGORIES[a.category].title[lang].localeCompare(LANA_CATEGORIES[b.category].title[lang])
  if (cCmp != 0) return cCmp
  else {
    const tCmp = LANA_DISTRICT_TOPICS[a.topic].title[lang].localeCompare(LANA_DISTRICT_TOPICS[b.topic].title[lang])
    return tCmp != 0 ? tCmp : a.title[lang].localeCompare(b.title[lang])
  }
})

export const categorizedDataListForDistricts = categorize(dataList, "District")

export const categorizedDataListForMunicipalities = categorize(dataList, "Municipality")

type ShapeLookupResponse = {
  coordinates: any
  type: string
}

const getInitialAreasExtents = (loadedShapesObject: Object): RegionExtentType => {
  const extentsMap = new Map()
  Object.entries(loadedShapesObject).map((i) =>
    extentsMap.set(i[0], transformExtent(new GeoJSON().readGeometry(i[1]).getExtent(), "EPSG:4326", "EPSG:3857"))
  )
  return extentsMap
}

const DistrictDataApp = ({ module }: Props) => {
  const assessment = useAppSelector((state) => state.assessment.currentAssessment)
  const assessmentEntry = useAppSelector((state) => state.assessment.currentAssessmentEntry)
  const assessmentLoadInProgress = useAppSelector((state) => state.assessment.assessmentLoadInProgress)
  const macroSelection = useAppSelector((state) => state.marketDataApp.macroSelection)
  const microSelection = useAppSelector((state) => state.marketDataApp.microSelection)
  const dataSet = useAppSelector((state) => state.marketDataApp.dataSet)
  const mainTab = useAppSelector((state) => state.marketDataApp.mainTab)
  const viewportSettings = useAppSelector((state) => state.marketDataApp.viewportSettings)
  const isochroneSettings = useAppSelector((state) => state.marketDataApp.isochroneSettings)

  const t = useMemo(translations, [translations])

  const microDataSetDefaults: AreaComparisonOptionsType = {
    focusArea: "District",
    referenceArea: "Municipality",
  }
  const macroDataSetDefaults: AreaComparisonOptionsType = {
    focusArea: "Municipality",
    referenceArea: "Country",
  }

  const initialCountryExtent = new Map().set("0", transformExtent(GERMANY, "EPSG:4326", "EPSG:3857"))

  const [selectionSettingsSource, setSelectionSettingsSource] = useState<SelectionSettingsSourceType>()
  const [currentSelectionArea, setCurrentSelectionArea] = useState<SelectionAreaType>("focusArea")
  const [pressedButton, setPressedButton] = useState<PressedButtonType>("select")
  const [microSelectionAreaSettings, setMicroSelectionAreaSettings] =
    useState<AreaComparisonOptionsType>(microDataSetDefaults)
  const [macroSelectionAreaSettings, setMacroSelectionAreaSettings] =
    useState<AreaComparisonOptionsType>(macroDataSetDefaults)
  const [microSelectionExtents, setMicroSelectionExtents] = useState<SelectedLocationsExtentsType>({
    focusArea: new Map(),
    referenceArea: new Map(),
  })
  const [macroSelectionExtents, setMacroSelectionExtents] = useState<SelectedLocationsExtentsType>({
    focusArea: new Map(),
    referenceArea: new Map(),
  })
  const [collapsedCategories, setCollapsedCategories] = useState<CollapsedItemType>({
    micro: new Set(),
    macro: new Set(),
  })
  const [collapsedTopics, setCollapsedTopics] = useState<CollapsedItemType>({ micro: new Set(), macro: new Set() })
  const [initialSettingsLoading, setInitialSettingsLoading] = useState(true)
  const [persistUserSettings, setPersistUserSettings] = useState(false)
  const isMicro = dataSet === "micro"

  const [displayResetMessage, setDisplayResetMessage] = useState<boolean>(false)
  let displayResetHasBeenInitialised = false

  const prevAgsId = usePrevious(assessmentEntry?.agsId)

  // TODO - Data Dictionary should be updated with a flag to indicate whether the property is available. We shouldn't hardcode it here
  const defaultMacroScores = ["LC_TOTAL", "EW", "EW_PROG_30", "BIP_EW"].filter((name) => !!LANA_DISTRICT_PROPS[name])
  const defaultMicroScores = ["LC_TOTAL", "EW", "EW_PROG_30"].filter((name) => !!LANA_DISTRICT_PROPS[name])

  const municipalityShapePromise = (id: string): Promise<ShapeLookupResponse> =>
    Axios.get(`${lanaApiUrl}/api/map/macroShape/${encodeURIComponent(id)}`, {}).then((response) => response.data)

  // assessment creation from MarketData module
  useInterval(
    () => {
      if (assessment && assessmentEntry) {
        Axios.get(
          `${lanaApiUrl}/api/assessments/${encodeURIComponent(assessment.id)}/entries/${encodeURIComponent(
            assessmentEntry.id
          )}`
        ).then(
          (success: AxiosResponse) => {
            if (assessmentEntry.agsId !== success.data.agsId) {
              void loadAssessmentForAssessmentModule(assessment.id, assessmentEntry.id, "marketdata")
            }
          },
          () => {}
        )
      }
    },
    assessment && assessmentEntry && assessmentEntry.agsId ? null : 1000
  )

  useEffect(() => {
    if (assessmentEntry && assessment) {
      void Axios.get(`${lanaApiUrl}/api/assessments/${assessment.id}/entries/${assessmentEntry.id}/datalist`).then(
        (success: AxiosResponse) => setSelectionSettingsSource(success.data)
      )
    }
  }, [assessmentEntry, assessment])

  useEffect(() => {
    if (!displayResetHasBeenInitialised && selectionSettingsSource) {
      const microNeedsReset =
        (selectionSettingsSource.microView && selectionSettingsSource.microView.districtionPartitionVersionReset) ??
        false
      const macroNeedsReset =
        (selectionSettingsSource.macroView && selectionSettingsSource.macroView.districtionPartitionVersionReset) ??
        false

      if (microNeedsReset || macroNeedsReset) {
        setDisplayResetMessage(true)
        displayResetHasBeenInitialised = true
      }
    }
  }, [selectionSettingsSource])

  const resetSettingsToDefault = async () => {
    setSelectedData("macro", defaultMacroScores)
    setSelectedData("micro", defaultMicroScores)

    if (assessmentEntry?.agsId && assessmentEntry?.address.location && assessmentEntry?.state !== "failure") {
      addSelectedRegion(assessmentEntry.agsId.toString(), "referenceArea", "micro")
      addSelectedRegion(assessmentEntry.agsId.toString(), "focusArea", "macro")
      addSelectedRegion("0", "referenceArea", "macro")

      const initialDistrictShapePromise = Axios.get(`${lanaApiUrl}/api/map/search/districtShape`, {
        params: {
          lng: assessmentEntry.address.location.lng,
          lat: assessmentEntry.address.location.lat,
        },
      })

      const initialMunicipalityShapePromise = municipalityShapePromise(`${assessmentEntry.agsId}`)

      try {
        await Promise.all([initialDistrictShapePromise, initialMunicipalityShapePromise]).then(
          ([districtResponse, municipalityResponse]) => {
            const initialDistrictId = districtResponse.data.refId
            const initialDistrictExtent = transformExtent(
              new GeoJSON().readGeometry(districtResponse.data.shape).getExtent(),
              "EPSG:4326",
              "EPSG:3857"
            )
            const initialDistrictMap = new Map().set(initialDistrictId, initialDistrictExtent)
            addSelectedRegion(initialDistrictId.toString(), "focusArea", "micro")

            const initialMunicipalityExtent = transformExtent(
              new GeoJSON().readGeometry(municipalityResponse).getExtent(),
              "EPSG:4326",
              "EPSG:3857"
            )
            const initialMunicipalityMap = new Map().set(assessmentEntry.agsId, initialMunicipalityExtent)

            setMicroSelectionExtents({ focusArea: initialDistrictMap, referenceArea: initialMunicipalityMap })
            setMacroSelectionExtents({ focusArea: initialMunicipalityMap, referenceArea: initialCountryExtent })

            setInitialSettingsLoading(false)
            setPersistUserSettings(true)
          },
          () => {}
        )
      } catch (_) {}
    }
  }

  useEffect(() => {
    if (selectionSettingsSource) {
      setPersistUserSettings(false)

      const resetMacro =
        !selectionSettingsSource?.macroView || selectionSettingsSource.macroView.districtionPartitionVersionReset
      const resetMicro =
        !selectionSettingsSource?.microView || selectionSettingsSource.microView.districtionPartitionVersionReset

      if (resetMacro || resetMicro) {
        void resetSettingsToDefault().then(() => {
          setInitialSettingsLoading(false)
          setPersistUserSettings(true)
        })
      } else {
        setCollapsedCategories({
          micro: new Set(selectionSettingsSource.microView?.collapsedCategories),
          macro: new Set(selectionSettingsSource.macroView?.collapsedCategories),
        })
        setCollapsedTopics({
          micro: new Set(selectionSettingsSource.microView?.collapsedTopics),
          macro: new Set(selectionSettingsSource.macroView?.collapsedTopics),
        })

        if (prevAgsId !== undefined) {
          if (selectionSettingsSource.microView?.checked !== undefined) {
            setSelectedData("micro", selectionSettingsSource.microView?.checked)
          }

          if (selectionSettingsSource.macroView?.checked !== undefined) {
            setSelectedData("macro", selectionSettingsSource.macroView?.checked)
          }
        }

        if (selectionSettingsSource.microView?.focusAreaType) {
          setMicroSelectionAreaSettings({
            focusArea: selectionSettingsSource.microView?.focusAreaType,
            referenceArea: selectionSettingsSource.microView?.referenceAreaType,
          })
          updateAreaComparisonKind(selectionSettingsSource.microView?.focusAreaType, "focusArea", "micro")
          updateAreaComparisonKind(selectionSettingsSource.microView?.referenceAreaType, "referenceArea", "micro")
          setMicroSelectionExtents({
            focusArea: getInitialAreasExtents(selectionSettingsSource.microView?.focusAreasShapes),
            referenceArea: getInitialAreasExtents(selectionSettingsSource.microView?.referenceAreasShapes),
          })
        }
        selectionSettingsSource.microView?.focusAreaId.map((id: string) => addSelectedRegion(id, "focusArea", "micro"))
        selectionSettingsSource.microView?.referenceAreaId.map((id: string) =>
          addSelectedRegion(id, "referenceArea", "micro")
        )

        if (selectionSettingsSource.macroView?.focusAreaType) {
          setMacroSelectionAreaSettings({
            focusArea: selectionSettingsSource.macroView?.focusAreaType,
            referenceArea: selectionSettingsSource.macroView?.referenceAreaType,
          })
          updateAreaComparisonKind(selectionSettingsSource.macroView?.focusAreaType, "focusArea", "macro")
          updateAreaComparisonKind(selectionSettingsSource.macroView?.referenceAreaType, "referenceArea", "macro")
          setMacroSelectionExtents({
            focusArea: getInitialAreasExtents(selectionSettingsSource.macroView.focusAreasShapes),
            referenceArea:
              selectionSettingsSource.macroView.referenceAreaType === "Country"
                ? initialCountryExtent
                : getInitialAreasExtents(selectionSettingsSource.macroView.referenceAreasShapes),
          })
        }
        selectionSettingsSource.macroView?.focusAreaId.map((id: string) => addSelectedRegion(id, "focusArea", "macro"))
        selectionSettingsSource.macroView?.referenceAreaId.map((id: string) =>
          addSelectedRegion(id, "referenceArea", "macro")
        )

        setInitialSettingsLoading(false)
        setPersistUserSettings(true)
      }
    }
  }, [assessmentEntry?.agsId, selectionSettingsSource])

  useEffect(() => {
    if (assessmentEntry && assessment && persistUserSettings) {
      const dataJson = JSON.stringify({
        assessmentId: assessment.id,
        assessmentEntryId: assessmentEntry.id,
        microView: {
          focusAreaType: microSelection.focusArea.kind,
          referenceAreaType: microSelection.referenceArea.kind,
          focusAreaId: microSelection.focusArea.list,
          referenceAreaId: microSelection.referenceArea.list,
          checked: Array.from(microSelection.data),
          collapsedCategories: Array.from(collapsedCategories.micro),
          collapsedTopics: Array.from(collapsedTopics.micro),
        },
        macroView: {
          focusAreaType: macroSelection.focusArea.kind,
          referenceAreaType: macroSelection.referenceArea.kind,
          focusAreaId: macroSelection.focusArea.list,
          referenceAreaId: macroSelection.referenceArea.list,
          checked: Array.from(macroSelection.data),
          collapsedCategories: Array.from(collapsedCategories.macro),
          collapsedTopics: Array.from(collapsedTopics.macro),
        },
      })
      void Axios.post(
        `${lanaApiUrl}/api/assessments/${assessment.id}/entries/${assessmentEntry.id}/datalist`,
        dataJson,
        { headers: { "Content-Type": "application/json" } }
      ).then(() => {})
    }
  }, [microSelection, macroSelection, collapsedTopics, collapsedCategories])

  useEffect(() => {
    if (!initialSettingsLoading) {
      setPersistUserSettings(true)
    }
  }, [initialSettingsLoading])

  const renderViewSettingsHeader = () => {
    return (
      <Grid columns={1} rowSpec="min-content">
        <BorderBottom>
          <Flex
            flexDirection="row"
            alignItems="center"
            padding={16}
            gap={32}
            backgroundColor={getThemeColorVar("background", "lighter")}
          >
            <div className={styles.viewSettingsButtonsClass}>
              <ButtonTabs<DataSetType>
                values={["micro", "macro"]}
                selected={dataSet}
                onSelect={(page) => {
                  setCurrentSelectionArea("focusArea")
                  trackUsageEvent("DISTRICT_DATA_SWITCH_VIEW", assessmentEntry?.address ?? null, page)
                  updateDataSet(page)
                }}
                translate={t.districtData.dataView}
              />
            </div>
            <div className={styles.viewSettingsButtonsClass}>
              <ButtonTabs<MainTabType>
                values={["configuration", "analysis"]}
                selected={mainTab}
                onSelect={(tab) => {
                  trackUsageEvent("DISTRICT_DATA_SWITCH_TAB", assessmentEntry?.address ?? null, tab)
                  updateMainTab(tab)
                }}
                translate={t.districtData.mainTabs}
              />
            </div>
          </Flex>
        </BorderBottom>
      </Grid>
    )
  }

  const renderContent = () => {
    const mapLoaded = assessment && assessmentEntry && !initialSettingsLoading

    const onSelectionAreaChange = (event: React.MouseEvent<HTMLElement>, selectionArea: SelectionAreaType) => {
      event.preventDefault()
      event.stopPropagation()
      setPressedButton("select")
      setCurrentSelectionArea(selectionArea)
    }

    const onAreaComparisonSettingsChange = (
      e: React.ChangeEvent<HTMLSelectElement>,
      areaOrReferenceArea: SelectionAreaType
    ) => {
      updateAreaComparisonKind(
        e.currentTarget.value as ReferenceAreaComparisonType,
        areaOrReferenceArea,
        isMicro ? "micro" : "macro"
      )
      isMicro
        ? setMicroSelectionAreaSettings({ ...microSelectionAreaSettings, [areaOrReferenceArea]: e.currentTarget.value })
        : setMacroSelectionAreaSettings({ ...macroSelectionAreaSettings, [areaOrReferenceArea]: e.currentTarget.value })
      setCurrentSelectionArea(areaOrReferenceArea)
      clearSelectedRegions(areaOrReferenceArea, dataSet)
      isMicro
        ? setMicroSelectionExtents({ ...microSelectionExtents, [areaOrReferenceArea]: new Map() })
        : setMacroSelectionExtents({
            ...macroSelectionExtents,
            [areaOrReferenceArea]:
              areaOrReferenceArea === "referenceArea" && e.currentTarget.value === "Country"
                ? initialCountryExtent
                : new Map(),
          })
      if (areaOrReferenceArea === "referenceArea" && e.currentTarget.value === "Country")
        addSelectedRegion("0", "referenceArea", "macro")
    }

    const onDeleteSelectionAreaButtonMouseDown = (
      event: React.MouseEvent<HTMLElement>,
      areaOrReferenceArea: SelectionAreaType
    ) => {
      setPressedButton("remove")
      setCurrentSelectionArea(areaOrReferenceArea)
      clearSelectedRegions(areaOrReferenceArea, dataSet)
      setTimeout(() => setPressedButton("select"), 500)
    }

    const onDeleteSelectionAreaClick = (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      event.stopPropagation()
    }

    const setSelectedLocationsExtents = (dataSet: DataSetType) => {
      return dataSet === "micro" ? setMicroSelectionExtents : setMacroSelectionExtents
    }

    const onSelectedLocationsExtentsChange = (id?: string, extent?: Extent): void => {
      let updatedMap = isMicro
        ? microSelectionExtents[currentSelectionArea]
        : macroSelectionExtents[currentSelectionArea]
      if (id && extent) {
        updatedMap = new Map(updatedMap)
        updatedMap.set(id, extent)
      } else if (id && !extent) {
        updatedMap = new Map(
          isMicro ? microSelectionExtents[currentSelectionArea] : macroSelectionExtents[currentSelectionArea]
        )
        updatedMap.delete(id)
      } else if (!id && !extent) {
        updatedMap = new Map()
      }
      setSelectedLocationsExtents(dataSet)(
        isMicro
          ? { ...microSelectionExtents, [currentSelectionArea]: updatedMap }
          : { ...macroSelectionExtents, [currentSelectionArea]: updatedMap }
      )
    }

    const entitiesCountString = (areaType: ReferenceAreaComparisonType, count: number): string => {
      switch (areaType) {
        case "Country":
          return ""
        case "District":
          return `${count} ${count != 1 ? t.districtData.districts : t.districtData.district} ${
            t.districtData.selected
          }`
        case "Municipality":
          return `${count} ${count != 1 ? t.districtData.municipalities : t.districtData.municipality} ${
            t.districtData.selected
          }`
      }
    }

    const renderAreaSettingsLine = (focusOrReference: SelectionAreaType): JSX.Element => {
      const engLabel = (str: string) => (language() === "de" ? str : str[0].toUpperCase() + str.slice(1))

      let selectOptions
      if (focusOrReference === "focusArea")
        selectOptions = isMicro
          ? [{ value: "District", label: engLabel(t.districtData.district) }]
          : [{ value: "Municipality", label: engLabel(t.districtData.municipality) }]
      else
        selectOptions = isMicro
          ? [
              { value: "District", label: engLabel(t.districtData.district) },
              { value: "Municipality", label: engLabel(t.districtData.municipality) },
            ]
          : [
              { value: "Municipality", label: engLabel(t.districtData.municipality) },
              { value: "Country", label: t.districtData.country },
            ]

      return (
        <div className={styles.filtersFormLine}>
          <Grid columns={2} columnSpec={"5fr 4fr auto"} colGap={16} rowSpec={"auto"} padding={[0, 16, 0, 0]}>
            <div className={styles.inputLabel}>{t.districtData[focusOrReference]}</div>
            <GridItem colSpan={2}>
              <div className={styles.inputLabel}>
                {entitiesCountString(
                  isMicro ? microSelectionAreaSettings[focusOrReference] : macroSelectionAreaSettings[focusOrReference],
                  isMicro ? microSelection[focusOrReference].list.length : macroSelection[focusOrReference].list.length
                )}
              </div>
            </GridItem>
            <GridItem alignSelf={"center"}>
              <HTMLSelect
                fill
                className={styles.dropdown}
                style={{ background: "white", borderRadius: 4 }}
                iconProps={{
                  icon: "chevron-down",
                  iconSize: 16,
                  color: getThemeColor("primary", "default").toString(),
                }}
                options={selectOptions}
                value={
                  isMicro ? microSelectionAreaSettings[focusOrReference] : macroSelectionAreaSettings[focusOrReference]
                }
                onClick={(e) => onSelectionAreaChange(e, focusOrReference)}
                onChange={(e) => onAreaComparisonSettingsChange(e, focusOrReference)}
              />
            </GridItem>

            <Button
              icon={"select"}
              size={"tiny"}
              id={"marketDataComparisonAreaSettingsButtons"}
              type={currentSelectionArea === focusOrReference && pressedButton === "select" ? "primary" : "secondary"}
              onClick={(e) => {
                onSelectionAreaChange(e, focusOrReference)
              }}
            >
              <span style={{ padding: "0 2px" }}>{t.districtData.selectArea}</span>
            </Button>

            <div onMouseDown={(e) => onDeleteSelectionAreaButtonMouseDown(e, focusOrReference)}>
              <Button
                icon={"delete"}
                size={"tiny"}
                id={"marketDataComparisonAreaSettingsButtons"}
                type={currentSelectionArea === focusOrReference && pressedButton === "remove" ? "primary" : "secondary"}
                onClick={(e) => onDeleteSelectionAreaClick(e)}
              />
            </div>
          </Grid>
        </div>
      )
    }

    return (
      <div style={{ height: "100%" }}>
        <div
          style={{
            visibility: mainTab === "configuration" ? "visible" : "hidden",
            height: mainTab === "configuration" ? "100%" : 0,
          }}
        >
          <Grid columns={2} columnSpec="1fr 431px" height={[100, "%"]}>
            <div className={cx(styles.borderGrey, css({ backgroundColor: "rgb(229, 227, 223)" }))}>
              {mapLoaded && (
                <DistrictDataMap
                  initialMunicipalityId={assessmentEntry?.agsId}
                  dataSet={dataSet}
                  mainTab={mainTab}
                  currentSelectionArea={currentSelectionArea}
                  selectedLocationsFromStore={isMicro ? microSelection : macroSelection}
                  selectionAreaSettings={isMicro ? microSelectionAreaSettings : macroSelectionAreaSettings}
                  onSelectLocation={(id: string) => addSelectedRegion(id, currentSelectionArea, dataSet)}
                  onRemoveSelectedLocation={(id: string) => removeSelectedRegion(id, currentSelectionArea, dataSet)}
                  markerLocation={assessmentEntry?.address.location}
                  removeSelectionActive={pressedButton === "remove"}
                  selectedLocationsExtents={isMicro ? microSelectionExtents : macroSelectionExtents}
                  onSelectedLocationsExtentsChange={onSelectedLocationsExtentsChange}
                  userViewportSettings={viewportSettings}
                  setUserViewportSettings={(settings: ViewportSettingsType) => updateViewportSettings(settings)}
                  showDistrictLayer={!initialSettingsLoading}
                  isochroneSettings={isochroneSettings}
                  setIsochroneSettings={(settings: IsochroneType) => updateIsochroneSettings(settings)}
                />
              )}
            </div>
            <Grid columns={1} rowSpec="min-content min-content 1fr" height={[100, "%"]}>
              {renderAreaSettingsLine("focusArea")}
              {renderAreaSettingsLine("referenceArea")}
              <div className={styles.dataList}>
                {!initialSettingsLoading && (
                  <DataList
                    areaComparisonScope={
                      isMicro ? microSelectionAreaSettings.focusArea : macroSelectionAreaSettings.focusArea
                    }
                    collapsedCategories={collapsedCategories}
                    collapsedTopics={collapsedTopics}
                    setCollapsedCategories={setCollapsedCategories}
                    setCollapsedTopics={setCollapsedTopics}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </div>
        {mainTab === "analysis" && !initialSettingsLoading && (
          <MarketDataAnalysis
            microSelectionExtents={microSelectionExtents}
            macroSelectionExtents={macroSelectionExtents}
          />
        )}
      </div>
    )
  }

  return (
    <Grid columns={1} height={[100, "%"]} rowSpec="fit-content(100%) min-content fit-content(100%) minmax(0, 1fr)">
      <AppHeader
        menuSection={"locationAssessment"}
        assessment={assessment}
        assessmentEntry={assessmentEntry}
        module={module}
        isLoading={assessmentLoadInProgress}
      >
        <div style={{ display: "flex" }}>
          <DocumentationAndSupport
            documentationURL={helpLink}
            tooltip={t.helpAndSupport}
            callLocation="District Data"
            addDocumentationLink
            onClick={() => {}}
          />
        </div>
        <HorizontalDivider height={32} />
        <DownloadReport />
      </AppHeader>

      <InvalidAddressMessage assessmentEntry={assessmentEntry} />

      <HorizontalDivider height={4} borderless={true} />
      {renderViewSettingsHeader()}
      {assessmentEntry?.state && assessmentEntry?.state !== "failure" && renderContent()}
      {displayResetMessage && (
        <AlertBox
          header=""
          onClose={() => setDisplayResetMessage(false)}
          children={<Text>{t.districtData.nexigaUpdateText}</Text>}
          actions={[
            {
              label: t.ok,
              action: () => setDisplayResetMessage(false),
            },
          ]}
        />
      )}
    </Grid>
  )
}

export default DistrictDataApp
