import { AssessmentTranslationsGroup } from "./translations"
import { SHARED_EN } from "../../shared/i18n/en"
import { FileProperties } from "../models/assessment"
import { default as React } from "react"
import { ComparablesAppPageType } from "../components/comparables/comparablesV2"

export const ASSESSMENTS_EN: AssessmentTranslationsGroup = {
  ...SHARED_EN,
  address: {
    postalCode: "ZIP",
    locality: "City",
    route: "Street",
  },
  assessment: {
    header: "Location Assessment",
    createNew: "Create new Assessment",
    enterSingleAddress: "Enter single address",
    uploadAddressList: "Upload address list",
    noAssessments: "There are currently no assessments in progress. Create a new assessment first.",
    createdAt: "Added at",
    title: "Title",
    assessmentType: "Type",
    assessmentTypeList: "Address List",
    assessmentTypeSingle: "Single Address",
    numberOfAddresses: "Number of addresses",
    actions: "Action",
    delete: "Delete assessment",
    deleteConfirm: (title: String) => `Are you sure that you want to delete '${title}'?`,
    rename: "Rename assessment",
    recalculate: "Recalculate scores / ratings / prices",
    recalculateHint:
      "Profiles and/or ratings and/or prices have been changed. Displayed values might be outdated and need to be recalculated.",
    macroScoreContext: "Context",
    macroScoreDefaultContext: "Germany",
    search: "Search:",
    searchForAddress: "Search for Address",
    tags: "Tags:",
    invalidAddressInfo: {
      title: "Invalid address",
      message: "We can't calculate / show any information for this address.",
    },
  },
  assessmentAddAddress: {
    addressInputPlaceholder: "Enter address",
    addressLabel: "Address",
    createButton: "Assess location",
    loadListButton: "Upload address list",
    uploadingFile: "Uploading file",
    setPinHint: "The address is ambiguous. You can click in the map to refine the desired location.",
    outsideOfGermany: "You can't use an address outside of Germany.",
    errorNoSmartdata: "No smartdata available for this address",
  },
  assessmentUploadAddresses: {
    title: "Upload Address List",
    hint: `You can upload an Excel file containing up to 3000 addresses.</br>
          Depending on the size of the list and the number of profiles and ratings to be calculated, the import can take quite a while.</br>
          If you need help with the file format, take a look at the <a href="/assets/Location%20Assessment%20-%20Upload%20Adressliste.pdf" target="_blank">documentation</a>.`,
    errorInvalidFileFormat: (fileProps: FileProperties | undefined) => {
      if (fileProps)
        return (
          <>
            The uploaded file doesn't seem to have valid xls format:
            <br />
            <dl>
              <dt>
                <b>Detected MIME file type:</b> <code>{fileProps.mimeType}</code>
              </dt>
              <dd>
                allowed: <code>application/octet-stream</code> or{" "}
                <code>application/vnd.openxmlformats-officedocument.spreadsheetml.sheet</code>
              </dd>
              <dt>
                <b>File Extension:</b> {fileProps.fileExtension}
              </dt>
              <dd>
                allowed: <code>xls</code>, <code>xlsx</code>
              </dd>
            </dl>
          </>
        )
      else return <>The uploaded file doesn&quot;t seem to have valid xls format.</>
    },
    errorNoRows: "The uploaded file does not have any rows",
    errorMissingColumns: "The following columns could not be found",
    errorTooManyEntries: "The uploaded file contains more than 1000 addresses",
    uploadButton: "Upload",
    column: {
      postalCode: "ZIP",
      locality: "City",
      route: "Street",
      streetNumber: "Nr",
    },
  },
  assessmentDetails: {
    viewMode: {
      list: "List",
      map: "Map",
    },
    entryFailure: "No smartdata available for this address",
    showDetails: "Show details",
    locationMacroPrices: "Location Prices City (EUR/m²)",
    locationMicroPrices: "Location Prices Cell (EUR/m²)",
    objectPrices: "Unit Prices (EUR/m²)",
    prices: {
      min: "Min",
      max: "Max",
      avg: "Ø",
    },
    emptyAssessmentAlert: {
      title: "Delete address",
      text: "If you delete all addresses, the whole assessment gets deleted. Do you want to continue?",
    },
    download: {
      title: "Download data",
      selectProfiles: "Select Profiles",
      downloadButton: "Download data",
      saveSettings: "Save settings",
      tooManyProfiles: "Please note that not more than 5 profiles can be selected.",
    },
  },
  assessmentEntryDetails: {
    ratings: "Ratings",
    profiles: "Profile",
    scores: "Scores",
    toggleAllScores: "All Scores",
    entryNotGeocoded:
      "It was not possible to find a geo location for the given address. Please correct the address manually.",
    entryHasMissingCellOnBorder:
      "The assessed address is close to the border of the municipality. We are currently optimising the method that is used to calculate scores, profiles and ratings at such special locations. Till then, we refrain from showing approximated values.",
    entryHasNoSmartdata: "There are no smartdata for this address.",
    noProfileSelected: "No profile is selected",
    noScoreGroupSelected: "No score group selected",
    profileNoScores: {
      macro: "Selected profile has no macro scores",
      micro: "Selected profile has no micro scores",
    },
    entryHasNoScores: {
      macro: "No macro scores at this address",
      micro: "No micro scores at this address",
    },
    stateStatus: {
      created: "Assessment created",
      geocoded: "Geocoding",
      "geocoded-ambiguous": "Geocoding error",
      smartdata: "Data processing",
      "smartdata-ambiguous": "Data processing error",
      rated: "Ratings processing",
      "rated-ambiguous": "Ratings error",
      "object-prices": "Prices processing",
      "object-prices-ambiguous": "Prices error",
      scored: "Scores processing",
      "scored-ambiguous": "Scores error",
      failure: "Failure",
    },
    updateEntry: "Change address",
    tags: "Tags",
    priceTitle: "Price",
    pricesTitle: "Prices",
    pricesTabTitle: "Prices & Yields",
    pricesTypesTitle: "Price Type",
    usageTypesTitle: "Usage Type",
    selectionWarning: "If you select one of the price types, you should also select at least one of the usage types.",
    pricesTabs: {
      market: "Market",
      trends: "Trends",
      rentIndex: "Rent index",
    },
    noRentIndex: "There is no Rent Index for this combination of location, year and area.",
    noRentIndexForCity:
      "We can’t offer a Rent Index for this city. If this city has an official Rent Index, please contact our customer support so that we can implement it.",
    addressForRentIndexNotDefined:
      "The assessed address is incomplete. To calculate the rent index, the full address is needed.",
    usageType: {
      office: "Office",
      retail: "Retail",
      residential: "Residential",
      hall: "Logistics",
      plot: "Plot",
    },
    netYield: "Net Yields",
    grossYield: "Gross Yields",
    assessmentUsageTypes: {
      "residential-apartment-rent": "Rent: Apartment",
      "residential-apartment-sale": "Purchase: Apartment",
      "residential-house-rent": "Rent: House",
      "residential-house-sale": "Purchase: House",
      office: "Rent: Office",
      retail: "Rent: Retail",
      hall: "Rent: Logistics",
      "plot-sale": "Purchase: Plot",
      logistics: "Rent: Logistics",
    },
    priceType: {
      rent: "Rent",
      sale: "Purchase",
    },
    houseTypeTitle: "House Type",
    houseType: {
      other: "Other",
      singleDetached: "One-family house (detached)",
      multiple: "Multifamily house",
      semiDetached: "Semi-detached house",
      corner: "End-terrace house",
      middle: "Mid-terrace house",
    },
    houseOrApartment: "Type",
    locationSourceLabel: "Selected by",
    priceCategories: {
      apartment: "Apartment",
      house: "House",
    },
    locationPrices: "Location prices",
    priceRange: { min: "Min", max: "Max", avg: "Average" },
    agsPriceLabel: "City",
    cellPriceLabel: "Neighborhood",
    objectPriceLabel: "Unit",
    rentIndexCellPriceLabel: "Market Price Neighborhood",
    rentIndexPriceLabel: "Rent Index",
    rentIndexLinkDescription:
      "This is a rough first indication of the Rent Index based on location and unit data (year and area). For more precise values, please visit our dedicated",
    rentIndexLinkLabel: "calculator",
    constructionOrRenovationYear: "Year of Constr. / Refurb.",
    newBuilding: "New Building",
    constructionYear: "Year of Construction",
    constructionYearAlert: {
      header: 'You are activating the filter "Year of Construction"',
      text: "Offers with an unknown year of construction won’t be represented on the map and in price distribution histogram when this filter is applied.",
      checkBox: "Do not show this message again",
    },
    livingArea: "Area",
    quality: "Exclusiveness",
    radius: "Radius",
    rooms: "Rooms",
    roomsNumberMax: " or more",
    publicationTime: "Publication date",
    offerDuration: "Offer duration",
    quarters: "quarters",
    noObjectPrices: "No price information available",
    euroPerSqm: "€\u00A0/\u00A0m²",
    euro: "€",
    years: "years",
    constructionYearValidation: "Specify reasonable year",
    columns: {
      year: "Year",
      area: "Area",
      exclusiveness: "Exclusiveness",
      usageType: "Type",
      externalID: "ID",
    },
    exclusivenessDropdown: {
      standard: {
        text: "Unit meets the standards that are to be expected in the building and closer neighborhood",
        label: "Standard (★)",
      },
      high: {
        text: "Unit distinctly stands out from other units in the building/neighborhood",
        label: "High (★★)",
      },
      highest: {
        text: "Far above-average amenities and features you wouldn't expect in the building/neighborhood",
        label: "Highest (★★★)",
      },
    },
    baseScoreLegend: "Index: Q1 2011 = 100",
    view: "View",
    offers: "Offers",
    index: "Index",
    rent: "rent",
    price: "price",
    showPriceDistribution: "Show price distribution",
    noDetailsAvailable: "No details available",
    noProfilesAvailable: "No profiles available",
    noRatingsAvailable: "No ratings available",
    notInMacroContext: "Location is not in macro context",
    comparablesExplanation:
      "The Machine Learning Prices for city and cell as well as the Object Prices are based on thousands of parameters and millions of fOf. You can filter those comparable offers within a certain radius to see their price distribution in a graph and their approximate location on the map.",
    comparablesAppPageTranslation: (page: ComparablesAppPageType) => {
      switch (page) {
        case "map":
          return "Map"
        case "analysis":
          return "Analysis"
      }
    },
    dataSource: {
      header: "Data source",
      historical: "Offer prices - Historical (21st)",
      onlineImmo: "Offer prices - Live (ImmoScout24)",
      transaction: "Transaction prices - Historical (21st)",
      seniorLiving: "Senior Living - Historical (21st)",
    },
    furnished: "Furnished",
    yes: "Yes",
    no: "No",
    nonFocusCellMessage:
      "The address is located in an uninhabited/undeveloped cell. For this type of cells, we do not calculate own scores. We show, however, approximate values. These are derived from the three nearest inhabited cells as a weighted average. Based on this it's possible to guess how the scores would look like if the cell were inhabited/developed.",
    nonFocusCellProfileMessage:
      "The address is located in an uninhabited/undeveloped cell. For this type of cells, we do not calculate own scores/profiles. We show, however, approximate values. These are derived from the three nearest inhabited cells as a weighted average. Based on this it's possible to guess how the scores/profiles would look like if the cell were inhabited/developed.",
    nonFocusCellRatingsMessage:
      "The address is located in an uninhabited/undeveloped cell. For this type of cells, we do not calculate own scores or ratings that are based on scores. We show, however, approximate values. These are derived from the three nearest inhabited cells as a weighted average. Based on this it's possible to guess how the ratings would look like if the cell were inhabited/developed.",
    additionalCosts: "Additional costs",
    notSpecified: "not specified",
    floor: "Floor",
    distributionGraphHeader: "Distribution of prices for comparables offers",
    range: "Range",
    observedOffers: "Observed offers",
    comparables: "Comparables",
    machineLearningPrices: "Machine Learning Prices",
    noComparablesFound: "No matching offers found",
    limitedOfferCountExplanation:
      "The map shows the closest 1000 offers. If you want to see offers more distributed within the radius, please adjust the filter settings.",
    yieldRecalculate: "Recalculate Market Yields",
    yieldsTitle: "Market Yields",
    yieldCity: "City Average",
    topYieldCity: "City Top",
    yieldMicro: "Neighborhood Average",
    yieldFeedback:
      "Our market yields give you insights into the risk/reward profiles of your locations. Please let us know what you think.",
    additionalInfo: "Additional Info",
    plotPricesRecalculate: "City level plot prices are not available. Please Recalculate the assessment.",
    unitSettings: "Unit Settings",
    reset: "Reset",
  },
  assessmentEntryReport: {
    downloadReport: "Download report",
    downloadReportButton: "Download report (Word)...",
    downloadDataButton: "Download data (Excel)",
    dialogTitle: "Report configuration",
    chooseForMap: "Choose Score for map",
    saveSettings: "Save Settings",
    warningMessage: "You selected many scores. The creation of the report will take some time and maybe even abort.",
    warningMessageWaitingTime:
      "Depending on the selected components, the creation of the report can take up to 10 minutes. Please don't cancel the download process.",
    showPOISummaryInReport: "Show POI summary in report",
    selectedCategories: "Selected categories",
    noCategoriesSelected:
      "Currently, no categories are selected.\nYou can switch to the POI Explorer and activate those categories you are interested in.",
  },
  comparablesGraphs: {
    chart: "Chart",
    table: "Table",
    median: "Median",
    average: "Average",
    meanValues: "Mean Values",
    range: "Range",
    quarter: "Quarter",
    offers: "Offers",
    observedOffers: "Observed offers",
    quantile: "Quantile",
    minimum: "Minimum",
    maximum: "Maximum",
  },
  assessmentComparables: {
    offerCount: "Offer count:",
    offerCountFiltered: "Offer count (filtered):",
    filteredOffers: "Filtered offers",
    priceTrends: "Trends: Prices",
    priceDistribution: "Distribution: Prices",
    areaDistribution: "Distribution: Area",
    offerYearDistribution: "Distribution: Offers per quarter",
    constructionYearDistribution: "Distribution: Construction year",
    immoscoutItemType: {
      apartment: "Apartment",
      house: "House",
      commercial: "Commercial",
    },
    roomsHeader: "Rooms",
    purchasePrice: "Purchase price",
    purchasePricePerM2: "Purchase price per m²",
    netRent: "Net rent (per month)",
    netRentPerM2: "Net rent (per month per m²)",
    totalRent: "Total rent (per month)",
    totalRentPerM2: "Total rent (per month per m²)",
    area: "Area (m²)",
    livingSpace: "Living space (m²)",
    plotArea: "Plot area (m²)",
    objectDetails: "Object Details",
    builtInKitchen: "Built In Kitchen",
    balcony: "Balcony",
    certificateOfEligibilityNeeded: "Certificate of eligibility needed",
    garden: "Garden",
    lift: "Lift",
    guestToilet: "Guest Toilet",
    cellar: "Cellar",
    barrierFree: "Barrier Free",
    energyPerformanceCertificate: "Energy Performance Certificate",
    isAvailable: "is available",
    courtage: "Courtage",
    vatIncluded: "VAT included",
    energyEfficiencyClass: "Energy Efficiency Class",
    offerDetails: "Offer Details",
    modificationDate: "Last modification date",
    privateOffer: "Private offer",
    usageType: "Usage Type",
    offersLimit: {
      map: {
        "historical-21st":
          "For historical data, we show up to 10,000 offers within the radius/ZIP, distributed around the address in the center.",
        "online-immoScout": "For online data, we show up to 200 offers, distributed within the radius.",
        "historical-21st-transaction": "",
        "historical-21st-senior-living": "",
      },
      analysis: {
        "historical-21st":
          "On the map, the maximum number of offers is limited. Unlike for the analysis, where all matching offers are included.",
        "online-immoScout": "",
        "historical-21st-transaction": "",
        "historical-21st-senior-living": "",
      },
    },
    analysisErrors: {
      noOffers: "No matching offers found for these filter settings.",
      notEnoughOffers: "Not enough offers for the chart analysis.",
      error: "Oops, an error occurred. Please try again later or change the filter settings.",
    },
    newComparablesOpenInfoText:
      "The new Comparables offer more data, more filters, more analysis and even realtime offers from ImmoScout24.",
    newComparablesOpenButton: "Open the new Comparables",
    allHouseTypes: "(All house types)",
    offersWithKnownConstructionYear: "Here we only list offers with known construction year.",
    transactionSourceInfo: (
      <div>
        <p>Transactions are not only shown around the address, but for whole Germany.</p>
        <p>
          Transaction data is based on own observations of real estate news portals (such as iz.de, thomas-daily.de,
          konii.de, immobilienmanager.de, deal-magazin.com, rohmert-median.de, property-magazine.de), auction websites
          (such as sga-ag.de, ndga.de, dga-ag.de) and news agencies (such as pressetext.com and presseportal.de).
        </p>
        <p>
          If possible, details such as transaction price, area, address, and transaction partners are verified by hand
          using multiple sources. Only transactions with publicly available price information are included in the
          database.
        </p>
      </div>
    ),
  },
  districtData: {
    dataView: (view) => {
      switch (view) {
        case "micro":
          return "Micro"
        case "macro":
          return "Macro"
      }
    },
    mainTabs: (tab) => {
      switch (tab) {
        case "configuration":
          return "Configuration"
        case "analysis":
          return "Analysis"
      }
    },
    focusArea: "Focus Area",
    referenceArea: "Reference Area",
    district: "district",
    districts: "districts",
    municipality: "municipality",
    municipalities: "municipalities",
    selected: "selected",
    country: "Germany",
    data: "Data",
    districtsZoomIn: "Area too big, please zoom in",
    selectArea: "Select area",
    noDataSelected: "No Data was selected",
    rawValue: "Raw value",
    inPercentOf: "in % of",
    asIndexOfReference: "As index (Reference=100)",
    asIndexOfGermany: "As index (DE=100)",
    population: "Population",
    area: "Area",
    readMore: "Read more...",
    nexigaUpdateText:
      "Since your last visit, the district borders changed. Your previous configuration of focus and reference area has been reset to the default values.",
  },
  quickStart: {
    headerLabel: "Quickstart",
    headerComment: "Assess location & unit",
    location: "Location",
    address: "Address",
    infoLabel: "Start typing your address...",
    ambiguousAddressMessage: "The address is ambiguous. You can click in the map to refine the desired location.",
    outsideOfGermanyAddress: "You can't use an address outside of Germany.",
    unitOptional: "Unit (optional)",
    usageType: {
      label: "Usage Type",
      residentialRent: "Rent / Residential",
      office: "Rent / Office",
      retail: "Rent / Retail",
      residentialSale: "Purchase / Residential",
      hall: "Purchase / Logistics",
      plotSale: "Purchase / Plot",
      logistics: "Rent / Logistics",
    },
    yearOfConstructionAndRefurbishment: "Year of Construction / Refurbishment",
    yearOfConstruction: "Year of Construction",
    yearOfConstructionAbbreviation: "BY",
    startAssessment: "Start Assessment",
    newAddress: "1. New Assessment Address",
    propertyDetails: "2. Optional: Property Details",
  },
  dashboard: {
    header: "Assessment Overview",
    configuration: "Configuration",
    remove: "Remove",
    widgetConfiguration: "Widget configuration",
    widgetSelectDropdown: {
      macroMap: "Map (Macro)",
      microMap: "Map (Micro)",
      nearestAccessibility: "Nearest Accessibility",
      macroScores: "Scores (Macro)",
      microScores: "Scores (Micro)",
      districtData: "Data",
      comparables: "Comparables",
      rentIndex: "Rent Index",
      POIs: "POIs",
      specialMaps: "Available Special Maps",
      population: "Population Distribution",
      MLPrices: "Machine Learning Prices",
      yields: "Market Yields Trends",
      ratings: "Ratings",
    },
    configInfo: {
      microMap: {
        header: "Micro Map",
        isochrone: "Configure the isochrone to be shown on the map.",
        privatePois: "Select the Private POIs to be shown on the map.",
      },
      scores: "Select the scores to be shown in the widget.",
      ratings: "Select the ratings to be shown in the widget.",
      pois: "Select the POI categories to be shown in the widget.",
      districtData: "Select the data variables to be shown in the widget.",
    },
    filter: "Filter",
    info: "Here you can filter matching scores. Names and descriptions are included in the search.",
    specialMaps: {
      header: "Available Special Maps",
      noMaps: "No special maps available",
    },
    ratings: {
      header: "Ratings",
      noRatings: "No ratings available",
      allRatings: "All ratings",
      selectedRatings: "Selected ratings",
      microRatings: "Micro Ratings",
      macroRatings: "Macro Ratings",
      metaRatings: "Meta Ratings",
    },
    nearestAccessibility: {
      header: "Nearest Accessibility",
    },
    rentIndex: {
      header: "Rent Index",
      noRentIndex: "No rent index available",
    },
    districtData: {
      header: "Data",
      district: "District",
      municipality: "Municipality",
      zip: "ZIP",
    },
    mlPrices: {
      header: "Machine Learning Prices",
      city: "City",
      neighborhood: "Neighborhood",
      unit: "Unit",
      usageType: {
        rentResidential: "Rent Residential",
        rentOffice: "Rent Office",
        rentRetail: "Rent Retail",
        purchaseResidential: "Purchase Residential",
      },
    },
    microMap: {
      header: (mode: string, translatedMode: string, minutes: number) =>
        mode === "none" ? "Neighborhood" : `${translatedMode} isochrone (${minutes} min. perimeter)`,
    },
    macroMap: {
      header: "Map",
    },
    population: {
      header: "Population Distribution",
    },
    scores: {
      microHeader: "Micro Scores",
      macroHeader: "Macro Scores",
    },
    POIs: {
      header: "POIs (15 min. perimeter)",
      configurationHeader: "POIs",
      categories: {
        eatAndDrink: "Eat & Drink",
        healthCare: "Health Care",
        schools: "Schools",
        shopping: "Shopping",
        culture: "Culture",
        postOfficeBank: "Post Office & Bank",
        parking: "Parking",
      },
      allPOIs: "Aggregated collection",
      selectedPOIs: "Selected POI categories",
    },
    yields: {
      header: {
        residential: "Market Gross Yields Trends",
        office: "Market Net Yields Trends",
        retail: "Market Net Yields Trends",
      },
      usageType: {
        residential: "Residential",
        office: "Office",
        retail: "Retail",
      },
    },
    comparables: {
      header: "Comparables (1 km radius)",
      historicalOffers: "Historical offers",
      liveOffers: "Live offers",
    },
    editDashboardConfigurationDialog: {
      header: "Multi Dashboard Configuration",
      dashboards: "Dashboards",
      newDashboard: "Add new dashboard",
      duplicate: "Duplicate",
      delete: "Delete",
      title: "Title",
      dashboardAlreadyExists: "This name already exists",
      deleteDashboard: "Delete Dashboard",
      deleteDashboardConfirmation: (name: string) => `Do you really want to delete the dashboard '${name}'?`,
      cancel: "Cancel",
      copy: "Copy",
      editContent: "Edit content",
      editLayout: "Edit layout",
      editing: "Editing",
    },
  },
  rentindex: {
    noCityInfoHeader: "No rent index available",
    noCityInfoDescription:
      "So far, we do not offer a Rent Index for this city. If this city has an official Rent Index, please contact our customer support.",
    rentIndexHeader: "Rent index",
    area: "Area",
    yearOfConstruction: "Year of construction",
    unknownAddress: "The assessed address is incomplete. To calculate the rent index, the full address is needed.",
    unknownError: "An unknown error occured",
    resultEmpty: "Rent index has no value",
    incompleteData: "Please enter an area and the year of construction.",
    transferData: "Transfer values",
    additionalInformation: "Additional information",
    generalInformation: "General information",
    calculationHeader: "Calculation",
    rentindexMinMax: "Rent Index (Min-Ø-Max)",
    allValues: "(All values in €/m²)",
    cantBeCalculated: "The rent index can't be calculated for this combination of year, area and answered questions.",
    quesitonCatalog: "Question Catalog",
    resetAllAnswers: "Reset all answers",

    availableRentIndices: "Available Rent Indices",
    listOfAllCities:
      "List of all cities where we offer a rent index. This list is regularly updated. Within our app, you'll have access to the most recent rent index. Through the API, you can also access older rent indices.",
    listOfAllCitiesInformationHeader: "Information",
    listOfAllCitiesInformationNavigateTo: (
      <>
        If you are interested, we have a <a href={"/rentindex/info"}>list of all cities</a> where we offer a rent index.
      </>
    ),
  },
  specialMaps: {
    availableSpecialMaps: "Available Special Maps",
    furtherSpecialMaps: "Further Special Maps",
    legendHeader: "Legend",
    noLegend: "No legend available",
    loadingMap: "Loading map...",
    error: "Temporary error - map could not be loaded",
    zoomIn: "This special map is only visible from a certain zoom level. Please zoom in.",
  },
  fundamentalData: {
    dataView: (view) => (view === "zip" ? "ZIP" : view === "municipality" ? "Municipality" : "Germany"),
    noDataSelected: "No data variables and/or areas selected",
    data: "Data",
    select: "Select",
    resetArea: "Reset area",
    resetData: "Reset data",
    loadingError: "Oops, an error occurred. Please try again later or change the filter settings.",
  },
  prices: {
    selector: {
      switchButtonLabels: {
        prices: "Prices",
        yields: "Market Yields",
        current: "Current",
        trends: "Trends",
        all: "All",
      },
      subtitles: {
        rent: "Rent",
        purchase: "Purchase",
      },
      residential: "Residential",
      office: "Office",
      retail: "Retail",
      logistics: "Logistics",
      plots: "Plots",
    },
    plotPricesAreNotAvailable: "Plot price trends are not available.",
    unitPrices: {
      header: "Unit Prices",
      info: "The unit prices also take the object into consideration, the relevant parameters (year of construction, area, etc.) can be specified. The values are to be understood as indications and evaluations.",
    },
    locationPrices: {
      header: "Location Prices",
      info: "The location prices are determined not only by the location itself but also by the market situation. The values should be interpreted as potential, with special attention to the range and the relative position of the mean.",
    },
    rentIndex: {
      header: "Rent Index",
      info: {
        calculator: "calculator",
        available: (calculatorSpan) => (
          <>
            This is a rough indication (€/m²) based on location, year and area. Visit our dedicated {calculatorSpan} for
            precise values.
          </>
        ),
        errors: {
          notAvailableForCity:
            "So far, we do not offer a Rent Index for this location. If this city has an official Rent Index, please contact our customer support.",
          cannotCalculate:
            "We do offer a Rent Index for this city, but it can't be calculated for this combination of location, year and area.",
          generic: "An error occurred while calculating the Rent Index.",
        },
      },
    },
    pricesTrends: {
      header: "Location Price Trends",
      info: "Instead of the current quarterly prices, their temporal development is illustrated here, allowing for a dynamic view. The last eight quarters offer a non-linear perspective into the near future.",
      noData: "No trends available for this asset class.",
    },
    priceVariations: {
      header: "Unit Price Variation",
      info: "These graphs reveal the influence of individual parameters on prices, explaining their variation. This enables What-If analyses and return forecasts.",
      yAxis: "Price",
      xAxisArea: "Area",
      xAxisConstructionYear: "Year of Constr. / Refurb.",
      xAxisClass: "Exclusiveness",
      noValues: "No values available for these settings",
    },
    marketYields: {
      info: "Yields describe the relationship between annual rental income and purchase price. Net yields also take into account operating and acquisition costs. Our market yields are primarily intended as an indicator and provide insights into the risk/reward profile of a location.",
      rentOrPurchaseResidential: "Market gross yields",
      rentOffice: "Market net yields",
      rentRetail: "Market net yields",
    },
    marketYieldsTrends: {
      info: "The additional temporal dimension of trends makes the yields even more valuable as an indicator of economic development as well as the dynamics of opportunities and risks of a location.",
      rentOrPurchaseResidential: "Market gross yields trends",
      rentOffice: "Market net yields trends",
      rentRetail: "Market net yields trends",
    },
    filter: "Filter",
    filterPrices: "Filter prices",
  },
}
