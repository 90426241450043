import { Component } from "react"
import React from "react"

import * as ol from "ol"
import * as layer from "ol/layer"
import * as source from "ol/source"
import { defaults as defaultControls, ScaleLine, FullScreen } from "ol/control"
import { defaults as defaultInteractions, DragRotateAndZoom } from "ol/interaction"
import { fromLonLat } from "ol/proj"
import Control from "ol/control/Control"

export interface OpenLayersAbstractProps {
  tileSource?: source.XYZ | false
  customControls?: Control[]
}

export abstract class OpenLayersMap<P extends OpenLayersAbstractProps, S = {}> extends Component<P, S> {
  protected mapContainer = React.createRef<HTMLDivElement>()
  private map: ol.Map

  private observer: MutationObserver | undefined = undefined

  private tileLayer: layer.Tile<any> | undefined = undefined

  private fallbackTileSource = new source.XYZ({
    crossOrigin: null as any,
    url: `https://assets.${(window as any).twentyone?.DOMAIN_NAME || "21re.works"}/tiles/{z}/{x}/{y}.png`,
    attributions: [
      '<span style="font-size: 12px" ><a href="https://www.maptiler.com/license/maps/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a></span>',
    ],
    tileSize: 512,
    maxZoom: 22,
  })

  protected registerMenuListener() {
    const maybeMainMenuElements = document.getElementsByClassName("revoMainMenu__wrapper")
    if (maybeMainMenuElements.length > 0) {
      const mainMenu = maybeMainMenuElements.item(0)
      if (mainMenu) {
        this.observer = new MutationObserver(() => {
          setTimeout(() => {
            this.map.updateSize()
          }, 520 /* animation of menu is 0.5s */)
        })
        this.observer.observe(mainMenu, { attributes: true, childList: false, subtree: false })
      }
    }
  }

  protected getMap(): ol.Map {
    return this.map
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect()
      this.observer = undefined
    }
    this.map.setTarget(undefined as any)
  }

  componentDidMount(): void {
    this.registerMenuListener()
    let zoom = 6
    let center = fromLonLat([10.5, 51])
    let rotation = 0

    if (this.props.tileSource !== false) {
      this.tileLayer = new layer.Tile({
        source: this.props.tileSource || this.fallbackTileSource,
      })
    }
    const controls = this.props.customControls
      ? (this.props.customControls as Control[])
      : [new ScaleLine(), new FullScreen()]
    this.map = new ol.Map({
      controls: defaultControls({
        attribution: true,
      }).extend(controls),
      interactions: defaultInteractions().extend([new DragRotateAndZoom()]),
      target: this.mapContainer.current || undefined,
      layers: this.tileLayer === undefined ? [] : [this.tileLayer],
      view: new ol.View({
        center,
        zoom,
        rotation,
      }),
    })

    this.map.updateSize()
    setTimeout(() => {
      this.map.updateSize()
    }, 1000)

    this.map.on("moveend", (_) => {
      this.onMoveEnd()
    })
  }

  componentDidUpdate(prevProps: P, prevState: S) {
    if (this.props.tileSource !== prevProps.tileSource && this.tileLayer) {
      const source: source.XYZ | undefined | false = this.props.tileSource
      if (source) {
        this.tileLayer.setSource(source)
      }
    }
  }

  protected abstract onMoveEnd(): void
  protected abstract containerClass(): string

  render(): React.ReactNode {
    return <div className={this.containerClass()} ref={this.mapContainer} />
  }
}
