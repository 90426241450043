import React, { useEffect, useState } from "react"
import DashboardWidget from "./dashboard-widget"
import { translations } from "../../i18n"
import { css } from "emotion"
import { Classes } from "@blueprintjs/core"
import { AssessmentEntryFull } from "../../models/assessment"
import { Location } from "../../models/address"
import { historicalOffersInputFormDefaults } from "../comparables/comparables-map/historical-offers-filters-model"
import { BaseQueryParam, ComparablesApi, ComparablesShortCollection } from "../../../generated-apis/comparables-service"
import { comparablesFormToQueryParams } from "../../reducers/comparables-slice"
import { onlineImmoScoutInputFormDefaults } from "../comparables/comparables-map/online-immoscout-filters-model"
import { AxiosResponse } from "axios"
import { comparablesServiceUrl } from "../../../app_config"
import { NumericRange } from "../../../shared/models/numeric-range"
import { numericYearWithQuarterToLabel } from "../../../utils/numeric-year-with-quarter-to-label"
import { DashboardEditMode } from "./dashboard-widget-grid"
import Grid from "../../../shared/components/restyle-grid/grid"
import GridItem from "../../../shared/components/restyle-grid/griditem"

const styles = {
  contentStyle: css({
    height: "100%",
    padding: "4px 8px",
  }),
  offerNumber: css({
    fontWeight: "bold",
    fontSize: "28px",
    alignSelf: "center",
    justifySelf: "end",
  }),
  offerLabel: css({
    alignSelf: "center",
    justifySelf: "start",
  }),
}

export interface Props {
  onHeaderClick: () => void
  onRemoveClick: () => void
  printMode: boolean
  editMode: DashboardEditMode
  assessmentEntry?: AssessmentEntryFull
  markerLocation?: Location
  onRenderingDone: () => void
}

const ComparablesWidget = ({
  onHeaderClick,
  onRemoveClick,
  printMode,
  editMode,
  assessmentEntry,
  markerLocation,
  onRenderingDone,
}: Props) => {
  const t = React.useMemo(translations, [translations])

  const [historicalComparables, setHistoricalComparables] = useState<number | null>(-1)

  const [liveComparables, setLiveComparables] = useState<number | null>(-1)

  const [yearAndQuarterToDisplay, setYearAndQuarterToDisplay] = useState<NumericRange>()

  const comparablesApi = new ComparablesApi(undefined, comparablesServiceUrl)

  const makeOfferDateToDisplay = (yearAndQuarterRange: NumericRange) =>
    `${numericYearWithQuarterToLabel(yearAndQuarterRange.from)} - ${numericYearWithQuarterToLabel(
      yearAndQuarterRange.to
    )}`

  const offerDate = yearAndQuarterToDisplay && makeOfferDateToDisplay(yearAndQuarterToDisplay)

  const fetchComparablesWidgetData = (entry: AssessmentEntryFull, markerLocation: Location) => {
    const historicalDefaultForm = historicalOffersInputFormDefaults(entry)
    const historicalParams: BaseQueryParam = comparablesFormToQueryParams(historicalDefaultForm, markerLocation)

    setYearAndQuarterToDisplay(historicalDefaultForm.publicationTimeRange)

    const immoScoutDefaultForm = onlineImmoScoutInputFormDefaults(entry)
    const immoScoutParams: BaseQueryParam = comparablesFormToQueryParams(immoScoutDefaultForm, markerLocation)

    const historicalPromise = comparablesApi.postApiV1ComparableShort(historicalParams).then(
      (success: AxiosResponse<ComparablesShortCollection>) => {
        success.data.items ? setHistoricalComparables(success.data.items.length) : setHistoricalComparables(null)
      },
      () => setHistoricalComparables(null)
    )

    const livePromise = comparablesApi.postApiV1ComparableShort(immoScoutParams).then(
      (success: AxiosResponse<ComparablesShortCollection>) => {
        success.data.items ? setLiveComparables(success.data.items.length) : setLiveComparables(null)
      },
      () => setLiveComparables(null)
    )

    return Promise.all([historicalPromise, livePromise])
  }

  useEffect(() => {
    if (assessmentEntry && markerLocation) {
      fetchComparablesWidgetData(assessmentEntry, markerLocation).finally(onRenderingDone)
    }
  }, [assessmentEntry, markerLocation])

  return (
    <DashboardWidget
      header={t.dashboard.comparables.header}
      onHeaderClick={onHeaderClick}
      onRemoveClick={onRemoveClick}
      htmlId={"assessment-dashboard-comparables-widget"}
      printMode={printMode}
      editMode={editMode}
    >
      <div className={styles.contentStyle}>
        <Grid columns={2} columnSpec={"1fr 3fr"} rowSpec={"1fr 1fr"} colGap={20} rowGap={16} height={[100, "%"]}>
          <div className={styles.offerNumber}>
            {historicalComparables !== null &&
              (historicalComparables < 0 ? (
                <div className={Classes.SKELETON} style={{ fontSize: "28px", width: "2em", height: "1em" }} />
              ) : (
                <div className="count-label">{historicalComparables}</div>
              ))}
            {historicalComparables === null && ""}
          </div>
          <GridItem alignSelf={"center"}>
            <div className={styles.offerLabel}>{t.dashboard.comparables.historicalOffers}</div>
            <div className={styles.offerLabel}>{offerDate}</div>
          </GridItem>

          <div className={styles.offerNumber}>
            {liveComparables !== null &&
              (liveComparables < 0 ? (
                <div className={Classes.SKELETON} style={{ fontSize: "28px", width: "2em", height: "1em" }} />
              ) : (
                <div className="count-label">{liveComparables}</div>
              ))}
            {liveComparables === null && ""}
          </div>
          <div className={styles.offerLabel}>{t.dashboard.comparables.liveOffers}</div>
        </Grid>
      </div>
    </DashboardWidget>
  )
}

export default ComparablesWidget
