import * as React from "react"
import { translations } from "../i18n"
import { GenericError } from "../helper/axios"

export interface GenericErrorMessageProps {
  /** The error to show*/
  error: GenericError
  /** Give the user a hint in which step something went wrong
   * @default ComponentTranslations.error.defaultHint*/
  hint?: string
}

export const GenericErrorMessage: React.FunctionComponent<GenericErrorMessageProps> = (
  props: GenericErrorMessageProps
) => {
  const translated = translations().error

  return (
    <div>
      <p>{props.hint || translated.defaultHint}</p>
      <p>{props.error.status ? translated.serverError : translated.networkError}</p>
      {props.error.flowId ? <p>{translated.messageWithFlowId(props.error.flowId)}</p> : null}
    </div>
  )
}

export default GenericErrorMessage
