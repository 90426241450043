import React, { useEffect, useState } from "react"
import { Drawer } from "@blueprintjs/core"
import { BorderBottom } from "../../../shared/components/ui/border-bottom"
import { COLORS } from "../../../shared/components/ui/colors"
import { Flex } from "../../../shared/components/ui/flex"
import { Grid } from "../../../shared/components/ui/grid"
import { translations } from "../../i18n"
import { css } from "emotion"
import { ScoresSelection } from "../../../shared/components/scores-selection"
import { defaultFundamentalWidgetData, RatingsSelectionSettings } from "./dashboard-export"
import { AssessmentDashboardWidgetId, POISelectionMode, WidgetConfigData } from "../../models/AssessmentDashboard"
import { useAppSelector } from "../../../relas/store"
import { RatingsSelection, SelectionModes } from "./ratings-selection"
import { AssessmentEntryFull } from "../../models/assessment"
import { POIsSelection } from "./pois-selection"
import { CollapsedItemType } from "../district-data/data-list"
import { updateWidgetConfiguration } from "../../../relas/user-slice"
import { aggregatedPOIsCategories, POIsSelectionSettings } from "./pois-widget"
import { defaultMacroScores, defaultMicroScores } from "./scores-widget"
import { PrivatePOICategoriesList } from "../../../private-data/models/private-data"
import { IsochroneType } from "../isochrone-type"
import { MicroMapConfigSelection } from "../../../shared/components/micro-map-config-selection"
import { defaultIsochroneConfig } from "./micro-map-widget"
import { trackUsageEvent } from "../../../utils/usage-tracking"
import Select from "../../../shared/components/select"
import Button from "../../../shared/components/button"
import GridItem from "../../../shared/components/restyle-grid/griditem"
import DataListSelector from "../fundamental-data/data-list-selector"

const styles = {
  drawerContent: css({
    display: "grid",
    gridTemplateRows: "repeat(3, min-content) 1fr min-content",
    height: "100%",
  }),
}

interface Props {
  assessmentEntry: AssessmentEntryFull | null
  scores?: { [p: string]: number }
  widgetConfig: WidgetConfigData
  widgetId: AssessmentDashboardWidgetId
  onClose: () => void
  selectedScores?: Array<string>
  selectedPOIs: POIsSelectionSettings
  selectedPrivatePoiCategories: string[]
  availablePrivatePoiCategories: PrivatePOICategoriesList
  isochrone: IsochroneType
  selectedRatings: RatingsSelectionSettings
  selectedInDistrictData: Array<string>
}

export const DashboardConfigDrawer = ({
  widgetConfig,
  widgetId,
  onClose,
  scores,
  selectedScores,
  selectedRatings,
  selectedPOIs,
  selectedInDistrictData,
  selectedPrivatePoiCategories,
  availablePrivatePoiCategories,
  isochrone,
}: Props) => {
  const t = translations()

  const assessmentEntry = useAppSelector((state) => state.assessment.currentAssessmentEntry)
  const selectedDashboard = useAppSelector(
    (state) =>
      state.user.lana.assessmentDashboards &&
      state.user.lana.selectedDashboardId &&
      state.user.lana.assessmentDashboards.find((d) => d.id === state.user.lana.selectedDashboardId)
  )

  const [microMapConfig, setMicroMapConfig] = useState({
    isochrone: isochrone,
    privatePOICategories: selectedPrivatePoiCategories,
  })
  const [selectedScoresInDrawer, setSelectedScoresInDrawer] = useState(selectedScores)
  const [selectedRatingsInDrawer, setSelectedRatingsInDrawer] = useState(selectedRatings.selectedRatings)
  const [selectedMetaRatingsInDrawer, setSelectedMetaRatingsInDrawer] = useState(selectedRatings.selectedMetaRatings)
  const [ratingsSelectionModeInDrawer, setRatingsSelectionModeInDrawer] = useState<SelectionModes>(
    selectedRatings.selectionMode
  )

  const [selectedDistrictDataInDrawer, setSelectedDistrictDataInDrawer] = useState<Set<string>>(
    new Set(selectedInDistrictData)
  )
  const [selectedPOIsInDrawer, setSelectedPOIsInDrawer] = useState<Array<string>>(selectedPOIs.selectedPOICategories)
  const [poisSelectionModeInDrawer, setPoisSelectionModeInDrawer] = useState<POISelectionMode>(
    selectedPOIs.selectionMode
  )
  const [collapsedCategories, setCollapsedCategories] = useState<CollapsedItemType>({
    micro: new Set(),
    macro: new Set(),
  })
  const [collapsedTopics, setCollapsedTopics] = useState<CollapsedItemType>({ micro: new Set(), macro: new Set() })
  const availableMacroContexts = useAppSelector((state) => state.assessment.availableMacroContexts)
  const [selectedMacroContext, setSelectedMacroContext] = useState<string | undefined>(
    widgetConfig.type === "macroScores" ? widgetConfig.macroContext : undefined
  )

  const fundamentalDataLevel = "zip"

  useEffect(() => {
    setSelectedScoresInDrawer(selectedScores)
  }, [selectedScores])

  useEffect(() => {
    setSelectedRatingsInDrawer(selectedRatings.selectedRatings)
    setRatingsSelectionModeInDrawer(selectedRatings.selectionMode)
    setSelectedMetaRatingsInDrawer(selectedRatings.selectedMetaRatings)
  }, [selectedRatings])

  useEffect(() => {
    setSelectedPOIsInDrawer(selectedPOIs.selectedPOICategories)
    setPoisSelectionModeInDrawer(selectedPOIs.selectionMode)
  }, [selectedPOIs])

  useEffect(() => {
    setSelectedDistrictDataInDrawer(new Set(selectedInDistrictData))
  }, [selectedInDistrictData])

  const getHeaderAndInfo = () => {
    switch (widgetConfig?.type) {
      case "microMap":
        return [t.dashboard.configInfo.microMap.header, t.dashboard.configInfo.microMap.isochrone]
      case "ratings":
        return [t.dashboard.ratings.header, t.dashboard.configInfo.ratings]
      case "microScores":
        return [t.dashboard.scores.microHeader, t.dashboard.configInfo.scores]
      case "macroScores":
        return [t.dashboard.scores.macroHeader, t.dashboard.configInfo.scores]
      case "POIs":
        return [t.dashboard.POIs.configurationHeader, t.dashboard.configInfo.pois]
      case "districtData":
        return [t.header.module.fundamentalData, t.dashboard.configInfo.districtData]
      default:
        return ["", ""]
    }
  }

  const [header, info] = getHeaderAndInfo()

  function saveWidgetConfiguration(widgetConfig: WidgetConfigData) {
    if (widgetConfig?.type === "microMap" && widgetConfig.privatePOICategories.length > 0) {
      const privatePOICategories = widgetConfig.privatePOICategories
        .map((id) => {
          const category = availablePrivatePoiCategories.find((c) => c.id === id)
          return category?.title
        })
        .filter((c) => c !== undefined) as string[]

      trackUsageEvent("PRIVATE_DATA_POIS_SHOW_ON_MAP", null, `dashboard - ${privatePOICategories.join(",")}`)
    }
    if (widgetConfig && widgetId && selectedDashboard) {
      updateWidgetConfiguration(selectedDashboard, widgetId, widgetConfig, assessmentEntry?.address)
    }
    onClose()
  }

  const onDefault = () => {
    switch (widgetConfig?.type) {
      case "microMap":
        saveWidgetConfiguration({
          type: "microMap",
          isochrone: defaultIsochroneConfig,
          privatePOICategories: [],
        })
        break
      case "microScores":
        saveWidgetConfiguration({ type: "microScores", microScores: [...defaultMicroScores] })
        break
      case "macroScores":
        saveWidgetConfiguration({ type: "macroScores", macroScores: [...defaultMacroScores] })
        break
      case "ratings":
        saveWidgetConfiguration({
          type: "ratings",
          selectionMode: "all",
          selectedRatings: assessmentEntry?.ratings.map((rating) => rating.id) ?? [],
          selectedMetaRatings: assessmentEntry?.metaRatings?.map((r) => r.id) ?? [],
        })
        break
      case "POIs":
        selectedPOIs.selectionMode === "defaults"
          ? onClose()
          : saveWidgetConfiguration({
              type: "POIs",
              selectedPOICategories: aggregatedPOIsCategories,
              selectionMode: "defaults",
            })
        break
      case "districtData":
        saveWidgetConfiguration({ type: "districtData", districtData: defaultFundamentalWidgetData })
        break
    }
  }

  const onOk = () => {
    switch (widgetConfig?.type) {
      case "microMap":
        saveWidgetConfiguration({
          type: "microMap",
          isochrone: microMapConfig.isochrone,
          privatePOICategories: microMapConfig.privatePOICategories,
        })
        break
      case "microScores":
        saveWidgetConfiguration({
          type: "microScores",
          microScores: selectedScoresInDrawer ? [...selectedScoresInDrawer] : [],
        })
        break
      case "macroScores":
        saveWidgetConfiguration({
          type: "macroScores",
          macroScores: selectedScoresInDrawer ? [...selectedScoresInDrawer] : [],
          macroContext: selectedMacroContext,
        })
        break
      case "ratings":
        saveWidgetConfiguration({
          type: "ratings",
          selectedRatings: selectedRatingsInDrawer,
          selectedMetaRatings: selectedMetaRatingsInDrawer,
          selectionMode: ratingsSelectionModeInDrawer,
        })
        break
      case "POIs":
        saveWidgetConfiguration({
          type: "POIs",
          selectedPOICategories: selectedPOIsInDrawer,
          selectionMode: poisSelectionModeInDrawer,
        })
        break
      case "districtData":
        saveWidgetConfiguration({ type: "districtData", districtData: [...selectedDistrictDataInDrawer] })
        break
    }
  }

  return (
    <Drawer isOpen={!!widgetConfig} size="450px" canOutsideClickClose={true}>
      <div className={styles.drawerContent}>
        <div
          style={{
            display: "grid",
            gridTemplateRows: "repeat(3, min-content)",
            gap: "8px",
            backgroundColor: COLORS.background.lighter,
            padding: "8px 8px 16px 16px",
          }}
        >
          <div style={{ justifySelf: "end" }}>
            <Button type="tertiary" icon="close" size="small" onClick={onClose} />
          </div>
          <h2>{header}</h2>
          <div>{t.dashboard.widgetConfiguration}</div>
        </div>
        <BorderBottom padding={16}>{info}</BorderBottom>

        {widgetConfig.type === "microMap" && (
          <MicroMapConfigSelection
            isochrone={microMapConfig.isochrone}
            selectedPrivatePoiCategories={microMapConfig.privatePOICategories}
            availablePrivatePoiCategories={availablePrivatePoiCategories}
            subtitle={t.dashboard.configInfo.microMap.privatePois}
            setConfig={(config: Partial<{ isochrone: IsochroneType; privatePOICategories: string[] }>) =>
              setMicroMapConfig({ ...microMapConfig, ...config })
            }
          />
        )}

        {(widgetConfig.type === "microScores" || widgetConfig.type === "macroScores") && (
          <ScoresSelection
            smartdataSource={widgetConfig.type === "microScores" ? "micro" : "macro"}
            scores={scores}
            selected={selectedScoresInDrawer ? new Set(selectedScoresInDrawer) : new Set()}
            setSelected={(selected) => setSelectedScoresInDrawer([...selected])}
          />
        )}

        {widgetConfig.type === "ratings" && (
          <RatingsSelection
            selectedRatingsInDrawer={selectedRatingsInDrawer}
            setSelectedRatingsInDrawer={setSelectedRatingsInDrawer}
            selectionModeInDrawer={ratingsSelectionModeInDrawer}
            setSelectionModeInDrawer={setRatingsSelectionModeInDrawer}
            selectedMetaRatingsInDrawer={selectedMetaRatingsInDrawer}
            setSelectedMetaRatingsInDrawer={setSelectedMetaRatingsInDrawer}
            ratings={assessmentEntry?.ratings ?? []}
            metaRatings={assessmentEntry?.metaRatings ?? []}
          />
        )}
        {widgetConfig.type === "POIs" && (
          <POIsSelection
            selectedPOIsInDrawer={selectedPOIsInDrawer}
            setSelectedPOIsInDrawer={setSelectedPOIsInDrawer}
            selectionModeInDrawer={poisSelectionModeInDrawer}
            setSelectionModeInDrawer={setPoisSelectionModeInDrawer}
          />
        )}
        {widgetConfig.type === "districtData" && (
          <>
            <div />
            {assessmentEntry && (
              <DataListSelector
                dataLevel={fundamentalDataLevel}
                collapsedCategories={Array.from(collapsedCategories.micro)}
                collapsedTopics={Array.from(collapsedTopics.micro)}
                setCollapsedCategories={(categories) =>
                  setCollapsedCategories({ ...collapsedCategories, micro: new Set(categories) })
                }
                setCollapsedTopics={(topics) => setCollapsedTopics({ ...collapsedTopics, micro: new Set(topics) })}
                selected={Array.from(selectedDistrictDataInDrawer)}
                setSelectedData={(selected) => {
                  const newSet = new Set(selectedDistrictDataInDrawer)
                  selectedDistrictDataInDrawer.has(selected) ? newSet.delete(selected) : newSet.add(selected)
                  setSelectedDistrictDataInDrawer(newSet)
                }}
                clearSelectedData={() => setSelectedDistrictDataInDrawer(new Set())}
              />
            )}
          </>
        )}
        <Grid columns={1}>
          {widgetConfig.type === "macroScores" && (
            <BorderBottom padding={16}>
              <GridItem justifySelf="left">
                <Flex flexDirection="row" alignItems="center" gap={8}>
                  <div style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{t.assessment.macroScoreContext}:</div>
                  <Select
                    size="small"
                    value={selectedMacroContext || ""}
                    options={[
                      { value: "", label: t.assessment.macroScoreDefaultContext },
                      ...(availableMacroContexts || []).map((selection) => ({
                        value: selection.id,
                        label: selection.name,
                      })),
                    ]}
                    onValueChange={(value) => setSelectedMacroContext(value)}
                  />
                </Flex>
              </GridItem>
            </BorderBottom>
          )}
          <div style={{ padding: "16px", display: "flex", gap: "16px" }}>
            <div style={{ marginRight: "auto" }}>
              <Button type="secondary" onClick={onDefault}>
                {t.default}
              </Button>
            </div>
            <Button type="secondary" onClick={onClose}>
              {t.cancel}
            </Button>
            <Button type="primary" onClick={onOk}>
              {t.ok}
            </Button>
          </div>
        </Grid>
      </div>
    </Drawer>
  )
}
export default DashboardConfigDrawer
