export const COLORS = {
  border: {
    default: "#CCD0DC",
    dark: "#23232D",
  },
  background: {
    default: "#FFFFFF",
    light: "#E1E1E6",
    lighter: "#F5F5FA",
    dark: "#69738C",
    darker: "#464B5F",
  },
  typo: {
    default: "#4B4B5A",
    dark: "#23232d",
    light: "#828796",
    lighter: "#B9BECD",
  },
  primary: {
    default: "#5A55EB",
    light: "#DCDCF5",
    dark: "#052DB9",
  },
  neutral: {
    default: "#EBC864",
    light: "#F5F0DC",
    dark: "#AF8209",
  },
  positive: {
    default: "#66C8B9",
    light: "#DCF5F0",
    dark: "#1E7D7D",
  },
  negative: {
    default: "#E6736E",
    light: "#F5DCDC",
    dark: "#AA3237",
  },
  secondary1: {
    default: "#00B9E1",
    light: "#E6FAFF",
    dark: "#0089AA",
  },
  secondary2: {
    default: "#0087E6",
    light: "#E6F5FF",
    dark: "#0064c3",
  },
}
