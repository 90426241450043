import * as React from "react"
import { css, cx } from "emotion"
import { getThemeColorVar } from "../helper/color"
import { EmotionCSS } from "../helper/responsive"

interface Props {
  id?: string
  value: number
  min: number
  max: number
  step?: number
  onChange: (value: number) => void
}

const sliderClass = css({
  'input[type="range"]': {
    WebkitAppearance: "none",
    width: "100%",
    cursor: "pointer",
    ":focus": {
      outline: "none",
    },
    "::-moz-range-progress": {
      backgroundColor: getThemeColorVar("primary", "default"),
    },
    "::-moz-range-thumb": {
      borderColor: getThemeColorVar("primary", "default"),
      backgroundColor: getThemeColorVar("primary", "default"),
      height: "12px",
      width: "12px",
      borderRadius: "6px",
    },
    "::-moz-range-track": {
      backgroundColor: getThemeColorVar("primary", "light"),
      height: "3px",
    },
    "::-webkit-slider-runnable-track": {
      backgroundColor: getThemeColorVar("primary", "default"),
      border: 0,
      height: "3px",
    },
    ":focus::-webkit-slider-runnable-track": {
      backgroundColor: getThemeColorVar("primary", "default"),
      border: 0,
    },
    "::-webkit-slider-thumb": {
      border: 0,
      height: "12px",
      width: "12px",
      borderRadius: "6px",
      backgroundColor: getThemeColorVar("primary", "default"),
      WebkitAppearance: "none",
      marginTop: "-5px",
    },
    ":focus::-webkit-slider-thumb": {
      backgroundColor: getThemeColorVar("primary", "default"),
      border: 0,
    },
  },
} as EmotionCSS)

export class Slider extends React.Component<Props, {}> {
  render() {
    return (
      <div className={cx(sliderClass)}>
        <input
          type="range"
          id={this.props.id}
          name="volume"
          step={this.props.step}
          min={this.props.min}
          max={this.props.max}
          value={this.props.value}
          onChange={(e) => this.props.onChange(parseFloat(e.target.value))}
        />
      </div>
    )
  }
}

export default Slider
