import * as React from "react"
import { useEffect, useState } from "react"
import { translations } from "../i18n"
import { Flex } from "../../shared/components/ui/flex"
import { ScrollBox } from "../../shared/components/ui/scroll-box"
import { AssessmentCreateSingle } from "./assessment-create-single"
import moment from "moment"
import { Assessment, isInProgressState } from "../models/assessment"
import { AssessmentRename } from "./assessment-rename"
import { useAppSelector } from "../../relas/store"
import { trackUsageEvent } from "../../utils/usage-tracking"
import { AppModules } from "../../menu/util/app-location-types"
import { AssessmentCreateList } from "./assessment-create-list"
import {
  clearAssessmentErrors,
  deleteAssessment,
  loadAssessmentEntries,
  openAssessmentModule,
  refreshAssessmentList,
  setAssessmentListSorting,
} from "../reducers/assessment-slice-functions"
import { doLoadRatingList } from "../../ratings/rating-manager-actions"
import DropdownButton from "../../shared/components/dropdown-button"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import HorizontalDivider from "../../shared/components/horizontaldivider"
import { SortableTableHeader } from "../../shared/components/sortable-table-header"
import Panel from "../../shared/components/panel"
import CenteredElement from "../../shared/components/layout/centeredelement"
import LoadingSpinner from "../../shared/components/loadingspinner"
import Grid from "../../shared/components/restyle-grid/grid"
import Table from "../../shared/components/table"
import { AlertBox } from "../../shared/components/alertbox"
import Button from "../../shared/components/button"
import Icon from "../../shared/components/icon"
import { SortDirection } from "../../shared/models/sorting"
import { InputGroup } from "@blueprintjs/core"
import { getThemeColorVar } from "../../shared/helper/color"

type Props = {
  module: AppModules["locationAssessment"]
}

type SortField = "createdAt" | "title" | "numberOfAddresses"

function hasInProgress(list: Assessment[] | null): boolean {
  if (!list) return false

  for (const assessment of list) {
    if (isInProgressState(assessment.state)) return true
  }
  return false
}

export const AssessmentList = ({ module }: Props) => {
  const t = translations()

  const assessmentList = useAppSelector((state) => state.assessment.assessmentList)
  const assessmentListInProgress = useAppSelector((state) => state.assessment.assessmentListInProgress)
  const assessmentListError = useAppSelector((state) => state.assessment.assessmentListError)
  const tagFilter = useAppSelector((state) => state.assessment.tagFilter)
  const assessmentListSorting = useAppSelector((state) => state.assessment.assessmentListSorting)
  const scopes = useAppSelector((state) => state.user.scopes)

  const [showAddressEnterDialog, setShowAddressEnterDialog] = useState(false)
  const [showUploadDialog, setShowUploadDialog] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [toDelete, setToDelete] = useState<Assessment>()
  const [toRename, setToRename] = useState<Assessment>()

  const [refresher, setRefresher] = useState<number>()
  const refresh = () => {
    void refreshAssessmentList().then(() => {
      setRefresher(undefined)
    })
  }

  useEffect(() => {
    if (hasInProgress(assessmentList)) {
      if (typeof refresher !== "number") {
        setRefresher(window.setTimeout(refresh, 2000))
      }
    } else if (typeof refresher === "number") {
      window.clearTimeout(refresher)
      setRefresher(undefined)
    }
  }, [assessmentList])

  useEffect(() => {
    return () => {
      if (typeof refresher === "number") {
        window.clearTimeout(refresher)
        setRefresher(undefined)
      }
    }
  }, [])

  const addNewAssessmentButton = () => {
    return (
      <DropdownButton
        type="primary"
        actions={[
          {
            title: t.assessment.enterSingleAddress,
            onClick: () => {
              clearAssessmentErrors()
              setShowAddressEnterDialog(true)
              setShowUploadDialog(false)
            },
          },
          {
            title: t.assessment.uploadAddressList,
            onClick: () => {
              clearAssessmentErrors()
              setShowAddressEnterDialog(false)
              setShowUploadDialog(true)
            },
          },
        ]}
      >
        {t.assessment.createNew}
      </DropdownButton>
    )
  }

  const renderContent = () => {
    if (assessmentListInProgress)
      return (
        <CenteredElement>
          <LoadingSpinner />
        </CenteredElement>
      )
    if (assessmentListError)
      return (
        <CenteredElement>
          <GenericErrorPanel error={assessmentListError} />
        </CenteredElement>
      )
    if (!assessmentList || assessmentList.length == 0) {
      return (
        <CenteredElement>
          {showAddressEnterDialog && (
            <AssessmentCreateSingle onClose={() => setShowAddressEnterDialog(false)} module={module} />
          )}
          {showUploadDialog && <AssessmentCreateList onCancel={() => setShowUploadDialog(false)} module={module} />}
          <Panel>
            <Grid
              columnSpec={[
                [25, "px"],
                [1, "fr"],
              ]}
              colGap="md"
              paddingY="md"
            >
              <Icon name="info" />
              <div style={{ whiteSpace: "nowrap" }}>{t.assessment.noAssessments}</div>
            </Grid>
            <div style={{ textAlign: "center", padding: "20px" }}>{addNewAssessmentButton()}</div>
          </Panel>
        </CenteredElement>
      )
    }

    let filteredAssessments = assessmentList.filter(
      (assessment) => assessment.title.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0
    )

    if (tagFilter !== null) {
      filteredAssessments = filteredAssessments.filter((assessment) => assessment.tags.indexOf(tagFilter) >= 0)
    }

    switch (assessmentListSorting[1]) {
      case "createdAt":
        filteredAssessments.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
        break
      case "title":
        filteredAssessments.sort((a, b) => a.title.localeCompare(b.title))
        break
      case "numberOfAddresses":
        filteredAssessments.sort((a, b) => (a.numberOfAddresses > b.numberOfAddresses ? 1 : -1))
        break
    }

    const onAssessmentRowClick = (assessment: Assessment) => {
      return (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        event.stopPropagation()

        const lanaScopes = scopes

        if (assessment.numberOfAddresses > 1) {
          void loadAssessmentEntries(assessment, module, scopes.explorer || scopes.locationSelector)
        } else if (module === "comparables" && lanaScopes.comparables) {
          void openAssessmentModule(assessment.id, null, "comparables")
        } else if (module === "marketdata" && lanaScopes.marketData) {
          void openAssessmentModule(assessment.id, null, "marketdata", undefined, assessment?.id)
        } else if (module === "assessment" && lanaScopes.assessment) {
          void openAssessmentModule(assessment.id, null, "assessment", undefined, assessment?.id)
        } else if (module === "dashboard" && lanaScopes.assessment) {
          void openAssessmentModule(assessment.id, null, "dashboard", undefined, assessment?.id)
        } else if (module === "poiExplorer" && lanaScopes.assessment) {
          void openAssessmentModule(assessment.id, null, "poiExplorer", undefined, assessment?.id)
        } else if (module === "specialMaps" && lanaScopes.assessment) {
          void openAssessmentModule(assessment.id, null, "specialMaps", undefined, assessment?.id)
        } else if (module === "rentindex" && lanaScopes.assessment) {
          void openAssessmentModule(assessment.id, null, "rentindex", undefined, assessment?.id)
        } else if (module === "ratings" && lanaScopes.ratingManager) {
          void openAssessmentModule(assessment.id, null, "ratings", undefined, assessment?.id).then(() =>
            doLoadRatingList()
          )
        } else if (module === "fundamentalData" && lanaScopes.fundamentalData) {
          void openAssessmentModule(assessment.id, null, "fundamentalData", undefined, assessment?.id)
        } else if (module === "prices" && lanaScopes.assessment) {
          void openAssessmentModule(assessment.id, null, "prices", undefined, assessment?.id)
        } else {
          console.error(`Can't load location assessment module ${module} with scopes: ${JSON.stringify(lanaScopes)}`)
        }
      }
    }

    if (assessmentListSorting[0] === SortDirection.DESC) filteredAssessments.reverse()

    return (
      <>
        <Grid
          columnSpec={"1fr 3fr 1fr"}
          colGap={16}
          backgroundColor={{ colorType: "lighter", color: "background" }}
          bordersColor={{ colorType: "light", color: "primary" }}
          padding={20}
          height={[80, "px"]}
        >
          <div></div>
          <div style={{ justifySelf: "center", maxWidth: "600px", width: "100%" }}>
            <InputGroup
              large
              fill={true}
              placeholder={t.assessment.searchForAddress}
              style={{
                borderRadius: "4px",
                border: `1px solid ${getThemeColorVar("primary", "light")}`,
                zIndex: 1,
                boxShadow: "none",
              }}
              value={searchTerm}
              leftIcon={
                <div style={{ position: "absolute", top: 0, padding: "9px", zIndex: 10 }}>
                  <Icon name={"search"} color={"primary"} colorType={"default"} fontSize={20} />
                </div>
              }
              onChange={(event) => setSearchTerm(event.currentTarget.value)}
            />
          </div>
          <div style={{ justifySelf: "end" }}>{addNewAssessmentButton()}</div>
        </Grid>
        <ScrollBox>
          <Flex flexDirection="column" padding={4}>
            {showAddressEnterDialog && (
              <AssessmentCreateSingle onClose={() => setShowAddressEnterDialog(false)} module={module} />
            )}
            {showUploadDialog && <AssessmentCreateList onCancel={() => setShowUploadDialog(false)} module={module} />}
            {toDelete && renderConfirmDelete(toDelete)}
            <AssessmentRename toRename={toRename || null} onClose={() => setToRename(undefined)} />

            <Table selectable>
              <thead style={{ paddingLeft: "2px" }}>
                <tr>
                  <SortableTableHeader
                    sorting={[assessmentListSorting[0], "createdAt" as SortField]}
                    label={t.assessment.createdAt}
                    sorter={setAssessmentListSorting}
                    sortingActive={(key) => key === assessmentListSorting[1]}
                  />
                  <SortableTableHeader
                    sorting={[assessmentListSorting[0], "title" as SortField]}
                    label={t.assessment.title}
                    sorter={setAssessmentListSorting}
                    sortingActive={(key) => key === assessmentListSorting[1]}
                  />
                  <th>{t.assessment.assessmentType}</th>
                  <SortableTableHeader
                    sorting={[assessmentListSorting[0], "numberOfAddresses" as SortField]}
                    label={t.assessment.numberOfAddresses}
                    sorter={setAssessmentListSorting}
                    sortingActive={(key) => key === assessmentListSorting[1]}
                  />
                  <th style={{ textAlign: "right" }}>{t.assessment.actions}</th>
                </tr>
              </thead>
              <tbody style={{ paddingLeft: "2px" }}>
                {filteredAssessments.map((assessment, idx) => (
                  <tr key={idx} onClick={onAssessmentRowClick(assessment)}>
                    <td>{moment(assessment.createdAt).format(t.dateFormat)}</td>
                    <td>{assessment.title}</td>
                    <td>
                      {assessment.numberOfAddresses === 1
                        ? t.assessment.assessmentTypeSingle
                        : t.assessment.assessmentTypeList}
                    </td>
                    <td>
                      {isInProgressState(assessment.state) ? (
                        <div style={{ float: "left" }}>
                          <LoadingSpinner size={20} />
                        </div>
                      ) : (
                        <div>{assessment.numberOfAddresses}</div>
                      )}
                    </td>
                    <td>
                      <Flex flexDirection="row-reverse" gap={8}>
                        <Button type="tertiary" icon="delete" onClick={onDeleteAction(assessment)} />
                        <HorizontalDivider height={24} />
                        <Button type="tertiary" icon="edit" onClick={onEditAction(assessment)} />
                      </Flex>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Flex>
        </ScrollBox>
      </>
    )
  }

  const onDeleteAction = (assessment: Assessment) => {
    return (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      event.stopPropagation()
      setToDelete(assessment)
    }
  }

  const onEditAction = (assessment: Assessment) => {
    return (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      event.stopPropagation()
      setToRename(assessment)
    }
  }

  const renderConfirmDelete = (assessment: Assessment) => {
    return (
      <AlertBox
        onClose={() => setToDelete(undefined)}
        header={t.assessment.delete}
        actions={[
          {
            label: t.delete,
            icon: "delete",
            action: () => {
              void deleteAssessment(assessment.id, module)
              trackUsageEvent("DELETE_ASSESSMENT", null, `${assessment.numberOfAddresses}, ${assessment.title}`)
              setToDelete(undefined)
            },
          },
          { label: t.cancel, action: () => setToDelete(undefined) },
        ]}
      >
        {t.assessment.deleteConfirm(assessment.title)}
      </AlertBox>
    )
  }

  const thirdRowHeight =
    assessmentListInProgress || assessmentListError || !assessmentList || assessmentList.length == 0
      ? ""
      : "fit-content(100%)"

  return (
    <>
      <Grid
        columns={1}
        height={[100, "%"]}
        rowSpec={`${thirdRowHeight} minmax(0, 1fr)`}
        backgroundColor={{ color: "background", colorType: "default" }}
      >
        {renderContent()}
      </Grid>
    </>
  )
}
