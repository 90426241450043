import { isRuleCategoryWithField, RatingSelectionRule, ruleCategories } from "../models/selection"
import { SharedTranslations } from "./translations"

export function ruleTitle(t: SharedTranslations, rule: RatingSelectionRule): string {
  switch (rule.type) {
    case "oneoffilter":
    case "rangefilter": {
      if (rule.field.startsWith("raw_")) {
        const ruleCategory = ruleCategories.find((c) => c.type === "rangefilter" && "field" in c && c.field == "raw_.*")

        return `${ruleCategory && t.pickTranslation(ruleCategory.label)}`
      }
      const ruleCategory = ruleCategories.find((c) => isRuleCategoryWithField(c) && c.field === rule.field)

      return `${ruleCategory && t.pickTranslation(ruleCategory.label)}`
    }
    case "rankbyprofile": {
      const ruleCategory = ruleCategories.find((c) => c.type === "rankbyprofile")
      return `${ruleCategory && t.pickTranslation(ruleCategory.label)}`
    }
    case "rankbyscores": {
      const ruleCategory = ruleCategories.find((c) => c.type === "rankbyscores")
      return `${ruleCategory && t.pickTranslation(ruleCategory.label)}`
    }
    case "selectranks": {
      const ruleCategory = ruleCategories.find((c) => c.type === "selectranks")
      return `${ruleCategory && t.pickTranslation(ruleCategory.label)}`
    }
    case "loadmacrodata":
      return t.loadMacroDataTitle
    case "loadmicrodata":
      return t.loadMicroDataTitle
    default:
      return ""
  }
}
