import * as cookie from "cookie"
import { CookieScopeRequirement, MenuScopeRequirement, MenuScopes } from "../models/scoperequirements"
import { Services } from "../models/services"
import { Scopes } from "../models/scopes"
import { isNullOrUndefined } from "../../shared/helper/utils"

//  Wix is cleaning all cookies that aren't started with "TS_" prefix. It should be refactored as TS_AUTH_SCOPES.
const AUTH_COOKIE = "TS_AUTH"

function getCookieValueParsed(cookieName: string): string | null {
  const cookieStr = document.cookie
  const lastCookies = cookie.parse(cookieStr)
  return cookieName in lastCookies ? lastCookies[cookieName] : null
}

export function authCookieExists(): boolean {
  return !!getCookieValueParsed(AUTH_COOKIE)
}

export function scopesFromCookie(): Array<CookieScopeRequirement> {
  const cookieValue = getCookieValueParsed(AUTH_COOKIE)

  if (!cookieValue) return []

  const jwtParts = cookieValue.split(".")

  if (jwtParts.length !== 3) return []

  // JSON.parse explodes on corrupt cookies
  try {
    const cookieScopeRequirements = jwtParts[1] ? JSON.parse(window.atob(jwtParts[1])) : {}

    return tryDecodeScopes(cookieScopeRequirements.scopes)
  } catch (e) {
    return []
  }
}

function tryDecodeScopes(cookieScopeRequirements: { [key: string]: Array<String> }): CookieScopeRequirement[] {
  return Object.keys(cookieScopeRequirements).map(
    (service) =>
      <CookieScopeRequirement>{
        service: <Services>service,
        scopes: (<Array<string>>cookieScopeRequirements[service]).map((scope) => <Scopes>scope),
      }
  )
}

function matchesScopeRequirements(currentScopes: Scopes[], menuScope: MenuScopes): boolean {
  if (typeof menuScope !== "string" && "scopeConditions" in menuScope) {
    if (menuScope.scopeConditions === "OR") {
      return currentScopes.some((curScope) => menuScope.scopes.indexOf(curScope) !== -1)
    }
    if (menuScope.scopeConditions === "AND_NOT") {
      return (
        currentScopes.length >= 1 &&
        currentScopes.some((curScore) => curScore === menuScope.requiredScope) &&
        !currentScopes.some((curScore) => menuScope.absentScopes.indexOf(curScore) !== -1)
      )
    }
    if (menuScope.scopeConditions === "AND") {
      return menuScope.scopes.every((menuScope) => currentScopes.includes(menuScope))
    }
  } else {
    return currentScopes.some((curScope) => curScope === menuScope || curScope === "*")
  }
  return false
}

export function scopeRequirementIsFulfilled(
  menuScopeRequirement: MenuScopeRequirement | undefined,
  cookieScopeRequirements: Array<CookieScopeRequirement>
): boolean {
  if (menuScopeRequirement) {
    return cookieScopeRequirements.some(
      (cookieScopeRequirement) =>
        cookieScopeRequirement.service === menuScopeRequirement.service &&
        matchesScopeRequirements(cookieScopeRequirement.scopes, menuScopeRequirement.scope)
    )
  } else {
    return true
  }
}

export function menuItemShouldBeShown(
  scopeRequirement: MenuScopeRequirement | undefined,
  cookieScopeRequirements: Array<CookieScopeRequirement>
) {
  if (isNullOrUndefined(scopeRequirement)) {
    return true
  }

  return scopeRequirementIsFulfilled(scopeRequirement, cookieScopeRequirements)
}
