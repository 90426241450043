import * as React from "react"
import { Translations } from "../i18n/translations"
import { translations } from "../i18n"
import { RegistrationContainer } from "./registration-container"
import RevoCard from "../../shared/components/revo-card"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"

export const RegistrationComplete = () => {
  const translate: Translations = translations()

  return (
    <RegistrationContainer title={translate.complete.header}>
      <RevoCard>
        <FlexContainer direction="column">
          <p>{translate.complete.text}</p>
        </FlexContainer>
      </RevoCard>
    </RegistrationContainer>
  )
}
