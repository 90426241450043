import * as React from "react"
import { PropsWithChildren } from "react"
import { css } from "emotion"
import Dimmer from "./dimmer"
import FlexContainer from "./restyle-grid/flexcontainer"
import LoadingSpinner from "./loadingspinner"
import { BrandingColor, RevoSize, UIState } from "../util/layoutconstants"
import { getThemeColorVar } from "../helper/color"

export interface LoadingDimmerProps {
  loading: boolean
  spinnerSize?: RevoSize
  label?: string
  spinnerColor?: UIState | BrandingColor
  overlayOpacity?: 0.1 | 0.2 | 0.3 | 0.4 | 0.5 | 0.6 | 0.7 | 0.8 | 0.9 | 1.0
}

function labelClass(color: UIState | BrandingColor | undefined) {
  const baseClass = { fontSize: "14px" }

  if (color === undefined) {
    return css(baseClass)
  } else {
    return css({ ...baseClass, color: getThemeColorVar(color, "default") })
  }
}

export const LoadingDimmer: React.FunctionComponent<PropsWithChildren<LoadingDimmerProps>> = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <Dimmer active={props.loading} overlayOpacity={props.overlayOpacity}>
        <FlexContainer md-align={"center"} md-justify={"center"} direction={"column"} spaceBetween={"sm"}>
          <div>
            <LoadingSpinner
              size={props.spinnerSize === undefined ? 72 : props.spinnerSize}
              color={props.spinnerColor}
            />
          </div>
          <div className={labelClass(props.spinnerColor)}>{props.label}</div>
        </FlexContainer>
      </Dimmer>
      {props.children}
    </div>
  )
}

export default LoadingDimmer
