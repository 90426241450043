import { Scores } from "../../shared/models/scores"

export interface Profile {
  id: string
  name: string
  companyId: string
  scores: Scores
  groupId?: string
  lockedBy: string | null
}

export interface ProfileDetails extends Profile {
  dependencies: { childProfileId?: string; selectionId?: string; ratingId?: string }[]
}

export interface Group {
  id: string
  name: string
  companyId: string
}

export interface Id {
  id: string
}

export interface CreateProfilePayload {
  name: string
  scores: Scores
}

export interface CreateProfileGroupPayload {
  name: string
}

export const CHILD_PROFILE_PREFIX = "profile."
