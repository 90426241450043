import React, { useState } from "react"
import { translations } from "../i18n"
import { Group, Profile, ProfileDetails } from "../models/profile"
import { css } from "emotion"
import Axios from "axios"
import { Flex } from "../../shared/components/ui/flex"
import { ScrollBox } from "../../shared/components/ui/scroll-box"
import { lanaApiUrl } from "../../app_config"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd"
import { ProfileDnDGroup } from "./profile-dnd-group"
import { ProfileDnD } from "./profile-dnd"
import { ProfileDependencyAlert } from "./profile-dependency-alert"
import {
  getAssessmentDownloadScoreSettings,
  SETTINGS_KEY,
} from "../../assessment/components/assessment-download-scores"
import { getRelasAuthScopes } from "../../relas/user-state"
import { useAppSelector } from "../../relas/store"
import {
  addGroupStart,
  deleteGroup,
  deleteProfile,
  doDNDGroup,
  fetchProfile,
  renameGroupStart,
  setCurrentProfile,
  startAddProfile,
  startDuplicateProfile,
  startRenameProfile,
  updateProfileLocked,
} from "../profile-slice"
import { AlertBox } from "../../shared/components/alertbox"
import Card from "../../shared/components/card"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import Text from "../../shared/components/text"
import Grid from "../../shared/components/restyle-grid/grid"
import LoadingSpinner from "../../shared/components/loadingspinner"
import Table from "../../shared/components/table"
import Button from "../../shared/components/button"

const profilesCta = css({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  padding: "0 10px",
  position: "relative",
  top: "10px",
})

const styles = {
  profileListWrap: css({
    height: "100%",
    margin: "-15px",
  }),
  profileWithoutGroup: css({
    td: {
      padding: "10px 0 10px 10px !important",
    },
  }),
}

export const ProfileList = () => {
  const translation = translations()

  const profileList = useAppSelector((state) => state.profile.profileList)
  const inProgress = useAppSelector(
    (state) => state.profile.profileListInProgress || state.profile.profileDeleteInProgress
  )
  const error = useAppSelector((state) => state.profile.profileListError || state.profile.profileDeleteError)
  const currentProfile = useAppSelector((state) => state.profile.profile)
  const agsRefResLoc = useAppSelector((state) => state.profile.agsRefResLoc)
  const selectedSmartdataSource = useAppSelector((state) => state.profile.smartdataSource)
  const groupList = useAppSelector((state) => state.profile.groupList)
  const profileDependencies = useAppSelector((state) => state.profile.profileDependencies)
  const dependenciesInProgress = useAppSelector((state) => state.profile.profileDependenciesInProgress)
  const userId = useAppSelector((state) => state.user.user?.id)

  const isCompanyAdmin = getRelasAuthScopes().includes("CA")

  const [profileToDelete, setProfileToDelete] = useState<Profile | null>(null)
  const [unableToDelete, setUnableToDelete] = useState<ProfileDetails | null>(null)
  const [groupToDelete, setGroupToDelete] = useState<Group | null>(null)
  const [collapsedGroup, setCollapsedGroup] = useState<Array<string>>([])
  const [profileToLock, setProfileToLock] = useState<Profile | null>(null)

  function onClickProfile(p: Profile) {
    setCurrentProfile(p, selectedSmartdataSource, agsRefResLoc)
  }

  const doCollapsedGroup = (groupId: string) => {
    collapsedGroup.indexOf(groupId) != -1
      ? setCollapsedGroup(collapsedGroup.filter((id) => id !== groupId))
      : setCollapsedGroup([...collapsedGroup, groupId])
  }

  return (
    <Grid height={[100, "%"]} columns={1}>
      {profileToDelete && renderDeleteConfirm(profileToDelete)}
      {unableToDelete && renderUnableToDelete(unableToDelete)}
      {groupToDelete && renderGroupConfirm(groupToDelete)}
      {profileToLock && renderLockConfirm(profileToLock)}
      <Card id="profileList" header={<h2>{translation.profileList.title}</h2>}>
        <div className={styles.profileListWrap}>
          <Flex flexDirection="column" gap={16} height={[100, "%"]}>
            {inProgress && (
              <div>
                <LoadingSpinner color="primary" />
              </div>
            )}
            {profileList && groupList && renderList(profileList, groupList)}

            {error && <GenericErrorPanel error={error} />}
          </Flex>
        </div>
      </Card>
    </Grid>
  )

  function renderList(profiles: Profile[], groups: Group[]) {
    if (!userId) {
      return <></>
    }

    const sortedGroups = [...groups].sort((a, b) => a.name.localeCompare(b.name))

    return (
      <>
        <ScrollBox>
          <DndProvider backend={HTML5Backend}>
            <Table selectable={true}>
              <tbody className={styles.profileWithoutGroup}>
                {profiles
                  .filter((profile) => profile.groupId === undefined)
                  .map((profile, index) => (
                    <ProfileDnD
                      profile={profile}
                      index={index}
                      key={profile.id}
                      onClickProfile={onClickProfile}
                      doFetchProfile={fetchProfile}
                      agsRefResLoc={agsRefResLoc}
                      onDelete={onDelete}
                      doProfileDuplicateStart={startDuplicateProfile}
                      doProfileRenameStart={startRenameProfile}
                      setProfileToDelete={setProfileToDelete}
                      doUpdateProfileLocked={updateProfileLocked}
                      setProfileToLock={setProfileToLock}
                      setUnableToDelete={setUnableToDelete}
                      dndGroup={doDNDGroup}
                      currentProfile={currentProfile}
                      currentUserId={userId}
                      isCompanyAdmin={isCompanyAdmin}
                    />
                  ))}
              </tbody>
            </Table>
            <Table>
              <tbody>
                {sortedGroups.map((group) => (
                  <ProfileDnDGroup
                    key={group.id}
                    profiles={profiles}
                    group={group}
                    doGroupRenameStart={renameGroupStart}
                    renderGroupConfirm={renderGroupConfirm}
                    onClickProfile={onClickProfile}
                    doFetchProfile={fetchProfile}
                    agsRefResLoc={agsRefResLoc}
                    onDelete={onDelete}
                    doProfileDuplicateStart={startDuplicateProfile}
                    doProfileRenameStart={startRenameProfile}
                    setProfileToDelete={setProfileToDelete}
                    setProfileToLock={setProfileToLock}
                    setUnableToDelete={setUnableToDelete}
                    dndGroup={doDNDGroup}
                    setGroupToDelete={setGroupToDelete}
                    currentProfile={currentProfile}
                    setCollapsedGroup={doCollapsedGroup}
                    collapsedGroup={collapsedGroup}
                    doUpdateProfileLocked={updateProfileLocked}
                    currentUserId={userId}
                    isCompanyAdmin={isCompanyAdmin}
                  />
                ))}
              </tbody>
            </Table>
          </DndProvider>
        </ScrollBox>
        <Flex flexDirection="row">
          <div className={profilesCta}>
            <Button type="secondary" onClick={addGroupStart}>
              {translation.profileAdd.group}
            </Button>
            <Button type="primary" icon="add" onClick={startAddProfile}>
              {translation.profileAdd.add}
            </Button>
          </div>
        </Flex>
      </>
    )
  }

  function onDelete(profile: Profile) {
    return () => {
      Axios.get<ProfileDetails>(`${lanaApiUrl}/api/profiles/${profile.id}`).then(
        (success) => {
          if (success.data.dependencies.length === 0) setProfileToDelete(profile)
          else setUnableToDelete(success.data)
        },
        () => {}
      )
    }
  }

  function renderGroupConfirm(group: Group) {
    return (
      <AlertBox
        header={translation.groupDelete.title}
        onClose={() => setGroupToDelete(null)}
        actions={[
          {
            label: translation.groupDelete.menuItem,
            icon: "delete",
            action: () => {
              void deleteGroup(group.id)
              setGroupToDelete(null)
            },
          },
          { label: translation.cancel, action: () => setGroupToDelete(null) },
        ]}
      >
        <Text>{translation.groupDelete.confirm(group.name)}</Text>
      </AlertBox>
    )
  }

  function renderLockConfirm(profile: Profile) {
    const t = translation.profileLock
    const isLocked = profile.lockedBy !== undefined && profile.lockedBy !== null

    return (
      <AlertBox
        header={isLocked ? t.unlockTitle : t.lockTitle}
        onClose={() => setProfileToLock(null)}
        actions={[
          {
            label: isLocked ? t.unlockMenuItem : t.lockMenuItem,
            icon: isLocked ? "unlocked" : "locked",
            action: () => {
              profileToLock && profileList && void updateProfileLocked(profileToLock.id, !isLocked)
              setProfileToLock(null)
            },
          },
          { label: translation.cancel, action: () => setProfileToLock(null) },
        ]}
      >
        <Text>{isLocked ? t.unlockConfirm : t.lockConfirm}</Text>
      </AlertBox>
    )
  }

  function renderDeleteConfirm(profile: Profile) {
    return (
      <AlertBox
        header={translation.profileDelete.title}
        onClose={() => setProfileToDelete(null)}
        actions={[
          {
            label: translation.profileDelete.menuItem,
            icon: "delete",
            action: () => {
              const selectedProfilesInStorage = getAssessmentDownloadScoreSettings().selectedProfiles
              window.localStorage.setItem(
                SETTINGS_KEY,
                JSON.stringify({
                  selectedProfiles: selectedProfilesInStorage.filter((id) => id !== profileToDelete?.id),
                })
              )
              profileToDelete && profileList && void deleteProfile(profileToDelete.id)
              setProfileToDelete(null)
            },
          },
          { label: translation.cancel, action: () => setProfileToDelete(null) },
        ]}
      >
        <Text>{translation.profileDelete.confirm(profile.name)}</Text>
      </AlertBox>
    )
  }

  function renderUnableToDelete(profile: ProfileDetails) {
    // TODO: proper way to handle loading/error status
    const dependencies = profileDependencies ? profileDependencies.dependencies[profile.id] : null

    return (
      <ProfileDependencyAlert
        setUnableToDelete={setUnableToDelete}
        profile={profile}
        dependenciesLoading={dependenciesInProgress}
        dependentProfileNames={dependencies !== null ? Object.values(dependencies.profiles) : []}
        dependentRatingNames={dependencies !== null ? Object.values(dependencies.ratings) : []}
        dependentSelectionNames={dependencies !== null ? Object.values(dependencies.selections) : []}
      />
    )
  }
}
