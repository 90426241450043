/* tslint:disable */
/* eslint-disable */
/**
 * Comparables Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum MarketingType {
    Purchase = 'PURCHASE',
    PurchasePerSqm = 'PURCHASE_PER_SQM',
    Rent = 'RENT',
    RentPerSqm = 'RENT_PER_SQM',
    Lease = 'LEASE',
    Leasehold = 'LEASEHOLD',
    BudgetRent = 'BUDGET_RENT',
    RentAndBuy = 'RENT_AND_BUY'
}



