import * as React from "react"
import { css, cx, keyframes } from "emotion"
import { BrandingColor, UIState } from "../util/colorconstants"
import { getThemeColorVar } from "../helper/color"
import { RevoSize } from "../util/layoutconstants"

const opacityKeyFrames = keyframes({
  "0%": { opacity: 1 },
  "100%": { opacity: 0.25 },
})

const loadingSpinnerOuterClassCentered = css({
  display: "inline-block",
  position: "relative",
  alignSelf: "center",
  verticalAlign: "top",
  left: "50%",
  transform: "translateX(-50%)",
})

const loadingSpinnerOuterClassLeft = css({
  display: "inline-block",
  position: "relative",
  alignSelf: "center",
  verticalAlign: "top",
})

const loadingSpinnerTickOuterClass = css({
  position: "absolute",
  left: 0,
  top: "50%",
})

function loadingSpinnerTickInnerClass(color?: UIState | BrandingColor, inverted?: boolean): string {
  const backgroundColor = color
    ? getThemeColorVar(color, inverted ? "inverted" : "default")
    : getThemeColorVar(inverted ? "white" : "black", undefined)

  return css({
    backgroundColor,
    position: "absolute",
    top: 0,
    bottom: 0,
    right: "10%",
    width: "25%",
    animation: `${opacityKeyFrames} 1s linear infinite`,
    transform: "translateZ(0)",
  })
}

export interface LoadingSpinnerProps {
  color?: UIState | BrandingColor
  size?: RevoSize
  inverted?: boolean
  alignLeft?: boolean
}

const ticks = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

export const LoadingSpinner: React.FunctionComponent<LoadingSpinnerProps> = (props) => {
  const size = props.size ? props.size : 80
  const tickHeight = size / 20.0
  const borderRadius = size / 40.0
  const outerPos = (size - tickHeight) / 2.0
  const angle = 360 / ticks.length
  const duration = `${ticks.length}00ms`

  return (
    <div
      className={cx(
        "revo-loading-spinner" /* The revo-loading spinner class is important for the button. I have no better idea how to handle that */,
        props.alignLeft ? loadingSpinnerOuterClassLeft : loadingSpinnerOuterClassCentered
      )}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      {ticks.map((i) => (
        <div
          key={i}
          className={loadingSpinnerTickOuterClass}
          style={{
            width: `${size}px`,
            height: `${tickHeight}px`,
            top: `${outerPos}px`,
            transform: `rotate(${-i * angle}deg)`,
          }}
        >
          <div
            className={loadingSpinnerTickInnerClass(props.color, props.inverted)}
            style={{
              borderRadius: `${borderRadius}px`,
              animationDuration: duration,
              animationDelay: `-${i}00ms`,
            }}
          />
        </div>
      ))}
    </div>
  )
}

export default LoadingSpinner
