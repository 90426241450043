import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { isMetaRating, MetaRating, Rating, RatingLike, RatingResults } from "../shared/models/ratings"
import { microRatingFirstAgsRefLoc, RatingDetail } from "../explorer/models/rating"
import { SelectionResults } from "../shared/models/selection"
import { GenericError } from "../shared/helper/axios"

export type MunicipalityAndFocus = {
  selectedMunicipalityId: string
  focusInMap: boolean
}

export interface RatingManagerState {
  ratingList: Rating[]
  metaRatingList: MetaRating[]
  listInProgress: boolean
  currentRating?: RatingDetail | MetaRating
  selectedRatingSelectionIdx: number
  selectedRatingSelectionRuleIdx?: number
  getInProgress: boolean
  ratingGetError?: GenericError
  listError?: GenericError
  updateInProgress: boolean
  updateError?: GenericError

  menuPane: "list" | "create" | "edit" | "edit-rating" | "duplicate" | "rename"

  ratingResults?: RatingResults | SelectionResults
  ratingResultsInProgress: boolean
  ratingResultsError?: GenericError
  showMunicipalityList: boolean
  agsRefResLoc?: string
  selectedMunicipalityAndFocus?: MunicipalityAndFocus
  showPreview: boolean
}

export const initialState: RatingManagerState = {
  ratingList: [],
  metaRatingList: [],
  listInProgress: false,
  getInProgress: false,
  selectedRatingSelectionIdx: 0,
  selectedRatingSelectionRuleIdx: undefined,
  updateInProgress: false,
  menuPane: "list",
  ratingResultsInProgress: false,
  showMunicipalityList: false,
  showPreview: false,
}

const ratingManagerSlice = createSlice({
  name: "ratingManager",
  initialState,
  reducers: {
    listLoadStart(state) {
      state.listInProgress = true
      state.listError = undefined
    },
    listDone(state, action: PayloadAction<{ simple: Rating[]; meta: MetaRating[] }>) {
      state.ratingList = action.payload.simple
      state.metaRatingList = action.payload.meta
      state.listInProgress = false
    },
    listError(state, action: PayloadAction<GenericError>) {
      state.listInProgress = false
      state.listError = action.payload
    },
    ratingCreateDone(state, action: PayloadAction<RatingDetail | MetaRating>) {
      if (isMetaRating(action.payload)) {
        state.metaRatingList.push(action.payload)
      } else {
        state.ratingList.push(action.payload)
        state.currentRating = action.payload
        if (action.payload.dataSetType === "micro") {
          state.showMunicipalityList = false
        }
      }

      state.ratingList = state.ratingList.sort((a, b) => a.name.localeCompare(b.name))
      state.metaRatingList = state.metaRatingList.sort((a, b) => a.name.localeCompare(b.name))

      state.selectedRatingSelectionIdx = 0
      state.selectedRatingSelectionRuleIdx = undefined
      state.ratingResults = undefined
    },
    ratingDeleteDone(state, action: PayloadAction<RatingLike>) {
      if (isMetaRating(action.payload)) {
        state.metaRatingList = state.metaRatingList?.filter((r) => r.id !== action.payload.id)
      } else {
        state.ratingList = state.ratingList?.filter((r) => r.id !== action.payload.id)
      }

      state.currentRating = undefined
      state.selectedRatingSelectionIdx = 0
      state.selectedRatingSelectionRuleIdx = undefined
      state.ratingResults = undefined
      state.showMunicipalityList = false
    },
    clearErrors(state) {
      state.listError = undefined
    },
    ratingGetStart(state) {
      state.getInProgress = true
      state.ratingGetError = undefined
    },
    ratingGetDone(state, action: PayloadAction<RatingDetail>) {
      state.getInProgress = false
      state.currentRating = action.payload
      if (action.payload.dataSetType === "micro") {
        state.showMunicipalityList = false
      }
      state.selectedRatingSelectionIdx = 0
      state.ratingGetError = undefined
    },
    ratingGetError(state, action: PayloadAction<GenericError<any>>) {
      state.getInProgress = false
      state.ratingGetError = action.payload
    },
    ratingUpdateStart(state) {
      state.updateInProgress = true
      state.updateError = undefined
    },
    ratingUpdateDone(state, action: PayloadAction<RatingDetail | MetaRating>) {
      state.updateInProgress = false
      state.updateError = undefined
      state.currentRating = action.payload
      if (isMetaRating(action.payload)) {
        state.metaRatingList = state.metaRatingList
          ?.map((r) => (r.id === action.payload.id ? (action.payload as MetaRating) : r))
          .sort((a, b) => a.name.localeCompare(b.name))
      } else {
        if (action.payload.dataSetType === "micro") {
          state.showMunicipalityList = false
        }
        state.ratingList = state.ratingList
          ?.map((r) => (r.id === action.payload.id ? (action.payload as RatingDetail) : r))
          .sort((a, b) => a.name.localeCompare(b.name))
      }
    },
    updateError(state, action: PayloadAction<GenericError>) {
      state.updateInProgress = false
      state.updateError = action.payload
    },

    setCurrentRating(state, action: PayloadAction<RatingDetail | MetaRating | undefined>) {
      state.currentRating = action.payload
      if (action.payload && !isMetaRating(action.payload) && action.payload.dataSetType === "micro") {
        state.agsRefResLoc = microRatingFirstAgsRefLoc(action.payload)
        state.showMunicipalityList = false
      } else {
        state.agsRefResLoc = undefined
      }
      if (action.payload === undefined) {
        state.showMunicipalityList = false
      }
    },
    setSelectedRatingRule(state, action: PayloadAction<{ selectionIdx: number; selectionRuleIdx?: number }>) {
      state.selectedRatingSelectionIdx = action.payload.selectionIdx
      state.selectedRatingSelectionRuleIdx = action.payload.selectionRuleIdx
      state.selectedMunicipalityAndFocus = undefined
    },
    setShowMunicipalityList(state, action: PayloadAction<boolean>) {
      state.showMunicipalityList = action.payload
    },
    setAgsRefResLoc(state, action: PayloadAction<string | undefined>) {
      state.agsRefResLoc = action.payload
    },
    setSelectedMunicipalityAndFocus(state, action: PayloadAction<MunicipalityAndFocus | undefined>) {
      state.selectedMunicipalityAndFocus = action.payload
    },
    startLoadingRatingResults(state) {
      state.ratingResultsInProgress = true
      state.ratingResultsError = undefined
    },
    ratingResultsDone(state, action: PayloadAction<RatingResults>) {
      state.ratingResults = action.payload
      state.ratingResultsInProgress = false
      state.ratingResultsError = undefined
    },
    ratingResultsError(state, action: PayloadAction<GenericError>) {
      state.ratingResultsInProgress = false
      state.ratingResultsError = action.payload
      state.ratingResults = undefined
    },
    setMenuPane(state, action: PayloadAction<RatingManagerState["menuPane"]>) {
      state.menuPane = action.payload
    },
    setShowPreview(state, action: PayloadAction<boolean>) {
      state.showPreview = action.payload
    },
  },
})

export const ratingManagerActions = ratingManagerSlice.actions

export default ratingManagerSlice.reducer
