import { Panel } from "@blueprintjs/core"
import { AddressListColumn, FileProperties } from "../../assessment/models/assessment"
import { AddressSuggestion } from "../../shared/models/poi-explorer"
import { Location } from "../../assessment/models/address"
import { CancelTokenSource } from "axios"
import { POIObjectMarkerType } from "../../shared/components/private-poi-clusters"
import { GenericError } from "../../shared/helper/axios"

export type PrivateDataTabType = "data" | "map"

export type PrivatePOICategory = {
  id: string
  companyId: string
  title: string
  icon: string
  notes: string
  lockedBy?: string
}

export type PrivatePOIErrorResponse = {
  code: number // 400
  message: string // "Bad request" | "Unauthorized" | "Forbidden" | "Service unavailable",
  details?:
    | "file is required"
    | "Incorrect format"
    | { error: "InvalidFileType" }
    | { error: "NoRows" }
    | { error: "MissingColumns"; missing: string[] }
}

export type AddressListImportError = GenericError & { data?: PrivatePOIErrorResponse }

export type PrivatePOICategoriesList = Array<PrivatePOICategory>

export interface PoisToShowData {
  module?: AllowedModules
  poiList: PrivatePOIList
}

export enum AllowedModulesEnum {
  COMPARABLES = "comparables",
  POI_EXPLORER = "poiExplorer",
  SPECIAL_MAPS = "specialMaps",
  DISTRICT_DATA = "districtData",
  PROFILE_MANAGER = "profileManager",
  RATING_MANAGER = "ratingManager",
  RATINGS = "ratings",
  LOCATION_SELECTOR = "locationSelector",
  RATINGS_SCORE_PRICES = "ratingsScorePrices",
  QUICK_START = "quickStart",
  FUNDAMENTAL_DATA = "fundamentalData",
}

export type AllowedModules = AllowedModulesEnum

export type PrivateDataModuleSettings = {
  selectedCategories: PrivatePOICategoriesList
  multipleCategoryPOIList: MultipleCategoryPOIs
  poisToShow: PrivatePOIList
}

export type PrivateDataModuleSettingsCollection = {
  [key in AllowedModules]: PrivateDataModuleSettings
}
export interface UpdatePrivatePOICategoriesListResponse {
  module: AllowedModules
  categories: PrivatePOICategoriesList
}

export interface CreateOrEditCategoryPanelInfo {
  category?: PrivatePOICategory
}

export interface CategoriesListPanelInfo {
  setCategoryToLock: (category: PrivatePOICategory) => void
  userId: string
  isAdmin: boolean
  loadedCategoryListIsEmpty: boolean
  addToPanelStack: (newPanel: Panel<CategoriesListPanelInfo | CreateOrEditCategoryPanelInfo>) => void
}

export interface MultipleCategoryPOIs {
  [key: string]: PrivatePOIList
}

export interface MultipleCategoryPOIsResponse {
  module: AllowedModules
  data: MultipleCategoryPOIs
}
export type StructuredData = [string, string][]
export interface Address {
  country: string
  postalCode: string
  locality: string
  route: string
  streetNumber: string
  location?: Location
}
export interface PrivatePOI {
  id: string
  companyAssignedId?: string
  title: string
  address: Address
  originalAddress: Address
  notes: string
  droppedLocation?: Location
  structuredData?: StructuredData
  state: InProgressState | ErrorState | SuccessState // INCOMING STATE OF GEOCODED POI
}
export enum PrivatePOIState {
  Initial = "INITIAL",
  Updated = "UPDATED",
  Geocoded = "GEOCODED",
  GeocodedAmbiguous = "GEOCODED_AMBIGUOUS",
  Error = "ERROR",
}
export const isInProgressState = (poi: PrivatePOI) =>
  new Set([PrivatePOIState.Initial, PrivatePOIState.Updated]).has(poi.state)
type InProgressState = PrivatePOIState.Initial | PrivatePOIState.Updated

type ErrorState = PrivatePOIState.GeocodedAmbiguous | PrivatePOIState.Error

type SuccessState = PrivatePOIState.Geocoded

export type PrivatePOIList = Array<PrivatePOI>

export const privatePOIsIcons: Array<string> = [
  "100",
  "101",
  "300",
  "301",
  "200",
  "400",
  "500",
  "501",
  "red_pin",
  "600",
  "601",
  "900",
  "901",
  "902",
  "000",
  "002",
]

export enum DeleteModes {
  CATEGORY = "category",
  POI = "poi",
}
export interface DeleteData {
  mode: DeleteMode
  selection: DeleteSelection
}

export interface CategoryFormData {
  title: string
  notes: string
  icon: string
}
export interface PrivatePOICategoryToCreateOrEdit {
  categoryData: CategoryFormData
  editedCategory?: PrivatePOICategory
}

export interface PrivatePOIToCreate {
  poiData: POIToSubmit
  categoryId: string
}
export interface PrivatePOIToEdit extends PrivatePOIToCreate {
  poiId: string
}

export type PrivatePOIToCreateOrEdit = PrivatePOIToCreate | PrivatePOIToEdit

export interface POIFormData {
  title: string
  companyAssignedId: string
  address: Address
  notes: string
  droppedLocation?: Location
  structuredData?: StructuredData
}

export interface POIToSubmit {
  title: string
  companyAssignedId?: string
  address: Address
  notes: string
  droppedLocation?: Location
  structuredData?: StructuredData
}

type POICreateOrEditErrorType = "MissingColumns" | "NoRows" | "InvalidFileType" | "TooManyEntries"

export type PoiFormDataErrors = GenericError<{ details?: "Limit for POIs in this category reached" }> & {
  error?: POICreateOrEditErrorType
  missing?: AddressListColumn[]
  fileProperties?: FileProperties
}

export interface SearchData {
  suggestions: AddressSuggestion[]
  zoom: number
  center: number[]
  showSuggestions: boolean
  tryToAddDuplicate: boolean
  manualCoords: boolean
  droppedLocation: Location | undefined
  droppedLocationGeocodingLoading: boolean
  cancel: CancelTokenSource | undefined
}
export type DeleteMode = DeleteModes | undefined
export type ToDeleteSelection = PrivatePOICategory | Array<string>
export type DeleteSelection = ToDeleteSelection | undefined

export const initialPrivateDataModuleSettings = (): PrivateDataModuleSettings => ({
  selectedCategories: [],
  multipleCategoryPOIList: {},
  poisToShow: [],
})

const getMarker = (poi: PrivatePOI): POIObjectMarkerType => ({
  type: "Feature",
  properties: {
    cluster: false,
    poiId: poi.id,
  },
  geometry: {
    type: "Point",
    coordinates: poi.droppedLocation
      ? [(poi.droppedLocation as Location).lng, (poi.droppedLocation as Location).lat]
      : [(poi.address.location as Location).lng, (poi.address.location as Location).lat],
  },
})
export const generatePoiMarkers = (poiList: PrivatePOIList): POIObjectMarkerType[] => {
  return poiList.reduce(
    (acc: POIObjectMarkerType[], poi: PrivatePOI) => (poi.address.location ? [...acc, getMarker(poi)] : acc),
    []
  )
}
