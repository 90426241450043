import * as React from "react"
import { css, cx } from "emotion"
import { getThemeColorVar } from "../helper/color"

export interface Props {
  id?: string
  label?: string | React.ReactNode
  checked?: boolean
  autoFocus?: boolean
  disabled?: boolean
  defaultChecked?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  required?: boolean
}

const defaultProps: Props = {}

const checkboxClass = css({
  position: "relative",
  display: "block",
  marginTop: "10px",
  marginBottom: "10px",

  "> label": {
    minHeight: "20px",
    paddingLeft: "20px",
    marginBottom: 0,
    fontWeight: "normal",
    cursor: "pointer",
  },

  "input:disabled": {
    cursor: "not-allowed",
  },

  "input:focus": {
    outline: "thin dotted 5px auto -webkit-focus-ring-color",
    outlineOffset: "-2px",
  },

  '> label > input[type="checkbox"]': {
    position: "absolute",
    marginLeft: "-20px",
    marginTop: "4px",
  },

  'input[type="checkbox"] + input[type="checkbox"]': {
    marginTop: "-5px",
  },
})

const requiredMarkerClass = css({
  color: getThemeColorVar("negative", "default"),
  fontWeight: 600,
})

export const Checkbox: React.FunctionComponent<Props> = (props) => {
  return (
    <div className={cx(checkboxClass)}>
      <label>
        <input
          type="checkbox"
          id={props.id}
          autoFocus={props.autoFocus}
          disabled={props.disabled}
          defaultChecked={props.defaultChecked}
          checked={props.checked}
          onChange={props.onChange}
          required={props.required}
        />
        {props.label}
        {props.required && <span className={cx(requiredMarkerClass)}> *</span>}
      </label>
    </div>
  )
}

Checkbox.defaultProps = defaultProps

export default Checkbox
