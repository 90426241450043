import React, { PropsWithChildren, ReactNode } from "react"
import Button from "../../shared/components/button"
import { PriceWidgetHeader } from "../../assessment/components/price-widget-header"
import { getThemeColorVar } from "../../shared/helper/color"
import { css, cx } from "emotion"
import Grid from "../../shared/components/restyle-grid/grid"

type Props = PropsWithChildren<{
  title: string
  info?: string | ReactNode
}>
const styles = {
  pricesWidgetStyle: css({
    padding: "14px 12px",
    fontSize: "13px",
    boxShadow: "none",
    border: `1px solid ${getThemeColorVar("background", "light")}`,
  }),
  info: css({
    fontSize: "14px",
    color: "#757576",
    paddingLeft: "20px",
    borderLeft: `4px solid ${getThemeColorVar("primary", "default")}`,
  }),
}

export const PriceWidget = ({ children, title, info }: Props) => {
  const [showInfo, setShowInfo] = React.useState(false)

  return (
    <div className={cx("dashboard-widget", styles.pricesWidgetStyle)}>
      <Grid
        columns={1}
        rowSpec={showInfo ? "min-content min-content 1fr" : "min-content 1fr"}
        gap={16}
        height={[100, "%"]}
      >
        <PriceWidgetHeader title={title}>
          <span style={{ paddingLeft: "9px", verticalAlign: "middle" }}>
            <Button type={"primary"} icon={"info"} inverted onClick={() => setShowInfo(!showInfo)} />
          </span>
        </PriceWidgetHeader>
        {showInfo && info && <div className={styles.info}>{info}</div>}
        {children}
      </Grid>
    </div>
  )
}
