import * as React from "react"
import { getInitialMapStyle, MapStyle } from "../../../shared/components/map-style-control"
import * as layer from "ol/layer"
import { bind } from "decko"
import { css, cx } from "emotion"
import * as source from "ol/source"
import * as ol from "ol"
import * as extend from "ol/extent"
import { Geometry } from "ol/geom"
import RenderFeature from "ol/render/Feature"
import * as style from "ol/style"
import Style, { StyleFunction } from "ol/style/Style"
import { transformExtent } from "ol/proj"
import { PopupFact } from "../../../shared/components/ui/map-popup"
import { fetchAgsResLocViewport } from "../../../shared/actions/map-actions"
import { mapStyles } from "./rating-manager-map-styles"
import { Flex } from "../../../shared/components/ui/flex"
import {
  AllowedModulesEnum,
  PrivateDataModuleSettings,
  PrivatePOICategoriesList,
  PrivatePOIList,
} from "../../../private-data/models/private-data"
import Layer from "ol/layer/Layer"
import Source from "ol/source/Source"
import { MapViewProps, MapViewState, RatingManagerMapView } from "./rating-manager-map-view"
import Feature from "ol/Feature"
import { cellVectorTileOptions, refRegionVectorTileOptions } from "../../../utils/openlayers"
import Toggle from "../../../shared/components/toggle"

interface Props extends MapViewProps {
  loading: boolean
  agsRefResLoc?: string
  onSelectAgsRefResLoc?: (agsRefResLoc: string) => void
  showStroke: boolean
  initialMapStyle?: MapStyle
  onPreviewToggle: () => void
  preview: boolean
  hasPreview: boolean
  isPrivateDataAccessible: boolean
  privatePoiCategories: PrivatePOICategoriesList
  privateDataSettings: PrivateDataModuleSettings
  getPrivateDataCategories: () => void
  updateSelectedPrivateDataCategories: (categories: PrivatePOICategoriesList, module: AllowedModulesEnum) => void
  getPrivatePoisFromCategories: (categoryId: string, module?: AllowedModulesEnum) => void
  updatePrivatePoisToShow: (poiList: PrivatePOIList, module?: AllowedModulesEnum) => void
}

interface OwnState extends MapViewState {
  hoverRegion: string | null
  hoverRegionName: string | null
  selectMicroId: string | null
}

const shapeFitOpts = { padding: [50, 50, 50, 50] }

export class RatingManagerMapMicroView extends RatingManagerMapView<Props, OwnState> {
  private regionLayer: layer.VectorTile | undefined = undefined

  constructor(props: Props) {
    super(props)

    this.state = {
      hoverRegion: null,
      hoverRegionName: null,
      selectMicroId: null,
      selectedMapStyle: getInitialMapStyle(props.initialMapStyle),
      showPrivatePoisPopover: false,
      poiPopUpPosition: undefined,
    }
  }

  componentDidMount() {
    super.componentDidMount()

    this.regionLayer = new layer.VectorTile({
      declutter: true,
      source: new source.VectorTile(refRegionVectorTileOptions()),
      style: this.regionStyle,
    })

    this.contentLayer = new layer.VectorTile({
      declutter: true,
      source: new source.VectorTile(cellVectorTileOptions()),
      style: this.cellTilesStyle(),
    })
    this.getMap().addLayer(this.regionLayer)
    this.getMap().addLayer(this.contentLayer)
    this.getMap().on("pointermove", this.onPointerMove)
  }

  componentDidUpdate(prevProps: Props, prevState: OwnState) {
    if (prevProps.items !== this.props.items) {
      this.overlay?.closeCurrentOverlay(true)
      this.contentLayer?.changed()
    }

    if (
      prevProps.agsRefResLoc !== this.props.agsRefResLoc ||
      (prevProps.items !== this.props.items && this.props.items.size === 0 && this.props.locationToPresent)
    ) {
      void this.resetMap()
    }
    this.contentLayer?.setVisible(this.props.mapErrorMessage !== "shape_outdated")
    super.componentDidUpdate(prevProps, prevState)
  }

  @bind
  protected onMoveEnd() {}

  @bind
  private onPointerMove(event: ol.MapBrowserEvent<any>) {
    const features = this.getMap().getFeaturesAtPixel(event.pixel, {
      layerFilter: (layer) => layer === this.regionLayer,
    })

    const coordinate = this.getMap().getCoordinateFromPixel(event.pixel)
    if (features.length < 1 || !extend.containsCoordinate(features[0].getGeometry()?.getExtent() ?? [], coordinate)) {
      this.cleanHoverRegion()
      return
    }

    const id = features[0]?.getId()?.toString()

    if (
      this.props.onSelectAgsRefResLoc &&
      id !== this.props.agsRefResLoc &&
      this.props.locationToPresent === undefined
    ) {
      if (id !== undefined && id != this.state.hoverRegion) {
        this.setState(
          {
            hoverRegion: id,
            hoverRegionName: `${features[0].getProperties()["BEZ"]} ${features[0].getProperties()["GEN"]}`,
          },
          () => {
            if (this.overlay?.getRendering()) {
              this.getMap().once("rendercomplete", () => this.regionLayer?.changed())
            } else {
              this.regionLayer?.changed()
            }
          }
        )
      }
    } else {
      this.cleanHoverRegion()
    }
  }

  @bind
  override isClickableLayers(layer: Layer<Source>): boolean {
    return super.isClickableLayers(layer) || layer === this.regionLayer
  }

  // why does this make new stuff show on map?
  @bind
  protected onMapClick(event: ol.MapBrowserEvent<any>): void {
    const features = this.getMap().getFeaturesAtPixel(event.pixel, {
      layerFilter: (layer) => this.isClickableLayers(layer),
    })

    if (features.length > 0 && features[0].getProperties().features?.[0]?.id_.includes("category:")) {
      this.overlay?.closeCurrentOverlay(true)
      const featuresArray: ol.Feature[] = features[0].getProperties().features
      const geometry = features[0].getProperties().geometry

      const categoryId = featuresArray[0].getId()?.toString().split(":")[1] ?? ""
      const poiIdsToShow: string[] = featuresArray.map((feature) => feature.getId()?.toString().split(":")[2] ?? "")
      const poisToShow: PrivatePOIList = (
        this.props.privateDataSettings.multipleCategoryPOIList[categoryId] ?? []
      ).filter((poi) => poiIdsToShow.includes(poi.id))
      const position = geometry.getCoordinates()
      this.setState({ ...this.state, poiPopUpPosition: position })
      this.props.updatePrivatePoisToShow(poisToShow, this.props.module)
    } else {
      if (this.state.poiPopUpPosition) this.onPoiPopUpClose()
      const coordinate = this.getMap().getCoordinateFromPixel(event.pixel)

      if (features.length < 1 || !extend.containsCoordinate(features[0].getGeometry()?.getExtent() ?? [], coordinate)) {
        return
      }

      if (this.props.locationToPresent) {
        return
      }

      const id = features[0].getId()?.toString()

      if (id !== this.props.agsRefResLoc) {
        this.overlay?.closeCurrentOverlay(true)
        this.props.onSelectAgsRefResLoc?.call(null, id)
        this.cleanHoverRegion()
      }
    }
  }

  @bind
  private cleanHoverRegion() {
    if (this.state.hoverRegion) {
      this.setState(
        {
          hoverRegion: null,
          hoverRegionName: null,
        },
        () => {
          if (this.overlay?.getRendering()) this.getMap().once("rendercomplete", () => this.regionLayer?.changed())
          else this.regionLayer?.changed()
        }
      )
    }
  }

  protected fillAdditionalItemsForOverlay(item: RatingManagerMapItem, additionalItems: PopupFact[]): void {
    if (additionalItems.length === 0) {
      additionalItems.push({ label: this.t.profileScoreTitle, value: "-" })
    }
  }

  @bind
  protected onSelection(id: string | null) {
    this.setState({ selectMicroId: id }, () => this.contentLayer?.changed())
  }

  @bind
  private cellTilesStyle(): StyleFunction {
    return (feature) => {
      const macroItem = this.props.items.get(feature.getId()?.toString() ?? "")
      if (!macroItem) return new style.Style()
      const selected = this.state.selectMicroId === feature.getId()?.toString()
      const currZoom = this.getMap().getView().getZoom() ?? 0

      return new style.Style({
        stroke:
          this.props.showStroke && currZoom > 11.5 && selected
            ? new style.Stroke({
                color: "rgba(255,0,0,0.8)",
                width: 2.0,
              })
            : undefined,
        fill: new style.Fill({
          color: macroItem.color,
        }),
      })
    }
  }

  @bind
  protected regionStyle(feature: Feature<Geometry> | RenderFeature): Style {
    if (
      feature.getId() &&
      feature.getId() !== this.props.agsRefResLoc &&
      this.state.hoverRegion &&
      feature.getId()?.toString() === this.state.hoverRegion
    ) {
      return new style.Style({
        fill: new style.Fill({
          color: this.props.locationToPresent ? "transparent" : "rgba(1,1,1,0.4)",
        }),
      })
    }
    return super.regionStyle(feature)
  }

  @bind
  async resetMap(): Promise<void> {
    try {
      const viewport = await fetchAgsResLocViewport(this.props.agsRefResLoc)
      viewport && this.getMap()?.getView().fit(transformExtent(viewport, "EPSG:4326", "EPSG:3857"), shapeFitOpts)
    } catch (e) {
      console.error(e)
    }
    this.overlay?.closeCurrentOverlay(true)
  }

  render() {
    return (
      <div className={mapStyles.containerClass} onMouseLeave={this.cleanHoverRegion}>
        {super.render()}

        {this.props.hasPreview && (
          <div className={mapStyles.showListContainerClass}>
            <Flex flexDirection="row" alignItems="center" gap={16}>
              <Toggle label={this.t.preview} checked={this.props.preview} onChange={this.props.onPreviewToggle} />
            </Flex>
          </div>
        )}

        {this.mapLoadingMessage()}

        <div
          className={cx(mapStyles.mapNotifierStyle, css({ maxHeight: this.state.hoverRegionName ? "48px" : "0px" }))}
        >
          <span style={{ padding: "8px" }}>
            {this.t?.map.switchToRegion}: {this.state.hoverRegionName}
          </span>
        </div>
        {this.mapRecalculationMessage()}
      </div>
    )
  }
}
