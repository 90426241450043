import React, { useMemo } from "react"
import { PanelProps } from "@blueprintjs/core"
import PrivatePoisCategoriesList from "./private-pois-categories-list"
import { translations } from "../../i18n"
import { css } from "emotion"
import { CategoriesListPanelInfo } from "../../models/private-data"
import { CreateOrEditCategoryPanel } from "./create-edit-category-panel"
import { COLORS } from "../../../shared/components/ui/colors"
import Button from "../../../shared/components/button"
import { getThemeColorVar } from "../../../shared/helper/color"

const styles = {
  newCategoryFlex: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    padding: "12px",
  }),
  emptyMessage: css({
    display: "flex",
    height: "min-content",
    padding: ".75em",
    margin: "2em 1.5em",
    border: "1.5px solid",
    borderColor: getThemeColorVar("border", "default"),
    backgroundColor: COLORS.background.lighter,
  }),
}

export const CategoriesListPanel: React.FC<PanelProps<CategoriesListPanelInfo>> = (props) => {
  const t = useMemo(translations, [translations])

  const openCreatePOIGroupPanel = () => props.openPanel({ renderPanel: CreateOrEditCategoryPanel })

  return (
    <div style={{ display: "grid", gridTemplateRows: "1fr min-content", height: "100%" }}>
      {props.loadedCategoryListIsEmpty ? (
        <div className={styles.emptyMessage}>{t.privateData.noCategoriesFound}</div>
      ) : (
        <PrivatePoisCategoriesList
          openPanel={props.openPanel}
          setCategoryToLock={props.setCategoryToLock}
          userId={props.userId}
          isAdmin={props.isAdmin}
        />
      )}
      <div className={styles.newCategoryFlex}>
        <Button type={"primary"} size={"normal"} onClick={openCreatePOIGroupPanel}>
          {t.privateData.newCategory}
        </Button>
      </div>
    </div>
  )
}
