import { RelasTranslations } from "./translations"

export const RELAS_EN: RelasTranslations = {
  pageTitles: {
    comparables: "Comparables",
    dashboard: "Dashboard",
    assessmentOverview: "Assessment Overview",
    districtData: "District Data",
    scoresRatingsPrices: "Scores & Prices",
    ratings: "Ratings",
    prices: "Prices",
    poiExplorer: "POI - Explorer",
    specialMaps: "Special Maps",
    rentIndex: "Rent Index",
    locationAssessment: "Location Assessment",
    privateData: "Private data",
    profileManager: "Profile Manager",
    quickstart: "Quickstart",
    locationSelector: "Location Selector",
    ratingManager: "Rating Manager",
    login: "Login",
    fundamentalData: "Fundamental Data",
  },
}
