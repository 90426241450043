import {
  numericYearWithQuarterToLabel,
  numericYearWithQuarterToTuple,
} from "../../../../utils/numeric-year-with-quarter-to-label"
import { FieldPath, useForm } from "react-hook-form"
import { PriceTypeDropdown } from "../comparables-entry-price-dropdown"
import { HandleInteractionKind, HTMLSelect, MultiSlider, NumericInput } from "@blueprintjs/core"
import { COLORS } from "../../../../shared/components/ui/colors"
import { Flex } from "../../../../shared/components/ui/flex"
import { HouseOrApartment } from "../../../models/assessment"
import Select from "react-select"
import { trackUsageEvent } from "../../../../utils/usage-tracking"
import React, { useEffect, useState } from "react"
import {
  FilterProps,
  iconProps,
  LocationSourceType,
  styles as comparablesComponentStyles,
  trackPriceTypeChange,
} from "../comparablesV2"
import { DistributionGraphResult } from "../../../../generated-apis/comparables-service"
import {
  fetchAssessmentComparablesStats,
  setAssessmentComparablesInput,
  fetchComparables,
  fetchAllComparablesGraphs,
} from "../../../reducers/comparables-slice"
import { HistoricalOffersInputForm, historicalOffersInputFormDefaults } from "./historical-offers-filters-model"
import { usePrevious } from "../../../../utils/use-previous"
import * as lodash from "lodash"
import { useAppSelector } from "../../../../relas/store"
import { css } from "emotion"
import { NumberInput } from "../../../../shared/components/ui/number-input"
import { integerValidator, minValidator, numberValidator, sequentialValidator } from "../../../../utils/utils"
import Checkbox from "../../../../shared/components/checkbox"
import Grid from "../../../../shared/components/restyle-grid/grid"
import GridItem from "../../../../shared/components/restyle-grid/griditem"
import { getThemeColor } from "../../../../shared/helper/color"

const sliderClass = css({
  "div div .bp4-slider-progress.bp4-intent-success": {
    backgroundColor: getThemeColor("primary", "default").string(),
  },
  padding: "24px 16px 0 16px",
})

const checkboxClass = css({
  accentColor: getThemeColor("primary", "default").string(),
})

export const HistoricalOffersFilters = ({ t, assessmentEntry, houseTypes }: FilterProps) => {
  const comparablesStats = useAppSelector((state) => state.comparablesApp.comparablesStats)
  const comparablesInputFromGlobalState = useAppSelector(
    (state) => state.comparablesApp.comparablesInput["historical-21st"]
  )
  const priceDistribution = useAppSelector((state) => state.comparablesApp.priceDistribution)
  const offerYearDistribution = useAppSelector((state) => state.comparablesApp.offerYearDistribution)
  const constructionYearDistribution = useAppSelector((state) => state.comparablesApp.constructionYearDistribution)
  const areaDistribution = useAppSelector((state) => state.comparablesApp.areaDistribution)
  const currentAssessmentEntry = useAppSelector((state) => state.assessment.currentAssessmentEntry)

  const comparablesInputDefault = {
    ...historicalOffersInputFormDefaults(currentAssessmentEntry || undefined),
    initialized: false,
  }
  const comparablesInput = comparablesInputFromGlobalState ?? comparablesInputDefault

  const currentDate = new Date()
  const currentQuarter = Math.ceil((currentDate.getMonth() + 1) / 3)
  const currentYearWithQuarter = currentDate.getFullYear() + 0.25 * (currentQuarter - 1)

  const minRadius = 1

  const globalComparablesOfferStats = {
    dateMin: 2004,
    dateMax: currentYearWithQuarter,
  }

  const multiSliderMax = globalComparablesOfferStats.dateMax
  const multiSliderMin = globalComparablesOfferStats.dateMin

  const { setValue, getValues, register, watch, handleSubmit, reset, clearErrors, setError } =
    useForm<HistoricalOffersInputForm>({
      defaultValues: lodash.cloneDeep(comparablesInput),
    })

  const onValueChangeUseForm =
    (field: FieldPath<HistoricalOffersInputForm>) => (num: number, _: string, valid: boolean) => {
      if (valid) {
        clearErrors(field)
        setValue(field, num)
      } else {
        setError(field, { type: "manual" })
      }
    }

  const previousPriceComp = usePrevious(comparablesInput.priceTypeComps)

  const [statsForPriceType, setStatsForPriceType] = useState(() => comparablesStats[getValues("priceTypeComps")])

  const possiblePublicationYears = (() => {
    const years = []
    if (statsForPriceType && statsForPriceType.offerDateMin > 0 && statsForPriceType.offerDateMax > 0) {
      const min = statsForPriceType.offerDateMin
      const max = statsForPriceType.offerDateMax
      for (let x = min; x <= max; x += 0.25) {
        years.push({ label: numericYearWithQuarterToLabel(x), value: x })
      }
    }
    return years
  })()

  const possiblePublicationYearFrom =
    possiblePublicationYears.length > 0
      ? possiblePublicationYears.length === 1
        ? possiblePublicationYears[0].value - 0.25
        : possiblePublicationYears[0].value
      : 0

  const possiblePublicationYearTo =
    possiblePublicationYears.length > 0 ? possiblePublicationYears[possiblePublicationYears.length - 1].value : 0

  const fetchComparablesStats = (data: HistoricalOffersInputForm) => {
    if (assessmentEntry && assessmentEntry.address.location) {
      // We disable publicationTimeRange as we'd like to know the time range of available comparables ignoring current slider values
      void fetchAssessmentComparablesStats(
        {
          ...data,
          publicationDateNeeded: false,
        },
        assessmentEntry.address.location
      )
    }
  }

  useEffect(() => {
    if (!comparablesStats[getValues("priceTypeComps")] && previousPriceComp !== comparablesInput.priceTypeComps) {
      fetchComparablesStats(comparablesInput)
    }
  }, [comparablesStats[getValues("priceTypeComps")], previousPriceComp, comparablesInput.priceTypeComps])

  const refreshRange = (
    flag: keyof HistoricalOffersInputForm,
    target: keyof HistoricalOffersInputForm,
    results: DistributionGraphResult | null
  ) => {
    if (results?.count && results.percentiles && getValues(flag) === false) {
      setValue(flag, true)
      setValue(target, {
        from: Math.round(results.percentiles.min * 100) / 100,
        to: Math.round(results.percentiles.max * 100) / 100,
      })
      setValue(flag, false)
    }
  }

  useEffect(() => {
    refreshRange("priceNeeded", "priceRange", priceDistribution)
    refreshRange("areaRangeNeeded", "areaRange", areaDistribution)
    refreshRange("constructionYearNeeded", "constructionYearRange", constructionYearDistribution)
  }, [priceDistribution, areaDistribution, offerYearDistribution, constructionYearDistribution])

  useEffect(() => {
    setStatsForPriceType(() => comparablesStats[getValues("priceTypeComps")])
  }, [assessmentEntry])

  useEffect(() => {
    if (!getValues("roomsFilterEnabled")) {
      setValue("roomsNumberRange", {
        from: statsForPriceType?.roomsMin ?? 1,
        to: statsForPriceType?.roomsMax ?? 6,
      })
    }
  }, [statsForPriceType, getValues("roomsFilterEnabled")])

  useEffect(() => {
    const statsForCurrentPriceType = comparablesStats[getValues("priceTypeComps")]
    setStatsForPriceType(statsForCurrentPriceType)
  }, [comparablesStats, getValues("priceTypeComps")])

  const isResidential = watch("priceTypeComps") === "residentialRent" || watch("priceTypeComps") === "residentialSale"

  const [submitTimeout, setSubmitTimeout] = useState<NodeJS.Timeout | undefined>(undefined)

  const onSubmit = (data: HistoricalOffersInputForm) => {
    if (submitTimeout) {
      clearTimeout(submitTimeout)
      setSubmitTimeout(undefined)
    }

    if (assessmentEntry && assessmentEntry.address.location) {
      const point = assessmentEntry.address.location

      const filterCriteriaSet = data.locationSource === "zip" ? data.zip && data.zip.trim() : true

      if (filterCriteriaSet) {
        // save comparable options in the global store. It will be used to generate the DOCX report and preserve the filter state while navigating
        setAssessmentComparablesInput({ ...data, initialized: true })

        void fetchComparablesStats(data)

        void fetchComparables(data, point)

        // loading trends in the background
        void fetchAllComparablesGraphs(data, point)
      }
    }
  }

  const submitHandler: () => Promise<void> = handleSubmit(onSubmit)

  useEffect(() => {
    onSubmit(getValues())
  }, [])

  useEffect(() => {
    onSubmit(getValues())
  }, [assessmentEntry, assessmentEntry?.address])

  useEffect(() => {
    if (comparablesInput && !comparablesInput.initialized) {
      reset(comparablesInput)
      onSubmit(comparablesInput)
    }
  }, [comparablesInput])

  function scheduleSubmit() {
    if (submitTimeout) {
      clearTimeout(submitTimeout)
    }

    setSubmitTimeout(
      setTimeout(() => {
        return submitHandler()
      }, 1000)
    )
  }

  return (
    <form onChange={() => scheduleSubmit()} onSubmit={submitHandler} className={comparablesComponentStyles.formStyle}>
      <div className={comparablesComponentStyles.filtersFormLine}>
        <div className={comparablesComponentStyles.inputLabel}>{t.assessmentComparables.usageType}</div>
        <PriceTypeDropdown
          dataSource={"historical-21st"}
          priceType={getValues("priceTypeComps")}
          onPriceTypeChange={(v) => {
            if (typeof v === "string") {
              trackPriceTypeChange(assessmentEntry, v)
              setValue("priceTypeComps", v)
            }
          }}
        />
      </div>

      <div className={comparablesComponentStyles.filtersFormLine}>
        <div className={comparablesComponentStyles.inputLabel}>{t.assessmentEntryDetails.locationSourceLabel}</div>
        <HTMLSelect
          fill
          {...register("locationSource")}
          className={comparablesComponentStyles.dropdown}
          style={{ background: "white", borderRadius: 4 }}
          iconProps={iconProps}
          options={[
            { value: "radius", label: t.assessmentEntryDetails.radius },
            { value: "zip", label: t.address.postalCode },
          ]}
          value={watch("locationSource")}
          onChange={(e) => setValue("locationSource", e.currentTarget.value as LocationSourceType)}
        />
        <div hidden={getValues("locationSource") !== "zip"}>
          <div className={comparablesComponentStyles.inputLabel}>{t.address.postalCode}</div>
          <NumericInput
            fill={true}
            buttonPosition="none"
            defaultValue={watch("zip")}
            value={watch("zip")}
            {...register("zip")}
            min={0}
            max={undefined}
            onValueChange={(_, valueAsString) => setValue("zip", valueAsString)}
          />
        </div>
        <div hidden={getValues("locationSource") !== "radius"}>
          <div className={comparablesComponentStyles.inputLabel}>{t.assessmentEntryDetails.radius}</div>
          <NumberInput
            defaultValue={watch("radius")}
            onValueChange={onValueChangeUseForm("radius")}
            validator={sequentialValidator([integerValidator(), minValidator(minRadius)])}
          />
        </div>
      </div>

      <div className={comparablesComponentStyles.filtersFormLine}>
        <div className={comparablesComponentStyles.inputLabel}>
          <Flex flexDirection={"row"} gap={4} alignItems={"center"}>
            <input
              className={checkboxClass}
              id="isPublicationDateNeededComparablesApp"
              type={"checkbox"}
              {...register("publicationDateNeeded")}
            />
            <label htmlFor="isPublicationDateNeededComparablesApp">{t.assessmentEntryDetails.publicationTime}</label>
          </Flex>
        </div>

        <div className={sliderClass} id="comparablesMultiSliderHandleLabel">
          <MultiSlider
            disabled={!watch("publicationDateNeeded")}
            labelStepSize={(multiSliderMax && multiSliderMin && (multiSliderMax - multiSliderMin) / 4) || 0.25}
            labelRenderer={(value, opt) => {
              const numericYearLabel = numericYearWithQuarterToLabel(value)
              const numericYearTuple = numericYearWithQuarterToTuple(value)
              return opt?.isHandleTooltip ? (
                <div>{numericYearLabel}</div>
              ) : (
                <div style={{ marginBlock: "-36px" }}>{globalComparablesOfferStats && numericYearTuple.year}</div>
              )
            }}
            max={multiSliderMax}
            min={multiSliderMin}
            stepSize={0.25}
          >
            <MultiSlider.Handle
              value={possiblePublicationYearFrom}
              intentAfter="success"
              interactionKind={HandleInteractionKind.NONE}
            />
            <MultiSlider.Handle
              value={possiblePublicationYearTo}
              intentBefore="success"
              interactionKind={HandleInteractionKind.NONE}
            />
            {watch("publicationDateNeeded") && statsForPriceType && (
              <MultiSlider.Handle
                type="start"
                {...register("publicationTimeRange")}
                value={watch("publicationTimeRange.from")}
                onChange={(e) =>
                  setValue("publicationTimeRange", { from: e, to: getValues("publicationTimeRange.to") })
                }
                interactionKind={HandleInteractionKind.LOCK}
                onRelease={scheduleSubmit}
              />
            )}
            {watch("publicationDateNeeded") && statsForPriceType && (
              <MultiSlider.Handle
                type="end"
                intentBefore={
                  possiblePublicationYears.find((year) => year.value === getValues("publicationTimeRange.to"))
                    ? "success"
                    : "none"
                }
                value={watch("publicationTimeRange.to")}
                onChange={(e) =>
                  setValue("publicationTimeRange", { from: getValues("publicationTimeRange.from"), to: e })
                }
                interactionKind={HandleInteractionKind.LOCK}
                onRelease={scheduleSubmit}
              />
            )}
          </MultiSlider>
        </div>
        <div className={comparablesComponentStyles.inputLabel}>{t.assessmentEntryDetails.offerDuration}</div>
        <div className={`comparablesMultiSliderQuarterAvailableDiv ${sliderClass}`}>
          <MultiSlider
            labelStepSize={1}
            labelRenderer={(value) => {
              return <div>{value === 5 ? ">" : value}</div>
            }}
            max={5}
            min={1}
            stepSize={1}
          >
            <MultiSlider.Handle
              value={getValues("quarterAvailable.from")}
              intentAfter="success"
              interactionKind={HandleInteractionKind.NONE}
            />
            <MultiSlider.Handle
              value={getValues("quarterAvailable.to")}
              intentBefore="success"
              interactionKind={HandleInteractionKind.NONE}
            />
            {watch("quarterAvailable") && (
              <MultiSlider.Handle
                type="start"
                {...register("quarterAvailable")}
                value={watch("quarterAvailable.from")}
                onChange={(e) => setValue("quarterAvailable", { from: e, to: getValues("quarterAvailable.to") })}
                interactionKind={HandleInteractionKind.LOCK}
                onRelease={scheduleSubmit}
              />
            )}
            {watch("quarterAvailable") && (
              <MultiSlider.Handle
                type="end"
                value={watch("quarterAvailable.to")}
                onChange={(e) => setValue("quarterAvailable", { from: getValues("quarterAvailable.from"), to: e })}
                interactionKind={HandleInteractionKind.LOCK}
                onRelease={scheduleSubmit}
              />
            )}
          </MultiSlider>
          <div className="quartersLabel">{t.assessmentEntryDetails.quarters}</div>
        </div>
      </div>
      <div className={comparablesComponentStyles.filtersFormLine} style={{ borderBottom: "none" }}>
        <Grid columns={3} columnSpec="1fr min-content 1fr">
          <div style={{ display: "contents" }}>
            <GridItem colSpan={3}>
              <div className={comparablesComponentStyles.inputLabel}>
                <Flex flexDirection={"row"} gap={4} alignItems={"center"}>
                  <input
                    id="isPriceNeededComparablesApp"
                    className={checkboxClass}
                    type={"checkbox"}
                    {...register("priceNeeded")}
                  />
                  <label htmlFor="isPriceNeededComparablesApp">{t.price}</label>
                </Flex>
              </div>
            </GridItem>

            <NumberInput
              defaultValue={watch("priceRange.from")}
              onValueChange={onValueChangeUseForm("priceRange.from")}
              disabled={!watch("priceNeeded")}
              validator={sequentialValidator([minValidator(0), numberValidator()])}
              suffix={t.assessmentEntryDetails.euroPerSqm}
            />

            <p className={comparablesComponentStyles.inputRangeDivider}>{String.fromCharCode(0x005f)}</p>
            <NumberInput
              defaultValue={watch("priceRange.to")}
              onValueChange={onValueChangeUseForm("priceRange.to")}
              disabled={!watch("priceNeeded")}
              validator={sequentialValidator([minValidator(0), numberValidator()])}
              suffix={t.assessmentEntryDetails.euroPerSqm}
            />
          </div>

          <GridItem colSpan={3}>
            <div className={comparablesComponentStyles.inputLabel}>
              <Flex flexDirection={"row"} gap={4} alignItems={"center"}>
                <input
                  id="isLivingAreaNeededComparablesApp"
                  className={checkboxClass}
                  type={"checkbox"}
                  {...register("areaRangeNeeded")}
                />

                <label htmlFor="isLivingAreaNeededComparablesApp">{t.assessmentEntryDetails.livingArea}</label>
              </Flex>
            </div>
          </GridItem>

          <NumberInput
            disabled={!watch("areaRangeNeeded")}
            defaultValue={watch("areaRange.from")}
            onValueChange={onValueChangeUseForm("areaRange.from")}
            suffix={"m²"}
            validator={sequentialValidator([minValidator(0), numberValidator()])}
          />

          <p className={comparablesComponentStyles.inputRangeDivider}>{String.fromCharCode(0x005f)}</p>

          <NumberInput
            disabled={!watch("areaRangeNeeded")}
            defaultValue={watch("areaRange.to")}
            onValueChange={onValueChangeUseForm("areaRange.to")}
            suffix={"m²"}
            validator={sequentialValidator([minValidator(0), numberValidator()])}
          />

          <div hidden={getValues("priceTypeComps") === "hall"}>
            <GridItem padding={[16, 0, 0, 0]} colSpan={3}>
              <Checkbox
                label={t.assessmentEntryDetails.furnished}
                {...{ ...register("furnished"), ref: undefined }}
                onChange={() => setValue("furnished", !getValues("furnished"))}
                checked={getValues("furnished")}
              />
            </GridItem>
          </div>

          <GridItem colSpan={3}>
            <div className={comparablesComponentStyles.inputLabel}>{t.assessmentEntryDetails.houseOrApartment}</div>
          </GridItem>
          <GridItem colSpan={3}>
            <HTMLSelect
              fill
              disabled={!isResidential}
              className={comparablesComponentStyles.dropdown}
              style={isResidential ? { background: "white", borderRadius: 4 } : {}}
              iconProps={iconProps}
              options={[
                {
                  value: "apartment",
                  label: t.assessmentEntryDetails.priceCategories.apartment,
                  disabled: !isResidential,
                },
                {
                  value: "house",
                  label: t.assessmentEntryDetails.priceCategories.house,
                  disabled: !isResidential,
                },
              ]}
              value={watch("houseOrApartment")}
              {...register("houseOrApartment")}
              onChange={(e) => setValue("houseOrApartment", e.currentTarget.value as HouseOrApartment)}
            />
          </GridItem>
          <div
            hidden={getValues("houseOrApartment") !== "house" || !isResidential}
            style={{ gridColumnStart: "span 3" }}
          >
            <div className={comparablesComponentStyles.inputLabel}>{t.assessmentEntryDetails.houseTypeTitle}</div>
            <Select
              {...register("houseTypes")}
              isMulti
              value={watch("houseTypes")}
              styles={{
                dropdownIndicator: (base) => ({
                  ...base,
                  color: COLORS.primary.default,
                  padding: "4px",
                  svg: { height: "18px" },
                }),
              }}
              placeholder={t.assessmentComparables.allHouseTypes}
              options={houseTypes}
              onChange={(a) => {
                assessmentEntry?.address &&
                  trackUsageEvent(
                    "COMPARABLES_CHANGE_HOUSE_TYPE",
                    assessmentEntry.address,
                    a.map((x) => x.label).join(", ")
                  )
                setValue("houseTypes", [...a])
                scheduleSubmit()
              }}
            />
          </div>
          {isResidential && (
            <>
              <GridItem colSpan={3}>
                <div className={comparablesComponentStyles.inputLabel}>
                  <Flex flexDirection={"row"} gap={4} alignItems={"center"}>
                    <input
                      id="isRoomsFilterNeededComparablesApp"
                      className={checkboxClass}
                      {...register("roomsFilterEnabled")}
                      type={"checkbox"}
                    />
                    <label htmlFor="isRoomsFilterNeededComparablesApp">{t.assessmentEntryDetails.rooms}</label>
                  </Flex>
                </div>
              </GridItem>

              <NumberInput
                defaultValue={watch("roomsNumberRange.from")}
                onValueChange={onValueChangeUseForm("roomsNumberRange.from")}
                disabled={!watch("roomsFilterEnabled")}
                validator={[minValidator(0), numberValidator()]}
              />

              <p className={comparablesComponentStyles.inputRangeDivider}>{String.fromCharCode(0x005f)}</p>
              <NumberInput
                defaultValue={watch("roomsNumberRange.to")}
                onValueChange={onValueChangeUseForm("roomsNumberRange.to")}
                disabled={!watch("roomsFilterEnabled")}
                validator={[minValidator(0), numberValidator()]}
              />
            </>
          )}

          <div style={{ display: "contents" }}>
            <GridItem colSpan={3}>
              <div className={comparablesComponentStyles.inputLabel}>
                <Flex flexDirection={"row"} gap={4} alignItems={"center"}>
                  <input
                    id="isYearOfConstructionNeededComparablesApp"
                    className={checkboxClass}
                    type={"checkbox"}
                    {...register("constructionYearNeeded")}
                  />
                  <label htmlFor="isYearOfConstructionNeededComparablesApp">
                    {t.assessmentEntryDetails.constructionYear}
                  </label>
                </Flex>
              </div>
            </GridItem>

            <NumberInput
              defaultValue={watch("constructionYearRange.from")}
              onValueChange={onValueChangeUseForm("constructionYearRange.from")}
              disabled={!watch("constructionYearNeeded")}
              validator={[minValidator(0), numberValidator()]}
            />

            <p className={comparablesComponentStyles.inputRangeDivider}>{String.fromCharCode(0x005f)}</p>
            <NumberInput
              defaultValue={watch("constructionYearRange.to")}
              onValueChange={onValueChangeUseForm("constructionYearRange.to")}
              disabled={!watch("constructionYearNeeded")}
              validator={[minValidator(0), numberValidator()]}
            />
          </div>
        </Grid>
      </div>
    </form>
  )
}
