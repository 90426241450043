import * as React from "react"
import { useEffect, useMemo } from "react"
import { translations } from "../i18n"
import { useSelector } from "react-redux"
import { State } from "../reducers/state"
import { Flex } from "../../shared/components/ui/flex"
import { FlexItem } from "../../shared/components/ui/flex-item"
import { ScrollBox } from "../../shared/components/ui/scroll-box"
import { Profile } from "../../profile/models/profile"
import { AssessmentEntryScores, DetailSelectionTab } from "../models/assessment"
import { NoContentDisclaimer } from "./assessment-no-content-disclaimer"
import { css } from "emotion"
import { ScoreView } from "./assessment-entry-score-view"
import { DataSetType, getScoreSetByDataSetType } from "../../shared/models/smartdata"
import { profileTooltip, ScoreProfileTooltip } from "./assessment-entry-score-view"
import { Rating } from "../../shared/models/ratings"
import { getEmptyScores, Scores, WeightOfScore } from "../../shared/models/scores"
import { trackUsageEvent } from "../../utils/usage-tracking"
import { Category, LANA_AGS_NODES, LANA_CELL_NODES, LanaNode } from "../../shared/smartdata-products/smartdata"
import Card from "../../shared/components/card"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import Panel from "../../shared/components/panel"
import Toggle from "../../shared/components/toggle"
import LoadingSpinner from "../../shared/components/loadingspinner"

const cardHeaderWrapperClass = css({
  padding: "6px 0px", // circumvent re-styles spacing restrictions to match neighbouring card's header
})

const toggleLabelClass = css({
  fontSize: "12px",
  fontWeight: "normal",
})

interface ScoreWithValue {
  title: string
  value: number
  tooltip: ScoreProfileTooltip
  scoreName: string
  weight: number
}

interface Props {
  viewMode: DataSetType
  selectedTab: DetailSelectionTab
  selectedProfile: Profile | undefined
  selectedScoreCategory?: Category
  selectedRating: Rating | undefined
  onSelectScores: (scores: Scores) => void
  selectedScores: Scores
  selectedProfileScore?: string
  setSelectedProfileScore: (selectedScore?: string) => void
  showScores: boolean
  setShowScores: (b: boolean) => void
}

type MacroAndMicro<T> = {
  micro: T
  macro: T
}

type getViewValue<T> = (m: MacroAndMicro<T>) => T

export const AssessmentDetailView = ({
  viewMode,
  selectedTab,
  selectedProfile,
  selectedScoreCategory,
  onSelectScores,
  selectedScores,
  selectedProfileScore,
  setSelectedProfileScore,
  selectedRating,
  showScores,
  setShowScores,
}: Props) => {
  const t = useMemo(translations, [translations])
  const currentAssessmentEntry = useSelector((state: State) => state.assessment.currentAssessmentEntry)
  const assessmentEntryProfileScoresInProgress = useSelector(
    (state: State) => state.assessment.assessmentEntryProfileScoresInProgress
  )
  const assessmentEntryScoresError = useSelector((state: State) => state.assessment.assessmentEntryScoresError)
  const assessmentEntryProfileScoresWithSources = useSelector(
    (state: State) => state.assessment.assessmentEntryProfileScoresWithSources
  )
  const assessmentEntryContextScoresInProgress = useSelector(
    (state: State) => state.assessment.assessmentEntryContextScoresInProgress
  )
  const assessmentEntryContextScores = useSelector((state: State) => state.assessment.assessmentEntryContextScores)
  const selectedMacroContext = useSelector((state: State) => state.assessment.currentAssessment?.macroContext)

  function getScoresForViewMode(): LanaNode[] {
    let values = viewMode === "macro" ? currentAssessmentEntry?.macroData : currentAssessmentEntry?.microData
    return getScoreSetByDataSetType(viewMode)
      .filter(
        (score) => score.category === selectedScoreCategory?.name && values && values[score.scoreName] !== undefined
      )
      .sort((a, b) => t.pickTranslation(a.title).localeCompare(t.pickTranslation(b.title)))
  }

  useEffect(() => {
    if (selectedTab === "scores") {
      const firstScore = getScoresForViewMode().shift()
      if (firstScore) {
        const newScores = getEmptyScores()
        newScores[viewMode][firstScore.scoreName] = 1
        onSelectScores(newScores)
      }
    }
  }, [selectedScoreCategory, viewMode])

  const cardHeader = () => {
    const selectedScore = getScoreSetByDataSetType(viewMode).find(
      (score) => score.scoreName === Object.keys(selectedScores[viewMode])[0]
    )
    switch (selectedTab) {
      case "profiles":
        return (
          <div className={cardHeaderWrapperClass}>
            <Flex flexDirection="row" alignItems="center" gap={8}>
              <div>{viewMode === "macro" ? t.macroScores : t.microScores}</div>
              <FlexItem flexGrow={1} />
              <div className={toggleLabelClass}>{t.assessmentEntryDetails.toggleAllScores}</div>
              <Toggle
                small
                checked={showScores && selectedProfileScore === undefined}
                onChange={(isSelected) => onHeaderToggleChange(isSelected)}
              />
            </Flex>
          </div>
        )
      case "scores":
        let score: string | undefined

        if (selectedScore && currentAssessmentEntry && !assessmentEntryContextScoresInProgress) {
          if (viewMode === "macro" && assessmentEntryContextScores) {
            score = assessmentEntryContextScores.insideContext
              ? assessmentEntryContextScores.values[selectedScore.scoreName]?.toFixed(0)
              : undefined
          } else {
            score = currentAssessmentEntry[`${viewMode}Data` as const][selectedScore.scoreName].toFixed(0)
          }
        }

        return (
          <div className={cardHeaderWrapperClass}>
            <Flex flexDirection="row" gap={12}>
              <div>{viewMode === "macro" ? t.macroScores : t.microScores}</div>
              {score && selectedScore && (
                <div style={{ fontWeight: "normal" }}>
                  {`(${t.selected}: ${t.pickTranslation(selectedScore.title)} \u2013 ${score})`}
                </div>
              )}
            </Flex>
          </div>
        )
    }
  }

  if (!currentAssessmentEntry) {
    return <Card header={cardHeader()} />
  }

  const onHeaderToggleChange = (showScores: boolean) => {
    setSelectedProfileScore(undefined)
    setShowScores(showScores)
  }

  const getRawScores: (
    data: { [source: string]: number },
    nodes: LanaNode[],
    weightedScores: WeightOfScore
  ) => ScoreWithValue[] = (data: { [source: string]: number }, nodes: LanaNode[], weightedScores: WeightOfScore) => {
    return nodes
      .filter((score) => score.scoreName in weightedScores)
      .map((score) => ({
        title: t.pickTranslation(score.title),
        scoreName: score.scoreName,
        tooltip: {
          tag: "score",
          description: t.pickTranslation(score.description),
          source: t.pickTranslation(score.source),
          scoreDescription: t.scoreDescription,
          scoreSource: t.scoreSource,
        },
        value: data[score.scoreName],
        weight: weightedScores[score.scoreName],
      }))
  }

  const getNestedProfiles = (
    assessmentEntryScores: AssessmentEntryScores,
    getEntryScores: getViewValue<number>,
    scores: Scores,
    getViewScores: getViewValue<WeightOfScore>
  ): ScoreWithValue[] => {
    const makeScoreName = (id: string) => `profile.${id}`

    return currentAssessmentEntry.profiles
      .filter((p) => makeScoreName(p.id) in getViewScores(scores))
      .map((p) => {
        const entryScores = getEntryScores(assessmentEntryScores[p.id])

        return {
          tooltip: profileTooltip,
          title: t.prefixWithProfile(p.name),
          scoreName: makeScoreName(p.id),
          value: entryScores,
          weight: 0, // TODO: how to get this weight?
        }
      })
  }

  // Sort first by weight and then by score name alphabetically
  const sortScores = (scores: ScoreWithValue[]) =>
    scores.sort((score1, score2) => {
      if (score1.weight > score2.weight) {
        return -1
      } else if (score1.weight < score2.weight) {
        return 1
      } else if (score1.title > score2.title) {
        return 1
      } else if (score1.title < score2.title) {
        return -1
      } else {
        return 0
      }
    })

  const assembleProfileScoreData = (data: { [source: string]: number }): ScoreWithValue[] => {
    if (!selectedProfile) return []

    // original scores inside the selected profile
    const rawScores: ScoreWithValue[] =
      viewMode === "macro"
        ? getRawScores(data, LANA_AGS_NODES, selectedProfile.scores.macro)
        : getRawScores(data, LANA_CELL_NODES, selectedProfile.scores.micro)

    const sortedRawScores = sortScores(rawScores)

    // calculated scores of all profiles
    const scoreForProfilesInCurrentContext = assessmentEntryProfileScoresWithSources?.insideContext
      ? assessmentEntryProfileScoresWithSources.profileScores
      : currentAssessmentEntry.scores

    const nestedProfiles: ScoreWithValue[] =
      viewMode === "macro"
        ? getNestedProfiles(
            scoreForProfilesInCurrentContext,
            (e) => e.macro,
            selectedProfile.scores,
            (s) => s.macro
          )
        : getNestedProfiles(
            scoreForProfilesInCurrentContext,
            (e) => e.micro,
            selectedProfile.scores,
            (s) => s.micro
          )

    return [...sortedRawScores, ...nestedProfiles].sort((a, b) => {
      let aValue = Math.abs(Math.round(a.weight * 100))
      let bValue = Math.abs(Math.round(b.weight * 100))
      if (aValue === bValue) {
        return a.title.localeCompare(b.title)
      }
      return bValue - aValue
    })
  }

  const renderProfileScores = () => {
    if (!selectedProfile) return <NoContentDisclaimer description={t.assessmentEntryDetails.noProfileSelected} />

    if (!currentAssessmentEntry.address.location)
      return <NoContentDisclaimer description={t.assessmentEntryDetails.entryNotGeocoded} />

    if (!currentAssessmentEntry.agsRefResloc)
      return <NoContentDisclaimer description={t.assessmentEntryDetails.entryHasNoSmartdata} />

    let data = currentAssessmentEntry[`${viewMode}Data` as const]

    // Context is available for "macro" scores only
    if (typeof selectedMacroContext === "string" && viewMode === "macro") {
      if (assessmentEntryProfileScoresInProgress) {
        return <LoadingSpinner />
      }
      if (assessmentEntryScoresError) {
        return <GenericErrorPanel error={assessmentEntryScoresError} />
      }
      if (!assessmentEntryProfileScoresWithSources?.insideContext) {
        return <Panel color="neutral">{t.assessmentEntryDetails.notInMacroContext}</Panel>
      }
      if (
        viewMode === "macro" &&
        assessmentEntryProfileScoresWithSources &&
        selectedProfile.id in assessmentEntryProfileScoresWithSources.profileScores
      ) {
        data = assessmentEntryProfileScoresWithSources.profileScores[selectedProfile.id].macroData
      }
    }

    const scores = assembleProfileScoreData(data)

    if (scores.length === 0)
      return <NoContentDisclaimer description={t.assessmentEntryDetails.profileNoScores[viewMode]} />

    const scoresOfSelectedProfile = currentAssessmentEntry?.scores[selectedProfile.id] || { macro: -1, micro: -1 }

    if (viewMode === "macro" && scoresOfSelectedProfile.macro < 0)
      return <NoContentDisclaimer description={t.assessmentEntryDetails.entryHasNoScores.macro} />

    if (viewMode === "micro" && scoresOfSelectedProfile.micro < 0) {
      if (currentAssessmentEntry.microDataIsIncomplete)
        return <NoContentDisclaimer description={t.assessmentEntryDetails.entryHasMissingCellOnBorder} />
      else return <NoContentDisclaimer description={t.assessmentEntryDetails.entryHasNoScores.micro} />
    }

    return scores.map(({ scoreName, title, value, tooltip }) => (
      <ScoreView
        name={scoreName}
        title={title}
        scoreProfileTooltip={tooltip}
        value={value}
        key={scoreName}
        isSelected={(showScores && selectedProfileScore === undefined) || selectedProfileScore === scoreName}
        inverted={selectedProfile?.scores[viewMode][scoreName] < 0}
        onToggleChange={(isChecked) => {
          if (selectedProfileScore === undefined || isChecked) {
            trackUsageEvent(
              "DETAIL_VIEW_SELECT_PROFILE_SCORE",
              currentAssessmentEntry.address,
              `${viewMode},${selectedProfile.name},${title}`
            )
          }

          if (selectedProfileScore === undefined || isChecked) {
            setSelectedProfileScore(scoreName)
          }

          setShowScores(isChecked)
        }}
      />
    ))
  }

  const isAnActiveScoresScore: () => boolean = () => {
    const scoresToSearch = selectedScores[viewMode]

    for (let key of Object.keys(scoresToSearch)) {
      if (scoresToSearch[key] !== 0) return true
    }

    return false
  }

  const renderGeneralScores = () => {
    if (!isAnActiveScoresScore()) {
      if (viewMode === "micro" && currentAssessmentEntry.microDataIsIncomplete)
        return <NoContentDisclaimer description={t.assessmentEntryDetails.entryHasMissingCellOnBorder} />
      else return <NoContentDisclaimer description={t.assessmentEntryDetails.noScoreGroupSelected} />
    }

    const scores = getScoresForViewMode()

    if (!currentAssessmentEntry.address.location)
      return <NoContentDisclaimer description={t.assessmentEntryDetails.entryNotGeocoded} />

    if (!currentAssessmentEntry.agsRefResloc)
      return <NoContentDisclaimer description={t.assessmentEntryDetails.entryHasNoSmartdata} />

    if (scores.length === 0) {
      if (viewMode === "micro" && currentAssessmentEntry.microDataIsIncomplete)
        return <NoContentDisclaimer description={t.assessmentEntryDetails.entryHasMissingCellOnBorder} />
      else return <NoContentDisclaimer description={t.assessmentEntryDetails.profileNoScores[viewMode]} />
    }

    let values = currentAssessmentEntry[`${viewMode}Data` as const]

    if (typeof selectedMacroContext === "string") {
      if (assessmentEntryContextScoresInProgress) {
        return <LoadingSpinner />
      }
      if (assessmentEntryScoresError) {
        return <GenericErrorPanel error={assessmentEntryScoresError} />
      }
      if (!assessmentEntryContextScores?.insideContext && viewMode == "macro") {
        return <Panel color="neutral">{t.assessmentEntryDetails.notInMacroContext}</Panel>
      }

      if (viewMode === "macro" && assessmentEntryContextScores) {
        values = assessmentEntryContextScores.values
      }
    }

    return scores.map(({ scoreName, title, description, source }) => {
      const isSelected = selectedScores[viewMode][scoreName] > 0

      return (
        <ScoreView
          name={scoreName}
          key={scoreName}
          title={t.pickTranslation(title)}
          scoreProfileTooltip={{
            tag: "score",
            description: t.pickTranslation(description),
            source: t.pickTranslation(source),
            scoreDescription: t.scoreDescription,
            scoreSource: t.scoreSource,
          }}
          isSelected={isSelected}
          value={values[scoreName]}
          onToggleChange={(isChecked) => {
            if (isChecked) {
              currentAssessmentEntry &&
                trackUsageEvent(
                  "DETAIL_VIEW_SELECT_SCORE",
                  currentAssessmentEntry.address,
                  `${viewMode},${selectedScoreCategory?.name},${scoreName}`
                )
            }
            const newScores = getEmptyScores()
            newScores[viewMode][scoreName] = 1
            onSelectScores(newScores)
          }}
        />
      )
    })
  }

  if (!currentAssessmentEntry) return null

  return (
    <Card header={cardHeader()}>
      <ScrollBox>
        {selectedTab === "scores" && renderGeneralScores()}
        {selectedTab === "profiles" && renderProfileScores()}
      </ScrollBox>
    </Card>
  )
}
