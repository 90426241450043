import PlaceResult = google.maps.places.PlaceResult
import { Location } from "./geo-partition"

export interface PlaceResultEncodable {
  address_components?: google.maps.GeocoderAddressComponent[]
  adr_address?: string
  aspects?: google.maps.places.PlaceAspectRating[]
  business_status?: google.maps.places.BusinessStatus
  formatted_address?: string
  formatted_phone_number?: string
  geometry?: {
    location?: Location
  }
  html_attributions?: string[]
  icon?: string
  icon_background_color?: string
  icon_mask_base_uri?: string
  international_phone_number?: string
  name?: string
  place_id?: string
  plus_code?: google.maps.places.PlacePlusCode
  price_level?: number
  rating?: number
  reviews?: google.maps.places.PlaceReview[]
  types?: string[]
  url?: string
  user_ratings_total?: number
  utc_offset_minutes?: number
  vicinity?: string
  website?: string
}

export function placeResultToEncodable(placeResult: PlaceResult): PlaceResultEncodable {
  return {
    address_components: placeResult.address_components,
    adr_address: placeResult.adr_address,
    aspects: placeResult.aspects,
    business_status: placeResult.business_status,
    formatted_address: placeResult.formatted_address,
    formatted_phone_number: placeResult.formatted_phone_number,
    geometry: {
      location: placeResult.geometry?.location
        ? {
            lat: placeResult.geometry.location.lat(),
            lng: placeResult.geometry.location.lng(),
          }
        : undefined,
    },
    html_attributions: placeResult.html_attributions,
    icon: placeResult.icon,
    icon_background_color: placeResult.icon_background_color,
    icon_mask_base_uri: placeResult.icon_mask_base_uri,
    international_phone_number: placeResult.international_phone_number,
    name: placeResult.name,
    place_id: placeResult.place_id,
    plus_code: placeResult.plus_code,
    price_level: placeResult.price_level,
    rating: placeResult.rating,
    reviews: placeResult.reviews,
    types: placeResult.types,
    url: placeResult.url,
    user_ratings_total: placeResult.user_ratings_total,
    utc_offset_minutes: placeResult.utc_offset_minutes,
    vicinity: placeResult.vicinity,
    website: placeResult.website,
  }
}
