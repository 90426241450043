import React, { useEffect, useMemo } from "react"
import { Grid } from "../ui/grid"
import { doChangeModule } from "../../../relas/router"
import { useAppSelector } from "../../../relas/store"
import DropdownButton, { DropdownItemProps } from "../dropdown-button"
import { appLocationConfig } from "../../../menu/util/app-location-config"
import { App, SubmenuEntry } from "../../../menu/util/app-location-types"
import { scopesFromCookie } from "../../../menu/util/scorefromcookies"
import { filterMenuEntries, isUserImpersonating } from "../../../menu/revo-application-menu"
import { css, cx } from "emotion"
import { getThemeColorVar } from "../../helper/color"
import { translations } from "../../i18n"
import { Menu, MenuDivider, MenuItem, Popover } from "@blueprintjs/core"
import Button from "../button"
import Icon from "../icon"
import { navigateTo } from "../../reducers/navigation-slice"
import { NavigationPage } from "../../actions/navigation"
import { dashboardServiceUrl } from "../../../app_config"
import { fetchProfile } from "../../../user-account/user-profile-slice"
import { Notifications } from "./notifications"
import { Flex } from "../ui/flex"
import { CompanySettings } from "./company-settings"
import { userLogout } from "../../../relas/user-slice"
import { language } from "../../language"

export const headerTextColor = "#333333"

const styles = {
  mainLinks: css({
    display: "flex",
    flexDirection: "row",
    gap: "40px",
    justifyContent: "center",
    height: "100%",
  }),
  headerLinks: css({
    fontSize: "16px",
    color: getThemeColorVar("typo", "default"),
    textDecoration: "none",
    alignContent: "center",
  }),
  notSelectedHeaderLink: css({
    borderBottom: "none",
  }),
  selectedHeaderLink: css({
    color: getThemeColorVar("primary", "dark"),
    borderBottom: `2px solid ${getThemeColorVar("primary", "default")}`,
    marginTop: "2px",
  }),
  selectedToolsButton: css({
    color: getThemeColorVar("primary", "dark"),
    "&:hover": { color: "#4844bc" },
  }),
  toolsButtonLabel: css({
    "&:hover": { color: "#4844bc" },
  }),
  profileSettingsItem: css({
    "span > i": {
      color: headerTextColor,
    },
    padding: "13px 30px",
    "&:hover": {
      outline: "none",
      textDecoration: "none",
      background: "none",
      color: getThemeColorVar("primary", "default"),
      "span > i": {
        color: getThemeColorVar("primary", "default"),
      },
    },
    "&:focus": {
      outline: "none",
      textDecoration: "none",
      background: "none",
      color: getThemeColorVar("primary", "default"),
      "span > i": {
        color: getThemeColorVar("primary", "default"),
      },
    },
  }),
  letterAvatar: css({
    backgroundColor: headerTextColor,
    borderRadius: "50%",
    color: "white",
    fontSize: "20px",
    width: "45px",
    height: "45px",
    textAlign: "center",
    alignSelf: "center",
  }),
  headerText: css({
    color: headerTextColor,
  }),
  dropdownDivider: css({
    padding: "13px 26px",
    border: "none",
    "&:focus": {
      outline: "none",
    },
  }),
  nameMenuItem: css({
    padding: "0px 30px 16px 30px",
    border: "none",
    textDecoration: "none",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      background: "none",
      cursor: "default",
    },
  }),
}

interface Props {
  companyAdmin: boolean
  selectedTab: LanaHeaderTabs
  setSelectedTab: (newTab: LanaHeaderTabs) => void
}
export type LanaHeaderTabs = "tools" | "locationAssessment" | "quickstart" | undefined

export const LanaHeader = ({ companyAdmin, selectedTab, setSelectedTab }: Props) => {
  const t = useMemo(translations, [translations])
  const currentPage = useAppSelector((state) => state.navigation.currentPage)
  const appLocation = useAppSelector((state) => state.navigation.menuAppLocation)
  const user = useAppSelector((state) => state.user)
  const userProfile = useAppSelector((state) => state.userProfile)

  const lang = language()

  useEffect(() => {
    fetchProfile()
  }, [])

  const onTabChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLDivElement>,
    newTabId: LanaHeaderTabs
  ) => {
    if (newTabId === selectedTab) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    if (newTabId !== "tools") {
      e.preventDefault()
      e.stopPropagation()
      doChangeModule({ app: "locationAnalytics", section: newTabId }, currentPage)
    }
    setSelectedTab(newTabId)
  }

  const onToolsEntryClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, entry: SubmenuEntry<App>) => {
    const toolsApp = {
      app: entry.app,
      section: entry.entry,
    }
    e.preventDefault()
    e.stopPropagation()
    setSelectedTab(undefined)
    doChangeModule(toolsApp, currentPage)
  }

  const toolsMenu = () => {
    const dropdownActions: DropdownItemProps[] = []

    if (!appLocation) return dropdownActions
    const appConfig = appLocationConfig["locationAnalytics"]
    const menuEntries = appConfig
      .map((entries) => filterMenuEntries(entries, scopesFromCookie()).filter((_) => _.toolsPopup))
      .flat()

    if (menuEntries.length > 0) {
      menuEntries.map((entry) =>
        dropdownActions.push({
          title: entry.text,
          onClick: (e) => {
            onToolsEntryClick(e, entry)
          },
        })
      )
    }
    return dropdownActions
  }

  return (
    <Grid
      columns={3}
      columnSpec={"80px 1fr auto"}
      colGap={8}
      alignItems={"center"}
      height={user.isAuthorised ? [50, "px"] : [0, "px"]}
      width={[100, "vw"]}
      backgroundColor={isUserImpersonating() ? "red" : "white"}
    >
      <img
        src="/assets/21st_logo.svg"
        alt="21st RE LOGO"
        height={"22px"}
        style={{ cursor: "pointer", justifySelf: "center" }}
        onClick={(e) => {
          if (selectedTab !== "quickstart" || appLocation?.app !== "locationAnalytics") onTabChange(e, "quickstart")
        }}
      />
      <div className={styles.mainLinks}>
        <a
          href="/"
          onClick={(e) => onTabChange(e, "locationAssessment")}
          className={
            selectedTab === "locationAssessment"
              ? cx(styles.headerLinks, styles.selectedHeaderLink)
              : cx(styles.headerLinks, styles.notSelectedHeaderLink)
          }
        >
          {t.header.allAsessments}
        </a>

        <a
          href="/"
          onClick={(e) => onTabChange(e, "quickstart")}
          className={
            selectedTab === "quickstart"
              ? cx(styles.headerLinks, styles.selectedHeaderLink)
              : cx(styles.headerLinks, styles.notSelectedHeaderLink)
          }
        >
          {t.header.newAssessment}
        </a>

        <div
          id="lanaHeaderToolsDropdown"
          className={
            selectedTab === "tools"
              ? css({ borderBottom: `2px solid ${getThemeColorVar("primary", "default")}`, marginTop: "2px" })
              : ""
          }
          onClick={(e) => {
            onTabChange(e, "tools")
          }}
        >
          <DropdownButton
            type={"tertiary"}
            actions={toolsMenu()}
            alignment={"left"}
            autoClose={selectedTab !== "tools"}
          >
            <span
              className={
                selectedTab === "tools"
                  ? cx(styles.headerLinks, styles.selectedToolsButton)
                  : cx(styles.toolsButtonLabel, styles.headerLinks)
              }
            >
              Tools
            </span>
          </DropdownButton>
        </div>
      </div>
      <Flex flexDirection={"row"} gap={40} padding={[0, 24, 0, 0]}>
        <Popover
          placement={"bottom"}
          modifiers={{ arrow: { enabled: false } }}
          content={
            <Menu style={{ padding: "13px 0 17px 0", color: headerTextColor }}>
              <MenuDivider title={"Help Center"} className={styles.dropdownDivider} />
              <MenuItem
                style={{ textDecoration: "none", paddingLeft: "36px" }}
                className={styles.profileSettingsItem}
                text={t.documentationAndSupport.contactSupport}
                icon={<Icon name={"support"} />}
                href={"mailto:support@21re.de"}
              />
              <MenuDivider title={t.legal.title} className={styles.dropdownDivider} />
              <MenuItem
                className={styles.profileSettingsItem}
                text={t.legal.imprint}
                href={`https://www.21re.de/impressum?lang=${lang}`}
                target={"_blank"}
              />
              <MenuItem
                className={styles.profileSettingsItem}
                text={t.legal.privacy}
                href={`https://www.21re.de/datenschutz?lang=${lang}`}
                target={"_blank"}
              />
              <MenuItem
                className={styles.profileSettingsItem}
                text={t.legal.tac}
                href={`https://www.21re.de/agb?lang=${lang}`}
                target={"_blank"}
              />
            </Menu>
          }
        >
          <Button type={"tertiary"} icon={"support"} />
        </Popover>
        <Notifications />
        <Popover
          placement={"bottom"}
          content={
            <Menu style={{ padding: "30px 0 25px 0", color: headerTextColor, minWidth: "319px" }}>
              <MenuItem
                className={styles.nameMenuItem}
                text={
                  <Grid columns={2} columnSpec={"auto 1fr"} colGap={8}>
                    {userProfile.profileData?.personalData.hasPicture && (
                      <img
                        src={dashboardServiceUrl + "/api/profile/picture"}
                        style={{ width: "45px", height: "45px", borderRadius: "50%" }}
                        alt={"Profile image"}
                      />
                    )}
                    {!userProfile.profileData?.personalData.hasPicture && (
                      <div className={styles.letterAvatar}>
                        <div style={{ padding: "12px 0" }}>
                          {userProfile.profileData?.personalData.name[0]}
                          {userProfile.profileData?.personalData.surname[0]}
                        </div>
                      </div>
                    )}
                    <Flex flexDirection={"column"} gap={4}>
                      <div
                        className={styles.headerText}
                      >{`${userProfile.profileData?.personalData.name} ${userProfile.profileData?.personalData.surname}`}</div>
                      <div className={cx(styles.headerText, css({ fontSize: "13px" }))}>
                        {userProfile.profileData?.personalData.email}
                      </div>
                    </Flex>
                  </Grid>
                }
                onClick={(e) => {
                  if (selectedTab === "tools") setSelectedTab(undefined)
                  e.preventDefault()
                  e.stopPropagation()
                }}
              />
              <MenuDivider title={t.header.admin.submenus.companySettings} className={styles.dropdownDivider} />
              {companyAdmin && (
                <CompanySettings
                  appLocation={appLocation}
                  resetSelectedTab={() => {
                    setSelectedTab(undefined)
                  }}
                />
              )}
              <MenuDivider title={t.header.profile.myProfile} className={styles.dropdownDivider} />
              <MenuItem
                style={{ paddingLeft: "36px" }}
                className={styles.profileSettingsItem}
                text={t.header.profile.profileSettings}
                icon={<Icon name={"settings_config"} />}
                href={"/user/profile"}
                onClick={(e) => {
                  setSelectedTab(undefined)
                  e.preventDefault()
                  navigateTo({ name: NavigationPage.userProfile })
                }}
              />
              <MenuItem
                style={{ textDecoration: "none", paddingLeft: "36px" }}
                className={styles.profileSettingsItem}
                text={t.header.profile.securitySettings}
                icon={<Icon name={"security"} />}
                href={"/user/security"}
                onClick={(e) => {
                  setSelectedTab(undefined)
                  e.preventDefault()
                  navigateTo({ name: NavigationPage.userSecurity, params: { securityPage: "password" } })
                }}
              />
              <MenuItem
                style={{ textDecoration: "none", paddingLeft: "36px" }}
                className={styles.profileSettingsItem}
                text={t.header.profile.logout}
                icon={<Icon name={"logout"} />}
                href={""}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  void userLogout().then(() => {
                    navigateTo({ name: NavigationPage.login })
                  })
                }}
              />
            </Menu>
          }
          modifiers={{ arrow: { enabled: false } }}
        >
          <Button type={"tertiary"} icon={"profile_male"} />
        </Popover>
      </Flex>
    </Grid>
  )
}
