import * as React from "react"
import { FC, ReactNode, useEffect, useMemo, useState } from "react"
import { ratingStyles } from "../rating-styles"
import { ratingManagerActions } from "../../rating-manager-slice"
import { useAppDispatch, useAppSelector } from "../../../relas/store"
import { translations } from "../../i18n"
import SplitPane from "react-split-pane"
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd"
import { RatingSelection } from "../../../explorer/models/rating"
import { BorderBottom } from "../../../shared/components/ui/border-bottom"
import { COLORS } from "../../../shared/components/ui/colors"
import { Flex } from "../../../shared/components/ui/flex"
import { FlexItem } from "../../../shared/components/ui/flex-item"
import { Grid } from "../../../shared/components/ui/grid"
import { PopupMenu } from "../../../shared/components/ui/popup-menu"
import { css, cx } from "emotion"
import { GradeLabel } from "../grade-label"
import { doUpdateRating, fetchProfiles, fetchRatingResults } from "../../rating-manager-actions"
import { RatingManagerSelectionRule } from "./rating-manager-selection-rule"
import { LoadMicroData, RatingSelectionRule } from "../../../shared/models/selection"
import { ruleTitle } from "../../../shared/i18n/rule"
import { RatingManagerRatingSelectionDuplicate } from "./rating-manager-rating-selection-duplicate"
import { Drawer } from "@blueprintjs/core"
import { RatingManagerRatingSelectionEdit } from "./rating-manager-rating-selection-edit"
import { RatingManagerSelectionRuleEdit } from "./rating-manager-selection-rule-edit"
import { Group, Profile } from "../../../profile/models/profile"
import { RatingManagerRatingSelectionCreate } from "./rating-manager-rating-selection-create"
import { isMetaRating } from "../../../shared/models/ratings"
import { AlertBox } from "../../../shared/components/alertbox"
import LoadingDimmer from "../../../shared/components/loadingdimmer"
import Text from "../../../shared/components/text"
import Button from "../../../shared/components/button"
import Icon from "../../../shared/components/icon"

const styles = {
  container: cx(
    ratingStyles.container,
    css({
      padding: "16px 0 0",
      gap: 0,
      hr: {
        margin: "0",
      },
    })
  ),
  dragDropContainer: css({
    position: "relative",
    height: "100%",
    width: "100%",
    minHeight: 0,
    overflow: "auto",
  }),
  withPadding: css({
    padding: "0 16px",
  }),
  topFlex: css({
    display: "flex",
    flexDirection: "row",
  }),
  droppable: css({
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  }),
  gradesList: css({
    display: "flex",
    flexDirection: "row",
    padding: "8px 0",
    gap: "8px",
  }),
}

type DragDropProps = {
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void
  droppableId: string
  items: ((provided: DraggableProvided) => ReactNode)[]
  onItemClick: (itemIdx: number) => void
}

const DragDrop: FC<DragDropProps> = ({ onDragEnd, droppableId, items, onItemClick }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className={styles.droppable}>
            {items.map((item, idx: number) => (
              <Draggable draggableId={`ratings-${idx}`} index={idx} key={idx}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={{
                      ...provided.draggableProps.style,
                    }}
                    onClick={() => onItemClick(idx)}
                  >
                    {item(provided)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export const RatingManagerEditStandardRating: FC = () => {
  const t = useMemo(() => translations(), [])

  const currentRating = useAppSelector((state) => {
    if (state.ratingManager.currentRating && !isMetaRating(state.ratingManager.currentRating)) {
      return state.ratingManager.currentRating
    } else {
      return undefined
    }
  })

  const dispatch = useAppDispatch()

  const [ruleToManage, setRuleToManage] = useState<
    { rule: RatingSelectionRule | RatingSelection; action: "edit" | "delete" | "duplicate"; idx: number } | undefined
  >(undefined)

  const [showSelectionRuleAddForm, setShowSelectionRuleAddForm] = useState(false)

  const [showSelectionAddForm, setShowSelectionAddForm] = useState(false)

  const { selectedRatingSelectionIdx, selectedRatingSelectionRuleIdx, updateInProgress, agsRefResLoc, showPreview } =
    useAppSelector((state) => ({
      currentRating: state.ratingManager.currentRating,
      selectedRatingSelectionIdx: state.ratingManager.selectedRatingSelectionIdx,
      selectedRatingSelectionRuleIdx: state.ratingManager.selectedRatingSelectionRuleIdx,
      updateInProgress: state.ratingManager.updateInProgress,
      agsRefResLoc: state.ratingManager.agsRefResLoc,
      showPreview: state.ratingManager.showPreview,
    }))

  const setSelectedRuleIdx = (idx?: number) => {
    dispatch(
      ratingManagerActions.setSelectedRatingRule({ selectionIdx: selectedRatingSelectionIdx, selectionRuleIdx: idx })
    )
  }

  const currentRatingSelection = currentRating?.rules[selectedRatingSelectionIdx]
  const setCurrentRatingSelection = (idx: number) => {
    dispatch(ratingManagerActions.setSelectedRatingRule({ selectionIdx: idx }))
    if (currentRating?.dataSetType === "micro") {
      const startPointRule = currentRating.rules[idx].rules.find((r) => r.type === "loadmicrodata") as
        | LoadMicroData
        | undefined
      dispatch(ratingManagerActions.setAgsRefResLoc(startPointRule?.exampleAgsRefResLoc))
    }
  }

  const [{ availableProfiles, profileGroups, availableProfileInProgress }, setProfilesAndGroups] = useState<{
    availableProfiles: Profile[]
    profileGroups: Group[]
    availableProfileInProgress: boolean
  }>({
    availableProfiles: [],
    profileGroups: [],
    availableProfileInProgress: false,
  })

  useEffect(() => {
    void (async () => {
      setProfilesAndGroups({ availableProfiles: [], profileGroups: [], availableProfileInProgress: true })
      const [profiles, groups] = await fetchProfiles()
      setProfilesAndGroups({ availableProfiles: profiles, profileGroups: groups, availableProfileInProgress: false })
    })()
  }, [])

  useEffect(() => {
    if ((currentRating?.rules?.length ?? 0) > 0) {
      setCurrentRatingSelection(0)
    }
  }, [currentRating?.id])

  useEffect(() => {
    void refreshMap()
  }, [selectedRatingSelectionIdx, selectedRatingSelectionRuleIdx, showPreview])

  async function refreshMap() {
    if (currentRating) {
      if (showPreview) {
        await fetchRatingResults(dispatch)(currentRating, agsRefResLoc)
      } else {
        await fetchRatingResults(dispatch)(
          currentRating,
          agsRefResLoc,
          selectedRatingSelectionIdx,
          selectedRatingSelectionRuleIdx
        )
      }
    }
  }
  async function onRatingRuleReorder(result: DropResult, provided: ResponderProvided) {
    if (!currentRating || !result.destination) {
      return
    }

    const newRules: RatingSelection[] = [...currentRating.rules]

    const element = newRules.splice(result.source.index, 1)[0]
    newRules.splice(result.destination.index, 0, element)

    await doUpdateRating(dispatch)({
      ...currentRating,
      rules: newRules,
    })
  }

  async function onSelectionRuleReorder(result: DropResult, provided: ResponderProvided) {
    if (!currentRating || !currentRatingSelection || !result.destination) {
      return
    }

    const newRules: RatingSelectionRule[] = [...currentRatingSelection.rules]

    //index + 1 because first rule is first rule cannot be reordered
    const element = newRules.splice(result.source.index + 1, 1)[0]
    newRules.splice(result.destination.index + 1, 0, element)

    await doUpdateRating(dispatch)({
      ...currentRating,
      rules: currentRating.rules.map((r) =>
        r === currentRatingSelection ? { ...currentRatingSelection, rules: newRules } : r
      ),
    })
  }

  function renderRatingRule(rule: RatingSelection, idx: number): (provided: DraggableProvided) => ReactNode {
    return (provided: DraggableProvided) => {
      if (!currentRating) {
        return <></>
      }

      return (
        <BorderBottom
          padding={8}
          backgroundColor={idx === selectedRatingSelectionIdx ? COLORS.secondary2.light : COLORS.background.default}
        >
          <Grid columnSpec="min-content min-content 1fr min-content" gap={4} alignItems="center">
            <div {...provided.dragHandleProps}>
              <Icon name="reorder_scrubbler" />
            </div>
            <GradeLabel grade={currentRating.grades[rule.gradeIdx]} />
            <div>{rule.title}</div>
            <PopupMenu
              actions={[
                {
                  title: t.editForm.editRuleMenuItem,
                  onClick: () => setRuleToManage({ rule, idx, action: "edit" }),
                },
                {
                  title: t.listMenu.duplicate,
                  onClick: () => setRuleToManage({ rule, idx, action: "duplicate" }),
                },
                "divider",
                {
                  title: t.editForm.deleteButtonLabel,
                  onClick: () => setRuleToManage({ rule, idx, action: "delete" }),
                },
              ]}
            />
          </Grid>
        </BorderBottom>
      )
    }
  }

  function renderSelectionRule(rule: RatingSelectionRule, idx: number): (provided: DraggableProvided) => ReactNode {
    return (provided: DraggableProvided) => {
      if (!currentRating) {
        return <></>
      }
      return (
        <BorderBottom
          padding={8}
          backgroundColor={idx === selectedRatingSelectionRuleIdx ? COLORS.secondary2.light : COLORS.background.default}
        >
          <Grid columnSpec="min-content 1fr" gap={4} alignItems="center">
            <div {...provided.dragHandleProps}>
              <Icon name="reorder_scrubbler" />
            </div>
            <RatingManagerSelectionRule
              rule={rule}
              idx={idx}
              dataSetType={currentRating.dataSetType}
              onRuleEdit={() => setRuleToManage({ rule, action: "edit", idx })}
              availableProfiles={availableProfiles}
              setRuleToDelete={(rule) => setRuleToManage({ rule, action: "delete", idx })}
            />
          </Grid>
        </BorderBottom>
      )
    }
  }

  function renderDeleteConfirm() {
    if (!ruleToManage || ruleToManage?.action !== "delete" || !currentRating) {
      return <></>
    }

    if ("rules" in ruleToManage.rule) {
      return (
        <AlertBox
          header={t.editForm.deleteSelectionTitle}
          onClose={() => setRuleToManage(undefined)}
          actions={[
            {
              label: t.editForm.deleteButtonLabel,
              icon: "delete",
              action: () => onRatingSelectionDelete(currentRating?.rules.indexOf(ruleToManage.rule as RatingSelection)),
            },
            { label: t.cancel, action: () => setRuleToManage(undefined) },
          ]}
        >
          <Text>{t.editForm.deleteSelectionConfirm(ruleToManage.rule.title)}</Text>
        </AlertBox>
      )
    } else if (currentRatingSelection) {
      return (
        <AlertBox
          header={t.editForm.deleteRuleTitle}
          onClose={() => setRuleToManage(undefined)}
          actions={[
            {
              label: t.editForm.deleteButtonLabel,
              icon: "delete",
              action: () =>
                onSelectionRuleDelete(currentRatingSelection?.rules.indexOf(ruleToManage.rule as RatingSelectionRule)),
            },
            { label: t.cancel, action: () => setRuleToManage(undefined) },
          ]}
        >
          <Text>{t.editForm.deleteRuleConfirm(ruleTitle(t, ruleToManage.rule))}</Text>
        </AlertBox>
      )
    }
    return <></>
  }

  function onRatingSelectionDelete(ruleIdx: number) {
    if (!currentRating) return

    doUpdateRating(dispatch)({
      ...currentRating,
      rules: [...currentRating.rules.slice(0, ruleIdx), ...currentRating.rules.slice(ruleIdx + 1)],
    }).then(
      () => {
        setRuleToManage(undefined)
        setCurrentRatingSelection(0)
      },
      () => {}
    )
  }

  function onSelectionRuleDelete(selectionRuleIdx: number) {
    if (!currentRating || !currentRatingSelection) return Promise.resolve()

    const newRatingRule = {
      ...currentRatingSelection,
      rules: [
        ...currentRatingSelection.rules.slice(0, selectionRuleIdx),
        ...currentRatingSelection.rules.slice(selectionRuleIdx + 1),
      ],
    }

    const selectionIdx = currentRating.rules.indexOf(currentRatingSelection)

    const newRules = [
      ...currentRating.rules.slice(0, selectionIdx),
      newRatingRule,
      ...currentRating.rules.slice(selectionIdx + 1),
    ]

    return doUpdateRating(dispatch)({
      ...currentRating,
      rules: newRules,
    }).then(() => {
      setSelectedRuleIdx(undefined)
      setRuleToManage(undefined)
    })
  }

  async function onSelectionRuleEdit(editedRule: RatingSelectionRule) {
    if (!currentRating || !currentRatingSelection || ruleToManage?.action !== "edit" || "rules" in ruleToManage.rule) {
      return
    }

    const currentRatingSelectionIdx = currentRating.rules.findIndex((r) => r === currentRatingSelection)

    const selectionRuleEditIdx = ruleToManage.idx

    const newSelection = {
      ...currentRatingSelection,
      rules: [
        ...currentRatingSelection.rules.slice(0, selectionRuleEditIdx),
        editedRule,
        ...currentRatingSelection.rules.slice(selectionRuleEditIdx + 1),
      ],
    }
    const newRatingRules = [
      ...currentRating.rules.slice(0, currentRatingSelectionIdx),
      newSelection,
      ...currentRating.rules.slice(currentRatingSelectionIdx + 1),
    ]

    await doUpdateRating(dispatch)({
      ...currentRating,
      rules: newRatingRules,
    })

    setRuleToManage(undefined)

    await fetchRatingResults(dispatch)(
      currentRating,
      agsRefResLoc,
      selectedRatingSelectionIdx,
      selectedRatingSelectionRuleIdx
    )
  }

  async function onSelectionRuleCreate(newRule: RatingSelectionRule) {
    if (!currentRating || !currentRatingSelection) {
      return
    }

    const currentRatingSelectionIdx = currentRating.rules.findIndex((r) => r === currentRatingSelection)

    const newSelection = {
      ...currentRatingSelection,
      rules: [...currentRatingSelection.rules, newRule],
    }
    const newRatingRules = [
      ...currentRating.rules.slice(0, currentRatingSelectionIdx),
      newSelection,
      ...currentRating.rules.slice(currentRatingSelectionIdx + 1),
    ]

    await doUpdateRating(dispatch)({
      ...currentRating,
      rules: newRatingRules,
    })

    setRuleToManage(undefined)
    setShowSelectionRuleAddForm(false)
  }

  function onSelectionCreated(newSelection: RatingSelection) {
    setShowSelectionAddForm(false)
    if (!currentRating) {
      return
    }
    const selectionIdx = currentRating.rules.findIndex((r) => r === newSelection)
    setCurrentRatingSelection(selectionIdx >= 0 ? selectionIdx : 0)
    setSelectedRuleIdx(0)
  }

  function renderRuleDuplicateForm() {
    if (!ruleToManage || ruleToManage?.action !== "duplicate" || !currentRating || !("rules" in ruleToManage.rule)) {
      return <></>
    }
    return (
      <Drawer isOpen={true} size="400px" onClose={() => setRuleToManage(undefined)}>
        <RatingManagerRatingSelectionDuplicate
          onClose={(idx) => {
            setRuleToManage(undefined)
            setSelectedRuleIdx(idx)
          }}
          ruleIdx={currentRating.rules.indexOf(ruleToManage.rule)}
          currentRating={currentRating}
        />
      </Drawer>
    )
  }

  function renderRuleEditForm() {
    if (!ruleToManage || ruleToManage?.action !== "edit" || !currentRating) {
      return <></>
    }
    if ("rules" in ruleToManage.rule) {
      return (
        <Drawer isOpen={true} size="400px" onClose={() => setRuleToManage(undefined)}>
          <RatingManagerRatingSelectionEdit
            onClose={(idx) => {
              setRuleToManage(undefined)
              setSelectedRuleIdx(idx)
            }}
            ruleIdx={currentRating.rules.indexOf(ruleToManage.rule)}
            grades={currentRating.grades}
            currentRating={currentRating}
          />
        </Drawer>
      )
    } else {
      return (
        <Drawer isOpen={true} size="400px" onClose={() => setRuleToManage(undefined)}>
          <RatingManagerSelectionRuleEdit
            rule={ruleToManage.rule}
            onClose={() => setRuleToManage(undefined)}
            onEdited={(result) => onSelectionRuleEdit(result)}
            dataSetType={currentRating.dataSetType}
            availableProfileInProgress={availableProfileInProgress}
            availableProfiles={availableProfiles}
            profileGroups={profileGroups}
          />
        </Drawer>
      )
    }
  }

  return (
    <div className={styles.container}>
      {ruleToManage?.action === "delete" && renderDeleteConfirm()}
      {ruleToManage?.action === "duplicate" && renderRuleDuplicateForm()}
      {ruleToManage?.action === "edit" && renderRuleEditForm()}
      {currentRating && showSelectionRuleAddForm && (
        <Drawer isOpen={true} size="400px" onClose={() => setRuleToManage(undefined)}>
          <RatingManagerSelectionRuleEdit
            onClose={() => setShowSelectionRuleAddForm(false)}
            onEdited={(result) => onSelectionRuleCreate(result)}
            dataSetType={currentRating.dataSetType}
            availableProfileInProgress={availableProfileInProgress}
            availableProfiles={availableProfiles}
            profileGroups={profileGroups}
          />
        </Drawer>
      )}
      {showSelectionAddForm && (
        <Drawer isOpen={true} size="400px" onClose={() => setShowSelectionAddForm(false)}>
          <RatingManagerRatingSelectionCreate
            onClose={() => setShowSelectionAddForm(false)}
            onCreate={onSelectionCreated}
            currentRating={currentRating}
          />
        </Drawer>
      )}
      <div className={cx(styles.withPadding, styles.topFlex)}>
        <Button type="tertiary" icon="back" onClick={() => dispatch(ratingManagerActions.setMenuPane("list"))}>
          {t.allRatings}
        </Button>
        <FlexItem flexGrow={1} />
        <Button type="tertiary" icon="edit" onClick={() => dispatch(ratingManagerActions.setMenuPane("edit-rating"))} />
      </div>
      <div className={styles.withPadding}>
        <h2>{currentRating?.name}</h2>
        <div className={styles.gradesList}>
          {currentRating?.grades.map((grade, idx) => (
            <GradeLabel grade={grade} key={idx} />
          ))}
        </div>
      </div>
      <hr />
      <SplitPane split="horizontal" minSize="20%" defaultSize="50%">
        <Grid rowSpec="min-content 1fr" columns={1} height={[100, "%"]}>
          <BorderBottom backgroundColor={COLORS.background.light} padding={8}>
            <Flex flexDirection="row" alignItems="center">
              <h3>{t.selectionsHeader}</h3>
              <FlexItem flexGrow={1} />
              <Button type="tertiary" icon="add" size="small" onClick={() => setShowSelectionAddForm(true)}>
                {t.addSelectionsRule}
              </Button>
            </Flex>
          </BorderBottom>
          <div className={styles.dragDropContainer}>
            <LoadingDimmer loading={updateInProgress}>
              <DragDrop
                onDragEnd={onRatingRuleReorder}
                droppableId={"ratings"}
                onItemClick={(idx) => setCurrentRatingSelection(idx)}
                items={currentRating?.rules.map(renderRatingRule) ?? []}
              />
            </LoadingDimmer>
          </div>
        </Grid>
        <Grid rowSpec="min-content 1fr" columns={1} height={[100, "%"]}>
          <BorderBottom backgroundColor={COLORS.background.light} padding={8}>
            <Flex flexDirection="row" alignItems="center">
              <h3>
                {t.selectionRuleEdit.selectionRulesHeader}
                {currentRatingSelection ? " : " + currentRatingSelection.title : ""}
              </h3>
              <FlexItem flexGrow={1} />
              <Button
                type="tertiary"
                icon="add"
                size="small"
                disabled={!currentRatingSelection}
                onClick={() => setShowSelectionRuleAddForm(true)}
              >
                {t.selectionRuleEdit.addSelectionRule}
              </Button>
            </Flex>
          </BorderBottom>
          {currentRatingSelection && currentRating && (
            <div className={styles.dragDropContainer}>
              <div onClick={() => setSelectedRuleIdx(0)}>
                <BorderBottom
                  padding={8}
                  backgroundColor={
                    selectedRatingSelectionRuleIdx === 0 ? COLORS.secondary2.light : COLORS.background.default
                  }
                >
                  <Grid columnSpec="24px 1fr" gap={4} alignItems="center">
                    <div />
                    <RatingManagerSelectionRule
                      rule={currentRatingSelection.rules[0]}
                      idx={0}
                      dataSetType={currentRating.dataSetType}
                      onRuleEdit={() =>
                        setRuleToManage({ rule: currentRatingSelection.rules[0], action: "edit", idx: 0 })
                      }
                      availableProfiles={availableProfiles}
                      setRuleToDelete={(rule) => null}
                    />
                  </Grid>
                </BorderBottom>
              </div>
              <DragDrop
                onDragEnd={onSelectionRuleReorder}
                droppableId={"selection"}
                onItemClick={(idx) => setSelectedRuleIdx(idx + 1)}
                items={currentRatingSelection.rules.slice(1).map((item, idx) => renderSelectionRule(item, idx + 1))}
              />
            </div>
          )}
        </Grid>
      </SplitPane>
    </div>
  )
}
