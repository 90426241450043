import React, { useEffect, useMemo } from "react"
import { css } from "emotion"
import { formatDistance, NearestAccessibilityDistances } from "../dashboard/nearest-accessibility-widget"
import { translations } from "../../i18n"
import { Checkbox, Classes } from "@blueprintjs/core"
import { ScrollBox } from "../../../shared/components/ui/scroll-box"
import { localPublicTransportCategory } from "./poi-categories-list"
import { GoogleMapsPOICategory } from "../../models/assessment"
import { calculateCategoryDetails, convertCategoriesForDetailsPane, iconsForCategories } from "./poi-utils"
import {
  NumberedTextSearchIds,
  stringIsNumberedTextSearchId,
  TextSearchIDsToTitle,
} from "../../../shared/models/poi-explorer"
import { useAppSelector } from "../../../relas/store"
import { PlaceResultEncodable } from "../../../shared/models/place-result-encodable"
import Grid from "../../../shared/components/restyle-grid/grid"
import { getThemeColor } from "../../../shared/helper/color"

const styles = {
  rightPanel: css({
    width: "314px",
  }),
  nearestAccessibility: css({
    display: "flex",
    flexDirection: "column",
    padding: "16px 0",
    gap: "8px",
    height: "100%",
  }),
  filters: css({
    display: "flex",
    flexDirection: "column",
    padding: "0 16px",
  }),
}

interface Props {
  nearestAccessibility?: NearestAccessibilityDistances
  isLoading: boolean
  selectedPOICategories: Set<string>
  loadingPois: Set<string>
  isochronePolygon: google.maps.Polygon | undefined
  pois: Map<string, PlaceResultEncodable[]> | null
  showOnlyPrimaryCategories: boolean
  setShowOnlyPrimaryCategories: (show: boolean) => void
  showPoiWithinIsochrone: boolean
  setShowPoiWithinIsochrone: (show: boolean) => void
  textSearch: TextSearchIDsToTitle
}

const PoisDetailsPane = ({
  nearestAccessibility,
  isLoading,
  selectedPOICategories,
  pois,
  loadingPois,
  isochronePolygon,
  textSearch,
  showPoiWithinIsochrone,
  setShowPoiWithinIsochrone,
}: Props) => {
  const t = translations()
  const googleMapsData = useAppSelector((state) => state.googleMapsData)
  const assessmentEntry = useAppSelector((state) => state.assessment.currentAssessmentEntry)

  useEffect(() => {
    if (!isochronePolygon) setShowPoiWithinIsochrone(false)
  }, [isochronePolygon])

  const poiCategories: Map<string, GoogleMapsPOICategory> = googleMapsData.poiCategories
    ? googleMapsData?.poiCategories
        .map(([, v]) => v)
        .reduce((acc, val) => acc.concat(val), [])
        .reduce<Map<string, GoogleMapsPOICategory>>((acc, cat) => {
          acc.set(cat.id, cat)
          return acc
        }, new Map())
    : new Map()

  // Make sure this list matches the list in lana-api in the report
  const categoriesWithDisabledDistances = new Set([
    "airport",
    "local_public_transport",
    "subway_station",
    "train_station",
  ])

  const renderDistanceLine = (label: string, distance: number | undefined, max?: number) => {
    return (
      <>
        <div>{label}</div>
        <div></div>
        {isLoading && <div className={Classes.SKELETON} style={{ width: "4em" }} />}
        {!isLoading && distance && <div>{formatDistance(distance, max)}</div>}
      </>
    )
  }

  const renderPOIcountLine = (categoryId: string, loading: boolean) => {
    const details = calculateCategoryDetails(
      categoryId,
      loading,
      pois,
      assessmentEntry?.address.location,
      poiCategories,
      textSearch,
      showPoiWithinIsochrone && isochronePolygon ? isochronePolygon : undefined
    )
    const categoryTitle = details.title
    const poisCount = details.count
    const [firstDistance, secondDistance, thirdDistance] = details.distances ?? []

    const showDistances = !categoriesWithDisabledDistances.has(categoryId)

    return (
      <React.Fragment key={categoryId}>
        <img src={`/assets/poi-explorer/${iconsForCategories[categoryId]}.svg`} alt="POI" width={25} height={37} />
        <Grid columns={5} rowGap={4} colGap={4} columnSpec={"2em repeat(3, 1fr) 2em"}>
          {loading && (
            <div className={Classes.SKELETON} style={{ width: "24px", height: "18px", alignSelf: "center" }} />
          )}
          {!loading && (
            <div style={{ fontWeight: "bold", textAlign: "left", alignSelf: "center" }}>
              {poisCount ? `${poisCount >= 60 ? "60+" : poisCount}` : "-"}
            </div>
          )}
          <div style={{ gridColumn: "span 4 / auto", fontWeight: "bold", alignSelf: "center" }}>
            {categoryTitle ? t.pickTranslation(categoryTitle) : t.poiExplorer.busAndTransit}
          </div>

          <div style={{ height: "14px" }}></div>
          {showDistances && (
            <>
              {loading && <div className={Classes.SKELETON} style={{ width: "2.5em", height: "22px" }} />}
              {!loading && <div style={{ paddingBottom: "8px" }}>{firstDistance ?? ""}</div>}

              {loading && <div className={Classes.SKELETON} style={{ width: "2.5em", height: "22px" }} />}
              {!loading && <div>{secondDistance ?? ""}</div>}

              {loading && <div className={Classes.SKELETON} style={{ width: "2.5em", height: "22px" }} />}
              {!loading && <div>{thirdDistance ?? ""}</div>}

              {!loading && <div>{poisCount > 3 ? "..." : ""}</div>}
            </>
          )}
        </Grid>
      </React.Fragment>
    )
  }

  const sortedSelectedCategories: string[] = useMemo(() => {
    const converted = convertCategoriesForDetailsPane(selectedPOICategories)
    const [textSearchIds, rest] = converted.reduce<[NumberedTextSearchIds[], string[]]>(
      (acc, id) => {
        const [textSearchIds, rest] = acc
        if (stringIsNumberedTextSearchId(id)) {
          textSearchIds.push(id)
        } else {
          rest.push(id)
        }
        return [textSearchIds, rest]
      },
      [[], []]
    )

    const sortedTextSearches: string[] = textSearchIds
      .sort((a: NumberedTextSearchIds, b: NumberedTextSearchIds) => {
        const titleA = textSearch[a]
        const titleB = textSearch[b]

        return titleA && titleB ? titleA.localeCompare(titleB) : 0
      })
      .reduce<string[]>((acc, id) => {
        const title = textSearch[id]
        title && acc.push(id)
        return acc
      }, [])

    const sortedRest = rest.sort((a, b) => {
      const nameA = poiCategories.get(a)?.title
      const nameB = poiCategories.get(b)?.title

      return nameA && nameB ? t.pickTranslation(nameA).localeCompare(t.pickTranslation(nameB)) : 0
    })

    return [...sortedTextSearches, ...sortedRest]
  }, [selectedPOICategories])

  const sidebarSelectedCategories = sortedSelectedCategories.map((category) => {
    const isLoadingCategory =
      category === "local_public_transport"
        ? [...localPublicTransportCategory].filter((cat) => loadingPois.has(cat)).length > 0
        : loadingPois.has(category)
    return renderPOIcountLine(category, isLoadingCategory)
  })

  return (
    <div className={styles.rightPanel}>
      <div className={styles.nearestAccessibility}>
        <div style={{ fontWeight: "bold", padding: "0 16px" }}>{t.poiExplorer.accessibility}</div>
        <Grid columns={3} columnSpec={"3fr 1fr 2fr"} rowGap={4} padding={[0, 16, 16, 16]}>
          {renderDistanceLine(
            t.poiExplorer.distances.nearestBusStop,
            nearestAccessibility?.nearestBusStop?.distance,
            5000
          )}
          {renderDistanceLine(
            t.poiExplorer.distances.nearestSubwayStation,
            nearestAccessibility?.nearestSubwayStation?.distance,
            5000
          )}
          {renderDistanceLine(t.poiExplorer.distances.nearestAirport, nearestAccessibility?.nearestAirport?.distance)}
        </Grid>
        <div style={{ borderTop: "1px solid", borderColor: `${getThemeColor("border", "default")}` }}></div>
        <div style={{ fontWeight: "bold", paddingTop: "16px", padding: "0 16px" }}>{t.poiExplorer.tabs.poi}</div>
        <div className={styles.filters}>
          <Checkbox
            style={{ marginBottom: "4px" }}
            label={t.poiExplorer.onlyWithinIsochrone}
            disabled={!isochronePolygon}
            checked={showPoiWithinIsochrone}
            onClick={() => setShowPoiWithinIsochrone(!showPoiWithinIsochrone)}
          ></Checkbox>
        </div>
        <ScrollBox>
          <Grid columns={2} gap={8} columnSpec={"25px 1fr"} padding={[0, 4, 0, 16]}>
            {sidebarSelectedCategories}
          </Grid>
        </ScrollBox>
        <div style={{ padding: "0 16px", fontSize: "12px" }}>{t.poiExplorer.infoText}</div>
      </div>
    </div>
  )
}
export default PoisDetailsPane
