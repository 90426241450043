import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  Assessment,
  AssessmentCreateError,
  AssessmentEntryFull,
  AssessmentEntryListViewMode,
  AssessmentEntryScoresWithSources,
  AssessmentEntryWithScore,
  AssessmentEntryWithScoreResult,
  AssessmentPriceType,
  AssessmentState,
  AssessmentTrendData,
  AssessmentYieldData,
  ContextScoreValues,
  ObjectPrices,
  ObjectPricesParams,
  priceTypeForUsageType,
  YieldType,
  yieldTypeForUsageType,
} from "../models/assessment"
import { SelectionRef } from "../models/selection-ref"
import { RentIndexParams, RentIndexQueryResult } from "../models/rentindex"
import { POICategoryTranslations } from "../../shared/models/poi-category-translations"
import { DataSetType } from "../components/district-data"
import { Profile } from "../../profile/models/profile"
import { ComparablesItem } from "../../generated-apis/comparables-service"
import { ASSESSMENT_ENTRY_CHANGED_MODULE } from "../actions/assessment-module-action-creators"
import { HeaderSorting } from "../../shared/components/sortable-table-header"
import { SortDirection } from "../../shared/models/sorting"
import { GenericError } from "../../shared/helper/axios"

export type AssessmentSortField = "createdAt" | "title" | "numberOfAddresses"
export type NotesIcon = "note" | "note_add" | null

export interface AssessmentAppState {
  assessmentList: Assessment[] | null
  assessmentListInProgress: boolean
  assessmentListError: GenericError | null

  availableMacroContexts: SelectionRef[] | null

  assessmentCreateInProgress: boolean
  assessmentCreateError: AssessmentCreateError | null

  assessmentLoadInProgress: boolean
  assessmentLoadError: GenericError | null
  currentAssessment: Assessment | null
  assessmentEntries: AssessmentEntryWithScoreResult | null

  selectedPriceType: AssessmentPriceType
  objectPricesParams: ObjectPricesParams | null
  rentIndexParams: RentIndexParams | null
  selectedYieldType: YieldType

  assessmentEntryLoadInProgress: boolean
  assessmentEntryLoadError: GenericError | null
  currentAssessmentEntry: AssessmentEntryFull | null

  assessmentEntryProfileScoresInProgress: boolean
  assessmentEntryProfileScoresWithSources: AssessmentEntryScoresWithSources | null
  assessmentEntryContextScoresInProgress: boolean
  assessmentEntryContextScores: (ContextScoreValues & { scoreCategoryName: string; macroContext?: string }) | null
  assessmentEntryScoresError: GenericError | null

  objectPricesLoadInProgress: boolean
  objectPricesLoadError: GenericError | null
  currentObjectPrices: ObjectPrices | null

  objectTrendsLoadInProgress: boolean
  objectTrendsLoadError: GenericError | null
  currentObjectTrends: AssessmentTrendData | null

  objectRentIndexInProgress: boolean
  objectRentIndexError: GenericError | null
  currentObjectRentIndex: RentIndexQueryResult | null

  objectYieldsLoadInProgress: boolean
  objectYieldsLoadError: GenericError | null
  currentObjectYields: AssessmentYieldData | null

  assessmentEntryUpdateInProgress: boolean
  assessmentEntryUpdateError: AssessmentCreateError | null

  assessmentListSorting: HeaderSorting<AssessmentSortField>
  assessmentDetailsSearchTerm: string
  assessmentEntriesSorting: HeaderSorting<string>

  entryListViewMode: AssessmentEntryListViewMode
  tagFilter: string | null

  poiCategoryTranslations: POICategoryTranslations | null

  assessmentUpdateInProgress: boolean

  comparablesItems: ComparablesItem[]
  comparablesItemsLoadInProgress: boolean
  comparablesItemsLoadError: GenericError | null

  selectedViewMode: DataSetType
  selectedProfile?: Profile

  notesIcon: NotesIcon
}

export const initialState: AssessmentAppState = {
  assessmentList: null,
  assessmentListInProgress: false,
  assessmentListError: null,

  availableMacroContexts: null,

  assessmentCreateInProgress: false,
  assessmentCreateError: null,

  assessmentLoadInProgress: false,
  assessmentLoadError: null,
  currentAssessment: null,
  assessmentEntries: null,

  assessmentEntryLoadInProgress: false,
  assessmentEntryLoadError: null,
  currentAssessmentEntry: null,

  selectedPriceType: "residentialRent",
  selectedYieldType: "residential",
  objectPricesParams: null,
  rentIndexParams: null,

  assessmentEntryProfileScoresInProgress: false,
  assessmentEntryScoresError: null,
  assessmentEntryProfileScoresWithSources: null,

  assessmentEntryContextScoresInProgress: false,
  assessmentEntryContextScores: null,

  objectPricesLoadInProgress: false,
  objectPricesLoadError: null,
  currentObjectPrices: null,

  objectTrendsLoadInProgress: false,
  objectTrendsLoadError: null,
  currentObjectTrends: null,

  objectRentIndexInProgress: false,
  objectRentIndexError: null,
  currentObjectRentIndex: null,

  objectYieldsLoadInProgress: false,
  objectYieldsLoadError: null,
  currentObjectYields: null,

  assessmentEntryUpdateInProgress: false,
  assessmentEntryUpdateError: null,

  assessmentListSorting: [SortDirection.DESC, "createdAt"],

  assessmentDetailsSearchTerm: "",
  assessmentEntriesSorting: [SortDirection.ASC, "locality"],

  entryListViewMode: "list",
  tagFilter: null,

  poiCategoryTranslations: null,

  assessmentUpdateInProgress: false,

  comparablesItemsLoadError: null,
  comparablesItemsLoadInProgress: false,
  comparablesItems: [],

  selectedViewMode: "macro",

  notesIcon: null,
}

const assessmentSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {
    assessmentListStart(state) {
      state.assessmentList = null
      state.assessmentListInProgress = true
      state.assessmentListError = null
    },
    assessmentListDone(state, action: PayloadAction<Assessment[]>) {
      const newTags = new Set<string>()
      action.payload.forEach((a: Assessment) => a.tags.forEach((tag) => newTags.add(tag)))
      state.assessmentList = action.payload
      state.assessmentListInProgress = false
      state.assessmentListError = null
      state.tagFilter = (state.tagFilter && newTags.has(state.tagFilter) && state.tagFilter) || null
    },
    assessmentListError(state, action: PayloadAction<GenericError>) {
      state.assessmentList = null
      state.assessmentListInProgress = false
      state.assessmentListError = action.payload
    },
    setAvailableSelectionsDone(state, action: PayloadAction<SelectionRef[]>) {
      state.availableMacroContexts = action.payload.filter(
        (selection: SelectionRef) => selection.dataSetType === "macro"
      )
    },
    assessmentCreateSingleStart(state) {
      state.assessmentCreateInProgress = true
      state.assessmentCreateError = null
    },
    assessmentCreateSingleDone(state, action: PayloadAction<Assessment>) {
      state.assessmentCreateInProgress = false
      state.assessmentCreateError = null
      state.currentAssessment = action.payload
      state.assessmentEntries = null
      state.currentAssessmentEntry = null
    },
    assessmentCreateSingleError(state, action: PayloadAction<AssessmentCreateError | null>) {
      state.assessmentCreateInProgress = false
      state.assessmentCreateError = action.payload
    },
    assessmentLoadStart(state) {
      state.assessmentLoadInProgress = true
      state.assessmentLoadError = null
      state.assessmentEntries = null
    },
    assessmentLoadError(state, action: PayloadAction<GenericError>) {
      state.assessmentLoadInProgress = false
      state.assessmentLoadError = action.payload
    },
    assessmentLoadDone(
      state,
      action: PayloadAction<{
        assessment: Assessment
        entries: AssessmentEntryWithScoreResult
      }>
    ) {
      const newAssessmentTags = new Set<string>()
      action.payload.entries.entries.forEach((a: AssessmentEntryWithScore) =>
        a.tags.forEach((tag) => newAssessmentTags.add(tag))
      )
      state.assessmentLoadInProgress = false
      state.assessmentDetailsSearchTerm = ""
      state.currentAssessment = action.payload.assessment
      state.assessmentEntries = action.payload.entries
      state.entryListViewMode =
        action.payload.assessment.id === state.currentAssessment?.id ? state.entryListViewMode : "list"
      state.tagFilter = (state.tagFilter && newAssessmentTags.has(state.tagFilter) && state.tagFilter) || null
    },
    assessmentEntryLoadStart(state) {
      state.assessmentEntryLoadInProgress = true
      state.assessmentEntryLoadError = null
      state.currentObjectTrends = null
    },
    assessmentEntryLoadDone(
      state,
      action: PayloadAction<{
        entry: AssessmentEntryFull
      }>
    ) {
      const newObjectPricesParams =
        action.payload.entry.id !== state.currentAssessmentEntry?.id ? null : state.objectPricesParams

      const newRentIndexParams =
        action.payload.entry.id !== state.currentAssessmentEntry?.id ? null : state.rentIndexParams

      state.assessmentEntryLoadInProgress = false
      state.assessmentEntryLoadError = null
      state.assessmentEntryProfileScoresWithSources = null
      state.currentAssessmentEntry = action.payload.entry
      state.selectedPriceType = priceTypeForUsageType(action.payload.entry.usageType) || "residentialRent"
      state.selectedYieldType = yieldTypeForUsageType(action.payload.entry.usageType) || "residential"
      state.objectPricesParams = newObjectPricesParams
      state.rentIndexParams = newRentIndexParams
    },
    setObjectPricesParams(state, action: PayloadAction<ObjectPricesParams | null>) {
      state.objectPricesParams = action.payload
        ? {
            ...action.payload,
          }
        : null
    },
    setRentIndexParams(state, action: PayloadAction<RentIndexParams | null>) {
      state.rentIndexParams = action.payload
        ? {
            ...action.payload,
          }
        : null
    },
    setSelectedPriceTypeDone(state, action: PayloadAction<AssessmentPriceType>) {
      state.selectedPriceType = action.payload
    },
    setSelectedYieldTypeDone(state, action: PayloadAction<YieldType>) {
      state.selectedYieldType = action.payload
    },
    assessmentEntryLoadError(state, action: PayloadAction<GenericError>) {
      state.assessmentEntryLoadInProgress = false
      state.assessmentEntryLoadError = action.payload
    },
    assessmentEntryProfileScoresLoadStart(state, action: PayloadAction<string | undefined>) {
      state.assessmentEntryProfileScoresInProgress = true
      state.assessmentEntryScoresError = null
      if (state.currentAssessment) {
        state.currentAssessment.macroContext = action.payload
      }
    },
    assessmentEntryProfileScoresLoadDone(state, action: PayloadAction<AssessmentEntryScoresWithSources>) {
      state.assessmentEntryProfileScoresInProgress = false
      state.assessmentEntryScoresError = null
      state.assessmentEntryProfileScoresWithSources = action.payload
    },
    assessmentEntryProfileScoresLoadError(state, action: PayloadAction<GenericError>) {
      state.assessmentEntryProfileScoresInProgress = false
      state.assessmentEntryScoresError = action.payload
    },
    assessmentEntryContextScoresLoadStart(state) {
      state.assessmentEntryContextScoresInProgress = true
      state.assessmentEntryScoresError = null
      state.assessmentEntryContextScores = null
    },
    assessmentEntryContextScoresLoadDone(
      state,
      action: PayloadAction<AssessmentAppState["assessmentEntryContextScores"]>
    ) {
      state.assessmentEntryContextScoresInProgress = false
      state.assessmentEntryScoresError = null
      state.assessmentEntryContextScores = action.payload
    },
    assessmentEntryContextScoresLoadError(state, action: PayloadAction<GenericError>) {
      state.assessmentEntryContextScoresInProgress = false
      state.assessmentEntryScoresError = action.payload
      state.assessmentEntryContextScores = null
    },
    assessmentEntryUpdateStart(state) {
      state.assessmentEntryUpdateInProgress = true
      state.assessmentEntryUpdateError = null
    },
    assessmentEntryUpdateDone(state, action: PayloadAction<AssessmentEntryFull>) {
      if (state.assessmentEntries) {
        const idx = state.assessmentEntries.entries.findIndex((a) => a.id === action.payload.id)
        if (idx >= 0) {
          state.assessmentEntries = {
            ...state.assessmentEntries,
            entries: [
              ...state.assessmentEntries.entries.slice(0, idx),
              action.payload,
              ...state.assessmentEntries.entries.slice(idx + 1),
            ],
          }
        }
      }
      if (state.currentAssessmentEntry?.id === action.payload.id) {
        state.currentAssessmentEntry = action.payload
        state.selectedPriceType = priceTypeForUsageType(action.payload.usageType) || "residentialRent"
        state.selectedYieldType = yieldTypeForUsageType(action.payload.usageType) || "residential"
      }
      state.assessmentEntryUpdateInProgress = false
      state.assessmentEntryUpdateError = null
    },
    assessmentEntryUpdateError(state, action: PayloadAction<AssessmentCreateError>) {
      state.assessmentEntryUpdateInProgress = false
      state.assessmentEntryUpdateError = action.payload
    },
    assessmentEntryTagsUpdateDone(state, action: PayloadAction<string[]>) {
      if (state.currentAssessmentEntry) {
        state.currentAssessmentEntry.tags = action.payload
      }
    },
    assessmentEntryTagsUpdateError(state, action: PayloadAction<GenericError>) {
      state.assessmentEntryUpdateInProgress = false
      state.assessmentEntryUpdateError = action.payload
    },
    assessmentClearErrors(state) {
      state.assessmentCreateError = null
      state.assessmentLoadError = null
      state.assessmentEntryLoadError = null
      state.assessmentEntryScoresError = null
      state.assessmentEntryUpdateError = null
    },
    updateAssessmentStart(state) {
      state.assessmentUpdateInProgress = true
    },
    updateAssessmentDone(state, action: PayloadAction<Assessment>) {
      if (state.assessmentList) {
        const idx = state.assessmentList.findIndex((a) => a.id === action.payload.id)
        if (idx >= 0) {
          state.assessmentList = [
            ...state.assessmentList.slice(0, idx),
            action.payload,
            ...state.assessmentList.slice(idx + 1),
          ]
        }
      }
      state.currentAssessment =
        state.currentAssessment && state.currentAssessment.id === action.payload.id
          ? action.payload
          : state.currentAssessment
      state.assessmentUpdateInProgress = false
    },
    setTagFilterDone(state, action: PayloadAction<string | null>) {
      state.tagFilter = action.payload
    },
    setAssessmentStatusDone(state, action: PayloadAction<AssessmentState>) {
      if (state.currentAssessment) {
        state.currentAssessment.state = action.payload
      }
    },
    setEntryListViewModeDone(state, action: PayloadAction<AssessmentEntryListViewMode>) {
      state.entryListViewMode = action.payload
    },
    setPOICategoryTranslationsDone(state, action: PayloadAction<POICategoryTranslations>) {
      state.poiCategoryTranslations = action.payload
    },
    setAssessmentDetailsSearchTermDone(state, action: PayloadAction<string>) {
      state.assessmentDetailsSearchTerm = action.payload
    },
    setAssessmentEntriesSortingDone(state, action: PayloadAction<HeaderSorting<string>>) {
      state.assessmentEntriesSorting = action.payload
    },
    setAssessmentListSortingDone(state, action: PayloadAction<HeaderSorting<AssessmentSortField>>) {
      state.assessmentListSorting = action.payload
    },
    objectPricesLoadStart(state, action: PayloadAction<ObjectPricesParams>) {
      state.objectPricesLoadInProgress = true
      state.objectPricesLoadError = null
      state.objectPricesParams = action.payload
    },
    objectPricesLoadError(state, action: PayloadAction<GenericError>) {
      state.objectPricesLoadInProgress = false
      state.objectPricesLoadError = action.payload
      state.currentObjectPrices = null
    },
    objectPricesLoadDone(state, action: PayloadAction<ObjectPrices>) {
      state.objectPricesLoadInProgress = false
      state.objectPricesLoadError = null
      state.currentObjectPrices = action.payload
    },
    objectTrendsLoadStart(state) {
      state.objectTrendsLoadInProgress = true
    },
    objectTrendsLoadError(state, action: PayloadAction<GenericError>) {
      state.objectTrendsLoadInProgress = false
      state.objectTrendsLoadError = action.payload
      state.currentObjectTrends = null
    },
    objectTrendsLoadDone(state, action: PayloadAction<AssessmentTrendData>) {
      state.objectTrendsLoadInProgress = false
      state.objectTrendsLoadError = null
      state.currentObjectTrends = action.payload
    },
    objectRentIndexStart(state, action: PayloadAction<RentIndexParams>) {
      state.objectRentIndexInProgress = true
      state.objectRentIndexError = null
      state.rentIndexParams = action.payload
    },
    objectRentIndexDone(state, action: PayloadAction<RentIndexQueryResult>) {
      state.objectRentIndexInProgress = false
      state.objectRentIndexError = null
      state.currentObjectRentIndex = action.payload
    },
    objectRentIndexError(state, action: PayloadAction<GenericError>) {
      state.objectRentIndexInProgress = false
      state.objectRentIndexError = action.payload
      state.currentObjectRentIndex = null
    },
    objectYieldsLoadStart(state) {
      state.objectYieldsLoadInProgress = true
    },
    objectYieldsLoadError(state, action: PayloadAction<GenericError>) {
      state.objectYieldsLoadInProgress = false
      state.objectYieldsLoadError = action.payload
      state.currentObjectYields = null
    },
    objectYieldsLoadDone(state, action: PayloadAction<AssessmentYieldData>) {
      state.objectYieldsLoadInProgress = false
      state.objectYieldsLoadError = null
      state.currentObjectYields = action.payload
    },
    setSelectedViewModeDone(state, action: PayloadAction<DataSetType>) {
      state.selectedViewMode = action.payload
    },

    setSelectedProfileDone(state, action: PayloadAction<Profile | undefined>) {
      state.selectedProfile = action.payload
    },
    setNoteIconDone(state, action: PayloadAction<NotesIcon>) {
      state.notesIcon = action.payload
    },
    assessmentLoadDoneModule(
      state,
      action: PayloadAction<{
        assessment: Assessment
        entry?: AssessmentEntryFull
      }>
    ) {
      state.currentAssessment = action.payload.assessment
      if (action.payload.entry) {
        state.currentAssessmentEntry = action.payload.entry
      }
    },
    clearAssessmentState(state) {
      state.currentAssessment = null
      state.currentAssessmentEntry = null
    },
  },
  extraReducers: {
    [ASSESSMENT_ENTRY_CHANGED_MODULE]: (state, action) => {
      if (action.payload.newId !== action.payload.oldId) {
        state.objectPricesParams = null
        state.rentIndexParams = null
      }
    },
  },
})

export const assessmentDispatchActions = assessmentSlice.actions

export default assessmentSlice.reducer
