import { ProfileTranslationsGroup } from "./translations"
import { SHARED_DE } from "../../shared/i18n/de"

export const PROFILE_DE: ProfileTranslationsGroup = {
  ...SHARED_DE,
  profileExplorer: {
    header: "Profile Manager",
    downloadMacroScores: "Download Macro Scores",
    downloadOptions: "Download Optionen",
    downloadFromScore: "Von Score",
    downloadToScore: "Bis Score",
  },
  profileAdd: {
    name: "Name",
    title: "Profil anlegen",
    create: "Anlegen",
    add: "Neues Profil",
    group: "Neue Gruppe",
    otherProfilesCategory: "Profile",
  },
  profileEdit: {
    menuItem: "Bearbeiten",
    addMicroScore: "Mikro Score hinzufügen",
    addMacroScore: "Makro Score hinzufügen",
    inverting: "Invertiert",
    weight: "Gewicht",
    scoreWeighting: "Scores gewichten",
    scoreWeightingInfo: "Alle Gewichte müssen größer Null sein und zusammen 100 ergeben",
    removingScoreAlertTitle: "Sie haben einen Score entfernt",
    removingScoreAlertText: "Wie soll sein Gewicht auf die verbliebenen Scores verteilt werden?",
    changingScoreAlertTitle: "Sie haben die Liste der Scores verändert",
    changingScoreAlertText: "Wie sollen die Gewichte zwischen den Scores verteilt werden?",
    modifiedProfileAlertTitle: "Das Profil wurde verändert",
    modifiedProfileAlertText: "Sollen die Änderungen gespeichert werden?",
    numericInput: "Numerische Eingabe",
    distributeEvenly: "Gleichmäßig verteilen",
  },
  profileDuplicate: {
    title: "Profil duplizieren",
    menuItem: "Duplizieren",
    nameSuffix: " Kopie",
  },
  profileRename: {
    title: "Profil umbenennen",
    menuItem: "Umbenennen",
  },
  profileDelete: {
    menuItem: "Löschen",
    title: "Profil löschen",
    confirm: (name: string) => `Wollen Sie das Profil '${name}' wirklich löschen?`,
    inUseTitle: "Profil kann nicht gelöscht werden",
    profileIsInUse: (name: string) => `Das Profil ${name} wird an folgenden Stellen verwendet.`,
    profile: "Profil",
    ratings: "Rating",
    selections: "Selection",
  },
  profileLock: {
    lockMenuItem: "Sperren",
    lockTitle: "Profil sperren",
    lockConfirm:
      "Durch das Sperren lässt sich ein Profil schützen. Ihre Kollegen können es dann immer noch nutzen, aber nicht bearbeiten oder löschen.",
    unlockConfirm: "Wenn Sie das Profil entsperren, werden es Ihre Kollegen bearbeiten und löschen können.",
    unlockTitle: "Profil entsperren",
    unlockMenuItem: "Entsperren",
  },
  profileList: {
    title: "Profile",
    backTo: "Alle Profile",
  },
  profileGroup: {
    title: "Gruppe erstellen",
  },
  groupRename: {
    title: "Gruppe umbenennen",
    menuItem: "Umbenennen",
  },
  groupDelete: {
    menuItem: "Löschen",
    title: "Gruppe löschen",
    confirm: (name: string) =>
      `"Wollen Sie die Gruppe '${name}' wirklich löschen? Die darin befindlichen Profile werden dabei nicht gelöscht.`,
  },
  scoreSearchFilter: {
    filter: "Filter",
    groups: "Gruppen",
    expandActive: "Aktive Gruppen ausklappen",
    expandAll: "Alle Gruppen ausklappen",
    collapseAll: "Alle Gruppen einklappen",
    info: "Über diese Eingabe lassen sich passende Scores filtern. Dabei werden Namen und Beschreibung durchsucht.",
  },
}
