import * as React from "react"
import { useMemo } from "react"
import { BorderBottom } from "../../shared/components/ui/border-bottom"
import { COLORS } from "../../shared/components/ui/colors"
import { Flex } from "../../shared/components/ui/flex"
import { FlexItem } from "../../shared/components/ui/flex-item"
import { Grid } from "../../shared/components/ui/grid"
import { GridItem } from "../../shared/components/ui/grid-item"
import { ScrollBox } from "../../shared/components/ui/scroll-box"
import { PopupMenu } from "../../shared/components/ui/popup-menu"
import { ExplorerRatingGradeLabel } from "../../shared/components/explorer-rating-grade-label"
import { translations } from "../i18n"
import { css } from "emotion"
import { useAppDispatch, useAppSelector } from "../../relas/store"
import { isRatingsResults } from "../rating-manager-common"
import { extractLabel } from "../../shared/models/selection"
import { isMetaRating, RatingResult } from "../../shared/models/ratings"
import { lanaApiUrl } from "../../app_config"
import { ratingManagerActions } from "../rating-manager-slice"
import { fetchRatingResults } from "../rating-manager-actions"
import { language } from "../../shared/language"
import { formatNumber, formatValue } from "../../shared/helper/number-format"
import LoadingSpinner from "../../shared/components/loadingspinner"

const styles = {
  municipality: css({
    padding: "8px 16px",
    cursor: "pointer",
    display: "flex",
    gap: "8px",
    borderBottom: "1px solid",
    borderColor: COLORS.border.default,
  }),
  gradeBlock: css({
    flex: "none",
    marginTop: "14px",
  }),
  municipalityTextBlock: css({
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  }),
  headerBackgroundColour: "#f5f8fa",
  container: css({
    height: "100%",
    display: "flex",
    flexDirection: "column",
  }),
}

export const MunicipalityList = () => {
  const t = React.useMemo(translations, [translations])

  const {
    currentRating,
    isLoading,
    agsRefResLoc,
    ratingResults,
    selectedRatingSelectionIdx,
    selectedRatingSelectionRuleIdx,
    selectedMunicipalityId,
    menuPane,
    showPreview,
  } = useAppSelector((state) => ({
    currentRating: state.ratingManager.currentRating,
    isLoading: state.ratingManager.getInProgress || state.ratingManager.ratingResultsInProgress,
    agsRefResLoc: state.ratingManager.agsRefResLoc,
    ratingResults: state.ratingManager.ratingResults,
    selectedRatingSelectionIdx: state.ratingManager.selectedRatingSelectionIdx,
    selectedRatingSelectionRuleIdx: state.ratingManager.selectedRatingSelectionRuleIdx,
    selectedMunicipalityId: state.ratingManager.selectedMunicipalityAndFocus?.selectedMunicipalityId,
    menuPane: state.ratingManager.menuPane,
    showPreview: state.ratingManager.showPreview,
  }))

  const dispatch = useAppDispatch()

  const content = useMemo(() => {
    if (isLoading) {
      return undefined
    }

    return {
      header: renderHeader(),
      municipalities: renderMunicipalities(),
    }
  }, [currentRating, ratingResults, selectedMunicipalityId])

  function onDownload() {
    const lang = language()

    if (!currentRating) {
      return
    }
    let params: object = { agsRefResLoc, lang }

    switch (menuPane) {
      case "list":
      case "duplicate":
      case "rename":
        break
      case "edit":
      case "edit-rating":
        params = {
          ...params,
          ratingRuleIdx: selectedRatingSelectionIdx,
          selectionRuleCount:
            selectedRatingSelectionRuleIdx !== undefined ? selectedRatingSelectionRuleIdx + 1 : undefined,
        }
        break
      case "create":
        return
    }

    const paramsString = new URLSearchParams(JSON.parse(JSON.stringify(params))).toString()
    window.open(`${lanaApiUrl}/api/ratings/${currentRating.id}/download?${paramsString}`, "_blank")
  }

  function renderHeader() {
    if (!ratingResults || !currentRating) {
      return <></>
    }
    const isRatingResults = ratingResults && isRatingsResults(ratingResults)
    let gradeLabels: React.ReactNode = undefined
    if (isRatingResults && !isMetaRating(currentRating)) {
      const gradeCounts = ratingResults.results.reduce((acc, result) => {
        acc[result.gradeIdx] = (acc[result.gradeIdx] ?? 0) + 1
        return acc
      }, {} as Record<number, number>)

      gradeLabels = currentRating.grades.map((grade, idx) => (
        <React.Fragment key={idx}>
          <ExplorerRatingGradeLabel grade={grade} />
          <div>
            {formatNumber(gradeCounts[idx] ?? 0)} (
            {formatValue(
              ratingResults.originalCount > 0 ? (gradeCounts[idx] ?? 0) / ratingResults.originalCount : 0,
              2,
              "%"
            )}
            )
          </div>
        </React.Fragment>
      ))
    }
    return (
      <BorderBottom>
        <Grid
          columnSpec="min-content 1fr"
          padding={16}
          rowGap={4}
          colGap={8}
          backgroundColor={styles.headerBackgroundColour}
        >
          <GridItem colSpan={2}>
            <Flex flexDirection="row">
              <h2>
                {ratingResults && formatNumber(ratingResults.results.length)} {t.municipalitiesTab.header}
              </h2>
              <FlexItem flexGrow={1} />
              <PopupMenu
                actions={[
                  {
                    title: t.municipalitiesTab.download,
                    onClick: onDownload,
                  },
                ]}
              />
            </Flex>
          </GridItem>
          {gradeLabels}
        </Grid>
      </BorderBottom>
    )
  }

  function onMunicipalityClick(refId: string) {
    dispatch(
      ratingManagerActions.setSelectedMunicipalityAndFocus({
        selectedMunicipalityId: refId,
        focusInMap: true,
      })
    )

    if (currentRating) {
      if (showPreview) {
        void fetchRatingResults(dispatch)(currentRating, agsRefResLoc)
      } else {
        void fetchRatingResults(dispatch)(
          currentRating,
          agsRefResLoc,
          selectedRatingSelectionIdx,
          selectedRatingSelectionRuleIdx
        )
      }
    }
  }

  function renderMunicipalities() {
    const isRatingResults = ratingResults && isRatingsResults(ratingResults)
    return ratingResults?.results.map((result, idx) => {
      const selected = result.refId === selectedMunicipalityId
      const label: any = extractLabel(
        result,
        isRatingResults ? (result as RatingResult).selectionLabel : ratingResults.selectionLabel
      )

      return (
        <div
          key={idx}
          className={styles.municipality}
          style={{ backgroundColor: selected ? COLORS.secondary2.light : undefined }}
          onClick={() => onMunicipalityClick(result.refId)}
        >
          {currentRating && isRatingResults && !isMetaRating(currentRating) && (
            <ExplorerRatingGradeLabel
              additionalClass={styles.gradeBlock}
              grade={currentRating.grades[(result as RatingResult).gradeIdx]}
            />
          )}
          <div className={styles.municipalityTextBlock}>
            <h3>{result.fields.ags_name}</h3>
            <div>
              {t.municipalitiesTab.population}: {formatNumber(result.fields.population as number)}
            </div>
            {label && (
              <div>
                {t.pickTranslation(label.label)}:{" "}
                {formatValue(label.value ? label.value : 0, 2, label.unit ? t.pickTranslation(label.unit) : undefined)}
              </div>
            )}
          </div>
        </div>
      )
    })
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <div className={styles.container}>
      {content?.header}
      <ScrollBox>{content?.municipalities}</ScrollBox>
    </div>
  )
}
