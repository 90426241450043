import * as React from "react"
import { css, cx } from "emotion"
import { Property } from "csstype"
import Grid from "./restyle-grid/grid"
import GridItem from "./restyle-grid/griditem"
import { getThemeColorVar } from "../helper/color"
import { ColorType, RevoColor } from "../util/colorconstants"
import { isNullOrUndefined } from "../helper/utils"

export type Props<C extends RevoColor> = {
  checked: boolean
  id?: string
  name?: string
  label?: string | React.ReactNode
  disabled?: boolean
  small?: boolean
  onChange?: (val: boolean) => void
  whiteSpace?: Property.WhiteSpace
} & ColorProps<C>

type ColorProps<C extends RevoColor> =
  | {
      color: C
      colorType: ColorType<C>
    }
  | {
      color?: undefined
      colorType?: undefined
    }

const toggleClass = css({
  border: "none",
  borderRadius: "0px",
  cursor: "pointer",
  boxSizing: "border-box",
  userSelect: "none",
})

const toggleSliderClass = css({
  cursor: "pointer",
  borderRadius: "50%",
  transition: ".4s",
  marginTop: "3px",
  marginLeft: "5px",
  width: "20px",
  height: "20px",
  backgroundColor: getThemeColorVar("white", undefined),
  border: "1px solid rgba(0,0,0, .3)",
})

const toggleSliderChecked = css({
  transform: "translateX(15px)",
})

const toggleSliderSmallClass = css({
  height: "16px",
  width: "16px",
  marginTop: 0,
  marginLeft: 0,
})

const railClass = css({
  width: "45px",
  height: "26px",
  cursor: "pointer",
  borderRadius: "15px",
  border: `2px solid ${getThemeColorVar("border", "default")}`,
  transition: ".4s",
})

const railUncheckedClass = css({
  backgroundColor: getThemeColorVar("white", undefined),
  transition: ".4s",
})

const railSmallClass = css({
  height: "16px",
  width: "31px",
  borderRadius: "8px",
  border: `1px solid ${getThemeColorVar("border", "default")}`,
})

export class Toggle<C extends RevoColor> extends React.Component<Props<C>, {}> {
  static defaultProps = {
    checked: false,
    color: "primary",
    colorType: "default",
  }

  render() {
    const classes = [toggleClass]
    const railClasses = [railClass]
    const sliderClasses = [toggleSliderClass]

    if (isNullOrUndefined(this.props.whiteSpace)) {
      classes.push(css({ whiteSpace: "nowrap" }))
    } else {
      classes.push(css({ whiteSpace: this.props.whiteSpace as any }))
    }

    if (this.props.small) {
      sliderClasses.push(toggleSliderSmallClass)
      railClasses.push(railSmallClass)
    }
    this.props.color &&
      this.props.checked &&
      railClasses.push(
        css({
          backgroundColor: getThemeColorVar(this.props.color, this.props.colorType as ColorType<C>),
          transition: ".4s",
          borderStyle: "none",
        })
      )

    !this.props.checked && railClasses.push(railUncheckedClass)
    this.props.checked && sliderClasses.push(toggleSliderChecked)

    this.props.color &&
      this.props.checked &&
      this.props.small &&
      sliderClasses.push(
        css({
          borderColor: getThemeColorVar(this.props.color, this.props.colorType as ColorType<C>),
        })
      )

    if (this.props.disabled) {
      railClasses.push(css({ backgroundColor: getThemeColorVar("background", "light") }))
      classes.push(css({ cursor: "not-allowed" }))
    }

    return (
      <div
        onClick={() => !this.props.disabled && this.props.onChange && this.props.onChange(!this.props.checked)}
        className={cx("re-toggle", classes)}
      >
        <Grid columnSpec="fit-content(100%) 1fr" gap="md">
          <GridItem colStart={1} rowStart={1}>
            <div className={cx(railClasses)}></div>
          </GridItem>
          <GridItem colStart={1} rowStart={1}>
            <input
              type="hidden"
              id={this.props.id}
              name={this.props.name}
              value={this.props.checked ? "true" : "false"}
              disabled={this.props.disabled}
            />
          </GridItem>
          <GridItem colStart={1} rowStart={1}>
            <div className={cx(sliderClasses)}> </div>
          </GridItem>
          {this.props.label && (
            <GridItem colStart={2} rowStart={1} alignSelf="center" justifySelf="start">
              <div>{this.props.label}</div>
            </GridItem>
          )}
        </Grid>
      </div>
    )
  }
}

export default Toggle
