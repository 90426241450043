import * as React from "react"
import { FC, ReactElement, useMemo } from "react"
import { Popover, PopoverInteractionKind, PopoverPosition } from "@blueprintjs/core"
import { Grid } from "./ui/grid"
import { GridItem } from "./ui/grid-item"
import { css, injectGlobal } from "emotion"
import { translations } from "../../assessment/i18n"
import Button from "./button"
import Icon from "./icon"

const borderBottom = css({
  borderBottom: "2px solid #DDDDDD",
  padding: "16px 16px 16px 16px",
})

const pointer = css({
  cursor: "pointer",
})

export type Props = {
  text: ReactElement
  position: PopoverPosition
}

injectGlobal`
  .help p {
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  }
`

const helpCss = css({
  width: "380px",
  padding: "16px",
  fontSize: "14px",
  fontWeight: "normal",
})

export const HelpPopover: FC<Props> = (p) => {
  const t = useMemo(translations, [translations])
  const [isOpen, setIsOpen] = React.useState(false)
  const helpIcon = isOpen ? (
    <Icon color="primary" colorType="dark" name="help_active" fontSize={16} />
  ) : (
    <Icon name="help" fontSize={16} />
  )

  return (
    <Popover
      position={p.position}
      interactionKind={PopoverInteractionKind.CLICK}
      hasBackdrop
      isOpen={isOpen}
      modifiers={{ arrow: { enabled: false }, offset: { offset: "-20,-20" } }}
      onInteraction={(nextIsOpen) => setIsOpen(nextIsOpen)}
      target={<div className={`${pointer}`}>{helpIcon}</div>}
      content={
        <Grid columns={1} padding={0} gap={8}>
          <div className={`${borderBottom}`}>
            <Grid columns={2}>
              <h3>{t.helpTitle}</h3>
              <GridItem justifySelf={"end"}>
                <div className="bp4-popover-dismiss ">
                  <Button type="tertiary" icon="close" size="small" onClick={() => {}} />
                </div>
              </GridItem>
            </Grid>
          </div>
          <div className={`${helpCss} help`}>{p.text}</div>
        </Grid>
      }
    />
  )
}
