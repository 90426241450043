import React, { useEffect, useMemo, useState } from "react"
import { translations } from "../../i18n"
import { css, cx } from "emotion"
import { COLORS } from "../../../shared/components/ui/colors"
import { GraphScale } from "../../models/graph-scale"
import { AssessmentPriceType, Exclusiveness, HouseOrApartment, PriceRange, UsageType } from "../../models/assessment"
import { DashboardBarGraph } from "./dashboard-bar-graph"
import { widgetStyles } from "./dashboard-widget"
import { Location } from "../../models/address"
import { Tooltip } from "../../../shared/components/ui/tooltip"
import { DashboardEditMode } from "./dashboard-widget-grid"
import { valuesOfPriceRange } from "../../../utils/utils"
import { fetchMachineLearningUnitPrice } from "../../reducers/assessment-slice-functions"
import Grid from "../../../shared/components/restyle-grid/grid"
import { getThemeColorVar } from "../../../shared/helper/color"
import Icon from "../../../shared/components/icon"

export interface Props {
  onHeaderClick: () => void
  onRemoveClick: () => void
  printMode: boolean
  editMode: DashboardEditMode
  cellId?: number
  onRenderingDone: () => void

  markerLocation?: Location

  agsPrices?: { [key in AssessmentPriceType]?: PriceRange }
  cellPrices?: { [key in AssessmentPriceType]?: PriceRange }

  // object properties
  usageType: UsageType | undefined
  exclusiveness?: Exclusiveness
  constructionYear?: number
  area?: number
  newBuilding?: boolean
}

const styles = {
  widgetHeader: (editMode: boolean) =>
    cx(
      css({
        display: "flex",
        gap: "12px",
      }),
      "dashboard-widget-header",
      editMode && "edit-mode"
    ),
  barGraphRow: css({
    height: "20px",
    display: "grid",
    gridTemplateColumns: "repeat(100, 1fr)",
    alignSelf: "center",
    backgroundColor: `${getThemeColorVar("background", "light")}`,
  }),
  rowLabel: css({
    fontWeight: "bold",
    alignSelf: "center",
  }),
  valuesGrid: css({
    padding: "4px",
    display: "grid",
    columnGap: "8px",
    alignSelf: "center",
    gridTemplateColumns: "repeat(3, 1fr)",
  }),
  unitSettings: css({
    fontSize: "12px",
    paddingBottom: "4px",
  }),
  contentStyle: css({
    padding: "4px 8px",
  }),
}

const MLPricesWidget = ({
  usageType,
  onHeaderClick,
  onRemoveClick,
  printMode,
  editMode,
  agsPrices,
  cellPrices,
  cellId,
  markerLocation,
  exclusiveness,
  constructionYear,
  area,
  newBuilding,
  onRenderingDone,
}: Props) => {
  const t = useMemo(translations, [translations])

  const [unitMLPrices, setUnitMLPrices] = useState<PriceRange>()

  const unitExclusiveness: 1 | 2 | 3 =
    !exclusiveness || exclusiveness === Exclusiveness.Standard ? 1 : exclusiveness === Exclusiveness.High ? 2 : 3

  const mlPricesUnitSettings = {
    construction_year: constructionYear ?? 2000,
    area: area ?? 100,
    class: unitExclusiveness,
    new_building: newBuilding ? 1 : 0,
  }

  const houseOrApartment: HouseOrApartment | undefined =
    usageType === UsageType.ResidentialHouseRent || usageType === UsageType.ResidentialHouseSale ? "house" : "apartment"

  useEffect(() => {
    if (markerLocation) {
      fetchMachineLearningUnitPrice(markerLocation, cellId, usageType || UsageType.ResidentialApartmentRent, {
        constructionYear: mlPricesUnitSettings.construction_year,
        area: mlPricesUnitSettings.area,
        class: mlPricesUnitSettings.class,
        newBuilding: !!mlPricesUnitSettings.new_building,
        houseOrApartment: houseOrApartment,
        perSqmOrTotal: "eurPerSqm",
      })
        .then(
          (success) => setUnitMLPrices(success),
          () => {}
        )
        .finally(onRenderingDone)
    }
  }, [markerLocation])

  const selectPricesByUsageType = (
    prices: { [key in AssessmentPriceType]?: PriceRange } | undefined
  ): PriceRange | undefined => {
    switch (usageType) {
      case UsageType.Office:
        return prices?.office
      case UsageType.Retail:
        return prices?.retail
      case UsageType.ResidentialApartmentSale:
      case UsageType.ResidentialHouseSale:
        return prices?.residentialSale
      default:
        return prices?.residentialRent
    }
  }

  const mlPricesCity = selectPricesByUsageType(agsPrices)
  const neighborhood = selectPricesByUsageType(cellPrices)

  let barMinArr = []
  let barMaxArr = []
  if (mlPricesCity) {
    barMinArr.push(mlPricesCity?.min)
    barMaxArr.push(mlPricesCity?.max)
  }
  if (neighborhood) {
    barMinArr.push(neighborhood?.min)
    barMaxArr.push(neighborhood?.max)
  }
  if (unitMLPrices) {
    barMinArr.push(unitMLPrices?.min)
    barMaxArr.push(unitMLPrices?.max)
  }

  const exclusivenessStars = (exclusivnessClass: number) => {
    const star = printMode ? "*" : "★"
    return star.repeat(exclusivnessClass)
  }

  const allPriceValues: number[] = [
    ...valuesOfPriceRange(mlPricesCity),
    ...valuesOfPriceRange(neighborhood),
    ...valuesOfPriceRange(unitMLPrices),
  ]
  const graphOffset = 24
  const scaleForWidth = (newGraphWidth: number): GraphScale =>
    new GraphScale(allPriceValues, graphOffset, newGraphWidth - 2 * graphOffset)
  const usageTypeHeader = (() => {
    switch (usageType) {
      case UsageType.Office:
        return t.dashboard.mlPrices.usageType.rentOffice
      case UsageType.Retail:
        return t.dashboard.mlPrices.usageType.rentRetail
      case UsageType.ResidentialApartmentRent:
      case UsageType.ResidentialHouseRent:
        return t.dashboard.mlPrices.usageType.rentResidential
      case UsageType.ResidentialApartmentSale:
      case UsageType.ResidentialHouseSale:
        return t.dashboard.mlPrices.usageType.purchaseResidential
      default:
        return t.dashboard.mlPrices.usageType.rentResidential
    }
  })()

  const onRemove = (e: React.MouseEvent) => {
    if (editMode) {
      e.stopPropagation()
      onRemoveClick && onRemoveClick()
    }
  }

  return (
    <div
      className={cx(
        "dashboard-widget",
        editMode && "edit-mode",
        editMode && "edit-mode-" + editMode,
        printMode && widgetStyles.widgetPrintMode
      )}
    >
      <Grid columns={1} rowSpec={"min-content 1fr"} height={[100, "%"]}>
        <div
          className={styles.widgetHeader(!!editMode)}
          onClick={(e) => {
            if (!editMode) {
              e.stopPropagation()
              onHeaderClick && onHeaderClick()
            }
          }}
        >
          <b>{t.dashboard.mlPrices.header}</b>
          <div className="single-line-with-ellipsis">({usageTypeHeader})</div>
          {editMode && !printMode && (
            <div className={`notDraggable widget-edit-button remove-button`}>
              <Tooltip tooltip={t.dashboard.remove} placement={"bottom"} hideArrow={true}>
                <span onClick={onRemove}>
                  <Icon name={"remove"} fontSize={16} />
                </span>
              </Tooltip>
            </div>
          )}
          <div style={{ flexGrow: 1 }}></div>
          <div style={{ paddingLeft: "24px" }}> {t.assessmentEntryDetails.euroPerSqm}</div>
        </div>
        <div className={styles.contentStyle}>
          <Grid columns={1} rowSpec={"1fr 1fr 1fr min-content"} padding={[4, 4, 0, 4]}>
            <DashboardBarGraph
              label={t.dashboard.mlPrices.city}
              color={COLORS.primary.default}
              data={mlPricesCity}
              scaleForWidth={scaleForWidth}
            />
            <DashboardBarGraph
              label={t.dashboard.mlPrices.neighborhood}
              color={COLORS.primary.default}
              data={neighborhood}
              scaleForWidth={scaleForWidth}
            />
            <DashboardBarGraph
              label={t.dashboard.mlPrices.unit}
              color={COLORS.primary.default}
              data={unitMLPrices}
              scaleForWidth={scaleForWidth}
            />
            <div className={styles.unitSettings}>
              {mlPricesUnitSettings.new_building ? t.header.newBldg : mlPricesUnitSettings.construction_year} -{" "}
              {mlPricesUnitSettings.area} m² - {exclusivenessStars(mlPricesUnitSettings.class)}
            </div>
          </Grid>
        </div>
      </Grid>
    </div>
  )
}

export default MLPricesWidget
