import * as React from "react"
import { State } from "../reducers/state"
import { connect } from "react-redux"
import { css } from "emotion"
import * as style from "ol/style"
import * as geom from "ol/geom"
import * as layer from "ol/layer"
import * as ol from "ol"
import * as interaction from "ol/interaction"
import * as source from "ol/source"
import { fromLonLat } from "ol/proj"
import { pointerMove } from "ol/events/condition"
import { Assessment, AssessmentEntryWithScore } from "../models/assessment"
import { createRoot } from "react-dom/client"
import { translations } from "../i18n"
import { Tag } from "@blueprintjs/core"
import { bind } from "decko"
import { AppModules } from "../../menu/util/app-location-types"
import { loadAssessmentEntry, navigateToAssessment, openAssessmentModule } from "../reducers/assessment-slice-functions"
import { OpenLayersAbstractProps, OpenLayersMap } from "../../shared/components/openlayers-map"
import Card from "../../shared/components/card"
import Grid from "../../shared/components/restyle-grid/grid"
import GridItem from "../../shared/components/restyle-grid/griditem"
import { getThemeColor } from "../../shared/helper/color"

const mapClass = css({
  minHeight: 0,
  height: "100%",
})

const mapStateToProps = (state: State) => ({
  currentAssessment: state.assessment.currentAssessment,
  assessmentEntries: state.assessment.assessmentEntries,
})

interface OwnProps {
  tagFilter: string | null
  module: AppModules["locationAssessment"]
}

export type Props = OwnProps & OpenLayersAbstractProps & ReturnType<typeof mapStateToProps>

interface OwnState {
  selectedEntry: AssessmentEntryWithScore | null
}

class AssessmentEntryMapImpl extends OpenLayersMap<Props, OwnState> {
  private t = translations()
  private clickSelect: interaction.Select
  private hoverSelect: interaction.Select
  private overlayContent: HTMLElement = document.createElement("div")
  private overlay: ol.Overlay
  private clusterLayer: layer.Vector<any>

  constructor(props: Props) {
    super(props)

    this.state = {
      selectedEntry: null,
    }
  }

  render() {
    return (
      <Grid columns={1} height={[100, "%"]} padding={16}>
        {super.render()}
      </Grid>
    )
  }

  componentDidMount() {
    super.componentDidMount()

    this.clusterLayer = new layer.Vector({
      source: this.createClusterSource(),
      style: this.clusterStyle(0.8),
    })

    this.clickSelect = new interaction.Select({
      layers: [this.clusterLayer],
      style: this.clusterStyle(1.1),
    })
    this.clickSelect.on("select", (event) => this.onSelect(event.selected))

    this.hoverSelect = new interaction.Select({
      condition: pointerMove,
      layers: [this.clusterLayer],
      style: this.clusterStyle(1.1),
    })

    this.overlay = new ol.Overlay({
      element: this.overlayContent,
      stopEvent: true,
      insertFirst: true,
      positioning: "bottom-center",
      offset: [0, -70],
      autoPan: true,
    })

    this.getMap().addLayer(this.clusterLayer)
    this.getMap().addInteraction(this.clickSelect)
    this.getMap().addInteraction(this.hoverSelect)
    this.getMap().addOverlay(this.overlay)
  }

  componentDidUpdate(prevProps: Props, prevState: OwnState): void {
    super.componentDidUpdate(prevProps, prevState)

    if (this.props.tagFilter !== prevProps.tagFilter) {
      this.clusterLayer.setSource(this.createClusterSource())
      this.closeCurrentOverlay()
    }
  }

  protected containerClass(): string {
    return mapClass
  }

  protected onMoveEnd(): void {}

  private onSelect(features: ol.Feature[]) {
    if (features.length !== 1) return

    this.clickSelect.getFeatures().clear()
    this.hoverSelect.getFeatures().clear()

    const innerFeatures: ol.Feature[] = features[0].get("features")

    if (innerFeatures.length === 0) return

    if ((this.getMap().getView().getZoom() ?? 0) < 17 && innerFeatures.length > 1) {
      this.getMap()
        .getView()
        .animate({
          center: (features[0].getGeometry() as geom.Point).getCoordinates(),
          zoom: (this.getMap().getView().getZoom() ?? 0) + 4,
        })
      return
    }

    const selectedEntry: AssessmentEntryWithScore | null = innerFeatures[0].get("entry")
    this.setState({ selectedEntry }, () => {
      this.clusterLayer.setStyle(this.clusterStyle(0.8))
    })

    if (selectedEntry) {
      this.getMap().removeEventListener("singleclick", this.closeCurrentOverlay)
      this.getMap().once("singleclick", this.closeCurrentOverlay)

      createRoot(this.overlayContent).render(this.renderOverlay(selectedEntry))
      this.overlay.setPosition((innerFeatures[0].getGeometry() as geom.Point).getCoordinates())
    }
  }

  private clusterStyle(multiply: number): (feature: ol.Feature) => style.Style {
    return (feature: ol.Feature) => {
      const { selectedEntry } = this.state

      const size = feature.get && feature.get("features").length

      if (size === 1) {
        const features: ol.Feature[] = feature.get("features")
        const entry: AssessmentEntryWithScore | null = features.length > 0 ? features[0].get("entry") : null

        return new style.Style({
          image: new style.Icon({
            src: "/assets/address_pin.svg",
            scale: selectedEntry != null && selectedEntry.id === entry?.id ? 1.1 : multiply,
            size: [36, 60],
            anchorOrigin: "top-left",
            anchor: [0.5, 1],
          }),
        })
      }

      return new style.Style({
        image: new style.Circle({
          radius: multiply * (12 + Math.min((Math.log(size) / Math.log(10)) * 5 - 5, 10)),
          stroke: new style.Stroke({
            color: "#000",
          }),
          fill: new style.Fill({
            color: getThemeColor("primary", "default").string(),
          }),
        }),
        text: new style.Text({
          text: size.toString(),
          offsetY: 1,
          fill: new style.Fill({
            color: "#000",
          }),
        }),
      })
    }
  }

  private renderOverlay(entry: AssessmentEntryWithScore) {
    return (
      <Card>
        <Grid columns={1} gap={4}>
          {entry.tags.map((tag, idx) => (
            <GridItem key={idx} justifySelf="start">
              <Tag>{tag}</Tag>
            </GridItem>
          ))}
          <div>
            {entry.address.route} {entry.address.streetNumber}
          </div>
          <div>
            {entry.address.postalCode} {entry.address.locality}
          </div>
          <div>{entry.address.country}</div>
          <a href="#" onClick={this.onSelectEntry(entry.id)}>
            {this.t.assessmentDetails.showDetails}
          </a>
        </Grid>
      </Card>
    )
  }

  @bind
  private onSelectEntry(entryId: string): (event: React.MouseEvent<HTMLElement>) => void {
    return (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      event.stopPropagation()

      const { currentAssessment } = this.props

      if (!currentAssessment) return

      void this.doLoadAssessmentEntryModule(currentAssessment, entryId)
    }
  }

  @bind
  private doLoadAssessmentEntryModule(currentAssessment: Assessment, entryId: string) {
    switch (this.props.module) {
      case "assessment":
        void loadAssessmentEntry(currentAssessment, entryId)
        return navigateToAssessment(currentAssessment.id, entryId)
      default:
        return openAssessmentModule(currentAssessment.id, entryId, this.props.module)
    }
  }

  @bind
  private closeCurrentOverlay(): boolean {
    this.setState({ selectedEntry: null }, () => {
      this.clusterLayer.setStyle(this.clusterStyle(0.8))
    })
    this.overlay.setPosition(undefined as any)
    return true
  }

  private createClusterSource() {
    const { assessmentEntries, tagFilter } = this.props

    let filtered = assessmentEntries?.entries || []

    if (tagFilter !== null) {
      filtered = filtered.filter((entry) => entry.tags.indexOf(tagFilter) >= 0)
    }

    return new source.Cluster({
      distance: 26,
      source: new source.Vector({
        features: filtered.map(
          (entry) =>
            new ol.Feature({
              geometry: new geom.Point(
                fromLonLat([entry.address.location?.lng || 0, entry.address.location?.lat || 0])
              ),
              entry,
            })
        ),
      }),
    })
  }
}

export const AssessmentEntryMap = connect(mapStateToProps, {})(AssessmentEntryMapImpl)
