import { FieldPath, useForm } from "react-hook-form"
import { PriceTypeDropdown } from "../comparables-entry-price-dropdown"
import { HTMLSelect } from "@blueprintjs/core"
import { Flex } from "../../../../shared/components/ui/flex"
import { HouseOrApartment } from "../../../models/assessment"
import React, { useEffect, useState } from "react"
import { FilterProps, iconProps, styles as comparablesComponentStyles, trackPriceTypeChange } from "../comparablesV2"
import { State } from "../../../reducers/state"
import { useSelector } from "react-redux"
import { OnlineImmoScoutInputForm, onlineImmoScoutInputFormDefaults } from "./online-immoscout-filters-model"
import { fetchComparables, setAssessmentComparablesInput } from "../../../reducers/comparables-slice"
import { css } from "emotion"
import { NumberInput } from "../../../../shared/components/ui/number-input"
import { integerValidator, minValidator, numberValidator, sequentialValidator } from "../../../../utils/utils"
import Grid from "../../../../shared/components/restyle-grid/grid"
import GridItem from "../../../../shared/components/restyle-grid/griditem"
import { getThemeColor } from "../../../../shared/helper/color"

const checkboxClass = css({
  accentColor: getThemeColor("primary", "default").string(),
})
export const OnlineImmoscoutFilters = ({ t, assessmentEntry }: FilterProps) => {
  const minRadius = 1

  const comparablesInput = useSelector(
    (state: State) =>
      state.comparablesApp.comparablesInput["online-immoScout"] || {
        ...onlineImmoScoutInputFormDefaults(state.assessment.currentAssessmentEntry || undefined),
        initialized: false,
      }
  )

  const [submitTimeout, setSubmitTimeout] = useState<NodeJS.Timeout | undefined>(undefined)
  const { getValues, setValue, clearErrors, setError, register, watch, handleSubmit, reset } =
    useForm<OnlineImmoScoutInputForm>({
      defaultValues: {
        ...comparablesInput,
      },
    })
  const setNumericInputValidValue =
    (path: FieldPath<OnlineImmoScoutInputForm>) => (value: number, _: string, valid: boolean) => {
      if (valid) {
        clearErrors(path)
        setValue(path, value)
      } else {
        setError(path, { type: "manual" })
      }
    }

  const isResidential = watch("priceTypeComps") === "residentialRent" || watch("priceTypeComps") === "residentialSale"

  const onSubmit = (data: OnlineImmoScoutInputForm) => {
    if (submitTimeout) {
      clearTimeout(submitTimeout)
      setSubmitTimeout(undefined)
    }

    if (assessmentEntry && assessmentEntry.address.location) {
      setAssessmentComparablesInput({
        ...data,
        initialized: true,
      })
      void fetchComparables(data, assessmentEntry.address.location)
    }
  }

  const submitHandler: () => Promise<void> = handleSubmit(onSubmit)

  useEffect(() => {
    onSubmit(getValues())
  }, [])

  useEffect(() => {
    onSubmit(getValues())
  }, [assessmentEntry, assessmentEntry?.address])

  useEffect(() => {
    if (comparablesInput && !comparablesInput.initialized) {
      reset(comparablesInput)
      onSubmit(comparablesInput)
    }
  }, [comparablesInput])

  function scheduleSubmit() {
    if (submitTimeout) {
      clearTimeout(submitTimeout)
    }

    setSubmitTimeout(
      setTimeout(() => {
        return submitHandler()
      }, 1000)
    )
  }

  return (
    <form onChange={() => scheduleSubmit()} onSubmit={submitHandler} className={comparablesComponentStyles.formStyle}>
      <div className={comparablesComponentStyles.filtersFormLine}>
        <div className={comparablesComponentStyles.inputLabel}>{t.assessmentComparables.usageType}</div>
        <PriceTypeDropdown
          dataSource="online-immoScout"
          priceType={getValues("priceTypeComps")}
          onPriceTypeChange={(v) => {
            if (typeof v === "string") {
              trackPriceTypeChange(assessmentEntry, v)
              setValue("priceTypeComps", v)
            }
          }}
        />
      </div>

      <div className={comparablesComponentStyles.filtersFormLine}>
        <div className={comparablesComponentStyles.inputLabel}>{t.assessmentEntryDetails.radius}</div>
        <NumberInput
          defaultValue={watch("radius")}
          onValueChange={setNumericInputValidValue("radius")}
          validator={sequentialValidator([integerValidator(), minValidator(minRadius), numberValidator()])}
        />
      </div>

      <div className={comparablesComponentStyles.filtersFormLine} style={{ borderBottom: "none" }}>
        <Grid columns={3} columnSpec="1fr min-content 1fr">
          <GridItem colSpan={3}>
            <div className={comparablesComponentStyles.inputLabel}>
              <Flex flexDirection={"row"} gap={4} alignItems={"center"}>
                <input id="isLivingAreaNeededComparablesApp" type={"checkbox"} {...register("areaRangeNeeded")} />

                <label htmlFor="isLivingAreaNeededComparablesApp">{t.assessmentEntryDetails.livingArea}</label>
              </Flex>
            </div>
          </GridItem>

          <NumberInput
            disabled={!watch("areaRangeNeeded")}
            defaultValue={watch("areaRange.from")}
            onValueChange={setNumericInputValidValue("areaRange.from")}
            suffix={"m²"}
            validator={sequentialValidator([minValidator(0), numberValidator()])}
          />

          <p className={comparablesComponentStyles.inputRangeDivider}>{String.fromCharCode(0x005f)}</p>
          <NumberInput
            disabled={!watch("areaRangeNeeded")}
            defaultValue={watch("areaRange.to")}
            onValueChange={setNumericInputValidValue("areaRange.to")}
            suffix={"m²"}
            validator={sequentialValidator([minValidator(0), numberValidator()])}
          />

          <GridItem colSpan={3}>
            <div className={comparablesComponentStyles.inputLabel}>{t.assessmentEntryDetails.houseOrApartment}</div>
          </GridItem>
          <GridItem colSpan={3}>
            <HTMLSelect
              fill
              disabled={!isResidential}
              className={comparablesComponentStyles.dropdown}
              style={isResidential ? { background: "white", borderRadius: 4 } : {}}
              iconProps={iconProps}
              options={[
                {
                  value: "apartment",
                  label: t.assessmentEntryDetails.priceCategories.apartment,
                  disabled: !isResidential,
                },
                {
                  value: "house",
                  label: t.assessmentEntryDetails.priceCategories.house,
                  disabled: !isResidential,
                },
              ]}
              value={watch("houseOrApartment")}
              {...register("houseOrApartment")}
              onChange={(e) => setValue("houseOrApartment", e.currentTarget.value as HouseOrApartment)}
            />
          </GridItem>

          {isResidential && (
            <>
              <GridItem colSpan={3}>
                <div className={comparablesComponentStyles.inputLabel}>
                  <Flex flexDirection={"row"} gap={4} alignItems={"center"}>
                    <input
                      id="isRoomsFilterNeededComparablesApp"
                      className={checkboxClass}
                      {...register("roomsFilterEnabled")}
                      type={"checkbox"}
                    />
                    <label htmlFor="isRoomsFilterNeededComparablesApp">{t.assessmentEntryDetails.rooms}</label>
                  </Flex>
                </div>
              </GridItem>
              <NumberInput
                defaultValue={watch("roomsNumberRange.from")}
                onValueChange={setNumericInputValidValue("roomsNumberRange.from")}
                disabled={!watch("roomsFilterEnabled")}
                validator={[minValidator(0), numberValidator()]}
              />
              <p className={comparablesComponentStyles.inputRangeDivider}>{String.fromCharCode(0x005f)}</p>
              <NumberInput
                defaultValue={watch("roomsNumberRange.to")}
                onValueChange={setNumericInputValidValue("roomsNumberRange.to")}
                disabled={!watch("roomsFilterEnabled")}
                validator={[minValidator(0), numberValidator()]}
              />
            </>
          )}
        </Grid>
      </div>
    </form>
  )
}
