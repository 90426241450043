import * as React from "react"
import { Manager, Popper, Reference } from "react-popper"
import * as PopperJS from "popper.js"
import { css, cx } from "emotion"
import ContentPortal from "../layout/contentportal"

export interface PopupProps {
  placement: PopperJS.Placement
  tooltip: string | JSX.Element
  onOpen?: () => void
  onClickOutside?: () => void
  background: string
  color: string
  show: boolean
  children: any
}

const referenceClass = css({
  cursor: "pointer",
})

function tooltipClass(color: string, background: string) {
  return css({
    background: background,
    color: color,
    fontSize: "12px",
    lineHeight: "16px",
    margin: "6px",
    padding: "8px",
    borderRadius: "3px",
    zIndex: 11000,
    boxShadow: "0px 6px 32px 0px rgba(0, 0, 0, 0.21)",
  })
}

const arrowClass = css({
  position: "absolute",
  height: "5px",
  width: "5px",
  borderWidth: "5px",
  borderStyle: "solid",
  boxShadow: "0px 6px 32px 0px rgba(0, 0, 0, 0.21)",
})

function arrowRightClass(background: string) {
  return css({
    left: 0,
    marginLeft: "-10px",
    borderColor: `transparent ${background} transparent transparent`,
  })
}

function arrowTopClass(background: string) {
  return css({
    bottom: 0,
    marginBottom: "-10px",
    borderColor: `${background} transparent transparent transparent`,
  })
}

function arrowLeftClass(background: string) {
  return css({
    right: 0,
    marginRight: "-10px",
    borderColor: `transparent transparent transparent ${background}`,
  })
}

function arrowBottomClass(background: string) {
  return css({
    top: 0,
    marginTop: "-10px",
    borderColor: `transparent transparent ${background} transparent`,
  })
}

function arrowClassForPlacement(background: string, placement: PopperJS.Placement): string {
  switch (placement) {
    case "right":
    case "right-start":
    case "right-end":
      return cx(arrowClass, arrowRightClass(background))
    case "top":
    case "top-start":
    case "top-end":
      return cx(arrowClass, arrowTopClass(background))
    case "left":
    case "left-start":
    case "left-end":
      return cx(arrowClass, arrowLeftClass(background))
    case "bottom":
    case "bottom-start":
    case "bottom-end":
      return cx(arrowClass, arrowBottomClass(background))
    default:
      return arrowClass
  }
}

function offsetForPlacement(placement: PopperJS.Placement): string {
  switch (placement) {
    case "bottom-start":
    case "top-start":
    case "right-start":
    case "left-start":
    case "auto-start":
      return "-9px"
    case "bottom-end":
    case "top-end":
    case "right-end":
    case "left-end":
    case "auto-end":
      return "9px"
    default:
      return "0px"
  }
}
export type ForwardRef<T, P> = React.ForwardRefExoticComponent<React.PropsWithoutRef<P> & React.RefAttributes<T>>
type Props = ForwardRef<HTMLDivElement, PopupProps & React.HTMLAttributes<HTMLDivElement>>

export const Popup: Props = React.forwardRef(({ show, children, placement, color, background, tooltip }, ref) => {
  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <span ref={ref} className={referenceClass}>
            {children}
          </span>
        )}
      </Reference>
      {show && (
        <ContentPortal>
          <div ref={ref}>
            <Popper
              placement={placement}
              modifiers={{
                flip: {
                  boundariesElement: "window",
                },
                preventOverflow: {
                  boundariesElement: "window",
                },
                offset: {
                  offset: offsetForPlacement(placement),
                },
              }}
            >
              {({ placement, ref, style, arrowProps }) => (
                <div className={cx(tooltipClass(color, background))} ref={ref} style={style}>
                  {tooltip}
                  <div
                    className={arrowClassForPlacement(background, placement)}
                    ref={arrowProps.ref}
                    style={arrowProps.style}
                  />
                </div>
              )}
            </Popper>
          </div>
        </ContentPortal>
      )}
    </Manager>
  )
})
