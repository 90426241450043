import * as React from "react"
import { translations } from "../i18n"
import { Translations } from "../i18n/translations"
import { Countries } from "../models/countries"
import { StepThreeData } from "../models/step-three-data"
import { StepThreeError } from "../models/errors"
import { RegistrationContainer } from "./registration-container"
import { useAppSelector } from "../../relas/store"
import { registrationDoStepThree } from "../reducers/registration-slice"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import RevoCard from "../../shared/components/revo-card"
import LoadingDimmer from "../../shared/components/loadingdimmer"
import Select from "../../shared/components/select"
import Panel from "../../shared/components/panel"
import TextField from "../../shared/components/textfield"
import NumberField from "../../shared/components/numberfield"
import Grid from "../../shared/components/restyle-grid/grid"
import GridItem from "../../shared/components/restyle-grid/griditem"
import Button from "../../shared/components/button"
import Form from "../../shared/components/form"
import { language } from "../../shared/language"

export const RegistrationStepThree = () => {
  const translate: Translations = translations()

  const userId = useAppSelector((state) => state.registration.userId)
  const stepThreeError = useAppSelector((state) => state.registration.stepThreeError)
  const stepThreeInProgress = useAppSelector((state) => state.registration.stepThreeInProgress)

  const [stepThreeData, setStepThreeData] = React.useState<StepThreeData>({
    password1: "",
    password2: "",
    businessCompanyName: "",
    address: {
      street: "",
      houseNumber: "",
      country: Countries.GERMANY,
      zip: undefined,
      city: "",
    },
    language: language() || "de",
  })
  const [passwordError, setPasswordError] = React.useState<string>()
  const [validationErrors, setValidationErrors] = React.useState<string[]>([
    "passwords",
    "businessCompanyName",
    "street",
    "houseNumber",
    "zip",
    "city",
  ])

  const countryOptions = [
    { label: translate.stepThree.countries.germany, value: Countries.GERMANY },
    { label: translate.stepThree.countries.austria, value: Countries.AUSTRIA },
    { label: translate.stepThree.countries.switzerland, value: Countries.SWITZERLAND },
  ]

  const onChange = (key: string, value: string, isValid: boolean) => {
    let newValidationErrors = validationErrors.slice()
    if (!isValid) {
      newValidationErrors.push(key)
    } else {
      newValidationErrors = newValidationErrors.filter((error) => error !== key)
    }

    const newData: StepThreeData = {
      ...stepThreeData,
      [key]: value,
    }
    setStepThreeData(newData)
    setValidationErrors(newValidationErrors)

    return newData
  }

  const passwordErrorValidation = (data: StepThreeData) => {
    if (data.password1.length < 9) return translate.stepThree.passwordTooShort
    if (data.password1.length >= 60) return translate.stepThree.passwordTooLong
    if (data.password1 !== data.password2) return translate.stepThree.passwordsAreNotEqual
    return
  }

  const onPasswordChange = (key: string, value: string) => {
    const newData = onChange(key, value, true)
    const passwordErrorStr = passwordErrorValidation(newData)

    if (passwordErrorStr) {
      setValidationErrors(validationErrors.concat(["passwords"]))
    } else {
      setValidationErrors(validationErrors.filter((error) => error !== "passwords"))
    }

    setPasswordError(passwordErrorStr)
  }

  const showPasswordError = () => {
    return passwordError && stepThreeData.password1.length + stepThreeData.password2.length > 0
  }

  const onAddressChange = (key: string, value: string | number | Countries, isValid: boolean) => {
    let newValidationErrors = validationErrors.slice()
    if (!isValid) {
      newValidationErrors.push(key)
    } else {
      newValidationErrors = newValidationErrors.filter((error) => error !== key)
    }

    setStepThreeData({
      ...stepThreeData,
      address: {
        ...stepThreeData.address,
        [key]: value,
      },
    })
    setValidationErrors(newValidationErrors)
  }

  const register = () => {
    if (stepThreeData && userId && validationErrors.length === 0) {
      void registrationDoStepThree(userId, stepThreeData)
    }
  }

  const renderError = () => {
    if (stepThreeError === undefined) return null

    switch (stepThreeError) {
      case StepThreeError.PasswordTooShort:
        return <Panel color="negative">{translate.stepThree.passwordTooShort}</Panel>
      case StepThreeError.PasswordTooLong:
        return <Panel color="negative">{translate.stepThree.passwordTooLong}</Panel>
      case StepThreeError.PasswordMustMatch:
        return <Panel color="negative">{translate.stepThree.passwordsAreNotEqual}</Panel>

      default:
        return <GenericErrorPanel error={stepThreeError} />
    }
  }

  return (
    <RegistrationContainer title={translate.stepThree.header}>
      <RevoCard>
        <LoadingDimmer loading={stepThreeInProgress}>
          <Form
            onSubmit={(event) => {
              event.preventDefault()
              register()
            }}
          >
            <Grid columns={12} gap={12}>
              <GridItem colSpan={12}>{renderError()}</GridItem>

              <GridItem colSpan={6}>
                <TextField
                  id="21re-password"
                  label={translate.stepThree.password1Label}
                  type="password"
                  onValueChange={(value: string) => onPasswordChange("password1", value)}
                  required={true}
                  value={stepThreeData.password1}
                />
              </GridItem>
              <GridItem colSpan={6}>
                <TextField
                  id="21re-password-confirm"
                  label={translate.stepThree.password2Label}
                  type="password"
                  onValueChange={(value: string) => onPasswordChange("password2", value)}
                  required={true}
                  value={stepThreeData.password2}
                />
              </GridItem>
              {showPasswordError() ? (
                <GridItem colSpan={12}>
                  <Panel color="negative">{passwordError}</Panel>
                </GridItem>
              ) : null}

              <GridItem colSpan={12}>
                <TextField
                  id="21re-company-name"
                  maxLength={256}
                  label={translate.stepThree.businessCompanyNameLabel}
                  value={stepThreeData.businessCompanyName}
                  onValueChange={(value: string, isValid: boolean) => onChange("businessCompanyName", value, isValid)}
                  required={true}
                />
              </GridItem>

              <GridItem colSpan={9}>
                <TextField
                  id="21re-street"
                  label={translate.stepThree.streetLabel}
                  maxLength={256}
                  onValueChange={(value: string, isValid: boolean) => onAddressChange("street", value, isValid)}
                  value={stepThreeData.address.street}
                  required={true}
                />
              </GridItem>
              <GridItem colSpan={3}>
                <TextField
                  id="21re-housenumber"
                  label={translate.stepThree.houseNumberLabel}
                  maxLength={10}
                  onValueChange={(value: string, isValid: boolean) => onAddressChange("houseNumber", value, isValid)}
                  value={stepThreeData.address.houseNumber}
                  required={true}
                />
              </GridItem>

              <GridItem colSpan={12}>
                <TextField
                  id="21re-additional"
                  label={translate.stepThree.additionalAddressInformationLabel}
                  maxLength={256}
                  onValueChange={(value: string, isValid: boolean) =>
                    onAddressChange("additionalAddressInformation", value, isValid)
                  }
                  value={stepThreeData.address.additionalAddressInformation || ""}
                />
              </GridItem>

              <GridItem colSpan={4}>
                <Select
                  id="21re-country"
                  label={translate.stepThree.countryLabel}
                  onValueChange={(value: string, isValid: boolean) => onAddressChange("country", value, isValid)}
                  value={stepThreeData.address.country}
                  options={countryOptions}
                />
              </GridItem>
              <GridItem colSpan={4}>
                <NumberField
                  id="21re-zipcode"
                  label={translate.stepThree.zipCodeLabel}
                  onValueChange={(value: string, isValid: boolean) => onAddressChange("zip", value, isValid)}
                  min={0}
                  max={99999}
                  value={stepThreeData.address.zip || ""}
                  required={true}
                />
              </GridItem>
              <GridItem colSpan={4}>
                <TextField
                  id="21re-city"
                  label={translate.stepThree.cityLabel}
                  onValueChange={(value: string, isValid: boolean) => onAddressChange("city", value, isValid)}
                  value={stepThreeData.address.city}
                  maxLength={256}
                  required={true}
                />
              </GridItem>

              <GridItem colSpan={12}>
                <div>
                  <Button id="21re-register-button" type="primary" icon="signup" disabled={validationErrors.length > 0}>
                    {translate.stepThree.registerButtonText}
                  </Button>
                </div>
              </GridItem>
            </Grid>
          </Form>
        </LoadingDimmer>
      </RevoCard>
    </RegistrationContainer>
  )
}
