import * as React from "react"
import { ScrollBox } from "../../shared/components/ui/scroll-box"
import { useAppDispatch, useAppSelector } from "../../relas/store"
import { Rating } from "../../shared/models/ratings"
import { Ratings } from "./ratings"
import { css } from "emotion"
import { translations } from "../i18n"
import { ratingManagerActions } from "../rating-manager-slice"
import { AssessmentEntryFull } from "../../assessment/models/assessment"
import Button from "../../shared/components/button"
import { getThemeColorVar } from "../../shared/helper/color"

const styles = {
  container: css({
    display: "flex",
    flexDirection: "column",
    height: "100%",
  }),
  bottomBlock: css({
    borderTop: `1px solid ${getThemeColorVar("border", "default")}`,
    padding: "16px",
    textAlign: "right",
  }),
}
interface Props {
  assessmentEntry?: AssessmentEntryFull | null
}

export const RatingList = ({ assessmentEntry }: Props) => {
  const t = React.useMemo(() => translations(), [])

  const dispatch = useAppDispatch()

  const { microRatings, macroRatings, userId, metaRatings } = useAppSelector((state) => {
    const { microRatings, macroRatings } = state.ratingManager.ratingList?.reduce(
      (acc, cur) => {
        cur.dataSetType === "micro" ? acc.microRatings.push(cur) : acc.macroRatings.push(cur)
        return acc
      },
      { microRatings: [] as Rating[], macroRatings: [] as Rating[] }
    ) ?? { microRatings: [], macroRatings: [] }
    return {
      microRatings,
      macroRatings,
      userId: state.user.user?.id,
      metaRatings: state.ratingManager.metaRatingList,
    }
  })

  const metaRatingDependencies = React.useMemo(() => {
    return metaRatings.reduce((acc, cur) => {
      Object.keys(cur.weights).forEach((key) => {
        if (acc[key]) {
          acc[key].push(cur.name)
        } else {
          acc[key] = [cur.name]
        }
      })
      return acc
    }, {} as { [key: string]: string[] })
  }, [metaRatings])

  function openCreateRatingMenu() {
    dispatch(ratingManagerActions.setCurrentRating(undefined))
    dispatch(ratingManagerActions.setMenuPane("create"))
  }

  return (
    <div className={styles.container}>
      <ScrollBox>
        <Ratings
          assessmentEntry={assessmentEntry}
          ratings={macroRatings}
          title={"Macro"}
          userId={userId}
          conflictList={metaRatingDependencies}
        />
        <Ratings
          assessmentEntry={assessmentEntry}
          ratings={microRatings}
          title={"Micro"}
          userId={userId}
          conflictList={metaRatingDependencies}
        />
        <Ratings assessmentEntry={assessmentEntry} ratings={metaRatings} title={"Meta"} userId={userId} />
      </ScrollBox>
      {!assessmentEntry && (
        <div className={styles.bottomBlock}>
          <Button onClick={openCreateRatingMenu} type="primary">
            {t.createNew}
          </Button>
        </div>
      )}
    </div>
  )
}
