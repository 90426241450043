import React, { useEffect, useMemo } from "react"
import { css, cx } from "emotion"
import { FieldPath, PathValue, useForm } from "react-hook-form"
import {
  AssessmentEntry,
  Exclusiveness,
  exclusivenessToNumber,
  HouseOrApartment,
  houseOrApartmentForUsageType,
  ObjectPricesParams,
} from "../../assessment/models/assessment"
import { useSelector } from "react-redux"
import { State } from "../../assessment/reducers/state"
import { Flex } from "../../shared/components/ui/flex"
import { Grid } from "../../shared/components/ui/grid"
import { GridItem } from "../../shared/components/ui/grid-item"
import { translations } from "../../assessment/i18n"
import { yupResolver } from "@hookform/resolvers/yup/dist/yup"
import {
  assessmentEntrySimpleCellRenderer,
  AssessmentUnitPriceYupSchemaSettings,
  OptionItem,
} from "../../assessment/components/assessment-entry-detail-edit"
import { Select } from "@blueprintjs/select"
import { Button as ButtonBlueprint } from "@blueprintjs/core/lib/esm/components/button/buttons"
import * as yup from "yup"
import {
  setAssessmentObjectPricesParams,
  setAssessmentRentIndexParams,
} from "../../assessment/reducers/assessment-slice-functions"
import { usePrevious } from "../../utils/use-previous"
import * as _ from "lodash"
import { maxYear, minConstructionYear } from "../../assessment/components/assessment-entry-object-price-inputs"
import { NumberInput } from "../../shared/components/ui/number-input"
import {
  maxValidator,
  minValidator,
  moreThanValidator,
  numberValidator,
  requiredValidator,
  sequentialValidator,
  StringKeys,
} from "../../utils/utils"
import { yearValidator } from "../../utils/form-validators"
import Button from "../../shared/components/button"
import { getThemeColorVar } from "../../shared/helper/color"
import Icon from "../../shared/components/icon"
import ToggleSwitch from "../../shared/components/ui/ToggleSwitch"
import { Checkbox } from "@blueprintjs/core"

const styles = {
  sectionTitleWithButton: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "21px",
    fontWeight: "bold",
    fontSize: "17px",
  }),
  eurRadioButtonsGroup: css({
    label: { span: { marginLeft: "-24px !important" } },
  }),
  eurRadioButton: css({
    backgroundColor: getThemeColorVar("primary", "light"),
    borderRadius: "15px",
    height: "29px",
    alignContent: "center",
  }),
  roundedCheckbox: css`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    column-gap: 16px;
    label.bp4-control.bp4-checkbox.bp4-large {
      > span.bp4-control-indicator {
        border-radius: 2px;
      }
    }
  `,
  inputFlex: css({
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
    "div div div input": {
      boxShadow: "none",
      borderRadius: "4px",
      border: `1px solid ${getThemeColorVar("border", "default")}`,
    },
  }),
  dropdownUnitSettings: css`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    div span span div button {
      border-radius: 4px;
    }
  `,
  wideSelectDropdown: css({
    borderRadius: "4px",
    span: { width: "100%" },
    "span button": {
      width: "100%",
      justifyContent: "space-around",
    },
  }),
  qualityTypeDropdownText: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  }),
}

export const ObjectPricesParamsYupSchema = yup.object({
  constructionYear: AssessmentUnitPriceYupSchemaSettings.buildingYear,
  newBuilding: AssessmentUnitPriceYupSchemaSettings.newBuilding,
  area: AssessmentUnitPriceYupSchemaSettings.area,
})

export type UnitSettingsForm = ObjectPricesParams

export function formDefaultValues(
  assessmentEntry?: AssessmentEntry,
  priceParams?: UnitSettingsForm
): UnitSettingsForm | undefined {
  return assessmentEntry
    ? {
        constructionYear: priceParams?.constructionYear || assessmentEntry.year || 2000,
        newBuilding: priceParams?.newBuilding || assessmentEntry.newBuilding || false,
        area: priceParams?.area || assessmentEntry.area || 100,
        class: priceParams?.class || exclusivenessToNumber(assessmentEntry?.exclusiveness),
        houseOrApartment:
          priceParams?.houseOrApartment || houseOrApartmentForUsageType(assessmentEntry.usageType) || "apartment",
        perSqmOrTotal: priceParams?.perSqmOrTotal || "eurPerSqm",
      }
    : undefined
}

export const UnitSettingsV2 = () => {
  const translate = useMemo(translations, [translations])
  const assessmentEntry = useSelector((state: State) => state.assessment.currentAssessmentEntry)
  const [resetCount, setResetCount] = React.useState(0)

  const previousAssessmentEntry = usePrevious(assessmentEntry)

  const priceParams: UnitSettingsForm | undefined = useSelector(
    (state: State) => state.assessment?.objectPricesParams || undefined
  )

  const priceType = useSelector((state: State) => state.assessment.selectedPriceType)

  // Defined in the TB-2038
  const visibleFields: { [key in keyof UnitSettingsForm]: boolean } = {
    constructionYear: priceType !== "plotSale",
    newBuilding: priceType !== "plotSale",
    area: true,
    class: priceType !== "logistics" && priceType !== "plotSale",
    houseOrApartment: priceType === "residentialSale",
    perSqmOrTotal: true,
  }

  const onSubmit = (data: UnitSettingsForm) => {
    setAssessmentObjectPricesParams(data)
  }

  const resetUnitSettingsForm = (data: UnitSettingsForm | undefined) => {
    setAssessmentObjectPricesParams(data ?? null)
    reset(data)
  }

  const resolver = yupResolver(ObjectPricesParamsYupSchema)

  const { setError, setValue, clearErrors, register, watch, handleSubmit, reset, getValues } =
    useForm<UnitSettingsForm>({
      defaultValues: formDefaultValues(assessmentEntry ?? undefined, priceParams),
      mode: "onChange",
      resolver: resolver,
    })

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)())
    return () => subscription.unsubscribe()
  }, [handleSubmit, watch])

  useEffect(() => {
    setAssessmentRentIndexParams({ area: watch("area"), yearOfConstruction: watch("constructionYear") })
  }, [watch("area"), watch("constructionYear")])

  const isResidential = useMemo(() => {
    return priceType === "residentialRent" || priceType === "residentialSale"
  }, [priceType])

  const onResetClick = (e: React.MouseEvent) => {
    reset(formDefaultValues(assessmentEntry ?? undefined))
    setResetCount(resetCount + 1)
    e.stopPropagation()
  }

  useEffect(() => {
    if (assessmentEntry && assessmentEntry.id !== previousAssessmentEntry?.id) {
      resetUnitSettingsForm(formDefaultValues(assessmentEntry ?? undefined, priceParams))
    } else {
      // check if any of the values from the unit settings form were changed
      const prevFormValues = formDefaultValues(previousAssessmentEntry ?? undefined)
      const currentFormValues = formDefaultValues(assessmentEntry ?? undefined)
      if (!_.isEqual(prevFormValues, currentFormValues)) {
        resetUnitSettingsForm(currentFormValues)
      }
    }
  }, [assessmentEntry])

  const onNumberInputChange =
    (inputName: keyof StringKeys<ObjectPricesParams>) => (newValue: number, strValue: string, valid: boolean) => {
      if (valid) {
        clearErrors(inputName)
        setValue(inputName, newValue)
      } else setError(inputName, { message: "invalid" })
    }

  const { exclusivenessOptions, houseOrAptOptions } = useMemo(() => {
    const exclusiveness = new Map(
      Object.values<Exclusiveness>(Exclusiveness).map((exclusiveness) => {
        const translation = translate.assessmentEntryDetails.exclusivenessDropdown[exclusiveness]
        return [exclusivenessToNumber(exclusiveness), translation]
      })
    )

    const houseOrApt = new Map(
      ["apartment", "house"].map((key: HouseOrApartment) => {
        return [key, { label: translate.assessmentEntryDetails.priceCategories[key] }]
      })
    )

    return {
      exclusivenessOptions: exclusiveness,
      houseOrAptOptions: houseOrApt,
    }
  }, [translate])

  const makeSelectDropdown = <
    K extends FieldPath<UnitSettingsForm>,
    T extends PathValue<UnitSettingsForm, K> & (number | string)
  >(
    propName: K,
    option: Map<T, OptionItem>,
    disabled: boolean = false
  ) => (
    <Select<T>
      items={[...option.keys()]}
      popoverProps={{ usePortal: false }}
      activeItem={watch(propName) as T}
      className={cx(styles.wideSelectDropdown, "customBlueprintSelect")}
      disabled={disabled}
      itemRenderer={(item: T, itemProps) => assessmentEntrySimpleCellRenderer(item, option.get(item), itemProps)}
      filterable={false}
      onItemSelect={(v: T) => setValue(propName, v)}
    >
      <ButtonBlueprint
        text={
          <div className={styles.qualityTypeDropdownText}>
            <div>{option.get(watch(propName) as T)?.label}</div>
            <Icon name="chevron_down" fontSize={14} color="primary" colorType="default" />
          </div>
        }
        disabled={disabled}
        minimal
        outlined
      />
    </Select>
  )
  const setPerSqmOrTotalValue = (value: string) => {
    const curr = getValues("perSqmOrTotal")
    setValue("perSqmOrTotal", curr === "eurPerSqm" ? "eurTotal" : "eurPerSqm")
  }

  const eurToggleLabels = [
    ["eurTotal", "€"],
    ["eurPerSqm", "€ / m²"],
  ] as const

  return (
    <div style={{ fontSize: "14px" }}>
      <Flex flexDirection={"column"} gap={24}>
        <div style={{ borderBottom: `1px solid ${getThemeColorVar("border", "default")}`, paddingTop: "20px" }} />

        <div className={styles.sectionTitleWithButton}>
          {translate.assessmentEntryDetails.unitSettings}
          <Button
            type={"primary"}
            onClick={onResetClick}
            icon={"refresh"}
            title={translate.assessmentEntryDetails.reset}
            size={"tiny"}
          />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          key={"form-reset-count-" + resetCount}
          id={"form-reset-count-" + resetCount}
        >
          <Grid columns={1} columnSpec={"1fr"} rowGap={32} alignItems={"baseline"}>
            {visibleFields.perSqmOrTotal && (
              <Flex flexDirection={"column"} gap={16}>
                <GridItem>
                  <label>Display €/m² vs. €</label>
                </GridItem>
                <ToggleSwitch
                  values={eurToggleLabels}
                  selected={getValues("perSqmOrTotal")}
                  onChange={setPerSqmOrTotalValue}
                />
              </Flex>
            )}
            {visibleFields.constructionYear && (
              <Flex flexDirection={"column"} gap={12}>
                <div
                  style={{ display: "flex", flexDirection: "row", alignItems: "baseline", columnGap: "16px" }}
                  className={styles.roundedCheckbox}
                >
                  <Checkbox
                    id="PricingModuleUnitSettingNewBuilding"
                    {...register("newBuilding", { deps: "buildingYear" })}
                    large
                  />
                  <label htmlFor={"PricingModuleUnitSettingNewBuilding"}>
                    {translate.assessmentEntryDetails.newBuilding}
                  </label>
                </div>
                <div className={styles.inputFlex}>
                  <label>{translate.assessmentEntryDetails.constructionOrRenovationYear}</label>
                  <NumberInput
                    defaultValue={watch("constructionYear")}
                    disabled={watch("newBuilding") ?? false}
                    validator={sequentialValidator([
                      requiredValidator(translate.validationTranslations.required),
                      minValidator(minConstructionYear, translate.validationTranslations.min(minConstructionYear)),
                      maxValidator(maxYear, translate.validationTranslations.max(maxYear)),
                      numberValidator(translate.validationTranslations.notANumber),
                      yearValidator(translate.validationTranslations.constructionYearValidation),
                    ])}
                    onValueChange={onNumberInputChange("constructionYear")}
                  />
                </div>
              </Flex>
            )}

            {visibleFields.area && (
              <div className={styles.inputFlex}>
                <GridItem>
                  <label>{translate.assessmentEntryDetails.livingArea}</label>
                </GridItem>
                <NumberInput
                  defaultValue={watch("area")}
                  validator={sequentialValidator([
                    requiredValidator(translate.validationTranslations.required),
                    moreThanValidator(0, translate.validationTranslations.moreThan(0)),
                    maxValidator(99999, translate.validationTranslations.max(99999)),
                    numberValidator(translate.validationTranslations.notANumber),
                  ])}
                  onValueChange={onNumberInputChange("area")}
                  suffix={"m²"}
                />
              </div>
            )}

            {visibleFields.class && (
              <div className={styles.dropdownUnitSettings}>
                <GridItem>
                  <label>{translate.assessmentEntryDetails.quality}</label>
                </GridItem>
                <GridItem>{makeSelectDropdown("class", exclusivenessOptions)}</GridItem>
              </div>
            )}

            {visibleFields.houseOrApartment && (
              <div className={styles.dropdownUnitSettings}>
                <GridItem>
                  <label>{translate.assessmentEntryDetails.columns.usageType}</label>
                </GridItem>
                <GridItem>{makeSelectDropdown("houseOrApartment", houseOrAptOptions, !isResidential)}</GridItem>
              </div>
            )}
          </Grid>
        </form>
      </Flex>
    </div>
  )
}
