import { useRef, useEffect } from "react"

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<() => void | null>()
  // Remember the latest callback.

  useEffect(() => {
    savedCallback.current = callback
  })

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (typeof savedCallback?.current !== "undefined") {
        savedCallback?.current()
      }
    }

    if (delay !== null) {
      const id = setInterval(tick, delay)
      // destructor - will be called if delay will be set to null (disabled)
      return () => clearInterval(id)
    } else {
      return
    }
  }, [delay])
}

export default useInterval
