import { FunctionComponent, MouseEventHandler, default as React, PropsWithChildren } from "react"
import { IconName } from "@21re/revoicons/dist/icon-type"
import { css, cx } from "emotion"
import FlexContainer from "./restyle-grid/flexcontainer"
import Grid from "./restyle-grid/grid"
import GridItem from "./restyle-grid/griditem"
import { getThemeColorVar } from "../helper/color"
import Icon from "./icon"

export interface DialogHeaderProps {
  icon?: IconName
  title: string
  modifier?: "danger"
  closeButton: boolean
  onClose: MouseEventHandler<HTMLDivElement>
}

const dialogHeaderClass = css({
  borderRadius: "5px",
  fontSize: "18px",
  fontWeight: 600,
  padding: "16px",
  backgroundColor: getThemeColorVar("background", "lighter"),
  whiteSpace: "nowrap",
})

const modifierClasses: { [key in "danger"]: string } = {
  danger: css({
    backgroundColor: getThemeColorVar("negative", "default"),
  }),
}

export const DialogHeader: FunctionComponent<PropsWithChildren<DialogHeaderProps>> = (props) => {
  const classes = [dialogHeaderClass]

  props.modifier && classes.push(modifierClasses[props.modifier])

  return (
    <div className={cx(classes)}>
      <FlexContainer md-justify="spaceBetween">
        <Grid columnSpec="fit-content(100%) 1fr" gap="md">
          {props.icon && <Icon size="regular" name={props.icon} />}
          <GridItem alignSelf="center">{props.title}</GridItem>
        </Grid>
        {props.closeButton && (
          <div style={{ cursor: "pointer" }} onClick={props.onClose}>
            <Icon name="close" />
          </div>
        )}
        {props.children}
      </FlexContainer>
    </div>
  )
}

export default DialogHeader
