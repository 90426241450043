import * as React from "react"
import { Flex } from "./flex"
import { Grid } from "./grid"
import { GridItem } from "./grid-item"
import { css } from "emotion"
import { Tooltip } from "./tooltip"
import { translations } from "../../i18n"
import { getThemeColorVar } from "../../helper/color"
import Icon from "../icon"
import LoadingSpinner from "../loadingspinner"
import Text from "../text"

export interface ProfilePopupFact {
  label: string
  value: string
  rawValue?: string
  weight?: string
  inverted?: boolean
}

interface Props {
  loading: boolean
  header: string
  mainFact: ProfilePopupFact
  secondaryFacts: ProfilePopupFact[]
  closeCallback?: () => void
  showRawValues: boolean
}

export const MapPopupProfilePopup: React.FunctionComponent<Props> = (props) => {
  const t = React.useMemo(translations, [translations])

  function renderMainFact(fact: ProfilePopupFact) {
    return (
      <React.Fragment key={fact.label}>
        <GridItem padding={[0, 0, 0, 0]}>{fact.label}</GridItem>
        <GridItem justifySelf={"end"} padding={[0, 0, 0, 0]}>
          <span style={{ wordBreak: "break-word" }}>{fact.value}</span>
        </GridItem>
      </React.Fragment>
    )
  }

  function renderFact(fact: ProfilePopupFact) {
    return (
      <React.Fragment key={fact.label}>
        <GridItem padding={[8, 8, 8, 0]}>
          {fact.weight}{" "}
          {fact.inverted ? (
            <Tooltip placement="top" tooltip={t.inverted}>
              <img style={{ marginLeft: "5px", width: "14px" }} alt="inverted" src={"/assets/icon_inverted.svg"} />
            </Tooltip>
          ) : null}
        </GridItem>
        <GridItem padding={[8, 8, 8, 0]}>{fact.label}</GridItem>
        <GridItem padding={[8, 8, 8, 0]}>{props.showRawValues ? fact.rawValue : null}</GridItem>
        <GridItem padding={[8, 0, 8, 0]} justifySelf={"end"}>
          <span style={{ wordBreak: "break-word" }}>{fact.value}</span>
        </GridItem>
      </React.Fragment>
    )
  }

  const topBorder = css({
    borderTop: `1px solid ${getThemeColorVar("border", "default")}`,
    padding: "8px 8px 0",
    margin: "8px -8px 0",
  })

  const grey = css({
    color: "grey",
    fontSize: "12px",
  })

  const popupClass = css({
    backgroundColor: getThemeColorVar("white", undefined),
    borderRadius: "5px",
    border: `1px solid ${getThemeColorVar("border", "default")}`,
    width: "570px",
    maxHeight: "340px",
    padding: "10px 25px 10px 15px",
    fontSize: "14px",
    overflow: "auto",
  })

  return (
    <div className={popupClass}>
      <Flex flexDirection={"row"}>
        <Text
          additionalStyles={{
            fontSize: "20px",
            lineHeight: "26px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            marginRight: "10px",
            flexGrow: 1,
            whiteSpace: "nowrap",
          }}
          size={"md"}
          fontWeight={"bold"}
          color={"typo"}
          colorType={"dark"}
        >
          {props.header}
        </Text>
        <div
          className={css({ i: { fontSize: "16px" }, alignSelf: "center", cursor: "pointer" })}
          onClick={(e) => props.closeCallback?.()}
        >
          <Icon name={"close"} color={"primary"} colorType={"default"} />
        </div>
      </Flex>
      {props.loading ? (
        <div className={topBorder}>
          <LoadingSpinner size={48} />
        </div>
      ) : (
        <>
          <div style={{ fontWeight: "bold" }} className={topBorder}>
            <Grid columnSpec={"auto auto"} rowGap={0} padding={[8, 0, 8, 0]}>
              {renderMainFact(props.mainFact)}
            </Grid>
          </div>
          {props.secondaryFacts.length > 0 && (
            <div className={`${topBorder}`}>
              <Grid columnSpec={"max-content 280px auto min-content"} rowGap={0}>
                <GridItem padding={[8, 8, 0, 0]}>
                  <div className={`${grey}`}>{t.weight}</div>
                </GridItem>
                <GridItem padding={[8, 8, 0, 0]}>
                  <div className={`${grey}`}>{t.description}</div>
                </GridItem>
                <GridItem padding={[8, 8, 0, 0]}>
                  {props.showRawValues && <div className={`${grey}`}>{t.rawValue}</div>}
                </GridItem>
                <GridItem padding={[8, 0, 0, 0]} justifySelf={"end"}>
                  <div className={`${grey}`}>{t.scoreValue}</div>
                </GridItem>
                {props.secondaryFacts.map(renderFact)}
              </Grid>
            </div>
          )}
        </>
      )}
    </div>
  )
}
