import * as React from "react"
import { css, cx } from "emotion"
import { PropsWithChildren } from "react"
import FlexContainer from "./restyle-grid/flexcontainer"
import { getThemeColorVar } from "../helper/color"
import { EmotionCSS } from "../helper/responsive"
import { RevoSizeSpec } from "../util/layoutconstants"

export type RevoCardProps = {
  header?: JSX.Element | string
  footer?: JSX.Element | string
  padding?: RevoSizeSpec
}

const revoCardClass = css({
  borderRadius: "5px",
  overflow: "hidden",
  border: `1px solid ${getThemeColorVar("border", "default")}`,
  backgroundColor: getThemeColorVar("white", undefined),
} as EmotionCSS)

const revoCardHeaderClass = css({
  borderBottom: `1px solid ${getThemeColorVar("border", "default")}`,
} as EmotionCSS)

const revoCardFooterClass = css({
  borderTop: `1px solid ${getThemeColorVar("border", "default")}`,
} as EmotionCSS)

export const RevoCard: React.FunctionComponent<PropsWithChildren<RevoCardProps>> = (props) => {
  const { header, footer, padding, children } = props

  return (
    <div className={cx(revoCardClass)}>
      <FlexContainer direction="column">
        {header && (
          <div className={cx(revoCardHeaderClass)}>
            <FlexContainer canGrow={false} padding={padding || [24, 24, 24, 24]}>
              {header}
            </FlexContainer>
          </div>
        )}

        <FlexContainer padding={padding || [24, 24, 24, 24]} direction="column">
          {children}
        </FlexContainer>

        {footer && (
          <div className={cx(revoCardFooterClass)}>
            <FlexContainer canGrow={false} canShrink={false} padding={[24, 24, 24, 24]}>
              {footer}
            </FlexContainer>
          </div>
        )}
      </FlexContainer>
    </div>
  )
}

export default RevoCard
