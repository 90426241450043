import { State } from "./state"
import { initialState as locationSelectorInitialState } from "../../locationselector/location-selector-slice"
import { initialState as comparablesInitialState } from "./comparables-slice"
import { initialState as assessmentInitialState } from "./assessment-slice"
import { initialState as marketDataInitialState } from "./market-data-slice"
import { initialState as poiExplorerInitialState } from "./poi-explorer-slice"
import { initialState as googleMapsInitialState } from "./google-maps-slice"
import { initialState as rentIndexInitialState } from "./rentindex-slice"
import { initialState as specialMapsInitialState } from "./special-maps-slice"
import { initialState as navigationInitialState } from "../../shared/reducers/navigation-slice"
import { initialState as fundamentalDataInitialState } from "./fundamental-data-slice"

export const centerBerlin = {
  lng: 13.404954,
  lat: 52.520008,
}

export const INITIAL_STATE: State = {
  assessment: assessmentInitialState,
  navigation: navigationInitialState,
  comparablesApp: comparablesInitialState,
  marketDataApp: marketDataInitialState,
  rentindex: rentIndexInitialState,
  POIExplorerApp: poiExplorerInitialState,
  specialMapsApp: specialMapsInitialState,
  googleMapsData: googleMapsInitialState,
  locationSelector: locationSelectorInitialState,
  fundamentalData: fundamentalDataInitialState,
}
