import * as React from "react"
import { bind } from "decko"
import { GenericInputElementProps, GenericInputElementState, ReValidator } from "../models/genericinputelement"
import { TextfieldType } from "../models/textfield-type"
import GenericInputElement from "./genericinputfield"
import Icon from "./icon"
import FlexContainer from "./restyle-grid/flexcontainer"

export interface Props extends GenericInputElementProps<HTMLInputElement> {
  type?: TextfieldType
  id?: string
  javaScriptAutoFocus?: boolean
  lang?: string
  minLength?: number
  maxLength?: number
  autoComplete?: "on" | "off"
}

export class TextField extends GenericInputElement<Props, GenericInputElementState, HTMLInputElement> {
  constructor(props: Props) {
    super(props)
    this.validators.push(this.createMinLengthValidator(), this.createMaxLengthValidator())

    this.state = {
      ...this.defaultState,
    }
  }

  createMinLengthValidator(): ReValidator<string> {
    return (value) => {
      return {
        valid: this.props.minLength === undefined ? true : value.length >= this.props.minLength,
        validationMessage: this.translate.validationTranslations.textTooShort(this.props.minLength),
      }
    }
  }

  createMaxLengthValidator(): ReValidator<string> {
    return (value) => {
      return {
        valid: this.props.maxLength === undefined ? true : value.length <= this.props.maxLength,
        validationMessage: this.translate.validationTranslations.textTooLong(this.props.maxLength),
      }
    }
  }

  componentDidMount() {
    this.inputElRef && this.props.javaScriptAutoFocus && this.inputElRef.focus()
  }

  @bind
  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ isPristine: false })
    this.props.onValueChange &&
      this.props.onValueChange(event.target.value, this.validate(event.target.value).length === 0)
  }

  @bind
  handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      // handle Enter
      const value = this.inputElRef ? this.inputElRef.value : ""
      this.props.onValueCommit && this.props.onValueCommit(value, this.validate(value).length === 0)
    }
    this.props.onKeyDown && this.props.onKeyDown(event)
  }

  @bind
  handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    const value = this.inputElRef ? this.inputElRef.value : ""
    this.props.onValueCommit && this.props.onValueCommit(value, this.validate(value).length === 0)
    this.props.onBlur && this.props.onBlur(event)
  }

  renderInner() {
    return (
      <FlexContainer direction={"row"}>
        {this.props.icon && (
          <div
            style={{
              backgroundColor: "white",
              padding: "10px 8px 6px 8px",
              alignContent: "center",
              borderRadius: "4px 0 0 4px",
            }}
          >
            <Icon name={"search_property_v2"} color={"primary"} colorType={"default"} fontSize={16} />
          </div>
        )}
        <input
          autoFocus={this.props.autoFocus}
          ref={(el) => (this.inputElRef = el)}
          id={this.props.id}
          type={this.props.type}
          value={this.props.value}
          placeholder={this.props.hint}
          required={this.props.required}
          disabled={this.props.disabled}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={this.defaultHandleFocus}
          onKeyDown={this.handleKeyDown}
          lang={this.props.lang}
          tabIndex={this.props.tabIndex}
          autoComplete={this.props.autoComplete}
          style={{ borderRadius: this.props.icon ? "0 4px 4px 0" : "4px" }}
        />
      </FlexContainer>
    )
  }
}

export default TextField
