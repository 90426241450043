import { default as React } from "react"
import { translations } from "../i18n"
import { useAppSelector } from "../../relas/store"
import { parseLocalDate } from "../models/booking"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import RevoHeader from "../../shared/components/revo-header"
import Card from "../../shared/components/card"
import LoadingDimmer from "../../shared/components/loadingdimmer"
import Grid from "../../shared/components/restyle-grid/grid"
import Table from "../../shared/components/table"

export const CompanyReadonlyBookings = () => {
  const translate = React.useMemo(translations, [translations])
  const t = translate.readonlyBooking
  const bookings = useAppSelector((state) => state.invoiceState.readonlyBookings)
  const inProgress = useAppSelector((state) => state.invoiceState.fetchReadonlyBookingsInProgress)
  const error = useAppSelector((state) => state.invoiceState.fetchReadonlyBookingsError)

  const sortedBookings = React.useMemo(
    () => bookings && [...bookings].sort((b1, b2) => b2.start.localeCompare(b1.start)),
    [bookings]
  )

  return (
    <div className="with-body-scroll">
      <RevoHeader title={t.cardTitle} />

      <Grid columns={1} paddingY="xxl" paddingX="xxl">
        <Card header={t.cardTitle}>
          <LoadingDimmer loading={inProgress}>
            {error && <GenericErrorPanel error={error} />}
            <Grid columns={1} gap="xl">
              <Table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>{translate.booking.bundle} </th>
                    <th>{translate.booking.startDate}</th>
                    <th>{translate.booking.endDate}</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedBookings &&
                    sortedBookings.map((booking) => {
                      const start = parseLocalDate(booking.start)
                      const end = booking.end && parseLocalDate(booking.end)
                      return (
                        <tr key={booking.id}>
                          <td>{booking.id}</td>
                          <td>
                            {booking.bundle}{" "}
                            {translate.booking.description[booking.bundle]
                              ? " - " + translate.booking.description[booking.bundle]
                              : ""}
                          </td>
                          <td>{start.format(translate.dateFormat)}</td>
                          <td>{end ? end.format(translate.dateFormat) : "-"}</td>
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </Grid>
          </LoadingDimmer>
        </Card>
      </Grid>
    </div>
  )
}
