import React, { useMemo, useState } from "react"
import { translations } from "../i18n"
import { BorderBottom } from "../../shared/components/ui/border-bottom"
import { Flex } from "../../shared/components/ui/flex"
import { FlexItem } from "../../shared/components/ui/flex-item"
import { Grid } from "../../shared/components/ui/grid"
import { GridItem } from "../../shared/components/ui/grid-item"
import { GlobalState, useAppSelector } from "../../relas/store"
import {
  clearCreationError,
  clearUpdateSelectionError,
  createSelection,
  returnToSelectionList,
  updateSelection,
} from "../location-selector-slice"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import Panel from "../../shared/components/panel"
import Button from "../../shared/components/button"
import TextField from "../../shared/components/textfield"
import { GenericError } from "../../shared/helper/axios"

interface Props {
  mode: "duplicate" | "rename"
}

export const SelectionNameEdit: React.FC<Props> = (props) => {
  const t = useMemo(translations, [translations])

  const currentSelection = useAppSelector((state: GlobalState) => state.locationSelector.currentSelection)
  const createInProgress = useAppSelector((state: GlobalState) => state.locationSelector.selectionCreationLoading)
  const createError = useAppSelector((state: GlobalState) => state.locationSelector.selectionCreationError)
  const updateError = useAppSelector((state: GlobalState) => state.locationSelector.updateSelectionError)

  const [newName, setNewName] = useState(
    props.mode === "duplicate"
      ? (currentSelection?.name || "") + ` ${t.selectionRuleEdit.duplicateSuffix}`
      : currentSelection?.name || ""
  )

  React.useEffect(() => {
    if (createError || updateError) {
      clearCreationError()
      clearUpdateSelectionError()
    }
  }, [newName])

  const onSubmit = () => {
    if (!currentSelection) return
    switch (props.mode) {
      case "duplicate":
        void createSelection(newName.trim(), currentSelection.dataSetType, currentSelection.rules)
        break
      case "rename":
        updateSelection({
          ...currentSelection,
          name: newName.trim(),
        })
          .then(returnToSelectionList)
          .catch(() => {})
        break
    }
  }

  function renderError(error: GenericError) {
    if (error.status && error.status === 409) {
      return <Panel color="negative">{t.selectionAlreadyExists}</Panel>
    }
    return <GenericErrorPanel error={error} />
  }

  let title = ""
  let submitText = ""

  switch (props.mode) {
    case "duplicate":
      title = t.selectionListMenu.duplicate
      submitText = t.selectionRuleEdit.duplicate
      break
    case "rename":
      title = t.selectionListMenu.rename
      submitText = t.selectionRuleEdit.rename
      break
  }

  return (
    <Grid height={[100, "%"]} columns={1}>
      <Grid columns={1} height={[100, "%"]} gap={16} rowSpec="min-content 1fr">
        <BorderBottom>
          <Flex flexDirection="column" gap={8} padding={[8, 0]}>
            <Flex flexDirection="row" padding={[0, 16]}>
              <FlexItem alignSelf="start">
                <Button type="tertiary" icon="back" onClick={returnToSelectionList}>
                  {t.allSelections}
                </Button>
              </FlexItem>
            </Flex>
            <FlexItem flexGrow={1} />
            <FlexItem padding={[0, 16]}>
              <h2>
                {title} {t.selection}
              </h2>
            </FlexItem>
          </Flex>
        </BorderBottom>

        <GridItem padding={[0, 16]}>
          <TextField
            javaScriptAutoFocus={true}
            disabled={createInProgress}
            value={newName}
            onValueChange={setNewName}
          />
        </GridItem>

        <Flex flexDirection="row-reverse" gap={16} padding={16}>
          <Button disabled={newName.trim().length === 0} onClick={onSubmit} type="primary">
            {submitText}
          </Button>
          <Button
            type="secondary"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              returnToSelectionList()
            }}
          >
            {t.cancel}
          </Button>
        </Flex>

        {createError && renderError(createError)}
        {updateError && renderError(updateError)}
      </Grid>
    </Grid>
  )
}

export const DuplicateSelection = () => <SelectionNameEdit mode="duplicate" />

export const RenameSelection = () => <SelectionNameEdit mode="rename" />
