import * as React from "react"
import { css, cx } from "emotion"
import { PropsWithChildren } from "react"
import { getThemeColorVar } from "../helper/color"

export interface Props {
  onSubmit?: (event: React.FormEvent<HTMLElement>) => void
}

const formClass = css({
  marginBottom: 0,
  legend: {
    display: "block",
    width: "100%",
    padding: 0,
    marginTop: "10px",
    marginBottom: "10px",
    fontSize: "18px",
    fontWeight: 600,
    color: getThemeColorVar("typo", "default"),
    lineHeight: "inherit",
    border: 0,
  },
  fieldset: {
    border: 0,
    padding: 0,
    margin: "0 0 15px 0",
  },
})

export const Form: React.FunctionComponent<PropsWithChildren<Props>> = (props) => {
  return (
    <form onSubmit={props.onSubmit} className={cx(formClass)}>
      {props.children}
    </form>
  )
}

export default Form
