import * as React from "react"
import { FC, useEffect, useMemo, useState } from "react"
import { Profile } from "../../../profile/models/profile"
import { DataSetType } from "../../../shared/models/smartdata"
import {
  DistrictDataRule,
  isRuleCategoryWithField,
  LoadMacroData,
  LoadMicroData,
  OneOfFilter,
  RangeFilter,
  RankByProfile,
  RankByScores,
  RatingSelectionRule,
  ruleCategories,
  SelectRanks,
} from "../../../shared/models/selection"
import { Grid } from "../../../shared/components/ui/grid"
import { GridItem } from "../../../shared/components/ui/grid-item"
import { PopupMenu } from "../../../shared/components/ui/popup-menu"
import { ruleTitle } from "../../../shared/i18n/rule"
import { translations } from "../../i18n"
import { LANA_DISTRICT_PROPS } from "../../../shared/smartdata-products/smartdata"
import { getRuleCriteria } from "../../rating-manager-common"

type Props = {
  rule: RatingSelectionRule
  idx: number
  dataSetType: DataSetType
  onRuleEdit: (ruleIdx: number) => void
  availableProfiles: Profile[]
  setRuleToDelete: (rule: RatingSelectionRule) => void
}

export const RatingManagerSelectionRule: FC<Props> = (props) => {
  const t = useMemo(() => translations(), [])

  const [view, setView] = useState<JSX.Element | null>(null)
  useEffect(() => {
    async function inner() {
      switch (props.rule.type) {
        case "loadmacrodata":
          return renderLoadMacroData(props.rule)
        case "loadmicrodata":
          return renderLoadMicroData(props.rule, props.idx)
        case "rangefilter":
          return renderRangeFilter(props.rule, props.idx)
        case "oneoffilter":
          return renderOneOfFilter(props.rule, props.idx)
        case "rankbyprofile":
          return renderRankByProfile(props.rule, props.idx)
        case "rankbyscores":
          return renderRankByScores(props.rule, props.idx)
        case "selectranks":
          return renderSelectRanks(props.rule, props.idx)
        case "districtdatarule":
          return renderDistrictDataRule(props.rule, props.idx)
        default:
          return null
      }
    }

    const getView = async () => {
      const view = await inner()
      setView(view)
    }

    void getView()
  }, [props.rule, props.availableProfiles])

  async function renderLoadMacroData(rule: LoadMacroData) {
    const criteria = await getRuleCriteria(rule, props.dataSetType, t)
    return (
      <Grid columns={1} gap={4}>
        <h3>{t.loadMacroDataTitle}</h3>
        <div>{criteria}</div>
      </Grid>
    )
  }

  async function renderLoadMicroData(rule: LoadMicroData, idx: number) {
    let label = await getRuleCriteria(rule, props.dataSetType, t)

    return (
      <Grid columnSpec="1fr min-content" gap={4}>
        <h3>{t.loadMicroDataTitle}</h3>
        <GridItem rowSpan={2}>
          <PopupMenu
            actions={[
              {
                title: t.editForm.editRuleMenuItem,
                onClick: () => props.onRuleEdit(idx),
              },
            ]}
          />
        </GridItem>
        <div>
          {t.editForm.loadMicroDataExample}: {label}
        </div>
      </Grid>
    )
  }

  async function renderRangeFilter(rule: RangeFilter, idx: number) {
    const title = ruleTitle(t, rule)
    const label = await getRuleCriteria(rule, props.dataSetType, t)
    return (
      <Grid columnSpec="1fr min-content" gap={4}>
        <h3>{title}</h3>
        {renderPopupMenu()}
        <div>{label}</div>
      </Grid>
    )
  }

  async function renderOneOfFilter(rule: OneOfFilter, idx: number) {
    const ruleCategory = ruleCategories.find((c) => isRuleCategoryWithField(c) && c.field === rule.field)
    const label = await getRuleCriteria(rule, props.dataSetType, t)

    return (
      <Grid columnSpec="1fr min-content" gap={4}>
        <h3>{ruleCategory && t.pickTranslation(ruleCategory.label)}</h3>
        {renderPopupMenu()}
        <div>{label}</div>
      </Grid>
    )
  }

  async function renderRankByProfile(rule: RankByProfile, idx: number) {
    const { availableProfiles } = props
    const ruleCategory = ruleCategories.find((c) => c.type === "rankbyprofile")
    let profileName = availableProfiles?.find((p) => p.id === rule.id)?.name
    const label = await getRuleCriteria(rule, props.dataSetType, t, profileName)

    return (
      <Grid columnSpec="1fr min-content" gap={4}>
        <h3>{ruleCategory && t.pickTranslation(ruleCategory.label)}</h3>
        {renderPopupMenu()}
        <div>{label}</div>
      </Grid>
    )
  }

  async function renderRankByScores(rule: RankByScores, idx: number) {
    const ruleCategory = ruleCategories.find((c) => c.type === "rankbyscores")
    const label = await getRuleCriteria(rule, props.dataSetType, t)

    return (
      <Grid columnSpec="1fr min-content" gap={4}>
        <h3>{ruleCategory && t.pickTranslation(ruleCategory.label)}</h3>
        {renderPopupMenu()}
        <div>{label}</div>
      </Grid>
    )
  }

  async function renderSelectRanks(rule: SelectRanks, idx: number) {
    const title = ruleTitle(t, rule)
    const label = await getRuleCriteria(rule, props.dataSetType, t)

    return (
      <Grid columnSpec="1fr min-content" gap={4}>
        <h3>{title}</h3>
        {renderPopupMenu()}
        <div>{label}</div>
      </Grid>
    )
  }

  async function renderDistrictDataRule(rule: DistrictDataRule, idx: number) {
    const label = await getRuleCriteria(rule, props.dataSetType, t)
    return (
      <Grid columnSpec="1fr min-content" gap={4}>
        <h3>{t.pickTranslation(LANA_DISTRICT_PROPS[rule.score].title)}</h3>
        {renderPopupMenu()}
        <div>{label}</div>
      </Grid>
    )
  }

  function renderPopupMenu() {
    return (
      <GridItem rowSpan={2}>
        <PopupMenu
          actions={[
            {
              title: t.editForm.editRuleMenuItem,
              onClick: () => props.onRuleEdit(props.idx),
            },
            "divider",
            {
              title: t.editForm.deleteButtonLabel,
              onClick: () => props.setRuleToDelete(props.rule),
            },
          ]}
        />
      </GridItem>
    )
  }

  return view
}
