import { RatingGrade } from "./rating-grade"
import { DataSetType } from "./smartdata"
import { RatingDetail } from "../../explorer/models/rating"

export interface Rating {
  id: string
  name: string
  lockedBy?: string
  grades: RatingGrade[]
  dataSetType: DataSetType
  createdAt: string
}

export interface SelectionLabel {
  type: "raw" | "score" | "profile"
  name: string
}

export interface RatingResult {
  refId: string
  gradeIdx: number
  fields: { [key: string]: string | number }
  focusCell?: boolean
  score?: number
  selectionLabel?: SelectionLabel
}

export interface RatingResults {
  dataSetType: DataSetType
  originalCount: number
  results: RatingResult[]
  agsRefResLoc?: string
}

export interface MetaRatingRange {
  from?: number
  to?: number
  color: string
}

export interface MetaRating {
  id: string
  name: string
  ranges: MetaRatingRange[]
  lockedBy?: string
  companyId: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  weights: { [key: string]: number }
}

export type MetaRatingCreatePayload = Pick<MetaRating, "name" | "ranges" | "weights">

export type MetaRatingResult = {
  rating?: MetaRating
  result: number
}

export type RatingCreatePayload = Pick<RatingDetail, "name" | "grades" | "dataSetType"> & {
  rules?: RatingDetail["rules"]
}

export function isMetaRating(rating: Rating | MetaRating): rating is MetaRating {
  return "ranges" in rating
}

export type RatingLike = Rating | MetaRating | RatingDetail

export function isInMetaRange(range: MetaRatingRange, value: number): boolean {
  if (range.from !== undefined && range.to !== undefined) {
    return value >= range.from && value <= range.to
  } else if (range.from !== undefined) {
    return value >= range.from
  } else if (range.to !== undefined) {
    return value <= range.to
  } else {
    return true
  }
}
