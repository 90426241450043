import { css } from "emotion"
import { COLORS } from "../../../../shared/components/ui/colors"
import { Grid } from "../../../../shared/components/ui/grid"
import { ColumnHeaderCell, IColumnHeaderRenderer } from "@blueprintjs/table"
import * as React from "react"
import { IconProps } from "@blueprintjs/core/src/components/icon/icon"
import { DistributionGraphResult } from "../../../../generated-apis/comparables-service"

export type ShowAnnotationOption = "off" | "all" | "no_median"

export const chartTitleStyle = css({
  fontSize: 16,
  fontWeight: "bold",
  color: COLORS.typo.dark,
  flexGrow: 1,
})

export const settingsItemLabelStyle = css({
  fontSize: 14,
  fontWeight: "bold",
  color: COLORS.typo.dark,
})

export const cellClass = css({
  fontSize: "14px",
  fontWeight: "normal",
  lineHeight: "24px",
  ":hover": {
    background: COLORS.background.lighter,
  },
})

const headerClass = css({
  justifySelf: "center",
  maxWidth: "100%",
  fontSize: "16px",
  fontWeight: "bold",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
})

export const dropdownIconProps: Partial<IconProps> = {
  icon: "chevron-down",
  iconSize: 16,
  color: COLORS.primary.default,
}

export const analysisItemClass = css({
  backgroundColor: "#ffffff",
  borderRadius: 4,
  overflow: "auto",
  padding: 16,
  border: `1px solid ${COLORS.border.default}`,
})

export function objectColumnHeader(headerCol: string): IColumnHeaderRenderer {
  return () => {
    return (
      <ColumnHeaderCell>
        <Grid columns={1}>
          <div className={headerClass}>{headerCol}</div>
        </Grid>
      </ColumnHeaderCell>
    )
  }
}

export const chartTableSwitchStyle = css({
  "div .revo-grid div": {
    paddingTop: 2,
    paddingBottom: 4,
  },
})

export const comparablesAnalysisContentStyle = css({
  height: 250,
})

export function calculateStep(r: DistributionGraphResult) {
  return r.count <= 1 ? 1 : (r.max - r.min) / (r.count - 1)
}

export const infoMessage = css({
  paddingTop: "12px",
  fontSize: "14px",
  color: COLORS.typo.light,
})
