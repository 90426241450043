import * as React from "react"
import { useMemo } from "react"
import { DistributionBuckets, DistributionGraphResult } from "../../../../generated-apis/comparables-service"
import { Cell, Column, RegionCardinality, RenderMode, Table } from "@blueprintjs/table"
import { translations } from "../../../i18n"
import { translations as sharedTranslations } from "../../../../shared/i18n"
import { calculateStep, cellClass, objectColumnHeader } from "./comparables-analysis-common"

interface Props {
  distributionData: DistributionGraphResult
  bucketToLabel: (bucketValue: number) => string
  bucketSuffix: string
  isRangeBuckets: boolean
}

const areAllBucketsPresent: (d: DistributionGraphResult) => boolean = (d: DistributionGraphResult) =>
  d.buckets?.length === d.count

const range: (start: number, end: number) => number[] = (start: number, end: number) => {
  const length = 1 + end - start
  return [...Array(length).keys()].map((v) => start + v)
}

const emptyBucket: (n: number) => DistributionBuckets = (n: number) => ({ bucket: n, offers: 0 })

const addMissingBuckets: (d: DistributionGraphResult) => DistributionGraphResult = (d: DistributionGraphResult) => {
  const emptyBuckets: DistributionBuckets[] = range(0, d.count).map((n) => emptyBucket(n + 1))

  const allBuckets: DistributionBuckets[] = emptyBuckets.map((bucket) => {
    const existingBucket = d.buckets?.find((b) => b.bucket === bucket.bucket)
    return existingBucket || bucket
  })

  const result: DistributionGraphResult = {
    ...d,
    buckets: allBuckets,
  }

  return result
}

export const ComparablesDistributionTable = ({
  distributionData,
  bucketToLabel,
  bucketSuffix,
  isRangeBuckets,
}: Props) => {
  const withoutMissingBuckets = areAllBucketsPresent(distributionData)
    ? distributionData
    : addMissingBuckets(distributionData)

  const t = useMemo(() => {
    return { ...translations().assessmentEntryDetails, shared: sharedTranslations() }
  }, [translations])

  const step = calculateStep(withoutMissingBuckets)

  function rangeColumnRenderer(idx: number) {
    const bucket =
      withoutMissingBuckets.buckets && withoutMissingBuckets.buckets[idx]
        ? withoutMissingBuckets.buckets[idx].bucket - 1
        : 0
    const min = bucketToLabel(Math.max(distributionData.min, distributionData.min + step * bucket - step / 2))
    const max = bucketToLabel(Math.min(distributionData.max, distributionData.min + step * bucket + step / 2))
    const singleValueLabel = bucketToLabel(distributionData.min + step * bucket)

    return min === max || !isRangeBuckets
      ? `${singleValueLabel}\xa0${bucketSuffix}`
      : `${min}\xa0-\xa0${max}\xa0${bucketSuffix}`
  }

  function dataForCell(field: keyof DistributionBuckets, idx: number) {
    switch (field) {
      case "offers":
        return withoutMissingBuckets.buckets && withoutMissingBuckets.buckets[idx]
          ? withoutMissingBuckets.buckets[idx].offers.toString()
          : ""
      case "bucket":
        return rangeColumnRenderer(idx)
    }
  }

  const cell = (field: keyof DistributionBuckets) => (idx: number) => {
    return <Cell className={cellClass}>{dataForCell(field, idx)}</Cell>
  }

  const columns = [
    <Column key="bucket" columnHeaderCellRenderer={objectColumnHeader(t.range)} cellRenderer={cell("bucket")} />,
    <Column
      key="offers"
      columnHeaderCellRenderer={objectColumnHeader(t.observedOffers)}
      cellRenderer={cell("offers")}
    />,
  ]

  return (
    <Table
      numRows={distributionData.count}
      minRowHeight={24}
      defaultRowHeight={24}
      enableRowHeader={false}
      getCellClipboardData={(row, col) => dataForCell(columns[col].key as keyof DistributionBuckets, row)}
      renderMode={RenderMode.BATCH}
      selectionModes={[RegionCardinality.CELLS]}
    >
      {columns}
    </Table>
  )
}
