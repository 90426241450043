import React, { CSSProperties, useEffect, useState } from "react"
import { css } from "emotion"
import { getThemeColorVar } from "../../../../shared/helper/color"
import {
  ApartmentItemData,
  CommercialItemData,
  ComparablesItemShort,
  HouseItemData,
  ImmoscoutComparablesItemShort,
  ItemTypeSpecificData,
  TwentyOneComparablesItemShort,
} from "../../../../generated-apis/comparables-service"
import { DataSource } from "../../../models/comparables"
import { translations } from "../../../../shared/i18n"
import { translations as assessmentTranslations } from "../../../i18n"
import { ComparablesApi } from "../../../../generated-apis/comparables-service"
import { comparablesServiceUrl } from "../../../../app_config"
import Icon from "../../../../shared/components/icon"
import Grid from "../../../../shared/components/restyle-grid/grid"
import { ComparablesOfferCard } from "./comparables-offer-card"
import Button from "../../../../shared/components/button"

const comparablesApi = new ComparablesApi(undefined, comparablesServiceUrl)

const styles = {
  offersListHeader: css({
    display: "flex",
    gap: "12px",
    textOverflow: "ellipsis",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "12px",
  }),
  offersListBody: css({
    display: "grid",
    gridTemplateRows: "repeat(3, min-content)",
  }),
  pagination: css({
    textAlign: "center",
    userSelect: "none",
    alignSelf: "end",
    fontSize: "14px",
  }),
}

export type OfferObjectType =
  | "office"
  | "retail"
  | "hall"
  | "flat"
  | "house"
  | "commercial"
  | "apartment"
  | "plot"
  | "other"

export interface OfferData {
  id: number
  street?: string
  houseNumber?: string
  sqmPriceCents?: number
  area?: number
  rooms?: number
  offerDateQuarter?: string
  offerDateYear?: string
  type?: OfferObjectType
  zip?: string
  city?: string
  constructionYear?: number
  furnished?: boolean
  description?: string
  int_id?: number
  additionalInfo?: string
  title?: string
}

type PossibleItemFeatures = keyof ApartmentItemData | keyof CommercialItemData | keyof HouseItemData

interface Props {
  items: ComparablesItemShort[]
  dataSource: DataSource
  left: number
  top: number
}

const getStyles = (left: number, top: number): CSSProperties => {
  return {
    top: "12px",
    left: "12px",
    position: "absolute",
    zIndex: 1001,
    display: "grid",
    gridTemplateRows: "min-content 1fr min-content",
    backgroundColor: "white",
    padding: "12px 15px 8px 15px",
    height: "calc(100% - 12px)",
    width: "384px",
    minHeight: "330px",
    overflowY: "auto",
  }
}

const t = { ...translations(), assessmentTranslations: assessmentTranslations() }

export const OffersBox = ({ left, top, dataSource, items }: Props) => {
  const ident = items
    .map((x) => x.id)
    .sort((a, b) => a - b)
    .map((x) => x.toString(36))
    .join("_")

  const getRandomSelectedItem = (currentItems: ComparablesItemShort[]) => {
    if (currentItems.length > 0) {
      const itemsOnPageNumber = currentItems.length
      const randomItemIndex = Math.floor(Math.random() * itemsOnPageNumber)
      return currentItems[randomItemIndex].id
    }
    return undefined
  }

  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentCount, setCurrentCount] = useState(items.length)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedOffer, setSelectedOffer] = useState<number>()

  const [objectDataArr, setObjectDataArr] = useState<Array<OfferData>>()
  const [objectDataLoading, setObjectDataLoading] = useState(false)

  const offersPerPage = 3

  useEffect(() => {
    setCurrentCount(items.length)
    setCurrentIndex(0)
    setCurrentPage(1)
  }, [ident])

  useEffect(() => {
    if (currentIndex >= 0) {
      const currItems = items.slice(currentIndex, currentIndex + offersPerPage)
      const refIdArr: Array<TwentyOneComparablesItemShort> = []
      const liveDataArr: Array<ImmoscoutComparablesItemShort> = []
      currItems.forEach((i) => {
        if ("refId" in i) refIdArr.push(i)
        else liveDataArr.push(i)
      })
      if (refIdArr.length > 0) itemsWithRefIdToMapOffersBox(refIdArr)
      if (liveDataArr.length > 0) itemsWithDataToMapOffersBox(liveDataArr)
      setSelectedOffer(getRandomSelectedItem(currItems))
    }
  }, [currentIndex, ident])

  const itemsWithDataToMapOffersBox = (itemArr: Array<ImmoscoutComparablesItemShort>) => {
    const objectFeatureMap = new Map<PossibleItemFeatures, string>([
      ["balcony", t.assessmentTranslations.assessmentComparables.balcony],
      ["garden", t.assessmentTranslations.assessmentComparables.garden],
      ["lift", t.assessmentTranslations.assessmentComparables.lift],
      ["guestToilet", t.assessmentTranslations.assessmentComparables.guestToilet],
      ["cellar", t.assessmentTranslations.assessmentComparables.cellar],
      ["isBarrierFree", t.assessmentTranslations.assessmentComparables.barrierFree],
      ["certificateOfEligibilityNeeded", t.assessmentTranslations.assessmentComparables.certificateOfEligibilityNeeded],
      ["builtInKitchen", t.assessmentTranslations.assessmentComparables.builtInKitchen],
    ])

    const getObjectFeatures = (item: ItemTypeSpecificData) => {
      const arr: string[] = []
      if ("privateOffer" in item.common && item.common.privateOffer)
        arr.push(t.assessmentTranslations.assessmentComparables.privateOffer)
      objectFeatureMap.forEach((label: string, key) => {
        if (key in item && !!(item as any)[key]) {
          arr.push(label)
        }
      })
      return arr
    }

    const itemForOffersBox = (liveItem: ImmoscoutComparablesItemShort) => {
      let item: OfferData = {
        id: liveItem.id,
        street: liveItem.data.common.address.street ?? undefined,
        houseNumber: liveItem.data.common.address.houseNumber ?? undefined,
        offerDateYear: liveItem.data.common.creationDate,
        type: liveItem.data.itemType,
        zip: liveItem.data.common.address.postcode,
        city: liveItem.data.common.address.city,
        int_id: liveItem.data.common.int_id,
        additionalInfo: getObjectFeatures(liveItem.data).join(", "),
        title: liveItem.data.common.title,
      }
      switch (liveItem.data.itemType) {
        case "apartment":
        case "house": {
          if ("livingSpace" in liveItem.data) {
            item = {
              ...item,
              sqmPriceCents: liveItem.data.price?.value
                ? Math.round((liveItem.data.price?.value * 100) / liveItem.data.livingSpace)
                : undefined,
              area: liveItem.data.livingSpace,
              rooms: liveItem.data.numberOfRooms,
            }
          }
          break
        }
        case "commercial": {
          if ("netFloorSpace" in liveItem.data) {
            item = {
              ...item,
              area: liveItem.data.netFloorSpace,
            }
          }
          if ("price" in liveItem.data && liveItem.data.price?.value) {
            item = {
              ...item,
              sqmPriceCents: liveItem.data.price?.value * 100,
            }
          }
          break
        }
      }
      return item
    }

    setObjectDataArr(itemArr.map((item) => itemForOffersBox(item)))
  }

  const itemsWithRefIdToMapOffersBox = (items: Array<TwentyOneComparablesItemShort>) => {
    setObjectDataLoading(true)
    const refIdArray = items.map((i: TwentyOneComparablesItemShort) => i.refId)
    comparablesApi.postApiV1ComparableDetails({ refIds: refIdArray }).then(
      (success) => {
        const offersArr: OfferData[] = success.data.map((item) => {
          return {
            id: item.id,
            street: item.street,
            houseNumber: item.houseNumber,
            sqmPriceCents: item.sqmPriceCents,
            area: item.area,
            rooms: item.rooms,
            offerDateQuarter: `${item.offerDateQuarter}`,
            offerDateYear: `${item.offerDateYear}`,
            constructionYear: item.buildYear,
            furnished: item.furnished,
            type: item.objectType,
            description: item.description,
            zip: item.zip,
            city: item.city,
          }
        })
        setObjectDataLoading(false)
        setObjectDataArr(offersArr)
      },
      () => {}
    )
  }
  const pagesNumber = Math.ceil(currentCount / offersPerPage)

  const onNext = (e: React.MouseEvent) => {
    if (currentPage < pagesNumber) {
      setCurrentIndex(Math.min(currentIndex + offersPerPage, currentCount))
      setCurrentPage(Math.min(currentPage + 1, pagesNumber))
    }
    e.stopPropagation()
  }

  const onPrevious = (e: React.MouseEvent) => {
    setCurrentIndex(Math.max(0, currentIndex - offersPerPage))
    setCurrentPage(Math.max(currentPage - 1, 1))
    e.stopPropagation()
  }

  const itemIdPrefix = "observed_offers_box_"

  return (
    <div style={getStyles(left, top)}>
      <Grid
        columns={2}
        columnSpec="1fr min-content"
        className={css({ alignItems: "center", borderBottom: `1px solid ${getThemeColorVar("primary", "light")}` })}
      >
        <div className={styles.offersListHeader}>
          <div>{`${t.assessmentTranslations.assessmentComparables.filteredOffers}`}</div>
          <div>{currentCount}</div>
        </div>
        <span style={{ paddingRight: "12px" }}>
          <Icon name={"offers_list"} color={"primary"} colorType={"default"} />
        </span>
      </Grid>

      <div className={styles.offersListBody}>
        {objectDataArr &&
          objectDataArr.map((objectData, index) => {
            if (objectData)
              return (
                <ComparablesOfferCard
                  key={objectData.id}
                  objectData={objectData}
                  objectDataLoading={objectDataLoading}
                  dataSource={dataSource}
                  selected={objectData.id === selectedOffer}
                  onClick={() => setSelectedOffer(objectData.id)}
                />
              )
            else return <div />
          })}
      </div>

      {currentCount > 1 && (
        <div className={styles.pagination}>
          <Button
            onClick={onPrevious}
            type="tertiary"
            icon="chevron_left_small"
            disabled={currentPage === 1}
            size={"small"}
          />
          <span>
            <b id={itemIdPrefix + "current_index"}>{currentPage}</b>/{pagesNumber}
          </span>
          <Button
            onClick={onNext}
            type="tertiary"
            icon="chevron_right_small"
            disabled={currentPage === pagesNumber}
            size={"small"}
          />
        </div>
      )}
    </div>
  )
}
