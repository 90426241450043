import * as React from "react"
import { css, cx } from "emotion"
import { PropsWithChildren } from "react"
import { getThemeColorVar } from "../helper/color"

export interface Props {
  /** Selectable rows */
  selectable?: boolean
  /** sticky table header */
  stickyHeader?: boolean
}

const defaultProps: Props = {
  selectable: false,
}

const tableClass = css({
  width: "100%",
  maxWidth: "100%",
  borderCollapse: "collapse",

  tr: {
    borderBottom: `1px solid ${getThemeColorVar("border", "default")}`,
  },

  th: {
    textAlign: "left",
    borderBottom: `1px solid ${getThemeColorVar("typo", "dark")}`,
    fontWeight: 700,
    padding: "10px 10px 8px 10px",
  },

  "th, td": {
    padding: "20px 10px",
    lineHeight: "20px",
  },

  thead: {
    color: getThemeColorVar("typo", "dark"),
  },
})

const selectableClass = css({
  "tr:hover": {
    backgroundColor: getThemeColorVar("background", "lighter"),
    cursor: "pointer",
  },
})

const stickyClass = css({
  th: {
    backgroundColor: getThemeColorVar("white", undefined),
    borderBottom: 0,
    position: ["sticky", "-webkit-sticky"],
    top: 0,
  },
  "th:after": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    borderBottom: `1px solid ${getThemeColorVar("border", "dark")}`,
  },
})

export const Table: React.FunctionComponent<PropsWithChildren<Props>> = (props) => {
  const classes: string[] = [tableClass]

  props.selectable && classes.push(selectableClass)
  props.stickyHeader && classes.push(stickyClass)

  return <table className={cx(classes)}>{props.children}</table>
}

Table.defaultProps = defaultProps

export default Table
