import React, { useEffect, useRef, useState } from "react"
import GoogleMapReact from "google-map-react"
import { css, cx } from "emotion"
import PoiMapPopup from "./poi-map-popup"
import { createRoot } from "react-dom/client"
import { IsochroneType } from "../isochrone-type"
import { getIsochronePolygon } from "../../../utils/here-maps-isochrone"
import { Location } from "../../models/address"
import { trackUsageEvent } from "../../../utils/usage-tracking"
import { AssessmentEntryFull } from "../../models/assessment"
import { IsochronePopup } from "../isochrone-popup"
import { reportAssetsUrl } from "../../../reports/report-config"
import { POIMarker, TextSearchPOIMarker } from "./poi-marker"
import { translations } from "../../i18n"
import {
  NUMBERED_TEXT_SEARCH_IDS,
  NUMBERED_TEXT_SEARCH_IDS_SET,
  NumberedTextSearchIds,
  stringIsNumberedTextSearchId,
  TextSearchIDsToTitle,
} from "../../../shared/models/poi-explorer"
import {
  CategoryDataList,
  generateNewPoiClusterData,
  PrivatePoiClusters,
} from "../../../shared/components/private-poi-clusters"
import { PoiMarkerType } from "../../../shared/components/poi-cluster-marker"
import {
  AllowedModules,
  AllowedModulesEnum,
  PrivateDataModuleSettings,
  PrivatePOICategoriesList,
  PrivatePOIList,
} from "../../../private-data/models/private-data"
import { PoiMarkerDialog } from "../../../shared/components/privatedata-poi-marker-dialog"
import { PrivateDataCategoryListPopup } from "../../../shared/components/privatedata-category-list-popup"
import { TooltipButtonContainer } from "../../../shared/components/tooltip-button-container"
import { PrivateDataCategoryListPopupNotBooked } from "../../../shared/components/private-data-category-list-popup-not-booked"
import { PlaceResultEncodable, placeResultToEncodable } from "../../../shared/models/place-result-encodable"
import PlacesServiceStatus = google.maps.places.PlacesServiceStatus
import PlaceSearchPagination = google.maps.places.PlaceSearchPagination
import LatLngBounds = google.maps.LatLngBounds
import { haversine_distance } from "../../../utils/utils"
import Grid from "../../../shared/components/restyle-grid/grid"
import LoadingSpinner from "../../../shared/components/loadingspinner"
import { getThemeColorVar } from "../../../shared/helper/color"

const styles = {
  mapContainer: css({
    height: "100%",
    width: "100%",
    position: "relative",
    backgroundColor: "rgb(229, 227, 223)",
  }),
  controlContainer: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }),
  pointMarker: css({
    position: "absolute",
    zIndex: -1,
    transform: "translate(-50%, -100%)",
    img: { width: "40px" },
  }),
  imgShadow: css({
    filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5))",
  }),
  mapNotifierStyle: css({
    backgroundColor: getThemeColorVar("background", "lighter"),
    width: "100%",
    position: "absolute",
    zIndex: 1000,
    bottom: 0,
    transition: "max-height 1s",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.9,
    overflow: "hidden",
  }),
  mapPopupWrapper: css({
    width: "max-content",
    fontSize: "14px",
    maxWidth: "500px",
    minWidth: "200px",
  }),
}
interface MarkerType {
  key: string
  lat: number
  lng: number
}

export type ExtendedPlaceResult = PlaceResultEncodable & {
  icon_background_color?: string
  icon_mask_base_uri?: string
}

export interface POIMarkerType extends MarkerType {
  poiData: ExtendedPlaceResult
  distance?: number
}

interface Props {
  showOnlyPrimaryCategories: boolean
  selectedPOIGroup: string
  poiCategories: Set<string>
  poiTextSearch: TextSearchIDsToTitle
  pois: Map<string, PlaceResultEncodable[]> | null
  doAddPois: (category: string, pois: PlaceResultEncodable[]) => void
  setAllLoadingPois: (pois: Set<string>) => void
  isochronePolygon: google.maps.Polygon | undefined
  setIsochronePolygon: (polygon: google.maps.Polygon | undefined) => void
  isochroneSettings: IsochroneType
  setIsochroneSettings: (settings: IsochroneType) => void
  assessmentEntry: AssessmentEntryFull | null
  showControls: boolean
  fitToBounds: "none" | "all" | "isochrone"
  setSelectedPOICategories: (categories: Set<string>) => void
  isPrivateDataAccessible: boolean
  privatePoiCategories: PrivatePOICategoriesList
  privateDataSettings: PrivateDataModuleSettings
  doPrivateDataPOICategoriesGet: () => void
  doPrivateDataSelectedCategoriesUpdate: (categories: PrivatePOICategoriesList, module: AllowedModules) => void
  doPrivateCategoryPOIsGet: (categoryId: string, module?: AllowedModules) => void
  doUpdatePoisToShow: (poiList: PrivatePOIList, module?: AllowedModules) => void
}
const AssessmentEntryMarker = (_: MarkerType) => {
  return (
    <div className={styles.pointMarker}>
      <img src={`${reportAssetsUrl ?? ""}/assets/marker.svg`} alt="Assessment entry" className={styles.imgShadow} />
    </div>
  )
}

const centerBerlin = { lat: 52.520008, lng: 13.404954 }
const initialZoom = 13
let service: google.maps.places.PlacesService

const MapPopupWrapper = (props: POIMarkerType) => {
  return (
    <div className={styles.mapPopupWrapper} onMouseDown={(e) => e.stopPropagation()}>
      <PoiMapPopup poiData={props.poiData} distance={props.distance} />
    </div>
  )
}

const module = AllowedModulesEnum.POI_EXPLORER

// DO NOT ADD REDUX DEPENDENCIES TO THIS COMPONENT, OTHERWISE IT WILL CRASH THE POI REPORT
export const POIExplorerMap = (props: Props) => {
  const {
    isPrivateDataAccessible,
    privatePoiCategories,
    privateDataSettings,
    doPrivateDataPOICategoriesGet,
    doPrivateDataSelectedCategoriesUpdate,
    doPrivateCategoryPOIsGet,
    doUpdatePoisToShow: doUpdatePrivatePoisToShow,
  } = props
  const { selectedCategories, multipleCategoryPOIList, poisToShow: privatePoisToShow } = privateDataSettings
  const [gmap, setGmap] = useState<google.maps.Map>()
  const [gmaps, setGmaps] = useState<typeof google.maps>()
  const [bounds, setBounds] = useState<LatLngBounds>()
  const [zoom, setZoom] = useState(initialZoom)
  const [pois, setPois] = useState<Map<string, PlaceResultEncodable[]>>(props.pois ? new Map() : new Map(props.pois))
  const [loadingPois, setLoadingPois] = useState<Set<string>>(new Set())
  const [popup, setPopup] = useState<POIMarkerType>()
  const [showIsochronePopup, setShowIsochronePopup] = useState(false)
  const [showPrivatePOIPopup, setShowPrivatePOIPopup] = useState(false)
  const [privateCategoryDataList, setPrivateCategoryDataList] = useState<CategoryDataList>([])
  const [locationToShowPrivatePoiPopup, setLocationToShowPrivatePoiPopup] = useState<PoiMarkerType | undefined>(
    undefined
  )
  const [isPopUpHovered, setIsPopUpHovered] = useState<boolean>(false)

  const t = React.useMemo(translations, [translations])

  let delayedRequestsCount = 0

  useEffect(() => {
    if (props.assessmentEntry?.address.location) {
      props.isochronePolygon?.setMap(null)

      if (props.isochroneSettings.mode !== "none" && gmap) {
        getIsochronePolygon(props.assessmentEntry?.address.location, props.isochroneSettings)
          .then((poly) => {
            props.setIsochronePolygon(poly)
          })
          .catch(() => {})
      } else {
        props.setIsochronePolygon(undefined)
      }
    }
  }, [props.isochroneSettings, gmap, props.assessmentEntry?.address.location])

  useEffect(() => {
    props.setAllLoadingPois(loadingPois)
  }, [loadingPois])

  useEffect(() => {
    if (gmap && props.isochronePolygon) {
      props.isochronePolygon.setMap(gmap)
    }
  }, [props.isochronePolygon, gmap])

  useEffect(() => {
    if (props.pois) setPois(new Map(props.pois))
  }, [props.showOnlyPrimaryCategories])

  useEffect(() => {
    setPrivateCategoryDataList(generateNewPoiClusterData(multipleCategoryPOIList))
  }, [privateDataSettings])

  const handleOnLoad = (map: google.maps.Map, maps: typeof google.maps) => {
    if (props.showControls) {
      const controlButtonDiv = document.createElement("div")
      controlButtonDiv.className = styles.controlContainer
      createRoot(controlButtonDiv).render(
        <>
          <TooltipButtonContainer
            tooltip={"Private POIs"}
            buttonName={"private_poi"}
            onClick={() => setShowPrivatePOIPopup(true)}
          />
          <TooltipButtonContainer
            tooltip={t.isochrone}
            buttonName={"marker"}
            onClick={() => setShowIsochronePopup(true)}
          />
        </>
      )
      map.controls[maps.ControlPosition.RIGHT_BOTTOM].push(controlButtonDiv)
    }
    service = new maps.places.PlacesService(map)
    map.setOptions({ isFractionalZoomEnabled: true })
    setGmap(map)
    setGmaps(maps)
    setBounds(map.getBounds())
    if (isPrivateDataAccessible) doPrivateDataPOICategoriesGet()
  }

  const fetchPOIs = (category: string, location?: Location) => {
    if (location && service) {
      service.nearbySearch(
        {
          location: {
            lat: location.lat,
            lng: location.lng,
          },
          rankBy: google.maps.places.RankBy.DISTANCE,
          type: category,
        },
        (results, status, pagination) =>
          poisCallback(results?.map(placeResultToEncodable) ?? [], status, pagination, category, fetchPOIs)
      )
    }
  }

  const fetchPOIsByTextSearch: (textSearchId: NumberedTextSearchIds, location?: Location) => void = async (
    textSearchId: NumberedTextSearchIds,
    location?: Location
  ) => {
    if (location && service) {
      return service.nearbySearch(
        {
          location: {
            lat: location.lat,
            lng: location.lng,
          },
          keyword: props.poiTextSearch[textSearchId],
          radius: 20_000,
        },
        (results, status, pagination) =>
          poisCallback(results?.map(placeResultToEncodable) ?? [], status, pagination, textSearchId, (_, location) =>
            fetchPOIsByTextSearch(textSearchId, location)
          )
      )
    }
  }

  const poisCallback = (
    results: PlaceResultEncodable[],
    status: PlacesServiceStatus,
    pagination: PlaceSearchPagination | null,
    category: string,
    fetchPOIs: (category: string, location?: Location) => void
  ) => {
    let loadingDone = false
    if (status == google.maps.places.PlacesServiceStatus.OK) {
      setPois((currentPois) => {
        const poisToUpdate = new Map(currentPois)
        const existingCategory = currentPois.get(category)
        poisToUpdate.set(
          category,
          existingCategory
            ? [
                // Only unique elements selected by the place id
                ...new Map([...existingCategory, ...results].map((item) => [item.place_id, item])).values(),
              ]
            : results
        )

        return poisToUpdate
      })
      pagination?.hasNextPage ? pagination.nextPage() : (loadingDone = true)
    } else {
      if (
        status == google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT &&
        props.assessmentEntry?.address.location
      ) {
        delayedRequestsCount++
        setTimeout(() => fetchPOIs(category, props.assessmentEntry?.address.location), delayedRequestsCount * 1000)
      } else {
        loadingDone = true
      }
    }

    if (loadingDone) {
      setPois((currentPois) => {
        const updatedPois = new Map(currentPois)
        const googlePois = updatedPois.get(category)
        setTimeout(() => {
          props.doAddPois(category, googlePois ?? [])
        }, 0)
        return updatedPois
      })

      setLoadingPois((currentLoading) => {
        const loading = new Set(currentLoading)
        loading.delete(category)
        return loading
      })
    }
  }

  useEffect(() => {
    setPois(new Map())
    setLoadingPois(new Set())
    setPopup(undefined)
    setShowIsochronePopup(false)
  }, [props.selectedPOIGroup])

  useEffect(() => {
    if (showPrivatePOIPopup && showIsochronePopup) {
      setShowIsochronePopup(false)
    }
  }, [showPrivatePOIPopup])

  useEffect(() => {
    if (showPrivatePOIPopup && showIsochronePopup) {
      setShowPrivatePOIPopup(false)
    }
  }, [showIsochronePopup])

  useEffect(() => {
    const newPois = new Map()
    if (gmaps) {
      pois.forEach((_, key) => {
        if (props.poiCategories.has(key)) newPois.set(key, pois.get(key))
      })
      setPois(newPois)

      props.poiCategories.forEach((categoryName) => {
        if (!pois.has(categoryName) && categoryName !== "local_public_transport")
          setLoadingPois((currentLoading) => {
            return new Set(currentLoading).add(categoryName)
          })
      })

      props.poiCategories.forEach((categoryName) => {
        // for every new selected poi category
        if (!pois.has(categoryName) && categoryName !== "local_public_transport") {
          stringIsNumberedTextSearchId(categoryName)
            ? fetchPOIsByTextSearch(categoryName, props.assessmentEntry?.address.location)
            : fetchPOIs(categoryName, props.assessmentEntry?.address.location)
        }
      })
    }
  }, [props.poiCategories, gmaps])

  const lastKnownLocation = useRef<Location | undefined>(undefined)
  useEffect(() => {
    // check for last known location to make sure that it was changed, not just initialized
    if (lastKnownLocation.current === undefined && props.assessmentEntry?.address.location) {
      return
    }
    lastKnownLocation.current = props.assessmentEntry?.address.location

    setPois(new Map())
    setLoadingPois(props.poiCategories)
    props.poiCategories.forEach((category) => props.doAddPois(category, []))

    props.poiCategories.forEach((categoryName) => {
      if (categoryName !== "local_public_transport") {
        stringIsNumberedTextSearchId(categoryName)
          ? fetchPOIsByTextSearch(categoryName, props.assessmentEntry?.address.location)
          : fetchPOIs(categoryName, props.assessmentEntry?.address.location)
      }
    })
  }, [props.assessmentEntry?.address.location])

  useEffect(() => {
    const updatedPois = new Map(pois)
    NUMBERED_TEXT_SEARCH_IDS.forEach((id) => updatedPois.delete(id))
    setPois(updatedPois)

    const searchedTermsWithIds: NumberedTextSearchIds[] = NUMBERED_TEXT_SEARCH_IDS.reduce<NumberedTextSearchIds[]>(
      (acc, id: NumberedTextSearchIds) => {
        const text = props.poiTextSearch[id]
        if (text === undefined) {
          return acc
        }
        acc.push(id)
        return acc
      },
      []
    )

    if (searchedTermsWithIds.length > 0) {
      const categoriesToUpdate = new Set(props.poiCategories)
      NUMBERED_TEXT_SEARCH_IDS.forEach((id) => categoriesToUpdate.delete(id))
      searchedTermsWithIds.forEach((id) => categoriesToUpdate.add(id))
      props.setSelectedPOICategories(categoriesToUpdate)

      const _loadPois = async () => {
        for (const id of searchedTermsWithIds) {
          await fetchPOIsByTextSearch(id, props.assessmentEntry?.address.location)
        }
      }

      _loadPois().catch(() => {})
    } else {
      // If none are defined, remove them all
      const categoriesToUpdate = new Set(props.poiCategories)
      NUMBERED_TEXT_SEARCH_IDS.forEach((id) => categoriesToUpdate.delete(id))
      props.setSelectedPOICategories(categoriesToUpdate)
    }
  }, [
    props.poiTextSearch["textSearch-1"],
    props.poiTextSearch["textSearch-2"],
    props.poiTextSearch["textSearch-3"],
    props.poiTextSearch["textSearch-4"],
    props.poiTextSearch["textSearch-5"],
  ])

  const onPOIClick = (poiData: POIMarkerType) => {
    props.assessmentEntry && trackUsageEvent("POI_EXPLORER_OPEN_POPUP", props.assessmentEntry.address, null)
    const distance =
      props.assessmentEntry?.address.location &&
      haversine_distance(
        [props.assessmentEntry?.address.location.lng, props.assessmentEntry?.address.location.lat],
        [poiData.lng, poiData.lat]
      )
    setPopup({ ...poiData, distance })
  }

  const openPrivatePoiMarkerDialog = (location: PoiMarkerType, poisToDisplay: PrivatePOIList) => {
    setLocationToShowPrivatePoiPopup(location)
    doUpdatePrivatePoisToShow(poisToDisplay, module)
  }

  const closePrivatePoiMarkerDialog = () => {
    setLocationToShowPrivatePoiPopup(undefined)
    doUpdatePrivatePoisToShow([], module)
  }

  const getMarkers = (filterFn: (p: [string, PlaceResultEncodable[]]) => boolean) =>
    [...pois]
      .filter((poi) => filterFn(poi))
      .map(([id, googlePOIs]) =>
        googlePOIs.map((poi) => {
          const lat = poi.geometry?.location?.lat
          const lng = poi.geometry?.location?.lng
          if (poi.place_id && lat && lng) {
            const markerProps = {
              key: poi.place_id,
              lat,
              lng,
              poiData: poi,
              onClick: onPOIClick,
              inside:
                poi.geometry?.location && props.isochronePolygon
                  ? google.maps.geometry.poly.containsLocation(poi.geometry?.location, props.isochronePolygon)
                  : true,
            }

            // TODO: New coloured icon for each category?
            return stringIsNumberedTextSearchId(id) && NUMBERED_TEXT_SEARCH_IDS_SET.has(id) ? (
              <TextSearchPOIMarker {...markerProps} textSearchId={id} />
            ) : (
              <POIMarker {...markerProps} />
            )
          } else return <></>
        })
      )

  const poisMarkers = getMarkers((poi) => props.poiCategories.has(poi[0]))

  const isAllDone = loadingPois.size < 1

  useEffect(() => {
    if (isAllDone && props.fitToBounds !== "none" && gmap) {
      const bounds = new google.maps.LatLngBounds()

      props.isochronePolygon?.getPath().forEach((latLng) => bounds.extend(latLng))
      if (props.fitToBounds === "all") {
        Array.from(pois.values())
          .flat()
          .forEach((poi) => {
            if (poi.geometry?.location) {
              bounds.extend(poi.geometry?.location)
            }
          })
      }
      if (props.assessmentEntry?.address.location) {
        bounds.extend(props.assessmentEntry?.address.location)
      }

      gmap.fitBounds(bounds, 50)
    }
  }, [isAllDone, props.isochronePolygon])

  return (
    <Grid columns={1} columnSpec={"1fr"} height={[100, "%"]}>
      <div id={"poi-explorer-google-map"} className={styles.mapContainer}>
        <GoogleMapReact
          onGoogleApiLoaded={({ map, maps }) => handleOnLoad(map, maps)}
          center={props.assessmentEntry?.address.location ?? centerBerlin}
          defaultZoom={initialZoom}
          onChange={({ zoom, bounds }) => {
            setZoom(zoom)
            setBounds(gmap?.getBounds())
          }}
          options={{
            mapTypeControl: props.showControls,
            rotateControl: props.showControls,
            streetViewControl: props.showControls,
            zoomControl: props.showControls,
            zoomControlOptions: { position: 3 },
            fullscreenControl: props.showControls,
            draggableCursor: "default",
            clickableIcons: !popup,
            disableDoubleClickZoom: isPopUpHovered,
          }}
          onClick={() => setPopup(undefined)}
        >
          {pois.size > 0 && poisMarkers}
          {props.assessmentEntry?.address.location && (
            <AssessmentEntryMarker
              key="entry-marker"
              lat={props.assessmentEntry.address.location.lat}
              lng={props.assessmentEntry.address.location.lng}
            />
          )}
          {popup && (
            <MapPopupWrapper
              key={`map-popup-wrapper-${popup.poiData.place_id}`}
              lat={popup.lat}
              lng={popup.lng}
              poiData={popup.poiData}
              distance={popup.distance}
            />
          )}
          {privateCategoryDataList.length > 0 &&
            bounds &&
            privateCategoryDataList.flatMap((categoryData, idx) => {
              const props = {
                categoryDataList: privateCategoryDataList,
                categoryData: categoryData,
                zoom: zoom,
                bounds: bounds,
                categories: selectedCategories,
                dataIndex: idx,
                openDialog: openPrivatePoiMarkerDialog,
              }
              return PrivatePoiClusters(props)
            })}
          {locationToShowPrivatePoiPopup && privatePoisToShow?.length > 0 && (
            <PoiMarkerDialog
              lat={locationToShowPrivatePoiPopup.lat}
              lng={locationToShowPrivatePoiPopup.lng}
              poisToShow={privatePoisToShow}
              onClose={closePrivatePoiMarkerDialog}
              onHover={setIsPopUpHovered}
              entryPinLocation={props.assessmentEntry?.address.location}
            />
          )}
        </GoogleMapReact>
        {showPrivatePOIPopup &&
          (isPrivateDataAccessible ? (
            <PrivateDataCategoryListPopup
              onClose={() => setShowPrivatePOIPopup(false)}
              categories={privatePoiCategories}
              module={module}
              selectedCategories={selectedCategories}
              updateCategories={doPrivateDataSelectedCategoriesUpdate}
              getPois={doPrivateCategoryPOIsGet}
              showCloseButton
            />
          ) : (
            <PrivateDataCategoryListPopupNotBooked onClose={() => setShowPrivatePOIPopup(false)} isContained={false} />
          ))}
        {showIsochronePopup && (
          <IsochronePopup
            assessmentEntry={props.assessmentEntry}
            isochrone={props.isochroneSettings}
            onChange={(field, value) => {
              props.setIsochroneSettings({ ...props.isochroneSettings, [field]: value })
            }}
            onClose={() => setShowIsochronePopup(false)}
            moduleName={"POI_EXPLORER"}
          />
        )}
        <div className={cx(styles.mapNotifierStyle, css({ maxHeight: !isAllDone ? "48px" : "0px" }))}>
          {!isAllDone && <LoadingSpinner size={32} />}
          <span style={{ padding: "8px" }}>Loading ...</span>
        </div>
      </div>
    </Grid>
  )
}
