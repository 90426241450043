import * as React from "react"
import { ComparablesTrend } from "./comparables-trend"
import {
  areaToBucketLabel,
  priceToBucketLabel,
  yearBucketToLabel,
  yearQuarterBucketToLabel,
} from "./comparables-distribution-graph"
import { translations } from "../../../i18n"
import { ComparablesDistribution } from "./comparables-distribution"
import { css } from "emotion"
import { calculateStep } from "./comparables-analysis-common"
import { DistributionGraphResult, PriceTrend } from "../../../../generated-apis/comparables-service"

type Props = {
  notEnoughOffers: boolean
  priceTrends: PriceTrend[]
  constructionYearDistribution: DistributionGraphResult | null
  priceDistribution: DistributionGraphResult | null
  areaDistribution: DistributionGraphResult | null
  offerYearDistribution: DistributionGraphResult | null
}

const mainGridStyle = css({
  padding: "16px",
  gridTemplateColumns: "repeat(1, 1fr)",
  display: "grid",
  gap: 20,
  backgroundColor: "white",
})

export const ComparablesAnalysis = ({
  notEnoughOffers,
  priceTrends,
  offerYearDistribution,
  constructionYearDistribution,
  areaDistribution,
  priceDistribution,
}: Props) => {
  const t = translations().assessmentComparables
  return (
    <div className={mainGridStyle}>
      <ComparablesTrend trendData={priceTrends} title={t.priceTrends} notEnoughOffers={notEnoughOffers} />
      <ComparablesDistribution
        distributionData={priceDistribution}
        title={t.priceDistribution}
        bucketSuffix={"EUR/m²"}
        showAnnotations={"all"}
        bucketToLabel={priceToBucketLabel}
        notEnoughOffers={notEnoughOffers}
      />
      <ComparablesDistribution
        distributionData={areaDistribution}
        title={t.areaDistribution}
        bucketSuffix={"m²"}
        showAnnotations={"all"}
        bucketToLabel={areaToBucketLabel}
        notEnoughOffers={notEnoughOffers}
      />
      <ComparablesDistribution
        distributionData={offerYearDistribution}
        title={t.offerYearDistribution}
        bucketToLabel={yearQuarterBucketToLabel}
        showAnnotations={"off"}
        isRangeBuckets={offerYearDistribution ? calculateStep(offerYearDistribution) != 0.25 : true}
        notEnoughOffers={notEnoughOffers}
      />
      <ComparablesDistribution
        distributionData={constructionYearDistribution}
        title={t.constructionYearDistribution}
        bucketToLabel={yearBucketToLabel}
        showAnnotations={"all"}
        isRangeBuckets={constructionYearDistribution ? calculateStep(constructionYearDistribution) != 1 : true}
        notEnoughOffers={notEnoughOffers}
        info={t.offersWithKnownConstructionYear}
      />
    </div>
  )
}
