import * as React from "react"
import { FC, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../relas/store"
import { useForm, useFormState } from "react-hook-form"
import { ratingStyles } from "../rating-styles"
import { ratingManagerActions } from "../../rating-manager-slice"
import { translations } from "../../i18n"
import { cx } from "emotion"
import { doCreateRating, doUpdateRating } from "../../rating-manager-actions"
import { RatingDetail } from "../../../explorer/models/rating"
import { nonEmptyTrimmed } from "../../../shared/models/form-validators"
import { MetaRating, RatingCreatePayload } from "../../../shared/models/ratings"
import Panel from "../../../shared/components/panel"
import Button from "../../../shared/components/button"
import TextField from "../../../shared/components/textfield"
import { GenericError } from "../../../shared/helper/axios"

type FormInputs = {
  title: string
}

export const RatingManagerDuplicateRenameForm: FC = ({}) => {
  const t = useMemo(() => translations(), [])

  const { currentRating, menuPane } = useAppSelector((state) => ({
    menuPane: state.ratingManager.menuPane,
    currentRating: state.ratingManager.currentRating,
  }))

  const defaultTitle = useMemo(() => {
    if (currentRating) {
      switch (menuPane) {
        case "duplicate":
          return `${currentRating.name} (copy)`
        case "rename":
          return currentRating.name
        default:
          console.error("RatingManagerDuplicateRenameForm: menuPane is not duplicate or rename")
      }
    }
    return ""
  }, [currentRating])

  const { setValue, handleSubmit, register, watch, control } = useForm<FormInputs>({
    defaultValues: {
      title: defaultTitle,
    },
  })

  const { isSubmitting, isValid } = useFormState({ control })

  const [submitError, setSubmitError] = useState<GenericError | undefined>(undefined)

  const dispatch = useAppDispatch()

  async function onSubmit(data: FormInputs) {
    if (currentRating) {
      let action: Promise<RatingDetail | MetaRating | GenericError>
      if (menuPane == "duplicate") {
        action = doCreateRating(dispatch)({
          ...currentRating,
          name: data.title,
        } as RatingCreatePayload)
      } else if (menuPane == "rename") {
        action = doUpdateRating(dispatch)({ ...currentRating, name: data.title })
      } else {
        console.error("RatingManagerDuplicateRenameForm: menuPane is not duplicate or rename")
        return
      }

      const result = await action
      if ("id" in result) {
        dispatch(ratingManagerActions.setMenuPane("list"))
        dispatch(ratingManagerActions.setCurrentRating(result))
      } else {
        setSubmitError(result)
      }
    }
  }

  return (
    <form
      className={ratingStyles.container}
      style={{ height: "100%" }}
      onSubmit={handleSubmit(onSubmit, (e, ev) => ev?.preventDefault())}
      onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
    >
      <div>
        <Button type="tertiary" icon="back" onClick={() => dispatch(ratingManagerActions.setMenuPane("list"))}>
          {t.allRatings}
        </Button>
      </div>
      <div>
        <h2>{t.createForm.formTitle}</h2>
      </div>
      <hr />
      <div className={cx(ratingStyles.fieldName, ratingStyles.noTopMargin)}>{t.createForm.titleField}</div>
      <TextField
        {...register("title", { validate: nonEmptyTrimmed })}
        onValueChange={(v) => setValue("title", v, { shouldValidate: true })}
        value={watch("title")}
      />
      {submitError && <Panel color="negative">{t.ratingAlreadyExists}</Panel>}
      <div className={ratingStyles.spacer}></div>
      <div className={ratingStyles.submitRow}>
        <Button type="primary" disabled={!isValid} loading={isSubmitting}>
          {menuPane === "duplicate" && t.listMenu.duplicate}
          {menuPane === "rename" && t.listMenu.rename}
        </Button>
      </div>
    </form>
  )
}
