import { css } from "emotion"
import { getThemeColorVar } from "../../helper/color"
import { Switch } from "@blueprintjs/core"
import React from "react"

type ValueWithLabel<X> = readonly [X, string]

type Props<L, R> = {
  selected: L | R
  onChange: (value: L | R) => void
  values: readonly [ValueWithLabel<L>, ValueWithLabel<R>]
}

const style = css`
  label.bp4-control.bp4-switch {
    input:checked ~ :hover {
      background-color: white;
    }
    input:checked ~ .bp4-control-indicator::before {
      left: calc(100% - 30px);
    }
    input:checked ~ .bp4-control-indicator {
      background-color: white;
      color: ${getThemeColorVar("typo", "default")};
    }

    > span.bp4-control-indicator {
      width: 80px;
      height: 30px;
      border-color: ${getThemeColorVar("primary", "default")};
      background-color: white;
      color: ${getThemeColorVar("typo", "default")};
      border: 1px solid ${getThemeColorVar("primary", "default")};
      align-content: center;

      ::before {
        box-shadow: none;
        background: ${getThemeColorVar("primary", "default")};
        height: 24px;
        width: 24px;
        margin: -2px -9px -2px 3px;

        ~ input:checked + & {
          left: calc(100% - 30px);
        }
      }
    }
  }
`

const ToggleSwitch = <L, R>({ selected, onChange, values }: Props<L, R>) => {
  const left = values[0][0]
  const right = values[1][0]

  return (
    <span className={style}>
      <Switch
        large
        innerLabel={values[0][1]}
        innerLabelChecked={values[1][1]}
        checked={selected === right}
        onChange={(value) => {
          onChange(value.target ? left : right)
        }}
        inline={true}
      />
    </span>
  )
}

export default ToggleSwitch
