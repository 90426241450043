import { PriceRange } from "../../models/assessment"
import { GraphScale } from "../../models/graph-scale"
import { IResizeEntry, ResizeSensor } from "@blueprintjs/core"
import * as React from "react"
import { FC, useState } from "react"
import { css } from "emotion"
import { COLORS } from "../../../shared/components/ui/colors"
import { ReactCanvas } from "../../../shared/components/ui/react-canvas"
import GridItem from "../../../shared/components/restyle-grid/griditem"
import Grid from "../../../shared/components/restyle-grid/grid"
import { formatNumber } from "../../../shared/helper/number-format"

const barGraphLabelTextClass = css({
  whiteSpace: "nowrap",
  fontWeight: "bold",
})

const svgWrapperClass = css({ overflow: "hidden" })

interface Props {
  label: string
  color: string
  data: PriceRange | undefined
  scaleForWidth: (width: number) => GraphScale
  background?: string
}

export const DashboardBarGraph: FC<Props> = ({
  label: labelName,
  color,
  data,
  scaleForWidth,
  background = COLORS.background.light,
}: Props) => {
  const [graphWidth, setGraphWidth] = useState(200)

  const scale = scaleForWidth(graphWidth)

  const onResize = (entries: IResizeEntry[]) => {
    if (entries.length > 0) setGraphWidth(entries[0].contentRect.width)
  }

  const formatPrice = (price: number | undefined): string => {
    if (price === undefined) return ""
    const decimalPlace = Math.floor(price).toString().length > 2 ? 0 : 2 // no decimal place for numbers greater than 99
    return formatNumber(price, decimalPlace).replace(/\s/g, "\u00A0")
  }

  const totalBarHeight = 16
  const columnSpec = `110px 45px 1fr 45px`

  return (
    <GridItem>
      <Grid columns={4} columnSpec={columnSpec} colGap={8}>
        <GridItem colSpan={2} />
        <GridItem colSpan={2}>
          <Grid columnSpec={`${scale.getPositionOnScale(data?.avg ?? 0) - 24}px 50px 1fr`}>
            <GridItem />
            <span style={{ fontSize: "14px", fontWeight: "bold" }}>{`Ø\u00A0${formatPrice(data?.avg)}`}</span>
            <GridItem />
          </Grid>
        </GridItem>

        <GridItem>
          <div className={barGraphLabelTextClass}>{labelName}</div>
        </GridItem>
        <GridItem justifySelf={"start"}>
          <span style={{ fontSize: "14px" }}>{formatPrice(data?.min)}</span>
        </GridItem>
        <ResizeSensor onResize={onResize}>
          <div className={css(svgWrapperClass)}>
            <ReactCanvas
              width={graphWidth}
              height={totalBarHeight}
              draw={(ctx) => {
                ctx.beginPath()
                ctx.rect(0, 4, graphWidth, totalBarHeight / 2)
                ctx.fillStyle = background || "white"
                ctx.fill()

                if (!data) return

                ctx.beginPath()
                ctx.rect(
                  scale.getPositionOnScale(data.min),
                  4,
                  scale.getPositionOnScale(data.max) - scale.getPositionOnScale(data.min),
                  totalBarHeight / 2
                )
                ctx.fillStyle = color
                ctx.fill()

                ctx.beginPath()
                ctx.rect(scale.getPositionOnScale(data.avg), 0, 4, totalBarHeight)
                ctx.fillStyle = "black"
                ctx.strokeStyle = "white"
                ctx.lineWidth = 2
                ctx.fill()
                ctx.stroke()
              }}
            />
          </div>
        </ResizeSensor>
        <GridItem justifySelf={"end"}>
          <span style={{ fontSize: "14px" }}>{formatPrice(data?.max)}</span>
        </GridItem>
      </Grid>
    </GridItem>
  )
}
