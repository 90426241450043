import { applyMiddleware, combineReducers } from "redux"
import { INITIAL_STATE as ASSESSMENT_INITIAL_STATE } from "../assessment/reducers/reducers"
import { State as AssessmentState } from "../assessment/reducers/state"
import {
  State as PrivateDataState,
  initialPrivateAppState as PRIVATE_DATA_INITIAL_STATE,
} from "../private-data/reducers/state"
import { router } from "./router"
import googleMapDataReducer from "../assessment/reducers/google-maps-slice"
import { trackAppSwitches } from "../utils/report-app-switch"
import {
  assessmentEntryChangedModuleAction,
  AssessmentEntryChangedPayload,
} from "../assessment/actions/assessment-module-action-creators"
import ratingManagerReducer, {
  initialState as ratingManagerInitialState,
  RatingManagerState,
} from "../ratings/rating-manager-slice"
import { useDispatch, useSelector } from "react-redux"
import { configureStore } from "@reduxjs/toolkit"
import {
  CompanyAdminState,
  INITIAL_STATE as COMPANY_ADMIN_INITIAL_STATE,
} from "../company-admin/reducers/company-admin-state"
import userProfileReducer, {
  initialState as userProfileInitialState,
  UserAccountState,
} from "../user-account/user-profile-slice"
import companyMembersListReducer from "../company-admin/reducers/memberslist-slice"
import companySettingsReducer from "../company-admin/reducers/settings-slice"
import invoiceReducer from "../company-admin/reducers/invoice-slice"
import companyApiReducer from "../company-admin/reducers/api-slice"
import locationSelectorReducer from "../locationselector/location-selector-slice"
import comparablesReducer from "../assessment/reducers/comparables-slice"
import assessmentReducer from "../assessment/reducers/assessment-slice"
import privateDataReducer from "../private-data/reducers/private-data-slice"
import marketDataReducer from "../assessment/reducers/market-data-slice"
import fundamentalDataReducer from "../assessment/reducers/fundamental-data-slice"
import userReducer, { getInitialUserState, UserState } from "./user-slice"
import profileReducer, { initialState as profileInitialState, State as ProfileState } from "../profile/profile-slice"
import rentIndexReducer from "../assessment/reducers/rentindex-slice"
import specialMapsAppReducer from "../assessment/reducers/special-maps-slice"
import poiExplorerReducer from "../assessment/reducers/poi-explorer-slice"
import navigationReducer from "../shared/reducers/navigation-slice"
import { LoginState, loginInitialState, loginReducer } from "../login/reducers/login-slice"
import { bindHistory } from "./routing"
import { initialState as registrationInitialState } from "../registration/reducers/registration-slice"
import registrationReducer from "../registration/reducers/registration-slice"
import { enableMapSet } from "immer"

export type GlobalState = AssessmentState &
  ProfileState & { user: UserState } & PrivateDataState & { ratingManager: RatingManagerState } & UserAccountState &
  CompanyAdminState & { registration: typeof registrationInitialState } & { login: LoginState }

function getGlobalInitialState(): GlobalState {
  return {
    ...ASSESSMENT_INITIAL_STATE,
    ...PRIVATE_DATA_INITIAL_STATE,
    ...COMPANY_ADMIN_INITIAL_STATE,
    profile: profileInitialState,
    user: getInitialUserState(),
    userProfile: userProfileInitialState,
    ratingManager: ratingManagerInitialState,
    registration: registrationInitialState,
    login: loginInitialState,
  }
}

const GLOBAL_INITIAL_STATE: GlobalState = getGlobalInitialState()

enableMapSet()

const rootReducer = combineReducers({
  navigation: navigationReducer,
  assessment: assessmentReducer,
  comparablesApp: comparablesReducer,
  marketDataApp: marketDataReducer,
  POIExplorerApp: poiExplorerReducer,
  specialMapsApp: specialMapsAppReducer,
  profile: profileReducer,
  user: userReducer,
  googleMapsData: googleMapDataReducer,
  rentindex: rentIndexReducer,
  privateData: privateDataReducer,
  ratingManager: ratingManagerReducer,
  locationSelector: locationSelectorReducer,
  userProfile: userProfileReducer,
  companyMembersList: companyMembersListReducer,
  companySettings: companySettingsReducer,
  invoiceState: invoiceReducer,
  apiState: companyApiReducer,
  login: loginReducer,
  registration: registrationReducer,
  fundamentalData: fundamentalDataReducer,
})

const RESET_STORE_ACTION = "RESET_GLOBAL_STORE"

export const store = configureStore({
  reducer: (state, action) => {
    if (action.type === RESET_STORE_ACTION) {
      return getGlobalInitialState()
    }
    return rootReducer(state, action)
  },
  preloadedState: GLOBAL_INITIAL_STATE,
  enhancers: [applyMiddleware()],
})

export function resetGlobalStore() {
  store.dispatch({ type: RESET_STORE_ACTION })
}

bindHistory(router)

trackAppSwitches(store)

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector = <T>(selector: (state: GlobalState) => T, equalityFn?: (left: T, right: T) => boolean) =>
  useSelector<GlobalState, T>(selector, equalityFn)

let lastKnownEntryId: string | undefined = undefined
store.subscribe(() => {
  const state = store.getState()

  if (lastKnownEntryId !== state.assessment.currentAssessmentEntry?.id) {
    const payload: AssessmentEntryChangedPayload = {
      newId: state.assessment.currentAssessmentEntry?.id,
      oldId: lastKnownEntryId,
      newAssessmentEntry: state.assessment.currentAssessmentEntry || undefined,
    }

    setTimeout(() => {
      store.dispatch(assessmentEntryChangedModuleAction(payload))
    }, 0)
  }
  lastKnownEntryId = state.assessment.currentAssessmentEntry?.id
})
