function _getExistingClasses(element: HTMLElement): string {
  const classes = (element.getAttribute("class") || "").replace(/[\n\t]/g, "")
  return " " + classes + " "
}

export function jqLiteAddClass(element: HTMLElement, cssClasses: string) {
  if (!cssClasses || !element.setAttribute) return

  let existingClasses = _getExistingClasses(element),
    splitClasses = cssClasses.split(" ")

  for (let i = 0; i < splitClasses.length; i++) {
    const cssClass: string = (splitClasses[i] || "").trim()
    if (existingClasses.indexOf(" " + cssClass + " ") === -1) {
      existingClasses += cssClass + " "
    }
  }

  element.setAttribute("class", existingClasses.trim())
}

export function jqLiteRemoveClass(element: HTMLElement, cssClasses: string) {
  if (!cssClasses || !element.setAttribute) return

  let existingClasses = _getExistingClasses(element),
    splitClasses = cssClasses.split(" ")

  for (let i = 0; i < splitClasses.length; i++) {
    const cssClass = (splitClasses[i] || "").trim()
    while (existingClasses.indexOf(" " + cssClass + " ") >= 0) {
      existingClasses = existingClasses.replace(" " + cssClass + " ", " ")
    }
  }

  element.setAttribute("class", existingClasses.trim())
}

export function jqLiteOffset(element: HTMLElement): {
  top: number
  left: number
  height: number
  width: number
} {
  const win = window
  const docEl = document.documentElement
  const rect = element.getBoundingClientRect()
  const scrollTop = docEl == null ? 0 : (win.pageYOffset || docEl.scrollTop) - (docEl.clientTop || 0)
  const scrollLeft = docEl == null ? 0 : (win.pageXOffset || docEl.scrollLeft) - (docEl.clientLeft || 0)

  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft,
    height: rect.height,
    width: rect.width,
  }
}
