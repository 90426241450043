import { Flex } from "./ui/flex"
import { Grid } from "./ui/grid"
import { language } from "../i18n/language"
import { MultiSlider } from "@blueprintjs/core"
import { css, cx } from "emotion"
import * as React from "react"
import { translations } from "../i18n"
import Axios, { AxiosProgressEvent } from "axios"
import { lanaApiUrl } from "../../app_config"
import { trackUsageEvent } from "../../utils/usage-tracking"
import { Address } from "../../assessment/models/address"
import { featureEnabled, QUICK_PROMPT_ENGINEERING } from "../../utils/features-toggle"
import { useAppSelector } from "../../relas/store"
import { Fragment } from "react"
import ButtonTabs from "./buttontabs"
import GenericErrorPanel from "./genericerrorpanel"
import Dialog from "./dialog"
import TextArea from "./textarea"
import Text from "../../shared/components/text"
import LoadingSpinner from "./loadingspinner"
import FlexContainer from "./restyle-grid/flexcontainer"
import Button from "./button"
import { getThemeColorVar } from "../helper/color"
import { GenericError } from "../helper/axios"

type Props = {
  onClose: () => void
  assessmentId: string
  assessmentEntryId: string
  assessmentEntryAddress: Address
}

const styles = {
  centerOverlayClass: css({
    verticalAlign: "middle",
    right: "25vw",
    pointerEvents: "auto",
    backgroundColor: getThemeColorVar("background", "lighter"),
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    height: "750px",
    gap: "10px",
  }),
  innerDiv: css({
    padding: "0 16px 16px 16px",
    width: "60vw",
  }),
  generatedTextBox: css({
    border: "1px solid #d9d9d9",
    padding: "12px",
    backgroundColor: getThemeColorVar("background", "default"),
    overflowY: "auto",
  }),
  inputLabel: css({
    fontWeight: "bold",
    paddingBottom: "6px",
    paddingTop: "24px",
    fontSize: "16px",
  }),
  centeredContainerCss: css({ display: "flex", alignItems: "center", height: "100%" }),
}

const customPromptPlaceholder = `Values for substitution:
- $language -> "DE" or "EN"
- $city -> name of city
- $zip -> zip code
- $length -> 1, 2 or 3 representing 100, 200, or 300 words
- \${state.<path.to.state.variable>} -> value of app state variable
`

type Lang = "DE" | "EN"
type Length = 1 | 2 | 3

type GeneratedTextResponse = {
  result?: string
}

const END_OF_STREAM = "[DONE]"

export const AITextGenerationOverlay: React.FC<Props> = (props) => {
  const currentLanguage = language()

  const displayPrompt = featureEnabled(QUICK_PROMPT_ENGINEERING)
  const quickPromptState = displayPrompt ? useAppSelector((state) => state) : null

  const [lang, setLang] = React.useState<Lang>(currentLanguage === "de" ? "DE" : "EN")
  const [length, setLength] = React.useState<Length>(2)
  const t = React.useMemo(() => translations().textGeneration, [currentLanguage])
  const [generatedText, setGeneratedText] = React.useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<GenericError | null>(null)
  const [customPrompt, setCustomPrompt] = React.useState<string | undefined>(undefined)
  const [disableButtons, setDisableButtons] = React.useState<boolean>(false)

  React.useEffect(() => {
    fetchMunicipalityDescription().catch((e) => setError(e))
  }, [])

  async function fetchMunicipalityDescription(): Promise<void> {
    setIsLoading(true)
    setError(null)

    try {
      const response = await Axios.get<GeneratedTextResponse>(
        `${lanaApiUrl}/api/assessments/${props.assessmentId}/entries/${props.assessmentEntryId}/municipality_desc`,
        { withCredentials: true }
      )
      setGeneratedText(response.data.result)
    } catch (e) {
      setError(e)
    } finally {
      setIsLoading(false)
    }
  }

  function generateMunicipalityDescription() {
    trackUsageEvent("AI_TEXT_LOCATION", props.assessmentEntryAddress, `language: ${lang}; length: ${length}`)

    setDisableButtons(true)
    setError(null)
    setGeneratedText(undefined)

    const interpolatedPrompt = customPrompt ? putStateValuesIntoPrompt(customPrompt) : undefined

    void Axios.post(
      `${lanaApiUrl}/api/assessments/${props.assessmentId}/entries/${props.assessmentEntryId}/municipality_desc`,
      { lang: lang, length: length, customPrompt: interpolatedPrompt },
      {
        responseType: "stream",
        onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
          if ((progressEvent.event.currentTarget.response as string).endsWith(END_OF_STREAM)) {
            setGeneratedText((progressEvent.event.currentTarget.response as string).slice(0, -END_OF_STREAM.length))
            setDisableButtons(false)
          } else {
            setGeneratedText(progressEvent.event.currentTarget.response)
          }
        },
        withCredentials: true,
      }
    ).catch((e) => setError(e))
  }

  function putStateValuesIntoPrompt(prompt: string): string {
    const regex = /\${state\.([^\$]*)}/g
    if (!quickPromptState) {
      return ""
    }

    return prompt.replace(regex, (_, args) => {
      const path = args.split(".") as string[]

      try {
        const stateValue = path.reduce((acc: any, curr) => {
          return acc[curr]
        }, quickPromptState as any)

        return `${stateValue}` ?? ""
      } catch (e) {
        console.log(`Could not match state variable "state.${path.join(".")}"`)
        return ""
      }
    })
  }

  async function deleteMunicipalityDescription() {
    setError(null)

    await Axios.delete(
      `${lanaApiUrl}/api/assessments/${props.assessmentId}/entries/${props.assessmentEntryId}/municipality_desc`,
      { withCredentials: true }
    )
  }

  const makeParagraphs = (text: string) => (
    <>
      {text.split("\n").map((line, i) => {
        return (
          <Fragment key={i}>
            <Text>{line}</Text>
          </Fragment>
        )
      })}
    </>
  )

  const textBoxContent: JSX.Element = (() => {
    if (isLoading) {
      return (
        <div className={styles.centeredContainerCss}>
          <LoadingSpinner />
        </div>
      )
    }
    if (error) {
      return <GenericErrorPanel error={error} />
    }
    return makeParagraphs(generatedText ?? "")
  })()

  return (
    <Dialog title={t.header} onClose={() => props.onClose()} closeOnClickOutside={true} closeButton={true}>
      <div className={styles.centerOverlayClass}>
        <div className={styles.innerDiv}>
          <Grid gap={16} height={[750, "px"]} padding={12} columnSpec={"2fr 1fr"}>
            <>
              <div
                className={cx(styles.generatedTextBox, displayPrompt && css({ height: "75%" }), "generated-content")}
              >
                {textBoxContent}
              </div>
              {displayPrompt && (
                <FlexContainer height={"25%"} direction="column" md-justify="end">
                  <TextArea
                    hint={customPromptPlaceholder}
                    rows={6}
                    value={customPrompt ?? ""}
                    onValueChange={(v) => setCustomPrompt(v ? v : undefined)}
                    resize={false}
                  />
                </FlexContainer>
              )}
            </>

            <Flex flexDirection="column" gap={8}>
              <Text fontWeight="bold">{t.descriptionTitle}</Text>
              <br />
              {makeParagraphs(t.newFeatureDescription)}
              <div className={styles.inputLabel}>{t.language}</div>
              <ButtonTabs<Lang>
                values={["DE", "EN"]}
                translate={(x) => (x === "DE" ? t.german : t.english)}
                selected={lang}
                onSelect={setLang}
              />

              <div className={styles.inputLabel}>{t.length}</div>
              <div style={{ padding: "0 12px 0 12px" }}>
                <MultiSlider
                  min={1}
                  max={3}
                  stepSize={1}
                  labelRenderer={(x) => (x === 1 ? t.short : x === 2 ? t.medium : t.long)}
                >
                  <MultiSlider.Handle
                    value={length}
                    onChange={(x) => {
                      if (x < 2) {
                        setLength(1)
                      } else if (x > 2) {
                        setLength(3)
                      } else {
                        setLength(2)
                      }
                    }}
                  />
                </MultiSlider>
              </div>

              <FlexContainer direction="column" gap={8} md-justify="end">
                <Button
                  disabled={disableButtons}
                  type="primary"
                  size="normal"
                  onClick={generateMunicipalityDescription}
                >
                  {t.generateText}
                </Button>

                <Grid columnSpec={"1fr 1fr"} gap={8}>
                  <Button
                    disabled={disableButtons}
                    type="secondary"
                    size="normal"
                    onClick={() => generatedText && navigator.clipboard.writeText(generatedText)}
                  >
                    {t.copy}
                  </Button>
                  <Button
                    disabled={disableButtons}
                    type="secondary"
                    size="normal"
                    onClick={() => {
                      deleteMunicipalityDescription().catch(() => {})
                      setGeneratedText("")
                    }}
                  >
                    {t.clear}
                  </Button>
                </Grid>
              </FlexContainer>
            </Flex>
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}
