import * as React from "react"
import { useMemo, useState } from "react"
import { useAppDispatch } from "../../../relas/store"
import { Flex } from "../../../shared/components/ui/flex"
import { FlexItem } from "../../../shared/components/ui/flex-item"
import { translations } from "../../i18n"
import { RatingDetail, RatingSelection } from "../../../explorer/models/rating"
import { doUpdateRating } from "../../rating-manager-actions"
import Select from "../../../shared/components/select"
import Button from "../../../shared/components/button"
import TextField from "../../../shared/components/textfield"

type Props = {
  onClose: () => void
  onCreate: (ratingSelection: RatingSelection) => void
  currentRating?: RatingDetail
}

export const RatingManagerRatingSelectionCreate: React.FC<Props> = ({ onClose, onCreate, currentRating }) => {
  const t = useMemo(translations, [translations])

  const dispatch = useAppDispatch()

  const [title, setTitle] = useState("")
  const [gradeIdx, setGradeIdx] = useState(0)

  async function onOk() {
    if (!currentRating) return

    let ratingRule: RatingSelection

    switch (currentRating.dataSetType) {
      case "macro":
        ratingRule = {
          title,
          gradeIdx,
          rules: [
            {
              type: "loadmacrodata",
              country: "de",
            },
          ],
        }
        break
      case "micro":
        ratingRule = {
          title,
          gradeIdx,
          rules: [
            {
              type: "loadmicrodata",
              exampleAgsRefResLoc: "11000000",
            },
          ],
        }
        break
    }

    ratingRule.title = ratingRule.title.trim()

    const createdRating = await doUpdateRating(dispatch)({
      ...currentRating,
      rules: [...currentRating.rules, ratingRule],
    })

    if ("rules" in createdRating && createdRating.rules.length > 0) {
      onCreate(createdRating.rules[createdRating.rules.length - 1])
    } else {
      onClose()
    }
  }

  if (!currentRating) {
    onClose()
    return null
  }

  return (
    <Flex flexDirection="column" height={[100, "%"]} gap={8} padding={16}>
      <Flex flexDirection="row">
        <FlexItem flexGrow={1} />
        <Button type="tertiary" icon="close" onClick={onClose} />
      </Flex>
      <h1>{t.addSelectionsRule}</h1>
      <TextField
        label={t.createForm.titleField}
        size="small"
        value={title}
        onValueChange={(title) => setTitle(title)}
      />
      <Select
        label={t.ratingGradeLabel}
        size="small"
        value={gradeIdx.toString()}
        onValueChange={(gradeIdx) => setGradeIdx(parseInt(gradeIdx))}
        options={currentRating.grades.map((grade, idx) => ({ label: grade.label, value: idx.toString() }))}
      />
      <FlexItem flexGrow={1} />
      <Flex flexDirection="row-reverse">
        <Button type="primary" size="small" disabled={title.trim().length === 0} onClick={onOk}>
          {t.ok}
        </Button>
      </Flex>
    </Flex>
  )
}
