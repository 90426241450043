import * as React from "react"
import { OpenLayersAbstractProps, OpenLayersMap } from "../../../shared/components/openlayers-map"
import { css, cx } from "emotion"
import { Grid } from "../../../shared/components/ui/grid"
import * as layer from "ol/layer"
import { Geometry, Point } from "ol/geom"
import * as ol from "ol"
import * as style from "ol/style"
import * as source from "ol/source"
import { reportAssetsUrl } from "../../../reports/report-config"
import { municipalitySource } from "../district-data"
import { bind } from "decko"
import * as extent from "ol/extent"
import RenderFeature from "ol/render/Feature"
import {
  DataLevel,
  ViewportSettings,
  centreBerlinCoordinate,
  updateSelectedTileIds,
  updateViewportSettings,
} from "../../reducers/fundamental-data-slice"
import { fromLonLat } from "ol/proj"
import { Location } from "../../models/address"
import { Coordinate } from "ol/coordinate"
import { germanStatesVectorTileOptions, postcodeVectorTileOptions } from "../../../utils/openlayers"
import { FullScreen, Zoom } from "ol/control"
import {
  MapStyle,
  MapStyleControl,
  getInitialMapStyle,
  persistMapStyle,
  setMapUrlBasedOnMapStyle,
} from "../../../shared/components/map-style-control"
import { PrivatePoiControl } from "../../../shared/components/map-private-pois-control"
import { AllowedModulesEnum, PrivatePOIList } from "../../../private-data/models/private-data"
import { ConnectedProps, connect } from "react-redux"
import { GlobalState } from "../../../relas/store"
import {
  fetchCategories,
  fetchCategoryPois,
  updatePOIsToShow,
  updateSelectedCategories,
} from "../../../private-data/reducers/private-data-slice"
import { IsochroneControl } from "../../../shared/components/map-isochrone-control"
import { IsochroneType } from "../isochrone-type"
import { getIsochronePolygon, toOlFeatureFromGooglePolygon } from "../../../utils/here-maps-isochrone"
import { Stroke } from "ol/style"
import { JSXContent, PrivatePoiOverlay } from "../../../shared/components/PrivatePoiOverlay"
import { PoiMarkerDialog } from "../../../shared/components/privatedata-poi-marker-dialog"
import { areMultiplePrivatePoisListsDifferent, arePoisToShowDifferent, updateClusters } from "../../../utils/utils"
import { FeatureLike } from "ol/Feature"
import { getThemeColor, getThemeColorVar } from "../../../shared/helper/color"

const MARKER_LAYER = "MARKER_LAYER"
const ISOCHRONE_LAYER = "ISOCHRONE_LAYER"

const styles = {
  mapNotifierStyle: css({
    backgroundColor: getThemeColorVar("background", "lighter"),
    width: "100%",
    position: "absolute",
    zIndex: 1000,
    bottom: 0,
    transition: "max-height 1s",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.9,
    overflow: "hidden",
  }),
  mapClass: css({
    height: "100%",
    position: "relative",
    backgroundColor: "rgb(229, 227, 223)",
  }),
  controlClass: (top: string) =>
    css({
      right: ".5em",
      top: top,
    }),
}

// style for postcodesLayer & municipalitiesLayer defined in component as they interact with its state
const postcodesLayer = new layer.VectorTile({
  declutter: true,
  source: new source.VectorTile(postcodeVectorTileOptions()),
})

const municipalitiesLayer = new layer.VectorTile({
  declutter: true,
  source: municipalitySource,
})

const countryLayer = new layer.VectorTile({
  declutter: true,
  source: new source.VectorTile(germanStatesVectorTileOptions()),
  style: new style.Style({
    fill: new style.Fill({
      color: rgbToRgba(getThemeColor("primary", "default").toString(), 0.75),
    }),
  }),
})

const makeAssessmentEntryMarker = (location: Location) => {
  const feature = new ol.Feature({
    geometry: new Point(fromLonLat([location.lng, location.lat])),
  })
  feature.setId(MARKER_LAYER)

  const markerLayer = new layer.Vector({
    source: new source.Vector({
      features: [feature],
    }),
    style: new style.Style({
      image: new style.Icon({
        src: `${reportAssetsUrl ?? ""}/assets/marker.svg`,
        size: [42, 60],
        anchorOrigin: "top-left",
        anchor: [0.5, 1],
      }),
    }),
    zIndex: 100,
  })
  return markerLayer
}

function rgbToRgba(color: string, opacity: number): string {
  return color.replace(/rgb/i, "rgba").replace(/\)/i, `,${opacity})`)
}

function isPrivatePOILayer(feature: FeatureLike): boolean {
  return feature.getProperties().features && feature.getProperties().geometry
}

type FundamentalDataMapProps = {
  markerLocation: Location | undefined
  dataLevel: DataLevel
  viewportSettings: ViewportSettings
  selectedTileIds: string[]
  assessmentEntryId: string | undefined
  initialMapStyle?: MapStyle
  isochroneSettings: IsochroneType
  setIsochroneSettings: (changedSettings: IsochroneType) => void
} & OpenLayersAbstractProps

type State = {
  hoveredTile: string | undefined
  hoverRegionName: string | undefined
  selectedMapStyle: MapStyle
  showPrivatePoisPopover: boolean
  showIsochronePopover: boolean
  poiPopUpPosition: [number, number] | undefined
}

const mapState = (state: GlobalState) => ({
  isPrivateDataAccessible: state.user.scopes.privateData,
  privatePoiCategories: state.privateData.privatePOICategories,
  privateDataSettings: state.privateData.modulesWithPrivateData.fundamentalData,
})

const connector = connect(mapState, {})

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = FundamentalDataMapProps & PropsFromRedux

class FundamentalDataMapImpl extends OpenLayersMap<Props, State> {
  private postcodesLayer: layer.VectorTile | undefined = undefined
  private municipalitiesLayer: layer.VectorTile | undefined = undefined
  private countryLayer: layer.VectorTile | undefined = undefined
  private privatePoiControl: PrivatePoiControl | undefined = undefined
  private isochroneLayer: layer.Vector<any> | undefined = undefined
  private poiPopUp: PrivatePoiOverlay | undefined = undefined
  private clusterLayerArray: layer.Vector<any>[] = []

  protected onMoveEnd(): void {}

  constructor(props: Props) {
    super(props)
    this.state = {
      hoveredTile: undefined,
      hoverRegionName: undefined,
      selectedMapStyle: getInitialMapStyle(props.initialMapStyle),
      showPrivatePoisPopover: false,
      showIsochronePopover: false,
      poiPopUpPosition: undefined,
    }
  }

  protected containerClass(): string {
    return styles.mapClass
  }

  async componentDidMount() {
    super.componentDidMount()
    void fetchCategories()

    if (this.props.markerLocation) {
      const markerLayer = makeAssessmentEntryMarker(this.props.markerLocation)
      this.getMap().addLayer(markerLayer)
    }

    let viewportCentre: Coordinate
    const viewpointFromSettings =
      this.props.viewportSettings[this.props.dataLevel] &&
      this.props.viewportSettings[this.props.dataLevel].viewportCentre

    if (viewpointFromSettings) {
      viewportCentre = viewpointFromSettings
    } else if (this.props.markerLocation) {
      viewportCentre = fromLonLat([this.props.markerLocation.lng, this.props.markerLocation.lat])
    } else {
      // default to Berlin but should never happen as assessmentEntry should have a location if it's gotten here
      viewportCentre = centreBerlinCoordinate
    }

    if (viewportCentre) {
      this.getMap().getView().setCenter(viewportCentre)
    }

    this.setMapPositionAndZoom()
    this.getMap().getControls().clear()

    this.getMap().addControl(new FullScreen({ className: `${styles.controlClass("3.5em")}` }))
    this.getMap().addControl(new Zoom({ className: `${styles.controlClass("6em")}` }))
    this.getMap().addControl(
      new MapStyleControl(
        this.state.selectedMapStyle,
        this.onChangeSelectedMapStyle,
        this.mapContainer.current || undefined
      )
    )

    this.addOrRefreshPrivatePoi()
    this.getMap().addControl(
      new IsochroneControl(
        "fundamentalData",
        this.props.isochroneSettings,
        this.props.setIsochroneSettings,
        this.state.showIsochronePopover,
        this.onChangeShowIsochrone,
        this.mapContainer.current || undefined
      )
    )

    this.poiPopUp = new PrivatePoiOverlay(this.getMap(), this.poiPopUpContentCreator)

    postcodesLayer.setStyle(this.tileStyle)
    municipalitiesLayer.setStyle(this.tileStyle)

    this.setMap()

    this.getMap().on("pointermove", this.onPointerMove)
    this.getMap().on("click", this.onMapClick)
    this.getMap().on("rendercomplete", this.onChangeViewport)

    await this.updateIsochrone()
    updateClusters(
      this.getMap(),
      this.clusterLayerArray,
      this.setClusterLayerArray,
      this.props.privateDataSettings.multipleCategoryPOIList,
      this.props.privatePoiCategories,
      this.props.isPrivateDataAccessible
    )
  }

  async componentDidUpdate(prevProps: Props, prevState: State) {
    super.componentDidUpdate(prevProps, prevState)
    let updatedActiveLayer = false

    this.addOrRefreshPrivatePoi()
    if (
      areMultiplePrivatePoisListsDifferent(
        prevProps.privateDataSettings.multipleCategoryPOIList,
        this.props.privateDataSettings.multipleCategoryPOIList
      )
    ) {
      updateClusters(
        this.getMap(),
        this.clusterLayerArray,
        this.setClusterLayerArray,
        this.props.privateDataSettings.multipleCategoryPOIList,
        this.props.privatePoiCategories,
        this.props.isPrivateDataAccessible
      )
    }

    if (this.props.dataLevel !== prevProps.dataLevel) {
      this.cleanHoverRegion()
      this.setMap()
      updatedActiveLayer = true

      this.setMapPositionAndZoom()

      if (this.props.viewportSettings[this.props.dataLevel].viewportCentre !== undefined) {
        this.getMap().getView().setCenter(this.props.viewportSettings[this.props.dataLevel].viewportCentre)
      }
    }

    if (
      this.props.markerLocation !== prevProps.markerLocation ||
      this.props.isochroneSettings !== prevProps.isochroneSettings
    ) {
      await this.updateIsochrone()
    }

    if (arePoisToShowDifferent(prevProps.privateDataSettings.poisToShow, this.props.privateDataSettings.poisToShow)) {
      this.updatePoiPopUp()
    }

    if (this.props.selectedTileIds !== prevProps.selectedTileIds && !updatedActiveLayer) {
      this.activeLayer()?.changed()
    }

    const currentViewportSettings = this.props.viewportSettings[this.props.dataLevel]
    const prevViewportSettings = prevProps.viewportSettings[prevProps.dataLevel]

    if (
      currentViewportSettings.pinnedTileExtent !== undefined &&
      prevViewportSettings.pinnedTileExtent === undefined &&
      !currentViewportSettings.movedByUser
    ) {
      const mapExtent = extent.createEmpty()
      extent.extend(mapExtent, currentViewportSettings.pinnedTileExtent)

      this.getMap()
        .getView()
        .fit(mapExtent, {
          duration: 250,
          size: this.getMap().getSize(),
          padding: [100, 100, 100, 100],
        })

      this.onChangeViewport()
    }
  }

  @bind
  onChangeSelectedMapStyle(style: MapStyle) {
    this.setState({ selectedMapStyle: style })
    setMapUrlBasedOnMapStyle(this.getMap(), style)
    persistMapStyle(style)
  }

  @bind
  private setMapPositionAndZoom() {
    if (
      this.props.viewportSettings[this.props.dataLevel] &&
      !this.props.viewportSettings[this.props.dataLevel].movedByUser
    ) {
      this.fitMapToExtent()
    } else {
      this.props.viewportSettings[this.props.dataLevel] &&
        this.getMap().getView().setZoom(this.props.viewportSettings[this.props.dataLevel].zoomLevel)
    }
  }

  @bind
  private fitMapToExtent() {
    const markerLocation = this.props.markerLocation
    if (!markerLocation) return

    let mapExtent = extent.createEmpty()
    const pinnedTileExtent = this.props.viewportSettings[this.props.dataLevel].pinnedTileExtent

    if (pinnedTileExtent) {
      extent.extend(mapExtent, pinnedTileExtent)
    }

    if (!extent.isEmpty(mapExtent)) {
      const viewExtent = this.getMap().getView().calculateExtent(this.getMap().getSize())

      if (!extent.containsExtent(viewExtent, mapExtent)) {
        this.getMap()
          .getView()
          .fit(mapExtent, {
            duration: 250,
            size: this.getMap().getSize(),
            padding: [100, 50, 100, 50],
          })

        this.onChangeViewport()
      }
    }
  }

  private setMap() {
    if (this.props.dataLevel === "municipality" && !this.municipalitiesLayer) {
      this.municipalitiesLayer = municipalitiesLayer
      this.getMap().addLayer(this.municipalitiesLayer)
    } else if (this.props.dataLevel === "zip" && !this.postcodesLayer) {
      this.postcodesLayer = postcodesLayer
      this.getMap().addLayer(this.postcodesLayer)
    } else if (this.props.dataLevel === "country" && !this.countryLayer) {
      this.countryLayer = countryLayer
      this.getMap().addLayer(this.countryLayer)
    }

    this.municipalitiesLayer?.setVisible(this.props.dataLevel === "municipality")
    this.postcodesLayer?.setVisible(this.props.dataLevel === "zip")
    this.countryLayer?.setVisible(this.props.dataLevel === "country")

    this.activeLayer()?.changed()
  }

  @bind
  private activeLayer() {
    switch (this.props.dataLevel) {
      case "municipality":
        return this.municipalitiesLayer
      case "zip":
        return this.postcodesLayer
      case "country":
        return this.countryLayer
    }
  }

  @bind
  onMapClick(event: ol.MapBrowserEvent<any>) {
    const activeLayer = this.activeLayer()
    if (!activeLayer && this.clusterLayerArray.length === 0) return

    const features = this.getMap().getFeaturesAtPixel(event.pixel, {
      layerFilter: (layer) => layer === activeLayer || layer.getClassName().includes("cluster-layer"),
    })

    if (features.length > 0) {
      if (isPrivatePOILayer(features[0])) {
        const featuresArray: ol.Feature[] = features[0].getProperties().features
        const geometry = features[0].getProperties().geometry

        const categoryId = featuresArray[0].getId()?.toString().split(":")[1] ?? ""
        const poiIdsToShow: string[] = featuresArray.map((feature) => feature.getId()?.toString().split(":")[2] ?? "")
        const poisToShow: PrivatePOIList = (
          this.props.privateDataSettings.multipleCategoryPOIList[categoryId] ?? []
        ).filter((poi) => poiIdsToShow.includes(poi.id))
        const position = geometry.getCoordinates()
        this.setState({ ...this.state, poiPopUpPosition: position })
        updatePOIsToShow(poisToShow, AllowedModulesEnum.FUNDAMENTAL_DATA)
      } else if (this.props.assessmentEntryId) {
        const id = features[0].getId()?.toString()
        updateSelectedTileIds(this.props.assessmentEntryId, this.props.dataLevel, id, true)
      }
    }
  }

  @bind
  onPointerMove(event: ol.MapBrowserEvent<any>) {
    const activeLayer = this.activeLayer()
    if (!activeLayer) return

    const features = this.getMap().getFeaturesAtPixel(event.pixel, {
      layerFilter: (layer) => layer === activeLayer,
    })
    const coordinate = this.getMap().getCoordinateFromPixel(event.pixel)

    if (features.length < 1 || !extent.containsCoordinate(features[0].getGeometry()?.getExtent() ?? [], coordinate)) {
      this.cleanHoverRegion()
      return
    }

    const id = features[0].getId()?.toString()

    if (id !== this.state.hoveredTile && id !== undefined) {
      let name: string
      switch (this.props.dataLevel) {
        case "municipality":
          name = features[0].getProperties()["GEN"]
          break
        case "zip":
          name = id
          break
        case "country":
          name = "Deutschland"
          break
      }

      this.setState({ hoveredTile: id, hoverRegionName: name }, () => {
        this.activeLayer()?.changed()
      })
    }
  }

  private addOrRefreshPrivatePoi() {
    if (!this.privatePoiControl) {
      this.privatePoiControl = new PrivatePoiControl(
        AllowedModulesEnum.FUNDAMENTAL_DATA,
        this.props.privatePoiCategories,
        this.props.privateDataSettings.selectedCategories,
        updateSelectedCategories,
        fetchCategoryPois,
        this.state.showPrivatePoisPopover,
        this.onChangeShowPrivatePoisPopover,
        this.props.isPrivateDataAccessible
      )
      this.getMap().addControl(this.privatePoiControl)
    } else {
      this.privatePoiControl?.refreshPopover(
        this.props.privatePoiCategories,
        this.props.privateDataSettings.selectedCategories,
        this.state.showPrivatePoisPopover
      )
    }
  }

  @bind
  onChangeShowIsochrone(value: boolean) {
    this.setState({
      ...this.state,
      showPrivatePoisPopover: value ? false : this.state.showPrivatePoisPopover,
      showIsochronePopover: value,
    })
  }

  @bind
  onChangeShowPrivatePoisPopover(value: boolean) {
    this.setState({
      ...this.state,
      showIsochronePopover: value ? false : this.state.showIsochronePopover,
      showPrivatePoisPopover: value,
    })
  }

  @bind
  private cleanHoverRegion() {
    this.setState({ hoveredTile: undefined, hoverRegionName: undefined })
  }

  @bind
  private tileStyle(feature: ol.Feature<Geometry> | RenderFeature): style.Style {
    const id = feature.getId()?.toString()

    // selected style takes precedence over hovered style
    if (id !== undefined && this.props.selectedTileIds.includes(id)) {
      return new style.Style({
        fill: new style.Fill({
          color: rgbToRgba(`${getThemeColor("primary", "default")}`, 0.75),
        }),
        stroke: new style.Stroke({
          width: 3,
          color: rgbToRgba(`${getThemeColor("primary", "dark")}`, 1),
        }),
      })
    }

    if (this.state.hoveredTile === id) {
      return new style.Style({
        fill: new style.Fill({
          color: "rgba(1,1,1,0.4)",
        }),
      })
    }

    return new style.Style({
      fill: new style.Fill({
        color: "rgba(1,0,0,0)", // transparent, but essential for layer.getFeatures to work
      }),
    })
  }

  @bind
  private onChangeViewport() {
    const viewportSettings: ViewportSettings = {
      ...this.props.viewportSettings,
      [this.props.dataLevel]: {
        zoomLevel: this.getMap().getView().getZoom(),
        viewportCentre: this.getMap().getView().getCenter(),
        movedByUser: true,
      },
    }
    this.props.assessmentEntryId && updateViewportSettings(this.props.assessmentEntryId, viewportSettings)
  }

  private async updateIsochrone() {
    if (this.props.markerLocation && this.props.isochroneSettings.mode !== "none") {
      const isochronePolygon = await getIsochronePolygon(this.props.markerLocation, this.props.isochroneSettings)
      const feature = toOlFeatureFromGooglePolygon(isochronePolygon)
      if (!this.isochroneLayer) {
        feature.setId(ISOCHRONE_LAYER)
        this.isochroneLayer = new layer.Vector({
          source: new source.Vector({
            features: [feature],
          }),
          style: new style.Style({
            stroke: new Stroke({
              color: rgbToRgba(`${getThemeColor("primary", "default")}`, 1),
              width: 4,
            }),
          }),
        })
        this.getMap().addLayer(this.isochroneLayer)
      } else {
        this.isochroneLayer.setSource(
          new source.Vector({
            features: [feature],
          })
        )
      }
    } else {
      if (this.isochroneLayer) {
        this.getMap().removeLayer(this.isochroneLayer)
        this.isochroneLayer = undefined
      }
    }
  }

  @bind
  private updatePoiPopUp() {
    if (this.props.isPrivateDataAccessible) {
      if (this.props.privateDataSettings.poisToShow.length > 0 && this.state.poiPopUpPosition) {
        this.poiPopUp?.openOverlayAt(this.state.poiPopUpPosition, this.props.privateDataSettings.poisToShow)
      } else if (this.poiPopUp) {
        this.poiPopUp.closeCurrentOverlay()
      }
    } else if (this.poiPopUp) {
      this.poiPopUp.closeCurrentOverlay()
    }
  }

  @bind onPoiPopUpClose() {
    this.poiPopUp?.closeCurrentOverlay()
    updatePOIsToShow([], AllowedModulesEnum.FUNDAMENTAL_DATA)
    this.setState({ ...this.state, poiPopUpPosition: undefined })
  }

  @bind
  private poiPopUpContentCreator(location: number[], poiList: PrivatePOIList): JSXContent {
    const props = {
      lat: location[1],
      lng: location[0],
      poisToShow: poiList,
      onClose: this.onPoiPopUpClose,
    }

    return {
      content: <PoiMarkerDialog {...props} entryPinLocation={this.props.markerLocation} />,
    }
  }

  @bind
  private setClusterLayerArray(newClusterArrayLayer: layer.Vector<any>[]) {
    this.clusterLayerArray = [...newClusterArrayLayer]
  }

  render() {
    return (
      <Grid columns={1} rowSpec="1fr" height={[100, "%"]}>
        <div className={styles.mapClass}>
          {super.render()}
          <div className={cx(styles.mapNotifierStyle, css({ maxHeight: this.state.hoverRegionName ? "48px" : "0px" }))}>
            <span style={{ padding: "8px" }}>{this.state.hoverRegionName}</span>
          </div>
        </div>
      </Grid>
    )
  }
}

export const FundamentalDataMap = connector(FundamentalDataMapImpl)
