import Color from "color"
import { ColorType, RevoColor } from "../util/colorconstants"
import { isNullOrUndefined } from "./utils"

interface UnsafeColorConfig<T extends RevoColor> {
  color: T
  colorType: ColorType<T>
}

export type DistributedColorValue<T extends RevoColor> = T extends T ? UnsafeColorConfig<T> : never
export type ColorConfig = DistributedColorValue<RevoColor>

function getThemeColorRef<C extends RevoColor>(color: C, colorType: ColorType<C>): string {
  return typeof colorType === "undefined" ? `--revo-color-${color}` : `--revo-color-${color}-${colorType}`
}

export function getThemeColorVar<C extends RevoColor>(color: C, colorType: ColorType<C>): string {
  return `var(${getThemeColorRef(color, colorType)})`
}

export function getThemeColor<C extends RevoColor>(color: C, colorType: ColorType<C>): Color {
  const cssVariableName = getThemeColorRef(color, colorType)
  const style = getComputedStyle(document.body)

  const maybevalue = style.getPropertyValue(cssVariableName)

  if (isNullOrUndefined(maybevalue) || maybevalue == "") {
    console.error(`Color --revo-color-${color}-${colorType} was not found in getThemeColor.`)
    throw Error(`Color --revo-color-${color}-${colorType} was not found.`)
  }

  return Color(maybevalue.trim())
}
