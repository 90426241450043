import Axios, { AxiosResponse } from "axios"
import { lanaApiUrl } from "../../app_config"
import { DataSetType } from "../models/smartdata"

export interface FieldOption {
  key: string
  label: string
}

const fieldOptionCache = new Map<string, FieldOption[]>()

export function getFieldOptions(scoreKey: DataSetType, field: string): Promise<FieldOption[]> {
  const cacheKey = `${scoreKey}-${field}`
  const cached = fieldOptionCache.get(cacheKey)

  if (cached) return Promise.resolve(cached)

  return Axios.get(`${lanaApiUrl}/api/data/${encodeURIComponent(scoreKey)}/${encodeURIComponent(field)}/options`).then(
    (success: AxiosResponse) => {
      fieldOptionCache.set(cacheKey, success.data)
      return success.data
    }
  )
}
