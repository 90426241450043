import React, { useEffect } from "react"
import { translations } from "../../i18n"
import { DataSetType, getScoreSetByDataSetType } from "../../../shared/models/smartdata"
import { css, cx } from "emotion"
import DashboardWidget from "./dashboard-widget"
import { LanaNode } from "../../../shared/smartdata-products/smartdata"
import { Grid } from "../../../shared/components/ui/grid"
import { useAppSelector } from "../../../relas/store"
import Axios from "axios"
import { ContextScoreValues } from "../../models/assessment"
import { lanaApiUrl } from "../../../app_config"
import { SelectionRef } from "../../models/selection-ref"
import { DashboardEditMode } from "./dashboard-widget-grid"

interface Props {
  header: string
  defaultData?: { [source: string]: number }
  widgetScores?: Array<string>
  selectionContext?: string
  dataSetType: DataSetType
  onHeaderClick: () => void
  onConfigClick?: () => void
  onRemoveClick?: () => void
  printMode: boolean
  configurable?: boolean
  editMode: DashboardEditMode
  onRenderingDone: () => void
  entryId: string
  assessmentId: string
}

const styles = {
  scoresGrid: css({
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
  }),
  scale: css({
    padding: "4px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  }),
  contentStyle: css({
    padding: "4px 8px",
  }),
  scoreBottomRuler: css({
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    position: "sticky",
    bottom: 0,
    padding: "2px 10px 0 10px",
    borderTop: "1px solid #ddd",
    backgroundColor: "#fff",
  }),
  scoreRowName: css({
    lineHeight: "20px",
    margin: "0 0 8px 0",
  }),
  contentArea: css({
    padding: "0 10px 0 10px",
    marginTop: "4px",
  }),
}

export const defaultMicroScores = new Set([
  "score_v2_connect",
  "score_v1_family",
  "score_v1_medical",
  "score_v1_nature",
  "score_v2_supermarkt",
  "score_v2_income_kgs22",
  "score_v2_pfi",
])
export const defaultMacroScores = new Set([
  "score_pop_per_sqkm_sum_00",
  "score_mobile_mean_4G",
  "score_svb_commuter_net",
  "score_gr_8y_rent_office",
  "score_gr_2y_rent_retail",
  "score_haushalte_WHN_EIG_rate_00",
  "score_vacancy_rate",
])
export const ScoresWidget = ({
  defaultData,
  widgetScores,
  dataSetType,
  header,
  onHeaderClick,
  onConfigClick,
  onRemoveClick,
  printMode,
  editMode,
  configurable,
  onRenderingDone,
  selectionContext,
  assessmentId,
  entryId,
}: Props) => {
  const t = React.useMemo(translations, [translations])

  const widgetScoresSet = new Set(widgetScores)

  const selectedContextRef = useAppSelector((state) =>
    selectionContext && state.assessment.availableMacroContexts
      ? state.assessment.availableMacroContexts.find((context) => context.id === selectionContext)
      : undefined
  )

  const [scores, setScores] = React.useState<LanaNode[]>()
  const [data, setData] = React.useState<{ [source: string]: number }>()
  const [selectionNotInContext, setSelectionNotInContext] = React.useState(false)

  const getSortedScores = (data: { [source: string]: number }, scoreSet: Set<String>) =>
    getScoreSetByDataSetType(dataSetType)
      .filter((score) => scoreSet.has(score.scoreName) && data && data[score.scoreName] !== undefined)
      .sort((a, b) => (data ? data[b.scoreName] - data[a.scoreName] : 0))

  const querySelectionContext = async (selection: SelectionRef) => {
    try {
      const result = await Axios.get<ContextScoreValues>(
        `${lanaApiUrl}/api/assessments/${encodeURIComponent(assessmentId)}/entries/${encodeURIComponent(
          entryId
        )}/macroValues`,
        {
          params: {
            scores: (widgetScores || []).join(","),
            macroContext: selectionContext,
          },
        }
      )

      if (result.data && !result.data.insideContext) {
        setSelectionNotInContext(true)
        return
      }

      setData(result.data.values)
      setScores(getSortedScores(result.data.values, new Set(Object.keys(result.data.values))))
    } catch (e) {
      setSelectionNotInContext(true)
    }
  }

  useEffect(() => {
    if (selectionContext && selectedContextRef && dataSetType === "macro") {
      void querySelectionContext(selectedContextRef)
    } else {
      setSelectionNotInContext(false)
      setData(defaultData || {})
      setScores(widgetScores ? getSortedScores(defaultData || {}, widgetScoresSet) : [])
    }
  }, [selectionContext, selectedContextRef, dataSetType, defaultData, widgetScores])

  const renderScoreRow = (scoreRow: LanaNode, index: number) => (
    <div key={`row-${index}`}>
      {data && (
        <div
          className={cx(
            css({
              backgroundPosition: `${Math.round(data[scoreRow.scoreName])}%`,
            }),
            data[scoreRow.scoreName] < 50 ? "background-score-under50" : "background-score-over50"
          )}
        >
          <div
            className={cx(styles.scoreRowName)}
            title={t.pickTranslation(scoreRow.title) + " : " + Math.round(data[scoreRow.scoreName])}
          >
            {t.pickTranslation(scoreRow.title)}
          </div>
        </div>
      )}
    </div>
  )

  onRenderingDone()

  const headerTitle = header + (selectedContextRef ? ` (${selectedContextRef.name})` : "")

  return (
    <DashboardWidget
      header={headerTitle}
      onHeaderClick={onHeaderClick}
      htmlId={"dashboard-widget-scores"}
      onConfigClick={onConfigClick}
      onRemoveClick={onRemoveClick}
      printMode={printMode}
      configurable={configurable}
      editMode={editMode}
    >
      <Grid rowGap={0} rowSpec="1fr min-content" columns={1} columnSpec={"1fr"} height={[100, "%"]}>
        {selectionNotInContext ? (
          <div className={styles.contentArea}>{t.assessmentEntryDetails.notInMacroContext}</div>
        ) : (
          scores &&
          data && (
            <>
              <div className={styles.contentArea}>{scores.map(renderScoreRow)}</div>
              <div className={styles.scoreBottomRuler}>
                <div>
                  <div style={{ background: "rgba(255,255,255, 0.3)" }}>
                    <div className={styles.scale}>
                      <div style={{ padding: "0 4px" }}>0</div>
                      <div style={{ padding: "0 4px" }}>25</div>
                      {/*It's a trick to align the number 50 exactly in the middle in between two grids*/}
                      <div>5</div>
                    </div>
                  </div>
                </div>
                <div>
                  <div style={{ background: "rgba(255,255,255, 0.3)" }}>
                    <div className={styles.scale}>
                      <div>0</div>
                      <div>75</div>
                      <div>100</div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        )}
      </Grid>
    </DashboardWidget>
  )
}
