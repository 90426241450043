import { RatingManagerTranslations } from "./translations"
import { SHARED_EN } from "../../shared/i18n/en"
import * as React from "react"

export const RATING_MANAGER_EN: RatingManagerTranslations = {
  ...SHARED_EN,
  rating: "Rating",
  listMenu: {
    delete: "Delete",
    duplicate: "Duplicate",
    edit: "Edit",
    rename: "Rename",
  },
  createNew: "Create Rating",
  edit: "Edit Rating",
  loadingResultData: "Loading rating results...",
  ratingGradeLabel: "Grade",
  showListToggle: "Show List",
  preview: "Preview",
  municipalitiesTab: {
    population: "Residents",
    header: "Municipalities",
    download: "Download",
  },
  allRatings: "All Ratings",
  context: "Context",
  createForm: {
    formTitle: "New Rating",
    titleField: "Title",
    gradesField: "Grades",
    append: "Add grade",
    typeField: "Type",
    standardType: "Standard (Fixed grades)",
    metaType: "Meta Rating (Calculated grades)",
    valueField: "Value",
    metaGrades: {
      label: "Color Ranges",
      from: "From (>)",
      to: "To (<=)",
      addRange: "Add range",
    },
  },
  editForm: {
    menuItem: "Edit",
    createRuleButton: "Create new rule",
    loadMicroDataExample: "Example",
    editRuleMenuItem: "Edit",
    deleteButtonLabel: "Delete",
    deleteRuleTitle: "Delete Rule",
    deleteRuleConfirm: (name: string) => `Do you really want to delete rule '${name}'?`,
    deleteSelectionTitle: "Delete Selection",
    deleteSelectionConfirm: (name: string) => `Do you really want to delete selection '${name}'?`,
    test: "Test",
    gradeField: "Grade",
  },
  calculate: "Calculate",
  weight: "Weight",
  selectionRuleEdit: {
    editSelectionTitle: "Edit Selection",
    selectionRulesHeader: "Rules",
    addSelectionRule: "Add rule",
    createTitle: "New Rule",
    editTitle: "Edit Rule",
    ruleTypeLabel: "Rule",
    ruleCategoryLabel: "Category",
    inclusiveExclusiveHeader: "Type",
    inclusiveLabel: "Inclusive",
    exclusiveLabel: "Exclusive",
    rangeHeader: "Range",
    selectRank: "Select",
    selectRankPercentage: "Percentage",
    selectRankAbsolute: "Absolute",
    selectRankTop: "Top",
    selectRankBottom: "Bottom",
    rescaleRank: "Score values",
    rescaleRankNo: "Absolute values",
    rescaleRankYes: "Relative values",
    municipalityTagsPlaceholder: "Enter municipality",
    options: "Options",
    helpText: (
      <>
        <p>Depending on the rule, one or more of the following options can be set:</p>
        <p>
          <strong>Inclusive / Exclusive</strong>
          <br />
          Determines if matching municipalities should be included in the selection or excluded from the selection.
        </p>

        <p>
          <strong>Raw data values from/to</strong>
          <br />
          These values can be just counts (e.g. from 1 to 5) or have a unit (e.g. "%" or "Inhabitants per km²"). If you
          don't set a value, "min to max" is assumed.
        </p>

        <p>
          <strong>Absolute / Relative </strong>
          <br />
          Scores always go from 0 to 100 and cover a certain reference area. The absolute macro scores are based on
          whole Germany. Relative macro scores are recalculated based on the current selection (e.g. only municipalities
          in Bavaria instead of all municipalities in whole Germany). Micro scores are handled analogously.
        </p>
        <p>
          <strong>Score/profile values from/to</strong>
          <br />
          The scores always go from 0-100 and can be filtered accordingly (e.g. only scores from 80-100).
        </p>
      </>
    ),
  },
  duplicateForm: {
    duplicateRuleTitle: "Duplicate Selection",
    duplicateRuleSuffix: "Copy",
  },
  ratingAlreadyExists: "A rating with this name already exists",
  addSelectionsRule: "Add Selection",
  selectionsHeader: "Selections",
  deleteDialog: {
    menuItem: "Delete",
    titleRating: "Delete Rating",
    confirmRating: (name: string) => `Do you really want to delete the rating '${name}'?`,
    titleSelection: "Delete Selection",
    confirmSelection: (name: string) => `Do you really want to delete the selection '${name}'?`,
    ratingCannotBeDeleted: "Rating can not be deleted",
    ratingCannotBeDeletedText: (name: string) => `${name} is used in the following meta ratings:`,
  },
  lockAlert: {
    lockConfirm:
      "You can lock a rating to protect it. Your colleagues can still use it, but they can not edit or delete it.",
    unlockConfirm: "If you unlock your rating, your colleagues will be able to edit and delete it.",
    unlockTitle: "Unlock Rating",
    title: "Lock Rating",
  },
  metaMapInfo: "Start typing your address and then select from the list. Or just click in the map.",
  nothingSelected: "Click on a rating to see more details",
  availableGrades: "This rating uses the following grades:",
  ratingExplanation: {
    textHead: "The grade",
    textTail: "was calculated for this address based on the following rules and facts:",
    metaText: "The final grade for this address was calculated based on the following ratings and their grades:",
    detailsHeader: "Details",
    ruleHeader: "Rule",
    valueHeader: "Value",
    resultLabel: "Result",
    noResult: "For this rating, no grade is given at this location.",
    noFactsHead: "The default grade",
    noFactsTail: "was calculated for this address because no other rule applied.",
  },
  relative: "Relative",
  absolute: "Absolute",
  percentage: "Percentage",
  mapRecalculationMessage:
    'The map may not show the same results as the table. Please click "Recalculate" to get the latest results.',
}
