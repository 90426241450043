import * as React from "react"
import { Address, formatAddress } from "../models/address"
import { bind } from "decko"
import { connect } from "react-redux"
import { State } from "../reducers/state"
import { AbstractProps, AbstractState, AddressDialog, INITIAL_STATE } from "./address-dialog"
import { createSingleAssessment } from "../reducers/assessment-slice-functions"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import Panel from "../../shared/components/panel"
import TextField from "../../shared/components/textfield"

const endsWithCountryCode = /,\s\w\w$/

export const createAssessmentTitle = (address: Address) => {
  const addressTitle = formatAddress(address)

  if (endsWithCountryCode.test(addressTitle)) {
    return addressTitle.slice(0, addressTitle.length - 4)
  }

  return addressTitle
}

const mapStateToProps = (state: State) => ({
  assessmentCreateError: state.assessment.assessmentCreateError,
  assessmentCreateInProgress: state.assessment.assessmentCreateInProgress,
})

export interface OwnProps extends AbstractProps {}

type Props = OwnProps & ReturnType<typeof mapStateToProps>

interface OwnState extends AbstractState {
  assessmentTitle: string
}

class AssessmentCreateSingleImpl extends AddressDialog<Props, OwnState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      ...INITIAL_STATE,
      assessmentTitle: "",
    }
  }

  protected isLoading(): boolean {
    return this.props.assessmentCreateInProgress
  }

  protected renderAbove(): JSX.Element | null {
    return (
      <>
        <h2>{this.t.assessment.enterSingleAddress}</h2>
        <TextField
          label={this.t.assessment.title}
          value={this.state.assessmentTitle}
          onValueChange={(assessmentTitle) => this.setState({ assessmentTitle })}
        />
      </>
    )
  }

  protected renderBelow(): JSX.Element | null {
    const { assessmentCreateError } = this.props

    if (!assessmentCreateError) return null

    if (assessmentCreateError.error === "NoSmartdata")
      return <Panel color="negative">{this.t.assessmentAddAddress.errorNoSmartdata}</Panel>
    return <GenericErrorPanel error={assessmentCreateError} />
  }

  protected okButtonLabel(): string {
    return this.t.assessmentAddAddress.createButton
  }

  @bind
  protected onOk(): void {
    const { addressSelection, assessmentTitle, droppedLocation } = this.state

    if (!addressSelection || !addressSelection.location) return

    const entryLabel = formatAddress(addressSelection)
    const title = assessmentTitle.trim().length === 0 ? createAssessmentTitle(addressSelection) : assessmentTitle

    void createSingleAssessment(
      {
        title,
        entryLabel,
        address: addressSelection,
        droppedLocation,
      },
      this.props.module,
      "assessmentList"
    )
  }
}

export const AssessmentCreateSingle = connect(mapStateToProps, {})(AssessmentCreateSingleImpl)
