import React, { PropsWithChildren } from "react"

type Props = PropsWithChildren<{
  title: String
}>

export const PriceWidgetHeader = ({ title, children }: Props) => {
  return (
    <div style={{ color: "black" }}>
      {title}
      {children}
    </div>
  )
}
