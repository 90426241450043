import * as React from "react"
import { css, cx } from "emotion"
import FlexContainer from "./restyle-grid/flexcontainer"
import { getThemeColorVar } from "../helper/color"
import { EmotionCSS } from "../helper/responsive"

export type LocalNavProps<T extends string> = {
  id?: string
  values: T[]
  selected: T
  onSelect?: (selection: T) => void
  translate?: (item: T) => string
}

const localNavClass = css({
  userSelect: "none",

  backgroundColor: getThemeColorVar("background", "light"),
  height: "64px",
  paddingLeft: "40px",
  whiteSpace: "nowrap",
} as EmotionCSS)

const localNavButtonClass = css({
  fontSize: "16px",
  paddingLeft: "32px",
  paddingRight: "32px",
  color: getThemeColorVar("typo", "default"),
  cursor: "pointer",
} as EmotionCSS)

const localNavSelectedClass = css({
  backgroundColor: getThemeColorVar("white", undefined),
  cursor: "inherit",
  color: getThemeColorVar("primary", "default"),
} as EmotionCSS)

export class LocalNav<T extends string> extends React.Component<LocalNavProps<T>, {}> {
  render() {
    return (
      <div className={cx(localNavClass)}>
        <FlexContainer direction="column">
          <FlexContainer direction="row">
            {this.props.values.map((value) => {
              const classes = [localNavButtonClass]
              if (value === this.props.selected) {
                classes.push(localNavSelectedClass)
              }
              const translatedValue = this.props.translate ? this.props.translate(value) : value
              return (
                <div
                  key={value}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    this.props.onSelect && this.props.onSelect(value)
                  }}
                  className={cx(classes)}
                >
                  <FlexContainer direction="column" md-justify="center">
                    {translatedValue}
                  </FlexContainer>
                </div>
              )
            })}
          </FlexContainer>
        </FlexContainer>
      </div>
    )
  }
}

export default LocalNav
