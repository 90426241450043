import * as React from "react"
import { PropsWithChildren } from "react"

type Props = {
  onMouseEnter: () => void
  onMouseLeave: () => void
}

export const RevoMenuItemGroup = (props: PropsWithChildren<Props>) => {
  return (
    <div
      className="revoMenu__group"
      onMouseEnter={() => props.onMouseEnter()}
      onMouseLeave={() => props.onMouseLeave()}
    >
      {props.children}
    </div>
  )
}

export default RevoMenuItemGroup
