import * as React from "react"
import { Tooltip } from "./ui/tooltip"
import { css } from "emotion"

export interface Props {
  grade: {
    label: string
    color?: string
  }
  padding?: string
  responsiveWidth?: boolean
  additionalClass?: string
}

const styles = {
  container: css({
    lineHeight: "18px",
    fontSize: "14px",
    height: "24px",
    minWidth: "24px",
    borderRadius: "12px",
    textAlign: "center",
    color: "white",
    whiteSpace: "nowrap",
    wordBreak: "break-all",
  }),
  tooltip: css({
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
    maxWidth: "120px",
    padding: "0 5px 0 5px",
  }),
}

export const ExplorerRatingGradeLabel = (props: Props) => {
  const { grade, padding, responsiveWidth } = props

  const paddingDefault = padding || (grade.label.length > 1 ? "2px 0.5em" : "2px")
  const lable = <div className={styles.tooltip}>{grade.label}</div>
  return (
    <div
      className={props.additionalClass ? `${styles.container} ${props.additionalClass}` : styles.container}
      style={{
        padding: paddingDefault,
        backgroundColor: grade.color,
        maxWidth: `${responsiveWidth ? "" : "150px"}`,
        color: grade.color === undefined ? "black" : undefined,
        border: grade.color === undefined ? "1px solid black" : undefined,
      }}
    >
      {grade.label.length > 14 ? (
        <Tooltip placement="auto-start" tooltip={grade.label} withPointer={false}>
          {lable}
        </Tooltip>
      ) : (
        lable
      )}
    </div>
  )
}
