import { default as React } from "react"
import { XlWidget, XlWidgetProps } from "./xlwidget"
import Select, { Option, OptionGroup } from "./select"

export interface SelectXlWidgetProps {
  options: (Option<string> | OptionGroup<string>)[]
}

export class SelectXlWidget extends XlWidget<SelectXlWidgetProps> {
  constructor(props: SelectXlWidgetProps & XlWidgetProps<string>) {
    super(props)
  }

  renderInner() {
    return (
      <Select
        ref={(el) => (this.inputWrapper = el)}
        disabled={this.props.saveInProgress || !!this.props.disabled}
        required={this.props.required}
        options={this.props.options}
        onKeyDown={this.handleKeyPress}
        onFocus={(e) => this.edit()}
        onValueChange={this.editValueUpdate}
        value={this.currentValue()}
        size={this.props.size}
      />
    )
  }
}

export default SelectXlWidget
