import { css } from "emotion"
import { Card } from "@blueprintjs/core"
import { BorderBottom } from "./ui/border-bottom"
import { Flex } from "./ui/flex"
import { Grid } from "./ui/grid"
import { language } from "../i18n/language"
import * as React from "react"
import { translations } from "../i18n"
import GridItem from "./restyle-grid/griditem"
import Button from "./button"
import Icon from "./icon"

const styles = {
  card: (isContained?: boolean) =>
    css({
      position: "absolute",
      top: isContained ? "0px" : undefined,
      bottom: isContained ? undefined : "50px",
      right: isContained ? "50px" : "100px",
      width: "450px",
      fontSize: "14px",
      cursor: "default",
      padding: 0,
    }),
}

export const PrivateDataCategoryListPopupNotBooked = (props: { onClose: () => void; isContained: boolean }) => {
  const t = React.useMemo(translations, [translations])
  return (
    <Card elevation={2} className={styles.card(props.isContained)}>
      <BorderBottom>
        <Flex flexDirection={"row"} padding={16}>
          <h3 style={{ flexGrow: 1 }}>{t.privatePOICategories}</h3>
          <div onClick={props.onClose} style={{ cursor: "pointer" }}>
            <Icon name={"close"} color={"primary"} colorType={"default"} fontSize={18} />
          </div>
        </Flex>
      </BorderBottom>
      <Grid columns={1} gap={16} padding={32}>
        <GridItem>
          {language() === "en" ? (
            <>
              <p>Add you own data to this map!</p>
              <p>
                With <i>Private POIs</i>, you can upload addresses into our system and supplement them with any
                structured information (e.g. area, price, CO2 emission, etc.). These can then be displayed on all maps
                and linked to our data.
              </p>
            </>
          ) : (
            <>
              <p>Lassen Sie sich auf dieser Karte auch Ihre eigenen Daten anzeigen!</p>
              <p>
                Mit Private POIs können Sie Adressen in unser System einspielen und durch beliebige strukturierte Infos
                ergänzen (z. B. Fläche, Preis, CO2-Ausstoß etc.). Diese können dann auf allen Karten angezeigt und mit
                unseren Daten verknüpft werden.
              </p>
            </>
          )}
        </GridItem>
        <GridItem>
          <div style={{ textAlign: "center" }}>
            <Button type={"primary"} href={"/privatedatanotbooked"}>
              {language() === "en" ? "Learn more..." : "Mehr erfahren..."}
            </Button>
          </div>
        </GridItem>
      </Grid>
    </Card>
  )
}
