import * as React from "react"
import { useMemo } from "react"
import { Cell, Column, IColumnProps, RegionCardinality, Table } from "@blueprintjs/table"
import { PriceTrend } from "../../../../generated-apis/comparables-service"
import { translations } from "../../../../reports/i18n"
import { cellClass, objectColumnHeader } from "./comparables-analysis-common"
import { language } from "../../../../shared/language"

type Props = {
  trendData: PriceTrend[]
  middleParameter: "median" | "average"
  topParameter: "seventyFifth" | "ninetieth"
  bottomParameter: "tenth" | "twentyFifth"
}

const lang = language()

function dataForCell(trendData: PriceTrend, parameter: keyof PriceTrend): string | number {
  switch (parameter) {
    case "offerDateYear":
    case "offerDateQuarter":
      return `Q${trendData.offerDateQuarter}\xa0${trendData.offerDateYear}`
    case "offers":
      return trendData.offers
    case "average":
    case "median":
    case "tenth":
    case "twentyFifth":
    case "seventyFifth":
    case "ninetieth":
    case "minimum":
    case "maximum":
      return `${(Math.round(trendData[parameter] * 100) / 100).toLocaleString(lang, {
        minimumFractionDigits: 2,
      })}\xa0EUR/m²`
  }
}

export const ComparablesTrendTable = ({ trendData, middleParameter, bottomParameter, topParameter }: Props) => {
  const t = useMemo(() => {
    return translations().comparablesGraphs
  }, [translations])

  const cell = (field: keyof PriceTrend) => (row: number) =>
    <Cell className={cellClass}>{dataForCell(trendData[row], field)}</Cell>

  const columnRenderer = (field: keyof PriceTrend, headerTitle: string) => {
    return (
      <Column
        key={field}
        name={headerTitle}
        columnHeaderCellRenderer={objectColumnHeader(headerTitle)}
        cellRenderer={cell(field)}
      />
    )
  }

  const columns: Array<React.ReactElement<IColumnProps>> = useMemo(() => {
    return [
      columnRenderer("offerDateQuarter", t.quarter),
      columnRenderer(middleParameter, t[middleParameter]),
      columnRenderer("offers", t.offers),
      columnRenderer("minimum", t.minimum),
      columnRenderer(bottomParameter, bottomParameter === "tenth" ? "10%" : "25%"),
      columnRenderer(topParameter, topParameter === "ninetieth" ? "90%" : "75%"),
      columnRenderer("maximum", t.maximum),
    ]
  }, [trendData, middleParameter, bottomParameter, topParameter])

  return (
    <Table
      numRows={trendData.length}
      getCellClipboardData={(rowIdx, colIdx) => {
        return dataForCell(trendData[rowIdx], columns[colIdx].key as keyof PriceTrend)
      }}
      minRowHeight={24}
      defaultRowHeight={24}
      enableRowHeader={false}
      selectionModes={[RegionCardinality.CELLS]}
    >
      {columns}
    </Table>
  )
}
