import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react"
import DashboardWidget from "./dashboard-widget"
import { translations } from "../../i18n"
import { css } from "emotion"
import { Classes } from "@blueprintjs/core"
import { POISelectionMode } from "../../models/AssessmentDashboard"
import { GoogleMapsPOICategory } from "../../models/assessment"
import { Location } from "../../models/address"
import Axios from "axios"
import { lanaApiUrl } from "../../../app_config"
import PlaceResult = google.maps.places.PlaceResult
import PlacesServiceStatus = google.maps.places.PlacesServiceStatus
import PlaceSearchPagination = google.maps.places.PlaceSearchPagination
import { HereCombinedResponse, IsoLine } from "../../../shared/models/poi-explorer"
import { useAppSelector } from "../../../relas/store"
import { fetchGoogleMapsCategories } from "../../reducers/google-maps-slice"
import { DashboardEditMode } from "./dashboard-widget-grid"

export type POICategoriesType = (typeof aggregatedPOIsCategories)[number]

export type POIsSelectionSettings = {
  selectedPOICategories: Array<string>
  selectionMode: POISelectionMode
}

type AggregatedPOICategories = {
  [key in POICategoriesType]: string[]
}
export const defaultAggregatedPOICategories: AggregatedPOICategories = {
  eatAndDrink: ["cafe", "restaurant", "bar", "meal_takeaway"],
  healthCare: ["dentist", "doctor", "hospital", "pharmacy", "physiotherapist"],
  schools: ["primary_school", "school", "secondary_school"],
  shopping: [
    "clothing_store",
    "convenience_store",
    "department_store",
    "drugstore",
    "furniture_store",
    "home_goods_store",
    "shoe_store",
    "shopping_mall",
    "store",
    "supermarket",
    "book_store",
  ],
  culture: ["art_gallery", "library", "museum"],
  postOfficeBank: ["atm", "bank", "post_office"],
  parking: ["parking"],
}

export const aggregatedPOIsCategories = [
  "eatAndDrink",
  "healthCare",
  "schools",
  "shopping",
  "culture",
  "postOfficeBank",
  "parking",
]

export const defaultPOIsSettings: POIsSelectionSettings = {
  selectedPOICategories: Object.entries(defaultAggregatedPOICategories).reduce(
    (acc: string[], curVal) => acc.concat(curVal[1]),
    []
  ),
  selectionMode: "defaults",
}

type POIWidgetData = {
  counts: Map<POICategoriesType, number>
}
interface Props {
  selectionMode: POISelectionMode
  onHeaderClick: () => void
  configurable: boolean
  onConfigClick: () => void
  onRemoveClick: () => void
  printMode: boolean
  editMode: DashboardEditMode
  selectedPOICategories?: Array<string>
  markerLocationLat?: number
  markerLocationLng?: number
  onRenderingDone: () => void
}

const styles = {
  contentStyle: css({
    padding: "0 8px",
  }),
  poisGrid: css({
    display: "grid",
    padding: "2px 4px",
    columnGapgap: "4px",
    rowGap: "3px",
    gridTemplateColumns: "minmax(100px, 3fr) min-content",
  }),
}

const POIsWidget = ({
  selectionMode,
  onHeaderClick,
  configurable,
  onConfigClick,
  onRemoveClick,
  printMode,
  editMode,
  selectedPOICategories,
  markerLocationLat,
  markerLocationLng,
  onRenderingDone,
}: Props) => {
  const t = React.useMemo(translations, [translations])

  const [isochrone, setIsochrone] = useState<IsoLine>()
  const [isochronePolygon, setIsochronePolygon] = useState<google.maps.Polygon>()

  const [poiWidgetsLoading, setPoiWidgetsLoading] = useState(false)
  const [poisWidgetData, setPoisWidgetData] = useState<POIWidgetData | { counts: Map<string, number> }>({
    counts: new Map(),
  })
  const selectedPOIData = { counts: new Map(poisWidgetData.counts) }

  const googleMapsData = useAppSelector((state) => state.googleMapsData)

  const googleMapsCategories = googleMapsData.poiCategories
    ? googleMapsData.poiCategories.map(([, v]) => v).reduce((flatten, arr) => [...flatten, ...arr])
    : []

  const delayedRequestsCount = useRef(0)

  const googlePlacesService = useMemo(() => {
    if (document.getElementById("POIwidget")) {
      return new google.maps.places.PlacesService(document.getElementById("POIwidget") as HTMLDivElement)
    }
    return undefined
  }, [document.getElementById("POIwidget")])

  useEffect(() => {
    if (!googleMapsData.poiCategoriesLoadError && !googleMapsData.poiCategories) {
      void fetchGoogleMapsCategories()
    }
  }, [])

  const getPOIsCount = (
    selectedPOIscategories: string[],
    isochronePolygon: google.maps.Polygon,
    poiSelectionMode: POISelectionMode
  ) => {
    const categoryMap = new Map<POICategoriesType, number>()
    selectedPOIscategories.forEach((cat) => categoryMap.set(cat, 0))

    setPoisWidgetData({ counts: categoryMap })

    const loadingDataMap = new Map<POICategoriesType, boolean>()
    selectedPOIscategories.forEach((cat) => loadingDataMap.set(cat, false))
    if (selectedPOIscategories.length > 0) setPoiWidgetsLoading(true)

    //  we save the request page number and Pagination object for every category to use in a retry logic in case of the rate limit error
    const loadingCategoryPage = new Map<POICategoriesType, number>()
    selectedPOIscategories.forEach((cat) => loadingCategoryPage.set(cat, 0))

    const loadingCategoryPagination = new Map<POICategoriesType, PlaceSearchPagination | null>()
    selectedPOIscategories.forEach((cat) => loadingCategoryPagination.set(cat, null))

    const fetchPOIs = (categoryName: string) => {
      if (markerLocationLat && markerLocationLng)
        googlePlacesService?.nearbySearch(
          {
            location: { lat: markerLocationLat, lng: markerLocationLng },
            rankBy: google.maps.places.RankBy.DISTANCE,
            types: poiSelectionMode === "defaults" ? defaultAggregatedPOICategories[categoryName] : [categoryName], // types field no longer supported in Google Places API, but still works
          } as any,
          (results, status, pagination) => placesCallback(results ?? [], status, pagination, categoryName)
        )
    }

    const onDone = (category: POICategoriesType) => {
      loadingDataMap.set(category, true)
      const isAllDone = [...loadingDataMap.values()].every((i) => i)
      if (isAllDone) {
        setPoisWidgetData({
          counts: new Map(categoryMap),
        })

        setPoiWidgetsLoading(false)

        setTimeout(() => {
          // once POIs are all downloaded, we allow time for the component to render
          onRenderingDone()
        }, 1000)
      }
    }

    const placesCallback = (
      results: PlaceResult[],
      status: PlacesServiceStatus,
      pagination: PlaceSearchPagination | null,
      category: POICategoriesType
    ) => {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        let sum = 0
        results.forEach((poi) => {
          if (
            poi.geometry?.location &&
            isochronePolygon &&
            google.maps.geometry.poly.containsLocation(poi.geometry?.location, isochronePolygon)
          ) {
            sum++
          }
        })
        categoryMap.set(category, (categoryMap.get(category) || 0) + sum)

        if (pagination?.hasNextPage) {
          pagination.nextPage()
          loadingCategoryPagination.set(category, pagination)
          loadingCategoryPage.set(category, (loadingCategoryPage.get(category) || 0) + 1)
        } else onDone(category)
      } else if (
        status == google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT &&
        markerLocationLng &&
        markerLocationLat
      ) {
        delayedRequestsCount.current++
        setTimeout(() => {
          if (loadingCategoryPage.get(category) === 0) {
            loadingCategoryPagination.set(category, null)
            fetchPOIs(category)
          } else if (pagination?.hasNextPage) {
            loadingCategoryPagination.set(category, pagination)
            pagination.nextPage()
          } else if (loadingCategoryPagination.get(category)) loadingCategoryPagination.get(category)?.nextPage()
        }, 1000 + delayedRequestsCount.current * 100)
      } else onDone(category)
    }

    selectedPOIscategories.forEach((categoryName) => fetchPOIs(categoryName))
  }

  const fetchPOIData = (markerLocation: Location) => {
    void Axios.get<HereCombinedResponse>(`${lanaApiUrl}/api/v2/here/combined`, {
      params: {
        lat: markerLocation.lat.toString(),
        lng: markerLocation.lng.toString(),
        range: 15 * 60,
        rangeType: "time",
        transportMode: "pedestrian",
      },
    }).then((success) => {
      setIsochrone(success.data.isolines[0])
      const gmapsIsochronePolygon = new google.maps.Polygon({
        paths: success.data.isolines[0].polygons[0].map((isochronePoint) => {
          return { lat: isochronePoint[1], lng: isochronePoint[0] }
        }),
      })
      setIsochronePolygon(gmapsIsochronePolygon)

      if (!selectedPOICategories || selectionMode === "defaults") {
        getPOIsCount(aggregatedPOIsCategories, gmapsIsochronePolygon, "defaults")
      } else {
        getPOIsCount(selectedPOICategories, gmapsIsochronePolygon, selectionMode)
      }
    })
  }

  useEffect(() => {
    if (isochrone && isochronePolygon) {
      if (selectionMode === "defaults") getPOIsCount(aggregatedPOIsCategories, isochronePolygon, "defaults")
      else selectedPOICategories && getPOIsCount(selectedPOICategories, isochronePolygon, selectionMode)
    }
  }, [selectedPOICategories, selectionMode, isochronePolygon])

  if (selectedPOIData.counts.has("bus_station")) {
    const busAndTransitStationCount =
      (selectedPOIData.counts.get("bus_station") ?? 0) +
      (selectedPOIData.counts.get("transit_station") ?? 0) +
      (selectedPOIData.counts.get("light_rail_station") ?? 0)
    selectedPOIData.counts.delete("bus_station")
    selectedPOIData.counts.delete("transit_station")
    selectedPOIData.counts.delete("light_rail_station")
    selectedPOIData.counts.set("bus&transit", busAndTransitStationCount)
  }

  const renderPOIs = (selectedPOIData: POIWidgetData | { counts: Map<string, number> }): ReactNode => {
    const categoriesToShow = [...selectedPOIData.counts.keys()]

    const sortedPOICategories =
      selectionMode === "defaults"
        ? categoriesToShow
        : categoriesToShow.sort((a, b) => {
            const nameA: GoogleMapsPOICategory | undefined = googleMapsCategories.find((item) => item.id === a)
            const nameB: GoogleMapsPOICategory | undefined = googleMapsCategories.find((item) => item.id === b)
            return nameA && nameB ? t.pickTranslation(nameA.title).localeCompare(t.pickTranslation(nameB.title)) : -1
          })

    return sortedPOICategories.map((categoryName) => {
      let categoryTitle
      if (selectionMode === "defaults") categoryTitle = t.dashboard.POIs.categories[categoryName]
      else {
        const name = googleMapsCategories.find((item) => item.id === categoryName)
        categoryTitle = name ? t.pickTranslation(name.title) : t.poiExplorer.busAndTransit
      }
      return (
        <React.Fragment key={"poi-category-" + categoryName}>
          <div className="single-line-with-ellipsis" title={categoryTitle}>
            {categoryTitle}
          </div>
          {poiWidgetsLoading && selectedPOIData.counts.has(categoryName) ? (
            <div className={Classes.SKELETON} style={{ width: "3em", justifySelf: "end" }} />
          ) : (
            <div style={{ textAlign: "right" }}>{selectedPOIData.counts.get(categoryName)}</div>
          )}
        </React.Fragment>
      )
    })
  }

  useEffect(() => {
    if (markerLocationLng && markerLocationLat) {
      fetchPOIData({
        lat: markerLocationLat,
        lng: markerLocationLng,
      })
    }
  }, [markerLocationLng, markerLocationLat])

  return (
    <DashboardWidget
      header={t.dashboard.POIs.header}
      onHeaderClick={onHeaderClick}
      configurable={configurable}
      onConfigClick={onConfigClick}
      onRemoveClick={onRemoveClick}
      printMode={printMode}
      editMode={editMode}
    >
      <div id="POIwidget" />
      <div className={styles.contentStyle}>
        <div className={styles.poisGrid}>{renderPOIs(selectedPOIData)}</div>
      </div>
    </DashboardWidget>
  )
}

export default POIsWidget
