import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { store } from "../../relas/store"
import { LoginData } from "../models/login-data"
import { LoginErrors } from "../models/login-errors"
import { PasswordForgottenState } from "../models/password-forgotten-state"
import { AxiosError, default as Axios } from "axios"
import { loginServiceUrl } from "../../app_config"
import { updateUserIsAuthorised } from "../../relas/user-slice"
import { fetchAndHandleUser } from "../../utils/local-storage"
import { TwoFactorDefaults } from "../../user-account/models/two-factor"

export type Mode = "username-password" | "otp" | "passwort-forgotten"

export type LoginState = {
  loginInProgress: boolean
  form: LoginData
  loginError: LoginErrors | null
  loginOtpRequired: boolean | null
  passwordForgottenState: PasswordForgottenState
  login2FASetup?: TwoFactorDefaults
}

export const loginInitialState: LoginState = {
  form: {
    username: undefined,
    password: undefined,
    trackingAllowed: false,
    otpCode: undefined,
  },
  loginOtpRequired: null,
  loginError: null,
  passwordForgottenState: PasswordForgottenState.nothing,
  loginInProgress: false,
  login2FASetup: undefined,
}

const loginSlice = createSlice({
  name: "login",
  initialState: loginInitialState,
  reducers: {
    loginStartAction(state, action: PayloadAction<LoginData>) {
      state.form = action.payload
      state.loginError = null
      state.loginInProgress = true
      state.loginOtpRequired = null
    },
    loginNeedOtpAction(state) {
      state.loginInProgress = false
      state.loginError = null
      state.loginOtpRequired = true
    },
    loginDeniedAction(state, action: PayloadAction<LoginErrors>) {
      state.loginError = action.payload
      state.loginInProgress = false
    },
    showLoginAction(state) {
      state.loginError = null
    },
    loginActionDone(state) {
      state.loginInProgress = false
    },
    sendPasswordForgottenEmailAction(state) {
      state.passwordForgottenState = PasswordForgottenState.in_progress
    },
    sendPasswordForgottenEmailDoneAction(state) {
      state.passwordForgottenState = PasswordForgottenState.performed
    },
    sendPasswordForgottenEmailErrorAction(state) {
      state.passwordForgottenState = PasswordForgottenState.error
    },
    set2FASetup(state, action: PayloadAction<TwoFactorDefaults | undefined>) {
      state.login2FASetup = action.payload
    },
  },
})

export const loginReducer = loginSlice.reducer

const {
  loginStartAction,
  loginDeniedAction,
  loginNeedOtpAction,
  loginActionDone,
  sendPasswordForgottenEmailAction,
  sendPasswordForgottenEmailDoneAction,
  sendPasswordForgottenEmailErrorAction,
  set2FASetup,
} = loginSlice.actions

export async function loginStart(data: LoginData): Promise<User | null> {
  store.dispatch(loginStartAction(data))

  return Axios.post<{ message?: string }>(`${loginServiceUrl}/api/login`, {
    username: data.username,
    password: data.password,
    otpCode: data.otpCode,
    trackingAllowed: data.trackingAllowed,
  })
    .then((data) => {
      store.dispatch(loginActionDone())

      if (data.status % 100 === 2) {
        updateUserIsAuthorised(true)
        return fetchAndHandleUser()
      } else {
        let error = LoginErrors.invalidPassword
        switch (data.data.message) {
          case "error.noProductsBooked":
            error = LoginErrors.noProductsBooked
            break
        }
        store.dispatch(loginDeniedAction(error))
      }
      return null
    })
    .catch((error: AxiosError<any>) => {
      if (error.response && error.response.data) {
        if (error.response.data["details"] === "otp code required") {
          store.dispatch(loginNeedOtpAction())
        } else if (error.response.data["message"] === "error.userBlocked") {
          store.dispatch(loginDeniedAction(LoginErrors.userBlocked))
          return null
        } else if (error.response.data["message"] === "error.noProductsBooked") {
          store.dispatch(loginDeniedAction(LoginErrors.noProductsBooked))
          return null
        } else if (error.response.data["message"] === "error.no2fa") {
          const twoFactorSetup: TwoFactorDefaults = {
            sharedSecretCandidate: error.response.data.details["sharedSecretCandidate"] ?? "",
            sharedSecretCandidateChartURL: error.response.data.details["sharedSecretCandidateChartURL"] ?? "",
          }
          store.dispatch(set2FASetup(twoFactorSetup))
          store.dispatch(loginDeniedAction(LoginErrors.mandatory2FASetup))
          return null
        } else {
          store.dispatch(loginDeniedAction(LoginErrors.invalidPassword))
        }
      }

      store.dispatch(loginDeniedAction(LoginErrors.generalError))
      return null
    })
}

export function sendPasswordForgottenEmail(email: string): void {
  store.dispatch(sendPasswordForgottenEmailAction())

  Axios.post(`${loginServiceUrl}/api/loginmail`, { email: email }, { params: {} })
    .then(() => store.dispatch(sendPasswordForgottenEmailDoneAction()))
    .catch(() => store.dispatch(sendPasswordForgottenEmailErrorAction()))
}
