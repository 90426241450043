import React, { useEffect, useMemo, useState } from "react"
import { css } from "emotion"
import { translations } from "../../i18n"
import POIExplorerMenu from "./poi-explorer-menu"
import Axios, { AxiosResponse } from "axios"
import { lanaApiUrl } from "../../../app_config"
import {
  addPois,
  fetchUserDefinedPOIGroups,
  updateIsochronePolygon,
  updateIsochroneSettings,
  updatePOICategories,
  updatePOIGroup,
  updateShowOnlyPrimaryCategories,
  updateShowOnlyWithingIsochrone,
} from "../../reducers/poi-explorer-slice"
import PoisDetailsPane from "./pois-details-pane"
import { NearestAccessibilityDistances } from "../dashboard/nearest-accessibility-widget"
import { DownloadReport } from "../../../shared/components/ui/download-report"
import { DocumentationAndSupport } from "../../../shared/components/documentation-and-support"
import { IsochroneType } from "../isochrone-type"
import useInterval from "../../../utils/use-interval"
import { POIExplorerMap } from "./poi-explorer-map"
import { useAppSelector } from "../../../relas/store"
import { AppModules } from "../../../menu/util/app-location-types"
import { loadAssessmentForAssessmentModule } from "../../reducers/comparables-slice"
import {
  fetchCategories,
  fetchCategoryPois,
  updatePOIsToShow,
  updateSelectedCategories,
} from "../../../private-data/reducers/private-data-slice"
import { InvalidAddressMessage } from "../../../shared/components/ui/invalid-address-message"
import { AlertBox } from "../../../shared/components/alertbox"
import Text from "../../../shared/components/text"
import Grid from "../../../shared/components/restyle-grid/grid"
import { getThemeColor, getThemeColorVar } from "../../../shared/helper/color"
import { language } from "../../../shared/language"
import LanaSubheader from "../../../shared/components/lana-subheader"

const styles = {
  stickyHeader: css({
    position: "sticky",
    top: 0,
    background: "white",
    zIndex: 100,
  }),
  borderGrey: css({
    borderRight: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
  }),
  notifierStyle: css({
    backgroundColor: getThemeColorVar("background", "dark"),
    color: "white",
    width: "inherit",
    position: "sticky",
    marginTop: "-64px",
    zIndex: 1005,
    bottom: 0,
    transition: "max-height 1s",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.9,
    overflow: "hidden",
    height: "64px",
  }),
  loadingAssessment: css({
    minHeight: "100%",
    padding: "1em",
    backgroundColor: "rgb(229, 227, 223)",
  }),
  contentWrap: css({
    height: "100%",
    display: "flex",
    flexDirection: "row",
    fontSize: "14px",
  }),
  rightPanel: css({
    width: "300px",
  }),
  mapGrid: css({
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "4fr 300px",
  }),
}

interface Props {
  assessmentId: string
  assessmentEntryId: string | null
  module: AppModules["locationAssessment"]
}

export interface UserDefinedPOIGroup {
  categories: string[]
  id: string
  name: string
}

const helpLink = "https://docs.google.com/document/d/10AfVbRP8eJssrQG3ZLlFgWXw7El1qhbddieRReBAOuA"

export const POIExplorerApp = ({ assessmentId, assessmentEntryId, module }: Props) => {
  const t = useMemo(translations, [translations])

  const [poiGroupToDelete, setPoiGroupToDelete] = useState<string | null>(null)
  const [nearestAccessibility, setNearestAccessibility] = useState<NearestAccessibilityDistances>()
  const [loadingNearestAccessibility, setLoadingNearestAccessibility] = useState(true)
  const [loadingPois, setLoadingPois] = useState<Set<string>>(new Set())

  const assessment = useAppSelector((state) => state.assessment.currentAssessment)
  const assessmentEntry = useAppSelector((state) => state.assessment.currentAssessmentEntry)

  const assessmentEntryLoadError = useAppSelector((state) => state.assessment.assessmentEntryLoadError)
  const assessmentLoadError = useAppSelector((state) => state.assessment.assessmentLoadError)

  const userDefinedPOIGroups = useAppSelector((state) => state.POIExplorerApp.userDefinedPOIGroups)

  const pois = useAppSelector((state) => new Map(Object.entries(state.POIExplorerApp.pois ?? {})))

  const poiExplorerInput = useAppSelector((state) => state.POIExplorerApp.poiExplorerInput)
  const isPrivateDataAccessible = useAppSelector((state) => state.user.scopes.privateData)
  const privatePoiCategories = useAppSelector((state) => state.privateData.privatePOICategories)
  const privateDataSettings = useAppSelector((state) => state.privateData.modulesWithPrivateData.poiExplorer)

  const poiCategories: Set<string> = useMemo(() => {
    return new Set<string>(poiExplorerInput.categories)
  }, [poiExplorerInput.categories])

  function setSelectedPOIGroup(group: string) {
    updatePOIGroup({
      group,
      categories: [],
    })
  }

  function setSelectedPOICategories(categories: Set<string>) {
    updatePOICategories([...categories])
  }

  function setIsochronePolygon(polygon: google.maps.Polygon | undefined) {
    updateIsochronePolygon(polygon)
  }

  function setShowOnlyPrimaryCategories(showOnlyPrimaryCategories: boolean) {
    updateShowOnlyPrimaryCategories(showOnlyPrimaryCategories)
  }

  function setShowPoiWithinIsochrone(showPoiWithinIsochrone: boolean) {
    updateShowOnlyWithingIsochrone(showPoiWithinIsochrone)
  }

  function setIsochroneSettings(isochroneSettings: IsochroneType) {
    updateIsochroneSettings(isochroneSettings)
  }

  useEffect(() => {
    if (assessmentEntry?.address.location)
      Axios.get(`${lanaApiUrl}/api/v2/here/distances`, {
        params: {
          lat: assessmentEntry?.address.location.lat.toString(),
          lng: assessmentEntry?.address.location.lng.toString(),
          language: language(),
        },
      }).then(
        (success: AxiosResponse) => {
          setNearestAccessibility(success.data)
          setLoadingNearestAccessibility(false)
        },
        () => setLoadingNearestAccessibility(false)
      )
  }, [assessmentEntry?.address.location])

  useEffect(() => {
    void loadAssessmentForAssessmentModule(assessmentId, assessmentEntryId, "poiExplorer")
  }, [assessmentId, assessmentEntryId])

  const assessmentLoaded = assessment && assessmentEntry
  const dataLoadingError = assessmentEntryLoadError || assessmentLoadError

  const poisForWhichSelectedCategoryIsPrimary = new Map()
  if (poiExplorerInput.onlyPrimaryCategories) {
    pois?.forEach((value, key) => {
      poisForWhichSelectedCategoryIsPrimary.set(
        key,
        value.filter((a) => a.types && a.types[0] === key)
      )
    })
  }

  // assessment creation from POI explorer module
  useInterval(
    () => {
      if (assessment && assessmentEntry) {
        Axios.get(
          `${lanaApiUrl}/api/assessments/${encodeURIComponent(assessment.id)}/entries/${encodeURIComponent(
            assessmentEntry.id
          )}`
        ).then(
          (success: AxiosResponse) => {
            void loadAssessmentForAssessmentModule(assessment.id, assessmentEntry.id, "poiExplorer")
          },
          () => {}
        )
      }
    },
    assessment &&
      (assessment.state === "scored" || assessment.state === "scored-ambiguous" || assessment.state === "failure")
      ? null
      : 2000
  )

  const renderContent = () => (
    <div className={styles.contentWrap}>
      <PoisDetailsPane
        nearestAccessibility={nearestAccessibility}
        isLoading={loadingNearestAccessibility}
        selectedPOICategories={poiCategories}
        loadingPois={loadingPois}
        isochronePolygon={poiExplorerInput.isochronePolygon}
        pois={pois}
        showOnlyPrimaryCategories={poiExplorerInput.onlyPrimaryCategories}
        setShowOnlyPrimaryCategories={setShowOnlyPrimaryCategories}
        showPoiWithinIsochrone={poiExplorerInput.onlyWithingIsochrone}
        setShowPoiWithinIsochrone={setShowPoiWithinIsochrone}
        textSearch={poiExplorerInput.poiTextSearch}
      />
      <div className={styles.mapGrid}>
        <div className={styles.borderGrey}>
          {!assessmentLoaded && <div className={styles.loadingAssessment} />}

          {assessmentLoaded && (
            <POIExplorerMap
              selectedPOIGroup={poiExplorerInput.group}
              poiCategories={poiCategories}
              poiTextSearch={poiExplorerInput.poiTextSearch}
              setAllLoadingPois={setLoadingPois}
              setIsochronePolygon={setIsochronePolygon}
              pois={pois}
              showOnlyPrimaryCategories={poiExplorerInput.onlyPrimaryCategories}
              doAddPois={addPois}
              assessmentEntry={assessmentEntry}
              isochroneSettings={poiExplorerInput.isochroneSettings}
              setIsochroneSettings={setIsochroneSettings}
              isochronePolygon={poiExplorerInput.isochronePolygon}
              showControls={true}
              fitToBounds={"none"}
              setSelectedPOICategories={setSelectedPOICategories}
              isPrivateDataAccessible={isPrivateDataAccessible}
              privatePoiCategories={privatePoiCategories}
              privateDataSettings={privateDataSettings}
              doPrivateDataPOICategoriesGet={fetchCategories}
              doPrivateDataSelectedCategoriesUpdate={updateSelectedCategories}
              doPrivateCategoryPOIsGet={fetchCategoryPois}
              doUpdatePoisToShow={updatePOIsToShow}
            />
          )}

          {dataLoadingError && (
            <div className={styles.notifierStyle}>
              <span style={{ padding: "8px" }}>{t.poiExplorer.error}</span>
            </div>
          )}
        </div>

        <POIExplorerMenu
          selectedPOIGroup={poiExplorerInput.group}
          setSelectedPOIGroup={setSelectedPOIGroup}
          selectedPOICategories={poiCategories}
          setSelectedPOICategories={setSelectedPOICategories}
          setPoiGroupToDelete={setPoiGroupToDelete}
        />
      </div>
    </div>
  )

  function renderDeleteConfirm(groupId: string) {
    const groupToDelete = userDefinedPOIGroups?.find((group) => group.id === groupId)
    return (
      <AlertBox
        header={t.poiExplorer.deleteConfirmHeader}
        onClose={() => setPoiGroupToDelete(null)}
        actions={[
          {
            label: t.delete,
            icon: "delete",
            action: () => {
              Axios.delete(`${lanaApiUrl}/api/v3/poi/categories_groups/${poiExplorerInput.group}`).then(
                () => {
                  setSelectedPOICategories(new Set())
                  setSelectedPOIGroup("manual-selection")
                  fetchUserDefinedPOIGroups("manual-selection")
                  setPoiGroupToDelete(null)
                },
                () => {}
              )
            },
          },
          { label: t.cancel, action: () => setPoiGroupToDelete(null) },
        ]}
      >
        <Text>{t.poiExplorer.deleteConfirmText(groupToDelete ? groupToDelete.name : "")}</Text>
      </AlertBox>
    )
  }

  return (
    <Grid columns={1} height={[100, "%"]} rowSpec="fit-content(100%) minmax(0, 1fr)">
      {poiGroupToDelete && renderDeleteConfirm(poiGroupToDelete)}
      <div className={styles.stickyHeader}>
        <LanaSubheader
          menuSection={"locationAssessment"}
          assessment={assessment}
          assessmentEntry={assessmentEntry}
          module={module}
        >
          <div style={{ display: "flex" }}>
            <DocumentationAndSupport
              documentationURL={helpLink}
              tooltip={t.helpAndSupport}
              callLocation="POI explorer"
              addDocumentationLink
              onClick={() => {}}
            />
          </div>
          <DownloadReport />
        </LanaSubheader>
      </div>

      <InvalidAddressMessage assessmentEntry={assessmentEntry} />
      {assessmentEntry && assessmentEntry.state !== "failure" && renderContent()}
    </Grid>
  )
}

export default POIExplorerApp
