import { ComparablesItem } from "../../generated-apis/comparables-service"
import { css } from "emotion"
import * as React from "react"
import { translations } from "../../shared/i18n"
import { translations as assessmentTranslations } from "../i18n"
import { Button } from "@blueprintjs/core"
import { useEffect } from "react"
import { usePrevious } from "../../utils/use-previous"
import Card from "../../shared/components/card"
import Grid from "../../shared/components/restyle-grid/grid"
import Icon from "../../shared/components/icon"

interface Props {
  items: ComparablesItem[]
  handleShowDetails?: (itemId: number | undefined) => void
  onClose: () => void
}

const comparablesOverlayRightColumn = css({
  fontWeight: "bold",
  justifySelf: "end",
})

const detailsButton = css({
  borderRadius: "4px",
  fontSize: "12px",
})

export const ObservedOffersMapPopup = ({ items, handleShowDetails, onClose }: Props) => {
  const getItemsIdent = (items: ComparablesItem[]): string => items.map((x) => x.id.toString(32)).join("_")
  const t = { ...translations(), assessmentTranslations: assessmentTranslations() }

  const [currentIndex, setCurrentIndex] = React.useState(0)
  const [currentCount, setCurrentCount] = React.useState(items.length)
  const [ident] = React.useState(getItemsIdent(items))
  const previousItems = usePrevious(items)

  useEffect(() => {
    let prevIdent = previousItems && getItemsIdent(previousItems)
    if (prevIdent !== ident) {
      setCurrentCount(items.length)
      setCurrentIndex(0)
    }
  }, [items])

  const item = currentIndex >= 0 && currentIndex < currentCount ? items[currentIndex] : null
  const displayRoomsRow = !!items.find((item) => item.rooms)
  const onNext = (e: React.MouseEvent) => {
    setCurrentIndex(Math.min(currentIndex + 1))
    setCurrentCount(currentCount - 1)
    e.stopPropagation()
  }

  const onPrevious = (e: React.MouseEvent) => {
    setCurrentIndex(Math.max(0, currentIndex - 1))
    e.stopPropagation()
  }

  const onClosePopup = () => {
    setCurrentIndex(0)
    onClose()
  }

  if (item) {
    const itemIdPrefix = "observer_offer_popup_info_"

    return (
      <Card id={itemIdPrefix + "card_" + items.map((i) => i.id.toString(32)).join("_")}>
        <div
          key={itemIdPrefix + item.id}
          id={itemIdPrefix + item.id}
          style={{ minWidth: "280px" }}
          className={itemIdPrefix + "class"}
        >
          <Grid
            columns={2}
            columnSpec={[
              [5, "fr"],
              [1, "fr"],
            ]}
            rowGap={4}
          >
            {item.street ? (
              <div style={{ fontWeight: "bold", textOverflow: "ellipsis" }}>
                {item.street} {item.houseNumber ?? ""}
              </div>
            ) : (
              <div />
            )}

            <div
              className={css({
                i: { fontSize: "16px" },
                alignSelf: "center",
                justifySelf: "end",
                cursor: "pointer",
              })}
              onClick={onClosePopup}
            >
              <Icon name={"close"} color={"primary"} colorType={"default"} />
            </div>
          </Grid>
          <hr style={{ margin: "1em 0" }} />
          <Grid columns={2} rowGap={4}>
            {item.sqmPriceCents && (
              <>
                <div>{t.price}</div>
                <div className={comparablesOverlayRightColumn}>
                  {(item.sqmPriceCents / 100).toFixed(2)} {t.assessmentTranslations.assessmentEntryDetails.euroPerSqm}
                </div>
              </>
            )}
            <div>{t.assessmentTranslations.assessmentEntryDetails.livingArea}</div>
            <div className={comparablesOverlayRightColumn}>{item.area} m²</div>
            {displayRoomsRow && <div>{t.assessmentTranslations.assessmentEntryDetails.rooms}</div>}
            {displayRoomsRow && <div className={comparablesOverlayRightColumn}>{item.rooms || "n/a"}</div>}
            <div>{t.assessmentTranslations.assessmentEntryDetails.constructionYear}</div>
            <div className={comparablesOverlayRightColumn}>{item.buildYear || "n/a"}</div>
            <div>{t.assessmentTranslations.assessmentEntryDetails.publicationTime}</div>
            <div className={comparablesOverlayRightColumn}>{`Q${item.offerDateQuarter} ${item.offerDateYear}`}</div>
          </Grid>
          {currentCount > 1 && (
            <>
              <hr style={{ margin: "1em 0" }} />
              <Grid columns={1}>
                <div style={{ textAlign: "center", userSelect: "none" }}>
                  <a onClick={onPrevious}>
                    <Icon name={"chevron_left_small"} />
                  </a>
                  <span>
                    <b id={itemIdPrefix + "current_index"}>{currentIndex + 1}</b>/{currentCount}
                  </span>
                  <a onClick={onNext}>
                    <Icon name={"chevron_right_small"} />
                  </a>
                </div>
              </Grid>
            </>
          )}
          {handleShowDetails && currentCount === 1 && (
            <div style={{ textAlign: "right", paddingTop: "16px" }}>
              <Button
                className={detailsButton}
                intent={"primary"}
                onClick={() => (handleShowDetails ? handleShowDetails(item?.id) : {})}
              >
                {t.assessmentTranslations.assessmentDetails.showDetails}
              </Button>
            </div>
          )}
        </div>
      </Card>
    )
  } else {
    return <></>
  }
}
