import { CompanyAdminMembersState, initialState as memberListInitialState } from "./memberslist-slice"
import { CompanyAdminSettingsState, initialState as settingsInitialState } from "./settings-slice"
import { CompanyAdminInvoiceState, initialState as invoiceInitialState } from "./invoice-slice"
import { CompanyApiState, initialState as apiInitialState } from "./api-slice"

export type CompanyAdminState = CompanyAdminMembersState &
  CompanyAdminSettingsState &
  CompanyAdminInvoiceState &
  CompanyApiState

export const INITIAL_STATE: CompanyAdminState = {
  companyMembersList: memberListInitialState,
  companySettings: settingsInitialState,
  invoiceState: invoiceInitialState,
  apiState: apiInitialState,
}
