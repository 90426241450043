import { BorderBottom } from "./ui/border-bottom"
import { Flex } from "./ui/flex"
import { FlexItem } from "./ui/flex-item"
import { GridItem } from "./ui/grid-item"
import { ScrollBox } from "./ui/scroll-box"
import { Checkbox, Radio, RadioGroup, Slider } from "@blueprintjs/core"
import * as React from "react"
import { css } from "emotion"
import { translations } from "../i18n"
import { PrivatePOICategoriesList, PrivatePOICategory } from "../../private-data/models/private-data"
import { IsochroneType } from "../../assessment/components/isochrone-type"
import { FormEvent, useState } from "react"
import { IsolineVehicleType } from "../models/poi-explorer"
import { useAppSelector } from "../../relas/store"
import Grid from "./restyle-grid/grid"

const styles = {
  label: css({
    paddingBottom: "8px",
    fontWeight: "bold",
  }),
}
interface Props {
  selectedPrivatePoiCategories: string[]
  availablePrivatePoiCategories: PrivatePOICategoriesList
  isochrone: IsochroneType
  subtitle: string
  setConfig: (config: Partial<{ isochrone: IsochroneType; privatePOICategories: string[] }>) => void
}
export const MicroMapConfigSelection = ({
  isochrone,
  selectedPrivatePoiCategories,
  availablePrivatePoiCategories,
  subtitle,
  setConfig,
}: Props) => {
  const t = React.useMemo(translations, [translations])
  const isPrivateDataAccessible = useAppSelector((state) => state.user.scopes.privateData)
  useState<string[]>(selectedPrivatePoiCategories)

  const onVehicleTypeChange = (e: FormEvent<HTMLInputElement>) => {
    setConfig({ isochrone: { ...isochrone, mode: e.currentTarget.value as IsolineVehicleType } })
  }

  const categoryRow = (category: PrivatePOICategory) => {
    const onChangeCheckbox = (category: PrivatePOICategory) => {
      const newCategories = selectedPrivatePoiCategories.includes(category.id)
        ? selectedPrivatePoiCategories.filter((c) => c !== category.id)
        : [...selectedPrivatePoiCategories, category.id]
      setConfig({ privatePOICategories: newCategories })
    }
    return (
      <Flex
        key={`private-data-row${category.id}`}
        flexDirection={"row"}
        alignItems={"center"}
        gap={8}
        padding={[0, 0, 0, 8]}
      >
        <img src={`/assets/private-data/${category.icon}.svg`} />
        <FlexItem alignSelf={"center"}>
          <Checkbox
            style={{ marginBottom: "0px" }}
            checked={selectedPrivatePoiCategories.includes(category.id)}
            onClick={() => onChangeCheckbox(category)}
          />
        </FlexItem>
        <p>{category.title}</p>
      </Flex>
    )
  }
  return (
    <React.Fragment>
      <div>
        <BorderBottom>
          <Grid columns={1} padding={16} gap={16}>
            <GridItem>
              <div className={styles.label}>{t.poiExplorer.vehicleLabel}</div>
              <RadioGroup selectedValue={isochrone.mode} onChange={onVehicleTypeChange} inline>
                <Radio label={t.map.vehicle["none"]} value="none" style={{ marginRight: 24 }} />
                <Radio label={t.map.vehicle["pedestrian"]} value="pedestrian" style={{ marginRight: 24 }} />
                <Radio label={t.map.vehicle["car"]} value="car" />
              </RadioGroup>
            </GridItem>

            <GridItem>
              <div className={styles.label}>{t.poiExplorer.transitTimeLabel}</div>
              <div style={{ padding: "8px 16px 16px 16px" }}>
                <Slider
                  min={5}
                  max={120}
                  stepSize={5}
                  labelStepSize={115}
                  labelRenderer={(v) => `${v}\u00a0${t.poiExplorer.transitTimeUnit}`}
                  value={isochrone.time}
                  onChange={(e) => setConfig({ isochrone: { ...isochrone, time: e } })}
                />
              </div>
            </GridItem>
          </Grid>
        </BorderBottom>
        {isPrivateDataAccessible && (
          <>
            <BorderBottom>
              <Flex flexDirection={"row"} padding={16}>
                {subtitle}
              </Flex>
            </BorderBottom>
            {availablePrivatePoiCategories && availablePrivatePoiCategories.length > 0 && (
              <BorderBottom>
                <Grid columns={1} gap={16} height={[250, "px"]}>
                  <ScrollBox>{availablePrivatePoiCategories.map((category) => categoryRow(category))}</ScrollBox>
                </Grid>
              </BorderBottom>
            )}
          </>
        )}
      </div>
      <FlexItem flexGrow={1} />
    </React.Fragment>
  )
}
