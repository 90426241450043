import * as React from "react"
import { FC, PropsWithChildren } from "react"
import { Popover, PopoverInteractionKind } from "@blueprintjs/core"
import { Grid } from "./ui/grid"
import { GridItem } from "./ui/grid-item"
import { IconName } from "@21re/revoicons/dist/icon-type"
import { css } from "emotion"
import { DocumentationAndSupport } from "./documentation-and-support"
import Button from "./button"
import Icon from "./icon"

const borderBottom = (justX: boolean) =>
  css({
    borderBottom: justX ? "" : "2px solid #DDDDDD",
    padding: "16px 16px 16px 16px",
    zIndex: 10,
  })

export type Props = {
  title?: string
  tooltip?: string
  helpLink?: string
  iconname: IconName
  parent?: HTMLElement
  defaultIsOpen?: boolean
  justX?: boolean
  onClose?: () => void
}

export const PopoverContent: FC<PropsWithChildren<Props>> = (p) => {
  return (
    <Popover
      usePortal={p.parent ? true : undefined}
      portalContainer={p.parent}
      position={"left-top"}
      interactionKind={PopoverInteractionKind.CLICK}
      hasBackdrop
      defaultIsOpen={p.defaultIsOpen}
      modifiers={{ arrow: { enabled: false }, offset: { offset: "0,10" } }}
    >
      <button>
        <Icon name={p.iconname} fontSize={16} />
      </button>

      <Grid columns={1} padding={0} gap={8}>
        {p.title && (
          <div className={borderBottom(p.justX ?? false)}>
            <Grid columns={2}>
              {!p.justX ? <h3>{p.title}</h3> : <span />}
              <GridItem justifySelf={"end"}>
                <div className="bp4-popover-dismiss" style={{ display: "flex", alignItems: "center" }}>
                  {p.tooltip && p.helpLink && (
                    <div style={{ marginRight: "8px" }}>
                      <DocumentationAndSupport
                        tooltip={p.tooltip}
                        documentationURL={p.helpLink}
                        callLocation="POI Explorer"
                        onClick={() => {}}
                      />
                    </div>
                  )}
                  <Button type="tertiary" icon="close" size="small" onClick={p.onClose} />
                </div>
              </GridItem>
            </Grid>
          </div>
        )}
        {p.children}
      </Grid>
    </Popover>
  )
}
