import React from "react"
import { getThemeColorVar } from "../../shared/helper/color"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"
import { Grid } from "../../shared/components/restyle-grid/grid"
import { css } from "emotion"

const styles = {
  header: css({ color: getThemeColorVar("primary", "default") }),
  subHeaderLink: css({ paddingLeft: "32px" }),
  subSubHeaderLink: css({ paddingLeft: "64px" }),
}

export const RatingsHelp = () => {
  const scrollToContentItem = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id: string) => {
    event.preventDefault()
    document.getElementById(id)?.scrollIntoView()
  }

  return (
    <div style={{ height: "100vh", overflow: "auto" }}>
      <h1 style={{ color: getThemeColorVar("primary", "default"), padding: "32px 32px 16px 32px" }}>Ratings</h1>
      <Grid columns={2} columnSpec="1fr 5fr" padding={32} gap={8}>
        <div style={{ lineHeight: "1.6" }}>
          <div
            style={{ textTransform: "uppercase", color: getThemeColorVar("primary", "default"), paddingBottom: "4px" }}
          >
            Inhaltsverzeichnis
          </div>
          <div>
            <div>
              <a href={"#einführung"} onClick={(event) => scrollToContentItem(event, "einführung")}>
                Einführung
              </a>
            </div>
            <div>
              <a href={"#rating-manager"} onClick={(event) => scrollToContentItem(event, "rating-manager")}>
                Rating Manager
              </a>
            </div>
            <div className={styles.subHeaderLink}>
              <a
                href={"#rating-manager-einführung"}
                onClick={(event) => scrollToContentItem(event, "rating-manager-einführung")}
              >
                Einführung
              </a>
            </div>
            <div className={styles.subHeaderLink}>
              <a href={"#standart-ratings"} onClick={(event) => scrollToContentItem(event, "standart-ratings")}>
                Standard-Ratings (Makro / Mikro)
              </a>
            </div>
            <div className={styles.subSubHeaderLink}>
              <a
                href={"#standart-ratings-ueberblick"}
                onClick={(event) => scrollToContentItem(event, "standart-ratings-ueberblick")}
              >
                Überblick
              </a>
            </div>
            <div className={styles.subSubHeaderLink}>
              <a href={"#rating-erstellen"} onClick={(event) => scrollToContentItem(event, "rating-erstellen")}>
                Rating erstellen
              </a>
            </div>
            <div className={styles.subSubHeaderLink}>
              <a href={"#regeln-festlegen"} onClick={(event) => scrollToContentItem(event, "regeln-festlegen")}>
                Regeln festlegen
              </a>
            </div>
            <div className={styles.subHeaderLink}>
              <a href={"#meta-ratings"} onClick={(event) => scrollToContentItem(event, "meta-ratings")}>
                Meta-Ratings
              </a>
            </div>
            <div className={styles.subSubHeaderLink}>
              <a
                href={"#meta-ratings-ueberblick"}
                onClick={(event) => scrollToContentItem(event, "meta-ratings-ueberblick")}
              >
                Überblick
              </a>
            </div>
            <div className={styles.subSubHeaderLink}>
              <a
                href={"#meta-rating-erstellen"}
                onClick={(event) => scrollToContentItem(event, "meta-rating-erstellen")}
              >
                Rating erstellen
              </a>
            </div>
            <div className={styles.subSubHeaderLink}>
              <a
                href={"#meta-rating-konfigurieren"}
                onClick={(event) => scrollToContentItem(event, "meta-rating-konfigurieren")}
              >
                Rating konfigurieren
              </a>
            </div>
            <div>
              <a href={"#ratings-im-einsatz"} onClick={(event) => scrollToContentItem(event, "ratings-im-einsatz")}>
                Ratings im Einsatz
              </a>
            </div>
            <div className={styles.subHeaderLink}>
              <a href={"#ratings-dashboard"} onClick={(event) => scrollToContentItem(event, "ratings-dashboard")}>
                Dashboard
              </a>
            </div>
            <div className={styles.subHeaderLink}>
              <a
                href={"#ratings-in-ratings-modul"}
                onClick={(event) => scrollToContentItem(event, "ratings-in-ratings-modul")}
              >
                Ratings Modul
              </a>
            </div>
            <div className={styles.subHeaderLink}>
              <a
                href={"#ratings-portfolio-liste"}
                onClick={(event) => scrollToContentItem(event, "ratings-portfolio-liste")}
              >
                Portfolio Liste
              </a>
            </div>
            <div className={styles.subHeaderLink}>
              <a href={"#ratings-report"} onClick={(event) => scrollToContentItem(event, "ratings-report")}>
                Report (DOC)
              </a>
            </div>
            <div className={styles.subHeaderLink}>
              <a href={"#ratings-export-excel"} onClick={(event) => scrollToContentItem(event, "ratings-export-excel")}>
                Export (Excel)
              </a>
            </div>
            <div className={styles.subHeaderLink}>
              <a href="#zugriff-ueber-API" onClick={(event) => scrollToContentItem(event, "zugriff-ueber-API")}>
                Zugriff über API
              </a>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "16px",
            flexDirection: "column",
            lineHeight: "1.6",
            textAlign: "justify",
          }}
        >
          <h2 id={"#einführung"} className={styles.header}>
            Einführung
          </h2>
          <p>
            Ratings sind Regeln für die Vergabe von Noten, womit Bewertungen und Vergleiche ermöglicht werden. Bekannte
            Beispiele sind Ratings zur Kreditwürdigkeit von Staaten. Aber auch das klassische Schulzeugnis ist ein
            Rating, bei dem sich aus bestimmten Regeln (Mitarbeit im Unterricht, Erfolg in Klausuren etc.) am Ende eine
            Note ergibt.
          </p>
          <p>
            Im Grunde lässt sich alles anhand unterschiedlichster Kriterien bewerten und in ein beliebiges Notensystem
            einordnen. Für die Ratings auf unserer Plattform gilt dabei Folgendes:
          </p>
          <ol>
            <li>
              Bewertet werden Lagen. Typischerweise haben Sie eine Adresse, und das Rating liefert Ihnen dazu eine Note.
            </li>
            <li>
              Als Kriterien dienen unsere Daten. So können z. B. einzelne Scores, komplexe Profile, oder Rohdaten für
              die Benotung herangezogen werden.
            </li>
            <li>
              Das Notensystem kann frei definiert werden. Möglich sind z. B. klassische Schulnoten (1-6) oder Buchstaben
              (A-B-C-D) oder auch ein Notenpaar wie “Ja” und “Nein”.
            </li>
            <li>
              Auch bei den Regeln ist fast alles möglich. Sie können ganz einfache Regeln definieren (Gemeinden mit mehr
              als 100.000 Einwohner erhalten ein “A”), aber auch sehr komplexe mit mehreren ganz unterschiedlichen
              Kriterien.
            </li>
            <li>Es gibt Makro- und Mikro-Ratings, die sich entweder auf Gemeinden oder unser Kachelsystem beziehen.</li>
            <li>
              Neben diesen Standard-Ratings bieten wir außerdem noch Meta-Ratings. Diese basieren nicht auf den üblichen
              Daten-Kriterien, sondern auf anderen Ratings. So lassen sich Makro- und Mikro-Ratings in einem Meta-Rating
              zu einer Endnote kombinieren.
            </li>
            <li>
              Erstellt und verwaltet werden Ratings im Rating Manager. Verwendet und angezeigt werden sie dann überall
              auf unserer Plattform, z. B. im Dashboard, im Modul Ratings, in Reports und Exports sowie über eine
              API-Schnittstelle.
            </li>
          </ol>
          <h2 id="rating-manager" className={styles.header}>
            Rating Manager
          </h2>
          <h3 id={"rating-manager-einführung"}>Einführung</h3>
          <p>
            Im <i>Rating Manager</i> werden Ratings erstellt und verwaltet. Geöffnet wird er im Untermenü <i>Tools</i>.
          </p>
          <FlexContainer direction={"column"} gap={4}>
            <img
              src="/assets/help/images-ratings/image3.png"
              alt="Den Rating Manager über das Menü öffnen"
              width={"35%"}
              height={"auto"}
            />
            <i>Abb: Den Rating Manager über das Menü öffnen</i>
          </FlexContainer>
          <p>
            Im Workspace finden Sie auf der linken Seite eine Liste mit allen bereits erstellten Ratings. Daneben
            befindet sich eine große Karte, wo die Noten des gerade ausgewählten Ratings farblich dargestellt werden.
          </p>
          <FlexContainer direction={"column"} gap={4}>
            <img src="/assets/help/images-ratings/image2.png" alt="Der Rating Manager" width={"50%"} height={"auto"} />
            <i>Abb: Der Rating Manager</i>
          </FlexContainer>
          <h3 id={"standart-ratings"}>Standard-Ratings (Makro / Mikro)</h3>
          <FlexContainer direction={"column"} gap={8}>
            <div>
              <p id={"standart-ratings-ueberblick"}>
                <b>Überblick</b>
              </p>
              <p>Standard-Ratings haben folgende Eigenschaften:</p>
              <ul>
                <li>Die Regeln basieren auf Daten (z. B. “Wenn mehr als 1000 Einwohner…”)</li>
                <li>Die Noten sind fix (z. B. “1 - 2 - 3 - 4 - 5 - 6” oder “Yes - No”)</li>
                <li>
                  Je nach Bezugsebene gibt es Makro-Ratings (auf Gemeindeebene) und Mikro-Ratings (auf Kachelebene)
                </li>
              </ul>
            </div>
            <div>
              <p id={"rating-erstellen"}>
                <b>Rating erstellen</b>
              </p>
              <p>
                Unterhalb der Liste aller Ratings gelangen Sie über den Button <b>Rating erstellen</b> in ein Formular,
                wo folgende Angaben für das neue Rating zu machen sind:
              </p>
              <div style={{ padding: "0 12px" }}>
                <p>
                  <b>Titel</b>
                  <br />
                  Jedes Rating braucht einen eindeutigen Titel.
                </p>
                <p>
                  <b>Typ</b>
                  <br />
                  Neben <i>Standard</i> lassen sich auch <i>Meta-Ratings</i> erstellen (siehe nächstes Kapitel).
                </p>
                <p>
                  <b>Kontext</b>
                  <br />
                  Hier wird festgelegt, ob es sich um ein Makro- oder Mikro-Rating handelt. Der Unterschied besteht
                  lediglich in der Bezugsebene (Gemeinden / Kacheln).
                </p>
                <div>
                  <b>Noten</b>
                  <br />
                  Für Standard-Ratings muss das Notenspektrum von Anfang an festgelegt werden. Über{" "}
                  <i>Note hinzufügen</i> können Sie bis zu 10 Einzelnoten erzeugen, die jeweils letzte Note lässt sich
                  über das Papierkorb Icon wieder entfernen.
                  <ul>
                    <li>
                      Jede einzelne Note erhält eine eindeutige <i>Bezeichnung</i> (z. B. “1” oder “Ja”).
                    </li>
                    <li>
                      Für die spätere Verwendung in einem Meta-Rating kann ein <i>Wert</i> vergeben werden. Dadurch
                      lassen sich auch mit Noten wie “A” oder “Gut” Berechnungen durchführen.
                    </li>
                    <li>
                      Über eine individuelle <i>Farbe</i> lassen sich Noten auf den Karten besser darstellen.
                    </li>
                    <li>
                      Bezeichnung, Wert und Farbe lassen sich später noch ändern, nicht aber die Anzahl der Noten.
                    </li>
                  </ul>
                </div>
              </div>
              <FlexContainer direction={"column"} gap={4} height="min-content">
                <img
                  src="/assets/help/images-ratings/image5.png"
                  alt="Ein Makro-Rating wird erstellt"
                  width={"35%"}
                  height={"auto"}
                />
                <i>Abb: Ein Makro-Rating wird erstellt</i>
              </FlexContainer>
            </div>
            <p>
              Im <b>Beispiel</b> wird das Makro-Rating “Städte mit Potential” mit den Noten <i>Ja</i>, <i>Vielleicht</i>{" "}
              und <i>Nein</i> erstellt. Anschließend wird das neue Rating in der Liste aller Ratings aufgeführt.
            </p>
            <p id={"regeln-festlegen"} style={{ paddingTop: "8px" }}>
              <b>Regeln festlegen</b>
            </p>
            <p>
              Nach dem Erstellen eines neuen Ratings ist dieses zunächst leer – also ohne Regeln, anhand derer die Noten
              vergeben werden. Um diese Regeln zu erzeugen bzw. im Nachhinein zu editieren, müssen Sie im Action-Menü
              des Ratings den Befehl <b>Bearbeiten</b> wählen.
            </p>
            <FlexContainer direction={"column"} gap={4} height="min-content" padding={[0, 0, 8, 0]}>
              <img
                src="/assets/help/images-ratings/image4.png"
                alt="Ein Rating wird für die Bearbeitung geöffnet"
                width={"35%"}
                height={"auto"}
              />
              <i>Abb: Ein Rating wird für die Bearbeitung geöffnet</i>
            </FlexContainer>
            <p>
              Wenn Sie eine Note vergeben wollen, müssen Sie vorher eine Auswahl treffen, denn es sollen ja nur ganz
              bestimmte Gemeinden (Makro) bzw. Kacheln (Mikro) diese Note erhalten. Welche Gemeinden/Kacheln das sind,
              legen Sie anhand von Regeln fest.
            </p>
            <div style={{ padding: "12px", lineHeight: "1,6" }}>
              <p>
                <b>Erster Schritt: Selektion hinzufügen</b>
                <br />
                Eine Selektion (bzw. Auswahl) erzeugen Sie über den Befehl <i>Selektion hinzufügen</i>. In der Sidebar
                legen Sie fest, welche Note diese Selektion erhalten soll. Um mehrere Selektionen unterscheiden zu
                können, braucht sie außerdem einen Titel.
              </p>
              <p>
                <b>Zweiter Schritt: Regeln hinzufügen</b>
                <br />
                Die Regeln funktionieren wie Filter, deshalb ist der <i>Startpunkt</i> immer die Gesamtheit aller
                Gemeinden (Makro) bzw. Kacheln (Mikro). Diese Gesamtheit wird Schritt für Schritt reduziert, bis am Ende
                die gewünschte Auswahl übrig bleibt.
              </p>
              <p>
                Eine neue Regel erzeugen Sie über den Befehl <i>Regel hinzufügen,</i>. In der erscheinenden Sidebar
                können Sie dann den Typ der Regel festlegen und Detailangaben machen. So lassen sich z. B. ganze
                Bundesländer oder einzelne Gemeinden filtern und natürlich Scores und Rohdaten als Kriterien verwenden.
              </p>
            </div>
            <p>
              Auf diese Weise können Sie für jede Note eine Selektion mit den passenden Regeln erstellen. Dabei ist die
              Reihenfolge wichtig, da Selektionen nur so lange von oben nach unten abgearbeitet werden, bis die erste
              passt. Es ist ja durchaus möglich, dass eine Gemeinde zu mehreren Selektionen passt – dann ergibt sich die
              Note aus dem ersten Treffer. Im Gegenzug müssen Sie für die letzte Selektion nicht unbedingt noch Regeln
              erstellen, da hier eh alle Kandidaten landen, für die vorher keine passende Selektion gefunden wurde.
            </p>
            <p>
              Ebenso ist es möglich, mehrere Selektionen für dieselbe Note zu erstellen. Im Beispiel{" "}
              <i>Städte mit Potential</i> sollen größere Städte (Bevölkerung {">"} 40.000) mit hoher Wachstumsprognose
              (Score <i>Bevölkerungsprognose 2030</i> {">"} 70) die Note <i>Ja</i> bekommen. Auf diese Weise würden Sie
              allerdings kleinere Städte mit extrem hoher Wachstumsprognose (Score {">"} 95) verpassen. Um diese Fälle
              abzudecken, könnten Sie eine weitere Selektion für die Note <i>Ja</i> erstellen, diesmal nur mit dem Score
              zur Wachstumsprognose als einziges Kriterium.
            </p>
          </FlexContainer>
          <FlexContainer direction={"column"} gap={4}>
            <img
              src="/assets/help/images-ratings/image7.png"
              alt="Selektion (1) und Regel (2) erstellen, Typ (3) auswählen und Detailangaben (4) machen"
              width={"60%"}
              height={"auto"}
            />
            <i>Abb: Selektion (1) und Regel (2) erstellen, Typ (3) auswählen und Detailangaben (4) machen</i>
          </FlexContainer>
          <h3 id={"meta-ratings"}>Meta-Ratings</h3>
          <p id={"meta-ratings-ueberblick"}>
            <b>Überblick</b>
          </p>
          <p>Im Gegensatz zu den Standard-Ratings haben Meta-Ratings folgende Eigenschaften:</p>
          <ul>
            <li>Für die Berechnung wird nicht auf Daten, sondern auf bereits bestehende Ratings zurückgegriffen.</li>
            <li>Die Noten sind nicht fix, sondern ergeben sich dynamisch und können jeden Wert annehmen.</li>
            <li>Bei der Bezugsebene wird nicht getrennt, Makro- und Mikro-Ratings lassen sich beliebig kombinieren.</li>
          </ul>
          <p>
            Mit Abiturnoten würde man das so erklären: jedes Fach bekommt ein eigenes Standard-Rating basierend auf den
            jeweiligen Leistungsdaten (mit Noten von 0-15). Für die endgültige Abiturnote werden all diese
            Fächer-Ratings zu einem Meta-Rating addiert (mit einem erreichbaren Punktespektrum von 0-900).
          </p>
          <p id={"meta-rating-erstellen"}>
            <b>Rating erstellen</b>
          </p>
          <p>
            Unterhalb der Liste aller Ratings bringt Sie der Button <b>Rating erstellen</b> zum Formular, wo folgende
            Angaben für das neue Meta-Rating zu machen sind:
          </p>
          <div style={{ padding: "12px", lineHeight: "1,6" }}>
            <p>
              <b>Titel</b>
              <br />
              Jedes Rating braucht einen eindeutigen Titel.
            </p>
            <p>
              <b>Typ</b>
              <br />
              Statt <i>Standard</i> (siehe letztes Kapitel) muss diesmal <i>Meta-Rating</i> ausgewählt werden.
            </p>
            <p>
              <b>Farbbereiche</b>
              <br />
              Die fixen Noten der Standard-Ratings konnten einzelnen Farben zugewiesen werden. Da die Note eines
              Meta-Ratings aber theoretisch jeden Wert annehmen kann, muss hier mit Bereichen gearbeitet werden. Im
              Beispiel bekommen alle Werte bis 3 die Farbe Rot zugewiesen, alle Werte über 3 die Farbe Grün.
            </p>
          </div>
          <FlexContainer direction={"column"} gap={4}>
            <img
              src="/assets/help/images-ratings/image6.png"
              alt="Ein Meta-Rating wird erstellt"
              width={"35%"}
              height={"auto"}
            />
            <i>Abb: Ein Meta-Rating wird erstellt</i>
          </FlexContainer>
          <p id={"meta-rating-konfigurieren"}>
            <b>Rating konfigurieren</b>
          </p>
          <p>
            Wie schon bei den Standard-Ratings ist ein Meta-Rating nach der Erstellung zunächst leer und muss über das
            Action-Menü zur Bearbeitung geöffnet werden. Im Unterschied zu den Standard-Ratings müssen diesmal aber
            keine Selektionen und Regeln erstellt werden – für die Konfiguration reicht es, die Standard-Ratings
            auszuwählen, die Teil des Meta-Ratings werden sollen.
          </p>
          <FlexContainer direction={"column"} gap={4}>
            <img
              src="/assets/help/images-ratings/image9.png"
              alt="Ein Meta-Rating wird konfiguriert"
              width={"35%"}
              height={"auto"}
            />
            <i>Abb: Ein Meta-Rating wird konfiguriert</i>
          </FlexContainer>
          <p>
            In der Liste finden Sie alle verfügbaren Makro- und Mikro-Ratings. Jedes einzelne Rating kann ausgewählt und
            zudem gewichtet werden. Für die Berechnung der Endnote werden dann alle ausgewählten Ratings zunächst mit
            dem Gewicht multipliziert und dann addiert. Um diesen Vorgang zu testen, können Sie eine beliebige Adresse
            eingeben und auf <b>Berechnen</b> klicken. Daraufhin wird der Wert zusammen mit der passenden Farbe
            angezeigt. Erst wenn Sie <b>Speichern</b> klicken, werden die Einstellungen übernommen.
          </p>
          <p>Beim Zusammenstellen und Berechnen eines Meta-Ratings sind einige Dinge zu beachten:</p>
          <ul>
            <li>
              <FlexContainer direction={"column"} gap={4}>
                <p>
                  Die Noten der verwendeten Makro- und Mikro-Ratings sollten einen Wert haben, denn sonst lassen sie
                  sich nicht addieren
                </p>
                <img
                  src="/assets/help/images-ratings/image8.png"
                  alt="Ein Meta-Rating wird konfiguriert"
                  width={"25%"}
                  height={"auto"}
                />
              </FlexContainer>
            </li>
            <li>
              Die Gewichtung der verwendeten Ratings ist beliebig. Wenn Sie z.B. zwei Ratings mit Schulnoten von 1-6 zu
              einer Gesamtnote verbinden wollen, die wiederum von 1-6 geht, würden Sie jedes einzelne Rating mit 0,5
              gewichten.
            </li>
            <li>
              Auch negative Gewichte sind möglich. Auf diese Weise lässt sich eine Note von der Gesamtnote abziehen.
            </li>
            <li>
              Ratings, die für eine Location keine Note erzeugen, werden bei der Berechnung der Endnote nicht
              berücksichtigt. Im Extremfall, wenn keines der verwendeten Ratings einen Wert liefert, kann das
              Meta-Rating nicht berechnet werden.
            </li>
          </ul>
          <h2 id={"ratings-im-einsatz"} className={styles.header}>
            Ratings im Einsatz
          </h2>
          <p>
            Die im <i>Rating Manager</i> (siehe letztes Kapitel) erzeugten Standard- und Meta-Ratings kommen an vielen
            Stellen unserer Plattform zum Einsatz.
          </p>
          <h3 id={"ratings-dashboard"}> Dashboard</h3>
          <p>
            Im Dashboard gibt es für die Ratings ein eigenes Widget, wo alle Makro-, Mikro- und Meta-Ratings mit den
            jeweils errechneten Noten der Reihe nach aufgelistet werden. Dieses Widget ist konfigurierbar, Sie können
            sich also nur ganz bestimmte Ratings anzeigen lassen.
          </p>
          <FlexContainer direction={"column"} gap={4}>
            <img
              src="/assets/help/images-ratings/image12.png"
              alt="Das Rating-Widget im Dashboard"
              width={"35%"}
              height={"auto"}
            />
            <i>Abb: Das Rating-Widget im Dashboard</i>
          </FlexContainer>
          <h3 id={"ratings-in-ratings-modul"}>Ratings Modul</h3>
          <p>
            Während im Dashboard nur ein Überblick geboten wird, finden Sie im <i>Ratings</i> Modul sämtliche
            Informationen und Erklärungen zu den Ratings des aktuellen Assessments. Öffnen lässt sich das Modul über den
            entsprechenden Eintrag im Menü.
          </p>
          <FlexContainer direction={"column"} gap={4}>
            <img
              src="/assets/help/images-ratings/image10.png"
              alt="Das Ratings Modul im Menü öffnen"
              width={"25%"}
              height={"auto"}
            />
            <i>Abb: Das Ratings Modul im Menü öffnen</i>
          </FlexContainer>
          <p>
            Auf der linken Seite des Moduls finden Sie eine Liste mit allen Ratings und den für das aktuelle Assessment
            kalkulierten Noten. Sobald Sie eines der Ratings anklicken, erhalten Sie weitere Detailinformationen:
          </p>
          <ul>
            <li>
              Für <b>Makro- und Mikro-Ratings</b> werden zunächst alle potentiellen Noten aufgeführt, damit Sie die
              aktuelle Note besser in Kontext setzen können.
            </li>
            <li>
              Darunter wird tabellarisch erklärt, wie sich die aktuelle Note ergeben hat (mit den Regeln und den für das
              aktuelle Assessment erzielten Werten).
            </li>
            <li>
              <FlexContainer direction={"column"} gap={4}>
                <p>
                  Auf der Karte sehen Sie alle Gemeinden (Makro) bzw. Kacheln (Mikro) in der Farbe der jeweiligen Note,
                  was einen Vergleich mit der Umgebung ermöglicht.
                </p>
                <img
                  src="/assets/help/images-ratings/image11.png"
                  alt="Ausgewähltes Makro-Rating im Ratings Modul"
                  width={"70%"}
                  height={"auto"}
                />
                <i>Abb: Ausgewähltes Makro-Rating im Ratings Modul</i>
              </FlexContainer>
            </li>
            <li>
              <FlexContainer direction={"column"} gap={4}>
                <p>
                  Für <b>Meta-Ratings</b> werden tabellarisch alle darin verwendeten Standard-Ratings mit den jeweiligen
                  Noten und Gewichten gezeigt. In der Spalte Ergebnis können Sie leicht nachvollziehen, wie die Endnote
                  errechnet wurde, die außerdem in der passenden Farbe angezeigt wird.
                </p>
                <img
                  src="/assets/help/images-ratings/image13.png"
                  alt="Aus Noten und Gewichten der Standard-Ratings ergibt sich die Endnote des Meta-Ratings"
                  width={"35%"}
                  height={"auto"}
                />
                <i>Abb: Aus Noten und Gewichten der Standard-Ratings ergibt sich die Endnote des Meta-Ratings</i>
              </FlexContainer>
            </li>
          </ul>
          <h3 id={"ratings-portfolio-liste"}>Portfolio Liste</h3>
          <p>
            Neben Assessments für einzelne Adressen können Sie auch eine ganze Liste von Adressen hochladen und
            auswerten. Innerhalb der Übersichtsliste werden für alle Adressen neben den Profilwerten auch die Ratings
            mit ihren Noten angezeigt. So lässt sich ein komplettes Portfolio auf den ersten Blick einschätzen.
          </p>
          <FlexContainer direction={"column"} gap={4}>
            <img
              src="/assets/help/images-ratings/image14.png"
              alt="Anzeige aller Ratings innerhalb der Portfolio Liste"
              width={"70%"}
              height={"auto"}
            />
            <i>Abb: Anzeige aller Ratings innerhalb der Portfolio Liste</i>
          </FlexContainer>
          <h3 id={"ratings-report"}>Report (DOC)</h3>
          <p>
            In all unseren Modulen befindet sich oben rechts ein Icon zum Download des Assessment Reports. Der Inhalt
            dieses Reports lässt sich konfigurieren – im Fall der Ratings können Sie einzeln festlegen, ob die Mikro-,
            Makro- und Meta-Ratings mit aufgenommen werden sollen.
          </p>
          <FlexContainer direction={"column"} gap={4}>
            <img
              src="/assets/help/images-ratings/image15.png"
              alt="Für den Report lassen sich die gewünschten Rating-Gruppen auswählen"
              width={"70%"}
              height={"auto"}
            />
            <i>Abb: Für den Report lassen sich die gewünschten Rating-Gruppen auswählen</i>
          </FlexContainer>
          <h3 id={"ratings-export-excel"}>Export (Excel)</h3>
          <p>
            Genau wie der Report (siehe letzter Abschnitt) lassen sich viele Assessment-Daten auch im Excel-Format
            herunterladen. In der Datei finden Sie dann Scores, Rohdaten sowie die berechneten Makro-, Mikro- und
            Meta-Ratings.
          </p>
          <FlexContainer direction={"column"} gap={4}>
            <img
              src="/assets/help/images-ratings/image16.png"
              alt="Exportierte Excel Datei mit Ratings"
              width={"70%"}
              height={"auto"}
            />
            <i>Abb: Exportierte Excel Datei mit Ratings</i>
          </FlexContainer>
          <h3 id="zugriff-ueber-API">Zugriff über API</h3>
          <p>
            Statt über die Programmoberfläche lassen sich viele unserer Daten auch programmatisch über eine
            Schnittstelle (API) abfragen. Dazu gehören auch die Standard- und Meta-Ratings. Diese Funktion muss
            allerdings extra gebucht werden.
          </p>
        </div>
      </Grid>
    </div>
  )
}
