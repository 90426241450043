/**
 * Converting number which is year as whole part and quarter as decimal part, for example 2020.25 -> Q2 2020
 * @param year
 */
import { YearAndQuarter } from "../generated-apis/comparables-service/comparables/models"

export function numericYearWithQuarterToLabel(year: number): string {
  const result = numericYearWithQuarterToTuple(year)
  return `Q${result.quarter} ${result.year}`
}

export function numericYearWithQuarterToTuple(year: number): YearAndQuarter {
  return { year: Math.floor(year), quarter: Math.floor((year - Math.floor(year)) * 4 + 1) }
}

export function getQuarterWithYearFromDate(date: Date) {
  const quarter = Math.floor(date.getMonth() / 3) / 4
  return date.getFullYear() + quarter
}
