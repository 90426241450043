import { SHARED_DE } from "../../shared/i18n/de"
import { PrivateDataTranslationsGroup } from "./translations"

export const PRIVATE_DATA_DE: PrivateDataTranslationsGroup = {
  ...SHARED_DE,
  privateData: {
    categories: "Kategorien",
    mainTabs: (tab) => {
      switch (tab) {
        case "map":
          return "Karte"
        case "data":
          return "Daten"
      }
    },
    deleteDialog: {
      category: {
        title: "Kategorie löschen",
        message: "Wollen Sie die Kategorie {} wirklich löschen? Dabei werden auch alle dazugehörigen POIs gelöscht.",
      },
      pois: {
        title: "POIs löschen",
        message: "Sie haben {} POIs zum Löschen ausgewählt. Fortfahren?",
      },
      delete: "Löschen",
    },
    poiCreateOrEdit: {
      create: "POI hinzufügen",
      edit: "POI bearbeiten",
      optional: "Optional",
      address: "Adresse",
      companyAssignedId: "ID",
      title: "Title",
      notes: "Notizen",
      addressInputPlaceholder: "Adresse eingeben",
      setPinHint:
        "Die Adresse ist nicht eindeutig. Sie können innerhalb der Karte klicken um die gewünschte Position festzulegen.",
      outsideOfGermany: "Diese Adresse liegt außerhalb von Deutschland und kann nicht verwendet werden.",
    },
    fileUpload: {
      title: "Adressliste hochladen",
      hint: `Sie können eine Excel Datei mit bis zu 1000 Adressen hochladen. </br> Wenn Sie Fragen zum Dateiformat haben, werfen Sie einen Blick in die <a href="https://docs.google.com/document/d/1LDviPxMe5NGCdxHOYFeNp4kH2z9Km2nqqeUVZT2eOfs" target="_blank">Dokumentation</a>.`,
      selectFile: "Datei auswählen",
      uploadingFile: "Datei wird hochgeladen",
      missingColumns: {
        postalCode: "PLZ",
        city: "Stadt",
        street: "Straße",
        streetNumber: "Nr",
      },
      fileUploadErrorResponse: {
        errorTitle: "Fehler beim Lesen der Datei.",
        "Incorrect format": "Die hochgeladene Datei hat kein gültiges xls Format.",
        "file is required": "Keine Datei zum Importieren gefunden.",
        InvalidFileType: "Die hochgeladene Datei hat kein gültiges xls Format.",
        NoRows: "Die hochgeladene Datei enthält keine Zeilen",
        MissingColumns: "Die folgenden Spalten konnten nicht gefunden werden: {}",
        "Limit for POIs in this category reached":
          "Sie haben das Limit von 1000 Zeilen für diese Kategorie überschritten. Insgesamt werden 1000 Zeilen, einschließlich vorhandener Einträge, beibehalten.",
      },
    },
    cancel: "Abbrechen",
    newCategory: "Neue Kategorie",
    allCategories: "Alle Kategorien",
    createCategory: "Kategorie erstellen",
    title: "Title",
    notes: "Notizen",
    data: "Daten",
    icon: "Icon",
    upload: "Hochladen",
    add: "Hinzufügen",
    create: "Erstellen",
    edit: "Bearbeiten",
    lock: "Sperren",
    unlock: "Entsperren",
    delete: "Löschen",
    editCategory: "Kategorie bearbeiten",
    save: "Speichern",
    lockCategory: "Kategorie sperren",
    unlockCategory: "Kategorie entsperren",
    lockMessage:
      "Durch das Sperren lässt sich eine Kategorie schützen. Ihre Kollegen können sie dann immer noch nutzen, aber nicht mehr bearbeiten oder löschen.",
    unlockMessage: "Wenn Sie diese Kategorie entsperren, kann sie von Ihren Kollegen bearbeitet und gelöscht werden.",
    categoryNameAlreadyExists: "Eine Kategorie mit diesem Namen existiert bereits.",
    id: "ID",
    address: {
      postalCode: "PLZ",
      locality: "Stadt",
      route: "Straße",
    },
    noPoisAvailable:
      "Dieser Kategorie wurden bisher keine POIs hinzugefügt. Nutzen Sie den 'Hinzufügen' oder 'Upload' Button, um einen einzelnen POI zu erzeugen oder eine ganze Liste mit POIs hochzuladen.",
    noCategoriesFound:
      "Bisher wurden keine Kategorien erstellt. Klicken Sie dafür auf 'Neue Kategorie'. Anschließend können Sie einzelne POIs erzeugen oder eine ganze Liste hochladen.",
  },
}
