import * as React from "react"
import { bind } from "decko"
import { css, cx } from "emotion"
import FlexContainer from "./restyle-grid/flexcontainer"
import { GenericInputElementProps, GenericInputElementState } from "../models/genericinputelement"
import GenericInputElement from "./genericinputfield"
import { getThemeColorVar } from "../helper/color"

export interface Props extends GenericInputElementProps<HTMLTextAreaElement> {
  rows?: number
  hint?: string
  resize?: boolean
  maxLength?: number
}

const maxWitdhInfoClass = css({
  background: getThemeColorVar("background", "lighter"),
  padding: "2.5px 5px",
  textAlign: "end",
})

const exceedMaxLengthClass = css({
  color: getThemeColorVar("negative", "default"),
  fontWeight: "bold",
})

export class TextArea extends GenericInputElement<Props, GenericInputElementState, HTMLTextAreaElement> {
  constructor(props: Props) {
    super(props)

    this.state = {
      ...this.defaultState,
    }
  }

  @bind
  handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ isPristine: false })
    this.props.onValueChange &&
      this.props.onValueChange(event.target.value, this.validate(event.target.value).length === 0)
  }

  @bind
  handleKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (event.key === "Enter") {
      const value = this.inputElRef ? this.inputElRef.value : ""
      this.props.onValueCommit && this.props.onValueCommit(value, this.validate(value).length === 0)
    }
    this.props.onKeyDown && this.props.onKeyDown(event)
  }

  @bind
  handleBlur(event: React.FocusEvent<HTMLTextAreaElement>) {
    const value = this.inputElRef ? this.inputElRef.value : ""
    this.props.onValueCommit && this.props.onValueCommit(value, this.validate(value).length === 0)
    this.props.onBlur && this.props.onBlur(event)
  }

  renderInner() {
    const { maxLength } = this.props

    return (
      <FlexContainer direction="column">
        <textarea
          ref={(el) => (this.inputElRef = el)}
          rows={this.props.rows}
          value={this.props.value}
          placeholder={this.props.hint}
          disabled={this.props.disabled}
          autoFocus={this.props.autoFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          id={this.props.id}
          maxLength={maxLength}
          tabIndex={this.props.tabIndex}
          onFocus={this.defaultHandleFocus}
        />
        {maxLength && (
          <div className={cx(maxWitdhInfoClass)}>
            <span className={maxLength < this.props.value.length ? cx(exceedMaxLengthClass) : undefined}>
              {this.props.value.length}
            </span>{" "}
            / {this.props.maxLength}
          </div>
        )}
      </FlexContainer>
    )
  }
}

export default TextArea
