import { PrivateDataTranslationsGroup } from "./translations"
import { PRIVATE_DATA_DE } from "./de"
import { PRIVATE_DATA_EN } from "./en"
import { language } from "../../shared/language"

export function translations(): PrivateDataTranslationsGroup {
  switch (language()) {
    case "de":
      return PRIVATE_DATA_DE
    default:
      return PRIVATE_DATA_EN
  }
}
