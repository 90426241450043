import { AssessmentBillableReport } from "../models/product-statistics"
import { ReadonlyBooking } from "../models/booking"
import { CompanyBudget } from "../models/company-budget"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AxiosError, AxiosResponse, default as Axios } from "axios"
import { invoiceServiceUrl } from "../../app_config"
import { store } from "../../relas/store"
import { GenericError, toGenericError } from "../../shared/helper/axios"

interface InvoiceState {
  readonlyBookings?: ReadonlyBooking[]
  fetchReadonlyBookingsInProgress: boolean
  fetchReadonlyBookingsError?: GenericError

  allBillables?: AssessmentBillableReport[]
  allBillablesLoading: boolean
  allBillablesFetchError?: GenericError

  companyBudget?: CompanyBudget
  companyBudgetLoading: boolean
  companyBudgetFetchError?: GenericError
}

export type CompanyAdminInvoiceState = {
  invoiceState: InvoiceState
}

export const initialState: InvoiceState = {
  readonlyBookings: undefined,
  fetchReadonlyBookingsError: undefined,
  fetchReadonlyBookingsInProgress: false,

  allBillables: undefined,
  allBillablesLoading: false,
  allBillablesFetchError: undefined,
  companyBudget: undefined,
  companyBudgetFetchError: undefined,
  companyBudgetLoading: false,
}

const invoiceSlice = createSlice({
  name: "companyInvoice",
  initialState,
  reducers: {
    readonlyBookingsFetchStart(state) {
      state.fetchReadonlyBookingsInProgress = true
      state.fetchReadonlyBookingsError = undefined
      state.readonlyBookings = undefined
    },
    readonlyBookingsFetchDone(state, action: PayloadAction<ReadonlyBooking[]>) {
      state.fetchReadonlyBookingsInProgress = false
      state.readonlyBookings = action.payload
      state.fetchReadonlyBookingsError = undefined
    },
    readonlyBookingsFetchError(state, action: PayloadAction<GenericError>) {
      state.fetchReadonlyBookingsInProgress = false
      state.fetchReadonlyBookingsError = action.payload
      state.readonlyBookings = undefined
    },
    allBillablesFetchStart(state) {
      state.allBillablesLoading = true
      state.allBillablesFetchError = undefined
      state.allBillables = undefined
    },
    allBillablesFetchDone(state, action: PayloadAction<AssessmentBillableReport[]>) {
      state.allBillablesLoading = false
      state.allBillables = action.payload
      state.allBillablesFetchError = undefined
    },
    allBillablesFetchError(state, action: PayloadAction<GenericError>) {
      state.allBillablesLoading = false
      state.allBillablesFetchError = action.payload
      state.allBillables = undefined
    },
    companyBudgetFetchStart(state) {
      state.companyBudgetLoading = true
      state.companyBudgetFetchError = undefined
      state.companyBudget = undefined
    },
    companyBudgetFetchDone(state, action: PayloadAction<CompanyBudget>) {
      state.companyBudgetLoading = false
      state.companyBudget = action.payload
      state.companyBudgetFetchError = undefined
    },
    companyBudgetFetchError(state, action: PayloadAction<GenericError>) {
      state.companyBudgetLoading = false
      state.companyBudgetFetchError = action.payload
      state.companyBudget = undefined
    },
  },
})

const {
  allBillablesFetchDone,
  allBillablesFetchStart,
  allBillablesFetchError,
  companyBudgetFetchDone,
  companyBudgetFetchStart,
  companyBudgetFetchError,
  readonlyBookingsFetchDone,
  readonlyBookingsFetchStart,
  readonlyBookingsFetchError,
} = invoiceSlice.actions

export function fetchAllBillables(): Promise<void> {
  store.dispatch(allBillablesFetchStart())
  return Axios.get(`${invoiceServiceUrl}/api/product-usage/company/all-billables`).then(
    (response: AxiosResponse) => {
      const report = response.data as Array<AssessmentBillableReport>
      store.dispatch(allBillablesFetchDone(report))
    },
    (error: AxiosError) => {
      store.dispatch(allBillablesFetchError(toGenericError(error)))
    }
  )
}

export function fetchCompanyBudget(): Promise<void> {
  store.dispatch(companyBudgetFetchStart())
  return Axios.get(`${invoiceServiceUrl}/api/product-usage/company/budget`).then(
    (response: AxiosResponse) => {
      const budget = response.data as CompanyBudget
      store.dispatch(companyBudgetFetchDone(budget))
    },
    (error: AxiosError) => {
      store.dispatch(companyBudgetFetchError(toGenericError(error)))
    }
  )
}

export function fetchReadonlyBookings(): Promise<void> {
  store.dispatch(readonlyBookingsFetchStart())
  return Axios.get(`${invoiceServiceUrl}/api/bookings/company/my-company`).then(
    (response: AxiosResponse) => {
      const bookings = response.data as ReadonlyBooking[]
      store.dispatch(readonlyBookingsFetchDone(bookings))
    },
    (error: AxiosError) => {
      store.dispatch(readonlyBookingsFetchError(toGenericError(error)))
    }
  )
}

export default invoiceSlice.reducer
