import * as React from "react"
import { translations } from "../i18n"
import { LanguageSwitcher } from "./language-switcher"
import { PropsWithChildren } from "react"
import Grid from "../../shared/components/restyle-grid/grid"
import GridItem from "../../shared/components/restyle-grid/griditem"
import Text from "../../shared/components/text"

type Link = {
  link: string
  translation: string
}

type Props = {
  title: string
}

export const RegistrationContainer = (props: PropsWithChildren<Props>) => {
  const translate = translations()

  const links: Link[] = [
    { link: translate.links.imprintLink, translation: translate.links.imprint },
    { link: translate.links.privacyLink, translation: translate.links.privacy },
    { link: translate.links.termsLink, translation: translate.links.terms },
  ]

  return (
    <Grid
      columns={3}
      rowSpec={[
        [20, "px"],
        [1, "fr"],
        [20, "px"],
      ]}
      paddingX="xxl"
      paddingY="xxl"
      height={[100, "vh"]}
    >
      <GridItem alignSelf="start" justifySelf="start">
        <a href="/">
          <img width="90px" src="https://assets.21real.estate/21_logo_without_padding.svg" alt="logo" />
        </a>
      </GridItem>

      <GridItem alignSelf="start" justifySelf="end" colSpan={2}>
        <LanguageSwitcher />
      </GridItem>

      <GridItem colSpan={3} justifySelf="center" alignSelf="center">
        <Grid columns={1} gap="xxl">
          <Text size="xxl" fontWeight="light" additionalStyles={{ fontSize: "40px" }} color="typo" colorType="dark">
            {props.title}
          </Text>

          <div className="re-registration-container">{props.children}</div>
        </Grid>
      </GridItem>

      <GridItem colSpan={3} alignSelf="end" justifySelf="end">
        {links
          .map((link, index) => (
            <a key={index} href={link.link}>
              {link.translation}
            </a>
          ))
          .map((link, index, arr) => (index === arr.length - 1 ? link : [link, " | "]))}
      </GridItem>
    </Grid>
  )
}
