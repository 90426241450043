import { MutableRefObject, useEffect } from "react"

export function useClickOutside<T extends HTMLElement>(ref: MutableRefObject<T | null>, callback: () => void) {
  function handleClickOutside(event: any) {
    if (ref.current && !ref.current.contains(event.target)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })
}
