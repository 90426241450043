import * as React from "react"
import { css } from "emotion"
import { translations } from "../../i18n"
import { WmsLayer } from "../../models/wms-layer"
import { Flex } from "./flex"
import LoadingDimmer from "../loadingdimmer"
import Text from "../../../shared/components/text"
import { getThemeColorVar } from "../../helper/color"
import Icon from "../icon"

const popupClass = css({
  backgroundColor: getThemeColorVar("white", undefined),
  borderRadius: "5px",
  border: `1px solid ${getThemeColorVar("border", "default")}`,
  width: "570px",
  padding: "15px",
  fontSize: "14px",
})

const topBorder = css({
  borderTop: `1px solid ${getThemeColorVar("border", "default")}`,
  padding: "8px 8px 0",
  margin: "8px -8px 0",
})

export interface WMSDetailsEmbeddedProps {
  selectedLayer: WmsLayer
  url: string
  closeCallback?: () => void
}

export type WMSDetailsState = {
  state: "loading" | "unavailable" | "available"
}

export const WMSDetailsEmbedded: React.FunctionComponent<WMSDetailsEmbeddedProps> = ({
  url,
  selectedLayer,
  closeCallback,
}) => {
  const t = React.useMemo(translations, [translations])
  const [state, setState] = React.useState<WMSDetailsState>({ state: "available" })

  return (
    <div className={popupClass}>
      <Flex flexDirection={"row"}>
        <Text
          additionalStyles={{
            fontSize: "20px",
            lineHeight: "26px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            marginRight: "10px",
            flexGrow: 1,
            whiteSpace: "nowrap",
          }}
          size={"md"}
          fontWeight={"bold"}
          color={"typo"}
          colorType={"dark"}
        >
          {t.pickTranslation(selectedLayer.name)}
        </Text>
        <div
          className={css({ i: { fontSize: "16px" }, alignSelf: "center", cursor: "pointer" })}
          onClick={(e) => {
            e.stopPropagation()
            closeCallback?.()
          }}
        >
          <Icon name={"close"} color={"primary"} colorType={"default"} />
        </div>
      </Flex>
      {state.state === "unavailable" && (
        <div style={{ fontWeight: "bold" }} className={topBorder}>
          {t.map.landvalues.detailsUnavailable}
        </div>
      )}
      <LoadingDimmer loading={state.state === "loading"}>
        <iframe
          style={{ border: "1px solid black" }}
          src={url}
          width="100%"
          height="340px"
          onLoad={() => setState({ state: "available" })}
          onError={() => setState({ state: "unavailable" })}
        />
      </LoadingDimmer>
    </div>
  )
}
