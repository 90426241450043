import * as React from "react"
import { Flex } from "./flex"
import { Grid } from "./grid"
import { GridItem } from "./grid-item"
import { css } from "emotion"
import { getThemeColorVar } from "../../helper/color"
import { Text } from "../text"
import Icon from "../icon"

export interface PopupFact {
  label: string
  value: string | number
}

interface Props {
  header: string
  mainFact: PopupFact
  secondaryFacts: PopupFact[]
  closeCallback?: () => void
  nonFocusCell?: boolean
}

export const MapPopup: React.FunctionComponent<Props> = (props) => {
  function renderFact(fact: PopupFact) {
    return (
      <React.Fragment key={fact.label}>
        <GridItem>
          <span style={{ overflowWrap: "break-word" }}>{fact.label}</span>
        </GridItem>
        <GridItem justifySelf={"end"}>
          <span style={{ overflowWrap: "break-word" }}>{fact.value}</span>
        </GridItem>
      </React.Fragment>
    )
  }

  const topBorder = css({
    borderTop: `1px solid ${getThemeColorVar("border", "default")}`,
    padding: "15px 15px 0",
    margin: "15px -15px 0",
  })

  const secondaryFactsStyle = {
    display: "grid",
    gridTemplateColumns: "70% 1fr",
    maxHeight: "127px",
    overflow: "auto",
    rowGap: "10px",
  }

  const popupClass = css({
    backgroundColor: getThemeColorVar("white", undefined),
    borderRadius: "5px",
    border: `1px solid ${getThemeColorVar("border", "default")}`,
    width: props.nonFocusCell ? "430px" : "305px",
    padding: "10px 15px",
  })

  return (
    <div className={popupClass}>
      <Flex flexDirection={"row"}>
        <Text
          additionalStyles={{
            fontSize: "20px",
            lineHeight: "26px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            marginRight: "10px",
            flexGrow: 1,
            whiteSpace: "nowrap",
          }}
          size={"md"}
          fontWeight={"bold"}
          color={"typo"}
          colorType={"dark"}
        >
          {props.header}
        </Text>
        <div
          className={css({ i: { fontSize: "20px" }, alignSelf: "center", cursor: "pointer" })}
          onClick={(e) => props.closeCallback?.()}
        >
          <Icon name={"close"} color={"primary"} colorType={"default"} />
        </div>
      </Flex>
      <div style={{ fontWeight: "bold" }} className={topBorder}>
        <Grid columnSpec={"auto auto"}>{renderFact(props.mainFact)}</Grid>
      </div>

      {props.secondaryFacts.length > 0 && (
        <div className={`${topBorder} force-macos-scrollbar-visibility`} style={secondaryFactsStyle}>
          {props.secondaryFacts.map(renderFact)}
        </div>
      )}
    </div>
  )
}
