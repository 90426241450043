import { jqLiteAddClass, jqLiteRemoveClass } from "./jqlite"

let scrollLock: number = 0
let _scrollBarWidth: number
let scrollLockPos: { left: number; top: number }
const scrollLockCls = "mui-scroll-lock"
let scrollStyleEl: any
/**
 * Turn on window scroll lock.
 */
export function utilEnableScrollLock() {
  // increment counter
  scrollLock += 1

  // add lock
  if (scrollLock === 1) {
    let doc = document,
      win = window,
      htmlEl = doc.documentElement,
      bodyEl = doc.body,
      scrollBarWidth = getScrollBarWidth(),
      cssProps,
      cssStr,
      x

    // define scroll lock class dynamically
    cssProps = ["overflow:hidden"]

    if (scrollBarWidth) {
      const bodyStyle = getComputedStyle(bodyEl)
      // scrollbar-y
      if (htmlEl && htmlEl.scrollHeight > htmlEl.clientHeight) {
        x = parseInt(bodyStyle.getPropertyValue("padding-right")) + scrollBarWidth
        cssProps.push("padding-right:" + x + "px")
      }

      // scrollbar-x
      if (htmlEl && htmlEl.scrollWidth > htmlEl.clientWidth) {
        x = parseInt(bodyStyle.getPropertyValue("padding-bottom")) + scrollBarWidth
        cssProps.push("padding-bottom:" + x + "px")
      }
    }

    // define css class dynamically
    cssStr = "." + scrollLockCls + "{"
    cssStr += cssProps.join(" !important;") + " !important;}"
    scrollStyleEl = loadStyleFn(cssStr)

    // cancel 'scroll' event listener callbacks
    win.addEventListener("scroll", scrollEventHandler, true)

    // add scroll lock
    const docEl = document.documentElement

    scrollLockPos = {
      left: docEl == null ? 0 : (win.scrollX || docEl.scrollLeft) - (docEl.clientLeft || 0),
      top: docEl == null ? 0 : (win.scrollY || docEl.scrollTop) - (docEl.clientTop || 0),
    }
    jqLiteAddClass(bodyEl, scrollLockCls)
  }
}

/**
 * Turn off window scroll lock.
 * @param {Boolean} resetPos - Reset scroll position to original value.
 */
export function utilDisableScrollLock(resetPos: boolean) {
  // ignore
  if (scrollLock === 0) return

  // decrement counter
  scrollLock -= 1

  // remove lock
  if (scrollLock === 0) {
    // remove scroll lock and delete style element
    jqLiteRemoveClass(document.body, scrollLockCls)
    scrollStyleEl.parentNode.removeChild(scrollStyleEl)

    // restore scroll position
    if (resetPos) window.scrollTo(scrollLockPos.left, scrollLockPos.top)

    // restore scroll event listeners
    window.removeEventListener("scroll", scrollEventHandler, true)
  }
}

/**
 * Return scroll bar width.
 */
function getScrollBarWidth() {
  // check cache
  if (_scrollBarWidth !== undefined) return _scrollBarWidth

  // calculate scroll bar width
  let doc = document,
    bodyEl = doc.body,
    el: any = doc.createElement("div")

  el.innerHTML =
    '<div style="width:50px;height:50px;position:absolute;' +
    'left:-50px;top:-50px;overflow:auto;"><div style="width:1px;' +
    'height:100px;"></div></div>'
  el = el.firstChild
  bodyEl.appendChild(el)
  _scrollBarWidth = el.offsetWidth - el.clientWidth
  bodyEl.removeChild(el)

  return _scrollBarWidth
}

function loadStyleFn(cssText: string): any {
  let doc = document,
    head

  // copied from jQuery
  head = doc.head || doc.getElementsByTagName("head")[0] || doc.documentElement

  const e: any = doc.createElement("style")
  e.type = "text/css"

  if (e.styleSheet) e.styleSheet.cssText = cssText
  else e.appendChild(doc.createTextNode(cssText))

  // add to document
  head.insertBefore(e, head.firstChild)

  return e
}

function scrollEventHandler(event: Event) {
  // stop propagation on window scroll events
  event.stopImmediatePropagation()
}

export function utilRaiseError(msg: string, useConsole: boolean = false) {
  if (useConsole) {
    if (typeof console !== "undefined") console.error("MUI Warning: " + msg)
  } else {
    throw new Error("MUI: " + msg)
  }
}
