import * as React from "react"
import { useMemo } from "react"
import { PriceTrend } from "../../../../generated-apis/comparables-service"
import { ChartData, ChartDataset, ChartOptions, ScatterDataPoint } from "chart.js"
import { COLORS } from "../../../../shared/components/ui/colors"
import { Line } from "react-chartjs-2"
import { numericYearWithQuarterToLabel } from "../../../../utils/numeric-year-with-quarter-to-label"
import { translations } from "../../../i18n"
import { language } from "../../../../shared/language"

interface Props {
  trendData: PriceTrend[]
  middleParameter: keyof PriceTrend
  topParameter: keyof PriceTrend
  bottomParameter: keyof PriceTrend
}

export function transformTrendToPoint(pt: PriceTrend, key: keyof PriceTrend): ScatterDataPoint {
  return {
    x: pt.offerDateYear + (pt.offerDateQuarter - 1) / 4,
    y: pt[key],
  }
}

const lang = language()
const centsToLabel = (n: number) => (Math.round(n * 100) / 100).toLocaleString(lang, { minimumFractionDigits: 2 })

export const ComparablesTrendGraph = ({ trendData, middleParameter, topParameter, bottomParameter }: Props) => {
  const t = useMemo(() => {
    return { ...translations().comparablesGraphs }
  }, [translations])

  const medianPoints = useMemo(() => {
    return trendData.map((p) => transformTrendToPoint(p, middleParameter))
  }, [middleParameter, trendData])

  const maxPoints = useMemo(() => {
    return trendData.map((p) => transformTrendToPoint(p, topParameter))
  }, [topParameter, trendData])

  const minPoints = useMemo(() => {
    return trendData.map((p) => transformTrendToPoint(p, bottomParameter))
  }, [bottomParameter, trendData])

  const { minX, minY, maxX, maxY } = useMemo(() => {
    return (
      trendData.reduce<{ minX: number; minY: number; maxX: number; maxY: number } | undefined>((acc, v: PriceTrend) => {
        const x = v.offerDateYear + (v.offerDateQuarter - 1) / 4

        return {
          minX: Math.min(x, acc?.minX ?? x),
          maxX: Math.max(x, acc?.maxX ?? x),
          minY: Math.min(v.tenth, acc?.minY ?? v.tenth),
          maxY: Math.max(v.ninetieth, acc?.maxY ?? v.ninetieth),
        }
      }, undefined) ?? { minX: 0, maxX: 0, minY: 0, maxY: 0 }
    )
  }, [trendData])

  const datasetConfigBase: Partial<ChartDataset<"line">> = {
    fill: false,
    pointHoverBorderColor: "white",
    pointHoverBorderWidth: 2,
    pointHoverRadius: 6,
    pointRadius: 6,
    borderWidth: 2,
    cubicInterpolationMode: "monotone",
  }

  const chartData: ChartData<"line"> = {
    datasets: [
      {
        ...datasetConfigBase,
        data: medianPoints,
        label: "median",
        pointBackgroundColor: "#00b9e1",
        pointBorderColor: "#ffffff",
        borderColor: "#00b9e1",
        // radius: 6,
      },
      {
        ...datasetConfigBase,
        data: maxPoints,
        label: "max",
        pointBackgroundColor: undefined,
        borderColor: "rgba(0,0,0,0)",
        pointRadius: 0,
        pointHoverRadius: 0,
        fill: "+1",
        backgroundColor: "rgba(0, 185, 225, 0.3)",
      },
      {
        ...datasetConfigBase,
        pointBackgroundColor: undefined,
        borderColor: "rgba(0,0,0,0)",
        pointRadius: 0,
        pointHoverRadius: 0,
        data: minPoints,
        label: "min",
        backgroundColor: "rgba(0, 185, 225, 0.3)",
      },
    ],
  }

  const chartOptions: ChartOptions<"line"> = {
    scales: {
      y: {
        suggestedMin: minY,
        suggestedMax: maxY,
        ticks: {
          display: true,
          callback: (value, index, values) => {
            const numValue = typeof value === "number" ? value : parseFloat(value)
            if (isNaN(numValue)) {
              return value
            }
            return `${Math.round(numValue)} EUR/m²`
          },
        },
        grid: {
          borderDash: [5, 5],
        },
      },
      x: {
        type: "linear",
        position: "bottom",
        min: minX,
        max: maxX,
        ticks: {
          font: {
            family: "Lato, Roboto, Arial, Helvetica, sans-serif",
            size: 12,
          },

          maxTicksLimit: 10,
          stepSize: Math.max(0.25, Math.round((maxX - minX) / 2) / 4), // it's needed to make sure that step size is divisible by 0.25
          callback: (value, index) => {
            const numValue = typeof value === "number" ? value : parseFloat(value)
            if (isNaN(numValue)) {
              return value
            }
            return numericYearWithQuarterToLabel(numValue)
          },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        titleFont: {
          size: 16,
          family: "Lato",
        },
        bodyFont: {
          size: 14,
          family: "Lato",
        },
        titleMarginBottom: 8,
        padding: {
          top: 8,
          left: 8,
          bottom: 8,
          right: 8,
        },
        titleSpacing: 16,
        caretPadding: 10,
        displayColors: false,
        backgroundColor: COLORS.background.dark,
        position: "nearest",
        callbacks: {
          label: (item) => {
            const trend = trendData[item.dataIndex ?? 0]
            const offerCount = trend.offers

            return [
              `${t.observedOffers}: ${offerCount}`,
              `90% ${t.quantile}: ${centsToLabel(trend.ninetieth)} EUR/m²`,
              `75% ${t.quantile}: ${centsToLabel(trend.seventyFifth)} EUR/m²`,
              `25% ${t.quantile}: ${centsToLabel(trend.twentyFifth)} EUR/m²`,
              `10% ${t.quantile}: ${centsToLabel(trend.tenth)} EUR/m²`,
            ]
          },
          title: (items) => {
            if (items.length === 0) return ""

            const [item] = items
            const trend = trendData[item.dataIndex ?? 0]
            const middleTranslation = middleParameter === "average" ? t.average : t.median
            const middleValue = middleParameter === "average" ? trend.average : trend.median
            return `${middleTranslation}: ${centsToLabel(middleValue)} EUR/m²`
          },
        },
      },
    },
  }

  return (
    <div style={{ overflow: "auto" }}>
      <Line data={chartData} options={chartOptions} height={250} redraw={false} />
    </div>
  )
}
