import * as React from "react"
import { translations } from "../i18n"
import { ProfileDetails } from "../models/profile"
import { AlertBox } from "../../shared/components/alertbox"
import Text from "../../shared/components/text"
import LoadingSpinner from "../../shared/components/loadingspinner"

type Props = {
  setUnableToDelete: (profile: ProfileDetails | null) => void
  profile: ProfileDetails
  dependentProfileNames: string[]
  dependentRatingNames: string[]
  dependentSelectionNames: string[]
  dependenciesLoading: boolean
}

export const ProfileDependencyAlert = (props: Props) => {
  const t = translations()
  const tDelete = t.profileDelete

  const renderDependencies = (dependentType: string, dependencyNames: string[]) => {
    let uniqueItems = [...new Set(dependencyNames)].sort((a, b) => a.localeCompare(b))

    return (
      dependencyNames.length > 0 && (
        <>
          <br />
          <Text>{dependentType}:</Text>
          <br />
          <ul>
            {uniqueItems.map((d) => (
              <li>{d}</li>
            ))}
          </ul>
        </>
      )
    )
  }

  return (
    <AlertBox
      header={t.profileDelete.inUseTitle}
      onClose={() => props.setUnableToDelete(null)}
      actions={[{ label: t.ok, action: () => props.setUnableToDelete(null) }]}
    >
      {props.dependenciesLoading ? (
        <div>
          <LoadingSpinner color="primary" />
        </div>
      ) : (
        <div>
          <Text>{tDelete.profileIsInUse(props.profile.name)}</Text>
          {renderDependencies(tDelete.profile, props.dependentProfileNames)}
          {renderDependencies(tDelete.selections, props.dependentSelectionNames)}
          {renderDependencies(tDelete.ratings, props.dependentRatingNames)}
        </div>
      )}
    </AlertBox>
  )
}
