import { WmsLayer } from "../../../shared/models/wms-layer"
import { mapProxyUrl } from "../../../app_config"
import { WMSDetailsEmbedded } from "../../../shared/components/ui/wms-details-embedded"
import { WMSDetailsPopup } from "../../../shared/components/ui/wms-details-popup"
import React, { CSSProperties, useState } from "react"
import { css } from "emotion"
import { xyzToBounds } from "./special-maps-map"
import { DragSourceMonitor, useDrag } from "react-dnd"
import { DraggableBoxesType, ItemTypes } from "./special-maps"
import { usePrevious } from "../../../utils/use-previous"

export type PopupDataType = null | undefined | Object | string

const styles = {
  mapPopupWrapper: css({
    width: "max-content",
    fontSize: "14px",
    maxWidth: "500px",
    minWidth: "200px",
  }),
}

interface Props {
  left: number
  top: number
  id: DraggableBoxesType
  selectedLayer: WmsLayer | null
  zoom?: number
  popupCoords: { lat: number; lng: number }
  onClose: () => void
}

const TILE_SIZE_IN_PIXELS = 256

function projectLatLngToWorldPixels(latLng: { lat: number; lng: number }) {
  let siny = Math.sin((latLng.lat * Math.PI) / 180)

  // Truncating to 0.9999 effectively limits latitude to 89.189. This is
  // about a third of a tile past the edge of the world tile.
  siny = Math.min(Math.max(siny, -0.9999), 0.9999)

  return new google.maps.Point(
    TILE_SIZE_IN_PIXELS * (0.5 + latLng.lng / 360),
    TILE_SIZE_IN_PIXELS * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI))
  )
}

function getTileCoordinates(
  latLng: { lat: number; lng: number },
  zoom: number
): { tile: google.maps.Point; pixelInTile: google.maps.Point } {
  const scale = 1 << zoom

  const worldCoordinate = projectLatLngToWorldPixels(latLng)

  return {
    tile: new google.maps.Point(
      Math.floor((worldCoordinate.x * scale) / TILE_SIZE_IN_PIXELS),
      Math.floor((worldCoordinate.y * scale) / TILE_SIZE_IN_PIXELS)
    ),
    pixelInTile: new google.maps.Point(
      Math.floor((worldCoordinate.x * scale) % TILE_SIZE_IN_PIXELS),
      Math.floor((worldCoordinate.y * scale) % TILE_SIZE_IN_PIXELS)
    ),
  }
}

const getStyles = (left: number, top: number): CSSProperties => {
  const transform = `translate3d(${left}px, ${top}px, 0)`
  return {
    position: "absolute",
    transform,
    WebkitTransform: transform,
    zIndex: 1001,
    backgroundColor: "white",
    borderRadius: "4px",
  }
}

export const SpecialMapsPopup = (props: Props) => {
  const [popupData, setPopupData] = useState<PopupDataType>()
  const { id, left, top } = props

  const prevPopupCoords = usePrevious(props.popupCoords)
  const popupCoordsChanged = prevPopupCoords !== props.popupCoords

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.BOX,
      item: { id, left, top },
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top]
  )

  if (isDragging) {
    return <div ref={drag} />
  }

  return (
    <div ref={drag} style={getStyles(left, top)}>
      <div className={styles.mapPopupWrapper} onMouseDown={(e) => e.stopPropagation()}>
        {renderSpecialMapPopup(
          props.selectedLayer,
          props.zoom,
          props.popupCoords,
          props.onClose,
          popupData,
          setPopupData,
          popupCoordsChanged
        )}
      </div>
    </div>
  )
}

const renderSpecialMapPopup = (
  selectedLayer: WmsLayer | null,
  zoom: number | undefined,
  popupCoords: { lat: number; lng: number },
  onClose: () => void,
  popupData: PopupDataType,
  setPopupData: (data: PopupDataType) => void,
  popupCoordsChanged: boolean
) => {
  let infoFormat: string | null = null

  if (selectedLayer?.popup) {
    switch (selectedLayer.popup.format) {
      case "xml":
      case "boris":
        infoFormat = "text/xml"
        break
      case "gml":
        infoFormat = "application/vnd.ogc.gml"
        break
      case "json":
        infoFormat = "application/json"
        break
      case "html":
        infoFormat = "text/html"
        break
    }

    if (infoFormat && popupCoords && zoom) {
      const tileAndPixelInTileCoordinates = getTileCoordinates(popupCoords, zoom)

      const url =
        `${mapProxyUrl}/wms?SERVICE=WMS&VERSION=1.3.0` +
        "&REQUEST=GetFeatureInfo&FORMAT=image%2Fpng&TRANSPARENT=true" +
        `&QUERY_LAYERS=${selectedLayer?.id}&LAYERS=${selectedLayer?.id}&TILED=true&INFO_FORMAT=${infoFormat}` +
        `&I=${tileAndPixelInTileCoordinates.pixelInTile.x}&J=${tileAndPixelInTileCoordinates.pixelInTile.y}` +
        `&WIDTH=${TILE_SIZE_IN_PIXELS}&HEIGHT=${TILE_SIZE_IN_PIXELS}&CRS=EPSG%3A3857&STYLES=` +
        `&BBOX=${xyzToBounds(tileAndPixelInTileCoordinates.tile.x, tileAndPixelInTileCoordinates.tile.y, zoom).join(
          ","
        )}`

      if (selectedLayer?.popup.format === "html")
        return <WMSDetailsEmbedded selectedLayer={selectedLayer} url={url} closeCallback={onClose} />
      else
        return (
          <WMSDetailsPopup
            selectedLayer={selectedLayer}
            url={url}
            closeCallback={onClose}
            popupData={popupCoordsChanged ? undefined : popupData}
            setPopupData={setPopupData}
          />
        )
    }
    return <></>
  }
  return <></>
}
