import * as React from "react"
import { bind } from "decko"
import { css, cx } from "emotion"
import { TextFieldProps } from "./text-field"
import { getThemeColorVar } from "../helper/color"

export type AutoCompleteProps<S> = {
  selectSuggestion: (suggestion: S | undefined) => void
  suggestions: S[]
  showSuggestions: boolean
  toDoOnBlur: () => void
  onForceSuggestion: () => void
  displaySuggestion: (s: S) => string
  hint?: string
  label?: string
  hasWarning?: boolean
} & TextFieldProps

export type AutoCompleteState = {
  activeSuggestion: number
}

const suggestionClass = css({
  border: `1px solid ${getThemeColorVar("border", "default")}`,
  borderTopWidth: 0,
  listStyle: "none",
  marginTop: 0,
  overflowY: "auto",
  paddingLeft: 0,
  position: "absolute",
  zIndex: 1000,
  backgroundColor: getThemeColorVar("white", undefined),
  display: "none",
})

const listItemClass = css({
  padding: "8px",
  zIndex: 1200,
  ":hover": {
    backgroundColor: getThemeColorVar("white", undefined),
    color: getThemeColorVar("primary", "default"),
    cursor: "pointer",
    fontWeight: 600,
  },
  ":not(:last-of-type)": {
    borderBottom: `1px solid ${getThemeColorVar("border", "default")}`,
  },
})

const listItemActiveClass = css({
  backgroundColor: getThemeColorVar("white", undefined),
  color: getThemeColorVar("primary", "default"),
  cursor: "pointer",
  fontWeight: 600,
})

export abstract class SuggestionAutoComplete<SuggestionType> extends React.Component<
  AutoCompleteProps<SuggestionType>,
  AutoCompleteState
> {
  ref: HTMLInputElement | null = null
  private listRef: React.RefObject<HTMLDivElement>

  constructor(props: AutoCompleteProps<SuggestionType>) {
    super(props)

    this.listRef = React.createRef<HTMLDivElement>()
    this.state = {
      activeSuggestion: 0,
    }
  }

  @bind
  onKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
    switch (event.key) {
      case "Enter":
        if (!this.props.showSuggestions || this.props.suggestions.length === 0) {
          this.props.onForceSuggestion()
        }
        break

      case "Tab":
        if (!this.props.showSuggestions) return
        event.preventDefault()
        this.selectSuggestion(this.props.suggestions[this.state.activeSuggestion])
        break

      case "ArrowUp":
        if (!this.props.showSuggestions) return
        this.state.activeSuggestion > 0 && this.setState({ activeSuggestion: this.state.activeSuggestion - 1 })
        break

      case "ArrowDown":
        if (!this.props.showSuggestions) return
        this.state.activeSuggestion + 1 < this.props.suggestions.length &&
          this.setState({ activeSuggestion: this.state.activeSuggestion + 1 })
        break
    }
  }

  @bind
  private selectSuggestion(suggestion: SuggestionType): void {
    this.setState({ activeSuggestion: 0 })

    this.props.selectSuggestion(suggestion)
    this.ref && this.ref.focus()
  }

  componentDidUpdate(prevProps: AutoCompleteProps<SuggestionType>) {
    if (this.props.suggestions !== prevProps.suggestions) {
      this.setState({ activeSuggestion: 0 })
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.handleOutsideClick.bind(this))
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick.bind(this))
  }

  private handleOutsideClick(event: React.MouseEvent<HTMLElement>) {
    if (this.listRef.current?.contains(event.target as any)) {
      return event.preventDefault()
    } else {
      this.props?.toDoOnBlur()
    }
  }

  buildRender(inputField: any) {
    const listClasses = [suggestionClass]
    const { showSuggestions, suggestions, displaySuggestion } = this.props

    showSuggestions && listClasses.push(css({ display: "inline" }))

    return (
      <div ref={this.listRef}>
        {inputField}

        <ul className={cx(listClasses)}>
          {suggestions.map((suggestion, index) => {
            const classes = [listItemClass]

            index === this.state.activeSuggestion && classes.push(listItemActiveClass)

            return (
              <li
                className={cx(classes)}
                key={index}
                onClick={(e) => {
                  e.preventDefault()
                  this.selectSuggestion(suggestion)
                  this.props.toDoOnBlur()
                }}
              >
                {displaySuggestion(suggestion)}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}
