import * as React from "react"
import { useEffect, useMemo } from "react"
import AppHeader from "../../shared/components/app-header"
import { useAppDispatch, useAppSelector } from "../../relas/store"
import { RatingList } from "./rating-list"
import { css, cx } from "emotion"
import { COLORS } from "../../shared/components/ui/colors"
import { RatingManagerMap } from "./map/rating-manager-map"
import { fetchRatingResults } from "../rating-manager-actions"
import { MunicipalityList } from "./rating-manager-municipality-list"
import { Panel, PanelStack2 } from "@blueprintjs/core"
import { RatingManagerCreateNew } from "./manager/rating-manager-create-new"
import { RatingManagerDuplicateRenameForm } from "./manager/rating-manager-duplicate-rename-form"
import { RatingManagerEditStandardRating } from "./manager/rating-manager-edit-standard-rating"
import { RatingManagerEditRatingForm } from "./manager/rating-manager-rating-edit"
import { isMetaRating } from "../../shared/models/ratings"
import { microRatingFirstAgsRefLoc } from "../../explorer/models/rating"
import { RatingManagerEditMetaRating } from "./manager/rating-manager-edit-meta-rating"
import { DocumentationAndSupport } from "../../shared/components/documentation-and-support"
import { translations } from "../../assessment/i18n"
import { ratingManagerActions } from "../rating-manager-slice"
import { ratingAppDocumentationLink } from "./ratings-app"
import { AllowedModulesEnum } from "../../private-data/models/private-data"

const styles = {
  grid: css({
    display: "grid",
    gridTemplateRows: "fit-content(100%) minmax(0, 1fr)",
    gridTemplateColumns: "420px 420px 1fr",
    gridTemplateAreas: `"header header header" "list map map"`,
    height: "100%",
    gap: "1px",
  }),
  withMunicipalities: css({
    gridTemplateAreas: `"header header header" "list municipalities map"`,
  }),
  header: css({
    gridArea: "header",
  }),
  borderPortal: css({
    outline: "1px solid",
    outlineColor: COLORS.border.default,
  }),
  list: css({
    gridArea: "list",
    "> div": {
      height: "100%",
    },
  }),
  map: css({
    gridArea: "map",
  }),
  municipalities: css({
    gridArea: "municipalities",
  }),
}

const RatingManager = ({}) => {
  const { menuPane, currentRating, showMunicipalityList } = useAppSelector((state) => ({
    menuPane: state.ratingManager.menuPane,
    currentRating: state.ratingManager.currentRating,
    showMunicipalityList: state.ratingManager.showMunicipalityList,
  }))

  const menuStack: Panel<any>[] = useMemo(() => {
    const stack: Panel<any>[] = [{ renderPanel: RatingList }]
    switch (menuPane) {
      case "list":
        break
      case "create":
        stack.push({ renderPanel: RatingManagerCreateNew })
        break
      case "duplicate":
      case "rename":
        stack.push({ renderPanel: RatingManagerDuplicateRenameForm })
        break
      case "edit":
        if (currentRating && !isMetaRating(currentRating)) {
          stack.push({ renderPanel: () => <RatingManagerEditStandardRating /> })
        } else if (currentRating) {
          stack.push({ renderPanel: () => <RatingManagerEditMetaRating /> })
        }
        break
      case "edit-rating":
        stack.push({ renderPanel: RatingManagerEditStandardRating })
        stack.push({ renderPanel: RatingManagerEditRatingForm })
        break
      default:
        console.error("Unknown menu pane", menuPane)
    }
    return stack
  }, [menuPane])

  const t = useMemo(translations, [translations])
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (menuPane === "list" && currentRating) {
      void fetchRatingResults(dispatch)(
        currentRating,
        !isMetaRating(currentRating) ? microRatingFirstAgsRefLoc(currentRating) : undefined
      )
    }
  }, [menuPane])

  useEffect(() => {
    dispatch(ratingManagerActions.setCurrentRating(undefined))
    return () => {
      dispatch(ratingManagerActions.setMenuPane("list"))
    }
  }, [])

  const mainGridStyles = useMemo(() => {
    return cx({
      [styles.grid]: true,
      [styles.withMunicipalities]: showMunicipalityList,
    })
  }, [showMunicipalityList])

  return (
    <div className={mainGridStyles}>
      <div className={styles.header}>
        <AppHeader menuSection={"ratingManager"} assessment={null} assessmentEntry={null} module={null}>
          <DocumentationAndSupport
            documentationURL={ratingAppDocumentationLink}
            tooltip={t.helpAndSupport}
            addDocumentationLink
            callLocation={"Rating Manager"}
            onClick={() => {}}
          />
        </AppHeader>
      </div>
      <div className={cx(styles.list, styles.borderPortal)}>
        <PanelStack2 stack={menuStack} showPanelHeader={false} renderActivePanelOnly={true} />
      </div>
      {showMunicipalityList && (
        <div className={cx(styles.municipalities, styles.borderPortal)}>
          <MunicipalityList />
        </div>
      )}
      <div className={cx(styles.borderPortal, styles.map)}>
        <RatingManagerMap module={AllowedModulesEnum.RATING_MANAGER} />
      </div>
    </div>
  )
}

export default RatingManager
