import React from "react"
import { getThemeColorVar } from "../../shared/helper/color"
import { Grid } from "../../shared/components/ui/grid"
import { css } from "emotion"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"

export const scrollToContentItem = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id: string) => {
  event.preventDefault()
  document.getElementById(id)?.scrollIntoView()
}

const styles = {
  header: css({ color: getThemeColorVar("primary", "default") }),
  subHeaderLink: css({ paddingLeft: "32px" }),
  subSubHeaderLink: css({ paddingLeft: "64px" }),
  lists: css({ display: "flex", flexDirection: "column", gap: "8px" }),
}

export const ComparablesHelp = () => {
  return (
    <div style={{ height: "100vh", overflow: "auto" }}>
      <h1 style={{ color: getThemeColorVar("primary", "default"), padding: "32px 32px 16px 32px" }}>Comparables</h1>
      <Grid columns={2} columnSpec="1fr 5fr" padding={32} gap={8}>
        <div style={{ lineHeight: "1.6" }}>
          <div
            style={{ textTransform: "uppercase", color: getThemeColorVar("primary", "default"), paddingBottom: "4px" }}
          >
            Inhaltsverzeichnis
          </div>
          <div>
            <div>
              <a href={"#grundsätzliches"} onClick={(event) => scrollToContentItem(event, "grundsätzliches")}>
                Grundsätzliches
              </a>
            </div>
            <div>
              <a href={"#comparables-module"} onClick={(event) => scrollToContentItem(event, "comparables-module")}>
                Das Modul
              </a>
            </div>
            <div>
              <a
                href={"#comparables-datenquellen"}
                onClick={(event) => scrollToContentItem(event, "comparables-datenquellen")}
              >
                Datenquellen
              </a>
            </div>
            <div>
              <a
                href={"#comparables-filter-spezifizieren"}
                onClick={(event) => scrollToContentItem(event, "comparables-filter-spezifizieren")}
              >
                Filter spezifizieren
              </a>
            </div>
            <div>
              <a
                href={"#comparables-auswertung"}
                onClick={(event) => scrollToContentItem(event, "comparables-auswertung")}
              >
                Auswertung
              </a>
            </div>
            <div className={styles.subHeaderLink}>
              <a href={"#comparables-karte"} onClick={(event) => scrollToContentItem(event, "comparables-karte")}>
                Karte
              </a>
            </div>
            <div className={styles.subHeaderLink}>
              <a href={"#comparables-analyse"} onClick={(event) => scrollToContentItem(event, "comparables-analyse")}>
                Analyse
              </a>
            </div>
            <div className={styles.subHeaderLink}>
              <a href={"#comparables-report"} onClick={(event) => scrollToContentItem(event, "comparables-report")}>
                Report
              </a>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "16px",
            flexDirection: "column",
            lineHeight: "1.6",
            textAlign: "justify",
          }}
        >
          <h2 id={"grundsätzliches"}>Grundsätzliches</h2>
          <p>
            Beim Planen und Festsetzen von Mieten & Preisen ist es enorm hilfreich zu wissen, was andere Marktteilnehmer
            für vergleichbare Einheiten und Häuser bekommen bzw. zahlen müssen. In der Branche ist daher die Analyse der
            Comparables eine beliebte und gerne eingesetzte Methode.
          </p>
          <p>
            Die Vergleichbarkeit von Objekten bzw. Einheiten bezieht sich auf bauliche, räumliche und zeitliche
            Kriterien. Für aussagekräftige Vergleichsmieten schaut man sich typischerweise möglichst ähnliche Wohnungen
            in der nahen Nachbarschaft an, die aktuell vermietet wurden. Neben dieser klassischen Perspektive eignen
            sich die Comparables allerdings auch für erweiterte Analysen: Wie haben sich die Preise entwickelt? Welchen
            Einfluss haben unterschiedliche Wohnungsschnitte auf die Miete? Welches Preisniveau hat das weitere Umfeld?
            Welche Arten von Objekten sind zuletzt entstanden und vermietet worden?
          </p>
          <p>
            Um diese unterschiedlichen Perspektiven und Analysen deutschlandweit über einen langen Zeitraum zu
            ermöglichen, sammeln wir schon seit vielen Jahren umfassende Angebotsdaten und -preise von Portalen wie
            ImmoScout24, lokalen Plattformen und weiteren kommerziellen Anbietern. Auf Basis dieser Informationen können
            wir das Marktgeschehen detailliert abbilden.
          </p>
          <p>
            Die Aussagekraft der Comparables hängt von der Datendichte der beobachteten Angebote ab. In reinen
            Altbau-Gegenden wird man keine vergleichbaren Neubauten finden. In Nachbarschaften mit vielen älteren
            Mietverträgen werden die sichtbaren Preise vermutlich nicht die aktuellen Trends abbilden. Deshalb ist es
            immer eine gute Idee, neben den Comparables auch unsere Machine-Learning-Preise in die Analyse mit
            einzubeziehen. Diese basieren auf lernenden Algorithmen und deutschlandweiten Vergleichen, mit denen sich
            lokale und zeitliche Datenlücken ausgleichen lassen. Die Machine-Learning-Preise (und noch viel mehr) finden
            Sie im Modul <i>Preise</i>.
          </p>
          <h2 id={"comparables-module"}>Das Modul</h2>
          <p>
            Der Aufruf der Moduls Comparables erfolgt über den entsprechenden Befehl im Hauptmenü. Da die Comparables
            immer im Rahmen eines Assessments angezeigt werden, müssen Sie vor dem Öffnen des Moduls eventuell noch ein
            Assessment auswählen.
          </p>
          <FlexContainer direction={"column"} gap={4}>
            <img
              src="/assets/help/images-comparables/image3.png"
              alt="Das Modul Comparables mit Karte und Filtereinstellungen"
              width={"60%"}
              height={"auto"}
            />
            <i>Abb: Das Modul Comparables mit Karte und Filtereinstellungen</i>
          </FlexContainer>
          <p>
            Auf der rechten Seite des Moduls finden Sie eine Liste mit verschiedenen <b>Filtern</b>, über die Sie die
            gewünschten Comparables auswählen können. Die gefilterten Angebote werden dann je nach Modus entweder auf
            der <b>Karte</b> angezeigt oder im Tab <b>Analyse</b> in mehreren Charts aggregiert ausgewertet.
          </p>
          <h2 id={"comparables-datenquellen"}>Datenquellen</h2>
          <p>
            Unsere Comparables sind in mehrere Gruppen aufgeteilt, die sich aus den zugrundeliegenden{" "}
            <i>Datenquellen</i> ergeben. Welche Datenquelle angezeigt werden soll, lässt sich im ersten Dropdown der
            Filterleiste einstellen. Die gewählte Datenquelle hat nicht nur Einfluss auf die verfügbaren Comparables,
            sondern auch auf die nutzbaren Filter und die Analyse-Möglichkeiten
          </p>
          <ul className={styles.lists}>
            <li>
              <b>Angebotspreise - Historisch (21st)</b>
              <br />
              Diese Datenquelle enthält alle historischen Angebote, die wir seit 2004 sammeln. Regelmäßig kommen neue
              Angebote hinzu, die in den Wochen vorher veröffentlicht wurden.
            </li>
            <li>
              <b>Angebotspreise - Live (ImmoScout24)</b>
              <br />
              Diese Datenquelle zeigt nur solche Angebote, die aktuell bei ImmoScout24 live sind. Die Datenbasis ist
              also erheblich kleiner als bei den historischen Angeboten, dafür ist sie tagesaktuell und zeigt, was
              gerade am Markt passiert.
            </li>
            <li>
              <b>Transaktionspreise - Historisch (21st)</b>
              <br />
              Statt auf Angebotspreisen basiert diese Datenquelle auf tatsächlich realisierten Transaktionspreisen. Die
              Zahl der verfügbaren Angebote ist allerdings sehr gering.
            </li>
          </ul>
          <h2 id={"comparables-filter-spezifizieren"}>Filter spezifizieren</h2>
          <p>
            Typischerweise wird man sich nicht <i>alle</i> Comparables anschauen wollen, sondern eben nur solche
            Angebote, die mit dem betrachteten Projekt vergleichbar sind oder wenigstens relevante Einblicke
            versprechen. Dafür bieten wir rechts neben der Karte mehrere Filter, mit denen sich die Angebote eingrenzen
            lassen.{" "}
          </p>
          <ul className={styles.lists}>
            <li>
              <b>Nutzungsart</b>
              <br />
              Die Nutzungsart (z. B. Miete/Büro oder Kauf/Wohnen) wird zwar von Ihrem Assessment vorgegeben, kann aber
              für einen anderen Betrachtungswinkel jederzeit angepasst werden.
            </li>
            <li>
              <b>Radius / PLZ</b>
              <br />
              Im Zentrum der Analyse steht immer die aktuell begutachtete Adresse. Über den Radius können Sie festlegen,
              in welchem Umkreis Angebote angezeigt werden sollen. In ländlichen Gegenden, wo die Datendichte meist
              geringer ist, sollte man den Radius evtl. erhöhen, um eine aussagekräftige Anzahl von Angeboten zu
              erhalten. In der Stadt muss man eher darauf achten, dass ein zu großer Umkreis Angebote umfasst, die zwar
              vom Objekt her vergleichbar sind, aber nicht mehr von der Lage. Gleiches gilt, wenn Sie anhand des
              Postleitzahlgebietes auswählen.
            </li>
            <li>
              <b>Veröffentlichungsdatum</b>
              <br />
              Über den Zeitraum der Veröffentlichung lässt sich steuern, wann die zu betrachtenden Objekte am Markt
              angeboten wurden. Anhand des eingefärbten Zeitstrahls erkennen Sie, für welche Quartale überhaupt Angebote
              verfügbar sind. Für aktuelle Preise würde man typischerweise die letzten 4-8 Quartale wählen. Setzt man
              den Zeitraum allerdings zu weit, kann der Median an Aussagekraft verlieren. Durch schrittweises
              Verschieben des Zeitraums ist sogar eine historische Trendanalyse möglich. Dieser Filter kann komplett
              deaktiviert werden, bei der Auswahl der Angebote spielt das Veröffentlichungsdatum dann keine Rolle.
            </li>
            <li>
              <b>Angebotsdauer</b>
              <br />
              Wie lange ein Angebot öffentlich verfügbar war, kann ein interessanter Indikator für seine Qualität, aber
              auch grundsätzlich für die Marktlage sein. Über die zwei Regler lässt sich steuern, wie lange ein Angebot
              mindestens bzw. maximal online gewesen sein muss, um in die Auswahl aufgenommen zu werden.
            </li>
            <li>
              <b>Preis</b>
              <br />
              In den beiden Textfeldern für den Min- und Max-Preis wird bereits die gesamte Preisspanne der verfügbaren
              Angebote angezeigt. Sie können diese Werte anpassen, wenn Sie sich z. B. auf höherpreisige Angebote
              konzentrieren wollen.
            </li>
            <li>
              <b>Fläche</b>
              <br />
              Die Fläche eines Objekts gehört zu den grundlegenden Merkmalen. Wie schon bei den Preisen werden die Min-
              und Max-Werte der aktuellen Auswahl in den Textfeldern vorgegeben. Innerhalb dieser Spanne lassen sich die
              Werte bei Bedarf an das zu evaluierende Objekt anpassen.
            </li>
            <li>
              <b>Typ</b>
              <br />
              Für die Nutzungsart <i>Wohnen</i> (Miete oder Kauf) kann hier zwischen den beiden Optionen <i>Wohnung</i>{" "}
              (die klassische Einheit innerhalb eines Mehrfamilienhauses) und Haus (Einfamilienhaus, Doppelhaushälfte
              und Reihenhaus) unterschieden werden.
            </li>
            <li>
              <b>Räume</b>
              <br />
              Über diese beiden Felder lassen sich die gewünschte Anzahl der Räume eingrenzen. Wie schon bei der Fläche,
              muss dieser Filter extra aktiviert werden, da längst nicht alle Exposés die Anzahl der Räume angeben. Im
              Zweifelsfall also lieber durch Aktivieren/Deaktivieren des Filters überprüfen, wie die Datendichte davon
              beeinflusst wird.
            </li>
            <li>
              <b>Baujahr</b>
              <br />
              Neben der Fläche gehört das Baujahr zu den wichtigsten Merkmalen eines Objekts. Über die zwei Textfelder
              lässt sich ein Zeitraum festlegen, wobei für Neubauten auch Jahre in der Zukunft angegeben werden können.
              Allerdings wird dieser Filter nicht standardmäßig angewandt, sondern muss extra aktiviert werden. Das
              liegt daran, dass einige Exposés keine Angaben zum Baujahr machen. Solche Angebote ohne Baujahr fallen
              durch den Filter, egal, wie groß die Zeitspanne eingestellt wurde. Aktuell betrifft das etwa 20% der
              Angebote. Kommt der Filter zum Einsatz, muss man sich also bewusst sein, dadurch die Datendichte zu
              verringern. Im Zweifelsfall sollte man noch mal ohne den Filter gegenprüfen.
            </li>
          </ul>
          <h2 id={"comparables-auswertung"}>Auswertung</h2>
          <p>
            Die Auswertung der Comparables kann innerhalb der <i>Karte</i> oder im <i>Analyse</i> Tab erfolgen. Die
            Angebote und Auswertungen lassen sich außerdem in den Report übernehmen.
          </p>

          <p>
            In jedem Fall hängen die zur Analyse herangezogenen Angebote von den im letzten Abschnitt beschriebenen
            Filtereinstellungen ab. Bei geringer Datendichte und strengen Filtern werden evtl. nur sehr wenige
            Comparables angezeigt. Umgekehrt können es aber auch sehr viele werden – deshalb ist die maximale Anzahl für
            die Auswertung auf 10.000 beschränkt.
          </p>
          <h3 id={"comparables-karte"}>Karte</h3>
          <p>
            Basierend auf den aktuellen Filtereinstellungen werden im Tab Karte alle gefundenen Comparables als Punkte
            auf der Karte gezeigt. Je nach Zoom-Level werden nahe beieinanderliegende Angebote zusammengefasst, wobei
            die aggregierte Anzahl angezeigt wird.
          </p>
          <FlexContainer direction={"column"} gap={4}>
            <img
              src="/assets/help/images-comparables/image2.png"
              alt="Die im Umkreis befindlichen Comparables"
              width={"50%"}
              height={"auto"}
            />
            <i>Abb: Die im Umkreis befindlichen Comparables</i>
          </FlexContainer>

          <p>
            Nicht für alle Angebote ist die exakte Adresse bekannt. Fehlt z.B. die Hausnummer, wird der Kreis in der
            Mitte der Straße positioniert. Entsprechend wird verfahren, wenn nur die Postleitzahl bekannt ist.
          </p>
          <p>
            Wenn Sie ein Angebot anklicken, öffnet sich ein Popup mit weiteren Detailinformationen. Falls es sich um
            mehrere Angebote handelt, können Sie innerhalb des Popups über die zwei kleinen Pfeile von einem Angebot zum
            nächsten wechseln.
          </p>
          <FlexContainer direction={"column"} gap={4}>
            <img
              src="/assets/help/images-comparables/image5.png"
              alt="Detailinformationen für mehrere Comparables"
              width={"50%"}
              height={"auto"}
            />
            <i>Abb: Detailinformationen für mehrere Comparables</i>
          </FlexContainer>
          <p>
            In die Auswertung mit einbezogen werden maximal 10.000 Angebote. Falls noch mehr Angebote auf die
            Filtereinstellungen passen, werden die verwendet, die der begutachteten Adresse am nächsten sind.
          </p>
          <h3 id={"comparables-analyse"}>Analyse</h3>
          <p>
            Im Tab <i>Analyse</i> werden die gefilterten Comparables in ihrer Gesamtheit analysiert. Anhand von
            Daten-Tabellen oder Charts zeigen wir die <i>Preisentwicklung</i> (Trends) sowie die <i>Verteilung</i> für
            einzelne Merkmale (Quadratmeterpreis, Fläche, Angebote pro Quartal, Baujahr)
          </p>
          <p>
            Anhand des klassischen Histogramms für die Preisverteilung können Sie sehen, wie die Preisklassen
            zahlenmäßig verteilt sind. Wenn Sie die Maus über die einzelnen Balken oder den Median bewegen, erhalten Sie
            Detailinformationen zur Preisklasse und der Anzahl der darin befindlichen Angebote. Außerdem können Sie
            jederzeit von <i>Chart</i> zu <i>Tabelle</i> wechseln, um die Daten als Tabelle anzuzeigen. Innerhalb der
            Tabelle lassen sich markierte Felder kopieren, um sie anschließend z. B. in Excel einzufügen.
          </p>
          <FlexContainer direction={"column"} gap={4}>
            <img
              src="/assets/help/images-comparables/image4.png"
              alt="Das Histogramm zeigt die Preisverteilung incl. Median"
              width={"50%"}
              height={"auto"}
            />
            <i>Abb: Das Histogramm zeigt die Preisverteilung incl. Median</i>
          </FlexContainer>
          <h3 id={"comparables-report"}>Report</h3>
          <p>
            Die aktuellen Filtereinstellungen, die daraus resultierende Karte mit den Comparables, die Analyse-Charts
            sowie eine detaillierte Auflistung der zehn neuesten Angebote können Sie auch in den Report übernehmen und
            bei Bedarf in anderen Dokumenten weiterverwenden. Der Report lässt sich in allen Modulen über das
            Download-Icon oben rechts im Header erstellen.
          </p>
          <FlexContainer direction={"column"} gap={4}>
            <img
              src="/assets/help/images-comparables/image6.png"
              alt="Comparables – Ausschnitt aus dem Report"
              width={"100%"}
              height={"auto"}
            />
            <i>Abb: Comparables – Ausschnitt aus dem Report</i>
          </FlexContainer>
        </div>
      </Grid>
    </div>
  )
}
