import { Translations } from "./translations"

export const EN: Translations = {
  membersList: {
    header: "Members of your company",
    headerFieldId: "ID",
    headerFieldName: "Name",
    headerFieldSurname: "Surname",
    headerFieldEMail: "Mail",
    headerFieldActions: "Actions",
    headerFieldRole: "Role",
    admin: "admin",
    user: "user",
    promote: "Promote to Admin",
    revoke: "Revoke Admin privileges",
    addNewMember: "Invite new Member",
    language: {
      de: "German",
      en: "English",
    },
    nameLabel: "Given name",
    surnameLabel: "Surname",
    languageLabel: "Language",
    emailLabel: "Email",
    inviteMemberLabel: "Send invite",
    invalidEmail: "Email addresse is invalid",
    duplicateEmailErrorMsg: "Email address is already in use",
    headerFieldMemberStatus: "State",
    memberInvitationPending: "invitation pending",
    blocked: "blocked",
    memberDelete: "Delete",
    deleteUserText: "Are you sure you want to delete this user?",
  },
  settings: {
    header: "Setting for your company",
    logoUploadHeadline: "Company logo",
    logoUrlUploadButtonLabel: "Upload",
    logoUrlDeleteButtonLabel: "Delete",
    addressHeadline: "Address",
    streetLabel: "Street",
    houseNumberLabel: "House number",
    countryLabel: "Country",
    zipLabel: "Zip code",
    cityLabel: "City",
    additionalInfoLabel: "Additional address information",
    openEditAddressDialogLabel: "Edit",
    saveAddressButton: "Save",
    editAddressDialogTitle: "Change address",
    editAddressWarningText:
      "Changing your address will cause you to lose your verified status & badge. To earn that badge again, you need to verify your new address. After submitting your new address, a letter with a new verification code will be sent to your new address.",
    editAddressWarningCancelLabel: "Cancel",
    editAddressWarningConfirmLabel: "Change address anyway",
    editAddressSuccessText:
      "We have saved your new address and will send you a verification code by letter. Please enter this code here in order to regain your address verified status & badge.",
    okButtonLabel: "OK",
    cancelButtonLabel: "Cancel",
    addressVerifiedLabel: "verified",
    addressNotVerifiedLabel: "unverified",
    verifyAddressDialogTitle: "Verify your address",
    verifyAddressHint: "Your verification code (was sent by post)",
    verifyAddressConfirmButtonLabel: "Verify",
    addressVerificationSuccessText:
      "Your address is now successfully verified. Please re-login in order to apply changes.",
    logoutButtonLabel: "Logout",
    openValidateAddressDialogLabel: "Verify address",
    wrongVerificationCodeError: "Your entered verification code is not correct. Please try again.",
  },
  shortCode: "en",
  overview: {
    cardHeader: "Companies with product usage",
    companyId: "ID",
    companyName: "Company name",
  },
  booking: {
    currentlyActive: "Currently active",
    currentlyInactive: "Currently inactive",
    currentlyActiveStandard: "Currently active standard bundles",
    currentlyInactiveStandard: "Aktuell inactive standard bundles",
    createBooking: "Create booking",
    endDate: "end date",
    startDate: "start date",
    softLimit: "soft limit",
    bundle: "bundle",
    cancel: "Cancel",
    create: "Create",
    bookings: "Bookings",
    expireNow: "Terminate now",
    actions: "Actions",
    endBeforeStart: "The end date must be after the start date.",
    processedDone: "Processed successfully",
    processedPending: "Processing pending",
    createdBy: "Created by",
    expiredBy: "Terminated by",
    description: {
      W1: "Market Prices",
      G1: "Market Prices",
      W2: "Price trends + Market prices",
      G2: "Price trends + Market prices",
      W3: "Environment information + Price trends + Market prices",
      G3: "Environment information + Price trends + Market prices",
      W4: " Investment rating + Environment information + Price trends + Market prices",
      G4: " Investment rating + Environment information + Price trends + Market prices",
      W5: "Smart Data Report",
      W6: "Rent Index",
      W7: "Teas Api Valuation",
      BHYP: "Bhyp excel + bhyp ratings",
      XLF: "Excel functions",
      Valuation: "Valuation Service",
      FullShaping: "Complete shaping package",
      FullApiExplorer: "Complete API-Explorer package",
      Everything: "Everything (only for 21st employees please)",
      LanaProfile: "LANA - Profile Manager",
      LanaAssessment: "LANA - Location Assessment",
      LanaSpecialMaps: "LANA - Special Maps",
      LanaExplorer: "LANA - Location Explorer",
      LanaPrivateData: "LANA - Private Data",
      LanaComparables: "LANA - Location Comparables",
      LanaMarketData: "LANA - District Data",
      LanaPointsOfInterest: "LANA - Points of Interest",
      LanaLocationSelector: "LANA - Location Selector",
      LanaRatingManager: "LANA - Rating Manager",
      DAPIFull: "Data-API Full access",
    },
    gotoBookings: "Manage bookings",
    gotoLimits: "Manage limits",
  },
  dateFormat: "MM/DD/YYYY",
  invoiceItemsTranslations: {
    from: "from",
    to: "to",
    fetch: "fetch",
    items: "items",
    download: "download",
  },
  productUsage: {
    billableAssessments: "Billable Assessments",
    productUsage: "Product Usage",
    usageCount: "Amount used",
    deduplicationNotice: "Usage for a specific address is only counted once for a 6 month period.",

    quota: {
      title: "Available Quota",
      amount: "Total quota",
      availableNow: "Still available",
      startDate: "Start billing cycle",
      usedForPeriod: "Already used",
    },

    translateMonth: (m: number) => {
      switch (m) {
        case 1:
          return "January"
        case 2:
          return "February"
        case 3:
          return "March"
        case 4:
          return "April"
        case 5:
          return "May"
        case 6:
          return "June"
        case 7:
          return "July"
        case 8:
          return "August"
        case 9:
          return "September"
        case 10:
          return "October"
        case 11:
          return "November"
        case 12:
          return "December"
        default:
          return "???"
      }
    },
    nextMonth: "Next month",
    prevMonth: "Previous month",
  },
  readonlyBooking: {
    cardTitle: "Active bookings",
    numberOfBillableAssessments: "Number of this month's billable assessments",
  },
  api: {
    apiToken: {
      listHeader: "API tokens",
      label: "Label",
      token: "Token",
      createdAt: "Created at",
      lastUsed: "Last used",
      active: "Active",
      revokeToken: "Revoke API token",
      revoke: "Revoke",
      createToken: "Generate new API Token",
      create: "Create",
      copyToClipboard: "Copy token to clipboard",
      labelHint: "Here you can set a short name for the token.",
      revokeExplanation: "Are you sure you want to revoke this token? It will not be useable anymore.",
    },
    cancel: "Cancel",
    close: "Close",
    demoInfo: "This links to a site which demos the use of our API with your token.",
    cardHeader: "Card header",
    panelHeader: "Panel header",
    buttonText: "Button text",
    dateFormat: "MM/DD/YYYY",
    dateTimeFormat: "MM/DD/YYYY h:mm a",
    shortLanguageName: "en",
    stats: {
      title: "API token usage",
      month: "Month",
      year: "Year",
      doFetch: "See usage",
      apiNameHeader: "API name",
      callsHeader: "Number of calls",
      translateMonth: (m: number) => {
        switch (m) {
          case 1:
            return "January"
          case 2:
            return "February"
          case 3:
            return "March"
          case 4:
            return "April"
          case 5:
            return "May"
          case 6:
            return "June"
          case 7:
            return "July"
          case 8:
            return "August"
          case 9:
            return "September"
          case 10:
            return "October"
          case 11:
            return "November"
          case 12:
            return "December"
          default:
            return "???"
        }
      },
      nextMonth: "Next month",
      prevMonth: "Previous month",
      statsLink: "Usage statistics",
      noCallsFor: (label: string) => `The token ${label} was not used in this timeframe`,
      explanation: (label: string, month: string, year: number) =>
        `These are the usage statistics for the token ${label} in ${month} ${year}. Requests in the test region around Berlin 10117 and errored API requests are not counted`,
      invoiceLinkText: "invoice overview",
      invoiceLinkPrefix: "To see which calls are accounting relevant, head to the",
    },
    apiDocExplanationBeforeLink: "Please refer to the API documentation",
    here: "here",
  },
}
