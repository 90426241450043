import { useAppSelector } from "../../relas/store"
import * as React from "react"
import { translations } from "../i18n"
import { IconName } from "@21re/revoicons/dist/icon-type"
import { Flex } from "../../shared/components/ui/flex"
import { FlexItem } from "../../shared/components/ui/flex-item"
import { useEffect } from "react"
import { cancelAddProfile, createProfile, createGroup, updateProfileName, updateGroupName } from "../profile-slice"
import Card from "../../shared/components/card"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import Panel from "../../shared/components/panel"
import Grid from "../../shared/components/restyle-grid/grid"
import TextField from "../../shared/components/textfield"
import { GenericError } from "../../shared/helper/axios"
import Button from "../../shared/components/button"
import Form from "../../shared/components/form"

interface OwnProps {
  mode: "create" | "rename" | "duplicate" | "createGroup" | "renameGroup"
}

export const ProfileNameEdit = ({ mode }: OwnProps) => {
  const t = translations()

  const createProfileInProgress = useAppSelector((state) => state.profile.profileSaveInProgress)
  const profileSaveError = useAppSelector((state) => state.profile.profileSaveError)
  const currentProfile = useAppSelector((state) => state.profile.profile)
  const currentGroup = useAppSelector((state) => state.profile.group)
  const isGroupError = useAppSelector((state) => state.profile.isGroupError)
  const scores = useAppSelector((state) => state.profile.scores)

  const [newProfileName, setNewProfileName] = React.useState<string>(
    (() => {
      switch (mode) {
        case "rename":
          return currentProfile ? currentProfile.name : ""

        case "duplicate":
          return currentProfile ? currentProfile.name + t.profileDuplicate.nameSuffix : ""

        case "renameGroup":
          return currentGroup ? currentGroup.name : ""

        default:
          return ""
      }
    })()
  )

  useEffect(() => {
    let newProfileName = ""

    switch (mode) {
      case "rename":
        newProfileName = currentProfile ? currentProfile.name : ""
        break
      case "duplicate":
        newProfileName = currentProfile ? currentProfile.name + t.profileDuplicate.nameSuffix : ""
        break
      case "renameGroup":
        newProfileName = currentGroup ? currentGroup.name : ""
        break
    }

    setNewProfileName(newProfileName)
  }, [currentProfile, currentGroup, mode])

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    event.stopPropagation()
    switch (mode) {
      case "rename":
        currentProfile && currentProfile.id && void updateProfileName(currentProfile.id, newProfileName)
        break
      case "duplicate":
        void createProfile({
          name: newProfileName,
          scores,
        })
        break

      case "renameGroup":
        currentGroup && void updateGroupName(currentGroup.id, newProfileName)
        break

      case "createGroup":
        void createGroup({
          name: newProfileName,
        })
        break
      default:
        void createProfile({
          name: newProfileName,
          scores: { micro: {}, macro: {} },
        })
        break
    }
  }

  const renderProfileSaveError = (error: GenericError, isGroupError: boolean) => {
    if (error.status && error.status === 409) {
      return <Panel color="negative">{isGroupError ? t.groupAlreadyExists : t.profileAlreadyExists}</Panel>
    }
    return <GenericErrorPanel error={error} />
  }

  let title = t.profileAdd.title
  let submitIcon: IconName = "add"
  let submitText = t.profileAdd.create

  switch (mode) {
    case "renameGroup":
      title = t.groupRename.title
      submitIcon = "save"
      submitText = t.save
      break
    case "rename":
      title = t.profileRename.title
      submitIcon = "save"
      submitText = t.save
      break
    case "createGroup":
      title = t.profileGroup.title
      break
    case "duplicate":
      title = t.profileDuplicate.title
      break
  }
  const header = (
    <Flex flexDirection="column" gap={8}>
      <FlexItem alignSelf="start">
        <Button type="tertiary" icon="back" onClick={cancelAddProfile}>
          {t.profileList.backTo}
        </Button>
      </FlexItem>
      <h2>{title}</h2>
    </Flex>
  )

  return (
    <Grid height={[100, "%"]} columns={1}>
      <Card id="profileNameEdit" header={header}>
        <Form onSubmit={onSubmit}>
          <Grid columns={1} gap={12}>
            <TextField
              javaScriptAutoFocus={true}
              disabled={createProfileInProgress}
              value={newProfileName}
              onValueChange={(value) => setNewProfileName(value)}
              label={t.profileAdd.name}
            />
            <Flex flexDirection="row-reverse" gap={16}>
              <Button
                icon={submitIcon}
                loading={createProfileInProgress}
                disabled={newProfileName === ""}
                formType="submit"
                type="primary"
              >
                {submitText}
              </Button>
              <Button
                type="secondary"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  cancelAddProfile()
                }}
              >
                {t.cancel}
              </Button>
            </Flex>
            {profileSaveError && renderProfileSaveError(profileSaveError, isGroupError)}
          </Grid>
        </Form>
      </Card>
    </Grid>
  )
}
