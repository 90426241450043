export type RouteParams = {
  [i: string]: string
}

export interface RouteAction {
  (params: RouteParams): Promise<any>
}

export interface RouteDefinition<T = void, NAVPAGE = string> {
  name: NAVPAGE
  action: RouteAction
  tag?: T
}

export type Routes<T = void, NAVPAGE = string> = {
  [uriPattern: string]: RouteDefinition<T, NAVPAGE>
}

interface LiteralPathParam {
  type: "literal"
  value: string
}

interface RoutePathParsedParam {
  type: "param"
  name: string
}

type RoutePathParam = LiteralPathParam | RoutePathParsedParam

export class RoutePath {
  public patten: string

  private pathParts: Array<RoutePathParam>

  private readonly optionalQueryParams: Array<string>
  private readonly requiredQueryParams: Array<string>
  private readonly allQueryParams: Array<string>

  // Example:
  // /user/:userId?requiredParam&*optionalParam1&*optionalParam2

  constructor(public pattern: string) {
    this.patten = pattern

    this.pathParts = []
    this.optionalQueryParams = []
    this.requiredQueryParams = []
    this.allQueryParams = []

    const urlParts = pattern.split("?", 2)

    const pathParts = urlParts[0].split("/")

    pathParts.forEach((pathParam) => {
      if (pathParam[0] === ":") {
        this.pathParts.push({
          type: "param",
          name: pathParam.slice(1),
        })
      } else {
        this.pathParts.push({
          type: "literal",
          value: pathParam,
        })
      }
    })

    if (urlParts.length > 1) {
      const query = urlParts[1].split("&")

      query.forEach((paramName) => {
        const cleanParamName = decodeURIComponent(paramName.trim())
        if (cleanParamName.length > 0) {
          if (cleanParamName[0] === "!") {
            // required param
            const p = cleanParamName.slice(1)
            this.allQueryParams.push(p)
            this.requiredQueryParams.push(p)
          } else {
            // optional param - may start with ":" - backward compatibility
            const p = cleanParamName[0] == ":" ? cleanParamName.slice(1) : cleanParamName
            this.allQueryParams.push(p)
            this.optionalQueryParams.push(p)
          }
        }
      })
    }
  }

  public build(params?: RouteParams): string | null {
    const processedPathParams: Array<string | false> = this.pathParts.map((param) => {
      switch (param.type) {
        case "literal":
          return param.value
        case "param":
          return (params && encodeURIComponent(params[param.name])) || false
      }
    })

    const processedRequiredQueryParams = this.requiredQueryParams.map((paramName) =>
      params && params[paramName] !== undefined ? [paramName, params[paramName]] : false
    )

    const processedOptionalQueryParams = this.optionalQueryParams.map((paramName) =>
      params && params[paramName] !== undefined ? [paramName, params[paramName]] : false
    )

    if (processedPathParams.indexOf(false) !== -1 || processedRequiredQueryParams.indexOf(false) !== -1) {
      return null
    } else {
      const path = processedPathParams.join("/")

      const encodedQueryParamsUnordered = new Map(
        [...processedOptionalQueryParams.filter((v) => typeof v !== "boolean"), ...processedRequiredQueryParams].map(
          (v) => v as [string, string]
        )
      )

      const encodedQueryParams = this.allQueryParams
        .map((paramName) =>
          encodedQueryParamsUnordered.has(paramName)
            ? encodeURIComponent(paramName) + "=" + encodeURIComponent(encodedQueryParamsUnordered.get(paramName)!)
            : false
        )
        .filter((v) => typeof v !== "boolean")

      return path + (encodedQueryParams.length ? "?" + encodedQueryParams.join("&") : "")
    }
  }

  public test(uri: string): RouteParams | false {
    const urlParts = uri.split("?", 2)

    const pathParts = urlParts[0].split("/")

    if (pathParts.length != this.pathParts.length) return false

    const maybeCapturedPathParams = this.pathParts
      .map((p, idx) => {
        const decodedPart = decodeURIComponent(pathParts[idx])
        switch (p.type) {
          case "param":
            return [p.name, decodedPart]
          case "literal":
            // we mark literal matched path parts as null and failed as "false"
            return p.value === decodedPart ? null : false
        }
      })
      .filter((params) => params !== null)

    if (maybeCapturedPathParams.indexOf(false) !== -1) return false

    const capturedPathParams = maybeCapturedPathParams.filter((p) => p != null).map((p) => p as [string, string])

    const queryParams: Array<[string, string]> =
      urlParts.length > 1
        ? urlParts[1].split("&").map((v) => {
            const parts = v.split("=", 2)
            const decodedParamName = decodeURIComponent(parts[0])
            if (parts.length === 1) {
              return [decodedParamName, ""] as [string, string]
            } else {
              return [decodedParamName, decodeURIComponent(parts[1])] as [string, string]
            }
          })
        : []

    const queryParamsMap = new Map(queryParams)

    const extractedQueryParams = new Map<string, string>()

    if (this.requiredQueryParams.length > 0) {
      if (queryParamsMap.size >= this.requiredQueryParams.length) {
        const extractedParams: Array<[string, string] | boolean> = this.requiredQueryParams.map((name) =>
          queryParamsMap.has(name) ? [name, queryParamsMap.get(name)!] : false
        )

        if (extractedParams.indexOf(false) !== -1) return false

        extractedParams.forEach((value) => {
          if (typeof value !== "boolean") {
            extractedQueryParams.set(value[0], value[1])
          }
        })
      } else {
        // we lack required query params
        return false
      }
    }

    this.optionalQueryParams.forEach((name) => {
      if (queryParamsMap.has(name)) {
        extractedQueryParams.set(name, queryParamsMap.get(name)!)
      }
    })

    const allCapturedParams = [...capturedPathParams, ...extractedQueryParams]

    return allCapturedParams.reduce((obj, [key, value]) => {
      obj[key] = value
      return obj
    }, {} as RouteParams)
  }
}

class RouteMatcher<T = void> {
  public path: RoutePath
  public name: string
  public action: RouteAction
  public tag?: T

  constructor(public uriPattern: string, definition: RouteDefinition<T>) {
    this.path = new RoutePath(uriPattern)
    this.name = definition.name
    if (typeof definition.action !== "object") {
      this.action = definition.action
    }
    this.tag = definition.tag
  }
}

type QueryParams = { [name: string]: string }
export class Router<T = void> {
  private lastUri: string = ""
  private readonly matchers: RouteMatcher<T>[]
  private navigating: boolean
  /**
   * Before run action is called before any route action is called. If it returns false, the route action is not called.
   * @private
   */
  private readonly beforeRunAction: (routeName: String, params: QueryParams, tag?: T) => boolean

  constructor(
    routes: Routes<T>,
    beforeRunAction: (routeName: String, params: QueryParams, tag?: T) => boolean = () => true
  ) {
    this.matchers = []
    this.navigating = false
    for (const uriPattern in routes) {
      if (routes.hasOwnProperty(uriPattern)) {
        this.matchers.push(new RouteMatcher<T>(uriPattern, routes[uriPattern]))
      }
    }
    this.beforeRunAction = beforeRunAction
  }

  findRoute(uri: string): { matcher: RouteMatcher<T>; params: RouteParams } | null {
    for (const matcher of this.matchers) {
      const match = matcher.path.test(uri)

      if (match) {
        const params: RouteParams = typeof match === "boolean" ? {} : (match as RouteParams)
        return { matcher, params }
      }
    }
    return null
  }

  handleUri(uri: string): Promise<any> | null {
    const route = this.findRoute(uri)

    if (route) {
      return this.runAction(route.matcher, route.params)
    }
    return null
  }

  uriFor(pageName: string, params: RouteParams): string {
    for (const matcher of this.matchers) {
      if (matcher.name === pageName) {
        const uri = matcher.path.build(params)

        return uri || "/"
      }
    }
    return "/"
  }

  routeMatcherFor(pageName: string): RouteMatcher<T> | null {
    for (const matcher of this.matchers) {
      if (matcher.name === pageName) {
        return matcher
      }
    }
    return null
  }

  navigateTo(pageName: string, params: QueryParams) {
    for (const matcher of this.matchers) {
      if (matcher.name === pageName) {
        void this.runAction(matcher, params)
        return
      }
    }
  }

  pushHistory(pageName: string, params: QueryParams) {
    if (this.navigating) return

    const uri = this.uriFor(pageName, params)

    if (uri === this.lastUri) {
      return
    }

    if (this.lastUri.length === 0) {
      history.replaceState(null, "", uri)
    } else {
      history.pushState(null, "", uri)
    }

    this.lastUri = uri

    return this.routeMatcherFor(pageName)
  }

  private runAction(matcher: RouteMatcher<T>, params: QueryParams): Promise<any> {
    if (this.beforeRunAction(matcher.name, params, matcher.tag)) {
      this.navigating = true
      return matcher.action(params).then(
        () => {
          this.navigating = false
          this.pushHistory(matcher.name, params)
        },
        () => {
          this.navigating = false
        }
      )
    } else {
      this.navigating = false
      return Promise.resolve()
    }
  }
}

export function bindHistory<T>(router: Router<T>) {
  const handleUri = () => {
    void router.handleUri(window.location.pathname + window.location.search)
  }

  window.onpopstate = handleUri
}
