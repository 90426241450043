import React, { useEffect, useState } from "react"
import {
  Cell,
  Column,
  ColumnHeaderCell,
  IColumnHeaderRenderer,
  IColumnProps,
  IRegion,
  RegionCardinality,
  RenderMode,
  Table,
} from "@blueprintjs/table"
import { Checkbox, Icon } from "@blueprintjs/core"
import { COLORS } from "../../../shared/components/ui/colors"
import { Flex } from "../../../shared/components/ui/flex"
import { FlexItem } from "../../../shared/components/ui/flex-item"
import { Grid } from "../../../shared/components/ui/grid"
import { ScrollBox } from "../../../shared/components/ui/scroll-box"
import { css, cx } from "emotion"
import { IconNames } from "@blueprintjs/icons"
import { translations } from "../../i18n"
import {
  isInProgressState,
  PrivatePOI,
  PrivatePOICategory,
  PrivatePOIList,
  StructuredData,
} from "../../models/private-data"
import { Tooltip } from "../../../shared/components/ui/tooltip"
import { CorrectedAddressTooltip } from "../../../shared/components/corrected-address-tooltip"
import { TruncatedTextCell } from "../../../shared/components/truncated-text-cell"
import { fetchCategoryPois } from "../../reducers/private-data-slice"
import { getThemeColorVar } from "../../../shared/helper/color"
import { SortDirection } from "../../../shared/models/sorting"
import LoadingSpinner from "../../../shared/components/loadingspinner"
import { HeaderSorting } from "../../../shared/components/sortable-table-header"
import { Icon as IconReStyle } from "../../../shared/components/icon"

const styles = {
  pointer: css({
    cursor: "pointer",
  }),
  header: css({
    justifySelf: "center",
    maxWidth: "100%",
    fontSize: "16px",
    fontWeight: "bold",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    minHeight: "32px",
  }),
  headerFlex: css({
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    alignItems: "center",
    padding: "4px 8px",
  }),
  emptyHeader: css({
    minHeight: "24px",
  }),
  cell: css({
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "24px",
    ":hover": {
      background: COLORS.background.lighter,
    },
  }),
  shrinkableText: css({
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  }),
  TableContainer: (displayContainedMessage: boolean) =>
    css(
      displayContainedMessage
        ? {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background: COLORS.background.lighter,
          }
        : {
            height: "100%",
            background: COLORS.background.lighter,
          }
    ),
  noPoisContainer: css({
    display: "flex",
    alignItems: "center",
    background: "white",
    padding: "16px",
    border: `1px solid ${getThemeColorVar("border", "default")}`,
  }),
}
export interface SelectedRow extends IRegion {
  poiId: string
}
export type POIColumnHeader = "id" | "title" | "notes" | "data"
type Props = {
  checkedPOIRows: Set<string>
  setCheckedPOIRows: (rows: Set<string>) => void
  selectedRow?: SelectedRow[]
  setSelectedRow: (row: SelectedRow[] | undefined) => void
  poiList: PrivatePOIList
  selectedCategory: PrivatePOICategory | undefined
  poiListLoading: boolean
}
const t = translations()

const arrowIcon = (size: number, direction: SortDirection) => (
  <Icon
    icon={direction === SortDirection.ASC ? IconNames.ARROW_UP : IconNames.ARROW_DOWN}
    color={getThemeColorVar("primary", "default")}
    iconSize={size}
  />
)

const emptyColumnHeader = (): IColumnHeaderRenderer => {
  return () => (
    <ColumnHeaderCell>
      <div className={styles.header}>&nbsp;</div>
    </ColumnHeaderCell>
  )
}

export const PrivatePoisTable = ({
  selectedRow,
  setSelectedRow,
  checkedPOIRows,
  setCheckedPOIRows,
  poiList,
  poiListLoading,
  selectedCategory,
}: Props) => {
  const [localPoiList, setLocalPoiList] = useState<PrivatePOIList>(poiList)
  const [columnWidths, setColumnWidths] = useState<Array<number>>([30, 80, 150, 80, 150, 200, 200, 200])

  const [privatePOIsSorting, setPrivatePOIsSorting] = useState<HeaderSorting<string>>([SortDirection.ASC, "title"])

  useEffect(() => {
    if (poiListGeocodeFinished()) sortRows(poiList)
    else if (selectedCategory) void fetchCategoryPois(selectedCategory?.id)
  }, [poiList])

  const categoryIsSelected = () => !!selectedCategory

  const poiListGeocodeFinished = () => {
    return poiList.every((poi) => !isInProgressState(poi))
  }
  const sortRows = (poiList: PrivatePOIList) => {
    let tempLocalPoiList = sortPrivatePOIs(privatePOIsSorting, poiList)
    setLocalPoiList(tempLocalPoiList)
    if (selectedRow && selectedRow.length > 0) {
      let tempCurrentSelectedRow = selectedRow ? [...selectedRow] : []
      const currentSelectedPoi = tempLocalPoiList.find((poi) => poi.id === tempCurrentSelectedRow[0].poiId)
      if (currentSelectedPoi) {
        const rowIndex = tempLocalPoiList.indexOf(currentSelectedPoi)
        tempCurrentSelectedRow[0].rows = [rowIndex, rowIndex]
        setSelectedRow([...tempCurrentSelectedRow])
      }
    }
  }
  const sortPrivatePOIs = (sorting: HeaderSorting<string>, poiList: PrivatePOIList) => {
    let sorter: (a: PrivatePOI, b: PrivatePOI) => number

    switch (sorting[1]) {
      case "id":
        sorter = (a, b) => a.companyAssignedId?.localeCompare(b.companyAssignedId || "") || 0
        break
      case "title":
        sorter = (a, b) => a.title?.localeCompare(b.title || "") || 0
        break
      case "locality":
        sorter = (a, b) => a.address.locality?.localeCompare(b.address.locality || "") || 0
        break
      case "postalCode":
        sorter = (a, b) => a.address.postalCode?.localeCompare(b.address.postalCode || "") || 0
        break
      case "route":
        sorter = (a, b) => {
          const aStreetWithNumber = a.address.route
            ? a.address.route + (a.address.streetNumber ? " " + a.address.streetNumber : "")
            : ""
          const bStreetNumber = b.address.route
            ? b.address.route + (b.address.streetNumber ? " " + b.address.streetNumber : "")
            : ""
          return aStreetWithNumber.localeCompare(bStreetNumber) || 0
        }
        break
      case "notes":
        sorter = (a, b) => a.notes?.localeCompare(b.notes || "") || 0
        break
      default:
        sorter = (a, b) => a.title?.localeCompare(b.title || "") || 0
        break
    }
    sorter = compareAscendingOrDescending(sorting[0], sorter)

    return [...poiList].sort((a, b) => sorter(a, b))
  }

  function compareAscendingOrDescending(
    sortDirection: SortDirection,
    sortFn: (a: PrivatePOI, b: PrivatePOI) => number
  ): (a: PrivatePOI, b: PrivatePOI) => number {
    return (a: PrivatePOI, b: PrivatePOI) => (sortDirection === SortDirection.ASC ? sortFn(a, b) : sortFn(b, a))
  }

  const onChangeSorting = (sortField: string): (() => void) => {
    return () => {
      let tempCurrentSelectedRow = selectedRow ? [...selectedRow] : []
      let tempLocalPoiList: PrivatePOIList = []
      if (privatePOIsSorting[1] !== sortField || privatePOIsSorting[0] === SortDirection.DESC) {
        tempLocalPoiList = sortPrivatePOIs([SortDirection.ASC, sortField], localPoiList)
        setLocalPoiList([...tempLocalPoiList])
        setPrivatePOIsSorting([SortDirection.ASC, sortField])
      } else {
        tempLocalPoiList = sortPrivatePOIs([SortDirection.DESC, sortField], localPoiList)
        setLocalPoiList([...tempLocalPoiList])
        setPrivatePOIsSorting([SortDirection.DESC, sortField])
      }
      if (tempCurrentSelectedRow.length > 0) {
        const currentSelectedPoi = tempLocalPoiList.find((poi) => poi.id === tempCurrentSelectedRow[0].poiId)
        if (currentSelectedPoi) {
          const rowIndex = tempLocalPoiList.indexOf(currentSelectedPoi)
          tempCurrentSelectedRow[0].rows = [rowIndex, rowIndex]
          setSelectedRow([...tempCurrentSelectedRow])
        }
      }
    }
  }

  const sortableColumnHeader = <T extends string>(
    columnHeader: T,
    getLabel: (s: T) => string
  ): IColumnHeaderRenderer => {
    return () => (
      <ColumnHeaderCell>
        <div className={cx(styles.pointer, styles.header, styles.headerFlex)} onClick={onChangeSorting(columnHeader)}>
          {getLabel(columnHeader)}
          {privatePOIsSorting[1] === columnHeader && arrowIcon(14, privatePOIsSorting[0])}
        </div>
      </ColumnHeaderCell>
    )
  }

  const addressColumnHeader = (sortField: "postalCode" | "locality" | "route"): IColumnHeaderRenderer => {
    return sortableColumnHeader(sortField, (s) => t.privateData.address[sortField])
  }

  const objectColumnHeader = (columnHeader: POIColumnHeader): IColumnHeaderRenderer => {
    return sortableColumnHeader(columnHeader, (s) => t.privateData[columnHeader])
  }

  const onSelectEntry = (regions: IRegion[]) => {
    if (regions.length === 0 || !regions[0].rows) return
    const checkedPOI = localPoiList[regions[0].rows[0]]
    const checkedEntriesFromState = new Set(checkedPOIRows)
    if (regions[0].cols && regions[0].cols[0] === 0 && regions[0].cols[1] === 0) {
      const row = selectedRow
      setSelectedRow(undefined)
      checkedPOIRows.has(checkedPOI.id)
        ? checkedEntriesFromState.delete(checkedPOI.id)
        : checkedEntriesFromState.add(checkedPOI.id)
      setCheckedPOIRows(checkedEntriesFromState)
      setSelectedRow(row)
      return
    }
    if (selectedRow && selectedRow[0].rows && selectedRow[0].rows[0] === regions[0].rows[0]) setSelectedRow(undefined)
    else setSelectedRow([{ cols: null, rows: [regions[0].rows[0], regions[0].rows[0]], poiId: checkedPOI.id }])
  }

  const categoryIsEmpty = () => {
    return localPoiList.length === 0 && categoryIsSelected()
  }
  const isLoadingOrListIsEmpty = () => {
    return poiListLoading || categoryIsEmpty()
  }

  const getProcessedStructuredData = (data?: StructuredData): string =>
    !data ? "" : data.map(([header, value]) => `${header}: ${value}`).join("\n")

  const columns: Array<React.ReactElement<IColumnProps>> = [
    <Column
      key="checked"
      columnHeaderCellRenderer={emptyColumnHeader()}
      cellRenderer={(rowIdx) => (
        <Cell className={styles.cell}>
          <Checkbox checked={checkedPOIRows.has(localPoiList[rowIdx].id)} />
        </Cell>
      )}
    />,
    <Column
      key="id"
      columnHeaderCellRenderer={objectColumnHeader("id")}
      cellRenderer={(rowIdx) => <Cell className={styles.cell}>{localPoiList[rowIdx].companyAssignedId}</Cell>}
    />,
    <Column
      key="title"
      columnHeaderCellRenderer={objectColumnHeader("title")}
      cellRenderer={(rowIdx) => <Cell className={styles.cell}>{localPoiList[rowIdx].title}</Cell>}
    />,
    <Column
      key="postalCode"
      columnHeaderCellRenderer={addressColumnHeader("postalCode")}
      cellRenderer={(rowIdx) => <Cell className={styles.cell}>{localPoiList[rowIdx].address.postalCode}</Cell>}
    />,
    <Column
      key="locality"
      columnHeaderCellRenderer={addressColumnHeader("locality")}
      cellRenderer={(rowIdx) => <Cell className={styles.cell}>{localPoiList[rowIdx].address.locality}</Cell>}
    />,
    <Column
      key="route"
      columnHeaderCellRenderer={addressColumnHeader("route")}
      cellRenderer={(rowIdx) => (
        <Cell className={styles.cell}>
          <Grid columns={2} columnSpec={"1fr min-content"}>
            <span className={styles.shrinkableText}>
              {localPoiList[rowIdx].address.route} {localPoiList[rowIdx].address.streetNumber}
            </span>
            {localPoiList[rowIdx].droppedLocation === undefined && localPoiList[rowIdx].originalAddress && (
              <Tooltip
                placement="auto"
                tooltip={<CorrectedAddressTooltip entry={localPoiList[rowIdx]} typeToUse={"poi"} />}
                inverted={true}
              >
                <IconReStyle name="warning" fontSize={14} />
              </Tooltip>
            )}
          </Grid>
        </Cell>
      )}
    />,
    <Column
      key="notes"
      columnHeaderCellRenderer={objectColumnHeader("notes")}
      cellRenderer={(rowIdx) => (
        <Cell className={styles.cell}>
          <TruncatedTextCell data={localPoiList[rowIdx].notes} />
        </Cell>
      )}
    />,
    <Column
      key="data"
      columnHeaderCellRenderer={objectColumnHeader("data")}
      cellRenderer={(rowIdx) => (
        <Cell className={styles.cell}>
          <TruncatedTextCell data={getProcessedStructuredData(localPoiList[rowIdx].structuredData)} />
        </Cell>
      )}
    />,
  ]
  const getContainedItems = (): JSX.Element | undefined => {
    if (isLoadingOrListIsEmpty()) {
      if (poiListLoading) {
        return (
          <Flex flexDirection={"column"} alignItems={"center"}>
            <FlexItem>
              <LoadingSpinner size={32} />
            </FlexItem>
          </Flex>
        )
      }
      if (categoryIsEmpty()) {
        return (
          <Flex flexDirection={"column"} alignItems={"center"} width={[40, "%"]}>
            <div className={styles.noPoisContainer}>
              <p>{t.privateData.noPoisAvailable}</p>
            </div>
          </Flex>
        )
      } else return undefined
    } else {
      if (categoryIsSelected())
        return (
          <ScrollBox>
            <Table
              columnWidths={columnWidths}
              numRows={localPoiList.length}
              minRowHeight={24}
              defaultRowHeight={24}
              enableRowHeader={false}
              renderMode={RenderMode.BATCH}
              selectionModes={[RegionCardinality.CELLS]}
              onSelection={onSelectEntry}
              selectedRegions={selectedRow}
              onColumnWidthChanged={(index: number, size: number) =>
                setColumnWidths([...columnWidths.slice(0, index), size, ...columnWidths.slice(index + 1)])
              }
            >
              {columns}
            </Table>
          </ScrollBox>
        )
      else return undefined
    }
  }

  return <div className={styles.TableContainer(isLoadingOrListIsEmpty())}>{getContainedItems()}</div>
}
