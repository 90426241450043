import { RatingResults } from "../shared/models/ratings"
import {
  isRuleCategoryWithField,
  RatingSelectionRule,
  ruleCategories,
  SelectionResults,
} from "../shared/models/selection"
import { FieldOption, getFieldOptions } from "../shared/models/field-option"
import { RatingManagerTranslations } from "./i18n/translations"
import { DataSetType } from "../shared/models/smartdata"
import { LANA_AGS_NODES, LANA_CELL_NODES } from "../shared/smartdata-products/smartdata"
import * as React from "react"
import { formatNumber } from "../shared/helper/number-format"

export function isRatingsResults(ratingResults: RatingResults | SelectionResults): ratingResults is RatingResults {
  return "originalCount" in ratingResults && ratingResults.results.some((r) => "gradeIdx" in r)
}

export async function getRuleCriteria(
  rule: RatingSelectionRule,
  dataSetType: DataSetType,
  t: RatingManagerTranslations,
  additionalName?: string
): Promise<string | JSX.Element> {
  let fieldOptions: FieldOption[] = []
  switch (rule.type) {
    case "loadmacrodata":
      return t.country[rule.country]
    case "loadmicrodata":
      fieldOptions = await getFieldOptions("micro", "ags_ref_res_loc")
      return fieldOptions.find((option) => option.key === rule.exampleAgsRefResLoc)?.label ?? ""
    case "districtdatarule":
    case "rangefilter": {
      let scoreName: string | undefined

      if ("field" in rule && rule.field.startsWith("raw_")) {
        const node =
          LANA_CELL_NODES.find((n) => n.rawName === rule.field) ?? LANA_AGS_NODES.find((n) => n.rawName === rule.field)

        scoreName = `${node && t.pickTranslation(node.title)}`
      }

      const inclusionLabel = rule.exclusive ? t.selectionRuleEdit.exclusiveLabel : t.selectionRuleEdit.inclusiveLabel
      const fromLabel = typeof rule.from === "number" ? `>= ${formatNumber(rule.from)}` : ""
      const toLabel = typeof rule.to === "number" ? `< ${formatNumber(rule.to)}` : ""

      return (
        <>
          {scoreName && (
            <>
              {scoreName}
              <br />
            </>
          )}
          {inclusionLabel}: {fromLabel} {toLabel}
        </>
      )
    }
    case "oneoffilter":
      const ruleCategory = ruleCategories.find((c) => isRuleCategoryWithField(c) && c.field === rule.field)

      fieldOptions = await getFieldOptions(dataSetType, rule.field)

      const values = rule.selections.map((key) => fieldOptions.find((s) => s.key === key)?.label).join(", ")

      const exclusivityLabel =
        ruleCategory?.id === "oneoffilter::macro::nuts1"
          ? ""
          : rule.exclusive
          ? t.selectionRuleEdit.exclusiveLabel + ": "
          : t.selectionRuleEdit.inclusiveLabel + ": "
      return `${exclusivityLabel} ${values}`
    case "rankbyprofile":
      return (
        <>
          {additionalName && (
            <>
              {additionalName}
              <br />
            </>
          )}
          {rule.rescale ? t.relative : t.absolute}:&nbsp;
          {formatNumber(rule.from, 0)} - {formatNumber(rule.to, 0)}
        </>
      )
    case "rankbyscores":
      const nodes = [...LANA_CELL_NODES, ...LANA_AGS_NODES]
        .filter((score) => score.scoreName in rule.scores)
        .map((node) => t.pickTranslation(node.title))
        .join(", ")
      return (
        <>
          {nodes}
          <br />
          {rule.rescale ? t.relative : t.absolute}:&nbsp;
          {formatNumber(rule.from, 0)} - {formatNumber(rule.to, 0)}
        </>
      )
    case "selectranks":
      const topBottom = rule.fromTop ? t.selectionRuleEdit.selectRankTop : t.selectionRuleEdit.selectRankBottom

      return (
        <>
          {rule.relative ? t.percentage : t.absolute}:&nbsp;{topBottom}&nbsp;
          {formatNumber(rule.value, 0, rule.relative ? "%" : "")}
        </>
      )
    case "prefixfilter":
      //unused
      return ""
  }
}
