import { AssessmentTranslationsGroup } from "./translations"
import { SHARED_DE } from "../../shared/i18n/de"
import { FileProperties } from "../models/assessment"
import { default as React } from "react"
import { ComparablesAppPageType } from "../components/comparables/comparablesV2"

export const ASSESSMENTS_DE: AssessmentTranslationsGroup = {
  ...SHARED_DE,
  assessment: {
    header: "Location Assessment",
    createNew: "Neu anlegen",
    enterSingleAddress: "Einzelne Adresse eingeben",
    uploadAddressList: "Adressliste hochladen",
    noAssessments: "Derzeit keine Bewertung in Arbeit. Legen Sie eine Bewertung an.",
    createdAt: "Angelegt am",
    title: "Titel",
    assessmentType: "Typ",
    assessmentTypeList: "Adressliste",
    assessmentTypeSingle: "Einzeladresse",
    numberOfAddresses: "Anzahl Adressen",
    actions: "Action",
    delete: "Bewertung löschen",
    deleteConfirm: (title: String) => `Sind Sie sicher, dass Sie '${title}' löschen wollen?`,
    rename: "Bewertung umbenennen",
    recalculate: "Scores/Ratings/Preise neu berechnen",
    recalculateHint:
      "Profile und/oder Ratings und/oder Preise wurden verändert. Die angezeigten Werte sind nicht mehr aktuell und müssen neu berechnet werden.",
    macroScoreContext: "Kontext",
    macroScoreDefaultContext: "Deutschland",
    search: "Suchen:",
    searchForAddress: "Adresse suchen",
    tags: "Tags:",
    invalidAddressInfo: {
      title: "Ungültige Adresse",
      message: "Für diese Adresse können wir keine Informationen berechnen / anzeigen.",
    },
  },
  address: {
    postalCode: "PLZ",
    locality: "Stadt",
    route: "Straße",
  },
  assessmentAddAddress: {
    addressInputPlaceholder: "Adresse eingeben",
    addressLabel: "Adresse",
    createButton: "Lage bewerten",
    loadListButton: "Adressliste hochladen",
    uploadingFile: "Datei wird hochgeladen",
    setPinHint:
      "Die Adresse ist nicht eindeutig. Sie können innerhalb der Karte klicken um die gewünschte Position festzulegen.",
    outsideOfGermany: "Diese Adresse liegt außerhalb von Deutschland und kann nicht verwendet werden.",
    errorNoSmartdata: "Für diese Adresse sind keine Smartdata Werte vorhanden",
  },
  assessmentUploadAddresses: {
    title: "Adressliste hochladen",
    hint: `Sie können eine Excel Datei mit bis zu 3000 Adressen hochladen.<br/>
          Abhängig von der Größe der Liste und der Anzahl an zu berechnenden Profilen/Ratings kann der Import eine ganze Weile dauern.<br/>
          Wenn Sie Fragen zum Dateiformat haben, werfen Sie einen Blick in die <a href="/assets/Location%20Assessment%20-%20Upload%20Adressliste.pdf" target="_blank">Dokumentation</a>.`,
    errorInvalidFileFormat: (fileProps: FileProperties | undefined) => {
      if (fileProps)
        return (
          <>
            Die hochgeladene Datei hat kein gültiges xls Format:
            <br />
            <dl>
              <dt>
                <b>Identifizierter MIME Datei Typ:</b> <code>{fileProps.mimeType}</code>
              </dt>
              <dd>
                erlaubt: <code>application/octet-stream</code> oder{" "}
                <code>application/vnd.openxmlformats-officedocument.spreadsheetml.sheet</code>
              </dd>
              <dt>
                <b>Dateiendung:</b> {fileProps.fileExtension}
              </dt>
              <dd>
                erlaubt: <code>xls</code>, <code>xlsx</code>
              </dd>
            </dl>
          </>
        )
      else return <>Die hochgeladene Datei hat kein gültiges xls Format.</>
    },
    errorNoRows: "Die hochgeladene Datei enthält keine Zeilen",
    errorMissingColumns: "Die folgenden Spalten konnten nicht gefunden werden",
    errorTooManyEntries: "Die hochgeladene Datei enthält mehr als 1000 Adressen",
    uploadButton: "Hochladen",
    column: {
      postalCode: "PLZ",
      locality: "Stadt",
      route: "Straße",
      streetNumber: "Nr",
    },
  },
  assessmentDetails: {
    viewMode: {
      list: "Liste",
      map: "Karte",
    },
    entryFailure: "Für diese Adresse sind keine Smartdata Werte vorhanden",
    showDetails: "Details anzeigen",
    locationMacroPrices: "Location Preise Stadt (EUR/m²)",
    locationMicroPrices: "Location Preise Kachel (EUR/m²)",
    objectPrices: "Einheit Preise (EUR/m²)",
    prices: {
      min: "Min",
      max: "Max",
      avg: "Ø",
    },
    emptyAssessmentAlert: {
      title: "Adresse löschen",
      text: "Wenn Sie alle Adressen löschen, wird auch das Assessment gelöscht. Wollen Sie fortfahren?",
    },
    download: {
      title: "Daten herunterladen",
      selectProfiles: "Profiles auswählen",
      downloadButton: "Daten herunterladen",
      saveSettings: "Einstellungen speichern",
      tooManyProfiles: "Bitte beachten Sie, dass höchstens 5 Profile ausgewählt werden können.",
    },
  },
  assessmentEntryDetails: {
    ratings: "Ratings",
    profiles: "Profiles",
    scores: "Scores",
    toggleAllScores: "Alle Scores",
    entryNotGeocoded:
      "Die Adresse konnte nicht in eine Geolocation umgewandelt werden. Bitte korrigieren Sie die Adresse manuell.",
    entryHasMissingCellOnBorder:
      "Die betrachtete Adresse liegt am Rand der Gemeinde. Derzeit optimieren wir die Methode, mit der sich für solche speziellen Lagen Scores, Profile und Ratings exakter berechnen lassen. Bis dahin verzichten wir auf die Anzeige von Näherungswerten.",
    entryHasNoSmartdata: "Für diese Adresse sind keine Smartdata vorhanden",
    noScoreGroupSelected: "Keine Score-Gruppe ausgewählt",
    noProfileSelected: "Kein Profil ausgewählt",
    profileNoScores: {
      macro: "Dieses Profil hat keine Makro Scores",
      micro: "Dieses Profil hat keine Mikro Scores",
    },
    entryHasNoScores: {
      macro: "Für diese Addresse gibt es keine Makro Scores",
      micro: "Für diese Addresse gibt es keine Mikro Scores",
    },
    stateStatus: {
      created: "Bewertung erstellen",
      geocoded: "Geocoding",
      "geocoded-ambiguous": "Geocoding fehler",
      smartdata: "Datenverarbeitung",
      "smartdata-ambiguous": "Datenverarbeitung fehler",
      rated: "Verarbeitung von Bewertungen",
      "rated-ambiguous": "Fehler bei der Rating-Verarbeitung",
      "object-prices": "Preise Verarbeitung",
      "object-prices-ambiguous": "Fehler bei der Preisverarbeitung",
      scored: "Scores Verarbeitung",
      "scored-ambiguous": "Fehler bei der Scores Verarbeitung",
      failure: "Fehler",
    },
    updateEntry: "Adresse ändern",
    tags: "Tags",
    priceTitle: "Preis",
    pricesTitle: "Preise",
    pricesTabTitle: "Preise & Renditen",
    pricesTypesTitle: "Preisart",
    usageTypesTitle: "Nutzungsart",
    selectionWarning:
      "Wenn Sie eine der Preisarten auswählen, sollten Sie auch mindestens eine der Nutzungsarten auswählen.",
    pricesTabs: {
      market: "Markt",
      trends: "Trends",
      rentIndex: "Mietspiegel",
    },
    noRentIndex: "Für diese Kombination aus Ort, Jahr und Fläche gibt es keinen Mietspiegel.",
    noRentIndexForCity:
      "Für diese Lage können wir bisher keinen Mietspiegel anbieten. Sollte es für die Stadt einen Mietspiegel geben, sprechen Sie bitte unseren Customer Support an, damit wir diesen schnellstmöglich implementieren können.",
    addressForRentIndexNotDefined:
      "Die begutachtete Adresse ist nicht vollständig. Der Mietspiegel lässt sich nur mit kompletter Adresse berechnen.",
    usageType: {
      office: "Büro",
      retail: "Einzelhandel",
      residential: "Wohnen",
      hall: "Logistik",
      plot: "Grundstück",
    },
    netYield: "Net Yields",
    grossYield: "Gross Yields",
    assessmentUsageTypes: {
      "residential-apartment-rent": "Miete: Wohnung",
      "residential-apartment-sale": "Kauf: Wohnung",
      "residential-house-rent": "Miete: Haus",
      "residential-house-sale": "Kauf: Haus",
      office: "Miete: Büro",
      retail: "Miete: Einzelhandel",
      hall: "Miete: Logistik",
      "plot-sale": "Kauf: Grundstück",
      logistics: "Miete: Logistik",
    },
    priceType: {
      rent: "Miete",
      sale: "Kauf",
    },
    houseTypeTitle: "Hausart",
    houseType: {
      other: "Andere",
      singleDetached: "Einfamilienhaus (freistehend)",
      multiple: "Mehrfamilienhaus",
      semiDetached: "Doppelhaushälfte",
      corner: "Reiheneckhaus",
      middle: "Reihenmittelhaus",
    },
    houseOrApartment: "Typ",
    locationSourceLabel: "Ausgewählt nach",
    priceCategories: {
      apartment: "Wohnung",
      house: "Haus",
    },
    locationPrices: "Lage Preise",
    priceRange: { min: "Min", max: "Max", avg: "Mittel" },
    agsPriceLabel: "Stadt",
    cellPriceLabel: "Nachbarschaft",
    objectPriceLabel: "Einheit",
    rentIndexCellPriceLabel: "Marktpreis Nachbarschaft",
    rentIndexPriceLabel: "Mietspiegel",
    rentIndexLinkDescription:
      "Der gezeigte Bereich ist ein erster grober Indikator für den möglichen Mietspiegel basierend auf Ort, Fläche und Jahr. Für präzisere Werte besuchen Sie bitte unseren speziellen",
    rentIndexLinkLabel: "Mietspiegel-Rechner",
    constructionOrRenovationYear: "Bau- oder Sanierungsjahr",
    newBuilding: "Neubau",
    constructionYear: "Baujahr",
    constructionYearAlert: {
      header: 'Sie aktivieren den Filter "Baujahr"',
      text: "Angebote mit unbekanntem Baujahr werden nicht auf der Karte und im Preisverteilungshistogramm dargestellt, wenn dieser Filter angewendet wird.",
      checkBox: "Diese Meldung nicht mehr anzeigen",
    },
    livingArea: "Fläche",
    quality: "Exklusivität",
    radius: "Radius",
    rooms: "Räume",
    roomsNumberMax: " oder mehr",
    publicationTime: "Veröffentlichungsdatum",
    offerDuration: "Angebotsdauer",
    quarters: "Quartale",
    noObjectPrices: "Keine Preisinformationen verfügbar",
    euroPerSqm: "€\u00A0/\u00A0m²",
    euro: "€",
    years: "Jahre",
    constructionYearValidation: "Gültiges Jahr erforderlich",
    columns: {
      year: "Jahr",
      area: "Fläche",
      exclusiveness: "Exklusivität",
      usageType: "Typ",
      externalID: "ID",
    },
    exclusivenessDropdown: {
      standard: {
        text: "Die Einheit entspricht dem Standard, der innerhalb des Objekts und der näheren Umgebung zu erwarten ist",
        label: "Standard (★)",
      },
      high: {
        text: "Die Einheit setzt sich deutlich von anderen Einheiten im Objekt und der Umgebung ab",
        label: "Hoch (★★)",
      },
      highest: {
        text: "Weit überdurchschnittliche Ausstattung und Merkmale, die man so in Objekt/Umgebung nicht erwarten würde",
        label: "Am höchsten (★★★)",
      },
    },
    baseScoreLegend: "Index: Q1 2011 = 100",
    view: "Ansicht",
    offers: "Angebote",
    index: "Index",
    rent: "Miete",
    price: "Preis",
    showPriceDistribution: "Preisverteilung anzeigen",
    noDetailsAvailable: "Keine Details verfügbar",
    noProfilesAvailable: "Keine Profile vorhanden",
    noRatingsAvailable: "Keine Ratings vorhanden",
    notInMacroContext: "Addresse befindet sich nicht im Macro Context",
    comparablesExplanation:
      "Die Machine-Learning-Preise sowie die Objekt-Preise basieren auf Tausenden von Parametern sowie Millionen von Comparables. Sie können diese Vergleichsangebote in einem gewissen Radius filtern und sich die Preisverteilung in einem Graph sowie die ungefähren Lagen in der Karte ansehen.",
    comparablesAppPageTranslation: (page: ComparablesAppPageType) => {
      switch (page) {
        case "map":
          return "Karte"
        case "analysis":
          return "Analyse"
      }
    },
    dataSource: {
      header: "Datenquelle",
      historical: "Angebotspreise - Historisch (21st)",
      onlineImmo: "Angebotspreise - Live (ImmoScout24)",
      transaction: "Transaktionspreise - Historisch (21st)",
      seniorLiving: "Seniorenwohnen - Historisch (21st)",
    },
    furnished: "Möbliert",
    yes: "Ja",
    no: "Nein",
    nonFocusCellMessage:
      "Die Adresse liegt in einer unbewohnten/unbebauten Kachel. Für solche Kacheln werden keine eigenen Scores berechnet. Wir zeigen allerdings Näherungswerte, die sich aus dem gewichteten Mittel der drei nächsten bewohnten Kacheln ergeben. Auf deren Basis lässt sich vorhersagen, wie die Scores aussehen würden, wenn die Kachel bewohnt/bebaut wäre.",
    nonFocusCellProfileMessage:
      "Die Adresse liegt in einer unbewohnten/unbebauten Kachel. Für solche Kacheln werden keine eigenen Scores/Profile berechnet. Wir zeigen allerdings Näherungswerte, die sich aus dem gewichteten Mittel der drei nächsten bewohnten Kacheln ergeben. Auf deren Basis lässt sich vorhersagen, wie die Scores/Profile aussehen würden, wenn die Kachel bewohnt/bebaut wäre.",
    nonFocusCellRatingsMessage:
      "Die Adresse liegt in einer unbewohnten/unbebauten Kachel. Für solche Kacheln werden keine eigenen Scores oder auf Scores basierende Ratings berechnet. Wir zeigen allerdings Näherungswerte, die sich aus dem gewichteten Mittel der drei nächsten bewohnten Kacheln ergeben. Auf deren Basis lässt sich vorhersagen, wie die Ratings aussehen würden, wenn die Kachel bewohnt/bebaut wäre.",
    additionalCosts: "Additional costs",
    notSpecified: "not specified",
    floor: "Floor",
    distributionGraphHeader: "Preisverteilung der beobachteten Angebote",
    range: "Werte",
    observedOffers: "Beobachtete Angebote",
    comparables: "Comparables",
    machineLearningPrices: "Machine Learning Preise",
    noComparablesFound: "Keine passenden Angebote gefunden",
    limitedOfferCountExplanation:
      "Die Karte zeigt die nähesten 1000 Angebote. Wenn Sie Angebote sehen möchten, die innerhalb des Radius verteilt sind, passen Sie bitte die Filtereinstellungen an.",
    yieldRecalculate: "Marktrenditen neu berechnen",
    yieldsTitle: "Marktrenditen",
    yieldCity: "Stadt Durchschnitt",
    topYieldCity: "Stadt Top",
    yieldMicro: "Nachbarschaft Durchschnitt",
    yieldFeedback:
      "Unsere Marktrenditen geben Ihnen Einblicke in die Chancen-Risiko-Profile Ihrer Locations. Bitte teilen Sie uns mit, wie Sie darüber denken.",
    additionalInfo: "Weitere Infos",
    plotPricesRecalculate: "Grundstückspreise auf Stadtebene sind nicht verfügbar. Bitte berechnen Sie neu.",
    unitSettings: "Einheit Einstellungen",
    reset: "Zurücksetzen",
  },
  assessmentEntryReport: {
    downloadReport: "Report downloaden",
    downloadReportButton: "Download Report (Word) ...",
    downloadDataButton: "Download Daten (Excel)",
    dialogTitle: "Report konfigurieren",
    chooseForMap: "Score für Karte wählen",
    saveSettings: "Einstellungen speichern",
    warningMessage:
      "Sie haben viele Scores ausgewählt. Die Erstellung des Reports kann eine Weile dauern und ggf. sogar abbrechen.",
    warningMessageWaitingTime:
      "Abhängig von den ausgewählten Komponenten kann die Erzeugung des Reports bis zu 10 Minuten dauern. Bitte brechen Sie den Download nicht ab.",
    showPOISummaryInReport: "POI Summary im Report anzeigen",
    selectedCategories: "Ausgewählte Kategorien",
    noCategoriesSelected:
      "Derzeit sind keine Kategorien ausgewählt.\nSie können jederzeit zum POI Explorer wechseln und dort die gewünschten Kategorien aktivieren.",
  },
  comparablesGraphs: {
    chart: "Chart",
    table: "Tabelle",
    median: "Median",
    average: "Durchschnitt",
    meanValues: "Mittelwerte",
    range: "Bereich",
    quarter: "Quartal",
    offers: "Angebote",
    observedOffers: "Beobachtete Angebote",
    quantile: "Quantil",
    minimum: "Minimum",
    maximum: "Maximum",
  },
  assessmentComparables: {
    offerCount: "Anzahl Angebote:",
    offerCountFiltered: "Anzahl Angebote (gefiltert):",
    filteredOffers: "Gefilterte Angebote",
    priceTrends: "Trends: Preise",
    priceDistribution: "Verteilung: Preise",
    areaDistribution: "Verteilung: Fläche",
    offerYearDistribution: "Verteilung: Angebote pro Quartal",
    constructionYearDistribution: "Verteilung: Baujahr",
    immoscoutItemType: {
      apartment: "Wohnung",
      house: "Haus",
      commercial: "Gewerblich",
    },
    roomsHeader: "Zimmer",
    purchasePrice: "Kaufpreis",
    purchasePricePerM2: "Kaufpreis pro m²",
    netRent: "Kaltmiete (pro Monat)",
    netRentPerM2: "Kaltmiete (pro Monat pro m²)",
    totalRent: "Gesamtmiete (pro Monat)",
    totalRentPerM2: "Gesamtmiete (pro Monat pro m²)",
    area: "Fläche (m²)",
    livingSpace: "Wohnfläche (m²)",
    plotArea: "Grundstück (m²)",
    objectDetails: "Objektdetails",
    builtInKitchen: "Einbauküche",
    balcony: "Balkon",
    certificateOfEligibilityNeeded: "Wohnberechtigungsschein erforderlich",
    garden: "Garten",
    lift: "Personenaufzug",
    guestToilet: "Gäste-WC",
    cellar: "Keller",
    barrierFree: "Barrierefrei",
    energyPerformanceCertificate: "Energieausweis",
    isAvailable: "liegt vor",
    courtage: "Provision",
    vatIncluded: "inkl. MwSt.",
    energyEfficiencyClass: "Energieeffizienzklasse",
    offerDetails: "Angebotsdetails",
    modificationDate: "Datum der letzten Änderung",
    privateOffer: "Privater Anbieter",
    usageType: "Nutzungsart",
    offersLimit: {
      map: {
        "historical-21st":
          "Für historische Daten werden bis zu 10.000 Angebote innerhalb des Radius/PLZ-Gebietes angezeigt und um die Adresse im Zentrum verteilt.",
        "online-immoScout": "Für Online Daten werden bis zu 200 Angebote angezeigt und innerhalb des Radius verteilt.",
        "historical-21st-transaction": "",
        "historical-21st-senior-living": "",
      },
      analysis: {
        "historical-21st":
          "Anders als auf der Karte gibt es für die Analyse kein Limit, hier werden sämtliche passenden Angebote mit einbezogen.",
        "online-immoScout": "",
        "historical-21st-transaction": "",
        "historical-21st-senior-living": "",
      },
    },
    analysisErrors: {
      noOffers: "Für diese Filtereinstellungen wurden keine passenden Angebote gefunden.",
      notEnoughOffers: "Nicht genügend Angebote für die Chart-Darstellung.",
      error:
        "Oops, ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch mal oder ändern Sie die Filtereinstellungen.",
    },
    newComparablesOpenInfoText:
      "Die neuen Comparables bieten mehr Daten, mehr Filter- und Analysemöglichkeiten und sogar Realtime-Angebote von ImmoScout24.",
    newComparablesOpenButton: "Die neuen Comparables öffnen",
    allHouseTypes: "(Alle Hausarten)",
    offersWithKnownConstructionYear: "Hier werden nur Angebote aufgelistet, deren Baujahr bekannt ist.",
    transactionSourceInfo: (
      <div>
        <p>Transaktionen werden nicht nur um die Adresse herum gezeigt, sondern für ganz Deutschland.</p>
        <p>
          Die Transaktionsdaten beruhen auf eigenen Beobachtungen von Immobiliennachrichtenportalen (wie z.B. iz.de,
          thomas-daily.de, konii.de, immobilienmanager.de, deal-magazin.com, rohmert-median.de, property-magazine.de),
          Auktionswebsites (wie z.B. sga-ag.de, ndga.de, dga-ag.de) aber auch allgemeinen Nachrichtenagenturen (wie z.B.
          pressetext.com und presseportal.de).
        </p>
        <p>
          Soweit verfügbar, werden Angaben wie Transaktionspreis, Fläche, Adresse und Transaktionspartner mithilfe
          mehrerer Quellen per Hand verifiziert. Es werden nur Transaktionen mit einem öffentlich bekannten Preis in die
          Datenbank übernommen.
        </p>
      </div>
    ),
  },
  districtData: {
    dataView: (view) => {
      switch (view) {
        case "micro":
          return "Mikro"
        case "macro":
          return "Makro"
      }
    },
    mainTabs: (tab) => {
      switch (tab) {
        case "configuration":
          return "Konfiguration"
        case "analysis":
          return "Analyse"
      }
    },
    focusArea: "Analysegebiet",
    referenceArea: "Referenzgebiet",
    district: "Quartier",
    districts: "Quartiere",
    municipality: "Gemeinde",
    municipalities: "Gemeinden",
    selected: "ausgewählt",
    country: "Deutschland",
    data: "Daten",
    districtsZoomIn: "Gebiet zu groß, bitte zoomen Sie näher heran",
    selectArea: "Bereich auswählen",
    noDataSelected: "Es wurden keine Daten ausgewählt",
    rawValue: "Rohwert",
    inPercentOf: "in % der",
    asIndexOfReference: "als Index (Referenz = 100)",
    asIndexOfGermany: "als Index (DE = 100)",
    population: "Bevölkerung",
    area: "Fläche",
    readMore: "Mehr lesen...",
    nexigaUpdateText:
      "Seit Ihrem letzten Besuch haben sich die Grenzen der Quartiere verändert. Die bisherige Konfiguration des Analyse- und Referenzgebietes wurde deshalb auf die Standardwerte zurückgesetzt.",
  },
  quickStart: {
    headerLabel: "Quickstart",
    headerComment: "Bewerten Sie Lage & Einheit",
    location: "Lage",
    address: "Adresse",
    infoLabel: "Geben Sie die Adresse ein...",
    ambiguousAddressMessage:
      "Die Adresse ist nicht eindeutig. Klicken Sie innerhalb der Karte, um die gewünschte Position festzulegen.",
    outsideOfGermanyAddress: "Diese Adresse liegt außerhalb von Deutschland und kann nicht verwendet werden.",
    unitOptional: "Einheit (optional)",
    usageType: {
      label: "Nutzungsart",
      residentialRent: "Miete / Wohnen",
      office: "Miete / Büro",
      retail: "Miete / Einzelhandel",
      residentialSale: "Kauf / Wohnen",
      hall: "Miete / Logistik",
      plotSale: "Kauf / Grundstück",
      logistics: "Miete / Logistik",
    },
    yearOfConstructionAndRefurbishment: "Baujahr / Sanierungsjahr",
    yearOfConstruction: "Baujahr",
    yearOfConstructionAbbreviation: "BJ",
    startAssessment: "Assessment starten",
    newAddress: "1. Adresse eingeben",
    propertyDetails: "2. Optional: Angaben zu Objekt und Einheit",
  },
  dashboard: {
    header: "Bewertungsübersicht",
    configuration: "Konfigurieren",
    remove: "Entfernen",
    widgetConfiguration: "Widget Konfiguration",
    widgetSelectDropdown: {
      macroMap: "Karte (Makro)",
      microMap: "Karte (Mikro)",
      nearestAccessibility: "Nächste Erreichbarkeit",
      macroScores: "Scores (Makro)",
      microScores: "Scores (Mikro)",
      districtData: "Daten",
      comparables: "Comparables",
      rentIndex: "Mietspiegel",
      POIs: "POIs",
      specialMaps: "Verfügbare Sonderkarten",
      population: "Bevölkerungsverteilung",
      MLPrices: "Machine Learnings Preise",
      yields: "Market Yields Trends",
      ratings: "Ratings",
    },
    configInfo: {
      microMap: {
        header: "Mikro Karte",
        isochrone: "Konfigurieren Sie die Isochrone für die Anzeige auf der Karte.",
        privatePois: "Wählen Sie die Private POIs für die Anzeige auf der Karte.",
      },
      scores: "Wählen Sie die Scores aus, die im Widget angezeigt werden sollen.",
      ratings: "Wählen Sie die Ratings aus, die im Widget angezeigt werden sollen.",
      pois: "Wählen Sie die POI Kategorien aus, die im Widget angezeigt werden sollen.",
      districtData: "Wählen Sie die Daten aus, die im Widget angezeigt werden sollen.",
    },
    filter: "Filter",
    info: "Über diese Eingabe lassen sich passende Scores filtern. Dabei werden Namen und Beschreibung durchsucht.",
    specialMaps: {
      header: "Verfügbare Sonderkarten",
      noMaps: "Keine Sonderkarten verfügbar",
    },
    ratings: {
      header: "Ratings",
      noRatings: "Keine Ratings verfügbar",
      allRatings: "Alle Ratings",
      selectedRatings: "Ausgewählte Ratings",
      microRatings: "Mikro Ratings",
      macroRatings: "Makro Ratings",
      metaRatings: "Meta Ratings",
    },
    nearestAccessibility: {
      header: "Nächste Erreichbarkeit",
    },
    rentIndex: {
      header: "Mietspiegel",
      noRentIndex: "Kein Mietspiegel verfügbar",
    },
    districtData: {
      header: "Daten",
      district: "Quartier",
      municipality: "Gemeinde",
      zip: "Postleitzahl",
    },
    mlPrices: {
      header: "Machine Learning Preise",
      city: "Stadt",
      neighborhood: "Nachbarschaft",
      unit: "Einheit",
      usageType: {
        rentResidential: "Miete Wohnen",
        rentOffice: "Miete Büro",
        rentRetail: "Miete Einzelhandel",
        purchaseResidential: "Kauf Wohnen",
      },
    },
    microMap: {
      header: (mode: string, translatedMode: string, minutes: number) =>
        mode === "none" ? "Nachbarschaft" : `${translatedMode}-Isochrone (${minutes} Min. Umkreis)`,
    },
    macroMap: {
      header: "Karte",
    },
    population: {
      header: "Bevölkerungsverteilung",
    },
    scores: {
      microHeader: "Mikro Scores",
      macroHeader: "Makro Scores",
    },
    POIs: {
      header: "POIs (15 Min. Umkreis)",
      configurationHeader: "POIs",
      categories: {
        eatAndDrink: "Essen & Trinken",
        healthCare: "Medizinische Versorgung",
        schools: "Schulen",
        shopping: "Einkaufen",
        culture: "Kultur",
        postOfficeBank: "Post & Bank",
        parking: "Parken",
      },
      allPOIs: "Aggregierte Zusammenstellung",
      selectedPOIs: "Ausgewählte POI Kategorien",
    },
    yields: {
      header: {
        residential: "Brutto Marktrenditen Trends",
        office: "Netto Marktrenditen Trends",
        retail: "Netto Marktrenditen Trends",
      },
      usageType: {
        residential: "Wohnen",
        office: "Büro",
        retail: "Einzelhandel",
      },
    },
    comparables: {
      header: "Comparables (1 km Radius)",
      historicalOffers: "Historische Angebote",
      liveOffers: "Live Angebote",
    },
    editDashboardConfigurationDialog: {
      header: "Multi Dashboard Konfiguration",
      dashboards: "Dashboards",
      newDashboard: "Neues Dashboard hinzufügen",
      delete: "Löschen",
      duplicate: "Duplizieren",
      title: "Titel",
      dashboardAlreadyExists: "Dieser Name existiert bereits",
      deleteDashboard: "Dashboard löschen",
      deleteDashboardConfirmation: (name: string) => `Wollen Sie das Dashboard '${name}' wirklich löschen?`,
      cancel: "Abbrechen",
      copy: "Kopie",
      editContent: "Inhalt bearbeiten",
      editLayout: "Layout bearbeiten",
      editing: "In Bearbeitung",
    },
  },
  rentindex: {
    incompleteData: "Bitte geben Sie eine Fläche und ein Baujahr an.",
    noCityInfoHeader: "Kein Mietspiegel verfügbar",
    noCityInfoDescription:
      "Für diese Lage bieten wir bisher keinen Mietspiegel an. Sollte es für die Stadt einen Mietspiegel geben, sprechen Sie bitte unseren Customer Support an.",
    rentIndexHeader: "Mietspiegel",
    area: "Wohnfläche",
    yearOfConstruction: "Baujahr",
    unknownAddress:
      "Die begutachtete Adresse ist nicht vollständig. Der Mietspiegel lässt sich nur mit kompletter Adresse berechnen.",
    unknownError: "Ein unbekannter Fehler is aufgetreten",
    resultEmpty: "Mietspiegel hat keinen Wert",
    transferData: "Werte übernehmen",
    additionalInformation: "Zusatzinformationen",
    generalInformation: "Allgemeine Informationen",
    calculationHeader: "Berechnung",
    rentindexMinMax: "Mietspiegel (Min-Ø-Max)",
    allValues: "(Alle Werte in €/m²)",
    cantBeCalculated:
      "Für diese Kombination aus Baujahr, Wohnfläche und beantworteten Fragen kann der Mietspiegel nicht berechnet werden.",
    quesitonCatalog: "Fragenkatalog",
    resetAllAnswers: "Alle Angaben zurücksetzen",

    availableRentIndices: "Verfügbare Mietspiegel",
    listOfAllCities:
      "Liste aller Städte, für die wir einen Mietspiegel anbieten. Diese Liste wird regelmäßig upgedatet. Innerhalb unserer App erhalten Sie Zugriff auf den jeweils jüngsten Mietspiegel. Über die API können Sie auch auf ältere Mietspiegel zugreifen.",
    listOfAllCitiesInformationHeader: "Information",
    listOfAllCitiesInformationNavigateTo: (
      <>
        Falls Interesse besteht, haben wir eine <a href={"/rentindex/info"}>Liste mit allen Städten</a>, für die wir
        einen Mietspiegel anbieten.
      </>
    ),
  },
  specialMaps: {
    availableSpecialMaps: "Verfügbare Sonderkarten",
    furtherSpecialMaps: "Weitere Sonderkarten",
    legendHeader: "Legende",
    noLegend: "Keine Legende verfügbar",
    loadingMap: "Karte wird geladen...",
    error: "Temporärer Fehler - Karte konnte nicht geladen werden",
    zoomIn: "Diese Sonderkarte ist erst ab einem bestimmten Zoomlevel sichtbar. Bitte zoomen Sie weiter heran.",
  },
  fundamentalData: {
    dataView: (view) => (view === "zip" ? "Postleitzahl" : view === "municipality" ? "Gemeinde" : "Deutschland"),
    noDataSelected: "Es wurden keine Daten und/oder Gebiete ausgewählt",
    data: "Daten",
    select: "Auswahl",
    resetArea: "Gebiet zurücksetzen",
    resetData: "Daten zurücksetzen",
    loadingError:
      "Oops, ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch mal oder ändern Sie die Filtereinstellungen.",
  },
  prices: {
    selector: {
      switchButtonLabels: {
        prices: "Preise",
        yields: "Marktrenditen",
        current: "Aktuell",
        trends: "Trends",
        all: "Alle",
      },
      subtitles: {
        rent: "Miete",
        purchase: "Kauf",
      },
      residential: "Wohnen",
      office: "Büro",
      retail: "Einzelhandel",
      logistics: "Logistik",
      plots: "Grundstück",
    },
    plotPricesAreNotAvailable: "Grundstückspreistrends sind nicht verfügbar.",
    unitPrices: {
      header: "Einheit Preise",
      info: "Für die Einheit-Preise wird auch das Objekt mit in die Betrachtung einbezogen, die relevanten Parameter (Baujahr, Fläche etc.) lassen sich spezifizieren. Die Werte sind als Indikation und Bewertung zu verstehen.",
    },
    locationPrices: {
      header: "Location Preise",
      info: "Die Location Preise ergeben sich neben der Lage auch aus der Marktsituation. Die Werte sollten als Potential interpretiert werden mit besonderem Augenmerk auf die Spanne und die relative Position des Mittelwerts.",
    },
    rentIndex: {
      header: "Mietspiegel",
      info: {
        calculator: "Rechner",
        available: (calculatorSpan: JSX.Element) => (
          <>
            Dies ist ein grober Indikator (€/m²) basierend auf Ort, Fläche und Jahr. Für präzisere Werte bieten wir
            einen speziellen {calculatorSpan}.
          </>
        ),
        errors: {
          notAvailableForCity:
            "Für diese Lage bieten wir bisher keinen Mietspiegel an. Sollte es für die Stadt einen Mietspiegel geben, sprechen Sie bitte unseren Customer Support an.",
          cannotCalculate:
            "Für diese Stadt bieten wir einen Mietspiegel an, der aber für diese Kombination aus Ort, Jahr und Fläche nicht berechnet werden kann.",
          generic: "Ein unbekannter Fehler ist aufgetreten.",
        },
      },
    },
    pricesTrends: {
      header: "Location Preise Trends",
      info: "Statt der aktuellen Quartalspreise wird hier deren zeitliche Entwicklung verdeutlicht, was eine dynamische Betrachtung ermöglicht. Die letzten acht Quartale eröffnen einen nichtlinearen Blick in die nahe Zukunft.",
      noData: "Für diese Nutzungsart sind keine Trends verfügbar.",
    },
    priceVariations: {
      header: "Einheit Preis Variation",
      info: "Hier zeigt sich der Einfluss einzelner Parameter auf die Preise, was deren Variation erklärt. Das ermöglicht What-If-Analysen und Rendite-Prognosen.",
      yAxis: "Preise",
      xAxisArea: "Fläche",
      xAxisConstructionYear: "Bau- / Sanierungsjahr",
      xAxisClass: "Exklusivität",
      noValues: "Keine Werte verfügbar für diese Einstellungen",
    },
    marketYields: {
      info: "Renditen beschreiben das Verhältnis der jährlichen Mieteinnahmen zum Kaufpreis. Bei den Nettorenditen werden auch die Bewirtschaftungs- und Anschaffungsnebenkosten berücksichtigt. Unsere Marktrenditen sind in erster Linie als Indikator zu verstehen und sollen Einblicke in das Chancen-Risiko-Profil einer Lage vermitteln.",
      rentOrPurchaseResidential: "Brutto Marktrenditen",
      rentOffice: "Netto Marktrenditen",
      rentRetail: "Netto Marktrenditen",
    },
    marketYieldsTrends: {
      info: "Die zusätzliche zeitliche Dimension der Trends macht die Renditen noch wertvoller als Indikator für die wirtschaftliche Entwicklung sowie die Dynamik der Chancen und Risiken einer Lage.",
      rentOrPurchaseResidential: "Brutto Marktrenditen Trends",
      rentOffice: "Netto Marktrenditen Trends",
      rentRetail: "Netto Marktrenditen Trends",
    },
    filter: "Filter",
    filterPrices: "Preise filtern",
  },
}
