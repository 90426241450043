import moment from "moment"

export interface ReadonlyBooking {
  id: string
  bundle: string
  start: string
  end: string | undefined
}

export function parseLocalDate(value: string): moment.Moment {
  return moment(value.substring(0, 10), "YYYY-MM-DD")
}
