import * as React from "react"
import * as moment from "moment"
import { CompanyApiTranslations } from "../i18n/translations"
import { translations } from "../i18n"
import { ApiTokenCreate } from "./api-token-create"
import { ApiToken } from "../models/api-token"
import CopyToClipboard from "react-copy-to-clipboard"
import { ApiTokenRevoke } from "./api-token-revoke"
import { ApiTokenStats } from "./api-token-stats"
import { useAppSelector } from "../../relas/store"
import { apiServiceUrl } from "../../app_config"
import { menuTranslations } from "../../menu/i18n"
import { showTokenStats } from "../reducers/api-slice"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import RevoHeader from "../../shared/components/revo-header"
import Table from "../../shared/components/table"
import Button from "../../shared/components/button"
import Grid from "../../shared/components/restyle-grid/grid"
import Card from "../../shared/components/card"
import LoadingSpinner from "../../shared/components/loadingspinner"

export const ApiTokenList = () => {
  const error = useAppSelector((state) => state.apiState.error)
  const apiTokens = useAppSelector((state) => state.apiState.apiTokens)
  const tokenStats = useAppSelector((state) => state.apiState.statToken)
  const translate: CompanyApiTranslations = translations().api
  const menuTranslate = menuTranslations()

  const renderApiTokens = (apiTokens: ApiToken[]) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>{translate.apiToken.label}</th>
            <th>{translate.apiToken.token}</th>
            <th>{translate.apiToken.createdAt}</th>
            <th>{translate.apiToken.lastUsed}</th>
            <th style={{ width: "300px" }} />
          </tr>
        </thead>
        <tbody>
          {apiTokens.map((apiToken) => {
            const createdAt = moment(new Date(apiToken.createdAt * 1000)).format(translate.dateTimeFormat)
            const maybeLastUsed = apiToken.lastUsed && moment(new Date(apiToken.lastUsed * 1000))
            const lastUsed = maybeLastUsed
              ? `${maybeLastUsed.format(translate.dateTimeFormat)} (${maybeLastUsed.fromNow()})`
              : "-"
            return (
              <tr key={apiToken.id}>
                <td>{apiToken.label}</td>
                <td style={{ whiteSpace: "nowrap" }}>
                  <span style={{ fontFamily: "monospace, monospace" }}>{apiToken.token}</span>&nbsp;
                  <CopyToClipboard text={apiToken.token} onCopy={() => {}}>
                    <Button
                      title={translate.apiToken.copyToClipboard}
                      type="secondary"
                      size="small"
                      icon="open_in_new"
                    />
                  </CopyToClipboard>
                </td>
                <td>{createdAt}</td>
                <td>{lastUsed}</td>
                <td>
                  <Grid gap="base" columns={2}>
                    <ApiTokenRevoke apiToken={apiToken} />
                    <Button type="secondary" size="small" onClick={() => showTokenStats(apiToken.id)}>
                      {translate.stats.statsLink}
                    </Button>
                  </Grid>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }
  return (
    <div className="with-body-scroll">
      <RevoHeader title={menuTranslate.apps.admin.submenus.apiAdmin} />
      <Grid columns={1} gap="xxl" paddingY="xxl" paddingX="xxl">
        {error && <GenericErrorPanel error={error} />}
        <div style={{ marginTop: "15px" }}>
          <Card header={translate.apiToken.listHeader}>
            <Grid columns={1} rowGap="md">
              {apiTokens === null && <LoadingSpinner />}
              {apiTokens !== null && renderApiTokens(apiTokens)}
              <div>
                <ApiTokenCreate />
              </div>
            </Grid>
          </Card>
        </div>
        <div style={{ marginTop: "15px" }}>
          <Card header="OpenAPI">
            <p>
              {translate.apiDocExplanationBeforeLink}{" "}
              <a href={apiServiceUrl} target="_blank">
                {translate.here}.
              </a>
            </p>
          </Card>
        </div>
        {tokenStats && (
          <ApiTokenStats
            tokenId={tokenStats}
            label={apiTokens ? apiTokens.filter((t) => t.id === tokenStats)[0].label : ""}
          />
        )}
      </Grid>
    </div>
  )
}
