import React, { useMemo } from "react"
import { AppModules } from "../../menu/util/app-location-types"
import { css } from "emotion"
import { useSelector } from "react-redux"
import { State } from "../../assessment/reducers/state"
import { useAppSelector } from "../../relas/store"
import { DocumentationAndSupport } from "../../shared/components/documentation-and-support"
import { translations } from "../../assessment/i18n"
import { DownloadReport } from "../../shared/components/ui/download-report"
import Grid from "../../shared/components/restyle-grid/grid"
import { WidgetsType } from "../../assessment/models/assessment"
import LanaSubheader from "../../shared/components/lana-subheader"
import { PricesModuleGridV2 } from "./prices-module-grid-v2"

const styles = {
  container: css({
    backgroundColor: "",
  }),
  stickyHeader: css({
    position: "sticky",
    top: 0,
    background: "white",
    zIndex: 100,
  }),
}

interface Props {
  module: AppModules["locationAssessment"]
  assessmentId: string
  assessmentEntryId: string | null
  widget?: WidgetsType
}

export const PricesApp = ({ module, widget }: Props) => {
  const t = useMemo(translations, [translations])
  const assessment = useSelector((state: State) => state.assessment.currentAssessment)
  const currentAssessmentEntry = useAppSelector((state) => state.assessment.currentAssessmentEntry)
  const assessmentLoadInProgress = useSelector((state: State) => state.assessment.assessmentLoadInProgress)
  const assessmentEntryLoadInProgress = useSelector((state: State) => state.assessment.assessmentEntryLoadInProgress)

  return (
    <Grid columns={1} height={[100, "%"]} rowSpec={"fit-content(100%) 1fr"} className={styles.container}>
      <div className={styles.stickyHeader}>
        <LanaSubheader
          menuSection={"locationAssessment"}
          assessment={assessment}
          assessmentEntry={currentAssessmentEntry}
          module={module}
          isLoading={assessmentLoadInProgress || assessmentEntryLoadInProgress}
          recalculationButton={true}
          recalculationDetails={true}
        >
          <DocumentationAndSupport
            tooltip={t.helpAndSupport}
            callLocation="Prices"
            onClick={() => {}}
            documentationURL="https://docs.google.com/document/d/1I1BCrRTBCAbfkHiE-i3QMv_SF2yr5EXj2X2bWM_sNYY"
            addDocumentationLink={true}
          />
          <DownloadReport />
        </LanaSubheader>
      </div>
      <PricesModuleGridV2 widget={widget} currentAssessmentEntry={currentAssessmentEntry} />
    </Grid>
  )
}
