import {
  AssessmentEntry,
  AssessmentPriceType,
  HouseOrApartment,
  priceTypeForUsageType,
} from "../../../models/assessment"
import { LocationSourceType } from "../comparablesV2"
import { NumericRange } from "../../../../shared/models/numeric-range"
import { HouseType } from "../../../../generated-apis/comparables-service"

export type HistoricalOffersInputForm = {
  dataSource: "historical-21st"
  priceTypeComps: AssessmentPriceType
  radius?: number
  locationSource: LocationSourceType
  zip?: string
  priceNeeded: boolean
  priceRange?: NumericRange
  areaRangeNeeded: boolean
  areaRange: NumericRange
  houseOrApartment: HouseOrApartment
  houseTypes: { label: string; value: HouseType }[]
  roomsFilterEnabled: boolean
  roomsNumberRange: NumericRange
  constructionYearNeeded: boolean
  constructionYearRange: NumericRange
  publicationDateNeeded: boolean
  publicationTimeRange: NumericRange
  furnished?: boolean

  quarterAvailable?: NumericRange
}

const currentDate = new Date()
const currentQuarter = Math.ceil((currentDate.getMonth() + 1) / 3)
const currentYearWithQuarter = currentDate.getFullYear() + 0.25 * (currentQuarter - 1)

const publicationTimeRangeDefault = {
  from: currentYearWithQuarter - 2,
  to: currentYearWithQuarter,
}

export const historicalOffersInputFormDefaults = (assessmentEntry?: AssessmentEntry) =>
  ({
    locationSource: "radius",
    radius: 1000,
    zip: assessmentEntry?.address?.postalCode || "",
    areaRangeNeeded: false,
    areaRange: { from: 0, to: 60 },
    publicationDateNeeded: true,
    publicationTimeRange: publicationTimeRangeDefault,
    constructionYearRange: { from: 0, to: 0 },
    priceNeeded: false,
    constructionYearNeeded: false,
    houseOrApartment: "apartment",
    houseTypes: [],
    roomsFilterEnabled: false,
    roomsNumberRange: { from: 1, to: 6 },
    furnished: false,
    priceTypeComps: priceTypeForUsageType(assessmentEntry?.usageType) || "residentialRent",
    dataSource: "historical-21st",
    quarterAvailable: { from: 1, to: 5 },
  } as HistoricalOffersInputForm)
