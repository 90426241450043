import React, { useState, useEffect } from "react"
import { Button as BPButton } from "@blueprintjs/core"
import { Drawer, NumericInput } from "@blueprintjs/core"
import { ScoreListItem } from "./profile-edit"
import { translations } from "../i18n"
import { BorderBottom } from "../../shared/components/ui/border-bottom"
import { COLORS } from "../../shared/components/ui/colors"
import { Flex } from "../../shared/components/ui/flex"
import { ScrollBox } from "../../shared/components/ui/scroll-box"
import { CHILD_PROFILE_PREFIX } from "../models/profile"
import Grid from "../../shared/components/restyle-grid/grid"
import GridItem from "../../shared/components/restyle-grid/griditem"
import Button from "../../shared/components/button"

interface Props {
  isOpen: boolean
  scoreList: ScoreListItem[]
  onClose: () => void
  saveScoreWeightNumericInput: (scores: ScoreListItem[]) => void
}

interface ScoreListItemNumericInput extends ScoreListItem {
  weightInput: string
  valid: boolean
  inverted: boolean
}

/**
 * Profile side panel for a numeric weight input
 * @param isOpen
 * @param scoreList Accepts weight in percents [0.01..99.9]
 * @param onClose
 * @param saveScoreWeightNumericInput
 * @constructor
 */
export const NumericInputDrawer = ({ isOpen, scoreList, onClose, saveScoreWeightNumericInput }: Props) => {
  // Locale is defined as "de" to accept both "," and "." in the numeric input
  const locale = "de"
  const weightToString = (weight: number) => (Math.round(Math.abs(weight) * 10) / 10).toString()
  const isValidInputWeight = (weight: number) => Math.abs(weight) >= 0.1 && Math.abs(weight) <= 100

  const inputScoreListInitial: ScoreListItemNumericInput[] = (() => {
    return scoreList.map((item) => ({
      ...item,
      weight: Math.abs(item.weight),
      weightInput: weightToString(item.weight),
      valid: isValidInputWeight(item.weight),
      inverted: item.weight < 0,
    }))
  })()
  const [scoreListNumericInput, setScoreList] = useState<ScoreListItemNumericInput[]>(inputScoreListInitial)

  useEffect(() => setScoreList(inputScoreListInitial), [scoreList])

  const t = translations()
  const sumScoresArray = (arr: ScoreListItemNumericInput[]) =>
    Math.round(arr.reduce((acc, item) => acc + (item.valid ? item.weight : 0), 0) * 10) / 10

  const currentWeightSum = sumScoresArray(scoreListNumericInput)
  const validScoresCheck = (scores: ScoreListItemNumericInput[]) => !!scores.find((score) => !score.valid)
  const areAllScoresValid = currentWeightSum !== 100 || validScoresCheck(scoreListNumericInput)

  const parseFloatWithComma = (value: string): number => {
    return Number.parseFloat(value.trim().replace(/,/, "."))
  }

  const handleInputChange = (value: string, scoreName: string) =>
    setScoreList(
      scoreListNumericInput.map((item: ScoreListItemNumericInput) => {
        if (item.scoreName === scoreName) {
          const parsedWeight = parseFloatWithComma(value)
          const isValid = !!parsedWeight && parsedWeight > 0 && isValidInputWeight(parsedWeight)
          return { ...item, weight: parsedWeight, weightInput: value, valid: isValid }
        } else {
          return item
        }
      })
    )

  const renderWeightedScore = ({ scoreName, title, inverted, weightInput, valid }: ScoreListItemNumericInput) => {
    const hasChildProfiles = scoreName.startsWith(CHILD_PROFILE_PREFIX)
    return (
      <BorderBottom key={`fragment-${scoreName}`}>
        <Grid padding={16} colGap={32} columnSpec="1fr 110px">
          <Flex flexDirection="row" gap={8} alignItems="center">
            {title}
            {hasChildProfiles && (
              <span style={{ fontFamily: "Revoicons", fontSize: "16px", color: "#B9BECD", margin: 8 }}>
                {" "}
                restructure{" "}
              </span>
            )}
            {inverted && (
              <img style={{ marginLeft: "8px", width: "16px" }} alt="inverted" src={"/assets/icon_inverted_grey.svg"} />
            )}
          </Flex>
          <div>
            <NumericInput
              className={!valid ? "invalidNumericInputScoreWeights" : "validNumericInputScoreWeights"}
              name={scoreName}
              min={0}
              max={100}
              locale={locale}
              value={weightInput}
              onValueChange={(_, valueAsString) => handleInputChange(valueAsString, scoreName)}
              buttonPosition={"right"}
              stepSize={1}
              rightElement={
                <BPButton minimal={true} disabled={true}>
                  %
                </BPButton>
              }
              allowNumericCharactersOnly={false}
            />
          </div>
        </Grid>
      </BorderBottom>
    )
  }

  return (
    <Drawer isOpen={isOpen} size="400px" canOutsideClickClose={false} className={"numeric-score-input-panel"}>
      <BorderBottom backgroundColor={COLORS.background.lighter}>
        <Grid columns={2} rowGap={16} gap={32} padding={16} columnSpec="1fr 110px">
          <div />
          <GridItem justifySelf={"end"}>
            <div className="close-button">
              <Button type="tertiary" icon="close" size="small" onClick={onClose} />
            </div>
          </GridItem>
          <h2>{t.profileEdit.scoreWeighting}</h2>
          <div />
          <div>{t.profileEdit.scoreWeightingInfo}</div>
          <div className={"numericScoreSum"}>
            <NumericInput
              className={currentWeightSum !== 100 ? "invalidNumericInputScoreWeights" : "validNumericScoreSum"}
              value={currentWeightSum}
              rightElement={
                <BPButton minimal={true} disabled={true}>
                  %
                </BPButton>
              }
              allowNumericCharactersOnly={false}
              readOnly={true}
              buttonPosition={"right"}
            />
          </div>
        </Grid>
      </BorderBottom>

      <Grid height={[100, "%"]} columns={1} gap={32} rowSpec="1fr min-content ">
        <form>
          <ScrollBox>{scoreListNumericInput.map(renderWeightedScore)}</ScrollBox>
        </form>
        <GridItem padding={16} justifySelf="end">
          <Button
            type="primary"
            onClick={() =>
              saveScoreWeightNumericInput(
                scoreListNumericInput.map((item) => ({ ...item, weight: (item.inverted ? -1 : 1) * item.weight }))
              )
            }
            disabled={areAllScoresValid}
          >
            {t.apply}
          </Button>
        </GridItem>
      </Grid>
    </Drawer>
  )
}
