export interface Location {
  lat: number
  lng: number
}

export interface Address {
  country: string
  administrativeAreaLevel1?: string
  postalCode?: string
  locality?: string
  route?: string
  streetNumber?: string
  location?: Location
  formatted?: string
}

function standardFormatAddress(address: Address, includeCountry: boolean): string {
  let result = ""

  if (address.route) {
    result += address.route
    if (address.streetNumber) {
      result += " "
      result += address.streetNumber
    }
    result += ", "
  }
  if (address.locality) {
    if (address.postalCode) {
      result += address.postalCode
      result += " "
    }
    result += address.locality
  }

  if (includeCountry) {
    result += ", "
    result += address.country
  }

  return result
}

export function formatAddress(address: Address): string {
  return standardFormatAddress(address, true)
}

export function formatAddressWithoutCountry(address: Address): string {
  return standardFormatAddress(address, false)
}

export function isAddressComplete(address: Address | null) {
  if (!address) return false
  return address.postalCode && address.locality && address.route && address.streetNumber && address.location
}
