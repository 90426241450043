import * as React from "react"
import { FormEvent, useEffect } from "react"
import { CompanyAdminState } from "../reducers/company-admin-state"
import { useSelector } from "react-redux"
import { translations } from "../i18n"
import { Company } from "../models/company"
import { addressEquals, emptyAddress } from "../models/address"
import { companyadminServiceUrl, domainName } from "../../app_config"
import { menuTranslations } from "../../menu/i18n"
import { usePrevious } from "../../utils/use-previous"
import { deleteImage, fetchCompany, updateAddress, uploadImage, validateAddress } from "../reducers/settings-slice"
import { navigateTo } from "../../shared/reducers/navigation-slice"
import { NavigationPage } from "../../shared/actions/navigation"
import { userLogout } from "../../relas/user-slice"
import Badge from "../../shared/components/badge"
import Fileupload from "../../shared/components/fileupload"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import RevoHeader from "../../shared/components/revo-header"
import Dialog from "../../shared/components/dialog"
import DialogFooter from "../../shared/components/dialogfooter"
import Card from "../../shared/components/card"
import LoadingDimmer from "../../shared/components/loadingdimmer"
import Panel from "../../shared/components/panel"
import Grid from "../../shared/components/restyle-grid/grid"
import GridItem from "../../shared/components/restyle-grid/griditem"
import Text from "../../shared/components/text"
import TextField from "../../shared/components/textfield"
import Button from "../../shared/components/button"
import { GenericError } from "../../shared/helper/axios"
import Form from "../../shared/components/form"

enum Dialogs {
  NONE,
  EDIT,
  EDIT_WARNING,
  VERIFICATION,
  EDIT_SUCCESS,
  VERIFICATION_SUCCESS,
}

const cacheBustImage = (url: string): string => {
  return url + "#" + new Date().getTime()
}

export const CompanySettings = () => {
  const t = React.useMemo(translations, [translations])
  const menuTranslate = React.useMemo(menuTranslations, [menuTranslations])
  const translate = t.settings
  const companyLogoPath = companyadminServiceUrl + "/api/v1/company/logo"

  const company = useSelector((state: CompanyAdminState) => state.companySettings.company)
  const fetchCompanyError = useSelector((state: CompanyAdminState) => state.companySettings.fetchCompanyError)
  const fetchCompanyInProgress = useSelector((state: CompanyAdminState) => state.companySettings.fetchCompanyInProgress)
  const updateLogoUrlError = useSelector((state: CompanyAdminState) => state.companySettings.updateLogoUrlError)
  const updateAddressInProgress = useSelector(
    (state: CompanyAdminState) => state.companySettings.updateAddressInProgress
  )
  const updateAddressError = useSelector((state: CompanyAdminState) => state.companySettings.updateAddressError)
  const validateAddressInProgress = useSelector(
    (state: CompanyAdminState) => state.companySettings.validateAddressInProgress
  )

  const previousCompany = usePrevious(company)

  const [currCompany, setCurrCompany] = React.useState<Company | undefined>(company)
  const [currentImageUrl, setCurrentImageUrl] = React.useState<string | undefined>(cacheBustImage(companyLogoPath))
  const [hasImage, setHasImage] = React.useState<boolean>(!!(company && company.logoS3Url))
  const [addressVerificationCode, setAddressVerificationCode] = React.useState<string>("")
  const [visibleDialog, setVisibleDialog] = React.useState<Dialogs>(Dialogs.NONE)
  const [currValidateAddressError, setCurrValidateAddressError] = React.useState<GenericError | undefined>(undefined)

  useEffect(() => {
    if (company !== previousCompany) {
      setCurrCompany(company)
      setCurrentImageUrl(cacheBustImage(companyLogoPath))
      setHasImage(!!(company && company.logoS3Url))
    }
  }, [company])

  useEffect(() => {
    void fetchCompany()
  }, [])

  const handleLogoUpload = (file: File): Promise<void> => {
    return uploadImage(file, companyLogoPath)
  }

  const handleLogoDeletion = () => {
    void deleteImage(companyLogoPath)
  }

  const handleAddressFormSubmit = (event: FormEvent) => {
    event.preventDefault()

    if (currCompany && currCompany.address) {
      updateAddress(currCompany.address).then(
        () => {
          setDialogVisibility(Dialogs.EDIT_SUCCESS)
        },
        () => {}
      )
    }
  }

  const setDialogVisibility = (visibleDialog: Dialogs) => {
    setVisibleDialog(visibleDialog)
  }

  const onCloseAddressVerificationDialog = () => {
    setDialogVisibility(Dialogs.NONE)
    setAddressVerificationCode("")
    setCurrValidateAddressError(undefined)
  }

  const onCloseAddressEditDialog = () => {
    setDialogVisibility(Dialogs.NONE)
    if (company) setCurrCompany(company)
  }

  const handleValidateCode = () => {
    validateAddress({ value: addressVerificationCode }).then(
      () => setDialogVisibility(Dialogs.VERIFICATION_SUCCESS),
      () => {}
    )
  }

  const renderCompanyLogo = () => {
    if (company && company.logoS3Url) {
      return (
        <div>
          <img alt="logo" style={{ maxHeight: "300px", width: "auto" }} src={currentImageUrl} />
        </div>
      )
    } else {
      return <div />
    }
  }

  const updateCompanyAddressState = (key: string, value: any) => {
    if (company) {
      const address = company.address ? company.address : emptyAddress
      const updatedCompany = { ...company, ...{ address: { ...address, ...{ [key]: value } } } }
      setCurrCompany(updatedCompany)
    }
  }

  const isAddressChanged = (): boolean => {
    return !(
      company &&
      company.address &&
      company &&
      company.address &&
      addressEquals(company.address, company.address)
    )
  }

  const renderEditAddressWarningDialog = () => {
    return (
      <Dialog
        width="500px"
        onClose={() => setDialogVisibility(Dialogs.NONE)}
        title={translate.editAddressDialogTitle}
        closeButton={true}
        closeOnClickOutside={true}
        icon="info"
      >
        <Grid paddingY="md" paddingX="md" columns={1}>
          <Panel color="neutral">{translate.editAddressWarningText}</Panel>
        </Grid>
        <DialogFooter md-justify="spaceBetween" spaceBetween="md" paddingX="md" paddingY="lg" direction="row">
          <Button type="secondary" onClick={() => setDialogVisibility(Dialogs.NONE)}>
            {translate.editAddressWarningCancelLabel}
          </Button>
          <Button type="primary" onClick={() => setDialogVisibility(Dialogs.EDIT)}>
            {translate.editAddressWarningConfirmLabel}
          </Button>
        </DialogFooter>
      </Dialog>
    )
  }

  const renderEditAddressSuccessDialog = () => {
    return (
      <Dialog
        width="500px"
        onClose={() => setDialogVisibility(Dialogs.NONE)}
        title={translate.editAddressDialogTitle}
        closeButton={true}
        closeOnClickOutside={true}
        icon="info"
      >
        <Grid paddingY="md" paddingX="md" columns={1}>
          <Panel color="positive">{translate.editAddressSuccessText}</Panel>
        </Grid>
        <DialogFooter md-justify="center" paddingX="md" paddingY="lg" direction="row">
          <Button type="primary" onClick={() => setDialogVisibility(Dialogs.NONE)}>
            {translate.okButtonLabel}
          </Button>
        </DialogFooter>
      </Dialog>
    )
  }

  const renderEditAddressDialog = () => {
    const address = company && company.address ? company.address : emptyAddress
    return (
      <Dialog
        onClose={onCloseAddressEditDialog}
        title={translate.editAddressDialogTitle}
        closeButton={true}
        closeOnClickOutside={true}
      >
        <Grid columns={1} paddingX="xl" paddingY="xl">
          <Form onSubmit={handleAddressFormSubmit}>
            <LoadingDimmer loading={updateAddressInProgress}>
              <Grid gap="xl" columns={3}>
                <GridItem colSpan={2}>
                  <TextField
                    required={true}
                    label={translate.streetLabel}
                    value={address.street}
                    onValueChange={(value) => {
                      updateCompanyAddressState("street", value)
                    }}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <TextField
                    required={true}
                    label={translate.houseNumberLabel}
                    value={address.houseNumber}
                    onValueChange={(value) => {
                      updateCompanyAddressState("houseNumber", value)
                    }}
                  />
                </GridItem>
                <GridItem colSpan={3}>
                  <TextField
                    required={false}
                    label={translate.additionalInfoLabel}
                    value={address.additionalAddressInformation ? address.additionalAddressInformation : ""}
                    onValueChange={(value) => {
                      updateCompanyAddressState("additionalAddressInformation", value)
                    }}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <TextField
                    required={true}
                    label={translate.zipLabel}
                    value={address.zip}
                    onValueChange={(value) => {
                      updateCompanyAddressState("zip", value)
                    }}
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <TextField
                    required={true}
                    label={translate.cityLabel}
                    value={address.city}
                    onValueChange={(value) => {
                      updateCompanyAddressState("city", value)
                    }}
                  />
                </GridItem>
                <GridItem colSpan={3}>
                  <TextField
                    required={true}
                    label={translate.countryLabel}
                    value={address.country}
                    onValueChange={(value) => {
                      updateCompanyAddressState("country", value)
                    }}
                  />
                </GridItem>
                <GridItem colSpan={3} justifySelf={"end"}>
                  <Button type="primary" disabled={!isAddressChanged()}>
                    {translate.saveAddressButton}
                  </Button>
                </GridItem>
                <GridItem colSpan={3}>
                  {updateAddressError && <GenericErrorPanel error={updateAddressError} />}
                </GridItem>
              </Grid>
            </LoadingDimmer>
          </Form>
        </Grid>
      </Dialog>
    )
  }

  const renderAddressVerificationDialog = () => {
    return (
      <Dialog
        onClose={() => onCloseAddressVerificationDialog()}
        title={translate.verifyAddressDialogTitle}
        closeButton={true}
        closeOnClickOutside={true}
        icon="info"
      >
        <LoadingDimmer loading={validateAddressInProgress}>
          <Grid paddingY="md" paddingX="md" columns={1}>
            <TextField
              minLength={6}
              maxLength={6}
              required={true}
              hint={translate.verifyAddressHint}
              label="Code"
              value={addressVerificationCode}
              onValueChange={(value) => setAddressVerificationCode(value)}
            />
            <GridItem>
              {currValidateAddressError && <Panel color="positive">{translate.wrongVerificationCodeError}</Panel>}
            </GridItem>
          </Grid>
        </LoadingDimmer>
        <DialogFooter md-justify="spaceBetween" spaceBetween="md" paddingX="md" paddingY="lg" direction="row">
          <Button type="secondary" onClick={onCloseAddressVerificationDialog}>
            {translate.cancelButtonLabel}
          </Button>
          <Button type="primary" disabled={addressVerificationCode.length != 6} onClick={() => handleValidateCode()}>
            {translate.verifyAddressConfirmButtonLabel}
          </Button>
        </DialogFooter>
      </Dialog>
    )
  }

  const onLogout = (e: React.MouseEvent) => {
    e.preventDefault()
    setDialogVisibility(Dialogs.NONE)
    void userLogout().then(() => {
      navigateTo({ name: NavigationPage.login })
    })
  }

  const renderAddressVerificationSuccessDialog = () => {
    return (
      <Dialog
        width="500px"
        onClose={() => setDialogVisibility(Dialogs.NONE)}
        title={translate.verifyAddressDialogTitle}
        closeButton={true}
        icon="info"
      >
        <Grid paddingY="md" paddingX="md" columns={1}>
          <Panel color="positive">{translate.addressVerificationSuccessText}</Panel>
        </Grid>
        <DialogFooter spaceBetween="md" md-justify="center" paddingX="md" paddingY="lg" direction="row">
          <Button type="primary" href={`https://login.${domainName}/logout`} onClick={onLogout}>
            {translate.logoutButtonLabel}
          </Button>
        </DialogFooter>
      </Dialog>
    )
  }

  const renderCompanyLogoCard = () => {
    return (
      <Card header={translate.logoUploadHeadline}>
        <Grid gap="lg" columns={1}>
          {updateLogoUrlError ? <GenericErrorPanel error={updateLogoUrlError} /> : renderCompanyLogo()}
          <GridItem justifySelf="start">
            <Grid columns={2} gap="md">
              <Fileupload type="primary" icon="upload" onUpload={handleLogoUpload}>
                {translate.logoUrlUploadButtonLabel}
              </Fileupload>
              <Button type="secondary" disabled={!hasImage} onClick={handleLogoDeletion}>
                {translate.logoUrlDeleteButtonLabel}
              </Button>
            </Grid>
          </GridItem>
        </Grid>
      </Card>
    )
  }

  const renderAddressCard = () => {
    const address = company && company.address ? company.address : emptyAddress
    const addressVerified = company && company.addressVerified

    const header = (
      <Grid columns={4} columnSpec="1fr fit-content(100%)">
        <GridItem>{translate.addressHeadline}</GridItem>
        <GridItem>
          {addressVerified ? (
            <Badge color="positive" size="large">
              {translate.addressVerifiedLabel}
            </Badge>
          ) : (
            <Badge color="negative" size="large">
              {translate.addressNotVerifiedLabel}
            </Badge>
          )}
        </GridItem>
      </Grid>
    )

    return (
      <Card header={header}>
        <Grid rowGap="md" columns={2} gap="md" paddingX="md" paddingY="md">
          <Text fontWeight="bold">{translate.streetLabel}</Text>
          <Text>{`${address.street} ${address.houseNumber}`}</Text>

          <Text fontWeight="bold">{translate.additionalInfoLabel}</Text>
          <Text>{address.additionalAddressInformation ? address.additionalAddressInformation : "-"}</Text>

          <Text fontWeight="bold">{translate.zipLabel}</Text>
          <Text>{address.zip}</Text>

          <Text fontWeight="bold">{translate.cityLabel}</Text>
          <Text>{address.city}</Text>

          <Text fontWeight="bold">{translate.countryLabel}</Text>
          <Text>{address.country}</Text>

          <GridItem justifySelf="start">
            <Grid columns={2} gap="md">
              {addressVerified ? (
                ""
              ) : (
                <Button type="primary" onClick={() => setDialogVisibility(Dialogs.VERIFICATION)}>
                  {translate.openValidateAddressDialogLabel}
                </Button>
              )}
              <Button type="secondary" onClick={() => setDialogVisibility(Dialogs.EDIT_WARNING)}>
                {translate.openEditAddressDialogLabel}
              </Button>
            </Grid>
          </GridItem>
          <div></div>
        </Grid>
      </Card>
    )
  }

  const renderDialog = () => {
    switch (visibleDialog) {
      case Dialogs.NONE:
        return <></>
      case Dialogs.EDIT:
        return renderEditAddressDialog()
      case Dialogs.EDIT_SUCCESS:
        return renderEditAddressSuccessDialog()
      case Dialogs.EDIT_WARNING:
        return renderEditAddressWarningDialog()
      case Dialogs.VERIFICATION:
        return renderAddressVerificationDialog()
      case Dialogs.VERIFICATION_SUCCESS:
        return renderAddressVerificationSuccessDialog()
      default:
        return <></>
    }
  }

  return (
    <div className="xwith-body-scroll">
      <Grid columns={1}>
        <RevoHeader title={menuTranslate.apps.admin.submenus.companySettings} />

        {fetchCompanyError ? (
          <GenericErrorPanel error={fetchCompanyError} />
        ) : (
          <LoadingDimmer loading={fetchCompanyInProgress}>
            <Grid paddingY="xxl" paddingX="xxl" columns={2} gap="xxl">
              <GridItem colSpan={{ mobilePortrait: 2, tabletLandscape: 1 }}>{renderCompanyLogoCard()}</GridItem>

              <GridItem colSpan={{ mobilePortrait: 2, tabletLandscape: 1 }}>
                {renderAddressCard()}
                {renderDialog()}
              </GridItem>
            </Grid>
          </LoadingDimmer>
        )}
      </Grid>
    </div>
  )
}
