import * as React from "react"
import { useSelector } from "react-redux"
import { translations } from "../i18n"
import { UserAccountState } from "../user-profile-slice"
import Grid from "../../shared/components/restyle-grid/grid"
import GridItem from "../../shared/components/restyle-grid/griditem"

export const PersonalDataReadOnly = () => {
  const t = React.useMemo(translations, [translations])
  const translate = t.settings
  const profileData = useSelector((state: UserAccountState) => state.userProfile.profileData)
  const personal = profileData && profileData.personalData

  return (
    <Grid
      columns={2}
      columnSpec={[
        [1, "fr"],
        [2, "fr"],
      ]}
      gap="lg"
      paddingY="lg"
    >
      <GridItem colStart={1} rowStart={2}>
        {translate.personalDataName}
      </GridItem>
      <GridItem colStart={1} rowStart={3}>
        {translate.personalDataSurname}
      </GridItem>
      <GridItem colStart={1} rowStart={4}>
        {translate.personalDataEmail}
      </GridItem>
      <GridItem colStart={1} rowStart={5}>
        {translate.personalDataPhone}
      </GridItem>
      <GridItem colStart={1} rowStart={6}>
        {translate.personalDataCompanyName}
      </GridItem>
      {personal && (
        <>
          <GridItem colStart={2} rowStart={2}>
            {personal.name}
          </GridItem>
          <GridItem colStart={2} rowStart={3}>
            {personal.surname}
          </GridItem>
          <GridItem colStart={2} rowStart={4}>
            {personal.email}
          </GridItem>
          <GridItem colStart={2} rowStart={5}>
            {personal.phone}
          </GridItem>
          <GridItem colStart={2} rowStart={6}>
            {personal.companyName}
          </GridItem>
        </>
      )}
    </Grid>
  )
}
