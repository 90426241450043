import { ProfileTranslationsGroup } from "./translations"
import { SHARED_EN } from "../../shared/i18n/en"

export const PROFILE_EN: ProfileTranslationsGroup = {
  ...SHARED_EN,
  profileExplorer: {
    header: "Profile Manager",
    downloadMacroScores: "Download macro scores",
    downloadOptions: "Download Options",
    downloadFromScore: "From score",
    downloadToScore: "To score",
  },
  profileAdd: {
    name: "Name",
    title: "Create profile",
    create: "Create",
    add: "New profile",
    group: "New group",
    otherProfilesCategory: "Profiles",
  },
  profileEdit: {
    menuItem: "Edit",
    addMicroScore: "Add micro score",
    addMacroScore: "Add macro score",
    inverting: "Inverting",
    weight: "Weight",
    scoreWeighting: "Score Weighting",
    scoreWeightingInfo: "All weights must be greater than zero and add up to 100",
    removingScoreAlertTitle: "You are removing a score",
    removingScoreAlertText: "How do you want to distribute its weight to the remaining scores?",
    changingScoreAlertTitle: "You are changing the list of scores",
    changingScoreAlertText: "How do you want to distribute the weight between the scores?",
    modifiedProfileAlertTitle: "The profile has been modified",
    modifiedProfileAlertText: "Do you want to save the changes?",
    numericInput: "Numeric input",
    distributeEvenly: "Distribute evenly",
  },
  profileDuplicate: {
    title: "Duplicate profile",
    menuItem: "Duplicate",
    nameSuffix: " Copy",
  },
  profileRename: {
    title: "Rename profile",
    menuItem: "Rename",
  },
  profileDelete: {
    menuItem: "Delete",
    title: "Delete profile",
    confirm: (name: string) => `Do you really want to delete the profile '${name}'?`,
    inUseTitle: "Profile cannot be deleted",
    profileIsInUse: (name: string) => `${name} is used in the following places.`,
    profile: "Profile",
    selections: "Selections",
    ratings: "Ratings",
  },
  profileLock: {
    lockMenuItem: "Lock",
    lockTitle: "Lock profile",
    unlockTitle: "Unlock profile",
    lockConfirm:
      "You can lock a profile to protect it. Your colleagues can still use it, but they can not edit or delete it.",
    unlockConfirm: "If you unlock your profile, your colleagues will be able to edit and delete it.",
    unlockMenuItem: "Unlock",
  },
  profileList: {
    title: "Profiles",
    backTo: "All profiles",
  },
  profileGroup: {
    title: "Create group",
  },
  groupRename: {
    title: "Rename group",
    menuItem: "Rename",
  },
  groupDelete: {
    menuItem: "Delete",
    title: "Delete group",
    confirm: (name: string) =>
      `Do you really want to delete the group '${name}'? The containing profiles won't be deleted.`,
  },
  scoreSearchFilter: {
    filter: "Filter",
    groups: "Groups",
    expandActive: "Expand active groups",
    expandAll: "Expand all groups",
    collapseAll: "Collapse all groups",
    info: "Here you can filter matching scores. Names and descriptions are included in the search.",
  },
}
