import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SpecialMapsInput } from "../models/special-maps"
import { store } from "../../relas/store"
import { WmsLayer } from "../../shared/models/wms-layer"
import { IsochroneType } from "../components/isochrone-type"
import { ASSESSMENT_ENTRY_CHANGED_MODULE } from "../actions/assessment-module-action-creators"

export interface SpecialMapsAppState {
  specialMapsInput: SpecialMapsInput
}

export const initialState: SpecialMapsAppState = {
  specialMapsInput: {
    selectedLayer: null,
    isochroneSettings: { mode: "none", time: 15 },
    isochronePolygon: undefined,
  },
}
const specialMapsSlice = createSlice({
  name: "specialMaps",
  initialState,
  reducers: {
    setSpecialMapsInputSelectedLayer(state, action: PayloadAction<WmsLayer | null>) {
      state.specialMapsInput.selectedLayer = action.payload
    },
    setSpecialMapsInputIsochroneSettings(state, action: PayloadAction<IsochroneType>) {
      state.specialMapsInput.isochroneSettings = action.payload
    },
    setSpecialMapsInputIsochronePolygon(state, action: PayloadAction<google.maps.Polygon | undefined>) {
      state.specialMapsInput.isochronePolygon = action.payload
    },
  },
  extraReducers: {
    [ASSESSMENT_ENTRY_CHANGED_MODULE]: (state) => {
      state.specialMapsInput = initialState.specialMapsInput
    },
  },
})

const { setSpecialMapsInputSelectedLayer, setSpecialMapsInputIsochroneSettings, setSpecialMapsInputIsochronePolygon } =
  specialMapsSlice.actions

export function updateSpecialMapsInputSelectedLayer(selectedLayer: WmsLayer | null): void {
  store.dispatch(setSpecialMapsInputSelectedLayer(selectedLayer))
}
export function updateSpecialMapsInputIsochroneSettings(isochroneSettings: IsochroneType): void {
  store.dispatch(setSpecialMapsInputIsochroneSettings(isochroneSettings))
}

export function updateSpecialMapsInputIsochronePolygon(isochronePolygon: google.maps.Polygon | undefined): void {
  store.dispatch(setSpecialMapsInputIsochronePolygon(isochronePolygon))
}

export default specialMapsSlice.reducer
