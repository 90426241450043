import { MenuTranslations } from "./translations"

export const EN: MenuTranslations = {
  appDrawerTeaser: "More apps and profile",
  menuTeaser: "Open menu",
  openMenu: "Open menu",
  popupMenu: {
    logout: "Logout",
    profileSettings: "Profile Settings",
    securitySettings: "Security Settings",
    support: "Support",
    imprint: "Imprint",
    privacy: "Data Privacy",
    tac: "Terms & Conditions",
    releaseNotes: "Release Notes",
  },
  appDrawer: {
    switchApps: "Switch Application",
    loggedInAs: "Logged in as",
    logout: "Logout",
    myAccount: "My Account",
    links: {
      support: {
        label: "Support",
        url: "mailto:support@21re.de",
        overThisLine: "Please write your feedback over this line",
      },
      imprint: {
        label: "Imprint",
        url: "https://21re.de/impressum?lang=en",
      },
      privacy: {
        label: "Data Privacy",
        url: "https://21re.de/datenschutz?lang=en",
      },
      tac: {
        label: "Terms & Conditions",
        url: "https://21re.de/agb?lang=en",
      },
    },
  },
  apps: {
    admin: {
      title: "Administration",
      submenus: {
        companySettings: "Company settings",
        apiAdmin: "Manage API Tokens",
        companyUsers: "User management",
        products: "Product usage",
      },
    },
    account: {
      title: "My account",
      submenus: {
        profile: "Profile",
        security: "Security",
        login: "Login",
      },
    },
    valuation: {
      title: "Valuation",
      submenus: {
        home: "Home",
      },
    },
    locationAnalytics: {
      title: "RELAS",
      submenus: {
        quickstart: "Quickstart",
        profileManager: "Profile Manager",
        locationAssessment: {
          name: "Location Assessment",
          modules: {
            dashboard: "Assessment Overview",
            assessment: "Scores",
            ratings: "Ratings",
            prices: "Prices",
            comparables: "Comparables",
            marketdata: "District Data",
            poiExplorer: "POI Explorer",
            specialMaps: "Special Maps",
            rentindex: "Rent Index",
            fundamentalData: "Fundamental Data",
          },
        },
        locationExplorer: "Location Explorer",
        privateData: "Private Data",
        locationSelector: "Location Selector",
        ratingManager: "Rating Manager",
      },
    },
  },
  admin: {
    admin: "Administration",
    companyDefaults: "Company defaults",
    calculationMethods: "Calculation methods",
    apiTokens: "Manage API tokens",
    productUsage: "Product usage",
    companyMembers: "User management",
    companySettings: "Company settings",
    autoassign: "Auto-assign app",
  },
  sidebar: {
    settings: "Settings",
    monitors: "External monitors",
    notifications: "Notifications",
    logout: "Logout",
    feedback: "Feedback",
  },
  login: "Login",
  registration: "Registration",
  forum: "Forum",
  profile: {
    logout: "Logout",
    language: "Language",
    templates: "Templates",
    settings: "Settings",
    monitor: "External Monitors",
    statusAndInvoice: "Status & Invoice",
    deleteAccount: "Delete Account",
  },
  imprint: "Legal data",
  terms: "Terms and conditions",
  privacy: "Privacy protection",
  relativeImprintLink: "impressum?lang=en",
  relativePrivacyLink: "datenschutz?lang=en",
  relativeTermsLink: "agb?lang=en",
  wrongBrowser: "This page is optimized for: ",
  languageCode: "en",
  legal: {
    contact: "Contact",
    imprint: "Imprint",
    agb: "AGB",
    dataProtection: "Data protection",
  },
  reloginPortal: {
    title: "Your session has expired.  Please login again.",
  },
}
