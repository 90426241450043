import { ObjectInterpolation } from "emotion"
import { isResponsiveSpec, mapBreakpoint, ResponsiveSpec } from "../util/layoutconstants"

export type EmotionCSS = ObjectInterpolation<undefined>

export function responsiveSpecToCss<T>(spec: ResponsiveSpec<T>, transformer: (item: T) => EmotionCSS): EmotionCSS {
  return mapBreakpoint((item, key) => {
    return {
      [`@media (min-width: ${key[1].min}px)`]: {
        ...revoSpecToCss(item, transformer),
      },
    }
  }, spec).reduce((acc, val) => ({ ...acc, ...val }), {} as EmotionCSS)
}

export const revoSpecToCss = <T>(spec: T | ResponsiveSpec<T>, transformer: (item: T) => EmotionCSS): EmotionCSS => {
  if (isResponsiveSpec(spec)) {
    return responsiveSpecToCss(spec, transformer)
  } else {
    return transformer(spec)
  }
}
