import * as React from "react"
import { css, cx } from "emotion"
import { PropsWithChildren } from "react"
import { UIState } from "../util/colorconstants"
import { getThemeColorVar } from "../helper/color"
import { EmotionCSS } from "../helper/responsive"

export interface Props {
  id?: string
  title?: string
  color?: UIState
}

const defaultProps: Props = {}

const panelClass = css({
  "&:before, &:after": {
    content: '" "',
    display: "table",
  },
  "&:after": {
    clear: "both",
  },
  padding: "10px 15px",
  borderRadius: "3px",
  backgroundColor: getThemeColorVar("white", undefined),
  border: `1px solid ${getThemeColorVar("border", "default")}`,
} as EmotionCSS)

const titleClass = css({
  fontWeight: 600,
  display: "block",
  margin: "0 0 5px 0",
} as EmotionCSS)

export const Panel: React.FunctionComponent<PropsWithChildren<Props>> = (props) => {
  const classes: string[] = [panelClass]
  const titleClasses = [titleClass]

  if (props.color) {
    classes.push(
      css({
        color: getThemeColorVar(props.color, "dark"),
        backgroundColor: getThemeColorVar(props.color, "light"),
        borderColor: getThemeColorVar(props.color, "default"),
      })
    )

    titleClasses.push(
      css({
        color: getThemeColorVar(props.color, "dark"),
      })
    )
  }

  return (
    <div id={props.id} className={cx(classes)}>
      {props.title && <div className={cx(titleClasses)}>{props.title}</div>}
      {props.children}
    </div>
  )
}

Panel.defaultProps = defaultProps

export default Panel
