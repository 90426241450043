import * as React from "react"
import { useEffect, useMemo, useState } from "react"
import { COLORS } from "../../shared/components/ui/colors"
import { Flex } from "../../shared/components/ui/flex"
import { FlexItem } from "../../shared/components/ui/flex-item"
import { TagEditField } from "../../shared/components/ui/tag-edit-fields"
import { Profile } from "../../profile/models/profile"
import { translations } from "../i18n"
import {
  Assessment,
  AssessmentEntryFull,
  AssessmentState,
  DetailSelectionTab,
  isInProgressState,
  WidgetsType,
} from "../models/assessment"
import { css } from "emotion"
import { AssessmentDetailSelection } from "./assessment-entry-detail-selection"
import { AssessmentDetailView } from "./assessment-entry-detail-view"
import { DataSetType, DataSetTypes } from "../../shared/models/smartdata"
import { lanaApiUrl } from "../../app_config"
import Axios, { AxiosResponse } from "axios"
import useInterval from "../../utils/use-interval"
import { Rating } from "../../shared/models/ratings"
import { getEmptyScores, Scores } from "../../shared/models/scores"
import { DocumentationAndSupport } from "../../shared/components/documentation-and-support"
import { useAppSelector } from "../../relas/store"
import { Category } from "../../shared/smartdata-products/smartdata"
import { AppModules } from "../../menu/util/app-location-types"
import CommonMapView from "../../shared/components/common-map-view"
import { AllowedModulesEnum } from "../../private-data/models/private-data"
import {
  loadAssessmentEntry,
  loadAssessmentEntryContextScores,
  loadAssessmentEntryProfileScores,
  loadSelectionEntries,
  setAssessmentSelectedViewMode,
  setAssessmentStatus,
  setSelectedProfile as onSetSelectedProfile,
  updateAssessmentEntryTags,
} from "../reducers/assessment-slice-functions"
import { DownloadReport } from "../../shared/components/ui/download-report"
import { isAssessmentPartitionOutdated } from "../../shared/models/geo-partition"
import { InvalidAddressMessage } from "../../shared/components/ui/invalid-address-message"
import ButtonTabs from "../../shared/components/buttontabs"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import Slider from "../../shared/components/slider"
import Select from "../../shared/components/select"
import CenteredElement from "../../shared/components/layout/centeredelement"
import Grid from "../../shared/components/restyle-grid/grid"
import GridItem from "../../shared/components/restyle-grid/griditem"
import LoadingSpinner from "../../shared/components/loadingspinner"
import LanaSubheader from "../../shared/components/lana-subheader"
import { profilesHelpLink } from "../../profile/components/profiles"

interface OwnProps {
  module: AppModules["locationAssessment"]
  widget?: WidgetsType
}

export type Props = OwnProps

const defaultFontSizeClass = css({
  fontSize: "14px", // eventually, the whole app should default to this. Limit to here for now.
})

const dataSetTypeClass = css({
  backgroundColor: "white",
  borderRadius: "4px",
  "div div:last-child": {
    lineHeight: "16px",
  },
})

export const AssessmentEntry = (props: Props) => {
  const t = useMemo(translations, [translations])

  const user = useAppSelector((state) => state.user)
  const currentAssessment = useAppSelector((state) => state.assessment.currentAssessment)
  const assessmentLoadInProgress = useAppSelector((state) => state.assessment.assessmentLoadInProgress)
  const currentAssessmentEntry = useAppSelector((state) => state.assessment.currentAssessmentEntry)
  const assessmentEntryLoadInProgress = useAppSelector((state) => state.assessment.assessmentEntryLoadInProgress)
  const assessmentEntryLoadError = useAppSelector((state) => state.assessment.assessmentEntryLoadError)
  const assessmentUpdateInProgress = useAppSelector((state) => state.assessment.assessmentUpdateInProgress)
  const poiCategoryTranslations = useAppSelector((state) => state.assessment.poiCategoryTranslations)
  const availableMacroContexts = useAppSelector((state) => state.assessment.availableMacroContexts)
  const selectedMacroContext = useAppSelector((state) => state.assessment.currentAssessment?.macroContext)
  const comparablesItems = useAppSelector((state) => state.assessment.comparablesItems)
  const profileScoresInProgress = useAppSelector((state) => state.assessment.assessmentEntryProfileScoresInProgress)
  const contextScoresInProgress = useAppSelector((state) => state.assessment.assessmentEntryContextScoresInProgress)
  const contextScores = useAppSelector((state) => state.assessment.assessmentEntryContextScores)
  const scopes = useAppSelector((state) => state.user.scopes)
  const selectedViewMode = useAppSelector((state) => state.assessment.selectedViewMode)
  const isPrivateDataAccessible = useAppSelector((state) => state.user.scopes.privateData)
  const privatePoiCategories = useAppSelector((state) => state.privateData.privatePOICategories)
  const privateDataSettings = useAppSelector((state) => state.privateData.modulesWithPrivateData.ratingsScorePrices)

  const [viewMode, setViewMode] = useState<DataSetType>(selectedViewMode)
  const [selectedTab, setSelectedTab] = useState<DetailSelectionTab>("scores")
  const [shapeFillOpacity, setShapeFillOpacity] = useState(0.6)
  const [showScores, setShowScores] = useState(true)
  const [scores, setScores] = useState(getEmptyScores())
  const [selectedProfile, setSelectedProfile] = useState<Profile>()
  const [selectedScoreCategory, setSelectedScoreCategory] = useState<Category>()
  const [selectedProfileScore, setSelectedProfileScore] = useState<string>()
  const [selectedRating, setSelectedRating] = useState<Rating>()
  const [currentAssessmentEntryStatus, setCurrentAssessmentEntryStatus] = useState<AssessmentState | null>(null)

  useEffect(() => {
    if (scopes.explorer || scopes.locationSelector) {
      void loadSelectionEntries()
    }

    if (props.widget === "microScores") setViewMode("micro")
  }, [])

  useEffect(() => {
    const scores = selectedProfile?.scores ?? getEmptyScores()
    setSelectedProfileScore(undefined)
    setSelectedRating(undefined)
    setShowScores(true)
    setScores(scores)
    onSetSelectedProfile(selectedProfile)
  }, [selectedTab, selectedProfile])

  useEffect(() => {
    if (
      currentAssessment &&
      currentAssessmentEntry &&
      selectedScoreCategory &&
      viewMode === "macro" &&
      !(
        contextScores?.scoreCategoryName === selectedScoreCategory.name &&
        contextScores?.macroContext === selectedMacroContext
      )
    )
      void loadAssessmentEntryContextScores(
        currentAssessment,
        currentAssessmentEntry.id,
        selectedScoreCategory.name,
        selectedMacroContext
      )
  }, [viewMode])

  // null timeout will disable the callback
  useInterval(
    () => {
      if (
        currentAssessment &&
        currentAssessmentEntry &&
        currentAssessmentEntry.state !== "scored" &&
        currentAssessmentEntry.state !== "scored-ambiguous"
      ) {
        Axios.get<AssessmentState>(
          `${lanaApiUrl}/api/assessments/${currentAssessment.id}/entries/${currentAssessmentEntry.id}/state`
        ).then(
          (success: AxiosResponse<AssessmentState>) => {
            setCurrentAssessmentEntryStatus(success.data.replace("-ambiguous", "") as AssessmentState)
            setAssessmentStatus(success.data)
            if (
              currentAssessmentEntry?.state !== success.data &&
              (success.data === "scored" || success.data === "scored-ambiguous") &&
              currentAssessment &&
              currentAssessmentEntry?.id
            ) {
              // state was updated - refresh the page
              void loadAssessmentEntry(currentAssessment, currentAssessmentEntry.id)
            }
          },
          () => {}
        )
      }
    },
    currentAssessment &&
      currentAssessmentEntry &&
      currentAssessmentEntry.state !== "scored" &&
      currentAssessmentEntry.state !== "scored-ambiguous"
      ? 2000
      : null
  )

  useInterval(() => {
    if (currentAssessmentEntryStatus === "scored") setCurrentAssessmentEntryStatus(null)
  }, 4000)

  const isScoreContextLoading = () =>
    profileScoresInProgress ||
    contextScoresInProgress ||
    assessmentUpdateInProgress ||
    (currentAssessmentEntry !== null && isInProgressState(currentAssessmentEntry.state))

  const isMacroContextSwitchDisabled = (viewMode: DataSetType) => {
    switch (viewMode) {
      case "macro":
        return isScoreContextLoading()
      case "micro":
        return true
    }
  }

  const onSelectDataSetType = (ds: DataSetType) => {
    setViewMode(ds)
    setAssessmentSelectedViewMode(ds)
    setSelectedProfileScore(undefined)
    setSelectedRating(undefined)
    setShowScores(true)
  }

  const renderHeader = () => {
    return (
      <Flex flexDirection="column">
        <LanaSubheader
          menuSection={"locationAssessment"}
          assessment={currentAssessment}
          assessmentEntry={currentAssessmentEntry}
          module={props.module}
          recalculationButton={true}
          recalculationDetails={true}
          isLoading={assessmentEntryLoadInProgress || assessmentLoadInProgress}
        >
          <DocumentationAndSupport
            documentationURL={profilesHelpLink}
            addDocumentationLink
            tooltip={t.helpAndSupport}
            onClick={() => {}}
            callLocation="Price Widget"
          />
          <DownloadReport selectedScoreCategory={selectedScoreCategory} scores={scores} showXLSDownload={true} />
        </LanaSubheader>

        {currentAssessmentEntry?.state !== "failure" && (
          <Flex flexDirection="row" alignItems="center" padding={16} backgroundColor={COLORS.background.lighter}>
            <Grid columns={6} columnSpec="60px 300px 200px min-content  min-content 1fr" gap={8}>
              <GridItem justifySelf="left" alignSelf="center">
                <div style={{ fontWeight: "bold", paddingBottom: "2px" }}>{t.assessment.macroScoreContext}:</div>
              </GridItem>
              <GridItem alignSelf={"right"}>
                <div className={dataSetTypeClass}>
                  <ButtonTabs
                    values={DataSetTypes}
                    selected={viewMode}
                    onSelect={onSelectDataSetType}
                    translate={(viewMode) => t.scoreKey[viewMode]}
                  />
                </div>
              </GridItem>
              <GridItem>
                <Select
                  disabled={isMacroContextSwitchDisabled(viewMode)}
                  size="small"
                  value={selectedMacroContext || ""}
                  options={[
                    { value: "", label: t.assessment.macroScoreDefaultContext },
                    ...(availableMacroContexts || []).map((selection) => ({
                      value: selection.id,
                      label: selection.name,
                    })),
                  ]}
                  onValueChange={onContextSelectionValueChange(
                    currentAssessment,
                    currentAssessmentEntry,
                    selectedScoreCategory
                  )}
                />
              </GridItem>
            </Grid>
            <>
              <FlexItem flexGrow={1}></FlexItem>
              <Flex flexDirection="row" gap={8} alignItems="center">
                <div>
                  <b>{t.assessmentEntryDetails.tags}:</b>
                </div>
                <TagEditField
                  availableTags={currentAssessmentEntry?.availableTags?.map((t) => t.name) || []}
                  selectedTags={currentAssessmentEntry?.tags ?? []}
                  onSelectionChanged={onUpdateTags}
                />
              </Flex>
            </>
          </Flex>
        )}
      </Flex>
    )
  }

  const onContextSelectionValueChange =
    (
      currentAssessment: Assessment | null,
      currentAssessmentEntry: AssessmentEntryFull | null,
      selectedScoreCategory: Category | undefined
    ) =>
    (contextId: string) => {
      if (!currentAssessment || !currentAssessmentEntry) return
      void loadAssessmentEntryProfileScores(
        currentAssessment,
        currentAssessmentEntry.id,
        contextId == "" ? undefined : contextId
      )
      if (selectedScoreCategory)
        void loadAssessmentEntryContextScores(
          currentAssessment,
          currentAssessmentEntry.id,
          selectedScoreCategory.name,
          contextId == "" ? undefined : contextId
        )
    }

  const renderContent = () => {
    if (assessmentEntryLoadInProgress) {
      return <LoadingSpinner />
    }

    if (assessmentEntryLoadError) {
      return (
        <CenteredElement>
          <GenericErrorPanel error={assessmentEntryLoadError} />
        </CenteredElement>
      )
    }

    const onSetSelectedScoreCategory = (newScoreGroup?: Category) => {
      if (typeof selectedMacroContext === "string" && viewMode === "macro") {
        currentAssessment &&
          currentAssessmentEntry &&
          newScoreGroup &&
          void loadAssessmentEntryContextScores(
            currentAssessment,
            currentAssessmentEntry.id,
            newScoreGroup.name,
            selectedMacroContext
          )
      }
      setSelectedScoreCategory(newScoreGroup)
    }

    return (
      <Grid columns={1} height={[100, "%"]} rowSpec="minmax(0, 1fr)" gap={16} padding={16}>
        <Grid gap={8} columnSpec="3fr 4fr 5fr" rowSpec="1fr min-content">
          <AssessmentDetailSelection
            viewMode={viewMode}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            selectedProfile={selectedProfile}
            setSelectedProfile={setSelectedProfile}
            selectedScoreCategory={selectedScoreCategory}
            setSelectedScoreCategory={onSetSelectedScoreCategory}
            selectedRating={selectedRating}
            setSelectedRating={setSelectedRating}
            onSelectScores={(scores) => setScores(scores)}
          />
          <AssessmentDetailView
            viewMode={viewMode}
            selectedTab={selectedTab}
            selectedProfile={selectedProfile}
            selectedScoreCategory={selectedScoreCategory}
            selectedRating={selectedRating}
            onSelectScores={(scores) => setScores(scores)}
            selectedScores={scores}
            selectedProfileScore={selectedProfileScore}
            setSelectedProfileScore={(selectedProfileScore) => setSelectedProfileScore(selectedProfileScore)}
            showScores={showScores}
            setShowScores={(showScores) => setShowScores(showScores)}
          />
          <GridItem rowSpan={2}>{renderMap()}</GridItem>
        </Grid>
      </Grid>
    )
  }

  const renderMap = () => {
    let scoresForMap: Scores = scores
    if (
      (!showScores && !selectedProfileScore) ||
      isAssessmentPartitionOutdated(viewMode, currentAssessmentEntry ?? undefined)
    ) {
      scoresForMap = getEmptyScores()
    } else if (selectedTab === "profiles") {
      const scoreValue = selectedProfileScore ? scores[viewMode][selectedProfileScore] : null
      if (selectedProfileScore && scoreValue) {
        scoresForMap = {
          micro: viewMode === "micro" ? { [selectedProfileScore]: scoreValue } : {},
          macro: viewMode === "macro" ? { [selectedProfileScore]: scoreValue } : {},
        }
      }
    }

    return (
      <Grid columns={1} rowSpec="1fr fit-content(100%)" height={[100, "%"]}>
        <CommonMapView
          user={user}
          profileList={currentAssessmentEntry?.profiles}
          colorForValue={colorForValue}
          shapeFillOpacity={shapeFillOpacity}
          smartdataSource={viewMode}
          scores={scoresForMap}
          rating={selectedRating}
          macroContext={selectedMacroContext}
          overlayMainFactScoreType={selectedTab == "scores" ? "assessment-score" : "assessment-profile"}
          agsRefResLoc={currentAssessmentEntry?.agsRefResloc || ""}
          markerLocation={currentAssessmentEntry?.address.location}
          poiCategoryTranslations={poiCategoryTranslations}
          comparablesItems={comparablesItems}
          displayWmsLayer={true}
          cellId={currentAssessmentEntry?.cellId}
          agsId={currentAssessmentEntry?.agsId}
          agsLocalityTitle={currentAssessmentEntry?.address.locality}
          selectedProfileScore={selectedProfileScore}
          widget={props.widget}
          currentAssessmentEntryAddress={currentAssessmentEntry?.address}
          isPrivateDataAccessible={isPrivateDataAccessible}
          allowedModule={AllowedModulesEnum.RATINGS_SCORE_PRICES}
          privatePoiCategories={privatePoiCategories}
          privateDataSettings={privateDataSettings}
          mapErrorMessage={
            isAssessmentPartitionOutdated(viewMode, currentAssessmentEntry ?? undefined) ? "shape_outdated" : undefined
          }
        />
        <Grid columnSpec="fit-content(100%) 1fr fit-content(100%)" gap={8} padding={8}>
          <Flex flexDirection="row" alignItems="center" gap={8}>
            <div>0</div>
            <div
              style={{
                height: "20px",
                width: "400px",
                backgroundImage: `linear-gradient(to right, ${colorForValue(0, 1)}, ${colorForValue(100, 1)})`,
              }}
            />
            <div>100</div>
          </Flex>

          <div />

          <Flex flexDirection="row" alignItems="center" gap={8}>
            <Slider
              min={0}
              max={1}
              step={0.05}
              value={shapeFillOpacity}
              onChange={(shapeFillOpacity) => setShapeFillOpacity(shapeFillOpacity)}
            />
          </Flex>
        </Grid>
      </Grid>
    )
  }

  const colorForValue = (value: number, opacity: number) =>
    `hsla(${225 - (100 - (value * 0.5 + 50))}, 100%, 50%, ${opacity})`

  const onUpdateTags = (tags: string[]) => {
    if (!currentAssessment || !currentAssessmentEntry) return

    updateAssessmentEntryTags(currentAssessment, currentAssessmentEntry.id, tags).then(
      () => {},
      () => {}
    )
  }

  return (
    <div className={defaultFontSizeClass}>
      {/* there should be a better way */}
      <Grid columns={1} rowSpec="fit-content(100%) minmax(0, 1fr)" className={css({ height: "calc(100vh - 50px)" })}>
        {renderHeader()}
        <InvalidAddressMessage assessmentEntry={currentAssessmentEntry} />
        {currentAssessmentEntry?.state !== "failure" && renderContent()}
      </Grid>
    </div>
  )
}
