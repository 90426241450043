import * as React from "react"
import * as lodash from "lodash"
import { translations } from "../i18n"
import { Customer } from "../models/customer"
import { InviteMemberDialog } from "./invite-member-dialog"
import { useAppSelector } from "../../relas/store"
import { menuTranslations } from "../../menu/i18n"
import { deleteCustomer, Role, updateCompanyAdmin } from "../reducers/memberslist-slice"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import RevoHeader from "../../shared/components/revo-header"
import { SortableTableHeader } from "../../shared/components/sortable-table-header"
import DialogFooter from "../../shared/components/dialogfooter"
import Dialog from "../../shared/components/dialog"
import Card from "../../shared/components/card"
import LoadingDimmer from "../../shared/components/loadingdimmer"
import Panel from "../../shared/components/panel"
import Grid from "../../shared/components/restyle-grid/grid"
import Table from "../../shared/components/table"
import Button from "../../shared/components/button"
import { SortDirection } from "../../shared/models/sorting"

export type SortKey = "id" | "person.name" | "person.email" | "person.surname" | "person.companyAdmin"

export type Sorting = [SortDirection, SortKey]

export const CompanyMembersList: React.FunctionComponent = () => {
  const t = React.useMemo(translations, [translations])
  const menuTranslate = React.useMemo(menuTranslations, [menuTranslations])
  const translate = t.membersList

  const members = useAppSelector((state) => state.companyMembersList.members)
  const errorFetchMemberList = useAppSelector((state) => state.companyMembersList.fetchMembersListError)
  const errorDeleteMember = useAppSelector((state) => state.companyMembersList.deleteMemberError)
  const userId = useAppSelector((state) => state.user.user?.id)
  const inProgress = useAppSelector(
    (state) =>
      state.companyMembersList.fetchMembersListInProgress || state.companyMembersList.updateCompanyAdminInProgress
  )

  const [inviteDialogOpen, setInviteDialogOpen] = React.useState(false)
  const [sorting, setSorting] = React.useState<Sorting>([SortDirection.ASC, "person.name"])
  const [deleteMemberDialogOpenForCustomer, setDeleteMemberDialogOpenForCustomer] = React.useState<Customer | null>(
    null
  )

  const sorter = (sorting: Sorting) => {
    setSorting(sorting)
  }

  const sortingActive = (sortKey: SortKey) => {
    return sorting[1] === sortKey
  }

  const tableHead = () => {
    const sortDirection = sorting[0]
    return (
      <tr>
        <SortableTableHeader
          sorting={[sortDirection, "id"]}
          label={translate.headerFieldId}
          sorter={sorter}
          sortingActive={sortingActive}
        />
        <SortableTableHeader
          sorting={[sortDirection, "person.name"]}
          label={translate.headerFieldName}
          sorter={sorter}
          sortingActive={sortingActive}
        />
        <SortableTableHeader
          sorting={[sortDirection, "person.surname"]}
          label={translate.headerFieldSurname}
          sorter={sorter}
          sortingActive={sortingActive}
        />
        <SortableTableHeader
          sorting={[sortDirection, "person.email"]}
          label={translate.headerFieldEMail}
          sorter={sorter}
          sortingActive={sortingActive}
        />
        <SortableTableHeader
          sorting={[sortDirection, "person.companyAdmin"]}
          label={translate.headerFieldRole}
          sorter={sorter}
          sortingActive={sortingActive}
        />
        <th>{translate.headerFieldMemberStatus}</th>
        <th>{translate.headerFieldActions}</th>
      </tr>
    )
  }

  const tableRow = (member: Customer, customersCount: number) => {
    return (
      <tr key={member.id}>
        <td>{member.id}</td>
        <td>
          <span>{member.person.name}</span>
        </td>
        <td>
          <span>{member.person.surname}</span>
        </td>
        <td>{member.person.email}</td>
        <td>{member.person.companyAdmin ? translate.admin : translate.user}</td>
        <td>{renderStatus(member)}</td>
        <td>
          <div>
            <Button
              type="tertiary"
              disabled={userId === member.id}
              onClick={() => changeCompanyAdminRole(member, member.person.companyAdmin ? "user" : "admin")}
            >
              {member.person.companyAdmin ? translate.revoke : translate.promote}
            </Button>
            {customersCount > 1 && (
              <>
                &nbsp;|&nbsp;
                <Button type="tertiary" onClick={() => doDeleteMember(member)} disabled={userId === member.id}>
                  {translate.memberDelete}
                </Button>
              </>
            )}
          </div>
        </td>
      </tr>
    )
  }

  const changeCompanyAdminRole = (member: Customer, patchToRole: Role) => {
    void updateCompanyAdmin(member, patchToRole)
  }

  const doDeleteMember = (member: Customer) => {
    setDeleteMemberDialogOpenForCustomer(member)
  }

  const closeDialog = () => {
    setDeleteMemberDialogOpenForCustomer(null)
  }

  const renderRemoveMemberDialog = (customer: Customer) => {
    return (
      <Dialog
        width="500px"
        onClose={closeDialog}
        title={translate.memberDelete}
        closeButton={true}
        closeOnClickOutside={true}
        icon="info"
      >
        <Grid paddingY="md" paddingX="md" columns={1}>
          <Panel color="neutral">
            <p>{translate.deleteUserText}</p>
            <p>
              {customer.person.name} {customer.person.surname} &lt;{customer.person.email}&gt;
            </p>
          </Panel>
        </Grid>
        <DialogFooter md-justify="spaceBetween" spaceBetween="md" paddingX="md" paddingY="lg" direction="row">
          <Button type="secondary" onClick={closeDialog}>
            {t.settings.cancelButtonLabel}
          </Button>
          <Button
            type="primary"
            onClick={() => {
              void deleteCustomer(customer.id)
              closeDialog()
            }}
          >
            {translate.memberDelete}
          </Button>
        </DialogFooter>
      </Dialog>
    )
  }

  const renderStatus = (member: Customer) => {
    if (member.person.blocked) {
      return translate.blocked
    }
    switch (member.person.status) {
      case "invitation-pending":
        return translate.memberInvitationPending
      default:
        return ""
    }
  }

  const renderMembers = (members: Customer[]) => {
    return lodash
      .orderBy(members, sorting[1], sorting[0] === SortDirection.ASC ? "asc" : "desc")
      .map((member) => tableRow(member, members.length))
  }

  return (
    <div className="with-body-scroll">
      <Grid columns={1}>
        <RevoHeader title={menuTranslate.apps.admin.submenus.companyUsers} />
        <Grid columns={1} paddingY="xxl" paddingX="xxl" gap="xxl">
          <Card header={translate.header}>
            <LoadingDimmer loading={inProgress}>
              {errorFetchMemberList && <GenericErrorPanel error={errorFetchMemberList} />}
              {errorDeleteMember && <GenericErrorPanel error={errorDeleteMember} />}
              {members && (
                <Table>
                  <thead>{tableHead()}</thead>
                  <tbody>{renderMembers(members)}</tbody>
                </Table>
              )}
              <div style={{ marginTop: "15px" }}>
                <Button type="primary" onClick={() => setInviteDialogOpen(true)}>
                  {translate.addNewMember}
                </Button>
              </div>
            </LoadingDimmer>
            {inviteDialogOpen && <InviteMemberDialog onClose={() => setInviteDialogOpen(false)} />}
            {deleteMemberDialogOpenForCustomer && renderRemoveMemberDialog(deleteMemberDialogOpenForCustomer)}
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}
