import { ReportTranslations } from "./reportTranslations"
import { ASSESSMENTS_EN } from "../../assessment/i18n/en"

export const REPORT_EN: ReportTranslations = {
  ...ASSESSMENTS_EN,
  report: {
    profileScoreTitle: "Score",
    usageTypes: {
      "residential-apartment-rent": "Rent: Apartment",
      "residential-apartment-sale": "Purchase: Apartment",
      "residential-house-rent": "Rent: House",
      "residential-house-sale": "Purchase: House",
      office: "Rent: Office",
      retail: "Rent: Retail",
      hall: "Rent: Logistics",
      "plot-sale": "Purchase: Plot",
      logistics: "Rent: Logistics",
    },
    rentIndexHeader: "Rent Index",
    comparableAddress: "Address",
    comparableRooms: "Rooms",
    comparablePrice: "Price",
    observedOffers: "Comparables",
  },
}
