/* tslint:disable */
/* eslint-disable */
/**
 * Comparables Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum CountryCode {
    Abw = 'ABW',
    Afg = 'AFG',
    Ago = 'AGO',
    Aia = 'AIA',
    Alb = 'ALB',
    And = 'AND',
    Ant = 'ANT',
    Are = 'ARE',
    Arg = 'ARG',
    Arm = 'ARM',
    Asm = 'ASM',
    Ata = 'ATA',
    Atg = 'ATG',
    Aus = 'AUS',
    Aut = 'AUT',
    Aze = 'AZE',
    Bdi = 'BDI',
    Bel = 'BEL',
    Ben = 'BEN',
    Bfa = 'BFA',
    Bgd = 'BGD',
    Bgr = 'BGR',
    Bhr = 'BHR',
    Bhs = 'BHS',
    Bih = 'BIH',
    Blr = 'BLR',
    Blz = 'BLZ',
    Bmu = 'BMU',
    Bol = 'BOL',
    Bra = 'BRA',
    Brb = 'BRB',
    Brn = 'BRN',
    Btn = 'BTN',
    Bwa = 'BWA',
    Caf = 'CAF',
    Can = 'CAN',
    Cck = 'CCK',
    Che = 'CHE',
    Chl = 'CHL',
    Chn = 'CHN',
    Civ = 'CIV',
    Cmr = 'CMR',
    Cod = 'COD',
    Cog = 'COG',
    Cok = 'COK',
    Col = 'COL',
    Com = 'COM',
    Cpv = 'CPV',
    Cri = 'CRI',
    Cub = 'CUB',
    Cxr = 'CXR',
    Cym = 'CYM',
    Cyp = 'CYP',
    Cze = 'CZE',
    Deu = 'DEU',
    Dji = 'DJI',
    Dma = 'DMA',
    Dnk = 'DNK',
    Dom = 'DOM',
    Dza = 'DZA',
    Ecu = 'ECU',
    Egy = 'EGY',
    Eri = 'ERI',
    Esh = 'ESH',
    Esp = 'ESP',
    Est = 'EST',
    Eth = 'ETH',
    Fin = 'FIN',
    Fji = 'FJI',
    Flk = 'FLK',
    Fra = 'FRA',
    Fro = 'FRO',
    Fsm = 'FSM',
    Gab = 'GAB',
    Gbr = 'GBR',
    Geo = 'GEO',
    Gha = 'GHA',
    Gib = 'GIB',
    Gin = 'GIN',
    Glp = 'GLP',
    Gmb = 'GMB',
    Gnb = 'GNB',
    Gnq = 'GNQ',
    Grc = 'GRC',
    Grd = 'GRD',
    Grl = 'GRL',
    Gtm = 'GTM',
    Guf = 'GUF',
    Gum = 'GUM',
    Guy = 'GUY',
    Hkg = 'HKG',
    Hmd = 'HMD',
    Hnd = 'HND',
    Hrv = 'HRV',
    Hti = 'HTI',
    Hun = 'HUN',
    Idn = 'IDN',
    Imn = 'IMN',
    Ind = 'IND',
    Irl = 'IRL',
    Irn = 'IRN',
    Irq = 'IRQ',
    Isl = 'ISL',
    Isr = 'ISR',
    Ita = 'ITA',
    Jam = 'JAM',
    Jor = 'JOR',
    Jpn = 'JPN',
    Kaz = 'KAZ',
    Ken = 'KEN',
    Kgz = 'KGZ',
    Khm = 'KHM',
    Kir = 'KIR',
    Kna = 'KNA',
    Kor = 'KOR',
    Kwt = 'KWT',
    Lao = 'LAO',
    Lbn = 'LBN',
    Lbr = 'LBR',
    Lby = 'LBY',
    Lca = 'LCA',
    Lie = 'LIE',
    Lka = 'LKA',
    Lso = 'LSO',
    Ltu = 'LTU',
    Lux = 'LUX',
    Lva = 'LVA',
    Mac = 'MAC',
    Mar = 'MAR',
    Mco = 'MCO',
    Mda = 'MDA',
    Mdg = 'MDG',
    Mdv = 'MDV',
    Mex = 'MEX',
    Mhl = 'MHL',
    Mkd = 'MKD',
    Mli = 'MLI',
    Mlt = 'MLT',
    Mmr = 'MMR',
    Mne = 'MNE',
    Mng = 'MNG',
    Mnp = 'MNP',
    Moz = 'MOZ',
    Mrt = 'MRT',
    Msr = 'MSR',
    Mtq = 'MTQ',
    Mus = 'MUS',
    Mwi = 'MWI',
    Mys = 'MYS',
    Myt = 'MYT',
    Nam = 'NAM',
    Ncl = 'NCL',
    Ner = 'NER',
    Nfk = 'NFK',
    Nga = 'NGA',
    Nic = 'NIC',
    Niu = 'NIU',
    Nld = 'NLD',
    Nor = 'NOR',
    Npl = 'NPL',
    Nru = 'NRU',
    Nzl = 'NZL',
    Omn = 'OMN',
    Pak = 'PAK',
    Pan = 'PAN',
    Pcn = 'PCN',
    Per = 'PER',
    Phl = 'PHL',
    Plw = 'PLW',
    Png = 'PNG',
    Pol = 'POL',
    Pri = 'PRI',
    Prk = 'PRK',
    Prt = 'PRT',
    Pry = 'PRY',
    Pyf = 'PYF',
    Qat = 'QAT',
    Reu = 'REU',
    Rou = 'ROU',
    Rus = 'RUS',
    Rwa = 'RWA',
    Sau = 'SAU',
    Sdn = 'SDN',
    Sen = 'SEN',
    Sgp = 'SGP',
    Shn = 'SHN',
    Sjm = 'SJM',
    Slb = 'SLB',
    Sle = 'SLE',
    Slv = 'SLV',
    Smr = 'SMR',
    Som = 'SOM',
    Spm = 'SPM',
    Srb = 'SRB',
    Stp = 'STP',
    Sur = 'SUR',
    Svk = 'SVK',
    Svn = 'SVN',
    Swe = 'SWE',
    Swz = 'SWZ',
    Syc = 'SYC',
    Syr = 'SYR',
    Tca = 'TCA',
    Tcd = 'TCD',
    Tgo = 'TGO',
    Tha = 'THA',
    Tjk = 'TJK',
    Tkl = 'TKL',
    Tkm = 'TKM',
    Tmp = 'TMP',
    Ton = 'TON',
    Tto = 'TTO',
    Tun = 'TUN',
    Tur = 'TUR',
    Tuv = 'TUV',
    Twn = 'TWN',
    Tza = 'TZA',
    Uga = 'UGA',
    Ukr = 'UKR',
    Ury = 'URY',
    Usa = 'USA',
    Uzb = 'UZB',
    Vat = 'VAT',
    Vct = 'VCT',
    Ven = 'VEN',
    Vgb = 'VGB',
    Vir = 'VIR',
    Vnm = 'VNM',
    Vut = 'VUT',
    Wlf = 'WLF',
    Wsm = 'WSM',
    Yem = 'YEM',
    Zaf = 'ZAF',
    Zmb = 'ZMB',
    Zwe = 'ZWE',
    Xko = 'XKO'
}



