import React, { useEffect, useState } from "react"
import GoogleMapReact, { ClickEventValue } from "google-map-react"
import { css, cx } from "emotion"
import { AssessmentEntryFull } from "../../models/assessment"
import { reportAssetsUrl } from "../../../reports/report-config"
import { mapProxyUrl } from "../../../app_config"
import { translations } from "../../i18n"
import { createRoot } from "react-dom/client"
import { IsochronePopup } from "../isochrone-popup"
import { IsochroneType } from "../isochrone-type"
import { getIsochronePolygon } from "../../../utils/here-maps-isochrone"
import { WmsLayer } from "../../../shared/models/wms-layer"
import { trackUsageEvent } from "../../../utils/usage-tracking"
import { BoxMap } from "./special-maps"
import update from "immutability-helper"
import { useAppSelector } from "../../../relas/store"
import {
  CategoryDataList,
  generateNewPoiClusterData,
  PrivatePoiClusters,
} from "../../../shared/components/private-poi-clusters"
import { PoiMarkerType } from "../../../shared/components/poi-cluster-marker"
import { AllowedModulesEnum, PrivatePOIList } from "../../../private-data/models/private-data"
import { PoiMarkerDialog } from "../../../shared/components/privatedata-poi-marker-dialog"
import { PrivateDataCategoryListPopup } from "../../../shared/components/privatedata-category-list-popup"
import LatLngBounds = google.maps.LatLngBounds
import { TooltipButtonContainer } from "../../../shared/components/tooltip-button-container"
import {
  updateSelectedCategories,
  fetchCategories,
  fetchCategoryPois,
  updatePOIsToShow,
} from "../../../private-data/reducers/private-data-slice"
import { PrivateDataCategoryListPopupNotBooked } from "../../../shared/components/private-data-category-list-popup-not-booked"
import Grid from "../../../shared/components/restyle-grid/grid"
import LoadingSpinner from "../../../shared/components/loadingspinner"
import { getThemeColorVar } from "../../../shared/helper/color"

const styles = {
  container: css({
    height: "100%",
    position: "relative",
    overflow: "hidden",
  }),
  mapContainer: css({
    height: "100%",
    width: "100%",
    position: "relative",
    backgroundColor: "rgb(229, 227, 223)",
  }),
  controlContainer: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }),
  pointMarker: css({
    position: "absolute",
    zIndex: -1,
    transform: "translate(-50%, -100%)",
    img: { width: "40px" },
  }),
  imgShadow: css({
    filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5))",
  }),
  isochroneButton: css({
    height: "40px",
    width: "40px",
    margin: "10px",
    backgroundColor: "white",
    border: "none",
    borderRadius: "2px",
    cursor: "pointer",
  }),
  mapNotifierStyle: css({
    backgroundColor: getThemeColorVar("background", "lighter"),
    width: "100%",
    position: "absolute",
    zIndex: 1000,
    bottom: 0,
    transition: "max-height 1s",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.9,
    overflow: "hidden",
  }),
  mapPopupWrapper: css({
    width: "max-content",
    fontSize: "14px",
    maxWidth: "500px",
    minWidth: "200px",
  }),
  zoomInNotifier: css({
    backgroundColor: "rgba(1,1,1,0.4)",
    color: "white",
    width: "100%",
    position: "absolute",
    zIndex: 1000,
    bottom: 0,
    transition: "max-height 1s",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  }),
}
interface MarkerType {
  key: string
  lat: number
  lng: number
}

interface OwnProps {
  assessmentEntry: AssessmentEntryFull | null
  showControls: boolean
  selectedLayer: WmsLayer | null
  fitToBounds: "none" | "all" | "isochrone"
  isochroneSettings: IsochroneType
  setIsochroneSettings: (settings: IsochroneType) => void
  isochronePolygon: google.maps.Polygon | undefined
  setIsochronePolygon: (polygon: google.maps.Polygon | undefined) => void
  popupCoords: { lat: number; lng: number } | null
  setPopupCoords: (coord: { lat: number; lng: number } | null) => void
  popupZoom: number | undefined
  setPopupZoom: (zoom: number) => void
  boxes: BoxMap
  setBoxes: (b: BoxMap) => void
}

type Props = OwnProps

const centerBerlin = { lat: 52.520008, lng: 13.404954 }
const initialZoom = 13
const EARTH_PERIMETER = Math.PI * 6378137
const module = AllowedModulesEnum.SPECIAL_MAPS

/**
 * This function returns the bounding box in current projection in meters from the origin
 * @param tileXNumber The Tile X number starting from zero
 * @param tileYNumber The Tile Y number starting from zero
 * @param z Zoom level
 */
export const xyzToBounds = (tileXNumber: number, tileYNumber: number, z: number) => {
  const tileSize = (EARTH_PERIMETER * 2) / Math.pow(2, z)
  const minx = tileXNumber * tileSize - EARTH_PERIMETER
  const maxx = (tileXNumber + 1) * tileSize - EARTH_PERIMETER
  const miny = EARTH_PERIMETER - (tileYNumber + 1) * tileSize
  const maxy = EARTH_PERIMETER - tileYNumber * tileSize
  return [minx, miny, maxx, maxy]
}

const getTileUrl = (coordinates: { x: number; y: number }, zoom: number, layerId: string) => {
  return (
    `${mapProxyUrl}/wms?SERVICE=WMS&VERSION=1.3.0` +
    "&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true" +
    `&LAYERS=${layerId}&TILED=true` +
    "&WIDTH=256&HEIGHT=256&CRS=EPSG%3A3857&STYLES=" +
    "&BBOX=" +
    xyzToBounds(coordinates.x, coordinates.y, zoom).join(",")
  )
}
const SpecialMapsMap = (props: Props) => {
  const isPrivateDataAccessible = useAppSelector((state) => state.user.scopes.privateData)
  const privatePoiCategories = useAppSelector((state) => state.privateData.privatePOICategories)
  const privateDataSettings = useAppSelector((state) => state.privateData.modulesWithPrivateData.specialMaps)

  const { selectedCategories, multipleCategoryPOIList, poisToShow: privatePoisToShow } = privateDataSettings
  const [gmap, setGmap] = useState<google.maps.Map>()
  const [bounds, setBounds] = useState<LatLngBounds>()
  const [zoom, setZoom] = useState(initialZoom)
  const [isLoading, setIsLoading] = useState(false)
  const [zoomInNeeded, setZoomInNeeded] = useState(false)
  const [showIsochronePopup, setShowIsochronePopup] = useState(false)
  const [showPrivatePOIPopup, setShowPrivatePOIPopup] = useState(false)
  const [privateCategoryDataList, setPrivateCategoryDataList] = useState<CategoryDataList>([])
  const [locationToShowPrivatePoiPopup, setLocationToShowPrivatePoiPopup] = useState<PoiMarkerType | undefined>(
    undefined
  )
  const [isPopUpHovered, setIsPopUpHovered] = useState<boolean>(false)

  const t = React.useMemo(translations, [translations])

  const mapsMinZoom: Map<string, number> = new Map([
    ["bawue_stuttgartBRW", 12.5],
    ["berlinALKIS", 13.5],
    ["berlinBRW", 10.5],
    ["berlinFNP", 12.5],
    ["berlinLK", 10.5],
    ["brandenburgFNP", 10.5],
    ["hamburgPV", 16.5],
    ["hessenBRW", 12.5],
    ["mvBRW", 11.5],
    ["ndsBRW", 10.5],
    ["nrwBRW", 13.5],
    ["nrw_duesseldorfFNP", 11.5],
    ["nrw_ruhrFNP", 10.5],
    ["nrw_wuppertalBBP", 11.5],
    ["nrw_wuppertalFNP", 10.5],
    ["rlpBRW", 10.5],
    ["rlpPV", 10.5],
    ["thueringenBRW", 11.5],
  ])

  const setPopup = (lat: number, lng: number, boxLeft: number, boxTop: number) => {
    if (props.selectedLayer?.popup) {
      const coord = { lat: lat, lng: lng }
      props.setPopupCoords(coord)
      props.setBoxes(
        update(props.boxes, {
          mapPopup: {
            $merge: { left: boxLeft, top: boxTop },
          },
        })
      )
    }
  }

  const AssessmentEntryMarker = (_: MarkerType) => {
    return (
      <div
        className={styles.pointMarker}
        onClick={(e) => {
          e.stopPropagation()
          if (props.assessmentEntry?.address.location)
            setPopup(
              props.assessmentEntry?.address.location?.lat,
              props.assessmentEntry?.address.location?.lng,
              e.clientX - 50,
              e.clientY - 76
            )
        }}
      >
        <img src={`${reportAssetsUrl ?? ""}/assets/marker.svg`} alt="Assessment entry" className={styles.imgShadow} />
      </div>
    )
  }

  const addSpecialMapLayer = (gmap: google.maps.Map) => {
    if (props.selectedLayer) {
      const layerID = props.selectedLayer.id
      setIsLoading(true)
      const specialMapLayer = new google.maps.ImageMapType({
        name: layerID,
        getTileUrl: (coord, zoom) => getTileUrl(coord, zoom, layerID),
        tileSize: new google.maps.Size(256, 256),
        minZoom: 12,
        maxZoom: 20,
        opacity: 0.6,
      })

      specialMapLayer.addListener("tilesloaded", () => {
        setIsLoading(false)
      })

      gmap.overlayMapTypes.push(specialMapLayer)
    }
  }

  useEffect(() => {
    setPrivateCategoryDataList(generateNewPoiClusterData(multipleCategoryPOIList))
  }, [privateDataSettings])

  useEffect(() => {
    if (gmap) {
      gmap.overlayMapTypes.clear()
      zoomChangeHandler()
      addSpecialMapLayer(gmap)
    }
  }, [props.selectedLayer])

  useEffect(() => {
    if (props.assessmentEntry?.address.location) {
      props.isochronePolygon?.setMap(null)

      if (props.isochroneSettings.mode !== "none" && gmap) {
        getIsochronePolygon(props.assessmentEntry?.address.location, props.isochroneSettings)
          .then((poly) => {
            props.setIsochronePolygon(poly)
          })
          .catch(() => {})
      } else {
        props.setIsochronePolygon(undefined)
      }
    }
  }, [props.isochroneSettings, gmap])

  useEffect(() => {
    if (gmap && props.isochronePolygon) {
      props.isochronePolygon.setMap(gmap)
    }
  }, [props.isochronePolygon, gmap])

  useEffect(() => {
    if (gmap && props.popupCoords) {
      const zoom = gmap?.getZoom() || 1
      props.setPopupZoom(Math.round(zoom))
      if (props.popupCoords && props.assessmentEntry?.address && props.selectedLayer?.name) {
        trackUsageEvent(
          "SPECIAL_MAPS_OPEN_POPUP",
          props.assessmentEntry?.address,
          t.pickTranslation(props.selectedLayer?.name)
        )
      }
    }
  }, [props.popupCoords])

  useEffect(() => {
    if (showPrivatePOIPopup && showIsochronePopup) {
      setShowIsochronePopup(false)
    }
  }, [showPrivatePOIPopup])

  useEffect(() => {
    if (showPrivatePOIPopup && showIsochronePopup) {
      setShowPrivatePOIPopup(false)
    }
  }, [showIsochronePopup])

  const handleOnLoad = (map: google.maps.Map, maps: typeof google.maps) => {
    const controlButtonDiv = document.createElement("div")
    controlButtonDiv.className = styles.controlContainer
    createRoot(controlButtonDiv).render(
      <React.Fragment>
        <TooltipButtonContainer
          tooltip={"Private POIs"}
          buttonName={"private_poi"}
          onClick={() => setShowPrivatePOIPopup(true)}
        />
        <TooltipButtonContainer
          tooltip={t.isochrone}
          buttonName={"marker"}
          onClick={() => setShowIsochronePopup(true)}
        />
      </React.Fragment>
    )
    map.controls[maps.ControlPosition.RIGHT_BOTTOM].push(controlButtonDiv)

    map.setOptions({ isFractionalZoomEnabled: true })
    setGmap(map)
    addSpecialMapLayer(map)
    setBounds(map.getBounds())
    if (isPrivateDataAccessible) void fetchCategories()
  }

  const openPrivatePoiMarkerDialog = (location: PoiMarkerType, poisToDisplay: PrivatePOIList) => {
    setLocationToShowPrivatePoiPopup(location)
    updatePOIsToShow(poisToDisplay, module)
  }

  const closePrivatePoiMarkerDialog = () => {
    setLocationToShowPrivatePoiPopup(undefined)
    updatePOIsToShow([], module)
  }

  const zoomChangeHandler = () => {
    const currZoom = gmap?.getZoom()
    if (currZoom && props.selectedLayer && mapsMinZoom.has(props.selectedLayer.id)) {
      const minZoomForCurrMap = mapsMinZoom.get(props.selectedLayer.id)
      if (minZoomForCurrMap && currZoom < minZoomForCurrMap) setZoomInNeeded(true)
      else setZoomInNeeded(false)
    } else setZoomInNeeded(false)
  }

  if (gmap) {
    google.maps.event.addListener(gmap, "zoom_changed", zoomChangeHandler)
    google.maps.event.addListener(gmap, "bounds_changed", () => {
      if (props.selectedLayer) setIsLoading(true)
      setTimeout(() => setIsLoading(false), 15000)
    })
  }

  return (
    <Grid columns={1} columnSpec={"1fr"} height={[100, "%"]}>
      <div id={"poi-explorer-google-map"} className={styles.mapContainer}>
        <GoogleMapReact
          onGoogleApiLoaded={({ map, maps }) => handleOnLoad(map, maps)}
          center={props.assessmentEntry?.address.location ?? centerBerlin}
          defaultZoom={initialZoom}
          onChange={({ zoom, bounds }) => {
            setZoom(zoom)
            setBounds(gmap?.getBounds())
          }}
          options={{
            mapTypeControl: props.showControls,
            rotateControl: props.showControls,
            streetViewControl: props.showControls,
            zoomControl: props.showControls,
            zoomControlOptions: { position: 3 },
            draggableCursor: props.selectedLayer?.popup ? "pointer" : "default",
            clickableIcons: !props.popupCoords,
            disableDoubleClickZoom: isPopUpHovered,
          }}
          onClick={(e: ClickEventValue) => {
            setPopup(e.lat, e.lng, e.x, e.y)
          }}
        >
          {props.assessmentEntry?.address.location && (
            <AssessmentEntryMarker
              key="entry-marker"
              lat={props.assessmentEntry.address.location.lat}
              lng={props.assessmentEntry.address.location.lng}
            />
          )}
          {privateCategoryDataList.length > 0 &&
            bounds &&
            privateCategoryDataList.flatMap((categoryData, idx) => {
              const props = {
                categoryDataList: privateCategoryDataList,
                categoryData: categoryData,
                zoom: zoom,
                bounds: bounds,
                categories: selectedCategories,
                dataIndex: idx,
                openDialog: openPrivatePoiMarkerDialog,
              }
              return PrivatePoiClusters(props)
            })}

          {locationToShowPrivatePoiPopup && privatePoisToShow?.length > 0 && (
            <PoiMarkerDialog
              lat={locationToShowPrivatePoiPopup.lat}
              lng={locationToShowPrivatePoiPopup.lng}
              poisToShow={privatePoisToShow}
              onClose={closePrivatePoiMarkerDialog}
              onHover={setIsPopUpHovered}
              entryPinLocation={props.assessmentEntry?.address.location}
            />
          )}
        </GoogleMapReact>
        {showPrivatePOIPopup &&
          (isPrivateDataAccessible ? (
            <PrivateDataCategoryListPopup
              onClose={() => setShowPrivatePOIPopup(false)}
              categories={privatePoiCategories}
              module={module}
              selectedCategories={selectedCategories}
              updateCategories={updateSelectedCategories}
              getPois={fetchCategoryPois}
              showCloseButton
            />
          ) : (
            <PrivateDataCategoryListPopupNotBooked onClose={() => setShowPrivatePOIPopup(false)} isContained={false} />
          ))}
        {showIsochronePopup && (
          <IsochronePopup
            assessmentEntry={props.assessmentEntry}
            isochrone={props.isochroneSettings}
            onChange={(field, value) => {
              props.setIsochroneSettings({ ...props.isochroneSettings, [field]: value })
            }}
            onClose={() => setShowIsochronePopup(false)}
            moduleName={"SPECIAL_MAPS"}
          />
        )}
        {
          <div
            className={cx(
              styles.mapNotifierStyle,
              css({ maxHeight: isLoading && !zoomInNeeded && props.selectedLayer ? "76px" : 0 })
            )}
          >
            {isLoading && props.selectedLayer && <LoadingSpinner size={32} />}
            <span style={{ padding: "16px" }}>{t.specialMaps.loadingMap}</span>
          </div>
        }
        {
          <div className={cx(styles.zoomInNotifier, css({ maxHeight: zoomInNeeded ? "180px" : 0 }))}>
            <span style={{ padding: "56px" }}>{t.specialMaps.zoomIn}</span>
          </div>
        }
      </div>
    </Grid>
  )
}

export default SpecialMapsMap
