export const WMS_DEVELOPMENT = "wms-development"
export const QUICK_PROMPT_ENGINEERING = "quick-prompt-engineering"
export const HELP_DEVELOPMENT = "help-development"

export const UI_FEATURES_SET = {
  [WMS_DEVELOPMENT]: "WMS Development",
  [QUICK_PROMPT_ENGINEERING]: "Quick prompt engineering",
  [HELP_DEVELOPMENT]: "help-development",
} as const

export type UI_FEATURE = keyof typeof UI_FEATURES_SET

export function featureEnabled(name: UI_FEATURE): boolean {
  if (typeof Storage !== "undefined") {
    return window.sessionStorage.getItem("feature:" + name) === "true"
  }
  return false
}

export function setFeature(name: UI_FEATURE, enabled: boolean) {
  if (typeof Storage !== "undefined") {
    if (enabled) {
      window.sessionStorage.setItem("feature:" + name, "true")
    } else {
      window.sessionStorage.removeItem("feature:" + name)
    }
  }
}
