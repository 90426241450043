import { AssessmentEntryFull } from "../models/assessment"
import { createAction } from "@reduxjs/toolkit"

export const ASSESSMENT_ENTRY_CHANGED_MODULE = "ASSESSMENT_ENTRY_CHANGED_MODULE"

export type AssessmentEntryChangedPayload = {
  newAssessmentEntry?: AssessmentEntryFull
  newId?: string
  oldId?: string
}

export const assessmentEntryChangedModuleAction = createAction<AssessmentEntryChangedPayload>(
  ASSESSMENT_ENTRY_CHANGED_MODULE
)
