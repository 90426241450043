import * as React from "react"
import { IconName } from "@21re/revoicons/dist/icon-type"
import { css, cx } from "emotion"
import { PropsWithChildren } from "react"
import { translations } from "../i18n"
import Grid from "./restyle-grid/grid"
import GridItem from "./restyle-grid/griditem"
import FlexContainer from "./restyle-grid/flexcontainer"
import Button from "./button"
import { getThemeColorVar } from "../helper/color"

export type RevoHeaderToolbarButton = {
  onClick: () => void
  icon: IconName
  title: string
  disabled?: boolean
}

export type RevoHeaderProps = {
  title: string
  backLabel?: string

  onBackAction?: () => void
  toolbarButtons?: RevoHeaderToolbarButton[]
  actions?: JSX.Element
}

const revoHeaderClass = css({
  backgroundColor: getThemeColorVar("background", "lighter"),
  minHeight: "184px",
  paddingLeft: "40px",
  paddingRight: "40px",
})

const revoHeaderOnlyTextClass = css({
  backgroundColor: getThemeColorVar("background", "lighter"),
  minHeight: "80px",
  paddingLeft: "40px",
  paddingRight: "40px",
})

const toolbarClass = css({
  marginTop: "24px",
  marginBottom: "24px",
})

const toolbarSeparatorClass = css({
  height: "24px",
  width: "1px",
  backgroundColor: getThemeColorVar("typo", "lighter"),
})

const titleClass = css({
  color: getThemeColorVar("typo", "dark"),
  fontSize: "40px",
  lineHeight: "40px",
  fontWeight: 300,
  minHeight: "40px",
})

const contentClass = css({
  marginTop: "40px",
  marginBottom: "40px",
})

const actionsClass = css({
  marginBottom: "40px",
})

export const RevoHeader: React.FunctionComponent<PropsWithChildren<RevoHeaderProps>> = (props) => {
  const { title, backLabel, onBackAction, toolbarButtons, children, actions } = props
  const t = translations()

  if (onBackAction || toolbarButtons || children) {
    return (
      <div className={cx(revoHeaderClass)}>
        <Grid columns={1}>
          <div className={cx(toolbarClass)}>
            <Grid columns={onBackAction && toolbarButtons ? 2 : 1}>
              {onBackAction && (
                <GridItem justifySelf="start">
                  <Button onClick={onBackAction} icon="back" type="tertiary">
                    {backLabel || t.revoHeader.backButton}
                  </Button>
                </GridItem>
              )}

              {toolbarButtons && (
                <GridItem justifySelf="end">
                  <FlexContainer spaceBetween="lg" md-align="center">
                    {toolbarButtons
                      .map((props, index) => (
                        <Button
                          key={index * 2}
                          type="tertiary"
                          icon={props.icon}
                          onClick={props.onClick}
                          title={props.title}
                          disabled={props.disabled}
                        />
                      ))
                      .map((button, index, array) =>
                        index === array.length - 1
                          ? button
                          : [button, <span key={index * 2 + 1} className={cx(toolbarSeparatorClass)}></span>]
                      )}
                  </FlexContainer>
                </GridItem>
              )}
            </Grid>
          </div>

          <Grid
            columnSpec={
              actions
                ? [
                    [2, "fr"],
                    [1, "fr"],
                  ]
                : [[1, "fr"]]
            }
          >
            <div className={cx(titleClass)}>{title}</div>

            {actions && (
              <GridItem rowSpan={2} alignSelf="end" justifySelf="end">
                <div className={cx(actionsClass)}>{actions}</div>
              </GridItem>
            )}

            <div className={cx(contentClass)}>{children}</div>
          </Grid>
        </Grid>
      </div>
    )
  } else {
    return (
      <div className={cx(revoHeaderOnlyTextClass)}>
        <Grid
          columnSpec={
            actions
              ? [
                  [2, "fr"],
                  [1, "fr"],
                ]
              : [[1, "fr"]]
          }
          height={[80, "px"]}
        >
          <GridItem alignSelf="center">
            <div className={cx(titleClass)}>{title}</div>
          </GridItem>
          {actions && (
            <GridItem alignSelf="center" justifySelf="end">
              {actions}
            </GridItem>
          )}
        </Grid>
      </div>
    )
  }
}

export default RevoHeader
