import * as React from "react"
import { useSelector } from "react-redux"
import { translations } from "../i18n"
import moment from "moment"
import { menuTranslations } from "../../menu/i18n"
import { CompanyAdminInvoiceState } from "../reducers/invoice-slice"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import RevoHeader from "../../shared/components/revo-header"
import Card from "../../shared/components/card"
import LoadingDimmer from "../../shared/components/loadingdimmer"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"
import Grid from "../../shared/components/restyle-grid/grid"
import GridItem from "../../shared/components/restyle-grid/griditem"

export const CompanyProductUsage = () => {
  const t = React.useMemo(() => translations().productUsage, [])
  const menuTranslate = React.useMemo(menuTranslations, [menuTranslations])
  const billables = useSelector((state: CompanyAdminInvoiceState) => state.invoiceState.allBillables)
  const billablesLoading = useSelector((state: CompanyAdminInvoiceState) => state.invoiceState.allBillablesLoading)
  const billablesError = useSelector((state: CompanyAdminInvoiceState) => state.invoiceState.allBillablesFetchError)
  const companyBudget = useSelector((state: CompanyAdminInvoiceState) => state.invoiceState.companyBudget)
  const max = billables ? billables.reduce((acc, b) => (acc > b.totalBillable ? acc : b.totalBillable), 0) : 0

  return (
    <div className="with-body-scroll">
      <FlexContainer gap={8} direction={"column"}>
        <RevoHeader title={menuTranslate.apps.admin.submenus.products} />

        <Grid columns={1} paddingY="xxl" paddingX="xxl">
          <GridItem padding={[0, 0, 16, 0]}>
            {companyBudget && (
              <Card header={t.quota.title}>
                <Grid columns={2} gap="md" columnSpec={"max-content 1fr"}>
                  <label>{t.quota.startDate}</label>
                  <div>{moment(companyBudget.startDate).format("DD.MM.YYYY")}</div>
                  <label>{t.quota.amount}</label>
                  <div>{companyBudget.amount}</div>
                  <label>{t.quota.usedForPeriod}</label>
                  <div>{companyBudget.totalUsed}</div>
                  <label>{t.quota.availableNow}</label>
                  <div>{companyBudget.available}</div>
                </Grid>
              </Card>
            )}
          </GridItem>
          <GridItem>
            <Card header={t.billableAssessments}>
              <LoadingDimmer loading={billablesLoading}>
                {billablesError && <GenericErrorPanel error={billablesError} />}
                {!billablesError &&
                  billables &&
                  billables.map((billable, idx, allBillables) => {
                    const percentage = max === 0 ? 0 : Math.round((billable.totalBillable / max) * 100)

                    return (
                      <div
                        key={idx}
                        style={idx === allBillables.length - 1 ? {} : { borderBottom: "1px solid lightgrey" }}
                      >
                        <Grid padding={4} columnSpec="4fr 1fr 15fr 3fr">
                          <GridItem>
                            {billable.year} {t.translateMonth(billable.month)}
                          </GridItem>
                          <GridItem>{billable.totalBillable}</GridItem>
                          <GridItem>
                            <Grid columnSpec={`${percentage}fr ${100 - percentage}fr`}>
                              <GridItem
                                backgroundColor={
                                  percentage === 0 ? undefined : { color: "primary", colorType: "light" }
                                }
                              >
                                &nbsp;
                              </GridItem>
                              <GridItem></GridItem>
                            </Grid>
                          </GridItem>
                          <GridItem></GridItem>
                        </Grid>
                      </div>
                    )
                  })}
              </LoadingDimmer>
            </Card>
          </GridItem>
        </Grid>
      </FlexContainer>
    </div>
  )
}
