import * as React from "react"
import { IItemRendererProps, MultiSelect } from "@blueprintjs/select"
import { FieldOption } from "../../models/field-option"
import { Button, MenuItem } from "@blueprintjs/core"

const OptionMultiSelect = MultiSelect.ofType<FieldOption>()

export interface Props {
  options: FieldOption[]
  selected: string[]
  onSelectionChanged: (selected: string[]) => void
}

export const FieldOptionMultiSelect = ({ selected, options, onSelectionChanged }: Props) => {
  const onItemSelect = (item: FieldOption) => {
    const idx = selected.indexOf(item.key)

    if (idx < 0) {
      onSelectionChanged([...selected, item.key])
    }
  }

  const onItemRemove = (item: any) => {
    const index = selected.indexOf(item.key)

    if (index >= 0) onSelectionChanged([...selected.slice(0, index), ...selected.slice(index + 1)])
  }

  const renderTag = (item: FieldOption) => {
    return (
      <span style={{ paddingLeft: "2px" }} key={item.key}>
        {item.label}
      </span>
    )
  }

  const renderItem = (item: FieldOption, itemProps: IItemRendererProps) => {
    if (!itemProps.modifiers.matchesPredicate) return null
    const isSelected = selected.indexOf(item.key) >= 0
    return (
      <MenuItem
        text={item.label}
        icon={isSelected ? "tick" : "blank"}
        key={item.key}
        active={itemProps.modifiers.active}
        onClick={itemProps.handleClick}
      />
    )
  }

  const onClear = () => {
    onSelectionChanged([])
  }

  const itemListPredicate = (query: string, items: FieldOption[]) => {
    if (query.length < 2) return []
    const filtered = items.filter((item) => item.label.toLocaleLowerCase().startsWith(query.toLocaleLowerCase()))

    return filtered.slice(0, 100)
  }

  const clearButton = selected.length > 0 ? <Button icon="cross" minimal={true} onClick={onClear} /> : undefined

  return (
    <OptionMultiSelect
      items={options}
      selectedItems={options.filter((o) => selected.indexOf(o.key) >= 0)}
      itemsEqual={(a, b) => a.key === b.key}
      onItemSelect={onItemSelect}
      itemRenderer={renderItem}
      itemListPredicate={itemListPredicate}
      tagRenderer={renderTag}
      tagInputProps={{ tagProps: { minimal: true }, onRemove: onItemRemove, rightElement: clearButton }}
      popoverProps={{ minimal: true, openOnTargetFocus: true }}
      resetOnSelect
      fill
    />
  )
}
