import * as React from "react"
import { Translations } from "./translations"

export const EN: Translations = {
  stepOne: {
    header: "Registration",
    areaCodes: {
      germany: "Germany: +49",
      austria: "Austria: +43",
      switzerland: "Switzerland: +41",
    },
    nameLabel: "Given name",
    surnameLabel: "Surname",
    areaCodeLabel: "Area code",
    phoneLabel: "Mobile phone number",
    emailLabel: "Email address",
    registerButtonText: "Register",
    wrongPhoneHeader: "Error",
    wrongPhone: (given: string) => `The given phone number '${given}' is not valid according to our operator.`,
    invalidPhone: "The phone number may only contain numbers.",
    emailTakenHeader: "Email taken",
    emailTaken: (given: string) => `The given email '${given}' is already taken.`,
    invalidEmail: "invalid email address",
    houseNumberTooLong: "House number too long",
  },
  stepTwo: {
    header: "Email verification",
    text: "Thank you for your registration! You will receive an email with a verification link that is valid for 48 hours. Please click on this link in the email to complete the registration process.",
  },
  verifyEmail: {
    header: "Email verification",
    text: "Please wait while we verify your email address.",
    codeExpiredTitle: "Code expired",
    codeExpiredBody: "Your email link has expired. It is only valid for 48 hours. You can request a new link below.",
    contactSupport: "please contact support@21re.de",
    resendCodesLabel: "Send me a new email link",
    userAlreadyVerifiedTitle: "You are already verified",
    userAlreadyVerifiedBody: "Your verification was already successful. Please click the button below to login.",
    loginServiceLinkLabel: "Login",
  },
  stepThree: {
    header: "Registration",
    password1Label: "Password",
    password2Label: "Repeat password",
    businessCompanyNameLabel: "Company name",
    streetLabel: "Street",
    houseNumberLabel: "House no.",
    additionalAddressInformationLabel: "Additional address information",
    countryLabel: "Country",
    zipCodeLabel: "Postal code",
    cityLabel: "City",
    agbLabel: (
      <>
        I have read and accepted the{" "}
        <a href="https://21re.de/agb?lang=en" target="_blank">
          general terms and conditions
        </a>{" "}
        and the{" "}
        <a href="https://21re.de/datenschutz?lang=en" target="_blank">
          privacy policy
        </a>
        .
      </>
    ),
    countries: {
      germany: "Germany",
      austria: "Austria",
      switzerland: "Switzerland",
    },
    registerButtonText: "Register",
    passwordTooShort: "Password too short (min. 9 characters)!",
    passwordTooLong: "Password too long (max. 60 characters)!",
    passwordsAreNotEqual: "Passwords must match",
  },
  complete: {
    header: "Registration complete",
    text: `Thank you for your registration. We wish you success with the 21st-software.`,
  },
  links: {
    iAlreadyHaveAnAccount: "I already have an account",
    imprint: "Legal data",
    terms: "Terms and conditions",
    privacy: "Privacy protection",
    imprintLink: "https://21re.de/impressum?lang=en",
    privacyLink: "https://21re.de/datenschutz?lang=en",
    termsLink: "https://21re.de/agb?lang=en",
  },
}
