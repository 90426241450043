import * as React from "react"
import { AddressSuggestion } from "../models/poi-explorer"
import { SuggestionAutoComplete } from "./suggestion-auto-complete"
import TextField from "./textfield"

export class AddressSuggestionAutoComplete extends SuggestionAutoComplete<AddressSuggestion> {
  render() {
    const { hint, hasWarning, ...textFieldProps } = this.props

    return this.buildRender(
      <TextField
        autoFocus={true}
        onKeyDown={this.onKeyDown}
        hint={hint}
        label={this.props.label}
        ref={(ref) => (this.ref = ref && ref.inputElRef)}
        {...textFieldProps}
        hasWarning={hasWarning}
        icon={"search_property_v1"}
      />
    )
  }
}
