import * as React from "react"
import { useEffect, useState } from "react"
import { translations } from "../i18n"
import { fetchTwoFactorEnabled, TwoFactorState, UserAccountState } from "../user-profile-slice"
import { useSelector } from "react-redux"
import { TwoFactorDefaults, TwoFactorSetupPayload } from "../models/two-factor"
import { toCanvas } from "qrcode"
import Axios from "axios"
import { dashboardServiceUrl } from "../../app_config"
import { assertUnreachable } from "../../utils/utils"
import { nukeTwoFactor, setupTwoFactor } from "../user-profile-slice"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import Panel from "../../shared/components/panel"
import Text from "../../shared/components/text"
import LoadingSpinner from "../../shared/components/loadingspinner"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"
import Grid from "../../shared/components/restyle-grid/grid"
import GridItem from "../../shared/components/restyle-grid/griditem"
import Card from "../../shared/components/card"
import TextField from "../../shared/components/textfield"
import Button from "../../shared/components/button"
import Form from "../../shared/components/form"
import { ValidationResult } from "../../shared/models/genericinputelement"

enum QRState {
  rendered,
  error,
  initial,
}

enum SubmitState {
  in_progress,
  error,
  nothing,
  performed,
}

export const UserAccountTwoFactorSetup = () => {
  const nukeTwoFactorInProgress = useSelector((state: UserAccountState) => state.userProfile.nukeTwoFactorInProgress)

  const nukeTwoFactorError = useSelector((state: UserAccountState) => state.userProfile.nukeTwoFactorError)
  const twoFactorEnabled = useSelector((state: UserAccountState) => state.userProfile.twoFactorEnabled)
  const fetchStateError = useSelector((state: UserAccountState) => state.userProfile.fetchTwoFactorEnabledError)

  const [generatedCode, setGeneratedCode] = useState("")
  const [submitState, setSubmitState] = useState(SubmitState.nothing)
  const [twoFactorDefaults, setTwoFactorDefaults] = useState<TwoFactorDefaults>()
  const [qrState, setQrState] = useState(QRState.initial)
  const [isValid, setIsValid] = useState(false)

  const translate = translations()

  const getDefaults = () => {
    Axios.get<TwoFactorDefaults>(dashboardServiceUrl + "/api/v2/profile/twoFactorDefaults").then(
      (response) => {
        setTwoFactorDefaults(response.data)
      },
      () => {}
    )
  }

  useEffect(getDefaults, [])
  useEffect(getDefaults, [twoFactorEnabled])

  useEffect(() => {
    if (twoFactorEnabled === TwoFactorState.Disabled && twoFactorDefaults) {
      populateQrCode()
    }
  }, [twoFactorEnabled, twoFactorDefaults])

  const populateQrCode = () => {
    if (twoFactorDefaults && twoFactorDefaults.sharedSecretCandidateChartURL) {
      toCanvas(document.getElementById("qr-canvas"), twoFactorDefaults.sharedSecretCandidateChartURL, (error: any) => {
        if (error) {
          console.log(`QR render error: ${error}`)
          setQrState(QRState.error)
        } else {
          setQrState(QRState.rendered)
        }
      })
    }
  }

  const onChangeCode = (value: string, valid: boolean) => {
    setGeneratedCode(value)
    setIsValid(valid)
  }

  const codeValidation = (value: string) => {
    if (!isNaN(parseInt(value)) && !isNaN(value as any) && value.toString().length === 6) {
      return { valid: true, validationMessage: "" }
    }

    return {
      valid: false,
      validationMessage: translate.twoFactorSetup.otpLengthMismatch,
    } as ValidationResult
  }

  const doSetup2fa = () => {
    if (twoFactorDefaults && twoFactorDefaults.sharedSecretCandidate) {
      setSubmitState(SubmitState.in_progress)

      const payload: TwoFactorSetupPayload = {
        sharedSecret: twoFactorDefaults.sharedSecretCandidate,
        generatedCode: parseInt(generatedCode),
      }

      setupTwoFactor(payload).then(
        (success: boolean) => {
          if (success) {
            setSubmitState(SubmitState.performed)
            // Update the state to reflect the new state
            return fetchTwoFactorEnabled()
          }

          return setSubmitState(SubmitState.error)
        },
        () => setSubmitState(SubmitState.error)
      )
    }
  }

  const renderSubmitState = () => {
    switch (submitState) {
      case SubmitState.nothing:
        return null
      case SubmitState.in_progress:
        return <LoadingSpinner />
      case SubmitState.error:
        return <Panel color="negative">{translate.twoFactorSetup.submittedErrorText}</Panel>
      case SubmitState.performed:
        return (
          <FlexContainer direction="column" spaceBetween="xl">
            <Panel color="positive">{translate.twoFactorSetup.submittedOkText}</Panel>
          </FlexContainer>
        )
    }
  }

  const renderQRCode = () => {
    return (
      <div>
        <canvas id="qr-canvas"></canvas>
        {qrState === QRState.error ? <Panel color="negative">{translate.twoFactorSetup.qrRenderError}</Panel> : null}
      </div>
    )
  }

  const onNukeTwoFactor = () => {
    nukeTwoFactor().then(
      () => {
        return fetchTwoFactorEnabled()
      },
      () => {
        setSubmitState(SubmitState.error)
      }
    )
  }

  if (fetchStateError) {
    return <GenericErrorPanel error={fetchStateError} />
  }

  if (!twoFactorDefaults) {
    return <LoadingSpinner />
  }

  switch (twoFactorEnabled) {
    case TwoFactorState.Enabled:
      return (
        <Grid columns={1} rowGap="xxl">
          <Text size="xl" fontWeight="light" color="typo" colorType="dark">
            {translate.twoFactorSetup.nukeTwoFactorHeader}
          </Text>

          {nukeTwoFactorError && <Panel color="negative">{translate.twoFactorSetup.nukeTwoFactorError}</Panel>}
          {nukeTwoFactorInProgress && <LoadingSpinner />}

          <GridItem>
            <Button type="primary" danger disabled={nukeTwoFactorInProgress} onClick={onNukeTwoFactor}>
              {translate.twoFactorSetup.nukeTwoFactor}
            </Button>
          </GridItem>
        </Grid>
      )
    case TwoFactorState.Disabled:
      return (
        <Grid columns={1} rowGap="xl">
          <Card header={translate.twoFactorSetup.infographicHeader}>
            <img
              style={{ marginLeft: "auto", marginRight: "auto", display: "block" }}
              src={translate.twoFactorSetup.infographic}
              alt="2FA Info"
            />
          </Card>

          <Grid columns={3} gap="xl">
            <GridItem colSpan={{ mobilePortrait: 3, mobileLandscape: 3, desktopSmall: 1 }}>
              <Card header={translate.twoFactorSetup.setupHeader}>
                <Grid gap="xl" columnSpec="fit-content(60%) 1fr">
                  {submitState !== SubmitState.performed && (
                    <>
                      <GridItem>
                        {renderQRCode()}
                        <p>{translate.twoFactorSetup.secretLabel}:</p>
                        <pre>{twoFactorDefaults?.sharedSecretCandidate}</pre>
                      </GridItem>
                      <GridItem>
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            doSetup2fa()
                          }}
                        >
                          <FlexContainer spaceBetween="xl" direction="column">
                            <TextField
                              value={generatedCode}
                              label={translate.twoFactorSetup.generatedCodeLabel}
                              onValueChange={onChangeCode}
                              customValidation={[codeValidation]}
                              required={true}
                            />
                            <div>
                              <Button disabled={!isValid || submitState === SubmitState.in_progress} type="primary">
                                {translate.twoFactorSetup.submitLabel}
                              </Button>
                            </div>
                          </FlexContainer>
                        </Form>
                      </GridItem>
                    </>
                  )}
                  <GridItem colSpan={2}>{renderSubmitState()}</GridItem>
                </Grid>
              </Card>
            </GridItem>

            <GridItem colSpan={{ mobilePortrait: 3, mobileLandscape: 3, desktopSmall: 2 }}>
              <Card header={translate.twoFactorSetup.explanationHeader}>
                <Grid columns={1} rowGap="xl">
                  <p>{translate.twoFactorSetup.teaser}</p>
                  <p>{translate.twoFactorSetup.explanation}</p>
                  <ul>
                    <li>
                      Google Authenticator (
                      <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en">
                        Android
                      </a>
                      , <a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8">iOS</a>)
                    </li>
                  </ul>
                </Grid>
              </Card>
            </GridItem>
          </Grid>
        </Grid>
      )
    case TwoFactorState.Loading:
      return <LoadingSpinner />
    default:
      return assertUnreachable(twoFactorEnabled)
  }
}
