import * as React from "react"
import { Card } from "@blueprintjs/core"
import { BorderBottom } from "./ui/border-bottom"
import { Flex } from "./ui/flex"
import { FlexItem } from "./ui/flex-item"
import { ScrollBox } from "./ui/scroll-box"
import { css } from "emotion"
import { translations } from "../i18n"
import { PrivatePOI, PrivatePOIList, StructuredData } from "../../private-data/models/private-data"
import { PoiMarkerType } from "./poi-cluster-marker"
import { useEffect, useMemo } from "react"
import { Location } from "../../assessment/models/address"
import { haversine_distance } from "../../utils/utils"
import { formatAddressWithoutCountry } from "../../assessment/models/address"
import { getThemeColorVar } from "../helper/color"
import { formatNumber } from "../helper/number-format"
import Icon from "./icon"

const styles = {
  card: css({
    width: "400px",
    height: "max-content",
    padding: "0px",
    position: "absolute",
  }),
  notesContainer: css({}),
  pageButton: (isDisabled: boolean) =>
    css(
      isDisabled
        ? {
            color: getThemeColorVar("background", "lighter"),
            pointerEvents: "none",

            "&:hover": {
              color: getThemeColorVar("background", "lighter"),
              pointerEvents: "none",
            },
          }
        : {}
    ),
}

type scrollableSections = "notes" | "structuredData"

interface Props extends PoiMarkerType {
  poisToShow: PrivatePOIList
  onClose: () => void
  onHover?: (value: boolean) => void
  entryPinLocation: Location | undefined
}

export const PoiMarkerDialog = ({ poisToShow, onClose, onHover, entryPinLocation }: Props) => {
  const t = React.useMemo(translations, [translations])
  const [currentDisplayedPoi, setCurrentDisplayedPoi] = React.useState<PrivatePOI>(poisToShow[0])
  const [isNotesMaxHeight, setIsNotesMaxHeight] = React.useState(false)
  const [isStructuredDataMaxHeight, setIsStructuredDataMaxHeight] = React.useState(false)

  useEffect(
    () => () => {
      onClose()
      if (onHover) {
        onHover(false)
      }
    },
    []
  )

  useEffect(() => {
    setCurrentDisplayedPoi(poisToShow[0])
    setIsNotesMaxHeight(false)
    setIsStructuredDataMaxHeight(false)
  }, [poisToShow])

  useEffect(() => {
    setIsNotesMaxHeight(isScrollableSectionMaxHeight("notes"))
    setIsStructuredDataMaxHeight(isScrollableSectionMaxHeight("structuredData"))
  }, [currentDisplayedPoi])

  const distanceToPin = useMemo(() => {
    if (!entryPinLocation || !currentDisplayedPoi.address.location) return undefined

    const distance = haversine_distance(
      [entryPinLocation.lng, entryPinLocation.lat],
      [currentDisplayedPoi.address.location.lng, currentDisplayedPoi.address.location.lat]
    )
    return formatNumber(distance, 0)
  }, [entryPinLocation, currentDisplayedPoi])
  const currentPoiIndex = () => {
    return poisToShow.indexOf(currentDisplayedPoi)
  }

  const MouseOver = () => (onHover ? onHover(true) : null)
  const MouseOut = () => (onHover ? onHover(false) : null)

  const onPageButtonClicked = (e: React.MouseEvent, pageToShow: "previous" | "next") => {
    if (pageToShow === "previous") {
      if (currentPoiIndex() > 0) {
        setCurrentDisplayedPoi(poisToShow[currentPoiIndex() - 1])
      }
    }
    if (pageToShow === "next") {
      if (currentPoiIndex() < poisToShow.length - 1) {
        setCurrentDisplayedPoi(poisToShow[currentPoiIndex() + 1])
      }
    }
    e.stopPropagation()
  }

  const getMinHeightSectionValue = (section: scrollableSections) => (section === "structuredData" ? 135 : 80)

  const isScrollableSectionMaxHeight = (section: scrollableSections) => {
    const sectionElement = document.getElementById(`${section}-section:${currentDisplayedPoi.id}`)
    const height = sectionElement?.clientHeight || 0
    return height >= getMinHeightSectionValue(section)
  }

  const getFormattedStructuredDataSection = (data: StructuredData): JSX.Element => (
    <Flex flexDirection={"column"} gap={8}>
      {data.map(([field, value], idx) => (
        <div key={idx} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div>
            <p>{field}</p>
          </div>
          <div style={{ display: "inline-block", maxWidth: "65%", hyphens: "auto" }}>
            <p style={{ fontWeight: "bold" }}>{value}</p>
          </div>
        </div>
      ))}
    </Flex>
  )

  const structuredDataExistAndHasValues = (data?: StructuredData): boolean => !!data && data.length > 0

  return (
    <Card elevation={2} className={styles.card}>
      <div
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
        onMouseOver={MouseOver}
        onMouseOut={MouseOut}
      >
        <Flex flexDirection={"column"} gap={12}>
          <BorderBottom>
            <Flex flexDirection={"column"} gap={4} padding={12}>
              <Flex flexDirection={"row"} padding={[4, 4, 0, 0]}>
                <h3 style={{ flexGrow: 1 }}>
                  {currentDisplayedPoi.title ? currentDisplayedPoi.title : `(${t.noTitle})`}
                </h3>
                <div onClick={onClose} style={{ cursor: "pointer" }}>
                  <Icon name={"close"} color={"primary"} colorType={"default"} fontSize={16} />
                </div>
              </Flex>
              <FlexItem>
                <p>{formatAddressWithoutCountry(currentDisplayedPoi.address)}</p>
              </FlexItem>
              {currentDisplayedPoi.companyAssignedId && (
                <FlexItem>
                  <p>{`ID: ${currentDisplayedPoi.companyAssignedId}`}</p>
                </FlexItem>
              )}
              {distanceToPin && (
                <FlexItem>
                  <p>
                    {t.map.poiMapPopup.distance}: {distanceToPin} m
                  </p>
                </FlexItem>
              )}
            </Flex>
          </BorderBottom>
          {currentDisplayedPoi.notes && (
            <BorderBottom padding={[0, 0, 12, 12]}>
              <div id={`notes-section:${currentDisplayedPoi.id}`}>
                {isNotesMaxHeight ? (
                  <ScrollBox minHeight={`${getMinHeightSectionValue("notes")}px`}>
                    <p>{currentDisplayedPoi.notes}</p>
                  </ScrollBox>
                ) : (
                  <p>{currentDisplayedPoi.notes}</p>
                )}
              </div>
            </BorderBottom>
          )}
          {structuredDataExistAndHasValues(currentDisplayedPoi.structuredData) && (
            <BorderBottom padding={[0, 12, 12, 12]}>
              <div id={`structuredData-section:${currentDisplayedPoi.id}`}>
                {isStructuredDataMaxHeight ? (
                  <ScrollBox minHeight={`${getMinHeightSectionValue("structuredData")}px`}>
                    <FlexItem padding={[0, 12, 0, 0]}>
                      {getFormattedStructuredDataSection(currentDisplayedPoi.structuredData as StructuredData)}
                    </FlexItem>
                  </ScrollBox>
                ) : (
                  getFormattedStructuredDataSection(currentDisplayedPoi.structuredData as StructuredData)
                )}
              </div>
            </BorderBottom>
          )}
          {poisToShow.length > 1 && (
            <FlexItem alignSelf={"center"} padding={[0, 0, 12, 0]}>
              <Flex flexDirection={"row"} gap={8} alignItems={"center"}>
                <a
                  className={styles.pageButton(currentPoiIndex() === 0)}
                  onClick={(e) => onPageButtonClicked(e, "previous")}
                >
                  <Icon name={"chevron_left_small"} />
                </a>
                <span>
                  <b>{currentPoiIndex() + 1}</b>
                  {`/${poisToShow.length}`}
                </span>
                <a
                  className={styles.pageButton(currentPoiIndex() === poisToShow.length - 1)}
                  onClick={(e) => onPageButtonClicked(e, "next")}
                >
                  <Icon name={"chevron_right_small"} />
                </a>
              </Flex>
            </FlexItem>
          )}
        </Flex>
      </div>
    </Card>
  )
}
