import * as React from "react"
import { css } from "emotion"
import VectorLayer from "ol/layer/Vector"
import * as style from "ol/style"
import * as ol from "ol"
import * as geom from "ol/geom"
import { fromLonLat, toLonLat } from "ol/proj"
import VectorSource from "ol/source/Vector"
import { Location } from "../models/address"
import { bind } from "decko"
import MapBrowserEvent from "ol/MapBrowserEvent"
import {
  AllowedModulesEnum,
  PrivateDataModuleSettings,
  PrivatePOICategoriesList,
  PrivatePOIList,
} from "../../private-data/models/private-data"
import { PrivatePoiControl } from "../../shared/components/map-private-pois-control"
import * as layer from "ol/layer"
import { JSXContent, PrivatePoiOverlay } from "../../shared/components/PrivatePoiOverlay"
import { areMultiplePrivatePoisListsDifferent, arePoisToShowDifferent, updateClusters } from "../../utils/utils"
import { PoiMarkerDialog } from "../../shared/components/privatedata-poi-marker-dialog"
import Layer from "ol/layer/Layer"
import Source from "ol/source/Source"
import { OpenLayersAbstractProps, OpenLayersMap } from "../../shared/components/openlayers-map"

const mapClass = css({
  minHeight: 0,
  height: "100%",
})

interface Props extends OpenLayersAbstractProps {
  address?: Location
  forceCenter?: number[]
  forceZoom?: number
  onSetPin?: (location: Location) => void

  isPrivateDataAccessible?: boolean
  privatePoiCategories?: PrivatePOICategoriesList
  privateDataSettings?: PrivateDataModuleSettings
  getPrivateDataCategories?: () => void
  updateSelectedPrivateDataCategories?: (categories: PrivatePOICategoriesList, module: AllowedModulesEnum) => void
  getPrivatePoisFromCategories?: (categoryId: string, module?: AllowedModulesEnum) => void
  updatePrivatePoisToShow?: (poiList: PrivatePOIList, module?: AllowedModulesEnum) => void
}

interface State {
  showPrivatePoisPopover: boolean
  poiPopUpPosition?: number[]
}

export class AddressGeoView extends OpenLayersMap<Props, State> {
  private selectedAddressLayer: VectorLayer<any>
  private privatePoiControl: PrivatePoiControl | undefined = undefined
  private clusterLayerArray: layer.Vector<any>[] = []
  private poiPopUp: PrivatePoiOverlay | undefined = undefined

  constructor(props: Props) {
    super(props)

    this.state = {
      showPrivatePoisPopover: false,
      poiPopUpPosition: undefined,
    }

    this.selectedAddressLayer = new VectorLayer({
      style: this.iconStyle,
      source: new VectorSource(),
    })
  }

  @bind
  onChangeShowPrivatePoisPopover(value: boolean) {
    this.setState({
      ...this.state,
      showPrivatePoisPopover: value,
    })
  }

  protected containerClass(): string {
    return mapClass
  }

  protected onMoveEnd() {}

  componentDidMount() {
    super.componentDidMount()
    if (this.props.isPrivateDataAccessible && this.props.getPrivateDataCategories) this.props.getPrivateDataCategories()
    this.props.address && this.setFeatures(this.props.address)
    this.getMap().addLayer(this.selectedAddressLayer)
    this.addOrRefreshPrivatePoi()
    if (this.props.isPrivateDataAccessible && this.props.privatePoiCategories && this.props.privateDataSettings) {
      this.poiPopUp = new PrivatePoiOverlay(this.getMap(), this.poiPopUpContentCreator)
      updateClusters(
        this.getMap(),
        this.clusterLayerArray,
        this.setClusterLayerArray,
        this.props.privateDataSettings.multipleCategoryPOIList,
        this.props.privatePoiCategories,
        this.props.isPrivateDataAccessible
      )
    }
  }

  private addOrRefreshPrivatePoi() {
    if (
      this.props.privatePoiCategories &&
      this.props.privateDataSettings &&
      this.props.getPrivateDataCategories &&
      this.props.getPrivatePoisFromCategories &&
      this.props.updateSelectedPrivateDataCategories &&
      this.props.updatePrivatePoisToShow
    ) {
      if (this.props.isPrivateDataAccessible) {
        if (!this.privatePoiControl) {
          this.privatePoiControl = new PrivatePoiControl(
            AllowedModulesEnum.QUICK_START,
            this.props.privatePoiCategories,
            this.props.privateDataSettings.selectedCategories,
            this.props.updateSelectedPrivateDataCategories,
            this.props.getPrivatePoisFromCategories,
            this.state.showPrivatePoisPopover,
            this.onChangeShowPrivatePoisPopover,
            this.props.isPrivateDataAccessible
          )
          this.getMap().addControl(this.privatePoiControl)
        } else {
          this.privatePoiControl?.refreshPopover(
            this.props.privatePoiCategories,
            this.props.privateDataSettings.selectedCategories,
            this.state.showPrivatePoisPopover
          )
        }
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (JSON.stringify(prevProps.address) !== JSON.stringify(this.props.address)) {
      this.props.address && this.setFeatures(this.props.address)
    }

    if (
      JSON.stringify(prevProps.forceCenter) !== JSON.stringify(this.props.forceCenter) &&
      this.props.forceCenter !== undefined
    ) {
      this.getMap()
        .getView()
        .animate({ duration: 500, center: this.props.forceCenter, zoom: this.props.forceZoom || 18 })
    }

    this.addOrRefreshPrivatePoi()

    if (
      this.props.isPrivateDataAccessible &&
      this.props.privatePoiCategories &&
      this.props.privateDataSettings &&
      prevProps.privateDataSettings
    ) {
      if (
        areMultiplePrivatePoisListsDifferent(
          prevProps.privateDataSettings.multipleCategoryPOIList,
          this.props.privateDataSettings.multipleCategoryPOIList
        )
      ) {
        updateClusters(
          this.getMap(),
          this.clusterLayerArray,
          this.setClusterLayerArray,
          this.props.privateDataSettings.multipleCategoryPOIList,
          this.props.privatePoiCategories,
          this.props.isPrivateDataAccessible
        )
      }
      if (arePoisToShowDifferent(prevProps.privateDataSettings.poisToShow, this.props.privateDataSettings.poisToShow)) {
        this.updatePoiPopUp()
      }
    }
  }

  @bind private setClusterLayerArray(newClusterArrayLayer: layer.Vector<any>[]) {
    this.clusterLayerArray = [...newClusterArrayLayer]
  }

  @bind
  private isClickableLayers(layer: Layer<Source>): boolean {
    return layer.getClassName().includes("cluster-layer")
  }

  @bind
  private onMapClick(event: ol.MapBrowserEvent<any>) {
    const features = this.getMap().getFeaturesAtPixel(event.pixel, {
      layerFilter: (layer) => this.isClickableLayers(layer),
    })
    if (
      features.length > 0 &&
      features[0].getProperties().features?.[0]?.id_.includes("category:") &&
      this.props.updatePrivatePoisToShow
    ) {
      const featuresArray: ol.Feature[] = features[0].getProperties().features
      const geometry = features[0].getProperties().geometry

      const categoryId = featuresArray[0].getId()?.toString().split(":")[1] ?? ""
      const poiIdsToShow: string[] = featuresArray.map((feature) => (feature.getId()?.toString() ?? "").split(":")[2])
      const poisToShow: PrivatePOIList = (
        this.props.privateDataSettings?.multipleCategoryPOIList[categoryId] ?? []
      ).filter((poi) => poiIdsToShow.includes(poi.id))
      const position = geometry.getCoordinates()
      this.setState({ ...this.state, poiPopUpPosition: position })
      this.props.updatePrivatePoisToShow(poisToShow, AllowedModulesEnum.QUICK_START)
    } else {
      if (this.state.poiPopUpPosition) this.onPoiPopUpClose()
      if (this.props.onSetPin) this.onSingleClick(event)
    }
  }

  @bind
  protected onSingleClick(event: MapBrowserEvent<any>) {
    const [lng, lat] = toLonLat(event.coordinate)
    if (this.props.onSetPin) {
      this.props.onSetPin({ lng, lat })
    }
  }

  render() {
    if (this.getMap()) {
      if (this.props.onSetPin || this.props.updatePrivatePoisToShow) {
        this.getMap().on("singleclick", this.onMapClick)
      } else {
        this.getMap().un("singleclick", this.onMapClick)
      }
    }

    return <div style={{ height: "100%" }}>{super.render()}</div>
  }

  private setFeatures(address: Location) {
    const coords = fromLonLat([address.lng, address.lat])
    const feature = new ol.Feature({
      type: "Feature",
      geometry: new geom.Point(coords),
    })

    this.selectedAddressLayer.setSource(new VectorSource({ features: [feature] }))

    this.getMap().getView().animate({ duration: 500, center: coords, zoom: 18 })
  }

  private iconStyle(): style.Style {
    return new style.Style({
      image: new style.Icon({
        src: "/assets/address_pin.png",
        scale: 1,
        size: [27, 40],
        anchorOrigin: "top-left",
        anchor: [0.5, 1],
      }),
    })
  }

  @bind
  private updatePoiPopUp() {
    if (this.props.isPrivateDataAccessible && this.props.privateDataSettings) {
      if (this.props.privateDataSettings.poisToShow.length > 0 && this.state.poiPopUpPosition) {
        this.poiPopUp?.openOverlayAt(this.state.poiPopUpPosition, this.props.privateDataSettings.poisToShow)
      } else if (this.poiPopUp) {
        this.poiPopUp.closeCurrentOverlay()
      }
    } else if (this.poiPopUp) {
      this.poiPopUp.closeCurrentOverlay()
    }
  }

  @bind onPoiPopUpClose() {
    this.poiPopUp?.closeCurrentOverlay()
    this.props.updatePrivatePoisToShow?.([], AllowedModulesEnum.QUICK_START)
    this.setState({ ...this.state, poiPopUpPosition: undefined })
  }

  @bind
  private poiPopUpContentCreator(location: number[], poiList: PrivatePOIList): JSXContent {
    const props = {
      lat: location[1],
      lng: location[0],
      poisToShow: poiList,
      onClose: this.onPoiPopUpClose,
    }
    return {
      content: <PoiMarkerDialog {...props} entryPinLocation={this.props.address} />,
    }
  }
}
