import * as React from "react"
import { createPortal } from "react-dom"
import { bind } from "decko"
import { css, keyframes, cx } from "emotion"
import { PropsWithChildren } from "react"
import { utilDisableScrollLock, utilEnableScrollLock } from "../util/util"

export interface Props {
  coverAllTheThings?: boolean
  /** onClickOutside will be triggered on a click outside the child element, requires overlay to be true */
  onClickOutside?: React.MouseEventHandler<HTMLDivElement>
}

const animation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const overlayClass = css({
  animation: `${animation} .5s`,
  backgroundColor: "rgba(0,0,0, .1)",
  bottom: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  overflow: "auto",
  top: 0,
  right: 0,
  left: 0,
  zIndex: 9999100,
})

const overlayContentClass = css({
  zIndex: 9999999,
  display: "flex",
  width: "100%",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  pointerEvents: "none",

  "> *": {
    pointerEvents: "initial",
  },
})

const outsideClickAreaClass = css({
  position: "absolute",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  zIndex: 9999110,
})

// PortalHolder will be renderer in its own document-level div element
class PortalHolder extends React.Component<PropsWithChildren<Props>, {}> {
  componentDidMount() {
    utilEnableScrollLock()
  }

  componentWillUnmount() {
    utilDisableScrollLock(false)
  }

  @bind
  handleClickOutside(event: React.MouseEvent<HTMLDivElement>) {
    event.preventDefault()
    event.stopPropagation()
    this.props.onClickOutside && this.props.onClickOutside(event)
  }

  render() {
    const style: React.CSSProperties = {}

    if (this.props.coverAllTheThings) {
      style.zIndex = 2147483647
    }

    return (
      <div className={cx(overlayClass)} style={style} tabIndex={-1}>
        <div className={cx(overlayContentClass)}>{this.props.children}</div>
        <div className={cx(outsideClickAreaClass)} onClick={this.handleClickOutside}></div>
      </div>
    )
  }
}

export class Portal extends React.Component<PropsWithChildren<Props>, {}> {
  private portalNode: Element | null = null

  componentWillUnmount() {
    if (this.portalNode) {
      document.body.removeChild(this.portalNode)
    }
    this.portalNode = null
  }

  render(): React.ReactPortal {
    if (!this.portalNode) {
      this.portalNode = document.createElement("div")
      document.body.appendChild(this.portalNode)
    }
    return createPortal(<PortalHolder {...this.props} />, this.portalNode)
  }
}

export default Portal
