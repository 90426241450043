import * as React from "react"
import { Control } from "ol/control"
import { createRoot } from "react-dom/client"
import { translations } from "../i18n"
import { Grid } from "./ui/grid"
import { GridItem } from "./ui/grid-item"
import { css } from "emotion"
import * as cookie from "cookie"
import * as layer from "ol/layer"
import * as source from "ol/source"
import * as ol from "ol"
import { PopoverContent } from "./PopoverContent"
import { CLASS_CONTROL } from "ol/css"
import { tileServerUrl } from "../../app_config"

export type MapStyle = "default" | "pastel" | "hybrid" | "positron" | "topo"

const selectedClass = css({
  border: "2px solid blue",
  borderRadius: 3,
})

const unselectedClass = css({
  border: "2px solid white",
  borderRadius: 3,
})

export function getInitialMapStyle(initialMapStyle: MapStyle | undefined): MapStyle {
  const persistedMapStyle: MapStyle | undefined = cookie.parse(document.cookie)["21re_lana_map_style"] as
    | MapStyle
    | undefined
  return persistedMapStyle || initialMapStyle || "default"
}

export function persistMapStyle(mapStyle: MapStyle) {
  cookie.serialize("21re_lana_map_style", mapStyle, {
    expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30),
  })
}

export function setMapUrlBasedOnMapStyle(map: ol.Map, style: MapStyle) {
  const layer0 = map.getLayers().item(0) as layer.Tile<any>
  if (layer0) {
    const source = layer0.getSource() as source.XYZ
    if (source) {
      source.setUrl(`${tileServerUrl}/data-tiles/${style}/{z}/{x}/{y}.${style === "hybrid" ? "jpg" : "png"}`)
    }
  }
}

export class MapStyleControl extends Control {
  private mapStylebutton: React.RefObject<MapStyleButtonPopover>

  constructor(initialMapStyle: MapStyle, onChange: (selectedMapStyle: MapStyle) => void, parent?: HTMLElement) {
    super({ element: document.createElement("div") })
    this.element.className = `${CLASS_CONTROL}`
    this.element.style.right = "0.5em"
    this.element.style.top = "1em"
    this.mapStylebutton = React.createRef()
    createRoot(this.element).render(
      <MapStyleButtonPopover
        initialMapStyle={initialMapStyle}
        onChange={onChange}
        ref={this.mapStylebutton}
        parent={parent}
      />
    )
  }
}

interface MapStyleButtonProps {
  initialMapStyle: MapStyle
  onChange: (selectedMapStyle: MapStyle) => void
  parent?: HTMLElement
}

interface MapStyleButtonState {
  selectedMapStyle: MapStyle
}

class MapStyleButtonPopover extends React.Component<MapStyleButtonProps, MapStyleButtonState> {
  private t = translations()

  constructor(props: MapStyleButtonProps) {
    super(props)

    this.state = { selectedMapStyle: props.initialMapStyle }
  }

  render() {
    const styles: MapStyle[] = ["default", "pastel", "hybrid", "positron", "topo"]

    return (
      <PopoverContent title={this.t.map.mapStyleTitle} iconname={"inventory"} parent={this.props.parent}>
        <Grid columns={4} padding={[0, 8, 0, 16]}>
          {styles.map((style) => (
            <Grid columns={1} padding={[8, 16, 8, 0]} gap={4} key={style}>
              <GridItem justifySelf={"center"}>
                <img
                  width={60}
                  height={60}
                  src={`/assets/map-style-${style}.png`}
                  alt={`${style}`}
                  className={`${this.state.selectedMapStyle === style ? selectedClass : unselectedClass}`}
                  onClick={() => {
                    this.setState({ selectedMapStyle: style })
                    this.props.onChange(style)
                  }}
                />
              </GridItem>
              <GridItem justifySelf={"center"}>
                <span style={{ fontSize: "14px" }}>{this.t.map.mapStyleName[style]}</span>
              </GridItem>
            </Grid>
          ))}
        </Grid>
      </PopoverContent>
    )
  }
}
