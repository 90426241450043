import * as React from "react"
import { Flex } from "../../shared/components/ui/flex"
import { GlobalState, useAppSelector } from "../../relas/store"
import { extractLabel } from "../../shared/models/selection"
import { translations } from "../i18n"
import { LocationSelectorMapMicroView } from "./location-selector-map-micro-view"
import { LocationSelectorMapMacroView } from "./location-selector-map-macro-view"
import {
  fetchSelectionResults,
  resetMap,
  setAgsRefResLoc,
  setSelectedMunicipalityAndFocus,
  setShowMunicipalityList,
} from "../location-selector-slice"
import {
  updateSelectedCategories,
  fetchCategories,
  fetchCategoryPois,
  updatePOIsToShow,
} from "../../private-data/reducers/private-data-slice"
import { BERLIN_AGS_REF_RES_LOC } from "../../shared/actions/map-actions"
import Grid from "../../shared/components/restyle-grid/grid"
import { formatNumber } from "../../shared/helper/number-format"

function colorForValue(value: number): string {
  return `hsla(${225 - (100 - (value * 50 + 50))}, 100%, 50%, 0.7)`
}

export const LocationSelectorMap = () => {
  const t = React.useMemo(() => translations(), [])

  const showMunicipalityList = useAppSelector((state: GlobalState) => state.locationSelector.showMunicipalityList)
  const selectionResults = useAppSelector((state: GlobalState) => state.locationSelector.selectionResults)
  const resultsLoading = useAppSelector((state: GlobalState) => state.locationSelector.selectionResultsLoading)
  const agsRefResLoc = useAppSelector((state: GlobalState) => state.locationSelector.agsRefResLoc)
  const selectedMunicipalityAndFocus = useAppSelector(
    (state: GlobalState) => state.locationSelector.selectedMunicipalityAndFocus
  )
  const currentSelection = useAppSelector((state: GlobalState) => state.locationSelector.currentSelection)
  const mapNeedsReset = useAppSelector((state: GlobalState) => state.locationSelector.mapNeedsReset)
  const partialRuleToBeDisplayed = useAppSelector(
    (state: GlobalState) => state.locationSelector.selectedRuleIndexForPartialDisplay
  )
  const isPrivateDataAccessible = useAppSelector((state: GlobalState) => state.user.scopes.privateData)
  const privatePoiCategories = useAppSelector((state: GlobalState) => state.privateData.privatePOICategories)
  const privateDataSettings = useAppSelector(
    (state: GlobalState) => state.privateData.modulesWithPrivateData.locationSelector
  )

  const getPrivateDataCategories = fetchCategories
  const updateSelectedPrivateDataCategories = updateSelectedCategories
  const getPrivatePoisFromCategories = fetchCategoryPois
  const updatePrivatePoisToShow = updatePOIsToShow

  React.useEffect(() => {
    setSelectedMunicipalityAndFocus(null)
  }, [currentSelection])

  const items = new Map<
    string,
    { color: string; title: string; data: { label: string; value: string }[]; score?: number; grade?: string }
  >()
  let minScore = undefined
  let maxScore = undefined
  let currAgsRefResLoc: string = agsRefResLoc ?? BERLIN_AGS_REF_RES_LOC

  if (currentSelection && selectionResults) {
    const scoredResults = selectionResults.results.filter((r) => typeof r.score === "number")

    if (selectionResults.agsRefResLoc) currAgsRefResLoc = selectionResults.agsRefResLoc

    if (scoredResults.length > 0) {
      minScore = Math.min(...scoredResults.map((r) => r.score || 0))
      maxScore = Math.max(...scoredResults.map((r) => r.score || 0))

      if (minScore === maxScore) {
        minScore = 0
        maxScore = 100
      }
      for (const result of selectionResults.results) {
        const data: { label: string; value: string }[] = []

        if (currentSelection.dataSetType === "macro") {
          const label = extractLabel(result, selectionResults.selectionLabel)

          if (label)
            data.push({
              label: t.pickTranslation(label.label),
              value: formatNumber(label.value, 0, label.unit ? t.pickTranslation(label.unit) : undefined),
            })
          data.push({
            label: t.municipalityPopulation,
            value: formatNumber(result.fields.population as number, 0),
          })
        }

        items.set(result.refId, {
          title: selectionResults.dataSetType === "macro" ? (result.fields.ags_name as string) : `# ${result.refId}`,
          data,
          score: typeof result.score === "number" ? result.score : undefined,
          color: colorForValue(((result.score || 0) - minScore) / (maxScore - minScore)),
        })
      }
    } else {
      for (const result of selectionResults.results) {
        const data: { label: string; value: string }[] = []

        if (currentSelection.dataSetType === "macro") {
          const label = extractLabel(result, selectionResults.selectionLabel)

          if (label) {
            const isPercentage = label.unit?.en === "%"
            const valueToDisplay = isPercentage
              ? formatNumber(
                  label.value ? label.value * 100 : undefined,
                  2,
                  label.unit ? t.pickTranslation(label.unit) : undefined
                )
              : formatNumber(
                  label.value,
                  label.value && label.value < 1000 ? 2 : 0,
                  label.unit ? t.pickTranslation(label.unit) : undefined
                )

            data.push({
              label: t.pickTranslation(label.label),
              value: valueToDisplay,
            })
          }
          data.push({
            label: t.municipalityPopulation,
            value: formatNumber(result.fields.population as number, 0),
          })
        }

        items.set(result.refId, {
          title: selectionResults.dataSetType === "macro" ? (result.fields.ags_name as string) : `# ${result.refId}`,
          data,
          score: typeof result.score === "number" ? result.score : undefined,
          color: "rgb(128,128,128)",
        })
      }
    }
  }

  const mapView = currentSelection?.dataSetType ?? "macro"

  const displayColourGradient = typeof minScore === "number" && typeof maxScore === "number"

  return (
    <Grid columns={1} rowSpec={displayColourGradient ? "1fr fit-content(100%)" : "1fr"} height={[100, "%"]}>
      {mapView === "micro" && (
        <LocationSelectorMapMicroView
          loading={resultsLoading}
          agsRefResLoc={currAgsRefResLoc}
          microItems={items}
          onSelectAgsRefResLoc={(agsRefResLoc) => {
            setAgsRefResLoc(agsRefResLoc)
            currentSelection && void fetchSelectionResults(currentSelection, partialRuleToBeDisplayed, agsRefResLoc)
          }}
          showStroke={minScore === maxScore}
          mapNeedsReset={mapNeedsReset}
          dispatchMapReset={resetMap}
          isPrivateDataAccessible={isPrivateDataAccessible}
          privatePoiCategories={privatePoiCategories}
          privateDataSettings={privateDataSettings}
          getPrivateDataCategories={getPrivateDataCategories}
          updateSelectedPrivateDataCategories={updateSelectedPrivateDataCategories}
          getPrivatePoisFromCategories={getPrivatePoisFromCategories}
          updatePrivatePoisToShow={updatePrivatePoisToShow}
        />
      )}

      {mapView === "macro" && (
        <LocationSelectorMapMacroView
          showMunicipalities={showMunicipalityList}
          onShowMunicipalities={setShowMunicipalityList}
          macroItems={items}
          loading={resultsLoading}
          selectedMunicipalityAndFocus={selectedMunicipalityAndFocus ?? null}
          onSetSelectedMunicipalityId={(selectedMunicipalityId) =>
            selectedMunicipalityId && setSelectedMunicipalityAndFocus({ selectedMunicipalityId, focusInMap: false })
          }
          selectedSelectionId={currentSelection?.id ?? null}
          mapNeedsReset={mapNeedsReset}
          dispatchMapReset={resetMap}
          isPrivateDataAccessible={isPrivateDataAccessible}
          privatePoiCategories={privatePoiCategories}
          privateDataSettings={privateDataSettings}
          getPrivateDataCategories={getPrivateDataCategories}
          updateSelectedPrivateDataCategories={updateSelectedPrivateDataCategories}
          getPrivatePoisFromCategories={getPrivatePoisFromCategories}
          updatePrivatePoisToShow={updatePrivatePoisToShow}
        />
      )}

      {/* Not using `displayColourGradient` so as to get the defined values for minScore & maxScore */}
      {typeof minScore === "number" && typeof maxScore === "number" && (
        <Grid columnSpec="fit-content(100%) 1fr fit-content(100%)" gap={8} padding={8}>
          <Flex flexDirection="row" alignItems="center" gap={8}>
            <div>{minScore.toFixed(0)}</div>
            <div
              style={{
                height: "20px",
                width: "400px",
                backgroundImage: `linear-gradient(to right, ${colorForValue(0)}, ${colorForValue(1)})`,
              }}
            />
            <div>{maxScore.toFixed(0)}</div>
          </Flex>
          <div />
        </Grid>
      )}
    </Grid>
  )
}
