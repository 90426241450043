import * as React from "react"
import { translations } from "../i18n"
import { translations as genericTranslation } from "../../user-account/i18n"
import { LoginErrors } from "../models/login-errors"
import { useAppSelector } from "../../relas/store"
import Panel from "../../shared/components/panel"
import { isNullOrUndefined } from "../../shared/helper/utils"

export const LoginError = () => {
  const error = useAppSelector((state) => state.login.loginError)

  const translate = React.useMemo(() => translations(), [])
  const genericTranslate = React.useMemo(() => genericTranslation(), [])

  if (isNullOrUndefined(error)) {
    return null
  }
  let message = translate.login.loginFailed
  switch (error) {
    case LoginErrors.noProductsBooked:
      message = genericTranslate.genericError.noProduct
      break
    case LoginErrors.userBlocked:
      message = translate.login.userBlocked
      break
  }
  return (
    <Panel color="negative">
      <p>{message}</p>
    </Panel>
  )
}
