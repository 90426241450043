import * as React from "react"
import { translations } from "../../i18n"
import { Grid } from "../../../shared/components/ui/grid"
import { ScrollBox } from "../../../shared/components/ui/scroll-box"
import { LANA_POSTCODE_PROPS } from "../../../shared/smartdata-products/smartdata"
import * as _ from "lodash"
import Text from "../../../shared/components/text"
import FlexContainer from "../../../shared/components/restyle-grid/flexcontainer"
import { language } from "../../../shared/language"
import { formatValue } from "../../../shared/helper/number-format"
import { getThemeColorVar } from "../../../shared/helper/color"

type Props = {
  selectedData: string[]
  scores: { [key in string]: number }
  isLoading: boolean
}

interface Item {
  name: string
  score: string
  idx: number
}
type ItemList = Item[]

export const DataListViewer = (props: Props) => {
  const t = React.useMemo(() => {
    return translations().fundamentalData
  }, [language()])

  const langKey = language() as "de" | "en"
  const [dataToDisplay, setDataToDisplay] = React.useState<Item[]>([])

  React.useEffect(() => {
    setDataToDisplay(generateGridArray(props.selectedData))
  }, [props.selectedData])

  const getDisplayData: (dataName: string) => [string, string | undefined] = (dataName: string) => {
    const prop = LANA_POSTCODE_PROPS[dataName]
    if (prop) {
      const unit = prop.unit && prop.unit[langKey]

      return [prop.title[langKey], unit !== null ? unit : undefined]
    } else {
      return [dataName, undefined]
    }
  }

  const generateGridArray = (data: string[]): ItemList => {
    if (data.length === 0) return []
    const itemArray: ItemList = data.map((data, idx) => {
      const [name, unit] = getDisplayData(data)
      const scoreToDisplay = props.isLoading ? "-" : formatValue(props.scores[data], 2, unit)
      return {
        name: name,
        score: scoreToDisplay !== "" ? scoreToDisplay : "-",
        idx: idx,
      }
    })
    if (itemArray.length < 2) return itemArray
    else {
      const sortedArr = _.orderBy(itemArray, ["name"], ["asc"])
      sortedArr.forEach((item, idx) => {
        item.idx = idx
      })
      return sortedArr
    }
  }

  return dataToDisplay.length === 0 ? (
    <FlexContainer md-align="center" md-justify="center" canGrow direction="column">
      <Text>{t.noDataSelected}</Text>
    </FlexContainer>
  ) : (
    <ScrollBox>
      {generateGridArray(props.selectedData).map((item) => {
        const { name, score, idx } = item
        return (
          <Grid
            padding={[4, 12, 4, 12]}
            columnSpec="3fr 1fr"
            backgroundColor={getThemeColorVar("background", idx % 2 === 0 ? "default" : "lighter")}
            key={idx}
          >
            <Text>{name}</Text>
            <Text>{score}</Text>
          </Grid>
        )
      })}
    </ScrollBox>
  )
}
