import * as React from "react"
import { bind } from "decko"
import { css, cx } from "emotion"
import { getThemeColorVar } from "../helper/color"

export interface Props {
  disabled?: boolean
  id: string
  required?: boolean
  checked?: boolean
  value: string
  label: string
  name: string
  onChange?: (value: string) => void
}

const radioClass = css({
  width: "100%",

  label: {
    cursor: "pointer",
    display: "inline",
    width: "initial",
    fontWeight: 400,
    color: getThemeColorVar("typo", "default"),
    fontSize: "14px",
    lineHeight: "25px",
    marginBottom: "5px",
  },

  input: {
    backgroundColor: getThemeColorVar("background", "default"),
    border: "none",
    color: getThemeColorVar("typo", "default"),
    lineHeight: "20px",
    padding: "9px",
    minWidth: "0px",
    borderRadius: "4px",

    "&:focus": {
      backgroundColor: getThemeColorVar("background", "default"),
      borderColor: getThemeColorVar("background", "lighter"),
      outline: "none",
    },

    "&::placeholder": {
      color: getThemeColorVar("typo", "lighter"),
      fontStyle: "italic",
    },

    "&:disabled": {
      cursor: "not-allowed",
      color: getThemeColorVar("typo", "default"),
    },

    "&:invalid": {
      boxShadow: "none",
    },

    cursor: "pointer",
    display: "inline",
    width: "auto",
    fontWeight: 400,
    marginRight: "5px",
    position: "relative",
    bottom: "-2px",
  },
})

export class RadioButton extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
  }

  @bind
  handleSelect(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.onChange && this.props.onChange(this.props.value)
  }

  render() {
    return (
      <div className={cx(radioClass)}>
        <label htmlFor={this.props.id}>
          <input
            id={this.props.id}
            type="radio"
            checked={this.props.checked}
            name={this.props.name}
            required={this.props.required}
            disabled={this.props.disabled}
            value={this.props.value}
            onChange={this.handleSelect}
          />
          {this.props.label}
        </label>
      </div>
    )
  }
}

export default RadioButton
