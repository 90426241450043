import React, { useEffect } from "react"
import { Flex } from "../../../shared/components/ui/flex"
import { FlexItem } from "../../../shared/components/ui/flex-item"
import { language } from "../../../shared/i18n/language"
import { ScrollBox } from "../../../shared/components/ui/scroll-box"
import { translations } from "../../i18n"
import { Tooltip } from "../../../shared/components/ui/tooltip"
import { Checkbox, Collapse, InputGroup, Intent, Menu, MenuDivider, MenuItem, Popover } from "@blueprintjs/core"
import { css } from "emotion"
import { Button as ButtonBP } from "@blueprintjs/core/lib/esm/components/button/buttons"
import { useDebounce } from "../../../utils/use-debounce"
import {
  Category,
  PostcodeProp,
  LANA_POSTCODE_CATEGORIES,
  Topic,
  LANA_POSTCODE_PROPS,
  HasTranslations,
  LANA_POSTCODE_TOPICS,
} from "../../../shared/smartdata-products/smartdata"
import { DataLevel } from "../../reducers/fundamental-data-slice"
import Grid from "../../../shared/components/restyle-grid/grid"
import { getThemeColor } from "../../../shared/helper/color"
import Icon from "../../../shared/components/icon"

const categorize = (dataList: PostcodeProp[]) => {
  const result: DataByCategory[] = []

  for (const postcodeProperty of dataList) {
    const existing = result.find((c) => c.category.name === postcodeProperty.category)

    if (existing) {
      const existingTopic = existing.data.find((item) => item.topic.name === postcodeProperty.topic.name)
      if (existingTopic) {
        existingTopic.data.push(postcodeProperty)
      } else
        existing.data.push({
          topic: LANA_POSTCODE_TOPICS[postcodeProperty.topic.name],
          data: [postcodeProperty],
        })
    } else
      result.push({
        category: LANA_POSTCODE_CATEGORIES[postcodeProperty.category],
        data: [
          {
            topic: LANA_POSTCODE_TOPICS[postcodeProperty.topic.name],
            data: [postcodeProperty],
          },
        ],
      })
  }

  const allCategories = new Set(result.map((category) => category.category.name))
  const allTopics = new Set(result.flatMap((category) => category.data.map((topic) => topic.topic.name)))
  const allPostcodeData = new Set(
    result
      .flatMap((category) => category.data.map((topic) => topic.data))
      .flatMap((topic) => topic.map((data) => data.name))
  )

  return {
    dataList: result,
    categories: allCategories,
    topics: allTopics,
    dataNames: allPostcodeData,
  }
}

const dataList: (lang: keyof HasTranslations) => PostcodeProp[] = (lang: keyof HasTranslations) =>
  Object.values(LANA_POSTCODE_PROPS).sort((a, b) => {
    const cCmp = LANA_POSTCODE_CATEGORIES[a.category].title[lang].localeCompare(
      LANA_POSTCODE_CATEGORIES[b.category].title[lang]
    )
    if (cCmp != 0) return cCmp
    else {
      const tCmp = LANA_POSTCODE_TOPICS[a.topic.name].title[lang].localeCompare(
        LANA_POSTCODE_TOPICS[b.topic.name].title[lang]
      )
      return tCmp != 0 ? tCmp : a.title[lang].localeCompare(b.title[lang])
    }
  })

const styles = {
  listItem: css({
    borderBottom: "1px solid",
    borderColor: `${getThemeColor("border", "default")}`,
    cursor: "pointer",
  }),
}

export interface DataByTopic {
  topic: Topic
  data: PostcodeProp[]
}
export interface DataByCategory {
  category: Category
  data: DataByTopic[]
}

interface Props {
  dataLevel: DataLevel
  selected: string[]
  collapsedCategories: string[]
  collapsedTopics: string[]
  setCollapsedCategories: (category: string[]) => void
  setCollapsedTopics: (topic: string[]) => void
  setSelectedData: (selected: string) => void
  clearSelectedData: () => void
}

const DataListSelector = (props: Props) => {
  const t = React.useMemo(translations, [translations])
  const langKey = language() === "de" ? "de" : "en"
  const categorizedData = React.useMemo(() => categorize(dataList(langKey)), [langKey])

  const {
    dataList: categorizedDataList,
    dataNames: allPostcodeData,
    topics: allTopics,
    categories: allCategories,
  } = categorizedData

  const selectedSet = new Set(props.selected)
  const collapsedCategoriesSet = new Set(props.collapsedCategories)
  const collapsedTopicsSet = new Set(props.collapsedTopics)

  const [debouncedScoreFilterName, scoreFilterName, setScoreFilterName] = useDebounce<string>("", 800)
  const [filteredPostcodeData, setFilteredPostcodeData] = React.useState<Set<string>>(allPostcodeData)

  useEffect(() => {
    if (debouncedScoreFilterName === "") {
      setFilteredPostcodeData(allPostcodeData)
    } else {
      const lowercasedSearchTerm = debouncedScoreFilterName.toLowerCase()

      const filteredScores = categorizedDataList.reduce<string[]>((acc, dataByCategory) => {
        const data = dataByCategory.data.flatMap((dataByTopic) => dataByTopic.data)
        const filteredBySearchTerm = data.filter(
          (s) =>
            s.title[langKey].toLowerCase().includes(lowercasedSearchTerm) ||
            s.description[langKey].toLowerCase().includes(lowercasedSearchTerm)
        )
        filteredBySearchTerm.forEach((data) => acc.push(data.name))
        return acc
      }, [])

      setFilteredPostcodeData(new Set(filteredScores))
    }
  }, [debouncedScoreFilterName])

  const renderDataListItem = (dataItem: PostcodeProp) => {
    const onCheckboxChange = (item: string) => props.setSelectedData(item)

    return (
      <div className={styles.listItem} key={"renderDataListItem-" + dataItem.name}>
        <Flex flexDirection="row" gap={4} key={dataItem.name} padding={[8, 0, 8, 32]}>
          <Checkbox
            checked={selectedSet.has(dataItem.name)}
            style={{ marginBottom: 0 }}
            id={dataItem.name}
            onChange={() => onCheckboxChange(dataItem.name)}
          >
            {t.pickTranslation(dataItem.title)}&nbsp;
            <Tooltip
              placement="right"
              tooltip={
                <div style={{ maxWidth: "400px" }}>
                  <div>{t.pickTranslation(dataItem.description)}</div>
                  <div>
                    <b>{t.scoreSource}</b>: {t.pickTranslation(dataItem.source)}
                  </div>
                </div>
              }
            >
              <Icon name="info" fontSize={14} color="primary" colorType="default" />
            </Tooltip>
          </Checkbox>
        </Flex>
      </div>
    )
  }

  const onArrowClick = (
    ev: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    name: string,
    collapsedItems: Set<string>,
    setCollapsedItems: (c: string[]) => void
  ) => {
    ev.stopPropagation()
    ev.preventDefault()
    const c = new Set(collapsedItems)
    if (c.has(name)) {
      c.delete(name)
      setCollapsedItems(Array.from(c))
    } else setCollapsedItems(Array.from(c.add(name)))
  }

  const getSetsDifference = (a: Set<string>, b: Set<string>): Set<string> => new Set([...a].filter((x) => !b.has(x)))

  const collapseNotActiveCategoriesAndTopics = () => {
    const expandedCategories = new Set<string>()
    const expandedTopics = new Set<string>()

    for (const [, value] of Object.entries(LANA_POSTCODE_PROPS)) {
      if (selectedSet.has(value.name)) {
        expandedCategories.add(value.category)
        expandedTopics.add(value.topic.name)
      }
    }

    props.setCollapsedCategories(Array.from(getSetsDifference(allCategories, expandedCategories)))
    props.setCollapsedTopics(Array.from(getSetsDifference(allTopics, expandedTopics)))
  }

  const scoreFiltering = () => (
    <div style={{ borderBottom: "1px solid", borderColor: `${getThemeColor("border", "default")}` }}>
      <Grid columnSpec={"1fr  min-content"} gap={16} padding={16}>
        <InputGroup
          placeholder={t.scoreSearch.filter}
          style={{ borderRadius: "4px" }}
          value={scoreFilterName}
          leftElement={
            <div style={{ padding: "5px 4px" }}>
              <Icon name={"search"} color={"typo"} colorType={"lighter"} fontSize={16} />
            </div>
          }
          rightElement={
            <Tooltip placement="auto" tooltip={t.districtDataSearchInfo}>
              <div style={{ padding: "5px" }}>
                <Icon name={"info"} color={"typo"} colorType={"lighter"} fontSize={16} />
              </div>
            </Tooltip>
          }
          onChange={(event) => setScoreFilterName(event.currentTarget.value)}
        />
        <Popover
          placement="auto"
          content={
            <Menu>
              <MenuItem text={t.scoreSearch.expandActive} onClick={collapseNotActiveCategoriesAndTopics} />
              <MenuItem
                text={t.scoreSearch.expandAll}
                onClick={() => {
                  props.setCollapsedCategories([])
                  props.setCollapsedTopics([])
                }}
              />
              <MenuItem
                text={t.scoreSearch.collapseAll}
                onClick={() => {
                  props.setCollapsedCategories(Array.from(allCategories))
                  props.setCollapsedTopics(Array.from(allTopics))
                }}
              />
              <MenuDivider />
              <MenuItem text={t.scoreSearch.unselectAll} onClick={props.clearSelectedData} />
            </Menu>
          }
        >
          <ButtonBP
            style={{ borderRadius: "4px" }}
            intent={Intent.PRIMARY}
            rightIcon={"caret-down"}
            text={t.scoreSearch.groups}
          />
        </Popover>
      </Grid>
    </div>
  )

  const renderDataListInTopic = (dataListInTopic: DataByTopic) => {
    const topicCollapsed = collapsedTopicsSet.has(dataListInTopic.topic.name)

    return (
      <React.Fragment key={"renderDataListInTopic-" + dataListInTopic.topic.name}>
        <div
          className={styles.listItem}
          onClick={(ev) => {
            onArrowClick(ev, dataListInTopic.topic.name, collapsedTopicsSet, props.setCollapsedTopics)
          }}
        >
          <Flex
            flexDirection={"row"}
            backgroundColor={`${getThemeColor("background", "lighter")}`}
            padding={[8, 0, 8, 32]}
          >
            <FlexItem flexGrow={1}>
              <b>{t.pickTranslation(dataListInTopic.topic.title)}</b>
            </FlexItem>
            <a
              onClick={(ev) => {
                onArrowClick(ev, dataListInTopic.topic.name, collapsedTopicsSet, props.setCollapsedTopics)
              }}
            >
              <Icon fontSize={20} name={topicCollapsed ? "arrow_drop_down" : "arrow_drop_up"} />
            </a>
          </Flex>
        </div>
        <Collapse isOpen={!topicCollapsed}>
          {dataListInTopic.data.filter((data) => filteredPostcodeData.has(data.name)).map(renderDataListItem)}
        </Collapse>
      </React.Fragment>
    )
  }

  const renderCategorizedDataList = (categorizedDataList: DataByCategory, index: number) => {
    const categoryCollapsed = collapsedCategoriesSet.has(categorizedDataList.category.name)
    const ungroupedData = categorizedDataList.data.find((item) => item.topic.name === "ungrouped")

    return (
      <React.Fragment key={index}>
        <div
          className={styles.listItem}
          onClick={(ev) =>
            onArrowClick(ev, categorizedDataList.category.name, collapsedCategoriesSet, props.setCollapsedCategories)
          }
        >
          <Flex
            flexDirection={"row"}
            backgroundColor={`${getThemeColor("background", "lighter")}`}
            padding={[8, 0, 8, 16]}
          >
            <FlexItem flexGrow={1}>
              <b>{t.pickTranslation(categorizedDataList.category.title)}</b>
            </FlexItem>
            <a
              onClick={(ev) =>
                onArrowClick(
                  ev,
                  categorizedDataList.category.name,
                  collapsedCategoriesSet,
                  props.setCollapsedCategories
                )
              }
            >
              <Icon fontSize={20} name={categoryCollapsed ? "arrow_drop_down" : "arrow_drop_up"} />
            </a>
          </Flex>
        </div>
        <Collapse isOpen={!categoryCollapsed}>
          {ungroupedData && (
            <>
              {ungroupedData.data
                .filter((data) => filteredPostcodeData.has(data.name))
                .map((item) => renderDataListItem(item))}
            </>
          )}
          {categorizedDataList.data
            .filter((dataByTopic) =>
              dataByTopic.topic.name !== "ungrouped"
                ? dataByTopic.data.filter((data) => filteredPostcodeData.has(data.name)).length > 0
                : false
            )
            .map(renderDataListInTopic)}
        </Collapse>
      </React.Fragment>
    )
  }

  return (
    <Grid columns={1} rowSpec={"min-content 1fr"} height={[100, "%"]}>
      {scoreFiltering()}
      <ScrollBox>
        <Flex flexDirection="column">
          {categorizedDataList
            .filter(
              (dataByCategory) =>
                dataByCategory.data.filter(
                  (dataByTopic) => dataByTopic.data.filter((data) => filteredPostcodeData.has(data.name)).length > 0
                ).length > 0
            )
            .map((c, index) => renderCategorizedDataList(c, index))}
        </Flex>
      </ScrollBox>
    </Grid>
  )
}

export default DataListSelector
