import { SharedTranslations } from "../i18n/translations"
import { Category, LANA_AGS_NODES, LANA_CATEGORIES, LANA_CELL_NODES, LanaNode } from "../smartdata-products/smartdata"

export type DataSetType = "micro" | "macro"

export const DataSetTypes: DataSetType[] = ["micro", "macro"]

export interface LanaNodeWithVariation extends LanaNode {
  scoreVariationNames?: LanaNode[]
}

export interface CategorizedScores {
  category: Category
  scores: LanaNodeWithVariation[]
}

export function categorize(t: SharedTranslations, scores: LanaNode[]): CategorizedScores[] {
  const result: CategorizedScores[] = []

  scores.sort((a, b) => {
    const cCmp = t
      .pickTranslation(LANA_CATEGORIES[a.category].title)
      .localeCompare(t.pickTranslation(LANA_CATEGORIES[b.category].title))
    return cCmp != 0 ? cCmp : t.pickTranslation(a.title).localeCompare(t.pickTranslation(b.title))
  })

  for (const score of scores) {
    const existing = result.find((c) => c.category.name == score.category)

    if (existing) {
      if (score.scoreVariationName) {
        const existingScoreWithVariationName = existing.scores.find((s) => s.scoreVariation === score.scoreVariation)
        existingScoreWithVariationName
          ? existingScoreWithVariationName.scoreVariationNames?.push(score)
          : existing.scores.push({ ...score, scoreVariationNames: [score] })
      } else existing.scores.push(score)
    } else
      result.push({
        category: LANA_CATEGORIES[score.category],
        scores: score.scoreVariationName ? [{ ...score, scoreVariationNames: [score] }] : [score],
      })
  }

  return result
}

export function getScoreSetByDataSetType(dataSetType: DataSetType): LanaNode[] {
  switch (dataSetType) {
    case "macro":
      return LANA_AGS_NODES
    case "micro":
      return LANA_CELL_NODES
  }
}
