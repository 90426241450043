import { ReportTranslations } from "./reportTranslations"
import { REPORT_EN } from "./en"
import { REPORT_DE } from "./de"
import { language } from "../../shared/language"

export function translations(): ReportTranslations {
  switch (language()) {
    case "de":
      return REPORT_DE
    default:
      return REPORT_EN
  }
}
