import React, { useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import {
  Address,
  POIFormData,
  PoiFormDataErrors,
  POIToSubmit,
  PrivatePOI,
  PrivatePOICategory,
  PrivatePOIToCreate,
  PrivatePOIToCreateOrEdit,
  PrivatePOIToEdit,
  SearchData,
} from "../../models/private-data"
import { translations } from "../../i18n"
import { Flex } from "../../../shared/components/ui/flex"
import { FlexItem } from "../../../shared/components/ui/flex-item"
import { AddressSuggestionAutoComplete } from "../../../shared/components/address-suggestion-auto-complete"
import {
  AddressSuggestion,
  AddressSuggestionResponse,
  GeoInformation,
  HereAddress,
  LocationResponse,
} from "../../../shared/models/poi-explorer"
import { AddressGeoView } from "../../../assessment/components/address-geo-view"
import { formatAddress, Location } from "../../../assessment/models/address"
import { isoCountry } from "iso-country"
import * as proj from "ol/proj"
import Axios from "axios"
import { geoServiceUrl } from "../../../app_config"
import { useDebounce } from "../../../utils/use-debounce"
import { useAppSelector } from "../../../relas/store"
import { cleanPoiCreateOrEditErrors, createPrivatePoi, editPrivatePoi } from "../../reducers/private-data-slice"
import { trackUsageEvent } from "../../../utils/usage-tracking"
import GenericErrorPanel from "../../../shared/components/genericerrorpanel"
import Panel from "../../../shared/components/panel"
import Dialog from "../../../shared/components/dialog"
import TextArea from "../../../shared/components/textarea"
import Grid from "../../../shared/components/restyle-grid/grid"
import TextField from "../../../shared/components/textfield"
import Button from "../../../shared/components/button"
import { language } from "../../../shared/language"
const SUGGESTION_SUFFIX = ", Deutschland"

const lang = language()
const getInitialAddress = () => ({
  route: "",
  streetNumber: "",
  postalCode: "",
  locality: "",
  country: "",
  location: undefined,
})

const getTrimmedAddress = (poi?: PrivatePOI | POIFormData) => {
  const address = poi?.address ?? getInitialAddress()
  return {
    location: poi?.droppedLocation ? poi.droppedLocation : address.location,
    route: address.route ? address.route.trim() : "",
    country: address.country ? address.country.trim() : "",
    locality: address.locality ? address.locality.trim() : "",
    postalCode: address.postalCode ? address.postalCode.trim() : "",
    streetNumber: address.streetNumber ? address.streetNumber.trim() : "",
  }
}

const isAddressIncomplete = (address: Address) => {
  return (
    address.route === "" ||
    address.postalCode === "" ||
    address.locality === "" ||
    address.country === "" ||
    address.streetNumber === ""
  )
}
const getInitialPOIFormData = (poiToEdit?: PrivatePOI) => ({
  title: poiToEdit && poiToEdit.title ? poiToEdit.title : "",
  companyAssignedId: poiToEdit && poiToEdit.companyAssignedId ? poiToEdit.companyAssignedId : "",
  address: getTrimmedAddress(poiToEdit),
  notes: poiToEdit && poiToEdit.notes ? poiToEdit.notes : "",
  structuredData: poiToEdit && poiToEdit.structuredData ? poiToEdit.structuredData : undefined,
})
const getInitialSearchFormData = (poiToEdit?: PrivatePOI): SearchData => ({
  suggestions: [],
  center:
    poiToEdit && poiToEdit.address.location
      ? proj.fromLonLat([poiToEdit.address.location.lng, poiToEdit.address.location.lat])
      : proj.fromLonLat([10.5, 51]),
  zoom: poiToEdit ? 6 : 16,
  showSuggestions: false,
  tryToAddDuplicate: false,
  manualCoords: false,
  droppedLocation: poiToEdit?.droppedLocation,
  droppedLocationGeocodingLoading: false,
  cancel: undefined,
})
const getOrElse = (maybe: string | undefined): string => `${maybe ? `${maybe} ` : ""}`

const getFormattedAddress = (obj: PrivatePOI | AddressSuggestion, isPoi?: boolean): string => {
  const city = getOrElse(isPoi ? (obj as PrivatePOI).address.locality : (obj as AddressSuggestion).address.city)
  const street = getOrElse(isPoi ? (obj as PrivatePOI).address.route : (obj as AddressSuggestion).address.street)
  const number = getOrElse(
    isPoi ? (obj as PrivatePOI).address.streetNumber : (obj as AddressSuggestion).address.houseNumber
  )
  return `${getOrElse(obj.address.country)}${getOrElse(obj.address.postalCode)}${city}${street}${number}`
}

export type PrivatePOICreateOrEditModes = "create" | "edit"

interface OwnProps {
  mode: PrivatePOICreateOrEditModes
  poiToEdit?: PrivatePOI
  selectedCategory: PrivatePOICategory
  onClose: () => void
}

type Props = OwnProps

const CreateEditSinglePOIDialog = (props: Props) => {
  const t = useMemo(translations, [translations])

  const privatePOICreateOrEditError = useAppSelector((state) => state.privateData.privatePOICreateOrEditError)

  const isEditMode = () => props.poiToEdit && props.mode === "edit"

  const { register, handleSubmit, watch, setValue, reset } = useForm<POIFormData>({
    defaultValues: {
      ...getInitialPOIFormData(isEditMode() ? props.poiToEdit : undefined),
    },
  })

  const [optionSelected, setOptionSelected] = useState(false)
  const [searchData, setSearchData] = useState<SearchData>(
    getInitialSearchFormData(isEditMode() ? props.poiToEdit : undefined)
  )
  const [debouncedSearchTerm, searchTerm, setSearchTerm] = useDebounce(
    isEditMode() && props.poiToEdit ? getFormattedAddress(props.poiToEdit, true) : "",
    700
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isEdited, setIsEdited] = useState<boolean>(false)

  React.useEffect(
    () => () => {
      setSearchData(getInitialSearchFormData())
      reset(getInitialPOIFormData())
      setSearchTerm("")
      setIsEdited(false)
    },
    []
  )

  React.useEffect(() => {
    if (debouncedSearchTerm !== "" && !searchData.manualCoords) {
      void fetchSuggestions()
    } else {
      setSearchData({ ...searchData, showSuggestions: false, suggestions: [] })
    }
    setSearchData({ ...searchData, manualCoords: false })
  }, [debouncedSearchTerm])

  const currentlyDisplayedAddressLocation: Location | undefined =
    searchData.droppedLocation && !searchData.droppedLocationGeocodingLoading && !optionSelected
      ? searchData.droppedLocation
      : watch("address")
      ? watch("address").location
      : undefined

  const showSetPinHint = optionSelected && isAddressIncomplete(watch("address"))
  const addressOutsideOfGermany = showSetPinHint && watch("address").country !== "DE"

  const getErrorSection = (error: PoiFormDataErrors) => {
    if (error.status === 400 && error.data?.details) {
      return <Panel color="negative">{t.privateData.fileUpload.fileUploadErrorResponse[error.data?.details]}</Panel>
    }

    return <GenericErrorPanel error={error} />
  }

  const fetchSuggestions = (): Promise<void> => {
    const stringToSearch = debouncedSearchTerm + SUGGESTION_SUFFIX
    getSuggestions(stringToSearch, (suggestions) =>
      setSearchData({ ...searchData, suggestions, showSuggestions: suggestions.length > 0 })
    )
    return Promise.resolve()
  }
  const onForceSelection = () => {
    const stringToSearch = debouncedSearchTerm + SUGGESTION_SUFFIX
    getSuggestions(stringToSearch, (suggestions) => {
      if (suggestions.length == 1) {
        selectAddress(suggestions[0])
      } else {
        setSearchData({ ...searchData, suggestions, showSuggestions: suggestions.length > 0 })
      }
    })
  }
  const getSuggestions = (address: string, callback: (suggestions: AddressSuggestion[]) => void): void => {
    searchData.cancel?.cancel()
    setSearchData({ ...searchData, cancel: Axios.CancelToken.source() })

    if (address.trim().length !== 0) {
      Axios.get(
        `${geoServiceUrl}/api/geocode/by/here/autocomplete?query=${encodeURIComponent(
          address
        )}&maxresults=10&language=${lang}`,
        { withCredentials: true, cancelToken: searchData.cancel?.token }
      ).then(
        (success) => {
          setSearchData({ ...searchData, cancel: undefined })
          const response: AddressSuggestionResponse = success.data
          const suggestions = response.suggestions

          return Promise.all(
            suggestions.map((suggestion) =>
              Axios.get(
                `${geoServiceUrl}/api/here/location/${encodeURIComponent(suggestion.locationId)}?language=${lang}`,
                { withCredentials: true }
              ).then((success) => {
                const locationResponse: LocationResponse = success.data
                const result: AddressSuggestion = {
                  ...suggestion,
                  address: resolveAddress(suggestion, locationResponse),
                  geoInformation: locationToGeoInformation(locationResponse),
                }

                return result
              })
            )
          ).then(
            (success) => callback(success),
            (error) => {
              if (!Axios.isCancel(error)) {
                callback([])
              }
            }
          )
        },
        (error) => {
          if (!Axios.isCancel(error)) {
            callback([])
          }
        }
      )
    }
  }

  const locationToGeoInformation = (locationResponse: LocationResponse): GeoInformation => {
    const location = locationResponse.response.view[0].result[0].location

    return {
      coordinates: {
        latitude: location.displayPosition.latitude,
        longitude: location.displayPosition.longitude,
      },
    }
  }

  const resolveAddress = (suggestion: AddressSuggestion, locationResponse: LocationResponse): HereAddress => {
    const location = locationResponse.response.view[0].result[0].location

    if (location.address) {
      const address = location.address
      const maybeCountry = address.additionalData && address.additionalData["CountryName"]

      return {
        ...address,
        country: maybeCountry || suggestion.address.country,
      }
    }

    return suggestion.address
  }

  const selectAddress = (suggestion: AddressSuggestion) => {
    searchData.cancel?.cancel()
    setSearchData({
      ...searchData,
      showSuggestions: false,
      center: proj.fromLonLat([
        suggestion.geoInformation.coordinates.longitude,
        suggestion.geoInformation.coordinates.latitude,
      ]),
      zoom: 16,
      manualCoords: false,
      droppedLocation: undefined,
    })
    setOptionSelected(true)
    setSearchTerm(getFormattedAddress(suggestion))
    setValue("address", suggestionToAddress(suggestion))
  }

  const suggestionToAddress = (s: AddressSuggestion): Address => {
    const lookupResult = isoCountry(s.countryCode)

    return {
      location: {
        lat: s.geoInformation.coordinates.latitude,
        lng: s.geoInformation.coordinates.longitude,
      },
      route: s.address.street ?? "",
      country: lookupResult ? lookupResult.code : "",
      locality: s.address.city ?? "",
      postalCode: s.address.postalCode ?? "",
      streetNumber: s.address.houseNumber ?? "",
    }
  }

  const updateSelectedAddressFromLocation = (location: Location, reverseAddresses: Address[]) => {
    if (reverseAddresses.length > 0) {
      setSearchData({
        ...searchData,
        manualCoords: true,
        droppedLocation: location,
        droppedLocationGeocodingLoading: false,
      })
      setSearchTerm(formatAddress(reverseAddresses[0]))
      setValue("address", { ...reverseAddresses[0], location })
      setOptionSelected(false)
    } else if (addressNotEmpty()) {
      setSearchData({
        ...searchData,
        manualCoords: false,
        droppedLocation: undefined,
        droppedLocationGeocodingLoading: false,
      })
      setValue("address", { ...watch("address"), location })
      setOptionSelected(false)
    }
  }
  const onSetPin = (location: Location) => {
    searchData.cancel?.cancel()
    setSearchData({ ...searchData, droppedLocation: location, droppedLocationGeocodingLoading: true })
    Axios.get(`${geoServiceUrl}/api/here/reverse?lng=${location.lng}&lat=${location.lat}&language=${lang}`, {
      withCredentials: true,
    }).then(
      (success) => updateSelectedAddressFromLocation(location, success.data),
      () => {}
    )
  }

  const onClose = () => {
    // clean last error
    cleanPoiCreateOrEditErrors()
    props.onClose()
  }

  const submit = async (poiData: POIToSubmit, categoryId: string, poiId?: string) => {
    setIsSubmitting(true)
    const poiToSubmit: PrivatePOIToCreateOrEdit = { poiData, categoryId, poiId }

    const isSuccessful = await (isEditMode() && "poiId" in poiToSubmit && poiToSubmit.poiId
      ? editPrivatePoi(poiToSubmit as PrivatePOIToEdit)
      : createPrivatePoi(poiToSubmit as PrivatePOIToCreate))

    setIsSubmitting(false)

    if (isSuccessful) {
      onClose()
    }
  }

  const onSubmit = async (data: POIFormData) => {
    if (!isSubmitting) {
      const poiDataToSend: POIToSubmit = {
        title: data.title.trim(),
        companyAssignedId: data.companyAssignedId.trim() === "" ? undefined : data.companyAssignedId.trim(),
        address: getTrimmedAddress(data),
        notes: data.notes.trim(),
        droppedLocation: !optionSelected && searchData.droppedLocation ? searchData.droppedLocation : undefined,
        structuredData: data.structuredData,
      }
      if (!isEditMode()) {
        trackUsageEvent("PRIVATE_DATA_POIS_ADD_ITEMS", null, `manual - ${props.selectedCategory.title} - 1`)
      }
      await submit(poiDataToSend, props.selectedCategory.id, props.poiToEdit?.id)
    }
  }

  const addressNotEmpty = () => {
    const address = watch("address")
    return address.route || address.locality || address.postalCode || address.country
  }
  const addressHasLocation = () => !!watch("address").location
  const isSubmitDisabled = () => {
    return !addressHasLocation() || showSetPinHint || addressOutsideOfGermany
  }

  return (
    <Dialog width="60vw" height="60vh" onClose={onClose} closeOnClickOutside closeButton>
      <Grid columns={2} padding={24} gap={16} height={[100, "%"]}>
        <Flex flexDirection="column" gap={8}>
          <h2>{isEditMode() ? t.privateData.poiCreateOrEdit.edit : t.privateData.poiCreateOrEdit.create}</h2>
          <AddressSuggestionAutoComplete
            {...register("address")}
            displaySuggestion={(s: AddressSuggestion) => s.address.label || s.label}
            label={t.privateData.poiCreateOrEdit.address}
            showSuggestions={searchData.showSuggestions && isEdited}
            suggestions={searchData.suggestions}
            value={searchTerm}
            onValueChange={(str) => {
              if (!isEdited) setIsEdited(true)
              setSearchTerm(str)
            }}
            selectSuggestion={(s) => s && selectAddress(s)}
            onFocus={() => {
              searchData.suggestions &&
                searchData.suggestions.length > 0 &&
                setSearchData({ ...searchData, showSuggestions: true })
            }}
            toDoOnBlur={() => setSearchData({ ...searchData, showSuggestions: false })}
            onForceSuggestion={onForceSelection}
            hint={t.privateData.poiCreateOrEdit.addressInputPlaceholder}
          />
          <TextField
            label={t.privateData.poiCreateOrEdit.title}
            {...register("title")}
            value={watch("title")}
            onValueChange={(text) => setValue("title", text)}
            hint={t.privateData.poiCreateOrEdit.optional}
          />
          <TextField
            label={t.privateData.poiCreateOrEdit.companyAssignedId}
            {...register("companyAssignedId")}
            value={watch("companyAssignedId")}
            onValueChange={(text) => setValue("companyAssignedId", text)}
            hint={t.privateData.poiCreateOrEdit.optional}
          />
          <TextArea
            label={t.privateData.poiCreateOrEdit.notes}
            {...register("notes")}
            value={watch("notes")}
            onValueChange={(text) => setValue("notes", text)}
            hint={t.privateData.poiCreateOrEdit.optional}
          />

          {showSetPinHint && <Panel color="neutral">{t.privateData.poiCreateOrEdit.setPinHint}</Panel>}
          {addressOutsideOfGermany && <Panel color="neutral">{t.privateData.poiCreateOrEdit.outsideOfGermany}</Panel>}

          {privatePOICreateOrEditError && getErrorSection(privatePOICreateOrEditError)}
          <FlexItem flexGrow={1} />
          <Flex flexDirection="row" flexGrow={0}>
            <Button
              type="secondary"
              onClick={(e) => {
                e.preventDefault()
                onClose()
              }}
            >
              {t.privateData.cancel}
            </Button>
            <FlexItem flexGrow={1} />
            <Button
              type="primary"
              icon="save"
              loading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              disabled={isSubmitDisabled()}
            >
              {isEditMode() ? t.privateData.save : t.privateData.add}
            </Button>
          </Flex>
        </Flex>
        <AddressGeoView
          address={currentlyDisplayedAddressLocation}
          onSetPin={searchData.droppedLocationGeocodingLoading ? undefined : onSetPin}
        />
      </Grid>
    </Dialog>
  )
}

export default CreateEditSinglePOIDialog
