import React, { useEffect, useState } from "react"
import DashboardWidget from "./dashboard-widget"
import { translations } from "../../i18n"
import { WmsLayer } from "../../../shared/models/wms-layer"
import { css, cx } from "emotion"
import { Location } from "../../models/address"
import Axios, { AxiosResponse } from "axios"
import { mapProxyUrl } from "../../../app_config"
import { DashboardEditMode } from "./dashboard-widget-grid"
import Icon from "../../../shared/components/icon"

interface Props {
  onHeaderClick: () => void
  onRemoveClick: () => void
  printMode: boolean
  editMode: DashboardEditMode
  markerLocation?: Location
  onRenderingDone: () => void
}

const styles = {
  noMaps: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  }),
  availableLayer: cx(
    css({
      padding: "4px",
      display: "flex",
      alignItems: "center",
      gap: "4px 12px",
    }),
    "single-line-with-ellipsis"
  ),
  contentStyle: css({
    padding: "4px 12px 0 4px",
    height: "100%",
  }),
}

const SpecialMapsWidget = ({
  markerLocation,
  onHeaderClick,
  onRemoveClick,
  printMode,
  editMode,
  onRenderingDone,
}: Props) => {
  const t = React.useMemo(translations, [translations])

  const [availableWMSLayers, setAvailableWMSLayers] = useState<WmsLayer[]>([])

  const [loadingWMSLayers, setLoadingWMSLayers] = useState(true)
  const availableLayers = markerLocation ? availableWMSLayers : []

  useEffect(() => {
    if (markerLocation) {
      Axios.get(`${mapProxyUrl}/api/maps`, {
        withCredentials: false,
      }).then(
        (success: AxiosResponse) => {
          const allWMSLayers: WmsLayer[] = success.data.filter((layer: WmsLayer) => layer.active)
          Axios.get(`${mapProxyUrl}/api/search/maps_by_location`, {
            params: { lat: markerLocation.lat, lng: markerLocation.lng },
            withCredentials: false,
          })
            .then(
              (success: AxiosResponse) => {
                const mapsInLocation = new Set(success.data.maps)
                setAvailableWMSLayers(allWMSLayers.filter((map) => mapsInLocation.has(map.id)))
                setLoadingWMSLayers(false)
              },
              () => setLoadingWMSLayers(false)
            )
            .finally(onRenderingDone)
        },
        () => {
          setLoadingWMSLayers(false)
        }
      )
    }
  }, [markerLocation])

  return (
    <DashboardWidget
      header={t.dashboard.specialMaps.header}
      onHeaderClick={onHeaderClick}
      onRemoveClick={onRemoveClick}
      printMode={printMode}
      editMode={editMode}
    >
      <div className={styles.contentStyle}>
        {!loadingWMSLayers && availableLayers.length === 0 && (
          <div className={styles.noMaps}>{t.dashboard.specialMaps.noMaps}</div>
        )}
        {!loadingWMSLayers &&
          availableLayers.length > 0 &&
          availableLayers.map((layer) => (
            <div className={styles.availableLayer} key={layer.id} title={t.pickTranslation(layer.name)}>
              <Icon name={"check"} color={"primary"} colorType={"default"} />{" "}
              <div className="single-line-with-ellipsis">{t.pickTranslation(layer.name)}</div>
            </div>
          ))}
      </div>
    </DashboardWidget>
  )
}

export default SpecialMapsWidget
