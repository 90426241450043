import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  GoogleMapsPOICategory,
  GoogleMapsThematicGroup,
  LOCAL_PUBLIC_TRANSPORT_CATEGORY,
  POIThematicGroupsType,
} from "../models/assessment"
import { translations } from "../../shared/i18n"
import Axios from "axios"
import { lanaApiUrl } from "../../app_config"
import { store } from "../../relas/store"
import { GenericError, toGenericError } from "../../shared/helper/axios"

export interface GoogleMapsDataState {
  poiCategoriesLoadInProgress: boolean
  poiCategoriesLoadError: GenericError | null

  poiCategories: Array<[string, Array<GoogleMapsPOICategory>]> | null
  poiThematicGroups: Array<[string, GoogleMapsThematicGroup]> | null
}

export const initialState: GoogleMapsDataState = {
  poiCategoriesLoadInProgress: false,
  poiCategoriesLoadError: null,
  poiCategories: null,
  poiThematicGroups: null,
}

const t = translations()

const googleMapsSlice = createSlice({
  name: "googleMaps",
  initialState,
  reducers: {
    loadGooglePOICategoriesStart(state) {
      state.poiCategoriesLoadInProgress = true
      state.poiCategoriesLoadError = null
    },
    loadGooglePOICategoriesError(state, action: PayloadAction<GenericError>) {
      state.poiCategoriesLoadInProgress = false
      state.poiCategoriesLoadError = action.payload
    },
    loadGooglePOICategoriesDone(
      state,
      action: PayloadAction<{
        categories: GoogleMapsPOICategory[] | null
        thematicGroups: POIThematicGroupsType | null
      }>
    ) {
      const categoriesByGroup = new Map<string, Array<GoogleMapsPOICategory>>()
      if (action.payload.categories) {
        for (const categoryItem of action.payload.categories) {
          const existing = categoriesByGroup.has(categoryItem.group)
          if (existing) categoriesByGroup.get(categoryItem.group)?.push(categoryItem)
          else categoriesByGroup.set(categoryItem.group, [categoryItem])
        }
      }
      for (const items of categoriesByGroup.values()) {
        items.sort((a, b) => t.pickTranslation(a.title).localeCompare(t.pickTranslation(b.title)))
      }
      const thematicGroupsSorted: Array<[string, GoogleMapsThematicGroup]> = Object.entries(
        action.payload.thematicGroups || {}
      )
        .sort(([, a], [, b]) => t.pickTranslation(a).localeCompare(t.pickTranslation(b)))
        .map(([k, v]) => [
          k,
          {
            title: v,
            groupName: k,
          },
        ])

      state.poiCategoriesLoadInProgress = false
      state.poiCategories = [...categoriesByGroup]
      state.poiThematicGroups = thematicGroupsSorted
    },
  },
})

const { loadGooglePOICategoriesStart, loadGooglePOICategoriesError, loadGooglePOICategoriesDone } =
  googleMapsSlice.actions

export async function fetchGoogleMapsCategories(): Promise<void> {
  store.dispatch(loadGooglePOICategoriesStart())
  try {
    const success = await Axios.get<{ categories: GoogleMapsPOICategory[]; thematic_groups: POIThematicGroupsType }>(
      `${lanaApiUrl}/api/v3/poi/categories`
    )
    const categories = success.data.categories
    categories.push(LOCAL_PUBLIC_TRANSPORT_CATEGORY)
    store.dispatch(
      loadGooglePOICategoriesDone({
        categories: categories,
        thematicGroups: success.data.thematic_groups,
      })
    )
  } catch (error) {
    store.dispatch(loadGooglePOICategoriesError(toGenericError(error)))
  }
}

export default googleMapsSlice.reducer
