import { store } from "../../relas/store"
import { AppModules } from "../../menu/util/app-location-types"
import Axios, { AxiosError, AxiosResponse } from "axios"
import { lanaApiUrl } from "../../app_config"
import { loadAssessmentForAssessmentModule } from "./comparables-slice"
import { NavigationAssessmentDetailsPageData, NavigationPage } from "../../shared/actions/navigation"
import {
  Assessment,
  AssessmentCreateSingle,
  AssessmentEntryFull,
  AssessmentEntryListViewMode,
  AssessmentPriceType,
  AssessmentState,
  AssessmentTrendData,
  AssessmentYieldData,
  ContextScoreValues,
  ObjectPrices,
  ObjectPricesParams,
  PriceRange,
  reconstructUsageType,
  UsageType,
  WidgetsType,
  YieldType,
} from "../models/assessment"
import { trackUsageEvent } from "../../utils/usage-tracking"
import { Address, Location } from "../models/address"
import { RentIndexParams, RentIndexQueryResult } from "../models/rentindex"
import { AssessmentEntryFormData } from "../components/assessment-entry-detail-edit"
import { DataSetType } from "../components/district-data"
import { Profile } from "../../profile/models/profile"
import { assessmentDispatchActions, AssessmentSortField, NotesIcon } from "./assessment-slice"
import { SelectionRef } from "../models/selection-ref"
import { navigateTo, navigateToAppPage, navigateToDashboardAppPage } from "../../shared/reducers/navigation-slice"
import { assessmentEntryChangedModuleAction } from "../actions/assessment-module-action-creators"
import { AssessmentDashboardId } from "../models/AssessmentDashboard"
import { HeaderSorting } from "../../shared/components/sortable-table-header"
import { toGenericError } from "../../shared/helper/axios"

export function fetchAssessmentList(module?: AppModules["locationAssessment"]): Promise<void> {
  const moduleName = module ? (`${module}List` as const) : "assessmentList"

  navigateTo({ name: moduleName, module: module })
  store.dispatch(assessmentDispatchActions.assessmentListStart())
  return Axios.get(`${lanaApiUrl}/api/assessments`).then(
    (success: AxiosResponse) => {
      store.dispatch(assessmentDispatchActions.assessmentListDone(success.data))
    },
    (error: AxiosError) => {
      store.dispatch(assessmentDispatchActions.assessmentListError(toGenericError(error)))
    }
  )
}

export function refreshAssessmentList(): Promise<void> {
  return Axios.get(`${lanaApiUrl}/api/assessments`).then(
    (success: AxiosResponse) => {
      store.dispatch(assessmentDispatchActions.assessmentListDone(success.data))
    },
    () => {}
  )
}

export function createSingleAssessment(
  create: AssessmentCreateSingle,
  module: AppModules["locationAssessment"],
  uiLocation: "quickstart" | "assessmentList"
): Promise<void> {
  store.dispatch(assessmentDispatchActions.assessmentCreateSingleStart())
  return Axios.post<Assessment>(`${lanaApiUrl}/api/assessments`, create).then(
    (success: AxiosResponse) => {
      store.dispatch(assessmentDispatchActions.assessmentCreateSingleDone(success.data))
      trackUsageEvent("CREATE_SINGLE_ASSESSMENT", create.address, uiLocation)

      if (module === "assessment") void loadAssessmentFirstEntry(success.data, true)
      else void openAssessmentModule(success.data.id, null, module)
    },
    (error: AxiosError) => {
      const mapped = toGenericError(error)
      if (error.response?.status === 424)
        store.dispatch(
          assessmentDispatchActions.assessmentCreateSingleError({
            ...mapped,
            error: "NoSmartdata",
          })
        )
      else store.dispatch(assessmentDispatchActions.assessmentCreateSingleError(mapped))
    }
  )
}

export function deleteAssessment(id: string, module: AppModules["locationAssessment"]): Promise<void> {
  return Axios.delete(`${lanaApiUrl}/api/assessments/${encodeURIComponent(id)}`).then(
    () => {
      void fetchAssessmentList(module)
    },
    (error: AxiosError) => {
      store.dispatch(assessmentDispatchActions.assessmentListError(toGenericError(error)))
    }
  )
}

export function renameAssessment(id: string, title: string): Promise<void> {
  store.dispatch(assessmentDispatchActions.updateAssessmentStart())

  return Axios.put(`${lanaApiUrl}/api/assessments/${encodeURIComponent(id)}/title`, JSON.stringify(title), {
    headers: { "Content-Type": "application/json" },
  }).then(
    (success: AxiosResponse) => {
      store.dispatch(assessmentDispatchActions.updateAssessmentDone(success.data))
    },
    (error: AxiosError) => {
      store.dispatch(assessmentDispatchActions.assessmentListError(toGenericError(error)))
    }
  )
}

export function setMacroContext(id: string, macroContext: string): Promise<void> {
  store.dispatch(assessmentDispatchActions.updateAssessmentStart())
  return Axios.put(
    `${lanaApiUrl}/api/assessments/${encodeURIComponent(id)}/macroContext`,
    JSON.stringify(macroContext === "" ? null : macroContext),
    { headers: { "Content-Type": "application/json" } }
  ).then(
    (success: AxiosResponse) => {
      store.dispatch(assessmentDispatchActions.updateAssessmentDone(success.data))
    },
    (error: AxiosError) => {
      store.dispatch(assessmentDispatchActions.assessmentListError(toGenericError(error)))
    }
  )
}

export function uploadAssessmentList(
  title: string,
  file: File,
  withSelectionManager: boolean,
  module: AppModules["locationAssessment"]
): Promise<void> {
  const formData = new FormData()

  formData.append("title", title)
  formData.append("file", file)

  store.dispatch(assessmentDispatchActions.assessmentCreateSingleError(null))

  return Axios.post(`${lanaApiUrl}/api/assessments`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  }).then(
    (success: AxiosResponse) => loadAssessmentEntries(success.data, module, withSelectionManager),
    (error: AxiosError<any>) => {
      let mapped = toGenericError(error)
      if (typeof error.response?.data.details === "object") {
        mapped = {
          ...mapped,
          ...error.response.data.details,
        }
      }
      store.dispatch(assessmentDispatchActions.assessmentCreateSingleError(mapped))
    }
  )
}

export function clearAssessmentErrors(): void {
  store.dispatch(assessmentDispatchActions.assessmentClearErrors())
}

export function loadAssessment(id: string): Promise<Assessment> {
  return Axios.get(`${lanaApiUrl}/api/assessments/${encodeURIComponent(id)}`).then(
    (success: AxiosResponse) => success.data,
    (error: AxiosError) => {
      store.dispatch(assessmentDispatchActions.assessmentLoadError(toGenericError(error)))
    }
  )
}

export function loadSelectionEntries(): Promise<void> {
  return Axios.get<SelectionRef[]>(`${lanaApiUrl}/api/selections`).then(
    (success) => {
      store.dispatch(assessmentDispatchActions.setAvailableSelectionsDone(success.data))
    },
    (error: AxiosError) => {
      store.dispatch(assessmentDispatchActions.assessmentLoadError(toGenericError(error)))
    }
  )
}

export function loadAssessmentEntries(
  assessment: Assessment,
  module: AppModules["locationAssessment"],
  loadSelections: boolean
): Promise<void> {
  navigateToAssessmentDetails(assessment, module)
  store.dispatch(assessmentDispatchActions.assessmentLoadStart())
  if (loadSelections) void loadSelectionEntries()
  return Axios.get(`${lanaApiUrl}/api/assessments/${encodeURIComponent(assessment.id)}/entries`).then(
    (success: AxiosResponse) => {
      store.dispatch(assessmentDispatchActions.assessmentLoadDone({ assessment, entries: success.data }))
      trackUsageEvent("APP_SWITCH_LOCATION_ASSESSMENT_ADDRESS_LIST", null, assessment.title)
    },
    (error: AxiosError) => {
      store.dispatch(assessmentDispatchActions.assessmentLoadError(toGenericError(error)))
    }
  )
}

export function setNotesIcon(notesIcon: NotesIcon): void {
  store.dispatch(assessmentDispatchActions.setNoteIconDone(notesIcon))
}

export function refreshAssessmentEntries(
  assessmentId: string,
  assessmentEntryId?: string,
  module?: AppModules["locationAssessment"] | null
): Promise<void> {
  return Axios.get(`${lanaApiUrl}/api/assessments/${encodeURIComponent(assessmentId)}`).then(
    (success: AxiosResponse) => {
      const assessment: Assessment = success.data
      return Axios.get(`${lanaApiUrl}/api/assessments/${encodeURIComponent(assessmentId)}/entries`).then(
        (success: AxiosResponse) => {
          store.dispatch(assessmentDispatchActions.assessmentLoadDone({ assessment, entries: success.data }))
          if (assessmentEntryId && module) {
            module === "dashboard"
              ? void loadAssessmentForAssessmentModule(assessmentId, assessmentEntryId ?? null, module)
              : void loadAssessmentFirstEntry(assessment, false)
          }
        },
        () => {}
      )
    },
    () => {}
  )
}

export function navigateToAssessmentDetails(assessment: Assessment, module: AppModules["locationAssessment"]): void {
  let navigationPage: NavigationAssessmentDetailsPageData["name"] = `${module}Details`
  navigateTo({
    name: navigationPage,
    params: {
      assessmentId: assessment.id,
    },
  })
}

export function loadAssessmentFirstEntry(
  assessment: Assessment,
  navigateToAssessmentEntryView: boolean
): Promise<void> {
  store.dispatch(assessmentDispatchActions.assessmentEntryLoadStart())
  const getTranslationsReq = Axios.get(`${lanaApiUrl}/api/v2/here/translations`).then(
    (success: AxiosResponse) => store.dispatch(assessmentDispatchActions.setPOICategoryTranslationsDone(success.data)),
    () => {}
  )

  const getEntryAndNavigateReq = Axios.get(`${lanaApiUrl}/api/profilegroups`).then(
    (success: AxiosResponse) => {
      const profileGroups = success.data
      Axios.get(`${lanaApiUrl}/api/assessments/${encodeURIComponent(assessment.id)}/firstEntry`).then(
        (success: AxiosResponse) => {
          store.dispatch(
            assessmentDispatchActions.assessmentEntryLoadDone({
              entry: { ...success.data, profileGroups: profileGroups },
            })
          )
          if (navigateToAssessmentEntryView) {
            navigateTo({
              name: NavigationPage.assessment,
              params: { assessmentId: assessment.id, entryId: success.data.id },
            })
          }
        },
        (error: AxiosError) => {
          store.dispatch(assessmentDispatchActions.assessmentEntryLoadError(toGenericError(error)))
        }
      )
    },
    () => {}
  )

  return Promise.all([getTranslationsReq, getEntryAndNavigateReq]).then(
    () => {},
    () => {}
  )
}

export async function openAssessmentModule(
  assessmentId: string,
  entryId: string | null,
  module: AppModules["locationAssessment"],
  pdf?: boolean,
  previousAssessmentId?: string,
  previousAssessmentEntryId?: string,
  extraParams?: AssessmentDashboardId
): Promise<void> {
  let result = await loadAssessmentForAssessmentModule(assessmentId, entryId, module)
  if (
    (previousAssessmentId && assessmentId !== previousAssessmentId) ||
    (previousAssessmentEntryId && entryId && previousAssessmentEntryId !== entryId)
  ) {
    store.dispatch(
      assessmentEntryChangedModuleAction({
        newId: assessmentId,
        oldId: previousAssessmentId,
      })
    )
  }
  if (result) {
    const [, entry] = result
    if (module === "dashboard") {
      // The dashboard could be printed as a PDF and should be rendered separately
      navigateToDashboardAppPage(assessmentId, entry.id, pdf, extraParams)
    } else {
      navigateToAppPage(assessmentId, entry.id, module)
    }
  }
}

export function navigateToAssessment(assessmentId: string, entryId: string, widget?: WidgetsType): void {
  navigateTo({
    name: NavigationPage.assessment,
    params: { assessmentId, entryId, widget },
  })
}

export function navigateToPrices(assessmentId: string, entryId: string, widget?: WidgetsType): void {
  navigateTo({
    name: NavigationPage.prices,
    params: { assessmentId, entryId, widget },
  })
}

export function loadAssessmentEntry(assessment: Assessment, entryId: string): Promise<void> {
  store.dispatch(assessmentDispatchActions.assessmentEntryLoadStart())
  if (typeof assessment.macroContext === "string") {
    void loadAssessmentEntryProfileScores(assessment, entryId, assessment.macroContext)
  }
  const getTranslationsReq = Axios.get(`${lanaApiUrl}/api/v2/here/translations`).then(
    (success: AxiosResponse) => store.dispatch(assessmentDispatchActions.setPOICategoryTranslationsDone(success.data)),
    () => {}
  )
  const getAssessmentEntryReq = Axios.get(`${lanaApiUrl}/api/profilegroups`).then(
    (success: AxiosResponse) => {
      const profileGroups = success.data
      Axios.get(
        `${lanaApiUrl}/api/assessments/${encodeURIComponent(assessment.id)}/entries/${encodeURIComponent(entryId)}`
      ).then(
        (success: AxiosResponse) => {
          store.dispatch(
            assessmentDispatchActions.assessmentEntryLoadDone({
              entry: { ...success.data, profileGroups: profileGroups },
            })
          )
        },
        (error: AxiosError) => {
          store.dispatch(assessmentDispatchActions.assessmentEntryLoadError(toGenericError(error)))
        }
      )
    },
    () => {}
  )

  return Promise.all([getTranslationsReq, getAssessmentEntryReq]).then(
    () => {},
    () => {}
  )
}

export function deleteAssessmentEntry(assessmentId: string, selectedAssessmentEntries: string[]): Promise<void> {
  return Axios.delete<number>(`${lanaApiUrl}/api/assessments/${assessmentId}/entries`, {
    data: {
      entries: selectedAssessmentEntries,
    },
  }).then(
    (response) => {
      void refreshAssessmentEntries(assessmentId)
    },
    (error: AxiosError) => {
      store.dispatch(assessmentDispatchActions.assessmentListError(toGenericError(error)))
    }
  )
}

export function loadAssessmentEntryProfileScores(
  assessment: Assessment,
  entryId: string,
  macroContext?: string
): Promise<void> {
  store.dispatch(assessmentDispatchActions.assessmentEntryProfileScoresLoadStart(macroContext))

  return Axios.get(
    `${lanaApiUrl}/api/assessments/${encodeURIComponent(assessment.id)}/entries/${encodeURIComponent(
      entryId
    )}/profileValues`,
    {
      params: {
        macroContext,
      },
    }
  ).then(
    (success: AxiosResponse) => {
      store.dispatch(assessmentDispatchActions.assessmentEntryProfileScoresLoadDone(success.data))
    },
    (error: AxiosError) => {
      store.dispatch(assessmentDispatchActions.assessmentEntryProfileScoresLoadError(toGenericError(error)))
    }
  )
}

export function loadAssessmentEntryContextScores(
  assessment: Assessment,
  entryId: string,
  scoreCategory: string,
  macroContext?: string
): Promise<void> {
  store.dispatch(assessmentDispatchActions.assessmentEntryContextScoresLoadStart())

  return Axios.get<ContextScoreValues>(
    `${lanaApiUrl}/api/assessments/${encodeURIComponent(assessment.id)}/entries/${encodeURIComponent(
      entryId
    )}/macroValues`,
    {
      params: {
        scoreCategory,
        macroContext,
      },
    }
  ).then(
    (success) => {
      store.dispatch(
        assessmentDispatchActions.assessmentEntryContextScoresLoadDone({
          ...success.data,
          scoreCategoryName: scoreCategory,
          macroContext,
        })
      )
    },
    (error: AxiosError) => {
      store.dispatch(assessmentDispatchActions.assessmentEntryContextScoresLoadError(toGenericError(error)))
    }
  )
}

export function updateAssessmentEntry(
  assessmentId: string,
  entryId: string,
  label: string,
  address: Address,
  droppedLocation: Location | null
): Promise<void> {
  store.dispatch(assessmentDispatchActions.assessmentEntryUpdateStart())

  return Axios.put(
    `${lanaApiUrl}/api/assessments/${encodeURIComponent(assessmentId)}/entries/${encodeURIComponent(entryId)}`,
    {
      label,
      address,
      droppedLocation,
    }
  ).then(
    (success: AxiosResponse) => {
      store.dispatch(assessmentDispatchActions.assessmentEntryUpdateDone(success.data))
    },
    (error: AxiosError) => {
      const mapped = toGenericError(error)
      if (error.response?.status === 424)
        store.dispatch(
          assessmentDispatchActions.assessmentEntryUpdateError({
            ...mapped,
            error: "NoSmartdata",
          })
        )
      else store.dispatch(assessmentDispatchActions.assessmentEntryUpdateError(mapped))
    }
  )
}

export function updateAssessmentEntryDetails(
  assessmentId: string,
  assessmentEntryId: string,
  details: AssessmentEntryFormData
): Promise<void> {
  store.dispatch(assessmentDispatchActions.assessmentEntryUpdateStart())

  const usageType = details.usageType ? reconstructUsageType(details.houseOrApartment, details.usageType) : undefined

  const payload = {
    usageType: usageType,
    buildingYear: details.buildingYear,
    newBuilding: details.newBuilding,
    area: details.area,
    exclusiveness: details.exclusiveness,
  }

  return Axios.put<AssessmentEntryFull>(
    `${lanaApiUrl}/api/assessments/${encodeURIComponent(assessmentId)}/entries/${encodeURIComponent(
      assessmentEntryId
    )}/details`,
    payload
  ).then(
    (success: AxiosResponse) => {
      store.dispatch(assessmentDispatchActions.assessmentEntryUpdateDone(success.data))
    },
    (error: AxiosError) => {
      const mapped = toGenericError(error)
      if (error.response?.status === 424)
        store.dispatch(
          assessmentDispatchActions.assessmentEntryUpdateError({
            ...mapped,
            error: "NoSmartdata",
          })
        )
      else store.dispatch(assessmentDispatchActions.assessmentEntryUpdateError(mapped))
    }
  )
}

export function updateAssessmentEntryTags(assessment: Assessment, entryId: string, tags: string[]): Promise<void> {
  return Axios.put(
    `${lanaApiUrl}/api/assessments/${encodeURIComponent(assessment.id)}/entries/${encodeURIComponent(entryId)}/tags`,
    {
      tags,
    }
  ).then(
    (success: AxiosResponse) => {
      store.dispatch(assessmentDispatchActions.assessmentEntryTagsUpdateDone(success.data))
    },
    (error: AxiosError) => {
      store.dispatch(assessmentDispatchActions.assessmentEntryTagsUpdateError(toGenericError(error)))
    }
  )
}

export function setAssessmentTagFilter(tagFilter: string | null): void {
  store.dispatch(assessmentDispatchActions.setTagFilterDone(tagFilter))
}

export function setAssessmentEntryListViewMode(viewMode: AssessmentEntryListViewMode): void {
  store.dispatch(assessmentDispatchActions.setEntryListViewModeDone(viewMode))
}

export function setAssessmentDetailsSearchTerm(searchTerm: string): void {
  store.dispatch(assessmentDispatchActions.setAssessmentDetailsSearchTermDone(searchTerm))
}

export function setAssessmentEntriesSorting(sorting: HeaderSorting<string>): void {
  store.dispatch(assessmentDispatchActions.setAssessmentEntriesSortingDone(sorting))
}

export function setAssessmentListSorting(sorting: HeaderSorting<AssessmentSortField>): void {
  store.dispatch(assessmentDispatchActions.setAssessmentListSortingDone(sorting))
}

export function setAssessmentStatus(status: AssessmentState): void {
  store.dispatch(assessmentDispatchActions.setAssessmentStatusDone(status))
}

export function resetAssessment(id: string): Promise<Assessment> {
  store.dispatch(assessmentDispatchActions.updateAssessmentStart())

  return Axios.put(`${lanaApiUrl}/api/assessments/${encodeURIComponent(id)}/reset`, {}).then(
    (success: AxiosResponse) => {
      store.dispatch(assessmentDispatchActions.updateAssessmentDone(success.data))
      return Promise.resolve(success.data)
    },
    (error: AxiosError) => {
      const mapped = toGenericError(error)
      store.dispatch(assessmentDispatchActions.assessmentListError(mapped))
      return Promise.reject(mapped)
    }
  )
}

export async function fetchMachineLearningUnitPrice(
  location: Location,
  cellId: number | undefined,
  usageType: UsageType,
  params: ObjectPricesParams
): Promise<PriceRange> {
  let url = `${lanaApiUrl}/api/v2/unit/prices`

  const response = await Axios.post<BulkPriceResponseData>(url, {
    requests: [
      {
        usage_type: usageType,
        cid: cellId,
        lon: location.lng,
        lat: location.lat,
        construction_year: params.constructionYear,
        area: params.area,
        class: params.class,
        new_building: params.newBuilding ? 1 : 0,
      },
    ],
  })

  return {
    min: response.data.results[0].price_min,
    avg: response.data.results[0].price,
    max: response.data.results[0].price_max,
  }
}

export type PriceResponseData = {
  price_min: number
  price_max: number
  price: number
}

type BulkPriceResponseData = {
  results: Array<PriceResponseData>
}

export async function loadObjectPrices(
  assessmentEntry: AssessmentEntryFull,
  params: ObjectPricesParams
): Promise<void> {
  store.dispatch(assessmentDispatchActions.objectPricesLoadStart(params))

  if (assessmentEntry.address.location === undefined) {
    store.dispatch(
      assessmentDispatchActions.objectPricesLoadError(toGenericError("no location to request object prices"))
    )
    return
  }

  const defaultRequest = {
    cid: assessmentEntry.cellId,
    lon: assessmentEntry.address.location?.lng,
    lat: assessmentEntry.address.location?.lat,
    construction_year: params.constructionYear,
    area: params.area,
    class: params.class,
    new_building: params.newBuilding ? 1 : 0,
    usage_type: assessmentEntry.usageType,
  }

  try {
    const success = await Axios.post<BulkPriceResponseData>(`${lanaApiUrl}/api/v2/unit/prices`, {
      requests: [
        { ...defaultRequest, usage_type: UsageType.Office },
        { ...defaultRequest, usage_type: UsageType.Retail },
        {
          ...defaultRequest,
          usage_type:
            params.houseOrApartment === "house" ? UsageType.ResidentialHouseRent : UsageType.ResidentialApartmentRent,
        },
        {
          ...defaultRequest,
          usage_type:
            params.houseOrApartment === "house" ? UsageType.ResidentialHouseSale : UsageType.ResidentialApartmentSale,
        },
        { ...defaultRequest, usage_type: UsageType.PlotSale },
        { ...defaultRequest, usage_type: UsageType.Logistics },
      ],
    })

    const prices: PriceRange[] = success.data.results.map((response) => {
      return {
        min: response.price_min,
        avg: response.price,
        max: response.price_max,
      }
    })

    const objectPrices: ObjectPrices = {
      office: prices[0],
      retail: prices[1],
      residentialRent: prices[2],
      residentialSale: prices[3],
      plotSale: prices[4],
      logistics: prices[5],
    }

    store.dispatch(assessmentDispatchActions.objectPricesLoadDone(objectPrices))
  } catch (errors) {
    store.dispatch(assessmentDispatchActions.objectPricesLoadError(toGenericError(errors)))
  }
}

export function loadAssessmentTrends(assessmentEntry: AssessmentEntryFull): Promise<AssessmentTrendData | void> {
  store.dispatch(assessmentDispatchActions.objectTrendsLoadStart())
  if (assessmentEntry.address.location === undefined) {
    store.dispatch(
      assessmentDispatchActions.objectTrendsLoadError(toGenericError("no location to request object prices"))
    )
    return Promise.resolve()
  }
  return Axios.post(`${lanaApiUrl}/api/v1/assessment_prices/trend_data`, {
    lon: assessmentEntry.address.location?.lng,
    lat: assessmentEntry.address.location?.lat,
  }).then(
    (success: AxiosResponse<AssessmentTrendData>) => {
      store.dispatch(assessmentDispatchActions.objectTrendsLoadDone(success.data))
      return Promise.resolve(success.data)
    },
    (error: AxiosError) => {
      const mapped = toGenericError(error)
      store.dispatch(assessmentDispatchActions.objectTrendsLoadError(mapped))
      return Promise.reject(mapped)
    }
  )
}

export function loadObjectRentIndex(address: Address, params: RentIndexParams): Promise<void> {
  store.dispatch(assessmentDispatchActions.objectRentIndexStart(params))

  return Axios.post(`${lanaApiUrl}/api/v1/rentindex/calculate`, {
    address,
    yearOfConstruction: params.yearOfConstruction,
    areas: [params.area],
    answers: {},
  }).then(
    (success: AxiosResponse<RentIndexQueryResult[]>) => {
      store.dispatch(assessmentDispatchActions.objectRentIndexDone(success.data[0]))
    },
    (error: AxiosError) => {
      store.dispatch(assessmentDispatchActions.objectRentIndexError(toGenericError(error)))
    }
  )
}

export function loadAssessmentYields(location?: Location): Promise<AssessmentYieldData | void> {
  store.dispatch(assessmentDispatchActions.objectYieldsLoadStart())
  if (location === undefined) {
    store.dispatch(
      assessmentDispatchActions.objectYieldsLoadError(toGenericError("no location to request object yields"))
    )
    return Promise.resolve()
  }

  return Axios.post(`${lanaApiUrl}/api/v1/assessment_prices/yields_data`, {
    lon: location?.lng,
    lat: location?.lat,
  }).then(
    (success: AxiosResponse<AssessmentYieldData>) => {
      store.dispatch(
        assessmentDispatchActions.objectYieldsLoadDone({
          cell_long_yearly:
            success.data.cell_long_yearly !== undefined
              ? success.data.cell_long_yearly.filter(
                  (item) => !!item.yield_office && !!item.yield_retail && !!item.yield_residential
                )
              : [],
          ags_long_yearly:
            success.data.ags_long_yearly !== undefined
              ? success.data.ags_long_yearly.filter(
                  (item) => !!item.yield_ags_office && !!item.yield_ags_retail && !!item.yield_ags_residential
                )
              : [],
        })
      )
      return success.data
    },
    (error: AxiosError) => {
      const mapped = toGenericError(error)
      store.dispatch(assessmentDispatchActions.objectYieldsLoadError(mapped))
      return Promise.reject(mapped)
    }
  )
}

export function setAssessmentSelectedPriceType(priceType: AssessmentPriceType): void {
  store.dispatch(assessmentDispatchActions.setSelectedPriceTypeDone(priceType))
}

export function setAssessmentSelectedYieldType(yieldType: YieldType): void {
  store.dispatch(assessmentDispatchActions.setSelectedYieldTypeDone(yieldType))
}

export function setAssessmentSelectedViewMode(viewMode: DataSetType): void {
  store.dispatch(assessmentDispatchActions.setSelectedViewModeDone(viewMode))
}

export function setSelectedProfile(profile?: Profile): void {
  store.dispatch(assessmentDispatchActions.setSelectedProfileDone(profile))
}

export function setAssessmentObjectPricesParams(params: ObjectPricesParams | null): void {
  store.dispatch(assessmentDispatchActions.setObjectPricesParams(params))
}

export function setAssessmentRentIndexParams(params: RentIndexParams | null): void {
  store.dispatch(assessmentDispatchActions.setRentIndexParams(params))
}
