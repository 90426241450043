import { FlexItem } from "../../../shared/components/ui/flex-item"
import React, { useEffect, useMemo } from "react"
import { translations } from "../../i18n"
import { useAppDispatch } from "../../../relas/store"
import { doUpdateRating } from "../../rating-manager-actions"
import { useForm, useFormState } from "react-hook-form"
import { css } from "emotion"
import { RatingGrade } from "../../../shared/models/rating-grade"
import { nonEmptyTrimmed } from "../../../shared/models/form-validators"
import { RatingDetail } from "../../../explorer/models/rating"
import Select from "../../../shared/components/select"
import Button from "../../../shared/components/button"
import TextField from "../../../shared/components/textfield"

interface Props {
  ruleIdx: number
  onClose: (idx: number) => void
  grades: RatingGrade[]
  currentRating: RatingDetail
}

type FormInputs = {
  title: string
  gradeIdx: number
}

const styles = {
  formContainer: css({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    gap: 8,
    padding: 16,
  }),
  rightAlignedContent: css({
    textAlign: "right",
  }),
}

export const RatingManagerRatingSelectionEdit: React.FC<Props> = ({ onClose, ruleIdx, grades, currentRating }) => {
  const t = useMemo(translations, [translations])

  const dispatch = useAppDispatch()

  const ratingRule = useMemo(() => currentRating?.rules[ruleIdx], [currentRating, ruleIdx])

  const { control, reset, watch, register, handleSubmit, setValue } = useForm<FormInputs>({
    defaultValues: defaultData(),
    mode: "all",
  })

  const { isSubmitting, isValid } = useFormState({ control })

  useEffect(() => {
    reset(defaultData())
  }, [ruleIdx, currentRating])

  function defaultData(): FormInputs {
    return {
      title: ratingRule?.title ?? "",
      gradeIdx: ratingRule?.gradeIdx ?? 0,
    }
  }

  async function onSubmit(data: FormInputs) {
    if (!currentRating) return

    const ratingRule = {
      ...currentRating.rules[ruleIdx],
      title: data.title.trim(),
      gradeIdx: data.gradeIdx,
    }

    await doUpdateRating(dispatch)({
      ...currentRating,
      rules: [...currentRating.rules.slice(0, ruleIdx), ratingRule, ...currentRating.rules.slice(ruleIdx + 1)],
    })

    onClose(ruleIdx + 1)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.formContainer}>
      <div className={styles.rightAlignedContent}>
        <Button type="tertiary" icon="close" onClick={() => onClose(-1)} />
      </div>
      <h1>{t.selectionRuleEdit.editSelectionTitle}</h1>
      <TextField
        {...register("title", { validate: nonEmptyTrimmed })}
        minLength={1}
        onValueChange={(str, isValid) => isValid && setValue("title", str, { shouldValidate: true })}
        value={watch("title")}
        label={t.createForm.titleField}
      />
      <Select
        {...register("gradeIdx")}
        onValueChange={(value) => setValue("gradeIdx", value, { shouldValidate: true })}
        value={watch("gradeIdx")}
        label={t.editForm.gradeField}
        options={grades.map((grade, idx) => ({ label: grade.label, value: idx }))}
      />
      <FlexItem flexGrow={1} />
      <div className={styles.rightAlignedContent}>
        <Button type="primary" disabled={!isValid} loading={isSubmitting}>
          {t.ok}
        </Button>
      </div>
    </form>
  )
}
