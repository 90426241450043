import * as React from "react"
import { useEffect, useMemo } from "react"
import { translations } from "../../i18n"
import { useAppSelector } from "../../../relas/store"
import { isMetaRating, MetaRating } from "../../../shared/models/ratings"
import { RatingDetail } from "../../../explorer/models/rating"
import { ExplorerRatingGradeLabel } from "../../../shared/components/explorer-rating-grade-label"
import { COLORS } from "../../../shared/components/ui/colors"
import { Flex } from "../../../shared/components/ui/flex"
import { css } from "emotion"
import { MetaRatingResultDetail, SelectionFact } from "../../../assessment/models/assessment"
import { ruleTitle } from "../../../shared/i18n/rule"
import { getRuleCriteria } from "../../rating-manager-common"
import { getFieldOptions } from "../../../shared/models/field-option"
import { language } from "../../../shared/i18n/language"
import { formatNumber } from "../../../shared/helper/number-format"
import { isNumber } from "../../../shared/helper/utils"

const styles = {
  container: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    lineHeight: "1.5em",
    p: {
      lineHeight: "1.5em",
    },
    ".selected-rating": {
      outline: `1px solid black`,
    },
    ".selected-rating-in-text": {
      position: "relative",
      top: "2px",
    },
  }),
  ratingTitle: css({
    fontWeight: "bold",
    fontSize: "16px",
    borderBottom: "1px solid",
    borderColor: COLORS.border.default,
    backgroundColor: COLORS.background.light,
    cursor: "pointer",
    display: "flex",
    padding: "16px",
    alignItems: "center",
  }),
  ratingContent: css({
    padding: "0 16px",
  }),
  nothingSelectedLabel: css({
    textAlign: "center",
    marginTop: "16px",
  }),
  explanationContainer: css({
    div: {
      display: "inline-block",
    },
  }),
  factsTable: css({
    width: "100%",
    borderCollapse: "collapse",
    thead: {
      backgroundColor: COLORS.background.light,
    },
    "td, th": {
      border: "1px solid",
      borderColor: COLORS.border.default,
      padding: "4px",
    },
    "tr:nth-child(even)": {
      backgroundColor: COLORS.background.lighter,
    },
  }),
  emptyCells: css({
    "td:first-child": {
      border: "none",
    },
    backgroundColor: "transparent !important",
  }),
  noRating: css({
    textAlign: "center",
    marginTop: "90px",
  }),
}

export const RatingDetailsExplanation = () => {
  const t = useMemo(translations, [translations])

  const { currentRating, assessmentEntry } = useAppSelector((state) => ({
    currentRating: state.ratingManager.currentRating,
    assessmentEntry: state.assessment.currentAssessmentEntry,
  }))

  const [renderedFacts, setRenderedFacts] = React.useState<JSX.Element[]>([])

  const noRatingDiv = useMemo(() => <div className={styles.noRating}>{t.ratingExplanation.noResult}</div>, [])

  useEffect(() => {
    const inner = async () => {
      if (currentRating === undefined || assessmentEntry === undefined) {
        setRenderedFacts([])
        return
      }

      if (isMetaRating(currentRating)) {
        const metaRatingResult = assessmentEntry?.metaRatingResults[currentRating?.id ?? ""]
        const result = [...(metaRatingResult?.details ?? [])]
          .sort((a, b) => {
            const dataSetCompare = (a.dataSetType ?? "").localeCompare(b.dataSetType ?? "")
            if (dataSetCompare !== 0) {
              return dataSetCompare
            }
            return a.ratingName.localeCompare(b.ratingName)
          })
          .map(renderMetaRatingDetail)
        setRenderedFacts(result)
      } else {
        const ratingResult = assessmentEntry?.ratingResults[currentRating?.id ?? ""]
        const result = (ratingResult ? await Promise.all(ratingResult.facts.map(renderSelectionFact)) : []).filter(
          (r): r is JSX.Element => r !== undefined
        )

        setRenderedFacts(result)
      }
    }
    void inner()
  }, [currentRating, assessmentEntry])

  function renderAvailableGrades(rating: MetaRating | RatingDetail) {
    if (isMetaRating(rating)) {
      return null
    }

    const resultRatingLabel = assessmentEntry?.ratingResults[rating?.id ?? ""]?.grade.label

    return (
      <div>
        <div>{t.availableGrades}</div>
        <Flex flexDirection={"row"} gap={8} padding={[8, 0, 24, 0]}>
          {rating.grades.map((g, id) => (
            <ExplorerRatingGradeLabel
              key={`grade-explanation-${id}`}
              grade={g}
              additionalClass={resultRatingLabel === g.label ? "selected-rating" : undefined}
            />
          ))}
        </Flex>
      </div>
    )
  }

  function renderMetaRatingDetail(detail: MetaRatingResultDetail, index: number) {
    return (
      <tr key={`${detail.dataSetType}-${index}`}>
        <td>{detail.ratingName}</td>
        <td>{detail.dataSetType ? t.smartdataSourceTranslation(detail.dataSetType) : ""}</td>
        <td>{detail.grade.value.toLocaleString(language())}</td>
        <td>{detail.weight.toLocaleString(language())}</td>
        <td>{formatNumber(detail.weight * detail.grade.value, 2)}</td>
      </tr>
    )
  }

  async function renderSelectionFact(fact: SelectionFact, id: number) {
    if (!currentRating || isMetaRating(currentRating)) {
      return <></>
    } else {
      const ruleCriteria = await getRuleCriteria(fact.rule, "macro", t, fact.additionalName)

      let value: string | undefined
      switch (fact.rule.type) {
        case "loadmacrodata":
          value = t.country[fact.value?.toString() ?? ""]
          break
        case "loadmicrodata":
          return undefined
        case "oneoffilter":
          const oneOfOpts = await getFieldOptions(currentRating.dataSetType, fact.rule.field)
          value = oneOfOpts.find((option) => option.key === fact.value)?.label || ""
          break
        case "selectranks":
          value = undefined
          break
        default:
          value = isNumber(fact.value) ? formatNumber(fact.value, 2) : fact.value?.toString()
      }

      return (
        <tr key={`selection-fact-${id}`}>
          <td>{ruleTitle(t, fact.rule)}</td>
          <td>{ruleCriteria}</td>
          <td>{value}</td>
        </tr>
      )
    }
  }

  function renderRatingExplanation(rating: MetaRating | RatingDetail) {
    if (isMetaRating(rating)) {
      const metaResults = assessmentEntry?.metaRatingResults[rating.id]

      if (metaResults?.result === undefined) {
        return noRatingDiv
      }

      return (
        <>
          <div>{t.ratingExplanation.metaText}</div>
          <div>
            <table className={styles.factsTable}>
              <thead>
                <tr>
                  <th>{t.rating}</th>
                  <th>{t.createForm.typeField}</th>
                  <th>{t.ratingGradeLabel}</th>
                  <th>{t.weight}</th>
                  <th>{t.ratingExplanation.resultLabel}</th>
                </tr>
              </thead>
              <tbody>{renderedFacts}</tbody>
              <tfoot>
                <tr>
                  <td colSpan={4}>
                    <b>{t.ratingExplanation.resultLabel}</b>
                  </td>
                  <td style={{ backgroundColor: metaResults?.range?.color }}>
                    {metaResults ? formatNumber(metaResults.result, 2) : ""}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </>
      )
    } else {
      const ratingResult = assessmentEntry?.ratingResults[rating.id]
      if (ratingResult === undefined) {
        return noRatingDiv
      }
      const grade = ratingResult.grade

      return (
        <div className={styles.explanationContainer}>
          <div>
            <span>{renderedFacts.length > 0 ? t.ratingExplanation.textHead : t.ratingExplanation.noFactsHead}</span>
            &nbsp;
            <ExplorerRatingGradeLabel grade={grade} additionalClass={"selected-rating selected-rating-in-text"} />
            &nbsp;
            <span>{renderedFacts.length > 0 ? t.ratingExplanation.textTail : t.ratingExplanation.noFactsTail}</span>
          </div>
          {renderedFacts.length > 0 && (
            <table className={styles.factsTable}>
              <thead>
                <tr>
                  <th>{t.ratingExplanation.ruleHeader}</th>
                  <th>{t.ratingExplanation.detailsHeader}</th>
                  <th>{t.ratingExplanation.valueHeader}</th>
                </tr>
              </thead>
              <tbody>{renderedFacts}</tbody>
            </table>
          )}
        </div>
      )
    }
  }

  return (
    <div className={styles.container}>
      {!currentRating && <div className={styles.nothingSelectedLabel}>{t.nothingSelected}</div>}
      {currentRating && (
        <>
          <div className={styles.ratingTitle}>
            {isMetaRating(currentRating) ? "Meta" : currentRating.dataSetType === "macro" ? "Macro" : "Micro"}{" "}
            {t.rating} '{currentRating.name}'
          </div>
          <div className={styles.ratingContent}>
            {renderAvailableGrades(currentRating)}
            {renderRatingExplanation(currentRating)}
          </div>
        </>
      )}
    </div>
  )
}
