import { Flex } from "../../../../shared/components/ui/flex"
import { Grid } from "../../../../shared/components/ui/grid"
import * as React from "react"
import { useMemo, useState } from "react"
import { DistributionGraphResult, DistributionPercentiles } from "../../../../generated-apis/comparables-service"
import { ComparablesDistributionGraph } from "./comparables-distribution-graph"
import { translations } from "../../../i18n"
import { ComparablesDistributionTable } from "./comparables-distribution-table"
import {
  analysisItemClass,
  chartTableSwitchStyle,
  chartTitleStyle,
  comparablesAnalysisContentStyle,
  dropdownIconProps,
  infoMessage,
  settingsItemLabelStyle,
  ShowAnnotationOption,
} from "./comparables-analysis-common"
import { HTMLSelect, Popover } from "@blueprintjs/core"
import { css, cx } from "emotion"
import ButtonTabs from "../../../../shared/components/buttontabs"
import Icon from "../../../../shared/components/icon"

type Tabs = "chart" | "table"

interface ComparablesDistributionProps {
  distributionData: DistributionGraphResult | null
  title: string
  bucketToLabel?: (bucketValue: number) => string
  bucketSuffix?: string
  showAnnotations: ShowAnnotationOption
  isRangeBuckets?: boolean
  notEnoughOffers: boolean
  info?: string
}

const defaultBucketToLabel = (bucketValue: number) => (Math.round(100 * bucketValue) / 100).toString()

export const ComparablesDistribution = ({
  distributionData,
  bucketToLabel = defaultBucketToLabel,
  bucketSuffix = "",
  title,
  showAnnotations,
  isRangeBuckets = true,
  notEnoughOffers,
  info,
}: ComparablesDistributionProps) => {
  const [selectedTab, setSelectedTab] = useState<Tabs>(notEnoughOffers ? "table" : "chart")

  const [middleParameter, setMiddleParameter] = useState<keyof DistributionPercentiles>("median")

  const translate = useMemo(() => {
    return translations().comparablesGraphs
  }, [])

  function doChangeMiddleParameter(p: string) {
    if (p === "median") {
      setMiddleParameter("median")
    } else {
      setMiddleParameter("average")
    }
  }

  React.useEffect(() => {
    if (notEnoughOffers) setSelectedTab("table")
  }, [notEnoughOffers])

  return (
    <div className={analysisItemClass}>
      <Flex flexDirection={"row"} alignItems={"center"} gap={20} padding={[0, 0, 8, 0]}>
        <span className={chartTitleStyle}>{title}</span>
        <div className={chartTableSwitchStyle}>
          <ButtonTabs
            values={["chart", "table"]}
            selected={selectedTab}
            onSelect={(t) => setSelectedTab(t)}
            translate={(t) => translate[t]}
          />
        </div>
        {showAnnotations != "off" && (
          <Popover usePortal={false} className={css({ display: "flex", position: "relative" })}>
            <div style={{ cursor: "pointer", display: "flex" }}>
              <Icon name={"settings_options"} colorType={"default"} color={"primary"} fontSize={20} />
            </div>

            <Grid columns={1} padding={16} rowGap={8} width={[200, "px"]}>
              <span className={settingsItemLabelStyle}>{translate.meanValues}</span>
              <HTMLSelect
                fill
                iconProps={dropdownIconProps}
                style={{ backgroundColor: "transparent" }}
                value={middleParameter}
                options={[
                  { value: "median", label: translate.median },
                  { value: "average", label: translate.average },
                ]}
                onChange={(e) => doChangeMiddleParameter(e.currentTarget.value)}
              />
            </Grid>
          </Popover>
        )}
      </Flex>

      {!!distributionData?.count && selectedTab === "table" && (
        <div className={comparablesAnalysisContentStyle}>
          <ComparablesDistributionTable
            distributionData={distributionData}
            bucketSuffix={bucketSuffix}
            bucketToLabel={bucketToLabel}
            isRangeBuckets={isRangeBuckets}
          />
        </div>
      )}

      {!!distributionData?.count && !notEnoughOffers && selectedTab === "chart" && (
        <div className={comparablesAnalysisContentStyle}>
          <ComparablesDistributionGraph
            distributionData={distributionData}
            bucketSuffix={bucketSuffix}
            bucketToLabel={bucketToLabel}
            middleParameter={middleParameter}
            showAnnotations={showAnnotations}
            isRangeBuckets={isRangeBuckets}
          />
        </div>
      )}

      {(!distributionData?.count || (notEnoughOffers && selectedTab === "chart")) && (
        <div className={comparablesAnalysisContentStyle}>
          <svg width="100%" height="250">
            <rect width="100%" height="250" rx="5" ry="5" fill="#eee" />
          </svg>
        </div>
      )}
      {info && (
        <div className={notEnoughOffers ? cx(infoMessage, css({ paddingBottom: "40px" })) : infoMessage}>{info}</div>
      )}
    </div>
  )
}
