import * as React from "react"
import { bind } from "decko"
import * as layer from "ol/layer"
import * as source from "ol/source"
import { StyleFunction } from "ol/style/Style"
import * as extend from "ol/extent"
import * as style from "ol/style"
import { fromLonLat, transformExtent } from "ol/proj"
import { Flex } from "../../../shared/components/ui/flex"
import { PopupFact } from "../../../shared/components/ui/map-popup"
import { getInitialMapStyle } from "../../../shared/components/map-style-control"
import { fetchMunicipalityViewport } from "../../../shared/actions/map-actions"
import { mapStyles } from "./rating-manager-map-styles"
import { MunicipalityAndFocus } from "../../rating-manager-slice"
import { PrivatePOIList } from "../../../private-data/models/private-data"
import * as ol from "ol"
import { MapViewProps, MapViewState, RatingManagerMapView } from "./rating-manager-map-view"
import { municipalityVectorTileOptions } from "../../../utils/openlayers"
import Toggle from "../../../shared/components/toggle"

interface RatingManagerMapMacroProps extends MapViewProps {
  showMunicipalities: boolean
  onShowMunicipalities: (show: boolean) => void
  onSetSelectedMunicipalityId: (id: string | null) => void
  selectedMunicipalityAndFocus: MunicipalityAndFocus | null
  selectedRatingId: string | null
  hideMunicipalityListToggle?: boolean
}

const shapeFitOpts = { padding: [50, 50, 50, 50] }

// This should be transformed to a company default country extent and be fetched dynamically
const GERMANY_EXTENT: extend.Extent = [5.87, 47.27, 15.03, 55.06]

export class RatingManagerMapMacroView extends RatingManagerMapView<RatingManagerMapMacroProps, MapViewState> {
  protected override locationPresentZoomLevel = 9

  constructor(props: RatingManagerMapMacroProps) {
    super(props)

    this.state = {
      selectedMapStyle: getInitialMapStyle(props.initialMapStyle),
      showPrivatePoisPopover: false,
      poiPopUpPosition: undefined,
    }
  }

  componentDidMount() {
    super.componentDidMount()

    this.contentLayer = new layer.VectorTile({
      declutter: true,
      source: new source.VectorTile(municipalityVectorTileOptions()),
      style: this.municipalityShapeMacroStyle(),
    })
    this.getMap().addLayer(this.contentLayer)
    this.getMap()
      .getView()
      .setCenter(fromLonLat([9.75, 51.47]))

    this.getMap()?.getView().fit(transformExtent(GERMANY_EXTENT, "EPSG:4326", "EPSG:3857"), shapeFitOpts)
  }

  componentDidUpdate(prevProps: RatingManagerMapMacroProps, prevState: MapViewState): void {
    this.contentLayer?.changed()

    if (this.props.selectedMunicipalityAndFocus !== prevProps.selectedMunicipalityAndFocus) {
      void this.resetMap()
    }

    if (prevProps.selectedRatingId !== this.props.selectedRatingId) {
      this.overlay?.closeCurrentOverlay(true)
    }

    this.contentLayer?.setVisible(this.props.mapErrorMessage !== "shape_outdated")

    super.componentDidUpdate(prevProps, prevState)
  }

  @bind
  protected override onMapClick(event: ol.MapBrowserEvent<any>): void {
    const features = this.getMap().getFeaturesAtPixel(event.pixel, {
      layerFilter: (layer) => this.isClickableLayers(layer),
    })

    if (features.length > 0 && features[0].getProperties().features?.[0]?.getId().includes("category:")) {
      this.overlay?.closeCurrentOverlay(true)
      const featuresArray: ol.Feature[] = features[0].getProperties().features
      const geometry = features[0].getProperties().geometry

      const categoryId = featuresArray[0].getId()?.toString().split(":")[1] ?? ""
      const poiIdsToShow: string[] = featuresArray.map((feature) => feature.getId()?.toString().split(":")[2] ?? "")
      const poisToShow: PrivatePOIList = (
        this.props.privateDataSettings.multipleCategoryPOIList[categoryId] ?? []
      ).filter((poi) => poiIdsToShow.includes(poi.id))
      const position = geometry.getCoordinates()
      this.setState({ ...this.state, poiPopUpPosition: position })
      this.props.updatePrivatePoisToShow(poisToShow, this.props.module)
    } else {
      if (this.state.poiPopUpPosition) this.onPoiPopUpClose()
    }
  }

  protected fillAdditionalItemsForOverlay(item: RatingManagerMapItem, additionalItems: PopupFact[]): void {
    item.data.forEach((d) => additionalItems.push(d))
  }

  @bind
  protected onSelection(id: string | null) {
    if (this.props.locationToPresent) {
      return
    }
    this.props.onSetSelectedMunicipalityId(id)
    this.contentLayer?.changed()
  }

  @bind
  private municipalityShapeMacroStyle(): StyleFunction {
    return (feature) => {
      const macroItem = this.props.items.get(feature.getId()?.toString() ?? "")
      if (!macroItem) return new style.Style()
      const selected =
        this.props.selectedMunicipalityAndFocus &&
        this.props.selectedMunicipalityAndFocus.selectedMunicipalityId === feature.getId()?.toString()

      return new style.Style({
        stroke: new style.Stroke({
          color: selected ? "rgba(255,0,0,0.8)" : "rgba(0,0,255,0.8)",
          width: selected ? 2.0 : 0.5,
        }),
        fill: new style.Fill({
          color: macroItem.color,
        }),
      })
    }
  }

  @bind
  protected async resetMap(): Promise<void> {
    if (this.props.selectedMunicipalityAndFocus && !this.props.selectedMunicipalityAndFocus.focusInMap) {
      return
    }
    const viewport =
      (this.props.selectedMunicipalityAndFocus?.focusInMap
        ? await fetchMunicipalityViewport(this.props.selectedMunicipalityAndFocus.selectedMunicipalityId)
        : undefined) ?? GERMANY_EXTENT

    this.getMap()?.getView().fit(transformExtent(viewport, "EPSG:4326", "EPSG:3857"), shapeFitOpts)

    this.overlay?.closeCurrentOverlay(true)
  }

  render() {
    const { showMunicipalities, onShowMunicipalities } = this.props

    return (
      <div className={mapStyles.containerClass}>
        {super.render()}

        {!this.props.hideMunicipalityListToggle && (
          <div className={mapStyles.showListContainerClass}>
            <Flex flexDirection="row" alignItems="center" gap={16}>
              <Toggle label={this.t.showListToggle} checked={showMunicipalities} onChange={onShowMunicipalities} />
              {this.props.hasPreview && (
                <Toggle label={this.t.preview} checked={this.props.preview} onChange={this.props.onPreviewToggle} />
              )}
            </Flex>
          </div>
        )}

        {this.mapLoadingMessage()}
        {this.mapRecalculationMessage()}
      </div>
    )
  }
}
