import { AssessmentPriceType } from "../models/assessment"
import * as React from "react"
import { useMemo } from "react"
import { translations } from "../i18n"
import { GraphScale } from "../models/graph-scale"
import { BarGraph } from "./assessment-entry-bar-graph"
import { useSelector } from "react-redux"
import { State } from "../reducers/state"
import { COLORS } from "../../shared/components/ui/colors"
import { valuesOfPriceRange } from "../../utils/utils"
import { UnitSettingsForm } from "../../prices/components/unit-settings-v2"

interface Props {
  priceType: AssessmentPriceType
  color?: string
  colorLabel?: boolean
}

export const scaleForWidth = (allPriceValues: number[], graphOffset: number = 24) => {
  return (newGraphWidth: number) => {
    return new GraphScale(allPriceValues, graphOffset, newGraphWidth - 2 * graphOffset)
  }
}

export const PriceGraphs = ({ priceType, color, colorLabel }: Props) => {
  const translate = useMemo(translations, [translations]).assessmentEntryDetails
  const agsPrices = useSelector((state: State) => state.assessment.currentAssessmentEntry?.agsPrices[priceType])
  const cellPrices = useSelector((state: State) => state.assessment.currentAssessmentEntry?.cellPrices[priceType])
  const objectPrices = useSelector((state: State) => state.assessment.currentObjectPrices)

  const priceParams: UnitSettingsForm | undefined = useSelector(
    (state: State) => state.assessment?.objectPricesParams || undefined
  )

  const selectedObjectPrices = () => {
    switch (priceType) {
      case "office":
        return objectPrices?.office
      case "retail":
        return objectPrices?.retail
      case "residentialRent":
        return objectPrices?.residentialRent
      case "residentialSale":
        return objectPrices?.residentialSale
      case "plotSale":
        return objectPrices?.plotSale
      case "hall":
      case "logistics":
        return objectPrices?.logistics
    }
  }

  const allPriceValues: number[] = [
    ...valuesOfPriceRange(agsPrices),
    ...valuesOfPriceRange(cellPrices),
    ...valuesOfPriceRange(selectedObjectPrices()),
  ]

  const scalChart = scaleForWidth(allPriceValues)

  return (
    <div style={{ display: "grid", gap: "30px", alignContent: "center" }}>
      {agsPrices && (
        <BarGraph
          label={translate.agsPriceLabel}
          color={color || COLORS.background.dark}
          data={agsPrices}
          labelWidth={"120px"}
          scaleForWidth={scalChart}
          colorLabel={colorLabel}
          perSqmOrTotal={priceParams?.perSqmOrTotal}
          area={priceParams?.area}
        />
      )}
      {!agsPrices && priceType === "plotSale" && (
        <div style={{ marginBottom: "1em" }}>{translate.plotPricesRecalculate}</div>
      )}

      {priceType !== "plotSale" && priceType !== "logistics" && (
        <BarGraph
          label={translate.cellPriceLabel}
          color={color || COLORS.secondary1.dark}
          data={cellPrices}
          labelWidth={"120px"}
          scaleForWidth={scalChart}
          colorLabel={colorLabel}
          perSqmOrTotal={priceParams?.perSqmOrTotal}
          area={priceParams?.area}
        />
      )}
    </div>
  )
}
