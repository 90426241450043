import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { DataSetType, MainTabType, ViewportSettingsType } from "../components/district-data"
import { IsochroneType } from "../components/isochrone-type"
import {
  ASSESSMENT_ENTRY_CHANGED_MODULE,
  AssessmentEntryChangedPayload,
} from "../actions/assessment-module-action-creators"
import { ReferenceAreaComparisonType, SelectionAreaType } from "../models/market-data"
import { store } from "../../relas/store"

export type MunicipalitiesAreaSelection = {
  kind: "Municipality"
  list: string[]
}

export type CountryAreaSelection = {
  kind: "Country"
  list: string[]
}
export type DistrictsAreaSelection = {
  kind: "District"
  list: string[]
}

export interface DistrictDataAppState {
  dataSet: DataSetType
  mainTab: MainTabType
  viewportSettings: ViewportSettingsType
  isochroneSettings: IsochroneType
  microSelection: {
    focusArea: DistrictsAreaSelection
    referenceArea: DistrictsAreaSelection | MunicipalitiesAreaSelection
    data: Array<string>
  }
  macroSelection: {
    focusArea: MunicipalitiesAreaSelection
    referenceArea: MunicipalitiesAreaSelection | CountryAreaSelection
    data: Array<string>
  }
}
// Made own centerBerlin const as it has dependency issues when importing from reducers file
const centerBerlinMarketData = {
  lng: 13.404954,
  lat: 52.520008,
}

export const defaultViewportSettings: ViewportSettingsType = {
  micro: {
    focusArea: {
      viewportCenter: [centerBerlinMarketData.lng, centerBerlinMarketData.lat],
      zoom: 13,
    },
    referenceArea: {
      viewportCenter: [centerBerlinMarketData.lng, centerBerlinMarketData.lat],
      zoom: 11,
    },
  },
  macro: {
    focusArea: {
      viewportCenter: [centerBerlinMarketData.lng, centerBerlinMarketData.lat],
      zoom: 11,
    },
    referenceArea: {
      viewportCenter: [centerBerlinMarketData.lng, centerBerlinMarketData.lat],
      zoom: 8,
    },
  },
}
export const initialState: DistrictDataAppState = {
  dataSet: "micro",
  mainTab: "configuration",
  viewportSettings: defaultViewportSettings,
  isochroneSettings: { mode: "none", time: 15 },
  microSelection: {
    focusArea: {
      kind: "District",
      list: [],
    },
    referenceArea: {
      kind: "Municipality",
      list: [],
    },
    data: [],
  },
  macroSelection: {
    focusArea: {
      kind: "Municipality",
      list: [],
    },
    referenceArea: {
      kind: "Country",
      list: [],
    },
    data: [],
  },
}

const marketDataSlice = createSlice({
  name: "marketData",
  initialState,
  reducers: {
    updateDataSetDone: (state, action: PayloadAction<DataSetType>) => {
      state.dataSet = action.payload
    },
    updateMainTabDone: (state, action: PayloadAction<MainTabType>) => {
      state.mainTab = action.payload
    },
    updateViewportSettingsDone: (state, action: PayloadAction<ViewportSettingsType>) => {
      state.viewportSettings = action.payload
    },
    updateIsochroneSettingsDone: (state, action: PayloadAction<IsochroneType>) => {
      state.isochroneSettings = action.payload
    },
    updateAreaComparisonKindDone: (
      state,
      action: PayloadAction<{
        areaComparisonKind: ReferenceAreaComparisonType
        areaOrReferenceArea: SelectionAreaType
        dataSet: DataSetType
      }>
    ) => {
      const selectionName = `${action.payload.dataSet}Selection` as "microSelection" | "macroSelection"

      let newAreaOrReferenceArea: DistrictsAreaSelection | MunicipalitiesAreaSelection | CountryAreaSelection = {
        kind: action.payload.areaComparisonKind,
        list: [],
      }
      if (selectionName === "microSelection") {
        if (action.payload.areaOrReferenceArea === "focusArea") {
          newAreaOrReferenceArea = newAreaOrReferenceArea as DistrictsAreaSelection
          state[selectionName][action.payload.areaOrReferenceArea] = newAreaOrReferenceArea
        } else {
          newAreaOrReferenceArea = newAreaOrReferenceArea as DistrictsAreaSelection | MunicipalitiesAreaSelection
          state[selectionName][action.payload.areaOrReferenceArea] = newAreaOrReferenceArea
        }
      } else {
        if (action.payload.areaOrReferenceArea === "focusArea") {
          newAreaOrReferenceArea = newAreaOrReferenceArea as MunicipalitiesAreaSelection
          state[selectionName][action.payload.areaOrReferenceArea] = newAreaOrReferenceArea
        } else {
          newAreaOrReferenceArea = newAreaOrReferenceArea as MunicipalitiesAreaSelection | CountryAreaSelection
          state[selectionName][action.payload.areaOrReferenceArea] = newAreaOrReferenceArea
        }
      }
    },
    addSelectedRegionDone: (
      state,
      action: PayloadAction<{
        selectedRegion: string
        areaOrReferenceArea: SelectionAreaType
        dataSet: DataSetType
      }>
    ) => {
      const selectionName = `${action.payload.dataSet}Selection` as "microSelection" | "macroSelection"
      const areaOrReferenceArea = JSON.parse(JSON.stringify(state[selectionName][action.payload.areaOrReferenceArea]))
      const newList = new Set([...areaOrReferenceArea.list, action.payload.selectedRegion])
      state[selectionName][action.payload.areaOrReferenceArea].list = Array.from(newList)
    },
    removeSelectedRegionDone: (
      state,
      action: PayloadAction<{ selectedRegion: string; areaOrReferenceArea: SelectionAreaType; dataSet: DataSetType }>
    ) => {
      const selectionName = `${action.payload.dataSet}Selection` as "microSelection" | "macroSelection"
      const prevRegionList = JSON.parse(JSON.stringify(state[selectionName][action.payload.areaOrReferenceArea].list))

      state[selectionName][action.payload.areaOrReferenceArea].list = prevRegionList.filter(
        (region: string) => region !== action.payload.selectedRegion
      )
    },
    clearSelectedRegionsDone: (
      state,
      action: PayloadAction<{ areaOrReferenceArea: SelectionAreaType; dataSet: DataSetType }>
    ) => {
      const selectionName = `${action.payload.dataSet}Selection` as "microSelection" | "macroSelection"
      state[selectionName][action.payload.areaOrReferenceArea].list = []
    },
    setSelectedDataDone: (state, action: PayloadAction<{ dataName: Array<string>; dataSet: DataSetType }>) => {
      const selectionName = `${action.payload.dataSet}Selection` as "microSelection" | "macroSelection"

      state[selectionName].data = Array.from(new Set(action.payload.dataName))
    },
    toggleSelectedDataDone: (state, action: PayloadAction<{ dataName: Array<string>; dataSet: DataSetType }>) => {
      const selectionName = `${action.payload.dataSet}Selection` as "microSelection" | "macroSelection"
      const stateSelection: string[] = JSON.parse(JSON.stringify(state[selectionName].data))
      const dataNamesSet = new Set(stateSelection)
      action.payload.dataName.forEach((item) =>
        dataNamesSet.has(item) ? dataNamesSet.delete(item) : dataNamesSet.add(item)
      )
      state[selectionName].data = Array.from(dataNamesSet)
    },
    clearSelectedDataDone: (state, action: PayloadAction<DataSetType>) => {
      const selectionName = `${action.payload}Selection` as "microSelection" | "macroSelection"
      state[selectionName].data = []
    },
  },
  extraReducers: {
    [ASSESSMENT_ENTRY_CHANGED_MODULE]: (state, action: PayloadAction<AssessmentEntryChangedPayload>) => {
      state.dataSet = "micro"
      state.mainTab = "configuration"
      state.viewportSettings = defaultViewportSettings
      state.isochroneSettings =
        action.payload.newId !== action.payload.oldId ? { mode: "none", time: 15 } : state.isochroneSettings
      state.microSelection = initialState.microSelection
      state.macroSelection = initialState.macroSelection
    },
  },
})

const {
  updateDataSetDone,
  updateIsochroneSettingsDone,
  updateViewportSettingsDone,
  setSelectedDataDone,
  clearSelectedDataDone,
  clearSelectedRegionsDone,
  removeSelectedRegionDone,
  toggleSelectedDataDone,
  addSelectedRegionDone,
  updateAreaComparisonKindDone,
  updateMainTabDone,
} = marketDataSlice.actions

export function updateDataSet(dataSet: DataSetType): void {
  store.dispatch(updateDataSetDone(dataSet))
}

export function updateMainTab(mainTab: MainTabType): void {
  store.dispatch(updateMainTabDone(mainTab))
}

export function updateViewportSettings(viewportSettings: ViewportSettingsType): void {
  store.dispatch(updateViewportSettingsDone(viewportSettings))
}

export function updateIsochroneSettings(isochroneSettings: IsochroneType): void {
  store.dispatch(updateIsochroneSettingsDone(isochroneSettings))
}

export function updateAreaComparisonKind(
  areaComparisonKind: ReferenceAreaComparisonType,
  areaOrReferenceArea: SelectionAreaType,
  dataSet: DataSetType
): void {
  store.dispatch(
    updateAreaComparisonKindDone({
      areaComparisonKind: areaComparisonKind,
      areaOrReferenceArea: areaOrReferenceArea,
      dataSet: dataSet,
    })
  )
}

export function addSelectedRegion(regId: string, areaOrReferenceArea: SelectionAreaType, dataSet: DataSetType): void {
  store.dispatch(
    addSelectedRegionDone({
      selectedRegion: regId,
      areaOrReferenceArea: areaOrReferenceArea,
      dataSet: dataSet,
    })
  )
}

export function removeSelectedRegion(
  regId: string,
  areaOrReferenceArea: SelectionAreaType,
  dataSet: DataSetType
): void {
  store.dispatch(
    removeSelectedRegionDone({
      selectedRegion: regId,
      areaOrReferenceArea: areaOrReferenceArea,
      dataSet: dataSet,
    })
  )
}

export function clearSelectedRegions(areaOrReferenceArea: SelectionAreaType, dataSet: DataSetType): void {
  store.dispatch(
    clearSelectedRegionsDone({
      areaOrReferenceArea: areaOrReferenceArea,
      dataSet: dataSet,
    })
  )
}

export function toggleSelectedData(dataSet: DataSetType, dataName: string[]): void {
  store.dispatch(toggleSelectedDataDone({ dataSet: dataSet, dataName: dataName }))
}

export function setSelectedData(dataSet: DataSetType, dataName: string[]): void {
  store.dispatch(setSelectedDataDone({ dataSet: dataSet, dataName: dataName }))
}

export function clearSelectedData(dataSet: DataSetType): void {
  store.dispatch(clearSelectedDataDone(dataSet))
}

export default marketDataSlice.reducer
