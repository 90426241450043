import React, { useEffect } from "react"
import { translations } from "../../assessment/i18n"
import { useSelector } from "react-redux"
import { State } from "../../assessment/reducers/state"
import { css } from "emotion"
import { YieldTrendsContent } from "../../assessment/components/assessment-entry-yield-trends-content"
import { useAppSelector } from "../../relas/store"
import { loadAssessmentYields } from "../../assessment/reducers/assessment-slice-functions"
import LoadingSpinner from "../../shared/components/loadingspinner"
import { PriceWidget } from "./price-widget"

const styles = {
  canvasContainer: css({
    height: "99%",
    justifySelf: "center",
    width: "99%",
  }),
}
export const MarketYieldTrendsV2 = () => {
  const t = React.useMemo(translations, [translations])

  const currentAssessmentEntry = useSelector((state: State) => state.assessment.currentAssessmentEntry)
  const yieldType = useSelector((state: State) => state.assessment.selectedYieldType)
  const currentObjectYields = useAppSelector((state) => state.assessment.currentObjectYields)
  const [isLoading, setIsLoading] = React.useState(false)
  useEffect(() => {
    setIsLoading(true)
    void loadAssessmentYields(currentAssessmentEntry?.address.location).finally(() => setIsLoading(false))
  }, [currentAssessmentEntry, yieldType])

  const getHeader = () => {
    switch (yieldType) {
      case "residential":
        return t.prices.marketYieldsTrends.rentOrPurchaseResidential
      case "office":
        return t.prices.marketYieldsTrends.rentOffice
      case "retail":
        return t.prices.marketYieldsTrends.rentRetail
    }
  }

  return (
    <PriceWidget title={getHeader()} info={t.prices.marketYieldsTrends.info}>
      <div className={styles.canvasContainer}>
        {isLoading ? (
          <div style={{ justifyContent: "center", alignItems: "center", marginTop: "10rem" }}>
            <LoadingSpinner size={64} />
          </div>
        ) : (
          currentObjectYields && <YieldTrendsContent yieldType={yieldType} dashboardView={true} />
        )}
      </div>
    </PriceWidget>
  )
}
