import * as React from "react"
import { useState } from "react"
import { useSelector } from "react-redux"
import { ChangePasswordRequest } from "../models/change-password"
import { translations } from "../i18n"
import { UserAccountState } from "../user-profile-slice"
import { changePassword } from "../user-profile-slice"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import LoadingDimmer from "../../shared/components/loadingdimmer"
import Card from "../../shared/components/card"
import Panel from "../../shared/components/panel"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"
import TextField from "../../shared/components/textfield"
import Button from "../../shared/components/button"
import Form from "../../shared/components/form"

enum FormState {
  Pristine,
  PasswordChanged,
  Dirty,
}

enum PasswordValidity {
  notMatching,
  tooShort,
  currentMissing,
  canSubmit,
  noNew,
}

export const UserAccountPasswordChange = () => {
  const inFlight = useSelector((state: UserAccountState) => state.userProfile.changePasswordInProgress)
  const error = useSelector((state: UserAccountState) => state.userProfile.changePasswordError)
  const wrongCurrentPasswordError = useSelector(
    (state: UserAccountState) => state.userProfile.wrongCurrentPasswordError
  )

  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")
  const [formState, setFormState] = useState(FormState.Pristine)

  const onChangeCurrentPassword = (newValue: string) => {
    setCurrentPassword(newValue)
    setFormState(FormState.Dirty)
  }

  const onChangeNewPassword = (newValue: string) => {
    setNewPassword(newValue)
    setFormState(FormState.Dirty)
  }

  const onChangeConfirmNewPassword = (newValue: string) => {
    setConfirmNewPassword(newValue)
    setFormState(FormState.Dirty)
  }

  const submitPassword = () => {
    const request: ChangePasswordRequest = {
      confirm: confirmNewPassword,
      oldPassword: currentPassword,
      password: newPassword,
    }

    changePassword(request).then(
      () => {
        setNewPassword("")
        setConfirmNewPassword("")
        setCurrentPassword("")
        setFormState(FormState.PasswordChanged)
      },
      () => undefined
    )
  }

  const canSubmit = () => {
    if (inFlight) return false
    return passwordValidity() === PasswordValidity.canSubmit
  }

  const passwordValidity = () => {
    if (currentPassword.length === 0) return PasswordValidity.currentMissing
    if (confirmNewPassword.length === 0 && newPassword.length === 0) return PasswordValidity.noNew
    if (confirmNewPassword !== newPassword) return PasswordValidity.notMatching
    if (newPassword.length < 9 || confirmNewPassword.length < 9) return PasswordValidity.tooShort
    return PasswordValidity.canSubmit
  }

  const renderValidityErrors = () => {
    const translate = translations().settings.changePassword
    if (formState === FormState.Pristine) return null
    if (formState === FormState.PasswordChanged)
      return (
        <Panel title={translate.doneTitle} color="positive">
          {translate.doneBody}
        </Panel>
      )
    switch (passwordValidity()) {
      case PasswordValidity.canSubmit:
        return null
      case PasswordValidity.currentMissing:
        return <Panel color="negative">{translate.currentMissingError}</Panel>
      case PasswordValidity.notMatching:
        return <Panel color="negative">{translate.notMatchingError}</Panel>
      case PasswordValidity.tooShort:
        return <Panel color="negative">{translate.tooShortError}</Panel>
      case PasswordValidity.noNew:
        return <Panel color="negative">{translate.noNewError}</Panel>
    }
  }

  const renderWrongCurrentPasswordError = () => {
    const translate = translations().settings.changePassword
    return wrongCurrentPasswordError ? <Panel color="negative">{translate.wrongCurrentPasswordError}</Panel> : null
  }

  const translate = translations().settings.changePassword

  return (
    <LoadingDimmer loading={inFlight}>
      <FlexContainer direction="column" spaceBetween="md">
        <Card header={translate.title}>
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              submitPassword()
            }}
          >
            <FlexContainer direction="column" spaceBetween="md">
              {error ? <GenericErrorPanel error={error} /> : null}
              {renderWrongCurrentPasswordError()}
              {renderValidityErrors()}
              <TextField
                minLength={8}
                value={currentPassword}
                type="password"
                label={translate.currentLabel}
                hint={translate.currentHint}
                onValueChange={onChangeCurrentPassword}
              />
              <TextField
                minLength={9}
                value={newPassword}
                type="password"
                label={translate.newLabel}
                hint={translate.newHint}
                onValueChange={onChangeNewPassword}
              />
              <TextField
                minLength={9}
                value={confirmNewPassword}
                type="password"
                label={translate.confirmLabel}
                hint={translate.confirmHint}
                onValueChange={onChangeConfirmNewPassword}
              />

              <FlexContainer paddingX="xl" spaceBetween="md" md-justify="end" direction="row">
                <Button formType="submit" disabled={inFlight || !canSubmit()} type="primary">
                  {" "}
                  {translate.doIt}{" "}
                </Button>
              </FlexContainer>
            </FlexContainer>
          </Form>
        </Card>
      </FlexContainer>
    </LoadingDimmer>
  )
}
