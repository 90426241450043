import { Translations } from "./translations"

export const DE: Translations = {
  login: {
    invalidEmail: "Ungültige Email",
    header: "Anmelden",
    usernameLabel: "E-Mail-Adresse",
    passwordLabel: "Passwort",
    cookiesSettings: {
      header: "Cookie Einstellungen",
      usageWarn: "Unsere Website und Services benötigen Cookies. Detaillierte Informationen finden Sie unter ",
      generalProtection: "der Datenschutzerklärung.",
      radioHeader: "Wählen Sie bitte den Umfang der Cookies, die wir einsetzen dürfen:",
      all: {
        label: "Alle Cookies zulassen",
        details:
          "Sie helfen uns dabei, die Seite an Ihre Bedürfnisse anzupassen, um Ihre Arbeit zu erleichtern. Neben technischen werden Tracking- und Analysecookies eingesetzt.",
      },
      necessary: {
        label: "Nur notwendige Cookies Zulassen",
        details:
          "Sie können die Seite nutzen. Es werden nur technisch notwendige Cookies eingesetzt, um z.B. Ihren Log-In zu verwalten.",
      },
      moreInfo: "mehr Informationen",
      lessInfo: "weniger Informationen",
      changeInfo: "Sie können Ihre Cookie-Einstellung jederzeit in Ihren Kontoeinstellungen ändern.",
    },
    loginButton: "Einloggen",
    loginFailed: "Login fehlgeschlagen. Bitte überprüfen Sie ihre Eingabe.",
    loginFailedNoProductsBooked: "Sie haben keine gebuchten Produkte.",
    otpLabel: "Authentifizierungscode",
    otpHint: "Bitte geben Sie einen Code ein.",
    otpExplanation:
      "Bitte geben Sie hier einen Code aus Ihrer Authenticator-App (z.B. Google Authenticator) ein.\nDieses Gerät wird für 30 Tage als vertrauenswürdig markiert (cookie). Vertrauenswürdige Geräte benötigen keinen Zwei-Faktor Code zum Einloggen.",
    passwordForgotten: "Passwort vergessen",
    registration: "Sie interessieren sich für einen Zugang?",
    backToLogin: "Zur Login-Ansicht wechseln",
    sendPasswordForgotten: "Abschicken",
    email: "Email",
    emailHint: `Ihre registrierte Email`,
    passwordForgottenOkHeader: "Email gesendet",
    passwordForgottenOkText:
      "Wenn Sie eine uns bekannte E-Mail-Adresse angegeben haben, erhalten Sie jetzt eine E-Mail mit weiteren Informationen.",
    passwordForgottenErrHeader: "Email konnte nicht versendet werden",
    passwordForgottenErrText:
      "Es ist leider ein technisches Problem aufgetreten. Bitte versuchen Sie es später erneut.",
    userBlocked:
      "Sie wurden gesperrt und besitzen nicht mehr die Berechtigungen für die Nutzung der Software. Bitte wenden Sie sich an support@21re.de.",
    or: "Oder",
    registerWithIS24: "Anmelden mit ImmoScout24",
    IS24LoginErrorTitle: "Anmeldefehler",
    IS24LoginError:
      "Wir können Sie derzeit nicht einloggen. Bitte wenden Sie sich an Ihren ImmoScout24 Account-Manager für Unterstützung.",
  },
  setPassword: {
    setNewPasswordHeader: "Neues Passwort vergeben",
    setPasswordHeader: "Passwort vergeben",
    setPasswordInfo:
      "Hier können Sie Ihr persönliches Passwort festlegen. Zusammen mit Ihrer E-Mail-Adresse wird dies für den Login gebraucht.",
    newPasswordLabel: "Ihr neues Passwort",
    passwordLabel: "Ihr Passwort",
    confirmPasswordLabel: "Bitte geben Sie das Passwort erneut ein",
    valid: "Die zwei Passwörter stimmen überein.",
    tooLong: (charCount: number) => `Das neue Passwort ist zu lang. Bitte verwenden Sie maximal ${charCount} Zeichen.`,
    tooShort: (charCount: number) =>
      `Das neue Passwort ist zu kurz. Bitte verwenden Sie mindestens ${charCount} Zeichen.`,
    notMatching: "Die zwei Passwortfelder stimmen nicht überein.",
    submitNewPassword: "Passwort ändern",
    submitPassword: "Passwort setzen",
    submitError: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
    submitSuccess: "Das Passwort wurde erfolgreich gesetzt. Bitte loggen Sie sich jetzt damit ein.",
    submitNewSuccess: "Das Passwort wurde erfolgreich geändert. Bitte loggen Sie sich jetzt damit ein.",
    loginNow: "Jetzt einloggen",
  },
  twoFactorSetup: {
    setupHeader: "Einrichtung",
    explanationHeader: "Was muss ich tun?",
    generatedCodeLabel: "Geben Sie bitte einen generierten Code ein.",
    submitLabel: "Einrichtung abschliessen",
    submittedOkText: "Die Einrichtung wurde erfolgreich abgeschlossen.",
    submittedErrorText:
      "Die Einrichtung konnte nicht abgeschlossen werden. Vermutlich ist der generierte Code ungültig.",
    secretLabel: "Der geheime Schlüssel ist",
    qrRenderError:
      "Leider ist uns beim erzeugen des QR-Codes ein Fehler passiert. Bitte versuchen Sie es mit einem anderen Browser oder probieren Sie ggf. den Browser zu aktualisieren. Alternativ können bestimmte Zwei-Faktor Apps mithilfe des geheimen Schlüssels eingerichtet werden.",
    explanation: `Installieren Sie eine der unten aufgelisteten Apps. Mit der App kann der QR Code unter 'Einrichtung' gescannt werden. Die App kann dann Codes erstellen, die immer 30 Sekunden lang gültig sind. Wenn Sie auf 21st-Market nach einem generierten Code gefragt werden, starten Sie die App und geben den gerade angezeigten Code ein. Damit Sie Erfahrung mit diesem Prozess sammeln muss ein solcher generierter Code im Feld weiter unten eingegeben werden, bevor Ihre Zwei-Faktor Authentifizierung aktiv wird.`,
    teaser:
      "Traditionell nutzen Sie zum Einloggen nur ein Passwort. Wenn ein Angreifer Ihr Passwort errät, ist Ihr Konto kompromittiert. Mit der sogenannten Zwei-Faktor Authentifizierung geben Sie beim Einloggen nicht nur Ihr Passwort sondern auch ein Code an, der nur begrenzt gültig ist. Diese Codes können nur von einem Gerät erzeugt werden, welches in Ihrem Besitz ist. Ein Angreifer müsste damit sowohl Ihr Passwort als auch das Gerät stehlen, um Ihr Konto zu kompromittieren.",
    infographic: "/assets/mfa_de.png",
    otpLengthMismatch: "Der Code muss aus genau 6 Ziffern bestehen.",
    infographicHeader: "So geht's",
    companyAdminHeader: "Bitte richten Sie Zwei-Faktor Authentifizierung ein",
    companyAdminExplanation:
      "Sie sind in unserem System innerhalb Ihrer Firma als Administrator gekennzeichnet. Damit können Sie wichtige Einstellungen der Firma ändern, z.B. Berechnungsparameter, sowie andere Mitglieder der Firma administrieren. Aus diesem Grund empfehlen wir besondere Vorsichtsmaßnahmen, damit Ihr Konto vor Hackerangriffen sicher ist.",
    mandatorySetup: "Sie müssen die Zwei-Faktor Authentifizierung einrichten, bevor Sie fortfahren können.",
  },
  links: {
    imprint: "Impressum",
    terms: "AGB",
    privacy: "Datenschutz",
    relativeImprintLink: "impressum?lang=de",
    relativePrivacyLink: "datenschutz?lang=de",
    relativeTermsLink: "agb?lang=de",
    relativeContactFormLink: "kontakt?lang=de",
  },
  autoCloseLogin: {
    title: "Ihre Sitzung wurde erneuert!",
    paragraph: "Einen Moment Geduld, wir bringen Sie gleich zurück.",
  },
}
