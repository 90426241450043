import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { CityInfo, RentIndexQuery, RentIndexResult } from "../models/rentindex"
import { PlayServiceError } from "../../shared/models/ServiceErrorResponse"
import { AssessmentEntryFull } from "../models/assessment"
import { getAnswers, saveAnswers } from "../components/rentindex/rent-index-form-utils"
import { Address } from "../models/address"
import Axios, { AxiosError, AxiosResponse, CancelTokenSource } from "axios"
import { rentIndexUrl } from "../../app_config"
import { store } from "../../relas/store"
import { GenericError, toGenericError } from "../../shared/helper/axios"

export interface RentIndexAppState {
  cityInfo: CityInfo | null
  cityInfoLoadInProgress: boolean
  cityInfoLoadError: GenericError | null

  rentindexResult: RentIndexResult[] | null
  rentindexResultLoadInProgress: boolean
  rentindexResultLoadError: GenericError<PlayServiceError> | null
}

export const initialState: RentIndexAppState = {
  cityInfoLoadError: null,
  cityInfoLoadInProgress: false,
  cityInfo: null,
  rentindexResult: null,
  rentindexResultLoadError: null,
  rentindexResultLoadInProgress: false,
}

const rentIndexSlice = createSlice({
  name: "rentIndex",
  initialState,
  reducers: {
    queryRentIndexStart: (state) => {
      state.rentindexResultLoadInProgress = true
      state.rentindexResultLoadError = null
      state.rentindexResult = null
    },
    queryRentIndexSuccess: (state, action: PayloadAction<RentIndexResult[]>) => {
      state.rentindexResultLoadInProgress = false
      state.rentindexResultLoadError = null
      state.rentindexResult = action.payload
    },
    queryRentIndexError: (state, action: PayloadAction<GenericError<PlayServiceError>>) => {
      state.rentindexResultLoadInProgress = false
      state.rentindexResultLoadError = action.payload
      state.rentindexResult = null
    },
    getCityInfoStart: (state) => {
      state.cityInfoLoadInProgress = true
      state.cityInfoLoadError = null
      state.cityInfo = null
      state.rentindexResult = null
    },
    getCityInfoSuccess: (state, action: PayloadAction<CityInfo>) => {
      state.cityInfoLoadInProgress = false
      state.cityInfoLoadError = null
      state.cityInfo = action.payload
    },
    getCityInfoError: (state, action: PayloadAction<GenericError>) => {
      state.cityInfoLoadInProgress = false
      state.cityInfoLoadError = action.payload
      state.cityInfo = null
    },
  },
  extraReducers: {
    ["ASSESSMENT_ENTRY_UPDATE_DONE"]: (state, action: PayloadAction<AssessmentEntryFull>) => {
      const storedAnswers = getAnswers(action.payload.id)
      if (storedAnswers) {
        saveAnswers(action.payload.id, {
          ...storedAnswers,
          yearOfConstruction:
            (action.payload.newBuilding ? new Date().getFullYear() : action.payload.year) ??
            storedAnswers.yearOfConstruction,
          area: action.payload.area ?? storedAnswers.area,
        })
      }
    },
  },
})

const {
  queryRentIndexStart,
  queryRentIndexError,
  queryRentIndexSuccess,
  getCityInfoStart,
  getCityInfoSuccess,
  getCityInfoError,
} = rentIndexSlice.actions

let pendingGetCityInfoQuery: CancelTokenSource | null = null

export function getCityInfo(address: Address): Promise<any> {
  if (pendingGetCityInfoQuery !== null) {
    pendingGetCityInfoQuery.cancel()
    pendingGetCityInfoQuery = null
  }
  pendingGetCityInfoQuery = Axios.CancelToken.source()
  store.dispatch(getCityInfoStart())
  return Axios.get(`${rentIndexUrl}/api/cities/${encodeURIComponent(address.locality ?? "")}`, {
    cancelToken: pendingGetCityInfoQuery.token,
  }).then(
    (success: AxiosResponse) => {
      store.dispatch(getCityInfoSuccess(success.data))
    },
    (error: AxiosError) => {
      if (Axios.isCancel(error)) return
      store.dispatch(getCityInfoError(toGenericError(error)))
    }
  )
}

let pendingRentIndexQuery: CancelTokenSource | null = null
export function getRentIndex(query: RentIndexQuery): void {
  if (pendingRentIndexQuery !== null) {
    pendingRentIndexQuery.cancel()
    pendingRentIndexQuery = null
  }
  pendingRentIndexQuery = Axios.CancelToken.source()
  store.dispatch(queryRentIndexStart())
  Axios.post(`${rentIndexUrl}/api/cities/${encodeURIComponent(query.address.locality ?? "")}`, query, {
    cancelToken: pendingRentIndexQuery.token,
  }).then(
    (success: AxiosResponse) => {
      store.dispatch(queryRentIndexSuccess(success.data))
    },
    (error: AxiosError<PlayServiceError>) => {
      if (Axios.isCancel(error)) return
      store.dispatch(queryRentIndexError(toGenericError<PlayServiceError>(error)))
    }
  )
}

export default rentIndexSlice.reducer
