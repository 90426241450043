import { FunctionComponent, default as React, CSSProperties, PropsWithChildren } from "react"

import { css, cx } from "emotion"
import { Globals } from "csstype"
import { ColorConfig, getThemeColorVar } from "../../helper/color"
import {
  ResponsiveSpec,
  RevoBreakpointLabel,
  RevoSizeSpec,
  revoSizeToPadding,
  SizeSpec,
  sortedRevoBreakpoints,
} from "../../util/layoutconstants"
import { EmotionCSS, revoSpecToCss } from "../../helper/responsive"
import { isNullOrUndefined, isString } from "../../helper/utils"

export type AlignSelf =
  | "auto"
  | "normal"
  | "center"
  | "start"
  | "end"
  | "self-start"
  | "self-end"
  | "left"
  | "right"
  | "baseline"
  | "first baseline"
  | "last baseline"
  | "stretch"
  | "safe center"
  | "unsafe center"
  | "inherit"
  | "initial"
  | "unset"

export interface GridItemProps {
  backgroundColor?: ColorConfig
  colStart?: number | ResponsiveSpec<number>
  colEnd?: number | ResponsiveSpec<number>
  rowStart?: number | ResponsiveSpec<number>
  rowEnd?: number | ResponsiveSpec<number>
  rowSpan?: number | ResponsiveSpec<number>
  colSpan?: number | ResponsiveSpec<number>
  alignSelf?: AlignSelf
  justifySelf?: AlignSelf
  overflow?: "visible" | "hidden" | "scroll" | "auto" | "inherit" | "initial" | "unset"
  minWidth?: SizeSpec | Globals | 0
  minHeight?: SizeSpec | Globals | 0
  padding?: RevoSizeSpec | ResponsiveSpec<RevoSizeSpec>
  zIndex?: number
  // disables pointer-events for grid-item and reenables it for
  // children. useful for stacked UI's (eg: controls over map)
  enablePointerEventPassthrough?: boolean
}

export type GridProp = {
  start?: number
  span?: number
  end?: number
}

export function computeGridAttribute(
  span: number | undefined,
  start: number | undefined,
  end: number | undefined
): string {
  if (span && end) {
    return `span ${span} / ${end}`
  } else if (span && start) {
    return `${start} / span ${span}`
  } else if (span) {
    return `span ${span} / auto`
  } else {
    return `${start || "auto"} / ${end || "auto"}`
  }
}

export function determinePreviousValue(
  i: ResponsiveSpec<GridProp>,
  previousKey: RevoBreakpointLabel,
  propKey: keyof GridProp
): number | undefined {
  const previousProps = i[previousKey]
  return previousProps ? previousProps[propKey] : undefined
}

export function determineFinalValue(
  spec: ResponsiveSpec<number>,
  key: RevoBreakpointLabel,
  previousValue: GridProp,
  propKey: keyof GridProp
): number | undefined {
  const maybeSpec = spec[key]
  return maybeSpec ? maybeSpec : previousValue[propKey]
}

export function rawGridSpecToGridProp(
  start: number | ResponsiveSpec<number> | undefined,
  span: number | ResponsiveSpec<number> | undefined,
  end: number | ResponsiveSpec<number> | undefined
): GridProp | ResponsiveSpec<GridProp> | undefined {
  if (typeof start == "object" || typeof span == "object" || typeof end == "object") {
    return sortedRevoBreakpoints.reduce((i, key: RevoBreakpointLabel, index: number, keys: RevoBreakpointLabel[]) => {
      const previousKey = keys[index === 0 ? 0 : index - 1]

      const previousValue: GridProp = {
        start: start && typeof start === "object" ? determinePreviousValue(i, previousKey, "start") : start,
        span: span && typeof span === "object" ? determinePreviousValue(i, previousKey, "span") : span,
        end: end && typeof end === "object" ? determinePreviousValue(i, previousKey, "end") : end,
      }

      i[key] = {
        start: start && typeof start === "object" ? determineFinalValue(start, key, previousValue, "start") : start,
        span: span && typeof span === "object" ? determineFinalValue(span, key, previousValue, "span") : span,
        end: end && typeof end === "object" ? determineFinalValue(end, key, previousValue, "end") : end,
      }

      return i
    }, {} as ResponsiveSpec<GridProp>)
  } else if (start || span || end) {
    return {
      start: start,
      span: span,
      end: end,
    }
  } else {
    return undefined
  }
}

export const GridItem: FunctionComponent<PropsWithChildren<GridItemProps>> = (props) => {
  const classes: string[] = []
  const { backgroundColor } = props
  const style: CSSProperties = {}
  const emotionStyles: EmotionCSS[] = [
    {
      zIndex: props.zIndex,
      ...(props.enablePointerEventPassthrough
        ? {
            pointerEvents: "none",
            "> *": {
              pointerEvents: "initial",
            },
          }
        : {}),
    },
  ]

  backgroundColor &&
    classes.push(
      css({
        backgroundColor: getThemeColorVar(backgroundColor.color, backgroundColor.colorType),
      })
    )

  const colProps = rawGridSpecToGridProp(props.colStart, props.colSpan, props.colEnd)
  const rowProps = rawGridSpecToGridProp(props.rowStart, props.rowSpan, props.rowEnd)

  colProps &&
    emotionStyles.push(
      revoSpecToCss(colProps, (i) => ({
        gridColumn: computeGridAttribute(i.span, i.start, i.end),
      }))
    )
  rowProps &&
    emotionStyles.push(
      revoSpecToCss(rowProps, (i) => ({
        gridRow: computeGridAttribute(i.span, i.start, i.end),
      }))
    )

  props.padding && emotionStyles.push(revoSpecToCss(props.padding, revoSizeToPadding))

  classes.push(css(emotionStyles))

  if (props.overflow) style.overflow = props.overflow
  if (props.alignSelf) style.alignSelf = props.alignSelf as any
  if (props.justifySelf) style.justifySelf = props.justifySelf
  if (!isNullOrUndefined(props.minWidth))
    style.minWidth = isString(props.minWidth)
      ? props.minWidth
      : typeof props.minWidth === "number"
      ? props.minWidth
      : props.minWidth.join("")
  if (!isNullOrUndefined(props.minHeight))
    style.minHeight = isString(props.minHeight)
      ? props.minHeight
      : typeof props.minHeight === "number"
      ? props.minHeight
      : props.minHeight.join("")

  return (
    <div style={style} className={cx("revo-grid__item", cx(classes))}>
      {props.children}
    </div>
  )
}

export default GridItem
