import { css, cx } from "emotion"
import { Card, Checkbox } from "@blueprintjs/core"
import { BorderBottom } from "./ui/border-bottom"
import { Flex } from "./ui/flex"
import { FlexItem } from "./ui/flex-item"
import { Grid } from "./ui/grid"
import { ScrollBox } from "./ui/scroll-box"
import * as React from "react"
import { useEffect } from "react"
import { translations } from "../i18n"
import { AllowedModules, PrivatePOICategoriesList, PrivatePOICategory } from "../../private-data/models/private-data"
import { trackUsageEvent } from "../../utils/usage-tracking"
import { getThemeColor, getThemeColorVar } from "../helper/color"
import Icon from "./icon"

export interface CategoryListPopupProps {
  onClose: () => void
  categories: PrivatePOICategoriesList
  selectedCategories: PrivatePOICategoriesList
  module: AllowedModules
  updateCategories: (categories: PrivatePOICategoriesList, moduleName?: AllowedModules) => void
  getPois: (categoryId: string, moduleName?: AllowedModules) => void
  isContained?: boolean
  hideRightMargin?: boolean
  showCloseButton?: boolean
}

const styles = {
  label: css({
    paddingBottom: "8px",
    fontWeight: "bold",
  }),
  card: (hideRightMargin: boolean, isContained?: boolean) =>
    css({
      position: "absolute",
      top: isContained ? "0px" : undefined,
      bottom: isContained ? undefined : "50px",
      right: hideRightMargin ? "0px" : isContained ? "50px" : "100px",
      width: "450px",
      fontSize: "14px",
      cursor: "default",
      padding: 0,
    }),
  categoryRow: css({
    gap: "8px",
    borderBottom: "1px solid",
    borderColor: getThemeColorVar("border", "default"),
    padding: "8px 0 8px 8px",
  }),
  noCategoryFound: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  }),
}

export const PrivateDataCategoryListPopup = ({
  onClose,
  module,
  categories,
  selectedCategories,
  updateCategories,
  getPois,
  isContained,
  hideRightMargin,
  showCloseButton,
}: CategoryListPopupProps) => {
  const t = React.useMemo(translations, [translations])

  const [checkedCategories, setCheckedCategories] = React.useState<Set<PrivatePOICategory>>(new Set(selectedCategories))
  useEffect(() => {
    const checkedCategoryArray = Array.from(checkedCategories)
    updateCategories(checkedCategoryArray, module)
    const missingCategory = checkedCategoryArray.find(
      (category) => !selectedCategories.map((category) => category.id).includes(category.id)
    )
    if (missingCategory) {
      getPois(missingCategory.id, module)
    }
  }, [checkedCategories])

  const categoryIsSelected = (category: PrivatePOICategory) => {
    const selectedList = new Set([...checkedCategories].map((category) => category.id))
    return selectedList.has(category.id)
  }

  const onChangeCheckbox = (categoryToChange: PrivatePOICategory) => {
    let newCheckedCategories = new Set(Array.from(selectedCategories))
    if (categoryIsSelected(categoryToChange)) {
      const remainingCategoriesArray = Array.from(selectedCategories).filter(
        (category) => category.id !== categoryToChange.id
      )
      newCheckedCategories = new Set(remainingCategoriesArray)
    } else {
      newCheckedCategories.add(categoryToChange)
    }
    setCheckedCategories(newCheckedCategories)
  }

  const categoryRow = (category: PrivatePOICategory) => {
    return (
      <div
        key={category.id}
        className={
          categoryIsSelected(category)
            ? cx(styles.categoryRow, css({ backgroundColor: `${getThemeColor("secondary2", "light")}` }))
            : styles.categoryRow
        }
        onClick={(e) => {
          if (
            ((e.target as HTMLElement).childElementCount > 0 && (e.target as HTMLElement).tagName !== "LABEL") ||
            (e.target as HTMLElement).tagName === "P"
          ) {
            onChangeCheckbox(category)
          }
        }}
      >
        <Flex flexDirection={"row"} alignItems={"center"} gap={8} padding={[0, 0, 0, 8]}>
          <img src={`/assets/private-data/${category.icon}.svg`} />
          <FlexItem alignSelf={"center"}>
            <Checkbox
              style={{ marginBottom: "0px" }}
              checked={categoryIsSelected(category)}
              onClick={(e) => onChangeCheckbox(category)}
            />
          </FlexItem>
          <p>{category.title}</p>
        </Flex>
      </div>
    )
  }

  function onCloseInternal() {
    onClose()
    if (checkedCategories.size > 0) {
      const categoryString = Array.from(checkedCategories)
        .map((category) => category.title)
        .join(", ")
      trackUsageEvent("PRIVATE_DATA_POIS_SHOW_ON_MAP", null, `${module} - ${categoryString}`)
    }
  }

  return (
    <Card elevation={2} className={styles.card(hideRightMargin ?? false, isContained)}>
      <BorderBottom>
        <Flex flexDirection={"row"} padding={16}>
          <h3 style={{ flexGrow: 1 }}>{t.privatePOICategories}</h3>
          {showCloseButton && (
            <div onClick={onCloseInternal} style={{ cursor: "pointer" }}>
              <Icon name={"close"} color={"primary"} colorType={"default"} fontSize={18} />
            </div>
          )}
        </Flex>
      </BorderBottom>
      <Grid columns={1} gap={16} height={categories.length > 0 ? [250, "px"] : [100, "px"]}>
        {categories.length > 0 ? (
          <ScrollBox>{categories.map((category) => categoryRow(category))}</ScrollBox>
        ) : (
          <div className={styles.noCategoryFound}>
            <p>{t.noPrivatePoisFound}</p>
          </div>
        )}
      </Grid>
    </Card>
  )
}
