import { NumericInput } from "@blueprintjs/core"
import React, { useState } from "react"
import { css } from "emotion"
import { Flex } from "./flex"
import { ReValidator } from "../../models/genericinputelement"
import { getThemeColorVar } from "../../helper/color"
import Icon from "../icon"

const styles = {
  validationError: css({
    padding: "4px 0",
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    alignItems: "center",
    color: getThemeColorVar("negative", "default"),
  }),
  suffix: css({
    margin: "1px 1px 1px 0",
    padding: "5px",
    color: "white",
    backgroundColor: getThemeColorVar("border", "default"),
    alignContent: "center",
  }),
}

type Props = {
  defaultValue: number | undefined
  validator?: ReValidator<string> | Array<ReValidator<string>>
  onValueChange?: (newValue: number, valueAsString: string, valid: boolean) => void
  onValidValueChange?: (newValue: number, valueAsString: string) => void
  disabled?: boolean
  buttonPosition?: "none" | "left" | "right"
  suffix?: string
}

const validationSuccess = { validatorId: -1, valid: true, validationMessage: "" }
export const NumberInput = (props: Props) => {
  const [validationResult, setValidationResult] = useState([validationSuccess])

  const handleTyping = (newValue: number, valueAsString: string) => {
    let validationResult = [validationSuccess]

    if (isNaN(newValue)) {
      validationResult = [{ validatorId: -1, valid: false, validationMessage: "" }]
    } else if (props.validator) {
      const validatorArr = Array.isArray(props.validator) ? props.validator : [props.validator]
      validationResult = validatorArr.map((validator, index) => ({ ...validator(valueAsString), validatorId: index }))
    }

    const allValid = validationResult.every((v) => v.valid)

    if (props.onValueChange) {
      props.onValueChange(newValue, valueAsString, allValid)
    } else if (allValid && props.onValidValueChange) {
      props.onValidValueChange(newValue, valueAsString)
    }

    setValidationResult(validationResult)
  }

  return (
    <Flex flexDirection={"column"}>
      <NumericInput
        defaultValue={props.defaultValue}
        buttonPosition={props.buttonPosition ?? "none"}
        onValueChange={handleTyping}
        intent={validationResult.every((v) => v.valid) ? "none" : "danger"}
        color={getThemeColorVar("negative", "default")}
        disabled={props.disabled}
        fill
        rightElement={props.suffix ? <div className={styles.suffix}>{props.suffix}</div> : undefined}
      />
      {!props.disabled &&
        validationResult
          .filter((r) => !r.valid && r.validationMessage)
          .map((r) => (
            <div className={styles.validationError} key={r.validatorId}>
              <Icon name={"cancel"} size={"small"} />
              <span>{r.validationMessage}</span>
            </div>
          ))}
    </Flex>
  )
}
