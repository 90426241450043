import * as React from "react"
import { CSSProperties, PropsWithChildren } from "react"
import { css, cx } from "emotion"
import { EmotionCSS } from "../helper/responsive"
import { ColorType, RevoColor, SpacingSize } from "../util/layoutconstants"
import { getThemeColorVar } from "../helper/color"

type ColorProps<C extends RevoColor> =
  | {
      color: C
      colorType: ColorType<C>
    }
  | {
      color?: undefined
      colorType?: undefined
    }

type BaseProps<C extends RevoColor> = {
  size?: SpacingSize
  inline?: boolean
  additionalStyles?: CSSProperties
} & ColorProps<C>

type RegularFontProps<C extends RevoColor> = BaseProps<C> & {
  fontWeight?: "regular"
  italic?: boolean
}

type FontProps<C extends RevoColor> = BaseProps<C> & {
  fontWeight?: "light" | "bold"
  italic?: undefined
}

const sizeClasses: { [key in SpacingSize]: string } = {
  sm: css({
    fontSize: "14px",
    lineHeight: "20px",
  }),

  base: css({
    fontSize: "16px",
    lineHeight: "25px",
  }),

  md: css({
    fontSize: "18px",
    lineHeight: "25px",
  }),

  lg: css({
    fontSize: "21px",
    lineHeight: "30px",
  }),

  xl: css({
    fontSize: "24px",
    lineHeight: "35px",
  }),

  xxl: css({
    fontSize: "27px",
    lineHeight: "35px",
  }),
}

const fontWeightClasses: {
  [key in "light" | "bold" | "regular"]: string | undefined
} = {
  light: css({
    fontWeight: 300,
  }),
  bold: css({
    fontWeight: "bold",
  }),
  regular: undefined,
}

const reTextClass = css({
  "&:not(:last-child)": {
    marginBottom: 0,
  },
} as EmotionCSS)

export class Text<C extends RevoColor> extends React.Component<
  PropsWithChildren<RegularFontProps<C> | FontProps<C>>,
  {}
> {
  render() {
    const classes: string[] = [reTextClass]

    this.props.color &&
      classes.push(
        css({
          color: getThemeColorVar(this.props.color, this.props.colorType as ColorType<C>),
        })
      )

    const weightClass = this.props.fontWeight && fontWeightClasses[this.props.fontWeight]
    weightClass && classes.push(weightClass)

    this.props.italic &&
      classes.push(
        css({
          fontStyle: "italic",
        })
      )

    classes.push(sizeClasses[this.props.size || "base"])
    return this.props.inline ? (
      <span style={this.props.additionalStyles} className={cx(classes)}>
        {this.props.children}
      </span>
    ) : (
      <p style={this.props.additionalStyles} className={cx(classes)}>
        {this.props.children}
      </p>
    )
  }
}

export const ReText = Text
export default Text
