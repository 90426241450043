import { PriceRange } from "../models/assessment"
import * as React from "react"
import { FC, useMemo } from "react"
import { BarGraph } from "./assessment-entry-bar-graph"
import { COLORS } from "../../shared/components/ui/colors"
import { translations } from "../i18n"
import { GraphScale } from "../models/graph-scale"
import { valuesOfPriceRange } from "../../utils/utils"

interface Props {
  agsYields: PriceRange | undefined
  agsTopYields: PriceRange | undefined
  cellYields: PriceRange | undefined
  assessmentEntryLoadInProgress: boolean
  isPriceWidget?: boolean
  showMultiplier?: boolean
}

const graphOffset = 24

const labelWidth = "185px"

export const YieldMarketBars: FC<Props> = ({
  agsYields,
  agsTopYields,
  cellYields,
  assessmentEntryLoadInProgress,
  isPriceWidget,
  showMultiplier,
}: Props) => {
  const t = useMemo(translations, [translations])

  const allYieldsValues: number[] = useMemo(() => {
    return [...valuesOfPriceRange(agsYields), ...valuesOfPriceRange(cellYields), ...valuesOfPriceRange(agsTopYields)]
  }, [agsYields, cellYields, agsTopYields])

  const scaleForWidth = (newGraphWidth: number): GraphScale =>
    new GraphScale(allYieldsValues, graphOffset, newGraphWidth - 2 * graphOffset)

  return isPriceWidget === true ? (
    <div style={{ display: "grid", gap: "30px", alignContent: "center" }}>
      {agsTopYields && (
        <BarGraph
          label={t.assessmentEntryDetails.topYieldCity}
          color={COLORS.primary.default}
          data={agsTopYields}
          scaleForWidth={scaleForWidth}
          labelWidth={labelWidth}
          yieldGraph={true}
          colorLabel={false}
          showSpinners={assessmentEntryLoadInProgress}
          showMultiplier={showMultiplier}
        />
      )}
      <BarGraph
        label={t.assessmentEntryDetails.yieldCity}
        color={COLORS.primary.default}
        data={agsYields}
        scaleForWidth={scaleForWidth}
        labelWidth={labelWidth}
        yieldGraph={true}
        colorLabel={false}
        showSpinners={assessmentEntryLoadInProgress}
        showMultiplier={showMultiplier}
      />
      <BarGraph
        label={t.assessmentEntryDetails.yieldMicro}
        color={COLORS.primary.default}
        data={cellYields}
        scaleForWidth={scaleForWidth}
        labelWidth={labelWidth}
        yieldGraph={true}
        colorLabel={false}
        showSpinners={assessmentEntryLoadInProgress}
        showMultiplier={showMultiplier}
      />
    </div>
  ) : (
    <>
      {agsTopYields && (
        <BarGraph
          label={t.assessmentEntryDetails.topYieldCity}
          color={COLORS.background.dark}
          data={agsTopYields}
          scaleForWidth={scaleForWidth}
          labelWidth={labelWidth}
          yieldGraph={true}
          showSpinners={assessmentEntryLoadInProgress}
        />
      )}
      <BarGraph
        label={t.assessmentEntryDetails.yieldCity}
        color={COLORS.secondary1.dark}
        data={agsYields}
        scaleForWidth={scaleForWidth}
        labelWidth={labelWidth}
        yieldGraph={true}
        showSpinners={assessmentEntryLoadInProgress}
      />
      <BarGraph
        label={t.assessmentEntryDetails.yieldMicro}
        color={COLORS.secondary1.default}
        data={cellYields}
        scaleForWidth={scaleForWidth}
        labelWidth={labelWidth}
        yieldGraph={true}
        showSpinners={assessmentEntryLoadInProgress}
      />
    </>
  )
}
