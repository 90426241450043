import * as React from "react"
import Panel from "./panel"
import { translations } from "../i18n"
import GenericErrorMessage from "./genericerrormessage"
import { GenericError } from "../helper/axios"

export interface GenericErrorPanelProps {
  /** The error to show*/
  error: GenericError
  /** The title of the dialog
   * @default ComponentTranslations.error.defaultTitle*/
  title?: string
  /** Give the user a hint in which step something went wrong
   * @default ComponentTranslations.error.defaultHint*/
  hint?: string
}

export const GenericErrorPanel: React.FunctionComponent<GenericErrorPanelProps> = (props) => {
  const translated = translations().error
  return (
    <Panel title={props.title || translated.defaultTitle} color="negative">
      <GenericErrorMessage {...props} />
    </Panel>
  )
}

export default GenericErrorPanel
