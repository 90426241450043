import React, { useMemo } from "react"
import {
  CreateOrEditCategoryPanelInfo,
  DeleteMode,
  DeleteModes,
  DeleteSelection,
  PrivatePOICategory,
} from "../../models/private-data"
import { css, cx } from "emotion"
import { PopupMenu } from "../../../shared/components/ui/popup-menu"
import { translations } from "../../i18n"
import { ScrollBox } from "../../../shared/components/ui/scroll-box"
import { Panel } from "@blueprintjs/core"
import { CreateOrEditCategoryPanel } from "./create-edit-category-panel"
import { openDelete, updateSelectedCategory } from "../../reducers/private-data-slice"
import { useAppSelector } from "../../../relas/store"
import { getThemeColor, getThemeColorVar } from "../../../shared/helper/color"
import Icon from "../../../shared/components/icon"

const styles = {
  category: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    borderBottom: "1px solid",
    borderColor: getThemeColorVar("border", "default"),
    padding: "8px 0 8px 8px",
  }),
  categoryDetails: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "4px",
  }),
}

interface Props {
  openPanel: (panel: Panel<CreateOrEditCategoryPanelInfo>) => void
  setCategoryToLock: (category: PrivatePOICategory) => void
  userId: string
  isAdmin: boolean
}

const PrivatePoisCategoriesList = (props: Props) => {
  const t = useMemo(translations, [translations])

  const privatePOICategories = useAppSelector((state) => state.privateData.privatePOICategories)
  const selectedCategory = useAppSelector((state) => state.privateData.selectedCategory)

  const renderCategory = (
    category: PrivatePOICategory,
    deleteStart: (deleteMode: DeleteMode, selection: DeleteSelection) => void
  ) => {
    const canBeUnlocked = category.lockedBy === props.userId || props.isAdmin
    const isCategorySelected = () => category.id === selectedCategory?.id

    return (
      <div
        key={category.id}
        className={
          isCategorySelected()
            ? cx(styles.category, css({ backgroundColor: `${getThemeColor("secondary2", "light")}` }))
            : styles.category
        }
        onClick={() => {
          if (isCategorySelected()) {
            updateSelectedCategory(undefined)
          } else {
            updateSelectedCategory(category)
          }
        }}
      >
        <div className={styles.categoryDetails}>
          <img src={`/assets/private-data/${category.icon}.svg`} alt={category.icon} />
          <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <div style={{ display: "inline-block", wordBreak: "break-word" }}>{category.title}</div>
            {category.lockedBy && <Icon name="locked" fontSize={14} />}
          </div>
          <div style={{ marginLeft: "auto" }}>
            <PopupMenu
              permitPropagation={!isCategorySelected()}
              actions={[
                {
                  title: t.privateData.edit,
                  disabled: !!category.lockedBy,
                  onClick: (e) => {
                    e.stopPropagation()
                    props.openPanel({
                      props: { category: category },
                      renderPanel: CreateOrEditCategoryPanel,
                    })
                  },
                },
                {
                  title: category.lockedBy ? t.privateData.unlock : t.privateData.lock,
                  disabled: !canBeUnlocked && !!category.lockedBy,
                  onClick: (e) => {
                    e.stopPropagation()
                    props.setCategoryToLock(category)
                  },
                },
                {
                  title: t.privateData.delete,
                  disabled: !!category.lockedBy,
                  onClick: (e) => {
                    e.stopPropagation()
                    deleteStart(DeleteModes.CATEGORY, category)
                  },
                },
              ]}
            />
          </div>
        </div>
        {category.notes && (
          <div style={{ maxHeight: "100px", overflowY: "auto", paddingLeft: "8px" }}>{category.notes}</div>
        )}
      </div>
    )
  }

  return <ScrollBox>{privatePOICategories.map((cat) => renderCategory(cat, openDelete))}</ScrollBox>
}

export default PrivatePoisCategoriesList
