import React, { useEffect, useMemo, useState } from "react"
import { css } from "emotion"
import { getThemeColorVar } from "../../helper/color"
import { useSelector } from "react-redux"
import { GlobalState } from "../../../relas/store"
import { Flex } from "../ui/flex"

const styles = {
  notificationsClass: css({
    width: "24px",
    height: "24px",
    pointer: "pointer",
    alignSelf: "center",
  }),
  notificationsCount: css({
    backgroundColor: getThemeColorVar("secondary2", "inverted"),
    borderRadius: "50%",
    color: "white",
    fontSize: "12px",
    width: "20px",
    height: "20px",
    textAlign: "center",
    alignSelf: "center",
    marginBottom: "4px",
  }),
  clickableStyle: css({
    cursor: "pointer",
    alignSelf: "center",
  }),
}
const releaseNotesSet = {
  releaseNotes20230911: "https://sites.google.com/21re.de/release-notes", // Special Maps
}

type ReleaseNotes = keyof typeof releaseNotesSet

const releaseNotesKeys: ReleaseNotes[] = Object.keys(releaseNotesSet) as ReleaseNotes[]

const VIEWED_RELEASE_NOTES_KEY = "VIEWED_FEATURES"

export const Notifications = () => {
  const userId = useSelector((state: GlobalState) => state.user.user?.id)
  const [visitedItems, setVisitedItems] = useState<string[]>([])

  const userKey: undefined | string = useMemo(() => {
    return userId && `${VIEWED_RELEASE_NOTES_KEY}_${userId}`
  }, [userId])

  useEffect(() => {
    setVisitedItems(getItemsFromLocalStore())
  }, [userId])

  function getItemsFromLocalStore() {
    return userKey ? (window.localStorage.getItem(userKey) ?? "").split(",").filter((s) => s.length > 0) : []
  }

  const nextItemToShow = useMemo(() => {
    return releaseNotesKeys.find((v) => !visitedItems.includes(v))
  }, [userKey && window.localStorage.getItem(userKey)])

  function openAndMarkAsViewed() {
    window.open("https://sites.google.com/21re.de/release-notes", "_blank")
    if (nextItemToShow && userKey) {
      window.localStorage.setItem(userKey, [nextItemToShow, ...getItemsFromLocalStore()].join(","))
    }
    setVisitedItems(getItemsFromLocalStore())
  }

  const newNotifications = releaseNotesKeys.length - visitedItems.length
  const notificationsCount = userKey && newNotifications > 0 ? newNotifications : undefined

  return (
    <Flex flexDirection={"row"} gap={4} alignItems={"center"}>
      {notificationsCount && <div className={styles.notificationsCount}>{notificationsCount}</div>}
      <div className={styles.clickableStyle} onClick={openAndMarkAsViewed}>
        <img src={"/assets/icon_bell_primary.svg"} alt={"Notifications"} className={styles.notificationsClass} />
      </div>
    </Flex>
  )
}
