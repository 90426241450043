import { Answers, CityInfo } from "../../models/rentindex"

export type RentIndexFormData = {
  answers: Answers
  area: number
  yearOfConstruction: number
}

export const rentIndexFormDefaults = {
  answers: {},
  area: 100,
  yearOfConstruction: 2000,
}

export function saveAnswers(assessmentEntryId: string, data: RentIndexFormData) {
  if (typeof Storage !== "undefined") {
    window.localStorage.setItem(`RENTINDEX_DATA_${assessmentEntryId}`, JSON.stringify(data))
  }
}

export function getAnswers(assessmentEntryId: string): RentIndexFormData | undefined {
  if (typeof Storage !== "undefined") {
    const data = window.localStorage.getItem(`RENTINDEX_DATA_${assessmentEntryId}`)
    if (data) {
      return JSON.parse(data)
    }
  }
  return undefined
}

export function readAnswers(assessmentEntryId: string, city: CityInfo): RentIndexFormData | undefined {
  if (typeof Storage !== "undefined") {
    const data = window.localStorage.getItem(`RENTINDEX_DATA_${assessmentEntryId}`)
    if (!data) {
      return undefined
    }
    const parsedData = JSON.parse(data)
    if (
      typeof parsedData === "object" &&
      typeof parsedData.area === "string" &&
      typeof parsedData.answers === "object" &&
      typeof parsedData.yearOfConstruction === "string"
    ) {
      const questionIds = new Set(
        city.questionsCatalogs.reduce((acc, catalog) => [...acc, ...catalog.questions], []).map((q) => q.id)
      )
      return {
        area: parsedData.area,
        yearOfConstruction: parsedData.yearOfConstruction,
        answers: Object.keys(parsedData.answers).reduce((acc, key) => {
          if (questionIds.has(key)) {
            acc[key] = parsedData.answers[key]
          }
          return acc
        }, {} as { [key: string]: any }),
      }
    }
  }
  return undefined
}
