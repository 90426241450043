import * as ol from "ol"
import Style from "ol/style/Style"
import * as style from "ol/style"
import { Fill } from "ol/style"
import { MultipleCategoryPOIs, PrivatePOICategoriesList, PrivatePOIList } from "../private-data/models/private-data"
import Feature from "ol/Feature"
import Geometry from "ol/geom/Geometry"
import { Point } from "ol/geom"
import { fromLonLat } from "ol/proj"
import * as source from "ol/source"
import * as layer from "ol/layer"
import { reportAssetsUrl } from "../reports/report-config"
import { PriceRange } from "../assessment/models/assessment"
import { ReValidator } from "../shared/models/genericinputelement"

export function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here: " + x)
}

export function isNumber(value: string): boolean {
  return /^[\-]?[0-9.]+$/.test(value) && !isNaN(value as any) && parseFloat(value).toString().indexOf("e") === -1
}

export function haversine_distance(p1: [number, number], p2: [number, number]) {
  let R = 6373 // Radius of the Earth in km
  let rlat1 = p1[1] * (Math.PI / 180) // Convert degrees to radians
  let rlat2 = p2[1] * (Math.PI / 180) // Convert degrees to radians
  let difflat = rlat2 - rlat1 // Radian difference (latitudes)
  let difflon = (p2[0] - p1[0]) * (Math.PI / 180) // Radian difference (longitudes)

  let d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)
      )
    )
  return d * 1000
}

export const trimText = (text: string, limit: number) => {
  let result = text
  let size = new Blob([text]).size
  if (size > limit) {
    do {
      const symbolSize = new Blob([result[result.length - 1]]).size
      size -= symbolSize
      result = result.slice(0, -1)
    } while (size > limit)
  }
  return result
}

export const arePrivateCategoriesDifferent = (
  prevCategories: PrivatePOICategoriesList,
  currentCategories: PrivatePOICategoriesList
): boolean => {
  const prevCategoryIdArray = new Set(prevCategories.map((category) => category.id))
  const currCategoryIdArray = new Set(currentCategories.map((category) => category.id))

  if (prevCategoryIdArray.size === currCategoryIdArray.size) {
    return Array.from(currCategoryIdArray).reduce((acc, id) => !prevCategoryIdArray.has(id), false)
  } else return true
}

export const areMultiplePrivatePoisListsDifferent = (
  prevList: MultipleCategoryPOIs,
  currentList: MultipleCategoryPOIs
): boolean => {
  const prevListKeys = new Set(Object.keys(prevList))
  const currentListKeys = new Set(Object.keys(currentList))

  if (prevListKeys.size === currentListKeys.size) {
    return Array.from(currentListKeys).reduce((acc, key) => !prevListKeys.has(key), false)
  } else return true
}
export const arePoisToShowDifferent = (prevPois: PrivatePOIList, currentPois: PrivatePOIList): boolean => {
  if (prevPois.length !== currentPois.length) {
    return true
  } else {
    const matchingPoiArr = currentPois.map((poi) => prevPois.some((prevPoi) => prevPoi.id === poi.id))
    return matchingPoiArr.every((match) => match)
  }
}

function cleanClusterLayers(map: ol.Map, layersToKeep: Set<string> = new Set()) {
  map
    .getLayers()
    .getArray()
    .filter((layer) => layer.getClassName().startsWith("cluster-layer-"))
    .forEach((layer) => {
      if (!layersToKeep.has(layer.getClassName())) {
        map.removeLayer(layer)
      }
    })
}

export const updateClusters = (
  map: ol.Map,
  clusterLayerArray: layer.Vector<any>[],
  setClusterLayerArray: (newCLusterLayerArray: layer.Vector<any>[]) => void,
  multiplePrivatePois: MultipleCategoryPOIs,
  privatePoiCategories: PrivatePOICategoriesList,
  isPrivateDataAccessible: boolean
) => {
  if (isPrivateDataAccessible) {
    const multipleCategoriesMap = { ...multiplePrivatePois }
    const categoryFeaturesMapArray: Array<[string, ol.Feature[]]> = Object.entries(multipleCategoriesMap).map(
      ([categoryId, pois]) => [
        categoryId,
        pois.reduce((acc, poi) => {
          if (poi.address.location) {
            const newFeature = new ol.Feature({
              geometry: new Point(
                fromLonLat(
                  poi.droppedLocation
                    ? [poi.droppedLocation.lng, poi.droppedLocation.lat]
                    : [poi.address.location.lng, poi.address.location.lat]
                )
              ),
            })
            newFeature.setId(`category:${categoryId}:${poi.id}`)
            return [...acc, newFeature]
          } else return acc
        }, [] as ol.Feature[]),
      ]
    )

    cleanClusterLayers(map)

    const newClusterLayerArray = categoryFeaturesMapArray.map(([categoryId, features]) => {
      const clusterVectors = new source.Vector({
        features: features,
      })
      const clusterSource = new source.Cluster({
        distance: 75,
        source: clusterVectors,
      })
      const clusterLayer = new layer.Vector({
        source: clusterSource,
        style: (feature: ol.Feature<Geometry>) => getOlPoiClusterMarkerStyle(feature, privatePoiCategories, categoryId),
        className: `cluster-layer-${categoryId}`,
      })
      clusterLayer.setZIndex(14)
      map.addLayer(clusterLayer)
      return clusterLayer
    })
    setClusterLayerArray(newClusterLayerArray)
  } else if (clusterLayerArray.length > 0) {
    clusterLayerArray.forEach((clusterLayer) => map.removeLayer(clusterLayer))
    setClusterLayerArray([])
  }
}

export const getOlPoiClusterMarkerStyle = (
  feature: Feature<Geometry>,
  categories: PrivatePOICategoriesList,
  categoryId: string
): Style | Style[] => {
  const getClusterIcon = () => {
    const category = categories.find((category) => category.id === categoryId)
    return category ? category.icon : "002"
  }
  const getClusterStyle = (feature: ol.Feature) => {
    const size = feature.get("features").length
    const marker = new style.Style({
      zIndex: 0,
      image: new style.Icon({
        opacity: 1,
        src: `${reportAssetsUrl ?? ""}/assets/private-data/${getClusterIcon()}.svg`,
        scale: 1.7,
        anchorOrigin: "top-left",
        anchor: [0.5, 1],
      }),
    })
    if (size === 1) {
      return marker
    } else {
      const sizeContainer = new style.Style({
        zIndex: 1,
        image: new style.Circle({
          radius: 14.5,
          displacement: [0, 42],
          stroke: new style.Stroke({
            color: "#4B4B5A",
            width: 3,
          }),
          fill: new style.Fill({
            color: "#fff",
          }),
        }),
        text: new style.Text({
          offsetY: -42,
          overflow: true,
          font: "bold 11px Arial",
          text: size > 0 ? size.toString() : "",
          fill: new Fill({
            color: "#000",
          }),
        }),
      })
      return [sizeContainer, marker]
    }
  }

  return getClusterStyle(feature)
}

export const valuesOfPriceRange = (maybePrices: PriceRange | undefined): number[] => Object.values(maybePrices ?? {})

export const minValidator =
  (min: number, errorMsg?: string): ReValidator<string> =>
  (value: string) => ({
    valid: parseInt(value) >= min,
    validationMessage: errorMsg ?? "",
  })

export const maxValidator =
  (max: number, errorMsg?: string): ReValidator<string> =>
  (value: string) => ({
    valid: parseInt(value) <= max,
    validationMessage: errorMsg ?? "",
  })

export const moreThanValidator =
  (moreThan: number, errorMsg: string): ReValidator<string> =>
  (value: string) => ({
    valid: parseFloat(value) > moreThan,
    validationMessage: errorMsg,
  })

export const lessThanValidator =
  (lessThan: number, errorMsg: string): ReValidator<string> =>
  (value: string) => ({
    valid: parseFloat(value) < lessThan,
    validationMessage: errorMsg,
  })

export const integerValidator =
  (errorMsg?: string): ReValidator<string> =>
  (value: string) => ({
    valid: isNumber(value) && value.match(/^[0-9]+$/) !== null,
    validationMessage: errorMsg ?? "",
  })

export const requiredValidator =
  (errorMsg: string): ReValidator<string> =>
  (value: string) => ({
    valid: !!value,
    validationMessage: errorMsg,
  })

export const numberValidator =
  (errorMsg?: string): ReValidator<string> =>
  (value) => ({
    valid: value === "" || isNumber(value),
    validationMessage: errorMsg ?? "",
  })

// This function takes an array of validators and returns a validator that will run all of them sequentially. It gives back the first one that fails
export function sequentialValidator(revalidatorArray: ReValidator<string>[]): ReValidator<string> {
  return (value: string) => {
    const firstFailedValidator = revalidatorArray.find((validator) => !validator(value).valid)
    if (firstFailedValidator) {
      return firstFailedValidator(value)
    } else {
      return { valid: true, validationMessage: "" }
    }
  }
}

export type StringKeys<T> = {
  [K in keyof T]: T[K] extends string ? string : never
}
