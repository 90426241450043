import { Profile, ProfileDetails } from "../models/profile"
import { translations } from "../i18n"
import React, { useRef } from "react"
import { useDrag, useDrop } from "react-dnd"
import Axios, { AxiosResponse } from "axios"
import { lanaApiUrl } from "../../app_config"
import { css } from "emotion"
import { PopupMenu } from "../../shared/components/ui/popup-menu"
import { getThemeColorVar } from "../../shared/helper/color"
import Icon from "../../shared/components/icon"

interface ItemType {
  index: number
  name: string
  type: string
}

interface DropResult {
  groupId: string | null
}

export const ProfileDnD = (props: {
  profile: Profile
  index: number
  onClickProfile: (profile: Profile) => void
  onDelete: (profile: Profile) => void
  setProfileToDelete: (profile: Profile) => void
  setProfileToLock: (profile: Profile) => void
  doFetchProfile: (id: string, agsRefResLoc: string) => void
  doProfileDuplicateStart: (profile: Profile, agsRefResLoc: string) => void
  doProfileRenameStart: (profile: Profile, agsRefResLoc: string) => void
  doUpdateProfileLocked: (id: string, locked: boolean) => void
  agsRefResLoc: string
  setUnableToDelete: (p: ProfileDetails) => void
  dndGroup: (profileId: string, groupId: string | null) => void
  currentProfile: null | Profile
  currentUserId: string
  isCompanyAdmin: boolean
}) => {
  const translation = translations()

  const ref = useRef<HTMLTableRowElement>(null)

  const [, drop] = useDrop({
    accept: "Type",
    hover(item: ItemType) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = props.index

      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      const hoverClientY = hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: "Type",
    item: { index: props.index, name: props.profile.name, type: "Type" } as ItemType,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>()
      props.dndGroup(props.profile.id, dropResult?.groupId ?? null)
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0.4 : 1

  drag(drop(ref))

  const isActive = props.currentProfile && props.currentProfile.id === props.profile.id
  const listItem = css({
    backgroundColor: isActive ? getThemeColorVar("background", "lighter") : undefined,
    label: `profile-id-${props.profile.id}`, // for tracking
  })

  // If lockedBy is `null` on backend, it does not get added to the JSON so can be undefined
  const isProfileLocked = props.profile.lockedBy !== undefined && props.profile.lockedBy !== null
  const userCanUnlock = props.isCompanyAdmin || props.profile.lockedBy === props.currentUserId

  return (
    <tr className={listItem} ref={ref} style={{ opacity }}>
      <td style={{ width: "100%", padding: "10px 0" }} onClick={() => props.onClickProfile(props.profile)}>
        {props.profile.name}
        {isProfileLocked && (
          <>
            &nbsp;
            <Icon name="locked" fontSize={14} />
          </>
        )}
      </td>
      <td style={{ padding: "10px 0" }}>
        <PopupMenu
          actions={[
            {
              title: translation.profileEdit.menuItem,
              onClick: () => {
                props.doFetchProfile(props.profile.id, props.agsRefResLoc)
              },
              disabled: isProfileLocked,
            },
            {
              title: translation.profileDuplicate.menuItem,
              onClick: () => {
                props.doProfileDuplicateStart(props.profile, props.agsRefResLoc)
              },
            },
            {
              title: translation.profileRename.menuItem,
              onClick: () => {
                props.doProfileRenameStart(props.profile, props.agsRefResLoc)
              },
              disabled: isProfileLocked,
            },
            "divider",
            {
              title: isProfileLocked ? translation.unlock : translation.lock,
              onClick: () => props.setProfileToLock(props.profile),
              disabled: !userCanUnlock && isProfileLocked,
            },
            "divider",
            {
              title: translation.profileDelete.menuItem,
              onClick: onDelete(props.profile),
              disabled: isProfileLocked,
            },
          ]}
        />
      </td>
    </tr>
  )

  function onDelete(profile: Profile) {
    return () => {
      Axios.get(`${lanaApiUrl}/api/profiles/${profile.id}`).then(
        (success: AxiosResponse) => {
          if (success.data.dependencies.length === 0) props.setProfileToDelete(profile)
          else props.setUnableToDelete(success.data)
        },
        () => {}
      )
    }
  }
}
