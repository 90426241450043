import React, { useEffect, useState } from "react"
import GoogleMapReact, { Bounds } from "google-map-react"
import { css } from "emotion"
import useSupercluster from "use-supercluster"
import { State } from "../../reducers/state"
import { ComparablesItemShort } from "../../../generated-apis/comparables-service"
import { createRoot } from "react-dom/client"
import { IsochroneType } from "../isochrone-type"
import Axios, { AxiosResponse, CancelTokenSource } from "axios"
import { googleMapsApiKey, googleMapsApiLibraries, lanaApiUrl } from "../../../app_config"
import { makePolygon, toGooglePolygonCoordinates } from "../../../utils/here-maps-isochrone"
import { IsochronePopup } from "../isochrone-popup"
import { reportAssetsUrl } from "../../../reports/report-config"
import { translations } from "../../i18n"
import { DataSource } from "../../models/comparables"
import { useAppSelector } from "../../../relas/store"
import { PrivateDataCategoryListPopup } from "../../../shared/components/privatedata-category-list-popup"
import { AllowedModulesEnum, PrivatePOIList } from "../../../private-data/models/private-data"
import {
  CategoryDataList,
  generateNewPoiClusterData,
  PrivatePoiClusters,
} from "../../../shared/components/private-poi-clusters"
import { Point } from "geojson"
import { PoiMarkerDialog } from "../../../shared/components/privatedata-poi-marker-dialog"
import { PoiMarkerType } from "../../../shared/components/poi-cluster-marker"
import { TooltipButtonContainer } from "../../../shared/components/tooltip-button-container"
import { useSelector } from "react-redux"
import {
  fetchCategories,
  fetchCategoryPois,
  updatePOIsToShow,
  updateSelectedCategories,
} from "../../../private-data/reducers/private-data-slice"
import {
  updateComparablesMapSettingsBounds,
  updateComparablesMapSettingsCenter,
  updateComparablesMapSettingsIsochrone,
  updateComparablesMapSettingsMapTypeId,
  updateComparablesMapSettingsZoom,
} from "../../reducers/comparables-slice"
import { PrivateDataCategoryListPopupNotBooked } from "../../../shared/components/private-data-category-list-popup-not-booked"
import Grid from "../../../shared/components/restyle-grid/grid"
import { getThemeColor } from "../../../shared/helper/color"
import { language } from "../../../shared/language"
import { OffersBox } from "./comparables-map/historical-offers-box"

type Props = {
  dataSource: DataSource
}

const styles = {
  mapContainer: css({
    height: "100%",
    width: "100%",
    position: "relative",
    backgroundColor: "rgb(229, 227, 223)",
  }),
  clusterMarker: css({
    font: "bold 12px Lato",
    width: "32px",
    height: "32px",
    color: "#fff",
    background: `${getThemeColor("primary", "default")}`,
    border: "2px solid #fff",
    borderRadius: "50%",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  }),
  selectedClusterMarker: css({
    font: "bold 12px Lato",
    width: "40px",
    height: "40px",
    color: `${getThemeColor("primary", "default")}`,
    background: "#fff",
    border: `2px solid ${getThemeColor("primary", "default")}`,
    borderRadius: "50%",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  }),
  pointMarker: css({
    position: "absolute",
    zIndex: -1,
    transform: "translate(-50%, -100%)",
    img: { width: "40px" },
  }),
  imgShadow: css({
    filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5))",
  }),
  closeIcon: css({
    i: { fontSize: "16px" },
    alignSelf: "center",
    justifySelf: "end",
    cursor: "pointer",
  }),
  controlContainer: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }),
}

type ObjectMarkerType = {
  type: "Feature"
  properties: { [name: string]: any }
  geometry: Point
}

type MarkerType = {
  key: string
  lat: number
  lng: number
}

interface ClusterMarkerType extends MarkerType {
  pointCount: number
  markerPoints: ObjectMarkerType[]
  index: number
}

const AssessmentEntryMarker = (props: MarkerType) => {
  return (
    <div className={styles.pointMarker}>
      <img src={`${reportAssetsUrl ?? ""}/assets/marker.svg`} alt="Assessment entry" className={styles.imgShadow} />
    </div>
  )
}

const centerBerlin = { lat: 52.520008, lng: 13.404954 }

const ClusterMarker = (
  props: ClusterMarkerType & { onClick: (data: ClusterMarkerType) => void; index: number; popup?: ClusterMarkerType }
) => {
  useEffect(() => {
    if (props.index === 0) props.onClick(props)
  }, [])

  return (
    <div
      className={props.index === props.popup?.index ? styles.selectedClusterMarker : styles.clusterMarker}
      onClick={(e) => props.onClick(props)}
    >
      {props.pointCount}
    </div>
  )
}

const module = AllowedModulesEnum.COMPARABLES
const offersListBoxCoord = {
  left: 15,
  top: 15,
}

const ComparablesMap = ({ dataSource }: Props) => {
  const mapSettings = useAppSelector((state) => state.comparablesApp.comparablesMapSettings)
  const isPrivateDataAccessible = useAppSelector((state) => state.user.scopes.privateData)
  const privatePoiCategories = useAppSelector((state) => state.privateData.privatePOICategories)
  const privateDataSettings = useAppSelector((state) => state.privateData.modulesWithPrivateData.comparables)

  const { selectedCategories, multipleCategoryPOIList, poisToShow } = privateDataSettings
  const comparablesItems = useSelector((state: State) => state.comparablesApp.comparablesItems)
  const currentAssessmentEntry = useSelector((state: State) => state.assessment.currentAssessmentEntry)
  const isLoadingItems = useSelector((state: State) => state.comparablesApp.comparablesItemsLoadInProgress)

  const [bounds, setBounds] = useState<Bounds | undefined>(mapSettings.bounds)
  const [zoom, setZoom] = useState(mapSettings.zoom)
  const [center, setCenter] = useState(mapSettings.center)
  const [popup, setPopup] = useState<ClusterMarkerType>()
  const [showIsochronePopup, setShowIsochronePopup] = useState(false)
  const [showPrivatePOIPopup, setShowPrivatePOIPopup] = useState(false)
  const [initialIsochroneLoaded, setInitialIsochroneLoaded] = useState(false)
  const [isochrone, setIsochrone] = useState<IsochroneType>(mapSettings.isochrone)
  const [gmap, setGmap] = useState<google.maps.Map>()
  const [gmaps, setGmaps] = useState<typeof google.maps>()
  const [isochronePolygon, setIsochronePolygon] = useState<google.maps.Polygon>()
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>()
  const [categoryDataList, setCategoryDataList] = useState<CategoryDataList>([])
  const [locationToShowPoiPopup, setLocationToShowPoiPopup] = useState<PoiMarkerType | undefined>(undefined)
  const [isPopUpHovered, setIsPopUpHovered] = useState<boolean>(false)

  const comparablesItemsMarkers: ObjectMarkerType[] =
    comparablesItems.length > 0
      ? comparablesItems.map((item) => ({
          type: "Feature",
          properties: {
            cluster: false,
            objectId: item.id,
          },
          geometry: {
            type: "Point",
            coordinates: [item.geom.lng, item.geom.lat],
          },
        }))
      : []

  const { clusters, supercluster } = useSupercluster({
    points: comparablesItemsMarkers,
    bounds: bounds && [bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat],
    zoom,
    options: { radius: 75, maxZoom: 23 },
  })

  const t = React.useMemo(translations, [translations])

  React.useEffect(() => {
    setZoom(mapSettings.zoom)
  }, [mapSettings.zoom])

  const getIsochronePolygon = (gmap: google.maps.Map, gmaps: typeof google.maps) => {
    if (
      currentAssessmentEntry &&
      currentAssessmentEntry?.address &&
      currentAssessmentEntry?.address.location &&
      isochrone.mode !== "none"
    ) {
      cancelToken?.cancel()
      const token = Axios.CancelToken.source()
      setCancelToken(token)
      Axios.get(`${lanaApiUrl}/api/v2/here/combined`, {
        params: {
          lat: currentAssessmentEntry.address.location.lat.toString(),
          lng: currentAssessmentEntry.address.location.lng.toString(),
          strictFilter: false,
          range: isochrone.time * 60,
          rangeType: "time",
          transportMode: isochrone.mode,
          language: language(),
        },
        cancelToken: token.token,
      })
        .then((success: AxiosResponse) => {
          if (gmaps) {
            const polygon = makePolygon(toGooglePolygonCoordinates(success.data))
            polygon.setMap(gmap)
            setIsochronePolygon(polygon)
          }
        })
        .catch(() => {})
    }
  }

  useEffect(() => {
    if (mapSettings.center !== center) {
      setCenter(mapSettings.center)
    }
  }, [mapSettings.center])

  useEffect(() => {
    setCategoryDataList(generateNewPoiClusterData(multipleCategoryPOIList))
  }, [privateDataSettings])

  useEffect(() => {
    if (currentAssessmentEntry?.address.location && center !== currentAssessmentEntry.address.location) {
      updateComparablesMapSettingsCenter(currentAssessmentEntry.address.location)
    }
  }, [currentAssessmentEntry?.address.location])

  useEffect(() => {
    if (!center) updateComparablesMapSettingsCenter(currentAssessmentEntry?.address.location || centerBerlin)
  }, [])

  useEffect(() => {
    if (isLoadingItems) {
      setPopup(undefined)
    }
  }, [isLoadingItems])

  useEffect(() => {
    if (!initialIsochroneLoaded && showIsochronePopup) {
      if (gmap && gmaps) {
        getIsochronePolygon(gmap, gmaps)
      }
      setInitialIsochroneLoaded(true)
    }
  }, [showIsochronePopup])

  useEffect(() => {
    if (isochronePolygon) {
      isochronePolygon.setMap(null)
    }
    if (gmap && gmaps) {
      getIsochronePolygon(gmap, gmaps)
    }
  }, [isochrone])

  useEffect(() => {
    if (showPrivatePOIPopup && showIsochronePopup) {
      setShowIsochronePopup(false)
    }
  }, [showPrivatePOIPopup])

  useEffect(() => {
    if (showPrivatePOIPopup && showIsochronePopup) {
      setShowPrivatePOIPopup(false)
    }
  }, [showIsochronePopup])

  const toObservedOffersItem = (item: ObjectMarkerType): ComparablesItemShort =>
    comparablesItems.filter((comparablesItem) => comparablesItem.id === item.properties.objectId)[0]

  const itemsArrayForMapPopup = (props: ClusterMarkerType) =>
    props.markerPoints
      .map((item: ObjectMarkerType) => toObservedOffersItem(item))
      .filter((x) => !!x)
      .sort((a, b) => a.id - b.id)

  const onClusterClick = (data: ClusterMarkerType) => {
    if (data.markerPoints.length > 0) {
      setPopup(data)
    }
  }

  const openPoiMarkerDialog = (location: PoiMarkerType, poisToDisplay: PrivatePOIList) => {
    setLocationToShowPoiPopup(location)
    updatePOIsToShow(poisToDisplay, module)
  }

  const closePoiMarkerDialog = () => {
    setLocationToShowPoiPopup(undefined)
    updatePOIsToShow([], module)
  }

  const handleOnLoad = (map: google.maps.Map, maps: typeof google.maps) => {
    map.setOptions({ isFractionalZoomEnabled: true })
    setGmap(map)
    setGmaps(maps)
    const controlButtonDiv = document.createElement("div")
    controlButtonDiv.className = styles.controlContainer
    createRoot(controlButtonDiv).render(
      <React.Fragment>
        <TooltipButtonContainer
          tooltip={"Private POIs"}
          buttonName={"private_poi"}
          onClick={() => setShowPrivatePOIPopup(true)}
        />

        <TooltipButtonContainer
          tooltip={t.isochrone}
          buttonName={"marker"}
          onClick={() => setShowIsochronePopup(true)}
        />
      </React.Fragment>
    )
    map.controls[maps.ControlPosition.RIGHT_BOTTOM].push(controlButtonDiv)
    if (mapSettings.mapTypeId) map.setMapTypeId(mapSettings.mapTypeId)

    if (mapSettings.isochrone.mode !== "none") {
      getIsochronePolygon(map, maps)
      setInitialIsochroneLoaded(true)
    }
    if (isPrivateDataAccessible) void fetchCategories()
  }

  return (
    <Grid columns={1} columnSpec={"1fr"} height={[100, "%"]}>
      <div id={"comparables-google-map" + Math.random()} className={styles.mapContainer}>
        {popup && (
          <OffersBox
            left={offersListBoxCoord.left}
            top={offersListBoxCoord.top}
            dataSource={dataSource}
            items={itemsArrayForMapPopup(popup)}
          />
        )}
        {center && (
          <GoogleMapReact
            onGoogleApiLoaded={({ map, maps }) => handleOnLoad(map, maps)}
            yesIWantToUseGoogleMapApiInternals
            bootstrapURLKeys={{
              key: googleMapsApiKey,
              libraries: googleMapsApiLibraries,
              id: "defaultGoogleMapScript",
            }}
            center={center}
            defaultZoom={zoom}
            options={{
              mapTypeControl: true,
              mapTypeControlOptions: { position: 3 },

              rotateControl: true,
              streetViewControl: true,
              zoomControl: true,
              zoomControlOptions: { position: 7 },
              fullscreenControl: true,
              fullscreenControlOptions: { position: 9 },
              draggableCursor: "default",
              disableDoubleClickZoom: isPopUpHovered,
            }}
            onMapTypeIdChange={(mapTypeId) => {
              updateComparablesMapSettingsMapTypeId(mapTypeId)
            }}
            onChange={({ zoom, bounds }) => {
              setZoom(zoom)
              setBounds(bounds)
              updateComparablesMapSettingsZoom(zoom)
              updateComparablesMapSettingsBounds(bounds)
            }}
          >
            {comparablesItemsMarkers.length > 0 &&
              clusters.map((cluster, index) => {
                const [longitude, latitude] = cluster.geometry.coordinates
                const { cluster: isCluster, point_count: pointCount, cluster_id, objectId } = cluster.properties
                return (
                  <ClusterMarker
                    key={isCluster ? `comparables:cluster:${cluster_id}` : `comparables:marker:${objectId}`}
                    lat={latitude}
                    lng={longitude}
                    pointCount={isCluster ? pointCount : 1}
                    markerPoints={
                      isCluster
                        ? supercluster
                          ? supercluster.getLeaves(cluster.id as number, Infinity)
                          : []
                        : [cluster]
                    }
                    onClick={onClusterClick}
                    index={index}
                    popup={popup}
                  />
                )
              })}

            {categoryDataList.length > 0 &&
              bounds &&
              categoryDataList.flatMap((categoryData, idx) => {
                const props = {
                  categoryDataList: categoryDataList,
                  categoryData: categoryData,
                  zoom: zoom,
                  bounds: bounds,
                  categories: selectedCategories,
                  dataIndex: idx,
                  openDialog: openPoiMarkerDialog,
                }
                return PrivatePoiClusters(props)
              })}

            {locationToShowPoiPopup && poisToShow?.length > 0 && (
              <PoiMarkerDialog
                lat={locationToShowPoiPopup.lat}
                lng={locationToShowPoiPopup.lng}
                poisToShow={poisToShow}
                onClose={closePoiMarkerDialog}
                onHover={setIsPopUpHovered}
                entryPinLocation={currentAssessmentEntry?.address.location}
              />
            )}

            {currentAssessmentEntry && currentAssessmentEntry.address.location && (
              <AssessmentEntryMarker
                key={currentAssessmentEntry.id}
                lat={currentAssessmentEntry.address.location.lat}
                lng={currentAssessmentEntry.address.location.lng}
              />
            )}
          </GoogleMapReact>
        )}
        {showPrivatePOIPopup &&
          (isPrivateDataAccessible ? (
            <PrivateDataCategoryListPopup
              onClose={() => setShowPrivatePOIPopup(false)}
              categories={privatePoiCategories}
              selectedCategories={selectedCategories}
              module={module}
              updateCategories={updateSelectedCategories}
              getPois={fetchCategoryPois}
              showCloseButton
            />
          ) : (
            <PrivateDataCategoryListPopupNotBooked onClose={() => setShowPrivatePOIPopup(false)} isContained={false} />
          ))}
        {showIsochronePopup && (
          <IsochronePopup
            isochrone={isochrone}
            onChange={(field, value) => {
              setIsochrone({ ...isochrone, [field]: value })
              updateComparablesMapSettingsIsochrone({ ...isochrone, [field]: value })
            }}
            assessmentEntry={currentAssessmentEntry}
            onClose={() => setShowIsochronePopup(false)}
            moduleName={"COMPARABLES"}
          />
        )}
      </div>
    </Grid>
  )
}

export default ComparablesMap
