import * as React from "react"

import { bind } from "decko"
import { css, cx } from "emotion"
import FlexContainer from "./restyle-grid/flexcontainer"
import { translations } from "../i18n"
import { SharedTranslations } from "../i18n/translations"
import {
  GenericInputElementProps,
  GenericInputElementState,
  ReValidator,
  ValidationError,
  ValidationResult,
} from "../models/genericinputelement"
import Icon from "./icon"
import { Label } from "./label"
import { getThemeColorVar } from "../helper/color"

const inputClass = css({
  width: "100%",
  "input,select,textarea": {
    backgroundColor: getThemeColorVar("background", "default"),
    border: "none",
    color: getThemeColorVar("typo", "default"),
    display: "block",
    fontWeight: 400,
    lineHeight: "20px",
    padding: "9px",
    width: "100%",
    minWidth: "0px",
    borderRadius: "4px",

    "&:focus": {
      backgroundColor: getThemeColorVar("background", "default"),
      borderColor: getThemeColorVar("background", "lighter"),
      outline: "none",
    },

    "&::placeholder": {
      color: getThemeColorVar("typo", "lighter"),
      fontStyle: "italic",
    },

    "&:disabled": {
      cursor: "not-allowed",
      color: getThemeColorVar("typo", "default"),
    },

    "&:invalid": {
      boxShadow: "none",
    },
  },

  textarea: {
    minWidth: "100%",
    maxWidth: "100%",
    resize: "vertical",
  },

  label: {
    color: getThemeColorVar("typo", "default"),
    display: "block",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "25px",
    marginBottom: "5px",
  },
})

const inputSmallClass = css({
  "input,select,textarea,p": {
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "20px",
    padding: "5px 0",
  },
  input: {
    padding: "7px",
  },
})

const inputWrapperClass = css({
  border: `1px solid ${getThemeColorVar("border", "default")}`,
  backgroundColor: getThemeColorVar("background", "lighter"),
  borderRadius: "4px",
  "&:focus-within": {
    border: `1px solid ${getThemeColorVar("border", "dark")}`,
  },
})

const inputWrapperIsInvalidClass = css({
  border: `1px solid ${getThemeColorVar("negative", "default")}`,
  color: getThemeColorVar("typo", "default"),
  borderRadius: "4px",
})

const inputWrapperHasWarningClass = css({
  border: `1px solid ${getThemeColorVar("neutral", "default")}`,
  color: getThemeColorVar("typo", "default"),
  borderRadius: "4px",
  "&:focus-within": {
    border: `1px solid ${getThemeColorVar("neutral", "default")}`,
  },
})

const disabledClass = css({
  "label:not(:focus)": {
    color: getThemeColorVar("typo", "light"),
  },

  "input[disabled]:not(:focus), select[disabled]:not(:focus)": {
    color: getThemeColorVar("typo", "default"),
    backgroundColor: getThemeColorVar("background", "lighter"),

    "&::placeholder": {
      color: getThemeColorVar("typo", "light"),
    },
  },
})

const inputWrapperIsDisabledClass = css({
  border: `1px solid ${getThemeColorVar("border", "default")}`,
})

const requiredMarkerClass = css({
  color: getThemeColorVar("negative", "default"),
  fontWeight: 600,
})

const validationErrorClass = css({
  margin: "5px 0 0 0",
  padding: 0,

  "> li": {
    listStyle: "none",
    color: getThemeColorVar("negative", "default"),
  },
})

export abstract class GenericInputElement<
  P,
  S extends GenericInputElementState,
  InputElement extends Element,
  T extends string | number | undefined = string
> extends React.Component<P & GenericInputElementProps<InputElement, T>, S> {
  public inputElRef: InputElement | null
  protected abstract renderInner(): JSX.Element

  protected validators: ReValidator<T>[]
  protected translate: SharedTranslations

  constructor(props: P & GenericInputElementProps<InputElement, T>) {
    super(props)
    this.translate = translations()
    this.validators = []
  }

  protected validate(value: T): ValidationError[] {
    const errors: ValidationError[] = []

    const maybeCustomValidators = this.props.customValidation
    const customValidators = maybeCustomValidators ?? []

    let activeValidators = this.validators.concat(customValidators)

    if (value === "") {
      // empty values are always valid (required should be used for that)
      activeValidators = []
    }

    if (this.props.required) {
      activeValidators.push((value) => {
        return {
          valid: value != "",
          validationMessage: this.translate.validationTranslations.required,
        }
      })
    }

    for (let validator of activeValidators) {
      const r: ValidationResult = validator(value)
      if (!r.valid) {
        errors.push(r)
      }
    }

    if (this.inputElRef) {
      const ref = this.inputElRef as any
      if (ref.setCustomValidity) {
        if (errors.length > 0) {
          ref.setCustomValidity(errors[0].validationMessage)
        } else {
          ref.setCustomValidity("")
        }
      }
    }

    return errors
  }

  protected defaultState: GenericInputElementState = {
    isTouched: false,
    isPristine: true,
  }

  @bind
  protected defaultHandleFocus(event: React.FocusEvent<InputElement>) {
    if (document.activeElement !== this.inputElRef) {
      // Ignore window blur
      this.setState({
        isTouched: true,
      })
    }

    this.props.onFocus && this.props.onFocus(event)
  }

  @bind
  protected defaultHandleBlur(event: React.FocusEvent<InputElement>) {
    if (document.activeElement !== this.inputElRef) {
      // Ignore window blur
      this.setState({
        isTouched: true,
      })
    }
    this.props.onBlur && this.props.onBlur(event)
  }

  renderErrors(validationErrors: ValidationError[]) {
    return (
      validationErrors.length > 0 && (
        <ul className={cx(validationErrorClass)}>
          {validationErrors.map((error, idx) => (
            <li key={idx}>
              <FlexContainer spaceBetween="base">
                <Icon name="cancel" />
                <span>{error.validationMessage}</span>
              </FlexContainer>
            </li>
          ))}
        </ul>
      )
    )
  }

  render() {
    const divClasses = [inputClass]
    const inputWrapperClasses = [inputWrapperClass]
    const validationErrors = this.validate(this.props.value)

    this.props.size === "small" && divClasses.push(inputSmallClass)

    validationErrors.length > 0 && !this.state.isPristine && inputWrapperClasses.push(inputWrapperIsInvalidClass)

    this.props.hasWarning && inputWrapperClasses.push(inputWrapperHasWarningClass)

    if (this.props.disabled) {
      divClasses.push(disabledClass)
      inputWrapperClasses.push(inputWrapperIsDisabledClass)
    }

    return (
      <div className={cx(divClasses)}>
        {this.props.label && (
          <FlexContainer spaceBetween="sm">
            <Label text={this.props.label || ""} />
            {this.props.required && <span className={cx(requiredMarkerClass)}> *</span>}
          </FlexContainer>
        )}

        <div className={cx(inputWrapperClasses)}>{this.renderInner()}</div>
        {!this.state.isPristine && this.renderErrors(validationErrors)}
      </div>
    )
  }
}

export default GenericInputElement
