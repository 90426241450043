import * as React from "react"

export interface Props {
  grade: {
    label: string
    color?: string
  }
}

export const ListRatingGradeLabel: React.FunctionComponent<Props> = (props) => {
  const { grade } = props

  return (
    <div
      style={{
        margin: "2px",
        padding: "2px",
        lineHeight: "14px",
        fontSize: "12px",
        height: "20px",
        minWidth: "20px",
        backgroundColor: grade.color,
        borderRadius: "10px",
        textAlign: "center",
        color: "white",
      }}
    >
      {grade.label}
    </div>
  )
}
