import * as React from "react"
import { Flex } from "../shared/components/ui/flex"
import { Switch } from "@blueprintjs/core"
import { featureEnabled, setFeature, UI_FEATURE, UI_FEATURES_SET } from "../utils/features-toggle"
import Button from "../shared/components/button"

export const FeaturePage: React.FunctionComponent = () => {
  const [featureSet, setFeatureSet] = React.useState<Map<UI_FEATURE, boolean>>(
    new Map(Object.keys(UI_FEATURES_SET).map((k: UI_FEATURE) => [k, featureEnabled(k)]))
  )

  function toggleAllFeatures(state: boolean) {
    Object.keys(UI_FEATURES_SET).forEach((k: UI_FEATURE) => {
      setFeature(k, state)
    })
    setFeatureSet(new Map(Object.keys(UI_FEATURES_SET).map((k: UI_FEATURE) => [k, state])))
  }

  return (
    <Flex flexDirection={"column"} gap={8} padding={16}>
      <h1>Features</h1>
      {Object.keys(UI_FEATURES_SET).map((k: UI_FEATURE) => (
        <Switch
          key={k}
          label={UI_FEATURES_SET[k]}
          checked={featureSet.get(k)}
          onChange={(event) => {
            setFeature(k, event.currentTarget.checked)
            setFeatureSet(new Map(featureSet).set(k, event.currentTarget.checked))
          }}
        />
      ))}
      <Flex gap={8} flexDirection={"row"}>
        <Button type={"primary"} onClick={() => toggleAllFeatures(true)}>
          Turn on all
        </Button>
        <Button type={"secondary"} onClick={() => toggleAllFeatures(false)}>
          Turn off all
        </Button>
      </Flex>
    </Flex>
  )
}
