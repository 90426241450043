import * as React from "react"
import { Assessment } from "../models/assessment"
import { translations } from "../i18n"
import { useEffect } from "react"
import { renameAssessment } from "../reducers/assessment-slice-functions"
import SidePanel from "../../shared/components/sidepanel"
import Grid from "../../shared/components/restyle-grid/grid"
import TextField from "../../shared/components/textfield"

interface OwnProps {
  toRename: Assessment | null
  onClose: () => void
}
export const AssessmentRename = ({ toRename, onClose }: OwnProps) => {
  const t = translations()
  const [newTitle, setNewTitle] = React.useState(toRename?.title || "")

  useEffect(() => {
    setNewTitle(toRename?.title || "")
  }, [toRename])

  return (
    <SidePanel
      title={t.assessment.rename}
      onClose={onClose}
      opened={toRename != null}
      actions={[
        {
          label: t.save,
          icon: "save",
          disabled: newTitle.length === 0,
          action: () => {
            toRename && void renameAssessment(toRename.id, newTitle)
            onClose()
          },
        },
        { label: t.cancel, action: onClose },
      ]}
    >
      <Grid columns={1}>
        <TextField label={t.assessment.title} value={newTitle} onValueChange={setNewTitle} />
      </Grid>
    </SidePanel>
  )
}
