import * as React from "react"
import { useEffect, useMemo } from "react"
import { useAppSelector } from "../../relas/store"
import { FieldPath, PathValue, useForm, useFormState } from "react-hook-form"
import {
  AssessmentEntry,
  AssessmentPriceType,
  Exclusiveness,
  HouseOrApartment,
  houseOrApartmentForUsageType,
  PRICE_TYPES,
  priceTypeForUsageType,
} from "../models/assessment"
import { translations } from "../i18n"
import { MenuItem } from "@blueprintjs/core"
import { css } from "emotion"

import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { trackUsageEvent } from "../../utils/usage-tracking"
import { Select } from "@blueprintjs/select"
import { IItemRendererProps } from "@blueprintjs/select/src/common/itemRenderer"
import { Button as ButtonBlueprint } from "@blueprintjs/core/lib/esm/components/button/buttons"
import { updateAssessmentEntryDetails } from "../reducers/assessment-slice-functions"
import { maxYear, minConstructionYear } from "./assessment-entry-object-price-inputs"
import {
  maxValidator,
  minValidator,
  moreThanValidator,
  numberValidator,
  requiredValidator,
  sequentialValidator,
  StringKeys,
} from "../../utils/utils"
import { NumberInput } from "../../shared/components/ui/number-input"
import { yearValidator } from "../../utils/form-validators"
import Dialog from "../../shared/components/dialog"
import Grid from "../../shared/components/restyle-grid/grid"
import Button from "../../shared/components/button"
import { INITIAL_CONFIGURATION_STATE } from "./quick-start/new-assessment-configuration"

type AssessmentEntryDetailEditProps = {
  isOpen: boolean
  toggle: () => void
  assessmentCreation?: boolean
  newAssessmentConfiguration?: AssessmentEntryFormData
  setNewAssessmentConfiguration?: (value: AssessmentEntryFormData) => void
}

export type AssessmentEntryFormData = {
  usageType: AssessmentPriceType
  houseOrApartment: HouseOrApartment
  buildingYear: number
  newBuilding: boolean
  area: number
  exclusiveness: Exclusiveness
}

const formGridStyle = css({
  display: "grid",
  gridTemplateColumns: "max-content 1fr",
  gap: "16px",
  alignItems: "center",
})

const dialogContainerStyle = css({
  padding: "16px",
})

const styles = {
  dropdownMenuItem: css({
    flexDirection: "column",
  }),
}

const ct = translations()

export const AssessmentUnitPriceYupSchemaSettings = {
  buildingYear: yup.number().when("newBuilding", {
    is: true,
    then: yup.number().optional(),
    otherwise: yup.number().required().min(1700, ct.validationTranslations.min(1700)),
  }),
  newBuilding: yup.boolean().required(),
  area: yup
    .number()
    .required()
    .moreThan(0, ct.validationTranslations.moreThan(0))
    .max(99999, ct.validationTranslations.max(99999)),
}
export const AssessmentUnitPriceYupSchema = yup.object(AssessmentUnitPriceYupSchemaSettings)

export interface OptionItem {
  label: string
  text?: string
}
export const assessmentEntrySimpleCellRenderer = <T extends string | number>(
  key: T,
  item: OptionItem | undefined,
  { modifiers, handleClick }: IItemRendererProps
) => {
  return (
    <MenuItem
      className={styles.dropdownMenuItem}
      {...modifiers}
      label={item?.text}
      text={item?.label}
      key={key}
      onClick={handleClick}
      shouldDismissPopover={false}
      labelClassName={"selectLabel"}
      textClassName={"textLabel"}
      multiline={true}
    />
  )
}
function getAssessmentEntryFormDataDefaultValues(
  assessmentEntry: AssessmentEntry | null,
  newAssessmentConfiguration?: AssessmentEntryFormData,
  assessmentCreation?: boolean
): AssessmentEntryFormData | undefined {
  if (assessmentEntry && !assessmentCreation) {
    const priceType = priceTypeForUsageType(assessmentEntry.usageType) ?? "residentialRent"
    const houseOrApartment = houseOrApartmentForUsageType(assessmentEntry.usageType) ?? "apartment"

    return {
      usageType: priceType,
      houseOrApartment,
      buildingYear: assessmentEntry.year ?? 2000,
      newBuilding: !!assessmentEntry.newBuilding,
      area: assessmentEntry.area ?? 100,
      exclusiveness: assessmentEntry.exclusiveness ?? Exclusiveness.Standard,
    }
  } else if (newAssessmentConfiguration) {
    return newAssessmentConfiguration
  }

  return INITIAL_CONFIGURATION_STATE
}

const AssessmentEntryDetailEdit = (props: AssessmentEntryDetailEditProps) => {
  const assessmentEntry = useAppSelector((state) => state.assessment.currentAssessmentEntry)
  const assessment = useAppSelector((state) => state.assessment.currentAssessment)
  const assessmentEntryFormDataDefaultValues = getAssessmentEntryFormDataDefaultValues(
    assessmentEntry,
    props.newAssessmentConfiguration,
    props.assessmentCreation
  )

  const resolver = yupResolver(AssessmentUnitPriceYupSchema)
  const { register, reset, watch, setValue, setError, clearErrors, handleSubmit, control } =
    useForm<AssessmentEntryFormData>({
      defaultValues: assessmentEntryFormDataDefaultValues,
      mode: "all",
      resolver: resolver,
    })

  const { isSubmitting, errors } = useFormState({ control })

  const t = translations()

  const isValid = Object.keys(errors).length === 0

  const { usageTypeOptions, exclusivenessOptions, priceCategoryOptions } = useMemo(() => {
    const usageTypes = new Map<AssessmentPriceType, OptionItem>(
      PRICE_TYPES.map((key) => {
        return [key, { label: t.quickStart.usageType[key] }]
      })
    )

    const exclusiveness = new Map(
      Object.values<Exclusiveness>(Exclusiveness).map((exclusiveness) => {
        const translation = t.assessmentEntryDetails.exclusivenessDropdown[exclusiveness]
        return [exclusiveness, translation]
      })
    )

    const houseOrApt = new Map(
      ["apartment", "house"].map((key: HouseOrApartment) => {
        return [key, { label: t.assessmentEntryDetails.priceCategories[key] }]
      })
    )

    return {
      usageTypeOptions: usageTypes,
      exclusivenessOptions: exclusiveness,
      priceCategoryOptions: houseOrApt,
    }
  }, [t])

  const isResidential = useMemo(() => {
    return watch("usageType") === "residentialRent" || watch("usageType") === "residentialSale"
  }, [watch("usageType")])

  useEffect(() => {
    if (props.isOpen) {
      reset(assessmentEntryFormDataDefaultValues)
    }
  }, [props.isOpen, assessmentEntry])

  const onNumberInputChange =
    (inputName: keyof StringKeys<AssessmentEntryFormData>) => (newValue: number, _: string, valid: boolean) => {
      if (valid) {
        if (errors[inputName]) clearErrors(inputName)
        setValue(inputName, newValue)
      } else setError(inputName, { message: "invalid" })
    }

  async function onSubmit(data: AssessmentEntryFormData) {
    if (assessment && assessmentEntry) {
      const formData = { ...data }

      await updateAssessmentEntryDetails(assessment.id, assessmentEntry.id, formData)
      trackUsageEvent(
        "CHANGE_ASSESSMENT_ENTRY_DETAILS",
        assessmentEntry.address,
        `${window.location.host}/${window.location.pathname.split("/")[1]}`
      )
      props.toggle()
    } else if (props.setNewAssessmentConfiguration) {
      props.setNewAssessmentConfiguration(data)
      props.toggle()
    }
  }

  function onSubmitAssessmentCreation(data: AssessmentEntryFormData) {
    if (props.setNewAssessmentConfiguration) props.setNewAssessmentConfiguration(data)
    props.toggle()
  }

  function makeSelectDropdown<
    K extends FieldPath<AssessmentEntryFormData>,
    T extends PathValue<AssessmentEntryFormData, K> & string
  >(propName: K, option: Map<T, OptionItem>, disabled: boolean = false) {
    return (
      <Select<T>
        items={[...option.keys()]}
        popoverProps={{ usePortal: false }}
        activeItem={watch(propName) as T}
        className={"customBlueprintSelect"}
        disabled={disabled}
        itemRenderer={(item: T, itemProps) => assessmentEntrySimpleCellRenderer(item, option.get(item), itemProps)}
        filterable={false}
        onItemSelect={(v: T) => setValue(propName, v)}
      >
        <ButtonBlueprint text={option.get(watch(propName) as T)?.label} rightIcon="chevron-down" disabled={disabled} />
      </Select>
    )
  }

  return props.isOpen ? (
    <Dialog onClose={() => props.toggle()} closeOnClickOutside={true} width={"650px"}>
      <div className={dialogContainerStyle}>
        <h2 style={{ paddingBottom: "16px" }}>{t.header.changeAssessmentEntryDetails}</h2>
        <form
          className={formGridStyle}
          onSubmit={handleSubmit(props.assessmentCreation ? onSubmitAssessmentCreation : onSubmit)}
        >
          <label>{t.assessmentEntryDetails.usageTypesTitle}</label>
          {makeSelectDropdown("usageType", usageTypeOptions)}
          <label>{t.quickStart.yearOfConstructionAndRefurbishment}</label>
          <div>
            <NumberInput
              defaultValue={assessmentEntryFormDataDefaultValues?.buildingYear}
              buttonPosition={"right"}
              disabled={watch("newBuilding") ?? false}
              validator={sequentialValidator([
                requiredValidator(t.validationTranslations.required),
                minValidator(minConstructionYear, t.validationTranslations.min(minConstructionYear)),
                maxValidator(maxYear, t.validationTranslations.max(maxYear)),
                numberValidator(t.validationTranslations.notANumber),
                yearValidator(t.validationTranslations.constructionYearValidation),
              ])}
              onValueChange={onNumberInputChange("buildingYear")}
            />
          </div>
          <div></div>
          <Grid columnSpec={"max-content min-content"} columns={2} gap={8}>
            <label>{t.assessmentEntryDetails.newBuilding}</label>
            <input type="checkbox" {...register("newBuilding", { deps: "buildingYear" })} />
          </Grid>
          <label>{t.assessmentEntryDetails.livingArea}</label>
          <div>
            <NumberInput
              defaultValue={assessmentEntryFormDataDefaultValues?.area}
              buttonPosition={"right"}
              validator={sequentialValidator([
                requiredValidator(t.validationTranslations.required),
                moreThanValidator(0, t.validationTranslations.moreThan(0)),
                maxValidator(99999, t.validationTranslations.max(99999)),
                numberValidator(t.validationTranslations.notANumber),
              ])}
              onValueChange={onNumberInputChange("area")}
              suffix={"m²"}
            />
          </div>
          <label>{t.assessmentEntryDetails.quality}</label>
          {makeSelectDropdown("exclusiveness", exclusivenessOptions)}
          <label>{t.assessmentEntryDetails.columns.usageType}</label>
          {makeSelectDropdown("houseOrApartment", priceCategoryOptions, !isResidential)}
          <div></div>
          <Grid gap={8} columnSpec={"max-content max-content"}>
            <Button type={"primary"} disabled={isSubmitting || !isValid}>
              {t.save}
            </Button>
            <Button type={"tertiary"} size={"small"} onClick={props.toggle}>
              {t.cancel}
            </Button>
          </Grid>
        </form>
      </div>
    </Dialog>
  ) : null
}

export default AssessmentEntryDetailEdit
