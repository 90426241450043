import * as React from "react"
import { RevoApplicationMenu } from "./revo-application-menu"
import { userApps } from "./util/app-authorization"
import { scopesFromCookie } from "./util/scorefromcookies"
import { useEffect, useRef, useState } from "react"
import { useAppSelector } from "../relas/store"
import { CookieScopeRequirement } from "./models/scoperequirements"
import { filterObject } from "./util/filter-object"
import { loadAndHandleUser, updateAppScopes, updateUserIsAuthorised } from "../relas/user-slice"
import { ReloginPortal } from "./relogin-portal"
import { getCookieValue, setCookie } from "../shared/helper/cookies"
import { getCurrentDomain, getSanitizedDomain, getServiceName } from "../shared/helper/domain"
import { axiosCallWithLocalhostFallback } from "../shared/helper/axios"
import { revoBreakpoints } from "../shared/util/layoutconstants"
import { isUnloggedPage } from "../relas/router"

type Props = {
  user?: User
}

const HOVER_DELAY_TIMEOUT = 300

const REVO_MAIN_MENU_OPEN_COOKIE = "TS_revoMainMenuOpen" // TS* prefixed cookies are persisted by WIX, others are removed.

export const RevoLastPathCookie = "TS_REVO_MAIN_MENU_LAST_PATH" // TB-1154 - TS* prefixed cookies are persisted by WIX, others are removed.

type CustomerLastPathEntry = {
  service: string
  path: string
  ts?: number
}

function persistUserApplicationPath(customerId: string) {
  const inOneYear = new Date()
  inOneYear.setFullYear(new Date().getFullYear() + 1)
  const cookiePath = getCookieValue(RevoLastPathCookie)
  let previousPaths = cookiePath ? JSON.parse(cookiePath) : {}
  const newServiceName = getServiceName(window.location)
  let newPath = window.location.pathname.split("/", 3).slice(1)

  if (newPath.length > 0 && newPath[0] === "login") {
    return
  }

  // All company and user paths are not truncated to 2 segments
  if (newPath.length === 2 && !(newPath[0] === "company" || newPath[0] === "user")) {
    newPath = newPath.slice(0, 1)
  }

  // we don't allow cookie to grow too big. Max 2 customer ids
  const maxCustomerRecords = 2
  const customerSettings: { [k: string]: CustomerLastPathEntry } = filterObject(previousPaths, ([key]) =>
    key.startsWith("customer/")
  )

  customerSettings[`customer/${customerId}`] = {
    service: newServiceName ?? "",
    path: newPath.join("/"),
    ts: Math.round(Date.now()), // UNIX timestamp in seconds
  }

  let customerPaths = Object.entries(customerSettings).sort(([, value1], [, value2]) => {
    return (value2.ts || 0) - (value1.ts || 0)
  })

  if (customerPaths.length > maxCustomerRecords) {
    customerPaths.length = maxCustomerRecords
  }

  const newCookieData = Object.fromEntries(customerPaths)

  setCookie(RevoLastPathCookie, JSON.stringify(newCookieData), inOneYear, getSanitizedDomain(window.location))
}

/**
 * Checks if the user is authorized to access the current application.
 * It could be undefined if we can't verify the user due to server error.
 */
export async function refreshAuthToken(): Promise<boolean | undefined> {
  const call = {
    domain: getCurrentDomain(window.location),
    serviceName: "login",
    pathWithoutLeadingSlash: "api/refreshToken",
  }

  return axiosCallWithLocalhostFallback(call, { withCredentials: true }, {}, 200)
    .then((response) => {
      if (Math.trunc(response.status / 100) === 2) {
        return true
      } else if (response.status === 401) {
        return false
      }
      return undefined
    })
    .catch((err) => {
      if ("isAxiosError" in err && err.isAxiosError && err.response?.status === 401) {
        return false
      }

      console.error("Refresh Auth token failed: ", err)
      return undefined
    })
}

export const RevoLoggedInMenu = (props: Props) => {
  const [hoverDelayTimer, setHoverDelayTimer] = useState<number>()
  const isMainMenuOpen = () => {
    const cookieValue = getCookieValue(REVO_MAIN_MENU_OPEN_COOKIE)

    const isMobile: boolean = window.innerWidth <= revoBreakpoints.mobileLandscape.max

    return (cookieValue ? cookieValue === "true" : true) && !isMobile
  }
  const currentPage = useAppSelector((state) => state.navigation.currentPage)
  const [scopes, setScopes] = useState(scopesFromCookie())
  const [mainMenuOpen, setMainMenuOpen] = useState(isMainMenuOpen())
  const [isHovered, setIsHovered] = useState(false)
  const [appDrawerOpen, setAppDrawerOpen] = useState(false)
  const isAuthorised = useAppSelector((state) => state.user.isAuthorised)
  const isAuthorisedRef = useRef(isAuthorised)
  const [isLoadingAuthorization, setIsLoadingAuthorization] = useState(false)
  const [isLoadingUserData, setIsLoadingUserData] = useState(false)
  const [loginRefreshTimer, setLoginRefreshTimer] = useState<number>()
  const appLocation = useAppSelector((state) => state.navigation.menuAppLocation)
  const [persistUserApplicationPathTimeout, setPersistUserApplicationPathTimeout] = useState<number>()
  const address = useAppSelector((state) => state.assessment.currentAssessmentEntry?.address)

  const persistUserApplicationPathWithTimeout = () => {
    if (!appLocation) return
    const userId = props.user?.id
    if (!userId) return

    if (persistUserApplicationPathTimeout) {
      clearTimeout(persistUserApplicationPathTimeout)
    }

    setPersistUserApplicationPathTimeout(
      window.setTimeout(() => {
        persistUserApplicationPath(userId)
      }, 500)
    )
  }

  useEffect(persistUserApplicationPathWithTimeout, [appLocation])

  useEffect(() => {
    isAuthorisedRef.current = isAuthorised
  }, [isAuthorised])

  useEffect(() => {
    persistUserApplicationPathWithTimeout()

    setIsLoadingUserData(true)
    void loadAndHandleUser().then(() => {
      setIsLoadingUserData(false)
    })

    checkAuthStatus()
    setLoginRefreshTimer(window.setInterval(checkAuthStatus, 30000))

    return () => {
      if (loginRefreshTimer) window.clearInterval(loginRefreshTimer)
    }
  }, [])

  const checkAuthStatus = () => {
    if (!isAuthorisedRef.current && !isLoadingUserData) return

    setIsLoadingAuthorization(true)
    void refreshAuthToken().then((isAuth) => {
      setIsLoadingAuthorization(false)
      if (isAuth === undefined) {
        // we can't verify the user due to server error - we will try again later
        return
      } else if (isAuth) {
        setAuthorizedAndUpdateScopes()
      } else {
        if (isAuthorisedRef.current) {
          updateUserIsAuthorised(false)
        }
      }
    })
  }

  const setAuthorizedAndUpdateScopes = () => {
    // we might receive updated scopes
    const scopesFromCookies = scopesFromCookie()

    if (JSON.stringify(scopesFromCookies) !== JSON.stringify(scopes)) {
      updateAppScopes(scopesFromCookies)
      setScopes(scopesFromCookies)
      redirectOnScopeChange(scopesFromCookies)
    }

    updateUserIsAuthorised(true)
  }

  const redirectOnScopeChange = (newScopesFromCookies: CookieScopeRequirement[]) => {
    const currentApp = appLocation?.app

    if (!currentApp) {
      return
    }
    const currentAppItem = userApps[currentApp]

    if (!currentAppItem) {
      return
    }

    if (!currentAppItem?.scopeRequirements(newScopesFromCookies)) {
      const redirectTo = "/user/profile"
      console.error("No scopes for this scope requirements, redirecting to", redirectTo)
      if (!window.location.href.includes(redirectTo)) {
        window.location.href = redirectTo
      }
    }
  }

  const toggleMainMenu = () => {
    const newState = !mainMenuOpen

    setMainMenuOpen(newState)

    const inOneYear = new Date()
    inOneYear.setFullYear(new Date().getFullYear() + 1)

    setCookie(REVO_MAIN_MENU_OPEN_COOKIE, `${newState}`, inOneYear, getSanitizedDomain(window.location))
  }

  const openMenu = () => {
    if (!hoverDelayTimer) {
      setHoverDelayTimer(window.setTimeout(() => setIsHovered(true), HOVER_DELAY_TIMEOUT))
    }
  }

  const closeMenu = () => {
    if (hoverDelayTimer) {
      clearInterval(hoverDelayTimer)
    }
    setHoverDelayTimer(undefined)
    setIsHovered(false)
  }

  const unloggedPage = currentPage ? isUnloggedPage(currentPage.name) : true

  return (
    <>
      {!isLoadingUserData && (
        <RevoApplicationMenu
          user={props.user}
          open={false}
          overlay={isHovered}
          location={appLocation}
          toggleMainMenu={toggleMainMenu}
          toggleAppDrawer={() => setAppDrawerOpen(!appDrawerOpen)}
          onMouseEnter={openMenu}
          onMouseLeave={closeMenu}
          menuIsHovered={isHovered}
          scopes={scopes}
          address={address}
        />
      )}

      {!isAuthorised && !isLoadingAuthorization && !isLoadingUserData && !unloggedPage && (
        <ReloginPortal onWindowVisible={checkAuthStatus} />
      )}
    </>
  )
}
