import * as React from "react"
import { css, cx } from "emotion"
import { PropsWithChildren } from "react"
import { getThemeColorVar } from "../helper/color"
import { EmotionCSS } from "../helper/responsive"

export interface Props {
  /** Header/title of the card */
  header?: string | JSX.Element
  footer?: string | JSX.Element
  id?: string
  growingElement?: "header" | "body" | "footer"
}

const reCardClass = css({
  backgroundColor: getThemeColorVar("white", undefined),
  display: "grid",
  borderRadius: "5px",
  border: `1px solid ${getThemeColorVar("border", "default")}`,
} as EmotionCSS)

const reCardHeaderClass = css({
  backgroundColor: getThemeColorVar("white", undefined),
  padding: "10px 15px 9px 15px",
  borderRadius: "4px 4px 0 0",
  borderBottom: `1px solid ${getThemeColorVar("border", "default")}`,
  color: getThemeColorVar("typo", "dark"),
  fontWeight: 600,
} as EmotionCSS)

const reCardFooterClass = css({
  backgroundColor: getThemeColorVar("white", undefined),
  borderRadius: "0 0 4px 4px",
  borderTop: `1px solid ${getThemeColorVar("border", "default")}`,
  color: getThemeColorVar("typo", "dark"),
  padding: "9px 15px 10px 15px",
  fontWeight: 600,
} as EmotionCSS)

const headlineClass = css({
  fontSize: "16px",
  lineHeight: "25px",
})

const contentClass = css({
  padding: "15px",
})

function cardHeader(header: string | JSX.Element) {
  return (
    <div className={cx(reCardHeaderClass)}>
      {typeof header === "string" ? <h1 className={cx(headlineClass)}>{header}</h1> : header}
    </div>
  )
}

function cardFooter(footer: string | JSX.Element) {
  return (
    <div className={cx(reCardFooterClass)}>
      {typeof footer === "string" ? <h1 className={cx(headlineClass)}>{footer}</h1> : footer}
    </div>
  )
}

function computeTemplateRows(growingElement: "header" | "body" | "footer" | undefined) {
  switch (growingElement) {
    case "header":
      return "1fr fit-content(100%) fit-content(100%)"
    case "footer":
      return "fit-content(100%) fit-content(100%) 1fr"
    case "body":
    default:
      return "fit-content(100%) 1fr fit-content(100%)"
  }
}

export const Card: React.FunctionComponent<PropsWithChildren<Props>> = (props) => {
  const { footer, header, children, id, growingElement } = props

  return (
    <div className={cx(reCardClass, css({ gridTemplateRows: computeTemplateRows(growingElement) }))} id={id}>
      {header ? cardHeader(header) : undefined}
      <div className={contentClass}>{children}</div>
      {footer ? cardFooter(footer) : undefined}
    </div>
  )
}

export default Card
