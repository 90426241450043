import React, { FunctionComponent, PropsWithChildren } from "react"
import { css, cx } from "emotion"
import {
  ResponsiveSpec,
  RevoSize,
  RevoSizeSpec,
  revoSizeToPadding,
  SizeSpec,
  SpacingSize,
} from "../../util/layoutconstants"
import { ColorConfig, getThemeColorVar } from "../../helper/color"
import { EmotionCSS, revoSpecToCss } from "../../helper/responsive"

const gridClass = css({
  display: "grid",
})

const spacingMap: { [key in SpacingSize]: string } = {
  sm: "2.5px",
  base: "5px",
  md: "10px",
  lg: "15px",
  xl: "20px",
  xxl: "25px",
}

export interface GridProps {
  backgroundColor?: ColorConfig
  bordersColor?: ColorConfig
  columnSpec?: SizeSpec[] | string
  rowSpec?: SizeSpec[] | string
  columns?: number
  gap?: SpacingSize
  rowGap?: SpacingSize
  colGap?: SpacingSize
  minRowHeight?: SizeSpec
  gridAutoRows?: string
  paddingX?: SpacingSize
  paddingY?: SpacingSize
  height?: SizeSpec
  width?: SizeSpec
  zIndex?: number
  // disables pointer-events for grid-item and reenables it for
  // children. useful for stacked UI's (eg: controls over map)
  enablePointerEventPassthrough?: boolean
  className?: string
}

export interface BetterGridProps {
  padding?: RevoSizeSpec | ResponsiveSpec<RevoSizeSpec>
  backgroundColor?: ColorConfig
  bordersColor?: ColorConfig
  columnSpec?: SizeSpec[] | string
  rowSpec?: SizeSpec[] | string
  columns?: number
  gap?: RevoSize | ResponsiveSpec<RevoSize>
  rowGap?: RevoSize | ResponsiveSpec<RevoSize>
  colGap?: RevoSize | ResponsiveSpec<RevoSize>
  minRowHeight?: SizeSpec
  gridAutoRows?: string
  height?: SizeSpec
  width?: SizeSpec
  zIndex?: number
  // disables pointer-events for grid-item and reenables it for
  // children. useful for stacked UI's (eg: controls over map)
  enablePointerEventPassthrough?: boolean
  className?: string
}

function isBetterGridProps(props: any): props is BetterGridProps {
  return (
    props.padding !== undefined ||
    typeof props.gap === "object" ||
    typeof props.colGap === "object" ||
    typeof props.rowGap === "object" ||
    typeof props.gap === "number" ||
    typeof props.colGap === "number" ||
    typeof props.rowGap === "number"
  )
}

export const Grid: FunctionComponent<PropsWithChildren<GridProps | BetterGridProps>> = (props) => {
  const { backgroundColor, bordersColor } = props
  const classes = [
    gridClass,
    css({
      zIndex: props.zIndex,
      ...(props.enablePointerEventPassthrough
        ? {
            pointerEvents: "none",
            "> *": {
              pointerEvents: "initial",
            },
          }
        : {}),
    }),
  ]

  props.className && classes.push(props.className)

  if (!isBetterGridProps(props)) {
    const colGap = props.colGap || props.gap
    if (colGap) {
      classes.push(
        css({
          gridColumnGap: spacingMap[colGap],
        })
      )
    }
    const rowGap = props.rowGap || props.gap
    if (rowGap) {
      classes.push(
        css({
          gridRowGap: spacingMap[rowGap],
        })
      )
    }
    if (props.paddingX) {
      classes.push(
        css({
          paddingLeft: spacingMap[props.paddingX],
          paddingRight: spacingMap[props.paddingX],
        })
      )
    }
    if (props.paddingY) {
      classes.push(
        css({
          paddingTop: spacingMap[props.paddingY],
          paddingBottom: spacingMap[props.paddingY],
        })
      )
    }
  } else {
    const { gap, rowGap, colGap } = props
    const emotionStyles: EmotionCSS[] = []

    if (props.padding) {
      const { padding } = props
      emotionStyles.push(revoSpecToCss(padding, revoSizeToPadding))
    }

    if (gap !== undefined) emotionStyles.push(revoSpecToCss(gap, (i) => ({ gridGap: `${i}px` })))

    if (rowGap !== undefined) emotionStyles.push(revoSpecToCss(rowGap, (i) => ({ gridRowGap: `${i}px` })))

    if (colGap !== undefined) emotionStyles.push(revoSpecToCss(colGap, (i) => ({ gridColumnGap: `${i}px` })))

    classes.push(css(emotionStyles))
  }

  const templateColumns = props.columnSpec
    ? typeof props.columnSpec === "string"
      ? props.columnSpec
      : props.columnSpec.map((elem) => elem.join("")).join(" ")
    : `repeat(${props.columns || 12}, 1fr)`

  classes.push(
    css({
      gridTemplateColumns: templateColumns,
    })
  )

  if (backgroundColor) {
    classes.push(
      css({
        backgroundColor: getThemeColorVar(backgroundColor.color, backgroundColor.colorType),
      })
    )
  }

  if (bordersColor) {
    classes.push(
      css({
        borderTop: `1px solid ${getThemeColorVar(bordersColor.color, bordersColor.colorType)}`,
        borderBottom: `1px solid ${getThemeColorVar(bordersColor.color, bordersColor.colorType)}`,
      })
    )
  }

  if (props.gridAutoRows) {
    classes.push(
      css({
        gridAutoRows: props.gridAutoRows,
      })
    )
  } else if (props.minRowHeight) {
    classes.push(
      css({
        gridAutoRows: `minmax(${props.minRowHeight.join("")}, auto)`,
      })
    )
  }

  if (props.rowSpec) {
    classes.push(
      css({
        gridTemplateRows:
          typeof props.rowSpec === "string" ? props.rowSpec : props.rowSpec.map((elem) => elem.join("")).join(" "),
      })
    )
  }

  if (props.height) {
    classes.push(
      css({
        height: props.height.join(""),
      })
    )
  }

  if (props.width) {
    classes.push(
      css({
        width: props.width.join(""),
      })
    )
  }

  return <div className={cx("revo-grid", cx(classes))}>{props.children}</div>
}

export default Grid
