import * as React from "react"
import { css, cx } from "emotion"
import { Property } from "csstype"
import { PropsWithChildren } from "react"
import {
  ContainerLayoutProps,
  flexAlignMapping,
  FlexAlignmentType,
  ResponsiveSpec,
  RevoSize,
  RevoSizeSpec,
  revoSizeToPadding,
  SpacingSize,
} from "../../util/layoutconstants"
import { ColorConfig, getThemeColorVar } from "../../helper/color"
import { FlexDirection } from "../ui/flex"
import { EmotionCSS, revoSpecToCss } from "../../helper/responsive"

export type Alignment = "xs-align" | "sm-align" | "md-align" | "lg-align" | "xl-align"
export type AlignmentProps = { [a in Alignment]?: FlexAlignmentType }

export type Justify = "xs-justify" | "sm-justify" | "md-justify" | "lg-justify" | "xl-justify"
export type JustifyProps = { [a in Justify]?: FlexAlignmentType }

const Alignments: Alignment[] = ["xs-align", "sm-align", "md-align", "lg-align", "xl-align"]
const Justifies: Justify[] = ["xs-justify", "sm-justify", "md-justify", "lg-justify", "xl-justify"]

export type FlexWrap = "nowrap" | "wrap" | "wrap-reverse" | "inherit" | "initial" | "unset"

export interface BetterFlexContainerProps extends AlignmentProps, JustifyProps {
  backgroundColor?: ColorConfig
  direction?: FlexDirection
  canGrow?: boolean
  canShrink?: boolean
  flexWrap?: FlexWrap
  basis?: number | string
  overflow?: "visible" | "hidden" | "scroll" | "auto" | "inherit" | "initial" | "unset"
  padding?: RevoSizeSpec | ResponsiveSpec<RevoSizeSpec>
  gap?: RevoSize | ResponsiveSpec<RevoSize>
  height?: number | string
  borderWidth?:
    | [Property.BorderWidth]
    | [Property.BorderWidth, Property.BorderWidth]
    | [Property.BorderWidth, Property.BorderWidth, Property.BorderWidth]
    | [Property.BorderWidth, Property.BorderWidth, Property.BorderWidth, Property.BorderWidth]
  borderStyle?: Property.BorderStyle
  borderColor?: Property.BorderBlockColor
}

export interface Props extends AlignmentProps, JustifyProps, ContainerLayoutProps {
  backgroundColor?: ColorConfig
  direction?: FlexDirection
  canGrow?: boolean
  canShrink?: boolean
  flexWrap?: FlexWrap
  basis?: number | string
  overflow?: "visible" | "hidden" | "scroll" | "auto" | "inherit" | "initial" | "unset"
  height?: number | string
  borderWidth?:
    | [Property.BorderWidth]
    | [Property.BorderWidth, Property.BorderWidth]
    | [Property.BorderWidth, Property.BorderWidth, Property.BorderWidth]
    | [Property.BorderWidth, Property.BorderWidth, Property.BorderWidth, Property.BorderWidth]
  borderStyle?: Property.BorderStyle
  borderColor?: Property.BorderBlockColor
}

export interface FlexContainerStyle {
  flexGrow: number
  flexShrink: number
  flexBasis?: number | string
  flexWrap?: FlexWrap
  overflow?: "visible" | "hidden" | "scroll" | "auto" | "inherit" | "initial" | "unset"
}

const isBetterProps = (a: any): a is BetterFlexContainerProps =>
  (a.padding !== undefined || a.gap !== undefined) &&
  (a.paddingX === undefined || a.paddingY === undefined || a.spaceBetween === undefined)

const oldSizeMapping: { [key in SpacingSize]: number } = {
  sm: 2.5,
  base: 5,
  md: 10,
  lg: 15,
  xl: 20,
  xxl: 25,
}

export const FlexContainer: React.FunctionComponent<PropsWithChildren<Props | BetterFlexContainerProps>> = (props) => {
  const { backgroundColor } = props
  const classes: string[] = [
    css({
      display: "flex",
      height: props.height,
    }),
  ]

  backgroundColor &&
    classes.push(
      css({
        backgroundColor: getThemeColorVar(backgroundColor.color, backgroundColor.colorType),
      })
    )

  if (props.borderWidth) {
    classes.push(
      css({
        borderWidth: props.borderWidth.map((e) => `${e}px`).join(" "),
      })
    )
  }

  if (props.borderStyle) {
    classes.push(
      css({
        borderStyle: props.borderStyle,
      })
    )
  }

  if (props.borderColor) {
    classes.push(
      css({
        borderColor: props.borderColor,
      })
    )
  }

  if (isBetterProps(props)) {
    props.padding && classes.push(css(revoSpecToCss(props.padding, revoSizeToPadding)))

    props.gap &&
      classes.push(
        css(
          revoSpecToCss(props.gap, (i) => {
            if (props.direction === "row-reverse")
              return {
                "> :not(:first-child)": {
                  marginRight: i,
                },
              }
            else if (props.direction === "column-reverse")
              return {
                "> :not(:first-child)": {
                  marginBottom: i,
                },
              }
            else if (props.direction === "column")
              return {
                "> :not(:last-child)": {
                  marginBottom: i,
                },
              }
            else
              return {
                "> :not(:last-child)": {
                  marginRight: i,
                },
              }
          })
        )
      )
  } else {
    if (props.paddingX) {
      classes.push(
        css({
          paddingLeft: oldSizeMapping[props.paddingX],
          paddingRight: oldSizeMapping[props.paddingX],
        })
      )
    }

    if (props.paddingY) {
      classes.push(
        css({
          paddingTop: oldSizeMapping[props.paddingY],
          paddingBottom: oldSizeMapping[props.paddingY],
        })
      )
    }

    if (props.spaceBetween) {
      if (props.direction === "column" || props.direction == "column-reverse") {
        classes.push(
          css({
            "> :not(:last-child)": {
              marginBottom: oldSizeMapping[props.spaceBetween],
            },
            "> .re-flex__col:not(:last-child)": {
              marginBottom: 0,
              paddingBottom: oldSizeMapping[props.spaceBetween],
            },
          })
        )
      } else if (props.direction === "row" || props.direction === "row-reverse") {
        classes.push(
          css({
            "> :not(:last-child)": {
              marginRight: oldSizeMapping[props.spaceBetween],
            },
            "> .re-flex__col:not(:last-child)": {
              marginRight: 0,
              paddingRight: oldSizeMapping[props.spaceBetween],
            },
          })
        )
      }
    }
  }

  let flexStyle: EmotionCSS = {
    flexGrow: props.canGrow ? 1 : 0,
    flexShrink: props.canShrink ? 1 : 0,
  }

  if (props.overflow) {
    flexStyle.overflow = props.overflow
  }

  if (props.flexWrap) flexStyle.flexWrap = props.flexWrap
  else flexStyle.flexWrap = "nowrap"

  if (props.basis) flexStyle.flexBasis = props.basis

  Alignments.forEach((alignment) => {
    const val = props[alignment]
    if (val) {
      classes.push(
        css({
          alignItems: flexAlignMapping[val],
        })
      )
    }
  })

  Justifies.forEach((alignment) => {
    const val = props[alignment]
    if (val) {
      classes.push(
        css({
          justifyContent: flexAlignMapping[val],
        })
      )
    }
  })

  if (props.direction)
    classes.push(
      css({
        flexDirection: props.direction,
      } as EmotionCSS)
    )

  if ((props.direction === "column" || props.direction === "column-reverse") && props.height === undefined) {
    classes.push(
      css({
        height: "100%",
      })
    )
  }

  classes.push(css(flexStyle))
  return <div className={cx("revo-flex", cx(classes))}>{props.children}</div>
}

FlexContainer.defaultProps = {
  basis: "auto",
  canGrow: true,
  canShrink: true,
  direction: "row",
}

export default FlexContainer
