import { RelasTranslations } from "./translations"

export const RELAS_DE: RelasTranslations = {
  pageTitles: {
    comparables: "Comparables",
    dashboard: "Dashboard",
    assessmentOverview: "Bewertungsübersicht",
    districtData: "Quartiersdaten",
    scoresRatingsPrices: "Scores & Preise",
    ratings: "Ratings",
    prices: "Preise",
    poiExplorer: "POI - Explorer",
    specialMaps: "Sonderkarten",
    rentIndex: "Mietspiegel",
    locationAssessment: "Location Assessment",
    privateData: "Private data",
    profileManager: "Profile Manager",
    quickstart: "Quickstart",
    locationSelector: "Location Selector",
    ratingManager: "Rating Manager",
    login: "Einloggen",
    fundamentalData: "Basisdaten",
  },
}
