import { ProfileTranslationsGroup } from "./translations"
import { PROFILE_DE } from "./de"
import { PROFILE_EN } from "./en"
import { language } from "../../shared/language"

export function translations(): ProfileTranslationsGroup {
  switch (language()) {
    case "de":
      return PROFILE_DE
    default:
      return PROFILE_EN
  }
}
