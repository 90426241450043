import { RatingManagerTranslations } from "./translations"
import { SHARED_DE } from "../../shared/i18n/de"
import * as React from "react"

export const RATING_MANAGER_DE: RatingManagerTranslations = {
  ...SHARED_DE,
  rating: "Rating",
  listMenu: {
    delete: "Löschen",
    duplicate: "Duplizieren",
    edit: "Bearbeiten",
    rename: "Umbenennen",
  },
  createNew: "Rating erstellen",
  edit: "Rating bearbeiten",
  loadingResultData: "Lade Rating ...",
  ratingGradeLabel: "Note",
  showListToggle: "Liste zeigen",
  preview: "Vorschau",
  municipalitiesTab: {
    population: "Einwohner",
    header: "Gemeinden",
    download: "Download",
  },
  allRatings: "Alle Ratings",
  context: "Kontext",
  createForm: {
    formTitle: "Neues Rating",
    titleField: "Titel",
    gradesField: "Noten",
    append: "Note hinzufügen",
    typeField: "Typ",
    standardType: "Standard (Fixe Noten)",
    metaType: "Meta Rating (Kalkulierte Noten)",
    valueField: "Wert",
    metaGrades: {
      label: "Farbbereiche",
      from: "Von (>)",
      to: "Bis (<=)",
      addRange: "Bereich hinzufügen",
    },
  },
  ratingAlreadyExists: "Ein Rating mit diesem Namen existiert bereits",
  addSelectionsRule: "Selektion hinzufügen",
  selectionsHeader: "Selektionen",
  editForm: {
    menuItem: "Bearbeiten",
    createRuleButton: "Neue Regel erstellen",
    loadMicroDataExample: "Beispiel",
    editRuleMenuItem: "Bearbeiten",
    deleteButtonLabel: "Löschen",
    deleteRuleTitle: "Regel löschen",
    deleteRuleConfirm: (name: string) => `Wollen Sie die Regel '${name}' wirklich löschen?`,
    deleteSelectionTitle: "Selektion löschen",
    deleteSelectionConfirm: (name: string) => `Wollen Sie die Selektion '${name}' wirklich löschen?`,
    test: "Test",
    gradeField: "Note",
  },
  weight: "Gewicht",
  calculate: "Berechnen",
  selectionRuleEdit: {
    editSelectionTitle: "Selektion bearbeiten",
    selectionRulesHeader: "Regeln",
    addSelectionRule: "Regel hinzufügen",
    createTitle: "Neue Regel",
    editTitle: "Regel bearbeiten",
    ruleTypeLabel: "Regel",
    ruleCategoryLabel: "Kategorie",
    inclusiveExclusiveHeader: "Typ",
    inclusiveLabel: "Inklusive",
    exclusiveLabel: "Exklusive",
    rangeHeader: "Werte",
    selectRank: "Auswahl",
    selectRankPercentage: "Prozent",
    selectRankAbsolute: "Absolute",
    selectRankTop: "Oberste",
    selectRankBottom: "Unterste",
    rescaleRank: "Werte",
    rescaleRankNo: "Absolute Werte",
    rescaleRankYes: "Relative Werte",
    municipalityTagsPlaceholder: "Enter municipality",
    options: "Optionen",
    helpText: (
      <>
        <p>Je nach Regel lassen sich eine oder mehrere der folgenden Optionen einstellen:</p>

        <p>
          <strong>Inklusive / Exklusive</strong>
          <br />
          Legt fest, ob die zum Filter passenden Gemeinden zur Auswahl hinzugefügt oder von der Auswahl ausgeschlossen
          werden sollen.
        </p>

        <p>
          <strong>Score Rohdaten von/bis</strong>
          <br />
          Bei diesen Werten kann es sich um eine Anzahl handeln (zB. von 1 bis 5) oder um eine bestimmte Einheit (zB.
          "%" oder "Einwohner pro km²"). Wenn Sie keinen Wert angeben, wird "von minimal bis maximal" angenommen.
        </p>

        <p>
          <strong>Absolute / Relative Werte</strong>
          <br />
          Scores gehen immer von 0 bis 100 und beziehen sich auf einen bestimmten Referenzrahmen. So beziehen sich
          absolute Makro-Scores auf ganz Deutschland. Relative Makro-Scores hingegen werden bezogen auf die aktuelle
          Auswahl neu berechnet (zB. nur innerhalb hessischer Gemeinden statt aller Gemeinden in ganz Deutschland).
          Micro-Scores werden analog behandelt.
        </p>

        <p>
          <strong>Score/Profil von/bis</strong>
          <br />
          Scores gehen immer von 0 bis 100 und lassen sich entsprechend filtern (zB. nur Scores von 80-100).
        </p>
      </>
    ),
  },
  duplicateForm: {
    duplicateRuleTitle: "Selektion duplizieren",
    duplicateRuleSuffix: " Kopie",
  },
  deleteDialog: {
    menuItem: "Löschen",
    titleRating: "Rating löschen",
    confirmRating: (name: string) => `Wollen Sie das Rating '${name}' wirklich löschen?`,
    titleSelection: "Selektion löschen",
    confirmSelection: (name: string) => `Wollen Sie die Selektion '${name}' wirklich löschen?`,
    ratingCannotBeDeleted: "Rating kann nicht gelöscht werden",
    ratingCannotBeDeletedText: (name: string) => `${name} wird in folgenden Meta Ratings verwendet:`,
  },
  lockAlert: {
    unlockTitle: "Rating entsperren",
    title: "Rating sperren",
    lockConfirm:
      "Durch das Sperren lässt sich ein Rating schützen. Ihre Kollegen können es dann immer noch nutzen, aber nicht mehr bearbeiten oder löschen.",
    unlockConfirm: "Wenn Sie dieses Rating entsperren, kann es von Ihren Kollegen bearbeitet und gelöscht werden.",
  },
  metaMapInfo:
    "Geben Sie die Adresse ein und wählen Sie aus der erscheinenden Liste. Oder klicken Sie einfach in die Karte.",
  nothingSelected: "Klicken Sie auf ein Rating für weitere Detailinformationen",
  availableGrades: "Dieses Rating verwendet folgende Noten:",
  ratingExplanation: {
    textHead: "Die Note",
    textTail: "wurde für diese Adresse berechnet basierend auf folgenden Regeln und Fakten:",
    metaText: "Die finale Note für diese Adresse wurde basierend auf folgenden Ratings und deren Noten berechnet:",
    detailsHeader: "Details",
    ruleHeader: "Regel",
    valueHeader: "Wert",
    resultLabel: "Ergebnis",
    noResult: "Für dieses Rating ergibt sich an dieser Location keine Note.",
    noFactsHead: "Die Standardnote",
    noFactsTail: "wurde für diese Adresse berechnet, weil keine andere Regel zutraf.",
  },
  relative: "Relativ",
  absolute: "Absolut",
  percentage: "Prozent",
  mapRecalculationMessage:
    'Die Karte entspricht evtl. nicht mehr den veralteten Ergebnissen in der Tabelle. Klicken Sie auf "Neu berechnen", um die aktuellen Ergebnisse anzuzeigen.',
}
