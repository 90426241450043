import { AssessmentAgsYieldItem, AssessmentCellYieldItem, AssessmentYieldData, YieldType } from "../models/assessment"
import React, { FC, useMemo } from "react"
import { Line } from "react-chartjs-2"
import { ChartData, ChartDataset, ChartOptions, ChartType, TooltipItem } from "chart.js"
import { translations } from "../i18n"
import { FillFromXOptions, fillFromXPlugin } from "../../utils/chart-plugins/fill-from-x"
import { css, cx } from "emotion"
import { getThemeColor } from "../../shared/helper/color"

interface Props {
  yieldType: YieldType
  yieldData: AssessmentYieldData | null
  dashboardView?: boolean
}

const styles = {
  canvasContainer: css({
    width: "100%",
    height: "100%",
    overflow: "hidden",
    backgroundColor: "transparent",
  }),
}

const datasetConfigBase: Partial<ChartDataset<"line">> = {
  fill: false,
  pointHoverBorderColor: "white",
  pointHoverBorderWidth: 2,
  pointHoverRadius: 7,
  borderWidth: 2,
}

export const YieldTrendsGraph: FC<Props> = (props) => {
  const t = useMemo(translations, [translations])

  const yieldDatacell = props.yieldData?.cell_long_yearly ? [...props.yieldData?.cell_long_yearly] : []
  const yieldDataAgs = props.yieldData?.ags_long_yearly ? [...props.yieldData?.ags_long_yearly] : []
  const { chartOptions, chartData } = useMemo(() => {
    const sortedCellYieldData = yieldDatacell.sort((x, y) => x.year - y.year)
    const sortedAgsYieldData = yieldDataAgs.sort((x, y) => x.year - y.year)
    const agsChartPoints =
      sortedAgsYieldData?.map(transformAgsYieldItem).filter((v) => {
        return v.y !== undefined
      }) ?? []
    const cellChartPoints = sortedCellYieldData?.map(transformCellYieldItem) ?? []
    const [minY, maxY] = agsChartPoints.concat(cellChartPoints).reduce(
      ([min, max], v) => {
        return [v.y < min ? v.y : min, v.y > max ? v.y : max]
      },
      [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER]
    )
    const currentYear = new Date().getFullYear()

    const chartData: ChartData<"line"> = {
      datasets: [
        {
          ...datasetConfigBase,
          data: agsChartPoints,
          label: t.assessmentEntryDetails.yieldCity,
          pointBackgroundColor: getThemeColor("primary", "default").toString(),
          backgroundColor: getThemeColor("primary", "default").toString(),
          borderColor: getThemeColor("primary", "default").toString(),
        },
        {
          ...datasetConfigBase,
          data: cellChartPoints,
          label: t.assessmentEntryDetails.yieldMicro,
          pointBackgroundColor: getThemeColor("secondary1", "default").toString(),
          backgroundColor: getThemeColor("secondary1", "default").toString(),
          borderColor: getThemeColor("secondary1", "default").toString(),
        },
      ],
    }

    const minXAxis = Math.min(
      sortedCellYieldData && sortedCellYieldData.length > 0 ? sortedCellYieldData[0].year : currentYear,
      agsChartPoints && agsChartPoints.length > 0 ? agsChartPoints[0].x : currentYear
    )
    const maxXAxis = Math.max(
      sortedCellYieldData && sortedCellYieldData.length > 0
        ? sortedCellYieldData[sortedCellYieldData.length - 1].year
        : currentYear - 1,
      agsChartPoints && agsChartPoints.length > 0 ? agsChartPoints[agsChartPoints.length - 1].x : currentYear - 1
    )

    const firstForecastedCell: FillFromXOptions | undefined = [
      { datasetIndex: 0, pointIndexInDataset: agsChartPoints?.findIndex((y) => y.x === currentYear) ?? -1 },
      {
        datasetIndex: 1,
        pointIndexInDataset: cellChartPoints?.findIndex((y) => y.x === currentYear) ?? -1,
      },
    ]
      .filter((v) => v.pointIndexInDataset >= 0)
      .sort((x) => -x.pointIndexInDataset)
      .shift()

    const chartOptions: ChartOptions<"line"> = {
      animation: props.dashboardView ? false : {},
      layout: {
        padding: {
          left: 10,
          right: 20,
          top: 10,
        },
      },
      scales: {
        y: {
          suggestedMin: Math.floor(minY),
          suggestedMax: Math.floor(maxY) + 1,
          ticks: {
            stepSize: 1,
            display: true,
            callback: (value, index, values) => {
              const numValue = typeof value === "number" ? value : parseFloat(value)
              if (isNaN(numValue)) {
                return value
              }
              return `${numValue} %`
            },
          },
          grid: {
            borderDash: [5, 5],
          },
        },
        x: {
          type: "linear",
          position: "bottom",
          min: minXAxis,
          max: maxXAxis,
          ticks: {
            font: {
              family: "Lato, Roboto, Arial, Helvetica, sans-serif",
              size: 12,
            },

            callback: (value, index) => {
              const numValue = typeof value === "number" ? value : parseFloat(value)
              if (isNaN(numValue)) {
                return value
              }
              return numValue
            },
          },
        },
      },

      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        fillFromX: firstForecastedCell,
        legend: {
          position: "bottom",
          align: "start",
          labels: {
            boxWidth: props.dashboardView ? 6 : 12,
            boxHeight: props.dashboardView ? 6 : 12,
          },
        },
        tooltip: {
          titleFont: {
            size: 12,
            family: "Lato",
            style: "normal",
          },
          bodyFont: {
            size: 12,
            family: "Lato",
            weight: "bold",
          },
          footerFont: {
            size: 12,
            style: "normal",
          },
          titleMarginBottom: 8,
          footerMarginTop: 2,
          padding: {
            top: 8,
            left: 8,
            bottom: 8,
            right: 8,
          },
          titleSpacing: 16,
          caretPadding: 10,
          displayColors: false,
          backgroundColor: getThemeColor("background", "dark").toString(),
          callbacks: {
            title: tooltipTitle,
            label: tooltipLabel,
            footer: tooltipFooter,
          },
        },
      },
    }

    return { chartData, chartOptions }
  }, [props.yieldData, props.yieldType])

  function extractCellPoint(item: AssessmentCellYieldItem): number {
    switch (props.yieldType) {
      case "office":
        return item.yield_office
      case "retail":
        return item.yield_retail
      case "residential":
        return item.yield_residential
    }
  }

  function extractAgsPoint(item: AssessmentAgsYieldItem): number {
    switch (props.yieldType) {
      case "office":
        return item.yield_ags_office
      case "retail":
        return item.yield_ags_retail
      case "residential":
        return item.yield_ags_residential
    }
  }

  function transformCellYieldItem(item: AssessmentCellYieldItem) {
    const pointValue = extractCellPoint(item)
    return {
      x: item.year,
      y: pointValue,
    }
  }

  function transformAgsYieldItem(item: AssessmentAgsYieldItem) {
    const pointValue = extractAgsPoint(item)
    return {
      x: item.year,
      y: pointValue,
    }
  }

  function tooltipTitle(tooltipItem: TooltipItem<ChartType>[]): string {
    if (tooltipItem[0]) {
      return `${tooltipItem[0].parsed.x}`
    }
    return ""
  }

  function tooltipLabel(tooltipItem: TooltipItem<ChartType>): string {
    return `${tooltipItem.dataset.label}`
  }

  function tooltipFooter(tooltipItem: TooltipItem<ChartType>[]): string {
    if (tooltipItem[0]) {
      return `${Math.round(tooltipItem[0].parsed.y * 100) / 100} %`
    }
    return ""
  }

  return (
    <div className={cx(styles.canvasContainer, css({ minHeight: props.dashboardView ? "150px" : "200px" }))}>
      <Line data={chartData} options={chartOptions} plugins={[fillFromXPlugin]} />
    </div>
  )
}
