import * as React from "react"
import { formatAddress, formatAddressWithoutCountry } from "../models/address"
import { bind } from "decko"
import { connect, ConnectedProps } from "react-redux"
import { State } from "../reducers/state"
import { AbstractProps, AbstractState, AddressDialog, INITIAL_STATE } from "./address-dialog"
import { trackUsageEvent } from "../../utils/usage-tracking"
import { Action, Dispatch } from "redux"
import { Assessment, AssessmentEntryFull } from "../models/assessment"
import { loadAssessmentForAssessmentModule } from "../reducers/comparables-slice"
import { renameAssessment, updateAssessmentEntry } from "../reducers/assessment-slice-functions"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import Panel from "../../shared/components/panel"
import TextField from "../../shared/components/textfield"

const mapStateToProps = (state: State) => ({
  assessmentEntryUpdateError: state.assessment.assessmentEntryUpdateError,
  assessmentEntryUpdateInProgress: state.assessment.assessmentEntryUpdateInProgress,
})

const mapDispatch = (dispatch: Dispatch<Action>) => ({
  doLoadAssessmentForAssessmentModule: loadAssessmentForAssessmentModule,
  doUpdateAssessmentEntry: updateAssessmentEntry,
  doRenameAssessment: renameAssessment,
})

const connector = connect(mapStateToProps, mapDispatch)

export interface OwnProps extends AbstractProps {
  assessment: Assessment | null
  assessmentEntry: AssessmentEntryFull | null
}

type Props = OwnProps & ConnectedProps<typeof connector>

interface OwnState extends AbstractState {
  assessmentTitle: string
  isUpdating: boolean
}

const moduleFromUrlPath = /^\/\w+\//

const getCurrentHostAndModule = () => {
  const module = moduleFromUrlPath.exec(window.location.pathname)

  if (!module) return null

  const hostName = window.location.hostname
  return hostName + module[0]
}

class AssessmentEntryUpdateImpl extends AddressDialog<Props, OwnState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      ...INITIAL_STATE,
      searchTerm: props.assessmentEntry ? formatAddress(props.assessmentEntry.address) : "",
      addressSelection: props.assessmentEntry?.address || null,
      assessmentTitle: props.assessment?.title || "",
      isUpdating: false,
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.assessment !== prevProps.assessment || this.props.assessmentEntry !== prevProps.assessmentEntry) {
      this.setState({
        searchTerm: this.props.assessmentEntry ? formatAddress(this.props.assessmentEntry.address) : "",
        addressSelection: this.props.assessmentEntry?.address || null,
        assessmentTitle: this.props.assessment?.title || "",
      })
    }
  }

  protected isLoading(): boolean {
    return this.props.assessmentEntryUpdateInProgress
  }

  protected renderAbove(): JSX.Element | null {
    const { assessment } = this.props
    return (
      <>
        <h2>{this.t.assessmentEntryDetails.updateEntry}</h2>
        {assessment && assessment.numberOfAddresses == 1 && (
          <TextField
            label={this.t.assessment.title}
            value={this.state.assessmentTitle}
            onValueChange={(assessmentTitle) => this.setState({ assessmentTitle })}
          />
        )}
      </>
    )
  }

  protected renderBelow(): JSX.Element | null {
    const { assessmentEntryUpdateError } = this.props

    if (!assessmentEntryUpdateError) return null

    if (assessmentEntryUpdateError.error === "NoSmartdata")
      return <Panel color="negative">{this.t.assessmentAddAddress.errorNoSmartdata}</Panel>
    return <GenericErrorPanel error={assessmentEntryUpdateError} />
  }

  protected okButtonLabel(): string {
    return this.t.save
  }

  @bind
  protected onOk(): void {
    const { assessment, assessmentEntry } = this.props
    const { addressSelection, assessmentTitle, droppedLocation } = this.state

    if (!assessment || !assessmentEntry || !addressSelection || !addressSelection.location) return

    const entryLabel = formatAddress(addressSelection)

    this.setState((s) => ({ ...s, isUpdating: true, droppedLocationGeocodingLoading: true }))

    if (assessmentEntry.address !== addressSelection) {
      const moduleUrl = getCurrentHostAndModule()

      const additionalInfoObj = {
        previousAddress: formatAddress(assessmentEntry.address),
        moduleUrl: moduleUrl ?? "URL ERROR",
      }

      trackUsageEvent("CHANGE_ASSESSMENT_ADDRESS", addressSelection, JSON.stringify(additionalInfoObj))
    }

    void this.props
      .doUpdateAssessmentEntry(assessment.id, assessmentEntry.id, entryLabel, addressSelection, droppedLocation)
      .then(
        () => this.props.onClose(),
        () => {}
      )
      .then(() => {
        var needsRefresh = false
        var promise = Promise.resolve()

        if (assessment.numberOfAddresses == 1 && assessmentTitle !== assessment.title) {
          needsRefresh = true
          const titleToUpdateWith =
            assessmentTitle.trim().length > 0 ? assessmentTitle.trim() : formatAddressWithoutCountry(addressSelection)
          promise = this.props.doRenameAssessment(assessment.id, titleToUpdateWith)
        }

        if (assessmentEntry.address !== addressSelection) {
          needsRefresh = true
        }

        if (needsRefresh)
          promise
            .then(() =>
              this.props
                .doLoadAssessmentForAssessmentModule(assessment.id, assessmentEntry.id, this.props.module)
                .catch(() => {})
            )
            .catch(() => {})

        this.setState((s) => ({ ...s, isUpdating: false, droppedLocationGeocodingLoading: false }))
      })
  }
}

export const AssessmentEntryUpdate = connector(AssessmentEntryUpdateImpl)
