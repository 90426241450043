import { css } from "emotion"
import { COLORS } from "../../shared/components/ui/colors"

export const ratingStyles = {
  container: css({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: "16px",
    gap: "8px",
    hr: {
      margin: "0 -16px",
    },
  }),
  submitRow: css({
    textAlign: "right",
  }),
  spacer: css({
    flexGrow: 1,
  }),
  fieldName: css({
    fontSize: "16px",
    fontWeight: "bold",
    marginTop: "1em",
  }),
  fieldset: css({
    borderRadius: "4px",
    border: "1px solid",
    borderColor: COLORS.border.default,
    margin: 0,
  }),
  noTopMargin: css({
    marginTop: 0,
  }),
}
