import * as React from "react"
import { BareCard } from "../../shared/components/ui/bare-card"
import { BorderBottom } from "../../shared/components/ui/border-bottom"
import { Flex } from "../../shared/components/ui/flex"
import { FlexItem } from "../../shared/components/ui/flex-item"
import { language } from "../../shared/i18n/language"
import { ScrollBox } from "../../shared/components/ui/scroll-box"
import { translations } from "../i18n"
import { DEFAULT_WEIGHT, WeightOfScore } from "../../shared/models/scores"
import { CHILD_PROFILE_PREFIX, Profile } from "../models/profile"
import { css, cx } from "emotion"
import { Tooltip } from "../../shared/components/ui/tooltip"
import { categorize, CategorizedScores, DataSetType, LanaNodeWithVariation } from "../../shared/models/smartdata"
import { Checkbox, Collapse, InputGroup, Intent, Menu, MenuItem, Popover } from "@blueprintjs/core"
import { Button as ButtonBP } from "@blueprintjs/core"
import { findNonApplicableNodes } from "../../utils/node-graph-helper"
import { ProfileDependencies } from "./profile-dependencies"
import { ScoreListItem } from "./profile-edit"
import { NumericInputDrawer } from "./numeric-input-edit"
import { Scores } from "../../shared/models/scores"
import Expandable from "../../shared/components/expandable"
import { useDebounce } from "../../utils/use-debounce"
import {
  LANA_AGS_NODES,
  LANA_AGS_NODES_BY_NAME,
  LANA_CELL_NODES,
  LANA_CELL_NODES_BY_NAME,
  LanaNode,
} from "../../shared/smartdata-products/smartdata"
import { useAppSelector } from "../../relas/store"
import { doProfileAddScoresDone, updateProfileScores, updateView } from "../profile-slice"
import { AlertBox } from "../../shared/components/alertbox"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import Text from "../../shared/components/text"
import Grid from "../../shared/components/restyle-grid/grid"
import Button from "../../shared/components/button"
import { getThemeColorVar } from "../../shared/helper/color"
import { GenericError } from "../../shared/helper/axios"
import Icon from "../../shared/components/icon"

export const groupsClass = css({
  "> div": {
    borderLeft: "none",
    borderRight: "none",
    borderTop: "none",
  },
  "> div:last-child": {
    borderBottom: "none",
  },
})

export const scoreInGroupClass = css({
  "> div": {
    borderLeft: "none",
    borderRight: "none",
    borderTop: `1px solid ${getThemeColorVar("border", "default")}`,
  },
  "> div:first-child": {
    borderTop: "none",
  },
})

const arrowClass = css({
  lineHeight: "25px",
  transition: "transform 0.4s",
})
const arrowExpandedClass = css({
  transform: "rotateZ(180deg)",
})

const _translations = translations()
const categorizedMacroScores = categorize(_translations, LANA_AGS_NODES)
const categorizedMicroScores = categorize(_translations, LANA_CELL_NODES)
const profileCategoryName = "profile_categories"

export const ProfileAddGroupedScores = () => {
  const t = _translations

  const profileList = useAppSelector((state) => state.profile.profileList)
  const currentProfile = useAppSelector((state) => state.profile.profile)
  const smartdataSource = useAppSelector((state) => state.profile.smartdataSource)
  const scores = useAppSelector((state) => state.profile.scores)
  const agsRefResLoc = useAppSelector((state) => state.profile.agsRefResLoc)

  const categorisedScores = smartdataSource === "micro" ? categorizedMicroScores : categorizedMacroScores
  const allScoresAndProfiles = new Set(categorisedScores.flatMap((c) => c.scores.map((s) => s.rawName)))
  getFilteredProfiles().forEach((p) => allScoresAndProfiles.add(p.id))

  const allCategoriesByName = new Set(categorisedScores.map((v) => v.category.name))
  allCategoriesByName.add(profileCategoryName)

  const [isDirty, setIsDirty] = React.useState<boolean>(false)
  const [selected, setSelected] = React.useState<Set<string>>(new Set(currentSelected()))
  const [showNumericInputEditAlert, setShowNumericInputEditAlert] = React.useState<boolean>(false)
  const [showModifiedProfileAlert, setShowModifiedProfileAlert] = React.useState<boolean>(false)
  const [addError, setAddError] = React.useState<GenericError | null>(null)
  const [showNumericInputPane, setShowNumericInputPane] = React.useState<boolean>(false)
  const [expandedScores, setExpandedScores] = React.useState<Set<string>>(new Set(currentSelected()))
  const [debouncedScoreFilterName, scoreFilterName, setScoreFilterName] = useDebounce<string>("", 800)
  const [expandedCategories, setExpandedCategories] = React.useState<Set<string>>(allCategoriesByName)
  const [filteredScoresAndProfiles, setFilteredScoresAndProfiles] = React.useState<Set<string>>(allScoresAndProfiles)

  const prevProfile = React.useRef<Profile | null>(currentProfile)
  const prevSmartDataSource = React.useRef<DataSetType>(smartdataSource)
  const langKey = language() === "de" ? "de" : "en"

  React.useEffect(() => {
    if (prevProfile.current !== currentProfile || prevSmartDataSource.current !== smartdataSource) {
      setIsDirty(false)
      setSelected(new Set(currentSelected()))
      setShowNumericInputEditAlert(false)
    }

    prevProfile.current = currentProfile
    prevSmartDataSource.current = smartdataSource
  }, [currentProfile, smartdataSource])

  React.useEffect(() => {
    if (debouncedScoreFilterName === "") {
      setFilteredScoresAndProfiles(allScoresAndProfiles)
    } else {
      const lowercasedSearchTerm = debouncedScoreFilterName.toLowerCase()

      const filteredScores = categorisedScores.reduce<string[]>((acc, categorisedScore) => {
        const scores = categorisedScore.scores
        const filteredBySearchTerm = scores.filter(
          (s) =>
            s.title[langKey].toLowerCase().includes(lowercasedSearchTerm) ||
            s.description[langKey].toLowerCase().includes(lowercasedSearchTerm)
        )
        filteredBySearchTerm.forEach((score) => acc.push(score.rawName))
        return acc
      }, [])

      const profilesFilteredByName = getFilteredProfiles().reduce<string[]>((acc, profile) => {
        if (profile.name.toLowerCase().includes(lowercasedSearchTerm)) {
          acc.push(profile.id)
        }
        return acc
      }, [])

      const filteredSet = new Set<string>()
      filteredScores.forEach((s) => filteredSet.add(s))
      profilesFilteredByName.forEach((p) => filteredSet.add(p))

      setFilteredScoresAndProfiles(filteredSet)
    }
  }, [debouncedScoreFilterName])

  const onNumericInputEditClick = () => setShowNumericInputPane(true)

  const selectedMicroScores = () => formatSelectedScoresForNumericInput(LANA_CELL_NODES_BY_NAME, scores.micro)

  const selectedMacroScores = () => formatSelectedScoresForNumericInput(LANA_AGS_NODES_BY_NAME, scores.macro)

  const formatSelectedScoresForNumericInput = (allScores: Map<string, LanaNode>, existingScores: WeightOfScore) => {
    const weightSum = Object.values(existingScores).reduce((acc, scoreWeight) => acc + Math.abs(scoreWeight), 0)
    const allProfiles = new Map((profileList || []).map((profile) => [CHILD_PROFILE_PREFIX + profile.id, profile.name]))

    const result: ScoreListItem[] = []

    // scores with profiles
    selected.forEach((selectedScoreName) => {
      let lanaNode = allScores.get(selectedScoreName)
      const weight = existingScores[selectedScoreName] || 0
      if (lanaNode) {
        result.push({
          scoreName: lanaNode.scoreName,
          title: t.pickTranslation(lanaNode.title),
          weight: weight,
        })
      } else if (allProfiles.has(selectedScoreName)) {
        const profileTitle = allProfiles.get(selectedScoreName)
        result.push({
          scoreName: selectedScoreName,
          title: t.prefixWithProfile(profileTitle || "Profile"),
          weight: weight,
        })
      } else {
        console.warn(`Unknown score name: ${selectedScoreName}`)
      }
    })

    // normalize weights to percents
    return result.map((item) => ({ ...item, weight: weightSum > 0 ? (100 * item.weight) / weightSum : item.weight }))
  }

  function getFilteredProfiles() {
    const scoreNodes: [string, string[]][] =
      profileList?.map((value) => {
        const onlyProfilesId = extractScoresFrom(value.scores)
          .filter((v) => v.startsWith(CHILD_PROFILE_PREFIX))
          .map((v) => v.replace(CHILD_PROFILE_PREFIX, ""))
        return [value.id, onlyProfilesId]
      }) ?? []

    const nodesToFilter = [...findNonApplicableNodes(scoreNodes, currentProfile?.id ?? ""), currentProfile?.id ?? ""]

    return profileList?.filter((p) => nodesToFilter.indexOf(p.id) < 0) ?? []
  }

  const getCurrentlySelected = () => {
    const expandedCategories = categorisedScores.reduce<string[]>((acc, categorisedScore) => {
      const isActive = categorisedScore.scores.some((score) =>
        score.scoreVariationNames
          ? score.scoreVariationNames.some((s) => selected.has(s.scoreName))
          : selected.has(score.scoreName)
      )
      if (isActive) acc.push(categorisedScore.category.name)

      return acc
    }, [])

    const expandedCategoriesSet = new Set(expandedCategories)

    const profileIsExpanded = getFilteredProfiles().some((profile) => {
      const scoreName = CHILD_PROFILE_PREFIX + profile.id
      return selected.has(scoreName)
    })

    if (profileIsExpanded) {
      expandedCategoriesSet.add(profileCategoryName)
    }

    return expandedCategoriesSet
  }

  const scoreFiltering = () => (
    <Grid columnSpec={"1fr  min-content"} gap={16} padding={[8, 16, 16, 16]}>
      <InputGroup
        placeholder={t.scoreSearch.filter}
        style={{ borderRadius: "4px" }}
        value={scoreFilterName}
        leftElement={
          <div style={{ padding: "5px 4px" }}>
            <Icon name={"search"} color={"typo"} colorType={"lighter"} fontSize={16} />
          </div>
        }
        rightElement={
          <Tooltip placement="auto" tooltip={t.scoreSearch.info}>
            <div style={{ padding: "5px" }}>
              <Icon name={"info"} color={"typo"} colorType={"lighter"} fontSize={16} />
            </div>
          </Tooltip>
        }
        onChange={(event) => setScoreFilterName(event.currentTarget.value)}
      />
      <Popover
        placement="auto"
        content={
          <Menu>
            <MenuItem
              text={t.scoreSearchFilter.expandActive}
              onClick={() => setExpandedCategories(getCurrentlySelected())}
            />
            <MenuItem text={t.scoreSearchFilter.expandAll} onClick={() => setExpandedCategories(allCategoriesByName)} />
            <MenuItem text={t.scoreSearchFilter.collapseAll} onClick={() => setExpandedCategories(new Set())} />
          </Menu>
        }
      >
        <ButtonBP
          style={{ borderRadius: "4px" }}
          intent={Intent.PRIMARY}
          rightIcon={"caret-down"}
          text={t.scoreSearchFilter.groups}
        />
      </Popover>
    </Grid>
  )

  const renderMicroScores = () => (
    <Grid columns={1}>
      <div className={groupsClass}>
        {categorizedMicroScores.map(renderCategorizedScores)}
        {renderOtherProfiles()}
      </div>
    </Grid>
  )

  const renderMacroScores = () => (
    <Grid columns={1}>
      <div className={groupsClass}>
        {categorizedMacroScores.map(renderCategorizedScores)}
        <div className={scoreInGroupClass}>{renderOtherProfiles()}</div>
      </div>
    </Grid>
  )

  const renderCategorizedScores = (categorizedScores: CategorizedScores) => (
    <Expandable
      onClick={() => {
        const categoryName = categorizedScores.category.name
        const isSelected = expandedCategories.has(categorizedScores.category.name)
        const newExpanded = new Set(expandedCategories)
        isSelected ? newExpanded.delete(categoryName) : newExpanded.add(categoryName)
        setExpandedCategories(newExpanded)
      }}
      key={`category-${categorizedScores.category.name}`}
      header={t.pickTranslation(categorizedScores.category.title)}
      isOpen={expandedCategories.has(categorizedScores.category.name)}
    >
      <Grid columns={1} gap={4} padding={[4, 0]}>
        <div className={scoreInGroupClass}>
          {categorizedScores.scores.filter((s) => filteredScoresAndProfiles.has(s.rawName)).map(renderScore)}
        </div>
      </Grid>
    </Expandable>
  )

  const renderButtonScoreVariation = (variationName: LanaNode) => {
    const isSelected = selected.has(variationName.scoreName)
    return (
      <React.Fragment key={`${variationName.scoreVariation}-${variationName.scoreVariationName?.en}`}>
        {variationName.scoreVariationName && (
          <ButtonBP
            style={{
              fontSize: 12,
              borderRadius: 4,
              minWidth: "72px",
              borderLeft: isSelected ? "1px solid" : "",
              borderRight: isSelected ? "1px solid" : "",
            }}
            outlined={!isSelected}
            minimal={!isSelected}
            intent={isSelected ? "primary" : undefined}
            small
            onClick={onToggleScoreVariation(variationName.scoreName)}
          >
            {t.pickTranslation(variationName.scoreVariationName)}
          </ButtonBP>
        )}
      </React.Fragment>
    )
  }

  const renderScore = (score: LanaNodeWithVariation) => {
    let scoreSelected: boolean
    let onToggleScoreHandler: (event: React.FormEvent<HTMLInputElement>) => void

    if (score.scoreVariationNames) {
      const selectedVariations =
        score.scoreVariationNames?.filter((s) => selected.has(s.scoreName)).map((s) => s.scoreName) || []
      scoreSelected = selectedVariations.length > 0
      onToggleScoreHandler = onToggleScoreWithVariations(score.scoreName, selectedVariations)
    } else {
      scoreSelected = selected.has(score.scoreName)
      onToggleScoreHandler = onToggleScore(score.scoreName)
    }

    const scoreVariationExpanded =
      !!score.scoreVariationNames &&
      score.scoreVariationNames.some((variation) => expandedScores.has(variation.scoreName))
    return (
      <div key={score.scoreName}>
        <Flex flexDirection="row" gap={4} padding={[16, 8, 8, 16]}>
          <Checkbox
            id={`fragment-${score.scoreName}`}
            key={`fragment-${score.scoreName}`}
            checked={scoreSelected}
            onChange={onToggleScoreHandler}
          />
          <div>
            <label htmlFor={`fragment-${score.scoreName}`}>
              {t.pickTranslation(score.title)}
              &nbsp;
              <Tooltip
                placement="right"
                tooltip={
                  <div style={{ maxWidth: "400px" }}>
                    <b>{t.scoreDescription}</b>
                    <span>: {t.pickTranslation(score.description)}</span>
                    <br />
                    <b>{t.scoreSource}</b>
                    <span>: {t.pickTranslation(score.source)}</span>
                  </div>
                }
              >
                <Icon name="info" fontSize={14} color="primary" colorType="default" />
              </Tooltip>
            </label>
          </div>
          <FlexItem flexGrow={1} />

          {score.scoreVariationNames && score.scoreVariationNames.length > 1 && (
            <div className={scoreVariationExpanded ? arrowClass : cx(arrowClass, arrowExpandedClass)}>
              <a onClick={onVariationListExpand(score)}>
                <Icon fontSize={20} name={"arrow_drop_up"} />
              </a>
            </div>
          )}
        </Flex>
        {score.scoreVariationNames && score.scoreVariationNames.length > 1 && (
          <Collapse isOpen={scoreVariationExpanded} transitionDuration={0}>
            <Flex flexDirection="row" gap={16} key={score.scoreVariationName?.en} padding={[0, 0, 16, 16]}>
              {score.scoreVariationNames.map(renderButtonScoreVariation)}
            </Flex>
          </Collapse>
        )}
      </div>
    )
  }

  const renderOtherProfiles = () => (
    <Expandable
      header={t.profileAdd.otherProfilesCategory}
      onClick={() => {
        const isSelected = expandedCategories.has(profileCategoryName)
        const newExpanded = new Set(expandedCategories)
        isSelected ? newExpanded.delete(profileCategoryName) : newExpanded.add(profileCategoryName)
        setExpandedCategories(newExpanded)
      }}
      isOpen={expandedCategories.has(profileCategoryName)}
    >
      <Grid columns={1} gap={4} padding={[4, 0]}>
        <div className={scoreInGroupClass}>
          {getFilteredProfiles()
            .filter((p) => p.id !== currentProfile?.id && filteredScoresAndProfiles.has(p.id))
            .map((profile) => {
              const scoreName = CHILD_PROFILE_PREFIX + profile.id

              return (
                <Flex flexDirection="row" gap={4} key={profile.id} padding={[16, 8, 8, 8]}>
                  <Checkbox
                    id={`profile-${profile.id}`}
                    key={`profile-${profile.id}`}
                    checked={selected.has(scoreName)}
                    onChange={onToggleScore(scoreName)}
                  />
                  <div>
                    <label htmlFor={`profile-${profile.id}`}>{profile.name}</label>
                  </div>
                  <ProfileDependencies profileId={scoreName.substring(8)} />
                </Flex>
              )
            })}
        </div>
      </Grid>
    </Expandable>
  )

  const updateSelectedScores = (updatedSelectedScores: Set<string>) => {
    const currentSelect = currentSelected()
    setSelected(updatedSelectedScores)
    setIsDirty(
      updatedSelectedScores.size != currentSelect.length ||
        !currentSelect.every((score) => updatedSelectedScores.has(score))
    )
  }

  const onToggleScoreWithVariations =
    (mainScoreName: string, selectedScoreNames: string[]) => (event: React.FormEvent<HTMLInputElement>) => {
      const updatedSelectedScores = new Set(selected)
      if (event.currentTarget.checked) {
        selectedScoreNames.forEach((s) => updatedSelectedScores.add(s))
        if (selectedScoreNames.length === 0) {
          // when we toggle on the score with variants we select the parent score by default
          updatedSelectedScores.add(mainScoreName)
        }

        setExpandedScores(new Set(expandedScores).add(mainScoreName))
      } else {
        selectedScoreNames.forEach((s) => updatedSelectedScores.delete(s))
      }
      updateSelectedScores(updatedSelectedScores)
    }

  const onToggleScore = (mainScoreName: string) => (event: React.FormEvent<HTMLInputElement>) => {
    const updatedSelectedScores = new Set(selected)
    if (event.currentTarget.checked) updatedSelectedScores.add(mainScoreName)
    else updatedSelectedScores.delete(mainScoreName)

    updateSelectedScores(updatedSelectedScores)
  }

  const onToggleScoreVariation = (scoreName: string) => (event: React.MouseEvent) => {
    const currentSelect = currentSelected()
    const updatedSelectedSet = new Set(selected)
    if (selected.has(scoreName)) {
      updatedSelectedSet.delete(scoreName)
    } else {
      updatedSelectedSet.add(scoreName)
    }

    setSelected(updatedSelectedSet)
    setIsDirty(
      updatedSelectedSet.size != currentSelect.length || !currentSelect.every((score) => updatedSelectedSet.has(score))
    )
  }

  const onVariationListExpand = (score: LanaNodeWithVariation) => (event: React.MouseEvent) => {
    let selectedVariations = new Set(expandedScores)
    score.scoreVariationNames?.some((scoreVariation) => expandedScores.has(scoreVariation.scoreName))
      ? score.scoreVariationNames?.forEach((scoreVariation) => selectedVariations.delete(scoreVariation.scoreName))
      : selectedVariations.add(score.scoreName)
    setExpandedScores(selectedVariations)
  }

  const onBack = () => (isDirty ? setShowModifiedProfileAlert(true) : onAbort())

  const onBackWithoutSaving = () => {
    setShowModifiedProfileAlert(false)
    onAbort()
  }

  const onAbort = () => {
    currentProfile && updateView(currentProfile.scores, smartdataSource, agsRefResLoc)
    doProfileAddScoresDone()
  }

  const handleDoneClick = () => {
    setShowModifiedProfileAlert(false)
    if (isDirty) {
      selected.size <= 1 ? onDistributeWeightsDone() : setShowNumericInputEditAlert(true)
    } else {
      onAbort()
    }
  }

  const onDistributeWeightsDone = () => {
    const newWeights: Scores = updatedDistributeScores(Array.from(selected))
    updateView(newWeights, smartdataSource, agsRefResLoc)
    onUpdateScores(newWeights)
  }

  const onNumericInputDone = (updatedScores: ScoreListItem[]) => {
    setShowNumericInputPane(false)
    setShowNumericInputEditAlert(false)
    const newWeights: Scores = updatedScoresToScoresType(updatedScores, Array.from(selected))
    onUpdateScores(newWeights)
  }

  const updatedScoresToScoresType = (updatedScores: ScoreListItem[], selected: string[]) => {
    const newWeights: Scores = {
      micro: smartdataSource === "macro" ? { ...scores.micro } : {},
      macro: smartdataSource === "micro" ? { ...scores.macro } : {},
    }
    for (const name of selected) {
      const scoreListItem = updatedScores.find((item) => item.scoreName === name)
      if (scoreListItem) {
        newWeights[smartdataSource][name] = scoreListItem.weight
      }
    }

    return newWeights
  }

  const onUpdateScores = (newWeights: Scores) => {
    if (currentProfile) {
      updateProfileScores(currentProfile.id, newWeights).then(
        () => {
          updateView(newWeights, smartdataSource, agsRefResLoc)
          doProfileAddScoresDone()
        },
        (addError) => setAddError(addError)
      )
    } else {
      doProfileAddScoresDone()
    }
  }

  const updatedDistributeScores = (selected: string[]) => {
    const key = smartdataSource
    const newWeights: Scores = {
      micro: key === "macro" ? { ...scores.micro } : {},
      macro: key === "micro" ? { ...scores.macro } : {},
    }
    for (const name of selected) {
      if (scores[key][name] !== undefined) {
        newWeights[key][name] = scores[key][name]
      } else {
        newWeights[key][name] = DEFAULT_WEIGHT
      }
    }

    return newWeights
  }

  function currentSelected(): string[] {
    return extractScoresFrom(scores)
  }

  function extractScoresFrom(scores: Scores): string[] {
    switch (smartdataSource) {
      case "micro":
        return Object.getOwnPropertyNames(scores.micro)
      case "macro":
        return Object.getOwnPropertyNames(scores.macro)
      default:
        return []
    }
  }

  function renderAddError(error: GenericError) {
    if (error.status === 424) {
      return (
        <AlertBox
          header={t.saveChangesErrorBoxTitle}
          onClose={() => {}}
          actions={[{ label: t.ok, action: () => setAddError(null) }]}
          danger
        >
          <div>{t.saveChangesErrorCyclic}</div>
        </AlertBox>
      )
    }
    return (
      <AlertBox
        header={t.saveChangesErrorBoxTitle}
        onClose={() => {}}
        actions={[{ label: t.ok, action: () => setAddError(null) }]}
        danger
      >
        <GenericErrorPanel error={error} />
      </AlertBox>
    )
  }

  const scoreListForNumericInput = smartdataSource === "micro" ? selectedMicroScores() : selectedMacroScores()

  return (
    <Grid height={[100, "%"]} columns={1}>
      {showModifiedProfileAlert && (
        <AlertBox
          header={t.profileEdit.modifiedProfileAlertTitle}
          onClose={() => setShowModifiedProfileAlert(false)}
          actions={[
            { label: t.yes, action: handleDoneClick, type: "primary" },
            { label: t.no, action: onBackWithoutSaving, type: "secondary" },
          ]}
        >
          <Text>{t.profileEdit.modifiedProfileAlertText}</Text>
        </AlertBox>
      )}
      {showNumericInputEditAlert && (
        <AlertBox
          header={t.profileEdit.changingScoreAlertTitle}
          onClose={() => {}}
          actions={[
            { label: t.profileEdit.distributeEvenly, action: onDistributeWeightsDone, type: "secondary" },
            { label: t.profileEdit.numericInput, action: onNumericInputEditClick, type: "secondary" },
          ]}
        >
          <Text>{t.profileEdit.changingScoreAlertText}</Text>
        </AlertBox>
      )}
      <NumericInputDrawer
        isOpen={showNumericInputPane}
        scoreList={scoreListForNumericInput}
        saveScoreWeightNumericInput={(newScores) => onNumericInputDone(newScores)}
        onClose={() => onBackWithoutSaving()}
      />
      {addError && renderAddError(addError)}
      <BareCard>
        <Grid columns={1} height={[100, "%"]} rowSpec="min-content min-content 1fr min-content">
          <BorderBottom>
            <Flex flexDirection="column">
              <FlexItem alignSelf="start" padding={[8, 16]}>
                <Button type="tertiary" icon="back" onClick={onBack}>
                  {currentProfile ? currentProfile.name : ""}
                </Button>
              </FlexItem>
              <FlexItem alignSelf="start" padding={[8, 16]}>
                <h2>{smartdataSource === "micro" ? t.microScores : t.macroScores}</h2>
              </FlexItem>
            </Flex>
          </BorderBottom>
          <BorderBottom padding={[8, 0, 0, 0]}>{scoreFiltering()}</BorderBottom>
          <BorderBottom>
            <ScrollBox>{smartdataSource === "micro" ? renderMicroScores() : renderMacroScores()}</ScrollBox>
          </BorderBottom>
          <Flex flexDirection="row-reverse" gap={8} padding={8}>
            <Button size="small" onClick={handleDoneClick} type="primary" disabled={!isDirty}>
              {t.done}
            </Button>
          </Flex>
        </Grid>
      </BareCard>
    </Grid>
  )
}
