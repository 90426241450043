import { v4 } from "uuid"
import * as _ from "lodash"
import { WidgetLayout, WidgetLayouts } from "../assessment/components/dashboard/dashboard-widget-grid"
import { WidgetConfigDimensions } from "../assessment/models/AssessmentDashboard"
import { TempWidgets } from "../assessment/models/assessment"

type Grid = boolean[][]
type AddWidgetConfig = { type: TempWidgets }
type AddWidget = WidgetLayout & { config: AddWidgetConfig }
type StaticWidgetDimension = Readonly<Pick<WidgetConfigDimensions, "h" | "w">>
type Partition = { startColumn: number; width: number; startRow: number; height: number }

export const minSize: StaticWidgetDimension = {
  w: 1,
  h: 1,
}
export const defaultMaxSize: StaticWidgetDimension = {
  w: 12,
  h: 12,
}

// Uses provided layout max height but grid should be restricted to 12 by 12 for printable
const emptyGrid = (height: number) => Array.from(Array(height), () => Array(defaultMaxSize.w).fill(false))

export const fillPlaceholdersWithAddWidgets = (layouts?: WidgetLayouts): WidgetLayouts => {
  if (layouts) {
    const oldLayout = [...layouts.lg]
    const gridRepresentation = getGridFromLayouts(oldLayout)
    const arrayOfEmptySpaces = getEmptyBoxesInGrid(gridRepresentation)
    const arrayOfAddWidgets = getAddWidgets(arrayOfEmptySpaces)
    return {
      lg: [...oldLayout, ...arrayOfAddWidgets],
    }
  } else return { lg: [] }
}

export const removeAddWidgets = (layouts?: WidgetLayouts): WidgetLayouts => {
  return {
    lg: layouts ? layouts.lg.filter((layout) => !layout.i.includes("add-")) : [],
  }
}

const getGridFromLayouts = (layouts: WidgetLayout[]): Grid => {
  const layoutHeight = layouts.length > 0 ? Math.max(...layouts.map((layout) => layout.y + layout.h)) : 0
  const gridRows: boolean[][] = emptyGrid(layoutHeight >= defaultMaxSize.h ? layoutHeight : defaultMaxSize.h)
  layouts.forEach((layout) => {
    // go through each layout and set the grid to true for each cell that is occupied by a widget
    const rowIndexes = _.range(layout.y, layout.y + layout.h)
    rowIndexes.forEach((rowIndex) => {
      gridRows[rowIndex].fill(true, layout.x, layout.x + layout.w)
    })
  })
  // result should be a (Grid Height or 12) by 12 grid of true/false where true represents the cell being occupied by a widget
  return gridRows
}

// Returns array of layout dimensions of available empty spaces in grid
const getEmptyBoxesInGrid = (grid: Grid): Partition[] => {
  // go through the rows and track the empty spaces in the grid, and then forms boxes out of the empty spaces
  const width = grid[0].length
  const height = grid.length

  function findContinuousAreas(startRow: number, startColumn: number): Partition {
    let finalPartition: Partition = {
      startColumn,
      width: 0,
      startRow,
      height: 1,
    }
    let endColumn = startColumn

    for (endColumn = startColumn; endColumn < width; endColumn++) {
      if (!grid[startRow][endColumn]) {
        grid[startRow][endColumn] = true
        finalPartition.width++
      } else {
        break
      }
    }

    if (startRow + 1 === height) return finalPartition

    for (let row = startRow + 1; row < height; row++) {
      // check we can take a whole row from startColumn to column
      let allEmpty = true
      for (let i = startColumn; i < endColumn; i++) {
        if (grid[row][i]) {
          // it's not available, we are done with this row
          allEmpty = false
        }
      }
      if (allEmpty) {
        // take it!
        for (let i = startColumn; i < endColumn; i++) {
          grid[row][i] = true
        }
        finalPartition.height++
      } else {
        return finalPartition
      }
    }
    return finalPartition
  }

  let partitionsFound: Partition[] = []

  for (let row = 0; row < height; row++) {
    for (let column = 0; column < width; column++) {
      if (grid[row][column]) continue
      partitionsFound.push(findContinuousAreas(row, column))
    }
  }
  return partitionsFound
}

// returns array of "Add" widgets with the dimensions from an array of empty space dimensions
const getAddWidgets = (emptySpaces: Partition[]): AddWidget[] => {
  return emptySpaces.map((space) =>
    getNewAddWidget({ h: space.height, w: space.width, x: space.startColumn, y: space.startRow })
  )
}

const getNewAddWidget = (layout: WidgetConfigDimensions): AddWidget => {
  return {
    i: `add-${v4()}`,
    isDraggable: false,
    isResizable: false,
    ...layout,
    config: { type: "add" },
  }
}
