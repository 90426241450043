import { css } from "emotion"
import { MetaRatingRange } from "../../../shared/models/ratings"
import { getThemeColorVar } from "../../../shared/helper/color"

export const mapStyles = {
  mapClass: css({
    minHeight: 0,
    height: "100%",
  }),
  containerClass: css({
    minHeight: 0,
    height: "100%",
    position: "relative",
    overflow: "hidden",
  }),
  mapNotifierStyle: css({
    backgroundColor: getThemeColorVar("background", "lighter"),
    width: "100%",
    position: "absolute",
    zIndex: 1000,
    bottom: 0,
    transition: "max-height 1s",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.9,
    overflow: "hidden",
  }),
  showListContainerClass: css({
    position: "absolute",
    top: "16px",
    left: "16px",
    border: `1px solid ${getThemeColorVar("border", "default")}`,
    background: getThemeColorVar("background", "default"),
    color: getThemeColorVar("typo", "default"),
    padding: "8px",
  }),
  calculationResult: (range: MetaRatingRange | undefined) =>
    css({
      fontWeight: "bold",
      padding: "8px",
      backgroundColor: range?.color ?? "transparent",
      height: "40px",
    }),
}
