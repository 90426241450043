import * as React from "react"
import { css, cx } from "emotion"
import { RevoSize } from "../util/layoutconstants"
import { getThemeColorVar } from "../helper/color"

export interface HorizontalDividerProps {
  height: RevoSize
  borderless?: boolean
  color?: string
}

function dividerClass(height: RevoSize, drawBorder: boolean, color?: string) {
  const dividerColor = drawBorder ? `1px solid ${color ?? getThemeColorVar("border", "default")}` : "none"

  return css({
    minHeight: `${height}px`,
    height: `${height}px`,
    borderLeft: dividerColor,
    marginLeft: "3px",
    marginRight: "4px",
  })
}

export const HorizontalDivider: React.FunctionComponent<HorizontalDividerProps> = (props) => {
  return <div className={cx(dividerClass(props.height, !props.borderless, props.color))} />
}

export default HorizontalDivider
