import { PrivatePOICategory, PrivatePOIList } from "../../private-data/models/private-data"
import React from "react"
import { css } from "emotion"
import { CategoryDataList, POIObjectMarkerType } from "./private-poi-clusters"

const styles = {
  pointMarker: (isAggregated: boolean) =>
    css({
      position: "absolute",
      transform: `translate(-50%, ${isAggregated ? "-60%" : "-90%"})`,
      img: { width: "40px" },
    }),
  clusterCountContainer: css({
    display: "flex",
    width: " 30px",
    height: "30px",
    background: "white",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    border: "solid",
    borderWidth: "3px",
    bottom: "56px",
    left: "5px",
    position: "relative",
    fontWeight: "bold",
  }),
  imgShadow: css({
    filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5))",
  }),
}

export type PoiMarkerType = {
  lat: number
  lng: number
}

export interface PoiClusterMarkerType extends PoiMarkerType {
  key: string
  markerId: string
  pointCount: number
  markerPoints: POIObjectMarkerType[]
  category: PrivatePOICategory | undefined
  categoryDataList?: CategoryDataList
  poiList?: PrivatePOIList
  openDialog: (location: PoiMarkerType, poisToDisplay: PrivatePOIList) => void
}
export const POIClusterMarker = (props: PoiClusterMarkerType) => {
  const { category, markerId } = props
  const categoryIcon = category ? category.icon : "002"
  const isAggregated = props.pointCount > 1

  const onMarkerClick = (data: PoiClusterMarkerType) => {
    const { category, markerPoints, lng, lat, categoryDataList, poiList, openDialog } = data
    if (markerPoints.length > 0) {
      const markerIds = markerPoints.map((p) => p.properties.poiId)
      const poisInMarker = categoryDataList
        ? categoryDataList
            .find((categoryData) => categoryData.categoryId === category?.id)
            ?.poiList.filter((poi) => markerIds.includes(poi.id))
        : poiList
        ? poiList.filter((poi) => markerIds.includes(poi.id))
        : []

      if (poisInMarker && poisInMarker.length > 0) {
        openDialog({ lat, lng }, poisInMarker)
      }
    }
  }

  return (
    <div key={markerId} className={styles.pointMarker(isAggregated)} onClick={() => onMarkerClick(props)}>
      <img src={`/assets/private-data/${categoryIcon}.svg`} alt="POI Marker" className={styles.imgShadow} />
      {isAggregated && <div className={styles.clusterCountContainer}>{props.pointCount}</div>}
    </div>
  )
}
