import { ReportTranslations } from "./reportTranslations"
import { ASSESSMENTS_DE } from "../../assessment/i18n/de"

export const REPORT_DE: ReportTranslations = {
  ...ASSESSMENTS_DE,
  report: {
    profileScoreTitle: "Score",
    usageTypes: {
      "residential-apartment-rent": "Miete: Wohnung",
      "residential-apartment-sale": "Kauf: Wohnung",
      "residential-house-rent": "Miete: Haus",
      "residential-house-sale": "Kauf: Haus",
      office: "Miete: Büro",
      retail: "Miete: Einzelhandel",
      hall: "Miete: Logistik",
      "plot-sale": "Kauf: Grundstück",
      logistics: "Miete: Logistik",
    },
    rentIndexHeader: "Mietspiegel",
    comparableAddress: "Addresse",
    comparableRooms: "Zimmer",
    comparablePrice: "Preis",
    observedOffers: "Comparables",
  },
}
