import * as React from "react"
import { PropsWithChildren } from "react"
import { css, cx } from "emotion"
import { getThemeColorVar } from "../helper/color"

export interface DimmerProps {
  active: boolean
  overlayOpacity?: 0.1 | 0.2 | 0.3 | 0.4 | 0.5 | 0.6 | 0.7 | 0.8 | 0.9 | 1.0
}

const dimmerClass = css({
  position: "absolute",
  top: "0px",
  bottom: "0px",
  left: "0px",
  right: "0px",
  zIndex: 1000,
})

const hiddenClass = css({
  display: "none",
})

function overlayClass(overlayOpacity: number | undefined) {
  return css({
    position: "absolute",
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px",
    backgroundColor: getThemeColorVar("white", undefined),
    opacity: overlayOpacity === undefined ? 0.9 : overlayOpacity,
  })
}

const contentClass = css({
  position: "absolute",
  top: "0px",
  bottom: "0px",
  left: "0px",
  right: "0px",
})

export const Dimmer: React.FunctionComponent<PropsWithChildren<DimmerProps>> = (props) => {
  const classes = [dimmerClass]

  !props.active && classes.push(hiddenClass)

  return (
    <div className={cx(classes)}>
      <div className={cx(overlayClass(props.overlayOpacity))} />
      <div className={cx(contentClass)}>{props.children}</div>
    </div>
  )
}

export default Dimmer
