import { SelectionLabel } from "./ratings"
import { DataSetType } from "./smartdata"
import { HasTranslations, LANA_AGS_NODES_BY_NAME, LANA_AGS_NODES_BY_RAW } from "../smartdata-products/smartdata"

export interface LoadMacroData {
  type: "loadmacrodata"
  country: string
}

export interface LoadMicroData {
  type: "loadmicrodata"
  exampleAgsRefResLoc: string
}

export interface OneOfFilter {
  type: "oneoffilter"
  field: string
  selections: string[]
  exclusive: boolean
}

export interface RangeFilter {
  type: "rangefilter"
  field: string
  from?: number
  to?: number
  exclusive: boolean
}

export interface PrefixFilter {
  type: "prefixfilter"
  field: string
  prefix: string
  exclusive: boolean
}

export interface RankByProfile {
  type: "rankbyprofile"
  id: string
  from: number
  to: number
  rescale: boolean
}

export interface RankByScores {
  type: "rankbyscores"
  scores: { [key: string]: number }
  from: number
  to: number
  rescale: boolean
}

export interface DistrictDataRule {
  type: "districtdatarule"
  from?: number
  to?: number
  score: string
  exclusive: boolean
}

export interface SelectRanks {
  type: "selectranks"
  relative: boolean
  fromTop: boolean
  value: number
}

export type RatingSelectionRule =
  | LoadMacroData
  | LoadMicroData
  | OneOfFilter
  | RangeFilter
  | PrefixFilter
  | RankByProfile
  | RankByScores
  | SelectRanks
  | DistrictDataRule

export interface Selection {
  id: string
  name: string
  lockedBy?: string
  rules: RatingSelectionRule[]
  dataSetType: DataSetType
  createdAt: string
}

export interface SelectionResult {
  refId: string
  fields: { [key: string]: string | number }
  score?: number
}

export interface SelectionResults {
  dataSetType: DataSetType
  results: SelectionResult[]
  agsRefResLoc?: string
  selectionLabel?: SelectionLabel
}

export interface RuleCategoryWithoutField {
  id: string
  label: HasTranslations
  type: RatingSelectionRule["type"]
}

export interface RuleCategoryWithField extends RuleCategoryWithoutField {
  field: string
  scoreKey: DataSetType
}

export type RuleCategory = RuleCategoryWithoutField | RuleCategoryWithField

export function isRuleCategoryWithField(ruleCategory: RuleCategory): ruleCategory is RuleCategoryWithField {
  return (ruleCategory as RuleCategoryWithField).field !== undefined
}

// TODO: Consider removing this entirely. It causes more trouble than it is helpful (any more)
export const ruleCategories: (RuleCategoryWithField | RuleCategoryWithoutField)[] = [
  {
    field: "nuts1",
    id: "oneoffilter::macro::nuts1",
    label: {
      de: "Bundesland",
      en: "State",
    },
    type: "oneoffilter",
    scoreKey: "macro",
  },
  {
    field: "ref_id",
    id: "oneoffilter::macro::ref_id",
    label: {
      en: "Municipality",
      de: "Gemeinde",
    },
    type: "oneoffilter",
    scoreKey: "macro",
  },
  {
    label: {
      de: "Score",
      en: "Score",
    },
    type: "rankbyscores",
    id: "rankbyscores",
  },
  {
    field: "raw_.*",
    id: "rangefilter::macro::raw_*",
    label: {
      de: "Score Rohdaten",
      en: "Score Raw Data",
    },
    type: "rangefilter",
    scoreKey: "macro",
  },
  {
    label: {
      de: "Profil",
      en: "Profile",
    },
    type: "rankbyprofile",
    id: "rankbyprofile",
  },
  {
    field: "",
    label: {
      en: "District data",
      de: "Quartiersdaten",
    },
    id: "districtdata",
    type: "districtdatarule",
    scoreKey: "macro",
  },
  {
    field: "",
    id: "selectranks::macro::",
    label: {
      de: "Anzahl",
      en: "Number",
    },
    type: "selectranks",
    scoreKey: "macro",
  },
  {
    field: "",
    id: "selectranks::micro::",
    label: {
      de: "Anzahl",
      en: "Number",
    },
    type: "selectranks",
    scoreKey: "micro",
  },
  {
    label: {
      en: "Starting point",
      de: "Startpunkt",
    },
    id: "loadmicrodata",
    type: "loadmicrodata",
  },
]

export const RuleCategories: (dataSetType: DataSetType) => RuleCategory[] = (dataSetType: DataSetType) =>
  ruleCategories.filter((c) => !isRuleCategoryWithField(c) || c.scoreKey === dataSetType)

export function extractLabel(
  result: SelectionResult,
  selectionLabel?: SelectionLabel
): { label: HasTranslations; value?: number; unit: HasTranslations | null } | undefined {
  switch (selectionLabel?.type) {
    case "raw": {
      const node = LANA_AGS_NODES_BY_RAW.get(selectionLabel.name)
      if (node) {
        const value = result.fields[node.rawName]
        return {
          label: node.title,
          value: typeof value === "number" ? value : undefined,
          unit: node.unit,
        }
      }
    }
    case "score": {
      const node = LANA_AGS_NODES_BY_NAME.get(selectionLabel.name)
      if (node) {
        return {
          label: { de: `Score "${node.title.de}"`, en: `Score "${node.title.en}"` },
          value: typeof result.score === "number" ? result.score : undefined,
          unit: null,
        }
      }
    }
    case "profile":
      return {
        label: { de: `Profil score "${selectionLabel.name}"`, en: `Profile score "${selectionLabel.name}"` },
        value: typeof result.score === "number" ? result.score : undefined,
        unit: null,
      }
    default:
      return undefined
  }
}
