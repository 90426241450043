import * as React from "react"
import { RatingGrade } from "../../shared/models/rating-grade"
import { Tooltip } from "../../shared/components/ui/tooltip"
import { useMemo } from "react"
import { css } from "emotion"

interface Props {
  grade: RatingGrade
  padding?: string
  responsiveWidth?: boolean
}

export const GradeLabel: React.FunctionComponent<Props> = (props) => {
  const { grade, padding, responsiveWidth } = props

  const style = useMemo(() => {
    return css({
      padding: padding || "2px",
      lineHeight: "18px",
      fontSize: "14px",
      height: "24px",
      minWidth: "24px",
      backgroundColor: grade.color,
      borderRadius: "12px",
      textAlign: "center",
      color: "white",
      whiteSpace: "nowrap",
    })
  }, [grade, padding])

  return (
    <div className={style}>
      <Tooltip placement="auto-start" tooltip={grade.label}>
        <div style={{ overflow: "hidden", maxWidth: `${responsiveWidth ? "" : "150px"}`, textOverflow: "ellipsis" }}>
          {grade.label}
        </div>
      </Tooltip>
    </div>
  )
}
