import React, { useEffect } from "react"
import { COLORS } from "../../../shared/components/ui/colors"
import { Flex } from "../../../shared/components/ui/flex"
import { FlexItem } from "../../../shared/components/ui/flex-item"
import { language } from "../../../shared/i18n/language"
import { Collapse, InputGroup, Intent, Menu, MenuItem, Popover } from "@blueprintjs/core"
import { css, cx } from "emotion"
import { Button as ButtonBP } from "@blueprintjs/core/lib/esm/components/button/buttons"
import { DataSetType } from "../../../shared/models/smartdata"
import {
  categorizedDataListForDistricts,
  categorizedDataListForMunicipalities,
} from "../../../assessment/components/district-data"
import { translations } from "../../i18n"
import { useDebounce } from "../../../utils/use-debounce"
import { Category, DistrictProp, LANA_DISTRICT_PROPS, Topic } from "../../../shared/smartdata-products/smartdata"
import { Tooltip } from "../../../shared/components/ui/tooltip"
import { RadioButton } from "../../../shared/components/ui/radio-button"
import Grid from "../../../shared/components/restyle-grid/grid"
import Icon from "../../../shared/components/icon"
import { getThemeColor } from "../../../shared/helper/color"

const styles = {
  container: css({
    height: "100%",
  }),
  listItem: css({
    borderBottom: "1px solid",
    borderColor: COLORS.border.default,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: 4,
    padding: "8px 0 8px 32px",
  }),
  lightBackground: css({
    backgroundColor: COLORS.background.lighter,
  }),
  smallPadding: css({
    padding: "8px 0 8px 16px",
  }),
}

export interface DataByTopic {
  topic: Topic
  data: DistrictProp[]
}
export interface DataByCategory {
  category: Category
  data: DataByTopic[]
}

interface Props {
  datasetType: DataSetType

  selectedScore?: string
  setSelectedScore: (score: string) => void
}

export const RatingManagerDistrictdataList = ({ datasetType, selectedScore, setSelectedScore }: Props) => {
  const t = React.useMemo(translations, [translations])
  const langKey = language() === "de" ? "de" : "en"
  const categorizedDataList =
    datasetType === "micro" ? categorizedDataListForDistricts : categorizedDataListForMunicipalities

  const [collapsedTopics, setCollapsedTopics] = React.useState<Set<string>>(new Set())
  const [collapsedCategories, setCollapsedCategories] = React.useState<Set<string>>(new Set())

  const [debouncedScoreFilterName, scoreFilterName, setScoreFilterName] = useDebounce<string>("", 800)
  const [filteredDistrictData, setFilteredDistrictData] = React.useState<Set<string>>(categorizedDataList.dataNames)

  useEffect(() => {
    if (debouncedScoreFilterName === "") {
      setFilteredDistrictData(categorizedDataList.dataNames)
    } else {
      const lowercasedSearchTerm = debouncedScoreFilterName.toLowerCase()

      const filteredScores = categorizedDataList.dataList.reduce<string[]>((acc, dataByCategory) => {
        const data = dataByCategory.data.flatMap((dataByTopic) => dataByTopic.data)
        const filteredBySearchTerm = data.filter(
          (s) =>
            s.title[langKey].toLowerCase().includes(lowercasedSearchTerm) ||
            s.description[langKey].toLowerCase().includes(lowercasedSearchTerm)
        )
        filteredBySearchTerm.forEach((data) => acc.push(data.name))
        return acc
      }, [])

      setFilteredDistrictData(new Set(filteredScores))
    }
  }, [debouncedScoreFilterName, datasetType])

  const renderDataListItem = (dataItem: DistrictProp, id: number) => {
    const onCheckboxChange = (item: string) => {
      setSelectedScore(item)
    }

    return (
      <div className={styles.listItem} key={`data-item-${dataItem.name}-${id}`}>
        <FlexItem>
          <RadioButton
            checked={selectedScore === dataItem.name}
            id={dataItem.name}
            value={dataItem.name}
            name={dataItem.name}
            onChange={() => onCheckboxChange(dataItem.name)}
            label={t.pickTranslation(dataItem.title)}
          />
        </FlexItem>
        <Tooltip
          placement="right"
          tooltip={
            <div style={{ maxWidth: "400px" }}>
              <div>{t.pickTranslation(dataItem.description)}</div>
              <div>
                <b>{t.scoreSource}</b>: {t.pickTranslation(dataItem.source)}
              </div>
            </div>
          }
        >
          <Icon name="info" fontSize={14} color="primary" colorType="default" />
        </Tooltip>
      </div>
    )
  }

  const onArrowClick = (
    ev: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    name: string,
    collapsedItems: Set<string>,
    setCollapsedItems: (c: Set<string>) => void
  ) => {
    const c = new Set<string>(collapsedItems)
    if (collapsedItems.has(name)) {
      c.delete(name)
      setCollapsedItems(c)
    } else setCollapsedItems(c.add(name))
  }

  const getSetsDifference = (a: Set<string>, b: Set<string>): Set<string> => new Set([...a].filter((x) => !b.has(x)))

  const collapseNotActiveCategoriesAndTopics = () => {
    const expandedCategories = new Set<string>()
    const expandedTopics = new Set<string>()

    for (const [, value] of Object.entries(LANA_DISTRICT_PROPS)) {
      if (selectedScore === value.name) {
        expandedCategories.add(value.category)
        expandedTopics.add(value.topic)
      }
    }

    setCollapsedCategories(getSetsDifference(categorizedDataList.categories, expandedCategories))
    setCollapsedTopics(getSetsDifference(categorizedDataList.topics, expandedTopics))
  }

  const scoreFiltering = () => (
    <div style={{ borderBottom: "1px solid", borderColor: `${getThemeColor("border", "default")}` }}>
      <Grid columnSpec={"1fr  min-content"} gap={16} padding={16}>
        <InputGroup
          placeholder={t.scoreSearch.filter}
          style={{ borderRadius: "4px" }}
          value={scoreFilterName}
          leftElement={
            <div style={{ padding: "5px 4px" }}>
              <Icon name={"search"} color={"typo"} colorType={"lighter"} fontSize={16} />
            </div>
          }
          rightElement={
            <Tooltip placement="auto" tooltip={t.districtDataSearchInfo}>
              <div style={{ padding: "5px" }}>
                <Icon name={"info"} color={"typo"} colorType={"lighter"} fontSize={16} />
              </div>
            </Tooltip>
          }
          onChange={(event) => setScoreFilterName(event.currentTarget.value)}
        />
        <Popover
          placement="auto"
          content={
            <Menu>
              <MenuItem text={t.scoreSearch.expandActive} onClick={collapseNotActiveCategoriesAndTopics} />
              <MenuItem
                text={t.scoreSearch.expandAll}
                onClick={() => {
                  setCollapsedCategories(new Set())
                  setCollapsedTopics(new Set())
                }}
              />
              <MenuItem
                text={t.scoreSearch.collapseAll}
                onClick={() => {
                  setCollapsedCategories(categorizedDataList.categories)
                  setCollapsedTopics(categorizedDataList.topics)
                }}
              />
            </Menu>
          }
        >
          <ButtonBP
            style={{ borderRadius: "4px" }}
            intent={Intent.PRIMARY}
            rightIcon={"caret-down"}
            text={t.scoreSearch.groups}
          />
        </Popover>
      </Grid>
    </div>
  )

  const renderDataListInTopic = (dataListInTopic: DataByTopic) => {
    const topicCollapsed = collapsedTopics.has(dataListInTopic.topic.name)
    return (
      <React.Fragment key={dataListInTopic.topic.name}>
        <div
          className={cx(styles.listItem, styles.lightBackground)}
          onClick={(ev) => {
            onArrowClick(ev, dataListInTopic.topic.name, collapsedTopics, setCollapsedTopics)
          }}
        >
          <FlexItem flexGrow={1}>
            <b>{t.pickTranslation(dataListInTopic.topic.title)}</b>
          </FlexItem>
          <a
            onClick={(ev) => {
              onArrowClick(ev, dataListInTopic.topic.name, collapsedTopics, setCollapsedTopics)
            }}
          >
            <Icon fontSize={20} name={topicCollapsed ? "arrow_drop_down" : "arrow_drop_up"} />
          </a>
        </div>
        <Collapse isOpen={!topicCollapsed}>
          {dataListInTopic.data.filter((data) => filteredDistrictData.has(data.name)).map(renderDataListItem)}
        </Collapse>
      </React.Fragment>
    )
  }

  const renderCategorizedDataList = (categorizedDataList: DataByCategory) => {
    const categoryCollapsed = collapsedCategories.has(categorizedDataList.category.name)
    const ungroupedData = categorizedDataList.data.find((item) => item.topic.name === "ungrouped")
    return (
      <React.Fragment key={categorizedDataList.category.name}>
        <div
          className={cx(styles.listItem, styles.lightBackground, styles.smallPadding)}
          onClick={(ev) =>
            onArrowClick(ev, categorizedDataList.category.name, collapsedCategories, setCollapsedCategories)
          }
        >
          <FlexItem flexGrow={1}>
            <b>{t.pickTranslation(categorizedDataList.category.title)}</b>
          </FlexItem>
          <a
            onClick={(ev) =>
              onArrowClick(ev, categorizedDataList.category.name, collapsedCategories, setCollapsedCategories)
            }
          >
            <Icon fontSize={20} name={categoryCollapsed ? "arrow_drop_down" : "arrow_drop_up"} />
          </a>
        </div>
        <Collapse isOpen={!categoryCollapsed}>
          {ungroupedData && (
            <>{ungroupedData.data.filter((data) => filteredDistrictData.has(data.name)).map(renderDataListItem)}</>
          )}
          {categorizedDataList.data
            .filter((dataByTopic) =>
              dataByTopic.topic.name !== "ungrouped"
                ? dataByTopic.data.filter((data) => filteredDistrictData.has(data.name)).length > 0
                : false
            )
            .map(renderDataListInTopic)}
        </Collapse>
      </React.Fragment>
    )
  }

  return (
    <div className={styles.container}>
      {scoreFiltering()}
      <Flex flexDirection="column">
        {categorizedDataList.dataList
          .filter(
            (dataByCategory) =>
              dataByCategory.data.filter(
                (dataByTopic) => dataByTopic.data.filter((data) => filteredDistrictData.has(data.name)).length > 0
              ).length > 0
          )
          .map((c) => renderCategorizedDataList(c))}
      </Flex>
    </div>
  )
}
