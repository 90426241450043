import * as React from "react"
import DropdownButton, { DropdownItemProps } from "../../shared/components/dropdown-button"
import { getCookieValue } from "../../shared/helper/cookies"
import { switchLanguage } from "../../shared/language"
import Icon from "../../shared/components/icon"

export const LanguageSwitcher = () => {
  const language = getCookieValue("SETTINGS_LANGUAGE")

  const actions: DropdownItemProps[] =
    language === "de"
      ? [{ id: "en", title: "EN", onClick: () => switchLanguage("en") }]
      : [{ id: "de", title: "DE", onClick: () => switchLanguage("de") }]

  return (
    <div className="white-dropdown-button">
      <DropdownButton actions={actions} type="secondary">
        <Icon name="translate" /> {language === "de" ? "DE" : "EN"}
      </DropdownButton>
    </div>
  )
}
