import * as React from "react"
import { useEffect } from "react"
import { Translations } from "../i18n/translations"
import { translations } from "../i18n"
import { RegistrationContainer } from "./registration-container"
import { useAppSelector } from "../../relas/store"
import { registrationDoResendCodes, registrationDoVerifyEmailToken } from "../reducers/registration-slice"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import RevoCard from "../../shared/components/revo-card"
import Panel from "../../shared/components/panel"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"
import LoadingSpinner from "../../shared/components/loadingspinner"
import Button from "../../shared/components/button"

export const RegistrationVerifyEmail = () => {
  const token = useAppSelector((state) => state.registration.emailToken)
  const error = useAppSelector((state) => state.registration.emailTokenVerificationError)
  const translate: Translations = translations()

  const t = translate.verifyEmail
  const [resetting, setResetting] = React.useState(false)

  useEffect(() => {
    if (token) {
      void registrationDoVerifyEmailToken(token)
    }
  }, [])

  const sendNew = () => {
    if (token) {
      setResetting(true)
      void registrationDoResendCodes(token)
    } else {
      console.error("BUG - have no token but trying to reset it")
    }
  }

  const renderInner = () => {
    if (error && error !== "expired" && error !== "user-already-verified") {
      return <GenericErrorPanel error={error} />
    }

    if (error === "expired") {
      return (
        <FlexContainer spaceBetween="md" direction="column">
          <Panel color="neutral" title={t.codeExpiredTitle}>
            {t.codeExpiredBody}, <a href="mailto:support@21re.de">{t.contactSupport}</a>.
          </Panel>
          <FlexContainer>
            <Button id="21re-resend-code-button" disabled={resetting} onClick={sendNew} type="primary">
              {t.resendCodesLabel}
            </Button>
          </FlexContainer>
        </FlexContainer>
      )
    }

    if (error === "user-already-verified") {
      return (
        <FlexContainer spaceBetween="xxl" direction="column">
          <Panel title={t.userAlreadyVerifiedTitle}>{t.userAlreadyVerifiedBody}</Panel>
          <FlexContainer>
            <Button type="primary" href="/">
              {t.loginServiceLinkLabel}
            </Button>
          </FlexContainer>
        </FlexContainer>
      )
    }

    return (
      <div>
        <LoadingSpinner />
        <p>{translate.verifyEmail.text}</p>
      </div>
    )
  }

  return (
    <RegistrationContainer title={translate.verifyEmail.header}>
      <RevoCard>
        <FlexContainer>{renderInner()}</FlexContainer>
      </RevoCard>
    </RegistrationContainer>
  )
}
