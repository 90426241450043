import Axios, { CancelTokenSource } from "axios"
import { GeoJSON } from "ol/format"
import { Extent } from "ol/extent"
import { lanaApiUrl } from "../app_config"

let viewportRequestToken: CancelTokenSource | undefined = undefined

export async function fetchMunicipalityViewport(selectedMunicipalityId: string): Promise<Extent> {
  if (typeof selectedMunicipalityId !== "string") return Promise.reject("Invalid state")

  viewportRequestToken?.cancel()
  viewportRequestToken = Axios.CancelToken.source()

  try {
    const response = await Axios.get(`${lanaApiUrl}/api/map/macroShape/${encodeURIComponent(selectedMunicipalityId)}`, {
      cancelToken: viewportRequestToken.token,
    })
    return new GeoJSON().readGeometry(response.data).getExtent()
  } catch (err) {
    return Promise.reject(`Couldn't get viewport for municipality ${selectedMunicipalityId}`)
  }
}

export async function fetchAgsResLocViewport(agsRefResLoc: string): Promise<Extent> {
  if (typeof agsRefResLoc !== "string") return Promise.reject("Invalid state")

  viewportRequestToken?.cancel()
  viewportRequestToken = Axios.CancelToken.source()

  try {
    const response = await Axios.get(`${lanaApiUrl}/api/map/microRegionShape/${encodeURIComponent(agsRefResLoc)}`, {
      cancelToken: viewportRequestToken.token,
    })
    return new GeoJSON().readGeometry(response.data).getExtent()
  } catch (err) {
    return Promise.reject("failed to get viewport")
  }
}
