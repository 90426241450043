import React, { useMemo } from "react"
import { FC } from "react"
import { Popover, PopoverInteractionKind } from "@blueprintjs/core"
import { ScrollBox } from "../../../shared/components/ui/scroll-box"
import { AssessmentDynamicDashboardConfig, WidgetConfig } from "../../models/AssessmentDashboard"
import { WidgetLayouts } from "./dashboard-widget-grid"
import { TempWidgets, WidgetsType } from "../../models/assessment"
import { Button as ButtonBlueprint } from "@blueprintjs/core/lib/esm/components/button/buttons"
import { translations } from "../../i18n"
import { css } from "emotion"
import * as _ from "lodash"
import { assertUnreachable } from "../../../utils/utils"
import { getThemeColorVar } from "../../../shared/helper/color"
import Icon from "../../../shared/components/icon"

const buttonStyle = css({
  width: "100%",
  boxShadow: "none !important",
  justifyContent: "start",
  backgroundColor: "white !important",
  "&:hover": {
    backgroundColor: `${getThemeColorVar("primary", "light")} !important`,
  },
})
type Props = {
  currentLayout: WidgetLayouts
  defaultConfigWidgets: AssessmentDynamicDashboardConfig
  replaceAddWidget: (widgetConfigToReplace: WidgetConfig) => void
}
type WidgetRecords = Record<WidgetsType, WidgetConfig>
type DropdownWidgetKey = Exclude<WidgetsType, TempWidgets>
export const AddWidgetPopover: FC<Props> = ({ defaultConfigWidgets, currentLayout, replaceAddWidget }: Props) => {
  const t = useMemo(translations, [translations])
  const [isOpen, setIsOpen] = React.useState(false)
  const widgetsTypes: DropdownWidgetKey[] = [
    "macroMap",
    "microMap",
    "nearestAccessibility",
    "microScores",
    "macroScores",
    "districtData",
    "comparables",
    "POIs",
    "rentIndex",
    "specialMaps",
    "population",
    "MLPrices",
    "yields",
    "ratings",
  ]
  const widgetRecords: WidgetRecords | Partial<WidgetRecords> = defaultConfigWidgets.widgets.reduce((acc, curr) => {
    acc = { ...acc, [curr.config.type]: curr }
    return acc
  }, {})

  const getText = (widget: DropdownWidgetKey): string => {
    const translations = t.dashboard.widgetSelectDropdown
    switch (widget) {
      case "macroMap":
        return translations.macroMap
      case "microMap":
        return translations.microMap
      case "nearestAccessibility":
        return translations.nearestAccessibility
      case "microScores":
        return translations.microScores
      case "macroScores":
        return translations.macroScores
      case "districtData":
        return translations.districtData
      case "comparables":
        return translations.comparables
      case "rentIndex":
        return translations.rentIndex
      case "POIs":
        return translations.POIs
      case "specialMaps":
        return translations.specialMaps
      case "population":
        return translations.population
      case "MLPrices":
        return translations.MLPrices
      case "yields":
        return translations.yields
      case "ratings":
        return translations.ratings
      default:
        assertUnreachable(widget)
    }
  }

  const onReplace = (widget: DropdownWidgetKey) => {
    const widgetToAdd: WidgetConfig | undefined = widgetRecords[widget]
    if (widgetToAdd) replaceAddWidget(widgetToAdd)
    setIsOpen(false)
  }

  const renderButtonIcon = () => (
    <div className={"add-button"} style={{ display: "flex", cursor: "pointer" }}>
      <Icon name="add_active" size="large" fontSize={30} color={"background"} colorType={"dark"} />
    </div>
  )

  const renderContent = () => {
    const isPOIsAvailable = currentLayout.lg.find((layout) => layout.config.type === "POIs")
    const availableWidgetsTOChoose = isPOIsAvailable ? widgetsTypes.filter((widget) => widget !== "POIs") : widgetsTypes
    const sortedWidgetKeys = _.sortBy(availableWidgetsTOChoose, (x) => getText(x), ["asc"])
    return (
      <ScrollBox minWidth={"300px"} minHeight={"350px"}>
        <div className={"add-list"}>
          {sortedWidgetKeys.map((widget) => (
            <ButtonBlueprint
              className={buttonStyle}
              key={widget}
              text={getText(widget)}
              onClick={() => onReplace(widget)}
            />
          ))}
        </div>
      </ScrollBox>
    )
  }

  return (
    <Popover
      usePortal
      minimal
      hasBackdrop
      position={"bottom-left"}
      className={css({ display: "flex", position: "relative" })}
      interactionKind={PopoverInteractionKind.CLICK}
      isOpen={isOpen}
      onInteraction={(nextIsOpen) => setIsOpen(nextIsOpen)}
      target={renderButtonIcon()}
      content={renderContent()}
    />
  )
}
