import React, { useEffect } from "react"
import { translations } from "../../i18n"
import { css, cx } from "emotion"
import { YieldTrendsContent } from "../assessment-entry-yield-trends-content"
import { UsageType, yieldTypeForUsageType } from "../../models/assessment"
import { widgetStyles } from "./dashboard-widget"
import { useAppSelector } from "../../../relas/store"
import { loadAssessmentYields } from "../../reducers/assessment-slice-functions"
import { Location } from "../../models/address"
import { Tooltip } from "../../../shared/components/ui/tooltip"
import { DashboardEditMode } from "./dashboard-widget-grid"
import Grid from "../../../shared/components/restyle-grid/grid"
import Icon from "../../../shared/components/icon"

const styles = {
  widgetHeader: (editMode: boolean) =>
    cx(
      css({
        display: "flex",
        gap: "12px",
      }),
      "dashboard-widget-header"
    ),
  canvasContainer: css({
    height: "100%",
    justifySelf: "center",
    width: "99%",
    padding: "5px 0",
  }),
}

interface Props {
  usageType?: UsageType
  markerLocation?: Location
  onHeaderClick: () => void
  onRemoveClick: () => void
  printMode: boolean
  editMode: DashboardEditMode
  onRenderingDone: () => void
}
const YieldsWidget = ({
  onHeaderClick,
  onRemoveClick,
  usageType,
  printMode,
  editMode,
  markerLocation,
  onRenderingDone,
}: Props) => {
  const t = React.useMemo(() => translations().dashboard, [translations])

  const currentObjectYields = useAppSelector((state) => state.assessment.currentObjectYields)

  const [header, usageTypeHeader] = (() => {
    switch (usageType) {
      case UsageType.Office:
        return [t.yields.header.office, t.yields.usageType.office]
      case UsageType.Retail:
        return [t.yields.header.retail, t.yields.usageType.retail]
      default:
        return [t.yields.header.residential, t.yields.usageType.residential]
    }
  })()

  useEffect(() => {
    if (markerLocation) {
      if (!currentObjectYields) {
        loadAssessmentYields(markerLocation).finally(onRenderingDone)
      } else {
        // we already fetched yields for this location
        onRenderingDone()
      }
    }
  }, [markerLocation])

  const onRemove = (e: React.MouseEvent) => {
    if (editMode) {
      e.stopPropagation()
      onRemoveClick && onRemoveClick()
    }
  }

  return (
    <div
      className={cx(
        "dashboard-widget",
        editMode && "edit-mode",
        editMode && "edit-mode-" + editMode,
        printMode && widgetStyles.widgetPrintMode
      )}
    >
      <Grid columns={1} rowSpec={"min-content 1fr"} height={[100, "%"]}>
        <div
          className={styles.widgetHeader(!!editMode)}
          onClick={(e) => {
            if (!editMode) {
              e.stopPropagation()
              onHeaderClick && onHeaderClick()
            }
          }}
        >
          <b>{header}</b>
          <div>({usageTypeHeader})</div>
          {editMode && !printMode && (
            <div className={`notDraggable widget-edit-button remove-button`}>
              <Tooltip tooltip={t.remove} placement={"bottom"} hideArrow={true}>
                <span onClick={onRemove}>
                  <Icon name={"remove"} fontSize={16} />
                </span>
              </Tooltip>
            </div>
          )}
        </div>
        <div className={styles.canvasContainer}>
          {currentObjectYields && (
            <YieldTrendsContent yieldType={yieldTypeForUsageType(usageType) || "residential"} dashboardView={true} />
          )}
        </div>
      </Grid>
    </div>
  )
}

export default YieldsWidget
