import { haversine_distance } from "../../../utils/utils"
import { Location } from "../../models/address"
import { GoogleMapsPOICategory } from "../../models/assessment"
import { SHARED_DE } from "../../../shared/i18n/de"
import { SHARED_EN } from "../../../shared/i18n/en"
import { stringIsNumberedTextSearchId, TextSearchIDsToTitle } from "../../../shared/models/poi-explorer"
import { HasTranslations } from "../../../shared/smartdata-products/smartdata"
import { PlaceResultEncodable } from "../../../shared/models/place-result-encodable"

export type CategoryDetails = {
  id: string
  icon: string | undefined
  count: number
  title: HasTranslations | undefined
  distances: string[] | undefined
}

export function calculateCategoryDetails(
  categoryId: string,
  loading: boolean,
  pois: Map<string, PlaceResultEncodable[]> | null,
  assessmentCoords: Location | undefined,
  poiCategories: Map<string, GoogleMapsPOICategory>,
  textSearchTitles: TextSearchIDsToTitle,
  isochronePolygon?: google.maps.Polygon
): CategoryDetails {
  const poisInCurrCategory =
    categoryId === "local_public_transport" && pois
      ? [
          ...(pois.get("bus_station") ?? []),
          ...(pois?.get("subway_station") ?? []),
          ...(pois?.get("transit_station") ?? []),
          ...(pois?.get("light_rail_station") ?? []),
        ]
      : pois?.get(categoryId)

  if (assessmentCoords && categoryId === "local_public_transport" && !loading) {
    poisInCurrCategory?.sort((a, b) => {
      return a.geometry?.location && b.geometry?.location
        ? haversine_distance(
            [assessmentCoords.lng, assessmentCoords.lat],
            [a.geometry.location.lng, a.geometry?.location.lat]
          ) -
            haversine_distance(
              [assessmentCoords.lng, assessmentCoords.lat],
              [b.geometry?.location.lng, b.geometry?.location.lat]
            )
        : 0
    })
  }

  let categoryTitle: HasTranslations | undefined
  if (categoryId === "local_public_transport") {
    categoryTitle = { de: SHARED_DE.poiExplorer.busAndTransit, en: SHARED_EN.poiExplorer.busAndTransit }
  } else if (stringIsNumberedTextSearchId(categoryId)) {
    const title = textSearchTitles[categoryId]
    categoryTitle = title !== undefined ? { de: title, en: title } : undefined
  } else {
    categoryTitle = poiCategories.get(categoryId)?.title
  }

  const poisCount = isochronePolygon
    ? poisInCurrCategory?.filter(
        (poi) =>
          poi.geometry?.location && google.maps.geometry.poly.containsLocation(poi.geometry.location, isochronePolygon)
      ).length
    : poisInCurrCategory?.length

  const distances = poisInCurrCategory
    ?.flatMap((poi) => {
      const geometry = poi.geometry
      if (assessmentCoords && geometry && geometry.location) {
        const distance = Math.round(
          haversine_distance(
            [assessmentCoords.lng, assessmentCoords.lat],
            [geometry.location.lng, geometry.location.lat]
          )
        )
        return [distance]
      } else {
        return []
      }
    })
    .sort((a, b) => a - b)
    .map((distance) => {
      if (distance > 1000) return `${Math.round(distance / 100) / 10} km`
      else return `${distance} m`
    })
    .slice(0, 3)

  return {
    id: categoryId,
    icon: iconsForCategories[categoryId],
    count: poisCount ?? 0,
    title: categoryTitle,
    distances,
  }
}

export const iconsForCategories: { [key: string]: string } = {
  accounting: "700-7400",
  airport: "400-4000",
  amusement_park: "550-5520",
  aquarium: "550-5520",
  art_gallery: "300-3000",
  atm: "700-7010",
  bakery: "600-6300-0244",
  bank: "700-7000",
  bar: "200-2000",
  beauty_salon: "600-6950",
  bicycle_store: "600-6100",
  book_store: "600-6700",
  bowling_alley: "800-8600",
  bus_station: "400-4100-0036",
  cafe: "100-1100",
  campground: "550-5510",
  car_dealer: "700-7800",
  car_rental: "700-7851",
  car_repair: "700-7850",
  car_wash: "700-7850",
  casino: "200-2300",
  cemetery: "300-3200",
  church: "300-3200",
  city_hall: "800-8100",
  clothing_store: "600-6800",
  convenience_store: "600-6100",
  courthouse: "800-8100",
  dentist: "800-8000-0154",
  department_store: "600-6100",
  doctor: "800-8000-0155",
  drugstore: "600-6400-0069",
  electrician: "700-7400",
  electronics_store: "600-6500",
  embassy: "800-8100",
  fire_station: "700-7300",
  florist: "600-6100",
  funeral_home: "700-7400",
  furniture_store: "600-6600",
  gas_station: "700-7600",
  gym: "800-8600",
  hair_care: "600-6950",
  hardware_store: "600-6600",
  hindu_temple: "300",
  home_goods_store: "600-6100",
  hospital: "800-8000-0159",
  insurance_agency: "700-7200",
  jewelry_store: "600-6100",
  laundry: "700-7400",
  lawyer: "700-7200",
  library: "800-8300",
  light_rail_station: "400-4100-0037",
  liquor_store: "600-6300-0068",
  local_government_office: "800-8100",
  local_public_transport: "400-4100",
  locksmith: "700-7200",
  lodging: "500-5100",
  meal_delivery: "100-1000",
  meal_takeaway: "100-1000",
  mosque: "300",
  movie_rental: "200-2100",
  movie_theater: "200-2100",
  moving_company: "700-7400",
  museum: "300-3100",
  night_club: "200-2000",
  painter: "700-7400",
  park: "550-5510",
  parking: "800-8500",
  pet_store: "600",
  pharmacy: "600-6400-0070",
  physiotherapist: "700-7200",
  plumber: "700-7400",
  police: "700-7300",
  post_office: "700-7450",
  primary_school: "800-8200-0174",
  real_estate_agency: "700-7400",
  restaurant: "100-1000",
  roofing_contractor: "700-7400",
  rv_park: "900-9200",
  school: "800-8200-0174",
  secondary_school: "800-8200-0174",
  shoe_store: "600-6100",
  shopping_mall: "600-6100",
  spa: "700-7400",
  stadium: "800-8600",
  storage: "700-7400",
  store: "600-6100",
  subway_station: "400-4100-0037",
  supermarket: "600-6000",
  synagogue: "300",
  taxi_stand: "400-4100-0047",
  tourist_attraction: "300-3000",
  train_station: "400-4100-0039",
  transit_station: "400-4100",
  travel_agency: "350-3500",
  university: "800-8200-0173",
  veterinary_care: "700-7400",
  zoo: "550-5520",
  "textSearch-1": "red_pin",
  "textSearch-2": "yellow_pin",
  "textSearch-3": "orange_pin",
  "textSearch-4": "pink_pin",
  "textSearch-5": "purple_pin",
}

export function convertCategoriesForDetailsPane(selectedPOICategories: Iterable<string>): string[] {
  const selectedPOICategoriesWithVirtualTransportCategory = new Set(selectedPOICategories)
  if (selectedPOICategoriesWithVirtualTransportCategory.has("bus_station")) {
    selectedPOICategoriesWithVirtualTransportCategory.add("local_public_transport")
  }

  return [...selectedPOICategoriesWithVirtualTransportCategory].filter(
    (category) => category !== "bus_station" && category !== "transit_station" && category !== "light_rail_station"
  )
}
