import * as React from "react"
import { CompanyAdminState } from "../reducers/company-admin-state"
import { useSelector } from "react-redux"
import { translations } from "../i18n"
import { inviteMember, resetMemberInvite } from "../reducers/memberslist-slice"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import Dialog from "../../shared/components/dialog"
import DialogFooter from "../../shared/components/dialogfooter"
import { Option, OptionGroup, Select } from "../../shared/components/select"
import Panel from "../../shared/components/panel"
import LoadingDimmer from "../../shared/components/loadingdimmer"
import Grid from "../../shared/components/restyle-grid/grid"
import GridItem from "../../shared/components/restyle-grid/griditem"
import TextField from "../../shared/components/textfield"
import Button from "../../shared/components/button"
import { ReValidator } from "../../shared/models/genericinputelement"
import Form from "../../shared/components/form"
import { GenericError } from "../../shared/helper/axios"

interface CustomProps {
  onClose: () => void
}

export const InviteMemberDialog: React.FunctionComponent<CustomProps> = (props) => {
  const t = React.useMemo(translations, [translations])
  const [name, setName] = React.useState("")
  const [surname, setSurname] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [language, setLanguage] = React.useState(t.shortCode)

  const errorInviteMember = useSelector((state: CompanyAdminState) => state.companyMembersList.inviteMemberError)
  const inProgressInviteMember = useSelector(
    (state: CompanyAdminState) => state.companyMembersList.inviteMemberInProgress
  )

  const emailRegexp =
    /^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\ x01\-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i

  const resetState = () => {
    setName("")
    setSurname("")
    setEmail("")
    setLanguage(t.shortCode)
  }
  const isValid = () => {
    return name !== "" && surname !== "" && email !== ""
  }

  const createMailValidator = (): ReValidator<string> => {
    return (value: string) => {
      return {
        valid: emailRegexp.test(value),
        validationMessage: t.membersList.invalidEmail,
      }
    }
  }
  const onInviteMember = () => {
    inviteMember({ name, surname, email, language }).then(
      () => {
        resetState()
        props.onClose()
      },
      () => undefined
    )
  }

  const renderInviteMemberError = (error: GenericError) => {
    const HTTP_CONFLICT = 409
    if (error.status === HTTP_CONFLICT) {
      // happens when email address is already in use
      return <Panel color="negative">{t.membersList.duplicateEmailErrorMsg}</Panel>
    }
    return <GenericErrorPanel error={error} />
  }

  const render = () => {
    const onClose = () => {
      void resetMemberInvite()
      props.onClose()
    }
    const translation = t.membersList

    const languageOptions: (Option<string> | OptionGroup<string>)[] = [
      { value: "de", label: translation.language.de },
      { value: "en", label: translation.language.en },
    ]
    const emailValidator = createMailValidator()
    return (
      <Dialog
        title={translation.addNewMember}
        closeButton={true}
        onClose={onClose}
        closeOnClickOutside={false}
        width="50%"
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            onInviteMember()
          }}
        >
          <LoadingDimmer loading={inProgressInviteMember}>
            <Grid columns={1} gap="md" paddingY="md" paddingX="md">
              {errorInviteMember && renderInviteMemberError(errorInviteMember)}
              <Grid columns={2} gap="md">
                <GridItem colSpan={{ mobileLandscape: 2, desktopSmall: 1 }}>
                  <TextField
                    required
                    label={translation.nameLabel}
                    value={name}
                    onValueChange={(value) => setName(value)}
                  />
                </GridItem>
                <GridItem colSpan={{ mobileLandscape: 2, desktopSmall: 1 }}>
                  <TextField
                    required
                    label={translation.surnameLabel}
                    value={surname}
                    onValueChange={(value) => setSurname(value)}
                  />
                </GridItem>
                <GridItem colSpan={{ mobileLandscape: 2, desktopSmall: 1 }}>
                  <TextField
                    required
                    label={translation.emailLabel}
                    value={email}
                    onValueChange={(value) => setEmail(value.trim())}
                    customValidation={[emailValidator]}
                  />
                </GridItem>
                <GridItem colSpan={{ mobileLandscape: 2, desktopSmall: 1 }}>
                  <Select
                    options={languageOptions}
                    required
                    label={translation.languageLabel}
                    value={language}
                    onValueChange={(value) => setLanguage(value)}
                  />
                </GridItem>
              </Grid>
            </Grid>
          </LoadingDimmer>
          <DialogFooter spaceBetween="md" md-justify="end" paddingY="lg" paddingX="lg" direction="row">
            <Button type="primary" formType="submit" disabled={!isValid() || inProgressInviteMember}>
              {translation.inviteMemberLabel}
            </Button>
          </DialogFooter>
        </Form>
      </Dialog>
    )
  }

  return render()
}
