// Make sure the type definition matches the smartdata-product in s3://21re-assets/smartdata-products/21re.de/latest/

declare namespace window {
  const twentyone: {
    LANA_DATA_DICTIONARY: {
      LANA_AGS_NODES: LanaNode[]
      LANA_CELL_NODES: LanaNode[]
      LANA_CATEGORIES: {
        [name: CategoryName]: Category
      }
      LANA_DISTRICT_PROPS: {
        [name: DistrictPropName]: DistrictProp
      }
      LANA_DISTRICT_TOPICS: {
        [name: TopicName]: Topic
      }
      LANA_POSTCODE_PROPS: {
        [name: PostcodePropName]: PostcodeProp
      }
      LANA_POSTCODE_TOPICS: {
        [name: TopicName]: Topic
      }
      LANA_POSTCODE_CATEGORIES: {
        [name: CategoryName]: Category
      }
    }
  }
}

export interface Category {
  name: CategoryName
  title: HasTranslations
}

export interface HasTranslations {
  en: string
  de: string
}

export type CategoryName = string
export type ScoreName = string
export type RawScoreName = string
export type TopicName = string

export interface LanaNode {
  rawName: RawScoreName
  scoreName: ScoreName
  category: CategoryName
  title: HasTranslations
  description: HasTranslations
  source: HasTranslations
  unit: HasTranslations | null
  scoreVariation: ScoreName | null
  scoreVariationName: HasTranslations | null
}
export const LANA_CATEGORIES = window.twentyone.LANA_DATA_DICTIONARY.LANA_CATEGORIES
export const LANA_AGS_NODES = window.twentyone.LANA_DATA_DICTIONARY.LANA_AGS_NODES
export const LANA_CELL_NODES = window.twentyone.LANA_DATA_DICTIONARY.LANA_CELL_NODES

export interface Topic {
  name: TopicName
  title: HasTranslations
}
export declare type DistrictPropAggregationType = "sum" | "weighted_mean"

export type DistrictPropName = string

export interface DistrictProp {
  name: DistrictPropName
  title: HasTranslations
  description: HasTranslations
  category: CategoryName // Category name
  topic: TopicName // District Topic name
  kgs8Years: number[]
  kgs22Years: number[]
  denominatorVarName: DistrictPropName | null
  unit: HasTranslations | null
  aggregationType: DistrictPropAggregationType
  source: HasTranslations
  showToUser: boolean
}

export const LANA_DISTRICT_TOPICS = window.twentyone.LANA_DATA_DICTIONARY.LANA_DISTRICT_TOPICS

// We exclude internal properties from the exported object
export const LANA_CELL_NODES_BY_NAME: Map<ScoreName, LanaNode> = new Map(
  LANA_CELL_NODES.map((elem) => [elem.scoreName, elem])
)

export const LANA_DISTRICT_PROPS: { [name: DistrictPropName]: DistrictProp } = Object.fromEntries(
  Object.entries(window.twentyone.LANA_DATA_DICTIONARY.LANA_DISTRICT_PROPS).filter(([_, data]) => data.showToUser)
)

export type PostcodePropName = string

export type PostcodeProp = {
  name: PostcodePropName
  title: HasTranslations
  description: HasTranslations
  category: CategoryName
  topic: Topic
  agsYears: number[]
  plzYears: number[]
  denominatorVarName: PostcodePropName | null
  unit: HasTranslations | null
  aggregationType: DistrictPropAggregationType | "unavailable"
  source: HasTranslations
  showToUser: boolean
  aggregator: string | null
}

export const LANA_POSTCODE_PROPS: { [name: PostcodePropName]: PostcodeProp } = Object.fromEntries(
  Object.entries(window.twentyone.LANA_DATA_DICTIONARY.LANA_POSTCODE_PROPS).filter(([_, data]) => data.showToUser)
)

export const LANA_POSTCODE_TOPICS = window.twentyone.LANA_DATA_DICTIONARY.LANA_POSTCODE_TOPICS
export const LANA_POSTCODE_CATEGORIES = window.twentyone.LANA_DATA_DICTIONARY.LANA_POSTCODE_CATEGORIES

export const LANA_AGS_NODES_BY_NAME: Map<ScoreName, LanaNode> = new Map(
  LANA_AGS_NODES.map((elem) => [elem.scoreName, elem])
)
export const LANA_AGS_NODES_BY_RAW: Map<RawScoreName, LanaNode> = new Map(
  LANA_AGS_NODES.map((elem) => [elem.rawName, elem])
)
