import { default as Axios, AxiosPromise } from "axios"

function readFile(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result === null ? undefined : reader.result)
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}

export function doUploadImage(
  file: File,
  path: string,
  onUploadProgress: (currentProgress: number) => void
): AxiosPromise {
  return readFile(file).then((fileContent) => {
    return Axios.put(path, fileContent, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      onUploadProgress: (progressEvent) => {
        const percentDone = (progressEvent.loaded / (progressEvent.total ?? progressEvent.loaded)) * 100
        onUploadProgress(percentDone)
      },
    })
  })
}

export function doDeleteImage(path: string): AxiosPromise {
  return Axios.delete(path, {
    headers: {
      "Content-Type": "application/octet-stream",
    },
  })
}
