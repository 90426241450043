import React, { useState } from "react"
import {
  AssessmentDashboard,
  AssessmentDynamicDashboardConfig,
  DashboardEvents,
} from "../../assessment/models/AssessmentDashboard"
import { ItemRenderer } from "@blueprintjs/select/lib/esm/common/itemRenderer"
import {
  loadMyDashboards,
  setUserAssessmentDashboardError,
  startDashboardResize,
  startDashboardConfigure,
  stopDashboardEdit,
} from "../../relas/user-slice"
import { TextField } from "./ui/text-field"
import { MenuItem } from "@blueprintjs/core"
import { Select as SelectBlueprint } from "@blueprintjs/select/lib/esm/components/select/select"
import { Button as ButtonBlueprint } from "@blueprintjs/core/lib/esm/components/button/buttons"
import { css, cx } from "emotion"
import Axios from "axios"
import { lanaApiUrl } from "../../app_config"
import { translations } from "../../assessment/i18n"
import * as _ from "lodash"
import { v4 } from "uuid"
import { DashboardEditMode } from "../../assessment/components/dashboard/dashboard-widget-grid"
import Button from "./button"
import Text from "./text"
import { getThemeColorVar } from "../helper/color"
import { language } from "../language"
import Icon from "./icon"

const styles = {
  buttonSelectBlueprint: css({
    background: "white !important",
    borderRadius: "4px",
    justifyContent: "space-between",
    width: "160px !important",
    span: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "80%",
    },
  }),
  newLinkSection: css({
    display: "flex",
    marginRight: "5px",
    marginLeft: "5px",
    color: getThemeColorVar("primary", "default"),
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  }),
  linkSection: (isHighlightedItem: boolean) =>
    css({
      display: "flex",
      opacity: isHighlightedItem ? "1" : "0",
      pointerEvents: isHighlightedItem ? "all" : "none",
      justifyContent: "space-evenly",
    }),
  optionalLink: (isDefault: boolean) =>
    css({
      display: "flex",
      marginRight: "5px",
      marginLeft: "5px",
      color: isDefault ? getThemeColorVar("background", "light") : getThemeColorVar("primary", "default"),
      "&:hover": {
        cursor: isDefault ? "not-allowed" : "pointer",
        textDecoration: isDefault ? "none" : "underline",
        opacity: isDefault ? ".5" : "1",
      },
    }),
  clearButton: css({
    button: {
      color: `${getThemeColorVar("background", "dark")} !important`,
      borderColor: `${getThemeColorVar("background", "dark")} !important`,
    },
  }),
  dashboardName: css({
    paddingRight: "4em",
    display: "flex",
    flexDirection: "column",
  }),
  dashboardItemLine: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }),
}
export type DashboardSelectItem = AssessmentDashboard | undefined
export type DashboardSelectProps = {
  dashboardConfigurations: AssessmentDashboard[]
  editDashboardConfiguration: DashboardEditMode
  selectedDashboardConfiguration: DashboardSelectItem
  highlightedItem: DashboardSelectItem
  setHighlightedItem: (item: DashboardSelectItem) => void
  dashboardAddMode: "add" | "duplicate" | undefined
  setDashboardAddMode: (string?: "add" | "duplicate") => void
  handleItemClick: (item: DashboardSelectItem, e?: React.SyntheticEvent<HTMLElement>) => void
  setDashboardToDelete: (item: DashboardSelectItem) => void
  stopEdit: () => void
  defaultConfig?: () => AssessmentDynamicDashboardConfig
  trackDashboardSelectAction: (action: DashboardEvents, dashboardName: string) => void
}

const reservedNames = ["Default", "Standard"]
export const MultiDashboardSelect = (props: DashboardSelectProps) => {
  const t = React.useMemo(() => translations().dashboard.editDashboardConfigurationDialog, [language()])
  const [newConfigurationTitle, setNewConfigurationTitle] = React.useState<string>("")
  const [sameNameError, setSameNameError] = React.useState<boolean>(false)
  const [processingAction, setProcessingAction] = React.useState<boolean>(false)
  const [dashboardToCopy, setDashboardToCopy] = useState<DashboardSelectItem>(undefined)
  const orderedArray = React.useMemo(
    () =>
      _.orderBy(
        [...props.dashboardConfigurations].map((dashboard) =>
          dashboard.isDefault ? { ...dashboard, name: reservedNames[1] } : dashboard
        ),
        (dashboard) => dashboard.name.toLowerCase().replace(/ /g, ""),
        ["desc"]
      ),
    [props.dashboardConfigurations]
  )
  const buttonsDisabled = processingAction

  React.useEffect(() => {
    if (props.dashboardAddMode === "add" || props.dashboardAddMode === "duplicate") {
      document.querySelector<HTMLInputElement>(".dashboardAddTextFieldContainer input")?.focus()
    }
  }, [props.dashboardAddMode])

  const isAddOrCopyActive = () => props.dashboardAddMode === "add" || props.dashboardAddMode === "duplicate"
  const isItemHighlighted = (item: DashboardSelectItem) => item?.id === props.highlightedItem?.id
  const getDashboardName = (dashboard: DashboardSelectItem) => (dashboard ? dashboard?.name : "")
  const getDashboardLabel = (dashboard: DashboardSelectItem) =>
    dashboard ? (dashboard.isDefault ? reservedNames[1] : dashboard.name) : ""

  const openPopover = () => {
    props.stopEdit()
    props.setHighlightedItem(props.selectedDashboardConfiguration)
  }

  const closePopover = () => {
    props.setDashboardAddMode()
    setNewConfigurationTitle("")
    setDashboardToCopy(undefined)
    setSameNameError(false)
  }

  const createConfigHelper = async (name: string, config: AssessmentDynamicDashboardConfig) => {
    const trimmed = name.trim()
    const nameIsNotDuplicated = () =>
      props.dashboardConfigurations.every((c) => c.name.toLowerCase().trim() !== trimmed.toLowerCase())

    const nameIsNotReserved = !reservedNames.map((word) => word.toLowerCase().trim()).includes(trimmed.toLowerCase())

    if (nameIsNotReserved && nameIsNotDuplicated()) {
      setProcessingAction(true)

      try {
        await Axios.post<AssessmentDashboard>(`${lanaApiUrl}/api/v1/dashboards/my`, {
          name: trimmed,
          config: config,
        })

        const res = await loadMyDashboards(props.selectedDashboardConfiguration?.id, true)

        if (res) {
          const newDashboard = res.find((c) => c.name.toLowerCase() === trimmed.toLowerCase())
          props.setHighlightedItem(newDashboard)
          if (newDashboard) {
            props.trackDashboardSelectAction("DASHBOARD_CREATE_NEW", newDashboard.name)
          }
        }

        stopDashboardEdit()
        setSameNameError(false)
      } catch (e) {
        setUserAssessmentDashboardError(e)
      } finally {
        setProcessingAction(false)
        setNewConfigurationTitle("")
        props.setDashboardAddMode()
      }
    } else {
      setSameNameError(true)
    }
  }

  const createNewConfiguration = () => {
    const trimmed = newConfigurationTitle.trim()
    if (props.defaultConfig) {
      void createConfigHelper(trimmed, props.defaultConfig())
    }
  }

  const duplicateConfiguration = () => {
    if (dashboardToCopy) {
      const trimmed = newConfigurationTitle.trim()
      // Reassign ids to widgets and reset the version number
      const configCopy: AssessmentDynamicDashboardConfig = {
        ...dashboardToCopy.config,
        version: 1,
        widgets: dashboardToCopy.config.widgets.map((widget) => ({ ...widget, id: v4() })),
      }
      void createConfigHelper(trimmed, configCopy)
    }
  }

  const setDuplicateMode = (item: AssessmentDashboard) => {
    setNewConfigurationTitle(`${item.name.trim()} ${t.copy}`)
    setDashboardToCopy(item)
    props.setDashboardAddMode("duplicate")
  }

  const renderDashboardItem: ItemRenderer<DashboardSelectItem> = (item: DashboardSelectItem, { handleClick }) => {
    const renderContent = (item: DashboardSelectItem) => {
      if (item) {
        return (
          <div
            id={`dashboard-select-item-${item.id}`}
            className={cx("dashboard-select-item-dropdown-wrapper", styles.dashboardItemLine)}
          >
            <div className={styles.dashboardName}>
              <span>{getDashboardName(item)}</span>
            </div>
            <div className={styles.linkSection(isItemHighlighted(item))}>
              <div className={styles.newLinkSection} onClick={startDashboardConfigure} title={t.editContent}>
                <Icon name={"edit"} size={"regular"} />
              </div>
              <div className={styles.newLinkSection} onClick={startDashboardResize} title={t.editLayout}>
                <Icon name={"move_element"} size={"regular"} />
              </div>

              <div
                className={styles.newLinkSection}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setDuplicateMode(item)
                }}
                title={t.duplicate}
              >
                <Icon name={"copy_default"} size={"regular"} />
              </div>
              <div
                className={styles.optionalLink(item.isDefault)}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  if (!item.isDefault) {
                    props.setDashboardToDelete(item)
                  }
                }}
                title={t.delete}
              >
                <Icon name={"delete"} size={"regular"} />
              </div>
            </div>
          </div>
        )
      } else
        return (
          <div
            style={{ display: "flex", alignItems: "center", flexDirection: "column", cursor: "default" }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            {isAddOrCopyActive() ? (
              <div style={{ width: "100%", display: "flex", alignItems: "start", justifyContent: "space-between" }}>
                <div
                  className={"dashboardAddTextFieldContainer"}
                  style={{ display: "flex", alignItems: "start", flexDirection: "column", width: "75%" }}
                  onKeyDown={(key) => {
                    if (key.key === "Enter") {
                      document.querySelector<HTMLInputElement>("#add-new-dashboard-button")?.click()
                    }
                  }}
                >
                  <TextField
                    value={newConfigurationTitle}
                    intent={sameNameError ? "danger" : "none"}
                    onValueChange={(value) => {
                      setNewConfigurationTitle(value)
                      if (sameNameError) setSameNameError(false)
                    }}
                  />
                  {sameNameError && (
                    <div style={{ margin: "0 auto" }}>
                      <Text color="negative" colorType="default" size={"sm"}>
                        {t.dashboardAlreadyExists}
                      </Text>
                    </div>
                  )}
                </div>
                <div style={{ display: "flex", width: "30%", justifyContent: "space-evenly", alignItems: "start" }}>
                  <Button
                    id={"add-new-dashboard-button"}
                    type="primary"
                    icon="check"
                    size="small"
                    disabled={newConfigurationTitle.trim() === "" || buttonsDisabled}
                    onClick={() =>
                      props.dashboardAddMode === "add" ? createNewConfiguration() : duplicateConfiguration()
                    }
                  />
                  <div className={styles.clearButton}>
                    <Button
                      type="secondary"
                      icon="clear"
                      size="small"
                      onClick={() => {
                        props.setDashboardAddMode()
                        setNewConfigurationTitle("")
                        setSameNameError(false)
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{ height: "30px", display: "flex", cursor: "pointer", alignItems: "center" }}
                title={t.newDashboard}
                onClick={() => {
                  props.setDashboardAddMode("add")
                }}
              >
                <Icon name="add" size={"large"} fontSize={22} />
              </div>
            )}
          </div>
        )
    }

    return (
      <MenuItem
        key={item?.id || "extra-section"}
        style={{
          backgroundColor: isItemHighlighted(item) ? getThemeColorVar("background", "lighter") : "",
        }}
        onClick={handleClick}
        roleStructure="listoption"
        text={renderContent(item)}
        textClassName={!item?.id ? "MultiSelectAddMenuItem" : ""}
        onMouseEnter={() => (item?.id ? props.setHighlightedItem(item) : undefined)}
      />
    )
  }

  return (
    <SelectBlueprint
      className={"multiDashboardSelect"}
      filterable={false}
      items={[undefined, ...orderedArray]}
      itemRenderer={renderDashboardItem}
      onItemSelect={props.handleItemClick}
      popoverProps={{
        minimal: true,
        hasBackdrop: true,
        popoverClassName: !sameNameError ? "customSelectPopover" : "customSelectPopover customSelectPopoverError",
        onInteraction: (nextOpenState: boolean) => {
          if (nextOpenState) openPopover()
          else closePopover()
        },
      }}
    >
      <ButtonBlueprint
        className={styles.buttonSelectBlueprint}
        text={`${props.editDashboardConfiguration ? `${t.editing}: ` : ""}${getDashboardLabel(
          props.selectedDashboardConfiguration
        )}`}
        rightIcon="chevron-down"
      />
    </SelectBlueprint>
  )
}
