import * as React from "react"
import { translations } from "../i18n"
import { Translations } from "../i18n/translations"
import { AreaCodes } from "../models/area-codes"
import { StepOneError } from "../models/errors"
import { StepOneData } from "../models/step-one-data"
import { ChangeEvent } from "react"
import { RegistrationContainer } from "./registration-container"
import { useAppSelector } from "../../relas/store"
import { registrationDoStepOne } from "../reducers/registration-slice"
import { navigateTo } from "../../shared/reducers/navigation-slice"
import { NavigationPage } from "../../shared/actions/navigation"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import RevoCard from "../../shared/components/revo-card"
import Checkbox from "../../shared/components/checkbox"
import LoadingDimmer from "../../shared/components/loadingdimmer"
import Panel from "../../shared/components/panel"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"
import Grid from "../../shared/components/restyle-grid/grid"
import GridItem from "../../shared/components/restyle-grid/griditem"
import TextField from "../../shared/components/textfield"
import Button from "../../shared/components/button"
import { ReValidator } from "../../shared/models/genericinputelement"
import { language } from "../../shared/language"

export const RegistrationStepOne = () => {
  const stepOneError = useAppSelector((state) => state.registration.stepOneError)
  const inFlight = useAppSelector((state) => state.registration.stepOneInProgress)

  const [stepOneData, setStepOneData] = React.useState<StepOneData>({
    registrationPassword: "",
    name: "",
    surname: "",
    email: "",
    language: language() || "de",
    acceptedGTC: "",
    acceptedCommercialTerms: "",
  })
  const [validationErrors, setValidationErrors] = React.useState<string[]>(["name", "surname", "email", "agb"])
  const [lastEmail, setLastEmail] = React.useState<string>("")

  const translate: Translations = translations()
  // RFC 2822 compliant regexp - https://tools.ietf.org/html/rfc2822#section-3.4.1
  // https://gist.github.com/gregseth/5582254
  const emailRegexp =
    /^(?:[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\ x01\-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/

  const isInvalid = () => !(stepOneData.email && stepOneData.name && stepOneData.surname)

  const onChange = (key: string, value: string | AreaCodes, isValid: boolean) => {
    let newValidationErrors = validationErrors.slice()
    if (!isValid) {
      newValidationErrors.push(key)
    } else {
      newValidationErrors = newValidationErrors.filter((error) => error !== key)
    }

    setStepOneData({
      ...stepOneData,
      [key]: value,
    })
    setValidationErrors(newValidationErrors)
  }

  const isAgbAccepted = () => !validationErrors.some((key) => key === "agb")

  const register = () => {
    if (inFlight) return
    if (validationErrors.length > 0 || isInvalid()) return

    if (stepOneData) {
      if (validationErrors.length === 0) {
        setLastEmail(stepOneData.email)
        void registrationDoStepOne(stepOneData)
      }
    }
  }

  const createMailValidator: () => ReValidator<string> = () => {
    return (value: string) => {
      return {
        valid: emailRegexp.test(value),
        validationMessage: translate.stepOne.invalidEmail,
      }
    }
  }

  const renderError = () => {
    if (stepOneError === undefined) return null

    const translate = translations()

    switch (stepOneError) {
      case StepOneError.EmailTaken:
        return (
          <Panel color="negative" title={translate.stepOne.emailTakenHeader}>
            {translate.stepOne.emailTaken(lastEmail)}
          </Panel>
        )

      default:
        return <GenericErrorPanel error={stepOneError} />
    }
  }

  const onCheckboxChange = (changeEvent: ChangeEvent<HTMLInputElement>) => {
    if (changeEvent.target.checked) {
      setValidationErrors(validationErrors.filter((error) => error !== "agb"))
    } else {
      setValidationErrors(validationErrors.concat(["agb"]))
    }
  }

  const submitDisabled = validationErrors.length > 0 || isInvalid()

  const link = (
    <FlexContainer spaceBetween="md" direction="row">
      <a
        href="/login"
        onClick={(e) => {
          e.preventDefault()
          navigateTo({ name: NavigationPage.login })
        }}
      >
        {translate.links.iAlreadyHaveAnAccount}
      </a>
    </FlexContainer>
  )

  return (
    <RegistrationContainer title={translate.stepOne.header}>
      <RevoCard footer={link}>
        <LoadingDimmer loading={inFlight}>
          <Grid columns={12} gap={12}>
            <GridItem colSpan={12}>{renderError()}</GridItem>

            <GridItem colSpan={6}>
              <TextField
                id="21re-name"
                label={translate.stepOne.nameLabel}
                onValueChange={(value: string, isValid: boolean) => onChange("name", value, isValid)}
                required={true}
                maxLength={256}
                value={stepOneData.name}
              />
            </GridItem>
            <GridItem colSpan={6}>
              <TextField
                id="21re-surname"
                label={translate.stepOne.surnameLabel}
                onValueChange={(value: string, isValid: boolean) => onChange("surname", value, isValid)}
                required={true}
                maxLength={256}
                value={stepOneData.surname}
              />
            </GridItem>
            <GridItem colSpan={12}>
              <TextField
                id="21re-email"
                label={translate.stepOne.emailLabel}
                onValueChange={(value: string, isValid: boolean) => onChange("email", value.trim(), isValid)}
                required={true}
                maxLength={256}
                customValidation={[createMailValidator()]}
                value={stepOneData.email}
              />
            </GridItem>

            <GridItem colSpan={12}>
              <Checkbox
                id="21re-confirm-terms"
                checked={isAgbAccepted()}
                onChange={onCheckboxChange}
                label={translate.stepThree.agbLabel}
                required
              />
            </GridItem>

            <GridItem colSpan={12}>
              <div>
                <Button icon="signup" type="primary" onClick={() => register()} disabled={submitDisabled}>
                  {translate.stepThree.registerButtonText}
                </Button>
              </div>
            </GridItem>
          </Grid>
        </LoadingDimmer>
      </RevoCard>
    </RegistrationContainer>
  )
}
