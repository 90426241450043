import * as React from "react"
import { useEffect, useMemo, useState } from "react"
import { BorderBottom } from "../../shared/components/ui/border-bottom"
import { Flex } from "../../shared/components/ui/flex"
import { FlexItem } from "../../shared/components/ui/flex-item"
import { Grid } from "../../shared/components/ui/grid"
import { GridItem } from "../../shared/components/ui/grid-item"
import { language } from "../../shared/i18n/language"
import { ScrollBox } from "../../shared/components/ui/scroll-box"
import { AssessmentReportPage, AssessmentReportParams } from "../models/report-params"
import {
  AssessmentPriceType,
  COMPARABLES_PRICE_TYPES,
  Exclusiveness,
  HouseOrApartment,
  PRICE_TYPES,
  reconstructUsageType,
  YieldType,
} from "../models/assessment"
import { DataSetType } from "../../shared/models/smartdata"
import { Profile } from "../../profile/models/profile"
import { Scores } from "../../shared/models/scores"
import { translations } from "../i18n"
import { Checkbox, HTMLSelect, Tab, Tabs } from "@blueprintjs/core"
import { ExplorerRatingGradeLabel } from "../../shared/components/explorer-rating-grade-label"
import { css, cx } from "emotion"
import { lanaApiUrl } from "../../app_config"
import { POIExplorerInput } from "../../shared/models/poi-explorer"
import { POICategoryTranslations } from "../../shared/models/poi-category-translations"
import { getInitialMapStyle } from "../../shared/components/map-style-control"
import { isMetaRating, RatingLike } from "../../shared/models/ratings"
import PoiCategoriesList from "./POI-explorer/poi-categories-list"
import { trackUsageEvent } from "../../utils/usage-tracking"
import { NoContentDisclaimer } from "./assessment-no-content-disclaimer"
import { Category, LANA_AGS_NODES, LANA_CATEGORIES, LANA_CELL_NODES } from "../../shared/smartdata-products/smartdata"
import { useAppSelector } from "../../relas/store"
import { comparablesFormToQueryParams, comparablesInputDefault } from "../reducers/comparables-slice"
import Card from "../../shared/components/card"
import Dialog from "../../shared/components/dialog"
import Button from "../../shared/components/button"
import { getThemeColorVar } from "../../shared/helper/color"
import Icon from "../../shared/components/icon"
import { formatNumber } from "../../shared/helper/number-format"

const subTitleClass = css({
  fontWeight: "bold",
  fontSize: "16px",
})

const reportSettingsTabClass = css({
  outline: "none",
  padding: "0 8px",
})

const activeTabClass = css({
  fontSize: "16px",
})

type Props = {
  viewMode: DataSetType
  selectedProfile?: Profile
  selectedScoreCategory?: Category
  scores: Scores
  poiExplorerInput: POIExplorerInput
  poiCategoryTranslations: POICategoryTranslations | null
  isOpen: boolean
  onClose: () => void
}

const tabsWrapperClass = css({
  ".bp4-tab-indicator-wrapper": {
    background: "white",
    borderRadius: "4px 4px 0 0",
  },
})

type TabId = "scores" | "profiles" | "ratings" | "prices" | "poi"
type PriceCategory = "machineLearning" | "observedOffers" | "trends" | "rentIndex"

interface ReportSettings {
  selectedMicroScores: string[]
  selectedMacroScores: string[]
  selectedMicroProfiles: string[]
  selectedMacroProfiles: string[]
  includeMicroRatings: boolean
  includeMacroRatings: boolean
  includeMetaRatings: boolean
  selectedPriceCategories: { [type in PriceCategory]: boolean }
  selectedPriceTypes: { [type in AssessmentPriceType]: boolean }
  selectedYieldTypes: { [type in YieldType]: boolean }
  includePOIs: boolean
}

const styles = {
  profileItem: css({
    borderBottom: `1px solid ${getThemeColorVar("border", "default")}`,
    padding: "0 8px",
  }),
  singleProfile: css({
    marginBottom: "0 !important",
    "> div": {
      borderBottom: `1px solid ${getThemeColorVar("border", "default")}`,
      ":last-child": {
        borderBottom: 0,
      },
      "> div": {
        minHeight: "44px",
      },
    },
    label: {
      marginBottom: 0,
    },
  }),
  profileGroup: css({
    "div:first-child": {
      "> div:first-child": {
        borderTop: `1px solid ${getThemeColorVar("border", "default")}`,
      },
    },
    label: {
      borderTop: "0 !important",
      paddingLeft: "20px !important",
    },
    "label:last-child": {
      marginBottom: 0,
    },
    ".revo-grid__item": {
      borderTop: "0 !important",
    },
  }),
  groupHeader: (isActive: boolean, isGroupEmpty: boolean) =>
    css({
      borderBottom: `1px solid ${getThemeColorVar("border", "default")}`,
      padding: "10px",
      display: "flex",
      justifyContent: "space-between",
      height: "45px",
      alignItems: "center",
      backgroundColor: isActive ? getThemeColorVar("background", "lighter") : getThemeColorVar("white", undefined),
      cursor: isGroupEmpty ? "pointer" : "default",

      ":hover": {
        backgroundColor: isGroupEmpty
          ? getThemeColorVar("background", "lighter")
          : getThemeColorVar("white", undefined),
      },
    }),

  groupName: css({
    fontWeight: "bold",
  }),
  chevronWrap: css({
    cursor: "pointer",
  }),
  borderedWrapper: css({
    padding: "8px 12px",
    border: `1px solid ${getThemeColorVar("border", "default")}`,
    backgroundColor: getThemeColorVar("white", undefined),
    marginTop: "8px",
    marginBottom: "8px",
  }),
  priceTypeCheckboxContainer: css({
    height: "10em",
  }),
}

const SETTINGS_KEY = "AssessmentEntryReportSettings"

const defaultSelectedYieldTypes = {
  residential: false,
  office: false,
  retail: false,
}

function getReportSettings(): ReportSettings | null {
  if (typeof Storage !== "undefined") {
    const settings = window.localStorage.getItem(SETTINGS_KEY)
    if (settings !== null) {
      const parsedSettings = JSON.parse(settings)
      return parsedSettings.hasOwnProperty("selectedYieldTypes")
        ? parsedSettings
        : { ...parsedSettings, selectedYieldTypes: defaultSelectedYieldTypes }
    }
  }
  return null
}

function setReportSettings(settings: ReportSettings) {
  if (typeof Storage !== "undefined") {
    window.localStorage.setItem(SETTINGS_KEY, JSON.stringify(settings))
  }
}

function clearReportSettings() {
  if (typeof Storage !== "undefined") {
    window.localStorage.removeItem(SETTINGS_KEY)
  }
}

const DEFAULT_PREFIX = "default:"

function scoreNameOrDefault(scoreName: string): string {
  return scoreName.startsWith(DEFAULT_PREFIX) ? scoreName.substring(DEFAULT_PREFIX.length) : scoreName
}

export const AssessmentEntryReportConfiguration = ({
  viewMode,
  selectedProfile,
  poiExplorerInput,
  isOpen,
  onClose,
}: Props) => {
  const t = useMemo(translations, [translations])
  const formRef = React.useRef<HTMLFormElement>(null)
  const currentAssessment = useAppSelector((state) => state.assessment.currentAssessment)
  const currentAssessmentEntry = useAppSelector((state) => state.assessment.currentAssessmentEntry)
  const objectPricesParams = useAppSelector((state) => state.assessment.objectPricesParams)
  const rentIndexParams = useAppSelector((state) => state.assessment.rentIndexParams)
  const comparablesInput = useAppSelector((state) => state.comparablesApp.comparablesInput)
  const selectedMacroContext = useAppSelector((state) => state.assessment.currentAssessment?.macroContext)

  const [selectedTab, setSelectedTab] = React.useState<TabId>("profiles")
  const [selectedMicroScores, setSelectedMicroScores] = React.useState<string[]>([])
  const [selectedMacroScores, setSelectedMacroScores] = React.useState<string[]>([])
  const [selectedMicroProfiles, setSelectedMicroProfiles] = React.useState<string[]>([])
  const [selectedMacroProfiles, setSelectedMacroProfiles] = React.useState<string[]>([])
  const [includeMicroRatings, setIncludeMicroRatings] = React.useState(false)
  const [includeMacroRatings, setIncludeMacroRatings] = React.useState(false)
  const [includeMetaRatings, setIncludeMetaRatings] = React.useState(false)
  const [selectedPriceCategories, setSelectedPriceCategories] = React.useState<{ [type in PriceCategory]: boolean }>({
    machineLearning: false,
    observedOffers: false,
    trends: false,
    rentIndex: false,
  })
  const [selectedPriceTypes, setSelectedPriceTypes] = React.useState<{ [type in AssessmentPriceType]: boolean }>({
    residentialSale: false,
    residentialRent: false,
    office: false,
    retail: false,
    hall: false,
    plotSale: false,
    logistics: false,
  })

  const [selectedYieldTypes, setSelectedYieldTypes] =
    React.useState<{ [type in YieldType]: boolean }>(defaultSelectedYieldTypes)

  const [includePOIs, setIncludePOIs] = React.useState(false)
  const [saveSettings, setSaveSettings] = React.useState(true)
  const hasMacroRatings =
    currentAssessmentEntry !== null &&
    currentAssessmentEntry.ratings.findIndex((rating) => rating.dataSetType === "macro") >= 0
  const hasMicroRatings =
    currentAssessmentEntry !== null &&
    currentAssessmentEntry.ratings.findIndex((rating) => rating.dataSetType === "micro") >= 0
  const maxSelectedProfiles = 5

  const [collapsedGroup, setCollapsedGroup] = useState<string[]>([])

  const poiCategories: Set<string> = useMemo(() => {
    return new Set(poiExplorerInput.categories)
  }, [poiExplorerInput.categories])

  React.useEffect(() => {
    const settings = getReportSettings()

    if (settings) {
      setSaveSettings(true)
      setSelectedMicroScores(settings.selectedMicroScores)
      setSelectedMacroScores(settings.selectedMacroScores)
      setSelectedMicroProfiles(
        settings.selectedMicroProfiles.filter((r) => currentAssessmentEntry?.profiles.find((a) => a.id === r))
      )
      setSelectedMacroProfiles(
        settings.selectedMacroProfiles.filter((r) => currentAssessmentEntry?.profiles.find((a) => a.id === r))
      )
      setIncludeMicroRatings(settings.includeMicroRatings)
      setIncludeMacroRatings(settings.includeMacroRatings)
      setIncludeMetaRatings(settings.includeMetaRatings)
      setSelectedPriceCategories(settings.selectedPriceCategories)
      setSelectedPriceTypes(settings.selectedPriceTypes)
      setSelectedYieldTypes(settings.selectedYieldTypes)
      setIncludePOIs(settings.includePOIs)
    } else {
      setSaveSettings(false)
      // TB-490 We don't select any score category groups by default, should be reverted
      // setSelectedMicroScores(selectedScoreCategory && viewMode === "micro" ? [Object.keys(scores.micro)[0]] : []);
      // setSelectedMacroScores(selectedScoreCategory && viewMode === "macro" ? [Object.keys(scores.macro)[0]] : []);
      setSelectedMicroProfiles(selectedProfile && viewMode === "micro" ? [selectedProfile.id] : [])
      setSelectedMacroProfiles(selectedProfile && viewMode === "macro" ? [selectedProfile.id] : [])
      setSelectedPriceCategories({
        machineLearning: false,
        observedOffers: false,
        trends: false,
        rentIndex: false,
      })
      setSelectedPriceTypes({
        residentialSale: false,
        residentialRent: false,
        office: false,
        retail: false,
        hall: false,
        plotSale: false,
        logistics: false,
      })
      setSelectedYieldTypes(defaultSelectedYieldTypes)
    }
  }, [isOpen])

  useEffect(() => {
    if (currentAssessmentEntry?.profiles && selectedMacroProfiles.length > 0)
      setSelectedMacroProfiles(
        selectedMacroProfiles.filter((r) => currentAssessmentEntry?.profiles.find((a) => a.id === r))
      )
    if (currentAssessmentEntry?.profiles && selectedMicroProfiles.length > 0)
      setSelectedMicroProfiles(
        selectedMicroProfiles.filter((r) => currentAssessmentEntry?.profiles.find((a) => a.id === r))
      )
  }, [currentAssessmentEntry?.profiles, isOpen])

  useEffect(() => {
    const mergeProfiles = selectedMacroProfiles.concat(selectedMicroProfiles)

    let arr: Array<string> = []

    if (mergeProfiles.length > 0) {
      currentAssessmentEntry?.profiles.forEach((p) => {
        if (mergeProfiles.includes(p.id) && p.groupId !== undefined && !arr.includes(p.groupId)) {
          arr.push(p.groupId)
        }
      })
    }
    setCollapsedGroup(arr)
  }, [currentAssessmentEntry?.profiles, isOpen, selectedMacroProfiles, selectedMicroProfiles])

  useEffect(() => {
    if (!isOpen) setCollapsedGroup([])
  }, [isOpen])

  if (!currentAssessment || !currentAssessmentEntry) return null

  function reportParams(): AssessmentReportParams {
    const pages: AssessmentReportPage[] = []
    const mapStyle = getInitialMapStyle(undefined)

    for (const scoreName of selectedMacroScores) {
      pages.push({
        type: "MacroScore",
        scoreName: scoreNameOrDefault(scoreName),
        contextId: selectedMacroContext,
        mapStyle,
      })
    }
    for (const scoreName of selectedMicroScores) {
      pages.push({ type: "MicroScore", scoreName: scoreNameOrDefault(scoreName), mapStyle })
    }
    for (const profileId of selectedMacroProfiles) {
      pages.push({ type: "MacroProfile", profileId, contextId: selectedMacroContext, mapStyle })
    }
    for (const profileId of selectedMicroProfiles) {
      pages.push({ type: "MicroProfile", profileId, mapStyle })
    }

    if (includeMacroRatings) pages.push({ type: "MacroRatings" })
    if (includeMicroRatings) pages.push({ type: "MicroRatings" })
    if (includeMetaRatings) pages.push({ type: "MetaRatings" })

    if (selectedPriceCategories.machineLearning) {
      for (const priceType of PRICE_TYPES) {
        if (selectedPriceTypes[priceType]) {
          const exclusiveness = () => {
            if (objectPricesParams?.class) {
              return objectPricesParams.class
            }
            if (currentAssessmentEntry?.exclusiveness) {
              switch (currentAssessmentEntry.exclusiveness) {
                case Exclusiveness.Standard:
                  return 1
                case Exclusiveness.High:
                  return 2
                case Exclusiveness.Highest:
                  return 3
              }
            }

            return 1
          }

          pages.push({
            type: "ObjectPrice",
            usageType: reconstructUsageType(objectPricesParams?.houseOrApartment || "apartment", priceType),
            constructionYear: objectPricesParams?.constructionYear || currentAssessmentEntry?.year || 2000,
            area: objectPricesParams?.area || currentAssessmentEntry?.area || 100,
            class: exclusiveness(),
            newBuilding: objectPricesParams?.newBuilding || currentAssessmentEntry?.newBuilding || false,
          })
        }
      }
    }
    if (selectedPriceCategories.trends) {
      for (const priceType of PRICE_TYPES) {
        if (selectedPriceTypes[priceType]) {
          pages.push({ type: "PriceTrends", usageType: reconstructUsageType("apartment", priceType) })
        }
      }
    }

    Object.entries(selectedYieldTypes).map(([key, value]) => {
      if (value) {
        pages.push({ type: "MarketYield", yieldType: key as YieldType })
      }
    })

    if (
      selectedPriceCategories.observedOffers &&
      (comparablesInput.dataSource === "historical-21st" || comparablesInput.dataSource === "online-immoScout")
    ) {
      const currentInput =
        comparablesInput[comparablesInput.dataSource] || comparablesInputDefault(currentAssessmentEntry || undefined)

      const houseOrApartment: HouseOrApartment | undefined =
        comparablesInput.dataSource === "historical-21st"
          ? comparablesInput["historical-21st"]?.houseOrApartment
          : comparablesInput.dataSource === "online-immoScout"
          ? comparablesInput["online-immoScout"]?.houseOrApartment
          : undefined

      for (const priceType of COMPARABLES_PRICE_TYPES) {
        if (selectedPriceTypes[priceType]) {
          const baseParams = comparablesFormToQueryParams(currentInput, currentAssessmentEntry?.address.location)

          pages.push({
            type: "Comparables",
            mapStyle,
            ...baseParams,
            usageType: reconstructUsageType(houseOrApartment || comparablesInputDefault().houseOrApartment, priceType),
            dataSource: comparablesInput.dataSource,
          })
        }
      }
    }
    if (selectedPriceCategories.rentIndex) {
      pages.push({
        type: "RentIndex",
        constructionYear: rentIndexParams?.yearOfConstruction || currentAssessmentEntry?.year || 2000,
        area: rentIndexParams?.area || currentAssessmentEntry?.area || 100,
      })
    }

    if (includePOIs && poiCategories.size > 0) {
      pages.push({
        type: "PlaceInfo",
        categories: [...poiCategories],
        isochroneSettings: poiExplorerInput.isochroneSettings,
        isochronePolygon: poiExplorerInput.isochronePolygon
          ?.getPaths()
          .getArray()
          .map((p) => p.getArray().map((c) => c.toJSON()))
          .flat(),
        onlyWithingIsochrone: poiExplorerInput.onlyWithingIsochrone,
        onlyPrimaryCategories: false,
        textSearch: poiExplorerInput.poiTextSearch,
      })
    }

    let location = window.location
    let assetBaseURL = location.protocol + "//" + location.host + "/assets"

    return {
      language: language(),
      pages,
      assetBaseURL,
    }
  }

  function renderReport(endpoint: string) {
    if (saveSettings) {
      setReportSettings({
        selectedMicroScores,
        selectedMacroScores,
        selectedMicroProfiles,
        selectedMacroProfiles,
        includeMicroRatings,
        includeMacroRatings,
        includeMetaRatings,
        selectedPriceCategories,
        selectedPriceTypes,
        selectedYieldTypes,
        includePOIs,
      })
    } else {
      clearReportSettings()
    }

    if (!formRef || !formRef.current) return
    formRef.current.action = endpoint
    formRef.current.submit()

    onClose()
  }

  function renderMicroScore(category: Category, idx: number) {
    const categoryNodes = LANA_CELL_NODES.filter((v) => v.category === category.name).sort((x, y) =>
      t.pickTranslation(x.title).localeCompare(t.pickTranslation(y.title))
    )
    const selectionIdx = selectedMicroScores
      .map(scoreNameOrDefault)
      .findIndex((scoreName) => categoryNodes.findIndex((v) => v.scoreName === scoreName) >= 0)

    return (
      <Flex flexDirection="column" key={idx}>
        <Checkbox
          checked={selectionIdx >= 0}
          onChange={() => {
            if (selectionIdx >= 0) {
              setSelectedMicroScores([
                ...selectedMicroScores.slice(0, selectionIdx),
                ...selectedMicroScores.slice(selectionIdx + 1),
              ])
            } else {
              setSelectedMicroScores([...selectedMicroScores, DEFAULT_PREFIX + categoryNodes[0].scoreName])
            }
          }}
        >
          {t.pickTranslation(category.title)}
        </Checkbox>
        {selectionIdx >= 0 && (
          <FlexItem padding={[0, 0, 0, 20]}>
            <HTMLSelect
              fill
              value={selectedMicroScores[selectionIdx]}
              options={[
                {
                  value: DEFAULT_PREFIX + categoryNodes[0].scoreName,
                  label: t.assessmentEntryReport.chooseForMap,
                  disabled: true,
                },
                ...categoryNodes.map((v) => ({ value: v.scoreName, label: t.pickTranslation(v.title) })),
              ]}
              onChange={(e) =>
                setSelectedMicroScores([
                  ...selectedMicroScores.slice(0, selectionIdx),
                  e.currentTarget.value,
                  ...selectedMicroScores.slice(selectionIdx + 1),
                ])
              }
            />
          </FlexItem>
        )}
      </Flex>
    )
  }

  function renderMacroScore(category: Category, idx: number) {
    const categoryNodes = LANA_AGS_NODES.filter((v) => v.category === category.name).sort((x, y) =>
      t.pickTranslation(x.title).localeCompare(t.pickTranslation(y.title))
    )
    const selectionIdx = selectedMacroScores
      .map(scoreNameOrDefault)
      .findIndex((scoreName) => categoryNodes.findIndex((v) => v.scoreName === scoreName) >= 0)

    return (
      <Flex flexDirection="column" key={idx}>
        <Checkbox
          checked={selectionIdx >= 0}
          onChange={() => {
            if (selectionIdx >= 0) {
              setSelectedMacroScores([
                ...selectedMacroScores.slice(0, selectionIdx),
                ...selectedMacroScores.slice(selectionIdx + 1),
              ])
            } else {
              setSelectedMacroScores([...selectedMacroScores, DEFAULT_PREFIX + categoryNodes[0].scoreName])
            }
          }}
        >
          {t.pickTranslation(category.title)}
        </Checkbox>
        {selectionIdx >= 0 && (
          <FlexItem padding={[0, 0, 0, 20]}>
            <HTMLSelect
              fill
              value={selectedMacroScores[selectionIdx]}
              options={[
                {
                  value: DEFAULT_PREFIX + categoryNodes[0].scoreName,
                  label: t.assessmentEntryReport.chooseForMap,
                  disabled: true,
                },
                ...categoryNodes.map((v) => ({ value: v.scoreName, label: t.pickTranslation(v.title) })),
              ]}
              onChange={(e) =>
                setSelectedMacroScores([
                  ...selectedMacroScores.slice(0, selectionIdx),
                  e.currentTarget.value,
                  ...selectedMacroScores.slice(selectionIdx + 1),
                ])
              }
            />
          </FlexItem>
        )}
      </Flex>
    )
  }

  function renderScores() {
    const microCategories = Object.values<Category>(
      LANA_CELL_NODES.map((v) => v.category).reduce((acc, v) => {
        acc[v] = LANA_CATEGORIES[v]
        return acc
      }, {} as Record<string, Category>)
    ).sort((x, y) => t.pickTranslation(x.title).localeCompare(t.pickTranslation(y.title)))
    const macroCategories = Object.values<Category>(
      LANA_AGS_NODES.map((v) => v.category).reduce((acc, v) => {
        acc[v] = LANA_CATEGORIES[v]
        return acc
      }, {} as Record<string, Category>)
    ).sort((x, y) => t.pickTranslation(x.title).localeCompare(t.pickTranslation(y.title)))

    return (
      <Grid columns={2} gap={8}>
        <div className={subTitleClass}>{t.microScores}</div>
        <div className={subTitleClass}>{t.macroScores}</div>
        <ScrollBox bordered minWidth="316px">
          <Flex flexDirection="column" gap={4} padding={8}>
            {microCategories.map(renderMicroScore)}
          </Flex>
        </ScrollBox>
        <ScrollBox bordered minWidth="316px">
          <Flex flexDirection="column" gap={4} padding={8}>
            {macroCategories.map(renderMacroScore)}
          </Flex>
        </ScrollBox>
      </Grid>
    )
  }

  function renderProfile(profile: Profile, idx: number) {
    const isMicro = Object.keys(profile.scores.micro).length > 0
    const isMacro = Object.keys(profile.scores.macro).length > 0
    const microSelectionIdx = selectedMicroProfiles.indexOf(profile.id)
    const macroSelectionIdx = selectedMacroProfiles.indexOf(profile.id)
    const hasMicroOrMacroScore = isMicro || isMacro
    const isSelectedProfileLimitReached =
      selectedMicroProfiles.length + selectedMacroProfiles.length >= maxSelectedProfiles
    const isMicroScoreChecked = microSelectionIdx >= 0
    const isMacroScoreChecked = macroSelectionIdx >= 0

    return hasMicroOrMacroScore ? (
      <div className={styles.profileItem}>
        <Grid columnSpec="1fr 100px 100px" gap={16} justifyItems="center" alignItems="center" padding={8}>
          <span style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>{profile.name}</span>
          <div>
            {isMicro && (
              <Checkbox
                checked={isMicroScoreChecked}
                onChange={() => {
                  if (isMicroScoreChecked)
                    setSelectedMicroProfiles([
                      ...selectedMicroProfiles.slice(0, microSelectionIdx),
                      ...selectedMicroProfiles.slice(microSelectionIdx + 1),
                    ])
                  else setSelectedMicroProfiles([...selectedMicroProfiles, profile.id])
                }}
                disabled={isSelectedProfileLimitReached && !isMicroScoreChecked}
              />
            )}
          </div>
          <div>
            {isMacro && (
              <Checkbox
                checked={isMacroScoreChecked}
                onChange={() => {
                  if (isMacroScoreChecked)
                    setSelectedMacroProfiles([
                      ...selectedMacroProfiles.slice(0, macroSelectionIdx),
                      ...selectedMacroProfiles.slice(macroSelectionIdx + 1),
                    ])
                  else setSelectedMacroProfiles([...selectedMacroProfiles, profile.id])
                }}
                disabled={isSelectedProfileLimitReached && !isMacroScoreChecked}
              />
            )}
          </div>
        </Grid>
      </div>
    ) : null
  }

  function renderProfiles() {
    const setCollapsedGroups = (groupId: string) =>
      collapsedGroup.includes(groupId)
        ? setCollapsedGroup(collapsedGroup.filter((id) => id !== groupId))
        : setCollapsedGroup([...collapsedGroup, groupId])

    const expandedPanel = (isActive: boolean) =>
      css({
        maxHeight: isActive ? "auto" : 0,
        overflow: "hidden",
        transition: "max-height .5s ease",
        "> div > div": {
          borderTop: "0 !important",
          minHeight: "44px",
          paddingLeft: "16px",
        },
      })

    return (
      <div style={{ backgroundColor: getThemeColorVar("white", undefined) }}>
        <ScrollBox bordered minWidth="640px">
          <Flex flexDirection="column">
            <BorderBottom>
              <Grid columnSpec="1fr 100px 100px" padding={16} gap={8}>
                <div />
                <div className={subTitleClass}>{t.microScores}</div>
                <div className={subTitleClass}>{t.macroScores}</div>
              </Grid>
            </BorderBottom>
            <div className={styles.singleProfile}>
              {currentAssessmentEntry?.profiles.filter((profile) => profile.groupId === undefined).map(renderProfile)}
            </div>
            <div className={styles.profileGroup}>
              {currentAssessmentEntry?.profileGroups?.map((group, index) => {
                const isCollapsed = collapsedGroup.includes(group.id)
                const isGroupEmpty = currentAssessmentEntry.profiles.some((profile) => profile.groupId === group.id)

                return (
                  <div key={index}>
                    <div
                      className={styles.groupHeader(isCollapsed, isGroupEmpty)}
                      onClick={() => (isGroupEmpty ? setCollapsedGroups(group.id) : {})}
                    >
                      <span className={styles.groupName}>{group.name}</span>
                      <span className={styles.chevronWrap}>
                        <Icon name={isCollapsed ? "dropdown_up" : "dropdown_down"} />
                      </span>
                    </div>
                    <div className={expandedPanel(isCollapsed)}>
                      {currentAssessmentEntry.profiles
                        .filter((profile) => profile.groupId === group.id)
                        .map(renderProfile)}
                    </div>
                  </div>
                )
              })}
            </div>
          </Flex>
        </ScrollBox>
      </div>
    )
  }

  function renderSingleRating(rating: RatingLike) {
    if (!currentAssessmentEntry) return null

    let grade
    if (isMetaRating(rating)) {
      const result = currentAssessmentEntry.metaRatingResults[rating.id]
      grade = {
        label: result ? formatNumber(result.result, 2) : "-",
        color: result?.range?.color,
      }
    } else {
      const result = currentAssessmentEntry.ratingResults[rating.id]
      grade = result?.grade
    }

    return (
      <Flex flexDirection="row" key={rating.id} padding={[4, 0]}>
        <div>{rating.name}</div>
        <FlexItem flexGrow={1} />
        {grade && <ExplorerRatingGradeLabel grade={grade} />}
      </Flex>
    )
  }

  function renderRatings() {
    return (
      <Grid columns={3} gap={8} columnSpec={"1fr 1fr 1fr"}>
        <Card
          header={
            <Checkbox
              disabled={!hasMicroRatings}
              checked={includeMicroRatings}
              onChange={() => setIncludeMicroRatings(!includeMicroRatings)}
            >
              {t.scoreKey.micro}
            </Checkbox>
          }
        >
          <ScrollBox>
            {currentAssessmentEntry?.ratings.filter((r) => r.dataSetType === "micro").map(renderSingleRating)}
          </ScrollBox>
        </Card>
        <Card
          header={
            <Checkbox
              disabled={!hasMacroRatings}
              checked={includeMacroRatings}
              onChange={() => setIncludeMacroRatings(!includeMacroRatings)}
            >
              {t.scoreKey.macro}
            </Checkbox>
          }
        >
          <ScrollBox>
            {currentAssessmentEntry?.ratings.filter((r) => r.dataSetType === "macro").map(renderSingleRating)}
          </ScrollBox>
        </Card>
        {currentAssessmentEntry?.metaRatings && (
          <Card
            header={
              <Checkbox
                disabled={!currentAssessmentEntry?.metaRatings?.length}
                checked={includeMetaRatings}
                onChange={() => setIncludeMetaRatings(!includeMetaRatings)}
              >
                {t.metaScoreKey}
              </Checkbox>
            }
          >
            <ScrollBox>{currentAssessmentEntry?.metaRatings.map(renderSingleRating)}</ScrollBox>
          </Card>
        )}
      </Grid>
    )
  }

  function renderPrices() {
    return (
      isOpen && (
        <div>
          <div className={subTitleClass}>{t.assessmentEntryDetails.pricesTitle}</div>
          <div className={styles.borderedWrapper}>
            <Grid columnSpec="1fr min-content 1fr" rowSpec="min-content 1fr" colGap={8} alignItems="start">
              <div className={subTitleClass}>{t.assessmentEntryDetails.pricesTypesTitle}</div>
              <div />
              <div className={subTitleClass}>{t.assessmentEntryDetails.usageTypesTitle}</div>
              <div className={cx(styles.borderedWrapper, styles.priceTypeCheckboxContainer)}>
                <Flex flexDirection="column" gap={8}>
                  <Checkbox
                    checked={selectedPriceCategories.machineLearning}
                    onChange={() =>
                      setSelectedPriceCategories({
                        ...selectedPriceCategories,
                        machineLearning: !selectedPriceCategories.machineLearning,
                      })
                    }
                  >
                    {t.assessmentEntryDetails.machineLearningPrices}
                  </Checkbox>
                  <Checkbox
                    checked={selectedPriceCategories.observedOffers}
                    onChange={(e) => {
                      const newCats = {
                        ...selectedPriceCategories,
                        observedOffers: !selectedPriceCategories.observedOffers,
                      }
                      if (!newCats.observedOffers) {
                        setSelectedPriceTypes({ ...selectedPriceTypes, hall: false })
                      }
                      setSelectedPriceCategories(newCats)
                    }}
                  >
                    {t.assessmentEntryDetails.comparables}
                  </Checkbox>
                  <Checkbox
                    checked={selectedPriceCategories.trends}
                    onChange={() =>
                      setSelectedPriceCategories({
                        ...selectedPriceCategories,
                        trends: !selectedPriceCategories.trends,
                      })
                    }
                  >
                    {t.assessmentEntryDetails.pricesTabs.trends}
                  </Checkbox>
                  <Checkbox
                    checked={selectedPriceCategories.rentIndex}
                    onChange={() =>
                      setSelectedPriceCategories({
                        ...selectedPriceCategories,
                        rentIndex: !selectedPriceCategories.rentIndex,
                      })
                    }
                  >
                    {t.assessmentEntryDetails.pricesTabs.rentIndex}
                  </Checkbox>
                </Flex>
              </div>
              <div style={{ alignSelf: "center" }}>
                <Icon name="copy_link" />
              </div>
              <div className={cx(styles.borderedWrapper, styles.priceTypeCheckboxContainer)}>
                <Flex flexDirection="column" gap={8}>
                  <Checkbox
                    checked={selectedPriceTypes.residentialRent}
                    onChange={() =>
                      setSelectedPriceTypes({
                        ...selectedPriceTypes,
                        residentialRent: !selectedPriceTypes.residentialRent,
                      })
                    }
                  >
                    {t.assessmentEntryDetails.priceType.rent} / {t.assessmentEntryDetails.usageType.residential}
                  </Checkbox>
                  <Checkbox
                    checked={selectedPriceTypes.office}
                    onChange={() =>
                      setSelectedPriceTypes({ ...selectedPriceTypes, office: !selectedPriceTypes.office })
                    }
                  >
                    {t.assessmentEntryDetails.priceType.rent} / {t.assessmentEntryDetails.usageType.office}
                  </Checkbox>
                  <Checkbox
                    checked={selectedPriceTypes.retail}
                    onChange={() =>
                      setSelectedPriceTypes({ ...selectedPriceTypes, retail: !selectedPriceTypes.retail })
                    }
                  >
                    {t.assessmentEntryDetails.priceType.rent} / {t.assessmentEntryDetails.usageType.retail}
                  </Checkbox>
                  <Checkbox
                    checked={selectedPriceTypes.hall}
                    disabled={!selectedPriceCategories.observedOffers}
                    onChange={() => setSelectedPriceTypes({ ...selectedPriceTypes, hall: !selectedPriceTypes.hall })}
                  >
                    {t.assessmentEntryDetails.priceType.rent} / {t.assessmentEntryDetails.usageType.hall}
                  </Checkbox>
                  <Checkbox
                    checked={selectedPriceTypes.residentialSale}
                    onChange={() =>
                      setSelectedPriceTypes({
                        ...selectedPriceTypes,
                        residentialSale: !selectedPriceTypes.residentialSale,
                      })
                    }
                  >
                    {t.assessmentEntryDetails.priceType.sale} / {t.assessmentEntryDetails.usageType.residential}
                  </Checkbox>
                </Flex>
              </div>
            </Grid>
            <div>{t.assessmentEntryDetails.selectionWarning}</div>
          </div>
          <div className={subTitleClass}>{t.assessmentEntryDetails.yieldsTitle}</div>
          <div className={styles.borderedWrapper}>
            <Flex flexDirection="column" gap={8}>
              <Checkbox
                checked={selectedYieldTypes.residential}
                onChange={() =>
                  setSelectedYieldTypes({ ...selectedYieldTypes, residential: !selectedYieldTypes.residential })
                }
              >
                {t.assessmentEntryDetails.usageType.residential}
              </Checkbox>
              <Checkbox
                checked={selectedYieldTypes.office}
                onChange={() => setSelectedYieldTypes({ ...selectedYieldTypes, office: !selectedYieldTypes.office })}
              >
                {t.assessmentEntryDetails.usageType.office}
              </Checkbox>
              <Checkbox
                checked={selectedYieldTypes.retail}
                onChange={() => setSelectedYieldTypes({ ...selectedYieldTypes, retail: !selectedYieldTypes.retail })}
              >
                {t.assessmentEntryDetails.usageType.retail}
              </Checkbox>
            </Flex>
          </div>
        </div>
      )
    )
  }

  const renderPOI = () => (
    <Grid columns={1} gap={8}>
      <Card
        header={
          <Checkbox
            disabled={poiCategories.size === 0}
            checked={includePOIs}
            onChange={() => setIncludePOIs(!includePOIs)}
            style={{ whiteSpace: "nowrap", marginBottom: 0 }}
          >
            {t.assessmentEntryReport.showPOISummaryInReport}
          </Checkbox>
        }
      >
        {poiCategories.size === 0 ? (
          <NoContentDisclaimer fontSize={16}>
            <div>
              {t.assessmentEntryReport.noCategoriesSelected.split("\n").map((str) => (
                <div style={{ paddingTop: "0.5em" }}>{str}</div>
              ))}
            </div>
          </NoContentDisclaimer>
        ) : (
          <PoiCategoriesList
            selectedPOICategories={poiCategories}
            selectedPOIGroup={poiExplorerInput.group !== "manual-selection" ? poiExplorerInput.group : undefined}
            setSelectedPOICategories={() => {}}
            readonly={true}
            textSearch={poiExplorerInput.poiTextSearch}
          />
        )}
      </Card>
    </Grid>
  )
  const renderDocxReport = () => {
    trackUsageEvent(
      "DOWNLOAD_WORD_REPORT",
      currentAssessmentEntry.address,
      `${window.location.host}/${window.location.pathname.split("/")[1]}`
    )
    renderReport(
      `${lanaApiUrl}/api/assessments/${encodeURIComponent(currentAssessment.id)}/entries/${encodeURIComponent(
        currentAssessmentEntry.id
      )}/report/docx`
    )
  }

  const tabClassName = (currTab: TabId) =>
    selectedTab === currTab ? cx(activeTabClass, reportSettingsTabClass) : reportSettingsTabClass

  return (
    <>
      {isOpen && (
        <Dialog onClose={onClose} title={t.assessmentEntryReport.dialogTitle} closeButton height="700px">
          <div style={{ overflow: "auto", height: "100%", width: "800px" }}>
            <Grid columns={1} rowSpec="min-content 1fr min-content min-content" height={[100, "%"]}>
              <div className={tabsWrapperClass}>
                <div style={{ padding: "8px 24px 0 24px", background: getThemeColorVar("background", "lighter") }}>
                  <Tabs selectedTabId={selectedTab} onChange={(id: TabId) => setSelectedTab(id)} large={true}>
                    <Tab className={tabClassName("profiles")} id="profiles">
                      {`1 - ${t.assessmentEntryDetails.profiles}`}
                    </Tab>
                    {(hasMacroRatings || hasMicroRatings) && (
                      <Tab className={tabClassName("ratings")} id="ratings">
                        {`2 - ${t.assessmentEntryDetails.ratings}`}
                      </Tab>
                    )}
                    <Tab className={tabClassName("prices")} id="prices">
                      {`${hasMacroRatings || hasMicroRatings ? 3 : 2} - ${t.assessmentEntryDetails.pricesTabTitle}`}
                    </Tab>
                    <Tab className={tabClassName("poi")} id="poi">
                      {`${hasMacroRatings || hasMicroRatings ? 4 : 3} - ${t.poiExplorer.tabs.poi}`}
                    </Tab>
                  </Tabs>
                </div>
              </div>
              <BorderBottom padding={24}>
                {selectedTab === "scores" && renderScores()}
                {selectedTab === "profiles" && renderProfiles()}
                {selectedTab === "ratings" && renderRatings()}
                {selectedTab === "prices" && renderPrices()}
                {selectedTab === "poi" && renderPOI()}
              </BorderBottom>

              <Grid padding={[16, 24, 16, 24]} columnSpec="min-content 1fr min-content" rowGap={16} colGap={16}>
                <GridItem alignSelf="center">
                  <Checkbox
                    checked={saveSettings}
                    onChange={() => setSaveSettings(!saveSettings)}
                    style={{ whiteSpace: "nowrap", marginBottom: 0 }}
                  >
                    {t.assessmentEntryReport.saveSettings}
                  </Checkbox>
                </GridItem>
                <GridItem alignSelf="center" justifySelf="end">
                  <Button type="secondary" size="small" onClick={onClose}>
                    {t.cancel}
                  </Button>
                </GridItem>
                <GridItem alignSelf="center">
                  <Button type="primary" size="small" onClick={renderDocxReport}>
                    {t.assessmentEntryReport.downloadReport}
                  </Button>
                </GridItem>
              </Grid>
              <form ref={formRef} method="POST" target="_blank">
                <input name="value" type="hidden" value={JSON.stringify(reportParams())} />
              </form>
            </Grid>
          </div>
        </Dialog>
      )}
    </>
  )
}
