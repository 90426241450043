import { SelectionModes } from "./ratings-selection"
import { WidgetsType } from "../../models/assessment"

export type RatingsSelectionSettings = {
  selectedRatings: Array<string>
  selectedMetaRatings: Array<string>
  selectionMode: SelectionModes
}

export const defaultDistrictWidgetData = ["EW", "EW_PROG_30", "LC_TOTAL", "FA_GES", "UKZ_EW", "ALO_QU"]

export const defaultFundamentalWidgetData = defaultDistrictWidgetData.map((scoreName) => scoreName.toLowerCase())

export const defaultWidgetLayouts: { t: WidgetsType; x: number; y: number; w: number; h: number; static: boolean }[] = [
  {
    t: "macroMap",
    x: 0,
    y: 0,
    w: 2,
    h: 3,
    static: true,
  },
  {
    t: "nearestAccessibility",
    x: 2,
    y: 0,
    w: 2,
    h: 3,
    static: true,
  },
  {
    t: "microMap",
    x: 4,
    y: 0,
    w: 4,
    h: 6,
    static: true,
  },
  {
    t: "microScores",
    x: 8,
    y: 0,
    w: 2,
    h: 6,
    static: true,
  },
  {
    t: "macroScores",
    x: 10,
    y: 0,
    w: 2,
    h: 6,
    static: true,
  },
  {
    t: "districtData",
    x: 0,
    y: 3,
    w: 4,
    h: 3,
    static: true,
  },
  {
    t: "comparables",
    x: 0,
    y: 6,
    w: 2,
    h: 3,
    static: true,
  },
  {
    t: "rentIndex",
    x: 2,
    y: 6,
    w: 2,
    h: 3,
    static: true,
  },
  {
    t: "POIs",
    x: 4,
    y: 6,
    w: 2,
    h: 3,
    static: true,
  },
  {
    t: "specialMaps",
    x: 6,
    y: 6,
    w: 2,
    h: 3,
    static: true,
  },
  {
    t: "population",
    x: 8,
    y: 6,
    w: 4,
    h: 3,
    static: true,
  },
  {
    t: "MLPrices",
    x: 0,
    y: 9,
    w: 4,
    h: 3,
    static: true,
  },
  {
    t: "yields",
    x: 4,
    y: 9,
    w: 4,
    h: 3,
    static: true,
  },
  {
    t: "ratings",
    x: 8,
    y: 9,
    w: 4,
    h: 3,
    static: true,
  },
]
