import { State } from "../reducers/state"
import { useSelector } from "react-redux"
import { AssessmentTrendItem, AssessmentPriceType } from "../models/assessment"
import React, { FunctionComponent, useMemo } from "react"
import { Line } from "react-chartjs-2"
import { ChartData, ChartDataset, ChartOptions, ChartType, TooltipItem } from "chart.js"
import { translations } from "../i18n"
import { css } from "emotion"
import { numericYearWithQuarterToLabel } from "../../utils/numeric-year-with-quarter-to-label"
import { FillFromXOptions, fillFromXPlugin } from "../../utils/chart-plugins/fill-from-x"
import { bottomTextPlugin } from "../../utils/chart-plugins/bottom-text"
import GenericErrorPanel from "../../shared/components/genericerrorpanel"
import Panel from "../../shared/components/panel"
import { getThemeColor } from "../../shared/helper/color"
import LoadingSpinner from "../../shared/components/loadingspinner"

const centeredContainerCss = css({ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" })

interface Props {
  priceType: AssessmentPriceType
  noDataMessage?: string
  pointWidth?: number
  colors?: { primary: string; secondary: string }
}

export const AssessmentEntryTrends: FunctionComponent<Props> = (props) => {
  const colors = {
    primary: props.colors?.primary ?? getThemeColor("background", "darker").toString(),
    secondary: props.colors?.secondary ?? getThemeColor("secondary1", "dark").toString(),
  }
  const t = useMemo(translations, [translations])

  const { objectTrendsLoadInProgress, objectTrendsLoadError, currentObjectTrends } = useSelector(
    (state: State) => state.assessment
  )

  function extractPoint(item: AssessmentTrendItem): [number, number] {
    switch (props.priceType) {
      case "office":
        return [item.avg_office_rent_index, item.med_rent_office]
      case "retail":
        return [item.avg_retail_rent_index, item.med_rent_retail]
      case "residentialRent":
        return [item.avg_residential_rent_index, item.med_rent]
      case "residentialSale":
        return [item.avg_residential_sale_index, item.med_sale]
      case "hall":
      case "plotSale":
      case "logistics":
        return [0, 0]
    }
  }

  function transformTrendItem(item: AssessmentTrendItem) {
    const [pointValue, price] = extractPoint(item)
    return {
      x: item.qy,
      y: pointValue,
      t: price,
    }
  }

  function tooltipLabel(tooltipItem: TooltipItem<ChartType>): string[] {
    const datasetIndex = tooltipItem.datasetIndex
    const itemIndex = tooltipItem.dataIndex

    if (datasetIndex !== undefined && itemIndex !== undefined) {
      const label = tooltipItem.dataset.label ?? ""
      const itemsArray = tooltipItem.dataset.data ?? []
      const item: any = itemsArray[itemIndex]
      const price = "t" in item ? item.t : null

      return [
        `${label}`,
        ` `,
        `${t.assessmentEntryDetails.index}: ${Math.round(tooltipItem.parsed.y * 100)}%`,
        `Ø ${props.priceType === "residentialSale" ? t.assessmentEntryDetails.price : t.assessmentEntryDetails.rent}: ${
          Math.round(price * 100) / 100
        } EUR / m²`,
      ]
    }
    return [""]
  }

  function tooltipTitle(tooltipItem: TooltipItem<ChartType>[]): string {
    if (tooltipItem[0]) {
      return numericYearWithQuarterToLabel(tooltipItem[0].parsed.x)
    }
    return ""
  }

  function findFirstForecastedCell(
    sortedAgsData: AssessmentTrendItem[] | undefined,
    sortedCellData: AssessmentTrendItem[] | undefined
  ): FillFromXOptions | undefined {
    const firstAgsForecasted = sortedAgsData?.findIndex((v) => v.type == "forecast") ?? -1
    const firstCellForecasted = sortedCellData?.findIndex((v) => v.type == "forecast") ?? -1

    return [
      { datasetIndex: 0, pointIndexInDataset: firstAgsForecasted },
      {
        datasetIndex: 1,
        pointIndexInDataset: firstCellForecasted,
      },
    ]
      .filter((v) => v.pointIndexInDataset >= 0)
      .sort((x) => -x.pointIndexInDataset)
      .shift()
  }

  function renderContent() {
    let sortedAgsData: AssessmentTrendItem[] | undefined = currentObjectTrends?.ags_long_quarterly
      ? [...currentObjectTrends.ags_long_quarterly].sort((x, y) => x.qy - y.qy)
      : undefined
    let sortedCellData: AssessmentTrendItem[] | undefined = currentObjectTrends?.cell_long
      ? [...currentObjectTrends.cell_long].sort((x, y) => x.qy - y.qy)
      : undefined

    const agsChartPoints = sortedAgsData?.map(transformTrendItem) ?? []
    const cellChartPoints = sortedCellData?.map(transformTrendItem) ?? []

    // It will display two different data points for the first node on the chart, see TB-803
    if (agsChartPoints.length > 1) {
      agsChartPoints[0].y = 1.000000001
    }

    const [minY, maxY] = agsChartPoints.concat(cellChartPoints).reduce(
      ([min, max], v) => {
        return [v.y < min ? v.y : min, v.y > max ? v.y : max]
      },
      [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER]
    )

    const datasetConfigBase: Partial<ChartDataset<"line">> = {
      fill: false,
      pointHoverBorderColor: "white",
      pointHoverBorderWidth: 2,
      pointHoverRadius: 7,
      borderWidth: 2,
    }

    const chartData: ChartData<"line", Array<ReturnType<typeof transformTrendItem>>> = {
      datasets: [
        {
          ...datasetConfigBase,
          data: agsChartPoints,
          label: t.assessmentEntryDetails.agsPriceLabel,
          pointBackgroundColor: colors.primary,
          backgroundColor: colors.primary,
          borderColor: colors.primary,
          pointRadius: props.pointWidth,
        },
        {
          ...datasetConfigBase,
          data: cellChartPoints,
          label: t.assessmentEntryDetails.cellPriceLabel,
          pointBackgroundColor: colors.secondary,
          backgroundColor: colors.secondary,
          borderColor: colors.secondary,
          pointRadius: props.pointWidth,
        },
      ],
    }

    const minXAxis = Math.min(sortedAgsData ? sortedAgsData[0].qy : 2011, sortedCellData ? sortedCellData[0].qy : 2011)

    const maxXAxis = Math.max(
      sortedAgsData ? sortedAgsData[sortedAgsData.length - 1].qy : new Date().getFullYear(),
      sortedCellData ? sortedCellData[sortedCellData.length - 1].qy : new Date().getFullYear()
    )

    const chartOptions: ChartOptions<"line"> = {
      layout: {
        padding: {
          left: 10,
          right: 20,
          top: 10,
        },
      },
      scales: {
        y: {
          suggestedMin: minY,
          suggestedMax: maxY,
          ticks: {
            display: true,
            callback: (value, index, values) => {
              const numValue = typeof value === "number" ? value : parseFloat(value)
              if (isNaN(numValue)) {
                return value
              }

              return `${Math.round(numValue * 100)}`
            },
          },
          grid: {
            borderDash: [5, 5],
          },
        },
        x: {
          type: "linear",
          position: "bottom",
          min: minXAxis,
          max: maxXAxis,
          ticks: {
            font: {
              family: "Lato, Roboto, Arial, Helvetica, sans-serif",
              size: 12,
            },
            callback: (value, index) => {
              const numValue = typeof value === "number" ? value : parseFloat(value)
              if (isNaN(numValue)) {
                return value
              }
              if (numValue === maxXAxis) return ""
              else return numericYearWithQuarterToLabel(numValue)
            },
          },
        },
      },
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        fillFromX: findFirstForecastedCell(sortedAgsData, sortedCellData),
        bottomText: {
          text: t.assessmentEntryDetails.baseScoreLegend,
        },
        legend: {
          position: "bottom",
          align: "start",
          labels: {
            boxWidth: 12,
          },
        },
        tooltip: {
          titleFont: {
            size: 16,
            family: "Lato",
          },
          bodyFont: {
            size: 14,
            family: "Lato",
          },
          titleMarginBottom: 8,
          padding: {
            top: 8,
            left: 8,
            bottom: 8,
            right: 8,
          },
          titleSpacing: 16,
          caretPadding: 10,
          displayColors: false,
          backgroundColor: getThemeColor("background", "dark").toString(),
          callbacks: {
            label: tooltipLabel,
            title: tooltipTitle,
          },
        },
      },
    }

    if (objectTrendsLoadError) {
      return (
        <div className={centeredContainerCss}>
          <GenericErrorPanel error={objectTrendsLoadError} />
        </div>
      )
    }

    if (objectTrendsLoadInProgress) {
      return (
        <div className={centeredContainerCss}>
          <div>
            <div>
              <LoadingSpinner size={32} color={"primary"} />
            </div>
            {t.loadingScores}
          </div>
        </div>
      )
    }

    if (props.priceType == "plotSale" || props.priceType == "logistics" || props.priceType == "hall") {
      return (
        <div style={{ padding: "1em" }}>
          <Panel>{props.noDataMessage ?? t.prices.plotPricesAreNotAvailable}</Panel>
        </div>
      )
    }

    if (currentObjectTrends) {
      return <Line data={chartData} options={chartOptions} plugins={[fillFromXPlugin, bottomTextPlugin]} />
    }

    return null
  }

  const content = useMemo(renderContent, [
    objectTrendsLoadInProgress,
    objectTrendsLoadError,
    currentObjectTrends,
    props.priceType,
  ])

  return <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>{content}</div>
}
