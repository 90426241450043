import * as React from "react"
import { useEffect, useMemo } from "react"
import { translations } from "../../i18n"
import { Flex } from "../../../shared/components/ui/flex"
import { DeleteModes, PrivatePOICategory, ToDeleteSelection } from "../../models/private-data"
import Dialog from "../../../shared/components/dialog"
import Button from "../../../shared/components/button"
import { getThemeColorVar } from "../../../shared/helper/color"

interface localTranslationDocument {
  [key: string]: string
}
interface DeleteDialogProps {
  mode: DeleteModes
  selection: ToDeleteSelection
  onDelete: (mode: DeleteModes, selection: ToDeleteSelection) => void
  onCancel: () => void
}
export const DeleteDialog = ({ mode, selection, onDelete, onCancel }: DeleteDialogProps) => {
  const t = useMemo(translations, [translations])

  const [modeTranslation, setModeTranslation] = React.useState<localTranslationDocument>({
    title: "",
    message: "",
  })

  useEffect(() => {
    switch (mode) {
      case DeleteModes.CATEGORY:
        setModeTranslation(t.privateData.deleteDialog.category)
        break
      case DeleteModes.POI:
        setModeTranslation(t.privateData.deleteDialog.pois)
        break
      default:
        setModeTranslation({})
        break
    }
  }, [mode])

  const translateWithContent = (message: string) => {
    switch (mode) {
      case DeleteModes.CATEGORY:
        return message.replace("{}", `"${(selection as PrivatePOICategory).title}"`)
      case DeleteModes.POI:
        return message.replace("{}", `"${(selection as string[]).length}"`)
    }
  }

  const onClose = () => {
    onCancel()
  }

  return (
    <Dialog onClose={() => onClose()} closeOnClickOutside>
      <Flex flexDirection="column" gap={16} padding={0}>
        <Flex flexDirection="column" flexGrow={0} padding={56} gap={24}>
          <h2>{modeTranslation.title}</h2>
          <p>{translateWithContent(modeTranslation.message)}</p>
        </Flex>
        <Flex
          flexDirection="row-reverse"
          flexGrow={0}
          gap={8}
          padding={[12, 24]}
          alignItems="start"
          backgroundColor={`${getThemeColorVar("background", "lighter")}`}
        >
          <Button type="primary" icon="delete" onClick={() => onDelete(mode, selection)}>
            {t.privateData.deleteDialog.delete}
          </Button>
          <Button type="secondary" onClick={() => onCancel()}>
            {t.privateData.cancel}
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  )
}
