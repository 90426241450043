import { DataSetType, DataSetTypes } from "./smartdata"

export type Scores = Record<DataSetType, WeightOfScore>

export function getEmptyScores() {
  return DataSetTypes.reduce((r, v) => {
    r[v] = {}
    return r
  }, {} as Scores)
}

export type WeightOfScore = {
  [key: string]: number
}

export const DEFAULT_WEIGHT = 50

export interface ScoreResults {
  fields: string[]
  data: {
    [refId: string]: number[]
  }
}

export function getScoreResult(fields: string[], data: number[], field: string): number {
  const idx = fields.indexOf(field)

  if (idx < 0) return 0.0
  return data[idx]
}
