import React, { useEffect, useMemo, useState } from "react"
import { translations } from "../../i18n"
import { rentIndexUrl } from "../../../app_config"
import Axios, { AxiosError, AxiosResponse } from "axios"
import Panel from "../../../shared/components/panel"

type RentIndexCityInfo = {
  name: string
  years: number[]
}

export const RentIndexAssessmentList = () => {
  const t = useMemo(translations, [translations])

  const [cities, setCities] = useState<RentIndexCityInfo[]>()
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    Axios.get<RentIndexCityInfo[]>(`${rentIndexUrl}/api/cities`).then(
      (success: AxiosResponse) => {
        setCities(success.data)
        setError(false)
      },
      (error: AxiosError) => {
        setError(true)
        setCities([])
      }
    )
  }, [])

  return (
    <div style={{ padding: "32px", width: "80%", minWidth: "600px", margin: "0 auto", lineHeight: "1.5em" }}>
      <Panel>
        <h1 style={{ lineHeight: "2em" }}>{t.rentindex.availableRentIndices}</h1>
        <p>{t.rentindex.listOfAllCities}</p>
        {error ? (
          <Panel color={"negative"}>Error</Panel>
        ) : cities ? (
          <p>
            <ul>
              {[...cities]
                .sort((a, b) => a.name.localeCompare(b.name))
                .filter((city) => city.years.length > 0)
                .map((city, index) => (
                  <li key={index}>
                    <p style={{ lineHeight: "1.5em" }}>
                      {city.name} ({[...city.years].sort((a, b) => b - a)[0]})
                    </p>
                  </li>
                ))}
            </ul>
          </p>
        ) : (
          <p>&nbsp;</p>
        )}
      </Panel>
    </div>
  )
}
