import { Checkbox, Radio, RadioGroup } from "@blueprintjs/core"
import React, { useState } from "react"
import { translations } from "../../i18n"
import { DataSetType } from "../../../shared/models/smartdata"
import { MetaRating, Rating, RatingLike } from "../../../shared/models/ratings"
import { Flex } from "../../../shared/components/ui/flex"
import { FlexItem } from "../../../shared/components/ui/flex-item"
import { ScrollBox } from "../../../shared/components/ui/scroll-box"
import Expandable from "../../../shared/components/expandable"
import { css } from "emotion"
import Grid from "../../../shared/components/restyle-grid/grid"
import { getThemeColorVar } from "../../../shared/helper/color"

const styles = {
  radioGroup: css({
    padding: "16px",
  }),
  dataSetTypeClass: css({
    "> div": {
      borderLeft: "none",
      borderRight: "none",
      borderTop: "none",
    },
    "> div:first-child": {
      borderTop: `1px solid ${getThemeColorVar("border", "default")}`,
    },
  }),
  ratingsByDataSetType: css({
    "> div": {
      borderLeft: "none",
      borderRight: "none",
      borderTop: `1px solid ${getThemeColorVar("border", "default")}`,
    },
    "> div:first-child": {
      borderTop: "none",
    },
  }),
}

type Props = {
  ratings: Rating[]
  selectedRatingsInDrawer: Array<string>
  setSelectedRatingsInDrawer: (selected: Array<string>) => void
  selectedMetaRatingsInDrawer: Array<string>
  setSelectedMetaRatingsInDrawer: (selected: Array<string>) => void
  selectionModeInDrawer: SelectionModes
  setSelectionModeInDrawer: (mode: SelectionModes) => void
  metaRatings: MetaRating[]
}

export type SelectionModes = "all" | "partial"

export const RatingsSelection = (props: Props) => {
  const t = translations()
  const [expandedRatings, setExpandedRatings] = useState<Array<DataSetType | "meta">>(["micro", "macro", "meta"])

  const onToggleRatingHandler = (rating: RatingLike, collection: string[], callback: (arr: string[]) => void) => {
    const newSelectedRatings = collection.find((selectedRatingId) => selectedRatingId === rating.id)
      ? collection.filter((id) => id !== rating.id)
      : [...collection, rating.id]
    callback(newSelectedRatings)
  }

  const renderRating = (rating: Rating) => {
    return (
      <Flex key={rating.name} flexDirection="row" gap={4} padding={[16, 8, 8, 16]}>
        <Checkbox
          id={`fragment-${rating.name}`}
          key={`fragment-${rating.name}`}
          checked={!!props.selectedRatingsInDrawer.find((ratingId) => ratingId === rating.id)}
          onChange={() =>
            onToggleRatingHandler(rating, props.selectedRatingsInDrawer, props.setSelectedRatingsInDrawer)
          }
        />
        <label htmlFor={`fragment-${rating.name}`}>{rating.name}</label>
        <FlexItem flexGrow={1} />
      </Flex>
    )
  }

  const renderMetaRating = (rating: MetaRating) => {
    return (
      <Flex key={rating.name} flexDirection="row" gap={4} padding={[16, 8, 8, 16]}>
        <Checkbox
          id={`fragment-meta-${rating.name}`}
          key={`fragment-meta-${rating.name}`}
          checked={!!props.selectedMetaRatingsInDrawer.find((ratingId) => ratingId === rating.id)}
          onChange={() =>
            onToggleRatingHandler(rating, props.selectedMetaRatingsInDrawer, props.setSelectedMetaRatingsInDrawer)
          }
        />
        <label htmlFor={`fragment-${rating.name}`}>{rating.name}</label>
        <FlexItem flexGrow={1} />
      </Flex>
    )
  }

  const onExpandableClick = (dataSetType: DataSetType | "meta") => {
    const isSelected = expandedRatings.find((expanded) => dataSetType === expanded)
    const newExpanded = [...expandedRatings]
    isSelected ? newExpanded.splice(newExpanded.indexOf(dataSetType), 1) : newExpanded.push(dataSetType)
    setExpandedRatings(newExpanded)
  }

  const renderRatingsList = (dataSetType: DataSetType) => {
    const sortedRatingsByDataSetType = props.ratings
      .filter((rating) => rating.dataSetType === dataSetType)
      .sort((a, b) => a.name.localeCompare(b.name))

    return (
      <Expandable
        onClick={() => onExpandableClick(dataSetType)}
        key={`${dataSetType}-ratings`}
        header={dataSetType === "micro" ? t.dashboard.ratings.microRatings : t.dashboard.ratings.macroRatings}
        isOpen={!!expandedRatings.find((item) => item === dataSetType)}
      >
        <Grid columns={1} gap={4} padding={[4, 0]}>
          <div className={styles.ratingsByDataSetType}>
            {sortedRatingsByDataSetType.map((rating) => renderRating(rating))}
          </div>
        </Grid>
      </Expandable>
    )
  }

  const renderMetaRatingsList = () => {
    return (
      <Expandable
        onClick={() => onExpandableClick("meta")}
        key={`meta-ratings`}
        header={t.dashboard.ratings.metaRatings}
        isOpen={!!expandedRatings.find((item) => item === "meta")}
      >
        <Grid columns={1} gap={4} padding={[4, 0]}>
          <div className={styles.ratingsByDataSetType}>{props.metaRatings.map(renderMetaRating)}</div>
        </Grid>
      </Expandable>
    )
  }

  return (
    <>
      <RadioGroup
        className={styles.radioGroup}
        selectedValue={props.selectionModeInDrawer}
        onChange={(event) =>
          props.setSelectionModeInDrawer((event.target as HTMLTextAreaElement).value as SelectionModes)
        }
      >
        <Radio
          label={t.dashboard.ratings.allRatings}
          value={"all"}
          onClick={() => {
            props.setSelectedRatingsInDrawer(props.ratings.map((rating) => rating.id))
            props.setSelectedMetaRatingsInDrawer(props.metaRatings.map((rating) => rating.id))
          }}
        />
        <Radio
          label={t.dashboard.ratings.selectedRatings}
          value={"partial"}
          onClick={() => {
            props.setSelectedRatingsInDrawer([])
            props.setSelectedMetaRatingsInDrawer([])
          }}
        />
      </RadioGroup>
      {props.selectionModeInDrawer === "partial" && (
        <div style={{ height: "100%" }}>
          <ScrollBox minHeight={"100%"}>
            <div className={styles.dataSetTypeClass}>
              {renderRatingsList("macro")}
              {renderRatingsList("micro")}
              {renderMetaRatingsList()}
            </div>
          </ScrollBox>
        </div>
      )}
      {props.selectionModeInDrawer === "all" && <div></div>}
    </>
  )
}
