import * as React from "react"
import { IconName } from "@21re/revoicons/dist/icon-type"
import { css, cx } from "emotion"
import { FunctionComponent } from "react"
import { ColorConfig, getThemeColorVar } from "../helper/color"

export type IconSize = "small" | "regular" | "large" | "extra-large" | "xxl"

const iconClass = css({
  fontFamily: "Revoicons",
  fontSize: "24px",
  fontWeight: "normal",
  fontStyle: "normal",
  display: "inline-block",
  textTransform: "none",
  letterSpacing: "normal",
  wordWrap: "normal",
  whiteSpace: "nowrap",
  verticalAlign: "top",
  direction: "ltr",

  webkitFontSmoothing: "antialiased",
  textRendering: "optimizeLegibility",
  fontFeatureSettings: '"liga" 1',
})

const sizeClasses: { [key in IconSize]: string } = {
  small: css({
    lineHeight: 1,
    fontSize: "14px",
  }),

  regular: css({
    lineHeight: 1,
    fontSize: "24px",
  }),

  large: css({
    lineHeight: 1,
    fontSize: "34px",
  }),

  "extra-large": css({
    lineHeight: 1,
    fontSize: "44px",
  }),

  xxl: css({
    lineHeight: 1,
    fontSize: "54px",
  }),
}

export type Props = {
  name: IconName
  size?: IconSize
  fontSize?: number
} & (ColorConfig | DefaultColorConfig)

type DefaultColorConfig = {
  color?: undefined
  colorType?: undefined
}

export const Icon: FunctionComponent<Props> = (props) => {
  const { color, colorType, size, fontSize, name } = props
  const classes = [iconClass, "notranslate"]

  if (size) {
    classes.push(sizeClasses[size])
  }
  if (color && colorType) {
    classes.push(css({ color: getThemeColorVar(color, colorType) }))
  }
  if (fontSize) {
    classes.push(css({ fontSize: `${fontSize}px`, verticalAlign: "baseline" }))
  }

  return <i className={cx(classes)}>{name.replace(/\s/, "_")}</i>
}

export default Icon
