import React from "react"
import { useSelector } from "react-redux"
import { State } from "../../assessment/reducers/state"
import { AgsYieldType } from "../../assessment/models/assessment"
import { YieldMarketBars } from "../../assessment/components/assessment-entry-yield-market-bars"
import { translations } from "../../assessment/i18n"
import { PriceWidget } from "./price-widget"

export const MarketGrossYieldsV2 = () => {
  const t = React.useMemo(translations, [translations])
  const { assessmentEntryLoadInProgress } = useSelector((state: State) => state.assessment)

  const currentAssessmentEntry = useSelector((state: State) => state.assessment.currentAssessmentEntry)
  const yieldType = useSelector((state: State) => state.assessment.selectedYieldType)

  const topYieldType = `top_${yieldType}` as AgsYieldType
  const agsYields = currentAssessmentEntry?.agsYields[yieldType]
  const agsTopYields = currentAssessmentEntry?.agsYields[topYieldType]
  const cellYields = currentAssessmentEntry?.cellYields[yieldType]

  const getHeader = () => {
    switch (yieldType) {
      case "residential":
        return t.prices.marketYields.rentOrPurchaseResidential
      case "office":
        return t.prices.marketYields.rentOffice
      case "retail":
        return t.prices.marketYields.rentRetail
    }
  }
  return (
    <PriceWidget title={getHeader()} info={t.prices.marketYields.info}>
      <YieldMarketBars
        agsYields={agsYields}
        cellYields={cellYields}
        agsTopYields={agsTopYields}
        assessmentEntryLoadInProgress={assessmentEntryLoadInProgress}
        isPriceWidget={true}
        showMultiplier={true}
      />
    </PriceWidget>
  )
}
