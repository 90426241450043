import { Action, Store } from "redux"
import { Address } from "../assessment/models/address"
import { GlobalState } from "../relas/store"
import { AllNavigationLabels, NavigationPage } from "../shared/actions/navigation"
import { trackUsageEvent } from "./usage-tracking"

export function trackAppSwitches(store: Store<GlobalState, Action>) {
  let previousPage: AllNavigationLabels | null = null

  store.subscribe(() => {
    const currentState = store.getState()
    const currentPage = currentState.navigation.currentPage?.name

    // track when currentPage is defined AND when currentPage is not the same as the last page
    if (!currentPage || currentPage === previousPage) return

    const addressToTrack = getAddressToTrack(currentPage, currentState)
    let usageEventName: string | null = null

    switch (currentPage) {
      case NavigationPage.assessment:
        usageEventName = "APP_SWITCH_LOCATION_ASSESSMENT_DETAIL_VIEW"
        break
      case NavigationPage.ratings:
        usageEventName = "APP_SWITCH_LOCATION_ASSESSMENT_RATINGS"
        break
      case NavigationPage.prices:
        usageEventName = "APP_SWITCH_LOCATION_ASSESSMENT_PRICES"
        break
      case NavigationPage.marketdata:
        usageEventName = "APP_SWITCH_LOCATION_ASSESSMENT_DISTRICT_DATA"
        break
      case NavigationPage.comparables:
        usageEventName = "APP_SWITCH_LOCATION_ASSESSMENT_COMPARABLES"
        break
      case NavigationPage.dashboard:
        usageEventName = "APP_SWITCH_LOCATION_ASSESSMENT_DASHBOARD"
        break
      case NavigationPage.quickstart:
        usageEventName = "APP_SWITCH_QUICKSTART"
        break
      case NavigationPage.poiExplorer:
        usageEventName = "APP_SWITCH_LOCATION_ASSESSMENT_POI_EXPLORER"
        break
      case NavigationPage.specialMaps:
        usageEventName = "APP_SWITCH_LOCATION_ASSESSMENT_SPECIAL_MAPS"
        break
      case NavigationPage.rentindex:
        usageEventName = "APP_SWITCH_LOCATION_ASSESSMENT_RENTINDEX"
        break
      case NavigationPage.profiles:
        if (previousPage !== NavigationPage.profileEditor) {
          usageEventName = "APP_SWITCH_PROFILE_MANAGER"
        }
        break
      case NavigationPage.privateData:
        usageEventName = "APP_SWITCH_PRIVATE_DATA"
        break
      case NavigationPage.locationSelector:
        usageEventName = "APP_SWITCH_LOCATION_SELECTOR"
        break
      case NavigationPage.ratingManager:
        if (previousPage !== NavigationPage.ratingManagerEdit) {
          usageEventName = "APP_SWITCH_RATING_MANAGER"
        }
        break
      case NavigationPage.fundamentalData:
        usageEventName = "APP_SWITCH_LOCATION_ASSESSMENT_FUNDAMENTAL_DATA"
        break
    }

    usageEventName && trackUsageEvent(usageEventName, addressToTrack, null)
    previousPage = currentPage
  })
}

const NO_ADDRESS_REQUIRED_EVENTS = new Set<AllNavigationLabels>([
  NavigationPage.quickstart,
  NavigationPage.profiles,
  NavigationPage.locationSelector,
])

function getAddressToTrack(currentPage: AllNavigationLabels, currentState: GlobalState): Address | null {
  if (NO_ADDRESS_REQUIRED_EVENTS.has(currentPage)) return null

  return currentState.assessment.currentAssessmentEntry?.address ?? null
}
