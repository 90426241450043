import { LOCATION_SELECTOR_DE } from "./de"
import { LOCATION_SELECTOR_EN } from "./en"
import { LocationSelectorTranslations } from "./translations"
import { language } from "../../shared/language"

export function translations(): LocationSelectorTranslations {
  switch (language()) {
    case "de":
      return LOCATION_SELECTOR_DE
    default:
      return LOCATION_SELECTOR_EN
  }
}
