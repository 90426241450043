import * as React from "react"
import { translations } from "../i18n"
import { default as Axios } from "axios"
import { loginServiceUrl } from "../../app_config"
import Panel from "../../shared/components/panel"
import LoadingSpinner from "../../shared/components/loadingspinner"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"
import TextField from "../../shared/components/textfield"
import Button from "../../shared/components/button"
import Form from "../../shared/components/form"
import { ReValidator, ValidationResult } from "../../shared/models/genericinputelement"

enum SubmitState {
  in_progress,
  error,
  nothing,
  performed,
}

const MIN_LENGTH = 9
const MAX_LENGTH = 60

interface Props {
  passwordLabel: string
  confirmPasswordLabel: string
  submitPasswordLabel: string
  submitSuccessMessage: string
  resetToken?: string
}

export const NewPasswordInput = (props: Props) => {
  const translate = React.useMemo(() => translations(), [translations])

  const [newPassword, setNewPassword] = React.useState<string>("")
  const [confirmation, setConfirmation] = React.useState<string>("")
  const [isValid, setIsValid] = React.useState<boolean>(false)
  const [submitState, setSubmitState] = React.useState<SubmitState>(SubmitState.nothing)

  const newPasswordValidationRules: ReValidator<string>[] = [
    (value: string) => {
      if (value.length > MAX_LENGTH) {
        return { valid: false, validationMessage: translate.setPassword.tooLong(MAX_LENGTH) }
      }

      return { valid: true, validationMessage: "" }
    },
    (value: string) => {
      if (value.length < MIN_LENGTH) {
        return { valid: false, validationMessage: translate.setPassword.tooShort(MIN_LENGTH) }
      }

      return { valid: true, validationMessage: "" }
    },
  ]

  const conformationValidationRules: ReValidator<string>[] = [
    (value: string) => {
      if (value !== newPassword) {
        return { valid: false, validationMessage: translate.setPassword.notMatching }
      }

      return { valid: true, validationMessage: "" }
    },
  ]

  const onChangePassword = (value: string) => {
    setNewPassword(value)
    validatePasswords(value, confirmation)
  }

  const onChangeConfirmation = (value: string, valid: boolean) => {
    setIsValid(valid && isValid)
    setConfirmation(value)
    validatePasswords(newPassword, value)
  }

  const updateValidation = (value: string, rules: ReValidator<string>[]): ValidationResult[] => {
    const errors: ValidationResult[] = []
    rules.forEach((rule) => {
      const result = rule(value)
      result && !result.valid && errors.push(result)
    })
    return errors
  }

  const validatePasswords = (newPassword: string, confirmation: string) => {
    const newPasswordErrors = updateValidation(newPassword || "", newPasswordValidationRules)
    const confirmationErrors = updateValidation(confirmation || "", conformationValidationRules)

    setIsValid(newPasswordErrors.length == 0 && confirmationErrors.length == 0)
  }

  const doSetPassword = (event: any) => {
    setSubmitState(SubmitState.in_progress)

    if (props.resetToken) {
      Axios.post(
        `${loginServiceUrl}/api/forgottenPassword/${encodeURIComponent(props.resetToken)}`,
        { password: newPassword },
        { params: {} }
      )
        .then(() => setSubmitState(SubmitState.performed))
        .catch(() => setSubmitState(SubmitState.error))
    }
  }

  const renderSubmitState = () => {
    switch (submitState) {
      case SubmitState.nothing:
        return null
      case SubmitState.in_progress:
        return <LoadingSpinner />
      case SubmitState.error:
        return <Panel color="negative">{translate.setPassword.submitError}</Panel>
      case SubmitState.performed:
        return (
          <FlexContainer direction="column" spaceBetween="xl">
            <Panel color="positive">{translate.setPassword.submitSuccess}</Panel>
            <FlexContainer direction="row">
              <Button type="primary" id="21re-new-password-success-link" href="/">
                {translate.setPassword.loginNow}
              </Button>
            </FlexContainer>
          </FlexContainer>
        )
    }
  }

  const canSubmit = (): boolean => {
    return isValid
  }

  return (
    <div>
      <FlexContainer spaceBetween="xl" direction="column">
        {submitState !== SubmitState.performed && (
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              doSetPassword(e)
            }}
          >
            <FlexContainer spaceBetween="xl" direction="column">
              <TextField
                id="21re-new-password"
                label={props.passwordLabel}
                customValidation={newPasswordValidationRules}
                type="password"
                onValueChange={onChangePassword}
                required={true}
                value={newPassword}
              />
              <TextField
                id="21re-new-password-confirm"
                label={props.confirmPasswordLabel}
                customValidation={conformationValidationRules}
                type="password"
                onValueChange={onChangeConfirmation}
                required={true}
                value={confirmation}
              />
              <FlexContainer direction="row">
                <Button id="21re-new-password-submit" type="primary" disabled={!canSubmit()}>
                  {props.submitPasswordLabel}
                </Button>
              </FlexContainer>
            </FlexContainer>
          </Form>
        )}
        {renderSubmitState()}
      </FlexContainer>
    </div>
  )
}
