import * as React from "react"

export interface Props {
  text: string
  onClick?: React.MouseEventHandler<HTMLLabelElement>
}

export interface State {
  style: {
    transition?: string
    WebkitTransition?: string
    MozTransition?: string
    OTransition?: string
    msTransform?: string
  }
}

export class Label extends React.Component<Props, State> {
  private styleTimer: number

  constructor(props: Props) {
    super(props)

    this.state = { style: {} }
  }

  componentDidMount() {
    this.styleTimer = window.setTimeout(() => {
      const s = ".15s ease-out"
      const style = {
        transition: s,
        WebkitTransition: s,
        MozTransition: s,
        OTransition: s,
        msTransform: s,
      }

      this.setState({ style })
    }, 150)
  }

  componentWillUnmount() {
    window.clearTimeout(this.styleTimer)
  }

  render() {
    return (
      <label style={this.state.style} onClick={this.props.onClick}>
        {this.props.text}
      </label>
    )
  }
}
