import { ScrollBox } from "../../shared/components/ui/scroll-box"
import * as React from "react"
import { Selection } from "../../shared/models/selection"
import { translations } from "../i18n"
import { Selections } from "./selections"
import { DataSetType } from "../../shared/models/smartdata"
import { GlobalState, useAppSelector } from "../../relas/store"
import { getRelasAuthScopes } from "../../relas/user-state"
import {
  SelectionPane,
  setSelectionAndResetMunicipality,
  setViewPane,
  toSelectionModificationPane,
} from "../location-selector-slice"
import FlexContainer from "../../shared/components/restyle-grid/flexcontainer"
import Button from "../../shared/components/button"
import { getThemeColorVar } from "../../shared/helper/color"

export const SelectionList = () => {
  const t = React.useMemo(() => translations(), [])

  const selections = useAppSelector((state: GlobalState) => state.locationSelector.selectionList)
  const selectionsLoading = useAppSelector((state: GlobalState) => state.locationSelector.selectionListLoading)
  const currentSelection = useAppSelector((state: GlobalState) => state.locationSelector.currentSelection)
  const userId = useAppSelector((state: GlobalState) => state.user.user?.id)

  const [collapsed, setCollapsed] = React.useState(new Set<DataSetType>())

  const isCompanyAdmin = React.useMemo(() => getRelasAuthScopes().includes("CA"), [])

  const [macroSelections, microSelections] =
    selections === null
      ? [null, null]
      : selections.reduce<[Selection[], Selection[]]>(
          (acc, curr) => {
            const [macroSelections, microSelections] = acc
            return curr.dataSetType === "macro"
              ? [[curr, ...macroSelections], microSelections]
              : [macroSelections, [curr, ...microSelections]]
          },
          [[], []]
        )

  const toEditSelection = (s: Selection) => toSelectionModificationPane(SelectionPane.Edit, s)
  const toDuplicateSelection = (s: Selection) => toSelectionModificationPane(SelectionPane.Duplicate, s)
  const toRenameSelection = (s: Selection) => toSelectionModificationPane(SelectionPane.Rename, s)

  const sharedSetCollapse = (dataSetType: DataSetType) => (collapse: boolean) => {
    const newCollapsed = new Set(collapsed)
    collapse ? newCollapsed.add(dataSetType) : newCollapsed.delete(dataSetType)
    setCollapsed(newCollapsed)
  }

  return (
    <>
      <ScrollBox>
        <Selections
          selectedSelectionId={currentSelection?.id ?? null}
          title={"Macro"}
          selections={macroSelections}
          collapsed={collapsed.has("macro")}
          setCollapsed={sharedSetCollapse("macro")}
          toEditSelection={toEditSelection}
          toRenameSelection={toRenameSelection}
          toDuplicateSelection={toDuplicateSelection}
          setSelectedSelection={setSelectionAndResetMunicipality}
          selectionsLoading={selectionsLoading}
          userId={userId}
          isCompanyAdmin={isCompanyAdmin}
        />

        <Selections
          selectedSelectionId={currentSelection?.id ?? null}
          title={"Micro"}
          selections={microSelections}
          collapsed={collapsed.has("micro")}
          setCollapsed={sharedSetCollapse("micro")}
          toEditSelection={toEditSelection}
          toRenameSelection={toRenameSelection}
          toDuplicateSelection={toDuplicateSelection}
          setSelectedSelection={setSelectionAndResetMunicipality}
          selectionsLoading={selectionsLoading}
          userId={userId}
          isCompanyAdmin={isCompanyAdmin}
        />
      </ScrollBox>

      <div style={{ borderTop: `1px solid ${getThemeColorVar("border", "default")}` }}>
        <FlexContainer canGrow xs-align="end" direction="row" padding={16}>
          <FlexContainer canGrow />
          <Button onClick={() => setViewPane(SelectionPane.Create)} type="primary" icon="add">
            {t.createNew}
          </Button>
        </FlexContainer>
      </div>
    </>
  )
}
