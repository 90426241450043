import { NavigationPage, NavigationPageData } from "../actions/navigation"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { router, RouteTag } from "../../relas/router"
import { store } from "../../relas/store"
import { Router } from "../../relas/routing"
import { AppLocation } from "../../menu/util/app-location-types"
import { AssessmentDashboardId } from "../../assessment/models/AssessmentDashboard"

export interface NavigationState {
  currentPage?: NavigationPageData
  menuAppLocation?: AppLocation
  hideMenu: boolean
  printMode?: boolean
}

export const initialState: NavigationState = {
  hideMenu: false,
}

const navigationSlice = (<T extends RouteTag>(router: Router<T>) =>
  createSlice({
    name: "navigation",
    initialState,
    reducers: {
      navigateToAction: (state, action: PayloadAction<NavigationPageData>) => {
        if (JSON.stringify(state.currentPage) !== JSON.stringify(action.payload)) {
          const params: { [k in string]: string | null | boolean } =
            "params" in action.payload ? action.payload.params : {}

          const cleanParams = Object.keys(params)
            .filter((k) => params[k] != null)
            .reduce((a, k) => ({ ...a, [k]: params[k] }), {})

          const route = router.pushHistory(action.payload.name, cleanParams)

          if (route && route.tag && route.tag !== "hideMenu" && route.tag !== "printMode") {
            state.menuAppLocation = route.tag
          }
          state.currentPage = action.payload
        }
      },
      reportAppLocationAction(state, action: PayloadAction<AppLocation>) {
        state.menuAppLocation = action.payload
      },
      hideMenuAction(state, payload: PayloadAction<boolean>) {
        state.hideMenu = payload.payload
      },
      setPrintModeAction(state, payload: PayloadAction<boolean>) {
        state.printMode = payload.payload
      },
    },
  }))(router)

const { navigateToAction, hideMenuAction, reportAppLocationAction, setPrintModeAction } = navigationSlice.actions

export function navigateTo(navigationData: NavigationPageData): void {
  store.dispatch(navigateToAction(navigationData))
}

export function hideMenu(hide: boolean = true): void {
  store.dispatch(hideMenuAction(hide))
}

export function setPrintMode(printMode: boolean = true): void {
  store.dispatch(setPrintModeAction(printMode))
}

export function menuSetActiveAppLocation(locationTag: RouteTag): void {
  if (locationTag === "hideMenu") {
    hideMenu(true)
    setPrintMode(false)
  } else if (locationTag === "printMode") {
    hideMenu(true)
    setPrintMode(true)
  } else {
    hideMenu(false)
    setPrintMode(false)

    store.dispatch(reportAppLocationAction(locationTag))
  }
}

export function navigateToAppPage(
  assessmentId: string,
  entryId: string | null,
  module:
    | "comparables"
    | "marketdata"
    | "dashboard"
    | "poiExplorer"
    | "specialMaps"
    | "rentindex"
    | "ratings"
    | "prices"
    | "assessment"
    | "fundamentalData"
): void {
  store.dispatch(
    navigateToAction({
      name: module,
      params: {
        assessmentId,
        entryId: entryId,
      },
    })
  )
}

export function navigateToDashboardAppPage(
  assessmentId: string,
  entryId: string | null,
  pdf?: boolean,
  dashboardId?: AssessmentDashboardId
): void {
  if (pdf === true) {
    store.dispatch(
      navigateToAction({
        name: NavigationPage.dashboardPdf,
        params: { assessmentId, entryId, pdf, dashboardId },
      })
    )
  } else {
    store.dispatch(
      navigateToAction({
        name: NavigationPage.dashboard,
        params: { assessmentId, entryId },
      })
    )
  }
}

export default navigationSlice.reducer
