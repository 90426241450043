export interface Address {
  street: string
  houseNumber: string
  additionalAddressInformation?: string
  zip: string
  city: string
  country: string
}

export const emptyAddress: Address = {
  street: "",
  houseNumber: "",
  additionalAddressInformation: "",
  zip: "",
  city: "",
  country: "",
}

export function addressEquals(one: Address, other: Address) {
  return (
    one.street == other.street &&
    one.houseNumber == other.houseNumber &&
    one.additionalAddressInformation == other.additionalAddressInformation &&
    one.zip == other.zip &&
    one.city == other.city &&
    one.country == other.country
  )
}
