import {
  BaseQueryParam,
  Buckets,
  ComparablesApi,
  ComparablesItemShort,
  ComparablesStatsResponse,
  DatasourceType,
  DistributionGraphResult,
  ObjectType,
  Point,
  PriceTrend,
  PriceType,
  QueryDataSource,
  RangeParamOptInt,
  TwentyOneComparablesQueryParam,
} from "../../generated-apis/comparables-service"
import {
  ComparablesGraphsFetchResult,
  ComparablesState,
  ComparablesStatsResponseWithPriceType,
} from "../../shared/models/comparables-form-input"
import { ComparablesMapSettings, DataSource } from "../models/comparables"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  Assessment,
  AssessmentEntry,
  AssessmentEntryFull,
  AssessmentPriceType,
  HouseOrApartment,
} from "../models/assessment"
import GoogleMapReact, { Coords } from "google-map-react"
import Axios, { CancelTokenSource } from "axios"
import {
  HistoricalOffersInputForm,
  historicalOffersInputFormDefaults,
} from "../components/comparables/comparables-map/historical-offers-filters-model"
import { comparablesServiceUrl, lanaApiUrl } from "../../app_config"
import { store } from "../../relas/store"
import { ComparablesInputFormType } from "../components/comparables/comparablesV2"
import { AppModules } from "../../menu/util/app-location-types"
import { NumericRange } from "../../shared/models/numeric-range"
import { numericYearWithQuarterToTuple } from "../../utils/numeric-year-with-quarter-to-label"
import { assertUnreachable } from "../../utils/utils"
import { IsolineVehicleType } from "../../shared/models/poi-explorer"
import { assessmentDispatchActions } from "./assessment-slice"
import {
  ASSESSMENT_ENTRY_CHANGED_MODULE,
  AssessmentEntryChangedPayload,
} from "../actions/assessment-module-action-creators"
import { Location } from "../models/address"
import { IsochroneType } from "../components/isochrone-type"
import { Group } from "../../profile/models/profile"
import { GenericError, toGenericError } from "../../shared/helper/axios"

type ComparablesPriceStats = {
  [key in AssessmentPriceType]: ComparablesStatsResponse | null
}

export interface ComparablesAppState {
  comparablesItems: ComparablesItemShort[]
  comparablesItemsLoadInProgress: boolean
  comparablesItemsLoadError: GenericError | null

  comparablesInput: ComparablesState

  priceTrends: PriceTrend[]
  constructionYearDistribution: DistributionGraphResult | null
  priceDistribution: DistributionGraphResult | null
  areaDistribution: DistributionGraphResult | null
  offerYearDistribution: DistributionGraphResult | null

  graphsLoadingInProgress: boolean
  graphsLoadingError: GenericError | null

  comparablesMapSettings: ComparablesMapSettings

  comparablesStats: ComparablesPriceStats
  comparablesStatsLoadInProgress: boolean
  comparablesStatsLoadError: GenericError | null
}

interface ObjectAndPriceTypeForApiCall {
  objectType: ObjectType
  priceType: PriceType
}

type AssessmentWithEntryResult = [Assessment, AssessmentEntryFull]

const comparablesApi = new ComparablesApi(undefined, comparablesServiceUrl)
let comparablesLoadCancelToken: CancelTokenSource | undefined

let comparablesLoadStatsCancelToken: CancelTokenSource | undefined
export const comparablesInputDefault = (assessmentEntry?: AssessmentEntry) =>
  historicalOffersInputFormDefaults(assessmentEntry)
export const comparablesInputDefaultDataSource: DataSource = comparablesInputDefault().dataSource

const comparablesStatsDefault = {
  retail: null,
  office: null,
  residentialRent: null,
  residentialSale: null,
  hall: null,
  plotSale: null,
  logistics: null,
}

// Made own centerBerlin const as it has dependency issues when importing from reducers file
const centerBerlinComparable = {
  lng: 13.404954,
  lat: 52.520008,
}

const comparablesMapSettingsDefault = {
  zoom: 12,
  bounds: undefined,
  center: centerBerlinComparable,
  mapTypeId: undefined,
  isochrone: { mode: "none" as IsolineVehicleType, time: 15 },
}
export const initialState: ComparablesAppState = {
  comparablesItemsLoadError: null,
  comparablesItemsLoadInProgress: false,
  comparablesItems: [],

  comparablesInput: { dataSource: comparablesInputDefaultDataSource },
  priceTrends: [],
  constructionYearDistribution: null,
  priceDistribution: null,
  areaDistribution: null,
  offerYearDistribution: null,
  graphsLoadingError: null,
  graphsLoadingInProgress: false,
  comparablesMapSettings: comparablesMapSettingsDefault,
  comparablesStats: comparablesStatsDefault,
  comparablesStatsLoadInProgress: false,
  comparablesStatsLoadError: null,
}

const comparablesSlice = createSlice({
  name: "comparables",
  initialState,
  reducers: {
    comparablesItemLoadStart(state) {
      state.comparablesItems = []
      state.comparablesItemsLoadInProgress = true
      state.comparablesItemsLoadError = null
    },
    comparablesItemLoadDone(state, action: PayloadAction<ComparablesItemShort[]>) {
      state.comparablesItemsLoadInProgress = false
      state.comparablesItemsLoadError = null
      state.comparablesItems = action.payload
    },
    comparablesItemLoadError(state, action: PayloadAction<GenericError>) {
      state.comparablesItems = []
      state.comparablesItemsLoadInProgress = false
      state.comparablesItemsLoadError = action.payload
    },
    allGraphsFetchStart(state) {
      state.graphsLoadingInProgress = true
      state.priceTrends = []
      state.constructionYearDistribution = null
      state.priceDistribution = null
      state.areaDistribution = null
      state.offerYearDistribution = null
      state.graphsLoadingError = null
    },
    allGraphsFetchDone(state, action: PayloadAction<ComparablesGraphsFetchResult>) {
      state.areaDistribution = action.payload.areaDistribution
      state.constructionYearDistribution = action.payload.constructionYearDistribution
      state.offerYearDistribution = action.payload.offerYearDistribution
      state.priceDistribution = action.payload.priceDistribution
      state.priceTrends = action.payload.priceTrends
      state.graphsLoadingError = null
      state.graphsLoadingInProgress = false
    },
    allGraphsFetchError(state, action: PayloadAction<GenericError>) {
      state.graphsLoadingInProgress = false
      state.graphsLoadingError = action.payload
      state.priceTrends = []
      state.constructionYearDistribution = null
      state.priceDistribution = null
      state.areaDistribution = null
      state.offerYearDistribution = null
    },
    comparablesMapSettingsUpdateCenter(state, action: PayloadAction<Location>) {
      state.comparablesMapSettings.center = action.payload
    },
    comparablesMapSettingsUpdateBounds(state, action: PayloadAction<GoogleMapReact.Bounds>) {
      state.comparablesMapSettings.bounds = action.payload
    },
    comparablesMapSettingsUpdateZoom(state, action: PayloadAction<number>) {
      state.comparablesMapSettings.zoom = action.payload
    },
    comparablesMapSettingsUpdateMapTypeId(state, action: PayloadAction<string>) {
      state.comparablesMapSettings.mapTypeId = action.payload
    },
    comparablesMapSettingsUpdateIsochrone(state, action: PayloadAction<IsochroneType>) {
      state.comparablesMapSettings.isochrone = action.payload
    },

    comparablesStatsLoadStart(state) {
      state.comparablesStats = comparablesStatsDefault
      state.comparablesStatsLoadInProgress = true
      state.comparablesStatsLoadError = null
    },
    comparablesStatsLoadDone(state, action: PayloadAction<ComparablesStatsResponseWithPriceType>) {
      state.comparablesStats[action.payload.priceType] = {
        offerDateMax: action.payload.offerDateMax,
        offerDateMin: action.payload.offerDateMin,
        roomsMin: action.payload.roomsMin,
        roomsMax: action.payload.roomsMax,
        count: action.payload.count,
      }
      state.comparablesStatsLoadInProgress = false
      state.comparablesStatsLoadError = null
    },
    comparablesStatsLoadError(state, action: PayloadAction<GenericError>) {
      state.comparablesStats = comparablesStatsDefault
      state.comparablesStatsLoadInProgress = false
      state.comparablesStatsLoadError = action.payload
    },
    setComparablesInputDone(state, action: PayloadAction<ComparablesState[DataSource]>) {
      if (action.payload && action.payload.dataSource) {
        // TODO - there is an issue in the typescript/immer library, it should be possible to set state.comparablesInput[action.payload.dataSource] = action.payload
        ;(state.comparablesInput as any)[action.payload.dataSource] = action.payload
      }
    },
    comparablesInputDefaultDataSourceUpdateDone(state, action: PayloadAction<DataSource>) {
      state.comparablesInput.dataSource = action.payload
    },
  },
  extraReducers: {
    [ASSESSMENT_ENTRY_CHANGED_MODULE]: (state, action: PayloadAction<AssessmentEntryChangedPayload>) => {
      if (action.payload.newId !== action.payload.oldId) {
        state.comparablesMapSettings = getComparablesMapSettings(action.payload.newAssessmentEntry)
        state.comparablesInput = {
          dataSource: comparablesInputDefaultDataSource,
        }
        state.comparablesStats = comparablesStatsDefault
      } else {
        return
      }
    },
  },
})

const {
  comparablesItemLoadStart,
  comparablesItemLoadDone,
  comparablesStatsLoadStart,
  comparablesStatsLoadDone,
  comparablesStatsLoadError,
  comparablesItemLoadError,
  comparablesMapSettingsUpdateCenter,
  comparablesMapSettingsUpdateBounds,
  comparablesMapSettingsUpdateZoom,
  comparablesMapSettingsUpdateMapTypeId,
  comparablesMapSettingsUpdateIsochrone,
  setComparablesInputDone,
  allGraphsFetchStart,
  allGraphsFetchError,
  allGraphsFetchDone,
  comparablesInputDefaultDataSourceUpdateDone,
} = comparablesSlice.actions

function getComparablesMapSettings(entry?: AssessmentEntryFull): ComparablesMapSettings {
  if (entry) {
    const coords: Coords = entry.droppedLocation || entry.address.location || initialState.comparablesMapSettings.center

    return {
      ...initialState.comparablesMapSettings,
      center: coords,
    }
  } else {
    return initialState.comparablesMapSettings
  }
}

const toApiObjectPrice = (objectType: ObjectType, priceType: PriceType) => ({ objectType, priceType })
function getObjectAndPriceTypeForApiCall(
  priceType: AssessmentPriceType,
  houseOrApartment: HouseOrApartment
): ObjectAndPriceTypeForApiCall {
  const houseOrFlat = houseOrApartment === "house" ? ObjectType.House : ObjectType.Flat
  switch (priceType) {
    case "office":
      return toApiObjectPrice(ObjectType.Office, PriceType.Rent)
    case "retail":
      return toApiObjectPrice(ObjectType.Retail, PriceType.Rent)
    case "hall":
      return toApiObjectPrice(ObjectType.Hall, PriceType.Rent)
    case "residentialRent":
      return toApiObjectPrice(houseOrFlat, PriceType.Rent)
    case "residentialSale":
      return toApiObjectPrice(houseOrFlat, PriceType.Sell)
    case "plotSale":
      return toApiObjectPrice(ObjectType.Plot, PriceType.Sell)
    case "logistics":
      // TODO - not implemented in comparables-api backend yet (25.10.2023)
      return { objectType: "logistics" as ObjectType, priceType: PriceType.Rent }
  }
}
export function comparablesFormToQueryParams(formData: ComparablesInputFormType, point?: Point): BaseQueryParam {
  switch (formData.dataSource) {
    case "historical-21st":
      const { objectType, priceType } = getObjectAndPriceTypeForApiCall(
        formData.priceTypeComps,
        formData.houseOrApartment
      )
      const houseTypes = formData.houseTypes.map(({ value }) => value)

      let quarterAvailable: RangeParamOptInt | undefined = formData.quarterAvailable
        ? {
            from: formData.quarterAvailable.from <= 1 ? undefined : formData.quarterAvailable.from,
            to: formData.quarterAvailable.to >= 5 ? undefined : formData.quarterAvailable.to,
          }
        : undefined

      return {
        area: formData.areaRangeNeeded ? formData.areaRange : undefined,
        point: point,
        radius: formData.locationSource === "radius" ? formData.radius : undefined,
        objectType: objectType,
        houseTypes: objectType === "house" ? houseTypes : undefined,
        priceType: priceType,
        year: formData.constructionYearNeeded ? formData.constructionYearRange : undefined,
        rooms: formData.roomsFilterEnabled ? formData.roomsNumberRange : undefined,
        offerDate: formData.publicationDateNeeded
          ? formData.publicationTimeRange && {
              from: numericYearWithQuarterToTuple(formData.publicationTimeRange.from),
              to: numericYearWithQuarterToTuple(formData.publicationTimeRange.to),
            }
          : undefined,
        price: formData.priceNeeded ? formData.priceRange : undefined,
        zip: formData.locationSource === "zip" ? formData.zip : undefined,
        source: QueryDataSource._21re,
        furnished: formData.furnished,
        quarterAvailable: quarterAvailable?.from || quarterAvailable?.to ? quarterAvailable : undefined,
      }
    case "online-immoScout":
      const onlineObjectTypes = getObjectAndPriceTypeForApiCall(formData.priceTypeComps, formData.houseOrApartment)
      return {
        area: formData.areaRangeNeeded ? formData.areaRange : undefined,
        point: point,
        radius: formData.radius,
        objectType: onlineObjectTypes.objectType,
        priceType: onlineObjectTypes.priceType,
        rooms: formData.roomsFilterEnabled ? formData.roomsNumberRange : undefined,
        source: QueryDataSource.Immoscout,
      }
    case "historical-21st-transaction":
      return {
        dataSource: [DatasourceType.Transaction],
        source: QueryDataSource._21re,
        point: point,
      }
    case "historical-21st-senior-living":
      return {
        dataSource: [DatasourceType.SeniorLiving],
        source: QueryDataSource._21re,
        point: point,
        limit: 10000000,
      }
    default:
      assertUnreachable(formData)
  }
}

export function setComparablesInputDefaultDataSource(dataSource: DataSource): void {
  store.dispatch(comparablesInputDefaultDataSourceUpdateDone(dataSource))
}
export function fetchAssessmentComparablesStats(formData: ComparablesInputFormType, point?: Point): Promise<void> {
  comparablesLoadStatsCancelToken?.cancel("CANCELED")
  comparablesLoadStatsCancelToken = Axios.CancelToken.source()
  const queryParameters = comparablesFormToQueryParams(formData, point)

  const maybePriceType = formData.dataSource === "historical-21st" ? formData.priceTypeComps : undefined

  store.dispatch(comparablesStatsLoadStart())
  return comparablesApi
    .postApiV1ComparableStats(queryParameters, {
      cancelToken: comparablesLoadStatsCancelToken.token,
      withCredentials: true,
    })
    .then(
      (success) => {
        store.dispatch(
          comparablesStatsLoadDone({
            ...success.data,
            priceType: maybePriceType || "residentialRent",
          })
        )
      },
      (error) => {
        if (error.message !== "CANCELED") {
          store.dispatch(comparablesStatsLoadError(toGenericError(error)))
        }
      }
    )
}

export async function loadAssessmentForAssessmentModule(
  id: string,
  entryId: string | null,
  module: AppModules["locationAssessment"]
): Promise<AssessmentWithEntryResult | null> {
  try {
    const assessmentResponse = await Axios.get(`${lanaApiUrl}/api/assessments/${encodeURIComponent(id)}`)

    const resolvedEntryId: string =
      entryId ??
      (await Axios.get(`${lanaApiUrl}/api/assessments/${encodeURIComponent(id)}/firstEntry`).then(
        (response) => response.data.id
      ))

    const assessment = assessmentResponse.data

    const entry = await loadAssessmentEntryForModule(assessment, resolvedEntryId)

    store.dispatch(assessmentDispatchActions.assessmentLoadDoneModule({ assessment, entry }))

    return [assessment, entry] as AssessmentWithEntryResult
  } catch (error) {
    return null
  }
}

export async function loadAssessmentEntryForModule(
  assessment: Assessment,
  entryId: string
): Promise<AssessmentEntryFull> {
  const getEntryPromise = Axios.get<AssessmentEntryFull>(
    `${lanaApiUrl}/api/assessments/${encodeURIComponent(assessment.id)}/entries/${encodeURIComponent(entryId)}`
  )
  const getGroupsPromise = Axios.get<Group[]>(`${lanaApiUrl}/api/profilegroups`)

  const [entrySuccessResponse, profileGroupsResponse] = await Promise.all([getEntryPromise, getGroupsPromise])

  const profileGroups = profileGroupsResponse.data

  const entry: AssessmentEntryFull = { ...entrySuccessResponse.data, profileGroups }
  store.dispatch(assessmentDispatchActions.assessmentEntryLoadDone({ entry }))

  return entry
}

export function setAssessmentComparablesInput(comparablesData: ComparablesState[DataSource]) {
  if (comparablesData) {
    store.dispatch(setComparablesInputDone(comparablesData))
  }
}

export function fetchComparables(formData: ComparablesInputFormType, point: Point): Promise<void> {
  comparablesLoadCancelToken?.cancel("CANCELED")
  comparablesLoadCancelToken = Axios.CancelToken.source()

  store.dispatch(comparablesItemLoadStart())
  const queryParameters = comparablesFormToQueryParams(formData, point)

  return comparablesApi
    .postApiV1ComparableShort(queryParameters, {
      cancelToken: comparablesLoadCancelToken.token,
      withCredentials: true,
    })
    .then(
      (success) => {
        store.dispatch(comparablesItemLoadDone(success.data.items ?? []))
      },
      (error) => {
        if (error.message !== "CANCELED") {
          store.dispatch(comparablesItemLoadError(toGenericError(error)))
        }
      }
    )
}

export function fetchAllComparablesGraphs(formData: HistoricalOffersInputForm, point?: Point): Promise<void> {
  const defaultMinStepValue = 1
  const defaultBuckets = 20

  const rangeToBucket: (range?: NumericRange, minStep?: number, maxStep?: number) => Buckets | undefined = (
    range?: NumericRange,
    minStep?: number,
    maxStep?: number
  ) => {
    if (range) {
      const diff = Math.max(range.to, range.from) - Math.min(range.to, range.from)
      const step = Math.max(diff / defaultBuckets, minStep ?? defaultMinStepValue)
      return {
        max: range.to,
        min: range.from,
        step: maxStep ? Math.min(step, maxStep) : step,
      }
    }
    return undefined
  }

  const baseQueryParam: TwentyOneComparablesQueryParam = comparablesFormToQueryParams(formData, point)

  store.dispatch(allGraphsFetchStart())
  return Promise.all([
    comparablesApi.postApiV1ComparableGraphsPricetrend(baseQueryParam),
    comparablesApi.postApiV1ComparableGraphsPrice({
      ...baseQueryParam,
      buckets: rangeToBucket(baseQueryParam.price, 0.1),
    }),
    comparablesApi.postApiV1ComparableGraphsArea({
      ...baseQueryParam,
      buckets: rangeToBucket(baseQueryParam.area, 1),
    }),
    comparablesApi.postApiV1ComparableGraphsOfferyear({
      ...baseQueryParam,
      buckets: formData.publicationTimeRange && rangeToBucket(formData.publicationTimeRange, 0.25, 0.25),
    }),
    comparablesApi.postApiV1ComparableGraphsConstructionyear({
      ...baseQueryParam,
      buckets: baseQueryParam.year && rangeToBucket(baseQueryParam.year, 1),
    }),
  ]).then(
    ([r1, r2, r3, r4, r5]) => {
      const data: ComparablesGraphsFetchResult = {
        priceTrends: r1.data,
        priceDistribution: r2.data,
        areaDistribution: r3.data,
        offerYearDistribution: r4.data,
        constructionYearDistribution: r5.data,
      }
      store.dispatch(allGraphsFetchDone(data))
    },
    (err) => {
      store.dispatch(allGraphsFetchError(err))
    }
  )
}
export function updateComparablesMapSettingsCenter(newValue: Location): void {
  store.dispatch(comparablesMapSettingsUpdateCenter(newValue))
}

export function updateComparablesMapSettingsBounds(newValue: GoogleMapReact.Bounds): void {
  store.dispatch(comparablesMapSettingsUpdateBounds(newValue))
}

export function updateComparablesMapSettingsMapTypeId(newValue: string): void {
  store.dispatch(comparablesMapSettingsUpdateMapTypeId(newValue))
}

export function updateComparablesMapSettingsIsochrone(newValue: IsochroneType): void {
  store.dispatch(comparablesMapSettingsUpdateIsochrone(newValue))
}

export function updateComparablesMapSettingsZoom(newValue: number): void {
  store.dispatch(comparablesMapSettingsUpdateZoom(newValue))
}

export default comparablesSlice.reducer
