import * as React from "react"
import { css, cx } from "emotion"
import { Flex } from "./flex"
import { Grid } from "./grid"
import { translations } from "../../i18n"
import Axios, { AxiosResponse } from "axios"
import { WmsLayer, WmsPopupField } from "../../models/wms-layer"
import { PopupDataType } from "../../../assessment/components/special-maps/special-maps-popup"
import { getThemeColorVar } from "../../helper/color"
import Icon from "../icon"
import LoadingSpinner from "../loadingspinner"
import Text from "../text"

const popupClass = css({
  backgroundColor: getThemeColorVar("white", undefined),
  borderRadius: "5px",
  border: `1px solid ${getThemeColorVar("border", "default")}`,
  width: "570px",
  padding: "15px",
  fontSize: "14px",
})

const dataContainer = css({
  maxHeight: "340px",
  overflow: "auto",
})

const topBorder = css({
  borderTop: `1px solid ${getThemeColorVar("border", "default")}`,
  padding: "8px 8px 0",
  margin: "8px -8px 0",
})

interface WMSDetailsPopupProps {
  selectedLayer: WmsLayer
  url: string
  closeCallback?: () => void
  popupData?: PopupDataType
  setPopupData?: (data: PopupDataType) => void
}

type WMSDetailsState =
  | {
      state: "loading"
    }
  | {
      state: "unavailable"
    }
  | {
      state: "available"
      data: { [key: string]: string }[]
      isObject: boolean
    }

type DisplayData = { [key: string]: string }[]

type PhotoVoltaicPopupData = {
  results: {
    attributes: { [key: string]: string }
  }[]
}

export const WMSDetailsPopup: React.FunctionComponent<WMSDetailsPopupProps> = ({
  url,
  selectedLayer,
  closeCallback,
  popupData,
  setPopupData,
}) => {
  const t = React.useMemo(translations, [translations])
  const [state, setState] = React.useState<WMSDetailsState>({ state: "loading" })
  const setUnavailable = () => {
    setState({ state: "unavailable" })
    if (setPopupData) setPopupData("")
  }

  const getPopupState = (popupData: PopupDataType) => {
    if (typeof popupData === "string" && popupData.length > 0) {
      const featureInfo = new DOMParser().parseFromString(popupData, "text/xml")
      const knownFields = new Set(
        selectedLayer.popup?.fields.map((p) => p.id).concat(selectedLayer.popup?.headerFields.map((p) => p.id))
      )
      let data: DisplayData | null = null

      switch (selectedLayer.popup?.format) {
        case "boris":
          data = convertBoris(featureInfo, knownFields)
          break
        case "gml":
          data = convertGml(featureInfo, knownFields)
          break
        default:
          data = convertXml(featureInfo, knownFields)
          break
      }

      data && data.length > 0 ? setState({ state: "available", data, isObject: false }) : setUnavailable()
    } else if (typeof popupData === "object") {
      const photoVoltaicPopupData = (popupData as PhotoVoltaicPopupData).results[0]
      photoVoltaicPopupData
        ? setState({ state: "available", data: [photoVoltaicPopupData.attributes], isObject: true })
        : setUnavailable()
    } else {
      setUnavailable()
    }
  }

  React.useEffect(() => {
    if (popupData !== undefined) {
      getPopupState(popupData)
    } else {
      setState({ state: "loading" })
      Axios.get(url, { withCredentials: false }).then((response: AxiosResponse) => {
        if (setPopupData) setPopupData(response.data)
        getPopupState(response.data)
      }, setUnavailable)
    }
  }, [url])

  function renderValue(value: string, field: WmsPopupField) {
    const enumValue = field.values && field.values.find((e) => e.value === value)

    if (enumValue) {
      return <div>{t.pickTranslation(enumValue.label)}</div>
    }

    if (field.unit === "url") {
      if (value.trim().length === 0) return <></>
      const link =
        value.slice(0, 8) === "https://" || value.slice(0, 7) === "http://" ? value : "https://".concat(value)
      return (
        <a target="_blank" href={link}>
          LINK
        </a>
      )
    } else if (field.unit) {
      return (
        <div>
          {value} {field.unit}
        </div>
      )
    }

    return <div>{value}</div>
  }

  function renderData(data: { [key: string]: string }[], isObject: boolean) {
    if (!selectedLayer.popup) return null

    if (isObject) return renderNrwPVData(Object.entries(data[0]))

    return (
      <div className={cx(topBorder, dataContainer)}>
        {data.map((dataItem, idx) => (
          <React.Fragment key={idx}>
            <div style={{ fontWeight: "bold" }} className={idx > 0 ? topBorder : undefined}>
              <Grid columns={2} rowGap={4}>
                {selectedLayer.popup?.headerFields
                  .filter((field) => field.id in dataItem)
                  .map((field, fieldIdx) => (
                    <React.Fragment key={fieldIdx}>
                      <div>{t.pickTranslation(field.label)}</div>
                      <div>{renderValue(dataItem[field.id], field)}</div>
                    </React.Fragment>
                  ))}
              </Grid>
            </div>
            <div className={topBorder} key={idx}>
              <Grid columns={2} rowGap={4}>
                {selectedLayer.popup?.fields
                  .filter((field) => field.id in dataItem)
                  .map((field, fieldIdx) => (
                    <React.Fragment key={fieldIdx}>
                      <div>{t.pickTranslation(field.label)}</div>
                      <div>{renderValue(dataItem[field.id], field)}</div>
                    </React.Fragment>
                  ))}
              </Grid>
            </div>
          </React.Fragment>
        ))}
      </div>
    )
  }

  return (
    <div className={popupClass}>
      <Flex flexDirection={"row"}>
        <Text
          additionalStyles={{
            fontSize: "20px",
            lineHeight: "26px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            marginRight: "10px",
            flexGrow: 1,
            whiteSpace: "nowrap",
          }}
          size={"md"}
          fontWeight={"bold"}
          color={"typo"}
          colorType={"dark"}
        >
          {t.pickTranslation(selectedLayer.name)}
        </Text>
        <div
          className={css({ i: { fontSize: "16px" }, alignSelf: "center", cursor: "pointer" })}
          onClick={(e) => {
            e.stopPropagation()
            closeCallback?.()
          }}
        >
          <Icon name={"close"} color={"primary"} colorType={"default"} />
        </div>
      </Flex>
      {state.state === "loading" && (
        <div style={{ fontWeight: "bold" }} className={topBorder}>
          <LoadingSpinner />
        </div>
      )}
      {state.state === "unavailable" && (
        <div style={{ fontWeight: "bold" }} className={topBorder}>
          {t.map.landvalues.detailsUnavailable}
        </div>
      )}
      {state.state === "available" && renderData(state.data, state.isObject)}
    </div>
  )
}

const renderNrwPVData = (data: [string, string][]) => {
  const renderValue = (t0: string, t1: string) => {
    if (t0 === "HTML") return <div dangerouslySetInnerHTML={{ __html: t1 }} />
    if (t1.slice(0, 8) === "https://" || t1.slice(0, 7) === "http://")
      return (
        <a target="_blank" href={t1}>
          LINK
        </a>
      )
    return <div>{t1}</div>
  }

  return (
    <div className={cx(topBorder, dataContainer)}>
      {data
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map((tuple, idx) => (
          <div style={{ fontWeight: "bold" }} className={idx > 0 ? topBorder : undefined}>
            <Grid columns={2} rowGap={4} columnSpec={"1fr 2fr"} colGap={4}>
              <div>{tuple[0]}</div>
              {renderValue(tuple[0], tuple[1])}
            </Grid>
          </div>
        ))}
    </div>
  )
}

function convertGml(featureInfo: Document, knownFields: Set<string>): DisplayData {
  const names = featureInfo.getElementsByTagNameNS("http://www.opengis.net/gml", "name")
  const data: { [key: string]: string }[] = []

  for (let i = 0; i < names.length; i++) {
    const info = names[i].nextElementSibling

    if (!info) continue

    const dataItem: { [key: string]: string } = {}

    for (let j = 0; j < info.children.length; j++) {
      const field = info.children[j]

      if (field.namespaceURI === "http://www.opengis.net/gml" || !knownFields.has(field.localName)) continue

      const value = field.textContent?.trim() || ""

      if (value.length > 0) dataItem[field.localName] = value
    }
    if (Object.keys(dataItem).length > 0) data.push(dataItem)
  }

  return data
}

function convertBoris(featureInfo: Document, knownFields: Set<string>): DisplayData {
  const members = featureInfo.getElementsByTagNameNS("http://www.opengis.net/gml", "featureMember")
  const data: { [key: string]: string }[] = []

  for (let i = 0; i < members.length; i++) {
    const info = members[i].firstElementChild

    if (!info) continue

    const dataItem: { [key: string]: string } = {}

    for (let j = 0; j < info.children.length; j++) {
      const field = info.children[j]

      if (!knownFields.has(field.localName)) continue

      const value = field.textContent?.trim() || ""

      if (value.length > 0) dataItem[field.localName] = value
    }
    if (Object.keys(dataItem).length > 0) data.push(dataItem)
  }

  return data
}

function convertXml(featureInfo: Document, knownFields: Set<string>): DisplayData {
  const fieldsCollection = featureInfo.getElementsByTagName("FIELDS")

  const data: { [key: string]: string }[] = []

  for (let i = 0; i < fieldsCollection.length; i++) {
    const fields = fieldsCollection[i]

    const dataItem: { [key: string]: string } = {}

    for (const name of fields.getAttributeNames()) {
      if (!knownFields.has(name)) continue

      const value = fields.getAttribute(name)

      if (typeof value === "string" && value.length > 0 && value !== "Null") {
        dataItem[name] = value
      }
    }
    if (Object.keys(dataItem).length > 0) data.push(dataItem)
  }

  return data
}
