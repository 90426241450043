import * as React from "react"
import { MapViewProps, MapViewState, RatingManagerMapView } from "./rating-manager-map-view"
import MapBrowserEvent from "ol/MapBrowserEvent"
import { PopupFact } from "../../../shared/components/ui/map-popup"
import { mapStyles } from "./rating-manager-map-styles"
import { Flex } from "../../../shared/components/ui/flex"
import { FlexItem } from "../../../shared/components/ui/flex-item"
import { css, cx } from "emotion"
import { getInitialMapStyle } from "../../../shared/components/map-style-control"
import { AddressSuggestionAutoCompleteField } from "../../../shared/components/address-suggestion-auto-complete-field"
import { Address, Location } from "../../../assessment/models/address"
import { fetchMetaResults } from "../../rating-manager-actions"
import { isInMetaRange, MetaRating, MetaRatingRange } from "../../../shared/models/ratings"
import { bind } from "decko"
import { fromLonLat, toLonLat, transformExtent } from "ol/proj"
import * as ol from "ol"
import { Point } from "ol/geom"
import * as layer from "ol/layer"
import * as source from "ol/source"
import * as style from "ol/style"
import { reportAssetsUrl } from "../../../reports/report-config"
import Axios, { CancelTokenSource } from "axios"
import { geoServiceUrl } from "../../../app_config"
import { fetchAgsResLocViewport } from "../../../shared/actions/map-actions"
import { refRegionVectorTileOptions } from "../../../utils/openlayers"
import { language } from "../../../shared/language"
import Toggle from "../../../shared/components/toggle"
import { formatNumber } from "../../../shared/helper/number-format"
import LoadingSpinner from "../../../shared/components/loadingspinner"

type MetaMapState = {
  locationToTest?: Location
  resolvedAddress?: Address
  calculationInProgress: boolean
  calculationResult?: {
    result: number
    range?: MetaRatingRange
  }
  addressCleanupTrigger: number
} & MapViewState

type MetaMapProps = MapViewProps & {
  currentRating?: MetaRating
  hideAddressInput?: boolean
}

const styles = {
  resultBlock: css({
    display: "flex",
    flexDirection: "row",
    gap: 8,
    width: "400px",
  }),
  resultBlockLabel: css({
    fontWeight: 600,
    marginBottom: "5px",
  }),
  resultBlockValue: css({
    marginTop: "10px",
    textAlign: "center",
  }),
}

export class RatingManagerMapMetaView extends RatingManagerMapView<MetaMapProps, MetaMapState> {
  private addressCancelToken: CancelTokenSource | undefined
  private regionLayer: layer.VectorTile | undefined = undefined

  constructor(props: MapViewProps) {
    super(props)

    this.state = {
      selectedMapStyle: getInitialMapStyle(props.initialMapStyle),
      showPrivatePoisPopover: false,
      poiPopUpPosition: undefined,
      calculationInProgress: false,
      addressCleanupTrigger: 0,
    }
  }

  componentDidUpdate(prevProps: MetaMapProps, prevState: MetaMapState): void {
    super.componentDidUpdate(prevProps, prevState)
    if (this.state.locationToTest != prevState.locationToTest) {
      if (this.markerLayer) {
        this.getMap().removeLayer(this.markerLayer)
      }

      if (this.state.locationToTest) {
        const { lng, lat } = this.state.locationToTest
        this.getMap()
          .getView()
          .animate({ duration: 500, center: fromLonLat([lng, lat]), zoom: 18 })

        const markerSource = new source.Vector({
          features: [
            new ol.Feature({
              geometry: new Point(fromLonLat([lng, lat])),
            }),
          ],
        })
        this.markerLayer = new layer.Vector({
          source: markerSource,
          style: new style.Style({
            image: new style.Icon({
              src: `${reportAssetsUrl ?? ""}/assets/marker.svg`,
              size: [42, 60],
              anchorOrigin: "top-left",
              anchor: [0.5, 1],
            }),
          }),
        })
        this.getMap().addLayer(this.markerLayer)
        void this.calculate()
      }
    }

    if (this.props.currentRating != prevProps.currentRating && this.state.locationToTest) {
      this.setState({ calculationResult: undefined })
      void this.calculate()
    }

    this.regionLayer?.setVisible(this.props.mapErrorMessage !== "shape_outdated")
  }

  componentDidMount() {
    super.componentDidMount()

    this.regionLayer = new layer.VectorTile({
      declutter: true,
      source: new source.VectorTile(refRegionVectorTileOptions()),
      style: this.regionStyle,
    })

    this.getMap().addLayer(this.regionLayer)
  }

  protected fillAdditionalItemsForOverlay(item: RatingManagerMapItem, additionalItems: PopupFact[]): void {}

  @bind
  protected async onMapClick(event: MapBrowserEvent<any>) {
    if (this.props.locationToPresent) {
      return
    }
    const [lng, lat] = toLonLat(event.coordinate)
    this.setState({ locationToTest: { lng, lat }, addressCleanupTrigger: this.state.addressCleanupTrigger + 1 })
    this.addressCancelToken?.cancel()
    const lang = language()

    this.addressCancelToken = Axios.CancelToken.source()
    const addressResult = await Axios.get<Address[]>(
      `${geoServiceUrl}/api/here/reverse?lng=${lng}&lat=${lat}&language=${lang}`,
      {
        withCredentials: true,
        cancelToken: this.addressCancelToken.token,
      }
    )

    const addresses = addressResult.data
    if (addresses.length > 0) {
      this.setState({ resolvedAddress: addresses[0] })
    }
  }

  protected onSelection(id: string | null): void {}

  protected async resetMap() {
    try {
      const viewport = await fetchAgsResLocViewport(this.props.agsRefResLoc)
      viewport && this.getMap()?.getView().fit(transformExtent(viewport, "EPSG:4326", "EPSG:3857"))
    } catch (e) {
      console.error(e)
    }
    this.overlay?.closeCurrentOverlay(true)
  }

  @bind
  async calculate() {
    this.setState({ calculationResult: undefined })
    if (this.state.locationToTest && this.props.currentRating) {
      this.setState({ calculationInProgress: true })
      try {
        const response = await fetchMetaResults(this.props.currentRating.id, this.state.locationToTest, undefined)
        const result = response.result
        if (result !== undefined) {
          const range = this.props.currentRating?.ranges.find((r) => isInMetaRange(r, result))
          this.setState({ calculationResult: { result, range } })
        } else {
          this.setState({ calculationResult: undefined })
        }
        this.setState({ calculationInProgress: false })
      } catch (e) {
        if (!Axios.isCancel(e)) {
          this.setState({ calculationInProgress: false })
        }
      }
    }
  }

  render() {
    return (
      <div className={cx(mapStyles.containerClass, "rating-manager-map")}>
        {super.render()}
        {this.props.hasPreview && (
          <div className={mapStyles.showListContainerClass}>
            <Flex flexDirection="row" alignItems="center" gap={16}>
              <Toggle label={this.t.preview} checked={this.props.preview} onChange={this.props.onPreviewToggle} />
            </Flex>
          </div>
        )}
        {!this.props.hideAddressInput && (
          <div
            className={cx(
              styles.resultBlock,
              mapStyles.showListContainerClass,
              "rating-manager-map-search-meta-popup-content"
            )}
          >
            <FlexItem>
              <AddressSuggestionAutoCompleteField
                label={this.t.map.poiMapPopup.address}
                onValueChange={(v) => this.setState({ locationToTest: v?.location })}
                cleanUpTrigger={this.state.addressCleanupTrigger}
                forcedAddress={this.state.resolvedAddress}
              />
              <div>
                <p style={{ padding: "0.5em 0", lineHeight: "1.5em" }}>{this.t.metaMapInfo}</p>
              </div>
            </FlexItem>
            <FlexItem>
              <label className={styles.resultBlockLabel}>{this.t.rating}</label>
              <div
                className={cx(
                  styles.resultBlockValue,
                  mapStyles.calculationResult(this.state.calculationResult?.range)
                )}
              >
                {!this.state.calculationInProgress &&
                  (this.state.calculationResult?.result !== undefined
                    ? formatNumber(this.state.calculationResult?.result, 2)
                    : "-")}
                {this.state.calculationInProgress && <LoadingSpinner size={20} />}
              </div>
            </FlexItem>
          </div>
        )}
        {this.mapLoadingMessage()}
        {this.mapRecalculationMessage()}
      </div>
    )
  }
}
