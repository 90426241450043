import { SHARED_EN } from "../../shared/i18n/en"
import { PrivateDataTranslationsGroup } from "./translations"

export const PRIVATE_DATA_EN: PrivateDataTranslationsGroup = {
  ...SHARED_EN,
  privateData: {
    categories: "Categories",
    mainTabs: (tab) => {
      switch (tab) {
        case "map":
          return "Map"
        case "data":
          return "Data"
      }
    },
    deleteDialog: {
      category: {
        title: "Delete Category",
        message: "Do you really want to delete the category {}? This will also remove all the associated POIs.",
      },
      pois: {
        title: "Delete POIs",
        message: "You have selected {} POIs for deletion. Continue?",
      },
      delete: "Delete",
    },
    poiCreateOrEdit: {
      create: "Add POI",
      edit: "Edit POI",
      optional: "Optional",
      address: "Address",
      companyAssignedId: "ID",
      title: "Title",
      notes: "Notes",
      addressInputPlaceholder: "Enter Address",
      setPinHint: "The address is ambiguous. You can click in the map to refine the desired location.",
      outsideOfGermany: "You can't use an address outside of Germany.",
    },
    fileUpload: {
      title: "Upload Address List",
      hint: `You can upload an Excel file containing up to 1000 addresses. </br> If you need help with the file format, take a look at the <a href="https://docs.google.com/document/d/1LDviPxMe5NGCdxHOYFeNp4kH2z9Km2nqqeUVZT2eOfs" target="_blank">documentation</a>.`,
      selectFile: "Select file",
      uploadingFile: "Uploading file",
      missingColumns: {
        postalCode: "Zip",
        city: "City",
        street: "Street",
        streetNumber: "Nr",
      },
      fileUploadErrorResponse: {
        errorTitle: "Error reading file.",
        "Incorrect format": "The uploaded file doesn't seem to have valid xls format.",
        "file is required": "No file detected for import.",
        InvalidFileType: "The uploaded file doesn't seem to have valid xls format.",
        NoRows: "The uploaded file does not have any rows",
        MissingColumns: "The following columns could not be found: {}",
        "Limit for POIs in this category reached":
          "You have exceeded the 1000-row limit for this category. A total of 1000 rows, including any existing entries, will be preserved.",
      },
    },
    cancel: "Cancel",
    newCategory: "New Category",
    allCategories: "All categories",
    createCategory: "Create category",
    title: "Title",
    notes: "Notes",
    data: "Data",
    icon: "Icon",
    upload: "Upload",
    add: "Add",
    create: "Create",
    edit: "Edit",
    lock: "Lock",
    unlock: "Unlock",
    delete: "Delete",
    editCategory: "Edit category",
    save: "Save",
    lockCategory: "Lock category",
    unlockCategory: "Unlock category",
    lockMessage:
      "You can lock a category to protect it. Your colleagues can still use it, but they can not edit or delete it.",
    unlockMessage: "If you unlock your category, your colleagues will be able to edit and delete it.",
    categoryNameAlreadyExists: "A category with this name already exists.",
    id: "ID",
    address: {
      postalCode: "ZIP",
      locality: "City",
      route: "Street",
    },
    noPoisAvailable:
      "No POIs have been added to this category yet. Use the 'Add' button to create a single POI or the 'Upload' button to upload a list of POIs.",
    noCategoriesFound:
      "No categories have been created yet. Please click on 'New category'. You can then add a single POI or upload a list.",
  },
}
