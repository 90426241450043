import * as React from "react"
import { css, cx } from "emotion"
import { getThemeColorVar } from "../helper/color"
import { EmotionCSS } from "../helper/responsive"
import Grid from "./restyle-grid/grid"

export type ButtonTabsProps<T extends string> = {
  id?: string
  values: T[]
  selected: T
  onSelect?: (selection: T) => void
  translate?: (item: T) => string
}

const buttonTabsButtonClass = css({
  userSelect: "none",

  ":first-of-type": {
    borderRadius: "4px 0px 0px 4px",
  },
  ":last-of-type": {
    borderRadius: "0px 4px 4px 0px",
  },

  zIndex: 0,

  color: getThemeColorVar("typo", "light"),
  borderColor: getThemeColorVar("border", "default"),

  ":not(:first-of-type)": {
    marginLeft: "-1px",
  },

  backgroundRepeat: "no-repeat",
  cursor: "pointer",
  textAlign: "center",
  whiteSpace: "nowrap",

  fontSize: "14px",
  lineHeight: "14px",
  padding: "7px 16px",

  borderWidth: "1px",
  borderStyle: "solid",
} as EmotionCSS)

const buttonTabsSelectedClass = css({
  cursor: "inherit",
  color: getThemeColorVar("white", undefined),
  borderColor: getThemeColorVar("primary", "default"),
  backgroundColor: getThemeColorVar("primary", "default"),
  zIndex: 1,
})

export class ButtonTabs<T extends string> extends React.Component<ButtonTabsProps<T>, {}> {
  render() {
    return (
      <div>
        <Grid columns={this.props.values.length}>
          {this.props.values.map((value) => {
            const classes = [buttonTabsButtonClass]
            if (value === this.props.selected) {
              classes.push(buttonTabsSelectedClass)
            }
            const translatedValue = this.props.translate ? this.props.translate(value) : value
            return (
              <div
                key={value}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  this.props.onSelect && this.props.onSelect(value)
                }}
                className={cx(classes)}
              >
                {translatedValue}
              </div>
            )
          })}
        </Grid>
      </div>
    )
  }
}

export default ButtonTabs
