import * as ol from "ol"
import { bind } from "decko"
import { Pixel } from "ol/pixel"
import { createRoot, Root } from "react-dom/client"

export type ContentCreator = (pixel: Pixel) => { id: string; content: JSX.Element } | null

export class SimpleOverlay {
  private map: ol.Map
  private overlayContent: HTMLElement = document.createElement("div")
  private overlayContentRoot: Root = createRoot(this.overlayContent)
  private overlay: ol.Overlay
  private contentCreator: ContentCreator
  private onSelection: (id: string | null) => void
  private selectedId: string | null
  private rendering: boolean = false
  private nextPixel: Pixel | undefined = undefined

  constructor(map: ol.Map, contentCreator: ContentCreator, onSelection: (id: string | null) => void) {
    this.map = map
    this.contentCreator = contentCreator
    this.onSelection = onSelection
    this.selectedId = null

    this.overlay = new ol.Overlay({
      element: this.overlayContent,
      stopEvent: true,
      insertFirst: true,
      autoPan: true,
    })

    this.map.addOverlay(this.overlay)
    this.map.on("singleclick", this.onClick)
    this.map.on("precompose", () => (this.rendering = true))
    this.map.on("rendercomplete", () => {
      this.rendering = false // Circumvent unpredictable behaviour when clicking while map is still rendering
      if (this.nextPixel) this.openOverlayAt(this.nextPixel)
      this.nextPixel = undefined
    })
  }

  @bind
  private onClick(event: ol.MapBrowserEvent<any>) {
    this.nextPixel = event.pixel
    if (!this.rendering) {
      this.nextPixel = undefined
      this.openOverlayAt(event.pixel)
    }
  }

  getSelectedId(): string | null {
    return this.selectedId
  }

  getRendering(): boolean {
    return this.rendering
  }

  @bind
  openOverlayAt(pixel: Pixel): void {
    const content = this.contentCreator(pixel)
    if (!content) {
      this.closeCurrentOverlay(true)
      return
    }

    this.selectedId = content.id
    this.overlayContentRoot.render(content.content)

    this.overlay.setPosition(this.map.getCoordinateFromPixel(pixel))
    this.onSelection(this.selectedId)
  }

  @bind
  closeCurrentOverlay(resetSelectedId: boolean): void {
    this.overlay.setPosition(undefined as any)

    if (resetSelectedId) {
      this.selectedId = null
      this.onSelection(null)
    }
  }
}
