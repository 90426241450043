import React from "react"
import { Overlay } from "@blueprintjs/core"
import { getThemeColorVar } from "../shared/helper/color"
import { css } from "emotion"
import Text from "../shared/components/text"
import Button from "../shared/components/button"
import { language } from "../shared/language"

type Props = {
  show: boolean
  onClose: () => void
}

const styles = {
  centered: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  }),
}

type Translations = {
  title: string
  dates: string
  bodyOne: string
  bodyTwo: string
  button: string
}

type Language = "de" | "en"

const translations: { [K in Language]: Translations } = {
  de: {
    title: "Systemwartungsbenachrichtigung",
    dates: "29. August von 7:00 bis 11:00 Uhr",
    bodyOne: "Das System wird am 29. August von 7:00 bis 11:00 Uhr gewartet.",
    bodyTwo: "Wir entschuldigen uns für die Unannehmlichkeiten und danken Ihnen für Ihr Verständnis.",
    button: "Bestätigen",
  },
  en: {
    title: "System Maintenance",
    dates: "29th of August from 7:00 AM until 11:00 AM",
    bodyOne: "Relas will be under maintenance on 29th of August from 7:00 AM to 11:00 AM.",
    bodyTwo: "We apologise for the inconvenience and appreciate your understanding",
    button: "Acknowledge",
  },
}

export const DowntimeOverlay = (props: Props) => {
  const lang = language() as Language
  const t = translations[lang]

  return (
    <Overlay isOpen={props.show} onClose={props.onClose} canEscapeKeyClose enforceFocus autoFocus>
      <div
        style={{
          top: "30%",
          height: "100vh",
          width: "100vw",
        }}
      >
        <div
          style={{
            height: "40vh",
            width: "30vw",
            backgroundColor: getThemeColorVar("background", "default"),
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          <div className={styles.centered}>
            <Text size="lg" fontWeight="bold">
              {t.title}
            </Text>
          </div>

          <div
            className={styles.centered}
            style={{
              backgroundColor: getThemeColorVar("primary", "light"),
              padding: "0 50px 0 50px",
            }}
          >
            <Text fontWeight="bold">{t.dates}</Text>
          </div>

          <div className={styles.centered}>
            <Text>{t.bodyOne}</Text>
            <Text>{t.bodyTwo}</Text>
          </div>

          <div className={styles.centered}>
            <Button type="primary" onClick={props.onClose}>
              {t.button}
            </Button>
          </div>
        </div>
      </div>
    </Overlay>
  )
}
