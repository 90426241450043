import React, { useMemo, useState } from "react"
import { AssessmentEntryReportConfiguration } from "../../../assessment/components/assessment-entry-report-configuration"
import { getEmptyScores, Scores } from "../../models/scores"
import { translations } from "../../../assessment/i18n"
import { css } from "emotion"
import { Assessment, AssessmentEntry, isInProgressState } from "../../../assessment/models/assessment"
import { Category } from "../../smartdata-products/smartdata"
import { useAppSelector } from "../../../relas/store"
import { shallowEqual } from "react-redux"
import { lanaApiUrl } from "../../../app_config"
import { trackUsageEvent } from "../../../utils/usage-tracking"
import { AssessmentDashboardId } from "../../../assessment/models/AssessmentDashboard"
import { DropdownButton, DropdownItemProps } from "../dropdown-button"
import { getThemeColorVar } from "../../helper/color"
import { language } from "../../language"
import Icon from "../icon"

const styles = {
  disabledLink: css({
    pointerEvents: "none",
    textDecoration: "none",
    color: getThemeColorVar("disabled", "light"),
  }),
}

interface Props {
  selectedScoreCategory?: Category
  scores?: Scores
  showDashboardDownload?: AssessmentDashboardId
  showXLSDownload?: boolean
}

const downloadButtonFlex = css({
  "div div button i:first-child": {
    marginRight: "0px",
  },
  "div div button i:last-child": {
    display: "none",
  },
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  alignItems: "center",
})

export const isDisabledForAssessmentEntry = (assessment: Assessment | null, assessmentEntry: AssessmentEntry | null) =>
  !assessment || !assessmentEntry || isInProgressState(assessment.state) || assessmentEntry?.state === "failure"

export const DownloadReport = (props: Props) => {
  const t = useMemo(translations, [translations])
  const [isAssessmentEntryReportOpen, setIsAssessmentEntryReportOpen] = useState(false)

  const onDownloadScores = () => {
    if (!currentAssessment || !currentAssessmentEntry || isInProgressState(currentAssessmentEntry.state)) return

    const lang = language()

    window.open(
      `${lanaApiUrl}/api/assessments/${currentAssessment.id}/entries/${currentAssessmentEntry.id}/downloadScores?lang=${lang}`,
      "_blank"
    )
  }
  const {
    currentAssessmentEntry,
    currentAssessment,
    poiExplorerInput,
    poiCategoryTranslations,
    viewMode,
    selectedProfile,
  } = useAppSelector((state) => {
    return {
      currentAssessmentEntry: state.assessment.currentAssessmentEntry,
      currentAssessment: state.assessment.currentAssessment,
      poiExplorerInput: state.POIExplorerApp.poiExplorerInput,
      poiCategoryTranslations: state.assessment.poiCategoryTranslations,
      viewMode: state.assessment.selectedViewMode,
      selectedProfile: state.assessment.selectedProfile,
    }
  }, shallowEqual)

  const actionItems = useMemo<(DropdownItemProps | "divider")[]>(() => {
    const actionItems: (DropdownItemProps | "divider")[] = [
      {
        title: t.assessmentEntryReport.downloadReportButton,
        onClick: () => setIsAssessmentEntryReportOpen(true),
        icon: "download",
      },
    ]

    if (props.showXLSDownload) {
      actionItems.push({
        title: t.assessmentEntryReport.downloadDataButton,
        onClick: onDownloadScores,
        icon: "download",
      })
    }

    if (props.showDashboardDownload) {
      const pdfDownloadName = `Assessment Dashboard - ${currentAssessment?.title?.replaceAll(
        /[\/\\\?\*:<>|"]/g,
        "_"
      )}.pdf`
      const langCode = language()
      actionItems.push({
        title: t.header.downloadPDFTooltip,
        onClick: () =>
          currentAssessmentEntry?.address &&
          trackUsageEvent("DOWNLOAD_DASHBOARD", currentAssessmentEntry.address, null),
        icon: "download",
        target: "_blank",
        href: `${lanaApiUrl}/api/dashboard/pdf?url=${encodeURIComponent(
          `${window.location.href}/pdf/${props.showDashboardDownload}`
        )}&name=${encodeURIComponent(pdfDownloadName)}&lang=${langCode}`,
        download: pdfDownloadName,
      })
    }

    return actionItems
  }, [
    props.showDashboardDownload,
    props.showXLSDownload,
    currentAssessment,
    currentAssessmentEntry,
    window.location.href,
  ])

  return (
    <>
      {!props.showDashboardDownload && !props.showXLSDownload && (
        <div className={downloadButtonFlex}>
          <a
            href={"#"}
            className={
              isDisabledForAssessmentEntry(currentAssessment, currentAssessmentEntry) ? styles.disabledLink : ""
            }
            onClick={(e) => {
              e.preventDefault()
              setIsAssessmentEntryReportOpen(true)
            }}
          >
            <Icon name="download" size={"regular"} />
          </a>
          <div style={{ fontSize: "14px" }}>{t.download}</div>
        </div>
      )}

      {(props.showDashboardDownload || props.showXLSDownload) && (
        <div id={"assessmentDownloadDropdownLanaSubheader"} className={downloadButtonFlex}>
          <DropdownButton
            type={"tertiary"}
            disabled={
              !currentAssessmentEntry ||
              isInProgressState(currentAssessmentEntry.state) ||
              currentAssessmentEntry?.state === "failure"
            }
            actions={actionItems}
          >
            <Icon name="download" size={"regular"} />
          </DropdownButton>
          <div>{t.download}</div>
        </div>
      )}
      <AssessmentEntryReportConfiguration
        isOpen={isAssessmentEntryReportOpen}
        viewMode={viewMode}
        selectedProfile={selectedProfile}
        selectedScoreCategory={props.selectedScoreCategory}
        scores={props.scores ?? getEmptyScores()}
        poiExplorerInput={poiExplorerInput}
        poiCategoryTranslations={poiCategoryTranslations}
        onClose={() => setIsAssessmentEntryReportOpen(false)}
      />
    </>
  )
}
