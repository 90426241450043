import { IPanel, PanelStack } from "@blueprintjs/core"
import * as React from "react"
import { CreateSelection } from "./create-selection"
import { EditSelection } from "./edit-selection"
import { EditSelectionTitle } from "./edit-selection-title"
import { SelectionList } from "./selection-list"
import { DuplicateSelection, RenameSelection } from "./selection-rule-name-edit"
import { SelectionPane } from "../location-selector-slice"

type Props = {
  selectionViewPane: SelectionPane
}

const initialStack = [{ component: SelectionList }]

export const LocationSelectorMenu: React.FC<Props> = (props) => {
  const [stack, setStack] = React.useState<IPanel<any>[]>(initialStack)

  function pushToStack<T>(el: React.ComponentType) {
    const panel: IPanel<any> = {
      component: el,
    }

    setStack([...stack, panel])
  }

  React.useEffect(() => {
    switch (props.selectionViewPane) {
      case SelectionPane.List:
        setStack(initialStack)
        break
      case SelectionPane.Create:
        pushToStack(CreateSelection)
        break
      case SelectionPane.Edit:
        pushToStack(EditSelection)
        break
      case SelectionPane.Duplicate:
        pushToStack(DuplicateSelection)
        break
      case SelectionPane.Rename:
        pushToStack(RenameSelection)
        break
      case SelectionPane.EditTitle:
        pushToStack(EditSelectionTitle)
        break
    }
  }, [props.selectionViewPane])

  return <PanelStack stack={stack} showPanelHeader={false} />
}
