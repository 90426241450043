import * as React from "react"
import { FC, Fragment, useMemo } from "react"
import { ratingStyles } from "../rating-styles"
import { useAppDispatch, useAppSelector } from "../../../relas/store"
import { translations } from "../../i18n"
import { css, cx } from "emotion"
import { isInMetaRange, isMetaRating, MetaRatingRange, Rating } from "../../../shared/models/ratings"
import { COLORS } from "../../../shared/components/ui/colors"
import { Flex } from "../../../shared/components/ui/flex"
import { FlexItem } from "../../../shared/components/ui/flex-item"
import { AddressSuggestionAutoCompleteField } from "../../../shared/components/address-suggestion-auto-complete-field"
import { ratingManagerActions } from "../../rating-manager-slice"
import { Address } from "../../../assessment/models/address"
import { doUpdateRating, fetchMetaResults } from "../../rating-manager-actions"
import { mapStyles } from "../map/rating-manager-map-styles"
import { NumberInput } from "../../../shared/components/ui/number-input"
import Button from "../../../shared/components/button"
import { formatNumber } from "../../../shared/helper/number-format"

const styles = {
  container: cx(
    ratingStyles.container,
    css({
      padding: "16px 8px",
      gap: 16,
      hr: {
        margin: "0",
      },
    })
  ),
  withPadding: css({
    padding: "0 16px",
  }),
  rowFlex: css({
    display: "flex",
    flexDirection: "row",
    gap: 8,
  }),
  ratingsGrid: css({
    display: "grid",
    gridTemplateColumns: "min-content 1fr min-content",
    overflowY: "auto",
    border: `1px solid ${COLORS.border.default}`,
    gridTemplateRows: "min-content",
    ">div": {
      borderBottom: `1px solid ${COLORS.border.default}`,
      padding: "8px 4px",
      display: "flex",
      alignItems: "center",
    },
  }),
  ratingGridHeader: css({
    fontWeight: "bold",
    backgroundColor: COLORS.background.light,
  }),
  ratingGridHeaderFirst: css({
    gridColumn: "span 2",
  }),
  bottomBlock: css({
    textAlign: "right",
  }),
}

export const RatingManagerEditMetaRating: FC = () => {
  const t = useMemo(() => translations(), [])

  const dispatch = useAppDispatch()

  const { currentRating, ratingList, updateInProgress } = useAppSelector((state) => {
    let currentRating
    if (state.ratingManager.currentRating && isMetaRating(state.ratingManager.currentRating)) {
      currentRating = state.ratingManager.currentRating
    } else {
      currentRating = undefined
    }

    return {
      currentRating,
      updateInProgress: state.ratingManager.updateInProgress,
      agsRefResLoc: state.ratingManager.agsRefResLoc,
      showPreview: state.ratingManager.showPreview,
      ratingList: state.ratingManager.ratingList,
    }
  })

  const [weights, setWeights] = React.useState(currentRating?.weights)

  const [addressToTest, setAddressToTest] = React.useState<Address | undefined>(undefined)

  const [calculationInProgress, setCalculationInProgress] = React.useState(false)

  const [calculationResult, setCalculationResult] = React.useState<
    { result: number; range: MetaRatingRange | undefined } | undefined
  >(undefined)

  const [macroRatings, microRatings] = useMemo(() => {
    const [macroRatings, microRatings] = ratingList.reduce(
      (acc, rating) => {
        if (rating.dataSetType === "macro") {
          acc[0].push(rating)
        } else {
          acc[1].push(rating)
        }
        return acc
      },
      [[], []] as [Rating[], Rating[]]
    )
    return [macroRatings, microRatings]
  }, [ratingList])

  function updateWeight(id: string, weight: number | undefined) {
    setWeights((weights) => {
      const w = weights ? { ...weights } : {}
      if (weight === undefined) {
        delete w[id]
      } else {
        w[id] = weight
      }
      return w
    })
  }

  function saveRating() {
    if (currentRating) {
      const filledWeights = Object.keys(weights || {}).reduce((acc, key) => {
        if (weights && weights[key] !== undefined) {
          acc[key] = weights[key]
        }
        return acc
      }, {} as { [key: string]: number })
      void doUpdateRating(dispatch)({
        ...currentRating,
        weights: filledWeights,
      })
    }
  }

  async function calculate() {
    setCalculationResult(undefined)
    if (addressToTest?.location && currentRating) {
      setCalculationInProgress(true)
      try {
        const response = await fetchMetaResults(currentRating.id, addressToTest.location, weights)
        const result = response.result
        if (result !== undefined) {
          const range = currentRating.ranges.find((r) => isInMetaRange(r, result))
          setCalculationResult({
            result,
            range,
          })
        } else {
          setCalculationResult(undefined)
        }
      } catch (e) {
        console.error(e)
      }
    }

    setCalculationInProgress(false)
  }

  function renderRatingCheckbox(rating: Rating): JSX.Element {
    const onClick = () => updateWeight(rating.id, weights?.[rating.id] !== undefined ? undefined : 1)
    return (
      <Fragment key={`rating-checkbox-${rating.id}`}>
        <div>
          <input
            type="checkbox"
            checked={weights?.[rating.id] !== undefined}
            onChange={onClick}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div style={{ cursor: "pointer" }} onClick={onClick}>
          {rating.name}
        </div>
        <NumberInput defaultValue={weights?.[rating.id]} onValidValueChange={(n) => updateWeight(rating.id, n)} />
      </Fragment>
    )
  }

  return (
    <div className={styles.container}>
      <div className={cx(styles.withPadding, styles.rowFlex)}>
        <Button type="tertiary" icon="back" onClick={() => dispatch(ratingManagerActions.setMenuPane("list"))}>
          {t.allRatings}
        </Button>
        <FlexItem flexGrow={1} />
        <Button type="tertiary" icon="edit" onClick={() => dispatch(ratingManagerActions.setMenuPane("edit-rating"))} />
      </div>
      <div className={styles.withPadding}>
        <h2>{currentRating?.name}</h2>
      </div>
      <div className={styles.ratingsGrid}>
        <div className={cx(styles.ratingGridHeader, styles.ratingGridHeaderFirst)}>{t.macroRating}</div>
        <div className={styles.ratingGridHeader}>{t.weight}</div>
        {macroRatings.map(renderRatingCheckbox)}
      </div>
      <div className={styles.ratingsGrid}>
        <div className={cx(styles.ratingGridHeader, styles.ratingGridHeaderFirst)}>{t.microRating}</div>
        <div className={styles.ratingGridHeader}>{t.weight}</div>
        {microRatings.map(renderRatingCheckbox)}
      </div>
      <fieldset>
        <Flex flexDirection={"column"} gap={12}>
          <AddressSuggestionAutoCompleteField label={t.editForm.test} onValueChange={setAddressToTest} />
          <div className={styles.rowFlex}>
            <FlexItem flexGrow={1} />
            <Button
              type="primary"
              loading={calculationInProgress}
              disabled={calculationInProgress || addressToTest === undefined}
              onClick={() => calculate()}
            >
              {t.calculate}
            </Button>
            <div className={mapStyles.calculationResult(calculationResult?.range)}>
              {calculationResult?.result !== undefined ? formatNumber(calculationResult?.result, 2) : "-"}
            </div>
          </div>
        </Flex>
      </fieldset>

      <div className={styles.bottomBlock}>
        <Button type="primary" disabled={updateInProgress} onClick={() => saveRating()}>
          {t.save}
        </Button>
      </div>
    </div>
  )
}
